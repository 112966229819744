import React from 'react';

import * as echarts from 'echarts';
import BarChart from "./BarChart_1";
// 雷达图
class BasicRadarChart extends React.Component {


    componentDidUpdate(num){
        if(this.props?.data){
            this.elementById();
        }
    }
    componentDidMount(){
        if(this.props?.data){
            this.elementById();
        }

    };
    elementById= async =>{
        var yData = this.props?.ydata;
        function formatter(params) {
            var result = '';
            for (var i = 0; i < params.length; i++) {
                result += params[i].name + '<br />';
                result += params[i] + ': ' + params[i].name + "%<br />";
            }
            return result;
            // return '风险率<br/>' +
            //     '   · 警情风险<br/>' +
            //     '   · 工作风险<br/>' +
            //     '   · 设备风险<br/>' +
            //     '   · 计划完成风险<br/>' +
            //     '   · 管理风险<br/>' +
            //     '   · 警情风险<br/>' +
            //     '<p style="width: 120px;color: #6CCB55;background-color: #00b6ec;text-align: right">风险:</p>'
        }


        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                // title: {
                //     text: 'Multiple Radar'
                // },
                tooltip: {
                    trigger: 'axis'
                },

                radar: {
                    // shape: 'circle',// 圆形
                    indicator: this.props?.ydata,
                    center: ['50%', '50%'],
                    radius: ['10%','80%'], //图形大小
                    axisName: {
                        borderRadius: 3,
                        padding: [-12, -10]
                    },
                },

                series: [
                    {
                        type: 'radar',

                        data: [
                            {
                                type: 'radar',
                                tooltip: {
                                    show:true,
                                    trigger: 'item',
                                    // formatter: formatter(yData)

                                },

                                value: this.props?.data?.data,
                                name: this.props?.data?.name,
                                itemStyle:{
                                    normal:{
                                        color:'rgba(244,127,59,0.6)'
                                    }
                                }

                            }
                        ]
                    }
                ]
            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BasicRadarChart;
