import React from 'react';

import * as echarts from 'echarts';
// 条形图
class BarChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        var series=[];
        // 处理单挑数据拼接
        this.props?.data?.map(
            one=>{
                series.push(
                    {
                        name: one.name,
                        type: 'bar',
                        // stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: one.data,
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(0,0,0,1,
                                    [
                                        {offset:0,color:one.color[0]},
                                        {offset:1,color:one.color[1]},
                                    ])
                            }
                        }

                    },

                )
            }
        );


        var chartDom = echarts.init(document.getElementById(this.props.elementById) );
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                //标题
                title: {
                    text: this.props.data[0].name||null,
                    textStyle:{
                        color:'#e5e5e5',
                        fontWeight:'normal',
                        fontSize:'14px'
                    },
                    left:'44%',
                    top:'85%'

                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {},
                grid: {
                    top:'10%',
                    left: '12%',
                    right: '10%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    // interval:this.props.number.interval,// 数值间隔
                    data:this.props?.yData,
                    type: 'category',
                    // boundaryGap: [0, 1],
                },
                yAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    // interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    data:this.props?.yData
                },
                series:series

            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
