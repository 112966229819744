import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Popconfirm, Table, Image} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import FormsText from "../../../components/common/forms/FormsText";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getLocationById} from "../../../services/customer";
import './SafetyAssessmentDetails.less'
import {getEvaluationReport, removeGasBurner} from "../../../services/meter";
import ajax from "../../../services/ajax";
import axios from "axios";
import moment from "moment";
// 工商户安全评估
export default class SafetyAssessmentDetails extends Component {

    state = {
        // 表单参数
        formText_1: {
            header: {
                customerName: '客户名称',
                address:'详细地址',
                evaluationResults:'评估结果',
                evaluationData: '评估日期',
            }
        },
        // 自检情况
        formText_2: {
            header: {
                selfInspectionRequiredNumber: '需自检次数',
                selfCheckedNumber:'已自检次数',
                selfInspectionCompletionRate:'自检完成率',
            }
        },
        // 安检情况
        formText_3: {
            header: {
                securityCheckRequiredNumber: '需安检次数',
                securityChecked:'已安检次数',
                securityCompletionRate:'安检完成率',
            }
        },
        // 燃气设备周检情况
        formText_4: {
            header: {
                alarmVerificationResults: '报警器检定结果',
                flowMeterResults:'流量计检定结果',
                shutoffValveStatus:'切断阀状态',
            }
        },
        selectRowData:null,
        switchType:0,// 类型切换，0正常页面，1明细界面


        // 表格标题-燃气设备周检情况-1
        left_tableData_1: [
            {title: '对象', dataIndex: 'type', key: 'type',align:'center'},
            {title: '隐患名称', dataIndex: 'name', key: 'name',align:'center'},
            {title: '分级', dataIndex: 'level', key: 'level',align:'center'},
            // {title: '自检时间', dataIndex: 'time', key: 'time'},
            {title: '状态', dataIndex: 'state', key: 'state',align:'center'}
        ],
        // 表格参数-1-参数
        left_tableData_1_param:{
            customerId:this.props.location?.state?.rowData?.customerId,
        },
        left_tableData_1_data:[],// 表格参数-1-数据


        evaluationReport:{},// 数据
    };


    componentDidMount=async ()=>{

        console.log(this.props.location?.state?.rowData?.id);
        // 判断是否跳转还是扫描进入,用id判断
        if(this.props.location?.state?.rowData?.id){
            console.log('跳转');
            this.getData1(this.props.location?.state?.rowData?.id); // 正常跳转
        }else{

            var search = this.props.location.search;// 获取扫描携带参数
            if(search){
                console.log('扫描');
                const index = search.indexOf('=');   // 获取相同值的位置
                const beforeStr = search.substr(index+1);                              // 截取前半部分没有相同的值
                this.getData2(beforeStr);   // 二维码
            }else{
                console.log('无数据面板');
                var evaluationReport = this.state.evaluationReport;
                evaluationReport=
                {
                    "customerId": null,
                    "customerName":  null,
                    "address":  "未知",
                    "evaluationResults": "高风险",
                    "evaluationData": moment(new Date()).format("yyyy-MM-DD"),
                    "selfInspectionRequiredNumber": "0",
                    "selfCheckedNumber": "0",
                    "selfInspectionCompletionRate": "0%",
                    "securityCheckRequiredNumber": "0",
                    "securityChecked": "0",
                    "securityCompletionRate": "0%",
                    "alarmVerificationResults": "不合格",
                    "flowMeterResults": "不合格",
                    "shutoffValveStatus": "不合格",
                };
                this.setState({evaluationReport})
            }

        }
    };

    async getData1(estimateId) {
        getEvaluationReport({estimateId:estimateId}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    var evaluationReport = this.state.evaluationReport;
                    evaluationReport=response.data.data;
                    evaluationReport.customerId=evaluationReport?.customerId??this.props.location?.state?.rowData.customerId;
                    evaluationReport.customerName=evaluationReport?.customerName??this.props.location?.state?.rowData.customerName;
                    evaluationReport.address=evaluationReport?.address??this.props.location?.state?.rowData.address;
                    this.setState({
                        evaluationReport:evaluationReport,
                        left_tableData_1_data:response.data.data?.templateList ||[]
                    });
                }
            }
        )
    }
    async getData2(estimateId) {
        axios
            .get('http://192.168.1.112:9881/security-assessment/getEvaluationReport?estimateId='+estimateId,)
            .then((response) => {
                if(response?.data?.data){
                    this.setState({
                        evaluationReport:response.data.data,
                        left_tableData_1_data:response.data.data?.templateList ||[]
                    });
                }
            })
            .catch((error) => console.log('Error', error))
    }

    render() {

        return (
            <Layout id={'SafetyAssessment'} className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                {/*返回上一页*/}
                <div className={'div_back_arrow'}>
					<span className={'back_arrow'}
                          onClick={() => this.props.history.go(-1)}
                    >
                        <ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回记录明细管理</span>
                </div>
                {/*分割线*/}
                <div style={{width:'100%',height:'80px',fontSize:'36px',textAlign: 'center', paddingTop:'10px'}}>
                    <span>安全评估</span>
                </div>



                <div className={'divider'}/>
                <div style={{width:'100%',height:'720px'}}>
{/*第一行-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'280px',paddingLeft:'18%'}}>

                        <div  style={{width:'500px', height:'100%', fontWeight: 'bold',fontSize:'20px', float:'left', padding:'20px 0 0 150px',}}>

                            <div style={{
                                width:'200px', height:'200px',
                                zIndex :'9998'}}>
                                    <FormsText
                                        // 数据
                                        record={this.state?.evaluationReport}
                                        header={this.state.formText_1.header}
                                        rowLen={1}
                                        span={6}
                                    />
                            </div>
                            {/*图片*/}
                            <div className={this.state?.evaluationReport.evaluationResults==='低风险'?'img_2':'img_1'}>
                            </div>
                        </div>
                        {/*二维码*/}
                        <div style={{width:'500px',height:'100%',float:'left',padding:'20px 0 0 20px'}}>
                            {/*<div style={{width:'250px',height:'200px',backgroundColor:'#000'}}/>*/}

                            <Image
                                width={200}
                                src={this.state?.evaluationReport?.evaluationPath || null}
                            />
                            {/*<QRCode*/}
                            {/*    option={{*/}
                            {/*        id:'123'*/}
                            {/*    }}*/}
                            {/*    value={'http://localhost:3000/SafetyAssessmentDetails/#id=123'}  //想要生成二维码的内容*/}
                            {/*    size={200} //大小*/}
                            {/*    fgColor="#000"  //颜色*/}
                            {/*    imageSettings={*/}
                            {/*        {*/}
                            {/*            // src: 'url', // 图片来源*/}
                            {/*            x: '1120', // 横向距离, 默认中心*/}
                            {/*            // y: number, // 纵向距离, 默认中心*/}
                            {/*            height: '10%',  // 图片高度，默认size的10%*/}
                            {/*            width: '10%', // 图片宽度， 默认size的10%*/}
                            {/*            excavate: false, // 展示未知效果，默认false*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*/>*/}
                        </div>
                    </div>
{/*第二行-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'120px'}}>
                        <div style={{width:'100%',height:'30px',textAlign: 'center'}}>
                            <span style={{fontSize:'18px',fontWeight: 'bolder'}}>自检情况</span>
                        </div>
                        <div style={{width:'100%',paddingLeft:'20%'}}>
                            <FormsText
                                record={this.state?.evaluationReport??{
                                    id: '客户名称',
                                    type: '详细地址',
                                    state: '评估结果',
                                }}
                                header={this.state.formText_2.header}
                                rowLen={3}
                                span={6}
                            />
                        </div>
                    </div>
{/*第三行-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'120px'}}>
                        <div style={{width:'100%',height:'30px',textAlign: 'center'}}>
                            <span style={{fontSize:'18px',fontWeight: 'bolder'}}>安检情况</span>
                        </div>
                        <div style={{width:'100%',paddingLeft:'20%'}}>
                            <FormsText
                                record={this.state?.evaluationReport??{
                                    id: '客户名称',
                                    type: '详细地址',
                                    state: '评估结果',
                                }}
                                header={this.state.formText_3.header}
                                rowLen={3}
                                span={6}
                            />
                        </div>
                    </div>
{/*第四行-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'120px'}}>
                        <div style={{width:'100%',height:'30px',textAlign: 'center'}}>
                            <span style={{fontSize:'18px',fontWeight: 'bolder'}}>燃气设备周检情况</span>
                        </div>
                        <div style={{width:'100%',paddingLeft:'20%'}}>
                            <FormsText
                                record={this.state?.evaluationReport??{
                                    id: '客户名称',
                                    type: '详细地址',
                                    state: '评估结果',
                                }}
                                header={this.state.formText_4.header}
                                rowLen={3}
                                span={6}
                            />
                        </div>
                    </div>
{/*第五行-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'120px'}}>
                        <div style={{width:'100%',height:'30px',textAlign: 'center'}}>
                            <span style={{fontSize:'18px',fontWeight: 'bolder'}}>存在隐患</span>
                        </div>
                        <div style={{width:'100%'}}>
                            <Table
                                columns={this.state.left_tableData_1}
                                dataSource={this.state.left_tableData_1_data}
                                // rowKey={"id"}
                                bordered
                                // pagination={false}
                                pagination = {{
                                    showSizeChanger:false,
                                    showQuickJumper: false,
                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                    total:this.state.left_tableData_1_data?.length,
                                    pageSize:6,
                                    showTotal: (count = this.state.left_tableData_1_data?.length)=>{return '共'+count+'条数据'},
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['6']
                                }}
                            />
                        </div>
                    </div>
                </div>

            </Layout>
        )
    }
}
