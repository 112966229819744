import React from 'react';

import * as echarts from 'echarts';
// 折线图
class StackedLineChartOne_1 extends React.Component {
    componentDidUpdate(num){
        this.elementById();
    }
    componentDidMount() {
        this.elementById();
    }
    elementById(){
        var chartDom = echarts.init(document.getElementById('this.props.elementById1'), "macarons");
        chartDom.setOption(
            {
                // title: {
                //     text: '七日数据表',
                //     textStyle:{
                //         fontSize:'12',
                //         color:"#fff",
                //     },
                //     tooltip: {
                //         trigger: 'axis',
                //     },
                // },
                tooltip: {
                    trigger: 'axis',
                },
                // 提示
                legend: {
                    data: ['维护数'],
                    align:'left',
                    right:4,
                    textStyle:{
                        fontSize:'12',
                        color:"#fff"
                    },
                    itemWidth:14,// 长度
                    itemHeight:4,// 小圆圈大小
                    itemGap:5// 间距
                },
                // 边距
                grid: {
                    top:'5%',
                    left: '0%',
                    right: '2%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.props?.xData,
                    axisLine:{
                        lineStyle:{
                            color:"#93a0a8"
                        }
                    },

                },
                yAxis: {
                    type: 'value',
                    min:this.props?.number?.min,     // 最小值
                    max:this.props?.number?.max,    // 最大值
                    interval:this.props?.number?.interval,// 数值间隔
                    axisLine:{
                        lineStyle:{
                            color:"#93a0a8"
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            // 设置背景横线
                            color: '#93a0a8',
                        }
                    },
                },
                series: [
                    // {
                    //     name: '今日未处理',
                    //     type: 'line',
                    //     // stack: 'Total',
                    //     data: this.props?.data?.untreateds,
                    //     // smooth: true, //默认是false,判断折线连线是平滑的还是折线
                    //     itemStyle: {
                    //         normal: {
                    //             color: "#ff7752", //改变折线点的颜色
                    //             lineStyle: {
                    //                 color: "#ff7752", //改变折线颜色
                    //             },
                    //         },
                    //     },
                    // },
                    // {
                    //     name: '今日已处理',
                    //     type: 'line',
                    //     // stack: 'Total',
                    //     data: this.props?.data?.processed,
                    //     itemStyle: {
                    //         normal: {
                    //             color: "#28e8ff", //改变折线点的颜色
                    //             lineStyle: {
                    //                 color: "#28e8ff", //改变折线颜色
                    //             },
                    //         },
                    //     },
                    // },
                    {
                        name: '今日在线率',
                        type: 'line',
                        // stack: 'Total',
                        data: this.props?.data?.totalToday,
                        itemStyle: {
                            normal: {
                                color: "#27eac1", //改变折线点的颜色
                                lineStyle: {
                                    color: "#27eac1", //改变折线颜色
                                },
                            },
                        },
                    }
                ]
            }
        )
    }


    render() {
        return (
            <div id={'this.props.elementById1'} style={{ width: '100%', height: '100%' }}>

            </div>
        );
    }

}
export default StackedLineChartOne_1;
