import React, { Component } from 'react'
import {Layout, Tabs, Descriptions, Button, Modal, Table, message,Checkbox,  DatePicker, Space } from "antd";
import './orderFormXinAo.less';
import {
    getOrderInfosByOrderId,
    getDeliverysByOrderId,
    getApplysByOrderId,
    approveTheApplyDelivery,
    getDevInfosByClassId, getMeterOrderForm, getDevInfosByOrderId
} from '../../../services/orderForm'
let user = JSON.parse(sessionStorage.getItem("user"));
const { Header, Footer, Sider, Content } = Layout;
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;
const { RangePicker } = DatePicker;
// -----------------------订单新奥详情页-----------------------
export default class orderFormXinAoDetails extends Component {
    state = {

        pointer_1:'auto',//auto/none

        modalVisible: false, // 弹窗-1

        indeterminate:null,
        checkAll:null,

        deliverSelectData:[],
        // 当前页标题栏-订单描述
        orderFormDes: {
            orderFormNo: "DD202212260001",
            createTime: "2022-7-12 18:00:01",
            completionTime: "",
            supplierCompany: "重庆市山城设备有限公司",
            counNum: "22",
            state: "测试中",
            supplierName: "罗成",
            supplierPhone: "1875998616"
        },// ----当前页标题栏-订单描述
        // 订单描述测试数据-可随时删除
        orderFormDes_test: [
            {title: "订单号", desc: "DD202212260001"}
            , {title: "创建时间", desc: "2022-7-12 18:00:01"}
            , {title: "完成时间", desc: "-"}
            , {title: "供应厂家", desc: "重庆市山城设备有限公司"}
            , {title: "采购总数", desc: "22"}
            , {title: "订单状态", desc: "测试中"}
            , {title: "供应厂家负责人", desc: "罗成"}
            , {title: "供应厂家联系电话", desc: "1875998616"}
        ],// ----订单描述测试数据-可随时删除

        // 第一页-订单详情-左表
        leftTableData: [
            {title: '设备型号', dataIndex: 'model', key: 'model',},
            {title: '台数', dataIndex: 'quantity', key: 'quantity',},
            {title: '已有', dataIndex: 'alreadyNum', key: 'alreadyNum',},
        ],// 第一页-订单详情-左表

        tableData_1_right_data:[ ],// 数据源


        // 第一页右表数据
        tableData_1_right_param:{
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                steelNum: '表刚号',
                simCard: 'SIM卡号',
                dataTime: '数据时间',
                total:'标况总量',
                workCumulativeFlow: '工况总量',
                pressure: '压力',
                temperature: '温度',
                valueStatus: '阀门状态',
                testResult: '测试结果',
                state: '表具状态',
                edit: '操作',
            },
            // 弹出框
            headers: {
                steelNum: '表刚号',
                simCard: 'SIM卡号',
                dataTime: '数据时间',
                total:'标况总量',
                workCumulativeFlow: '工况总量',
                pressure: '压力',
                temperature: '温度',
                valueStatus: '阀门状态',
                testResult: '测试结果',
                state: '表具状态',
            },
            params: {
                page:1,
                size:10,
                id:null
            },
            // 是否绑定左侧区域
            bindArea: true,
        },



        tableData_1_right:[
            {title: '表刚号1', dataIndex: 'steelNum', key: 'steelNum',
                render:(text)=>{
                return<>
                    <a onClick={this.communicationRecord}>
                        {text}
                    </a>
                    </>
                }
            },
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard',},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName',},
            {title: '数据时间', dataIndex: 'dataTime', key: 'dataTime',},
            {title: '标况总量', dataIndex: 'total', key: 'total',},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow',},
            {title: '压力', dataIndex: 'pressure', key: 'pressure',},
            {title: '温度', dataIndex: 'temperature', key: 'temperature',},
            {title: '阀门状态', dataIndex: 'valueStatus', key: 'valueStatus',},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult',},
            {title: '表具状态', dataIndex: 'state', key: 'state',
                render: (text, record) =>{
                    switch (record.state) {
                        case "已批准":
                            return<><span style={{"color": "#7cb305"}}>{text}</span></>;
                        case "已发货":
                            return<><span style={{"color": "#f5222d"}}>{text}</span></>;
                        case "申请发货":
                            return<><span style={{"color": "coral"}}>{text}</span></>;
                        default:
                            return<><span >{text}</span></>;
                    }
                }
            },
        ],
        rightTablepage:{
            areaId:"",
            steelNum:"",
            total: '', // 数据总数
            size: '12', // 每页条数
            page: '1', // 当前页码
        },
        tableData_2_data:[],// 第二页-表格内容
        // 第二页-表格标题
        tableData_2: [
            {title: '申请发货号', dataIndex: 'applyNum', key: 'applyNum',
                onCell:(text,index)=>(this.sharedOnCell_tableData_2(text, index,"applyNum","applyNum"))
            },
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum',},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard'},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName'},
            {title: '数据时间', dataIndex: 'applyTime', key: 'applyTime'},
            {title: '标况总量', dataIndex: 'total', key: 'total'},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow'},
            {title: '压力', dataIndex: 'pressure', key: 'pressure'},
            {title: '温度', dataIndex: 'temperature', key: 'temperature'},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus'},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult'},
            {title: '表具状态', dataIndex: 'state', key: 'state',
                render: (text, record) =>{
                    switch (record.state) {
                        case "已批准":
                            return<><span style={{"color": "#7cb305"}}>{text}</span></>;
                        case "已发货":
                            return<><span style={{"color": "#f5222d"}}>{text}</span></>;
                        case "申请发货":
                            return<><span style={{"color": "coral"}}>{text}</span></>;
                        default:
                            return<><span >{text}</span></>;
                    }
                }
            },
        ],// ----第二页-表格标题
        tableData_2_param:{
            total:0,
            page:1,
            size:2,
        },
        tableData_3_data:[],// 第三页-表格内容
        // 第三页-发货列表-表格标题
        tableData_3: [
            {title: '表钢号', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'
                // , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"sheetSteelGrade"))
            },
            {title: '型号', dataIndex: 'model', key: 'model', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"model","deliveryId"))},
            {title: '发货时间', dataIndex: 'deliveryTime', key: 'deliveryTime', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"deliveryTime","deliveryId"))},
            {title: '发货人', dataIndex: 'consignor', key: 'consignor', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"operatorName","deliveryId"))},
            {title: '联系电话', dataIndex: 'consignorPhone', key: 'consignorPhone', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsPhone","deliveryId"))},
            {title: '快递单号', dataIndex: 'logisticsNo', key: 'logisticsNo', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsNo","deliveryId"))},
            {title: '物流公司', dataIndex: 'logisticsCompany', key: 'logisticsCompany', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsCompany","deliveryId"))},
            {title: '物流联系电话', dataIndex: 'logisticsPhone', key: 'logisticsPhone', onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsPhone","deliveryId"))},
        ],// ----第三页-发货列表-表格标题
        tableData_3_param:{
            total:0,
            page:1,
            size:1000,
        },
        // 第三页-发货信息表
        tableDtoDeliveryNoteData: {
            manageName: "数据项管理",
            // 表格行是否可选
            // rowSelection: false,
            // checkStatus:true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                entrustNum: '表钢号',
                entrustUnit: '型号',
                state: '发货',
                type: '发货时间',
                principal: '发货人',
                phone: '联系电话',
                createTime: '快递单号',
                creator: '物流公司',
                verification: '物流联系电话',
                edit: '操作',
            },
            // 弹出框
            headers: {
            },
            params: {
                page:1,
                size:10,
            },
            // 是否绑定左侧区域
            bindArea: true
        },


        ModalSignalTabel_1:[
            {title: '数据时间', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '压力', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '标况总量', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '工况总量', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '标况流量', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '工况流量', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '阀门状态', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
        ],
        ModalSignalTabel_1_data:[],
        ModalSignalTabel_2:[
            {title: '数据包', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '方向', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '通讯事件', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
            {title: '通道号', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'},
        ],
        ModalSignalTabel_2_data:[]
        // ----表格参数
    };

    //---------------------------------------------------方法---------------------------------------------




    // 标签页_发货单列表2_表格样式
    sharedOnCell_tableData_2 = (text,index,dataIndex,key) => {

        let dataSource = this.state.tableData_2_data;
        let num1 =0;
        // 判断上层信息，控制本层是否渲染展示
        if(index!==0){
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1][key]===dataSource[index][key]){
                if(dataSource[num1][dataIndex]===dataSource[index][dataIndex]){
                    return {rowSpan:0};
                }
                // return {rowSpan:0};
            }
        }

        // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的
        for(let i =index+1;i<dataSource.length;){
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值
            if(dataSource[index][key]===dataSource[i][key]){
                if(dataSource[index][dataIndex]===dataSource[i][dataIndex]){
                    num3++;
                }else{
                    return {rowSpan: num3};
                }
                i++;
            }else{
                return {rowSpan: num3};
            }

        }
        return {rowSpan:num3}
    };// ----标签页_发货单列表2_表格样式
    // 标签页_发货单列表3_表格样式
    sharedOnCell_tableData_3 = (text,index,dataIndex,key) => {

        let dataSource = this.state.tableData_3_data;
        let num1 =0;
        console.log(dataSource);
        if(dataSource[index][dataIndex]===null){
            return {rowSpan:1};
        }
        // 判断上层信息，控制本层是否渲染展示
        if(index!==0){
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1][key]===dataSource[index][key]){
                if(dataSource[num1][dataIndex]===dataSource[index][dataIndex]){
                    return {rowSpan:0};
                }
                // return {rowSpan:0};
            }
        }
        // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的
        for(let i =index+1;i<dataSource.length;){
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值
            if(dataSource[index][key]===dataSource[i][key]){
                if(dataSource[index][dataIndex]===dataSource[i][dataIndex]){
                    num3++;
                }else{
                    return {rowSpan: num3};
                }
                i++;
            }else{
                return {rowSpan: num3};
            }

        }
        return {rowSpan:num3}
    };// ----标签页_发货单列表3_表格样式
    // 弹窗开关-1
    selectModal1=(data)=>{
        this.setState({modalVisible:data});
    };

    // 主表第一页表钢号点击事件
    communicationRecord=(data)=>{
        console.log("主表第一页表钢号点击事件");
        this.selectModal1(true);// 弹窗开关-1
    };



    // 页面初始化
    componentDidMount=()=>{
        this.getOrderInfosByOrderId();


    };// ----页面初始化
    // 遍历分类数据id获取全部商品
    // 遍历分类数据id获取全部商品
    forClassId=()=>{
        getDevInfosByOrderId({id:this.props.location.state?.orderId}).then(item=>{
            console.log("遍历分类数据id获取全部商品");
            console.log(item);
            console.log(item.data.data.deviceAndDataInfos);
            let tableData_1_right_data = [...this.state.tableData_1_right_data];
            tableData_1_right_data=[];
            item.data.data.deviceAndDataInfos.forEach(one=>{
                tableData_1_right_data.push(one.deviceInfo);
            });
            this.setState({tableData_1_right_data})
        })
    };
    // PC端甲方(新奥)指定订单id查询订单
    getOrderInfosByOrderId=()=>{
        getOrderInfosByOrderId({id:this.props.location.state?.orderId}).then(
            data=>{
                if(data?.data?.data){
                    let orderFormDes = this.state.orderFormDes;
                    orderFormDes=data?.data?.data;
                    this.setState({orderFormDes});
                    this.forClassId();
                }
            }
        )
    };// ----PC端甲方(新奥)指定订单id查询订单


    // 配置表格操作功能
    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-'; // 判断参数是否为空
        switch (value) {
            case 'edit':
                text = rowData.state;
                return <>
                    <div className={'tableLink'}
                         title={'查看'}
                    >
                        <u>{'查看'}</u>

                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // // 表格选择框
    // onChangeSelect = e => {
    //     console.log(111,e)
    // };
    //---------------------------------------------------中间方法END---------------------------------------------
    orderTitle=()=>{
        return <>
            <div>
                <span style={{"color": "rgba(64,149,229,1)"}}>订单号:{this.state.orderFormDes.orderFormNo}</span>
            </div>
        </>
    };

    // 第一页_申请发货_表格_数据样式
    setTableValue_1=(value, text, rowData)=>{
        switch (value) {
            case 'sheetSteelGrade':
                text = rowData.state;
                return <>
                    <div className={'tableLink'}
                         title={'查看'}
                         onClick={this.communicationRecord}
                    >
                        <u>{'查看'}</u>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
        if(text === null||text===undefined) return '-'; // 判断参数是否为空
    };

    checkChange=(selectedRowKeys)=>{
        console.log(selectedRowKeys);
    };
    // 第一页左右表单选框
    onChangeLeftTable=(e)=>{
        let id=new URLSearchParams();
        id.append('id',e);
        getDevInfosByClassId(id).then(
            item=>{
                let tableData_1_right_data = [...this.state.tableData_1_right_data];// 第一个右边表格数据源
                tableData_1_right_data=[];
                if(item?.data?.data){
                    let data=item?.data?.data;

                    data.forEach(one=>{

                        if(one?.deviceData){
                            // 可补充
                            // {
                            //     "pressure": "",
                            //     "result": "",
                            //     "simCard": "",
                            //     "standardFlow": "",
                            //     "standardTotal": "",
                            //     "steelNum": "",
                            //     "sysDateTime": "",
                            //     "temperature": "",
                            //     "valveState": "",
                            //     "workingFlow": "",
                            //     "workingTotal": ""
                            // },
                        }
                        tableData_1_right_data.push(one.deviceInfo)

                        // deviceData: 少了null
                    });

                }
                this.setState({tableData_1_right_data:tableData_1_right_data})
            }
        )
    };
    // 获取发货申请列表
    getApplysByOrderId=()=>{
        getApplysByOrderId({id:this.props.location.state?.orderId}).then(
            data=>{
                let tableData_2_data = [...this.state.tableData_2_data];
                tableData_2_data=[];
                // let tableData_2_param = this.state.tableData_2_param;
                // tableData_2_param.page=data?.data?.pageInfo.pageIndex;
                // tableData_2_param.size=data?.data?.pageInfo.pageLine;
                // tableData_2_param.total=data?.data?.pageInfo.total;

                if(data?.data?.data){
                    const deliveryNoteList =data?.data?.data;
                    if(deliveryNoteList){
                        deliveryNoteList?.forEach(
                            one=>{
                                let data1={
                                    id:null,
                                    applicantId:null,
                                    applyNum:null,
                                    outModelName:null
                                };
                                if(one?.devices){
                                    one?.devices.forEach(
                                        two=>{
                                            if(two?.deviceInfo){
                                                data1=two?.deviceInfo;
                                                data1.key=two.deviceInfo.commodityId;
                                                console.log(data1.key)
                                            }
                                            // data1.key=one.id;
                                            data1.applyTime=one.applyTime;
                                            data1.applicantId=one.applicantId;
                                            data1.applyNum=one.applyNum;
                                            tableData_2_data.push(data1);
                                        }
                                    );
                                }else{
                                    data1.applyTime=one.applyTime;
                                    data1.applicantId=one.applicantId;
                                    data1.applyNum=one.applyNum;
                                    tableData_2_data.push(one);
                                }

                            });
                        this.setState({tableData_2_data:tableData_2_data})
                    }
                }
            }
        );
    };
    // 获取发货列表
    getDeliverysByOrderId=()=>{
        const meterCommodityList3=[];
        let tableData_3_param = this.state.tableData_3_param;

        tableData_3_param.orderId=this.props.location.state?.orderId;
        getDeliverysByOrderId(tableData_3_param).then(
            data=>{

                if(data?.data?.data){
                    tableData_3_param.total=data?.data?.pageInfo.total;
                    tableData_3_param.page=data?.data?.pageInfo.pageLine;
                    tableData_3_param.size=data?.data?.pageInfo.pageIndex;
                    const  deliveryNoteList =data?.data?.data;
                    if(deliveryNoteList){
                        deliveryNoteList.forEach(
                            one=>{
                                one?.meterCommodityList.forEach(
                                    two=>{
                                        two.deliveryId=one?.deliveryId;     // 发货单id
                                        two.deliveryTime=one?.deliveryTime; // 发货单时间
                                        two.logisticsPhone=one?.logisticsPhone;// 物流电话
                                        two.logisticsNo=one?.logisticsNo;       // 物流编号
                                        two.logisticsCompany=one?.logisticsCompany;// 物流单位
                                        two.consignor=one?.consignor;
                                        two.consignorPhone=one?.consignorPhone;
                                        two.model=two?.model;
                                        two.sheetSteelGrade=two?.sheetSteelGrade;
                                        meterCommodityList3.push(two);
                                    })
                            });
                    }
                    this.setState({tableData_3_data:meterCommodityList3,tableData_3_param:tableData_3_param})
                }
            }
        );
    };

    // 标签页-切换事件
    tabsClick=(key)=>{
        console.log(key);
        switch (key) {
            case "1":
                let tableData_1_right_data = [...this.state.tableData_1_right_data];
                this.setState({tableData_1_right_data:[]});
                this.forClassId();
                break;
            case "2":
                console.log("申请发货");
                this.getApplysByOrderId();
                break;
            case "3":
                message.info("申请发货");
                console.log("发货信息");
                this.getDeliverysByOrderId();
                break;
            default:
                console.log("meiyou");
                break;
        }
    };// ----标签页-切换事件

    // 批量发货按钮事件
    UpDeliverGoods=()=>{
        this.setState({pointer_1:'none'});
        console.log("批量发货按钮事件");
        let deliverSelectData = this.state.deliverSelectData;
        if(deliverSelectData.length===0){
            return message.info("请勾选商品")
        }
        console.log(deliverSelectData);
        deliverSelectData.forEach(one=>{
            this.setState({pointer_1:'auto'});
            let id=new URLSearchParams();
            id.append('id',one.applyDeliveryId);
            console.log(id);
            approveTheApplyDelivery(id).then(
                data=>{
                    console.log(data);
                    if(data?.data?.data){
                        message.info(data?.data?.responseInfo?.description)
                    }else{
                        message.info(data?.data?.responseInfo?.description)
                    }
                    this.getApplysByOrderId();
                }
            )
        });
        this.setState({deliverSelectData:[]})
    };

    // 批量选择复选框
    DeliverSelect=(key,value)=>{
        console.log(key);
        console.log(value);

        this.setState({deliverSelectData:value});
    };

    tableData_2_pagination=(page, pageSize) => {
        console.log("右边表格：获取当前页数和条数");
        let param = this.state.tableData_2_param;
        param.page = page;
        param.size = pageSize;
        this.setState({tableData_2_param: param});
        // this.classifyDeviceAccordingToModel();
    };

    render() {


        return (
            <Layout className={'item_body_number_back tables'}>
                <div style={{  "background-color": "#ffffff"}}>

                    {/*----------返回上层按钮--------------*/}



                    <div className={"orderXinAo_TopCaoZuoLan_div"}>

                        <div className={"orderXinAo_TopCaoZuoLan_info_div"}>
                            <Descriptions
                                // bordered
                                labelStyle={{"font-size": 14,"font-weight": "bold"}}
                                title={this.orderTitle()}
                                column={5}
                                extra={<Button type="primary" onClick={() => this.props.history.go(-1)}>返回</Button>}
                            >
                                {/*{*/}
                                {/*    this.state.orderFormDes_test.map(item=> {*/}
                                {/*            const {title, desc} = item;*/}
                                {/*            return (*/}
                                {/*                <Descriptions.Item labelStyle={{"width": 140,}} label={title}>*/}
                                {/*                    <span style={{"font-size": 14}}>{desc}</span>*/}
                                {/*                </Descriptions.Item>*/}
                                {/*            );*/}
                                {/*        }*/}
                                {/*    )*/}
                                {/*}*/}
                                <Descriptions.Item labelStyle={{"width": 150,}} label="创建时间">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.createTime
                                            ?this.state.orderFormDes?.createTime:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="完成时间">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.completionTime
                                            ?this.state.orderFormDes.completionTime:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="供应厂家">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.supplierCompany
                                            ?this.state.orderFormDes?.supplierCompany:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="采购总台数">
                                    <span style={{"font-size": 14,"margin-left": "-30%"}}>
                                        {this.state.orderFormDes?.counNum
                                            ?this.state.orderFormDes?.counNum:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="订单状态">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.state
                                            ?this.state.orderFormDes?.state:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="供应厂家负责人">
                                    <span style={{"font-size": 14,"margin-left": "-10%"}}>
                                        {this.state.orderFormDes?.supplierName
                                            ?this.state.orderFormDes?.supplierName:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="供应厂家联系电话">
                                    <span style={{"font-size": 14,"margin-left": "-1%"}}>
                                        {this.state.orderFormDes?.supplierPhone
                                            ?this.state.orderFormDes?.supplierPhone:"-"}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                    {/*标签页*/}
                    <div style={{"padding":5}}>
                        <Tabs
                            // activeKey={activeKey}
                            tabPosition={"top"} animated={true}
                            // renderTabBar={() => renderTabBar()}
                            onTabClick={this.tabsClick}
                        >
                            <TabPane tab="订单详情" key="1" >
                                <div>
                                    {/*第一页左框*/}
                                    <div className={"orderXinAoLeftTableData_div"}>
                                        <Table
                                            columns={this.state.leftTableData}
                                            dataSource={this.state.orderFormDes?.dtoClassificationList}
                                            rowKey={"classId"}
                                            bordered
                                            rowSelection={{
                                                onChange: this.onChangeLeftTable,
                                                type:"radio",
                                                //"checkbox"
                                                // "radio"
                                            }}
                                            pagination = {{
                                                showSizeChanger:true,
                                                // showQuickJumper: true,
                                                // onChange: this.getPaginationRight,       // 获得翻页数值
                                                total:this.state.orderFormDes?.dtoClassificationList?.length,
                                                pageSize:9,
                                                showTotal: (count = this.state.orderFormDes?.dtoClassificationList?.length)=>{
                                                    return '共'+this.state.orderFormDes?.dtoClassificationList?.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['9']
                                            }}
                                        >
                                        </Table>
                                    </div>
                                    {/*---------------------------第一页左框END----------------------*/}
                                    {/*第一页右框*/}
                                    <div className={"orderXinAoRightTableData_div"}>

                                        <div style={{padding:'0 0 0 0',background:'#fff'}}>

                                            {/*<TableComponents*/}
                                            {/*    paramenter={this.state.tableData_1_right_param}*/}
                                            {/*    setData = {getDevInfosByClassId}*/}
                                            {/*    setTableValue={this.setTableValue}*/}
                                            {/*    ref={e => this.table = e}*/}
                                            {/*>*/}
                                            {/*</TableComponents>*/}


                                            <Table
                                                columns={this.state.tableData_1_right}
                                                dataSource={this.state.tableData_1_right_data}
                                                rowKey={"id"}
                                                bordered
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                                    total:this.state.tableData_1_right_data.length,
                                                    pageSize:12,
                                                    showTotal: (count = this.state.tableData_1_right_data.length)=>{
                                                        return '共'+this.state.tableData_1_right_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['12']
                                                }}
                                            >
                                            </Table>
                                        </div>

                                    </div>
                                    {/*--------------------------第一页右框END----------------------*/}
                                </div>
                            </TabPane>{/*--------------------------第一页END----------------------*/}

                            <TabPane
                                tab="申请发货"
                                key="2"

                            >
                                <div>
                                    <div style={{background:'#fff'}}>
                                        <div style={{background:'#fff',"float": "right","margin":"-50px 10px 0 0"}}>
                                            <Button
                                                // style={{"pointer-events": this.state.pointer_1}}// 禁止按钮功能
                                                type="link" onClick={this.UpDeliverGoods}>批准发货</Button>
                                        </div>

                                    </div>

                                    <div style={{padding:'0 0 0 0',background:'#fff',}}>
                                        <Table
                                            columns={this.state.tableData_2}
                                            dataSource={this.state.tableData_2_data}
                                            rowKey={"applyNum"}
                                            bordered
                                            pagination={false}
                                            scroll={{ y:600}}
                                            // scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                            rowSelection={{

                                                onChange: this.DeliverSelect,
                                                type:"checkbox",
                                                getCheckboxProps: (record) => ({
                                                    disabled: record.state!=='申请发货',
                                                }),
                                            }}
                                            // pagination = {{
                                            //     showSizeChanger:true,
                                            //     showQuickJumper: true,
                                            //     // onChange: this.getPaginationRight,       // 获得翻页数值
                                            //     total:this.state.tableData_2_data.length,
                                            //     pageSize:10,
                                            //     showTotal: (count = this.state.tableData_2_data.length)=>{
                                            //         return '共'+this.state.tableData_2_data.length+'条数据'
                                            //     },
                                            //     defaultCurrent: 1,
                                            //     pageSizeOptions: ['10']
                                            // }}
                                        >
                                        </Table>

                                    </div>
                                </div>

                            </TabPane>{/*--------------------------第二页END----------------------*/}
                            <TabPane tab="发货信息" key="3">
                                <div>
                                    <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                        <Table
                                            columns={this.state.tableData_3}
                                            dataSource={this.state.tableData_3_data}
                                            bordered
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                // onChange: this.tableData_3_pagination,       // 获得翻页数值
                                                total:this.state.tableData_3_data.length,
                                                pageSize:12,
                                                showTotal: (count = this.state.tableData_3_data.length)=>{
                                                    return '共'+this.state.tableData_3_data.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['12']
                                            }}
                                        >
                                        </Table>
                                    </div>
                                </div>
                            </TabPane>{/*--------------------------第三页----------------------*/}
                        </Tabs>
                    </div>

                    {/*----------返回上层按钮END--------------*/}
                    <div>


                        <Modal
                            title={'通信记录'}
                            style={{ top: 0 }}
                            visible={this.state.modalVisible}
                            okText="确定"
                            onOk={() => this.selectModal1(false)}           // 点击确定
                            onCancel={() => this.selectModal1(false)}       // 点击取消
                            width={1000}
                            centered
                            // footer={[
                            //     <Button key="back" onClick={() => this.selectModal1(false)}>返回</Button>,
                            //     <Button type="primary" onClick={() => this.upModalData2(false)}>下一步</Button>
                            // ]}
                        >
                            <div style={{"height":"600px" }}>
                                <Tabs
                                    // activeKey={activeKey}
                                    tabPosition={"top"}
                                    animated={true}
                                    // renderTabBar={() => renderTabBar()}
                                >
                                    {/*历史记录*/}
                                    <TabPane
                                        tab="历史记录"
                                        key="1"

                                    >
                                        <div>
                                            <div style={{background:'#fff'}}>
                                                <div style={{background:'#fff',"margin":"10px "}}>
                                                    <span>
                                                        <span style={{"size":"16px"}}>选择事件：</span>
                                                        <RangePicker
                                                            // showTime
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            // onChange={onRangeChange}
                                                        />
                                                    </span>
                                                </div>

                                            </div>

                                            <div style={{padding:'0 0 0 0',background:'#fff',}}>
                                                <Table
                                                    bordered
                                                    columns={this.state.ModalSignalTabel_1}
                                                    dataSource={this.state.ModalSignalTabel_1_data}
                                                    rowKey={"logisticsNo"}
                                                >
                                                </Table>
                                            </div>
                                        </div>

                                    </TabPane>{/*--------------------------历史记录END----------------------*/}
                                    <TabPane tab="CMD" key="2">
                                        <div>
                                            <div style={{background:'#fff'}}>
                                                <div style={{background:'#fff',"margin":"10px "}}>
                                                    <span>
                                                        <span style={{"size":"16px"}}>选择事件：</span>
                                                        <RangePicker
                                                            // showTime
                                                            format="YYYY-MM-DD HH:mm:ss"
                                                            // onChange={onRangeChange}
                                                        />
                                                    </span>
                                                </div>

                                            </div>
                                            <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                                <Table
                                                    columns={this.state.ModalSignalTabel_2}
                                                    dataSource={this.state.ModalSignalTabel_2_data}
                                                    rowKey={"logisticsNo"}
                                                >
                                                </Table>
                                            </div>
                                        </div>
                                    </TabPane>{/*--------------------------CMD END----------------------*/}
                                </Tabs>
                            </div>
                        </Modal>
                    </div>
                </div>
            </Layout>
        )
    }
}
