import React, { Component } from 'react'

import { Layout,Button,message, Modal } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import {TYPE_NAME} from '../../../components/Const'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import FormsText from '../../../components/common/forms/FormsText'
import {getMetersByAreaIds} from '../../../services/customer'
import { endReplaceMeter,installMeter} from '../../../services/measurePoint'
import {funTraceInfo} from '../../../services/common'
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {partBgetOrdersByCon} from "../../../services/orderForm";
export default class InstallTable extends Component {

    state = {


		// 条件查询栏
		topFormItem: {
			modal: true,
			number: 4,
			labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					rules: TYPE_NAME.INPUT,
					variableName: 'steelNum',
					str: '表钢号'
				},
			]
		},

		// 表格参数
		tableParamenter: {
			bindArea: false,
			manageName: "计量点装表",
			// title: "计量点装表",
			// 下拉框长度
			selectWidth: '7.5vw',
			// 表格行是否可选
            rowSelection: true,
			columnMaxWith: 180,
			height: '60%',
			arr: [5,10],
			// 表头：变量和变量解释
			header: {
				steelNum: '表钢号',
				installSite: '安装位置',
				communication: '表具通讯号',
				modelName: '表具型号',
				type: '表具类型',
				state: '表具状态',
				// customerName: '客户名称',
				// value: '表盘示数',
				dataTime: '数据时间',
                communicateStatus: '通信状态',
                // lastTime: '最后通信时间',
				temperature: '温度',
				pressure: '压力'
			},
			// 条件选择器参数
			// conditionsParamenter: {
			// 	parameter: [
			// 		{
			// 			type: TYPE_NAME.INPUT,
			// 			variableName: 'steelNum',
			// 			str: '表钢号'
			// 		},
			// 	]
			// },
			params: {
				page: 1,
				size: 10,
				isAreaIds : 0,//JSON.parse(localStorage.getItem('menus')),
				customerId: this.props.location.state?.rowData?.customerId,
			},
		},

		// 表单参数
		parameter: {
			id: '计量点代码',
			mpName: '计量点名称',
			customerId: '客户号',
			contractNum: '合同号',
			purpose: '用气用途',
			properties: '用气性质',
			gasEquipment: '用气设备',
			type: '用气类型',
			installTime: '安装日期',
			environment: '用气环境',
			isInstall: '是否装表',
		},
	};

	componentDidMount=()=>{
		console.log('初始化');
		// let menusStr = localStorage.getItem('menus')
	};

	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'type':
				text = text*1
				return text === 1 ? '备用表' : text === 2 ? '用户表' : '-'
			case 'state':
				text = text*1
				return text === 1 ? '开通' : text === 2 ? '未开通' : text === 3 ? '禁用' : text === 4 ? '停用' : '-'
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
		}
	}

	// 装表
	installTable = () => {
		if(this.table.getSelectChange().length < 1) return message.warning('未选中表')
		Modal.confirm({
			title: `是否将该表安装到${this.props.location.state?.rowData?.mpName}下?`,
			onOk: () => {
				let rowData = this.table.getSelectChange()
				let mpId = this.props.location.state?.rowData?.id
				let meterId = rowData?.[0]?.id
				if(rowData?.[0]?.mpId) return message.warning('该表已被安装')
				installMeter({mpId, meterId}).then(
					response => {
						if(response.data?.data) {
							message.success('安装成功')
							this.props.history.replace({
								pathname: '/customer/businessMaintain',
								state: this.props.location.state?.data
							})
						}
						else message.error('安装失败')
					}
				)
			}
		})
	};

	getConditionsValue=  async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data.steelNum);
		var tableParamenter = this.state.tableParamenter;
		console.log(tableParamenter.params)
		tableParamenter.params.steelNum = data.steelNum;
		this.setState({tableParamenter:tableParamenter});
		console.log(tableParamenter.params)
		this.table.refreshTable(tableParamenter.params);
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回客户计量点维护</span>
				</div>
                <div className={'div_from insert_table'}>
					<FormsText
						record = {this.props.location.state?.rowData}
						header = {this.state.parameter}
						rowLen = {4}
					/>
				</div>
				<div className={'divider'}></div>
                <div style={{height: '79%',backgroundColor: '#ffffff'}}>
					<div style={{height: '5%',  textAlign: 'center',fontSize:'24px'}}>
						<span>计量点装表</span>
					</div>
					<div style={{height: '4%',width:'100%'}}>
						<div style={{marginLeft:'10px'}}>
							<Button type="primary" onClick={this.installTable}>装表</Button>
						</div>
						<div style={{position:'relative',margin:'-1.9% 0 0 80px'}}>
							<div className={'topFormBox'} style={{width:'90%'}}>
								<FormsTwo
									ref={e => this.forms = e}
									formParamenter={this.state.topFormItem}
									data={this.state.searchGetData}
								/>
							</div>
							<Button style={{position:'absolute', right:'73%',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>

						</div>
					</div>
					<div style={{height: '90%',width:'100%'}}>
						<TableComponents
							paramenter={this.state.tableParamenter}
							setData = {getMetersByAreaIds}
							ref={e => this.table = e}
						/>
					</div>
					{/*<TableComponents*/}
					{/*	paramenter={this.state.tableParamenter}*/}
					{/*	setData={getMetersByAreaIds}*/}
					{/*	ref={e => this.table = e}*/}
					{/*>*/}
					{/*	<Button type="primary" onClick={this.installTable}>装表</Button>*/}
					{/*</TableComponents>*/}
                </div>
            </Layout>
        )
    }
}
