import React, { Component } from 'react'
import {Layout, Input, Table, Button, Radio, Select, Popconfirm, message} from 'antd'
import FormsText from "../../components/common/forms/FormsText";
import {queryCheckTemplate, saveCheckInfoDetail, saveCheckTemplate} from "../../services/meter";

// 工商户安检模板管理
export default class securityInspectionTemplate extends Component {
    state={
        tableDataJuder:null,// 管控文本转变输入框，类型，位置
        tableDataTitle:null,// 记录文本
        tableData_1_data:[],// 表格内容

        upDataTableData:[], // 要修改的数据
        deleteTableData:[], // 要删除的数据
    };

    // 标签页_发货单列表3_表格样式
    // 文本，层级 变量名，key
    sharedOnCell_tableData_1 = (text,index,dataIndex,key) => {
        let dataSource = this.state.tableData_1_data; // 表格内容
        let num1 =0;
        // 判断指定行数的变量名是否为空
        if(dataSource[index][dataIndex]===null){return {rowSpan:1};}
        // 判断上层信息，控制本层是否渲染展示
        if(index!==0){
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1][key]===dataSource[index][key]){
                if(dataSource[num1][dataIndex]===dataSource[index][dataIndex]){return {rowSpan:0};}
            }
        }
        // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的
        for(let i =index+1;i<dataSource.length;){
            if(index===4){
            }
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值
            if(dataSource[index][key]===dataSource[i][key]){
                if(dataSource[index][dataIndex]===dataSource[i][dataIndex]){
                    num3++;
                }else{return {rowSpan: num3};}
                i++;
            }else{return {rowSpan: num3};}
        }
        return {rowSpan:num3}
    };
    // 安检状态展示数据
    sharedOnCell_tableData_2 = (text,index,dataIndex,key) => {

        return<>
            {this.state.selectType===0?
                (<Radio.Group defaultValue={Number(text)} onChange={(v)=>{
                    var tableData1Data = this.state.tableData_1_data;
                    tableData1Data?.map(one=>{
                        if(one.id===index.id){return one.status=v.target.value}
                    });
                    this.setState({tableData_1_data:tableData1Data})
                }}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>)
                :
                (<span>{Number(text)===1?'是':'否'}</span>)

            }
        </>
    };

    addAndUpTitle = async ()=>{
        var {tableDataJuder,tableDataTitle,upDataTableData} = this.state;// // 管控文本转变输入框，类型，位置；表格输入框内容
        var tableData = [...this.state.tableData_1_data];   // 获取表格数据
        tableData[tableDataJuder.dataIndex][tableDataJuder.type]=tableDataTitle;

        if(tableData[tableDataJuder.dataIndex]?.id){
            upDataTableData.push(tableData[tableDataJuder.dataIndex])
        }

        await this.setState({
            tableData_1_data:tableData, // 保存表格数据
            tableDataJuder:null,        // 清空修改文本的位置
            tableDataTitle:null,        // 重置输入框内容
            upDataTableData:upDataTableData,
        });
    };

    tableDataTitle =(text,rowData,index,type)=>{
         var {tableDataJuder,tableDataTitle} = this.state;
         // 空文本
        if(!text){
            return <>
                <Input defaultValue={text}
                       onChange={(title)=>{
                           console.log('初步数据');
                           this.setState({
                               tableDataJuder:{type:type,dataIndex:index},// 记录类型 和 位置
                               tableDataTitle:title.target.value          // 记录输入内容
                           })
                       }}
                       onBlur={()=>{
                           console.log('输入框光标移除');
                           // 如果点击有文字数据没有修改离开不修改
                           if(tableDataTitle){
                               this.addAndUpTitle();// 保存输入框内容
                           }else{
                               this.setState({tableDataJuder:null})
                           }
                       }
                       }// 输入框光标移除运行 赋值
                />
            </>
        // 非空文本
        }else{
            if(tableDataJuder?.type===type & index===tableDataJuder?.dataIndex){
                return <>
                    <Input defaultValue={text}
                           onChange={(title)=>{
                               console.log('初步数据');
                               this.setState({
                                   tableDataJuder:{type:type,dataIndex:index},
                                   tableDataTitle:title.target.value
                               })
                           }}
                           onBlur={()=>{
                               console.log('光标移除');
                               if(tableDataTitle){
                                   console.log('运行');
                                   this.addAndUpTitle();
                               }else{
                                   console.log('运行');
                                   this.setState({tableDataJuder:null})
                               }
                           }}
                    />
                </>
            }else{
                return <><a onClick={()=>{
                    this.setState({
                        tableDataJuder:{type:type,dataIndex:index}
                    })
                }}>{text??'-'}</a></>
            }

        }
    };
    // upDataTableData
    // 表格下拉框
    tableDataTitle_1 =(text,rowData,index,type)=>{
        var {tableDataJuder} = this.state;
        // 空文本,视为新增数据
        if(!text){
            return <>
                <Select
                    defaultValue="一般"
                    style={{ width: 120 }}
                    value={text==='I'?'一般':text==='II'?'重度':text==='III'?'危险':'一般'}
                    onChange={async (v,m)=>{
                        await this.setState({
                            tableDataJuder:{type:type,dataIndex:index},
                            tableDataTitle:v==='1'?'I':v==='2'?'II':v==='3'?'III':'-'
                        });
                        await this.addAndUpTitle();
                    }}
                    options={[{ value: '1', label: '一般' }, { value: '2', label: '重度' }, { value: '3', label: '危险' },]}
                />
            </>
        }else{
            //
            if(tableDataJuder?.type===type & index===tableDataJuder?.dataIndex){
                return <>
                    <Select
                        defaultValue="一般"
                        style={{ width: 120 }}
                        value={text==='I'?'一般':text==='II'?'重度':text==='III'?'危险':'一般'}
                        onChange={async (v,m)=>{
                           await this.setState({
                                tableDataJuder:{type:type,dataIndex:index},
                                tableDataTitle:v==='1'?'I':v==='2'?'II':v==='3'?'III':'-'
                            });
                            await this.addAndUpTitle();
                        }}
                        options={[{ value: '1', label: '一般' }, { value: '2', label: '重度' }, { value: '3', label: '危险' },]}
                    />
                </>
            }else{
                return <><a onClick={()=>{this.setState({tableDataJuder:{type:type,dataIndex:index}})}}>{text??'-'}</a></>
            }

        }
    };

    componentDidMount(): void {
        this.queryCheckTemplate();
    }

    // 查询模板
    queryCheckTemplate=()=>{
        var params =  new URLSearchParams();
        params.append('classification','安检');
        queryCheckTemplate(params).then(
            response=>{
                var tableData = [...this.state.tableData_1_data];
                tableData = [];
                if(response?.data?.data){
                    tableData=response?.data?.data;
                }else{
                    tableData=[]
                }
                this.setState({tableData_1_data:tableData,deleteTableData:[]})
            }
        )
    };


    getTableData=()=>{

    };



    // 保存模板
    saveCheckInfoDetail=()=>{

        var tableData = this.state.tableData_1_data;
        var upDataTableData = this.state.upDataTableData;
        // 最新一条不能为空
        if(tableData[tableData.length-1]){
            if(!tableData[tableData.length-1]?.type){return message.info('请输入内容');}
            if(!tableData[tableData.length-1]?.name){return message.info('请输入内容');}
            if(!tableData[tableData.length-1]?.level)tableData[tableData.length-1].level='一般';
        }
        // 判断表格数据有无等级
        tableData.map(one=>{
            if(!one?.level){
                one.level='I';
                upDataTableData.push(one);
            }
        });
        this.setState({tableData: tableData});
        //需要上传的数据
        var tableString=[];
        // 循环表格数据-封装需要上传的数据
        tableData.map(one=>{
            // 判断当前数组有无数据，默认第一条数据
            if(tableString.length===0){
                // 选没有Id的
                // if(!one?.id){tableString.push({type:one.type, list:[{name:one.name,level:one.level}]})}
                tableString.push({type:one.type, list:[{id:one.id,name:one.name,level:one.level}]})
            } else{
                var juder=true;
                // 循环数组里有无表单内相同组
                tableString.map(two=>{
                    if(one.type===two.type){
                        juder=false;
                        // 把表格数据加进去
                        // if(!one?.id){
                        //     two.list.push({name:one.name,level:one.level});
                        // }
                        tableString.push({type:one.type, list:[{id:one.id,name:one.name,level:one.level}]})
                    }
                });
                // 为false,视为当前表格的数据已经加入相关组内，不用新增
                if(juder){
                    // if(!one?.id){
                    //     tableString.push(
                    //         {type:one.type, list:[{name:one.name,level:one.level}]})
                    // }
                    tableString.push({type:one.type, list:[{id:one.id,name:one.name,level:one.level}]})
                }
            }
        });
        //  // 处理修改数据
        //  upDataTableData.map(one=>{
        //     // 判断当前数组有无数据，默认第一条数据
        //     if(tableString.length===0){
        //         tableString.push({type:one.type, list:[{id:one.id,name:one.name,level:one.level}]});
        //     } else{
        //         var juder=true;
        //         // 循环数组里有无表单内相同组
        //         tableString.map(two=>{
        //             if(one.type===two.type){
        //                 juder=false;
        //                 // 把表格数据加进去
        //                 two.list.push({id:one.id,name:one.name,level:one.level});
        //             }
        //         });
        //         // 为false,视为当前表格的数据已经加入相关组内，不用新增
        //         if(juder){
        //             tableString.push({type:one.type, list:[{id:one.id,name:one.name,level:one.level}]});
        //         }
        //     }
        // });

        var param={
            "classification": "安检",
            "idList": this.state?.deleteTableData.length!==0?this.state?.deleteTableData:[],// 删除的数组
            "template": tableString.length!==0?JSON.stringify(tableString):null             // 新增的数组无id ,修改带id
        };

        console.log(param);
        saveCheckTemplate(param).then(
            response=>{
                if(response?.data?.data){
                    message.info('安检模板更新成功');
                    this.queryCheckTemplate();
                    this.setState({deleteTableData:[]})
                }else{
                    message.info('安检模板更新失败')
                }
            }
        )
    };


    deleteTableData=(index,rowData)=>{

        var deleteTableData = this.state.deleteTableData;
        if(rowData?.id){deleteTableData.push(rowData.id);}

        var tableData_1_data = [...this.state.tableData_1_data];
        tableData_1_data.splice(index,1);

        this.setState({deleteTableData:deleteTableData,tableData_1_data:tableData_1_data});
    };

    render() {
        const tableData_2=[
            {title: '对象', dataIndex: 'type', key: 'type',editable:true,
                onCell: (text, index) => (this.sharedOnCell_tableData_1(text, index,"model","type")),
                render: (text,rowData,index) => {return(this.tableDataTitle(text,rowData,index,'type'))}
            },
            {title: '隐患名称', dataIndex: 'name', key: 'name',
                render: (text,rowData,index) => {return(this.tableDataTitle(text,rowData,index,'name'))}
            },
            {title: '分级', dataIndex: 'level', key: 'level',width:145,
                render: (text,rowData,index) => {return(this.tableDataTitle_1(text,rowData,index,'level'))}
            },
            {title: '操作', dataIndex: 'edit', key: 'edit',width:145,
                render: (text,rowData,index) => {
                    return<>
                        <div className={'tableLink'} title={'删除'}>
                            <Popconfirm title="是否删除?"
                                        onConfirm={()=>{this.deleteTableData(index,rowData);}}
                                // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>删除</a>
                            </Popconfirm>
                        </div>
                        <div className={'tableLink'} title={'插入隐患'}>
                            <a
                                onClick={()=>{
                                    var tableData = [ ...this.state.tableData_1_data];
                                    tableData.splice(index+1,0,{type:rowData.type,level:'I'});
                                    this.setState({tableData_1_data:tableData});
                                }}
                            >插入隐患</a>
                        </div>
                    </>
                }
            }

        ];
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width: '100%',height: '40px',backgroundColor:'rgba(250,225,255,0.13)',
                    textAlign: 'center',fontSize: '21px',fontWeight: 'bold'}}>
                    <span>安检模板</span>
                </div>
                <div style={{width: '100%', padding: '10px 10px 0 10px', overflowY: 'auto'}}>
                    <Table
                        columns={tableData_2}
                        dataSource={this.state.tableData_1_data}
                        bordered
                        pagination={false}
                    />
                    <div id={'box'}/>
                </div>
                <div style={{width: '100%',height:' 50px',  padding: '0 13px 10px 10px' }}>
                    <div style={{
                        width: '100%', height:' 100%', textAlign: 'center', fontSize: '21px',
                        borderLeft: '1px #cccccc solid',
                        borderRight: '1px #cccccc solid',
                        borderBottom: '1px #cccccc solid',
                    }}>
                        <a type={'primary'}
                           onClick={async ()=>{
                               var tableData = [...this.state.tableData_1_data];
                               if(tableData[tableData.length-1]){
                                   if(!tableData[tableData.length-1]?.type){return message.info('请输入内容');}
                                   if(!tableData[tableData.length-1]?.name){return message.info('请输入内容');}
                                   if(!tableData[tableData.length-1]?.level)tableData[tableData.length-1].level='一般';
                               }
                               tableData.push({level:'I'});
                               await  this.setState({tableData_1_data:tableData});
                               let element = document.getElementById("box");
                               element.scrollIntoView();	//元素的顶部将对齐到可滚动祖先的可见区域的顶部。
                               element.scrollIntoView(false);  //元素的底部将与可滚动祖先的可见区域的底部对齐。
                               // #对于新添加的元素有时可能不能立即获取到，此时可以设置个定时器
                               setTimeout(()=>{
                                   let div=document.getElementById('box');
                                   if(div){div.scrollIntoView(false);}
                               },200);


                           }}
                        >+新增</a>
                    </div>
                </div>
                <div style={{width: '100%',height: '50px',}}>
                    <div style={{float:'right',marginRight:'15px'}}>
                        <Button type={'primary'} onClick={()=>this.saveCheckInfoDetail()}>提交</Button>
                    </div>
                </div>
            </Layout>
        )
    }
}
