import ajax from './ajax'
import ajaxToServer from './ajaxToServer'

// 表具异常数据报表
export const getAbnormalData = data => ajax('/web/reportCtrl/getAbnormalData', data, 'POST')

// 表具异常用气报表
export const getAbnormalUsage = data => ajax('/web/reportCtrl/getAbnormalUsage', data, 'POST')

// 日用气量报表
export const getDailyConsumption = data => ajax('/web/reportCtrl/getDailyConsumption', data, 'POST')

// 用户导入数据差异报表
export const getDataDifference = data => ajax('/web/reportCtrl/getDataDifference', data, 'POST')

// 表具历史数据查询报表
export const getHistoricalData = data => ajax('/web/reportCtrl/getHistoricalData', data, 'POST')

export const getHistoricalEchartData = data => ajax('/web/reportCtrl/getCurveData', data, 'POST')

export const getDevicePageShowDataByTimeRangeAndContent = data => ajaxToServer('/gateway/equipment/queryDevicePageShowDataByTimeRangeAndContent', data, 'POST')



export const queryValveControlCommandByDevId = data => ajaxToServer('/gateway/web/commandReplyCtrl/queryValveControlCommandByDevId', data, 'POST')
export const queryDeviceControlStatus = data => ajaxToServer('/gateway/web/commandReplyCtrl/queryDeviceControlStatus', data, 'POST')
export const replyValveControlCommandByDevId = data => ajaxToServer('/gateway/web/commandReplyCtrl/replyValveControlCommandByDevId', data, 'POST')


//表具废弃-旧
export const abandon = data => ajax('/web/stateMachineCtrl/abandon', data, 'POST')


//表具废弃-新
export const startAbandon = data => ajax('/web/stateMachineCtrl/startAbandon', data, 'POST')




//获取铅封
export const queryLeadSealRecord = data => ajax('/businesse/leadSeal/queryLeadSealRecord', data, 'GET')







// 用户信息管理报表
export const getInfoManagement = data => ajax('/web/reportCtrl/getInfoManagement', data, 'POST')

// 抄表率统计报表-旧
export const getMeterReading = data => ajax('/web/reportCtrl/getMeterReading', data, 'POST');
// 抄表率统计报表-新
export const queryMeterReadingRate = data => ajax('/web/reportCtrl/queryMeterReadingRate', data, 'POST');
// 用户抄表和未回传明细报表
export const getNotReturned = data => ajax('/web/reportCtrl/getNotReturned', data, 'POST')

// 表具实时数据报表
export const getRealTimeData = data => ajax('/web/reportCtrl/getRealTimeData', data, 'POST')

// 未注册用户报表
export const getUnregistered = data => ajax('/web/reportCtrl/getUnregistered', data, 'POST')

// 查询所有厂商和型号
export const getAllManufacturerAndModel = data => ajax('/web/deviceCtrl/getAllManufacturerAndModel', data, 'GET')



// 客户用量-计量点用量报表-旧
export const queryCustomerPointValuePage = data => ajax('/web/reportCtrl/queryCustomerPointValuePage', data, 'POST');
// 客户用量-客户用量报表合计
export const queryCustomerPointValueAmount = data => ajax('/web/reportCtrl/queryCustomerPointValueAmount', data, 'POST');

// 计量点用量报表
export const queryPointValuePage = data => ajax('/web/reportCtrl/queryPointValuePage', data, 'POST');
// 计量点用量报表合计
export const queryPointValueAmount = data => ajax('/web/reportCtrl/queryPointValueAmount', data, 'POST');


// 用气点-表具用量报表
export const queryPeakValleyPage = data => ajax('/web/reportCtrl/queryPeakValleyPage', data, 'POST');

// 报表导出
export const ReportExport = data => ajax('/web/exportStatisticalreportCtrl/ReportExport', data, 'GET');

// 多时段用量对比
export const multiPeriodContrast = data => ajax('/web/reportCtrl/multiPeriodContrast', data, 'POST');


// 多时段用量对比
export const multiPeriodOtherItemContrast = data => ajax('/web/reportCtrl/multiPeriodOtherItemContrast', data, 'POST');


// 按条件查询修改台账
export const queryUpdateLedger = data => ajax('/web/ledgerCtrl/queryUpdateLedger', data, 'POST');
// 按条件查询换表台账
export const queryReplaceMeterLedger = data => ajax('/web/ledgerCtrl/queryReplaceMeterLedger', data, 'POST');

