import React, { Component } from 'react'
import { NavLink, withRouter } from "react-router-dom"

import {Layout, Button, Radio, message, Modal, Input, Tag, Popconfirm, Tabs} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import {TYPE_NAME} from '../../../components/Const'
import Tables from '../../../components/common/tables/Tables'
import Conditions from '../../../components/common/conditions/Conditions'
import FormsText from '../../../components/common/forms/FormsText'

import {
	getPageMpAddress,
	openMp,
	disarmMeter,
	getPageCustomerName,
	migrateMeteringPointCustomers,
	deleteMp
} from '../../../services/customer'
import moment from "moment";
import TableComponents from '../../../components/common/tables/TableComponents'
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {getMetersByAreaIds, replaceMeter} from "../../../services/meter";

const { TabPane } = Tabs;
class BusinessMaintain extends Component {

    state = {
		// 弹出表格参数
		tablePopParamenter: {
			manageName: "计量点信息管理",
			// 表格行是否可选
			rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				steelNum: '表钢号',
				installSite: '安装位置',
				communication: '表具通讯号',
				modelName: '表具型号',
				type: '表具类型',
				state: '表具状态',
			},
			// 弹出框
			headers: {
				steelNum: '表钢号',
				installSite: '安装位置',
				communication: '表具通讯号',
				modelName: '表具型号',
				type: '表具类型',
				state: '表具状态',
			},
			params: {
				page:1,
				size:9,
				isAreaIds:0
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: TYPE_NAME.INPUT,
						variableName: 'steelNum',
						str: '表钢号'
					},
				]
			},
			// 是否绑定左侧区域
			bindArea: false
		},
		topFormItem: {
			modal: true,
			number: 3,
			labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'oldMeterSheetNum',
					str: '旧表钢号',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'oldMeterDalData',
					str: '旧表示数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'newMeterDalData',
					str: '新表示数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'offTime',
					str: '拆表时间',
					rules: TYPE_NAME.DATE_PICKER,
				},
				{
					variableName: 'onTime',
					str: '装表时间',
					rules: TYPE_NAME.DATE_PICKER,
				},
			]
		},
		changeData:{},
		openChangeMeter:false, //换表
		changeMeterId:'',
		changeMeterCode:'',

		// 条件查询栏
		topFormItem_1: {
			modal: true,
			number: 4,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{variableName: 'contractNum', str: '合同号', rules: TYPE_NAME.INPUT,disabled: true,},
				{variableName: 'id', str: '计量点编码', rules: TYPE_NAME.INPUT,disabled: true,},
				{variableName: 'mpAreaName', str: '区域', rules: TYPE_NAME.INPUT,disabled: true,},
			]
		},
		topFormItem_1_data:[],
		topFormItem_2: {
			modal: true,
			number: 4,
			labelCol: 9,
			layout: "horizontal",
			parameter: [
				{variableName: 'id', str: '客户编号', rules: TYPE_NAME.INPUT,},
				{variableName: 'name', str: '客户名称', rules: TYPE_NAME.INPUT,},
				{variableName: 'businessCooperationNum', str: '业务合作号', rules: TYPE_NAME.INPUT,},
			]
		},
		topFormItem_2_data:[],
		// 表格参数
		tableParamenter_1: {
			manageName: "客户关系管理系统",
			// 表格行是否可选
			rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				id: '客户编号',
				businessCooperationNum: '业务合作号',
				name: '客户名称',
				type: '客户类型'
			},
			params: {
				page: 1,
				size: 5,
			}
		},


		// 表格参数
		tableParamenter: {
			manageName: "客户关系管理系统",
			title: "计量点维护",
			// 表格行是否可选
            rowSelection: true,
			// 表头：变量和变量解释
			header: {
				id: '计量点代码',
				purpose: '用气用途',
				properties: '用气性质',
				gasEquipment: '用气设备',
				mpName: '计量点名称',
				customerType: '客户类型',
				customerId: '客户号',
				contractNum: '合同号',
                steelNum: '表钢号',
                modelName: '表具型号',
				isInstall: '是否装表',
				state: '计量点状态',
				address: '计量点地址',
				edit:'操作'
			},

			// 表格数据
			data: []
		},
		// 条件选择器参数
		conditionsParamenter: {
			// 是否显示条件标题
			isLable: true,
			// 是否显示清空按钮
			clearButton: true,
			parameter: [
				{
					type: TYPE_NAME.INPUT,
					variableName: 'address',
					str: '用气地址'
				},

			]
		},
		// 表单参数
		formText: {
			header: {
				id: '客户号',
				name: '客户名称',
				principalPhone: '联系人电话',
				type: '用户类型',
				nature: '客户性质',
				address: '客户地址',
			}
		},
		params: {
			page: 1,
			size: 10,
			address: '',
			customerId: this.props.location?.state?.id
		},
		// 总条数
		totalCount: 0,
		// 选中的数据
		rowData: [],
		// 表格加载动画
		loading: false,
		// 刷新动画
		refreshTable: false,
		openMpVisible:false, //作废窗口开关器-1
		openMpVisible_1:false, //拆表窗口开关器-2
		openMpVisible_2:false, //迁移窗口开关器-3
		mpId:'',
		selectOneData:{},
		InputData:'',


		ControlButtons_1:false,// 控制按钮-换表


	};

	componentDidMount() {

		let menuAuthories =[];
		menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
		menuAuthories.map(one=>{
			if(one.name==='客户关系管理'){
				one?.children.map(two=>{
					if(two.name==='换表'){
						this.setState({ControlButtons_1:true})
					}
				})
			}
		});

		this.getData()
	}

	setTableValue_1 = (value,text,rowData) => {
		switch (value) {
			case 'type':
				text = text*1;
				return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
			case 'state':
				text = text*1;
				return text === 1 ? '正式' : text === 2 ? '非正式' : '';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

    // 表格：刷新方法
	refreshTable = () => {
		this.setState({refreshTable: true});
		this.getData()
	};

	// 表格：获取勾选中的值
	onSelectChange = data => {
		this.setState({rowData: data});
	};

	// 表格：导出
	exportTable = () => {
		console.log('数据导出')
	};
	// 条件选择：获取输入的条件
	getConditionsValue = value => {
		let {params} = this.state;
		params.address = value.address;
		params.page = 1;
		this.setState({params})
		this.getData()
	}

	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
		let {params} = this.state;
		params.page = page;
		params.size = pageSize;
		this.setState({params});
		this.getData()
	};
    // 拆表
	disarmMeter=(endNumValue)=>{
		console.log(endNumValue);
		if(!endNumValue){
			return message.info('表盘数不能为空');
		}
		var {selectOneData} = this.state;
		var params={
			// 表盘数据
			// dialData: [
			// 	{
			// 		data: [{dataTime: "", id: 0, value: ""}],
			// 		id: "", meterItemId: "", name: "", unit: ""
			// 	}
			// ],
			endNumValue: endNumValue,			// 拆表-表盘示数
			meterId: selectOneData.meterId,		// 旧表id
			mpId: selectOneData.id,				// 计量点id
			offTime: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss") // 拆除时间
		};
		this.setState({openMpVisible_1:false});
		params.operator= JSON.parse(sessionStorage.getItem("user")).username;
		disarmMeter(params).then(
			response=>{
				if(response.data.data){
					// funTraceInfo("客户管理", "拆表",null,params)
					message.info("拆表成功");
					this.getData()// 刷新表格
				}else {
					message.info("拆表失败")
				}
		}).catch(e=>{message.info("异常问题，请联系管理员"+e)})
	};
	openChangeMeter = async (openChangeMeter,id,code)=>{
		await this.setState({openChangeMeter,changeMeterId:id,changeMeterCode:code});
		let cData={};
		cData.mpId=id;
		cData.oldMeterSheetNum=code;
		await this.setState({changeData: cData});
		this.tablePop.refreshTable();
		await this.forms.onModalClose();
	};
	// 表格数据渲染
	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'type':
				text = text*1
				return text === 1 ? '备用表' : text === 2 ? '用户表' : '-';
			case 'customerType':
				text = text*1
				return text === 1 ? '居民客户' : text === 2 ? '工商客户' : '-'
			case 'edit':
				text = rowData.state;
				return <>
					{/*<div style={{float:'left'}} className={'tableLink'}  title={'开通计量点'} onClick={() => this.openMp(true,rowData.id)}>*/}
					{/*	<u>{'开通计量点'}</u>*/}
					{/*</div>*/}
					{this.state.ControlButtons_1?
					<div className={'tableLink'} title={'换表'}
						 onClick={() => this.openChangeMeter(true,rowData.id,rowData.steelNum)}>
						<u>{'换表'}</u>
					</div>
						:null
					}
					<div style={{float:'left'}} className={'tableLink'} title={'开通计量点'} onClick={() =>
					{
						if(!rowData?.meterId){
							return message.info("当前计量点没有表具，无法操作");
						}
						this.setState({openMpVisible_1:true,selectOneData:rowData})
					}
					}>
						<u>{'拆表'}</u>
					</div>
					<div  style={{float:'left'}}  className={'tableLink'} title={'删除'}  >
						<Popconfirm
							title="是否确定删除"
							description="是否确定删除?"
							onConfirm={()=>{
								if(rowData?.steelNum){
									return message.info("当前计量点有表具，无法操作");
								}
								this.deteleMp(rowData.id);
							}}
							// onCancel={cancel}
							okText="是"
							cancelText="否"
						>
							<u>{'删除'}</u>
						</Popconfirm>
					</div>
				</>;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
		}
	};

	// 删除计量点
	deteleMp=(mpId)=>{
		deleteMp({mpId:mpId}).then(
			response => {
				if(response?.data?.data){
					message.info("删除成功");
					this.refreshTable();
					// this.table.refreshTable(this.state.tableParamenter_1.params);
				}else{
					message.info("删除失败")
				}
			}
		)
	};

	// 开通计量点
	openMp=(openMpVisible,id)=>{
			this.setState({openMpVisible,mpId:id});
	};
	toOpenMp=(openMpVisible)=>{
		openMp({mpId: this.state.mpId}).then(
				response => {
					console.log(response)
					if(response.data.data==true){
						this.setState({openMpVisible})
						message.success('操作成功')
						// funTraceInfo("客户管理", "计量点操作", null,this.state.mpId)
						this.getData()
					}else{
						this.setState({openMpVisible})
						message.error('该状态下不支持此操作！')
						this.getData()
					}

				}
			)
	};
	// 初始化数据
	getData = () => {
		this.setState({loading: true})
		getPageMpAddress(this.state.params).then(
			response => {
				let { data,pageInfo } = response.data
				let {tableParamenter} = this.state
				tableParamenter.data = data;
				this.setState({
					loading: false,
					refreshTable: false,
					totalCount: pageInfo?.total??0,
					tableParamenter: tableParamenter
				})
				message.success('查询成功')
			}
		).catch(
			() => {
				this.setState({
					loading: false,
					refreshTable: false,
				})
				message.error('查询失败')
			}
		)
	}

	// 点击装表
	installTable = () => {
		if(this.state.rowData.length < 1) return message.error('未选择计量点')
		if(this.state.rowData[0]?.isInstall === '已安装') return message.warning('该计量点已装表')
		this.props.history.push({
			pathname: '/customer/installTable',
			state: {
				rowData: this.state.rowData[0],
				data: this.props.location.state
			},
		})
	}

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		params.address = ''
		this.setState({params})
		this.getData()
	};


	getConditionsValue_1 = async value => {
		let data = await this.forms2.onFinishNotReset();
		console.log(data);
		var tableParamenter_1 = this.state.tableParamenter_1;
		tableParamenter_1.params.customerId=data.id||null;
		tableParamenter_1.params.name=data.name||null;
		tableParamenter_1.params.businessCooperationNum=data.businessCooperationNum||null;
		this.setState({tableParamenter_1:tableParamenter_1});
		var params={} ;
		Object.assign(params,tableParamenter_1.params);
		params.customerId=data.id||null;
		params.name=data.name||null;
		params.businessCooperationNum=data.businessCooperationNum||null;
		console.log(params);
		await this.table.refreshTable(params);
	};

	// 迁移客户计量点
	migrateMeteringPointCustomers=()=>{
		let rowData = this.table.getSelectChange();
		console.log(rowData);

		console.log(this.props.location.state);
		if(rowData.length < 1) return message.error('未选择数据');

		if(rowData[0].id===this.props.location.state.id){
			return message.error('该计量点已在当前客户');
		}
		this.setState({openMpVisible_2:false});
		var param={
			mpId: this.state.rowData[0].id,
			newCustomerId: rowData[0].id,
			oldCustomerId: this.props.location.state.id,
			userId: JSON.parse(sessionStorage.getItem("user")).orgId
		};
		console.log(param);
		migrateMeteringPointCustomers(param).then(
			response=>{
				console.log(response.data.data);
				if(response.data.data){
					this.getData();
					// funTraceInfo("客户管理", "迁移客户计量点",rowData,param)
					return message.info("迁移客户计量点成功");
				}else{
					return message.info("迁移客户计量点失败");
				}
			}
		)
	};

	toChangeMeter = async (openChangeMeter)=>{
		let rowData = this.tablePop.getSelectChange();
		if(rowData.length < 1){
			return message.error('未选择需要替换的表具')
		}else{
			let changeData = await this.forms.onFinishNotReset();
			if(!changeData) return null;
			changeData.newMeterSheetNum=rowData[0].steelNum;
			changeData.mpId=this.state.changeMeterId;
			changeData.offTime=moment(changeData.offTime?._d).format('YYYY-MM-DD HH:mm:ss');
			changeData.onTime=moment(changeData.onTime?._d).format('YYYY-MM-DD HH:mm:ss');
			console.log(JSON.parse(sessionStorage.getItem("user")));
			changeData.initiatorId=JSON.parse(sessionStorage.getItem("user")).id;
			changeData.initiatorName=JSON.parse(sessionStorage.getItem("user"))?.username;
			changeData.initiatorCompanyId=JSON.parse(sessionStorage.getItem("user")).orgId;
			changeData.initiatorCompanyName=JSON.parse(sessionStorage.getItem("user")).orgName;
			changeData.operator = JSON.parse(sessionStorage.getItem("user")).username;
			console.log(changeData);
			replaceMeter(changeData).then(
				response => {
					console.log(response);
					if(response.data.data===true){
						this.setState({openChangeMeter});
						// funTraceInfo("计量点信息管理", "替换未安装表具", null,changeData);
						message.success('操作成功');
						this.refreshTable();
					}else{
						this.setState({openChangeMeter});
						message.error('操作失败');
						this.refreshTable();
					}

				}
			)
		}

	};
    render() {
		const {InputData}=this.state;

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'}
						  onClick={() =>
							  // this.props.history.replace("/customer/clientBusiness")
							  this.props.history.go(-1)
						  }
					><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回客户管理</span>
				</div>
                <div className={'div_title_from insert_table'} style={{paddingLeft: '3vw'}}>
					<FormsText
						record={this.props.location.state??{}}
						header={this.state.formText.header}
						rowLen={3}
						span={6}
					/>
				</div>
				<div className={'divider'}></div>



				{/*计量点*/}
                <div className={'item_body_number_back tables'}>
					<Tables
						tableParamenter={this.state.tableParamenter}  // 表格表头、数据
						exportTable={this.exportTable}  // 导出时触发的方法
						onSelectChange={this.onSelectChange} // 选中多选框时触发的方法
						refreshTable={this.refreshTable}  // 刷新时触发的方法
						getPagination={this.getPagination} // 分页器变化时触发的方法
						pagination={{
							totalCount: this.state.totalCount, // 总条数
							page: this.state.params.page, // 当前页数
							size: this.state.params.size, // 页面条数
							arr: [5,10] // 页数
						}}
						setTableValue={this.setTableValue}
						loading={{
							loading: this.state.loading,
							refreshTable: this.state.refreshTable
						}}
						columnMaxWith = {130}
					>
						<Conditions
							conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
							getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
							clearCondition={this.clearCondition} // 清除条件
						>
							<Radio.Group defaultValue="addClient" buttonStyle="solid">
								<NavLink to={{pathname: "/point/addPoint",state: {
									dataKey: "1",
									customerId:this.props.location.state?.id
								}}}>
									<Radio.Button value="addClient">创建计量点</Radio.Button>
								</NavLink>
								<Radio.Button
									onClick={()=>{
										console.log(this.state?.rowData);
										if(this.state?.rowData.length===0){
											return message.info('请选择计量点');
										}
										this.props.history.push({
											pathname:"/point/addPoint",
											state:{
												dataKey: "2",
												name: this.state.rowData[0]?.mpName,
												id: this.state.rowData[0]?.id,
											}
										})
									}}
									value="updateClient">修改计量点</Radio.Button>
							</Radio.Group>
							<Button type="primary" onClick={this.installTable}>装表</Button>
							<Button type="primary" onClick={()=>{
								if(this.state.rowData.length < 1) return message.error('未选择计量点');
								console.log(this.state.rowData);
								var {topFormItem_1_data}=this.state;

								this.setState({openMpVisible_2:true,topFormItem_1_data:this.state.rowData[0]})
							}}>迁移计量点</Button>
						</Conditions>
					</Tables>
                </div>
				{/*计量点*/}
				<Modal
                        title='计量点开通'
                        style={{ top: 0 }}
                        visible={this.state.openMpVisible}
                        okText="确定"
                        onOk={() => this.toOpenMp(false)}           // 点击确定
                        onCancel={() => this.openMp(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认开通计量点吗？</h4>
                        </div>
                </Modal>
				<Modal
					title='拆表'
					style={{ top: 0 }}
					visible={this.state.openMpVisible_1}
					okText="确定"
					onOk={() => this.disarmMeter(InputData)}           // 点击确定
					onCancel={() => this.setState({openMpVisible_1:false})}       // 点击取消
					width={400}
					centered
				>
					<div style={{height:'50px',padding:'10px 0 0 20px'}}>
						<div style={{float:'left',width:'20%'}}>
							<span>表盘示数</span>
						</div>
						<div style={{float:'left',width:'60%'}}>
							<Input  onChange={(title)=> this.setState({InputData:title.target.value})}/>
						</div>
					</div>
					{/*<div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>*/}
					{/*	<span>表盘示数<Input/></span>*/}
					{/*</div>*/}
				</Modal>
				<Modal
					title='计量点迁移'
					style={{ top: 0 }}
					visible={this.state.openMpVisible_2}
					okText="确定"
					onOk={() => {

						this.migrateMeteringPointCustomers();
					}}         // 点击确定

					onCancel={() => this.setState({openMpVisible_2:false})}       // 点击取消
					width={1200}
					centered
				>
					<div style={{height:'550px',}}>
						<div>
							<FormsTwo
								ref={e => this.forms1 = e}
								formParamenter={this.state.topFormItem_1}
								data={this.state.topFormItem_1_data}
							/>
						</div>
						<div style={{width:'100%',height:'1px',backgroundColor:'#E0E0E0',margin:'5px 0 10px 0'}} />
						<div>
							<div style={{position:'relative'}}>
								<div className={'topFormBox'} style={{width:'90%'}}>
									<FormsTwo
										ref={e => this.forms2 = e}
										formParamenter={this.state.topFormItem_2}
										data={this.state.topFormItem_2_data}
									/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => {
									this.getConditionsValue_1();
								}}>查询</Button>
							</div>
							<div>
								<TableComponents
									paramenter={this.state.tableParamenter_1}
									setData={getPageCustomerName}
									setTableValue={this.setTableValue_1}
									ref={e => this.table = e}
								>
								</TableComponents>
							</div>
						</div>
					</div>
					{/*<div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>*/}
					{/*	<span>表盘示数<Input/></span>*/}
					{/*</div>*/}
				</Modal>
				<Modal
					title='换表'
					style={{ top: 0 }}
					visible={this.state.openChangeMeter}
					okText="确定"
					onOk={() => this.toChangeMeter(false)}           // 点击确定
					onCancel={() => {this.openChangeMeter(false)}}       // 点击取消
					width={1200}
					centered
				>
					<div>
						<TableComponents
							paramenter={this.state.tablePopParamenter}
							setData = {getMetersByAreaIds}
							setTableValue={this.setTableValue}
							ref={e => this.tablePop = e}
						>
						</TableComponents>

						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.topFormItem}
							data={this.state.changeData}
						/>
					</div>
				</Modal>
            </Layout>
        )
    }
}

export default withRouter(BusinessMaintain)
