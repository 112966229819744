
import { Component } from 'react'
import { Table, Button, Modal, Layout, Tree, message } from "antd";

import {
  queryPositionInfo,
  deleteMenu,
  updateMenu,
  addOrUpdateAuth,
  listPositionsByOrganizationId,
  getMenuTree,
  getResourcesByNodeId,
  insertMenu,
  getNewTree
} from '../../../services/admin'
import '../../../style/luo.less'
import FormsTwo from '../../../components/common/forms/FormsTwo'

import { TYPE_NAME } from '../../../components/Const'
import Pubsub from 'pubsub-js'
import React from 'react';
import {funTraceInfo} from '../../../services/common'

import { PlusCircleOutlined, MinusCircleOutlined, FormOutlined, DeleteOutlined} from '@ant-design/icons';

export default class AuthorityManagement extends Component {


  state = {
    // 表单参数
    formParamenter: {
      modal: true,
      number: 1,
      labelCol: 4,
      align: 'center',
      layout: "horizontal",
      // itemSize: 'large',
      parameter: [
        {
          variableName: 'name',
          str: '菜单标题',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'url',
          str: '菜单路径',
          rules: TYPE_NAME.INPUT,

        },
        {
          variableName: 'components',
          str: '菜单组件',
          rules: TYPE_NAME.INPUT,
          // disabled: true,
        },
        {
					variableName: 'type',
					str: '菜单类型',
					rules: TYPE_NAME.REQ_SELECT,

					data:[
						{
							key: 'true',
							value: '菜单',
						},
            {
							key: 'false',
							value: '按钮',
						},
					]
				},
      ]
    },
    formParamenteres: {
      modal: true,
      number: 1,
      labelCol: 4,
      align: 'center',
      layout: "horizontal",
      // itemSize: 'large',
      parameter: [
        {
          variableName: 'code',
          str: '权限码',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'name',
          str: '菜单标题',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'url',
          str: '菜单路径',
          rules: TYPE_NAME.INPUT,

        },
        {
          variableName: 'components',
          str: '菜单组件',
          rules: TYPE_NAME.INPUT,
          // disabled: true,
          //一级菜单填写该字段为功能图标名称
        },
        {
					variableName: 'type',
					str: '菜单类型',
					rules: TYPE_NAME.REQ_SELECT,

					data:[
						{
							key: 'true',
							value: '菜单',
						},
            {
							key: 'false',
							value: '按钮',
						},
					]
				},
      ]
    },
    selectedRowKeys: [], // Check here to configure the default column
    selectedRowKey: null,
    loading: false,
    visible: false,
    visible1: false,
    visible2: false,
    visible2s:false,
    responseInfo: {},
    responseInfoa: {},
    responseInfos: {},
    // 表格行是否可选
    rowSelection: true,
    tablesData: [],
    dataSource: [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`,
        key: 'id',
        width: 40,
        align: 'center',
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'id',
        width: 80,
        align: 'center',
      },
    ],

    tablesDatas: [],
    dataSources: [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`,
        // dataIndex: 'id',
        key: 'positionId',
        width: 40,
        align: 'center',
      },
      {
        title: '职位名称',
        dataIndex: 'name',
        key: 'id',
        width: 80,
        align: 'center',
      },
    ],


    expandedKeys: [],
    checkedKeys: [],
    selectedKeys: [],
    objecktKey:null,
    objecktKeyes:null,

    NodeTreeItem: null, //右键菜单
    tree: {
      treeData: [
        {
          title: 'name',
          key: 'id',

          children: [
            {
              title: 'name',
              key: 'id',

              children: [
                { title: 'name', key: 'id' },
              ],
            },
          ],
        },
      ],

      menuTreeData: [
        {
          title: 'name',
          key: 'id',

          children: [
            {
              title: 'name',
              key: 'id',

              children: [
                { title: 'name', key: 'id' },
              ],
            },
          ],
        },
      ],


      dataTreeData: [
        // {
        //   title: 'name',
        //   key: 'id',
        //   parent: 'parentId',
        //
        //   children: [
        //     {
        //       title: 'name',
        //       key: 'id',
        //       parent: 'parentId',
        //
        //       children: [
        //         { title: 'name', key: 'id', parent: 'parentId' },
        //       ],
        //     },
        //   ],
        // },
      ],

    },

    rowData: [],

    checkedDataKeys: [],
    selectedDataKeys: [],

    parentId: [],
    keyId: null,
    titles: [],

    treeId: [],
    formdatas: [],
    top:null,
    selectIds: null,
    deleteData: [],
    onData:[],
    onDataes:[],

    equeal:[],
    equeals:[]
  };



  componentDidMount() {
    this.getTreeNode();
    // this.getMenuTreeNode();
    // this.getDataTreeNode();
  }


  showModal = () => {
    this.setState({
      visible: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  showModalinsert = () => {
    this.setState({
      visible1: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  showModalupdate = (parent) => {
    if(parent === 0){
      this.setState({
        visible2s: true,

      });
      console.log("第一")
    }else{
      this.setState({
        visible2: true,
      });
      console.log("第二")
    }

    setTimeout(() => this.forms.onModalClose(), 0)
  };

  insert = async () => {
    this.setState({
      loading: true
    });

    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      url: value.url,
      code:1,
      type:value.type,
      components:value.components,
      parentId:this.state.keyId
    }
    console.log(params)
    // 新增数据权限
    insertMenu(params).then(
      response => {
        response.data.data ? message.success('新增成功',[0.5]) : message.error('新增失败',[0.5])
        funTraceInfo("权限管理", "新增菜单权限", null, params)
        this.getMenuTreeNode();
      }
    )
    setTimeout(() => {
      this.setState({ loading: false, visible1: false });
    }, 0);
  }

  handleOk = async () => {
    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      code:value.code,
      url:value.url,
      parentId:0,
      type:value.type,
      components:value.components
    }
    // 新增数据权限
    insertMenu(params).then(
      response => {
        response.data.data ? message.success('新增成功',[0.5]) : message.error('新增失败',[0.5])
        funTraceInfo("权限管理", "新增菜单权限", null, params)
        this.getMenuTreeNode();
      }
    )
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 0);

  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleback = () => {
    this.setState({ visible1: false });
  };

  handlebacks = () => {
    this.setState({ visible2: false });
  };
  handlebackes = () => {
    this.setState({ visible2s: false });
  };

  // 组件将要被卸载时,取消订阅
  componentWillUnmount() {
    Pubsub.unsubscribe('areaId')
  }

  onCheck_1=(selectedKeys, info)=>{
    var data=[];
    // 当前操作未添加
    if(info.checked){
      // 添加父级,
      if(info.node.parent!==0){
        // 判断当前的父级有无存在
        if(
            !selectedKeys.checked.find((one)=>{ if(one===info.node.parent) return true; })?true:false
        ){
          selectedKeys?.checked.push(info.node.parent);
        }
      }
      // 添加子级
      info.node.children.map(one=>{
        selectedKeys?.checked.push(one.key)
        if(one?.children.length!==0){
          addforChildren(one?.children)

        }
      });
      // 循环添加
      function addforChildren(children) {
        children.map(one=>{
          selectedKeys?.checked.push(one.key);
          if(one?.children.length!==0){
            addforChildren(one?.children)

          }
        });
      }
    }else{
      // 判断有没有子级
      if(info.node.children.length!==0){
        for(var i=0;i<info.node.children.length;i++){
          console.log(info.node.children.length);
          for(var a=0;a<selectedKeys.checked.length;a++){
            if(selectedKeys.checked[a]===info.node.children[i].key){
              selectedKeys.checked.splice(a,1);
            }
          }
        }
        function deleforChildren(children) {
          for(var i=0;i<children.length;i++){
            if(
                selectedKeys.checked.find((one)=>{ if(one===children[i].key) return true; })?true:false
            ){
              selectedKeys?.checked.splice(i);
              if(children[i].children.length!==0){
                deleforChildren(children[i].children);
              }
            }
          }
        }
      }
    }



    console.log(selectedKeys.checked);
    this.setState({
      checkedKeys: selectedKeys.checked,
      // objecktKey: keys,
      objecktKey: selectedKeys.checked,
      onData:selectedKeys.checked,
    });
  };

  //点击复选框触发
  onCheck = (checkedKeysValue: React.Key[],info: any) => {
    // let s1=new Set(checkedKeysValue.checked);
    // let s2=Array.from(s1);
    // console.log("s1",s1,s2);
    let checkedsKey= info.halfCheckedKeys
    console.log("check",checkedsKey)
    let key=info.node.parent
    console.log("父节点",key)
    let keys = Object.values({...checkedKeysValue})
    // let keys = JSON.parse(JSON.stringify(checkedKeysValue))
    keys.push.apply(keys,checkedsKey)
    keys.push(key)
    console.log("点击复选框触发的值",checkedKeysValue,keys)
    console.log('--------------------')
    console.log(keys);
    console.log('--------------------')
    //点击树节点触发
    this.setState({
      checkedKeys: checkedKeysValue,
      // objecktKey: keys,
      objecktKey: checkedKeysValue,
      onData:checkedKeysValue,
    });
  };

  onChecks = (checkedKeysValue: React.Key[],info: any) => {

    // let key=info.node.parent
    let keys = Object.values({...checkedKeysValue.checked})
    // keys.push(key)
    console.log("点击复选框触发的值",checkedKeysValue.checked,keys)
    //点击树节点触发
    this.setState({
      checkedDataKeys: checkedKeysValue.checked,
      objecktKeyes: keys,
      onDataes:checkedKeysValue.checked,
    });
  };

  //点击树节点触发
  onSelect = (selectedKeysValue: React.Key[], info: any) => {
    this.setState({
      checkedDataKeys: [null],
      checkedKeys: [null]
      // tablesDatas: [],
      // tablesData: []
    })
    let key = info.node.key
    let param = {
      organizationId: key
    }
    // let name = info.node.title
    listPositionsByOrganizationId(param).then(
      response => {
        let { data } = response.data
        if (data === null) {
          message.error("该组织机构下不存在数据")
          let { tablesDatas } = this.state
          tablesDatas = data
          this.setState({ tablesDatas ,selectedRowKey:null})
        } else {
          let { tablesDatas } = this.state
          tablesDatas = data
          this.setState({ tablesDatas,selectedRowKey:null})
          message.success('查询成功')
        }
      }
    )
  };

  //点击树节点触发
  onMenuSelect = (selectedKeysValue: React.Key[], info: any) => {
    let item=info.node.type
    let datas={
        name:info.node.name,
        code:info.node.code,
        components:info.node.components,
        url:info.node.url,
        type:item + ''
    }
    this.setState({
      formdatas: datas,
    })
    let key = info.node.key
    let param = {
      nodeId: key
    }
    getResourcesByNodeId(param).then(
      response => {
        let { data } = response.data
        if (data === null) {
          message.warning("该菜单权限下不存在数据",[0.5])
          this.setState({ tablesData: [] ,keyId:key})
        } else {
          let { tablesData } = this.state
          tablesData = data
          this.setState({ tablesData ,keyId:key})
          message.success('查询成功',[0.5])
        }
      }
    )

  };

  //点击树节点触发
  onDaSelect = (selectedKeysValue: React.Key[], info: any) => {
  }



  //保存方法
  onSave = () => {
    if( this.state.selectedRowKey === null ) return message.error('不能保存数据，请对部门职位进行选择')
    console.log('数据权限id', this.state.checkedDataKeys);
    console.log('数据权限id(浅拷贝)', this.state.objecktKeyes);
    console.log('菜单权限id', this.state.checkedKeys);
    console.log('菜单权限id(浅拷贝)', this.state.objecktKey);
    console.log('部门职位id', this.state.selectedRowKey);
    console.log('资源id', this.state.selectedRowKeys);
    // this.setState({ loading: true });

    let key = this.state.selectedRowKey,
    newSite = key.join();
    console.log('字符串转换', newSite);

    let keyIds ={
      dataIds: this.state.checkedDataKeys,
      resourceIds: this.state.selectedRowKeys,
      menuIds: this.state.checkedKeys,
    }

    // const arr=this.state.checkedKeys;
    // arr.push(this.state.objecktKey);
    // console.log("cscscscs",arr);
    let param = {
      dataIds: this.state.objecktKeyes,
      menuIds: this.state.objecktKey,
      positionId: newSite,
      resourceIds: this.state.selectedRowKeys
    }
    console.log(param)
    addOrUpdateAuth(param).then(
      response => {
        response.data.data ? message.success('保存成功',[0.5]) : message.error('保存失败',[0.5])
        funTraceInfo("权限管理", "更改部门权限", keyIds, param)
      }
    )
    // this.getDataMenu();
    // this.getData();
  }

  refreshTable = () => {
    this.setState({ refreshTable: true })
  }

  //获取组织机构的树节点
  getTreeNode = () => {
    this.setState({ loading: true });
    // 新
    getNewTree({userId:JSON.parse(sessionStorage.getItem("user")).id}).then(
        reponse => {
          console.log(reponse.data.responseInfo.code);
          if(reponse.data.responseInfo.code!==200){
            return message.info('未有组织结构树数据');
          }
          let { data } = reponse.data;
          let { responseInfo, tree } = this.state;
          responseInfo.data = data;
          tree.treeData = data;
          this.setState({
            loading: false,
            refreshTable: false,
            totalCount: data.total,
            responseInfo: responseInfo,
            tree
          });
          message.info('查询成功',[0.5])
          // funTraceInfo("权限管理", "组织结构查询", null, tree)
        }
    ).catch((e) => {
          this.setState({loading: false, refreshTable: false,});

          message.info('查询异常，请重新刷新页面',)
        }
    );
    ////旧
    // // getOrganizationTree(this.state.responseInfo).then(
    // fetch("/gateway/newAuth/admin/organization/tree").then(
    //   res => res.json())
    //   .then(
    //     reponse => {
    //       console.log(reponse);
    //       // let { data } = reponse.data;
    //       console.log("查询到的组织机构数的数据",reponse.data);
    //       let { responseInfo, tree } = this.state;
    //       responseInfo.data = reponse.data;
    //       tree.treeData = reponse.data
    //       this.setState({
    //         loading: false,
    //         refreshTable: false,
    //         responseInfo: responseInfo,
    //         tree
    //       });
    //       // message.success('查询成功')
    //       // this.getMenuTreeNode();
    //     }
    //   ).catch(
    //     () => {
    //       this.setState({
    //         loading: false,
    //         refreshTable: false,
    //       });
    //       message.error('查询失败',[0.5])
    //     }
    //   )
  };

  //递归(组织机构)
  getTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(item => {
      return {
        title: item?.nodeExtend?.name,
        key: item?.nodeExtend?.id,
        // dataIndex:value?.positions?.name,
        disabled: false,
        children: this.getTreeData(item?.children)
      }
    });
    return tree
  }

  //获取菜单权限的树节点
  getMenuTreeNode = () => {
    this.setState({ loading: true });
    console.log("looklook",this.state.responseInfoa)
    getMenuTree().then(
      reponse => {
        console.log(reponse);
        let { data } = reponse.data;
        console.log("查询到菜单树的数据", data);
        let { responseInfoa, tree } = this.state;
        responseInfoa.data = data;
        tree.menuTreeData = data
        this.setState({
          loading: false,
          refreshTable: false,
          responseInfoa: responseInfoa,
          tree
        });

      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败',[0.5])
      }
    )

  };
  //递归
  getDataTreeData_1 = data => {
    data = Array.isArray(data) ? data : [];
    let tree = data?.map(value => {
      return {
        title: value?.name,
        key: value?.id,
        parent: value?.parentId,
        // dataIndex:value?.positions?.name,

        children: this.getDataTreeData_1(value?.children)
      }
    });
    return tree
  }
  //递归(菜单权限)//getDataTreeData
  getDataTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(value => {
      return {
        title: value?.name,
        key: value?.id,
        parent: value?.parentId,
        // disabled: false,
        children: this.getDataTreeData(value?.children)
      }
    })
    return tree
  }

  //获取数据权限树节点
  getDataTreeNode = () => {
    console.log(11111111111111111111111111111111, this.state.responseInfos);
    this.setState({ loading: true });
    // getdataTree(this.state.responseInfos).then(
      fetch("/gateway/newAuth/admin/data/tree").then(
        res => res.json())
        .then(
      reponse => {
        let { responseInfos, tree } = this.state;
        responseInfos.data = reponse.data;
        tree.dataTreeData = reponse.data
        this.setState({
          loading: false,
          refreshTable: false,
          responseInfos: responseInfos,
          tree
        });
        message.success('查询成功',[0.5])
        // this.getTreeNode()
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败',[0.5])
      }
    )
    // this.getTreeNode()
  };


  //递归(菜单权限) getMenuTreeData
  getMenuTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(item => {
      item.title = (
        <div>
          <span>
            {item?.name}
          </span>
          <span>
            <FormOutlined style={{ marginLeft: 5 }} onClick={()=>this.showModalupdate(item?.parentId)} />

            <PlusCircleOutlined style={{ marginLeft: 5 }} onClick={this.showModalinsert} />

            <MinusCircleOutlined style={{ marginLeft: 5 }} onClick={() => this.delete(item?.children,item.id)} />
          </span>
        </div>
      );
      return {
        code:item.code,
        name:item.name,
        url:item.url,
        components:item.components,
        type:item.type,
        title: item.title,
        key: item?.id,
        parent: item.parentId,
        children: this.getMenuTreeData(item?.children)
      }
    })
    return tree
  }

  update = async () => {
    this.setState({ loading: true });
    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      url:value.url,
      components:value.components,
      type:value.type,
      id:this.state.keyId,
    }
    console.log(params)
    // 修改职位
    updateMenu(params).then(
      response => {
        response.data.data ? message.success('修改成功',[0.5]) : message.error('修改失败',[0.5])
        funTraceInfo("权限管理", "修改菜单权限", this.state.formdatas, params)
        this.getMenuTreeNode()
      }
    )

    setTimeout(() => {
      this.setState({
        loading: false,
        visible2: false,
      });
    }, 0);
  }

  updates = async () => {
    this.setState({ loading: true });
    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      url:value.url,
      components:value.components,
      type:value.type,
      code:value.code,
      id:this.state.keyId,
    }
    console.log(params)
    // 修改职位
    updateMenu(params).then(
      response => {
        response.data.data ? message.success('修改成功') : message.error('修改失败',[0.5])
        funTraceInfo("权限管理", "修改菜单权限", this.state.formdatas, params)
        this.getMenuTreeNode()
      }
    )
    setTimeout(() => {
      this.setState({
        loading: false,
        visible2s: false,
      });
    }, 0);
  }


  delete = (children,key) => {
    if(children == null ){
      let param = {
        menuId: key,
      }
      Modal.confirm({
        title: `是否确认删除该菜单权限？`,
        onOk: () => {
          // 删除数据权限
          deleteMenu(param).then(
            response => {
              this.setState({
                loading: false,
                refreshTable: false,
              });
              message.success('删除成功',[1])
              funTraceInfo("权限管理", "删除菜单权限", param, null)
              this.getMenuTreeNode();
            }
          ).catch(
            () => {
              this.setState({
                loading: false,
                refreshTable: false,
              });
              message.error('删除失败',[1])
              this.getMenuTreeNode();
            }
          )
        }
      })
        this.getMenuTreeNode();
      console.log("sanchushuju", param)
    }else{
      return message.error('请先对下面的数据权限进行删除',[1])
    }
  }

  onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    this.setState({ selectedRowKeys });
  };

  clear = () => {
    this.refreshTable();
    this.setState({
      checkedDataKeys: [null],
      checkedKeys: [null],
      tablesDatas: [],
      tablesData: []
    })
    // Radio.prop("checked",false);
  }

  // getDataMenu =()=>{
  //   // console.log('数据权限id', this.state.checkedDataKeys);
  //   // console.log('菜单权限id', this.state.checkedKeys);
  //   console.log("菜单对比数据",this.state.equeal);
  //   console.log("数据权限id对比数据",this.state.equeals);
  //   this.setState({
  //     // checkedDataKeys: [null],
  //     checkedKeys: [null],
  //     // tablesDatas: [],
  //     // tablesData: []
  //   })
  //   let arr=this.state.onData;
  //   console.log("arr",arr);
  //   let arres=this.state.onDataes;
  //   // console.log("arres",arres);
  //   let param = {
  //     positionId: this.state.selectIds
  //   }
  //   console.log("======",param)
  //   queryPositionInfo(param).then(
  //     reponse => {
  //       console.log(reponse);
  //       let { data } = reponse.data;
  //       let {  checkedKeys, selectedRowKeys } = this.state;
  //       // checkedDataKeys = data.dataIds;
  //       checkedKeys = data.menuIds;
  //       console.log("对比数据2",checkedKeys);
  //       selectedRowKeys = data.resourceIds;
  //       if(checkedKeys?.length==this.state.equeal?.length){
  //         // if(checkedDataKeys?.toString()==this.state.equeals.toString()){
  //           this.setState({
  //             loading: false,
  //             refreshTable: false,
  //             // checkedDataKeys: checkedDataKeys,
  //             checkedKeys: checkedKeys,
  //             selectedRowKeys: selectedRowKeys
  //           });
  //         // }
  //         console.log("====1")
  //       }else{
  //         console.log("====2")
  //       this.setState({
  //         loading: false,
  //         refreshTable: false,
  //         // checkedDataKeys: arres,
  //         checkedKeys: arr,
  //         selectedRowKeys: selectedRowKeys
  //       });
  //       }

  //     }
  //   ).catch(
  //     () => {
  //       this.setState({
  //         loading: false,
  //         refreshTable: false,
  //       });
  //       // message.error('查询失败',[0.5])
  //     }
  //   )
  // }

  // getData =()=>{
  //   console.log('数据权限id', this.state.checkedDataKeys);
  //   console.log("数据权限id对比数据",this.state.equeals);
  //   this.setState({
  //     checkedDataKeys: [null],
  //     // checkedKeys: [null],
  //     // tablesDatas: [],
  //     // tablesData: []
  //   })

  //   let arres=this.state.onDataes;
  //   console.log("arres",arres);
  //   let param = {
  //     positionId: this.state.selectIds
  //   }
  //   console.log("======",param)
  //   queryPositionInfo(param).then(
  //     reponse => {
  //       console.log(reponse);
  //       let { data } = reponse.data;
  //       let { checkedDataKeys } = this.state;
  //       checkedDataKeys = data.dataIds;
  //       console.log("对比数据2",checkedDataKeys);
  //       // if(checkedKeys?.toString()==this.state.equeal.toString()){
  //         if(checkedDataKeys?.length==this.state.equeals.length){
  //           this.setState({
  //             loading: false,
  //             refreshTable: false,
  //             checkedDataKeys: checkedDataKeys,
  //           });
  //         // }
  //         console.log("====3")
  //       }else{
  //         console.log("====4")
  //       this.setState({
  //         loading: false,
  //         refreshTable: false,
  //         checkedDataKeys: arres,
  //       });
  //       }

  //     }
  //   ).catch(
  //     () => {
  //       this.setState({
  //         loading: false,
  //         refreshTable: false,
  //       });
  //       // message.error('查询失败',[0.5])
  //     }
  //   )
  // }

  onSelectChanges = selectedRowKey => {
    this.getMenuTreeNode();
    this.getDataTreeNode();
    this.setState({ selectedRowKey });
    let newSite = selectedRowKey.join();
    // console.log("转换", newSite)
    this.setState({selectIds:newSite});
    let param = {
      positionId: newSite
    };
    queryPositionInfo(param).then(
      reponse => {
        console.log(reponse);
        let { data } = reponse.data;
        let { checkedDataKeys, checkedKeys, selectedRowKeys ,equeal,equeals} = this.state;
        checkedDataKeys = data.dataIds;
        equeals=data.dataIds;
        checkedKeys = data.menuIds;
        equeal=data.menuIds;
        selectedRowKeys = data.resourceIds;
        let arr=this.state.responseInfoa.data?.map(val=>val.id);
        //去除checkedKeys数组中存在的arr数组的的父节点
        let new_arr = checkedKeys?.filter((x) => !arr.some((item) => x === item));
        // console.log("===checkedKeys===",checkedDataKeys)
        //去除checkedDataKeys数组中存在的dataArr数组的的父节点
        // let new_dataArr = checkedDataKeys?.filter((x) => !dataArr?.some((item) => x === item));
              this.setState({
              loading: false,
              refreshTable: false,
              checkedDataKeys: checkedDataKeys,
              // checkedKeys: new_arr,
                checkedKeys:data.menuIds,
              selectedRowKeys: selectedRowKeys,
              equeal:equeal,
              equeals:equeals
            });
            // message.success('查询成功',[0.5])
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败',[0.5])
      }
    )
    this.setState({treeId:newSite})
  };



  render() {
    const { loading, selectedRowKeys, visible, visible1, visible2, visible2s } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
        <div id={'equipment_frame'}>
          <div className={'left_tt'}>
            <div className={'particulars_info_div'}>
              <span className="title">组织结构：</span>
              <div className={'boole'}>

              <Tree
                  treeData={this.getDataTreeData_1(this.state.tree.treeData)} // 新
                // treeData={this.getTreeData(this.state.tree.treeData)}
                // getTreeNode={this.getTreeNode}
                // onExpand={this.onExpand}
                // expandedKeys={this.state.expandedKeys}
                // autoExpandParent={this.state.autoExpandParent}
                // onCheck={this.onCheck}
                // checkedKeys={this.state.checkedKeys}
                onSelect={this.onSelect}
              // selectedKeys={this.state.selectedKeys}
              >
              </Tree>
              </div>
            </div>

          </div>

          <div className={'left_aa'}>
            <div className={'particulars_info_div'}>
              <span className="title">部门职位：</span>
              <div className={'boole'}>
                <Table
                  columns={this.state.dataSources}
                  dataSource={this.state.tablesDatas}
                  rowSelection={{ onChange: this.onSelectChanges, type: 'radio' }}
                  rowKey={record => record.positionId}
                >
                </Table>
              </div>

            </div>
          </div>

          <div className={'left_aa'}>
            <div className={'particulars_info_div'}>
              <span className="title">菜单权限：</span>
              <div className={'boole'}>
              <div className={'buttons'}>
                  <Button type="primary" onClick={this.showModal}>
                    新增菜单权限
                  </Button>
                </div>

                <Modal
                  visible={visible}
                  title="新增菜单权限"
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={this.handleOk}
                    >
                      提交
                    </Button>,
                    <Button key="back" onClick={this.handleCancel}>
                      返回
                    </Button>
                  ]}
                >
                  <FormsTwo
                    ref={e => this.forms = e}
                    formParamenter={this.state.formParamenteres}
                    data={this.state.data}
                  />
                </Modal>

                <Modal
                  visible={visible1}
                  title="新增菜单权限"
                  onOk={this.insert}
                  onCancel={this.handleback}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={this.insert}
                    >
                      提交
                    </Button>,
                    <Button key="back" onClick={this.handleback}>
                      返回
                    </Button>
                  ]}
                >
                  <FormsTwo
                    ref={e => this.forms = e}
                    formParamenter={this.state.formParamenter}
                    data={this.state.data}
                  />
                </Modal>

                <Modal
                  visible={visible2}
                  title="修改菜单权限"
                  onOk={this.update}
                  onCancel={this.handlebacks}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={this.update}
                    >
                      提交
                    </Button>,
                    <Button key="back" onClick={this.handlebacks}>
                      返回
                    </Button>
                  ]}
                >
                  <FormsTwo
                    ref={e => this.forms = e}
                    formParamenter={this.state.formParamenter}
                    data={this.state.formdatas}
                  />
                </Modal>


                <Modal
                  visible={visible2s}
                  title="修改菜单权限"
                  onOk={this.updates}
                  onCancel={this.handlebackes}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={this.updates}
                    >
                      提交
                    </Button>,
                    <Button key="back" onClick={this.handlebackes}>
                      返回
                    </Button>
                  ]}
                >
                  <FormsTwo
                    ref={e => this.forms = e}
                    formParamenter={this.state.formParamenteres}
                    data={this.state.formdatas}
                  />
                </Modal>
                <div className={"tree"}>
                {/*  123*/}
                <Tree
                  checkable
                  treeData={this.getMenuTreeData(this.state.tree.menuTreeData)}
                  getTreeNode={this.getMenuTreeNode}
                  // onExpand={this.onExpand}
                  // expandedKeys={this.state.expandedKeys}
                  // autoExpandParent={this.state.autoExpandParent}
                  checkStrictly
                  // 新
                  onCheck={(selectedKeys, info)=>{
                    this.onCheck_1(selectedKeys, info)

                  }}

                  // onCheck={this.onCheck}// 旧
                  checkedKeys={this.state.checkedKeys}
                  onSelect={this.onMenuSelect}
                  selectedKeys={this.state.selectedKeys}
                />
              </div>
                  </div>
            </div>
          </div>

          <div className={'left_aa'}>
            <div className={'particulars_info_div'}>
              <span className="title">功能权限：</span>
              <div className={'boole'}>
                <Table
                  columns={this.state.dataSource}
                  dataSource={this.state.tablesData}
                  rowSelection={rowSelection}
                  rowKey={record => record.id}
                >
                </Table>
              </div>

            </div>
          </div>

          <div className={'left_aa'}  >
            <div className={'particulars_info_div scoll_table_x'} >
              <span className="title">数据权限：</span>
              <div className={'tree_flow'}>
              <div className={'boole'}>
                  <Tree
                    checkable

                    treeData={this.getDataTreeData(this.state.tree.dataTreeData)}
                    getTreeNode={this.getDataTreeNode}
                    //  onExpand={this.onExpand}
                    //  expandedKeys={this.state.expandedKeys}
                    checkStrictly
                    onCheck={this.onChecks}
                    checkedKeys={this.state.checkedDataKeys}
                    onSelect={this.onDaSelect}
                    selectedKeys={this.state.selectedDataKeys}
                  />
              </div>
              </div>
            </div>
          </div>


          <div className={'button'}>
            <Button type="primary" onClick={this.onSave} icon={<FormOutlined />} >保存</Button>
            <Button type="info" onClick={this.clear} icon={<DeleteOutlined />}>取消</Button>
          </div>
        </div>
      </Layout>
    )
  }

}
