import React, {Component} from "react";
import {Button, Layout, message, Popconfirm, Radio, Switch} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import {getMetersByAreaIds} from "../../../services/meter";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {batchRemoveFileRecord, getFileRecordTop} from "../../../services/dataIntegration";
import {getDataIgnoreLevel} from "../../../services/steamReport";
import moment from "moment";


export default class historicalReport extends Component{

    state={
        // 条件选择器参数_1
        topFormItem_1: {
            modal: true,
            number: 2,
            labelCol: 5,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds', str: '区域选择', rules: TYPE_NAME.SELECTINPUT, data:[],
                },
                {
                    variableName: 'granularity', str: '时间范围',
                    onChange: (k,v) => this.onChangeSelect(k,v),
                    rules: TYPE_NAME.RANGE_PICKER,
                },
            ]
        },
        // 条件选择器参数_1_参数
        topFormItem_data_1:[],
        // 弹出表格参数
        tablePopParamenter: {
            manageName: "计量点信息管理",
            // 表格行是否可选
            checkStatus: true,

            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                areaName: '区域',
                fileName: '报表名称',
                createTime: '报表时间',
                edit:'操作'
            },
            // 弹出框
            headers: {
                areaName: '区域',
                fileName: '报表名称',
                createTime: '报表时间',
            },
            params: {
                page:1,
                size:100,
            },

            // 是否绑定左侧区域
            bindArea: false
        },
    };

    // 初始化地图加载
    componentDidMount() {
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        console.log(data[i]);
                        selectArry.push({key:data[i].id,value:data[i].name})
                    }
                }
                this.state.topFormItem_1.parameter[0].data=selectArry;
            }
        );
    }


    // 查询
    getConditionsValue= async value =>{
        console.log('查询事件');
        let data = await this.forms.onFinishNotReset();
        console.log(data);
        if(!data) return null;
        let {tablePopParamenter} = this.state.tablePopParamenter;

        tablePopParamenter = {
            areaId: data?.areaIds,
            startTime:data?.granularity?moment(data?.granularity[0]?._d).format('YYYY-MM-DD HH:mm:ss'):null,
            endTime:data?.granularity?moment(data?.granularity[1]?._d).format('YYYY-MM-DD HH:mm:ss'):null,
            page: 1,
            size: 100
        };
        console.log(tablePopParamenter);
        await this.table.refreshTable(tablePopParamenter);

    };

    // 表格数据渲染
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'type':
                text = text*1;
                return text === 1 ? '备用表' : text === 2 ? '用户表' : '-';
            case 'customerType':
                text = text*1
                return text === 1 ? '居民客户' : text === 2 ? '工商客户' : '-';
            case 'edit':
                text = rowData.state;
                return <>

                    <div style={{float:'left'}} className={'tableLink'} title={'导出'} onClick={() => {
                        window.location.href = rowData.filePath
                    }}>
                        <u>{'导出'}</u>
                    </div>
                    <div  style={{float:'left'}}  className={'tableLink'} title={'删除'}  >
                        <Popconfirm
                            title="是否确定删除"
                            description="是否确定删除?"
                            onConfirm={()=>{
                                const params = new URLSearchParams();
                                let ids=[rowData.id];
                                params.append('ids',ids);
                                batchRemoveFileRecord(params).then(
                                    response=>{
                                        if(response?.data?.data){
                                            this.table.refreshTable(this.state.tablePopParamenter.params);
                                        }else{
                                            message.info('删除失败')
                                        }
                                    }
                                )
                            }}
                            // onCancel={cancel}
                            okText="是"
                            cancelText="否"
                        >
                            <u>{'删除'}</u>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };
    getSelectChange=()=>{
        let rowData = this.table.getSelectChange();
        let ids=[];
        rowData.map(one=>{
            ids.push(one.id);
        });
        const params = new URLSearchParams();

        params.append('ids',ids);
        batchRemoveFileRecord(params).then(
            response=>{
                if(response?.data?.data){
                    this.table.refreshTable(this.state.tablePopParamenter.params);
                }else{
                    message.info('删除失败')
                }
            }
        )
    };
    render(){
        return(
        <Layout  className={'item_body_number_back tables'}>
            <div className={'topSearchBox'}>
                <h3>历史报表</h3>
                <div style={{width:'100%',height:'40px'}}>
                    <div style={{width:'85%' ,float:'left',}}>
                        <div style={{width:'70%' ,float:'left'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.topFormItem_1}
                                data={this.state.topFormItem_1_data}
                            />
                        </div>
                        <div style={{width:'20%',paddingLeft:'5px' ,float:'left'}}>
                            <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                        </div>
                    </div>
                    <div style={{width:'10%' ,float:'left',}} className={'tableLink'} title={'删除'}>
                        <div>
                            <Button onClick={()=>{this.getSelectChange();}} type="primary">批量删除</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width:'100%',overflowX: 'auto'}}>
                <TableComponents
                    paramenter={this.state.tablePopParamenter}
                    setData = {getFileRecordTop}
                    setTableValue={this.setTableValue}
                    // getSelectChange={this.getSelectChange}	//选择事件
                    ref={e => this.table = e}
                >
                </TableComponents>
            </div>
        </Layout>
        );
    }
}
