// 客户管理页面
import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Alert, Input, Select, Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {addDev,getCustomerList,insertCustomer,getCustomerOne} from '../../../services/verifvication'
import areaData from "../standby/addreData";
import {Option} from "antd/es/mentions";
export default class PointManagement extends Component {

    state = {
		// 客户信息
		customerData:{
			id:null,			// 客户号
			companyType:null,	// 客户类型
			customerUnit:null,	// 客户单位
			legalPerson:null,	// 法人
			principal:null,		// 联系人
			principalPhone:null,// 联系电话
			province:'湖南省',		// 省
			city:'长沙市',			// 市
			district:'岳麓区',		// 区
			details:null,		// 详细地址
		},
		city:areaData[17].city,
		district: areaData[17].city[0].area,

		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				customerUnit: '客户单位',
				companyType: '客户类型',
				legalPerson: '法人',
				principal: '联系人',
				principalPhone: '负责人电话',
				details: '详细地址',
				// registrationTime: '注册时间',
				edit: '操作',

			},
			// 弹出框
			headers: {
				customerUnit: '客户单位',
				companyType: '客户类型',
				legalPerson: '法人/联系人',
				principal: '联系人',
				principalPhone: '负责人电话',
				details: '详细地址',
				// registrationTime: '注册时间',
			},
			params: {
				page:1,
				size:10,
			},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'customerUnit',
					str: '客户单位'
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'principal',
					str: '客户联系人'
				},
			]
		},

		    	// 总条数
		totalCount: 0,
		cusId:'',
		modTitle:'添加客户',
		devVisible:false, // 小窗口开关器-1
		devVisible_1:false,
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: '客户号',
					rules: TYPE_NAME.NAME,
					disabled: true
				},
				{
					variableName: 'province',
					str: '省',
					data:'',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',
					data:'',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'companyType',
					str: '客户类型',
					rules: TYPE_NAME.REQ_SELECT,
					disabled: this.props.location.state ? true : false,
					onChange: e => this.onChangeSelect(e),
					data: [
						{
							key: '1',
							value: '居民客户'
						},
						{
							key: '2',
							value: '工商客户'
						}
					]
				},
				// {
				// 	variableName: 'nature',
				// 	str: '客户性质',
				// 	rules: TYPE_NAME.NAME
				// },
				{
					variableName: 'customerUnit',
					str: '客户单位',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				// {
				// 	variableName: 'organizationCode',
				// 	str: '组织机构代码',
				// 	rules: TYPE_NAME.LABLE_18
				// },
				{
					variableName: 'legalPerson',
					str: '法人',
					rules: TYPE_NAME.LABLE
				},
				{
					variableName: 'principal',
					str: '联系人',
					rules: TYPE_NAME.LABLE_NOT_NULL_20
				},
				{
					variableName: 'principalPhone',
					str: '联系人电话',
					rules: TYPE_NAME.PHONE_NUM_NOT_NULL
				}
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'verification':
			return <>
				<NavLink to={{
				pathname: "/verifvication/verificationRecordManagement",
				state: {
					id: rowData.id,
				}
			}}>
			<div className={'tableLink'} title={'查看'}>
					<u>{'查看'}</u>
			</div>
			</NavLink>
			</>
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editDevStatus(true,rowData.id)}>
						<u>{'修改'}</u>
				</div>
				</>
			case 'companyType':
				text = text*1;
				return text === 1 ? '居民客户' : text === 2 ? '工商客户' : '-';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	updatePoint = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}
		this.props.history.push({
			pathname: '/device/addDataItem',
			state: data
		})
	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params;
		let formParams=this.table.getParams();
		Object.assign(params,value);
		params.page=1;
		// params.size=formParams.size;
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		this.setState({tableParamenter});
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
	getData = () => {

  	}

	//添加客户窗口显示
	addDevStatus= async (devVisible_1)=>{
		var customerData = this.state.customerData;
		customerData.id=null;				// 客户号
		customerData.companyType=null;		// 客户类型
		customerData.customerUnit=null;		// 客户单位
		customerData.legalPerson=null;		// 法人
		customerData.principal=null;		// 联系人
		customerData.principalPhone=null;	// 联系电话
		customerData.province='湖南省';		// 省
		customerData.city='长沙市';			// 市
		customerData.district='岳麓区';		// 区
		customerData.details=null;			// 详细地址
		customerData.areaId=null;			// 区域id
		await this.setState({devVisible_1,customerData,data:{province: '湖南省',city: '长沙市'},modTitle:'添加客户',cusId:''});
        // await this.setState({devVisible,data:{province: '湖南省',city: '长沙市'},modTitle:'添加客户',cusId:''});
		// await this.forms.onModalClose()
    };
	editDevStatus=(devVisible_1,id)=>{
        this.setState({devVisible_1,cusId:id,modTitle:'修改客户'});
		// this.state.cusId=id;
		getCustomerOne({id: id}).then(
			response => {
				const {data} = response.data;
				console.log(data);
				this.setState({customerData: data});
				message.success('查询成功');
				// this.forms.onModalClose()
			}
		)
    };

	// 添加客户
	addDevData_1 = async ()=>{
		var customerData = this.state.customerData;

		if(!customerData.customerUnit){
			this.setState({juder_2:true});
			return message.info('客户单位不能为空');
		}
		if(!customerData.principal){
			this.setState({juder_4:true});
			return message.info('联系人不能为空');
		}
		if(!customerData.principalPhone){
			this.setState({juder_5:true});
			return message.info('联系电话不能为空');
		}

		this.setState({button_juder:true});
		insertCustomer(customerData).then(data=>{
			if(data.data?.data){
				message.info("操作成功");
				funTraceInfo("报警器检定管理", "添加或修改客户", null,customerData);
				this.setState({devVisible_1:false,button_juder:false,cusId:''});
				this.table.refreshTable();// 刷新表格
			}else{
				message.info('操作失败'+data.data.responseInfo.description);
				this.setState({button_juder:false})
			}

		});
	};
    // 添加客户
	addDevData = async ()=>{
		console.log(this.state.data.areaId);
		let devData = await this.forms.onFinishNotReset();
		if(!devData) return null
		if(this.state.cusId){
			devData.id=this.state.cusId
		}
		if(this.state.data.areaId){
			devData.areaId=this.state.data.areaId
		}
		insertCustomer(devData).then(data=>{
			console.log(data);
			if(data.data?.data){
				message.config("添加成功");
				funTraceInfo("报警器检定管理", "添加客户", null,devData)
				this.setState({devVisible:false,cusId:''})
			}else{
				this.setState({devVisible:false,cusId:''})
				message.config('测试失败');
			}
			this.table.refreshTable();// 刷新表格
		});
	};
// 	// 修改客户信息
// 	updataCustomer = async()=>{
// 		let customer = await this.forms.onFinishNotReset();
// 		updateCustomer(customer).then(data=>{
// ||||||| .r911
//
// 	};
// 	// 修改客户信息
// 	updataCustomer = async()=>{
// 		let customer = await this.forms.onFinishNotReset();
// 		updateCustomer(customer).then(data=>{
// =======
// 		insertCustomer(devData).then(data=>{
// >>>>>>> .r942
// 			console.log(data);
// 			if(data.data?.data){
// 				message.config("添加成功");
// 				this.setState({devVisible:false,cusId:''})
// 			}else{
// 				this.setState({devVisible:false,cusId:''})
// 				message.config('测试失败');
// 			}
// 			this.table.refreshTable();// 刷新表格
// 		});
// 	};

	// 清空条件
	clearCondition = () => {
		let params={
			page:1,
			size:10,
		};
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		this.table.refreshTable(tableParamenter.params);
	   // this.getData();
	};
    render() {

        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>客户管理</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addDevStatus(true)}><PlusSquareOutlined />新增</span>
				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getCustomerList}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
					maskClosable={false}
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addDevData(true)}           // 点击确定
                        onCancel={() => this.addDevStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>


				<Modal
					maskClosable={false}
					title={this.state.modTitle}
					style={{ top: 0 }}
					visible={this.state.devVisible_1}
					okText="确定"
					// onOk={()=>{}}// 点击确定
					onCancel={() => this.setState({devVisible_1:false})}       // 点击取消
					width={900}
					centered
					footer={[
						<Button key="back"
								disabled={this.state?.button_juder}
								loading={this.state?.button_juder}
								onClick={() => this.setState({devVisible_1:false})}
						>取消</Button>,
						<Button type="primary"
								disabled={this.state?.button_juder}
								loading={this.state?.button_juder}
								onClick={()=>{
									this.addDevData_1();
								}}
						>确定</Button>
					]}
				>
					{/*框加大小*/}
					<div style={{width:'100%',height:'250px'}}>
						{/*内容*/}
						<div style={{width:'100%',height:'100%'}}>
							{/*1行*/}
							<div style={{width:'100%',height:'20%'}}>
								{/*客户编号*/}
								<div style={{width:'33%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'30%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>客户编号:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Input
												disabled
												value={this.state.customerData.id}
												placeholder={'新增客户无编号'}
												style={{width:'100%'}}
											/>
										</div>
									</div>
									{/*<div style={{width:'98%',textAlign:'right'}}>*/}
									{/*	<span style={{color:'red'}}>新增客户无编号</span>*/}
									{/*</div>*/}
								</div>
							</div>
							{/*2行*/}
							<div style={{width:'100%',height:(this.state?.juder_1||this.state?.juder_2)?'25%':'20%'}}>
								{/*客户类型*/}
								<div style={{width:'33%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'30%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>客户类型:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Select
												value={this.state.customerData.companyType}
												status={this.state?.juder_1?'error':''}
												placeholder={'请选择客户类型'}
												style={{width:'100%'}}
												onChange={(a,c)=>{
													var customerData = this.state.customerData;
													customerData.companyType=a;
													this.setState({customerData:customerData})
												}}
											>
												<option key={'1'}>居民客户</option>
												<option key={'2'}>工商客户</option>
											</Select>
										</div>
									</div>
									<div style={{width:'98%',textAlign:'right',display:this.state?.juder_1?null:'none'}}>
										<span style={{color:'red'}}>请选择客户类型</span>
									</div>
								</div>
								{/*客户单位*/}
								<div style={{width:'33%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'30%',margin:'5px',textAlign:'right',float:'left'}}>
											<span><span style={{color:'red'}}>*</span>客户单位:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Input
												value={this.state.customerData.customerUnit}
												status={this.state?.juder_2?'error':''}
												placeholder={'请输入客户单位'}
												style={{width:'100%'}}
												onChange={(title)=>{
													var customerData = this.state.customerData;
													customerData.customerUnit=title.target.value;
													this.setState({customerData:customerData,juder_2:false})
												}}
											/>
										</div>
									</div>
									<div style={{width:'98%',textAlign:'right',display:this.state?.juder_2?null:'none'}}>
										<span style={{color:'red'}}>请输入客户单位</span>
									</div>
								</div>

							</div>
							{/*3行*/}
							<div style={{width:'100%',height:(this.state?.juder_3)?'25%':'20%'}}>
								{/*法人*/}
								<div style={{width:'33%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'30%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>法人:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Input
												value={this.state.customerData.legalPerson}
												status={this.state?.juder_3?'error':''}
												placeholder={'请输入联系人'}
												style={{width:'100%'}}
												onChange={(title)=>{
													var customerData = this.state.customerData;
													customerData.legalPerson=title.target.value;
													this.setState({customerData:customerData,juder_3:false})
												}}
											/>
										</div>
									</div>
									<div style={{width:'98%',textAlign:'right',display:this.state?.juder_3?null:'none'}}>
										<span style={{color:'red'}}>请输入法人</span>
									</div>
								</div>
							</div>
							{/*4行*/}
							<div style={{width:'100%',height:(this.state?.juder_4||this.state?.juder_5)?'25%':'20%'}}>
								{/*联系人*/}
								<div style={{width:'33%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'30%',margin:'5px',textAlign:'right',float:'left'}}>
											<span><span style={{color:'red'}}>*</span>联系人:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Input
												value={this.state.customerData.principal}
												status={this.state?.juder_4?'error':''}
												placeholder={'请输入联系人'}
												style={{width:'100%'}}
												onChange={(title)=>{
													var customerData = this.state.customerData;
													customerData.principal=title.target.value;
													this.setState({customerData:customerData,juder_4:false})
												}}
											/>
										</div>
									</div>
									<div style={{width:'98%',textAlign:'right',display:this.state?.juder_4?null:'none'}}>
										<span style={{color:'red'}}>请输入联系人</span>
									</div>
								</div>
								{/*联系电话*/}
								<div style={{width:'33%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'30%',margin:'5px',textAlign:'right',float:'left'}}>
											<span><span style={{color:'red'}}>*</span>联系电话:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Input
												value={this.state.customerData.principalPhone}
												status={this.state?.juder_5?'error':''}
												placeholder={'请输入联系电话'}
												style={{width:'100%'}}
												onChange={(title)=>{
													var customerData = this.state.customerData;
													if(!/^[1]([3-9])[0-9]{9}$/g.test(title.target.value)) {
														this.setState({juder_5:true})
													}else{
														this.setState({juder_5:false})
													}
													customerData.principalPhone=title.target.value;
													this.setState({customerData:customerData})
												}}
											/>
										</div>
									</div>
									<div style={{width:'98%',textAlign:'right',display:this.state?.juder_5?null:'none'}}>
										<span style={{color:'red'}}>请输入联系电话</span>
									</div>
								</div>
							</div>
							{/*5行*/}
							<div style={{width:'100%',height:'22%'}}>
								{/*省*/}
								<div style={{width:'25%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'40%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>省:</span>
										</div>
										<div style={{width:'50%',float:'left'}}>
											<Select
												value={this.state.customerData.province}
												placeholder={'请选择省'}
												style={{width:'100%'}}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												options={areaData}
												onChange={(a,c)=>{
													var customerData = this.state.customerData;
													customerData.province=a;
													customerData.city=null;
													customerData.district=null;
													this.setState({customerData:customerData,city:c.city})
												}}
											/>
										</div>
									</div>
								</div>
								{/*市*/}
								<div style={{width:'15%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'15%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>市:</span>
										</div>
										<div style={{width:'75%',float:'left'}}>
											<Select
												value={this.state.customerData.city}
												placeholder={'请选择市'}
												style={{width:'100%'}}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												options={this.state.city}
												onChange={(a,c)=>{
													var customerData = this.state.customerData;
													customerData.city=a;
													customerData.district=null;
													this.setState({
														customerData:customerData
														,district:c.area
													})
												}}
											/>
										</div>
									</div>
								</div>
								{/*区*/}
								<div style={{width:'15%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'15%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>区:</span>
										</div>
										<div style={{width:'75%',float:'left'}}>
											<Select
												value={this.state.customerData.district}
												placeholder={'请选择区'}
												style={{width:'100%'}}
												onChange={(a,c)=>{
													var customerData = this.state.customerData;
													customerData.district=a;
													this.setState({
														customerData:customerData
													})
												}}
											>
												{this.state.district?.map((name)=>
													<Option key={name} label={name}>{name}</Option>
												)}
											</Select>
										</div>
									</div>
								</div>
								{/*详细地址*/}
								<div style={{width:'35%',height:'100%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'20%',margin:'5px',textAlign:'right',float:'left'}}>
											<span>详细地址:</span>
										</div>
										<div style={{width:'75%',float:'left'}}>
											<Input
												value={this.state.customerData.details}
												placeholder={'请输入详细地址'}
												style={{width:'100%'}}
												onChange={(title,c)=>{
													var customerData = this.state.customerData;
													customerData.details=title.target.value;
													this.setState({customerData:customerData})
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</Modal>
            </Layout>
        )
    }
}

