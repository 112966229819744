import React, { Component } from 'react'
import './deviceModelMap.less'
import * as echarts from "echarts";
import {EditOutlined,LoadingOutlined,GlobalOutlined,CheckOutlined} from '@ant-design/icons';
import {Button, Input, message, Modal, Pagination, Popconfirm, Tooltip} from "antd";
import {pageValveInfo, updateBaseDevice} from "../../../../services/qrCode";
import moment from "moment";
import {getInfoByAreaIds} from "../../../../services/customer";
export default class deviceModelMap extends Component {

    state={
        visible_1:false,                // 弹窗-开关

        allCoordinateShow:[],
        valueMapData:[],                // 地图坐标列表
        longitude:null,                 // 地图经度
        latitude:null,                  // 地图纬度

        tableParams:{areaIds:[],devType: 10200,page: 1,size: 10}, // 左侧数据查询参数
        tableDataS:[],                  // 左侧数据展示列表
        totalCount:0,                   // 左侧数据展示列表总量
        loading:false,                  // 左侧数据展示列表-加载时间
        choiceDataId:null,              // 左侧数据展示列表-选择中的数据id
        selectMode:'阀井',
        // 左侧数据展示列表-选择中的数据id
        selectModeDataS:[{a:'阀井',b:10200},{a:'调压柜',b:10211}]

    };

    componentDidMount(): void {

        var selectMode = JSON.parse(sessionStorage.getItem('selectMode'));
        // 左侧数据查询参数
        var tableParams = this.state.tableParams;
        tableParams.areaIds=JSON.parse(localStorage.getItem('menus'));
        tableParams.devType= selectMode.b;
        this.setState({
            tableParams:tableParams,
            selectMode:selectMode.a
        });
        this.pageValveInfo(tableParams);            // 获取阀井数据列表
        this.getInfoByAreaIds(tableParams.areaIds); // 根据区域ID查询管道信息

        this.initialization_valueMap(); // 初始化地图
    }

    // 根据区域ID查询管道信息
    getInfoByAreaIds=(areaIds=[],name)=>{
        var params =  new URLSearchParams();
        params.append('areaIds',areaIds??null);
        // params.append('name',name??null);
        getInfoByAreaIds(params).then(
            response=>{
                console.log('根据区域ID查询管道信息');
                var coordinateData = this.state.coordinateData;
                coordinateData=[];
                if(response?.data?.data){
                    response?.data?.data.map(one=>{
                        coordinateData.push({
                            "coordinateData":  one.coordinateData??null,
                            "id": one.id??null,
                            "name":  one.name??null,
                            "style":  one.style??null,
                        });
                    });

                }
                this.piecingCoordinate(coordinateData);// 拼凑数据 data：数据

                this.setState({coordinateData:coordinateData});
            }
        )
    };
    // 拼凑数据 data：数据
    piecingCoordinate = (data=[]) =>{
        var coordinateDatas=[];
        data.map(one=>{
            // 严重数据是否合适
            if(this.ValidateData(one.coordinateData)){
                coordinateDatas.push(JSON.parse(one.coordinateData)[0])
            }
        });
        this.configure_valueMaps([],[],coordinateDatas);       // 地图
        this.setState({allCoordinateShow:coordinateDatas}); // 管道
    };
    // 验证数据格式是否合适
    ValidateData=(data)=>{
        try{
            if(data ==="" && data === null){return false;}
            if(data.indexOf('[')<0){return false;}
            var params = JSON.parse(data); // 字符串转数据对象
            // 判断当前对象是否数组
            if(!Array.isArray(params)){return false;}
            // 判断对象第二层是否数组
            for(var i=0;i<params.length;i++){if(!Array.isArray(params[i])){
                return false;
            }}
            return true;
        }catch (e) {return false;}
    };


    // 获取阀井数据列表
    pageValveInfo=(params)=>{
        this.setState({loading:true});
        pageValveInfo(params).then(
            response=>{
                var tableDataS =this.state.tableDataS;
                var valueMapData=this.state.valueMapData;
                tableDataS=[];
                valueMapData=[];
                if(response?.data?.data){
                    let { data=[],pageInfo } = response?.data;
                    console.log('配置地图列表');
                    data.map(one=>{
                        var e = {
                            "id": one.id,                           // id
                            "devId":one.devId,                      // 设备id
                            "name": one.devName,                    // 设备名
                            'installPosition':one.installPosition,  // 安装位置
                            "value": [],                            // 坐标
                            "systemTime":one.data[0].systemTime,            // 通讯时间
                            "data_1":null,                          // 溶度
                            "data_2":null,                          // 阀井压力
                            "data_3":null,                          // 阀井温度
                            "data_4":null,                          // 异动
                            "data_5":null,                          // 液位
                            "data_6":null,                          // 电池
                            color: '#0063ff'                        // 该坐标颜色
                        };
                        // 坐标
                        if(one.voLocation?.longitude && one.voLocation?.latitude){e.value = [one.voLocation?.longitude,one.voLocation?.latitude,200]}

                        // 数据拼凑，用于地图展示
                        if(one){
                            if(one?.data.length!==0){
                                one?.data.map(two=>{
                                    // 溶度
                                    if(two.itemId===3219){e.data_1 = two?.defaultValue?? '-';}
                                    // 阀井压力
                                    else if(two.itemId===3221){e.data_2 = two?.defaultValue?? '-';}
                                    // 温度
                                    else if(two.itemId===1205){e.data_3 = two?.defaultValue?? '-';}
                                    // 异动
                                    else if(two.itemId===3235){e.data_4 = two?.defaultValue?? '-';}
                                    // 液位
                                    else if(two.itemId===3224){e.data_5 = two?.defaultValue?? '-';}
                                    // 电池
                                    else if(two.itemId===3226){e.data_6 = two?.defaultValue?? '-';}
                                });
                            }
                        }
                        valueMapData.push(e)
                    });
                    this.setState({
                        loading:false,
                        tableDataS:data,
                        totalCount:pageInfo?.total??0,
                        valueMapData:valueMapData
                    });
                }else{
                    this.setState({
                        loading:false,
                        tableDataS:tableDataS,
                        totalCount:0,
                        valueMapData:valueMapData
                    });
                }
                this.configure_valueMaps(valueMapData)
            }
        )
    };
    // 初始化配置地图
    initialization_valueMap(){
        var _this = this;
        if (echarts.getInstanceByDom(document.getElementById('deviceModelMapId'))) {
            echarts.dispose(document.getElementById('deviceModelMapId'));
        }
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        var xy=[111.63101871462548,26.214717170445535];    // 默认坐标
        chartDom.setOption(
            {
                tooltip: {
                    show:true,
                    triggerOn: 'click',
                    trigger: 'item',
                    borderColor: 'rgba(0,0,0,0)',
                    backgroundColor: 'rgba(0,0,0,0)',
                    padding:[0,0],
                    borderWidth: 0,
                    formatter: function (params) {
                        // 自定义格式化内容

                        return `
                <div style="width: 500px; height: 290px;background-color: #00b6ec; border: 1px #cccccc solid;">
                    <div style="width: 500px; height: 120px;background-color: #629d3b;">
                        <div style="width: 500px; height: 40px;">
                            <div style="color: #fff;font-size: 16px;font-weight: bold; padding: 10px 0 0 50px">阀门井智慧终端</div>
                        </div>
                        <div style="width: 500px; height: 100px;">
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备编号: `+ params.data.devId +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备名称: `+ params.data.name +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">安装位置: `+ params.data.installPosition +`</div>
                        </div>
                    </div>
                    <div style="width: 500px; height: 170px;background-color: #fff;">
                        <div style="width: 60%; height: 100%; padding: 10px 0 0 60px;float: left">
                            <div style="height: 40px;font-size: 14px;color:#000">通讯时间: `+ params.data.systemTime +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">甲烷浓度: `+ params.data.data_1 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井压力: `+ params.data.data_2 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井温度: `+ params.data.data_3 +`</div>
                        </div>
                        <div style="width: 40%; height: 100%; padding: 10px;float: left">
                            <div style="height: 40px;"/>
                            <div style="height: 40px;font-size: 14px;color:#000">异动: `+ params.data.data_4 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">液位: `+ params.data.data_5 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">电池: `+ params.data.data_6 +`</div>
                        </div>
                    </div>
                </div>
            `;
                    }
                },
                animation: false,

                bmap: {
                    center: xy,
                    zoom: 16,
                    roam: true,
                    animation: true,
                    // 地图样式
                    // mapStyle: {
                    //     styleJson: [
                    //         {
                    //             featureType: 'water',// 水
                    //             elementType: 'all',
                    //             stylers: {color: '#1f2f4e'}
                    //         },
                    //         {
                    //             featureType: 'land',// 土地
                    //             elementType: 'all',
                    //             stylers: {color: '#35312b'}
                    //         },
                    //         {
                    //             featureType: 'railway',// 铁路
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'highway',// 公路
                    //             elementType: 'all',
                    //             stylers: {color: '#23221d'}
                    //         },
                    //         {
                    //             featureType: 'highway',// 公路
                    //             elementType: 'labels',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'arterial',// 山脉
                    //             elementType: 'geometry',
                    //             stylers: {color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'arterial',// 山脉
                    //             elementType: 'geometry.fill',
                    //             stylers: {color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'poi',
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off', color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'green',
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'subway',// 地铁
                    //             elementType: 'all',
                    //             stylers: {visibility: 'off'}
                    //         },
                    //         {
                    //             featureType: 'manmade',
                    //             elementType: 'all',
                    //             stylers: {color: '#d1d1d1'}
                    //         },
                    //         // {
                    //         //     featureType: 'local',
                    //         //     elementType: 'all',
                    //         //     stylers: {
                    //         //         color: '#d1d1d1'
                    //         //     }
                    //         // },
                    //         // {
                    //         //     featureType: 'arterial',
                    //         //     elementType: 'labels',
                    //         //     stylers: {
                    //         //         visibility: 'off'
                    //         //     }
                    //         // },
                    //         {
                    //             featureType: 'boundary',
                    //             elementType: 'all',
                    //             stylers: {color: '#fefefe'}
                    //         },
                    //         {
                    //             featureType: 'building',
                    //             elementType: 'all',
                    //             stylers: {color: '#d1d1d1'}
                    //         },
                    //         // {
                    //         //     featureType: 'label',
                    //         //     elementType: 'labels.text.fill',
                    //         //     stylers: {
                    //         //         color: '#999999'
                    //         //     }
                    //         // }
                    //     ]
                    // },
                },
                series: []
            }


        );
        // 点击地图触发
        chartDom.getZr().on('click', function (params,e,b) {
            if(_this.state.choiceDataId){
                var pixelPoint = [params.offsetX, params.offsetY];
                var dataPoint = chartDom.convertFromPixel({ geoIndex: 0 }, pixelPoint);
                _this.setState({longitude:dataPoint[0],latitude:dataPoint[1]});
                var data = _this.state.valueMapData;
                if(data.find(one=>{if(!one.id)return true})){
                    data.splice(data.length-1,1,{name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'})
                }else{
                    data.push({name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'});
                }
                chartDom.setOption({
                    series: [
                        {
                            name: '标点',
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            data: data.map(function (pipe) {
                                return {
                                    id: pipe.id??null,
                                    devId:pipe.devId??null,
                                    name: pipe?.name??null,
                                    data:pipe?.data,
                                    installPosition:pipe.installPosition??null,
                                    value:pipe?.value??null,
                                    itemStyle: {color: pipe?.color??null,}
                                };
                            }),
                            encode: {value: 2},
                            symbolSize: function (val) {return val[2] / 15;},// 标点大小
                            showEffectOn: 'emphasis',// 标点波动方式
                            label: {
                                formatter: '{b}',
                                position: 'bottom',
                                show: true,
                            },
                            rippleEffect:{number:3,},// 波动样式
                        },
                    ]
                })
            }
        });



        // 窗口大小变化时，ECharts地图自适应
        window.onresize = function() {chartDom.resize();};
    };
    // 配置地图 data 坐标, location 指定经纬度, e 管道
    configure_valueMaps(data=[],location=[],e=[]){
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        // 坐标
        var datas=[];
        if(data.length<0){datas = data;}
        else{datas = this.state.valueMapData;}
        // 管道
        var lines_1;
        if(e.length<0){lines_1 = e.map(function (track) {return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};});}
        else{lines_1 = this.state.allCoordinateShow.map(function (track) {return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};});}

        chartDom.setOption({
            series: [
                // 标点
                {
                    name: '标点',
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    // data: data,
                    data: datas.map(function (pipe) {
                        return {
                            id: pipe.id,
                            devId:pipe.devId,
                            name: pipe?.name,
                            data:pipe?.data,
                            installPosition:pipe.installPosition,
                            value:pipe?.value,
                            systemTime:pipe?.systemTime??null,                      // 通讯时间
                            data_1:pipe?.data_1??null,                          // 溶度
                            data_2:pipe?.data_2??null,                          // 阀井压力
                            data_3:pipe?.data_3??null,                          // 阀井温度
                            data_4:pipe?.data_4??null,                          // 异动
                            data_5:pipe?.data_5??null,                          // 液位
                            data_6:pipe?.data_6??null,                          // 电池
                            itemStyle: {
                                color: pipe?.color,
                                borderColor : '#0063ff'
                            }
                        };
                    }),
                    encode: {value: 2},
                    symbolSize: 300/15,
                    showEffectOn: 'emphasis',// 标点波动方式
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        show: true,
                    },
                    rippleEffect:{number:3,color: '#0063ff',},// 波动样式
                },
                // 管道
                {
                    type: 'lines',
                    coordinateSystem: 'bmap',
                    data:  lines_1.map(function (pipe) {
                        return {
                            coords: pipe.coords,
                            lineStyle: {
                                width: 3,
                                opacity: 10,
                                color: 'rgba(0,255,33,0.9)',
                            }
                        };
                    }),
                    polyline: true,
                },
            ]
        });
    };
    // 坐标跳转
    coordinate_valueMaps(id,location=[]){
        let mapdata=Object.assign([],this.state.valueMapData);
        mapdata.map(one=>{
            if(one.id===id){
                one.color='red';
            }else{
                one.color='#0063ff';
            }
        });

        if(location.length<1){
            return false;
        }
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        chartDom.setOption({
            bmap: {
                center: [location[0],location[1]] // 上海的经纬度
            },
            series: [
                // 标点
                {
                    name: '标点',
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    // data: data,
                    data: mapdata.map(function (pipe) {
                        return {
                            id: pipe.id,
                            devId:pipe.devId,
                            name: pipe?.name,
                            data:pipe?.data,
                            installPosition:pipe.installPosition,
                            value:pipe?.value,
                            systemTime:pipe?.systemTime??null,                      // 通讯时间
                            data_1:pipe?.data_1??null,                          // 溶度
                            data_2:pipe?.data_2??null,                          // 阀井压力
                            data_3:pipe?.data_3??null,                          // 阀井温度
                            data_4:pipe?.data_4??null,                          // 异动
                            data_5:pipe?.data_5??null,                          // 液位
                            data_6:pipe?.data_6??null,                          // 电池
                            itemStyle: {
                                color: pipe?.color,
                                borderColor : '#0063ff'
                            }
                        };
                    }),
                    encode: {value: 2},
                    symbolSize: 300/15,
                    showEffectOn: 'emphasis',// 标点波动方式
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        show: true,
                    },
                    rippleEffect:{number:3,color: '#0063ff',},// 波动样式
                },
            ]
        })
    }

    // 地图
    valueMap= (data=[],location=[],e=[])=>{
        console.log('-----------');
        console.log(data);
        var _this=this;
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        var xy=[111.63101871462548,26.214717170445535];    // 默认坐标
        if(location.length!==0){xy = location}             // 判断有无选取经纬度
        else if(data.length!==0){                          // 判断有无列表
            // 判断有无地址获取经纬度
            if(data[0]?.value.length>2){
                xy =[data[0]?.value[0],data[0]?.value[1]];
            }

            // if(data[0]?.voLocation?.longitude && data[0]?.voLocation?.latitude){
            //     xy =[data[0].voLocation?.longitude,data[0].voLocation?.latitude];
            // }
        }

        var lines_1=null;
        if(e.length<0){
            lines_1 = e.map(function (track) {
                return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};
            });
        }else{
            lines_1 = this.state.allCoordinateShow.map(function (track) {
                return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};
            });
        }


        chartDom.setOption({
            tooltip: {
                show:true,
                triggerOn: 'click',
                trigger: 'item',
                borderColor: 'rgba(0,0,0,0)',
                backgroundColor: 'rgba(0,0,0,0)',
                padding:[0,0],
                borderWidth: 0,
                formatter: function (params) {
                    // 自定义格式化内容

                    return `
                <div style="width: 500px; height: 290px;background-color: #00b6ec; border: 1px #cccccc solid;">
                    <div style="width: 500px; height: 120px;background-color: #629d3b;">
                        <div style="width: 500px; height: 40px;">
                            <div style="color: #fff;font-size: 16px;font-weight: bold; padding: 10px 0 0 50px">阀门井智慧终端</div>
                        </div>
                        <div style="width: 500px; height: 100px;">
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备编号: `+ params.data.devId +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备名称: `+ params.data.name +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">安装位置: `+ params.data.installPosition +`</div>
                        </div>
                    </div>
                    <div style="width: 500px; height: 170px;background-color: #fff;">
                        <div style="width: 60%; height: 100%; padding: 10px 0 0 60px;float: left">
                            <div style="height: 40px;font-size: 14px;color:#000">通讯时间: `+ params.data.systemTime +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">甲烷溶度: `+ params.data.data_1 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井压力: `+ params.data.data_2 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井温度: `+ params.data.data_3 +`</div>
                        </div>
                        <div style="width: 40%; height: 100%; padding: 10px;float: left">
                            <div style="height: 40px;"/>
                            <div style="height: 40px;font-size: 14px;color:#000">异动: `+ params.data.data_4 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">液位: `+ params.data.data_5 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">电池: `+ params.data.data_6 +`</div>
                        </div>
                    </div>
                </div>
            `;
                }
            },
            animation: false,
            bmap: {
                center: xy,
                zoom: 16,
                roam: true,
                animation: true
            },
            series: [
                // 标点
                {
                    name: '标点',
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    // data: data,
                    data: data.map(function (pipe) {

                        return {
                            id: pipe.id,
                            devId:pipe.devId,
                            name: pipe?.name,
                            data:pipe?.data,
                            installPosition:pipe.installPosition,
                            value:pipe?.value,
                            systemTime:pipe?.systemTime??null,                      // 通讯时间
                            data_1:pipe?.data_1??null,                          // 溶度
                            data_2:pipe?.data_2??null,                          // 阀井压力
                            data_3:pipe?.data_3??null,                          // 阀井温度
                            data_4:pipe?.data_4??null,                          // 异动
                            data_5:pipe?.data_5??null,                          // 液位
                            data_6:pipe?.data_6??null,                          // 电池
                            itemStyle: {
                                color: pipe?.color,
                                borderColor : '#a0e5ff'
                            }
                        };
                    }),
                    encode: {value: 2},
                    symbolSize: 300/15,
                    showEffectOn: 'emphasis',// 标点波动方式
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        show: true,
                    },
                    rippleEffect:{number:3,color: 'red',},// 波动样式
                },
                // 管道
                {
                    type: 'lines',
                    coordinateSystem: 'bmap',
                    data:  lines_1.map(function (pipe) {
                        return {
                            coords: pipe.coords,
                            lineStyle: {
                                width: 3,
                                opacity: 10,
                                color: 'green',
                            }
                        };
                    }),
                    polyline: true,
                },
            ]
        });

        // 点击地图图标触发
        chartDom.on('click',function (params) {});
        // 点击地图触发
        chartDom.getZr().on('click', function (params,e,b) {
            if(_this.state.choiceDataId){
                var pixelPoint = [params.offsetX, params.offsetY];
                var dataPoint = chartDom.convertFromPixel({ geoIndex: 0 }, pixelPoint);
                _this.setState({longitude:dataPoint[0],latitude:dataPoint[1]});
                if(data.find(one=>{if(!one.id)return true})){
                    data.splice(data.length-1,1,{name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'})
                }else{
                    data.push({name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'});
                }
                chartDom.setOption({
                    series: [
                        {
                            name: '标点',
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            data: data.map(function (pipe) {
                                return {
                                    id: pipe.id??null,
                                    devId:pipe.devId??null,
                                    name: pipe?.name??null,
                                    data:pipe?.data,
                                    installPosition:pipe.installPosition??null,
                                    value:pipe?.value??null,
                                    itemStyle: {color: pipe?.color??null,}
                                };
                            }),
                            encode: {value: 2},
                            symbolSize: function (val) {return val[2] / 15;},// 标点大小
                            showEffectOn: 'emphasis',// 标点波动方式
                            label: {
                                formatter: '{b}',
                                position: 'bottom',
                                show: true,
                            },
                            rippleEffect:{number:3,},// 波动样式
                        },
                        {
                            type: 'lines',
                            coordinateSystem: 'bmap',
                            data:  lines_1.map(function (pipe) {
                                return {
                                    coords: pipe.coords,
                                    lineStyle: {
                                        width: 3,
                                        opacity: 10,
                                        color: 'green',
                                    }
                                };
                            }),
                            polyline: true,
                        },
                    ]
                })
            }
        });
        window.onresize = function() {chartDom.resize();}; // 窗口大小变化时，ECharts地图自适应
    };

    // 用于左侧栏表格数据显示
    forTableDataShow = (data = []) =>{
        if(data.length===0){
            return(
                <div style={{width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.4)',paddingTop:"40%",textAlign: 'center'}}>
                    <span style={{fontWeight: 'bold',fontSize:'18px'}}>暂无数据</span>
                </div>
            )
        }
        return data.map(one=>{
            return(
                <div style={{width:'100%',height:'130px',padding:'10px 10px 0 10px'}}>
                    <div id={'div'}/>
                    {/*设备编号----------*/}
                    <div style={{width:'100%',height:'25px'}}>
                        <div style={{width:'25%',height:'100%',fontWeight: 'bold',float:'left',textAlign: 'right'}}>
                            <span>设备编号：</span>
                        </div>
                        <div style={{width:'50%',height:'100%',float:'left', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            <span>{one?.devId??null}</span>
                        </div>
                        <div style={{width:'20%',height:'100%',float:'left'}}>
                            {/*定位*/}
                            <Tooltip title="定位">
                                <GlobalOutlined className={'EditOutlined'} onClick={()=>{
                                    var params=[];
                                    console.log(one);
                                    if(one?.voLocation?.longitude && one?.voLocation?.latitude){
                                        params =[one.voLocation?.longitude,one.voLocation?.latitude];
                                        console.log(params);
                                        this.coordinate_valueMaps(one.id,params);
                                    }

                                }}/>
                            </Tooltip>

                        </div>
                    </div>
                    {/*设备名称----------*/}
                    <div style={{width:'100%',height:'25px'}}>
                        <div style={{width:'25%',height:'100%',fontWeight: 'bold',float:'left',textAlign: 'right'}}>
                            <span>设备名称：</span>
                        </div>
                        <div style={{width:'50%',height:'100%',float:'left', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            <span>{one?.devName??null}</span>
                        </div>
                    </div>
                    {/*安装位置----------*/}
                    <div style={{width:'100%',height:'25px'}}>
                        <div style={{width:'25%',height:'100%',fontWeight: 'bold',float:'left',textAlign: 'right'}}>
                            <span>安装位置：</span>
                        </div>
                        <div style={{width:'50%',height:'100%',float:'left', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            <span>{one?.installPosition??null}</span>
                        </div>
                    </div>
                    {/*坐标位置----------*/}
                    <div style={{width:'100%',height:'25px'}}>
                        <div style={{width:'25%',height:'100%',fontWeight: 'bold',float:'left',textAlign: 'right'}}>
                            <span>坐标位置：</span>
                        </div>
                        <div style={{width:'50%',height:'100%',float:'left', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            {(this.state.choiceDataId && this.state.choiceDataId===one?.devId)?
                                <div style={{width:'100%',height:'100%'}}>
                                    <div style={{width:'50%',height:'100%',float:'left'}}>
                                        <span>经度:</span><Input value={this.state.longitude} disabled style={{width:'60%'}} size="small"/>
                                    </div>
                                    <div style={{width:'50%',height:'100%',float:'left'}}>
                                        <span>纬度:</span><Input value={this.state.latitude} disabled style={{width:'60%'}} size="small"/>
                                    </div>
                                </div>
                                :
                                one?.voLocation?.longitude && one?.voLocation?.latitude?
                                    <span>{one?.voLocation?.longitude+','+one?.voLocation?.latitude}</span>
                                    :
                                    null
                            }
                        </div>
                        {/*按钮-修改*/}
                        <div style={{width:'10%',height:'100%',float:'left'}}>
                            {(this.state?.choiceDataId && this.state.choiceDataId===one?.devId)?
                                <Tooltip title="保存">
                                    <Popconfirm title="是否保存配置?"
                                                onConfirm={()=>{
                                                    // one.voLocation.longitude=this.state?.longitude;
                                                    // one.voLocation.latitude=this.state?.latitude;
                                                    var params = Object.assign(one,
                                                        {
                                                            regionManagementId:JSON.parse(localStorage.getItem('menus'))?.[0]??null,
                                                            name:localStorage.getItem('areaName')??null,
                                                            locationType:'区域',                              // 安装点类型
                                                            locationId:one?.voLocation?.id??null,
                                                            longitude:this.state.longitude,
                                                            latitude:this.state.latitude
                                                        });
                                                    // 处理照片
                                                    one.photos.map(two=>{two.id=null});
                                                    params.installDate=params?.installDate
                                                        ?moment(params?.installDate).format('YYYY-MM-DD')
                                                        : null;

                                                    this.updateBaseDeviceServer(params);
                                                    // 清空数据
                                                    this.setState({choiceDataId:null,longitude:'',latitude:''});
                                                }}
                                                onCancel={ async ()=>{
                                                    await this.setState({choiceDataId:null,longitude:'',latitude:''});
                                                    await this.pageValveInfo(this.state.tableParams);
                                                }}
                                                okText="是" cancelText="否">
                                        <CheckOutlined className={'EditOutlined'}/>
                                    </Popconfirm>
                                </Tooltip>
                                :
                                <Tooltip title="修改">
                                    <EditOutlined
                                        className={'EditOutlined'}
                                        onClick={()=>{

                                            this.setState({choiceDataId:one?.devId??null,longitude:'',latitude:''});
                                        }}
                                    />
                                </Tooltip>
                            }
                        </div>
                    </div>
                    {/*底线*/}
                    <div style={{width:'100%',height:'15%',paddingTop:'3%'}}>
                        <div style={{width:'100%',height:'5%',backgroundColor:'rgb(204,204,204,0.4)',borderRadius: '15px'}}/>
                    </div>

                </div>
            );
        })
    };

    // 分页器变化触发
    pageChange = (page, pageSize) => {
        let params = this.state.tableParams;
        params.page = page;
        params.size = pageSize;
        this.setState({tableParams:params});
        this.pageValveInfo(params);
    };

    // 修改阀井数据-后端请求
    updateBaseDeviceServer=(params)=>{
        if(!params && !params?.longitude && !params?.latitude){return false;}
        updateBaseDevice(params).then(
            response=>{
                if(response.data.data){
                    message.info('修改成功');
                    this.updateBaseDevice(params);
                    // this.pageValveInfo(this.state.tableParams);
                }else{
                    message.info('修改失败');
                }
            }
        );

    };
    // 修改阀井数据-本地操作
    updateBaseDevice=(params)=>{
        if(!params && !params?.longitude && !params?.latitude){return false;}
        console.log(this.state.valueMapData);
        var valueMapData =this.state.valueMapData;
        var tableDataS = [...this.state.tableDataS];

        valueMapData.map(one=>{
            if(one.id===params.id){
                one.value=[params.longitude,params.latitude,200];
            }
        });
        tableDataS.map(one=>{
            if(one.id===params.id){
                one.voLocation = {longitude:params.longitude,latitude:params.latitude};
                // one=Object.assign(one,voLocation);
                // one.longitude=params.longitude;
                // one.latitude=params.latitude;
            }
        });
        console.log(tableDataS);
        this.setState({valueMapData:valueMapData,tableDataS:tableDataS});
        this.valueMap(valueMapData,[params.longitude,params.latitude])
    };

    render() {
        // 左侧数据查询参数,左侧数据展示列表总量
        let { tableParams, totalCount, } = this.state;

        return (
            <div id={'deviceModelMap'}>
{/*顶部---地图----------------------------------*/}
                <div style={{ width: '100vw',height: '50px', opacity: '0.9', backgroundColor:'#fff',position: 'absolute', zIndex: '999'}}>
                    <div style={{  width: '20%', height: '100%', padding: '10px',float: 'left'}}>
                        <span style={{fontSize:'18px',fontWeight: 'bold'}}>{localStorage.getItem('areaName')}</span>
                    </div>
                    <div style={{  width: '65%', height: '100%',  float: 'left'}}/>
                    <div style={{  width: '10%', height: '100%',float: 'left'}}/>
                </div>

{/*左框----------------------------------------*/}
                <div className={'body_left_css_1'}>
                    <div style={{width:'100%',height:'6%',borderBottom:'1px rgb(204,204,204,0.4) solid',padding:'5px 10px 10px 15px'}}>
                        <span style={{fontSize:'18px',fontWeight: 'bold',color:'#64a4ff'}}>智能终端列表</span>
                    </div>

                    <div style={{width:'100%',height:'92%'}}>
                        {/*左侧-栏*/}
                        <div style={{width:'15%',height:'100%',overflowY: 'auto',backgroundColor:'#f9f9f9',float:'left',  boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.03)'}}>
                            {this.state.selectModeDataS.map(one=>{
                                return(
                                    <div className={this.state.selectMode===one?.a?'body_left_button_type_1':'body_left_button_type_2'}
                                         onClick={()=>{
                                             var tableParams = this.state.tableParams;
                                             tableParams.devType=one.b;
                                             this.setState({tableParams:tableParams});
                                             this.pageValveInfo(tableParams);
                                             this.setState({selectMode:one.a});
                                         }}
                                    >
                                        <div style={{padding:'0px 0 0 10px'}}>
                                            <div className={one?.a==='阀井'?'body_left_button_img_1':'body_left_button_img_2'}/>
                                        </div>
                                        <span >{one.a}</span>
                                    </div>
                                )
                            })}
                        </div>
                        {/*右侧-栏*/}
                        <div style={{width:'85%',height:'100%',float:'left'}}>
                            {/*分页功能*/}
                            <div style={{width:'100%',height:'10%'}}>
                                {(this.state.tableDataS && this.state.tableDataS.length!==0)?
                                    <Pagination
                                        // size="small"
                                        position={'center'}
                                        style={{marginTop: '20px',float: 'right',fontSize: '15px'}}
                                        total={totalCount} // 总条数
                                        showTotal={(total, range) => `当前第${range[0]}-${range[1]}条 / 共${totalCount??0}条`}
                                        current={tableParams?.page} // 当前页数
                                        defaultsize={tableParams?.size} // 默认每页条数
                                        defaultPageSize={tableParams?.size}
                                        showQuickJumper={tableParams?.jumper}
                                        showSizeChanger={false}
                                        onChange={this.pageChange} // 状态改变的回调
                                    >
                                    </Pagination>
                                    :
                                    null
                                }
                            </div>
                            {/*内容*/}
                            {this.state.loading?
                                // 加载效果
                                <div style={{width:'100%',height:'90%',textAlign: 'center',paddingTop:'10%'}}>
                                    <LoadingOutlined style={{fontSize:'18px'}}/>
                                </div>
                                :
                                // 内容
                                <div style={{width:'100%',height:'90%',overflowY: 'auto'}}>
                                    {this.forTableDataShow(this.state.tableDataS)}
                                </div>
                            }
                        </div>
                    </div>
                </div>
{/*地图-----------------------------------------*/}
                <div id={'deviceModelMapId'} style={{width:'100vw',height:'100vh',zIndex:'9',}}/>
{/*弹窗-----------------------------------------*/}
                <div style={{zIndex:'99999', position: 'absolute'}}>
                </div>
            </div>
        )
    }
}

