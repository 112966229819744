import ajax from './ajax'
//设备信息管理-接口

//获取100条实时用户数据
export const getEquipmentData  = data => ajax('/equipment/subscribe/listNewestCommunicationPacketLimitOneHundred', data, 'GET');

//获取100条实时用户数据
export const queryAllDataInPages  = data => ajax('/equipment/subscribe/queryAllDataInPages', data, 'POST');

// 获取指定的一条数据发生16进制编码给后端
export const getEquipmentParticular = data =>ajax('/equipment/subscribe/queryDevicePageShowData', data, 'GET');

// 获取指定的一条数据发生16进制编码给后端
export const queryDevicePageShowDataByTimeRangeAndContent = data =>ajax('/equipment/subscribe/queryDevicePageShowDataByTimeRangeAndContent', data, 'POST');
//----------------------------------------------------------------------------------------------------------------------
// 根据协议类型、时间、失败原因等查询设备通信失败记录
export const getCommFailRecPaging = data =>ajax('/equipment/access/getCommFailRec', data, 'POST');
//----------------------------------------------------------------------------------------------------------------------
// 查询树结构数据-设备页面
export const listClassAndModelInfo = data =>ajax('/devices/device/listClassAndModelInfo', data, 'GET');
// 新增设备类型-设备页面
export const addDevClass = data =>ajax('/devices/device/addDevClass', data, 'GET');
// 删除模版-设备页面
export const deleteTemp = data =>ajax('/devices/device/deleteTemp', data, 'POST');

// 删除型号-设备页面
export const deleteDevModelItems = data =>ajax('/devices/device/addDevClass', data, 'POST');
// 根据设备类型id查询设备型号信息-设备页面
export const listDevModelInfo = data =>ajax('/devices/device/listDevModelInfo', data, 'POST');

// 根据设备类型id查询设备型号信息-设备页面
export const queryTemp = data =>ajax('/devices/device/queryTemp', data, 'GET');


// 批量查询设备（基本信息+所有属性项）-设备页面
export const getDevModelAttr = data =>ajax('/devices/item/getAllItem', data, 'GET');
// 新增设备型号-设备页面
export const addDevModel = data =>ajax('/devices/device/createOrUpdateDevModel', data, 'POST');
// 保存设备型号-设备页面
export const updateDevModel = data =>ajax('/devices/device/updateDevModel', data, 'PUT');

// 新增设备型号-设备页面
export const createOrUpdateModelTemp = data =>ajax('/devices/device/createOrUpdateModelTemp', data, 'POST');


// 查询树结构数据-设备页面
export const getAllClassAndTemp = data =>ajax('/devices/device/getAllClassAndTemp', data, 'GET');


// 查询手持终端手持终端
export const queryHandheldTerminal = data =>ajax('/checkup/handheld-terminal/queryHandheldTerminal', data, 'POST');
// 保存手持终端
export const saveHandheldTerminal = data =>ajax('/checkup/handheld-terminal/saveHandheldTerminal', data, 'POST');
// 修改手持终端
export const updateHandheldTerminal = data =>ajax('/checkup/handheld-terminal/updateHandheldTerminal', data, 'POST');
// 删除手持终端
export const removeHandheldTerminal = data =>ajax('/checkup/handheld-terminal/removeHandheldTerminal', data, 'GET');
// 激活-注册手持终端
export const registerHandheldTerminal = data =>ajax('/checkup/handheld-terminal/registerHandheldTerminal', data, 'POST');
// 解绑手持终端
export const unbindHandheldTerminal = data =>ajax('/checkup/handheld-terminal/unbindHandheldTerminal', data, 'POST');


// 设备类型下配置列表表头
export const createHeaderFormClass = data =>ajax('/devices/device/createHeaderFormClass', data, 'POST');

