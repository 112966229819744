import React, { Component } from 'react'
import {Button, Layout, Popconfirm} from 'antd'
import {FORM_ITEM, TYPE_NAME} from '../../../../components/Const'
import {ArrowLeftOutlined} from '@ant-design/icons';
import TableComponents from '../../../../components/common/tables/TableComponentsNew'
import {getPageCustomerName} from "../../../../services/customer";
import {queryHisDataByDevIdAndTime} from "../../../../services/qrCode";
import FormsTwo from "../../../../components/common/forms/FormsTwo";
import moment from "moment";

export default class deviceRealHistory extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "数据项管理",
            // 表格行是否可选
            // rowSelection: true,
            //下拉框长度
            // selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                time: '时间',
                concentration: '浓度',
                state: '状态',
            },
            params: {
                devId:this.props.location.state.rowData.devId,
                startTime:moment(new Date()).format('YYYY-MM-DD 00:00:00'),
                endTime:moment(new Date()).format('YYYY-MM-DD 23:59:59'),
                page:1,
                size:10,
            },
            // 是否绑定左侧区域
            bindArea: false
        },
        // 查询栏
        topFormItem: {
            modal: true,
            number: 3,
            labelCol: 5,
            layout: "horizontal",
            parameter: [
                {variableName: 'Time', str: '查询时间', rules: FORM_ITEM.RANGE_PICKER_TIME,},
            ]
        },
    };
    componentDidMount =()=> {
    };

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'edit':
                return (
                    <div>
                        <div style={{paddingLeft:'10px',float: 'left'}}>
                            <Popconfirm title="是否注销客户?"
                                        onConfirm={()=>{this.cancelCustomer(rowData?.id)}}
                                // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>注销客户</a>
                            </Popconfirm>
                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 查询
    getConditionsValue=async ()=>{

        let data = await this.forms.onFinishNotReset();
        let sTime=data?.Time ?moment(data?.Time[0]._d).format('YYYY-MM-DD HH:mm:ss') : null;
        let eTime=data?.Time ?moment(data?.Time[1]._d).format('YYYY-MM-DD HH:mm:ss'): null;
        let params = this.state.tableParamenter.params;
        Object.assign(params,data);
        params.startTime=sTime;
        params.endTime=eTime;
        params.page=1;
        let {tableParamenter} = this.state;
        tableParamenter.params = params;
        this.setState({tableParamenter});
        await this.table.refreshTable(params);


    };
    // 清空
    getEmptyValue=async ()=>{
        await this.setState({topFormItemData:{}});
        await this.forms.onModalClose();
    };

    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width:'100%'}}>
                    <div
                        className={'div_back_arrow'}
                        // style={{backgroundColor:'rgb(240 242 245)'}}
                    >
                        <span className={'back_arrow'} onClick={() => {this.props.history.go(-1, {state: {rowData: this.props.location?.state?.formsTwo}})}}>
                            <ArrowLeftOutlined />
                        </span>&nbsp;&nbsp;&nbsp;
                        <span className={'font_size1'}>返回上一页</span>
                    </div>
                </div>
                <div style={{width:'100%',height:'40px',position:'relative',backgroundColor:'#fff',paddingTop:'5px'}}>
                    <div style={{width:'85%'}}>
                        <FormsTwo
                            ref={e => this.forms = e}
                            formParamenter={this.state.topFormItem}
                            data={this.state.topFormItemData}
                        />
                    </div>
                    <Button style={{position:'absolute', right:'150px',top:'5px'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                    <Button style={{position:'absolute', right:'60px',top:'5px'}} onClick={() => this.getEmptyValue()}>清空</Button>
                </div>
                <TableComponents
                    paramenter={this.state.tableParamenter}
                    setData={queryHisDataByDevIdAndTime}
                    // setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                >
                </TableComponents>
            </Layout>
        )
    }
}
