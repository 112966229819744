import React, { Component } from 'react'
import { Layout, Select, Form ,Modal,message} from 'antd'
import {getCustomerImportInfo, getDataId, getImportInfo} from "../../../services/alarm";
import TableComponents from "../../../components/common/tables/TableComponents";

const { Option } = Select;
const FormItem = Form.Item;
// 小区客户信息导入
export default class customerRecord extends Component {
    state = {
        // 表格参数
        tableParamenter: {
            bindArea:false,
            // manageName: "客户信息导入",
            rowSelection: false,
            width: '83.7vw',
            title: '小区客户信息导入',
            // exportUrl: "/web/reportExportCtrl/exportDataDifference",
            importUrl: "/gateway/customer-manage/import/addCustomer",
            // 表头：变量和变量解释
            header: {
                name:'客户名称',
                contacts: '联系人电话',
                type: '客户类型',
                code: '表钢号',
                manufactor:'厂家名称',
                address: '区域地址',
                regionManagement: '小区名称',
                building: '楼栋',
                unit: '单元',
                household: '户号',
                result:'状态'
            },

            // 手动导入
            customRequest: (files)=>{
                const formData = new FormData();
                formData.append('file', files?.file??null);
                formData.append('areaId', JSON.parse(localStorage.getItem('menus')));
                try {
                    // 使用 fetch 发送文件到服务器
                    const res =  fetch('/gateway/customer-manage/import/addCustomer', {
                        method: 'POST',
                        body: formData,
                        ContentType:'.xls, .xlsx'

                    });
                    files.onSuccess(files.url);
                    console.log( res.json())
                } catch (err) {
                    console.error(err)
                }
                },

        },
        // 下拉框
        listDatas: [],
        // 类型选择
        modelDatas: [],
        manufactureValue: null,
        meterModelValue: null
    };

    importTable = () => {
        let menus = JSON.parse(localStorage.getItem('menus')) ?? [];
        menus = Array.isArray(menus) ? menus[0] : menus;
        let areaName=localStorage.getItem('areaName');
        // areaName = Array.isArray(areaName) ? areaName[0] : areaName
        console.log(menus,areaName);
        let param = {
            menus:menus
        };

        if (areaName == null) return message.error('未选择供应商,请选择供应商');
        Modal.confirm({
            title: `是否绑定到${localStorage.getItem('areaName')}?`,
            onOk: () => {
                getDataId(param).then(
                    response => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                        });
                    }
                ).catch(
                    () => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                        });

                    }
                )
            }
        })
    };

    render() {
        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    paramenter={this.state.tableParamenter}
                    setData={getCustomerImportInfo}
                    // importTable={this.importTable}
                    // getConditionsValue={this.getConditionsValue}
                    clearCondition={this.clearCondition}
                    ref={e => this.table = e}
                >
                </TableComponents>
            </Layout>
        )
    }
}
