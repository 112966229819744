import React, { Component } from 'react'
import {Layout, Divider, Timeline, Image, Button, message} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getMeterOrderForm} from "../../../services/orderForm";
import {PageQueryLifecycle, PageQueryLifecycleNew} from '../../../services/qrCode'
import moment from "moment";
// 生命周期管理
export default class lifeCycleData extends Component {
  state= {
      topFormItem: {
          modal: true,
          number: 4,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'installationAddress', str: '安装地址', rules: TYPE_NAME.INPUT,},
              {variableName: 'controllerNumber', str: '控制器编号', rules: TYPE_NAME.INPUT,},
              {variableName: 'cycleType', str: '周期类型', rules: TYPE_NAME.INPUT,}
          ],
          searchGetData:{}
      },
      // 表格参数
      tableParamenter: {
          manageName: "SIM卡申请管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              controllerModel: '设备型号',
              controllerNumber: '控制器编号',
              controllerCommunicationNumber: '控制器通信号',
              detectorRoadNumber:'探测器路号',
              detectorNumber: '探测器编号',
              installationAddress: '安装点地址',
              communicationStatus: '通信状态',
              cycleType: '周期类型',
              expirationTime: '到期时间',
              businessStatus: '业务状态',
              // edit: '操作',
          },
          // 弹出框
          headers: {
              controllerModel: '设备型号',
              controllerNumber: '控制器编号',
              controllerCommunicationNumber: '控制器通信号',
              detectorRoadNumber:'探测器路号',
              detectorNumber: '探测器编号',
              installationAddress: '安装点地址',
              communicationStatus: '通信状态',
              cycleType: '周期类型',
              expirationTime: '到期时间',
              businessStatus: '业务状态',
          },
          params: {
              customerIds:[],
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
  };

  componentDidMount =async value=> {

      var tableParamenter = this.state.tableParamenter;
      var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));
      console.log("--------------------------");
      console.log(customerNames);
      console.log("--------------------------");
      if (customerNames?.length !== 0) {
          console.log("不为空");
          customerNames?.map(one => {
              tableParamenter.params.customerIds.push(one.id)
          });
      } else {
          console.log("为空");
          customerNames = null;
      }
      this.setState({tableParamenter: tableParamenter});
      await this.table.refreshTable(tableParamenter.params);
  }
    // 条件选择：获取输入的条件
    getConditionsValue = async value => {
        let data = await this.forms.onFinishNotReset();
        console.log(data);
        let installationAddress = data.installationAddress?data.installationAddress : null;
        let controllerNumber = data.controllerNumber?data.controllerNumber : null;
        let cycleType = data.cycleType?data.cycleType : null;
        let tableParamenter = this.state.tableParamenter;
        tableParamenter.params.installationAddress=installationAddress;
        tableParamenter.params.controllerNumber=controllerNumber;
        tableParamenter.params.cycleType=cycleType;
        this.setState({tableParamenter});
        await this.table.refreshTable(tableParamenter.params);
    };
    // 清空条件选择框
    // clearValue = async () => {
    //     await this.setState({isClear: true});
    //     await this.form.resetFields();
    //     message.success('条件已清空');
    //     this.props.clearCondition?.()
    // };


    setTableValue = (value,text,data,) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'expirationTime':
                return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
  render() {
    return (
        <Layout className={'item_body_number_back tables'}>
            <div>
                <div className={'topSearchBox'}>
                    <h3>生命周期管理</h3>
                    <div style={{position:'relative'}}>
                        <div className={'topFormBox'} style={{width:'90%'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.topFormItem}
                                data={this.state.topFormItem.searchGetData}
                            />
                            <Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                        </div>
                        </div>
                </div>
                {/**/}
                <div style={{padding:'0 0 0 0',background:'#fff'}}>
                    <div>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData = {PageQueryLifecycleNew}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        >
                        </TableComponents>
                    </div>
                </div>
            </div>
        </Layout>
    )
  }
}
