import React, { Component } from 'react'
import { Form, Col, Row} from 'antd'

const FormItem = Form.Item

class FormsText extends Component {

    getData = header => {
        let {record,rowLen} = this.props
        rowLen = rowLen ?? 2
        const keys = Object.keys(header)
        let row = []

        for (let i = 0,len = keys.length; i < len; i++) {

            // 设置每行的列数
            let arr = []
            for(let j = 0; j < rowLen; j++){
                arr.push(j)
            }
            // 将要显示的数据放入数组中，该数组直接返回，用于展示元素。
            row.push(
                <Row gutter={24} key={--i}>
                    {arr.map(v => {
                        return i < len ?
                            <Col span={24/rowLen} key={++i}>
                                <FormItem
                                    label={header[keys[i]]}
                                >
                                    {record?.[keys[i]]}
                                </FormItem>
                            </Col>
                        : null
                    })}
                </Row>
            )
        }
        return row
    }

    render() {

        const {record,header,layout,span} = this.props

        return (
            <Form
                layout={layout??"horizontal"}
                hideRequiredMark
                initialValues={record ?? {}}
                // className={"drawer_from"}
                // labelCol={(span || span === 0) ? {span: span} : {span: 12}}
                key={record?.id}
            >
                {this.getData(header)}
            </Form>
        )
    }
}
export default FormsText
