import React,{Component} from "react";
import {
    Button,
    Layout,
    Input,
    Space,
    Table,
    TreeSelect,
    message,
    Select,
    Modal,
    InputNumber,
    Form,
    Popconfirm,
    Tag, Checkbox, Cascader
} from "antd";
import {ArrowLeftOutlined,MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './earlyWarningCU.less'
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getPageCustomerName} from "../../services/customer";
import {getMetersBySteelNum} from "../../services/meter";
import {
    createMeterWarningConfiguration,
    createMeterWarningConfigurationNew, determineDevItem,
    getObjectInfo
} from "../../services/earlyWarning";
import {getdataTree, getItem} from "../../services/admin";
import {getAllClassAndTemp} from "../../services/equipment";
import {pageValveInfo} from "../../services/qrCode";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../components/Const";
const { Search } = Input;
const CheckboxGroup = Checkbox.Group;
// 数据预警配置，配置页面
export default class earlyWarningCU extends Component {
    state={


        // 多选框的选择数据disabled 1、短信、2、电话、3、邮件、4、app、5、系统页面
        plainOptions : [
            {id:1,label:'短信',value:1}
            ,{id:2,label:'电话',value:2,disabled:false}
            // ,{id:3,label:'邮件',value:3}
            ],
        checkedList:[],         // 多选框-选中的值
        Input_1_value:[],       // 手写添加的值-推送目标
        Input_2_value:[],       // 手写添加的值
        customerList:[],        // 客户列表

        // 条件查询栏
        topFormItem: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'pushName', str: '客户名', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'pushPath', str: '电信电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL,},
            ]
        },
        searchGetData:{},


        modalVisible_2:false,   // 开关-2


        fieldValues:[],
        formNum:0,
        modalVisible_1:false,   // 开关-1
        formData:null,
        // 配置名
        profileName:this.props?.location?.state?.rowData?.profileName || null,

        dataType:this.props?.location?.state?.rowData?.monitoringObjectType??null,// 类型
        // 监控数据
        dataName:{
            value:null,
            label:null
        },

        areaIdDatas:[], // 当前选择区域的数据
        tableLeftCss:'',// 表格样式

        rule:this.props?.location?.state?.rowData?.ruleData || null,    // 表达式输入框数据
        ruleDataList:[],

        fieldsData:[],                      // 规则表达式
        type:'',
        treeNodeInfo_1:[],                  // 下拉框数据
        treeNodeInfo_1_value:undefined,     // 下拉框选中的区域
        // 左表标头
        tableParamenter_1_header:[
            {title: '区域', dataIndex: 'areaName', key: 'areaName'},
            {title: '唯一标识', dataIndex: 'serialno', key: 'serialno'},
            {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit'
                , render: (_, record) =>
                    <div className={'tableLink'} title={'删除'}
                         onClick={() => this.deleteLeftData(record)}
                    >
                        <u>{'删除'}</u>
                    </div>
            },
        ],
        // 左表表格数据
        tableParamenter_1_data: [],
        tableParamenter_1_datas: [],


        // 右表表格
        tableParamenter_2_header:{
            manageName: "客户表格",
            rowSelection: false,    // 表格行是否可选
            selectWidth: '6.5vw',   // 下拉框长度
            isNotInitData:true,
            header: {
                // devName: '类型',
                serialno:'唯一标识',
                manufacture: '供应商',
                devModelName: '规格型号',
                state: '状态',
                edit:'操作',
            },
            data:[],
            params: {
                steelNum:null,
                areaIds:[],
                page:1,
                size:8,
            },
        },
        select_disabled:true,
        selectData_1:[], // 数据类型
        selectData_2:[], // 监控数据
    };


    componentDidMount=()=>{

        if(this.props?.location?.state?.rowData?.monitoringObjectType){
            var tableParamenter = this.state.tableParamenter_2_header; // 右表数据结构
            tableParamenter.params.devType=this.props?.location?.state?.rowData?.monitoringObjectType;
            this.setState({tableParamenter_2_header:tableParamenter});
            this.setState({select_disabled:false,});
            this.getItem(this.props?.location?.state?.rowData?.monitoringObjectType);// 获取监控数据
        }

        // 查询树结构数据-设备页面
        getAllClassAndTemp().then(e=>{
            var datas=[];
            e?.data?.data.map(one=>{
                datas.push({key:one.devClassId,value:one.devClassId,label:one.devClassName})
            });
            this.setState({selectData_1:datas})
        });

        // 左表数据封装及表达式和其他杂项
        if(this.props?.location?.state?.rowData?.id){
            getObjectInfo({id: this.props?.location?.state?.rowData?.id}).then(
                response => {
                    if(response?.data?.data){
                        // 封装左表数据
                        var {tableParamenter_1_data}=[...this.state.tableParamenter_1_data];
                        tableParamenter_1_data=[];
                        response?.data?.data?.voMonitoringObjects.forEach(one=>{
                            var param={
                                address: null,                      // 安装地址
                                juer: one.allDev? '是': '否',       // 是否所有设备
                                areaId: one.areaId,                 // 区域Id主
                                areaIds: one.allDev !== true? null:one?.areaIds,  // 是所有的设备才需要填区域ID
                                areaName: one.areaName,                 // 区域名称
                                serialno: one?.code,                // 唯一标识
                                devName:one?.codeName??null,        // 	表钢号名称codeName devName
                                createTime: null,                   // 创建日期
                                devId: one?.devId,                  // 设备ID
                                id: one?.id || null,                // id
                                installPosition: one?.installationPosition,// 安装位置
                                modelName: one?.model,              // 型号
                                manufacture: one?.supplier,         // 供应商
                                type: one?.type,                    // 类型
                                updateTime: null                    // 修改日期
                            };
                            tableParamenter_1_data.push(param);
                        });
                        console.log('response?.data?.data?.voRules');
                        console.log(response?.data?.data.voRules);
                        if(response?.data?.data?.voRules){
                            var fieldsData = [...this.state.fieldsData];
                            var datas=[];
                            this.forRules(response?.data?.data?.voRules,datas);
                            fieldsData=datas;
                            this.setState({fieldsData:fieldsData,ruleDataList:datas});
                        }

                        this.setState({
                            tableParamenter_1_data:tableParamenter_1_data,
                            // 监控数据
                            dataName:{
                                value:String(response?.data?.data?.dataId) ??null,
                                label:String(response?.data?.data?.dataName) ??null,
                            },
                            type:response?.data?.data?.type
                        })
                    }
                }
            );
        }






        this.treeNodeAll();// 获取客户区域
    };

    getItem=(devType)=>{
        if(!devType){return false;}
        // 获取数据项
        getItem({devType}).then(e=>{
            var datas=[];
            if(e?.data?.data){e?.data?.data.map(one=>{
                datas.push({key:one.itemId,value:one.itemId,label:one.itemName})
            });
            }

            this.setState({selectData_2:datas})
        });
    };


    forRules=(voRules,data)=>{
        var param={
            first:voRules?.ruleSymbols,
            label:voRules?.ruleValue,
            select:voRules?.flag,
        };
        if(voRules?.includeValue){
            this.forRules(voRules.includeValue[0],data);
        }
        data.push(param);
        return data
    }

    ;

    // 获取客户区域
    treeNodeAll = () => {
        getdataTree().then(
            response => {
                if(response?.data?.data){
                    this.setState({ treeNodeInfo_1: response.data?.data });
                }else{
                    return message.info('获取不到数据');
                }
            }
        )
    };
    // 右表删除功能
    deleteLeftData=(data)=>{
        var tableParamenter = [...this.state.tableParamenter_1_data];
        for(let i = 0; i<tableParamenter.length;i++){
            if(data.devId===tableParamenter[i].devId){
                tableParamenter.splice(i,1);
            }
        }
        this.setState({tableParamenter_1_data:tableParamenter})
    };
    // 下拉框-点击功能
    treeSelectOnChange_1=(text)=>{
        var treeNodeInfo = this.state.treeNodeInfo_1;                       // 区域数据
        var tableParamenter = this.state.tableParamenter_2_header;          // 右表数据结构
        tableParamenter.params.areaIds=[];                                  // 右表查询参数
        var areaIds = this.funareaIds(treeNodeInfo,text);                   // 获取选择区域的数据
        areaIds?.map(one=>{tableParamenter.params.areaIds.push(one.id);});  // 将选择区域id读取出了
        this.setState({tableParamenter_2_header:tableParamenter,areaIdDatas:areaIds,treeNodeInfo_1_value:text});// 保存
        this.getPageCustomerName();// 刷新右表
    };
    // 用户选取地区后的操作-递归
    funareaIds=(data,text)=>{
        var areaIds=[];
        data.map(one => {
            if(one.name===text){
                areaIds.push(one);
                if(one?.children){
                    one?.children.map(two=>{
                        areaIds.push(two);
                    })
                }
            }else if(one?.children){
                this.funareaIds(one?.children,text)?.map(
                    one=>{
                        areaIds.push(one);
                    }
                )
            }
        });
        return areaIds;
    };
    // 用户选取地区后的操作-递归 确认父层
    funareaIds_1=(data=[],text=String)=>{
        var dataOne=null;
        data.map(one=>{
            if(one.name===text){
                dataOne = one;
            }else if(one?.children){
                var dq = this.funareaIds_1(one.children,text);
                if(dq!==null){
                    dataOne=one;
                }
            }
        });
        return dataOne;
    };
    // 刷新右表
    getPageCustomerName=async params=>{
        var tableParamenter = this.state.tableParamenter_2_header; // 右表数据结构
        console.log(tableParamenter.params)
        await this.table.refreshTable(params ?? tableParamenter.params)
    };
    // 右表-加入监控列表
    addRightData=(data)=>{
        // 调用该参数，已最前面为主
        var treeNodeInfoValue = this.state.areaIdDatas;                 // 选取的区域
        var tableParamenter = [...this.state.tableParamenter_1_data];   // 左表数据
        // 配置添加参数
        data.juder='否';
        data.areaName=this.state.treeNodeInfo_1_value;
        data.areaId=treeNodeInfoValue[0]?.id??null;
        data.areaIds=[];
        // 判断提交参数是否符合要求
        // 通过当前所选区域获取父层数据
        // 判断区域的目前并不太需要
        console.log(data);
        var dq = this.funareaIds_1(this.state.treeNodeInfo_1,this.state.treeNodeInfo_1_value);// 用户选取地区后的操作-递归 确认父层
        for(var i=0;i<tableParamenter.length;i++){
            // 判断当前区域是否有大区域
            if(tableParamenter[i].areaName===data.areaName & tableParamenter[i].juder==='是'){
                return message.info('添加设备已被加入区域全部设备');
            }else if(data.serialno===tableParamenter[i].serialno){
                return message.info('添加设备已被加入');
            }
            if(dq){
                if(tableParamenter[i].areaName===dq?.name  & tableParamenter[i].juder==='是'){
                    return message.info('添加设备区域的父层已被加入区域全部设备');
                }
            }
        }
        // 没有重复添加，没有在大区内就添加
        data.id=null;
        // tableParamenter.push(data);

        this.determineDevItem([data]);
        this.setState({
            // tableParamenter_1_data:tableParamenter,
            tableLeftCss:''})
    };

    // 监控类型
    handleChange_1=(a,b)=>{
        this.getItem(a);

        var tableParamenter = this.state.tableParamenter_2_header; // 右表数据结构
        tableParamenter.params.devType=a;
        this.setState({tableParamenter_2_header:tableParamenter});

        var {dataName,} = this.state;
        dataName.value=null;
        dataName.label=null;

        this.setState({dataType:a,select_disabled:false,dataName:dataName})
    };
    // 监控数据
    handleChange_2=(a,b)=>{
        console.log(a);
        console.log(b);
        this.setState({dataName:b})
    };


    // 加入区域下所有设备
    addAllCustomerNameNew=()=> {
        var treeNodeInfo_1_value = this.state.treeNodeInfo_1_value;             // 选取的区域名
        var tableParamenter_1_data = [...this.state.tableParamenter_1_data];    // 左表的数据
        var tableParamenterData = [];    // 左表的临时数据
        var tableParamenter_2_header = this.state.tableParamenter_2_header;     // 右表的数据
        // 检查参数
        if(treeNodeInfo_1_value===undefined){return message.info('请选择区域');}
        // 获取右侧表格数据
        var totalCount = this.table.getTotalCount();
        //查询参数
        var parms = Object.assign({},tableParamenter_2_header.params);
        parms.size=totalCount;
        parms.page=1;
        pageValveInfo(parms).then(
            response=>{
                if(response?.data?.data){
                    var datas = response?.data?.data;
                    var treeNodeInfoValue = this.state.areaIdDatas;    // 选的区域，包含子数据
                    // 添加的数据不能和左表重复
                    for(var i =0;i<datas.length;i++){
                        var juder=true;
                        // 获取的该区所有数据添加不能重复
                        if(tableParamenter_1_data.find(
                            one=>{if(one.serialno===datas[i]?.serialno)return true;})) juder=false;
                        if(juder){
                            datas[i].id=null;
                            tableParamenterData.push(Object.assign({
                                juder:'否',
                                areaName:this.state.treeNodeInfo_1_value,
                                areaId:treeNodeInfoValue[0]?.id??null,
                                areaIds:[]
                            },datas[i]));
                        }
                    }
                    this.determineDevItem(tableParamenterData);
                }
            }
        )

        // 判断子层添加的情况
        // for(var i =0;i<tableParamenter_1_data.length;i++){
        //     console.log(tableParamenter_1_data[i]);
        //     console.log(treeNodeInfo_1_value);
        //     // 判断区域内有无相同区域并且不能属于全部添加
        //     if(tableParamenter_1_data[i].area===treeNodeInfo_1_value | tableParamenter_1_data[i].juder!=='是'){
        //         return message.info('当前选择区域已有在内的数据在内，不能使用该功能');
        //     }
        //     if(tableParamenter_1_data[i].area===dq.name | tableParamenter_1_data[i].juder!=='是'){
        //         return message.info('当前选择区域已有父层数据，不能使用该功能');
        //     }
        // }
        // // 配置添加参数
        // var treeNodeInfoValue = this.state.areaIdDatas;    // 选的区域，包含子数据
        // var param={
        //     juder:'是',
        //     area:this.state.treeNodeInfo_1_value,
        //     areaId:treeNodeInfoValue[0].id,
        //     areaIds:[]
        // };
        // treeNodeInfoValue?.forEach(one=>{
        //     param?.areaIds.push(one.id);
        // });
        // tableParamenter_1_data.push(param);
        // this.setState({tableParamenter_1_data:tableParamenter_1_data})
    };
    // 加入区域下所有设备
    addAllCustomerName=()=> {
        var treeNodeInfo_1_value = this.state.treeNodeInfo_1_value;         // 选取区域名
        var tableParamenter_1_data = [...this.state.tableParamenter_1_data]; // 左表数据
        // 检查参数
        if(treeNodeInfo_1_value===undefined){return message.info('请选择区域');}
        // 获取所选区域最上层
        var dq = this.funareaIds_1(this.state.treeNodeInfo_1,treeNodeInfo_1_value);
        var juder=false;
        // 判断子层添加的情况
        for(var i =0;i<tableParamenter_1_data.length;i++){
            console.log(tableParamenter_1_data[i]);
            console.log(treeNodeInfo_1_value);
            // 判断区域内有无相同区域并且不能属于全部添加
            if(tableParamenter_1_data[i].area===treeNodeInfo_1_value | tableParamenter_1_data[i].juder!=='是'){
                return message.info('当前选择区域已有在内的数据在内，不能使用该功能');
            }
            if(tableParamenter_1_data[i].area===dq.name | tableParamenter_1_data[i].juder!=='是'){
                return message.info('当前选择区域已有父层数据，不能使用该功能');
            }
        }
        // 配置添加参数
        var treeNodeInfoValue = this.state.areaIdDatas;    // 选的区域，包含子数据
        var param={
            juder:'是',
            area:this.state.treeNodeInfo_1_value,
            areaId:treeNodeInfoValue[0].id,
            areaIds:[]
        };
        treeNodeInfoValue?.forEach(one=>{
            param?.areaIds.push(one.id);
        });
        tableParamenter_1_data.push(param);
        this.setState({tableParamenter_1_data:tableParamenter_1_data})
    };

    // 弹窗的表单获取方法
    onFinish_1=(value)=>{
        console.log('弹窗的表单获取方法');
        console.log(value);
        var stringData=null;
        if(value?.users){
            // 拼凑字符串
            value?.users.forEach(one=>{
                stringData = (stringData || '' ) + (one?.select || '' ) +one?.first+one?.label
            });
            // 检查数据是否重复
            for(var i=0;i<value?.users.length;i++){
                for(var a=i+1;a<value.users.length;a++){
                    if(value.users[i].first===value.users[a].first){
                        if(value.users[i].label===value.users[a].label){
                            return message.info('表达式不能重复');
                        }
                    }
                }
            }
        }
        console.log('value.users')
        console.log(value.users)
        this.setState({formData:value,modalVisible_1:false,rule:stringData,ruleDataList:value.users})
    };

    // 右表样式
    setTableValue_1 = (value,text,rowData) => {
        switch (value) {
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'edit':
                    var juder=true;
                    for(var i=0;i<this.state.tableParamenter_1_data.length;i++) {
                        if (this.state.tableParamenter_1_data[i].serialno === rowData.serialno) {
                            juder = false;
                        }
                    }
                    if(juder){
                        return<>
                            <div className={'tableLink'} title={'添加'}
                                 onClick={() => this.addRightData(rowData)}
                            >
                                <u>{'加入监控清单'}</u>
                            </div>
                        </>;
                    }

                break;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };


    // 添加和修改方法
    createDetectorClick=()=>{

        if(!this.state?.dataType){
            message.info('请选择数据类型');
            return false;
        }
        // 配置参数
        var params = {
            monitoringObjectType:this.state?.dataType,
            dataId: this.state.dataName.value,     // 数据Id
            dataName: this.state.dataName.label,   // 数据名称
            id: this.props?.location?.state?.rowData?.id || null,
            orgId: JSON.parse(sessionStorage.getItem("user")).orgId,    // 从属部门Id
            orgName: JSON.parse(sessionStorage.getItem("user")).orgName,// 从属部门名称
            profileName: this.state.profileName,            // 配置名称
            ruleData: this.state.rule,                  // 规则数据(前端显示拼凑好的数据)
            voMonitoringObjects: [],
        };

        var tableParamenter_1_data = this.state.tableParamenter_1_data; // 配置左表数据
        if(tableParamenter_1_data?.length===0){message.info('请选择预警对象')}
        // 封装左表数据
        tableParamenter_1_data.forEach(one=>{
            var param={
                address: null,                                  // 安装地址
                allDev: one.juder === '是' ? true : false,       // 是否所有设备
                areaId: one.areaId,                             // 区域Id主
                areaIds: one.juder !== '是'? null:one?.areaIds,  // 是所有的设备才需要填区域ID
                areaName: one.areaName,                         // 区域名称
                code: one?.serialno,                            // 表钢号
                codeName: one?.devName??null,                   // 	表钢号名称codeName devName
                createTime:  one?.createTime??null,             // 创建日期
                devId: one?.devId??null,                        // 设备ID
                id: one?.id??null,
                installationPosition: one?.installPosition??null,// 安装位置
                model: one?.devModelName??null,                 // 型号
                supplier: one?.manufacture??null,               // 供应商
                type: one?.devType??null,                       // 类型
                updateTime: null,                               // 修改日期
            };
            params.voMonitoringObjects.push(param)
        });

        // 封装表达式
        var ruleDataList =this.state.ruleDataList;
        console.log('ruleDataList');
        console.log(ruleDataList);
        for(var i = ruleDataList.length-1;i>0;i--){
            var includeValue= {
                flag: ruleDataList[i-1]?.select ? ruleDataList[i]?.select : null,       //是否包含or 或者adn
                ruleSymbols: ruleDataList[i]?.first, // 规则符号 > < = >= <=
                ruleValue: ruleDataList[i]?.label,    // 规则值
                includeValue: ruleDataList[i]?.includeValue
            };
            ruleDataList[i-1].includeValue=[];
            ruleDataList[i-1].flag=ruleDataList[i]?.select || null;
            ruleDataList[i-1]?.includeValue.push(includeValue);
        }
        // 添加表达式
        if(ruleDataList.length!==0){
            params.voRules={
                flag: ruleDataList[0]?.flag ? ruleDataList[i]?.flag : null,       //是否包含or 或者adn
                ruleSymbols: ruleDataList[0]?.first, // 规则符号 > < = >= <=
                ruleValue: ruleDataList[0]?.label,    // 规则值
                includeValue: ruleDataList[0]?.includeValue
            };
        }else{
            params.voRules= this.props?.location?.state?.rowData?.voRules
        }
        params.type=this.state.type;

        var params_1 ={
            dataId: params.dataId,
            voMonitoringObjects : params.voMonitoringObjects,
        };



        var customerList = this.state.customerList; // 客户列表
        var checkedList = this.state.checkedList;   // 多选框-选中的值
        var pushConfigs=[];    // 保存转换数据的多选框
        // 循环推送类型配置客户
        checkedList.map(one=>{
            customerList.map(two=>{
                pushConfigs.push({
                    pushName:two.pushName??null,
                    pushPath:two.pushPath??null,
                    pushType:one===1?'邮箱 ':one===2?'短信':one===3?'电话 ':null
                })
            })
        });
        // checkedList.map(one=>{
        //     pushType.push(one===1?'邮箱 ':one===2?'短信':one===3?'电话 ':null);
        // });
        // customerList.map(one=>{one=Object.assign(one,{pushType:pushType})});
        params=Object.assign(params,{pushConfigs:pushConfigs});

        console.log(params);
        // 旧
        // determineDevItem(params_1).then(
        //     response=>{
        //         if(!response?.data?.data){
        //             console.log('没有重复数据');
        //             createMeterWarningConfigurationNew(params).then(
        //                 response=>{
        //                     if(response.data.data){
        //
        //                         this.props.history.push({
        //                             pathname: '/earlyWarning/earlyWarningInfoDispose',
        //                             // page:this.props?.location?.page
        //                         });
        //                         return message.info(params.id!==null?'修改成功':'添加成功');
        //                     }else{
        //                         return message.info(params.id !== null ?'修改失败':'添加失败');
        //                     }
        //                 }
        //             )
        //         }else{
        //             message.info('不可添加'+response?.data?.data)
        //         }
        //     }
        // )
        // 新
        createMeterWarningConfigurationNew(params).then(
            response=>{
                if(response.data.data){

                    this.props.history.push({
                        pathname: '/earlyWarning/earlyWarningInfoDispose',
                        // page:this.props?.location?.page
                    });
                    return message.info(params.id!==null?'修改成功':'添加成功');
                }else{
                    return message.info(params.id !== null ?'修改失败':'添加失败');
                }
            }
        )
    };

    // 要检验的数据集，要对比的数据
    determineDevItem=(voMonitoringObjects)=>{
        var params_1 ={
            dataId: this.state?.dataName?.value,
            devIds : [],
        };
        voMonitoringObjects.map(one=>{params_1.devIds.push(one.devId)});
        determineDevItem(params_1).then(
            response=> {
                var tableParamenter = [...this.state.tableParamenter_1_data];   // 左表数据

                if (response?.data?.data) {
                    var datas=response?.data?.data;
                    message.info('无法添加已使用设备'+datas);
                    for(var i =0;i<voMonitoringObjects.length;i++){
                        if(!datas.find(one=>{if(String(voMonitoringObjects[i]?.devId).indexOf(one) !== -1) return true})){
                            tableParamenter.push(voMonitoringObjects[i]);
                        }
                    }
                    this.setState({tableParamenter_1_data:tableParamenter})
                }else{
                    this.setState({tableParamenter_1_data:voMonitoringObjects})
                }
            }
        );
    };
    // 添加客户
    addCustomer=async value => {
        let data = await this.forms.onFinishNotReset();
        var input1 = [...this.state.Input_1_value];
        var customerList = this.state.customerList;

        if(!data?.pushName){
            return message.info("请填写客户名")
        }

        if(!data?.pushTel &&  !data?.pushPath){
            return message.info("请填写电话或邮箱必填一项")
        }
        if(data){
            customerList.push(data);
        }
        if(data.pushName){
            input1.push([data.pushName]);
        }
        this.setState({customerList:customerList,Input_1_value:input1,modalVisible_2:false})
    };

    render() {

        return (
            <Layout className={'item_body_number_back tables'}>
                <div id={"earlyWarningCU"}>
{/*标头---------------------------------*/}
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}>
                                <ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>返回上一层</span>
                        </div>
                    </div>
{/*上层---------------------------------*/}
                    <div className={"top_div"}>
                        <div>
                            <div className={'top_div_left_div'}>
                                <div>
                                    <span style={{fontSize:'18px',fontWeight: 'bold', }}>预警配置</span>
                                </div>

                                <div style={{paddingTop: '10px'}}>
                                    <span style={{fontSize:'14px',fontWeight: 'bold', }}>配置从属部门：
                                        {JSON.parse(sessionStorage.getItem("user")).orgName}
                                    </span>
                                </div>

                                <div style={{width:'100%',paddingTop: '10px'}}>
                                    <div style={{float:'left',paddingTop:'5px'}}>
                                        <span style={{fontSize:'14px',fontWeight: 'bold' }}>配置名：</span>
                                    </div>
                                    <div style={{float:'left',width:'60%'}}>
                                        <Input
                                            // size="small"
                                            value={this.state.profileName}
                                            onChange={(text)=>{
                                                this.setState({profileName:text.target.value})
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

{/*规则定义---------------------------------------------------------*/}
                            <div className={'top_div_right_div'} >
                                <div >
                                    <span style={{fontSize:'18px',fontWeight: 'bold', }}>规则定义</span>
                                </div>

                                {/*上层*/}
                                <div style={{width:'100%',height:'35px',paddingTop: '2px'}}>
                                    {/*数据类型*/}
                                    <div style={{float:'left',paddingTop: '5px'}}>
                                        <span style={{fontSize:'14px',fontWeight: 'bold', }}><
                                            span style={{color:'red'}}>*</span>数据类型：</span>
                                    </div>
                                    <div style={{float:'left',width:'16%'}}>
                                        <Select
                                            defaultValue={this.props?.location?.state?.rowData?.monitoringObjectType}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange_1}
                                            options={this.state.selectData_1}
                                        />
                                    </div>
                                    {/*监控数据*/}
                                    <div style={{float:'left',paddingTop: '5px',paddingLeft:'12px'}}>
                                        <span style={{fontSize:'14px',fontWeight: 'bold', }}><span style={{color:'red'}}>*</span>监控数据：</span>
                                    </div>
                                    <div style={{float:'left',width:'16%'}}>
                                        <Select
                                            // defaultValue={this.props?.location?.state?.rowData?.dataId}
                                            value={this.state?.dataName?.value}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange_2}
                                            options={this.state.selectData_2}
                                            disabled={this.state.select_disabled}
                                        />
                                    </div>
                                    <div style={{float:'left',paddingTop: '5px',paddingLeft:'12px'}}>
                                        <span style={{fontSize:'14px',fontWeight: 'bold', }}>监控类型：</span>
                                    </div>
                                    <div style={{float:'left',width:'16%'}}>
                                        <Input
                                            value={this.state.type}
                                            onChange={(text)=>{
                                                this.setState({type:text.target.value})
                                            }}
                                        />
                                    </div>

                                    {/*规则表达式*/}
                                    <div style={{float:'left',paddingTop:'5px',paddingLeft:'12px'}}>
                                        <span style={{fontSize:'14px',fontWeight: 'bold' }}>规则表达式：</span>
                                    </div>
                                    <div style={{float:'left',width:'22%'}}>

                                        <div style={{float:'left',width:'65%'}}>
                                            <Input
                                                // size="small"
                                                disabled
                                                value={this.state.rule}
                                                style={{borderRadius:'5px 0 0 5px'}}/>
                                        </div>
                                        <div style={{float:'left'}}>
                                            <Button
                                                // size='small'
                                                type="primary"
                                                style={{borderRadius:' 0 5px 5px 0 ',float:'left'}}
                                                onClick={() => this.setState({modalVisible_1:true })}
                                            >添加方式</Button>
                                        </div>
                                    </div>
                                </div>
                                {/*下层--------------------------------------*/}
                                <div style={{width:'100%',paddingTop: '10px'}}>
                                    {/*规则表达式*/}
                                    <div style={{float:'left',width:'23%'}}>
                                        <div style={{float:'left',paddingLeft:'12px',paddingTop: '5px'}}>
                                            <span style={{fontSize:'14px',fontWeight: 'bold' }}>规则表达式：</span>
                                        </div>
                                        <div style={{float:'left',width:'62%',paddingTop: '5px'}}>
                                            <CheckboxGroup options={this.state.plainOptions}
                                                           value={this.state.checkedList}
                                                           onChange={(list)=>{ this.setState({checkedList:list})}} />
                                        </div>
                                    </div>
                                    {/*推送目标*/}
                                    <div style={{float:'left',width:'45%'}}>
                                        <div style={{float:'left',paddingLeft:'12px',paddingTop: '5px'}}>
                                            <span style={{fontSize:'14px',fontWeight: 'bold' }}>推送目标：</span>
                                        </div>
                                        <Cascader
                                            style={{ width: '54%'}}
                                            // size="small"
                                            value={this.state.Input_1_value}
                                            // options={this.state.Input_2}
                                            onChange={(text)=>{this.deleUserName(text)}}
                                            multiple
                                            maxTagCount="responsive"
                                        />
                                        <Button type="primary"
                                                onClick={() => this.setState({modalVisible_2:true})} >
                                            <span style={{"color":"#fff"}} >添加用户</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


{/*横线-----------*/}<div className={'top_separate_div'}/>
{/*下层---------------------------------*/}
                    <div className={'bootm_div'}>
                        {/*左*/}
                        <div className={'bootm_div_left_div'}>
                            <div style={{ "margin":"5.5px",height:'52px' }}>
                                <span style={{fontSize:'14px',fontWeight: 'bold', }}>预警对象推送清单:</span>
                                {/*<Input placeholder={'请输入查询值,根据表钢号查询'}  style={{  "width": "100%"}} onChange={*/}
                                {/*    (text)=>{*/}
                                {/*        var tableParamenter_1_datas = this.state.tableParamenter_1_datas;*/}
                                {/*        tableParamenter_1_datas=[];*/}
                                {/*        var tableParamenter_1_data = this.state.tableParamenter_1_data;*/}
                                {/*        tableParamenter_1_data.forEach(one=>{*/}
                                {/*            if(one.steelNum.indexOf(text.target.value.toString())>-1){*/}
                                {/*                tableParamenter_1_datas.push(one)*/}
                                {/*            }*/}
                                {/*        });*/}
                                {/*        this.setState({tableParamenter_1_datas:tableParamenter_1_datas})*/}
                                {/*    }}/>*/}
                            </div>
                            <div  style={{ "margin":"5.5px" ,"border":this.state.tableLeftCss}}>
                                <Table
                                    columns={this.state.tableParamenter_1_header}
                                    dataSource={this.state.tableParamenter_1_data}
                                    // dataSource={this.state.tableParamenter_1_datas.length!==0?this.state.tableParamenter_1_datas :this.state.tableParamenter_1_data}
                                    rowKey={"steelNum"}
                                    bordered
                                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    pagination = {{
                                        showSizeChanger:false,
                                        showQuickJumper: false,
                                        // onChange: this.getPaginationRight,       // 获得翻页数值
                                        total:this.state.tableParamenter_1_data?.length,
                                        pageSize:8,
                                        showTotal: (count = this.state.tableParamenter_1_data?.length)=>{return '共'+count+'条数据'},
                                        defaultCurrent: 1,
                                        pageSizeOptions: ['8']
                                    }}
                                >
                                </Table>
                            </div>
                        </div>
{/*横线--------------*/}<div className={'bottom_separate_div'} />
{/*右------------------------*/}
                        <div className={'bootm_div_right_div'}>

                            <div style={{ "margin":"5.5px 5.5px 0 0px" }}>
                                <span style={{fontSize:'14px',fontWeight: 'bold', }}>可产生该预警对象</span>
                                <div>
                                    <TreeSelect
                                        showSearch
                                        style={{ width: '30%' }}
                                        value={this.state.treeNodeInfo_1_value}
                                        dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                                        fieldNames={{ label: 'name' ,value:'name'}}
                                        placeholder="请选择区域"
                                        allowClear
                                        disabled={this.state.select_disabled}
                                        // treeDefaultExpandAll
                                        onChange={this.treeSelectOnChange_1}
                                        treeData={this.state.treeNodeInfo_1}
                                    />
                                    <Button type="primary" onClick={this.addAllCustomerNameNew}>加入区域下所有对象</Button>

                                </div>
                            </div>
                            <div style={{"margin":"-4px" }}>
                                <TableComponents
                                    paramenter={this.state.tableParamenter_2_header}
                                    // setData={getMetersBySteelNum}
                                    setData={pageValveInfo}
                                    setTableValue={this.setTableValue_1}
                                    ref={e => this.table = e}
                                />
                            </div>
                        </div>
                        {/*右END*/}
                    </div>
                    {/*下层END------------------------------*/}
                    <div className={'top_separate_div'}/>
                    <div className={'bootm_1_div'} style={{padding:'20px'}}>
                        <div style={{float:'right' }}>
                            <Button type="primary" onClick={() => this.createDetectorClick()}>
                                提交
                            </Button>
                        </div>

                    </div>
                </div>
                <div>
                    <div>
                        <Modal
                            title={'规则表达式'}
                            style={{ top: 0 }}
                            visible={this.state.modalVisible_1}
                            okText="确定"
                            onOk={() => this.setState({modalVisible_1:false})}            // 点击确定
                            onCancel={() => this.setState({modalVisible_1:false})}       // 点击取消
                            width={580}
                            centered
                            footer={[
                                // <Button key="back" onClick={() => this.setState({modalVisible_1:false})} >取消</Button>,
                                // <Button type="primary" htmlType="submit" >确定</Button>

                                <Button type="primary"  form="form" htmlType="submit">
                                    提交
                                </Button>
                            ]}
                        >
                            <div>
                                <Form
                                    id="form"
                                    name="dynamic_form_nest_item"
                                    onFinish={(value)=>{ this.onFinish_1(value)}}
                                    ref={e => this.form = e}
                                    style={{ maxWidth: 620 }}
                                    autoComplete="off"

                                >
                                    <Form.List name="users">
                                        {(fields, { add, remove }) => (

                                            <>
                                                {
                                                //     [
                                                //     {
                                                //         "name": 0,
                                                //         "key": 0,
                                                //         "isListField": true,
                                                //         "fieldKey": 0
                                                //     },
                                                //     {
                                                //         "name": 1,
                                                //         "key": 1,
                                                //         "isListField": true,
                                                //         "fieldKey": 1
                                                //     }
                                                // ]
                                                    fields.map(({ key, name, ...restField }) => (

                                                    <div>
                                                        {

                                                            name!==0
                                                            ?
                                                            <div style={{width:'100%',height:'50px',paddingLeft:'16px'}}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'select']}
                                                                    label={'判断'}
                                                                    rules={[{ required: true, message: '不能为空' }]}
                                                                >
                                                                    <Select
                                                                        size="small"
                                                                        placeholder="请选择类型"
                                                                        style={{ width: '80px' }}
                                                                        onChange={(a)=>{
                                                                            console.log(fields);
                                                                        }}
                                                                        options={[
                                                                            { value: 'and', label: 'and' },
                                                                            { value: 'or', label: 'or' }]}
                                                                    />
                                                                </Form.Item>

                                                            </div>
                                                            :null
                                                        }
                                                        <div style={{width:'100%',height:'50px'}}>
                                                            <Space key={key} style={{ display: 'flex',}} align="baseline">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'first']}
                                                                    label={'表达式'}
                                                                    rules={[{ required: true, message: '不能为空' }]}
                                                                >
                                                                    <Select
                                                                        size="small"
                                                                        placeholder="请选择类型"
                                                                        style={{ width: '110px' }}
                                                                        onChange={(a)=>{this.setState({numData_1:a})}}
                                                                        options={[
                                                                            { value: '>', label: '>' },
                                                                            { value: '<', label: '<' },
                                                                            { value: '=', label: '=' },
                                                                            { value: '>=', label: '>=' },
                                                                            { value: '<=', label: '<=' },
                                                                            { value: '≠', label: '≠' }
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'label']}
                                                                    label={'数值'}
                                                                    rules={[{ required: true, message: '不能为空' }]}
                                                                >
                                                                    <Input
                                                                        size="small"
                                                                        placeholder="请输入数值"
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Form.Item>
                                                                {
                                                                    name===fields.length-1?
                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                        :null
                                                                }
                                                            </Space>
                                                        </div>
                                                    </div>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed"
                                                            onClick={async value  => {
                                                                console.log(this.state.fieldsData);
                                                                add()
                                                                // this.setState({formNum:this.state.formNum+1})
                                                            }}
                                                            block icon={<PlusOutlined />}>
                                                        添加新算法
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form>
                            </div>
                        </Modal>

                        <Modal
                            title={'客户'}
                            style={{ top: 0 }}
                            visible={this.state.modalVisible_2}
                            okText="确定"
                            onOk={() => this.addCustomer()}           // 点击确定
                            onCancel={() => this.setState({modalVisible_2:false})}       // 点击取消
                            width={400}
                            centered
                        >
                            <div>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem}
                                    data={this.state.searchGetData}
                                />
                            </div>
                        </Modal>

                    </div>
                </div>
            </Layout>
        );
    }
}
