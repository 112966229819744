import React,{Component} from "react";
import {Button, Layout, message, Modal, Popconfirm, Switch, Table} from "antd";
import FormsTwo from "../../components/common/forms/FormsTwo";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {
    getCode,
    getMeterWarningInfoByCondition,
    getWarningInfoByCondition,
    polymerizationWarningConfigurationInfoByCondition,
    polymerizationWarningInfoByCondition
} from "../../services/earlyWarning";
import {TYPE_NAME} from "../../components/Const";
import moment from "moment";
import {pcGetBooksByCon, pcGetDevicesByBookId} from "../../services/account";

// 数值预警信息
export default class earlyWarningInfo extends Component {
    state = {

        loading_1:false,
        loading_2:false,
        expandedRowKeyDatas:[],

        devVisible_1:false,// 弹窗开关

        // 表格参数_1
        tableParamenter_1: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                areaName: '区域',
                profileName: '配置名',
                dataName: '数据名',
                // address:'安装地址',
                installationPosition: '安装位置',
                warningObject: '预警对象',
                warningInfo: '预警信息',
                warningTime: '预警时间',

                edit:'操作'
            },
            params: {
                // profileName:'',     // 配置名
                // warningObject:'',   // 预警对象
                // dataName:'',        // 数据名
                // address:'',         // 安装地址
                page:1,
                size:11,
                type: 1,
            },
            // 是否绑定左侧区域
            areaIds:'ids',// 针对不同区域id名
            bindArea: true,
        },
        // 弹窗查看-表格
        tableParamenter_2: {
            manageName: "null",
            // 表格行是否可选
            rowSelection: false,
            isNotInitData:true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                profileName: '配置名',
                dataName: '数据名',
                warningObject: '预警对象',
                warningInfo: "状态",
                areaName:'安装地址',
                installationPosition: '安装位置',
                warningTime:'预警时间',


            },
            params: {
                page:1,
                size:11,
                warningObject:null,
                warningTimeEnd:null, // 结束
                warningTimeStart:null// 开始
            },
            // 是否绑定左侧区域
            // areaIds:'ids',// 针对不同区域id名
            bindArea: false,
        },
        // 条件查询栏
        topFormItem_1: {
            modal: true,
            number: 5,
            labelCol: 7,
            layout: "horizontal",
            parameter: [
                {variableName: 'profileName', str: '配置名', rules: TYPE_NAME.INPUT,},
                {variableName: 'warningObject', str: '预警对象', rules: TYPE_NAME.INPUT,},
                {variableName: 'dataName', str: '数据名',rules: TYPE_NAME.INPUT,},
                {variableName: 'address', str: '安装地址',rules: TYPE_NAME.INPUT,}
            ]
        },
        topFormItem_1_data:[],
        // 条件查询栏
        topFormItem_2: {
            modal: true,
            number: 5,
            labelCol: 7,
            layout: "horizontal",
            parameter: [
                {variableName: 'warningTime', str: '预警时间', rules: TYPE_NAME.RANGE_PICKER,},

            ]
        },
        topFormItem_2_data:[],
        // 1正常模式
        tableParamenter_1_value:[
            {title: '区域', dataIndex: 'areaName', key: 'areaName'},
            {title: '配置名', dataIndex: 'profileName', key: 'profileName'},
            {title: '数据名', dataIndex: 'dataName', key: 'dataName'},
            {title: '安装地址', dataIndex: 'address', key: 'address'},
            {title: '安装位置', dataIndex: 'installationPosition', key: 'installationPosition'},
            {title: '预警对象', dataIndex: 'warningObject', key: 'warningObject'},
            {title: '预警信息', dataIndex: 'warningInfo', key: 'warningInfo'},
            {title: '预警时间', dataIndex: 'warningTime', key: 'warningTime'},
        ],
        tableParamenter_1_data:[{areaName:1,profileName:1,dataName:1,address:1,installationPosition:1,warningObject:1,warningInfo:1,warningTime:1}],
        tableParamenter_1_param:{total:0, page:1, size:12,},
        //2聚合模式
        tableParamenter_2_value:[
            {title: '配置ID', dataIndex: 'id', key: 'id'},
            {title: '配置从属部门', dataIndex: 'orgName', key: 'orgName'},
            {title: '配置名', dataIndex: 'profileName', key: 'profileName'},
            {title: '数据名', dataIndex: 'dataName', key: 'dataName'},
            {title: '规则', dataIndex: 'ruleData', key: 'ruleData'},
            {title: '监控对象从属区域', dataIndex: 'monitoringObjectArea', key: 'monitoringObjectArea'},
            {title: '监控对象类型', dataIndex: 'monitoringObjectType', key: 'monitoringObjectType'},
            {title: '监控对象总数', dataIndex: 'monitoringObjectTotal', key: 'monitoringObjectTotal'},
            {title: '预警总条数', dataIndex: 'warningInfoTotal', key: 'warningInfoTotal'},
        ],
        tableParamenter_2_data:[],
        tableParamenter_2_param:{
            total:0,
            page:1,
            size:11,
        },

        displayData:false
    };

    // 分类聚合模式模糊查询预警配置-父层数据
    polymerizationWarningConfigurationInfoByCondition=()=>{
        this.setState({loading_2:true});
        // 配置查询参数,从普通模式读取数据，查询之前会先把参数写道普通模式参数内
        var param = this.state.tableParamenter_2_param;
        param.profileName=this.state.tableParamenter_1.params?.profileName || null; // 配置名
        param.dataName=this.state.tableParamenter_1.params?.dataName || null;       // 数据名

        // 查询接口，获取父层数据
        polymerizationWarningConfigurationInfoByCondition(param).then(
            response=>{
                console.log("模糊查询预警信息");
                console.log(response);
                if(response?.data?.data){
                    var {tableParamenter_2_data} = this.state;
                    tableParamenter_2_data=response.data.data;
                    this.setState({tableParamenter_2_data:tableParamenter_2_data})
                }else{
                    message.info('查询失败')
                }
                this.setState({loading_2:false});
            }
        ).catch(e=>{
            this.setState({loading_2:false});
            return message.info('查询异常')})
    };
    // 分类聚合模式模糊查询预警信息-子层数据
    polymerizationWarningInfoByCondition=(record,page, pageSize)=>{
        console.log('分类聚合模式模糊查询预警信息-子层数据');
        this.setState({loading_1:true});
        var param={
            page: page|| 1,
            size: 10,
            id: record?.id|| record[record.length-1], // 获取点击后得到的展开数据的最后一个
        };
        if(param?.id){
            polymerizationWarningInfoByCondition(param).then(
                response=>{
                    if(response?.data?.data){
                        var tableParamenterData = this.state.tableParamenter_2_data;
                        // 查询最后展开的数据
                        for(var i=0;i<tableParamenterData.length;i++){
                            if(tableParamenterData[i].id===param.id){
                                tableParamenterData[i].subobject=response?.data;
                                this.setState({tableParamenter_2_data:tableParamenterData})
                            }
                        }
                    }
                    this.setState({loading_1:false});
                }
            );
        }
    };


    // 特殊模式父层分页参数
    getPaginationRight=(page,size)=>{
        var params = this.state.tableParamenter_2_param;
        params.page=page;
        params.size=size;
        this.setState({tableParamenter_2_param:params})
    };
    // 条件查询
    getConditionsValue= async value =>{
        console.log('条件查询');
        let data = await this.forms.onFinishNotReset();
        // 配置参数
        var {tableParamenter_1} = this.state;
        tableParamenter_1.params=Object.assign(tableParamenter_1.params,data);

        this.setState({tableParamenter_1:tableParamenter_1});

        // 判断当前查询下所选模式，根据选择模式查询
        if(this.state.displayData){
            // 聚合模式
            this.polymerizationWarningConfigurationInfoByCondition()
        }else{
            // 普通模式
            await this.table.refreshTable(tableParamenter_1.params);
        }
    };

// 根据表钢号查询历史数据
    getCode=async ()=>{
        var tableParamenter2 = this.state.tableParamenter_2;
        let data = await this.modalForms.onFinishNotReset();
        tableParamenter2.warningTimeEnd=null; // 结束
        tableParamenter2.warningTimeStart=null; // 开始
        if(data){
            tableParamenter2.warningTimeEnd=moment(data.warningTime[1]).format('YYYY-MM-DD HH:mm:ss');
            tableParamenter2.warningTimeStart=moment(data.warningTime[0]).format('YYYY-MM-DD HH:mm:ss')
        }
        this.setState({tableParamenter_2:tableParamenter2});
        this.modalTable.refreshTable(tableParamenter2.params)

    };

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'查看'} onClick={async () => {
                        var tableParamenter2 = this.state.tableParamenter_2;
                        tableParamenter2.params.warningObject=rowData?.warningObject ?? null;
                        this.setState({devVisible_1:true,tableParamenter_2:tableParamenter2});
                        this.modalTable.refreshTable(tableParamenter2.params)
                    }}>
                        <u>{'查看'}</u>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };


    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
                <div>
                    <div className={'topSearchBox'}>
                        <h3>数值预警信息</h3>

                        <div style={{width:'90%',height:'30px'}}>
                            <div style={{width:'80%' ,float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms = e}
                                        formParamenter={this.state.topFormItem_1}
                                        data={this.state.topFormItem_1_data}
                                    />

                            </div>
                            <div style={{width:'6%' ,float:'left'}}>
                                <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            </div>
                            <div style={{width:'14%'  ,float:'left'}}>
                                <span>分类聚合模式：</span>
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={(juder)=>{
                                    this.setState({displayData:juder});
                                    // 判断是否为开启,开启就调查询方法
                                    var topFormItem_1 = this.state.topFormItem_1;
                                    if(juder){
                                        this.polymerizationWarningConfigurationInfoByCondition();
                                        topFormItem_1.parameter=[
                                            {variableName: 'profileName', str: '配置名', rules: TYPE_NAME.INPUT,},
                                            {variableName: 'dataName', str: '数据名',rules: TYPE_NAME.INPUT,}
                                        ]
                                    }else {
                                        topFormItem_1.parameter=[
                                            {variableName: 'profileName', str: '配置名', rules: TYPE_NAME.INPUT,},
                                            {variableName: 'warningObject', str: '预警对象', rules: TYPE_NAME.INPUT,},
                                            {variableName: 'dataName', str: '数据名',rules: TYPE_NAME.INPUT,},
                                            {variableName: 'address', str: '安装地址',rules: TYPE_NAME.INPUT,}
                                        ]
                                    }
                                    this.setState({topFormItem_1:topFormItem_1})
                                }} />
                            </div>
                        </div>

                    </div>
{/*单表格-------------------------------------------*/}
                    <div style={{width:'100%',padding:'0 0 0 0',background:'#fff',display:this.state.displayData?'none':'',float:'left'}}>
                        <div>
                            <TableComponents
                                paramenter={this.state.tableParamenter_1}
                                setData = {getWarningInfoByCondition}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            >
                            </TableComponents>
                        </div>
                    </div>
{/*父子框表格-------------------------------------------*/}
                    <div style={{padding:'8px',background:'#fff',display:this.state.displayData?'':'none'}}>
                        <Table
                            loading={this.state.loading_2}
                            rowKey={'id'}
                            columns={this.state.tableParamenter_2_value}
                            dataSource={this.state.tableParamenter_2_data}
                            bordered
                            // 父子框
                            expandable={{
                                expandedRowKeys:this.state.expandedRowKeyDatas,
                                onExpandedRowsChange:(rows)=>{
                                    this.setState({expandedRowKeyDatas:[rows[rows?.length-1]]});
                                    console.log(rows[rows?.length-1]);
                                    this.polymerizationWarningInfoByCondition([rows[rows.length-1]]);
                                },
                                // 展开样式
                                expandedRowRender:(record)=>{
                                    return<>
                                        <Table
                                            loading={this.state.loading_1}
                                            columns={this.state.tableParamenter_1_value}
                                            dataSource={record?.subobject?.data||null}
                                            pagination = {{
                                                onChange:(page, pageSize)=>{this.polymerizationWarningInfoByCondition(record,page, pageSize)},       // 获得翻页数值
                                                showSizeChanger:false,
                                                showQuickJumper: false,
                                                total:record?.subobject?.pageInfo?.total||0,
                                                pageSize:10,
                                                showTotal: (count = record?.subobject?.pageInfo?.total)=>{return '共'+count+'条数据'},
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['10']
                                            }}
                                        />
                                    </>
                                },
                                rowExpandable: (record) => record.id !== 'Not Expandable',
                            }}

                            pagination = {{
                                showSizeChanger:false,
                                showQuickJumper: false,
                                onChange: this.getPaginationRight,       // 获得翻页数值
                                total:this.state.tableParamenter_2_param.total,
                                pageSize:this.state.tableParamenter_2_param.size,
                                showTotal: (count = this.state.tableParamenter_2_param.total)=>{
                                    return '共'+count+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['11']
                            }}
                        />
                    </div>

                    <Modal
                        title={'详情'}
                        style={{ top: 0 }}
                        visible={this.state.devVisible_1}
                        okText="确定"
                        forceRender={true}
                        // footer={false}
                        onOk={() => this.setState({devVisible_1:false})}
                        onCancel={() => this.setState({devVisible_1:false})}
                        width={1360}
                        centered
                    >

                        <div>
                            <div  style={{width:'100%',height:'60px'}}>
                                <div style={{width:'30%',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.modalForms = e}
                                        formParamenter={this.state.topFormItem_2}
                                        data={this.state.topFormItem_2_data}
                                    />
                                </div>
                                <div style={{width:'10%',float:'left'}}>
                                    <Button  type="primary" onClick={() => this.getCode()}>查询</Button>
                                </div>
                            </div>
                            <div  style={{width:'100%'}}>
                                <TableComponents
                                    paramenter={this.state.tableParamenter_2}
                                    setData = {getCode}
                                    // setTableValue={this.setTableValue}
                                    ref={e => this.modalTable = e}
                                />
                            </div>

                        </div>
                    </Modal>


                </div>
            </Layout>
        );
    }
}
