import React, {Component} from "react";
import {Image, Layout,message} from "antd";
import {ArrowLeftOutlined} from '@ant-design/icons';
import './AlarmHandleInfo.less';
import {getAlarmHanding,getDeviceInfoByDevId} from '../../../../services/alarm'
import {funTraceInfo} from '../../../../services/common'

// 设备处理详情
export default class AlarmHandleInfo extends Component {
    state={
        handleInfo_1:[
            {text:'信息来源：'    ,value:this.props?.location?.state?.rowData?.sourceName ||''},
            {text:'报警类型：'    ,value:this.props?.location?.state?.rowData?.alarmType ||''},
            {text:'安装点地址：'  ,value:'-'},
            {text:'安装点位置：'  ,value:'-'},
        ],
        handleInfo_2:[
            {text:'报警对象：'   ,value:this.props?.location?.state?.rowData?.associationName ||''},
            {text:'报警信息：'   ,value:this.props?.location?.state?.rowData?.alarmDescription ||''},
            {text:'报警等级：'   ,value:this.props?.location?.state?.rowData?.alarmLevel ||''},
            {text:'报警时间：'   ,value:this.props?.location?.state?.rowData?.alarmTime ||''},
        ],
        handleInfo_3:[
            {text:'推送情况：'   ,value:this.props?.location?.state?.rowData?.state ||''},
            {text:'处理人：'     ,value:'-'},
            {text:'联系电话：'   ,value:'-'},
            {text:'处理时间：'   ,value:'-'}
        ],
        handleInfo_5:[
            {text:'设备型号：'   ,value:'-'},
            {text:'供应商：'     ,value:'-'},
            {text:'设备编号：'   ,value:'-'},
            {text:'控制器编号：'  ,value:'-'},
            {text:'控制器通信号：',value:'-'}
        ],
        handleInfo_6:[
            {text:'设备业务状态：' ,value:'-'},
            {text:'可燃气体浓度：' ,value:'-'},
            {text:'报警状态：'     ,value:'-'},
            {text:'采集时间：'     ,value:'-'}
        ],
        alarmHanding:{
            id:'',              // 主键id
            alarmMessageId:'',  // 报警信息外键
            disposalTime:"",    // 处置时间
            path:[],            // 图片组
            disposer:'',        // 处置人
            tel:'',             // 处置人电话
            type:'',            // 处置类型
            disposalRemark:''   // 处置说明
        }
    };
    componentDidMount() {
        console.log(this.props?.location?.state?.rowData);
        this.getAlarmHanding();
        this.getDeviceInfoByDevId();
    }

    // 根据devID查询设备信息
    getDeviceInfoByDevId=()=>{
        // this.props.location.state.rowData.relevanceId  100010872
        getDeviceInfoByDevId({devId:this.props.location.state.rowData.relevanceId}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    let {handleInfo_5,handleInfo_6} = this.state;

                    handleInfo_5[0].value=response?.data?.data?.devModelName;   // 设备型号
                    handleInfo_5[1].value=response?.data?.data?.manufacture;    // 供应商
                    handleInfo_5[2].value=response?.data?.data?.hdstno;         // 设备编号
                    handleInfo_5[3].value=response?.data?.data?.parentNo;       // 控制器编号
                    handleInfo_5[4].value=response?.data?.data?.parentComNo;    // 控制器通信号

                    handleInfo_6[0].value=response?.data?.data?.state;          // 设备业务状态
                    handleInfo_6[1].value=response?.data?.data?.currentData;    // 可燃气体浓度
                    handleInfo_6[2].value=response?.data?.data?.status;         // 报警状态
                    handleInfo_6[3].value=response?.data?.data?.dataTime;       // 采集时间

                    this.setState({handleInfo_5,handleInfo_6})
                }else{
                    return message.info('查询不到设备信息');
                }
            }
        ).catch(e=>{ return message.info('接口连接异常,请联系管理员');})
    };


    // 根据报警信息ID查询处置信息
    getAlarmHanding=()=>{
        getAlarmHanding({alarmMessageId:this.props.location.state.rowData.id}).then(
            response=>{
                console.log(response);
                if(response.data.data){
                    var {alarmHanding,handleInfo_3} = this.state;
                    alarmHanding=response.data.data;
                    handleInfo_3[1].value=alarmHanding.disposer;        // 处置人
                    handleInfo_3[2].value=alarmHanding.tel;             // 处置人电话
                    handleInfo_3[3].value=alarmHanding.disposalTime;    // 处置时间
                    this.setState({alarmHanding})
                }else {
                    return message.info("获取不到处置信息");
                }
        }).catch(e=>{return message.info("连接异常，请联系管理员")})
    };
    render() {
        return(
            <Layout className={'item_body_number_back tables'}>
                <div id={'AlarmHandleInfo'}>
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'}
                                  onClick={() => this.props.history.go(-1)
                                  }
                            ><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>返回上一页</span>
                        </div>
                    </div>
                    <div className={'dvi_top'}>
                        {/*上方——左侧----------*/}
                        <div className={'dvi_top_left'}>
                            <div style={{margin:'10px'}}>
                                <div>
                                    <span style={{fontWeight: 'bolder',fontSize:'16px'}}>报警信息详情</span>
                                </div>
                                {/*---------------------------------------------*/}
                                <div style={{float:'left',width:'33%'}}>
                                    <ul>
                                        {this.state.handleInfo_1.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li>
                                                        <span>{one.text}</span>
                                                        <span>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                                {/*---------------------------------------------*/}
                                <div style={{float:'left',width:'33%'}}>
                                    <ul>
                                        {this.state.handleInfo_2.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li>
                                                        <span>{one.text}</span>
                                                        <span>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                                {/*---------------------------------------------*/}
                                <div style={{float:'left',width:'33%'}}>
                                    <ul>
                                        {this.state.handleInfo_3.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li>
                                                        <span>{one.text}</span>
                                                        <span>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                        </div>
                        {/*上方——右侧----------*/}
                        <div className={'dvi_top_right'}>
                            <div  style={{margin:'10px'}}>
                                <div>
                                    <span style={{fontWeight: 'bolder',fontSize:'16px'}}>设备当前信息</span>
                                </div>
                                <div style={{float:'left',width:'36%'}}>
                                    <ul>
                                        {this.state.handleInfo_5.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li>
                                                        <span>{one.text}</span>
                                                        <span>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div style={{float:'left',width:'60%'}}>
                                    <ul>
                                        {this.state.handleInfo_6.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li>
                                                        <span>{one.text}</span>
                                                        <span>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'top_separate_div'} />{/*-------分割线------*/}
                    <div className={'dvi_below'}>
                        <div style={{width:'100%',height:'100%',margin:'10px'}}>
                            <ul>
                                <div>
                                    <li>
                                        <span>处置类型：</span>
                                        <span>{this.state.alarmHanding.type}</span>
                                    </li>
                                </div>
                                <div>
                                    <li>
                                        <span>处置信息：</span>
                                        <span>{this.state.alarmHanding.disposalRemark}</span>
                                    </li>
                                </div>
                                <div>
                                    <li>
                                        <div><span style={{fontWeight:'bold',fontSize:'16px'}}>处置照片</span></div>
                                        <div className={'dvi_below_div'}>
                                            {this.state.alarmHanding.path.map(one=>{
                                                return(
                                                    <div className={'dvi_below_div_iamge'}>
                                                        <Image
                                                            width={100}
                                                            height={100}
                                                            src={one}
                                                        />
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }


}
