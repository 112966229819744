import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,CloseOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getAbandonDevicePaging,subscribe,unsubscribe,querySubscriberDetail} from '../../../services/device'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "订阅管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				className: '设备类型名字	',
				communicateStatusName: '通讯状态',
				devModelName: '设备型号名',
				devName: '设备名称',
				devSetUniCode: '集合唯一编码',
				devStatusName: '设备状态',
				location: '设备位置描述',
				manufacture: '厂商',
				thresholdValue: '阈值',
				searchName: '检索名',
			},
			// 弹出框
			headers: {
				devId: '设备id',
				className: '设备类型名字	',
				communicateStatusName: '通讯状态',
				devModelName: '设备型号名',
				devName: '设备名称',
				devSetUniCode: '集合唯一编码',
				devStatusName: '设备状态',
				location: '设备位置描述',
				manufacture: '厂商',
				thresholdValue: '阈值'
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},

		// 表格参数
		tableTwoParamenter: {
			manageName: "订阅详情",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				devName: '设备名称',
				itemName: '数据项名称',
				value:'值',
				dataTime: '提交时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				devName: '设备名称',
				itemName: '数据项名称',
				value:'值',
				dataTime: '提交时间',
			},
			params: {
				page:1,
				size:10,
				pathId:'',
			},
			// 是否绑定左侧区域
			bindArea: false
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'searchName',
					str: '设备名称'
				}
				
			]
		},
		    	// 总条数
		totalCount: 0,
		subscribeVisible:false, // 小窗口开关器-1
		detailSubscribeVisible:false, // 小窗口开关器-1
		tabelBox:'none',
		qrId:'',
		oldCode:'',
		modTitle:'添加订阅',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 3,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'path',
					str: '地址',
					rules: TYPE_NAME.LABLE_NOT_NULL_255,
				}
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}


	updatePoint = (subscribeVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({subscribeVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	

	





	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>废弃设备</h3>
				<Conditions 
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>			
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getAbandonDevicePaging}
					setTableValue={this.setTableValue}
					ref={e => this.table = e} 
				>
				</TableComponents>
            </Layout>
        )
    }
}
