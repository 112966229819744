import React, { Component } from 'react'
import {Button, Layout, message, Modal, Table, Tabs} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import './openAccount.less'

import AccountInfo from './js/accountInfo'
import HierarchicaRelationship from './js/hierarchicaRelationship'
import SafetyOfficer from './js/safetyOfficer'
import DeviceInfo from './js/deviceInfo'
import {
    addCustomerNew,
    getSafetyOfficerByCustomerId,
    removeSafetyOfficer,
    updateCustomerNew
} from "../../../services/customer";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {
    addPoTimeTemplate,
    getCusPropertiesList,
    getPoTimeTemplateByCustomerId,
    updatePoTimeTemplate
} from "../../../services/meter";
import {TYPE_NAME} from "../../../components/Const";
import {queryRelationData, queryResidentialCommunity} from "../../../services/community";

const {TabPane}=Tabs;

export default class openAccountNew extends Component {


    state = {
        // 表单参数-类型1-工商
        formParamenter: {
            number: 2,
            labelCol: 5,
            parameter: [
                {
                    variableName: 'type', str: '客户类型', rules: TYPE_NAME.REQ_SELECT, onChange: e => this.onChangeSelect(e),
                    data: this.props.location?.state?.selectWidth? [{key: '2', value: '工商客户'}] : [{key: '1', value: '居民客户'}, {key: '2', value: '工商客户'}]
                },
                {variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'district', str: '区/县', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT, onChange: (e,a) => this.onChangeSelectList(e,a),
                    data: [{key: "", value: ""}]},
                {variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'organizationCode', str: '组织机构代码', rules: TYPE_NAME.LABLE_18},
                {variableName: 'businessCooperationNum', str: '业务合作号', rules: TYPE_NAME.LABLE_50},
                {variableName: 'legalPerson', str: '法人', rules: TYPE_NAME.LABLE},
                {variableName: 'legalPersonPhone', str: '法人联系电话', rules: TYPE_NAME.PHONE_NUM},
                {variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
                {variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'mainBusiness', str: '主营业务', rules: TYPE_NAME.DESCRIPTION},
                {variableName: 'workTelephone', str: '单位电话', rules: TYPE_NAME.PHONE_NUM},

                {variableName: 'regionManagementId', str: '小区', rules: TYPE_NAME.SELECT, data: [],
                    onChange: (e,a) => this.onChange_2(e,a),
                },
                {variableName: 'buildingId', str: '楼栋',rules: TYPE_NAME.SELECT, data: [],
                    onChange: (e,a) => this.onChange_3(e,a),
                },
                {variableName: 'unitId', str: '单元号', rules: TYPE_NAME.SELECT, data: []},

                {variableName: 'householdNumber', str: '户号', rules: TYPE_NAME.LABLE_NOT_NULL_20},

                {variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
                {variableName: 'gasType', str: '气源', rules: TYPE_NAME.REQ_SELECT,
                    data:[{key: '天然气', value: '天然气'}, {key: '液化气', value: '液化气'}]
                },
            ]
        },
        // 表单参数-类型2-居民
        formParamenter_2: {
            number: 2,
            labelCol: 5,
            parameter: [
                {variableName: 'type', str: '客户类型', rules: TYPE_NAME.REQ_SELECT,
                    onChange: e => this.onChangeSelect(e), data: [{key: '1', value: '居民客户'}, {key: '2', value: '工商客户'}]},
                {variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT,
                    onChange: (e,a) => this.onChangeSelectList(e,a), data: [{key: "", value: ""}]
                },
                {variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
                {variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
                {variableName: 'gasType', str: '气源',rules: TYPE_NAME.REQ_SELECT,
                    data:[{key: '天然气', value: '天然气'}, {key: '液化气', value: '液化气'}]},
            ]
        },
        // 表单参数-类型3
        formParamenter_3: {
            number: 2,
            labelCol: 5,
            parameter: [
                {variableName: 'type', str: '检定类型',        rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'intervalType', str: '安排时期',rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'number', str: '次数',          rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true,}
            ]
        },
        // 表单参数-类型4
        formParamenter_4: {
            number: 2,
            labelCol: 5,
            parameter: [
                {variableName: 'type', str: '检定类型',         rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'intervalType', str: '安排时期', rules: TYPE_NAME.REQ_SELECT, disabled: true,
                    onChange: (e,a) => this.onChangeintervalType_2(e,a), data: [
                        {key: '1', value: '每月每天'},
                        {key: '2', value: '每月'}]},
            ]
        },

        // 默认数据
        data: this.props?.location?.state?.rowData ?? {province: '湖南省', city: '长沙市'},
        data1: {type: '安检',intervalType:'每年'},
        data2: {type: '自检'},
        nature:this.props?.location?.state?.rowData?.nature??null,  // 客户性质
        juder:2,                                   // 客户类型模式
        selectWidth:this.props?.location?.state?.selectWidth?? true,// 客户性质

        safetyOfficer:null,// 安全员
        addFormParam:[]
    };
    componentDidMount(): void {
        this.getSafetyOfficerByCustomerId();  // 查询安全员列表
        this.onChangeSelect(this.props?.location?.state?.rowData?.type); // 客户类型排版表单
        this.getCusAll();                     // 查询客户性质列表
        this.getPoTimeTemplateByCustomerId(); // 初始化获取客户性质
        this.queryResidentialCommunity();     // 获取小区列表
    }
    // 获取小区列表
    queryResidentialCommunity=(name)=>{
        var params={
            name:name??null,
            page:1,
            size:99
        };
        queryResidentialCommunity(params).then(
            response => {
                if(response?.data?.data){
                    var {formParamenter,addFormParam} = this.state;
                    const data = response.data.data;
                    data.map(one=>{
                        one=Object.assign({},one,{key:one.id, value:one.name});
                        addFormParam.push(one)
                    });

                    // 给小区添加下来数据
                    for(var i=0;i<formParamenter.parameter.length; i++){
                        if(formParamenter.parameter[i].variableName.indexOf('regionManagementId')!==-1){
                            formParamenter.parameter[i].data=addFormParam;
                        }
                    }

                    this.setState({formParamenter:formParamenter})
                }
            }
        )
    };

    // 小区-控制楼栋
    onChange_2 = async (e,a,juder) => {
        if(!e){return false;}
        var addFormParam = this.state.addFormParam;
        addFormParam.map(one=>{if(e===one.id){this.forms.setFieldsValue('detailAddress',one.address);}});// 地址
        this.forms.setFieldsValue('buildingId',null);   // 清空单元号数据
        this.forms.setFieldsValue('unitId',null);       // 清空单元号数据
        var params={regionManagementId: e, type: 1, businessType:'小区',};
        queryRelationData(params).then(
            response => {
                var {formParamenter} = this.state;
                var buildingLists=[];
                if(response?.data?.data){
                    var data =response?.data?.data;
                    data?.buildingList?.map(
                        one=>{buildingLists.push({key:one?.id.toString(),value:one?.number,units:one?.units??[]})}
                    );
                }
                // 给楼栋添加下来数据
                for(var i=0;i<formParamenter.parameter.length; i++){
                    if(formParamenter.parameter[i].variableName.indexOf('buildingId')!==-1){
                        formParamenter.parameter[i].data=buildingLists;
                    }
                }
                for(var i=0;i<formParamenter.parameter.length; i++){
                    if(formParamenter.parameter[i].variableName.indexOf('unitId')!==-1){
                        formParamenter.parameter[i].data=[];
                    }
                }

                if(juder){
                    for(var i=0;i<formParamenter.parameter.length; i++){
                        if(formParamenter.parameter[i].variableName.indexOf('unitId')!==-1){
                            buildingLists.map(one=>{
                                if(one.key.indexOf((this.props.location?.state?.rowData?.buildingId??null).toString())!==-1){
                                    one?.units.map(two=>{
                                        formParamenter.parameter[i].data.push(
                                            {key:two?.id.toString(),value:two?.name,}
                                        )});
                                }
                            });
                        }
                    }
                }

                this.setState({formParamenter:formParamenter,});
            }
        )
    };

    // 楼栋-控制单元号
    onChange_3 = async (e,a) => {
        if(!e){return false;}
        this.forms.setFieldsValue('unitId',null);// 清空单元号数据
        var {formParamenter} = this.state;
        var units=[];

        for(var q=0;q<formParamenter.parameter.length; q++){
            if(formParamenter.parameter[q].variableName.indexOf('buildingId')!==-1){
                formParamenter.parameter[q].data.map(one=>{
                    if(one.key.indexOf(e)!==-1){
                        one?.units.map(two=>{units.push({key:two?.id.toString(),value:two?.name,})});
                    }
                });
            }
        }

        for(var i=0;i<formParamenter.parameter.length; i++){
            if(formParamenter.parameter[i].variableName.indexOf('unitId')!==-1){
                formParamenter.parameter[i].data=units;
            }
        }

        this.setState({formParamenter:formParamenter})
    };


    // 初始化获取客户性质
    getPoTimeTemplateByCustomerId=()=>{
        //选择类型控制展示内容
        if(this.props?.location?.state?.selectWidth){
            if(this.props?.location?.state?.rowData?.customerId){
                // 获取检查数据
                getPoTimeTemplateByCustomerId({customerId:this.props?.location?.state?.rowData.customerId}).then(
                    response=>{
                        var {data1,data2} = this.state;

                        if(response?.data?.data){
                            response.data.data.map(one=>{
                                if(one.type===1){
                                    data1=one;
                                    data1.type='安检';
                                    data1.intervalType='每年';
                                }else if(one.type===2){
                                    data2=one;
                                    data2.type='自检';
                                    this.onChangeintervalType_2(one.intervalType.toString());
                                    data2.intervalType=data2.intervalType===1?'每月每天':'每月';
                                }
                            });
                            this.setState({data1,data2});
                            this.forms1.onModalClose();
                            this.forms2.onModalClose();
                        }
                    }
                );
            }
        }
    };

// 客户类型：工商和民用模式变更--------
    onChangeSelect = e => {if(e==='1'){this.setState({juder:1});}else{this.setState({juder:2});}};
// 查询客户性质列表------
    getCusAll =()=>{
        getCusPropertiesList().then(
            response=>{
                if(response?.data?.data){
                    let {data} = response.data;
                    let {formParamenter, formParamenter_2} = this.state;
                    formParamenter.parameter[4].data = this.getTreeData(data);formParamenter_2.parameter[4].data = this.getTreeData(data);
                    this.setState({cusPropertiesList: data, formParamenter, formParamenter_2});
                }
            }
        )
    };
// 客户性质列表 递归----------------
    getTreeData = data => {let arr = data?.map(value => {return {key:value?.id, value:value?.name}});return arr};

// 客户性质--赋值----------
    onChangeSelectList = async (e,a) => {
        this.state.cusPropertiesList.map(one=>{
            if(one.name===a.children){
                console.log(one);
                if(this.state.selectWidth){
                    this.forms1.setFieldsValue('number',one.securityCheckNumberYear);
                    this.forms2.setFieldsValue('intervalType',Number(one.selfCheckedType)>1?'每月':'每月每天');
                    this.forms2.setFieldsValue('number',one.selfCheckedNumberMon);
                    this.onChangeintervalType_2(Number(one.selfCheckedType)>1?'2':'1',null);
                }
            }
        });
        this.setState({nature:a.children})// 客户性质
    };
//  安检时间类型变更----------
    onChangeintervalType_2= async (e,a)=>{
        console.log(Number(e));
        if(!e){return false;}
        var {formParamenter_4} =this.state;
        // 自定义类型选用
        if(Number(e)===2){
            console.log('当前表单不能有次数-1');
            if(!formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
                formParamenter_4.parameter.push({variableName: 'number', str: '次数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true});
            }
        }else{
            console.log('当前表单有次数去掉-2');
            if(formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
                formParamenter_4.parameter.splice(formParamenter_4.parameter.length-1,1)
            }
        }
        this.setState({formParamenter_4})
    };

    onSave = async () => {
        // this.addOrUpdatePoTimeTemplate();// 新增(修改)客户时间配置
        var data = await this.forms.onFinishNotReset();
        var value ={
            id:null,
            address:{
                province: null,
                city: null,
                district: null,
                detailAddress:null
            }
        };
        value = Object.assign(value,data);
        value.address.province=data?.province??null;
        value.address.city=data?.city??null;
        value.address.district=data?.district??null;
        value.address.detailAddress=data?.detailAddress??null;
        console.log('---------------非form获取参数，而是手动配置-注解掉--------------');
        if(this.props.location?.state?.selectWidth){
            if(this.props.location?.state?.rowData?.customerId){
                value.id=this.props.location?.state?.rowData?.customerId;}
        }else{
            if(this.props.location?.state?.rowData?.id){value.id=this.props.location?.state?.rowData?.id;}
        }
        if(!value) return null;
        value.nature=this.state?.nature;// 客户性质
        // 判断是否有区域
        value.areaId = JSON.parse(localStorage.getItem('menus'))?.[0];
        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域")}

        // 没有id视为新增，初次不需要和基本信息一起发
        if(!value?.id){

            value.voSafetyOfficers= await this.getSafetyOfficer();
        }
        console.log(value);
        Modal.confirm({
            title: `是否绑定到${localStorage.getItem('areaName')}?`,
            onOk: () => {
                message.loading({ content: value.id ? '修改中...' : '开户中...', key: 'save'});
                console.log('--------');
                console.log(value);
                // 修改
                if(value?.id){
                    updateCustomerNew(value).then(
                        response => {
                            if(response?.data?.data){
                                // funTraceInfo("客户管理", "开户", null,value)
                                message.success({ content: value.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
                                if(this.props.location?.state?.selectWidth){
                                    this.addOrUpdatePoTimeTemplate(response?.data?.data);
                                }
                                // this.props.history.replace('/customer/businessMaintain')
                                return this.props.history.go(-1);
                            }else{
                                message.error({ content: value.id ? '修改失败' : '该号码已存在或系统错误', key: 'save', duration: 2})
                            }
                        }
                    ).catch(
                        () => message.error({ content: value.id ? '修改失败' : '开户失败', key: 'save', duration: 2})
                    )
                }else{
                    addCustomerNew(value).then(
                        response => {
                            if(response?.data?.data){
                                // funTraceInfo("客户管理", "开户", null,value)
                                message.success({ content: value.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
                                if(this.props.location?.state?.selectWidth){
                                    this.addOrUpdatePoTimeTemplate(response?.data?.data);
                                }
                                // this.props.history.replace('/customer/businessMaintain')
                                return this.props.history.go(-1);
                            }else{
                                message.error({ content: value.id ? '修改失败' : '该号码已存在或系统错误', key: 'save', duration: 2})
                            }
                        }
                    ).catch(
                        () => message.error({ content: value.id ? '修改失败' : '开户失败', key: 'save', duration: 2})
                    )
                }

            }
        })
    };

    // 新增(修改)客户时间配置
    addOrUpdatePoTimeTemplate= async (data)=>{

        console.log('新增(修改)客户时间配置');

        let forms1 = await this.forms1.onFinishNotReset();// 安检
        let forms2 = await this.forms2.onFinishNotReset();// 自检
        if(!forms1) return false;
        if(!forms2) return false;
        console.log(forms1);
        console.log(forms2);
        forms1.type=1;// 默认数据
        forms2.type=2;// 默认数据


        forms1.customerId= this.props.location?.state?.rowData?.customerId??data;
        forms2.customerId= this.props.location?.state?.rowData?.customerId??data;

        // 检查类型
        forms1.intervalType=3;// 默认数据
        //只针对控制当前每月每天只能一天
        if((forms2.intervalType==='1') || (forms2.intervalType==='每月每天')){
            forms2.intervalType=1;
            forms2.number=1;
        }
        // 针对修改时
        if((forms2.intervalType==='2') || (forms2.intervalType==='每月')){
            forms2.intervalType=2;
        }


        var params =[];

        if(this.state.data1?.id){
            forms1.id= this.state.data1?.id;
            forms2.id= this.state.data2?.id;
            params = [forms1,forms2];
            // 修改客户时间配置
            updatePoTimeTemplate(params).then(
                response => {
                    console.log('修改客户时间配置');
                    console.log(response.data.data);
                    if(response?.data?.data){
                        console.log(response.data.data)
                    }
                }
            )
        }else{
            params = [forms1,forms2];
            // 新增(修改)客户时间配置
            addPoTimeTemplate(params).then(
                response => {
                    console.log('新增(修改)客户时间配置');
                    console.log(response.data.data);
                    if(response?.data?.data){
                        console.log(response.data.data)
                    }
                }
            )
        }
    };




// 获取安全员
    getSafetyOfficer = async ()=>{if(this?.safety){return await this.safety.getSafetyOfficer();}else {return this.state.safetyOfficer;}};
// 查询安全员列表
    getSafetyOfficerByCustomerId= () =>{
        var params={"id":  this.props.location?.state?.rowData?.customerId, "page": 1, "size": 5};
        getSafetyOfficerByCustomerId(params).then(response => {if(response?.data?.data){this.setState({safetyOfficer:response?.data?.data})}})
    };
    render() {

        return (
            <Layout className={'item_body_number_back'}>
                <div style={{width:'100%',height:'98%',backgroundColor:'#fff'}}>
{/*返回上一页按钮------------------------------------------------------------------------------------------------------*/}
                    <div className={'div_back_arrow'}>
                        <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                        <span className={'font_size2'}>返回客户管理</span>
                    </div>
{/*内容---------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width:'100%',height:'95.5%',padding:'1px'}}>
                        <div style={{width:'100%',height:'100%'}}>
                            <Tabs
                                // activeKey={activeKey}// onTabClick={this.tabsClick}
                                tabPosition={"top"} animated={true}
                            >
                                {/*基本信息-客户参数*/}
                                <TabPane tab="基本信息" key="1">
                                    <div style={{width:'100%',height:'98%',backgroundColor:"#fff"}}>

                                        <div style={{width:'100%',paddingLeft:'20px',paddingRight:'100px'}}>
                                            <FormsTwo
                                                ref={e => this.forms = e}
                                                formParamenter={this.state.juder===2?this.state.formParamenter:this.state.formParamenter_2}
                                                data={this.state.data}
                                            />
                                            {/*判断所处模式-检查日期*/}
                                            {this.state.selectWidth?
                                                <>
                                                    {/*安检*/}
                                                    <FormsTwo
                                                        ref={e => this.forms1 = e}
                                                        formParamenter={this.state.formParamenter_3}
                                                        data={this.state.data1}
                                                    />
                                                    {/*自检*/}
                                                    <FormsTwo
                                                        ref={e => this.forms2 = e}
                                                        formParamenter={this.state.formParamenter_4}
                                                        data={this.state.data2}
                                                    />
                                                </>
                                                :null
                                            }
                                        </div>
                                        <div className={'card_open_account_div'} style={{height:'70px'}}>
                                            <Button type="primary"  htmlType="submit" onClick={() => this.onSave()}>保存</Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button type="primary" onClick={() => this.clearData()}>清空</Button>
                                        </div>
                                    </div>

                                </TabPane>
                                <TabPane tab="上下关系" key="2">
                                    <div style={{width:'100%',height:'100%',backgroundColor:"red"}}>
                                        <HierarchicaRelationship
                                            ref={e => this.hierarchicaRelationship = e}
                                            rowData={this.props.location?.state?.rowData}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tab="安全员" key="3">
                                    <div style={{width:'100%',height:'100%',backgroundColor:"#fff",padding:'10px'}}>
                                        <SafetyOfficer
                                            ref={e => this.safety = e}
                                            rowData={this.props.location?.state?.rowData}
                                        />
                                    </div>
                                </TabPane>
                                {/*<TabPane tab="终端设备" key="4">*/}
                                {/*    <div style={{width:'100%',height:'100%',backgroundColor:"#fff",padding:'10px'}}>*/}
                                {/*        <DeviceInfo*/}
                                {/*            ref={e => this.safety = e}*/}
                                {/*            rowData={this.props.location?.state?.rowData}*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</TabPane>*/}
                            </Tabs>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
