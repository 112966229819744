import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,CloseOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {querySubscriber,subscribe,unsubscribe,querySubscriberDetail} from '../../../services/device'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "订阅管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				path: '地址',
				createTime: '提交时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				path: '地址',
				createTime: '提交时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},

		// 表格参数
		tableTwoParamenter: {
			manageName: "订阅详情",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				devName: '设备名称',
				itemName: '数据项名称',
				value:'值',
				dataTime: '提交时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				devName: '设备名称',
				itemName: '数据项名称',
				value:'值',
				dataTime: '提交时间',
			},
			params: {
				page:1,
				size:10,
				pathId:'',
			},
			// 是否绑定左侧区域
			bindArea: false
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'url',
					str: '地址'
				}

			]
		},
		    	// 总条数
		totalCount: 0,
		subscribeVisible:false, // 小窗口开关器-1
		// subscribeVisible_1:false, // 小窗口开关器-1
		detailSubscribeVisible:false, // 小窗口开关器-1
		tabelBox:'none',
		qrId:'',
		oldCode:'',
		modTitle:'添加订阅',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 3,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'path',
					str: '地址',
					rules: TYPE_NAME.LABLE_NOT_NULL_255,
				}
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'查看详情'} onClick={() =>
					this.detailSubscribeStatus(true,rowData.id)}>
						<u>{'查看详情'}</u>
				</div>
				<div className={'tableLink'} title={'取消订阅'} onClick={() => this.delSubscribeData(rowData.id)}>
						<u>{'取消订阅'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	setTable2Value = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'删除'} onClick={() => this.delSubscribeDetailData(rowData.devItemId)}>
						<u>{'删除'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	updatePoint = (subscribeVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({subscribeVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	//添加设备窗口显示
	addSubscribeStatus = async (subscribeVisible)=>{
		await this.setState({subscribeVisible,data:{},modTitle:'添加订阅'})
		await this.forms.onModalClose()
    };
	detailSubscribeStatus = async(detailSubscribeVisible,id)=>{
		// this.setState({tabelBox:'block'})
		let tableTwoParamenter = this.state.tableTwoParamenter;
		tableTwoParamenter.params.pathId=id;
		await this.setState({detailSubscribeVisible:detailSubscribeVisible,tableTwoParamenter:tableTwoParamenter});

		await this?.table2.refreshTable(tableTwoParamenter?.params);
		// querySubscriberDetail({pathId:id}).then(data=>{
		// 	if(data.data.data==true){
		// 		message.config('查询成功')
		// 	}else{
		// 		message.config('查询失败')
		// 	}

		// });
    };
	hidedDtailSubscribe=()=>{
		this.setState({tabelBox:'none'})
	};

	addSubscribeData = async (value)=>{
			let devData = await this.forms.onFinishNotReset();
			subscribe(devData).then(data=>{
				if(data.data.data===true){
					message.config("修改成功")
					// funTraceInfo("设备管理", "添加订阅", null,devData)
					this.addSubscribeStatus(false)
				}else{
					this.addSubscribeStatus(false)
					message.config('修改失败')

				}
				this.table.refreshTable();// 刷新表格
			});

	};

//取消订阅
delSubscribeData = async (value)=>{
	unsubscribe({pathIdList:[value]}).then(data=>{
		if(data.data.data==true){
			// funTraceInfo("设备管理", "取消订阅", null,value)
			message.config("取消成功")
		}else{
			message.config('取消失败')

		}
		this.table.refreshTable();// 刷新表格
	});

};

//取消订阅详情
delSubscribeDetailData = async (value)=>{
	let params = this.state.tableTwoParamenter.params
	unsubscribe({pathIdList:[params.pathId],devItemIdList:[value]}).then(data=>{
		if(data.data.data==true){
			// funTraceInfo("设备管理", "删除", null,value)
			message.config("删除成功")
		}else{
			message.config('删除失败')

		}
		this.table2.refreshTable(params);// 刷新表格
	});

};



	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>订阅管理</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addSubscribeStatus(true)}><PlusSquareOutlined />新增</span>
					{/* <span className={'addLink'} onClick={() => this.updatePoint(true)}><EditOutlined />修改</span> */}

				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {querySubscriber}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				 {/*<div style={{display:this.state.tabelBox}}>*/}
					{/*<div style={{width:'100%', height:'100%', position:'fixed', left:'0', top:'0', background:'#000',zIndex:'999998',opacity:'0.3'}} onClick={() => this.hidedDtailSubscribe()}></div>*/}
					{/*<div style={{width:'1000px', height:'600px', position:'fixed', left:'50%', top:'50%',margin:'-300px 0 0 -500px',zIndex:'999999',background:'#fff'}}>*/}
					{/*	<h4 style={{padding:'5px 15px', fontSize:'16px',lineHeight:'40px',borderBottom:'1px solid #ccc'}}>订阅详情<CloseOutlined style={{float:'right',fontSize:'20px',lineHeight:'40px'}} onClick={() => this.hidedDtailSubscribe()} /></h4>*/}
					{/*	<div style={{width:'1000px', height:'550px',overflow:'auto'}}>*/}
					{/*		<TableComponents*/}
					{/*			paramenter={this.state.tableTwoParamenter}*/}
					{/*			setData = {querySubscriberDetail}*/}
					{/*			setTableValue={this.setTable2Value}*/}
					{/*			ref={e => this.table2 = e}*/}
					{/*		>*/}
					{/*		</TableComponents>*/}
					{/*	</div>*/}
                	{/*</div>*/}
				 {/*</div>*/}
					<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.subscribeVisible}
                        okText="确定"
                        onOk={() => this.addSubscribeData(true)}           // 点击确定
                        onCancel={() => this.addSubscribeStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>
				<Modal
					title={'订阅详情'}
					style={{ top: 0 }}
					visible={this.state.detailSubscribeVisible}
					okText="确定"
					onOk={() => this.setState({detailSubscribeVisible:false})}           // 点击确定
					onCancel={() => this.setState({detailSubscribeVisible:false})}       // 点击取消
					width={1000}
					centered
				>
					<div style={{width:'100%', height:'550px',overflow:'auto'}}>
						<TableComponents
							paramenter={this.state.tableTwoParamenter}
							setData = {querySubscriberDetail}
							setTableValue={this.setTable2Value}
							ref={e => this.table2 = e}
						>
						</TableComponents>
					</div>
				</Modal>
            </Layout>
        )
    }
}
