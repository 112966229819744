import ajax from './ajax'





// 获取出入库列表
export const getStorageBooksByCondition = data => ajax('/businesse/pcStorageBook/getStorageBooksByCondition', data, 'POST');
//根据出入库ID查询台账关系
export const getSteelNumsByBookId = data => ajax('/businesse/pcStorageBook/getSteelNumsByBookId', data, 'GET')

// 获取APP组织树
export const getAppOrganizationTree = data => ajax('/newAuth/adminApp/organizationManagement/tree', null, 'GET')

//根据出入库ID查询台账关系
export const getStoragesByOutOrgId = data => ajax('/businesse/pcStorage/getStoragesByOutOrgId', data, 'GET')

//根据App组织ID查询职位名称
export const listAppPositionsByOrganizationId = (data) => ajax('/newAuth/adminApp/positionManagement/listPositionsByOrganizationId', (data), 'GET')
//根据组织ID查询APP用户基本信息
export const getTheUsersByOutPostId = (data) => ajax('/businesse/pcStorage/getTheUsersByOutPostId', (data), 'GET')
//根据职位查用户
export const findUsersByOutPostId = (data) => ajax('/businesse/pcStorage/findUsersByOutPostId', (data), 'GET')
//根据仓库查用户
export const findUsersByOutStorageId = (data) => ajax('/businesse/pcStorage/findUsersByOutStorageId', (data), 'GET')


//创建仓库
export const createStorage = (data) => ajax('/businesse/pcStorage/createStorage', (data), 'POST')
//修改仓库
export const modifyTheStorage = (data) => ajax('/businesse/pcStorage/modifyTheStorage', (data), 'POST')
//删除仓库
export const delTheStorage = (data) => ajax('/businesse/pcStorage/delTheStorage', (data), 'POST')
//仓库配置职位
export const createPostsByOutStorageId = (data) => ajax('/businesse/pcStorage/createPostsByOutStorageId', (data), 'POST')
//仓库配置用户
export const createUsersByOutPostId = (data) => ajax('/businesse/pcStorage/createUsersByOutPostId', (data), 'POST')

































