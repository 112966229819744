import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import moment from 'moment'
import $ from 'jquery'
import FormsTwo from '../../../components/common/forms/FormsTwo'

import {funTraceInfo} from '../../../services/common'
import {getSimCardApplicationListInXinao,getManufacturerList,approveApplication,getDeliverGoods,deliverGoods,rejectApplication} from '../../../services/simCard'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "SIM卡申请管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				applyTime: '申请时间',
				deliveryTime: '发货时间',
				factoryName: '厂家',
				applyUserName:'申请人',
				type: '类型',
				applyCounts: '申请/退回张数',
				deliveryCounts: '发货张数',
				state: '状态',
				edit: '操作',
			},
			// 弹出框
			headers: {
				applyTime: '申请时间',
				deliveryTime: '发货时间',
				factoryName: '厂家',
				applyUserName: '申请人',
				type: '类型',
				applyCounts: '申请/退回张数',
				deliveryCounts: '发货张数',
				state: '状态',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'applyTime',
					str: '申请时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'facId',
					str: '厂家',
					rules: TYPE_NAME.SELECT,
					disabled: this.props.location.state ? true : false,
					data:[],
				},
				{
					variableName: 'state',
					str: '状态',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'提交申请',value:'提交申请'},
						{key:'已批准',value:'已批准'},
						{key:'拒绝',value:'拒绝'},
						{key:'已发货',value:'已发货'},
						{key:'已收货',value:'已收货'},
						{key:'退回',value:'退回'},
					],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'type',
					str: '类型',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'申请',value:'申请'},
						{key:'申请退回',value:'申请退回'},
					],
					rules: TYPE_NAME.SELECT,
				},
			]
		},
		    	// 总条数
		totalCount: 0,
		approvalVisible:false, // 批准
		dgVisible:false, // 发货
		refuseVisible:false, // 拒绝
		//批准
		ModalFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: 'sim卡申请Id',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'factoryName',
					str: '申请厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '申请数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'deliveryCounts',
					str: '发货数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'code',
					str: '厂家未使用张数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'code',
					str: '厂家新订单数量',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'sim',
					str: 'SIM卡号',
					rules: TYPE_NAME.TEXT_AREA,
				},
			]
		},

		//发货
		dgFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: 'sim卡申请Id',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'factoryName',
					str: '申请厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '申请数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'sim',
					str: 'SIM卡号',
					rules: TYPE_NAME.TEXT_AREA,
					disabled:true
				},
			]
		},
		//拒绝
		refuseFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: 'sim卡申请Id',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'factoryName',
					str: '申请厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '申请数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'reasonForRejection',
					str: '拒绝原因',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				},
			]
		},
		//详情
		detailFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 4,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'factoryName',
					str: '厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '申请张数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'deliveryCounts',
					str: '发货张数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'waybillNum',
					str: '快递单号',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'state',
					str: '状态',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
			]
		},
		//详情
		detailTrFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 4,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'refuse',
					str: '退回原因',
					rules: TYPE_NAME.TEXT_AREA,
					disabled:true
				},
			]
		},

	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				text = rowData.state;
				return <>
				<div className={'tableLink'} title={'查看'} onClick={() => this.detailStatus(true,rowData)}>
						<u>{'查看'}</u>
				</div>
				{text=='提交申请' ? <div className={'tableLink'} title={'批准'} onClick={() => this.approvalStatus(true,rowData)}>
				<u>{'批准'}</u>
				</div>
				: <div className={'tableNoLink'} title={'批准'}>
				<u>{'批准'}</u>
				</div>}

				{text=='已批准' ? <div className={'tableLink'} title={'发货'} onClick={() => this.dgStatus(true,rowData)}>
				<u>{'发货'}</u>
				</div>
				: <div className={'tableNoLink'} title={'发货'}>
				<u>{'发货'}</u>
				</div>}

				{text=='提交申请' ? <div className={'tableLink'} title={'拒绝'} onClick={() => this.refuseStatus(true,rowData)}>
				<u>{'拒绝'}</u>
				</div>
				: <div className={'tableNoLink'} title={'拒绝'}>
				<u>{'拒绝'}</u>
				</div>}
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		getManufacturerList().then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}
	//详情
	detailStatus = async (detailVisible,rData)=>{
		await this.setState({detailVisible})
		if(rData){
			getDeliverGoods({simApplyId:rData.id}).then(
				response => {
					const {data} = response.data
					if(data){
						$('#detailSimList').html('')
						if(data.voIccIdAndSimList){
							if(data.voIccIdAndSimList.length>0)
							{
								for(var i=0;i<data.voIccIdAndSimList.length;i++)
								{
									$('#detailSimList').append("<tr><td>"+data.voIccIdAndSimList[i].sim+"</td><td>"+data.voIccIdAndSimList[i].iccId+"</td></tr>")
								}
							}
						}
						if(data.state=='拒绝')
						{

							$('#detailTrBox').show()
							$('#detailSimListBox').hide()
						}else{
							$('#detailTrBox').hide()
							$('#detailSimListBox').show()
						}

					}
					this.setState({detailData: data})
					this.setState({detailTrData: data})
					this.detailforms.onModalClose()
					this.detailTrforms.onModalClose()
				}
			)
		}
	}


	//批准
	approvalStatus = async (approvalVisible,rData)=>{
		await this.setState({approvalVisible})
		this.setState({data: rData})
		await this.forms.onModalClose()
	}
	addApprovalData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.forms.onFinishNotReset()
			let simArry=[]
			if(!approvalData) return null
			if(approvalData.sim){
				simArry=approvalData.sim.split('\n')
			}
			approvalData.sim=simArry
			approvalData.approverId=user.id
			approvalData.approverName=user.realName
			approveApplication(approvalData).then(
				response => {
					if(response.data.data==true){
						// funTraceInfo("sim卡管理", "sim卡申请", null,approvalData)
						message.config("操作成功")
						this.approvalStatus(false)
					}else{
						this.approvalStatus(false)
						message.config('操作失败')

					}
					this.table.refreshTable();// 刷新表格
				}
			)


	};

		//发货
	dgStatus = async (dgVisible,rData)=>{
			await this.setState({dgVisible})
			let sim=''
			if(rData){
			getDeliverGoods({simApplyId:rData.id}).then(
				response => {
					const {data} = response.data
					if(data){
						if(data.sim){
							if(data.sim.length>0)
							{
								for(var i=0;i<data.sim.length;i++)
								{
									sim+=data.sim[i]+'\n'
								}
							}
						}

					}

					rData.sim=sim
					this.setState({dgData: rData})
					this.dgforms.onModalClose()
				}
			)
		}

	}
	addDgData = async (value)=>{
		let approvalData = await this.dgforms.onFinishNotReset()
		approvalData.sim=[]
		deliverGoods(approvalData).then(
			response => {
				if(response.data.data==true){
					// funTraceInfo("sim卡管理", "发货", null,approvalData)
					message.config("操作成功")
					this.dgStatus(false)
				}else{
					this.dgStatus(false)
					message.config('操作失败')

				}
				this.table.refreshTable();// 刷新表格
			}
		)
	};

	//拒绝
	refuseStatus = async (refuseVisible,rData)=>{
		await this.setState({refuseVisible})
		this.setState({refuseData: rData})
		await this.refuseforms.onModalClose()
	}
	addRefuseData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.refuseforms.onFinishNotReset()
			if(!approvalData) return null
			approvalData.approverId=user.id
			approvalData.approverName=user.realName
			rejectApplication(approvalData).then(
				response => {
					if(response.data.data==true){
						// funTraceInfo("sim卡管理", "拒绝", null,approvalData)
						message.config("操作成功")
						this.refuseStatus(false)
					}else{
						this.refuseStatus(false)
						message.config('操作失败')

					}
					this.table.refreshTable();// 刷新表格
				}
			)


	};




	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		let sTime=data.applyTime ?moment(data.applyTime[0]._d) : null;
		let eTime=data.applyTime ?moment(data.applyTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.applyStartTime=sTime;
		params.applyEndTime=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }





	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
					<h3>SIM卡申请管理</h3>
					<div style={{position:'relative'}}>
								<div className={'topFormBox'} style={{width:'90%'}}>
								<FormsTwo
										ref={e => this.forms = e}
										formParamenter={this.state.topFormItem}
										data={this.state.searchGetData}
								/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
					</div>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff'}}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getSimCardApplicationListInXinao}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
					</TableComponents>
				</div>
				<Modal
                        title={'批准'}
                        style={{ top: 0 }}
                        visible={this.state.approvalVisible}
                        okText="确定"
                        onOk={() => this.addApprovalData(true)}           // 点击确定
                        onCancel={() => this.approvalStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                </Modal>
				<Modal
                        title={'发货'}
                        style={{ top: 0 }}
                        visible={this.state.dgVisible}
                        okText="确定"
                        onOk={() => this.addDgData(true)}           // 点击确定
                        onCancel={() => this.dgStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.dgforms = e}
							formParamenter={this.state.dgFormParamenter}
							data={this.state.dgData}
							/>
                        </div>
                </Modal>
				<Modal
                        title={'拒绝'}
                        style={{ top: 0 }}
                        visible={this.state.refuseVisible}
                        okText="确定"
                        onOk={() => this.addRefuseData(true)}           // 点击确定
                        onCancel={() => this.refuseStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.refuseforms = e}
							formParamenter={this.state.refuseFormParamenter}
							data={this.state.refuseData}
							/>
                        </div>
                </Modal>
				<Modal
                        title={'详情'}
                        style={{ top: 0 }}
                        visible={this.state.detailVisible}
                        okText="确定"
                        onOk={() => this.detailStatus(false)}           // 点击确定
                        onCancel={() => this.detailStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.detailforms = e}
							formParamenter={this.state.detailFormParamenter}
							data={this.state.detailData}
							/>
							<div id='detailTrBox'>
								<FormsTwo
								ref={e => this.detailTrforms = e}
								formParamenter={this.state.detailTrFormParamenter}
								data={this.state.detailTrData}
								/>
							</div>
							<div className={'detailSimListBox'} id='detailSimListBox'>
								<h4>SIM卡号信息</h4>
								<table>
									<thead>
										<th>SIM卡号</th>
										<th>ICCID号</th>
									</thead>
									<tbody id='detailSimList'>
									</tbody>
								</table>
							</div>
                        </div>
                </Modal>
            </Layout>
        )
    }
}
