import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Menu,Table,Button,Tree,Checkbox,Tooltip} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import moment from 'moment'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'

import {funTraceInfo} from '../../../services/common'

import {getAppOrganizationTree,getStoragesByOutOrgId,listAppPositionsByOrganizationId,getTheUsersByOutPostId,createStorage,delTheStorage,modifyTheStorage,createPostsByOutStorageId,createUsersByOutPostId,findUsersByOutStorageId} from '../../../services/store'

export default class PointManagement extends Component {

    state = {
		responseInfo: {},
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				outStorageName: '仓库名',
				edit: '操作',
			},
			// 弹出框
			headers: {
				outStorageName: '仓库名',
			},
			params: {
				page:1,
				size:10,
				outOrgId:''
			},
			// 是否绑定左侧区域
			bindArea: false,
			isNotInitData:true
		},
		// 表格参数
		tableTwoParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				name: '职位名称',
			},
			// 弹出框
			headers: {
				name: '职位名称',
			},
			params: {
				page:1,
				size:10,
				organizationId:''
			},
			// 是否绑定左侧区域
			bindArea: false,
			isNotInitData:true
		},
		// 表格参数
		tableThereParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			checkStatus:true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				realName: '用户姓名',
				phone: '手机号',
			},
			// 弹出框
			headers: {
				realName: '用户姓名',
				phone: '手机号',
			},
			params: {
				page:1,
				size:10,
				outPostId:''
			},
			// 是否绑定左侧区域
			bindArea: false
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
			]
		},
		plainOptions:[],
		checkOptions:[],
		funCheckArry:[],
		expandedKeys: [],
		checkedKeys: [],
		selectedKeys: [],
		autoExpandParent: [],
		tree: {
			treeData: [
			  {
				title: 'name',
				key: 'id',
	  
				children: [
				  {
					title: 'name',
					key: 'id',
	  
					children: [
					  { title: 'name', key: 'id' },
					],
				  },
				],
			  },
			]
		  },
		    	// 总条数
		totalCount: 0,

		// 表单参数
		formParamenter: {
		modal: true,
		number: 1,
		labelCol: 4,
		align: 'center',
		layout: "horizontal",
		parameter: [
			{
				variableName: 'outOrgId',
				str: '所属机构ID',
				rules: TYPE_NAME.NOT_BLANK,
				disabled: true,
	
				},
			{
			variableName: 'outOrgName',
			str: '所属机构',
			rules: TYPE_NAME.NOT_BLANK,
			disabled: true,

			},
			{
			variableName: 'storeRoomName',
			str: '仓库名称',
			rules: TYPE_NAME.NOT_BLANK
			}
		]
		},
		delVisible:false, //删除
		storeVisible:false,//仓库弹框
		storeId:'',
		orgId:'',
	}
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.showStoreBox(true,rowData)}>
						<u>{'修改'}</u>
				</div>
				<div className={'tableLink'} title={'删除'} onClick={() => this.toDelStatus(true,rowData)}>
						<u>{'删除'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}

	//添加设备窗口显示
	showStoreBox = async (storeVisible,rowData)=>{
		if(rowData){
			await this.setState({storeVisible})
			this.setState({data: {outOrgId:rowData.outOrgId,outOrgName:rowData.outOrgName,storeRoomName:rowData.outStorageName,outStorageId:rowData.outStorageId}})
			await this.forms.onModalClose()
		}else{
			if(this.state.data){
				this.state.data.storeRoomName=''
				this.state.data.outStorageId=''
				await this.setState({storeVisible})
				this.setState({data: this.state.data})
				await this.forms.onModalClose()
			}else{
				return message.error('未选择公司')
			}
		}
	}
	addStoreData = async (value)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		let approvalData = await this.forms.onFinishNotReset()
		if(!approvalData) return null
		approvalData.operatorId=user.id
		approvalData.operatorName=user.realName
		console.log(approvalData)
		console.log(this.state.data)
		if(this.state.data.outStorageId){
			approvalData.outStorageId=this.state.data.outStorageId
			modifyTheStorage(approvalData).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("仓库管理", "修改仓库", null,approvalData)
						message.config("操作成功")
						this.showStoreBox(false)
					}else{
						this.showStoreBox(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable(this.state.tableParamenter.params);// 刷新表格
				}
			)
		}else{
			createStorage(approvalData).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("仓库管理", "添加仓库", null,approvalData)
						message.config("操作成功")
						this.showStoreBox(false)
					}else{
						this.showStoreBox(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable(this.state.tableParamenter.params);// 刷新表格
				}
			)
		}

		

};

	//删除
	toDelStatus = async (delVisible,rData)=>{
		if(rData){
			this.setState({delVisible,storeId: rData.outStorageId,orgId:rData.outOrgId})
		}else{
			this.setState({delVisible})
		}
		
	}
	delStoreData = async (value)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		delTheStorage({outStorageId:this.state.storeId,outOrgId:this.state.orgId,operatorId:user.id,operatorName:user.realName}).then(
				response => {
					if(response.data.data){
						funTraceInfo("仓库管理", "删除仓库", null,this.state.storeId)
						message.config("操作成功")
						this.toDelStatus(false)
					}else{
						this.toDelStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable(this.state.tableParamenter.params);// 刷新表格
				}
			)
			

	};
	//根据复选框获取权限
	onChange=(checkedValues)=> {
		this.setState({plainOptions: checkedValues})
	};
		// 选择职位获取用户
		getSelectChange = row => {
			let rowData1 = this.table.getSelectChange()
			this.state.tableThereParamenter.params.outPostId=row[0].id
			// this.table3.refreshTable(this.state.tableThereParamenter.params);
		  // this.table.refreshTable();// 刷新表格
		  	let ckArry=[]
			if(rowData1.length<1){
				message.error('请先选择需要配置的仓库')
			}else{
				getTheUsersByOutPostId({outPostId:row[0].id}).then(
					response=>{
							if(response.data.data){
								for(var i=0;i<response.data.data.length;i++)
								{
									
									ckArry.push({label:response.data.data[i].realName,value:response.data.data[i].id.toString(),disabled: false});
									
								}
								this.setState({checkOptions:ckArry})
							}else{
								this.setState({checkOptions:ckArry})
							}
						}
				)
				findUsersByOutStorageId({outStorageId: rowData1[0].outStorageId}).then(
					response => {
						let pArry=[]
						if(response.data.data){
							for(var i=0;i<response.data.data.length;i++)
							{
								pArry.push(response.data.data[i].outUserId.toString());
							}
						}
						
						this.setState({plainOptions:pArry})
		
					}
				)
			}
	
	  };
	//仓库授权
	saveStoreData = async (value)=>{
		let rowData1 = this.table.getSelectChange()
		let rowData2 = this.table2.getSelectChange()
		// let rowData3 = this.table3.getSelectChange()
		console.log(rowData1,rowData2,this.state.plainOptions)
		if(rowData1.length < 1 || rowData2.length < 1 || this.state.plainOptions < 1)
		{
			message.error('请选择需要授权的仓库，职位，用户')
		}else{
			createPostsByOutStorageId({outStorageId:rowData1[0].outStorageId,outPostId:rowData2[0].id}).then(
				response => {
					if(response.data.data){
						funTraceInfo("仓库管理", "仓库配置职位", null,rowData1)
						message.config("操作成功")
						createUsersByOutPostId({outStorageId:rowData1[0].outStorageId,outUserIds:this.state.plainOptions}).then(
							response => {
								if(response.data.data){
									funTraceInfo("仓库管理", "仓库配置用户", null,rowData1)
									message.config("操作成功")
									this.toDelStatus(false)
									window.location.reload()
								}else{
									this.toDelStatus(false)
									message.config('操作失败')
					
								}
								this.table.refreshTable();
								this.table2.refreshTable();
								this.setState({checkOptions:[]})
								// this.table3.refreshTable();
							}
						)
					}else{
						this.toDelStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable();
					this.table2.refreshTable();
					this.setState({checkOptions:[]})
					// this.table3.refreshTable();
				}
			)
			// let userArry=[]
			// for(var i=0;i<rowData3.length;i++)
			// {
			// 		userArry.push(rowData3[i].id)
			// }


		}
		
	}; 


	componentDidMount(){
		this.getTreeNode();
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data)
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTime=''
		params.addTimeStart=sTime;
		params.addTimeEnd=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
  getTreeNode = () => {//获取树节点
    this.setState({ loading: true });
    getAppOrganizationTree(this.state.responseInfo).then(
      reponse => {
       
        let { data } = reponse.data;
     
        let { responseInfo, tree } = this.state;
        responseInfo.data = data;
        tree.treeData = data
        this.setState({
          loading: false,
          refreshTable: false,
          totalCount: data.total,
          responseInfo: responseInfo,
          tree
        });
        message.success('查询成功',[0.5])
        // funTraceInfo("权限管理", "组织结构查询", null, tree)
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败',[0.5])
      }
    )
  };
    //展开/收起节点时触发
	onExpand = (expandedKeysValue: React.Key[]) => {
		this.setState({
		  expandedKeys: expandedKeysValue,
		  autoExpandParent: false
		});
	  };
  //树形
  getTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(value => {
      return {
        title: value?.nodeExtend?.name,
        key: value?.nodeExtend?.id,
        parent: value?.id,
        // dataIndex:value?.positions?.name,
        disabled: false,
        children: this.getTreeData(value?.children)
      }
    })
    return tree
  }
    //点击树节点触发
	onSelect = (selectedKeysValue: React.Key[], info: any) => {
   
		let childrens=info.node.children
	   
		let node = info.node.parent
	  
		let key = info.node.key
		let param = {
		  organizationId: key
		}
		let name=info.node.title
		this.setState({data: {outOrgId:key,outOrgName:name}})
		this.state.tableParamenter.params.outOrgId=key
		this.state.tableTwoParamenter.params.organizationId=key
		this.table.refreshTable(this.state.tableParamenter.params);
		this.table2.refreshTable(this.state.tableTwoParamenter.params);
		this.setState({checkOptions:[]})
		let rowData = this.table2.getSelectChange()
		// this.table3.refreshTable();
	  };


	

	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
					<h3 style={{marginBottom:'10px'}}>仓库配置</h3>
					<Button style={{position:'absolute', right:'120px',top:'25px'}} type="primary" onClick={() => this.showStoreBox(true)}>添加仓库</Button>
					<Button style={{position:'absolute', right:'20px',top:'25px'}} type="primary" onClick={() => this.saveStoreData()}>保存配置</Button>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff',position:'relative',height:'88%'}}>
					<div className={'storeBox'}>
						<Tree
						treeData={this.getTreeData(this.state.tree.treeData)}
						getTreeNode={this.getTreeNode}
						onExpand={this.onExpand}
						expandedKeys={this.state.expandedKeys}
						autoExpandParent={this.state.autoExpandParent}
						onCheck={this.onCheck}
						checkedKeys={this.state.checkedKeys}
						onSelect={this.onSelect}
						/>
					</div>
					<div className={'storeBox'}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getStoragesByOutOrgId}
						setTableValue={this.setTableValue}
						ref={e => this.table = e} 
					>
					</TableComponents>
					</div>
					<div className={'storeBox'}>
						<TableComponents
							paramenter={this.state.tableTwoParamenter}
							setData = {listAppPositionsByOrganizationId}
							getSelectChange={this.getSelectChange}	//选择事件
							setTableValue={this.setTableValue}
							ref={e => this.table2 = e} 
						>
						</TableComponents>
					</div>
					<div className={'storeBox storeCkList'}>
						<h4>用户选择</h4>
						{/* <TableComponents
							paramenter={this.state.tableThereParamenter}
							setData = {getTheUsersByOutPostId}
							setTableValue={this.setTableValue}
							ref={e => this.table3 = e} 
						>
						</TableComponents> */}

						<Checkbox.Group
							key={this.state.plainOptions}
							defaultValue={this.state.plainOptions} // 多选框的值
							style={{ width: '100%' }}
							onChange={this.onChange}    //多选框点击事件
							>
							{
								// 根据右框各个数据展示出来
								this.state.checkOptions.map(item => (
									<Tooltip title={item.name}>
									<Checkbox
										disabled={item.disabled}    // 状态控制
										key={item.value}               // id
										value={item.value.toString()}>
										<span>{item.label}</span>
									</Checkbox>
									</Tooltip>
								))
							}
						</Checkbox.Group>
					
					</div>	
				</div>
				<Modal
                        title={'设备详情'}
                        style={{ top: 0 }}
                        visible={this.state.storeVisible}
                        okText="确定"
						// footer={false}
                        onOk={() => this.addStoreData(true)}
                        onCancel={() => this.showStoreBox(false)}
                        width={800}
                        centered
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenter}
                        data={this.state.data}
                      />
                </Modal>
				<Modal
                        title='删除'
                        style={{ top: 0 }}
                        visible={this.state.delVisible}
                        okText="确定"
                        onOk={() => this.delStoreData(false)}           // 点击确定
                        onCancel={() => this.toDelStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认删除该仓库吗？</h4>
                        </div>
                	</Modal>
            </Layout>
        )
    }
}
