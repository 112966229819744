// 燃气报警器-历史检定记录
import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Select,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import {funTraceInfo} from '../../../services/common'

import {getVerificationRecordByAlarmId} from '../../../services/verifvication'
const { Option } = Select;
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {

				entrustNum:'委托单号',
				equipmentName: '设备名称',
				model: '型号',
				check: '核验员',
				manufacturers: '制造厂家',
				entrustUnit: '送检单位',
				verifierDate: '检定时间',
				state: '检定状态',
				edit: '操作',

				// verifier: '检验员',


			},
			// 弹出框
			headers: {
				entrustNum:'委托单号',
				equipmentName: '设备名称',
				model: '型号',
				check: '核验员',
				manufacturers: '制造厂家',
				entrustUnit: '送检单位',
				verifierDate: '检定时间',
				state: '检定状态',
				// verifier: '检验员',


			},
			params: {
				page:1,
				size:10,
				gasAlarmId:this.props.location.state?.id
			},
			// 是否绑定左侧区域
			bindArea: false
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'verificationRecordEntrustNum',
					str: '委托单号',
				}
			]
		},
		    	// 总条数
		totalCount: 0,
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
	};

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'下载'}
					 onClick={() => this.downloadStatus(true,rowData.id)}
				>
						<u>{'下载'}</u>
				</div>
				<div className={'tableLink'} title={'预览'}
					 onClick={() => this.previewStatus(true,rowData.id)}
				>
						<u>{'预览'}</u>
				</div>
				</>;
			case 'state':
				text = text*1;
				return text === 0 ? <span style={{color:'#44A072'}}>合格</span> : text === 1 ? <span style={{color:'#DF6A5B'}}>不合格</span>: '-';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}


	componentDidMount(){
		console.log(this.state.params)
	}


	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
			let params = this.state.tableParamenter.params;
			let formParams=this.table.getParams()
			Object.assign(params,value);
			params.page=formParams.page
			params.size=formParams.size
			let {tableParamenter} = this.state;
			tableParamenter.params = params;
			this.setState({tableParamenter});
			await this.table.refreshTable(params);

	};

// 初始化表格数据
getData = async () => {
	this.setState({})
	this.table.refreshTable();
  };
	//下载窗口
	downloadStatus=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus,downloadId:id});
	};
	download=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus})
		console.log(this.state.downloadId)
	};

	downloadOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadByRecordId?id='+alarmId)
	};
	downloadOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadBlankByRecordId')
	};

	//预览窗口
	previewStatus=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus,previewId:id});
	};
	preview=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus})
		console.log(this.state.previewId)
	};

	previewOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewByRecordId?id='+alarmId)
	};
	previewOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewBlankByRecordId')
	};


	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	   this.setState({params:params});
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>历史检定记录<Button style={{margin:'0 0 0 20px',float:'right'}} onClick={() => this.props.history.go(-1)}>返回</Button></h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getVerificationRecordByAlarmId}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title='下载'
                        style={{ top: 0 }}
                        visible={this.state.downloadVisible}
                        okText="确定"
                        onOk={() => this.downloadStatus(false)}           // 点击确定
                        onCancel={() => this.downloadStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.downloadOpen(this.state.downloadId,0,1,0)}>记录</span>
							<span onClick={() => this.downloadOpenNull(this.state.downloadId,0,1,1)}>模板</span>
                        </div>
                    </Modal>
					<Modal
                        title='预览'
                        style={{ top: 0 }}
                        visible={this.state.previewVisible}
                        okText="确定"
                        onOk={() => this.previewStatus(false)}           // 点击确定
                        onCancel={() => this.previewStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.previewOpen(this.state.previewId,0,1,0)}>记录</span>
							<span onClick={() => this.previewOpenNull(this.state.previewId,0,1,1)}>模板</span>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
