import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,FireOutlined} from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getSimInfoListInCondition,getManufacturerList,updateSim,batchInsertSim,removeSimCard,discardSimCard} from '../../../services/simCard'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "SIM卡申请管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				createTime: '添加时间',
				sim: 'SIM卡号',
				iccId:'ICCID号',
				location: '归属地',
				factoryName: '供应厂家',
				state: '状态',
				edit: '操作',
			},
			// 弹出框
			headers: {
				createTime: '添加时间',
				sim: 'SIM卡号',
				iccId:'ICCID号',
				location: '归属地',
				factoryName: '供应厂家',
				state: '状态',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [			
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'createTime',
					str: '添加时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'facId',
					str: '厂家',
					rules: TYPE_NAME.SELECT,
					disabled: this.props.location.state ? true : false,
					data:[],
				},
				{
					variableName: 'state',
					str: '状态',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'未使用',value:'未使用'},
						{key:'使用中',value:'使用中'},
						{key:'报废',value:'报废'},
						{key:'退回',value:'退回'},
						{key:'添加',value:'添加'},
					],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'sim',
					str: '卡号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'iccId',
					str: 'ICCID号',
					rules: TYPE_NAME.INPUT,
				},
			]
		},
		    	// 总条数
		totalCount: 0,
		editVisible:false, // 修改
		addVisible:false, // 添加
		delVisible:false, //删除
		disVisible:false, //删除
		delId:'',
		//修改
		editFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'sim',
					str: 'SIM卡号',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'iccId',
					str: 'ICCID',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'state',
					str: '状态',
					rules: TYPE_NAME.SELECT,
					data:[
						{key:'未使用',value:'未使用'},
						{key:'使用中',value:'使用中'},
						{key:'报废',value:'报废'},
						{key:'退回',value:'退回'},
						{key:'添加',value:'添加'},
					],
				},
			]
		},
		//添加
		addFormParamenter: {
			modal: true,
			number: 2,
            labelCol: 8,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'sim',
					str: 'SIM卡号',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				},
				{
					variableName: 'iccId',
					str: 'ICCID号',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				},
			]
		},
		//报废
		disFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 4,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'sim',
					str: 'SIM卡号',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				},
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editSimStatus(true,rowData)}>
						<u>{'修改'}</u>
				</div>
				<div className={'tableLink'} title={'删除'} onClick={() => this.toDelStatus(true,rowData)}>
						<u>{'删除'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		getManufacturerList().then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)
	}

	//修改
	editSimStatus = async (editVisible,rData)=>{
		await this.setState({editVisible})
		this.setState({data: rData})
		await this.forms.onModalClose()
	}
	editSimData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.forms.onFinishNotReset()
			if(!approvalData) return null
			approvalData.id=this.state.data.id
			approvalData.lastUserId=user.id
			approvalData.lastUserName=user.realName
			approvalData=[approvalData]
			updateSim(approvalData).then(
				response => {
					if(response.data.responseInfo.code==200){
						funTraceInfo("sim卡管理", "修改sim卡", null,approvalData)
						message.config("操作成功")
						this.editSimStatus(false)
						this.table.refreshTable();// 刷新表格
					}else{
						message.error(response.data.responseInfo.description)
						this.editSimStatus(false)
					}
					
				}
			)
			

	};


	//添加
	addSimStatus = async (addVisible)=>{
		await this.setState({addVisible,data:{}})
		await this.forms.onModalClose()
	}
	addSimData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.forms.onFinishNotReset()
			let simArry=[]
			let iccidArry=[]
			if(!approvalData) return null
			if(approvalData.sim){
				simArry=approvalData.sim.split('\n')
			}
			if(approvalData.iccId){
				iccidArry=approvalData.iccId.split('\n')
			}
			if(simArry.length!=iccidArry.length)
			{
				message.error("SIM号与ICCID号数量不一致")
				return
			}
			approvalData.sim=simArry
			approvalData.iccId=iccidArry
			approvalData.lastUserId=user.id
			approvalData.lastUserName=user.realName
			batchInsertSim(approvalData).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("sim卡管理", "添加sim卡", null,approvalData)
						message.config("操作成功")
						this.addSimStatus(false)
					}else{
						this.addSimStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable();// 刷新表格
				}
			)
			

	};


	//报废
	disSimStatus = async (disVisible)=>{
		await this.setState({disVisible,data:{}})
		await this.forms.onModalClose()
	}
	disSimData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.forms.onFinishNotReset()
			let simArry=[]
			if(!approvalData) return null
			if(approvalData.sim){
				simArry=approvalData.sim.split('\n')
			}
			approvalData.sim=simArry
			approvalData.lastUserId=user.id
			approvalData.lastUserName=user.realName
			discardSimCard(approvalData).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("sim卡管理", "报废sim卡", null,approvalData)
						message.config("操作成功")
						this.disSimStatus(false)
					}else{
						this.disSimStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable();// 刷新表格
				}
			)
			

	};


	//删除
	toDelStatus = async (delVisible,rData)=>{
		if(rData){
			this.setState({delVisible,delId: rData.id})
		}else{
			this.setState({delVisible})
		}
		
	}
	delSimData = async (value)=>{
		removeSimCard({simId:this.state.delId}).then(
				response => {
					if(response.data.data){
						message.config("操作成功")
						funTraceInfo("sim卡管理", "删除sim卡", null,this.state.delId)
						this.toDelStatus(false)
					}else{
						this.toDelStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable();// 刷新表格
				}
			)
			

	}; 


	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		let sTime=data.createTime ?moment(data.createTime[0]._d) : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTime=sTime;
		params.endTime=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	

	
 
	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
					<h3>SIM卡审批管理</h3>
					<div style={{position:'relative'}}>
								<div className={'topFormBox'} style={{width:'90%'}}>
								<FormsTwo 
										ref={e => this.forms = e} 
										formParamenter={this.state.topFormItem} 
										data={this.state.searchGetData}
								/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
					</div>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addSimStatus(true)}><PlusSquareOutlined />添加</span>
					<span className={'addLink'} onClick={() => this.disSimStatus(true)}><FireOutlined />报废</span>
				</div>	
				<div style={{padding:'0 0 0 0',background:'#fff'}}>			
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getSimInfoListInCondition}
						setTableValue={this.setTableValue}
						ref={e => this.table = e} 
					>
					</TableComponents>
				</div>
				<Modal
                        title={'修改'}
                        style={{ top: 0 }}
                        visible={this.state.editVisible}
                        okText="确定"
                        onOk={() => this.editSimData(true)}           // 点击确定
                        onCancel={() => this.editSimStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.editFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>
					<Modal
                        title={'添加SIM卡'}
                        style={{ top: 0 }}
                        visible={this.state.addVisible}
                        okText="确定"
                        onOk={() => this.addSimData(true)}           // 点击确定
                        onCancel={() => this.addSimStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div className={'trBox'}>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.addFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>
					<Modal
                        title={'报废SIM卡'}
                        style={{ top: 0 }}
                        visible={this.state.disVisible}
                        okText="确定"
                        onOk={() => this.disSimData(true)}           // 点击确定
                        onCancel={() => this.disSimStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'trBox'}>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.disFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>

					

					<Modal
                        title='删除'
                        style={{ top: 0 }}
                        visible={this.state.delVisible}
                        okText="确定"
                        onOk={() => this.delSimData(false)}           // 点击确定
                        onCancel={() => this.toDelStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认删除该SIM卡吗？</h4>
                        </div>
                	</Modal>

					
            </Layout>
        )
    }
}
