import React, { Component } from 'react'
import { Layout, Button, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import {TYPE_NAME} from '../../../components/Const'
import FormsText from '../../../components/common/forms/FormsText'
import TableComponents from '../../../components/common/tables/TableComponents'
import Modals from '../../../components/common/modals/Modals'

import { pageLikeMpName, addHistoryMeter } from '../../../services/meter'
import { endReplaceMeter } from '../../../services/measurePoint'
import {funTraceInfo} from '../../../services/common'
export default class MeterInstall extends Component {

    state = {

		isModalVisible: false,
        // 表单参数
		formParamenterText: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: {
				steelNum: '表钢号',
				state: '表具状态',
				lastCommunicationTime: '最后通信时间',
				dumpEnergy: '剩余电量',
				communication: '表具通讯号',
				value: '表盘示数',
				temperature: '温度',
				type: '表具类型',
				modelName: '表具型号',
				dataTime: '数据时间',
				pressure: '压力',
				alarm: '报警信息',
				communicateStatus: '通信状态',
				signals: '信号',
				installSite: '安装位置',
			}
		},
		// 默认数据
		data: {},

		// 表格参数
		tableParamenter: {
			// 下拉框长度
			selectWidth: '6.5vw',
			// 是否绑定左侧区域
			bindArea: true,
			// 表格行是否可选
            rowSelection: true,
			// 表头：变量和变量解释
			header: {
				id: '计量点代码',
				purpose: '用气用途',
				properties: '用气性质',
				gasEquipment: '用气设备',
				name: '计量点名称',
				customerId: '客户号',
				contractNumber: '合同号',
				steelNum: '表钢号'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: TYPE_NAME.INPUT,
						variableName: 'name',
						str: '计量点名称'
					},
					
				]
			},
			params: {
				page: 1,
				size: 10,
				areaId: JSON.parse(localStorage.getItem('menus'))?.[0]
			},
		},
		// 选中行时保存的数据
		rowData: [],
		// 弹出框输入框
		modalParamenter: [],
    }

	install = () => {
		let rowData = this.table.getSelectChange()
		if(rowData?.[0]?.steelNum) return message.info('计量点已安装表,无法再次安装')
		let accData = rowData?.[0]?.accData
		let modalParamenter = accData?.map(value => {
			return {
				variableName: value.name+"+"+value.meterItemId,
				str: value.name,
				rules: 'input'
			}
		})
		// modalParamenter.length < 1 ? message.error('该计量点下不存在数据项') : this.setState({ isModalVisible: true, modalParamenter })
		this.setState({ isModalVisible: true, modalParamenter })
	}

	getFormValue = value => {
		let meterId = this.props.location.state?.meterId
		let rowData = this.table.getSelectChange()
		let mpId = rowData[0]?.id
		let keys = Object.keys(value)
		// 获取表单数据，key格式为 数据项名称 + 数据项id
		let data = keys.map(v => {
			return {
				name: v.split('+')[0],
				value: value[v],
				meterId: meterId,
				mpId: mpId,
				type:1,
				meterItemId: v.split('+')[1]
			}
		})
		let historyMeter = {
			meterId: meterId,
			mpId: mpId,
			bottomData: data
		}
		// 只有底表数据添加成功，才能进行装表操作
		addHistoryMeter(historyMeter).then(
			response => {
				if(response.data?.data){
					
				}
			}
		)
		endReplaceMeter({mpId, meterId}).then(
			response => {
				if(response.data.responseInfo.code === 200) {
					this.table.getData()
					funTraceInfo("表具信息管理", "装表业务 -旧", null,meterId)
					message.success('安装成功')
				}
				else message.error('安装失败')
			}
		)
	}

	getSelectChange = data => this.setState({rowData: data})

    render() {

		const data = this.props.location.state ?? []
		
        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
				<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>表具安装</span>
				</div>
				<div className={'div_from insert_table'} style={{padding: '1vh 1vw'}}>
					<FormsText 
						record = {data}
						header = {this.state.formParamenterText.parameter}
						rowLen = {4}
					/>
                </div>
				<div className={'divider'}></div>
				<div className={'item_body_number_back tables'}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {pageLikeMpName}
						getSelectChange = {this.getSelectChange}
						ref={e => this.table = e} 
					>
						<Button type="primary" onClick={this.install} disabled={this.state.rowData.length === 0}>安装</Button>
					</TableComponents>
				</div>
				<Modals 
					isModalVisible = {this.state.isModalVisible} 
					onModalClose={() => this.setState({ isModalVisible: !this.state.isModalVisible })} 
					getFormValue={this.getFormValue} 
					modalParamenter={{
						// 表单参数
						modal: true,
						number: 2,
						layout: "vertical", 
						title: '新增表具底数',
						width: 35,
						parameter: this.state.modalParamenter
					}}
				/>

            </Layout>
        )
    }
}
