import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Input, Button} from 'antd'
import {getMetersBySteelNum, getItemByDevId, selectDevInfoAttr, updateMeterStencilNum} from '../../../services/meter'
import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getAllManufacturerAndModel,abandon,startAbandon} from '../../../services/report'
import moment from "moment";

export default class MeterManagement extends Component {

	state = {
		radioDisplay:null,
		// 表格参数
		tableParamenter: {
			manageName: "表具信息管理",
			columnMaxWith: 500,
			// 是否绑定左侧区域
			bindArea: true,
			// 表格行是否可选
            rowSelection: true,
			width: '83.7vw',
			localStorageName: 'meter',
			fileName: '表具信息管理',
			sorters:['type'],
			exportUrl: "/web/reportExportCtrl/getMetersBySteelNum",
			// 表头：变量和变量解释
			header: {
				steelNum: '表钢号',
				installSite: '安装位置',
				modelName: '表具型号',
				type: '表具类型',
				state: '表具状态',
				customerName: '客户名称',
				createTime: '创建时间',
				manufactureTime: '生产时间',
				dataTime: '数据时间',
				// instantaneousFlow: '瞬时流量',
				// cumulativeFlow: '累计流量',
				// pressure: '压力',
				// temperature: '温度',
				// dumpEnergy: '电量',
				edit:'操作',
			},
			// 表头：变量和变量解释
			headers: {
				id: '表具id',
				steelNum: '表钢号',
				installSite: '安装位置',
				communication: '表具通讯号',
				modelName: '表具型号',
				type: '表具类型',
				state: '表具状态',
				customerName: '客户名称',
				simCard: 'sim卡号'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'manufacture',
						str: '表具厂商',
						data: []
					},
					{
						type: TYPE_NAME.SELECT_TAGS,
						variableName: 'steelNums',
						str: '表钢号',
						initData: JSON.parse(localStorage.getItem('meter'))?.steelNum
					},
					{
						type: TYPE_NAME.SELECT_TAGS,
						variableName: 'simCards',
						str: '通讯号',
						initData: JSON.parse(localStorage.getItem('meter'))?.simCard
					},
				],
				// 将指定的参数为空转null
				variableName:['steelNum','simCard','steelNums','simCards'],
				// 登入清空指定参数
				variableValue:['steelNums','simCards'],
				// onchange:(data)=>{this.getData(data)},	// 查询参数
				// pageChange:(data)=>{this.getData(data)},// 分页参数
				// 处理后台请求参数
				changeValue:(data)=>{
					var params={};
					Object.assign(params,data);
					// 表刚号
					if(data?.steelNums){

						if(data?.steelNums.length>1){
							params.steelNum=null;
						}else if(data?.steelNums.length===1){
							params.steelNums=null;
							params.steelNum=data.steelNums[0];
						}else{
							params.steelNums=null;
							params.steelNum=null;
						}
					}
					// 通信号
					if(data?.simCards){
						if(data?.simCards.length>1){
							params.simCard=null;
						}else if(data?.simCards.length===1){
							params.simCards=null;
							params.simCard=data.simCards[0]
						}else{
							params.simCards=null;
							params.simCard=null;
						}
					}
					return params
				},		// 分页参数
			},
			drawersItem: {}
		},
		abandonVisible:false, //作废窗口开关器-1
		abandonVisible_1:false,
		meterId:'',

	};


	getData=(data)=>{
		var params={};
		Object.assign(params,data);
		// 表刚号
		if(data?.steelNums){

			if(data?.steelNums.length>1){
				params.steelNum=null;
			}else if(data?.steelNums.length===1){
				params.steelNums=null;
				params.steelNum=data.steelNums[0];
			}else{
				params.steelNums=null;
				params.steelNum=null;
			}

		}
		// 通信号
		if(data?.simCards){
			if(data?.simCards.length>1){
				params.simCard=null;
			}else if(data?.simCards.length===1){
				params.simCards=null;
				params.simCard=data.simCards[0]
			}else{
				params.simCards=null;
				params.simCard=null;
			}
		}
		this.table.getData(params);
	};

	componentDidMount(){
		// 获取查询参数
		let rowData = this.table.getParams();

		// 按钮控制
		this.setState({radioDisplay:'none'});
		JSON.parse(sessionStorage.getItem("menuAuthories")).map(one=>{
			if(one.name==='表具信息管理'){
				if(one?.children.length!==0){
					one.children.map(two=>{
						if(two.name==='修改表刚号'){
							this.setState({radioDisplay:''});
						}
					})
				}
			}
		});
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state;
				let {data} = response.data;
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});
				this.setState({tableParamenter})
			}
		)
	};

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'manufactureTime':
				text =text?moment(text).format('YYYY-MM-DD'):'-';
				return <>
					<span>{text}</span>
				</>
				break;
			case 'steelNum':
				return <NavLink to={{
					pathname: "/meter/meterBusiness",
					state: {
						customerId: rowData.customerId,
						meterId: rowData.id,
						mpId: rowData.mpId,
						steelNum: rowData.steelNum,
						devId: rowData.devId,
					}
				}}>
					<div className={'table_overflow'} title={text}>
						<u>{text}</u>
					</div>
				</NavLink>;
			case 'edit':
				return <>
				<div className={'tableLink'} title={'手工采集'} onClick={() => this.collection(rowData)}>
						<u>{'手工采集'}</u>
				</div>
				<div className={'tableLink'} title={'废弃'} onClick={() => this.abandonShow(true,rowData.id,rowData)}>
				<u>{'废弃'}</u>
				</div>
				</>;
			// case 'type':
			// 	text = text*1
			// 	return text === 1 ? <Tag color={'geekblue'}>备用表</Tag> : text === 2 ? <Tag color={'green'}>用户表</Tag> : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	// 表具废弃
	abandonShow=(abandonVisible,id,rowData)=>{
		this.setState({abandonVisible,meterId:id});
	};
	toAbandon=(abandonVisible)=>{
		// 新-废弃功能
		startAbandon({meterId: this.state.meterId}).then(
				response => {
					if(response.data.data===true){
						this.setState({abandonVisible});
						// funTraceInfo("表具信息管理", "表具废弃-新", null,this.state.meterId)
						message.success('操作成功');
						this.table.refreshTable();
					}else{
						this.setState({abandonVisible});
						message.error('该状态下不支持此操作！');
						this.table.refreshTable();
					}
				}
			)
		// 旧-废弃功能
		// abandon({meterId: this.state.meterId}).then(
		// 		response => {
		// 			console.log(response)
		// 			if(response.data.data==true){
		// 				this.setState({abandonVisible})
		// 				message.success('操作成功')
		// 				this.table.refreshTable();
		// 			}else{
		// 				this.setState({abandonVisible})
		// 				message.error('该状态下不支持此操作！')
		// 				this.table.refreshTable();
		// 			}
		//
		// 		}
		// 	)
	};



	updateMeter = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		this.props.history.push({
			pathname: '/meter/meterOperating',
			state: rowData[0]
		})
	}

	collection = (rowData) => {
		this.props.history.push({
			pathname: '/meter/MenterCollection',
			state: rowData
		})
	};

	// 必须为异步: 因为弹出框出现时，页面已经更新完毕了
	onRowData = async (record, drawersHeader) => {
		await getItemByDevId({devId: record?.devId}).then(
			response => {
				let { tableParamenter } = this.state
				let {data = []} = response?.data?.data
				let onRowData = record
				data?.forEach(({value,name}) => {
					onRowData[name] = value
					drawersHeader[name] = name
				})
				tableParamenter.drawersItem = {
					onRowData: onRowData,
					headers: drawersHeader
				}
				this.setState({tableParamenter})
			}
		  )
	};

	upDataSteem=()=>{
		let rowData = this.table.getSelectChange();
		if(rowData.length < 1) return message.error('未选择数据');
		this.setState({originalSteem:rowData[0].steelNum});
		this.setState({abandonVisible_1:true});
	};
	getDevInfo=()=>{
		this.setState({MBJuder:true});
		var newSteem =this.state.newSteem;
		if(!newSteem){
			this.setState({MBJuder:false});
			return message.info('新表钢号不能为空');
		}
		let rowData = this.table.getSelectChange();
		selectDevInfoAttr({devId:rowData[0].devId}).then(
			response=>{
				if(response?.data?.data){
					let {data} = response.data;
					if(data?.attr.length){
						// 修改数据
						updateMeterStencilNum({
							operator:JSON.parse(sessionStorage.getItem("user")).id,
							itemId:data?.attr[0].id,
							meterId:rowData[0].id,
							stencilNum:newSteem
						}).then(
							response_1=>{
								if(response_1?.data){
									if(response_1.data.responseInfo.code===200){
										this.table.refreshTable();
										this.setState({MBJuder:false,abandonVisible_1:false});
										return message.info(response_1.data.responseInfo.description);
									}
								}
								message.info('修改失败,请重新提交');
							}
						)
					}else{
						message.info('未能获取表钢号数据,请重新提交');
					}
				}
				this.setState({MBJuder:false});
			})
	};

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: 'red'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getMetersBySteelNum}
					setTableValue={this.setTableValue}
					onRowData={this.onRowData}
					ref={e => this.table = e}
				>
					<Radio.Group defaultValue="addClient" buttonStyle="solid">
						<NavLink to="/meter/meterOperating">
							<Radio.Button value="addClient">新增表具</Radio.Button>
						</NavLink>

						<Radio.Button value="updateClient" onClick={this.updateMeter}>修改表具</Radio.Button>

						<Radio.Button  style={{display:this.state.radioDisplay}}
									   value="updateSteem"
									   onClick={()=>{
									   	this.upDataSteem();

									   }}
						>
							修改表钢号
						</Radio.Button>
						{/* <Radio.Button value="collection" onClick={this.collection}>手工采集</Radio.Button> */}
					</Radio.Group>
					{/* <Button type="primary" onClick={this.showModal}>计量点关系图</Button> */}
				</TableComponents>



				<Modal centered title="计量点关系图" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
					<p>计量点关系图</p>
				</Modal>

				<Modal
                        title='表具废弃'
                        style={{ top: 0 }}
                        visible={this.state.abandonVisible}
                        okText="确定"
                        onOk={() => this.toAbandon(false)}           // 点击确定
                        onCancel={() => this.abandonShow(false)}       // 点击取消
                        width={450}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确定要废弃该表具吗？确定后系统无法恢复该表具信息。</h4>
                        </div>
                </Modal>
				<Modal
					maskClosable={false}
					title='修改表钢号'
					style={{ top: 0 }}
					visible={this.state.abandonVisible_1}
					okText="确定"
					// onOk={() => this.getDevInfo()}           						// 点击确定
					onCancel={() => this.setState({abandonVisible_1:false})} 	// 点击取消
					// 自定义按钮
					footer={[
						<Button key="back"
								onClick={() => this.setState({abandonVisible_1:false})}
						>取消</Button>,
						<Button type="primary"
								disabled={this.state?.MBJuder}
								loading={this.state?.MBJuder}
								onClick={() =>  this.getDevInfo()}
						>提交</Button>
					]}


					width={450}
					centered
				>
					<div  style={{width:'100%',height:'100px'}}>
						<div style={{width:'100%',height:'50px'}}>
							<div style={{width:'20%',textAlign:'right',padding:'5px',float:'left'}}>
								<span>原表钢号:</span>
							</div>
							<Input disabled  style={{width:'70%',float:'left'}} value={this.state.originalSteem}/>
						</div>
						<div style={{width:'100%',height:'50px'}}>
							<div style={{width:'20%',textAlign:'right',padding:'5px',float:'left'}}>
								<span>新表钢号:</span>
							</div>
							<Input
								style={{width:'70%', float:'left'}}
								value={this.state.newSteem}
								onChange={(text)=>{
									this.setState({newSteem:text.target.value})
								}}
							/>
						</div>
					</div>

				</Modal>
            </Layout>
        )
    }
}
