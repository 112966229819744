import React, { Component } from 'react'
import {Layout, Select, Form, Tag, Button, Modal, Upload, Image, Radio, message, Popconfirm, Table} from 'antd'
import TableComponents from '../../../components/common/tables/TableComponents'
import {pageCusPropertiesList, queryGasBurner, querySecurityAssessment, removeGasBurner} from "../../../services/meter";
import {FORM_ITEM, TYPE_NAME} from "../../../components/Const";
import {NavLink} from "react-router-dom";
import {
    queryHandheldTerminal, registerHandheldTerminal,
    removeHandheldTerminal,
    saveHandheldTerminal,
    updateHandheldTerminal
} from "../../../services/equipment";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {listDeviceModelByDeviceClassId} from "../../../services/qrCode";
import {getPageCustomerName} from "../../../services/customer";
import FormsText from "../../../components/common/forms/FormsText";
import moment from "moment";

const { Option } = Select;
const FormItem = Form.Item;

export default class handheldDevices extends Component {
    state = {
        // 表格参数
        tableParamenter: {
            manageName: "客户配置",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'customer',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '客户配置',

            header: {
                modelName: '终端型号',
                num: '终端编号',
                communicationNum: '通信号',
                manufacturer: '供应商',
                status: '状态',
                createTime: '添加时间',
                customerName: '客户',
                // state: '电池电量',
                // state: '通信时间',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [

                    {
                        type: TYPE_NAME.SELECT,
                        variableName: 'status',
                        str: '状态',
                        data:[
                            {key:'未激活',value:'未激活'},
                            {key:'已激活',value:'已激活'},
                        ],
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'num',
                        str: '终端编号',
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                    {
                        type: FORM_ITEM.RANGE_PICKER,
                        variableName: 'time',
                        str: '添加时间',
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                ],

                onchange:(params)=>{
                    this.tableOnchange(params);
                }
            },
        },


        // 表单参数
        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [

                {variableName: 'modelName',
                    str: '终端型号',
                    fieldNames:{label: 'devModelName', value: 'devModelId', children: 'children'},
                    rules: FORM_ITEM.AutoComplete,
                    disabled: false,
                    onChange: (name,dataS)=>{
                        var essentialData = this.state.essentialData;
                        // 记录型号id
                        essentialData.modelId=dataS?.key??null;
                        if(dataS?.key){
                            this.state.formParamenter.parameter[0].data.map(one=>{

                                if(one?.key.toString()===dataS?.key.toString()){
                                    this.forms.setFieldsValue('manufacturer',one?.manufacture??null);
                                }
                            });
                        }else {
                            this.forms.setFieldsValue('manufacturer',null);
                        }
                        this.setState({essentialData});
                    },
                    data:[]
                },
                {variableName: 'num', str: '终端编号', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'manufacturer', str: '供应商', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'communicationNum', str: '通讯号', rules: TYPE_NAME.NOT_BLANK},
            ]
        },

        formType:0,
        visible_1:false,
        visible_2:false,
        essentialData:{modelId:null},

    //    -------------------

        // 表单参数
        parameter: {
            num: '终端编号',
            modelName: '终端型号',
            startTime: '供应商',
            communicationNum: '通信号',
            status: '状态',
            createTime: '添加时间',
        },
        parameter_data:{},
        // 激活弹窗-左-表格参数
        cusTableParamenter: {
            manageName: "客户关系管理系统",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: true,
            // 获取表格参数数据
            localStorageName: 'customer',
            height:350,
            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系人电话',
                detailAddress: '详细地址',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {type: TYPE_NAME.NAME, variableName: 'name', str: '客户名称', initData: JSON.parse(localStorage.getItem('customer'))?.name},
                    {type: TYPE_NAME.NAME, variableName: 'principalPhone', str: '手机号', initData: JSON.parse(localStorage.getItem('customer'))?.principalPhone}
                ]
            },
            params:{
                page:1,
                size:2
            }
        },
        // 激活弹窗-右
        right_tableData: [
            {title: '终端型号', dataIndex: 'modelName', key: 'modelName'},
            {title: '终端编号', dataIndex: 'num', key: 'num'},
            {title: '最后一次通讯时间', dataIndex: 'communicateTime', key: 'communicateTime',
                render:(text,rowData)=>{
                var title='-';
                if(rowData?.dataItems){
                    if(rowData?.dataItems.length!==0){
                        title= rowData?.dataItems[0]?.communicateTime?rowData?.dataItems[0]?.communicateTime:'-'
                    }
                }
                return(
                    <span>{title}</span>
                );
            }},
        ],
        right_tableData_data:[],
        right_tableData_param:{
            page:1,
            size:100,
        },

    };



    // 表格查询条件
    tableOnchange=(params)=>{

        // // startT
        var param={
            status:params.status??null,
            num:params.num??null,
            startTime:params.time?moment(params.time[0]).format('YYYY-MM-DD 00:00:00'):null,
            endTime:params.time?moment(params.time[1]).format('YYYY-MM-DD 23:59:59'):null,
            page: 1,
            size: 13
        };
        this.table.getData(param)
    };

    // 表格内容修饰
    setTableValue = (value,text,rowData) => {
        switch (value) {

            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async ()=> {
                             this.listDeviceModelByDeviceClassId();
                             await this.setState({visible_1:true,essentialData:rowData,formType:1});
                             this.forms.onModalClose();
                         }}
                    >
                        <u >{'修改'}</u>
                    </div>


                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm
                            title="是否确定删除"
                            description="是否确定删除?"
                            onConfirm={()=>{

                                this.removeHandheldTerminal(rowData.id)
                            }}
                            // onCancel={cancel}
                            okText="是"
                            cancelText="否"
                        >
                            <u>{'删除'}</u>
                        </Popconfirm>

                    </div>
                    {rowData.status==='未激活'?
                        <div className={'tableLink'} title={'未激活'}
                             onClick={async ()=> {await this.setState({visible_2:true,parameter_data:rowData});}}
                        >
                            <u>{'激活'}</u>
                        </div>
                        :
                        <div className={'tableNoLink'} title={'激活'}>
                            <u>{'激活'}</u>
                        </div>
                    }

                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };
    // 激活窗口表格样式
    setTableValue_1 = (value,text,rowData) => {
        switch (value) {
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'state':
                text = text*1;
                return text === 1 ? '正式' : text === 2 ? '非正式' : '';

            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 根据设备类型id查询设备型号信息
    listDeviceModelByDeviceClassId=()=>{
        // 获取控制器型号数据10201
        listDeviceModelByDeviceClassId({deviceClassId : '10201'}).then(
            response=>{
                if(response?.data?.data){
                    var formParamenter = this.state.formParamenter;
                    formParamenter.parameter[0].data=[];
                    response.data.data.forEach(one =>{
                        var parameter={
                            classId:one.classId,
                            key:one.devModelId,
                            value:one.devModelName,
                            manufacture:one.manufacture,
                            remark:one.remark,
                            searchName:one.searchName,
                            thresholdValue:one.thresholdValue,
                            state:one.state
                        };
                        formParamenter.parameter[0].data.push(parameter);
                    });

                    this.setState({formParamenter:formParamenter});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });
    };

    // 保存手持终端
    saveHandheldTerminal=async ()=>{
        var params = await this.forms.onFinish();
        if(!params){ return false;}
        var essentialData = this.state.essentialData;
        if(essentialData?.modelId){
            params.modelId=essentialData?.modelId;
        }
        this.setState({visible_1:false});
        saveHandheldTerminal(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('新增成功');
                    this.table.refreshTable();
                }else{
                    message.info('新增失败')
                }
            }
        ).catch(e=>{message.info('新增异常')})
    };
    // 修改手持终端
    updateHandheldTerminal=async ()=>{
        var params = await this.forms.onFinish();
        if(!params){ return false;}
        var essentialData = this.state.essentialData;
        if(essentialData?.modelId){
            params.modelId=essentialData?.modelId;
        }
        if(!essentialData?.id){
            message.info('修改异常，未获得id')
        }
        params.id=essentialData.id;
        this.setState({visible_1:false});
        updateHandheldTerminal(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('修改成功');
                    this.table.refreshTable();
                }else{
                    message.info('修改失败')
                }
            }
        ).catch(e=>{message.info('修改异常')})
    };
    // 删除手持终端
    removeHandheldTerminal= (id)=>{
        removeHandheldTerminal({idList:id}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功');
                    this.table.refreshTable();
                }else{
                    message.info('删除失败')
                }
            }
        ).catch(e=>{message.info('删除异常')})
    };


    // 手动获取数据
    getData=(params)=>{
        var right_tableData_param=this.state.right_tableData_param;
        queryHandheldTerminal(params??right_tableData_param).then(
            response=>{
                var right_tableData_data=[...this.state.right_tableData_data];
                if(response?.data?.data){
                    console.log(response.data);
                    right_tableData_data=response.data.data;

                }else{
                    right_tableData_data=[];
                }
                this.setState({right_tableData_data:right_tableData_data,tableLocale:false})
            }
        ).catch(e=>{   this.setState({right_tableData_data:[],tableLocale:false})})
    };
    // 激活
    registerHandheldTerminal=()=>{
        var getSelectChange = this.table1.getSelectChange();
        if(getSelectChange.length===0)return message.info('请选择客户');

        var params={
            "customerId": getSelectChange[0].id,
            "customerName": getSelectChange[0].name,
            "htIdList": []
        };
        console.log(this.state.parameter_data);
        params.htIdList.push(this.state.parameter_data.id);
        registerHandheldTerminal(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('激活成功');
                    this.table.refreshTable();
                }else{
                    message.info('激活失败')
                }
            }
        ).catch(e=>{message.info('激活异常')})
    };



    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={queryHandheldTerminal}
                    setTableValue={this.setTableValue}// 设置表格显示
                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addClient" buttonStyle="solid">
                        <Radio.Button value="addClient"
                                      onClick={async ()=>{
                                          this.listDeviceModelByDeviceClassId();
                                          await this.setState({visible_1:true,essentialData:{},formType:0});
                                          this.forms.onModalClose();
                                      }}
                        >
                            新增手持
                        </Radio.Button>
                    </Radio.Group>
                </TableComponents>

                {/*------------------------------------------------------激活窗口----------------------------------*/}
                <Modal
                    title={"激活"}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_2}
                    okText="确定"
                    onOk={() => this.setState({visible_2:false})}          	// 点击确定
                    onCancel={() => this.setState({visible_2:false})}	// 点击取消

                    footer={[
                        <Button key="back" onClick={() => {this.setState({visible_2:false})}}>取消</Button>,
                        <Button type="primary" onClick={() => {
                            this.registerHandheldTerminal();
                            this.setState({visible_2:false});
                        }}>激活</Button>
                    ]}
                    width={1500}
                    centered
                >
                    <div style={{width:'100%',height:'600px'}}>

                        {/*顶部*/}
                        <div style={{width:'100%',height:'100px'}}>
                            <FormsText record = {this.state.parameter_data} header = {this.state.parameter} rowLen = {4}/>
                        </div>
                        <div style={{width:'100%',height:'500px'}}>
                            <div style={{width:'65%',height:'100%',float:'left'}}>
                                <div style={{width:'100%',height:'100%',  border:' 1px #b4b4b5 solid',padding:'5px'}}>
                                    <span style={{ fontWeight: 'bold'}}>请选择客户</span>
                                    <TableComponents
                                        paramenter={this.state.cusTableParamenter}
                                        setData={getPageCustomerName}
                                        setTableValue={this.setTableValue_1}
                                        // 选择行-运行
                                        getSelectChange={(rowData)=>{
                                            if(!rowData[0]?.id){return message.info('未获取当前用户id')}
                                            var right_tableData_param = this.state.right_tableData_param;
                                            right_tableData_param.customerId=rowData[0].id;
                                            this.setState({tableLocale:true});
                                            this.getData(right_tableData_param);
                                        }}
                                        ref={e => this.table1 = e}
                                    />
                                </div>
                            </div>
                            {/*右*/}
                            <div style={{width:'35%',height:'100%',float:'left'}}>
                                <div style={{width:'100%',height:'100%',  border:' 1px #b4b4b5 solid',padding:'5px'}}>
                                    <span style={{ fontWeight: 'bold'}}>已绑定手持终端</span>
                                    <div style={{height:'92%',overflow: 'auto'}}>
                                        <Table
                                            columns={this.state.right_tableData}
                                            dataSource={this.state.right_tableData_data}
                                            rowKey={"id"}
                                            bordered
                                            loading={this.state.tableLocale}
                                            locale={{emptyText: '请选择客户'}}
                                            pagination={false}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/*-----------------------------------------------添加和修改窗口----------------------------------------*/}
                <Modal
                    title={this.state.formType===0?"添加手持终端":'修改手持终端'}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_1}
                    okText="确定"
                    onOk={() => {
                        this.state.formType===0?
                            this.saveHandheldTerminal()
                            :
                            this.updateHandheldTerminal()

                    }}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={500}
                    centered
                >
                    <div style={{width:'100%',height:'200px'}}>
                        <div style={{width:'90%',height:'50px'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.formParamenter}
                                data={this.state.essentialData}
                            />
                        </div>
                    </div>
                </Modal>

            </Layout>
        )
    }
}
