import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getCommentsByPcAdmin,getQrcodeDetail,addQrcode,replyCommentByParentId} from '../../../services/IKit'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				title: '标题',
				comment:'评论内容',
				replyState: '回复状态',
				useName:'评论者',
				createTime: '提交时间',
				replyContent:'回复内容',
				respondentName:'回复者',
				replyTime:'回复时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				title: '标题',
				comment:'评论内容',
				replyState: '回复状态',
				useName:'评论者',
				createTime: '提交时间',
				replyContent:'回复内容',
				respondentName:'回复者',
				replyTime:'回复时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'title',
					str: '标题'
				}

			]
		},
		    	// 总条数
		totalCount: 0,
		devVisible:false, // 小窗口开关器-1
		parentId:'',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 4,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'respondentName',
					str: '回复者',
					disabled: true,
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'replyContent',
					str: '回复内容',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				}
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				text = rowData.replyState;
				return <>
				{text=='未回复' ?<div className={'tableLink'} title={'回复'} onClick={() => this.editReplyStatus(true,rowData.id)}>
						<u>{'回复'}</u>
				</div>:
				<div className={'tableNoLink'} title={'回复'}>
					<u>{'回复'}</u>
				</div>}
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	//添加设备窗口显示
	addReplyStatus = async (devVisible)=>{
		await this.setState({devVisible,data:{},parentId:''})
		await this.forms.onModalClose()
    }
	editReplyStatus=(devVisible,id)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		this.setState({devVisible:devVisible,parentId:id,data:{respondentName:user.realName,respondentId:user.id}});
    }

	addReplyData = async (value)=>{
			let devData = await this.forms.onFinishNotReset()
			if(!devData) return null
			devData.parentId=this.state.parentId
			devData.respondentId=this.state.data.respondentId
			replyCommentByParentId(devData).then(data=>{
				if(data.data.data==true){
					// funTraceInfo("IKit系统管理", "回复", null,devData)
					message.config("修改成功")
					this.addReplyStatus(false)
				}else{
					this.addReplyStatus(false)
					message.config('修改失败')

				}
				this.table.refreshTable();// 刷新表格
			});

	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>评论管理</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				{/* <div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addQrcodeStatus(true)}><PlusSquareOutlined />新增</span>
				</div>	 */}
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getCommentsByPcAdmin}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addReplyData(true)}           // 点击确定
                        onCancel={() => this.addReplyStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div style={{padding:'30px 0'}}>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
