import ajax from './ajax'

// 多条件分页查询设备信息-1正常模式-2聚合模式,当前用于正常模式-旧
export const getMeterWarningInfoByCondition = data => ajax('/web/MeterEarlyWarning/getMeterWarningInfoByCondition', data, 'POST');

// 多条件分页查询设备信息-1正常模式-2聚合模式,当前用于正常模式-新
export const getWarningInfoByCondition = (data) => ajax('/measurepoint/earlyWarning/getWarningInfoByCondition', (data), 'POST');



// 模糊查询预警配置
export const getAlertConfigurationByCondition = data => ajax('/web/MeterEarlyWarning/getAlertConfigurationByCondition', data, 'POST');
// 模糊查询预警配置
export const getAlertConfigurationByConditionNew = data => ajax('/measurepoint/earlyWarning/getAlertConfigurationByCondition', data, 'POST');

// 创建表具预警配置
export const createMeterWarningConfiguration = data => ajax('/web/MeterEarlyWarning/createMeterWarningConfiguration', data, 'POST');
// 创建表具预警配置
export const createMeterWarningConfigurationNew = data => ajax('/measurepoint/earlyWarning/createMeterWarningConfiguration', data, 'POST');

// 判断设备是否存在同一个数据项下
export const determineDevItem = data => ajax('/measurepoint/earlyWarning/determineDevItem', data, 'POST');



// 修改表具预警配置状态
export const updateMeterState = data => ajax('/web/MeterEarlyWarning/updateMeterState', data, 'GET');
// 删除配置
export const removeConfig = data => ajax('/measurepoint/earlyWarning/removeConfig', data, 'GET');


// 分类聚合模式模糊-查询预警配置-父层
export const polymerizationWarningConfigurationInfoByCondition = data => ajax('/web/MeterEarlyWarning/polymerizationWarningConfigurationInfoByCondition', data, 'POST');
// 分类聚合模式模糊-查询预警信息-子层
export const polymerizationWarningInfoByCondition = data => ajax('/web/MeterEarlyWarning/polymerizationWarningInfoByCondition', data, 'POST');
// 根据表钢号查询历史数据
export const getCode = data => ajax('/measurepoint/earlyWarning/getCode', data, 'POST');



// 地图接口
// 查询区域下经纬度范围内的所有计量点
export const queryPointByAreaIdAndLongLat = data => ajax('/web/meterHomePage/queryPointByAreaIdAndLongLat', data, 'POST');

// 查询计量点明细信息
export const queryPointDetailById = data => ajax('/web/meterHomePage/queryPointDetailById', data, 'POST');




// 查询表具地图的报警信息
export const getAlarmMapInfo = data => ajax('/web/meterHomePage/getAlarmMapInfo', data, 'POST');
// 首页产销差及7日图
export const queryProAndMarketingByAreaId = data => ajax('/web/meterHomePage/queryProAndMarketingByAreaId', data, 'GET');
// 检定信息
export const getVerificationInfo = data => ajax('/web/meterHomePage/getVerificationInfo', data, 'POST');

// 根据ID查询详情配置
export const getObjectInfo = data => ajax('/measurepoint/earlyWarning/getObjectInfo', data, 'GET');
