import React from 'react';

import * as echarts from 'echarts';
// 折线图
class BarChart extends React.Component {


    // componentDidUpdate(num){this.elementById();}
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        if (echarts.getInstanceByDom(document.getElementById(this.props.elementById))) {
            echarts.dispose(document.getElementById(this.props.elementById));
        }

        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                // 标题
                title: {
                    text: this.props.name||null,
                    textStyle:{color:'#e5e5e5', fontWeight:'normal', fontSize:'14px'},
                    left:'35%', top:'83%'
                },

                tooltip: {trigger: 'axis', axisPointer: {type: 'shadow'}},
                legend: {textStyle:{color:'#fff', fontWeight: 'inherit',}},
                grid: {
                    top:'20%',
                    left: '10%',
                    right: '14%',
                    bottom: '32%',
                    containLabel: false // 不包含标签区域
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    data:this.props?.yData,
                    type: 'category',

                },
                yAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    data:this.props?.yData,

                },
            }
        );
        console.log('this.props?.data');
        console.log(this.props?.data);
        if(this.props?.data && this.props?.data.length!==0){
            this.setData(this.props?.data);
        }
    };

    // 更新 时间
    setYData=(yData)=>{
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        chartDom.setOption(
            {
                xAxis: {data:yData,},
                yAxis: {data:yData,},
            });
    };
    // 更新数据
    setData=(data)=>{
        if(!data){return false;}
        var series=[];
        // 处理单挑数据拼接
        data?.map(
            one=>{
                series.push(
                    {
                        name: one.name,
                        type: 'line',
                        emphasis: {focus: 'series'},
                        data: one.data,
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(1,0,0,0,
                                    [
                                        {offset:0,color:one.color[0]},
                                        {offset:0,color:one.color[1]},
                                    ])
                            }
                        }
                    },)
            }
        );
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        chartDom.setOption({series:series});
    };


    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
