import React from 'react';

import * as echarts from 'echarts';
// 柱状图和折线图
class BarAndLineCharts extends React.Component {

    // componentDidUpdate(num){this.elementById();}
    componentDidMount(){this.elementById();};
    elementById= async =>{
        if (echarts.getInstanceByDom(document.getElementById(this.props.elementById))) {
            echarts.dispose(document.getElementById(this.props.elementById));
        }

        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {color: '#999'}
                    }
                },
                // 边距
                grid: {
                    top:'6%',
                    left: '0%',
                    right: '-0%',
                    bottom: '0%',
                    containLabel: true
                },
                // legend: {
                //     data: ['Evaporation', 'Precipitation', 'Temperature'],
                //     textStyle:{
                //         fontSize:'12',
                //         color:"#fff"
                //     },
                // },
                xAxis: [
                    {
                        type: 'category',
                        data: this.props?.data?.xData,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        // splitLine: {
                        //     lineStyle: {
                        //         // 设置背景横线
                        //         color: 'red'
                        //     }
                        // },
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    //左
                    {
                        type: 'value',
                        // name: '左侧',
                        // min: this.state.rightData_3.yData_left?.min,
                        // max: this.state.rightData_3.yData_left?.max,
                        interval: 100,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        axisLabel: {
                            formatter: '{value}',
                        }
                    },
                    //右
                    {
                        type: 'value',
                        // name: '右侧',
                        // min: this.state.rightData_3.yData_right?.min,
                        // max: this.state.rightData_3.yData_right?.max,
                        interval: 100,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                // 设置背景横线
                                color: '#8fa4ac',

                            }
                        },
                        axisLabel: {
                            formatter: '{value} %'
                        }
                    }
                ],
                series: [
                    {
                        name: '数量',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value ;
                            }
                        },
                        data: this.props?.data?.data_1
                    },
                    // {
                    //     name: '完成率',
                    //     type: 'line',
                    //     yAxisIndex: 1,
                    //     tooltip: {
                    //         valueFormatter: function (value) {
                    //             return value + ' %';
                    //         }
                    //     },
                    //     data: this.props?.data?.data_2
                    // }
                ]
            }
        )
    };
    updateData=()=>{
        this.elementById();
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}/>
        );
    }
}
export default BarAndLineCharts;
