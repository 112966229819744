import React from "react";
import StackedLineChart from "./echart_1";
import * as echarts from "echarts";
class doughnutChartWithRoundedCorner extends  React.Component {
    componentDidUpdate(num){
        this.elementById();
    }
    componentDidMount(){
        this.elementById();
    };

    elementById=()=>{
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");

        chartDom.setOption(
            {
                tooltip: {
                    trigger: 'item',

                },

                legend: {
                    top: '0%',
                    orient: 'vertical',
                    left: 'left',
                    right:4,
                    textStyle:{
                        fontSize:'10',
                        color:"#fff"
                    },
                },

                series: [
                    {
                        name: '数量',
                        type: 'pie',
                        radius: ['35%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 1,
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },

                        data: [
                            {
                                value: this.props.data[0].value, name:  this.props.data[0].name,
                                itemStyle: {
                                    color:  this.props.data[0].color,
                                    // shadowBlur: 10,   // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                                    // shadowOffsetX: 1, // 阴影水平方向上的偏移距离
                                    // shadowColor: '#112949' // 阴影颜色
                                }
                            },
                            {
                                value: this.props.data[1].value, name:  this.props.data[1].name,
                                itemStyle: {
                                    color:  this.props.data[1].color,
                                    // shadowBlur: 10,   // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                                    // shadowOffsetX: 1, // 阴影水平方向上的偏移距离
                                    // shadowColor: '#112949' // 阴影颜色
                                }
                            },
                            {
                                value: this.props.data[2].value, name:  this.props.data[2].name,
                                itemStyle: {
                                    color:  this.props.data[2].color,
                                    // shadowBlur: 10,   // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                                    // shadowOffsetX: 1, // 阴影水平方向上的偏移距离
                                    // shadowColor: '#112949' // 阴影颜色
                                }
                            },
                        ]
                    }
                ]
            }
        )
    };

    render() {
        return(
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>

            </div>
        );
    }

}

export default doughnutChartWithRoundedCorner;
