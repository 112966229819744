import React, { Component } from 'react'
import {Layout} from "antd";
import './bottomTwo.less'
import BasicLineChart from '../assembly/BasicLineChart'
import BarChart1 from "../assembly/BarChart_1";
import {getGasDevNumber} from "../../../services/customerDataPanel";
export default class bottomTwo extends Component {
    state = {
        // 使用情况 最近七日曲线图
        leftData_eCharts:{
            name:"抽查合格率",
            // 数值
            data:[
                {name:'探测器',data:[70,20,20,10,60,40,70],color:['#67ff96','#b3ffc2']},
                {name:'用气设备',data:[50,70,20,40,90,90,21],color:['#FFA06C','#FFC081']},
                {name:'压力表',data:[70,40,50,60,10,60,50],color:['#4972ff','#878eff']},
                {name:'阀门',data:[30,90,21,30,22,30,20],color:['#2fe1ff','#95e2ff']},
            ],
            yData:['1月','2月','3月','4月','5月','6月','7月'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:50} // 最小、最大、间距
        },
        // leftData_eCharts_1:{
        //     // 数值
        //     data:[
        //         {name:'燃气',data:[{name:'燃气泄露',data:[5,20,60,30,40]},{name:'燃气使用',data:[5,20,60,30,40]}]},
        //         {name:'液化',data:[{name:'液化泄露',data:[5,20,60,30,40]},{name:'液化使用',data:[5,20,60,30,40]}]},
        //
        //     ],
        //     yData:['岳麓','芙蓉','雨花','天心','开福','宁乡','浏阳'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
        //     number:{min:0,max:100,interval:100} // 最小、最大、间距
        // },
        data1:0,// 工业探测器
        data2:0,// 家用探测器
        data3:0,// 计量表具
        data4:0,// 调压器
        data5:0,// 阀门
        data6:0,// 气瓶
        data7:0,// 阴极保护
        data8:0,// 用气设备
    };

    componentDidMount(): void {
        this.getGasDevNumber();
    }

    // 燃气设备(数量查询)
    getGasDevNumber=()=>{
        getGasDevNumber().then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    console.log(data[0]);
                    this.setState({
                        data1:data[10168]??0,   // 工业探测器
                        data2:data[10202]??0,   // 家用探测器
                        data3:data[10207]?data[10207]:0,// 计量表具
                        // data3:data[10167]?data[10167]:data[10171]
                        //         ?data[10171]
                        //         :data[10195]?data[10195]:0,       // 计量表具
                        data4:0,   // 调压器
                        data5:data[10204]?data[10204]
                            :data[10170]?data[10170]:0,// 阀门
                        data6:0,   // 气瓶
                        data7:0,// 阴极保护
                        data8:data[0]?data[0]:0,// 用气设备
                    });
                }
            }
        )
    };


    render() {
        return (
            <div id={'bottomTwo'}>
                <div style={{ width: '100%',height: '20%', padding: '20px 0 0 30px'}}>
                    <span style={{ fontSize: '22px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>设备生命周期</span>
                </div>
                <div style={{ width: '100%',height: '80%'}}>
                    {/*上部分*/}
                    <div style={{ width: '100%',height: '55%'}}>
                        {/*1*/}
                        <div style={{ width: '100%',height: '45%'}}>
                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'10%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_1'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data1}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>工业探测器</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_2'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data2}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>家用探测器</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_3'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data3}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>计量表具</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_4'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data4}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>调压器</span>
                                </div>
                            </div>
                        </div>
                        {/*2*/}
                        <div style={{ width: '100%',height: '45%',marginTop:'3%'}}>
                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'10%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_1'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data5}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>阀门</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_2'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data6}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>气瓶</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_3'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data7}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>阴极保护</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_1'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>{this.state.data8}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>用气设备</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*下部分*/}
                    <div style={{ width: '100%',height: '40%'}}>
                        <BasicLineChart
                            elementById={'bottomTwo_1'}
                            name={this.state.leftData_eCharts.name}
                            data={this.state.leftData_eCharts.data}    // 数值
                            yData={this.state.leftData_eCharts.yData}  // X轴数据
                            number={this.state.leftData_eCharts.number}// 最小、最大、间距
                        />

                    </div>
                </div>
            </div>
        )
    }
}
