import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'


import {queryDevMaintenance,getEngineeringCompany,getInitInfoByInitId,getAllManufacturerAndModel} from '../../../services/account'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				stencilNum: '表钢号',
				manufacture: '表具厂家',
				createTime: '维修时间',
				sponsor: '维修人员',
				phone: '联系电话',
				maintenanceType: '维修类型',
				status: '维修结果',
				partsNum: '更换配件数',
				amount: '维修费用',
				reason: '问题原因',
				edit: '操作',
			},
			// 弹出框
			headers: {
				stencilNum: '表钢号',
				manufacture: '表具厂家',
				createTime: '维修时间',
				sponsor: '维修人员',
				phone: '联系电话',
				maintenanceType: '维修类型',
				status: '维修结果',
				partsNum: '更换配件数',
				amount: '维修费用',
				reason: '问题原因',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'sponsor',
					str: '维修人员',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'manufacture',
					str: '表具厂家',
					// isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'createTime',
					str: '维修时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'stencilNum',
					str: '表钢号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'maintenanceType',
					str: '维修类型',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'现场维修',value:'现场维修'},
						{key:'送检维修',value:'送检维修'},
					],
					rules: TYPE_NAME.SELECT,
				},
			]
		},

		    	// 总条数
		totalCount: 0,
		loading: false,
		searchGetData:{},
		basicsData:{},
		voInstallReqData:{},
		voInstallMeterData:{},
		devVisible:false, // 小窗口开关器-1
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'查看详情'} onClick={() => this.showDetailBox(true,rowData)}>
						<u>{'查看详情'}</u>
				</div>
				</>
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].manufacturer,value:data[i].manufacturer})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)

		$(document).on('click', '.smallImg', function() {
			var url=$(this).attr('src')
			$('.bigImg').attr('src',url)
			$('.bigImgBox').show()
		});
		$(document).on('click', '.bigImgBox', function() {
			$('.bigImgBox').hide()
		});
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

		//详情
		showDetailBox = async (devVisible,data)=>{
			this.setState({devVisible})			
			if(data){
				if(data.amount){
					this.state.basicsData.amount=data.amount
				}else{
					this.state.basicsData.amount='0.00'
				}
				this.state.basicsData.pointName=data.pointName
				this.state.basicsData.stencilNum=data.stencilNum
				this.state.basicsData.maintenanceType=data.maintenanceType
				this.state.basicsData.location=data.location
				this.state.basicsData.devModelName=data.devModelName
				this.state.basicsData.manufacture=data.manufacture
				this.state.basicsData.phone=data.phone
				this.state.basicsData.status=data.status
				this.state.basicsData.createTime=data.createTime
				this.state.basicsData.reason=data.reason
				$('#componentList').html('');
				if(data.componentList){
					for(var i=0;i<data.componentList.length;i++){
						var imgUrl=''
						if(data.componentList[i].pathList){
							if(data.componentList[i].pathList.length>0){
								for(var j=0;j<data.componentList[i].pathList.length;j++){
									imgUrl+="<img class='smallImg' alt='暂未上传' src="+data.componentList[i].pathList[j]+" />"
								}
							}
						}
						$('#componentList').append("<div class='installInnerSmall'>"+
						"<ul>"+
							"<li>配件名称：<span>"+data.componentList[i].partsName+"</span></li>"+
							"<li>配件单价：<span>"+data.componentList[i].price+"元</span></li>"+
						"</ul>"+
						"<div class='installInnerImgBox'>"+imgUrl+
						"</div>"+
						"</div>")
					}
				}else{
					$('#componentList').append("<div class='installInnerSmall'><p style='text-align:center; font-size:18px; color:#ccc;'>暂未上传照片</p></div>")
				}
			}

		}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data)
		let sTime=data.createTime ?moment(data.createTime[0]._d) : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTime=''
		params.startTime=sTime;
		params.endTime=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	


	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>维修台账</h3>
						<div style={{position:'relative'}}>
							<div style={{width:'88%'}}>
							<FormsTwo 
									ref={e => this.forms = e} 
									formParamenter={this.state.topFormItem} 
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'90px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary">导出</Button>
						</div>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {queryDevMaintenance}
					setTableValue={this.setTableValue}
					ref={e => this.table = e} 
				>
				</TableComponents>
				</div>
				<Modal
                        title={'维修详情'}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
						forceRender={true}
						// footer={false}
                        onOk={() => this.showDetailBox(false)}
                        onCancel={() => this.showDetailBox(false)}
                        width={1360}
                        centered
                    >
                        <div className={'installBox'}>
							<div className={'installList'}>
								<h4>维修信息</h4>
								<div className={'installInner'}>
									<ul>
										<li>用气点名称：<span>{this.state.basicsData.pointName}</span></li>
										<li>表钢号：<span>{this.state.basicsData.stencilNum}</span></li>
										<li>维修类型：<span>{this.state.basicsData.maintenanceType}</span></li>
										<li>详细地址：<span>{this.state.basicsData.location}</span></li>
										<li>表具型号：<span>{this.state.basicsData.devModelName}</span></li>
										<li>厂家：<span>{this.state.basicsData.manufacture}</span></li>
										<li>联系电话：<span>{this.state.basicsData.phone}</span></li>
										<li>维修结果：<span>{this.state.basicsData.status}</span></li>
										<li>维修时间：<span>{this.state.basicsData.createTime}</span></li>
										<li>维修原因：<span>{this.state.basicsData.reason}</span></li>
									</ul>
								</div>
							</div>
							<div className={'installList'}>
								<h4>配件信息</h4>
								<div id='componentList'>

								</div>
							</div>
							<p style={{textAlign:'right'}}>合计：<b style={{color:'#1890FF',fontSize:'16px',marginRight:'10px'}}>{this.state.basicsData.amount}</b>元</p>
                        </div>
                    </Modal>
					<div className={'bigImgBox'}>
						<div className={'bigImgBoxBg'}></div>
						<img className={'bigImg'}></img>
					</div>

            </Layout>
        )
    }
}
