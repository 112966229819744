import React from 'react';

import * as echarts from 'echarts';
// 圆角环形图
class EcDoughnutChartwithRoundedCornerhart extends React.Component {
    componentDidUpdate(num){
        this.elementById();
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{


        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");

        chartDom.setOption(
            {
                tooltip: {
                    position: function(point, params, dom, rect, size) {
                        // 提示框位置
                        var x = 0; // x坐标位置
                        var y = 0; // y坐标位置
                        // 当前鼠标位置
                        var pointX = point[0];
                        var pointY = point[1];
                        // 提示框大小
                        var boxWidth = size.contentSize[0];
                        var boxHeight = size.contentSize[1];
                        // boxWidth > pointX 说明鼠标左边放不下提示框
                        if (boxWidth > pointX) {
                            x = 5;
                        } else {
                            // 左边放的下
                            x = pointX - boxWidth;
                        }
                        // boxHeight > pointY 说明鼠标上边放不下提示框
                        if (boxHeight > pointY) {
                            y = 5;
                        } else {
                            // 上边放得下
                            y = pointY - boxHeight;
                        }
                        return [x+70, y+20];
                    }
                    // trigger: 'item'
                },
                // 顶部提示文字位置CSS
                // legend: {top: '5%', left: 'center'},
                series: [
                    {
                        name: '数量',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        center: ['50%', '50%'],
                        avoidLabelOverlap: false,
                        // 边框CSS
                        itemStyle: {
                            borderRadius: 2,
                            borderColor: '#03171f',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        // 内部字体
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 12,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.props.data.value, name:  this.props.data.name,
                                itemStyle: {
                                    color:  this.props.data.color,
                                    shadowBlur: 10,   // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                                    shadowOffsetX: 1, // 阴影水平方向上的偏移距离
                                    shadowColor: '#112949' // 阴影颜色
                                }},
                            this.props?.data1?
                                { value: this.props.data1.value, name: this.props.data1.name,
                                    itemStyle: {
                                        color: this.props.data1.color,
                                    }}:null
                        ]
                    }
                ]
            }
        );
    };

    render() {
        return (
            <div id={this.props.elementById} style={{ width: '95%', height: '95%' }}>

            </div>
        );
    }
}

export default EcDoughnutChartwithRoundedCornerhart;
