import React, { Component } from 'react'
import {Button, Divider, message, Spin, Table} from "antd";
import { Map, Marker, InfoWindow ,ScaleControl,Polyline,MassMarks} from '@uiw/react-amap';
import { UserOutlined,EnvironmentOutlined,MacCommandOutlined,ProfileOutlined,ProjectOutlined,GoldOutlined,DownCircleOutlined,ClockCircleOutlined,FireOutlined,StockOutlined} from '@ant-design/icons';
import moment from 'moment'
import {getDateByUserId, queryPipingByAreaId} from "../../../../services/admin";
import {queryPointByAreaIdAndLongLat,queryPointDetailById} from "../../../../services/earlyWarning";
import './baiduMap.less';

import {FullscreenOutlined,FullscreenExitOutlined} from '@ant-design/icons';

import * as echarts from "echarts";
const { AMap } = window;
// 地图
class baiduMap extends React.Component {

    state={


        mapSwithType:false,
        mapCssType:false,

        showMap:[113.394054,28.223727],
        show : false,// 信息窗口开关
        mapDisplay:null,// 加载开关
        mapDisplayNum:0,// 计算
        // 选中坐标
        selectPosition:{
            // longitude:28.199714,
            // latitude:113.018664
        },
        // 选择查询后的值
        selectCustomerData:{},
        // 展示地图坐标
        position:[
            // {id:1, name:"长沙1号",longitude: 113.018664, latitude: 28.199714},
            // {id:2, name:"长沙2号",longitude: 113.118664, latitude: 28.199714},
            // {id:3, name:"长沙3号",longitude: 113.218664, latitude: 28.199714},
            // {id:4, name:"长沙4号",longitude: 113.318664, latitude: 28.199714},
        ],

        // 提交查询坐标参数
        getMapParam:{
            // pointType:this.props?.pointType,
            pointType:'全部',
            // areaId: null,         // 单个地址id-----------------不需要
            areaIds: [],             // 多个地址id
            // businessType: null,   // 业务类型（1：维修，2：检定）-不需要
            // customerIds: [],      // 客户id列表-----------------不需要
            endLatitude: null,       // 结束纬度
            endLongitude: null,      // 结束经度
            startLatitude: null,     // 开始纬度y
            startLongitude: null,    // 开始经度
            // type: 0               // 设备类型-------------------不需要
        },
        echartData:{
            type:'bar', // line折线 bar柱状
            head:['1','2'],// 日期
            data:[
                {label:'1',values:'1'},
                {label:'2',values:'2'}
            ]
        },
        // 计量点数据
        queryPointDeta: {
            address: null,      // 地址
            className: "",      // 设备类型
            customerName: "",
            // 用量明细
            details: {
                additionalProperties1: {
                    communicateTime: "",
                    dataTime: "",
                    devItemId: "",
                    itemId: "",
                    name: "",
                    unit: "",
                    value: ""
                }
            },
            // 七日数据
            dosage: {},
            realTimeAcc: "",
            realTimeFlow: "",
            realTimePre: "",
            realTimeTemp: "",
            realTimeVoltage: "",
            stencilNum: "",
            time: ""
        },

        // 查询类型按钮参数
        buttonClickType:{
            currentNum:0,   // 之前所选数据
            // 点击#007c88，未点击linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)
            buttonType:[
                '#007c88',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
            ],

        },
    //    管道数据
        pipelineRouteData:[
            [{
                "coord": [113.39034, 28.232227]
            }, {
                "coord": [113.388004, 28.232449]
            }],
            [{
                "coord": [113.388004, 28.232457]
            }, {
                "coord": [113.388067, 28.232545]
            }, {
                "coord": [113.386522, 28.23264]
            }, {
                "coord": [113.385677, 28.232831]
            }, {
                "coord": [113.385749, 28.23517]
            }, {
                "coord": [113.381294, 28.235202]
            }],
            [{
                "coord": [113.388004, 28.232457]
            }, {
                "coord": [113.386571, 28.232544]
            }, {
                "coord": [113.385789, 28.232703]
            }, {
                "coord": [113.385709, 28.232393]
            }, {
                "coord": [113.384083, 28.232496]
            }, {
                "coord": [113.384054, 28.232236]
            }, {
                "coord": [113.382725, 28.232331]
            }, {
                "coord": [113.382311, 28.22961]
            }, {
                "coord": [113.383246, 28.229443]
            }, {
                "coord": [113.383227, 28.229446]
            }],
            [{
                "coord": [113.382427, 28.229589]
            }, {
                "coord": [113.382391, 28.229478]
            }, {
                "coord": [113.382275, 28.229526]
            }, {
                "coord": [113.382167, 28.229183]
            }, {
                "coord": [113.382167, 28.229167]
            }],
            [{
                "coord": [113.382481, 28.23091]
            }, {
                "coord": [113.382248, 28.23095]
            }, {
                "coord": [113.382194, 28.230695]
            }, {
                "coord": [113.38108, 28.230703]
            }],
            [{
                "coord": [113.385742, 28.232556]
            }, {
                "coord": [113.385536, 28.232596]
            }],
            [{
                "coord": [113.385419, 28.232413]
            }, {
                "coord": [113.385356, 28.232158]
            }],
            [{
                "coord": [113.382912, 28.232333]
            }, {
                "coord": [113.383065, 28.23293]
            }, {
                "coord": [113.381673, 28.233081]
            }],
            [{
                "coord": [113.382679, 28.232071]
            }, {
                "coord": [113.382293, 28.232134]
            }],
            [{
                "coord": [113.388195, 28.232429]
            }, {
                "coord": [113.387934, 28.231745]
            }],
            [{
                "coord": [113.382579, 28.235199]
            }, {
                "coord": [113.382642, 28.236146]
            }, {
                "coord": [113.382148, 28.23614]
            }],
            [{
                "coord": [113.383792, 28.235177]
            }, {
                "coord": [113.383882, 28.236124]
            }],
            [{
                "coord": [113.383882, 28.2361]
            }, {
                "coord": [113.383567, 28.236108]
            }],
            [{
                "coord": [113.385678, 28.235178]
            }, {
                "coord": [113.385705, 28.235488]
            }, {
                "coord": [113.385561, 28.23548]
            }, {
                "coord": [113.385561, 28.235488]
            }],
            [{
                "coord": [113.385517, 28.235162]
            }, {
                "coord": [113.385409, 28.233809]
            }, {
                "coord": [113.385274, 28.233825]
            }],
            [{
                "coord": [113.385148, 28.23517]
            }, {
                "coord": [113.385085, 28.234732]
            }, {
                "coord": [113.384861, 28.234756]
            }],
            [{
                "coord": [113.384034, 28.23517]
            }, {
                "coord": [113.383558, 28.233419]
            }],
            [{
                "coord": [113.382992, 28.235186]
            }, {
                "coord": [113.382597, 28.233515]
            }],
            [{
                "coord": [113.382022, 28.235186]
            }, {
                "coord": [113.381995, 28.234987]
            }, {
                "coord": [113.3816, 28.234995]
            }, {
                "coord": [113.381339, 28.233594]
            }],
            [{
                "coord": [113.38151, 28.235209]
            }, {
                "coord": [113.38151, 28.23548]
            }, {
                "coord": [113.380576, 28.235472]
            }],
            [{
                "coord": [113.381312, 28.235202]
            }, {
                "coord": [113.380199, 28.235186]
            }, {
                "coord": [113.380217, 28.235353]
            }],
            [{
                "coord": [113.390334, 28.23222]
            }, {
                "coord": [113.39063, 28.233676]
            }, {
                "coord": [113.391583, 28.233597]
            }, {
                "coord": [113.39169, 28.234082]
            }, {
                "coord": [113.39275, 28.23409]
            }, {
                "coord": [113.392723, 28.233836]
            }],
            [{
                "coord": [113.39134, 28.233605]
            }, {
                "coord": [113.391322, 28.23343]
            }, {
                "coord": [113.392149, 28.233494]
            }],
            [{
                "coord": [113.391664, 28.233964]
            }, {
                "coord": [113.390712, 28.234083]
            }, {
                "coord": [113.39108, 28.235929]
            }, {
                "coord": [113.392374, 28.235817]
            }],
            [{
                "coord": [113.392095, 28.235841]
            }, {
                "coord": [113.392131, 28.236478]
            }],
            [{
                "coord": [113.392266, 28.235834]
            }, {
                "coord": [113.392284, 28.236072]
            }],
            [{
                "coord": [113.392311, 28.235826]
            }, {
                "coord": [113.392284, 28.235428]
            }],
            [{
                "coord": [113.392383, 28.235826]
            }, {
                "coord": [113.392374, 28.235515]
            }],
            [{
                "coord": [113.39276, 28.233948]
            }, {
                "coord": [113.39294, 28.23394]
            }, {
                "coord": [113.393829, 28.232969]
            }],
            [{
                "coord": [113.390337, 28.232212]
            }, {
                "coord": [113.388918, 28.22961]
            }, {
                "coord": [113.388711, 28.229483]
            }, {
                "coord": [113.388208, 28.228528]
            }, {
                "coord": [113.387857, 28.227844]
            }, {
                "coord": [113.387686, 28.22774]
            }, {
                "coord": [113.387479, 28.227343]
            }],
            [{
                "coord": [113.388369, 28.228791]
            }, {
                "coord": [113.388306, 28.228807]
            }],
            [{
                "coord": [113.388054, 28.228226]
            }, {
                "coord": [113.387902, 28.228274]
            }],
            [{
                "coord": [113.387488, 28.227351]
            }, {
                "coord": [113.387354, 28.227048]
            }, {
                "coord": [113.383814, 28.227382]
            }, {
                "coord": [113.383679, 28.227216]
            }, {
                "coord": [113.383616, 28.226865]
            }, {
                "coord": [113.382601, 28.226969]
            }],
            [{
                "coord": [113.38366, 28.227224]
            }, {
                "coord": [113.383328, 28.227232]
            }, {
                "coord": [113.383391, 28.227462]
            }],
            [{
                "coord": [113.384684, 28.227303]
            }, {
                "coord": [113.384837, 28.227661]
            }, {
                "coord": [113.38428, 28.227725]
            }],
            [{
                "coord": [113.385969, 28.227176]
            }, {
                "coord": [113.386095, 28.22755]
            }, {
                "coord": [113.385861, 28.227582]
            }],
            [{
                "coord": [113.38675, 28.227128]
            }, {
                "coord": [113.386831, 28.227423]
            }, {
                "coord": [113.386831, 28.227415]
            }],
            [{
                "coord": [113.386957, 28.227088]
            }, {
                "coord": [113.387101, 28.227518]
            }, {
                "coord": [113.386975, 28.227526]
            }],
            [{
                "coord": [113.387451, 28.227271]
            }, {
                "coord": [113.387289, 28.227303]
            }],
            [{
                "coord": [113.387165, 28.227064]
            }, {
                "coord": [113.386787, 28.22634]
            }, {
                "coord": [113.386644, 28.226388]
            }],
            [{
                "coord": [113.387434, 28.227191]
            }, {
                "coord": [113.388198, 28.226889]
            }, {
                "coord": [113.388287, 28.227016]
            }],
            [{
                "coord": [113.388072, 28.226953]
            }, {
                "coord": [113.387955, 28.226786]
            }, {
                "coord": [113.388728, 28.226491]
            }],
            [{
                "coord": [113.388449, 28.226587]
            }, {
                "coord": [113.388341, 28.226475]
            }],
            [{
                "coord": [113.389886, 28.231353]
            }, {
                "coord": [113.391405, 28.230899]
            }, {
                "coord": [113.391117, 28.230406]
            }],
            [{
                "coord": [113.390282, 28.231225]
            }, {
                "coord": [113.390614, 28.231727]
            }, {
                "coord": [113.390722, 28.231671]
            }],
            [{
                "coord": [113.390371, 28.231337]
            }, {
                "coord": [113.391683, 28.230931]
            }, {
                "coord": [113.392078, 28.231408]
            }],
            [{
                "coord": [113.387341, 28.227057]
            }, {
                "coord": [113.386685, 28.225688]
            }, {
                "coord": [113.386595, 28.22564]
            }, {
                "coord": [113.386416, 28.225386]
            }, {
                "coord": [113.383892, 28.225616]
            }, {
                "coord": [113.383892, 28.225616]
            }, {
                "coord": [113.383919, 28.225608]
            }],
            [{
                "coord": [113.384727, 28.225537]
            }, {
                "coord": [113.384664, 28.22541]
            }],
            [{
                "coord": [113.386416, 28.225386]
            }, {
                "coord": [113.386434, 28.225274]
            }, {
                "coord": [113.384979, 28.222736]
            }, {
                "coord": [113.386586, 28.222171]
            }, {
                "coord": [113.386488, 28.221996]
            }, {
                "coord": [113.386856, 28.221837]
            }],
            [{
                "coord": [113.38558, 28.22377]
            }, {
                "coord": [113.385311, 28.223794]
            }],
            [{
                "coord": [113.385401, 28.22346]
            }, {
                "coord": [113.385113, 28.223468]
            }],
            [{
                "coord": [113.385482, 28.223619]
            }, {
                "coord": [113.382212, 28.223826]
            }, {
                "coord": [113.382257, 28.224065]
            }, {
                "coord": [113.381978, 28.224081]
            }, {
                "coord": [113.381996, 28.224311]
            }],
            [{
                "coord": [113.393873, 28.232951]
            }, {
                "coord": [113.39479, 28.232044]
            }, {
                "coord": [113.395005, 28.231964]
            }, {
                "coord": [113.393945, 28.230246]
            }, {
                "coord": [113.39346, 28.228082]
            }, {
                "coord": [113.392939, 28.22832]
            }],
            [{
                "coord": [113.394215, 28.230628]
            }, {
                "coord": [113.394466, 28.230596]
            }, {
                "coord": [113.394107, 28.229975]
            }],
            [{
                "coord": [113.395018, 28.231972]
            }, {
                "coord": [113.397605, 28.232202]
            }, {
                "coord": [113.399159, 28.232552]
            }, {
                "coord": [113.400749, 28.23291]
            }, {
                "coord": [113.401019, 28.23272]
            }, {
                "coord": [113.400534, 28.231486]
            }, {
                "coord": [113.403166, 28.230985]
            }, {
                "coord": [113.405205, 28.230404]
            }, {
                "coord": [113.40561, 28.23027]
            }, {
                "coord": [113.406095, 28.230119]
            }, {
                "coord": [113.406463, 28.230024]
            }, {
                "coord": [113.406409, 28.229825]
            }, {
                "coord": [113.407011, 28.229753]
            }, {
                "coord": [113.406984, 28.229682]
            }, {
                "coord": [113.407658, 28.229602]
            }, {
                "coord": [113.407613, 28.229451]
            }, {
                "coord": [113.407613, 28.229451]
            }, {
                "coord": [113.407613, 28.229443]
            }],
            [{
                "coord": [113.407011, 28.229753]
            }, {
                "coord": [113.407964, 28.229618]
            }, {
                "coord": [113.408368, 28.229594]
            }, {
                "coord": [113.408278, 28.228281]
            }, {
                "coord": [113.408245, 28.227397]
            }, {
                "coord": [113.408236, 28.227175]
            }, {
                "coord": [113.408262, 28.227039]
            }, {
                "coord": [113.408586, 28.226769]
            }, {
                "coord": [113.408559, 28.226291]
            }, {
                "coord": [113.408245, 28.225846]
            }, {
                "coord": [113.408245, 28.225846]
            }, {
                "coord": [113.408139, 28.223038]
            }, {
                "coord": [113.408103, 28.222099]
            }, {
                "coord": [113.408112, 28.222123]
            }, {
                "coord": [113.408112, 28.222146]
            }, {
                "coord": [113.408087, 28.221709]
            }, {
                "coord": [113.408302, 28.221693]
            }, {
                "coord": [113.408275, 28.221399]
            }, {
                "coord": [113.408087, 28.221391]
            }, {
                "coord": [113.408042, 28.220149]
            }, {
                "coord": [113.408446, 28.220165]
            }, {
                "coord": [113.408446, 28.220165]
            }, {
                "coord": [113.408446, 28.220165]
            }, {
                "coord": [113.408437, 28.220165]
            }],
            [{
                "coord": [113.403065, 28.230994]
            }, {
                "coord": [113.403208, 28.231515]
            }],
            [{
                "coord": [113.406083, 28.230115]
            }, {
                "coord": [113.406227, 28.230544]
            }],
            [{
                "coord": [113.407933, 28.229637]
            }, {
                "coord": [113.407915, 28.229399]
            }, {
                "coord": [113.408005, 28.229351]
            }, {
                "coord": [113.407951, 28.228046]
            }, {
                "coord": [113.407484, 28.228078]
            }],
            [{
                "coord": [113.408203, 28.224752]
            }, {
                "coord": [113.406694, 28.224848]
            }, {
                "coord": [113.406766, 28.225452]
            }],
            [{
                "coord": [113.407754, 28.224784]
            }, {
                "coord": [113.407736, 28.224513]
            }, {
                "coord": [113.407089, 28.224561]
            }, {
                "coord": [113.406981, 28.224116]
            }],
            [{
                "coord": [113.408185, 28.223368]
            }, {
                "coord": [113.408005, 28.223368]
            }, {
                "coord": [113.407718, 28.222413]
            }, {
                "coord": [113.407664, 28.221124]
            }],
            [{
                "coord": [113.408221, 28.225596]
            }, {
                "coord": [113.408563, 28.22558]
            }, {
                "coord": [113.408527, 28.225453]
            }, {
                "coord": [113.409299, 28.225405]
            }],
            [{
                "coord": [113.408185, 28.224975]
            }, {
                "coord": [113.409928, 28.224864]
            }],
            [{
                "coord": [113.408329, 28.229336]
            }, {
                "coord": [113.408742, 28.229383]
            }, {
                "coord": [113.408707, 28.229972]
            }, {
                "coord": [113.411635, 28.230545]
            }, {
                "coord": [113.411815, 28.230752]
            }, {
                "coord": [113.411779, 28.233161]
            }, {
                "coord": [113.414581, 28.233066]
            }],
            [{
                "coord": [113.411797, 28.232477]
            }, {
                "coord": [113.411437, 28.232477]
            }, {
                "coord": [113.411437, 28.233002]
            }, {
                "coord": [113.410773, 28.232988]
            }],
            [{
                "coord": [113.412084, 28.233163]
            }, {
                "coord": [113.412048, 28.234388]
            }, {
                "coord": [113.411491, 28.234325]
            }, {
                "coord": [113.411527, 28.234913]
            }],
            [{
                "coord": [113.412012, 28.234388]
            }, {
                "coord": [113.411995, 28.236673]
            }],
            [{
                "coord": [113.411995, 28.236689]
            }, {
                "coord": [113.411312, 28.236689]
            }],
            [{
                "coord": [113.414438, 28.233077]
            }, {
                "coord": [113.41442, 28.233268]
            }],
            [{
                "coord": [113.408278, 28.228393]
            }, {
                "coord": [113.408458, 28.228377]
            }, {
                "coord": [113.40853, 28.229061]
            }],
            [{
                "coord": [113.408242, 28.227311]
            }, {
                "coord": [113.40871, 28.226834]
            }, {
                "coord": [113.408835, 28.226738]
            }, {
                "coord": [113.412195, 28.226849]
            }, {
                "coord": [113.413111, 28.226881]
            }, {
                "coord": [113.413129, 28.225831]
            }, {
                "coord": [113.413794, 28.225831]
            }],
            [{
                "coord": [113.409734, 28.22677]
            }, {
                "coord": [113.409734, 28.227104]
            }, {
                "coord": [113.409249, 28.227088]
            }],
            [{
                "coord": [113.410668, 28.226802]
            }, {
                "coord": [113.410668, 28.227454]
            }],
            [{
                "coord": [113.41207, 28.234027]
            }, {
                "coord": [113.415178, 28.234202]
            }, {
                "coord": [113.41534, 28.234058]
            }, {
                "coord": [113.415645, 28.234202]
            }, {
                "coord": [113.417909, 28.234122]
            }, {
                "coord": [113.420227, 28.233676]
            }, {
                "coord": [113.420442, 28.233486]
            }, {
                "coord": [113.420227, 28.232356]
            }, {
                "coord": [113.419993, 28.232419]
            }, {
                "coord": [113.420191, 28.233199]
            }, {
                "coord": [113.419831, 28.233279]
            }],
            [{
                "coord": [113.409714, 28.230173]
            }, {
                "coord": [113.409678, 28.230483]
            }, {
                "coord": [113.410064, 28.230547]
            }]
        ],
        newPipelineRouteData:[]
    };


    componentDidMount() {
        console.log("地图组建初始化");
        //
        this.getDateByUserId();
        // 管道
        this.getPipelineRouteShow();
    }



    // 获取当前用户区域id
    getDateByUserId=()=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    var getMapParam = this.state.getMapParam;
                    this.forChildren(response?.data,getMapParam.areaIds);
                    this.setState({getMapParam});
                    // this.queryPipingByAreaId(getMapParam.areaIds);
                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };

    forChildren=(children=[],areaIds=[])=>{
        children?.map(one=>{
            if(one.type==='蒸汽'){
                areaIds.push(one.id.toString());
            }
            if(one?.children){
                this.forChildren(one?.children,areaIds);
            }
        });
        return areaIds;
    };

    // 通过区域id查询管道数据
    queryPipingByAreaId=(aredIds)=>{

        var {newPipelineRouteData} = this.state;
        console.log(newPipelineRouteData);
        var params =  new URLSearchParams();
        params.append('areaIdList',aredIds);
        queryPipingByAreaId(params).then(
            response => {
                if(response?.data?.data){


                    let {data} = response?.data;
                    var params=[];

                    data.map(one=>{
                        one?.pipingList.map(two=>{
                            var params=[];
                            two?.coordinates.map(three=>{
                                var param = this.bMapTransQQMap(three.lng,three.lat);
                                params.push([param.lng,param.lat]);

                            });
                            newPipelineRouteData.push(params);

                        });
                    });

                    this.setState({newPipelineRouteData:newPipelineRouteData})
                }
            }
        ).catch(e=>{
            message.error('异常问题，请重新尝试')
        })
    };


    // 获取边界
    getBounds=(start,end)=>{
        var getMapParam = this.state.getMapParam;
        var mapDisplayNum = this.state.mapDisplayNum;
        // 开始
        getMapParam.startLongitude=this.aMapTransbmap(end.lng,end.lat).lng;
        getMapParam.startLatitude=this.aMapTransbmap(end.lng,end.lat).lat;
        // 结束
        getMapParam.endLongitude=this.aMapTransbmap(start.lng,start.lat).lng;
        getMapParam.endLatitude=this.aMapTransbmap(start.lng,start.lat).lat;
        mapDisplayNum=mapDisplayNum+1;
        console.log('开头');
        console.log(mapDisplayNum);
        this.setState({getMapParam,mapDisplay:null,mapDisplayNum:mapDisplayNum});
        this.getData(getMapParam); // // 获取坐标
    };

    // 获取坐标
    getData=(param)=>{
        var mapDisplayNum = this.state.mapDisplayNum;
        console.log('查询区域下经纬度范围内的所有计量点');
        queryPointByAreaIdAndLongLat(param).then(
            response=>{
                if(response?.data?.data){
                    var position = this.state.position;
                    position=[];
                    position=response?.data?.data;
                    this.setState({position:position})
                }
                if(Number(mapDisplayNum)!==0){mapDisplayNum-=1;}
                if(Number(mapDisplayNum)===0){this.setState({mapDisplay:'none'})}
                this.setState({ mapDisplayNum});
        }).catch(e=>{
            if(Number(mapDisplayNum)!==0){mapDisplayNum-=1;}
            if(Number(mapDisplayNum)===0){this.setState({mapDisplay:'none'})}
            this.setState({ mapDisplayNum});
        })
    };
    // 百度坐标转高德
    bMapTransQQMap(lng, lat) {
        let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
        let x = lng - 0.0065;
        let y = lat - 0.006;
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
        let lngs = z * Math.cos(theta);
        let lats = z * Math.sin(theta);
        return {
            lat: lats,
            lng: lngs
        }
    }
    // 高德转百度
    aMapTransbmap=(gd_lon,gd_lat)=>{
        let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
        let  x = Number(gd_lon);
        let y = Number( gd_lat);
        let z = Number(Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi));
        let theta = Number(Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi));
        let bd_lon  = Number(z * Math.cos(theta) + 0.0065);
        let bd_lat  = Number(z * Math.sin(theta) + 0.006);
        return {
            lat: bd_lat,
            lng: bd_lon
        }
    } ;


    // 七日数据
    getElementDosageData=(name,data,type)=>{
      var param={
          date:data?.时间 || [],
          data:data?.用量 || []
      };
      this.elementById_1(name,param,type);
    };
    // 明细
    getElementDetailsData=(name,data,type)=>{
        var param={
            date: [],
            xdata: [],
            ydata: [],
        };
        data?.压力?.map(one=>{
            param?.date?.push(one?.dataTime);
            param?.xdata?.push(one?.value);
        });
        data?.温度?.map(one=>{
            param?.ydata?.push(one?.value);
        });
        this.elementById_2(name,param,type)
    };

    // 弹窗加载的数据图——1
    elementById_1=(name,data,type)=>{
        var chartDom = echarts.init(document.getElementById(name));
        chartDom.setOption(
            {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                grid: {
                    top:'6%',
                    left: '0%',
                    right: '0%',
                    bottom: '12%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: data?.date || [],
                    axisLine:{
                        lineStyle:{
                            color:"#93a0a8"
                        }
                    },
                },
                yAxis: [

                    //右
                    {
                        type: 'value',
                        // name: '右侧',
                        // min: 0,
                        // max: this.state.rightData_3.yData_right?.max,
                        interval: 100,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                // 设置背景横线
                                color: '#8fa4ac',

                            }
                        },
                        axisLabel: {
                            formatter: '{value} '
                        }
                    }
                ],
                series: [
                    {
                        name: data?.name || null ,
                        data: data?.data || null,
                        type: type,
                    }
                ]
            },true);
    };
    // 弹窗加载的数据图——2
    elementById_2=(name,data,type)=>{
        var chartDom = echarts.init(document.getElementById(name), "macarons");
        chartDom.setOption(
            {
                // 滑动设定
                dataZoom: [
                    {
                        type: 'slider',
                        realtime: true,
                        // start: 0,
                        // end: 40,  // 初始展示40%
                        height: 4,
                        fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
                        borderColor: "rgba(17, 100, 210, 0.12)",
                        handleSize:0, // 两边手柄尺寸
                        showDetail: false, // 拖拽时是否展示滚动条两侧的文字
                        top:'96%',

                        // zoomLock:true, // 是否只平移不缩放
                        moveOnMouseMove:true, //鼠标移动能触发数据窗口平移
                        // zoomOnMouseWheel :true, //鼠标移动能触发数据窗口缩放
                    },
                    {
                        type: 'inside',
                        show: true,
                        height: 5,
                        // start: 1,
                        // end: 35
                    },

                ],


                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                // 边距
                grid: {
                    top:'5%',
                    left: '0%',
                    right: '0%',
                    bottom: '20%',
                    containLabel: true
                },
                // legend: {
                //     data: ['Evaporation', 'Precipitation', 'Temperature'],
                //     textStyle:{
                //         fontSize:'12',
                //         color:"#fff"
                //     },
                // },
                xAxis: [
                    {
                        type: 'category',
                        data: data?.date,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        // splitLine: {
                        //     lineStyle: {
                        //         // 设置背景横线
                        //         color: 'red'
                        //     }
                        // },
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    //左
                    {
                        type: 'value',
                        // name: '左侧',
                        // min: this.state.rightData_3.yData_left?.min,
                        // max: this.state.rightData_3.yData_left?.max,
                        // interval: 0.2,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        axisLabel: {
                            formatter: '{value} ',
                        }
                    },
                    //右
                    {
                        type: 'value',
                        // name: '右侧',
                        // min: this.state.rightData_3.yData_right?.min,
                        // max: this.state.rightData_3.yData_right?.max,
                        // interval: 0.5,
                        axisLine:{
                            lineStyle:{
                                color:"#93a0a8"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                // 设置背景横线
                                color: '#8fa4ac',

                            }
                        },
                        axisLabel: {
                            formatter: '{value} '
                        }
                    }
                ],
                series: [
                    {
                        name: '压力',
                        type: 'line',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value ;
                            }
                        },
                        data: data.xdata
                    },

                    {
                        name: '温度',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value ;
                            }
                        },
                        data: data.ydata
                    }
                ]
            }
        )
    };

    getconsole=(devId)=>{

    };

    queryPointDetailById=(pointId)=>{
        const params = new URLSearchParams();
        params.append('pointId', pointId);
        queryPointDetailById(params).then(
            response=>{
                if(response?.data?.data){

                    this.setState({queryPointDeta:response?.data?.data})
                }else {
                    message.info("获取信息失败")
                }

            }
        )
    };
    // 查询类型按钮事件
    buttonOnClick=(num)=>{
        let buttonClickType = this.state.buttonClickType;
        // 与之前数据相同不做处理
        if(buttonClickType.currentNum===num){
            return false;
        }
        // 之前选项切换成未点击样式，现在点击的切换点击样式,并且覆盖记录当前点击位置的数据
        buttonClickType.buttonType[buttonClickType.currentNum]='linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)';
        buttonClickType.buttonType[num]='#007c88';
        buttonClickType.currentNum=num;
        var getMapParam = this.state.getMapParam;
        getMapParam.pointType=num===1?'正常':num===2?'故障':'全部';
        this.getData(getMapParam);
        this.setState({
            buttonClickType:buttonClickType,
            getMapParam:getMapParam

        })


    };

    getPipelineRouteShow=()=>{
        var {pipelineRouteData,newPipelineRouteData} = this.state;
        pipelineRouteData.map(one=>{
            var params=[];
            one.map(two=>{
                var param = this.bMapTransQQMap(two.coord[0],two.coord[1]);
                params.push([param.lng,param.lat])
            });
            newPipelineRouteData.push(params);
        });
        this.setState({newPipelineRouteData:newPipelineRouteData})
        // //
        // var params = [
        //     [120.098109,31.212311],
        //     [117.518308,31.969555],
        //     [116.329812,31.823006],
        //     [113.363503,30.68583],
        //     [114.861589,28.969429],
        //     [111.937253,28.158361],
        // ];
        // var params = [
        //     [113.39034, 28.232227],[113.388004, 28.232449]
        // ];
        // {"coord": [113.39034, 28.232227]},
        // {"coord": [113.388004, 28.232449]}
        // return<>
        //     <Polyline
        //         akey={'1'}
        //         zIndex={3}
        //         visiable={true}
        //         strokeColor={"yellow"}
        //         strokeOpacity={1}
        //         strokeWeight={4}
        //         path={params}
        //     />
        // </>;
        // var title=null;
        // for(var i = 0; i<pipelineRouteData.length; i++ ){
        //     var params=[];
        //     pipelineRouteData[i].map(one=>{
        //         var param = this.bMapTransQQMap(one.coord[0],one.coord[1]);
        //
        //         params.push([param.lng,param.lat])
        //     });
        //     console.log('-----------------'+i+'----------------');
        //     console.log(params);
        //     title+=<Polyline
        //         akey={i}
        //         zIndex={3}
        //         visiable={true}
        //         strokeColor={"yellow"}
        //         strokeOpacity={1}
        //         strokeWeight={4}
        //         path={params}
        //     />
        // }
        // console.log(title);
        // return title;
    };

    render(){
        const {position,queryPointDeta,selectPosition} = this.state;
        return(
            <div id={"baiduMap"} style={{width: '100%',height: '100%'}}>

                <div style={{  width: '100%',height: '7%'}}>
                    <div style={{'width':'10%','height':'80%',float:'left',marginRight:'1%', textAlign: 'center',cursor:'pointer',paddingTop:'0.5%',borderRadius: '5px',
                        background: this.state.buttonClickType.buttonType[0],
                        color:'#fff',fontSize:'15px',  border: '1px #003943 solid'}}
                         onClick={()=>{this.buttonOnClick(0)}}
                    ><span>全部</span></div>
                    <div style={{'width':'10%','height':'80%',float:'left', marginRight:'1%', textAlign: 'center',cursor:'pointer',paddingTop:'0.5%',borderRadius: '5px',
                        background: this.state.buttonClickType.buttonType[1],
                        color:'#fff',fontSize:'15px',  border: '1px #003943 solid'}}
                         onClick={()=>{this.buttonOnClick(1)}}>
                        <span>正常</span>
                    </div>
                    <div style={{'width':'10%','height':'80%',float:'left', marginRight:'1%', textAlign: 'center',cursor:'pointer',paddingTop:'0.5%',borderRadius: '5px',
                        background: this.state.buttonClickType.buttonType[2],
                        color:'#fff',fontSize:'15px',  border: '1px #003943 solid'}}
                         onClick={()=>{
                             // this.setState({showMap:[116.397428, 39.90923]});
                             // this.setState({showMap:[]});
                             this.buttonOnClick(2)
                         }}>
                        <span>异常</span>
                    </div>
                    {/*<div style={{'width':'10%','height':'80%',float:'left', marginRight:'1%', textAlign: 'center',cursor:'pointer',paddingTop:'0.5%',borderRadius: '5px',*/}
                    {/*    background: this.state.buttonClickType.buttonType[3],*/}
                    {/*    color:'#fff',fontSize:'15px',  border: '1px #003943 solid'}}*/}
                    {/*     onClick={()=>{this.buttonOnClick(3)}}>*/}
                    {/*    <span>超期未检定</span>*/}
                    {/*</div>*/}
                    {/*<div style={{'width':'10%','height':'80%',float:'left', marginRight:'1%', textAlign: 'center',cursor:'pointer',paddingTop:'0.5%',borderRadius: '5px',*/}
                    {/*    background: this.state.buttonClickType.buttonType[4],*/}
                    {/*    color:'#fff',fontSize:'15px',  border: '1px #003943 solid'}}*/}
                    {/*     onClick={()=>{this.buttonOnClick(4)}}>*/}
                    {/*    <span>超期未报废</span>(222, 222, 222, 0.05)*/}
                    {/*</div>*/}
                </div>
                {/*关闭按钮*/}
                <div className={this.state.mapSwithType?'mapSwithTypeOne':'mapSwithTypeTwo'}>
                    <span onClick={()=>{
                        this.setState({mapSwithType:this.state.mapSwithType?false:true,mapCssType:this.state.mapCssType?false:true})
                    }}>{this.state.mapSwithType?<FullscreenExitOutlined />:<FullscreenOutlined />}</span>
                </div>
                {/*提示信息*/}
                <div  className={this.state.mapSwithType?'SpinSwithTypeOne':'SpinSwithTypeTwo'}
                      style={{display: this.state.mapDisplay}}>
                    <Spin/><span>加载未完成</span>
                </div>
                <div className={this.state.mapCssType?'mapCssTypeOne':'mapCssTypeTwo'}>

                    <Map
                        ref={e => this.maps = e}
                        // 地图加载完启动
                        onComplete={()=>{
                            console.log('地图加载完启动');
                            this.setState({show:false});
                            this.getBounds(this.maps?.map.getBounds().northeast,this.maps?.map.getBounds().southwest);
                        }}
                        // 移动画面结束
                        onDragEnd={()=>{
                            console.log('移动画面结束');
                            //右上角坐标\左下角坐标
                            this.setState({show:false});
                            this.getBounds(this.maps?.map.getBounds().northeast,this.maps?.map.getBounds().southwest);
                        }}
                        滚动结束
                        onZoomEnd={()=>{
                            console.log('滚动结束');
                            //右上角坐标\左下角坐标
                            this.setState({show:false});
                            this.getBounds(this.maps?.map.getBounds().northeast,this.maps?.map.getBounds().southwest);

                        }}
                        zoom={14}// 初始画面层级this.state.showMap 15
                        // position={[116.397428, 39.90923]}
                        center={this.state.showMap}

                        mapStyle={'amap://styles/blue'}// 官方样式
                    >


                        {/* 管道图*/}
                        {this.state.newPipelineRouteData.map(one=>{
                            return(
                                <Polyline
                                akey={'1'}
                                zIndex={3}
                                visiable={true}
                                showDir={true}
                                dirColor={'red'}
                                // strokeColor={"red"}
                                strokeColor={"white"}
                                strokeOpacity={0.7}
                                strokeWeight={5}
                                path={one}
                                onMouseDown={(a,b,c)=>{
                                    console.log('-------------------');
                                    console.log(a);
                                    console.log(b);
                                    console.log(c);

                                }}
                            />
                            )
                        })}

                        {/*坐标*/}
                        {position.map(one=>{
                            return (
                                <Marker  title={one.id}
                                         position={new AMap.LngLat(
                                             this.bMapTransQQMap(one.longitude,one.latitude).lng,
                                             this.bMapTransQQMap(one.longitude,one.latitude).lat
                                         )}
                                         icon={
                                             new AMap.Icon({
                                                 imageColor:'red',
                                                 imageSize: new AMap.Size(25, 34),
                                                 // image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'
                                                 // image: '//webapi.amap.com/theme/v1.3/markers/b/mark_b.png'
                                                 image:one.state==='故障'?
                                                     '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png'
                                                     :
                                                     '//webapi.amap.com/theme/v1.3/markers/b/mark_b.png'

                                         })}
                                         label={{
                                             // 设置文本标注偏移量
                                             // offset: new AMap.Pixel(20, 20),
                                             // 设置文本标注内容
                                             content: one.customerName,
                                             // 设置文本标注方位
                                             direction: 'bottom'
                                         }}
                                         onClick={(evn) => {
                                             this.queryPointDetailById(one.id);
                                             selectPosition.longitude=this.bMapTransQQMap(one.longitude,one.latitude).lng;
                                             selectPosition.latitude=this.bMapTransQQMap(one.longitude,one.latitude).lat;
                                             this.setState({show:false});
                                             this.setState({show:true});
                                         }}
                                />
                            )
                        })}

                        {/*弹窗*/}
                        {this.state.show?
                            <InfoWindow
                                visiable={this.state.show}
                                isCustom={true}
                                position={ new AMap.LngLat(selectPosition.longitude,selectPosition.latitude)}
                                offset={{ x: 15, y: -30}}
                                closeWhenClickMap={()=>{
                                    this.setState({show:false});
                                    return true
                                }}
                            >
                                <div style={{width: '500px',height:'350px'}}>
                                    <div style={{
                                        width:'100%',
                                        height: '100%',
                                        padding:'1.5% ',
                                        margin:'2px',float: "left",
                                        backgroundColor: '#e3edfc',
                                        borderRadius: '15px'
                                    }}
                                    >
                                        <div style={{
                                            width:'47%',
                                            height: '44%',
                                            backgroundColor: '#fff',
                                            margin:'1.5% ',
                                            padding:'2%',
                                            float:'left'
                                        }}>
                                            <div style={{ width: '80%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>
                                                <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●客户信息</span>
                                            </div>
                                            <div>
                                                <span style={{fontSize:'10px'}}><UserOutlined /></span><span style={{color:'#969d9d'}}>客户名：</span><span style={{fontSize:'10px'}}>{queryPointDeta.customerName}</span>
                                            </div>
                                            <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />
                                            <div>
                                                <span style={{fontSize:'10px'}}><EnvironmentOutlined /></span><span style={{color:'#969d9d'}}>安装地址：</span><span style={{fontSize:'10px'}}>{queryPointDeta.address}</span>
                                            </div>
                                            <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />
                                            <div>
                                                <span style={{fontSize:'10px'}}><MacCommandOutlined /></span><span style={{color:'#969d9d'}}>设备类型：</span><span style={{fontSize:'10px'}}>{queryPointDeta.className}</span>
                                            </div>
                                            <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />
                                            <div>
                                                <span style={{fontSize:'10px'}}><ProfileOutlined /></span><span style={{color:'#969d9d'}}>表刚号：</span><span style={{fontSize:'10px'}}>{queryPointDeta.stencilNum}</span>
                                            </div>
                                        </div>
                                        <div style={{
                                            width:'47%',
                                            height: '44%',
                                            backgroundColor: '#fff',
                                            margin:'1.5% ',
                                            padding:'2%',
                                            float:'left'
                                        }}>
                                            <div style={{ width: '80%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>
                                                <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●实时数据</span>
                                            </div>
                                            <div style={{ width: '100%',height:'15%'}}>
                                                <div style={{ width: '50%',float:'left'}}>
                                                    <span style={{fontSize:'10px'}}><FireOutlined /></span><span style={{color:'#969d9d'}}>温度：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimeTemp).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '50%',float:'left'}}>
                                                    <span style={{fontSize:'10px'}}><StockOutlined /></span><span style={{color:'#969d9d'}}>瞬时：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimeFlow).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div style={{width: '100%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />
                                            <div style={{ width: '100%',height:'15%'}}>
                                                <div style={{ width: '50%',float:'left'}}>
                                                    <span style={{fontSize:'10px'}}><ProjectOutlined /></span><span style={{color:'#969d9d'}}>压力：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimePre).toFixed(2)}</span>
                                                </div>
                                                <div style={{ width: '50%',float:'left'}}>
                                                    <span style={{fontSize:'10px'}}><GoldOutlined /></span><span style={{color:'#969d9d'}}>累计：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimeAcc).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            {/*虚线*/}
                                            <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />
                                            <div>
                                                <span style={{fontSize:'10px'}}><DownCircleOutlined /></span><span style={{color:'#969d9d'}}>电池电压：</span><span style={{fontSize:'10px'}}>{queryPointDeta.realTimeVoltage}</span>
                                            </div>
                                            {/*虚线*/}
                                            <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />
                                            <div>
                                                <span style={{fontSize:'10px'}}><ClockCircleOutlined /></span><span style={{color:'#969d9d'}}>采集时间：</span><span style={{fontSize:'10px'}}>
                                                {moment(queryPointDeta.time).format("yyyy-MM-DD HH:mm:ss")}
                                            </span>
                                            </div>
                                        </div>


                                        <div style={{
                                            width:'47%',
                                            height: '44%',
                                            backgroundColor: '#fff',
                                            margin:'1.5% ',
                                            float:'left',
                                            padding:'2%',
                                        }}>
                                            <div style={{ width: '90%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>
                                                <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●7日用量数据</span>
                                            </div>
                                            <div id={'dosage'} style={{ width: '100%',height: '100%'}}>
                                                <a onClick={()=>{this.getElementDosageData('dosage',queryPointDeta?.dosage,'bar')}}>获取数据</a>
                                            </div>
                                        </div>
                                        <div style={{
                                            width:'47%',
                                            height: '44%',
                                            backgroundColor: '#fff',
                                            margin:'1.5% ',
                                            float:'left',
                                            padding:'2%',
                                        }}>
                                            <div style={{ width: '90%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>
                                                <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●今日温压明细</span>
                                            </div>
                                            <div id={'details'} style={{ width: '100%',height: '100%'}}>
                                                <a onClick={()=>{this.getElementDetailsData('details',queryPointDeta.details,'line')}}>获取数据</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </InfoWindow>:null
                        }

                        {/*弹窗*/}
                        {/*{this.state.show? (*/}

                        {/*    <InfoWindow*/}
                        {/*        isCustom={true}*/}
                        {/*        position={ new AMap.LngLat(selectPosition.longitude,selectPosition.latitude)}*/}
                        {/*        offset={{ x: 15, y: -30}}*/}
                        {/*        closeWhenClickMap={()=>{*/}
                        {/*            this.setState({show:false});*/}
                        {/*            return true*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div style={{width: '500px',height:'350px'}}>*/}
                        {/*            <div style={{*/}
                        {/*                width:'100%',*/}
                        {/*                height: '100%',*/}
                        {/*                padding:'1.5% ',*/}
                        {/*                margin:'2px',float: "left",*/}
                        {/*                backgroundColor: '#e3edfc',*/}
                        {/*                borderRadius: '15px'*/}
                        {/*            }}*/}
                        {/*            >*/}
                        {/*                <div style={{*/}
                        {/*                    width:'47%',*/}
                        {/*                    height: '44%',*/}
                        {/*                    backgroundColor: '#fff',*/}
                        {/*                    margin:'1.5% ',*/}
                        {/*                    padding:'2%',*/}
                        {/*                    float:'left'*/}
                        {/*                }}>*/}
                        {/*                    <div style={{ width: '80%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>*/}
                        {/*                        <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●客户信息</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div>*/}
                        {/*                        <span style={{fontSize:'10px'}}><UserOutlined /></span><span style={{color:'#969d9d'}}>客户名：</span><span style={{fontSize:'10px'}}>{queryPointDeta.customerName}</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />*/}
                        {/*                    <div>*/}
                        {/*                        <span style={{fontSize:'10px'}}><EnvironmentOutlined /></span><span style={{color:'#969d9d'}}>安装地址：</span><span style={{fontSize:'10px'}}>{queryPointDeta.address}</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />*/}
                        {/*                    <div>*/}
                        {/*                        <span style={{fontSize:'10px'}}><MacCommandOutlined /></span><span style={{color:'#969d9d'}}>设备类型：</span><span style={{fontSize:'10px'}}>{queryPointDeta.className}</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />*/}
                        {/*                    <div>*/}
                        {/*                        <span style={{fontSize:'10px'}}><ProfileOutlined /></span><span style={{color:'#969d9d'}}>表刚号：</span><span style={{fontSize:'10px'}}>{queryPointDeta.stencilNum}</span>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div style={{*/}
                        {/*                    width:'47%',*/}
                        {/*                    height: '44%',*/}
                        {/*                    backgroundColor: '#fff',*/}
                        {/*                    margin:'1.5% ',*/}
                        {/*                    padding:'2%',*/}
                        {/*                    float:'left'*/}
                        {/*                }}>*/}
                        {/*                    <div style={{ width: '80%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>*/}
                        {/*                        <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●实时数据</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div style={{ width: '100%',height:'15%'}}>*/}
                        {/*                        <div style={{ width: '50%',float:'left'}}>*/}
                        {/*                            <span style={{fontSize:'10px'}}><FireOutlined /></span><span style={{color:'#969d9d'}}>温度：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimeTemp).toFixed(2)}</span>*/}
                        {/*                        </div>*/}
                        {/*                        <div style={{ width: '50%',float:'left'}}>*/}
                        {/*                            <span style={{fontSize:'10px'}}><StockOutlined /></span><span style={{color:'#969d9d'}}>瞬时：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimeFlow).toFixed(2)}</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    <div style={{width: '100%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />*/}
                        {/*                    <div style={{ width: '100%',height:'15%'}}>*/}
                        {/*                        <div style={{ width: '50%',float:'left'}}>*/}
                        {/*                            <span style={{fontSize:'10px'}}><ProjectOutlined /></span><span style={{color:'#969d9d'}}>压力：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimePre).toFixed(2)}</span>*/}
                        {/*                        </div>*/}
                        {/*                        <div style={{ width: '50%',float:'left'}}>*/}
                        {/*                            <span style={{fontSize:'10px'}}><GoldOutlined /></span><span style={{color:'#969d9d'}}>累计：</span><span style={{fontSize:'10px'}}>{parseFloat(queryPointDeta.realTimeAcc).toFixed(2)}</span>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                    /!*虚线*!/*/}
                        {/*                    <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />*/}
                        {/*                    <div>*/}
                        {/*                        <span style={{fontSize:'10px'}}><DownCircleOutlined /></span><span style={{color:'#969d9d'}}>电池电压：</span><span style={{fontSize:'10px'}}>{queryPointDeta.realTimeVoltage}</span>*/}
                        {/*                    </div>*/}
                        {/*                    /!*虚线*!/*/}
                        {/*                    <div style={{width: '99%',height: '1px',background:'#ebeef4',margin:'2% 0 2% 0'}} />*/}
                        {/*                    <div>*/}
                        {/*                        <span style={{fontSize:'10px'}}><ClockCircleOutlined /></span><span style={{color:'#969d9d'}}>采集时间：</span><span style={{fontSize:'10px'}}>*/}
                        {/*                        {moment(queryPointDeta.time).format("yyyy-MM-DD HH:mm:ss")}*/}
                        {/*                    </span>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}


                        {/*                <div style={{*/}
                        {/*                    width:'47%',*/}
                        {/*                    height: '44%',*/}
                        {/*                    backgroundColor: '#fff',*/}
                        {/*                    margin:'1.5% ',*/}
                        {/*                    float:'left',*/}
                        {/*                    padding:'2%',*/}
                        {/*                }}>*/}
                        {/*                    <div style={{ width: '90%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>*/}
                        {/*                        <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●7日用量数据</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div id={'dosage'} style={{ width: '100%',height: '100%'}}>*/}
                        {/*                        <a onClick={()=>{this.getElementDosageData('dosage',queryPointDeta?.dosage,'bar')}}>获取数据</a>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*                <div style={{*/}
                        {/*                    width:'47%',*/}
                        {/*                    height: '44%',*/}
                        {/*                    backgroundColor: '#fff',*/}
                        {/*                    margin:'1.5% ',*/}
                        {/*                    float:'left',*/}
                        {/*                    padding:'2%',*/}
                        {/*                }}>*/}
                        {/*                    <div style={{ width: '90%',height: '15%', background: 'linear-gradient(100deg, #6685c0, #fff 50%)',paddingLeft:'2px',margin:'0 0 2% 0'}}>*/}
                        {/*                        <span style={{color:'#fff',fontSize:'8px',  fontWeight: 'initial'}}>●今日温压明细</span>*/}
                        {/*                    </div>*/}
                        {/*                    <div id={'details'} style={{ width: '100%',height: '100%'}}>*/}
                        {/*                        <a onClick={()=>{this.getElementDetailsData('details',queryPointDeta.details,'line')}}>获取数据</a>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}

                        {/*    </InfoWindow>*/}
                        {/*):null}*/}
                    </Map>
                </div>
            </div>
        );
    }
}
export default baiduMap;
