import React,{Component} from "react";
import {Layout} from "antd";

// import  EZUIKit from 'ezuikit-js';
// 客户可视化数据大屏
export default class customerVisualDataInterfaceBox extends React.Component{
    state={
        href:"http://localhost:3000"
    };

    componentDidMount(): void {
        var href = window.location.href;
        var port = window.location.port;
        this.setState({
            href:href.substr(0,href.indexOf(port))+port

        });
    }
    transfer=()=>{
        window.open(this.state.href+"/dataPanel"); //云端
        // window.open("http://192.168.1.196:8989/dataPanel"); //196
        // window.open("http://192.168.1.218:8989/dataPanel"); //218
        // this.props.history.push({
        //     pathname:"/dataPanel",
        //     state:{
        //
        //     }
        // })
    };

    render() {
        return (
            <Layout  className={'item_body_number_back tables'}>
                <div style={{width: '100%', height: '100%',background: '#fff',padding:'20% 0 0 40%'}}>
                    <div
                        onClick={()=>{this.transfer()}}
                        style={{
                            width: '20%',
                            height: '50px',
                            paddingTop:'8px',
                            textAlign: 'center',
                            fontSize:'18px',
                            color:'#fff',
                            background:'#1890ff',
                            border:'1px #ccc solid',
                            borderRadius: '10px',
                            cursor:'pointer'}}>
                        <span>可视化数据面板平台</span>
                    </div>
                </div>

            </Layout>
        );
    }
}
