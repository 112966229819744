
import { Component } from 'react'
import {Layout, Tree, Button, message, Radio, Modal, Popconfirm, Spin} from "antd";
import { NavLink } from "react-router-dom"
import {
    getOrganizationTree,
    fuzzyQueryUser,
    listUserByOrganizationId,
    deleteUser,
    updateUserStatus,
    updatePassword, getNewTree
} from '../../../services/admin'
import '../../../style/luo.less'
import Pubsub from 'pubsub-js'
import React from 'react';
import moment from "moment";
import {TYPE_NAME} from '../../../components/Const'
import Conditions from '../../../components/common/conditions/Conditions'
import Tables from "../../../components/common/tables/Tables";
import {funTraceInfo} from '../../../services/common'
import {addOrUpdateAllocation, userBoundOrg} from "../../../services/IKit";
export default class AccountCenter extends Component {

    state={
        selectUserId:null,// 用户id
        tree_1:{treeData :[]},
        defaultCheckedKeys:[],
        selectDatas:[],
        requestState:1,
        modalVisible_1:false,
        modalVisible:false,
        // statusAndAlarmData:'左框第三个',  // 左-3文本
        responseInfo:{},
        treeId: [],
        deleteId:[],
            // 表格参数
            tableParamenter: {
              // 表格行是否可选
              rowSelection: true,
              // 表头：变量和变量解释
              header: {
                  username: '用户名',
                  realName: '真实姓名',
                  // organization: '组织机构',
                  positionName: '职位',
                  phone: '手机号码',
                  email:'邮箱',
                  // birthday: '生日',
                  createTime:'创建时间',
                  status: '状态',
                  // address:'地址',
                  workStatus: '工作状态',
                  operate:'操作',
              },
              // 下拉框：变量和变量解释
              headers: {
                username: '用户名',
                realName: '真实姓名',
                // organization: '组织机构',
                positionName: '职位',
                phone: '手机号码',
                email:'邮箱',
                // birthday: '生日',
                createTime:'创建时间',
                status: '状态',
                // address:'地址',
                workStatus: '工作状态',

              },
              // 表格数据
              data: [],
            },// 表格参数END
        expandedKeys: [],
        checkedKeys:[],
        selectedKeys:[],
        autoExpandParent:[],
        tree: {
          treeData :[
          {
            title: 'name',
            key: 'id',

            children: [
              {
                title: 'name',
                key: 'id',

                children: [
                  { title: 'name', key: 'id'},
                ],
              },
            ],
          },
        ]
      },
      // 条件选择器参数
		conditionsParamenter: {
			// 是否显示条件标题
			isLable: true,
			// 是否显示清空按钮
			clearButton: true,

			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'username',
					str: '用户名'
				},

			]
		},
    	// 总条数
		totalCount: 0,

		params: {
      pageInfo: {
        pageIndex:1,
        pageLine:10,

      },
      username: null,
      // organizationId :this.state.treeId
			// areaId: localStorage.getItem('menus')
		},
    // 表格加载动画
		loading: false,
		// 刷新动画
		refreshTable: false,
        rowData: [],
        // data: {}
    };

    async  componentDidMount() {
      this.getTreeNode();

    }


	getAreaId = () => {
		Pubsub.subscribe('areaIds', (msg, data) => {
			let {params} = this.state
			params.areaIds = data
			params.page = 1
			params.size = 15
			this.setState({params})

		})
	}

  // 组件将要被卸载时,取消订阅
	componentWillUnmount() {
		Pubsub.unsubscribe('areaId')
	}



	// 表格：刷新方法
	refreshTable = () => {
		// this.setState({refreshTable: true})
		this.getData();
		this.setState({loading:false})
        // funTraceInfo("权限管理", "刷新账户信息", this.state.params.pageInfo, this.state.tableParamenter.data)
	};

	// 表格：获取勾选中的值
	onSelectChange = data => {
      let ss=data[0].status
    if(ss==='可用') {
      this.setState({rowData: data,deleteId: data})
    }else {
      this.setState({rowData: data,deleteId: data})
      message.warning('该用户已被禁用',[0.5])
    }
	}

	// 表格：导出
	exportTable = () => {
		console.log('数据导出')
	}

	// 条件选择：获取输入的条件
	getConditionsValue = value => {
    let key = this.state.treeId;
    if(key.length === 0 ) return message.warning('未选择组织部门',[0.5])
		// let {params} = this.state
		// params.userName = value.userName
    let {params} = this.state;
		params.username = value.username;
    this.setState({params});
    let param = {
      pageInfo: this.state.params.pageInfo,
      organizationId: this.state.treeId,
      username:this.state.params.username
    };
    param.pageInfo.pageIndex=1;
    console.log('获取选中的值:',param);
    fuzzyQueryUser(param).then(
      response => {
        const {data} = response.data
        if(data === null) {
          message.warning("该条件下不存在用户信息",[0.5])
          let {tableParamenter} = this.state
          tableParamenter.data = data
          const {pageInfo} = response.data
          let total= pageInfo.total
          this.setState({tableParamenter,totalCount:total})
        }
        let {tableParamenter} = this.state
        tableParamenter.data = data
        const {pageInfo} = response.data
        let total= pageInfo.total
        this.setState({tableParamenter,totalCount:total})
        message.success('查询成功',[0.5])
        // funTraceInfo("权限管理", "模糊查询账户信息", param, tableParamenter.data)
      }
    )

	}



	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
		let {params} = this.state
		params.pageInfo.pageIndex = page
		params.pageInfo.pageLine = pageSize
		this.setState({params})
    if(this.state.username == null){
      this.getData()
    }else{
      this.getDatas()
    }
		// this.getData()
	}

  getDatas = () => {
    let param = {
      pageInfo: this.state.params.pageInfo,
      organizationId: this.state.treeId,
      username:this.state.params.username
    }
    console.log('para',param);
    fuzzyQueryUser(param).then(
      response => {
        const {data} = response.data
        console.log('22222222222', data);
        const {pageInfo} = response.data
        console.log('分页111111111111', pageInfo);
        let total= pageInfo.total

        let {tableParamenter} = this.state
        tableParamenter.data = data
        this.setState({tableParamenter,totalCount:total})
        message.success('查询成功',[0.5])
        console.log('sssssssss',this.state.totalCount);
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        })
        message.error('查询失败',[0.5])
      }
    )
  }

// 清空条件
clearCondition = () => {
  let {params} = this.state
  params.username = null
  this.setState({params})
  if(this.state.username == null){
    this.getData()
  }else{
    this.getDatas()
  }
}


    //展开/收起节点时触发
     onExpand = (expandedKeysValue) => {
      console.log('onExpand', expandedKeysValue);
      this.setState({
          expandedKeys: expandedKeysValue,
          autoExpandParent: false
        });
    };

    //点击复选框触发
    onCheck = (checkedKeysValue) => {
      console.log('onCheck', checkedKeysValue);
    //点击树节点触发
      this.setState({
        checkedKeys: checkedKeysValue,
      });
    };

// 初始化表格数据
getData = () => {
  this.setState({loading: true});
  let param = {
      userId:JSON.parse(sessionStorage.getItem("user")).id,
      pageInfo: this.state.params.pageInfo,
      organizationId: this.state.treeId
  };
  listUserByOrganizationId(param).then(
    response => {
      const {data} = response.data;
      const {pageInfo} = response.data;
      let total= pageInfo.total;
      let {tableParamenter} = this.state;
      tableParamenter.data = data;
      this.setState({tableParamenter,totalCount:total,loading: false});
      message.success('查询成功',[0.5])
    }
  ).catch(
    () => {
      this.setState({
        loading: false,
        refreshTable: false,
      });
      message.error('查询失败',[0.5])
    }
  )
};
    //点击树节点触发
     onSelect = (selectedKeysValue, info) => {
      console.log('onSelect', selectedKeysValue,info,info.node.key);
      let key = info.node.key;
      console.log(JSON.parse(sessionStorage.getItem("user")));
      let param = {
          userId:JSON.parse(sessionStorage.getItem("user")).id,
        pageInfo: {
          pageIndex:1,
          pageLine:10,
          total: null,
        },
        organizationId: key
      }
      console.log('ssss', param);
      // console.log('ssss', param2);
      listUserByOrganizationId(param).then(
        response => {
          const {data} = response.data
          console.log('onSelect11111111', data);
          if(data === null){
            message.warning('未查询到该组织机构下的数据',[0.5])
            const {pageInfo} = response.data
            console.log('分页', pageInfo);
            let total= pageInfo?.total
            console.log("total",total)
            let {tableParamenter} = this.state
            tableParamenter.data = data
            this.setState({tableParamenter,totalCount:total})
          }else{
            const {pageInfo} = response.data
          console.log('分页', pageInfo);
          let total= pageInfo?.total
          console.log("total",total)
          let {tableParamenter} = this.state
			  	tableParamenter.data = data
          this.setState({tableParamenter,totalCount:total})
          message.success('查询成功')
          }

        }
      )
      this.setState({treeId:key});
    };

    //用户注销
    deleteAccount = data =>{
      if(this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
      let ids=this.state.deleteId
      let ss=ids[0].id
       let param = {
        userId: ss,
        organizationId:this.state.treeId,
        ids
  }
      console.log("注销用户带的参数",param)
      Modal.confirm({
        title: `是否确认注销用户？`,
        content: '',
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk: () => {
          // 注销用户
          deleteUser(param).then(
            response => {
              this.setState({
                loading: false,
                refreshTable: false,
                totalCount: data.total,
            });
            message.success('用户注销成功',[1])
            this.getData();
            // funTraceInfo("权限管理", "注销用户", param, null)
            }
          ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('用户注销失败',[1])
                this.getData();
            }
        )
        }
      })
    }

    //变更状态
    updateStatus=()=>{
      if(this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
      let status =this.state.rowData[0].id
      let param={
          userId :status,
          status:1
      };
      console.log("状态",status);
      updateUserStatus(param).then(
        response => {
          this.setState({
            loading: false,
            refreshTable: false,
            // totalCount: data.total,
        });
        message.success('用户状态更改成功',[1])
        this.getData();
        // funTraceInfo("权限管理", "状态变更", param, null)
        }
      ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('用户状态更改失败',[1])
            this.getData()
        }
    )
    }

    //修改
    updateInfo = () => {
    if(this.state.rowData.length < 1) return message.warning('未选择组织部门或数据',[0.5])

		this.props.history.push({
			pathname: '/auth/updateInfo',
			state: this.state.rowData[0]
		})
	}



  getTreeNode =()=>{//获取树节点
    this.setState({loading: true});
    // 新
    getNewTree({userId:JSON.parse(sessionStorage.getItem("user")).id}).then(
          reponse => {
              console.log(reponse.data.responseInfo.code);
              if(reponse.data.responseInfo.code!==200){
                  return message.info('未有组织结构树数据');
              }
              let { data } = reponse.data;
              let { responseInfo, tree } = this.state;
              responseInfo.data = data;
              tree.treeData = data;
              this.setState({
                  loading: false,
                  refreshTable: false,
                  totalCount: data.total,
                  responseInfo: responseInfo,
                  tree
              });
              message.success('查询成功',[0.5])
              // funTraceInfo("权限管理", "组织结构查询", null, tree)
          }
      ).catch((e) => {
              this.setState({loading: false, refreshTable: false,});

              message.error('查询异常，请重新刷新页面',)
          }
      );



//// 旧
  //   getOrganizationTree(this.state.responseInfo).then(
  //     reponse=>{
  //         let {data} = reponse.data;
  //         let {responseInfo,tree} = this.state;
  //         responseInfo.data = data;
  //         tree.treeData = data
  //         this.setState({
  //             loading: false,
  //             refreshTable: false,
  //             totalCount: data.total,
  //             responseInfo: responseInfo,
  //             tree
  //         });
  //         message.success('查询成功',[0.5])
  //     }
  // ).catch(
  //     () => {
  //         this.setState({
  //             loading: false,
  //             refreshTable: false,
  //         });
  //         message.error('查询失败',[0.5])
  //     }
  // )
  };
    //递归
    getTreeData_1 = data => {
        data = Array.isArray(data) ? data : [];
        let tree = data?.map(value => {
            return {
                title: value?.name,
                key: value?.id,
                parent: value?.parentId,
                // dataIndex:value?.positions?.name,
                disabled: false,
                children: this.getTreeData_1(value?.children)
            }
        });
        return tree
    };
  //递归
  getTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(value => {
        return {
          title:value?.nodeExtend?.name,
          key:value?.nodeExtend?.id,
          // dataIndex:value?.positions?.name,
          disabled: false,
          children: this.getTreeData(value?.children)
        }
    });
    return tree
  };

  getOrganizationTree=()=>{
      this.setState({requestState:1});
      // 新
      getNewTree().then(
          reponse => {
              if(reponse.data.data){
                  if(reponse.data.responseInfo.code!==200){
                      return message.info('未有组织结构树数据');
                  }
                  let { data } = reponse.data;
                  let { tree_1 } = this.state;
                  // selectDatas
                  tree_1.treeData = data;
                  this.setState({
                      requestState:3,
                      tree_1
                  });
              }else{
                  this.setState({requestState:2});
              }

          }
      ).catch((e) => {
          this.setState({requestState:2});
          }
      );
  };
  // 获取用户绑定的组织结构
    userBoundOrg=(userId)=>{
        userBoundOrg({userId:userId}).then(
            reponse=>{
                this.setState({selectDatas:reponse.data.data})
            }
        )
    };


  setTableValue = (value,text,rowData) => {
    switch (value) {
        case 'createTime':
            text =moment(text).format('YYYY-MM-DD HH:mm:ss');
           return(
               <span>{text}</span>
           )
          case 'operate':
           return <>
               {/*<NavLink to={{pathname:'/auth/customerAuth',state:rowData}}>*/}
               {/*    <Button  type="primary" >客户权限</Button>*/}
               {/*</NavLink>*/}
               <div style={{width:'340px'}}>
                   <div style={{float: 'left'}}>
                       <div>
                           <Button
                               type="primary"
                               onClick={()=>{
                                   this.setState({selectUserId:rowData.id,selectDatas:[]});
                                   this.getOrganizationTree();// 刷新获取区域树
                                   this.userBoundOrg(rowData.id);// 获取当前用户操作的数据
                                   this.setState({modalVisible_1:true});
                               }}
                           >组织机构</Button>
                       </div>
                   </div>
                   <div style={{paddingLeft:'10px',float: 'left'}}>
                       <NavLink to={{pathname:'/auth/customerAuth',state:rowData}}>
                           <div title={'安装点'}>
                               <Button  type="primary" >客户权限</Button>
                           </div>
                       </NavLink>
                   </div>
                   <div style={{paddingLeft:'10px',float: 'left'}}>
                       <Popconfirm title="是否初始化密码?"
                                   onConfirm={()=>{this.updatePassword(rowData?.id)}}
                           // onCancel={cancel}
                                   okText="是" cancelText="否">
                           <Button  type="primary" >重置密码</Button>
                       </Popconfirm>
                   </div>
               </div>
				</>
           default:
            return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
    }
};

    updatePassword=(userId)=>{
        var param={
            "id": userId,
            "password": "Aa1234567@"
        };
        updatePassword(param).then(
            reponse=>{
                console.log(reponse?.data.data);
                if(reponse?.data?.data){
                    this.setState({modalVisible:true})
                }else{
                    message.info('重置失败',2)
                }
            }
        )
    };



    // 给用户新增或修改组织机构
    addOrUpdateAllocation=()=>{
        console.log('给用户新增或修改组织机构');
        var selectDatas = this.state.selectDatas;
        console.log(selectDatas);
        if(selectDatas.length===0){return message.info('请选择数据');}
        var params={
            "orgIds": selectDatas,
            "userId": this.state.selectUserId
        };
        addOrUpdateAllocation(params).then(
            request=>{
                if(request?.data){
                    if(request.data.responseInfo.code===200){
                        message.info('操作成功');
                        this.setState({modalVisible_1:false});
                    }else{
                        message.info('操作失败')
                    }
                }
                this.setState({defaultCheckedKeys:[]});
            }).catch(e=>{ message.info('异常问题，请重新尝试');})
    };

    render() {
        return (
            <Layout  className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={'equipment_frame'}>
                    <div className={'left_table'}>
                    <div className={'particulars_info_div'}>
                            <span className="title">组织结构：</span>
                            <div className={'textBox_particulars_info_div'}>

                            <Tree

                                treeData={this.getTreeData_1(this.state.tree.treeData)}
                                // treeData={this.getTreeData(this.state.tree.treeData)}
                                getTreeNode={this.getTreeNode}
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={this.state.autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                onSelect={this.onSelect}
                                selectedKeys={this.state.selectedKeys}
                            />
                            </div>

                    </div>
                    </div>

                         <div className={'right_table'}>
				             <div className={'aTable_info_div'} >
                     <div className={'poes'}>
                     <Tables
                     tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
                     exportTable={this.exportTable}                  // 导出时触发的方法
                     onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                     refreshTable={this.refreshTable}                // 刷新时触发的方法
                     getPagination={this.getPagination}              // 分页器变化时触发的方法
                      setTableValue={this.setTableValue}              // 设置表格显示

                    pagination={{
                      totalCount: this.state.totalCount, // 总条数
                      page: this.state.params.pageInfo.pageIndex, // 当前页数
                      size: this.state.params.pageInfo.pageLine // 页面条数
                    }}

                    loading={{
                        loading: this.state.loading,
                        refreshTable: this.state.refreshTable
                    }}
                >
                 <Conditions
						conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
						getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
            clearCondition={this.clearCondition} // 清除条件
					>
						<Radio.Group defaultValue="addAccount" buttonStyle="solid">
							<NavLink to="/auth/addAccount">
								<Radio.Button value="addAccount">新增账户信息</Radio.Button>
							</NavLink>
              <Radio.Button value="deleteAccount"  onClick={this.deleteAccount}>注销用户</Radio.Button>
							<Radio.Button value="updatePost"  onClick={this.updateInfo}>修改账户信息</Radio.Button>
              <Radio.Button value="updateStatus"  onClick={this.updateStatus}>状态变更</Radio.Button>
						</Radio.Group>
					</Conditions>
				</Tables>
        </div>
                </div>
                </div>
                </div>
                <div>
                    <Modal
                        title={'重置密码'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible}
                        okText="确定"
                        onOk={() => this.setState({modalVisible:false})}           // 点击确定
                        onCancel={() => this.setState({modalVisible:false})}       // 点击取消
                        width={280}
                        centered
                    >
                        <p>重置密码成功，密码：Aa1234567@</p>
                        <p></p>
                    </Modal>

                    <Modal
                        maskClosable={false}
                        title={'组织结构'}
                        style={{ top: 0 }}
                        visible={this.state?.modalVisible_1}
                        okText="确定"
                        onOk={() => this.addOrUpdateAllocation()}           // 点击确定
                        onCancel={() => this.setState({modalVisible_1:false,defaultCheckedKeys:[]})}       // 点击取消
                        width={500}
                        centered
                    >
                        <div  style={{width:'100%',height:'300px'}}>
                            {this.state.requestState!==3?
                                <div style={{
                                    width:'100%',
                                    height:'100%',
                                    textAlign:'center',
                                    paddingTop:'30%',
                                    opacity:'0.3',
                                    backgroundColor:'#000000'
                                }}>
                                    {
                                        this.state.requestState===1?
                                            <div>
                                                <Spin/><span style={{color:'#fff'}}>数据请求中</span>
                                            </div>
                                            :
                                            <div>
                                                <span style={{color:'#fff'}}>暂无获取数据，请重新刷新</span>
                                                <Button type={'primary'}
                                                        onClick={()=>{this.getOrganizationTree()}}
                                                >刷新
                                                </Button>
                                            </div>
                                    }
                                </div>
                                :
                                <div style={{  width:'100%', height:'100%',  overflowY: 'auto'}}>
                                    <Tree
                                        checkable
                                        checkStrictly={true}
                                        key={'id'}
                                        treeData={this.getTreeData_1(this.state.tree_1.treeData)}// 新
                                        // expandedKeys={this.state.defaultCheckedKeys}
                                        checkedKeys={this.state.selectDatas}
                                        onExpand={()=>{
                                            console.log('onExpand')
                                        }}
                                        checkChange={()=>{
                                            console.log('checkChange')
                                        }}
                                        onCheck={(selectedKeys, info,a)=>{
                                            // 当前只支持二层，需要多层要改

                                            // 检查当前有无父节点id,0为根节点不操作
                                            if(info.node.parent!==0){
                                                // 当前点击的根节点数据
                                                var data = this.state.tree_1.treeData.find((one)=>{ if(one.id===info.node.parent) return one; });
                                                // 判断复选框数据里有没有根节点
                                                if(selectedKeys.checked.find((one)=>{ if(one===info.node.parent) return true; })?true:false) {
                                                    console.log('有父节点');
                                                    // 有id判断是否删除操作，
                                                    if (!info.checked) {
                                                        var juder=true;// 本要删除根节点，但需要判断是否还有其他一组的
                                                        // 循环根节点内的子节点判断复选框有无其他子节点
                                                        data.children.map(one => {
                                                            selectedKeys.checked.map(two =>{
                                                                if(two===one.id){
                                                                    console.log('还有子节点在');
                                                                    juder=false;
                                                                }
                                                            });
                                                        });
                                                        // 子节点不存在后删除父节点
                                                        // if(juder){
                                                        //     console.log('删除父节点');
                                                        //     for(var i=0;i<selectedKeys.checked.length;i++){
                                                        //         if(selectedKeys.checked[i]===info.node.parent){
                                                        //             selectedKeys.checked.splice(i,1);
                                                        //         }
                                                        //     }
                                                        // }
                                                    }
                                                }else {
                                                    console.log('无父节点');
                                                    // 必须是添加才会操作,本身没有id删除操作也没用所以只有添加操作
                                                    if (info.checked) {
                                                        selectedKeys.checked.push(info.node.parent);

                                                    }
                                                }
                                            }else {
                                                console.log('当根节点取消删除其所有子节点');
                                                console.log(info);
                                                // 状态为false操作
                                                if (!info.checked) {
                                                    info.node.children.map(one => {
                                                        for(var i=0;i<selectedKeys.checked.length;i++){
                                                            if(selectedKeys.checked[i]===one.key){
                                                                selectedKeys.checked.splice(i,1);
                                                            }
                                                        }
                                                    })
                                                }
                                            }

                                            this.setState({selectDatas:selectedKeys.checked})
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </Modal>

                </div>
            </Layout>
        )
    }

}
