import React, { Component } from 'react'
import { Layout, Input, Button, Checkbox, TreeSelect, Select, Tag, Table, Cascader, message, Modal, } from "antd";
import './newConfigurePage.less'
import {funTraceInfo} from '../../../../services/common'
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import TableComponents from "../../../../components/common/tables/TableComponentsNew";
import {getTreeByNodeIds } from "../../../../services/area";
import { queryCustomerPurview, queryCustomerByCondition, removeRelationByUserId, appointCustomerPurview } from "../../../../services/customer";
import {getDateByUserId} from "../../../../services/admin";

const CheckboxGroup = Checkbox.Group;

// 客户权限
export default class CustomerAuth extends Component {

    state = {
        Input_name: this.props?.location?.headerChild?.configName,          // 配置名
        checkedList: [],         // 多选框-选中的值
        Input_1_value: [],       // 手写添加的值
        Input_2_value: [],       // 手写添加的值
        modalVisible_1: false,   // 开关
        customerList: [],        // 客户列表


        inputError: '',
        tableLeftCss: "",
        // 右表-客户表格参数
        tableParamenter_1: {

            manageName: "客户表格",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            header: {
                id: '客户号',
                industrialName: '商业客户名称',
                principal: '商业客户负责人',
                principalPhone: '商业客户电话	',
                residentsName: '居民客户名称',
                residentsPhone: '居民客户电话	',
                edit: '操作'
            },
            data: [],
            params: {
                areaIds: [260],
                bindingUser: false,
                customerName: null,
                page: 1,
                size: 9,
                type: 1,
                userId: this.props.location.state?.id
            },
            // params:{
            //     userId:this.props.location.state?.id
            // }
        },
        tableParamenter_2: [
            { title: '客户号', dataIndex: 'id', key: 'id', render: (_, record,) => <div><span>{record.id}</span></div> },
            { title: '工商客户名称', name: 'industrialName', key: 'industrialName', render: (_, record,) => <div><span>{record.industrialName}</span></div> },
            { title: '联系人', name: 'principal', key: 'principal', render: (_, record,) => <div><span>{record.principal}</span></div> },
            { title: '联系人电话', name: 'principalPhone', key: 'principalPhone', render: (_, record,) => <div><span>{record.principalPhone}</span></div> },
            // {title: '客户类型', name: 'type', key: 'type',
            //     render: (_,record,)=>
            //         <div>
            //             <span>{record.type*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : record.type*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : record.type*1 === 3 ? <Tag color={'#f5222d'}>区域</Tag>:''}
            //             </span>
            //         </div>},
            { title: '居民客户名称', name: 'residentsName', key: 'residentsName', render: (_, record,) => <div><span>{record.residentsName}</span></div> },
            { title: '居民电话', name: 'residentsPhone', key: 'residentsPhone', render: (_, record,) => <div><span>{record.residentsPhone}</span></div> },
            // {title: '联系人电话', name: 'principalPhone', key: 'principalPhone',render: (_,record,)=> <div><span>{record.principalPhone}</span></div>},
            {
                title: '操作', name: 'edit', key: 'edit'
                , render: (_, record) =>
                    <div className={'tableLink'} title={'删除'}
                        onClick={() => this.deleteLeftData(record)}
                    >
                        <u>{'删除'}</u>
                    </div>
            }
        ],

        // 条件查询栏
        // ----条件查询栏
        searchGetData: [],

        tableParamenter_2_data: [],  // 左表数据


        treeNodeInfo_1: [],        // 客户区域
        treeNodeInfo_1_value: [],  // 选中的客户区域

        associatedvalue: [],
        setAssociatedValue: [],
        filterparamList: [],
        tableParamenter_2_input_data: []
    };




    // 初始化加载
    componentDidMount() {
        let name = this.props.location.state ?? {};
        const dataAuthories = JSON.parse(sessionStorage.getItem("dataAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("dataAuthories"))

        this.treeNodeAll(dataAuthories);// 获取区域列表
        // 获取子表详情-左表数据
        this.queryCustomerPurview();
    }
    // 获取子表详情-左表数据
    queryCustomerPurview = () => {
        // userId: this.props.location?.state?.id,
        if (this.props?.location?.state?.id) {
            queryCustomerPurview({ userId: this.props.location?.state?.id }).then(
                response => {
                    const { data } = response.data;

                    this.setState({ tableParamenter_2_data: data });
                    message.success('查询成功', [0.5]);
                }).catch(
                    () => {

                        message.error('查询失败', [0.5]);
                    }
                )

        }
    };

    // 获取客户区域
    treeNodeAll = (data) => {
        // 新
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    console.log('--------------');
                    console.log(response?.data);
                    // this.state.topFormItem.parameter[0].data=response?.data;
                    this.setState({treeNodeInfo_1:response?.data});
                } else {
                    return message.info('获取不到数据');
                }
            }
        );
        // 旧
        // getTreeByNodeIds(data).then(
        //     response => {
        //         let {menus} = this.state.treeNodeInfo_1;
        //         menus = JSON.parse(response.data?.data);
        //         console.log(menus);
        //         this.setState({treeNodeInfo_1:menus});
        //     }
        // ).catch(e=>{return message.info("异常问题，请联系管理员")});
        // treeNodeAll().then(
        //     response => {
        //         let { menus } = this.state.treeNodeInfo_1;
        //         menus = JSON.parse(response.data?.data);
        //         this.setState({ treeNodeInfo_1: menus });
        //     }
        // )
    };

    // 加入区域下所有对象
    addAllCustomerName = () => {
        var treeNodeInfoValue = this.state.treeNodeInfo_1_value;
        if (treeNodeInfoValue.length === 0 || treeNodeInfoValue.constructor === Function) return message.warning('未选择区域', [0.5])
        var treeNodeInfo = this.state.treeNodeInfo_1;                   // 所有区域数据EW
        var areaIds = this.funareaIds(treeNodeInfo, treeNodeInfoValue);  // 选区的详情，参数：全部区域，所选区域不含子区域，返回整个区域详情包括子区域
        var areaIdsKey = [];
        areaIds.map(one => {
            areaIdsKey.push(one.id)
        });

        var params = {
            areaId: areaIdsKey,
            userId: this.props.location.state?.id,
            isSingle: false,
            type: 1
        }
        appointCustomerPurview(params).then(
            response => {
                funTraceInfo("二维码管理", "给用户指定客户权限", null,params)
                response.data.data ? message.success('分配成功', [0.5]) : message.error('分配失败', [0.5])
                this.queryCustomerPurview();
                this.getPageCustomerName();
            })

    };

    // getPageCustomerName_1 = (text) => {
    //     var params = this.state.tableParamenter_1;
    //     params.params.name = text;
    //     // return getPageCustomerName(params.params).then(
    //     //     (response) => {
    //     //         const data = response?.data?.data;
    //     //         if (data !== null) {
    //     //             message.info("已有客户添加，请先删除该客户");
    //     //             return false;
    //     //         }
    //     //     }
    //     // );
    // };

    // 刷新右表
    getPageCustomerName = async areaIds => {
        var tableParamenter = this.state.tableParamenter_1; // 右表数据结构
        await this.table.refreshTable(tableParamenter.params)
    };

    // // 用户选取地区后的操作
    treeSelectOnChange_1 = (text = String) => {
        console.log(text);
        this.setState({ treeNodeInfo_1_value: text });
        var treeNodeInfo = this.state.treeNodeInfo_1;       // 所有区域数据
        var tableParamenter = this.state.tableParamenter_1; // 右表数据结构
        tableParamenter.params.areaIds = [];
        var areaIds = this.funareaIds(treeNodeInfo, text);
        areaIds?.map(one => {
            tableParamenter.params.areaIds.push(one.id);
        });
        this.setState({ tableParamenter_1: tableParamenter });
        this.getPageCustomerName();
    };


    // 用户选取地区后的操作-递归
    funareaIds = (data, text) => {
        var areaIds = [];
        data.map(one => {
            if (one.name === text) {
                areaIds.push(one);
                if (one?.children) {
                    one?.children.map(two => {
                        areaIds.push(two);
                    })
                }
            } else if (one?.children) {
                this.funareaIds(one?.children, text)?.map(
                    one => {
                        areaIds.push(one);
                    }
                )
            }
        });
        return areaIds;
    };



    // 右表删除功能
    deleteLeftData = (data) => {

        var params = {
            customerIdList: [data.id],
            userId: this.props.location.state?.id
        };
        removeRelationByUserId(params).then(
            response => {
                // funTraceInfo("二维码管理", "删除用户的客户权限", null,params)
                response.data.data ? message.success('删除成功', [0.5]) : message.error('删除失败', [0.5])
                // 获取子表详情-左表数据
                this.queryCustomerPurview();
                this.getPageCustomerName();
            });

    };

    // 数据检索
    dataRetrieval = (text = String) => {
        var text = text.target.value;
        console.log("rowdata", text)
        var tableParamenter = this.state.tableParamenter_2_data;
        var tableParamenter_2_input_data = this.state;
        tableParamenter_2_input_data = [];
        tableParamenter.forEach(one => {

            if (one?.residentsName?.indexOf(text) > -1 || one?.industrialName?.indexOf(text) > -1) {
                tableParamenter_2_input_data.push(one);
            }
        })
        this.setState({ tableParamenter_2_input_data, associatedvalue: text })
    };

    assignSingle = (rowData) => {
        console.log("rowdata", rowData);
        var params = {
            // areaId: areaIdsKey,
            isSingle:true,
            customerIdList: [rowData.id],
            userId: this.props.location.state?.id,
            // type:1
        };
        appointCustomerPurview(params).then(
            response => {
                // funTraceInfo("二维码管理", "appointCustomerPurview", null,params)
                response.data.data ? message.success('分配成功', [0.5]) : message.error('分配失败', [0.5]);
                this.queryCustomerPurview();
                this.getPageCustomerName();
            })

    };

    setTableValue_2 = (value, text, rowData) => {
        switch (value) {
            case 'id':
                return (
                    <div className={'table_overflow'} title={text}>
                        <span>{text}</span>
                    </div>
                );
            case 'edit':
                return <>
                    <Button type="primary" onClick={() => this.assignSingle(rowData)}>加入权限</Button>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    render() {

        return (
            <Layout className={'item_body_number_back tables'}>
                <div id={'newConfigurePage'}>
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>客户权限</span>
                        </div>
                    </div>
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div>
                        <div className={"formText_1_div"}>

                            <div className={"formText_1_installInner"}>
                                <ul>
                                    <li>用户名：<span>{this.props?.location?.state?.username}</span></li>
                                    <li>真实姓名：<span>{this.props?.location?.state?.realName}</span></li>
                                    <li>职位：<span>{this.props?.location?.state?.positionName}</span></li>
                                </ul>
                            </div>
                        </div>
                        {/*------------------------------------------*/}
                        <div className={"formText_1_div"}>
                            {/* <div>
                                <span style={{"width": "60%","font-size": "18px","font-weight": "bold"}}>推送信息</span>
                            </div> */}
                            <div className={"formText_1_installInner"}>
                                <ul>
                                    <li>手机号码：<span>{this.props?.location?.state?.phone}</span></li>
                                    <li>邮箱：<span>{this.props?.location?.state?.email}</span></li>
                                    <li>性别：<span>{this.props?.location?.state?.sex}</span></li>
                                </ul>
                            </div>
                        </div>
                        {/*------------------------------------------*/}

                    </div>
                    <div className={"top_separate_div"} />
                    {/*----分割线----/}
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div className={"bottom_div2"}>
                        <div className={"bottom_left_div"}>

                            <div style={{height:'65px'}}>
                                <span>客户权限</span>
                                <div>
                                    <Input
                                        value={this.state.associatedvalue}
                                        // onChange={(text = String) => {
                                        //     if (text.target.value !== '') {		//当value不为空时
                                        //         setFilterParamList([])
                                        //         setFilterParamList(
                                        //           data?.list?.filter(item => {
                                        //           //  name,displayName,paramValue为接口数据中属性（根据需求灵活变更）
                                        //           //  其中任一含有associatedvalue则通过筛选
                                        //             if (
                                        //               item?.name?.indexOf(associatedvalue) !== -1 ||
                                        //               item?.displayName?.indexOf(associatedvalue) !== -1 ||
                                        //               item?.paramValue?.indexOf(associatedvalue) !== -1
                                        //             ) {
                                        //               return true
                                        //             }
                                        //             return false
                                        //           }),
                                        //         )
                                        //       } else {	//为空时将渲染原始表格数据
                                        //         setFilterParamList(data?.list)
                                        //       }


                                        //     this.setState({ associatedvalue: text.target.value });
                                        // }}
                                        onChange={this.dataRetrieval}
                                        placeholder="请输入客户名称"
                                        allowClear
                                        style={{ marginBottom: '2px', width: 350 }}
                                        prefix={<SearchOutlined style={{ color: '#DEE0E8' }} />}
                                    />
                                </div>
                            </div>
                            <div style={{height:'580px',  overflowY: 'auto'}}>
                                <Table
                                    columns={this.state.tableParamenter_2}
                                    // dataSource={this.state.tableParamenter_2_data}
                                    dataSource={this.state.tableParamenter_2_input_data.length === 0 ? this.state.tableParamenter_2_data : this.state.tableParamenter_2_input_data}
                                    rowKey={"id"}
                                    bordered
                                    // rowSelection={
                                    //     { type:"checkbox"}
                                    // //     //"checkbox"
                                    // //     // "radio"
                                    // }
                                    pagination={false}
                                    scroll={{ x: 'max-content', y: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                // pagination = {{
                                //     showSizeChanger:true,
                                //     showQuickJumper: true,
                                //     // onChange: this.getPaginationRight,       // 获得翻页数值
                                //     total:this.state.tableParamenter_2_data.length,
                                //     pageSize:10,
                                //     showTotal: (count = this.state.tableParamenter_2_data.length)=>{
                                //         return '共'+this.state.tableParamenter_2_data.length+'条数据'
                                //     },
                                //     defaultCurrent: 1,
                                //     pageSizeOptions: ['10']
                                // }}
                                >
                                </Table>
                            </div>
                        </div>
                        <div className={"bottom_separate_div"} />
                        {/*------------------------------------------*/}

                        <div className={"bottom_right_div"}>

                            <div >
                                <span>选择客户</span>
                                <div>
                                    <TreeSelect
                                        showSearch
                                        style={{ width: '30%' }}
                                        value={this.state.treeNodeInfo_1_value}
                                        dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                                        fieldNames={{ label: 'name', value: 'name' }}
                                        placeholder="请选择客户区域"
                                        allowClear
                                        // treeDefaultExpandAll
                                        onChange={this.treeSelectOnChange_1}
                                        treeData={this.state.treeNodeInfo_1}
                                    />
                                    {/* <Button type="primary" onClick={this.addAllCustomerName}>加入区域下所有对象</Button> */}
                                    <Input style={{ "marginLeft": "5%", "width": "20%" }}
                                        placeholder="请输入客户名称"
                                        onChange={(text = String) => {
                                            var params = this.state.tableParamenter_1;
                                            params.params.customerName = text.target.value;
                                            this.setState({ tableParamenter_1: params });

                                        }} />
                                    <Button type="primary" onClick={this.getPageCustomerName}>查询</Button>

                                    <Button type="primary" style={{ "marginLeft": "7%", }} onClick={this.addAllCustomerName}>加入区域下所有对象</Button>
                                </div>
                            </div>
                            <div style={{height:'580px',  overflowY: 'auto'}}>
                                <TableComponents
                                    paramenter={this.state.tableParamenter_1}
                                    setData={queryCustomerByCondition}
                                    setTableValue={this.setTableValue_2}
                                    ref={e => this.table = e}
                                >
                                </TableComponents>
                            </div>
                        </div>
                        {/*------------------------------------------*/}
                    </div>

                </div>

            </Layout>
        );
    }
}
