import { Component } from 'react'
import './login.less'
import { UserOutlined,LockOutlined } from '@ant-design/icons';
import { Redirect } from "react-router-dom"
import { Layout,Button,Form,Input, message,Checkbox } from 'antd';
import { doLogin } from '../../services/auth'
import {getHomeInfoByOrgId} from "../../services/configuration";
import Pubsub from "pubsub-js";
import {getDateByUserId} from "../../services/admin";

class Login extends Component {

	state = {
		// 根据需要修改这里会间接修改后续对应的参数
		loginType:4,// 1-新奥，2-汉德史特云端，3-新奥(数据面板),4-测试196。目前主要使用2号，


		isChecked: false
	};

	onFinish = value => {
		doLogin(value).then(
			response => {
				if(response.data?.data){
					// 记住用户名
					let {voMenuAuthories, voCustomerNames,voDataAuthories, poUsers} = response.data?.data;
					sessionStorage.setItem("user", JSON.stringify(poUsers));
					sessionStorage.setItem("menuAuthories",JSON.stringify(voMenuAuthories));
					sessionStorage.setItem("dataAuthories",JSON.stringify(voDataAuthories));
					sessionStorage.setItem("customerNames",JSON.stringify(voCustomerNames));
					sessionStorage.setItem("loginType",JSON.stringify(this.state.loginType));// 1-标准云端(测试服并用)，2-汉德史特云端，3-新奥

					// localStorage.setItem('menus', voDataAuthories?.[0] ?? null);
					// this.getDateByUserId(poUsers?.id,poUsers.orgName);
					//1新奥2汉德不进入跳转数据面板
					(this.state.loginType===1 || this.state.loginType===2 || this.state.loginType===4)
						?
						response.data.data.org.name==='长沙新奥热力'?
							this.props.history.replace('/meterMap')	// 判断登录公司是否正确，调整管道地图
							:
							this.getHomeInfoByOrgId(poUsers)
							// this.props.history.replace('/customer/ClientIndustry')	// 通常页面跳转

						: this.props.history.replace('/regionalInfo');	// 数据面板跳转

					// this.props.history.replace('/customer');
					message.success('登录成功')
				}else{
					message.error('登入错误:'+response.data?.responseInfo.description)
				}
			}
		).catch(
			e => message.error("服务器异常")
		)
	};
	getDateByUserId=(userId,orgName,url)=>{
		if(!userId){return false;}
		getDateByUserId({userId:userId}).then(
			response => {
				if (response?.data) {
					if(response?.data.length===1){
						localStorage.setItem('menus',JSON.stringify(response?.data[0].id ?? null));
						return this.props.history.push({
							pathname: url,
							state: {
								selectWidth: true,
							}
						})

					}
					// console.log('response?.data');
					// console.log(response?.data);
					// response.data.map(one=>{
					// 	console.log('区域'+one);
					// 	if(one.name.indexOf(orgName)!==-1){
					// 		console.log('选择的区域'+one);
					// 		localStorage.setItem('menus', [one?.id] ?? null);
					// 	}
					// });
				}
				this.props.history.replace('/customer/ClientIndustry')
			}
		).catch(e=> this.props.history.replace('/customer/ClientIndustry'))

	};
	// 根据组织机构ID查询首页配置
	getHomeInfoByOrgId=(a)=>{
		var param={
			"organizationId": a.orgId,
			"page": 1,
			"size": 10,
		};
		getHomeInfoByOrgId(param).then(
			reponse=>{
				if(reponse?.data?.data){
					var data = reponse?.data?.data;
					if(data[0]?.url){
						this.getDateByUserId(a?.id,a.orgName,data[0]?.url);
						// return this.props.history.push({
						// 	pathname: data[0]?.url,
						// 	state: {
						// 		selectWidth: true,
						// 		// areaIds:keys
						// 	}
						// })
					}else{
						this.props.history.replace('/customer/ClientIndustry')
					}
				}else{
					this.props.history.replace('/customer/ClientIndustry')
				}

			}
		).catch(e=> this.props.history.replace('/customer/ClientIndustry'))
	};


	onFinishFailed = error => console.log('错误提交');

	saveUserName = e => this.setState({isChecked: e.target.checked});

	render() {
		const userName = sessionStorage.getItem('userName');
		const {isChecked} = this.state;
		// if(localStorage.getItem('user_key')) return <Redirect to='/assetsManage'/>
		if(userName) return <Redirect to='/customer'/>;
		return (
			<Layout className={this.state.loginType===2?'login-body_1' :this.state.loginType===4?'login-body_3' :'login-body'}>
				<div className={'login-main'}>
					<div className={'login-main-title'}>欢迎登录</div>
					<Form
						style={{margin: '0vh 2vw'}}
						name="basic"
						initialValues={{ remember: true }}
						width={'30vw'}
						onFinish={this.onFinish}
						labelCol={{span: 5}}
						size={'large'}
						onFinishFailed={this.onFinishFailed}
					>
						<Form.Item
							name="account"
							initialValue={isChecked ? userName : null}
							rules={[{ required: true, message: '未输入用户名' }]}
						>
							<Input prefix={<UserOutlined />} placeholder={'请输入用户名'}/>
						</Form.Item>

						<Form.Item
							name="password"
							rules={[{ required: true, message: '未输入密码' }]}
							style={{marginBottom: '1.5vh'}}
						>
							<Input.Password prefix={<LockOutlined />} placeholder={'请输入密码'}/>
						</Form.Item>
						<div style={{margin: '2.5vh 0', padding: '0 1vw', color: '#525252'}}>
							<div style={{float: 'left'}}>
								<Checkbox onChange={this.saveUserName} style={{color: '#525252'}}>记住用户名</Checkbox>
							</div>
							<div style={{float: 'right'}}>忘记密码?</div>
						</div>
						<Button key='submit' type="primary" htmlType="submit" style={{width: '100%',marginTop: '30px'}}>登录</Button>
					</Form>
					<div className={'login-main-bottom-text'}>没有账号  ?  请联系云平台管理员</div>

				</div>
				<div style={{width:'100%',height:'3%',position: 'absolute',marginTop: '97vh',textAlign: 'right',paddingRight:'20px'}}>
					<span style={{color: '#fff',cursor: 'pointer'}}
						  onClick={()=>{
						  	window.open('https://beian.miit.gov.cn/#/Integrated/index');
						  }}
					>湖南汉德史特仪表有限公司版权所有备案号：湘ICP备2021021424号-1</span>
				</div>
			</Layout>
		)
	}
}
export default Login
