import React, { Component } from 'react'
import {Layout, Select, Form, Tag, Button, Modal, Upload, Image, Radio} from 'antd'
import TableComponents from '../../components/common/tables/TableComponents'
import {getImportInfoRecord} from '../../services/alarm'
import {pageCusPropertiesList, queryGasBurner, querySecurityAssessment} from "../../services/meter";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../components/Const";
import {NavLink} from "react-router-dom";

const { Option } = Select;
const FormItem = Form.Item;

export default class HiddenDangersOfStoves extends Component {
    state = {
        // 表格参数
        tableParamenter: {
            manageName: "客户配置",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'customer',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '客户配置',

            header: {
                name: '灶具名称',
                customerName: '客户名称',
                productiveTime: '生产日期',
                startTime: '初装日期',
                state: '状态'
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'name',
                        str: '客户名称',
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                    {
                        type: TYPE_NAME.SELECT,
                        variableName: 'state',
                        str: '状态',
                        data:[
                            {key:'0',value:'正常'},
                            {key:'1',value:'过期'},
                        ],
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                    {
                        type: TYPE_NAME.RANGE_PICKER,
                        variableName: 'date',
                        str: '过期日期',
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                ],

                onchange:(params)=>{
                    this.tableOnchange(params);
                }
            },
        },

    };

    tableOnchange=(params)=>{
        console.log(params);
        var param={
            name:params.name??null,
            state:params.state??null,
            page: 1,
            size: 13
        };
        if(params?.date){
            param.startTime=params?.date[0];
            param.endTime=params?.date[1];
        }
        console.log(param);
        this.table.getData(param)
    };
    setTableValue = (value,text,rowData) => {

        switch (value) {
            case 'state':
                return <><span>{text===0?'正常':text===1?'过期':'-'}</span></>;
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={()=>this.setState({visible_1:true})}
                    >
                        <u >{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <u>{'删除'}</u>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={queryGasBurner}
                    setTableValue={this.setTableValue}// 设置表格显示
                    ref={e => this.table = e}
                >
                    {/*<Radio.Group defaultValue="addClient" buttonStyle="solid">*/}
                    {/*    <Radio.Button value="addClient"*/}
                    {/*                  onClick={()=>{this.setState({visible_1:true})}}*/}
                    {/*    >*/}
                    {/*        新增客户*/}
                    {/*    </Radio.Button>*/}
                    {/*</Radio.Group>*/}
                </TableComponents>

            </Layout>
        )
    }
}
