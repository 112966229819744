import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Popconfirm} from 'antd'
import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'
import {
    getPageCustomerName,
    cancelCustomer,
    getPageMpAddress,
    getLocationById,
    newGetPageCustomerName
} from '../../../services/customer'
import {querySecurityAssessment} from "../../../services/meter";

// 居民安检管理
export default class ResidentIndustrySecurity extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "居民安检管理",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'ResidentIndustrySecurity',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '居民安检管理',
            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系电话',
                detailAddress: '详细地址',
                securityCheckedTime: '最近安检时间',
                securityCheckRequiredTime: '最近应安检日期',
                securityCheckStatus: '安检状态',
                gasApplianceStatus: '燃气具状态',
                edit: '安检记录',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'customerName',
                        str: '客户名称',
                        initData: JSON.parse(localStorage.getItem('industrySecurity'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'addressInfo',
                        str: '详细地址',
                        initData: JSON.parse(localStorage.getItem('industrySecurity'))?.addressInfo
                    },
                ]
            },
            params: {
                searchType:'PERSON',
                page: 1,
                size:10,
            }
        },
    };

    // 注销客户
    cancelCustomer=(id)=>{
        if(!id){return message.info('请选择客户');}
        var param = {
            "page": 1,
            "size": 10,
            "address": "",
            "customerId": id
        };
        // 验证计量点
        getPageMpAddress(param).then(
            response=>{
                // return message.info('该客户存在计量点或安装点，无法进行注销');
                console.log(response);
                if(response?.data?.data){
                    console.log(response);
                    return message.info('该客户存在计量点，无法进行注销');
                }else{
                    var locatioParam = {customerId:id, page:1, size:10,};
                    // 验证安装点
                    getLocationById(locatioParam).then(
                        response1=>{
                            console.log(response1);
                            if(response1?.data?.data){
                                console.log(response1);
                                return message.info('该客户存在安装点，无法进行注销');
                            }else{
                                cancelCustomer({customerId:id}).then(
                                    response2=>{
                                        if(response2.data.data){
                                            this.table.refreshTable();
                                            return message.info('注销成功');
                                        }else{
                                            return message.info('注销失败');
                                        }
                                    })
                            }
                        });
                }

            }
        );
    };

    setTableValue = (value,text,rowData) => {

        switch (value) {
            case 'id':
                // 安装点参数
                return (
                    <NavLink to={{pathname: "/customer/residentIndustrySecurityDetails",state: {
                            id: rowData.id,
                            type: rowData.type*1 === 1 ? '居民用户' : rowData.type*1 === 2 ? '工商用户' : '',
                            nature: rowData.nature,
                            address: rowData.addressInfo,
                            name: rowData.name,
                            principalPhone: rowData.principalPhone,
                            rowData:rowData
                        }}}>
                        <div className={'table_overflow'} title={text}>
                            <u>{text}</u>
                        </div>
                    </NavLink>
                );
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'state':
                text = text*1;
                return text === 1 ? '正式' : text === 2 ? '非正式' : '';
            case 'edit':
                var formsTwo_data={
                    customerId:     rowData?.customerId,			// 用户id
                    address:        rowData?.addressInfo,	// 地址
                    customerName:   rowData?.name,   		// 客户名称
                    createDate:'-',     // 创建时间
                    mpName:         rowData?.principal,		// 联系人
                    principalPhone: rowData?.principalPhone	// 电话
                };
                return (
                    <div>
                        <NavLink to={{pathname: "/customer/residentIndustrySecurityDetails",state: {
                                id: rowData.id,
                                type: rowData.type*1 === 1 ? '居民用户' : rowData.type*1 === 2 ? '工商用户' : '',
                                nature: rowData.nature,
                                address: rowData.addressInfo,
                                name: rowData.name,
                                principalPhone: rowData.principalPhone,
                                rowData:rowData
                            }}}>
                            <div className={'table_overflow'} title={text}>
                                <a>明细</a>
                            </div>
                        </NavLink>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={newGetPageCustomerName}
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                />
            </Layout>
        )
    }
}
