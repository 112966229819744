import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import { Layout, Modal, Radio, message, Tag } from 'antd'
import {TYPE_NAME} from '../../components/Const'

import TableComponents from '../../components/common/tables/TableComponents'
import {queryPushRecord} from '../../services/pushData'

export default class PushRecord extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据推送管理系统",
			// 是否绑定左侧区域
			bindArea: true,
			// 表格行是否可选
            rowSelection: false,
			// 获取表格参数数据
			localStorageName: 'pushRecord',
			//下拉框长度
			selectWidth: '7.5vw',
			// 表头：变量和变量解释
			fileName: '数据推送记录',
			header: {
				// id: '推送编号',
				// pushName: '推送名',
				// pushObject: '推送对象',
				// pushInterval: '推送间隔',
				// url: '推送地址',
				// createTime: '创建时间',
				// lastPushTime: '最后一推送时间',
				// lastSuccess: '最后一次成功数',
				// lastFails: '最后一次失败数',
				// pushSums: '推送总数'
			//	----------------------
				pushName: '推送名',
				url: '推送目标',
				pushObject: '推送对象',
				// pushInterval: '推送间隔',
				// createTime: '创建时间',
				lastPushTime: '推送时间',
				lastSuccess: '成功数',
				lastFails: '失败数',
				pushSums: '推送总数'
			},

			// 条件选择器参数
			conditionsParamenter: {
				// 条件
				parameter: [
					{
						type: TYPE_NAME.NAME,
						variableName: 'pushName',
						str: '推送名',
						initData: JSON.parse(localStorage.getItem('pushRecord'))?.pushName
					},
					{
						type: TYPE_NAME.NAME,
						variableName: 'pushObject',
						str: '推送对象',
						initData: JSON.parse(localStorage.getItem('pushRecord'))?.pushObject
					},
				]
			},
		},
	}

	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'pushName':
				return (
					<NavLink to={{pathname: "/pushData/PushRecordDetails",state: {
						id: rowData.id,
						pushName: rowData.pushName,
						pushObject: rowData.pushObject,
						pushSums: rowData.pushSums,
						url: rowData.url,
						pushInterval: rowData.pushInterval,
						lastPushTime: rowData.lastPushTime,
						createTime: rowData.createTime,
					}}}>
						<div className={'table_overflow'} title={text}>
							<u>{text}</u>
						</div>
					</NavLink>
				)
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={queryPushRecord}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
            </Layout>
        )
    }
}
