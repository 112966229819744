import ajax from './ajax'

// 分页查询表具信息和该表具下的设备信息 ---> 根据计量点名称查询
export const pageLikeMpName = data => ajax('/web/mpCtrl/pageLikeMpName', data, 'POST')

// 分页查询表具---表钢号
export const getDeviceNum = data => ajax('/newDevice/device/getVoDevicePaging', data, 'POST')

// 获取设备的所有类型信息
export const getAllDevClass = data => ajax('/newDevice/device/getAllDevClass', data, 'GET')

// 根据设备id获取所有数据项
export const getItemByDevId = data => ajax('/newDevice/device/selectDevInfoAttr', data, 'GET')

// 根据设备id改变状态
export const controlDevStatus = data => ajax('/newDevice/device/controlDevStatus', data, 'POST')

// 查询型号下的数据项信息
export const listDevModelInfo = data => ajax('/newDevice/device/listDevModelInfo', data, 'POST')

// 获取类型下的型号信息
export const listDeviceModelByDeviceClassId = data => ajax('/newDevice/device/listDeviceModelByDeviceClassId', data, 'GET')

// 根据设备id查询设备信息
export const selectDevInfoAttr = devId => ajax('/newDevice/device/getDevInfoAndExclusiveAttr', devId, 'GET')

// 新增设备
export const saveDevice = data => ajax('/newDevice/device/createOrUpdateDev', data, 'POST')

// 获取设备的所有数据项
export const getDataItemList = data => ajax('/newDevice/item/getItemInfoPage', data, 'POST')

// 新增数据项
export const saveDataItem = data => ajax('/newDevice/item/createOrUpdateItem', data, 'POST')

// 根据数据项id查询
export const getOne = data => ajax('/newDevice/item/getItem', data, 'GET')


// 订阅列表
export const querySubscriber = data => ajax('/devicePush/device/querySubscriber', data, 'POST')

//添加订阅
export const subscribe = data => ajax('/devicePush/device/subscribe', data, 'POST')

//取消订阅
export const unsubscribe = data => ajax('/devicePush/device/unsubscribe', data, 'POST')

// 订阅详情
export const querySubscriberDetail = data => ajax('/devicePush/device/querySubscriberDetail', data, 'GET')


// 报表规则配置
export const queryReportConfig = data => ajax('/device/report/queryReportConfig', data, 'POST')

//配置需要生成报表的数据项
export const assignItem = data => ajax('/device/report/assignItem', data, 'POST')


export const getAllItem = data => ajax('/device/item/getAllItem', data, 'GET');

// 获取废弃设备
export const getAbandonDevicePaging = data => ajax('/newDevice/device/getAbandonDevicePaging', data, 'POST')




// 删除型号
export const deleteModel = data => ajax('/newDevice/device/deleteModel', data, 'GET');

// 删除数据项
export const deleteItem = data => ajax('/newDevice/item/deleteItem', data, 'GET');

