import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,RotateRightOutlined} from '@ant-design/icons';
import $ from 'jquery'
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getSimCardApplicationListInManufactor,getManufacturerList,simUsesTheCardToApply,removeSimCardApplication,returnSim,receipt,getDeliverGoods} from '../../../services/simCard'



export default class PointManagement extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "SIM卡申请管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				applyTime: '申请时间',
				applyNumber: '申请号',
				factoryName: '厂家',
				applyUserName:'申请人',
				type: '类型',
				applyCounts: '申请/退回张数',
				deliveryCounts: '发货张数',
				state: '状态',
				edit: '操作',
			},
			// 弹出框
			headers: {
				applyTime: '申请时间',
				applyNumber: '申请号',
				factoryName: '厂家',
				applyUserName: '申请人',
				type: '类型',
				applyCounts: '申请/退回张数',
				deliveryCounts: '发货张数',
				state: '状态',
			},
			params: {
				page:1,
				size:10,
				facId:JSON.parse(sessionStorage.getItem("user")).orgId
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [			
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'applyTime',
					str: '申请时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'state',
					str: '状态',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'提交申请',value:'提交申请'},
						{key:'已批准',value:'已批准'},
						{key:'拒绝',value:'拒绝'},
						{key:'已发货',value:'已发货'},
						{key:'已收货',value:'已收货'},
						{key:'退回',value:'退回'},
					],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'type',
					str: '类型',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'申请',value:'申请'},
						{key:'申请退回',value:'申请退回'},
					],
					rules: TYPE_NAME.SELECT,
				},
			]
		},
		    	// 总条数
		totalCount: 0,
		applyVisible:false, //申请
		delVisible:false, //删除
		backVisible:false, //退回
		receiptVisible:false, //收货
		detailVisible:false, //详情
		delId:'',
		receiptId:'',
		//申请
		applyFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 8,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'factoryName',
					str: '申请厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '申请张数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.NOT_BLANK,
				},
			]
		},
		//退回
		backFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 8,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'factoryName',
					str: '厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '退回张数',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'refuse',
					str: '退回原因',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				},
				{
					variableName: 'waybillNum',
					str: '退回单号',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'sim',
					str: '退回SIM卡号',
					rules: TYPE_NAME.TEXT_AREA_REQ,
				},
			]
		},
		//详情
		detailFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 4,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'factoryName',
					str: '厂家',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'applyCounts',
					str: '申请张数',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'waybillNum',
					str: '快递单号',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
				{
					variableName: 'state',
					str: '状态',
					rules: TYPE_NAME.INPUT,
					disabled:true
				},
			]
		},
		//详情
		detailTrFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 4,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'refuse',
					str: '退回原因',
					rules: TYPE_NAME.TEXT_AREA,
					disabled:true
				},
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				text = rowData.state;
				return <>
				<div className={'tableLink'} title={'查看'} onClick={() => this.detailStatus(true,rowData)}>
						<u>{'查看'}</u>
				</div>
				{text=='已发货' ? <div className={'tableLink'} title={'收货'} onClick={() => this.toReceiptStatus(true,rowData)}>
				<u>{'收货'}</u>
				</div>
				: <div className={'tableNoLink'} title={'收货'}>
				<u>{'收货'}</u>
				</div>}
				<div className={'tableLink'} title={'删除'} onClick={() => this.toDelStatus(true,rowData)}>
						<u>{'删除'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){

	}

	//详情
	detailStatus = async (detailVisible,rData)=>{
		await this.setState({detailVisible})
		if(rData){
			getDeliverGoods({simApplyId:rData.id}).then(
				response => {
					const {data} = response.data
					if(data){
						$('#detailSimList').html('')
						if(data.voIccIdAndSimList){
							if(data.voIccIdAndSimList.length>0)
							{
								for(var i=0;i<data.voIccIdAndSimList.length;i++)
								{
									$('#detailSimList').append("<tr><td>"+data.voIccIdAndSimList[i].sim+"</td><td>"+data.voIccIdAndSimList[i].iccId+"</td></tr>")
								}
							}
						}
						if(data.state=='拒绝')
						{
							
							$('#detailTrBox').show()
							$('#detailSimListBox').hide()
						}else{
							$('#detailTrBox').hide()
							$('#detailSimListBox').show()
						}

					}
					this.setState({detailData: data})
					this.setState({detailTrData: data})
					this.detailforms.onModalClose()
					this.detailTrforms.onModalClose()
				}
			)
		}
	}

	//申请
	applyStatus = async (approvalVisible)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		let rData={}
		await this.setState({approvalVisible})
		rData.factoryName=user.orgName
		this.setState({data: rData})
		await this.forms.onModalClose()
	}
	addApplyData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.forms.onFinishNotReset()
			if(!approvalData) return null
			approvalData.facId=user.orgId
			approvalData.applyUserId=user.id
			approvalData.applyUserName=user.realName
			simUsesTheCardToApply(approvalData).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("sim卡管理", "sim卡申请", null,approvalData)
						message.config("操作成功")
						this.applyStatus(false)
					}else{
						this.applyStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable();// 刷新表格
				}
			)
			

	}; 

	//删除
	toDelStatus = async (delVisible,rData)=>{
		if(rData){
			this.setState({delVisible,delId: rData.id})
		}else{
			this.setState({delVisible})
		}
		
	}
	delApplyData = async (value)=>{
		removeSimCardApplication({id:this.state.delId}).then(
				response => {
					if(response.data.data){
						funTraceInfo("sim卡管理", "删除sim卡申请", null,this.state.delId)
						message.config("操作成功")
						this.toDelStatus(false)
					}else{
						this.toDelStatus(false)
						message.config('操作失败')
		
					}
					this.table.refreshTable();// 刷新表格
				}
			)
			

	}; 	

	//退回
	backStatus = async (backVisible)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		let rData={}
		await this.setState({backVisible})
		rData.factoryName=user.orgName
		this.setState({data: rData})
		await this.forms.onModalClose()
	}
	addBackData = async (value)=>{
			let user = JSON.parse(sessionStorage.getItem("user"))
			let approvalData = await this.forms.onFinishNotReset()
			let simArry=[]
			if(!approvalData) return null
			if(approvalData.sim){
				simArry=approvalData.sim.split('\n')
			}
			approvalData.sim=simArry
			approvalData.facId=user.orgId
			approvalData.applyUserId=user.id
			approvalData.applyUserName=user.realName
			returnSim(approvalData).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("sim卡管理", "退回sim卡", null,approvalData)
						message.config("操作成功")
						this.backStatus(false)
						this.table.refreshTable();// 刷新表格
					}else{
						this.backStatus(false)
						message.error('操作失败')
					}
					
				}
			)
			

	}; 

	//收货
	toReceiptStatus = async (receiptVisible,rData)=>{
		if(rData){
			this.setState({receiptVisible,receiptId: rData.id})
		}else{
			this.setState({receiptVisible})
		}
		
	}
	receiptData = async (value)=>{
		receipt({simApplyId:this.state.receiptId}).then(
				response => {
					if(response.data.data==true){
						funTraceInfo("sim卡管理", "收货", null,this.state.receiptId)
						message.config("操作成功")
						this.toReceiptStatus(false)
					}else{
						this.toReceiptStatus(false)
						message.config('该状态下不支持收货')
		
					}
					this.table.refreshTable();// 刷新表格
				}
			)
	}; 	



	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		let sTime=data.applyTime ?moment(data.applyTime[0]._d) : null;
		let eTime=data.applyTime ?moment(data.applyTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.applyStartTime=sTime;
		params.applyEndTime=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	

	
 
	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
					<h3>SIM卡申请</h3>
					<div style={{position:'relative'}}>
								<div className={'topFormBox'} style={{width:'90%'}}>
								<FormsTwo 
										ref={e => this.forms = e} 
										formParamenter={this.state.topFormItem} 
										data={this.state.searchGetData}
								/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
					</div>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.applyStatus(true)}><PlusSquareOutlined />申请</span>
					<span className={'addLink'} onClick={() => this.backStatus(true)}><RotateRightOutlined />退回</span>
					
				</div>	
				<div style={{padding:'0 0 0 0',background:'#fff'}}>			
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getSimCardApplicationListInManufactor}
						setTableValue={this.setTableValue}
						ref={e => this.table = e} 
					>
					</TableComponents>
				</div>
				<Modal
                        title={'申请'}
                        style={{ top: 0 }}
                        visible={this.state.approvalVisible}
                        okText="确定"
                        onOk={() => this.addApplyData(true)}           // 点击确定
                        onCancel={() => this.applyStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.applyFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                </Modal>
				<Modal
                        title={'申请退回'}
                        style={{ top: 0 }}
                        visible={this.state.backVisible}
                        okText="确定"
                        onOk={() => this.addBackData(true)}           // 点击确定
                        onCancel={() => this.backStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.backFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                </Modal>
				<Modal
                        title='删除'
                        style={{ top: 0 }}
                        visible={this.state.delVisible}
                        okText="确定"
                        onOk={() => this.delApplyData(false)}           // 点击确定
                        onCancel={() => this.toDelStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认删除该申请记录吗？</h4>
                        </div>
                </Modal>
				<Modal
                        title='收货'
                        style={{ top: 0 }}
                        visible={this.state.receiptVisible}
                        okText="确定"
                        onOk={() => this.receiptData(false)}           // 点击确定
                        onCancel={() => this.toReceiptStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认收到货了吗？</h4>
                        </div>
                </Modal>

				<Modal
                        title={'详情'}
                        style={{ top: 0 }}
                        visible={this.state.detailVisible}
                        okText="确定"
                        onOk={() => this.detailStatus(false)}           // 点击确定
                        onCancel={() => this.detailStatus(false)}       // 点击取消
                        width={600}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.detailforms = e} 
							formParamenter={this.state.detailFormParamenter} 
							data={this.state.detailData}
							/>
							<div id='detailTrBox'>
								<FormsTwo 
								ref={e => this.detailTrforms = e} 
								formParamenter={this.state.detailTrFormParamenter} 
								data={this.state.detailTrData}
								/>
							</div>
							<div className={'detailSimListBox'} id='detailSimListBox'>
								<h4>SIM卡号信息</h4>
								<table>
									<thead>
										<th>SIM卡号</th>
										<th>ICCID号</th>
									</thead>
									<tbody id='detailSimList'>
									</tbody>
								</table>
							</div>
                        </div>
                </Modal>
				
            </Layout>
        )
    }
}
