import React, { Component } from 'react'
import { Layout } from 'antd'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getUnregistered} from '../../../services/report'

export default class Unregistered extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 下拉框长度
			selectWidth: '7.5vw',
			bindArea: true,
            rowSelection: false,
            title: '未注册用户报表',
			exportUrl: "/web/reportExportCtrl/exportUnregistered",
			// 表头：变量和变量解释
			header: {
				steelNum: '表钢号',
				modelName: '表型号',
				meterManufacturer: '表具厂商',
				measure: '表具读数',
				importTime: '导入系统时间', // 创建时间
                startTime: '开始用气时间', // 开通时间
				simCard: 'SIM卡号'
			},
		},
	}

    render() {
        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getUnregistered}
					ref={e => this.table = e} 
				/>
            </Layout>
        )
    }
}