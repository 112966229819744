import React, { Component } from 'react'
import { Layout, Input, Form, DatePicker } from 'antd'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getHistoricalData,getAllManufacturerAndModel} from '../../../services/report'

const FormItem = Form.Item
const { RangePicker } = DatePicker;

class HistoricalData extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 是否绑定左侧区域
			bindArea: true,
			position: 'top',
            rowSelection: false,
			width: '83.7vw',
			height: '85%',
            title: '表具历史数据查询报表',
			exportUrl: "/web/reportExportCtrl/exportHistoricalData",
			isNotInitData: true,
			// 表头：变量和变量解释
			header: {
                company: '公司',
                readUnit: '读表单元',
                userName: '用户名称',
                installNumber: '安装',
                contractNumber: '合同',
                address: '详细地址',
                meterId: '表具编号',
                meterManufacturer: '表具厂商',
                // protocolModel: '协议型号', // 新奥协议
                modelName: '表具型号',
                simCard: 'SIM卡号', // 1244
                collectionTime: '采集时间', // 数据时间 1183
                clockInTable: '表内时钟',
                pressure: '压力', // 1206
                standardFlow: '标况流量', // 瞬时流量 1248
                standardTotal: '标况总量', // 累积流量 1184
                workingFlow: '工况流量', // 工况瞬时流量 1266
                workingTotal: '工况总量', // 工况累积流量 1267
                temperature: '温度', // 1205
                signal: '信号量', // 1186
                voltageValue: '电压值', // 3000
                valveState: '阀门状态' // 1187
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'input',
						variableName: 'steelNum',
						onChange: e => this.onChangeSelect(e),
						str: '表钢号'
					},
					{
						type: 'input',
						variableName: 'contractNum',
						str: '合同号'
					},
					{
						type: 'input',
						variableName: 'readUnit',
						str: '读表单元'
					},
					{
						type: 'input',
						variableName: 'simCard',
						str: '通讯号',
					},
				]
			},
		},
		// 设置时间选择范围时需要的参数，不需要改动
		dates: null,
		// 时间参数框
		dateData: null,
		// 时间框类型
		dateType: false
	}
	onChangeSelect = e => {
		let {dateType} = this.state
		dateType=true
		if(!e.target.value) dateType = false
		this.setState({
			dateType
		})
		// let [picker,timeHorizon] = ['rangePicker',365]
		// if(!e.target.value) [picker,timeHorizon] = ['datePicker',null]
		// let {tableParamenter} = this.state
		// let {parameter} = tableParamenter.conditionsParamenter
		// parameter[3] = {
		// 	type: picker,
		// 	variableName: 'dataTime',
		// 	timeHorizon: timeHorizon,
		// 	time: true,
		// 	str: '时间'
		// }
		// tableParamenter.conditionsParamenter.parameter = parameter
		// this.setState({tableParamenter})
	}

	// 条件选择：获取输入的条件
	getConditionsValue = () => {
		let {dateData} = this.state

		// if(value?.steelNum && !value?.startTime) this.setState({dateType: false})

		if(dateData){
			return dateData._d ? { startTime: moment(dateData._d).format("yyyy-MM-DD HH:mm:ss") } :
			{
				startTime: moment(dateData[0]._d).format("yyyy-MM-DD HH:mm:ss"),
				endTime: moment(dateData[1]._d).format("YYYY-MM-DD HH:mm:ss")
			}
		}
		// 不能删除，否则再次点击查询时，时间参数会使用上次查询的时间，必须赋值为空
		return {
			startTime: null,
			endTime: null
		}
	}
	componentDidMount(){
		this.setValue();
	}
	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state;
				let {data} = response.data;
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});
				this.setState({tableParamenter})
			}
		)
	};
	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'protocolModel':
				return '新奥协议';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	// 清空条件
	clearCondition = () => {
		this.setState({
			dateData: null,
			dates: false,
			dateType: null
		})
	};

	// 设置时间选择区间
	disabledDate = current => {
        let {dates} = this.state;
        if (!dates || dates.length === 0) return false;
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 365;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 365;
        return tooEarly || tooLate;
      };

	// 时间戳改变时获取时间
	handleSelectTime = e => this.setState({dateData: e});

    render() {

		var areaName = localStorage.getItem('areaName')??null;
		if(areaName==='undefined'){areaName=null}

		return (
            <Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getHistoricalData}
					getConditionsValue={this.getConditionsValue}
					clearCondition={this.clearCondition}
					// setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
					<FormItem label={"公司"}>
						<Input disabled value={areaName} size='middle'/>
					</FormItem>
					<FormItem label={"时间"}>
						{
							this.state.dateType?
							<RangePicker
								showTime
								format="YYYY-MM-DD HH"
								value={this.state.dateData}
								disabledDate={e => this.disabledDate(e)}
								onCalendarChange={val => this.setState({dates:val})}
								placeholder={['开始时间','结束时间']}
								onChange={this.handleSelectTime}
							/>:
							<DatePicker
								placeholder={`选择时间`}
								format="YYYY-MM-DD HH"
								onChange={this.handleSelectTime}
								showTime
								value={this.state.dateData}
							/>
						}

					</FormItem>
				</TableComponents>
            </Layout>
        )
    }
}
export default HistoricalData
