import React, { Component } from 'react'
import {Button, Layout, Popconfirm, Radio} from "antd";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {queryPushConfigs} from "../../../services/pushData";
import {FORM_ITEM, TYPE_NAME} from "../../../components/Const";
import {queryReplaceMeterLedger, queryUpdateLedger} from "../../../services/report";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import moment from "moment";
import {ArrowLeftOutlined} from '@ant-design/icons';
import {pageHistoricalTimeValve} from "../../../services/qrCode";
// 阀井历史记录
export default class NewPointManagement extends Component {
    state={
        topFormItemData:{},
        topFormItem: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                // {variableName: 'uniqueEncoding', str: '唯一编码', rules: TYPE_NAME.NAME,},
                {variableName: 'updateStarTime', str: '查询时间', rules: FORM_ITEM.RANGE_PICKER_TIME,},
            ]
        },
        // 表格参数
        tableParamenter: {
            manageName: "表具修改台账",
            // 表格行是否可选
            rowSelection: false,
            overflow:true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {

                uniqueEncoding: '唯一编码',
                address: '安装位置',
                // comStatus: '通信状态',
                dataTime: '数据时间',
                data_1: '温度',
                data_3: '浓度',
                data_4: '压力值',
                data_16: '异动',
                data_17: '经度',
                data_18: '纬度',

                data_5: '液位',
                data_6: '终端故障检测',
                data_7: '电池欠压',
                data_8: '水位检测',
                data_9: '温度检测',
                data_10: '可燃气体泄漏低限检测',
                data_11: '可燃气体泄漏高限检测',
                data_12: '压力低低限监测',
                data_13: '压力低限监测',
                data_14: '压力高限监测',
                data_15: '压力高高限监测',
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
            },
            params:{
                dataTimeRangeEnd: moment(new Date()).format('YYYY-MM-DD 23:59:59'),
                dataTimeRangeStart:moment(new Date()).format('YYYY-MM-DD 00:00:00'),
                devId:this.props.location.state?.rowData?.devId,
                size:10,
                page:1,
            }
        },
    };
    async componentDidMount(){

    }

    setTableValue = (value,text,data) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'data_1': // 温度
                var data_1='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='1205'){
                                data_1 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_1}>{data_1}</div> </>;
            case 'data_3': // 浓度
                var data_3='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3219'){
                                data_3 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_3}>{data_3}</div> </>;
            case 'data_4': // 压力值
                var data_4='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3221'){
                                data_4 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_4}>{data_4}</div> </>;
            case 'data_16':// 异动
                var data_16='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3235'){
                                data_16 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_16}>{data_16}</div> </>;
            case 'data_17':// 经纬度
                var data_17='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3236'){
                                data_17 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_17}>{data_17}</div> </>;
            case 'data_18':// 维度
                var title_18='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3237'){
                                title_18 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={title_18}>{title_18}</div> </>;
            case 'data_5': //'液位',
                var data_5='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3224'){
                                data_5 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_5}>{data_5}</div> </>;
            case 'data_6': //'终端故障检测',
                var data_6='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3225'){
                                data_6 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_6}>{data_6}</div> </>;
            case 'data_7': //'电池欠压',
                var data_7='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3226'){
                                data_7 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_7}>{data_7}</div> </>;
            case 'data_8': //'水位检测',
                var data_8='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3227'){
                                data_8 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_8}>{data_8}</div> </>;
            case 'data_9': //'温度检测',
                var data_9='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3228'){
                                data_9 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_9}>{data_9}</div> </>;
            case 'data_10': //'可燃气体泄漏低限检测',
                var data_10='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3229'){
                                data_10 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_10}>{data_10}</div> </>;
            case 'data_11': //'可燃气体泄漏高限检测',
                var data_11='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3230'){
                                data_11 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_11}>{data_11}</div> </>;
            case 'data_12': //'压力低低限监测',
                var data_12='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3231'){
                                data_12 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_12}>{data_12}</div> </>;
            case 'data_13': //'压力低限监测',
                var data_13='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3232'){
                                data_13 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_13}>{data_13}</div> </>;
            case 'data_14': //'压力高限监测',
                var data_14='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3233'){
                                data_14 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_14}>{data_14}</div> </>;
            case 'data_15': //'压力高高限监测',
                var data_15='-';
                if(data?.data){
                    if(data?.data.length!==0){
                        data?.data.map(one=>{
                            if(one.itemId==='3234'){
                                data_15 = one?.itemValue?? '-';
                            }
                        });
                    }}
                return <> <div className={'table_overflow'} title={data_15}>{data_15}</div> </>;

            // case 'systemTime':
            //     var systemTime='-';
            //     if(data.data){
            //         if(data?.data.length!==0){
            //             //valDateTime
            //             systemTime = data.data[0]?.sysDateTime?moment(data.data[0]?.sysDateTime).format('YYYY-MM-DD HH:mm:ss') : '-';
            //         }
            //     }
            //     return <> <div className={'table_overflow'} title={systemTime}>{systemTime}</div></>;

            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async()=>{
                             var modalFormItem = this.state.modalFormItem;
                             modalFormItem.searchGetData.id=data?.id;
                             modalFormItem.searchGetData.serialno=data?.serialno;
                             modalFormItem.searchGetData.uniqueEncoding=data?.uniqueEncoding;
                             modalFormItem.searchGetData.devModelId=data?.devModelId;
                             modalFormItem.searchGetData.devModelName=data?.devModelName;
                             modalFormItem.searchGetData.manufacture=data?.manufacture;
                             this.setState({modalFormItem:modalFormItem,modalVisible_1:true,modalFormItemText:'修改'});
                             // this.addAndUpdataForms.onModalClose();
                             await this.addAndUpdataForms?.onModalClose();

                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'卸载'}>
                        <Popconfirm title="是否卸载阀井?"
                                    onConfirm={()=>{
                                        // this.cancelCustomer(rowData?.id)
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>卸载</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 查询
    getConditionsValue = async() =>{
        let data = await this.forms.onFinishNotReset();
        // console.log(data);
        let sTime=data?.updateStarTime ?moment(data?.updateStarTime[0]._d).format('YYYY-MM-DD HH:mm:ss') : null;
        let eTime=data?.updateStarTime ?moment(data?.updateStarTime[1]._d).format('YYYY-MM-DD HH:mm:ss') : null;

        let params = this.state.tableParamenter.params;
        Object.assign(params,data);
        params.dataTimeRangeStart=sTime;
        params.dataTimeRangeEnd=eTime;
        let {tableParamenter} = this.state;
        tableParamenter.params = params;
        this.setState({tableParamenter});
        await this.table.refreshTable(params);
    };
    // 清空
    getEmptyValue = async () =>{
        await this.setState({topFormItemData:{}});
        await this.forms.onModalClose();
        var tableParamenter  = this.state.tableParamenter;
        tableParamenter.params.dataTimeRangeEnd = moment(new Date()).format('YYYY-MM-DD 23:59:59');
        tableParamenter.params.DataTimeRangeStart = moment(new Date()).format('YYYY-MM-DD 00:00:00');
        this.setState({tableParamenter:tableParamenter})
    };
    render(){
        return (
            <Layout className={'item_body_number_back tables'} >
                <div>
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() => {
                                this.props.history.push({
                                    pathname: '/deviceDataInfo/deviceDataInfo',
                                    state: {
                                        activeKey:'3',
                                    }
                                })
                                // this.props.history.go(-1);
                            }}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>返回上一页</span>
                        </div>
                    </div>
                    <div className={'topSearchBox'}>
                        <h3>智能终端历史记录</h3>
                        <div style={{width:'100%',height:'40px',position:'relative',backgroundColor:'#fff',paddingTop:'5px'}}>
                            <div style={{width:'85%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem}
                                    data={this.state.topFormItemData}
                                />
                            </div>
                            <Button style={{position:'absolute', right:'150px',top:'5px'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            <Button style={{position:'absolute', right:'60px',top:'5px'}} onClick={() => this.getEmptyValue()}>清空</Button>
                        </div>
                    </div>

                    <div style={{width:'100%',height:'400px',padding:'0 0 0 0',background:'#fff'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData={pageHistoricalTimeValve}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        >
                            {/*<Radio.Group defaultValue="addClient" buttonStyle="solid">*/}
                            {/*    <Radio.Button value="addClient" onClick={()=>{this.modalData(true,{},'新增配置')}}>新增配置</Radio.Button>*/}
                            {/*</Radio.Group>*/}
                        </TableComponents>
                    </div>


                </div>
            </Layout>
        );
    }
}
