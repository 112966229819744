import ajax from './ajax'
//客户可视化大屏

// 首页用用气分析
export const homePageGasAnalysis = data => ajax('/web/screenManagement/homePageGasAnalysis', data, 'POST');
// 大屏安检信息
export const largeScreenSelfCheckInfoRecord = data => ajax('/web/screenManagement/largeScreenSelfCheckInfoRecord', data, 'GET');
// 安全分析
export const safetyAnalysis = data => ajax('/web/screenManagement/safetyAnalysis', data, 'GET');

// 设备数量(设备信息)
export const getDevNumber = data => ajax('/web/screenManagement/getDevNumber', data, 'GET');
// 设备列表(设备信息)
export const getDevList = data => ajax('/web/screenManagement/getDevList', data, 'POST');
// 报警数量(报警信息)
export const getAlarmNumber = data => ajax('/web/screenManagement/getAlarmNumber', data, 'GET');
// 报警列表(报警信息)
export const getAlarmList = data => ajax('/web/screenManagement/getAlarmList', data, 'POST');
// 莹石直播地址
export const liveFluoriteDev = data => ajax('/gasdevice/homeAlarm/liveFluoriteDev', data, 'POST');
// 系统设备删除
export const deleteDev = data => ajax('/web/screenManagement/deleteDev', data, 'GET');
// 分页条件查询安全学习信息
export const queryLearning = data => ajax('/checkup/learn/queryLearning', data, 'POST');
// 分页条件查询当前客户的安全学习信息
export const queryLearningByCustomer = data => ajax('/checkup/learn/queryLearningByCustomer', data, 'POST');

// 大屏安全隐患
export const largeScreenHiddenDanger = data => ajax('/checkup/checkInfoDetail/largeScreenHiddenDanger', data, 'GET');

// 安全评估
export const getEvaluationResults = data => ajax('/checkup/securityAssessment/getEvaluationResults', data, 'GET');
// 更新评估结果
export const updateResults = data => ajax('/checkup/securityAssessment/updateResults', data, 'GET');


// 燃气用户(按区分组)
export const dividedByRegion = data => ajax('/web/screenManagement/dividedByRegion', data, 'GET');
// 大屏表具分析(2024-02-28新增)
export const largeScreenMeterAnalysis = data => ajax('/web/meterHomePage/largeScreenMeterAnalysis', data, 'POST');


// 燃气用户(安全-隐患)
export const gasQuantity = data => ajax('/web/screenManagement/gasQuantity', data, 'GET');
// 燃气设备(数量查询)
export const getGasDevNumber = data => ajax('/web/screenManagement/getGasDevNumber', data, 'GET');
