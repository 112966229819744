import { Component } from 'react'
import { Input,Space,DatePicker,Button,message,Select,Form, Col, Row,Radio,Modal,ConfigProvider} from 'antd';
import Pubsub from 'pubsub-js'
import {FORM_ITEM, RULE} from '../../Const'
import './conditions.less'
import Modals from '../modals/Modals'

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const { Option } = Select;
const confirm = Modal.confirm;

export default class Conditions extends Component {

    state={
        isModalVisible: false,
        headers: [],
        checkValue: [],
        isClear: false
    };

    setFieldsValue=(name,value)=>{
        this.form.setFieldsValue({
            [name]:value
        })
    };

    componentDidMount =async()=>{
        console.log('----------测试-----------');
        var values = await this.form.validateFields();
        console.log(values['steelNums']);
        console.log(values['simCards']);
        // this.setFieldsValue('steelNums',[]);
        // this.setFieldsValue('simCards',[]);
        console.log('----------测试-----------');

    };

    // 弹出表单
    showModal = () => {
        const {isModalVisible} = this.state
        this.setState({ isModalVisible: !isModalVisible });
    }

    // 。
    handleChange = value => {
    };
    // 清空条件选择框
    clearValue = async () => {
        await this.setState({isClear: true});
        await this.form.resetFields();
        const values = await this.form.validateFields();

        this.props.conditionsParamenter.parameter.map(one=>{
            if(one.type==='selectTags'){
                this.form.setFieldsValue({[one.variableName]:[]})
            }else  if(one.type==='selectMultiple'){
                this.form.setFieldsValue({[one.variableName]:[]})
            }
        });
        // this.form.setFieldsValue({steelNum:[]});
        message.info('条件已清空');
        this.props.clearCondition?.()
    };

    // 获取输入的条件
    getFormValue = async () => {
        var values = await this.form.validateFields();
        this.props?.getConditionsValue(values)
    };

    // 删除选中的行
    deleteItem = () => {
        confirm({
            title: '是否删除已选行?',
            okText: '确认',
            onOk: () => {
                this.props.deleteItem()
                message.success('删除成功')
            }
        });
    };

    disabledDate = (current,timeHorizon) => {
        let {dates} = this.state
        if (!dates || dates.length === 0) {
          return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > timeHorizon;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > timeHorizon;
        return tooEarly || tooLate;
      };

    // 设置条件选择框
    setFormItem = parameter => {
        if(!parameter){
            return false;
        }
        let arr = [];
        parameter?.forEach(({variableName,str,type,data,time,initData,onChange,timeHorizon,number},index,) => {

            let types = type;
            arr.push(
                <Col key={variableName} >
                    <FormItem
                        key={index}
                        name={variableName}
                        label={str}
                        style={{width: '100%'}}
                        rules={RULE[types]?.rules}
                        initialValue={this.state.isClear ? null : initData}
                    >
                        {
                            FORM_ITEM.DATE_PICKER === RULE[types].type ? <DatePicker placeholder={`输入${str}`} showTime={time}/> :
                            FORM_ITEM.INPUT === RULE[types].type ? <Input onChange={e => onChange?.(e)} placeholder={`输入${str}`}/> :
                            FORM_ITEM.RANGE_PICKER === RULE[types].type ? <RangePicker
                                                                            showTime={time}
                                                                            format="YYYY-MM-DD HH"
                                                                            disabledDate={e => this.disabledDate(e,timeHorizon)}
                                                                            onCalendarChange={val => this.setState({dates:val})}
                                                                            placeholder={['开始时间','结束时间']}
                                                                        /> :
                            FORM_ITEM.SELECT === RULE[types].type ? (
                                <Select style={{width: '150px'}} defaultValue={null}>
                                    <Option value={null}>全部类型</Option>
                                    {
                                        data?.length > 0 ? data.map(option => {
                                            return <Option key={option.key} value={option.key}>{option.value}</Option>
                                        }) : null
                                    }
                                </Select>
                            ) :
                                FORM_ITEM.SELECT_TAGS === RULE[types].type ?
                                    <Select
                                        mode="tags"
                                        style={{width: '200px'}}
                                        placeholder={`输入${str}`}
                                        onChange={e => onChange?.(e)}
                                    >

                                        {
                                            data?.length > 0 ? data.map(option => {
                                                return <Option key={option.key} value={option.key}>{option.value}</Option>
                                            }) : []
                                        }
                                    </Select>
                                    :  FORM_ITEM.SELECT_MULTIPLE === RULE[types].type ?
                                    <Select
                                        mode="multiple"
                                        style={{width: '200px'}}
                                        placeholder={`输入${str}`}
                                        onChange={e => {
                                            if(e?.length>number){
                                                this.form.setFieldsValue({'verifierDates':  e.slice(0,number)});
                                                return message.info('仅限三位');
                                            }

                                        }}
                                    >

                                        {
                                            data?.length > 0 ? data.map(option => {
                                                return <Option key={option.key} value={option.key}>{option.value}</Option>
                                            }) : []
                                        }
                                    </Select>
                                    : null
                        }
                    </FormItem>
                </Col>
            )
        });
        return arr
    };

    render() {

        const {parameter,modals} = this.props.conditionsParamenter
        const {isAddModal1,isAddModal2,modalTitle1,modalTitle2} = modals ? modals : {}
        return (
            <div>
                <Space direction="horizontal" align='baseline'>
                    {
                        isAddModal1 || isAddModal2 ? <Radio.Group defaultValue="modal1" buttonStyle="solid">
                            {
                                isAddModal1 ? <Radio.Button value="modal1" onClick={this.showModal}>{modalTitle1}</Radio.Button> : null
                            }
                            {
                                isAddModal2 ? <Radio.Button value="modal2" onClick={this.showModal}>{modalTitle2}</Radio.Button> : null
                            }
                        </Radio.Group> : null
                    }

                    <Form
                        layout="horizontal"
                        onFinish={this.getFormValue}
                        ref={e => this.form = e}
                        style={{height: '2vh'}}
                    >
                        <Row key={'row'}>
                            <Space direction="horizontal" size={15}>
                                {this.props.children}
                                {this.setFormItem(parameter)}
                            </Space>
                        </Row>
                    </Form>
                    <div style={{padding:'0 0 0 10px',minWidth:'180px'}}>
                        <ConfigProvider autoInsertSpaceInButton = { false }>
                        <Button key='submit' type="primary" htmlType="submit" onClick={this.getFormValue}>查询</Button>
                        <Button style={{border:'1px solid #EBEBEB',background:'#fff',color:'#1B1B1B',margin:'0 0 0 10px'}}
                                type="primary"
                                onClick={this.clearValue}>清空</Button>
                        </ConfigProvider>
                    </div>
                </Space>

                <Modals isModalVisible = {this.state.isModalVisible} onModalClose={this.showModal} modalParamenter={this.props.modalParamenter} getFormValue={this.props.getFormValue}/>
            </div>
        )
    }
}
