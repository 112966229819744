import React, { Component } from 'react'
import {Layout, Card, Button, message, Modal, Input, Select, Form, Col, Table, Upload, Image} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import './openAccount.less'
import {FORM_ITEM, TYPE_NAME} from '../../../components/Const'

import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
import {
	addCustomer,
	addCustomerNew,
	addSafetyOfficer,
	getSafetyOfficerByCustomerId, removeSafetyOfficer,
	updateCustomerNew, updateSafetyOfficer
} from '../../../services/customer'
import {
	addPoTimeTemplate,
	getCusPropertiesList,
	getPoTimeTemplateByCustomerId, updatePoTimeTemplate
} from '../../../services/meter'
import {queryRelationData, queryResidentialCommunity} from "../../../services/community";
export default class OpenAccount extends Component {

    state = {
    	juder:2,

		// 表单参数-类型1-工商
		formParamenter: {
			parameter: [
				// {variableName: 'id', str: '客户号', rules: TYPE_NAME.NAME, disabled: true},
				{
					variableName: 'type', str: '客户类型', rules: TYPE_NAME.REQ_SELECT,
					// disabled: this.props.location.state?.rowData ? true : false,
					onChange: e => this.onChangeSelect(e),
					data: this.props.location?.state?.selectWidth? [{key: '2', value: '工商客户'}] : [{key: '1', value: '居民客户'}, {key: '2', value: '工商客户'}]
				},
				{variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
				{variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
				{
					variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT,
					onChange: (e,a) => this.onChangeSelectList(e,a),
					data: [{key: "", value: ""}]
				},
				{variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
				{variableName: 'organizationCode', str: '组织机构代码', rules: TYPE_NAME.LABLE_18},
				{variableName: 'businessCooperationNum', str: '业务合作号', rules: TYPE_NAME.LABLE_50},
				{variableName: 'legalPerson', str: '法人', rules: TYPE_NAME.LABLE},
				{variableName: 'legalPersonPhone', str: '法人联系电话', rules: TYPE_NAME.PHONE_NUM},
				{variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
				{variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
				{variableName: 'mainBusiness', str: '主营业务', rules: TYPE_NAME.DESCRIPTION},
				{variableName: 'workTelephone', str: '单位电话', rules: TYPE_NAME.PHONE_NUM},

				{variableName: 'regionManagementId', str: '小区', rules: TYPE_NAME.SELECT, data: [],
					onChange: (e,a) => this.onChange_2_1(e,a),
				},
				{variableName: 'buildingId', str: '楼栋',rules: TYPE_NAME.SELECT, data: [],
					onChange: (e,a) => this.onChange_3_1(e,a),
				},
				{variableName: 'unitId', str: '单元号', rules: TYPE_NAME.SELECT, data: []},

				{variableName: 'householdNumber', str: '户号', rules: TYPE_NAME.LABLE_NOT_NULL_20},

				{variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
				{variableName: 'gasType', str: '气源', rules: TYPE_NAME.LABLE_NOT_NULL_50},

			]
		},
        // 表单参数-类型2-居民
		formParamenter_2: {
			parameter: [
				{
					variableName: 'type', str: '客户类型', rules: TYPE_NAME.REQ_SELECT,
					onChange: e => this.onChangeSelect(e),
					data: [
						{key: '1', value: '居民客户'},
						{key: '2', value: '工商客户'}
					]
				},
				{variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
				{variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
				{variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT,
					onChange: (e,a) => this.onChangeSelectList(e,a),
					data: [{key: "", value: ""}]
				},
				{variableName: 'residentPopulation', str: '客户类型', rules: TYPE_NAME.SELECT,
					onChange: (e,a) => this.onChange_1(e,a),
					data: [
						{key: "1", value: "小区用户"},
						{key: "2", value: "散户"},
						]
				},

				{variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
				{variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
				{variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
				{variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
				{variableName: 'gasType', str: '气源', rules: TYPE_NAME.LABLE_NOT_NULL_50},
			]
		},

		cusPropertiesList:[],

		// 表单参数-类型3
		formParamenter_3: {
			parameter: [
				{variableName: 'type', str: '检定类型', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
				{variableName: 'intervalType', str: '安排时期', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
				{variableName: 'number', str: '次数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true,}
			]
		},
		// 表单参数-类型4
		formParamenter_4: {
			parameter: [
				{variableName: 'type', str: '检定类型', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
				{variableName: 'intervalType', str: '安排时期', rules: TYPE_NAME.REQ_SELECT, disabled: true,
					onChange: (e,a) => this.onChangeintervalType_2(e,a),
					data: [{key: '1', value: '每月每天'}, {key: '2', value: '每月'}]
				},
			]
		},

		// 默认数据
		data: this.props.location?.state?.rowData ?? {province: '湖南省', city: '长沙市'},
		data1: {type: '安检',intervalType:'每年'},
		data2: {type: '自检'},
		nature:null,



		form_modal_1:{
			modal: true,
			number: 1,
			labelCol: 8,
			parameter: [
				{variableName: 'name', str: '负责人姓名', rules: TYPE_NAME.INPUT},
				{variableName: 'phone', str: '联系电话', rules: TYPE_NAME.PHONE_NUM},
				{variableName: 'type', str: '类型', rules: TYPE_NAME.REQ_SELECT,
					data: [{key: '负责人', value: '负责人'}, {key: '管理员', value: '管理员'}]
				},
			]
		},
		form_modal_1_data:null,
		// 弹框表格
		table_columns:[
			{title: '安全负责人',dataIndex: 'name', key: 'name',      align:'center',width:'100px'},
			{title: '电话',dataIndex: 'phone', key: 'phone',          align:'center',width:'100px'},
			{title: '类型',dataIndex: 'type', key: 'type',            align:'center',width:'100px'},
			{title: '是否有资质',dataIndex: 'qualifications', key: 'qualifications',align:'center',width:'100px',

			},
			{title: '操作',dataIndex: 'edit', key: 'edit',align:'center',width:'100px',
				render: (text, rowData,index) => {
					return<>
						<div title={'修改'} style={{paddingLeft:'15px', float: 'left'}}
							 onClick={async ()=>{
								 await this.setState({
									 visible_1:true,	// 弹窗开关
									 table_index:index,// 行级
									 addOrUpModel:false,// 新增或修改
									 fileList:rowData?.fileList??[],// 新增图片
									 table_rowData:rowData,
									 visible_1_juder:rowData?.id?'1':'0'
								 });
								 console.log('----------------');
								 console.log(rowData);
								 await this.forms_4.setFieldsValues(rowData);// 表单赋值
							 }}
						><a>修改</a></div>

						<div  title={'删除'} style={{paddingLeft:'15px', float: 'left'}}
							  onClick={()=>{
							  	var table_param = this.state.table_param;
							  	var tableData = [...this.state.table_data];
							  	if(rowData?.id){
									removeSafetyOfficer({id:rowData?.id}).then(
										response=>{
											if(response?.data?.data){
												message.info('删除成功');
												this.getSafetyOfficerByCustomerId();// 查询安全员列表
											}else{
												message.info('删除失败');
											}
										}
									)

								}else{
									tableData.splice((table_param.page-1)*table_param.size+index,1);
									table_param.total=tableData.length;
								}
							  	this.setState({table_data:tableData,table_param:table_param});
							  }}
						><a>删除</a></div>
					</>
				}
			},
		],
		table_data:[], 		//弹框表格数据
		table_loading:false,
		table_rowData:null, // 所选行数据源
		table_index:0,		// 表格所选行级
		addOrUpModel:true,	// 新增或修改
		table_param:{
			total:0,
			page:1,
			size:5,

		},

		visible_1:false,// 弹窗开关
		visible_1_juder:'0',//0新增 1修改
		selectMode:this.props.location?.state?.rowData?.id?1:0,// 0新增，1修改


		fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
		fileList: [], // 设备照片



		addFormParam:[]

	};
    // 时间类型
	onChangeintervalType_1=(e,a)=>{
		var {formParamenter_3} =this.state;
		if(e==='2'){
			if(!formParamenter_3.parameter.find(one=>{if(one?.variableName==='intervalDays') return true;})){
				formParamenter_3.parameter.push({variableName: 'intervalDays', str: '间隔天数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30});
			}
		}else{
			if(formParamenter_3.parameter.find(one=>{if(one?.variableName==='intervalDays') return true;})){
				formParamenter_3.parameter.splice(formParamenter_3.parameter.length-1,1)
			}
		}
	};
	// 时间类型
	onChangeintervalType_2= async (e,a)=>{
		if(!e){return false;}
		var {formParamenter_4} =this.state;
		// 自定义类型选用
		if(e==='2'){
			console.log('当前表单不能有次数-1');
			if(!formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
				formParamenter_4.parameter.push({variableName: 'number', str: '次数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true});
			}
		}else{
			console.log('当前表单有次数去掉-2');
			if(formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
				formParamenter_4.parameter.splice(formParamenter_4.parameter.length-1,1)
			}
		}
		this.setState({formParamenter_4})
	};


	async componentDidMount(){
		// 客户类型排版表单
		this.onChangeSelect(this.props.location?.state?.rowData?.type);
		//选择类型控制展示内容
		if(this.props.location?.state?.selectWidth){
			if(this.props.location?.state?.rowData?.customerId){
				// 获取检查数据
				getPoTimeTemplateByCustomerId({customerId:this.props.location?.state?.rowData.customerId}).then(
					response=>{
						var {data1,data2} = this.state;

						if(response?.data?.data){
							response.data.data.map(one=>{
								if(one.type===1){
									data1=one;
									data1.type='安检';
									data1.intervalType='每年';
								}else if(one.type===2){
									data2=one;
									data2.type='自检';
									this.onChangeintervalType_2(one.intervalType.toString());
									data2.intervalType=data2.intervalType===1?'每月每天':'每月';
								}
							});
							this.setState({data1,data2});
							this.forms1.onModalClose();
							this.forms2.onModalClose();
						}
					}
				);
			}
		}


		if(this.props.location?.state?.rowData?.id){
			if(this.props.location?.state?.rowData?.residentPopulation){

				this.onChange_1(this.props?.location?.state?.rowData?.residentPopulation===1?'1':'2',null);// 初始化小区
				this.onChange_2(this.props?.location?.state?.rowData?.regionManagementId,null,true);				// 新增楼栋
				// this.onChange_3(this.props?.location?.state?.rowData?.buildingId,null);						// 单元号 units


				this.forms.setFieldsValue('residentPopulation',this.props.location?.state?.rowData?.residentPopulation??null);
				this.forms.setFieldsValue('regionManagementId',this.props.location?.state?.rowData?.regionManagementId??null);
				this.forms.setFieldsValue('buildingId',this.props.location?.state?.rowData?.buildingId??null);
				this.forms.setFieldsValue('unitId',this.props.location?.state?.rowData?.unitId??null);

			}

		}
		this.getCusAll();

		// 0 新增客户，1修改客户
		if(this.props.location?.state?.rowData?.id){
			this.getSafetyOfficerByCustomerId();// 查询安全员列表
		}
		this.queryResidentialCommunity();		// 获取小区列表
	};





    // 获取小区列表
	queryResidentialCommunity=(name)=>{
		var params={
			name:name??null,
			page:1,
			size:99
		};
		queryResidentialCommunity(params).then(
			response => {
				if(response?.data?.data){
					var addFormParam = this.state.addFormParam;
					var formParamenter = this.state.formParamenter;
					const data = response.data.data;
					data.map(one=>{
						one=Object.assign({},one,{key:one.id, value:one.name});
						addFormParam.push(one)
					});
					// 给楼栋添加下来数据
					for(var i=0;i<formParamenter.parameter.length; i++){
						if(formParamenter.parameter[i].variableName.indexOf('regionManagementId')!==-1){
							formParamenter.parameter[i].data=addFormParam;
						}
					}

					this.setState({addFormParam:addFormParam})
				}
			}
		)
	};


	// 查询安全员列表
	getSafetyOfficerByCustomerId=()=>{
		this.setState({table_loading:true});
		var params={
			"id": this.props.location?.state?.rowData?.customerId,
			"page": 1,
			"size": 5
		};
		getSafetyOfficerByCustomerId(params).then(
			response => {
				if(response?.data?.data){
					var tableData = [...this.state.table_data];
					var tableParam = this.state.table_param;
					tableData = response?.data?.data;
					tableParam.total = response.data.pageInfo.total;
					this.setState({table_data:tableData,table_param:tableParam})
				}
				this.setState({table_loading:false})
			}
		)
	};

	onChangeSelect = e => {
		var {juder}=this.state;
		console.log(juder);
		if(e==='1'){
			juder=1
		}else{
			juder=2
		}
		this.setState({juder})
	};

	// 客户性质
	onChangeSelectList = async (e,a) => {
		const {data1,data2} = this.state;

		let {formParamenter} = this.state;
		console.log(formParamenter.parameter[3].data);

		var {formParamenter_3,formParamenter_4} = this.state;
		this.state.cusPropertiesList.map(one=>{
			if(one.name===a.children){
				if(this.props.location?.state?.selectWidth){
					this.forms1.setFieldsValue('number',one.securityCheckNumberYear);
					this.forms2.setFieldsValue('intervalType',Number(one.selfInspectionNumberMon)>1?'每月':'每月每天');
					this.forms2.setFieldsValue('number',one.selfCheckedNumberMon);
					this.onChangeintervalType_2(Number(one.selfInspectionNumberMon)>1?'2':'1',null);
				}
			}
		});
		this.setState({
			nature:a.children // 客户性质
		})
	};
	// 客户类型
	onChange_1 = async (e,a) => {
		var {formParamenter_2,addFormParam} = this.state;
		if(e.indexOf(1)!==-1){// 判断选择类型是否小区

			// 循环有没有小区，又小区不新增
			if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('regionManagementId')!==-1)return true;})){
				formParamenter_2.parameter.splice(5,0,
					{variableName: 'regionManagementId', str: '小区',
						onChange: (e,a) => this.onChange_2(e,a),
						rules: TYPE_NAME.REQ_SELECT, data: addFormParam
					},);
			}
			// 循环有没有楼栋，又楼栋不新增
			if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('buildingId')!==-1)return true;})){
				formParamenter_2.parameter.splice(6,0,
					{variableName: 'buildingId', str: '楼栋',
						onChange: (e,a) => this.onChange_3(e,a),
						rules: TYPE_NAME.SELECT, data: []
					},);
			}
			// 循环有没有楼栋，又楼栋不新增
			if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('unitId')!==-1)return true;})){
				formParamenter_2.parameter.splice(7,0,
					{variableName: 'unitId', str: '单元号',
						rules: TYPE_NAME.SELECT, data: []
					},);
			}
			// 循环有没有楼栋，又楼栋不新增
			if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('householdNumber')!==-1)return true;})){
				formParamenter_2.parameter.splice(8,0,
					{variableName: 'householdNumber', str: '户号', rules: TYPE_NAME.LABLE_NOT_NULL_50},);
			}
		}else{// 删除小区
			if(formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('regionManagementId')!==-1)return true;})){
				formParamenter_2.parameter.splice(5,4);
			}
		}
		this.forms.setFieldsValue('regionManagementId',null);
		this.forms.setFieldsValue('buildingId',null);
		this.forms.setFieldsValue('unitId',null);
		this.setState({formParamenter_2:formParamenter_2});
	};
	// 小区-控制楼栋
	onChange_2 = async (e,a,juder) => {
		var addFormParam = this.state.addFormParam;
		// 地址
		addFormParam.map(one=>{if(e===one.id){this.forms.setFieldsValue('detailAddress',one.address);}});

		this.forms.setFieldsValue('buildingId',null);// 清空单元号数据
		this.forms.setFieldsValue('unitId',null);// 清空单元号数据
		if(!e){return false;}
		var params={regionManagementId: e, type: 1, businessType:'小区',};
		queryRelationData(params).then(
			response => {
				var {formParamenter_2} = this.state;
				var buildingLists=[];
				if(response?.data?.data){
					var data =response?.data?.data;
					if(data?.buildingList){
						data?.buildingList.map(
							one=>{buildingLists.push({key:one?.id.toString(),value:one?.number,units:one?.units??[]})}
						);
					}
				}

				// 给楼栋添加下来数据
				for(var i=0;i<formParamenter_2.parameter.length; i++){
					if(formParamenter_2.parameter[i].variableName.indexOf('buildingId')!==-1){
						formParamenter_2.parameter[i].data=buildingLists;
					}
				}
				for(var i=0;i<formParamenter_2.parameter.length; i++){
					if(formParamenter_2.parameter[i].variableName.indexOf('unitId')!==-1){
						formParamenter_2.parameter[i].data=[];
					}
				}

				if(juder){
					for(var i=0;i<formParamenter_2.parameter.length; i++){
						if(formParamenter_2.parameter[i].variableName.indexOf('unitId')!==-1){
							buildingLists.map(one=>{
								if(one.key.indexOf((this.props.location?.state?.rowData?.buildingId??null).toString())!==-1){
									one?.units.map(two=>{
										formParamenter_2.parameter[i].data.push(
											{key:two?.id.toString(),value:two?.name,}
										)});
								}
							});
						}
					}
				}

				this.setState({formParamenter_2:formParamenter_2,});
			}
		)
	};
	// 小区-控制楼栋
	onChange_2_1 = async (e,a) => {
		var addFormParam = this.state.addFormParam;
		// 地址
		addFormParam.map(one=>{if(e===one.id){this.forms.setFieldsValue('detailAddress',one.address);}});

		this.forms.setFieldsValue('buildingId',null);// 清空单元号数据
		this.forms.setFieldsValue('unitId',null);// 清空单元号数据
		if(!e){return false;}
		var params={regionManagementId: e, type: 1, businessType:'小区',};
		queryRelationData(params).then(
			response => {
				var {formParamenter} = this.state;
				var buildingLists=[];
				if(response?.data?.data){
					var data =response?.data?.data;
					if(data?.buildingList){
						data?.buildingList.map(
							one=>{buildingLists.push({key:one?.id.toString(),value:one?.number,units:one?.units??[]})}
						);
					}
				}

				// 给楼栋添加下来数据
				for(var i=0;i<formParamenter.parameter.length; i++){
					if(formParamenter.parameter[i].variableName.indexOf('buildingId')!==-1){
						formParamenter.parameter[i].data=buildingLists;
					}
				}
				for(var i=0;i<formParamenter.parameter.length; i++){
					if(formParamenter.parameter[i].variableName.indexOf('unitId')!==-1){
						formParamenter.parameter[i].data=[];
					}
				}

				this.setState({formParamenter:formParamenter,});
			}
		)
	};
	// 楼栋-控制单元号
	onChange_3 = async (e,a) => {
		if(!e){return false;}
		this.forms.setFieldsValue('unitId',null);// 清空单元号数据
		var {formParamenter_2} = this.state;
		var units=[];

		for(var q=0;q<formParamenter_2.parameter.length; q++){
			if(formParamenter_2.parameter[q].variableName.indexOf('buildingId')!==-1){
				formParamenter_2.parameter[q].data.map(one=>{
					if(one.key.indexOf(e)!==-1){
						one?.units.map(two=>{units.push({key:two?.id.toString(),value:two?.name,})});
					}
				});
			}
		}
		for(var i=0;i<formParamenter_2.parameter.length; i++){
			if(formParamenter_2.parameter[i].variableName.indexOf('unitId')!==-1){
				formParamenter_2.parameter[i].data=units;
			}
		}

		this.setState({formParamenter_2:formParamenter_2})
	};
	// 楼栋-控制单元号
	onChange_3_1 = async (e,a) => {
		if(!e){return false;}
		this.forms.setFieldsValue('unitId',null);// 清空单元号数据
		var {formParamenter} = this.state;
		var units=[];

		for(var i=0;i<formParamenter.parameter.length; i++){
			if(formParamenter.parameter[i].variableName.indexOf('buildingId')!==-1){
				formParamenter.parameter[i].data.map(one=>{
					if(one.key.indexOf(e)!==-1){
						one?.units.map(two=>{units.push({key:two?.id.toString(),value:two?.name,})});
					}
				});
			}
		}

		for(var i=0;i<formParamenter.parameter.length; i++){
			if(formParamenter.parameter[i].variableName.indexOf('unitId')!==-1){
				formParamenter.parameter[i].data=units;
			}
		}

		this.setState({formParamenter:formParamenter})
	};




    onSave = async () => {
		// this.addOrUpdatePoTimeTemplate();// 新增(修改)客户时间配置
		let value ={id:null,address:{}};//数据集合
		let data = await this.forms.onFinishNotReset();// 表单数据
		if(!data) return false;
		value=Object.assign({}, value,data);
		value.address.province=value?.province??null;
		value.address.city=value?.city??null;
		// 配iD
		if(this.props.location?.state?.selectWidth){
			if(this.props.location?.state?.rowData?.customerId){value.id=this.props.location?.state?.rowData?.customerId;}
		}else{
			if(this.props.location?.state?.rowData?.id){value.id=this.props.location?.state?.rowData?.id;}
		}

		value.nature=this.state?.nature;
		value.areaId = JSON.parse(localStorage.getItem('menus'))?.[0];
		if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域")}
		if(!value?.id){value.voSafetyOfficers=this.state.table_data;}
		console.log(value);
		Modal.confirm({
			title: `是否绑定到${localStorage.getItem('areaName')}?`,
			onOk: () => {
				message.loading({ content: value.id ? '修改中...' : '开户中...', key: 'save'});
				console.log('--------');
				console.log(value);
				// 修改
				if(value?.id){
					updateCustomerNew(value).then(
						response => {
							if(response?.data?.data){
								// funTraceInfo("客户管理", "开户", null,value)
								message.success({ content: value.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
								if(this.props.location?.state?.selectWidth){
									this.addOrUpdatePoTimeTemplate(response?.data?.data);
								}
								// this.props.history.replace('/customer/businessMaintain')
								return this.props.history.go(-1);
							}else{
								message.error({ content: value.id ? '修改失败' : '该号码已存在或系统错误', key: 'save', duration: 2})
							}
						}
					).catch(
						() => message.error({ content: value.id ? '修改失败' : '开户失败', key: 'save', duration: 2})
					)
				}else{
					addCustomerNew(value).then(
						response => {
							if(response?.data?.data){
								// funTraceInfo("客户管理", "开户", null,value)
								message.success({ content: value.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
								if(this.props.location?.state?.selectWidth){
									this.addOrUpdatePoTimeTemplate(response?.data?.data);
								}
								// this.props.history.replace('/customer/businessMaintain')
								return this.props.history.go(-1);
							}else{
								message.error({ content: value.id ? '修改失败' : '该号码已存在或系统错误', key: 'save', duration: 2})
							}
						}
					).catch(
						() => message.error({ content: value.id ? '修改失败' : '开户失败', key: 'save', duration: 2})
					)
				}

			}
		})
    };

	// 新增(修改)客户时间配置
	addOrUpdatePoTimeTemplate= async (data)=>{

		console.log('新增(修改)客户时间配置');

		let forms1 = await this.forms1.onFinishNotReset();// 安检
		let forms2 = await this.forms2.onFinishNotReset();// 自检
		if(!forms1) return false;
		if(!forms2) return false;
		console.log(forms1);
		console.log(forms2);
		forms1.type=1;// 默认数据
		forms2.type=2;// 默认数据
		// forms1.customerId= data;
		// forms2.customerId= data;

		// 检查类型
		forms1.intervalType=3;// 默认数据
		//只针对控制当前每月每天只能一天
		if((forms2.intervalType==='1') || (forms2.intervalType==='每月每天')){
			forms2.intervalType=1;
			forms2.number=1;
		}
		// 针对修改时
		if((forms2.intervalType==='2') || (forms2.intervalType==='每月')){
			forms2.intervalType=2;
		}


		console.log(forms1);
		console.log(forms2);
		var params =[];

		if(this.state.data1?.id){
			forms1.id= this.state.data1?.id;
			forms2.id= this.state.data2?.id;
			params = [forms1,forms2];
			// 修改客户时间配置
			updatePoTimeTemplate(params).then(
				response => {
					console.log('修改客户时间配置');
					console.log(response.data.data);
					if(response?.data?.data){
						console.log(response.data.data)
					}
				}
			)
		}else{
			params = [forms1,forms2];
			// 新增(修改)客户时间配置
			addPoTimeTemplate(params).then(
				response => {
					console.log('新增(修改)客户时间配置');
					console.log(response.data.data);
					if(response?.data?.data){
						console.log(response.data.data)
					}
				}
			)
		}
	};

	clearData = async  () => {
		if(this.state.data?.id){
			message.warn("修改时不允许清空")
		}else{
			await this.form?.resetFields();
			// var {formParamenter_1} = this.state;
			// formParamenter_1.map(one=>{
			// 	if(one.statusText!=='none'){
			// 		one.data=''
			// 	}
			//
			// });
			// this.setState({formParamenter_1})
		}
	};

	// 查询客户性质列表
	getCusAll =()=>{
        getCusPropertiesList().then(
            response=>{
            	if(response?.data?.data){
					let {data} = response.data;
					let {formParamenter,
						// formParamenter_1,
						formParamenter_2} = this.state;
					formParamenter.parameter[3].data = this.getTreeData(data);
					formParamenter_2.parameter[3].data = this.getTreeData(data);
					// formParamenter_1[4].selectData = this.getTreeData(data);
					// formParamenter_1[4].data=formParamenter_1[4]?.selectData[0]?.value;
					this.setState({
						cusPropertiesList: data,
						formParamenter,		// 表单参数-类型1-工商
						// formParamenter_1,	// -----------------
						formParamenter_2	// 表单参数-类型2-居民
					});
				}

            }
        )
    };
	//递归
	getTreeData = data => {
		let arr = data?.map(value => {
			return {
				key:value?.id,
				value:value?.name
			}
		});
		return arr
	};

	// inputData=(variableName,data)=>{
	// 	var {formParamenter_1} = this.state;
	// 	formParamenter_1?.forEach(one=>{
	// 		if(one.variableName===variableName){
	// 			one.data=data;
	// 		}
	// 	});
	// 	this.setState(formParamenter_1)
	// };
	// selectValue=(variableName,value)=>{
	// 	if(variableName==='type'){
	// 		var {formParamenter_1}=this.state;
	// 		formParamenter_1[8].display= value ==='居民客户'?'none':'';
	// 		formParamenter_1[8].data='';
	// 		formParamenter_1[9].display= value ==='居民客户'?'none':'';
	// 		formParamenter_1[9].data='';
	// 		formParamenter_1[10].display= value ==='居民客户'?'none':'';
	// 		formParamenter_1[10].data='';
	// 		formParamenter_1[11].display= value ==='居民客户'?'none':'';
	// 		formParamenter_1[11].data='';
	// 		formParamenter_1[12].display= value ==='居民客户'?'none':'';
	// 		formParamenter_1[12].data='';
	// 		formParamenter_1[13].display= value ==='居民客户'?'none':'';
	// 		formParamenter_1[13].data='';
	// 		this.setState({formParamenter_1})
	// 	}
	// };

	// 弹窗设置
	handlePreview = (file) => {
		this.setState({
			previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
			previewVisible: true,// 弹窗开关
		});
	};
	// 照片点击事件
	onChangeUpload =  file => {
		if (file.file.status !== 'uploading') {
		}
		if (file.file.status === 'done') {
			message.success(`${file.file.name} 上传成功`);
		} else if (file.file.status === 'error') {
			message.error(`${file.file.name} 上传异常.`);
		}
		this.setState({fileList:file.fileList});
	};

	// 本地新增和修改
	localityAddAndUpSafety = async () =>{
		var formData1 = await this.forms_4.onFinishNotReset();
		if(!formData1) return false;
		formData1.path=[];
		// formData1.fileList=this.state.fileList;
		this.state.fileList.map(one=>{formData1.path.push(one.response)});
		var tableData = [...this.state.table_data];// 表格数据
		var {table_param,table_index,addOrUpModel} = this.state;    // 表格参数
		// 新增或修改
		if(addOrUpModel){
			console.log('新增');
			tableData.push(formData1);
			table_param.total = tableData.length;
		}else{
			console.log('修改');
			tableData[(table_param.page-1)*table_param.size+table_index] = formData1;
		}
		this.setState({table_data:tableData,table_param:table_param,visible_1:false})
	};
	// 分页操作
	getPaginationRight=(p,s)=>{
		var tableParam = this.state.table_param;
		tableParam.page=p;
		this.setState({tableParam})
	};

	// 新增安全员
	addSafetyOfficer=async ()=>{
		var formData1 = await this.forms_4.onFinishNotReset();
		if(!formData1) return false;
		formData1.path=[];

		if(this.state.fileList.length!==0){
			this.state.fileList.map(one=>{formData1.path.push(one.response)});
		}else{
			formData1.path=this.state.table_rowData?.path??null;
		}

		formData1.customerId=this.props.location?.state?.rowData?.id;
		addSafetyOfficer(formData1).then(
			response=>{
				if(response?.data?.data){
					this.getSafetyOfficerByCustomerId();// 查询安全员列表
					message.info('新增成功')
				}else{
					message.info('新增失败')
				}
				this.setState({visible_1:false})
			}
		);
	};
	// 修改安全员信息
	updateSafetyOfficer=async ()=>{
		var formData1 = await this.forms_4.onFinishNotReset();
		if(!formData1) return false;
		formData1.fileList=this.state.fileList;
		if(formData1?.fileList.length!==0){
			formData1.path=[];
			this.state.fileList.map(one=>{formData1.path.push(one.response)});
		}else {
			formData1.path=this.state.table_rowData.path
		}
		formData1.id=this.state.table_rowData.id;
		formData1.customerId=this.props.location?.state?.rowData?.customerId;
		console.log(formData1);
		console.log('-----------------------');
		updateSafetyOfficer(formData1).then(
			response=>{
				if(response?.data?.data){
					this.getSafetyOfficerByCustomerId();// 查询安全员列表
					message.info('修改成功')
				}else{
					message.info('修改失败')
				}
				this.setState({visible_1:false})
			}
		);
	};

    render() {
		const onFinish = (values) => {console.log('onFinish:', values);};
		const onFinishFailed = (errorInfo) => {console.log('onFinishFailed:', errorInfo);};
		const uploadButton = (<div><div className="ant-upload-text">上传图片</div></div>);

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'}
						  onClick={() =>
							  // this.props.history.replace('/customer/clientBusiness')
							  this.props.history.go(-1)
						  }
					><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回客户管理</span>
				</div>
                <div className={'item_body_number'}  style={{backgroundColor:'#fff'}}>

					<FormsTwo
						ref={e => this.forms = e}
						formParamenter={this.state.juder===2?this.state.formParamenter:this.state.formParamenter_2}
						data={this.state.data}
					/>

					{/*判断所处模式*/}
					{this.props.location?.state?.selectWidth?
						<>
							{/*安检*/}
							<FormsTwo
								ref={e => this.forms1 = e}
								formParamenter={this.state.formParamenter_3}
								data={this.state.data1}
							/>
							{/*自检*/}
							<FormsTwo
								ref={e => this.forms2 = e}
								formParamenter={this.state.formParamenter_4}
								data={this.state.data2}
							/>
						</>
						:null
					}
{/*安全员-------------------------------------------------------------------------------------------------------------*/}
					<div style={{width:'100%',height:'390px',borderTop: '1px rgb(235, 237, 240) solid'}}>
						<div style={{width:'99%', height:'25px',marginLeft:'5px'}}>
							<span style={{fontWeight:"bold",fontSize:'16px'}}>安全员</span>
							<a style={{paddingLeft: '10PX'}}
							   onClick={async ()=>{
								   await this.setState({
									   visible_1:true,
									   table_index:0,		// 表格所选行
									   addOrUpModel:true,	// 新增或修改
									   table_rowData:null,  // 表格数据
									   fileList:[],			// 图片
									   visible_1_juder:'0',	// 图片模式
								   });
								   this.forms_4.onModalClose();
							   }}>新增安全员</a>
							<a style={{float:'right'}}
							   onClick={async ()=>{

								   this.getSafetyOfficerByCustomerId();
							   }}>刷新</a>
						</div>
						<div style={{margin:'3px'}}>
							<Table
								bordered
								loading={this.state.table_loading}
								scroll={{ y: 400 }}
								columns={this.state.table_columns}
								dataSource={this.state.table_data}
								pagination = {{
									showSizeChanger:false, showQuickJumper: false,
									onChange: this.getPaginationRight,       // 获得翻页数值
									total:this.state.table_param.total,
									pageSize:5,
									showTotal: (count = this.state.table_param.total)=>{return '共'+count+'条数据'},
									defaultCurrent: 1,
									pageSizeOptions: ['5']
								}}
							/>
						</div>
					</div>

					<div className={'card_open_account_div'} style={{height:'70px'}}>
						<Button type="primary"  htmlType="submit" onClick={() => this.onSave()}>保存</Button>
						&nbsp;&nbsp;&nbsp;
						<Button type="primary" onClick={() => this.clearData()}>清空</Button>
					</div>
                </div>
{/*弹窗---------------------------------------------------------------------------------------------------------------*/}
				<Modal
					title={"安全员"}
					maskClosable={false}
					style={{ top: 0 }}
					visible={this.state.visible_1}
					okText="确定"
					onOk={ async () => {
						// 模式变更
						if(this.state.selectMode===0){
							this.localityAddAndUpSafety();// 本地新增和修改
						}else{
							console.log('修改模式');
							if(this.state.table_rowData?.id){
								this.updateSafetyOfficer();	// 修改安全员信息
							}else {
								this.addSafetyOfficer();	//	新增安全员
							}
						}
					}}           	// 点击确定
					onCancel={() => this.setState({visible_1:false})}	// 点击取消
					width={500}
					centered
				>
					<div style={{width:'90%'}}>
						<FormsTwo
							ref={e => this.forms_4 = e}
							formParamenter={this.state.form_modal_1}
							data={this.state.form_modal_1_data}/>
					</div>
					<div style={{display:this.state.visible_1_juder==='0'?null:'none'}} >
						<Upload
							action={this.state.fileUrl}
							listType="picture-card"
							name={"file"}
							fileList={this.state.fileList}
							onPreview={this.handlePreview}
							onChange={this.onChangeUpload}
						>
							{this.state.fileList.length >= 4 ? null : uploadButton}
						</Upload>
					</div>

					<div style={{display:this.state.visible_1_juder!=='0'?null:'none'}}>
						<a onClick={()=>{this.setState({visible_1_juder:'0'})}}>是否修改</a>
						{
							this.state.table_rowData?.path?
								this.state.table_rowData?.path.map(one=>{return<><Image width={105} src={one}/></>})
								:
								null
						}

					</div>
				</Modal>

            </Layout>
        )
    }
}
