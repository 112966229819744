import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'


import {getInstallationLedgerInfoInCondition,getEngineeringCompany,getInitInfoByInitId} from '../../../services/account'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				id: '安装ID',
				initUserName: '发起人',
				initUserBbs: '发起公司',
				steelNum: '表钢号',
				createTime: '发起时间',
				gasPointName: '用气点名称',
				phone: '客户手机号',
				district: '区域',
				business: '业务合作号',
				step: '安装状态',
				edit: '操作',
			},
			// 弹出框
			headers: {
				id: '安装ID',
				initUserName: '发起人',
				initUserBbs: '发起公司',
				steelNum: '表钢号',
				createTime: '发起时间',
				gasPointName: '用气点名称',
				phone: '客户手机号',
				district: '区域',
				business: '业务合作号',
				step: '安装状态',
				createTime: '提交时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'initUserName',
					str: '发起人',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'initUserBbsId',
					str: '发起公司',
					// isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'createTime',
					str: '发起时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'gasPointName',
					str: '用气点名称',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'steelNum',
					str: '表钢号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'business',
					str: '业务合作号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'step',
					str: '发起公司',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:1,value:'发起'},
						{key:2,value:'领表'},
						{key:3,value:'安装'},
						{key:4,value:'验收'},
						{key:5,value:'点火'},
					],
					rules: TYPE_NAME.SELECT,
				},
			]
		},

		    	// 总条数
		totalCount: 0,
		loading: false,
		searchGetData:{},
		basicsData:{},
		voInstallReqData:{},
		voInstallMeterData:{},
		devVisible:false, // 小窗口开关器-1
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'查看详情'} onClick={() => this.showDetailBox(true,rowData.id)}>
						<u>{'查看详情'}</u>
				</div>
				</>
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
		getEngineeringCompany().then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)

		$(document).on('click', '.smallImg', function() {
			var url=$(this).attr('src')
			$('.bigImg').attr('src',url)
			$('.bigImgBox').show()
		});
		$(document).on('click', '.bigImgBox', function() {
			$('.bigImgBox').hide()
		});
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

		//添加设备窗口显示
		showDetailBox = async (devVisible,id)=>{
			this.setState({devVisible})
			getInitInfoByInitId({initId:id}).then(
				response => {
					let data=response.data.data
					console.log(data)
					if(data){
						this.state.basicsData.initUserName=data.initUserName
						this.state.basicsData.custerName=data.custerName
						this.state.basicsData.phone=data.phone
						this.state.basicsData.createTime=data.createTime
						this.state.basicsData.areaName=data.areaName
						this.state.basicsData.business=data.business
						this.state.basicsData.district=data.district
						this.state.basicsData.initUserBbs=data.initUserBbs
						this.state.basicsData.address=data.address
						if(data.voInstallReq)
						{
							this.state.voInstallReqData.reqUserName=data.voInstallReq.reqUserName
							this.state.voInstallReqData.steelNum=data.voInstallReq.steelNum
							this.state.voInstallReqData.reqTime=data.voInstallReq.reqTime
							this.state.voInstallReqData.manufacture=data.voInstallReq.manufacture
							this.state.voInstallReqData.modelName=data.voInstallReq.modelName
						}
						if(data.voInstallMeter){
							this.state.voInstallMeterData.installUserName=data.voInstallMeter.installUserName
							this.state.voInstallMeterData.workSum=data.voInstallMeter.workSum
							this.state.voInstallMeterData.steelNum=data.voInstallMeter.steelNum
							this.state.voInstallMeterData.installTime=data.voInstallMeter.installTime
							if(data.voInstallMeter.photo.length>0){
								$('#installInnerImgBox').html('');
								for(var i=0;i<data.voInstallMeter.photo.length;i++){
									$('#installInnerImgBox').append("<img class='smallImg' alt='暂未上传' src="+data.voInstallMeter.photo[i]+" />")
								}
							}
						}else{
							$('#installInnerImgBox').html('<p class="installAltText">请完成上一步操作</p>');
						}
						if(data.voInstalCheckList.length>0){
							$('#installCheckList').html('');
							let num=0
							for(var i=0;i<data.voInstalCheckList.length;i++){
								num++
								var imgUrl=''
								if(data.voInstalCheckList[i].photo){
									if(data.voInstalCheckList[i].photo.length>0){
										for(var j=0;j<data.voInstalCheckList[i].photo.length;j++){
											imgUrl+="<img class='smallImg' alt='暂未上传' src="+data.voInstalCheckList[i].photo[j]+" />"
										}
									}
								}
								$('#installCheckList').append("<div class='installInnerSmall'>"+
								"<p>"+data.voInstalCheckList[i].checkUserName+"的第"+num+"次验收</p>"+
								"<ul>"+
									"<li>表具是否安装正常：<span>"+data.voInstalCheckList[i].isQualified+"</span></li>"+
									"<li>是否要更换表具：<span>"+data.voInstalCheckList[i].isChagMeter+"</span></li>"+
									"<li>换表负责人：<span>"+data.voInstalCheckList[i].phone+"</span></li>"+
									"<li>问题描述：<span>"+data.voInstalCheckList[i].remark+"</span></li>"+
									"<li>验收时间：<span>"+data.voInstalCheckList[i].checkTime+"</span></li>"+
								"</ul>"+
								"<div class='installInnerImgBox'>"+imgUrl+
								"</div>"+
								"</div>")
							}
						}else{
							$('#installCheckList').html('<p class="installAltText">请完成上一步操作</p>');
						}
						
						if(data.voInstallIgnightList.length>0){
							$('#instalIgnightList').html('');
							let num=0
							for(var i=0;i<data.voInstallIgnightList.length;i++){
								num++
								var imgUrl=''
								if(data.voInstallIgnightList[i].voGasEquipmentInfoList){
									if(data.voInstallIgnightList[i].voGasEquipmentInfoList.length>0){
										for(var j=0;j<data.voInstallIgnightList[i].voGasEquipmentInfoList.length;j++){
											for(var k=0;k<data.voInstallIgnightList[i].voGasEquipmentInfoList[j].photo.length;k++){
											imgUrl+="<img class='smallImg' alt='暂未上传' src="+data.voInstallIgnightList[i].voGasEquipmentInfoList[j].photo[k]+" />"
											}
										}
									}
								}
								$('#instalIgnightList').append("<div class='installInnerSmall'>"+
								"<p>"+data.voInstallIgnightList[i].ignightUserName+"的第"+num+"次点火</p>"+
								"<ul>"+
									"<li>客户性质：<span>"+data.voInstallIgnightList[i].cusPropeertiesName+"</span></li>"+
									"<li>用气性质：<span>"+data.voInstallIgnightList[i].gasPropeertiesName+"</span></li>"+
									"<li>合同号：<span>"+data.voInstallIgnightList[i].contractNumber+"</span></li>"+
									"<li>点火时间：<span>"+data.voInstallIgnightList[i].ignightTime+"</span></li>"+
								"</ul>"+
								"<div class='installInnerImgBox'>"+imgUrl+
								"</div>"+
								"</div>")
							}
						}else{
							$('#instalIgnightList').html('<p class="installAltText">请完成上一步操作</p>');
						}


					}
				}
			)

		}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data)
		let sTime=data.createTime ?moment(data.createTime[0]._d) : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTimeStart=sTime;
		params.createTimeEnd=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	


	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>安装管理</h3>
						<div style={{position:'relative'}}>
							<div style={{width:'88%'}}>
							<FormsTwo 
									ref={e => this.forms = e} 
									formParamenter={this.state.topFormItem} 
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'90px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary">导出</Button>
						</div>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getInstallationLedgerInfoInCondition}
					setTableValue={this.setTableValue}
					ref={e => this.table = e} 
				>
				</TableComponents>
				</div>
				<Modal
                        title={'设备详情'}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
						// footer={false}
                        onOk={() => this.showDetailBox(false)}
                        onCancel={() => this.showDetailBox(false)}
                        width={1500}
                        centered
                    >
                        <div className={'installBox'}>
							<div className={'installList'}>
								<h4><span>1</span>发起</h4>
								<div className={'installInner'}>
									<ul>
										<li>发起人：<span>{this.state.basicsData.initUserName}</span></li>
										<li>用气点名称：<span>{this.state.basicsData.custerName}</span></li>
										<li>客户手机号：<span>{this.state.basicsData.phone}</span></li>
										<li>发起日期：<span>{this.state.basicsData.createTime}</span></li>
										<li>管辖公司：<span>{this.state.basicsData.areaName}</span></li>
										<li>业务合作号：<span>{this.state.basicsData.business}</span></li>
										<li>区域：<span>{this.state.basicsData.district}</span></li>
										<li>工程公司：<span>{this.state.basicsData.initUserBbs}</span></li>
										<li>详细地址：<span>{this.state.basicsData.address}</span></li>
									</ul>
								</div>
							</div>
							<div className={'installList'}>
								<h4><span>2</span>领表</h4>
								<div className={'installInner'}>
									<ul>
										<li>领表人：<span>{this.state.voInstallReqData.reqUserName}</span></li>
										<li>表钢号：<span>{this.state.voInstallReqData.steelNum}</span></li>
										<li>领表时间：<span>{this.state.voInstallReqData.reqTime}</span></li>
										<li>表具厂家：<span>{this.state.voInstallReqData.manufacture}</span></li>
										<li>表具型号：<span>{this.state.voInstallReqData.modelName}</span></li>
									</ul>
								</div>
							</div>
							<div className={'installList'}>
								<h4><span>3</span>安装</h4>
								<div className={'installInner'}>
									<ul>
										<li>安装人：<span>{this.state.voInstallMeterData.installUserName}</span></li>
										<li>表底数：<span>{this.state.voInstallMeterData.workSum}</span></li>
										<li>表钢号：<span>{this.state.voInstallMeterData.steelNum}</span></li>
										<li>安装时间：<span>{this.state.voInstallMeterData.installTime}</span></li>
									</ul>
									<div id='installInnerImgBox' className={'installInnerImgBox'}>

									</div>
								</div>
							</div>
							<div className={'installList'}>
								<h4><span>4</span>验收</h4>
								<div id='installCheckList'>

								</div>
							</div>
							<div className={'installList'}>
								<h4><span>5</span>点火</h4>
								<div id='instalIgnightList'>

								</div>
							</div>
                        </div>
                    </Modal>
					<div className={'bigImgBox'}>
						<div className={'bigImgBoxBg'}></div>
						<img className={'bigImg'}></img>
					</div>

            </Layout>
        )
    }
}
