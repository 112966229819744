import { Component } from 'react';
import { Layout, Menu } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import { NavLink } from "react-router-dom";

import './menus.css'
import {subMenuLogo} from '../../../services/common'

const { Sider } = Layout;
const { SubMenu } = Menu;

class LinkMenus extends Component {
	state = {
		collapsed: false,
		openKeys: [],
		rootSubmenuKeys: JSON.parse(sessionStorage.getItem("menuAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("menuAuthories")),
		backgroundColor: this.props.linkMenus?.backgroundColor
	};

	componentDidMount(){
		this.setKeys()
	}

	setKeys = () => {
		let {rootSubmenuKeys} = this.state
		const url = '/' + localStorage.getItem('url')?.split('/')[2]
		let keys = [];
		rootSubmenuKeys?.forEach(data => {
			data?.children?.forEach(value => {
				if(value.url === url) keys[0] = data.name
			})
		})
		this.setState({openKeys: keys??'客户关系管理'})
	}

	onCollapse = collapsed => {

		 if(collapsed) this.setState({
			collapsed: collapsed,
			visible: !collapsed
		 });
		 else this.setState({ collapsed: collapsed })
	}

	getKeys = data => {
        let items = []
		data?.forEach(val => {
			items.push(
				val.type ? val?.children ? (
				<SubMenu key={val.url} title={subMenuLogo(val.url,val.name,val.components)} style={{color: '#B8B8B8'}}>
					{val.children?.map(v => {
						return v.type ? (v?.children ? this.getKeys(v.children) : (
								<Menu.Item key={v.url} icon={<AppstoreOutlined />}>
									<NavLink style={{color: '#B8B8B8'}} to={v.url}>{v.name}</NavLink>
								</Menu.Item>
							)): null
						})
					}
				</SubMenu>) : (
				<Menu.Item key={val.url} icon={<AppstoreOutlined />}>
					<NavLink style={{color: '#B8B8B8'}} to={val.url}>{val.name}</NavLink>
				</Menu.Item>) : null)
		})
		return items
	}

	onOpenChange = keys => {
		const {openKeys,rootSubmenuKeys} = this.state
		let linkMenus = rootSubmenuKeys.map(value => value.url)
		const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
		if (linkMenus.indexOf(latestOpenKey) === -1) {
			this.setState({openKeys: keys})
		} else {
			this.setState({openKeys: latestOpenKey ? [latestOpenKey] : []})
		}
	}

	render() {
		const { collapsed } = this.state;
		const {linkMenus,backgroundColor='#404840'} = this.props.linkMenus

		return (
			<Sider width={'100%'} style={{backgroundColor: backgroundColor}} collapsible={this.props.collapsible} collapsed={collapsed} onCollapse={this.onCollapse} key='00'>
				<Menu
					openKeys={this.state.openKeys}
					onOpenChange={this.onOpenChange}
					theme="light"
					mode="inline"
					style={{backgroundColor: backgroundColor}}
					key='01'
					defaultSelectedKeys={[localStorage.getItem('url') ?? '/customer/clientBusiness']}
				>
					{this.getKeys(linkMenus)}
				</Menu>
			</Sider>
		);
	}
}

export default LinkMenus;
