import React from 'react';

import * as echarts from 'echarts';
// 条形图
class BarChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        var series=[];
        this.props?.data?.map(
            one=>{
                series.push(
                    {
                        name: one.name,
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: one.data
                    },
                )
            }
        );
        // this.props?.data?.dataS.map(
        //     one=>{
        //         series.push(
        //             {
        //                 value: one?.value,
        //                 name: one?.name,
        //                 itemStyle: {
        //                     normal: {
        //                         color: one?.color, //改变折线点的颜色
        //                         // lineStyle: {
        //                         //     color: "#ff7752", //改变折线颜色
        //                         // },
        //                     },
        //                 },
        //             },
        //         )
        //     }
        // );

        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        chartDom.setOption(
            {
                //标题
                // title: {
                //     text: 'World Population'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    top:'15%',
                    left: '3%',
                    right: '14%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    // boundaryGap: [0, 1],
                },
                yAxis: {
                    type: 'category',
                    data:this.props?.yData
                    // data: ['Brazil', 'Indonesia', 'USA', 'India']
                },
                series:series
            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
