import React,{ Component } from 'react'
import {Layout, message} from "antd";
import Tables from "../../../components/common/tables/Tables";
import Conditions from "../../../components/common/conditions/Conditions";
import {getCommFailRecPaging} from "../../../services/equipment";
import moment from "moment";
import {funTraceInfo} from '../../../services/common'
export default class equipmentBe extends Component {
    state={
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            parameter: [


                {
                    type: 'input',
                    variableName: 'uniIdOnDev',
                    str: '设备编码'
                },
                {
                    type: 'rangePicker',
                    variableName: 'rangePicker',
                    str: '通信时间'
                },
            ]
        },// 条件选择器参数
        // 表格参数
        tableParamenter: {
            params: {
                total: 0,
                pageIndex: 1,
                pageLine: 14

            },
            // 表格行是否可选
            rowSelection: true,
            // 表头：变量和变量解释
            header: {
                uniIdOnDev: '设备唯一编码',
                reason: '失败原因',
                protocolType: '协议类型',
                channelId: '通道Id',
                commTime: '通信时间"'
            },
            // 下拉框：变量和变量解释
            headers: {
                uniIdOnDev: '设备唯一编码',
                reason: '失败原因',
                protocolType: '协议类型',
                channelId: '通道Id',
                commTime: '通信时间'
            },
            // 表格数据
            data: [],
        },// 表格参数END


        // pageinfo: {
        //     total: 0,
        //     pageIndex: 1,
        //     pageLine: 15
        // },
        isModalVisible: false,
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,

        queryShowData:{
            uniIdOnDev:null,
            reason:null,
            protocolType:null,
            dateTimeStart:null,
            dateTimeEnd:null,
            pageInfo: {
                total: 0,
                pageIndex: 1,
                pageLine: 14
            },
        }
    };
    // 初始默认执行
    componentDidMount() {
        this.getData(); // 获取表格数据
    }
    // 获取表格数据
    getData =()=>{
        console.log("获取表格数据");
        // this.setState({loading: true});                            // 开启表格载入动画
        console.log(this.state.queryShowData);
        getCommFailRecPaging(this.state.queryShowData).then(reponse=>{
            console.log(reponse);
            let data = reponse.data.data;
            let {tableParamenter,queryShowData} = this.state;
            if(data!==null) {    // 判断是否有数据
                tableParamenter.data=data;
                queryShowData.pageInfo.total=reponse.data.pageInfo.total;
                queryShowData.pageInfo.pageLine=reponse.data.pageInfo.pageLine;
                queryShowData.pageInfo.pageIndex=reponse.data.pageInfo.pageIndex;
                this.setState({tableParamenter:tableParamenter,queryShowData:queryShowData});
            }else{
                tableParamenter.data=[];
                queryShowData.pageInfo.total=0;
                queryShowData.pageInfo.pageIndex=1;
                queryShowData.pageInfo.pageLine=14;
                this.setState({tableParamenter:tableParamenter,queryShowData:queryShowData});
                message.success('查询失败');
                console.log(reponse.data.responseInfo.description);
            }
        })
    };
    // 条件选择：获取输入的条件
    getConditionsValue = (value) => {
        let{queryShowData}=this.state;
        queryShowData.uniIdOnDev=value.uniIdOnDev;
        queryShowData.dateTimeStart = value.rangePicker ? moment(value.rangePicker[0]?._d).format('YYYY-MM-DD HH:mm:ss') : null;
        queryShowData.dateTimeEnd = value.rangePicker ? moment(value.rangePicker[1]?._d).format('YYYY-MM-DD HH:mm:ss') : null;
        queryShowData.pageInfo.total=0;
        queryShowData.pageInfo.pageIndex=1;
        this.setState(queryShowData);
        this.getData(); // 获取表格数据
    };
    // 获取当前页数和条数
    getPagination = (page, pageSize) => {
        let {queryShowData} = this.state;

        queryShowData.pageInfo.pageIndex = page;
        queryShowData.pageInfo.pageLine = pageSize;
        this.setState({queryShowData});
        this.getData()
    };
    // 表格：刷新方法
    refreshTable = () => {
        this.setState({refreshTable: true});
        this.getData()
    }
    // 表格：获取勾选中的值
    onSelectChange = data => {
        console.log(data);
    };
    // 清空条件
    clearCondition = () => {
        let{queryShowData}=this.state;
        queryShowData.uniIdOnDev=null;
        queryShowData.dateTimeStart=null;
        queryShowData.dateTimeEnd=null;
        this.setState(queryShowData);
        this.getData()
    };
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'commTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };
    render(){

        console.log(sessionStorage.getItem("lastname"));
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div>


                </div>
                <Tables
                    tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
                    exportTable={this.exportTable}                  // 导出时触发的方法
                    onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                    refreshTable={this.refreshTable}                // 刷新时触发的方法
                    getPagination={this.getPagination}              // 分页器变化时触发的方法
                    pagination={{
                        totalCount: this.state.queryShowData.pageInfo.total,          // 总条数
                        page: this.state.queryShowData.pageInfo.pageIndex,               // 当前页数
                        size: this.state.queryShowData.pageInfo.pageLine                // 页面条数
                    }}
                    setTableValue={this.setTableValue}              // 设置表格显示
                    loading={{
                        loading: this.state.loading,
                        refreshTable: this.state.refreshTable
                    }}
                >
                    <div style={{padding: '0.5% 0.5% 0 0.5%'}}>
                        <Conditions
                            conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                            getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                            clearCondition={this.clearCondition} // 清除条件
                        >
                        </Conditions>
                    </div>
                </Tables>
            </Layout>
        );
    }
}
