import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import { Layout, Modal, Radio, message, Tag } from 'antd'
import {getDeviceNum, getItemByDevId,controlDevStatus} from '../../../services/device'
import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'

export default class deviceManagement extends Component {

	state = {
		// 表格参数
		tableParamenter: {
			manageName: "设备信息管理",
			columnMaxWith: 500,
			selectWidth:150,
			// 是否绑定左侧区域
			bindArea: true,
			// 表格行是否可选
            rowSelection: true,
			width: '83.7vw',
			localStorageName: 'device',
			fileName: '设备信息管理',
			// exportUrl: "/web/reportExportCtrl/getMetersBySteelNum",
			// 表头：变量和变量解释
			header: {
				className: '设备类型名字	',
				communicateStatusName: '通讯状态',
				devModelName: '设备型号名',
				devName: '设备名称',
				devSetUniCode: '集合唯一编码',
				devStatusName: '设备状态',
				location: '设备位置描述',
				manufacture: '厂商',
				thresholdValue: '阈值',
				searchName: '检索名',
				edit: '操作',
			},
			// 表头：变量和变量解释
			headers: {
				devId: '设备id',
				className: '设备类型名字	',
				communicateStatusName: '通讯状态',
				devModelName: '设备型号名',
				devName: '设备名称',
				devSetUniCode: '集合唯一编码',
				devStatusName: '设备状态',
				location: '设备位置描述',
				manufacture: '厂商',
				thresholdValue: '阈值'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: TYPE_NAME.INPUT,
						variableName: 'manufacture',
						str: '设备厂商',
						initData: JSON.parse(localStorage.getItem('device'))?.manufacture
					},
					{
						type: TYPE_NAME.INPUT,
						variableName: 'searchName',
						str: '设备名称',
						initData: JSON.parse(localStorage.getItem('device'))?.searchName
					},

				]
			},
			drawersItem: {}
		},
		voidVisible:false, //作废窗口开关器-1
		devId:null,
		status:null,
		modTitle:null,

	}

	componentDidMount(){
		// this.setValue()
	}

	// setValue = () => {
	// 	getAllManufacturerAndModel({hasModel: false}).then(
	// 		response => {
	// 			let {tableParamenter} = this.state
	// 			let {data} = response.data
	// 			tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
	// 				return {
	// 					key: manufacturer,
	// 					value: manufacturer
	// 				}
	// 			})
	// 			this.setState({tableParamenter})
	// 		}
	// 	)
	// }

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				text = rowData.devStatus*1;
				return <>
				{text==0 ? <div className={'tableNoLink'} title={'启用'}>
				<u>{'启用'}</u>
				</div>
				: <div className={'tableLink'} title={'启用'} onClick={() => this.toVoidStatus(true,rowData.devId,'启用',0)}>
				<u>{'启用'}</u>
				</div>}
				{text==1 ? <div className={'tableNoLink'} title={'禁用'}>
				<u>{'禁用'}</u>
				</div>
				: <div className={'tableLink'} title={'禁用'} onClick={() => this.toVoidStatus(true,rowData.devId,'禁用',1)}>
				<u>{'禁用'}</u>
				</div>}
				{text==3 ? <div className={'tableNoLink'} title={'废弃'}>
				<u>{'废弃'}</u>
				</div>
				: <div className={'tableLink'} title={'废弃'} onClick={() => this.toVoidStatus(true,rowData.devId,'废弃',3)}>
				<u>{'废弃'}</u>
				</div>}

				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	updateMeter = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		this.props.history.push({
			pathname: '/device/addDevice',
			state: rowData[0]
		})
	}

	toVoidStatus=(voidVisible,id,title,status)=>{
		console.log(id)
		this.setState({voidVisible,devId:id,modTitle:title,status:status});
	};
	toVoid=(voidVisible)=>{
		 console.log(this.state.devId)
		controlDevStatus({devId: this.state.devId,status:this.state.status}).then(
			response => {
				console.log(response)
				this.setState({voidVisible})
				message.success('操作成功')
				// funTraceInfo("设备管理", "状态变更", null,this.state.devId)
				this.table.refreshTable();// 刷新表格
			}
		)
    };

	// 必须为异步: 因为弹出框出现时，页面已经更新完毕了
	onRowData = async (record, drawersHeader) => {
		await getItemByDevId({devId: record?.devId}).then(
			response => {
				let { tableParamenter } = this.state
				// let {data = []} = response?.data?.data
				// let onRowData = record
				// data?.forEach(({value,name}) => {
				// 	onRowData[name] = value
				// 	drawersHeader[name] = name
				// })
				// tableParamenter.drawersItem = {
				// 	onRowData: onRowData,
				// 	headers: drawersHeader
				// }
				this.setState({tableParamenter})
			}
		  )
	}

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: 'red'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getDeviceNum}
					setTableValue={this.setTableValue}
					onRowData={this.onRowData}
					ref={e => this.table = e}
					id={'devId'}
				>
					{/*<Radio.Group defaultValue="addClient" buttonStyle="solid">*/}
					{/*	<NavLink to="/device/addDevice">*/}
					{/*		<Radio.Button value="addClient">新增设备</Radio.Button>*/}
					{/*	</NavLink>*/}
					{/*	<Radio.Button value="updateClient" onClick={this.updateMeter}>修改设备</Radio.Button>*/}
					{/*</Radio.Group>*/}
					{/* <Button type="primary" onClick={this.showModal}>计量点关系图</Button> */}
				</TableComponents>
				{/* <Modal centered title="计量点关系图" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
					<p>计量点关系图</p>
				</Modal> */}
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.voidVisible}
                        okText="确定"
                        onOk={() => this.toVoid(false)}           // 点击确定
                        onCancel={() => this.toVoidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认{this.state.modTitle}该设备吗？</h4>
                        </div>
                </Modal>
            </Layout>
        )
    }
}
