import { Component } from 'react';
import {Layout, Drawer, message} from 'antd';

import './menus.css'
import LinkMenus from './LinkMenus'
import Trees from '../tree/Trees'
import { Scrollbars } from 'react-custom-scrollbars'

import {DownOutlined,UpOutlined} from '@ant-design/icons';
import { UserOutlined,ClockCircleOutlined,PoweroffOutlined,MenuUnfoldOutlined,CloseOutlined ,PieChartOutlined} from '@ant-design/icons';
import {getDateByUserId} from "../../../services/admin";
import Pubsub from 'pubsub-js'
const { Sider } = Layout;

class Menus extends Component {

	state = {
		content:this.props.menus?.content,
		collapsed: false,
		parameter: {
			backgroundColor : this.props.menus?.backgroundColor
		},
		topText:'点击选择区域',
		boxShow:true,
		boxHide:'none',
		color:'#B8B8B8'
	};

	onCollapse = collapsed => {
		this.setState({ collapsed });
		this.props.onClose()
	};

	changArea = (topText,color) => {
		this.setState({topText,color})
	};

	showDrawer = () => {
		this.setState({boxShow: !this.state.boxShow});
		this.props?.showDrawer()
	};
	componentDidMount(): void {
		this.getDateByUserId();



		this.setState({content:this.props.menus?.content})
	};


	getDateByUserId=()=>{
		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
			response => {
				if (response?.data) {
					var content = response?.data;
					if(content.length!==0){
						this.changArea(content[0]?.name??null,'#000');// 默认数据
						var keys =[];
						keys.push(content[0]?.id??null);
						content[0]?.children?.forEach(({key}) => keys.push(key));
						localStorage.setItem('menus', JSON.stringify(keys ?? null));
						localStorage.setItem('areaName', content[0]?.name??null);
						Pubsub.publish('areaIds', keys)
					}
				} else {
					return message.info('获取不到数据');
				}
			}
		)

	};



	render() {

		let {content,backgroundColor='#404840',visible} = this.props.menus;

		return (
			<div className="column-left" style={{position: 'relative',width:'268px'}} onMouseOut={this.onTreeClose}>
				<div className="resize-bar"></div>
				<div className="resize-line"></div>
				<div className="resize-save" style={{overflow: 'hidden',paddingTop:'15px'}}>

					<div style={{fontSize: '1.4rem',color: '#000',cursor: 'pointer', textAlign:'center',borderBottom:'10px solid #F9F9FA'}}>
						<div className='leftTopBtn' onClick={this.showDrawer} style={{color:this.state.color,position:'relative'}}>
							{/* {
								visible ? '区域选择' : '返回'
							} */}
							<div className='leftTopBtnText' title={this.state.topText}>{this.state.topText}</div>
							{/*图标*/}
							<span className='leftTopBtnIcon'>{this.state.boxShow ? <DownOutlined /> : <UpOutlined />}</span>
						</div>
					</div>
					<Sider
						className={'menu_sider'}
						width={'100%'}
						style={{
							backgroundColor: '#fff',
							overflow:'auto',
							height: 'calc(100% - 50px)',
						}}
						onCollapse={this.onCollapse}
						key='00'
					>
{/*地址-----------------------*/}
						<Scrollbars className={'tree'}>
							<Trees
								data={content}
								parameter={this.state.parameter}
								changArea={this.changArea}/>
						</Scrollbars>
{/*功能----------------------*/}
						<Drawer
							placement="right"
							closable={false}
							width={'100%'}
							height={'100%'}
							onClose={this.props.onClose}
							visible={visible}
							getContainer={false}
							style={{ position: 'absolute'}}
						>
							<Scrollbars style={{ backgroundColor: backgroundColor}}>
								<LinkMenus linkMenus={this.props.linkMenus} collapsible={false}/>
							</Scrollbars>
						</Drawer>
					</Sider>

				</div>
			</div>
		)
	}
}

export default Menus
