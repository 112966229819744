import React, { Component } from 'react'
import {Layout} from 'antd'
// import {Map, Marker, NavigationControl, InfoWindow,Polyline,Polygon} from 'react-bmap'
import $ from 'jquery'


export default class BaiduMap extends React.Component{
	state = {
		latNum:'',
		lngNum:''
	}
	showInfo = data => {

		var opts = {
			width : 200,     		// 信息窗口宽度
			height: 100,     		// 信息窗口高度
			title : "故宫博物院" ,	// 信息窗口标题
			message:"这里是故宫"
		}
		var infoWindow = new window.BMap.InfoWindow("地址：北京市东城区王府井大街88号乐天银泰百货八层", opts);
		var mapArry=[[112.949494,28.235399],[112.959494,28.235399],[112.969494,28.235399]];
		var path = [];
		var map = new window.BMap.Map("container");
		var point = new window.BMap.Point(112.949494,28.235399);
		map.centerAndZoom('长沙市岳麓区', 15);
		map.enableScrollWheelZoom();
		map.addEventListener('click', function (e) {
			console.log(e.Cg.lat,e.Cg.lng);
			$('#latNum').text(e.Cg.lat);
			$('#lngNum').text(e.Cg.lng)
		});
		for(var i=0;i<mapArry.length;i++){
			var pathNum={lng:mapArry[i][0],lat:mapArry[i][1]}
			path.push(pathNum)
		}
		for(var i=0;i<path.length;i++){
			map.addOverlay(new window.BMap.Marker(path[i]));
		}
	};
	componentDidMount(){
		this.showInfo();
	}
	render(){
		return (
			<Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
				<div className='topTextList'>
					<ul>
						<li>客户信息：<span>-</span></li>
						<li>联系电话：<span>2AAAAA</span></li>
						<li>字段：<span>3AAAAA</span></li>
						<li>字段：<span>4AAAAA</span></li>
						<li>字段：<span>5AAAAA</span></li>
						<li>字段：<span>6AAAAA</span></li>
						<li>经度：<span id='latNum'></span></li>
						<li>纬度：<span id='lngNum'></span></li>
					</ul>
				</div>
				<div id="container" style={{width:'100%',height:'80%'}}></div>
			</Layout>
		)
	}
}
