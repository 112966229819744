import React,{Component} from 'react';

import '../meterMap_div.less'
import '../meterMap_type.less'
import BaiduMap from "./baiduMap";
import '../less/middleType.less'
import {message, Modal, Table} from "antd";
import {getAlarmMapInfo} from "../../../../services/earlyWarning";
import {getDateByUserId} from "../../../../services/admin";
import {getPageMpAddress} from "../../../../services/customer";
import {getDeviceInfoByDevId} from "../../../../services/alarm";
import {getMeterById} from "../../../../services/meter";

// 左侧下部分样式
class middleType extends React.Component {

    state = {
        modalVisible_1:false,
        handleInfo_1:[
            {text:'客户号：'   ,value:'-'},
            {text:'客户名：'   ,value:'-'},
            {text:'客户地址：' ,value:'-'},
        ],
        handleInfo_2:[
            {text:'设备型号：'   ,value:'-'},
            {text:'表刚号：'     ,value:'-'},
            {text:'供应商：'   ,value:'-'},

        ],
        handleInfo_3:[
            {text:'用气性质：' ,value:'-'},
            {text:'累计流量：' ,value:'-'},
            {text:'累计温度：'     ,value:'-'},
            {text:'采集时间：'     ,value:'-'}
        ],
        // 10167蒸汽 10195燃气
        // 查询类型按钮参数
        buttonClickType:{
            currentNum:0,   // 之前所选数据
            // 点击#007c88，未点击linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)
            buttonType:[
                '#007c88',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
                'linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)',
            ],

        },
        // 表格表头 baiduMap
        tableParamenter_1_header:[
            {title: '报警时间', dataIndex: 'alarmTime', key: 'alarmTime',width:'15%',height:'10px'},
            {title: '安装位置', dataIndex: 'address', key: 'address',width:'30%',height:'10px',
                // render: (text, record) =>
                //     <div style={{borderRadius: '2px',cursor:'pointer',color:'#dde6eb'}} title={'查看'}
                //          onClick={() => {
                //              console.log('devId');
                //              console.log(text);
                //              console.log(record);
                //              this.baiduMap.getconsole(record.devId)
                //          }}
                //     >
                //         <u>{text}</u>
                //     </div>
            },
            {title: '报警信息', dataIndex: 'info', key: 'info',width:'30%',height:'10px'},
            {title: '报警类型', dataIndex: 'alarmType', key: 'alarmType',width:'15%',height:'10px'},
            {title: '', dataIndex: 'edit', key: 'edit',width:'10%',height:'10px'
                , render: (_, record) =>
                    <div style={{background:'#004968',borderRadius: '2px',cursor:'pointer',color:'#dde6eb',width:'45px',padding:"1px 1px 1px 8px"}} title={'查看'}
                         onClick={() => {
                             this.DevHelpInfo(record);
                         }}
                    >
                        <u>{'查看'}</u>
                    </div>
            },
        ],
        // 表格数据
        tableParamenter_1_data:[ ],
        // 表格参数
        tableParamenter_1_param:{},
        tableParamenter_1_loading:false,
        selectTableData:{},
        areaIds:[],
        pointType:'正常'
    };
    componentDidMount(): void {
        this.getDateByUserId();
    }

    // 查询类型按钮事件
    buttonOnClick=(num)=>{
        let buttonClickType = this.state.buttonClickType;
        // 与之前数据相同不做处理
        if(buttonClickType.currentNum===num){
            return false;
        }
        // 之前选项切换成未点击样式，现在点击的切换点击样式,并且覆盖记录当前点击位置的数据
        buttonClickType.buttonType[buttonClickType.currentNum]='linear-gradient(180deg, #0092a8 -10px,#003345 ,#0092a8  40PX)';
        buttonClickType.buttonType[num]='#007c88';
        buttonClickType.currentNum=num;
        this.setState({
            buttonClickType:buttonClickType,
            pointType:num===1?'正常':num===2?'故障':null

        })
    };

    // 获取当前用户区域id
    getDateByUserId=()=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    var areaIds = this.state.areaIds;
                    response?.data.map(one=>{
                        areaIds.push(one.id.toString());
                        one?.children?.map(two=>{
                            areaIds.push(two.id.toString());
                        })
                    });
                    this.setState({areaIds});
                    this.getTable(areaIds);
                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };
    // 获取表格数据
    getTable=(areaIds)=>{
        console.log('地址');
        var param ={
            "areaIds": areaIds,
            "page": 1,
            "size": 10
        };
        this.setState({tableParamenter_1_loading:true});
        getAlarmMapInfo(param).then(
            response => {
                if(response?.data?.data){
                    this.setState({tableParamenter_1_data:response?.data?.data})
                }
                this.setState({tableParamenter_1_loading:false});
            }
        )
    };
    // 设备报警信息
    DevHelpInfo=(record)=>{
        this.setState({modalVisible_1:true,selectTableData:record});
        this.getPageMpAddress(record.customerId);
        this.getDeviceInfoByDevId(record.meterId);
    };
    // 获取用户信息
    getPageMpAddress=(customerId)=>{
        var params={
            customerId:customerId,
            page: 1,
            size: 10
        };
        getPageMpAddress(params).then(
            response=>{
                if(response.data.data){
                    var data =response.data.data;
                    var handleInfo = this.state.handleInfo_1;
                    handleInfo[0].value=data[0].customerId;
                    handleInfo[1].value=data[0].customerName;
                    handleInfo[2].value=data[0].address;
                    this.setState({handleInfo_1:handleInfo});
                }
            }
        )
    };
    // 获取报警信息
    getDeviceInfoByDevId=(devId)=>{
        getMeterById({meterId:devId}).then(
            response=>{
                if(response?.data?.data){
                    var data =response.data.data;
                    var {handleInfo_2,handleInfo_3}=this.state;
                    handleInfo_2[0].value=data.modelName;
                    handleInfo_2[1].value=data.steelNum;
                    handleInfo_2[2].value=data.manufacture;

                    handleInfo_3[0].value=data.properties;
                    handleInfo_3[1].value=data.data[0].value;
                    handleInfo_3[2].value=data.data[1].value;
                    handleInfo_3[3].value=data.data[0].dataTime;
                    this.setState({handleInfo_2:handleInfo_2,handleInfo_3:handleInfo_3})
                }
            }
        )
    };
    render() {
        return (
            <div style={{ 'width':' 100%', 'height': '100%'}} id={'middleType'}>
                {/*地图*/}
                <div style={{  width: '100%',height: '77%'}}>
                    <BaiduMap
                        ref={e => this.baiduMap = e}
                        pointType={this.state.pointType}
                    />
                    {/*<test/>*/}
                </div>
                {/*表格*/}
                <div className={'body_middle_bottom_div'}>
                    <Table
                        rowClassName={(record,index)=>{

                            if(index % 2 === 0 ){
                                return "oddRow"
                            }else{
                                return "evenRow"
                            }
                        }}
                        columns={this.state.tableParamenter_1_header}
                        dataSource={this.state.tableParamenter_1_data}
                        rowKey={"id"}
                        // bordered
                        scroll={{ x: 'max-content', y: '130px' }}// 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        loading={this.state.tableParamenter_1_loading}
                        pagination={false}  // 不显示分页
                    >
                    </Table>
                </div>
                <div>
                    <Modal
                        title={'报警处理'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible_1}
                        okText="确定"
                        onOk={()=>this.setState({modalVisible_1:false})}           // 点击确定
                        onCancel={() =>this.setState({modalVisible_1:false})}       // 点击取消
                        width={'800px'}
                        centered
                    >
                        <div style={{width:'100%',height:'400px'}}>
                            {/*设备当前客户*/}
                            <div style={{float:'left',width:'33%',height: '40%'}}>
                                <div>
                                    <span style={{fontWeight: 'bolder',fontSize:'16px'}}>设备当前客户</span>
                                </div>
                                <div style={{width:'100%'}}>
                                    <ul>
                                        {this.state.handleInfo_1.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li style={{lineHeight: '30px'}}>
                                                        <span style={{color:'#000'}}>{one.text}</span>
                                                        <span style={{color:'#666'}}>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {/*设备当前信息*/}
                            <div style={{  float: 'left',width: '60%',height: '40%'}}>
                                <div>
                                    <span style={{fontWeight: 'bolder',fontSize:'16px'}}>设备当前信息</span>
                                </div>
                                <div style={{float:'left',width:'50%'}}>
                                    <ul>
                                        {this.state.handleInfo_2.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li style={{lineHeight: '30px'}}>
                                                        <span style={{color:'#000'}}>{one.text}</span>
                                                        <span style={{color:'#666'}}>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div style={{float:'left',width:'50%'}}>
                                    <ul>
                                        {this.state.handleInfo_3.map(one=>{
                                            return(
                                                <div  className={'dvi_top_left_li'}>
                                                    <li style={{lineHeight: '30px'}}>
                                                        <span style={{color:'#000'}}>{one.text}</span>
                                                        <span style={{color:'#666'}}>{one.value}</span>
                                                    </li>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div style={{width: '99.9%',
                                height: '1px',
                                backgroundColor: '#ccc',
                                // marginLeft: '1px',
                                float: 'left'}}/>{/*-------分割线------*/}
                            <div style={{width:'100%',float: 'left'}}>



                                <ul>
                                    <li style={{lineHeight: '30px',color:' #000000'}}>
                                        <span style={{color:'#000'}}>报警时间：</span>
                                        <span style={{color:'#666'}}>{this.state.selectTableData.alarmTime}</span>
                                    </li>
                                    <li style={{lineHeight: '30px',color:' #000000'}}>
                                        <span style={{color:'#000'}}>报警信息</span>
                                        <div style={{  border:this.state?.selectTableData?.info?' 1px rgb(255 0 0) solid':null,padding:'3px'}}>
                                            <span style={{color:'#666'}}>{this.state.selectTableData.info}</span>
                                        </div>
                                    </li>
                                </ul>
                                {/*{title: '报警时间', dataIndex: 'alarmTime', key: 'alarmTime',width:'15%',height:'10px'},*/}
                                {/*{title: '安装位置', dataIndex: 'address', key: 'address',width:'30%',height:'10px'},*/}
                                {/*{title: '报警信息', dataIndex: 'info', key: 'info',width:'30%',height:'10px'},*/}
                                {/*{title: '报警类型', dataIndex: 'alarmType', key: 'alarmType',width:'15%',height:'10px'},*/}
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}
export default middleType;
