import React, { Component } from 'react'
import {Layout} from "antd";
import './dataPanel_v.02.less'
import { ExpandOutlined } from '@ant-design/icons';
import MiddleLeft from './subpage/middleLeft'
import MiddleRight from './subpage/middleRight'
import MiddleMap1 from "./subpage/middleMap";
import MiddleMap2 from "./subpage/middleMap";
import BottomFive from "./subpage/bottomFive";
import BottomThree from "./subpage/bottomThree";
import BottomTwo from "./subpage/bottomTwo";
import BottomOne from "./subpage/bottomOne";
import FormsTwo from "../../components/common/forms/FormsTwo";
import BarChart from "./assembly/BarChart";
import {DoubleRightOutlined,DoubleLeftOutlined,ExclamationCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';
import moment from "moment";
import axios from "axios";
export default class dataPanel extends Component {
    state = {
        middleMiddleType:true,
        headLeftDate:'22',  // 时间
        warn_type_1:true,   // 警告栏
        textSwitch:1,
    };

    componentDidMount = async () => {

        // 循环加载
        setInterval(()=>{
            this.circulateTime();
        },10000);


        // const aixos = require('axios');
        // try{
        //     const apiKey = 'YOUR_API_KEY';
        //     const cityName = 'Beijing';
        //     const url = `http://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${apiKey}`;
        //     const response = await axios.get(url);
        //     const temperature = response.data.main.temp - 273.15;
        //     console.log(`今天${cityName}的气温为${temperature}°C`);
        // }catch (e) {
        //     console.log('无法获取气温信息',e);
        // }


        this.getTime(); // 初始化加载当天时间
    };
    getTime=()=>{
        //获得显示时间的div
        var now=new Date();
        //替换div内容
        console.log(now.getDay());
        var t_div  =  <>
            <div>
                <div style={{width:'33%',float:'left'}}>
                    <span>{moment(now).format("HH:mm:ss")}</span>
                </div>
                <div style={{width:'43%',float:'left'}}>
                    <span>{moment(now).format("yyyy-MM-DD")}</span>
                </div>
                <div style={{width:'20%',float:'left'}}>
                    <span>{' 星期'+'日一二三四五六'.charAt(new Date().getDay())}</span>
                </div>

                {/*moment(now).format("HH:mm:ss  ")+moment(now).format("  yyyy-MM-DD")+("  星期"+now.getDay())*/}
            </div>
        </>;


        this.setState({headLeftDate:t_div});
        // setTimeout(this.getTime(),1000);
    };
    // 循环时间
    circulateTime=()=>{
        this.setState({
            warn_type_1:!this.state.warn_type_1,
            textSwitch:  Math.floor(Math.random() * 3)
        })
    };

    getwarningBarText = () =>{
        switch (this.state.textSwitch) {
            case 1:
                return<>
                    <div style={{width:'98%',height:'100%',position: 'absolute',padding :'0px 2px 0 30px'}}>
                        <ExclamationCircleOutlined style={{ fontSize: '28px',color: '#ff5654' ,float:'left',marginTop:'10px'}} />
                        <span style={{ fontSize: '18px',color: '#ff5654',marginLeft:'8px',float:'left',marginTop:'8px'}}>处理超时：</span>
                        <div style={{width:'72%',height:'100%',float:'left', flexWrap: 'wrap',paddingTop:'2px',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                            <span style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>关于</span>
                            <span style={{fontSize: '14px', color: '#ff9a27', }}>湖南省长沙市岳麓区学士路336号A栋04单元</span>
                            <span style={{fontSize: '14px', color: '#fff', }}>的可燃气体探测器发生燃气泄露的报</span>
                            <p style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>警，已超过10分钟未进行处理，请及时催办！</p>
                            {/*    湖南省长沙市岳麓区学士路336号A栋04单元的可燃气体探测器发生燃气泄露的报警，已超过10分钟未进行处理，请及时催办！*/}
                        </div>
                        <div style={{width:'10%',height:'100%',float:'left', flexWrap: 'wrap',padding:'10px 0 0 5px'}}>
                            <div className={'middle_middle_warningBar_div_buttom'}>
                                <span style={{color:'#fff'}}>查看</span>
                            </div>
                        </div>
                    </div>
                </>;
            case 2:
                return<>
                    <div style={{width:'98%',height:'100%',position: 'absolute',padding :'0px 2px 0 30px'}}>
                        <ExclamationCircleOutlined style={{ fontSize: '28px',color: '#ff5654' ,float:'left',marginTop:'10px'}} />
                        <span style={{ fontSize: '18px',color: '#ff5654',marginLeft:'8px',float:'left',marginTop:'8px'}}>处理超时：</span>
                        <div style={{width:'72%',height:'100%',float:'left', flexWrap: 'wrap',paddingTop:'2px',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                            <span style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>关于</span>
                            <span style={{fontSize: '14px', color: '#ff9a27', }}>湖南省长沙市岳麓区学士路336号B栋01单元</span>
                            <span style={{fontSize: '14px', color: '#fff', }}>的可燃气体探测器发生燃气泄露的报</span>
                            <p style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>警，已超过1分钟未进行处理，请及时催办！</p>
                            {/*    湖南省长沙市岳麓区学士路336号A栋04单元的可燃气体探测器发生燃气泄露的报警，已超过10分钟未进行处理，请及时催办！*/}
                        </div>
                        <div style={{width:'10%',height:'100%',float:'left', flexWrap: 'wrap',padding:'10px 0 0 5px'}}>
                            <div className={'middle_middle_warningBar_div_buttom'}>
                                <span style={{color:'#fff'}}>查看</span>
                            </div>
                        </div>
                    </div>
                </>;
            case 3:
                return<>
                    <div style={{width:'98%',height:'100%',position: 'absolute',padding :'0px 2px 0 30px'}}>
                        <ExclamationCircleOutlined style={{ fontSize: '28px',color: '#ff5654' ,float:'left',marginTop:'10px'}} />
                        <span style={{ fontSize: '18px',color: '#ff5654',marginLeft:'8px',float:'left',marginTop:'8px'}}>处理超时：</span>
                        <div style={{width:'72%',height:'100%',float:'left', flexWrap: 'wrap',paddingTop:'2px',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                            <span style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>关于</span>
                            <span style={{fontSize: '14px', color: '#ff9a27', }}>湖南省长沙市芙蓉区韭菜园街道八一路284号湘汇大厦</span>
                            <span style={{fontSize: '14px', color: '#fff', }}>探测器发生燃气泄露的报</span>
                            <p style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>警，已超过1分钟未进行处理，请及时催办！</p>
                            {/*    湖南省长沙市岳麓区学士路336号A栋04单元的可燃气体探测器发生燃气泄露的报警，已超过10分钟未进行处理，请及时催办！*/}
                        </div>
                        <div style={{width:'10%',height:'100%',float:'left', flexWrap: 'wrap',padding:'10px 0 0 5px'}}>
                            <div className={'middle_middle_warningBar_div_buttom'}>
                                <span style={{color:'#fff'}}>查看</span>
                            </div>
                        </div>
                    </div>
                </>;
            default:
                return<>
                    <div style={{width:'98%',height:'100%',position: 'absolute',padding :'0px 2px 0 30px'}}>
                        <ExclamationCircleOutlined style={{ fontSize: '28px',color: '#ff5654' ,float:'left',marginTop:'10px'}} />
                        <span style={{ fontSize: '18px',color: '#ff5654',marginLeft:'8px',float:'left',marginTop:'8px'}}>处理超时：</span>
                        <div style={{width:'72%',height:'100%',float:'left', flexWrap: 'wrap',paddingTop:'2px',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                            <span style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>关于</span>
                            <span style={{fontSize: '14px', color: '#ff9a27', }}>湖南省长沙市芙蓉区韭菜园街道八一路284号湘汇大厦</span>
                            <span style={{fontSize: '14px', color: '#fff', }}>探测器发生燃气泄露的报</span>
                            <p style={{fontSize: '14px', color: '#fff', marginLeft:'10px' }}>警，已超过1分钟未进行处理，请及时催办！</p>
                            {/*    湖南省长沙市岳麓区学士路336号A栋04单元的可燃气体探测器发生燃气泄露的报警，已超过10分钟未进行处理，请及时催办！*/}
                        </div>
                        <div style={{width:'10%',height:'100%',float:'left', flexWrap: 'wrap',padding:'10px 0 0 5px'}}>
                            <div className={'middle_middle_warningBar_div_buttom'}>
                                <span style={{color:'#fff'}}>查看</span>
                            </div>
                        </div>
                    </div>
                </>;

        }


    };


    render() {

        return (
            <Layout id={'dataPanel2'}>
                <div className={'body_type'}>
                    {/*顶部-标题行--------------------------------------------------------------------------------------------------------*/}
                    <div className={'head_type'}>
                        <div className={'head_type_img'}>
                            <div  style={{width:'30%',height:'100%',float:'left',padding:'1.7% 1% 1.5% 5%',color:'#fff'}}>
                                <div style={{width:'100%',height:'100%'}}>
                                    <div style={{float:'left',width:'45%'}}>
                                        <span>管理员 罗成 12055559138</span>
                                    </div>
                                    <div style={{float:'left',width:'50%'}}>
                                        <span>负责人 何水潇 135233788130</span>
                                    </div>
                                </div>
                            </div>
                            <div  style={{width:'40%',height:'100%',float:'left',textAlign: 'center',paddingTop:'1.4%'}}>
                                <span style={{color:'#fff',fontSize:'28px'}}>长沙市城市燃气安全可视化大屏</span>
                            </div>
                            <div  style={{width:'30%',height:'100%',float:'left',padding:'1.7% 0% 2.5% 6%',color:'#fff'}}>
                                <div style={{width:'100%',height:'100%'}}>
                                    <div style={{float:'left',width:'60%',fontSize:'17px',borderRight: '3px #b4b4b5 ridge'}}>
                                        {this.state.headLeftDate}
                                    </div>
                                    <div style={{float:'left',width:'35%',height:'100%'}}>
                                        <div className={'head_type_right_img_1'}/>
                                        {/*<div className={'head_type_right_img_2'}/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*中部---------------------------------------------------------------------------------------------------------------*/}
                    <div className={'middle_type'}>
                        {/*左*/}
                        <div className={'middle_left_type'}>
                            <div className={'middle_left_img'}>
                                <MiddleLeft/>
                            </div>
                        </div>
                        {/*中*/}
                        <div className={this.state.middleMiddleType?'middle_middle_type_1':'middle_middle_type_2'}>
                            {/*大小按钮*/}
                            <div className={this.state.middleMiddleType?'middle_middle_MiddleMap_type_1':'middle_middle_MiddleMap_type_2'}
                                onClick={()=>{this.setState({middleMiddleType:!this.state.middleMiddleType});}}
                            ><ExpandOutlined style={{ fontSize: '18px', color: '#fff' }}/>
                            </div>
                            {/*提示条------------------------------------------------------------------------------------------*/}
                            <div
                                className={this.state.middleMiddleType?'middle_middle_warningBar_div_1':'middle_middle_warningBar_div_2'}
                                style={{display:this.state.warn_type_1?null:'none'}}
                            >
                                {this.getwarningBarText()}

                                {/*关闭提示栏按钮*/}
                                <CloseCircleOutlined style={{float:'right',cursor: 'pointer',color:'#fff'}} onClick={()=>{this.setState({warn_type_1:false});}}/>
                                {/*底图*/}
                                <div style={{backgroundColor: 'rgb(10 48 95/90%)', width:'100%',height:'100%'}}/>
                            </div>
                            {/*地图*/}
                            <MiddleMap1 elementById={'MiddleMap1'}/>
                        </div>


                        {/*右*/}
                        <div className={'middle_right_type'}>
                            <div className={'middle_right_img'}>
                                <MiddleRight/>
                            </div>
                        </div>
                    </div>
                    {/*/!*低部---------------------------------------------------------------------------------------------------------------*!/*/}
                    <div className={'bottom_type'}>
                        {/*1*/}
                        <div className={'bottom_type_1'}>
                            <div className={'bottom_type_1_img'}>
                                <BottomOne/>
                            </div>
                        </div>
                        {/*2*/}
                        <div className={'bottom_type_2'}>
                            <div className={'bottom_type_2_img'}>
                                <BottomTwo/>
                            </div>
                        </div>
                        {/*3*/}
                        <div className={'bottom_type_3'}>
                            <div className={'bottom_type_3_img'}>
                                <BottomThree/>
                            </div>
                        </div>
                        {/*4*/}
                        <div className={'bottom_type_4'}>
                            <div className={'bottom_type_4_img'}>
                                <BottomFive/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
