import React from 'react';

import * as echarts from 'echarts';

// 折线图-单挑
class StackedLineChartOne extends React.Component {
    componentDidMount() {
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");

        chartDom.setOption(
            {
                title: {
                    text: '七日数据表',
                    textStyle:{
                        fontSize:'12',
                        color:"#fff",
                    },
                },
                tooltip: {
                    trigger: 'axis',
                },

                // 提示
                legend: {

                    data: ['维护数'],
                    align:'left',
                    right:4,
                    textStyle:{
                        fontSize:'12',
                        color:"#fff"
                    },
                    itemWidth:14,// 长度
                    itemHeight:4,// 小圆圈大小
                    itemGap:5// 间距
                },
                // 边距
                grid: {
                    top:'14%',
                    left: '5%',
                    right: '5%',
                    bottom: '10%',
                    containLabel: true
                },
                // toolbox: {
                //     feature: {
                //         saveAsImage: {}
                //     }
                // },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['1日', '2日', '3日', '4日', '5日', '6日', '7日'],
                    axisLine:{
                        lineStyle:{
                            color:"#fff"
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    min:this.props.axis.min,     // 最小值
                    max:this.props.axis.max,    // 最大值
                    interval:this.props.axis.interval,// 数值间隔
                    // data:[50,100,150.200,250,300,350],// 只有type为category时有效
                    axisLine:{
                        lineStyle:{
                            color:"#fff"
                        }
                    }
                },

                series:
                    [
                        {
                            name: '维护数',
                            type: 'line',
                            // stack: 'Total',
                            data: this.props.data,
                            // smooth: true, //默认是false,判断折线连线是平滑的还是折线
                            itemStyle: {
                                normal: {
                                    color: "#ff7752", //改变折线点的颜色
                                    lineStyle: {
                                        color: "#ff7752", //改变折线颜色
                                    },
                                },
                            },
                        }
                    ]
            })
    }

    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '90%' }}>

            </div>
        );
    }
}

export default StackedLineChartOne;
