import React, { Component } from 'react'
import {Layout, Button, Popconfirm, message, Input, Modal, Table, Radio, Pagination,} from 'antd'
import './InstallationInfoNew.less'
import {
    getAllInfoByCustomerId,
    addOrUpdateGasBurner,
    getGasDeviceList,
    removeGasBurner,
    addOrUpdateSecurityCheckpoint,
    removeSecurityCheckpoint,
    getGasBurnerInfoByCustomerId,
    getDevInfoByCustomerId,
    devUnboundByCustomerId
} from '../../../services/meter'
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {FORM_ITEM, TYPE_NAME} from "../../../components/Const";
import { Map, Marker, Geolocation } from '@uiw/react-amap';
import $ from "jquery";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {queryHandheldTerminal, registerHandheldTerminal, unbindHandheldTerminal} from "../../../services/equipment";
import {getVersionInfosByPcAdmin} from "../../../services/IKit";
import moment from "moment";
const { AMap } = window;
// import {InstallationMap} from '../businessNew/InstallationMap'
// //工商户详情页 - 安检点
export default class Security extends Component {
    state = {
        title1:'123',
        // 表格标题-1
        left_tableData_1: [
            {title: '燃气具类型', dataIndex: 'name', key: 'name'},
            {title: '燃气具品牌', dataIndex: 'brand', key: 'brand'},
            {title: '燃气具型号', dataIndex: 'model', key: 'model'},
            {title: '初装日期', dataIndex: 'startTime', key: 'startTime'},
            {title: '状态', dataIndex: 'state', key: 'state',},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (_, record) =>
                    <>
                        <a onClick={ async () => {
                            console.log(record);
                            record.startTime=record.startTime?moment(record.startTime):null;
                            record.productiveTime=record.productiveTime?moment(record.productiveTime):null;
                            record.gasDeviceName=record.name;
                            console.log(record);
                            await this.setState({
                                topFormItem_1_data:record,
                                openMpVisible_1:true,                // 开关
                                selectGasDeviceId:record.gasDeviceId,// 选择的id
                                openMpVisible_type_1:false               // 类型
                            });
                            // gasDeviceName
                            await this.forms1?.onModalClose();
                        }}
                        >修改</a>
                        <a
                            style={{paddingLeft:'10px'}}
                            onClick={() => {
                            removeGasBurner({gasBurnerId:record.id}).then(
                                response=>{
                                    if(response?.data){
                                        message.info('删除成功');

                                    }else{
                                        message.info('删除失败');
                                    }
                                    this.getAllInfoByCustomerId();
                                })

                        }}>删除</a>
                    </>
            },
        ],
        // 表格参数-1-参数
        left_tableData_1_param:{
            customerId:this.props.location?.state?.rowData?.customerId,
        },
        left_tableData_1_data:[],// 表格参数-1-数据
        //----------------------------------------------------
        // 表格标题-2
        right_tableData_2: [
            {title: '安检点类型', dataIndex: 'type', key: 'type'},
            {title: '电子标签号', dataIndex: 'ifIdCode', key: 'ifIdCode'},
            {title: '设备编号', dataIndex: 'devId', key: 'devId'},
            {title: '安检点状态', dataIndex: 'selfInspectionStatus', key: 'selfInspectionStatus'},
            {title: '最后安检时间', dataIndex: 'endSecurityCheckTime', key: 'endSecurityCheckTime'},
            {title: '最后自检时间', dataIndex: 'endSelfTestTime', key: 'endSelfTestTime'},
            // {title: '状态', dataIndex: 'state', key: 'state',},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (_, record) =>
                    <>
                        <a onClick={ async () => {
                            console.log(record);
                            await this.setState({
                                topFormItem_2_data:record,
                                openMpVisible_2:true,
                                selectGasDeviceId:record.gasDeviceId,
                                openMpVisible_type_1:false,
                                openMpVisible_type_2:false,
                                coordinate:{
                                    longitude:record.longitude,
                                    latitude:record.latitude,
                                },

                            });
                            await this.forms2?.onModalClose();
                            if(record.type){
                                this.getModelButtonType(record.type);
                                this.setState({selectGasDeviceId:record.type||null})
                            }


                        }}
                        >修改</a>
                        <a
                            style={{paddingLeft:'10px'}}
                            onClick={() => {
                            removeSecurityCheckpoint({securityCheckpointId:record.id}).then(
                                response=>{
                                    if(response?.data){message.info('删除成功');
                                    }else{message.info('删除失败');}
                                    this.getAllInfoByCustomerId();
                                })

                        }}>删除</a>
                    </>
            },
        ],
        right_tableData_1_param:{
            customerId:this.props.location?.state?.rowData?.customerId,
        },
        right_tableData_1_data:[],// 表格参数-2-数据


        // 弹窗开关-1
        openMpVisible_1:false,
        openMpVisible_type_1:true,// 0添加，1修改


        selectGasDeviceId:null,
        selectLife:null,

        // 条件查询栏燃气具-1
        topFormItem_1: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'gasDeviceName', str: '燃气具类型', rules: TYPE_NAME.AutoComplete,disabled: false,
                    data: [],
                    onChange: (k,v) => {
                        this.state.topFormItem1.parameter[0].data.map(
                            one=>{
                                if(one.key.toString()===v.key.toString()) {
                                    if(!one.life){return message.info('当前设备无使用年限，不可用')}
                                    this.setState({selectLife:one.life||null});
                                }});
                        this.setState({selectGasDeviceId:v.key||null})
                    },
                },
                {variableName: 'brand', str: '燃气具品牌', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'model', str: '燃气具型号', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'state', str: '状态', rules: TYPE_NAME.SELECT,disabled: false,
                    data: [
                        {key: '0', value: '正常'},
                        {key: '1', value: '过期'},
                    ]
                },

                {variableName: 'startTime', str: '初装日期', rules: FORM_ITEM.DATE_PICKER,disabled: false,},
                {variableName: 'productiveTime', str: '出厂日期', rules: FORM_ITEM.DATE_PICKER,disabled: false,},
            ]
        },
        // 弹窗开关-2
        openMpVisible_2:false,

        openMpVisible_type_2:true,// 0添加，1修改
        // 条件查询栏安检点-2
        topFormItem_2: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'type', str: '安检点类型', rules: TYPE_NAME.REQ_SELECT,disabled: false,
                    data:[
                        {key: '接口', value: '接口'},
                        {key: '阀门', value: '阀门'},
                    ],

                    onChange: (k,v) => {
                    this.getModelButtonType(v.key);
                    this.setState({selectGasDeviceId:v.key||null})
                    },
                },
                {variableName: 'securityCheckpointNumber',
                    str: '安检点编号',
                    rules: TYPE_NAME.NOT_BLANK,
                    disabled: false,
                },

                {variableName: 'stoveId', str: '燃气具类型', rules: TYPE_NAME.SELECT,disabled: false,
                    data:[]
                },
                {variableName: 'ifIdCode', str: '电子标签唯一编码', rules: TYPE_NAME.NOT_BLANK,disabled: false,},

                {variableName: 'location', str: '位置', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'longitude', str: '经度', rules: TYPE_NAME.INPUT,disabled: true,},
                {variableName: 'latitude', str: '维度', rules: TYPE_NAME.INPUT,disabled: true,},
            ]
        },
        // 照片
        previewVisible: false,  // 照片弹窗
        previewImage: '',       // 浏览图片路径
        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [], // 设备照片
        newFileList:[],

        // 地图坐标
        showMap:[116.397428, 39.90923],
        // 地图标点
        coordinate:{
            longitude:112.962538,
            latitude:28.195452,
        },
        topFormItem_2_data:{
            longitude:112.962538,
            latitude:28.195452,
        },



    //    智慧手持终端机

        // 表格参数
        tableHandheldDevices: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                modelName: '终端型号',
                num: '终端编号',
                communicationNum: '通信号',
                manufacturer: '供应商',
                status: '状态',
                createTime: '添加时间',
                customerName: '客户',
                edit: '操作',
            },
            params: {
                customerId:this.props.location.state.id,
                page:1,
                size:10,
            },
            // 是否绑定左侧区域
            bindArea: false
        },
        // 弹窗-终端表格参数
        modalTableHandheldDevices: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: true,
            overflow:true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                modelName: '终端型号',
                num: '终端编号',
                createTime: '添加时间',
                communicateTime: '最后一次通讯时间'
            },
            params: {
                status:'未激活',
                page:1,
                size:10,
            },
            // 是否绑定左侧区域
            bindArea: false
        },
        openMpVisible_3:false,
        openMpVisible_4:false,
        // 阀门表格参数
        tableParamenter_1: {
            manageName: "阀门表格参数",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                // edit: '操作',
            },
            params: {
                customerId:this.props.location?.state?.rowData?.customerId??null,
                devType:'阀门',
                page:1,
                size:10,

            },
        },

        modelButtonType:[
                <Button key="back"  onClick={async() => {
                    await this.forms2.onFinish();
                    await this.setState({openMpVisible_2:false});
                }}>
                    返回
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    // loading={loading}
                    onClick={async() => {
                        this.addOrUpdateSecurityCheckpoint();
                    }}
                >
                    提交
                </Button>,
        ],
    };

    // 弹窗按钮样式
    getModelButtonType = async (type) =>{
        console.log('弹窗按钮样式');
        console.log(type);
        var modelButtonType = [...this.state.modelButtonType];
        switch (type) {
            case '阀门':
                modelButtonType=[
                    <Button key="back"  onClick={ async () => {
                        var data = await this.forms2.onFinishNotReset();
                        if(!data){return false;}
                        await this.setState({openMpVisible_2:false});
                    }}>
                        返回
                    </Button>,
                    <Button key="submit" type="primary" onClick={async() => {
                        var data = await this.forms2.onFinishNotReset();
                        if(!data){return false;}
                        this.setState({openMpVisible_4:true});}}>
                        下一步
                    </Button>,
                ];
                break;
            default:
                modelButtonType=[
                    <Button key="back"  onClick={async() => {
                        await this.forms2.onFinish();
                        await this.setState({openMpVisible_2:false});
                    }}>
                        返回
                    </Button>,
                    <Button key="submit" type="primary" onClick={async() => {
                        this.addOrUpdateSecurityCheckpoint();
                    }}>
                        提交
                    </Button>,
                ];
                break;
        }
        console.log('执行');
        await this.setState({modelButtonType:modelButtonType});
    };



    // 修改弹窗
    openMpVisible_1_select=async record =>{
        console.log(record);
        await this.setState({
            topFormItem_1_data:record,
            openMpVisible_1:true,
            selectGasDeviceId:record.gasDeviceId,
            openMpVisible_type_1:false
        });
        await this.forms1?.onModalClose()
    };


    componentDidMount(): void {
        this.getAllInfoByCustomerId();   // 根据客户Id查询安检点燃气具信息
        this.getGasDeviceList();        // 查询所有的用气设备

    }
    // 查询所有的用气设备
    getGasDeviceList=()=>{
        getGasDeviceList().then(
            response=>{
                if(response?.data){
                    var topFormItem1 = this.state.topFormItem_1;
                    // var topFormItem2 = this.state.topFormItem_2;
                    var data = response?.data?.data?.data;

                    let selectArry=[];
                    data?.map(one=>{
                        selectArry.push({key: one.id, value: one.name,life:one.life})
                    });
                    topFormItem1.parameter[0].data=selectArry;
                    // topFormItem2.parameter[0].data=selectArry;
                    this.setState({
                        topFormItem1,
                        // topFormItem2
                    })
                }
            }
        );
    };


    // 根据客户Id查询安检点燃气具信息
    getAllInfoByCustomerId=(customerId)=>{

        getAllInfoByCustomerId({customerId:this.props.location?.state?.rowData?.customerId}).then(
            response=>{
                var leftTableData1Data = this.state.left_tableData_1_data;
                var rightTableData2Data = this.state.right_tableData_1_data;
                if(response?.data){
                    var data = response.data.data;
                    leftTableData1Data=data?.voGasBurnerList || null;
                    rightTableData2Data=data?.voSecurityCheckpointList || null;
                    leftTableData1Data?.forEach(
                        one=>{
                            one.state===0?one.state='正常':one.state='过期'
                        });
                    rightTableData2Data?.forEach(
                        one=>{
                            one.state===0?one.state='正常':one.state='过期'
                        });
                    console.log(leftTableData1Data)
                    console.log(rightTableData2Data)

                    this.setState({
                        left_tableData_1_data:leftTableData1Data,
                        right_tableData_1_data:rightTableData2Data,
                    })
                }

        })
    };

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            // funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList:file.fileList})
        console.log(this.state.fileList)
    };

    // 左表数据
    setTableValue_1=(value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'contractNum':
                return <>

                    <a style={{paddingLeft:'15px', float: 'left'}}
                       onClick={() => {
                           console.log(rowData);
                           this.props.history.push({
                               pathname:"/customer/InstallationMap",
                               state:{
                                   formsTwo:this.state?.formsTwo_data,
                                   rowData:rowData,
                                   juder:true
                               }
                           })
                       }}
                    >修改</a>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 右表数据
    setTableValue_2=(value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return <>

                    <a style={{paddingLeft:'15px', float: 'left'}}
                       onClick={() => {
                           console.log(rowData);
                           this.props.history.push({
                               pathname:"/customer/InstallationMap",
                               state:{
                                   formsTwo:this.state?.formsTwo_data,
                                   rowData:rowData,
                                   juder:true
                               }
                           })
                       }}
                    >修改</a>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 新增(修改)燃气具
    addOrUpdateGasBurner = async () =>{
        // 关闭弹窗
        var formsData = await this.forms1.onFinishNotReset(); // 获取当前表单数据
        if(!formsData){return false}
        var pragmas={};
        Object.assign(pragmas,formsData);

        var topFormItem = this.state.topFormItem_1;
        topFormItem.parameter.map(one=>{one.disabled=true;});
        this.setState({MBJuder:true});


        // 判断有无用户id
        if(this.props.location?.state?.rowData?.customerId){
            pragmas.customerId=this.props.location?.state?.rowData?.customerId;
        }else{
            topFormItem.parameter.map(one=>{one.disabled=false;});
            this.setState({MBJuder:false});
            return message.info('获取不到用户参数，请刷新页面')
        }
        pragmas.state=pragmas.state==='正常'?0:pragmas.state==='过期'?1:pragmas.state;
        pragmas.gasDeviceId=this.state.selectGasDeviceId||null; // 若选择其他已用设备就会有新id.单独参数适用于新增和修改指定设备id
        pragmas.id=this.state.topFormItem_1_data.id||null;      // 若选择其他已用设备就会有新id
        if(this.state.selectLife) {
            pragmas.life = this.state.selectLife;
        }else{
            topFormItem.parameter.map(one=>{one.disabled=false;});
            this.setState({MBJuder:false});
            return message.info('当前设备无使用年限，不可用')
        }
        pragmas.startTime=pragmas.startTime?moment(pragmas.startTime).format('YYYY-MM-DD HH:mm:ss'):null;
        pragmas.productiveTime=pragmas.productiveTime?moment(pragmas.productiveTime).format('YYYY-MM-DD HH:mm:ss'):null;


        addOrUpdateGasBurner(pragmas).then(
            response=>{
                if(response?.data?.data){
                    message.info('添加成功');
                    this.getAllInfoByCustomerId();
                    this.setState({MBJuder:false,openMpVisible_1:false});
                }else {
                    message.info('添加失败');
                    this.setState({MBJuder:false,openMpVisible_1:true});
                }
                topFormItem.parameter.map(one=>{one.disabled=false;});

            }
        )
    };
    // 新增(修改)安检点
    addOrUpdateSecurityCheckpoint= async ()=>{
        // 关闭弹窗
        var formsData = await this.forms2.onFinish(); // 获取当前表单数据
        var data = await this?.table1?.getSelectChange();
        if(!formsData){return false}
        var input = document.getElementById("latNum");

        this.setState({openMpVisible_2:false});
        var pragmas={};
        Object.assign(pragmas,formsData);
        // 判断有无用户id
        if(this.props.location?.state?.rowData?.customerId){
            pragmas.customerId=this.props.location?.state?.rowData?.customerId;
        }else{
            return message.info('获取不到用户参数，请刷新页面')
        }
        pragmas.state=pragmas.state==='正常'?0:pragmas.state==='过期'?1:pragmas.state;
        pragmas.gasDeviceId=this.state.selectGasDeviceId||null;// 若选择其他已用设备就会有新id.单独参数适用于新增和修改指定设备id\


        if(data){
            if(data.length!==0){
                pragmas.devId=data[0].devId;
            }else{
                pragmas.devId=null;
            }
        }else{
            pragmas.devId=null;
        }

        pragmas.id=this.state.topFormItem_2_data?.id||null;// 若选择其他已用设备就会有新id
        pragmas.address=input.value;
        console.log(pragmas);
        addOrUpdateSecurityCheckpoint(pragmas).then(
            response=>{
                if(response?.data?.data){
                    message.info('添加成功');
                    this.getAllInfoByCustomerId();
                }else {
                    message.info('添加失败')
                }
            }
        )
    };
    // 根据客户ID查询燃气具信息
    getGasBurnerInfoByCustomerId=()=> {

        var tableData1Data = this.state.left_tableData_1_data;
        var topFormItem2 = this.state.topFormItem_2;
        let selectArry=[];
        tableData1Data?.map(one=>{selectArry.push({key: one.id, value: one.name})});
        topFormItem2.parameter[2].data=selectArry;
        this.setState({topFormItem2})
        // getGasBurnerInfoByCustomerId({customerId: this.props.location?.state?.rowData?.customerId}).then(
        //     response => {
        //         var topFormItem2 = this.state.topFormItem_2;
        //         var data = response?.data?.data;
        //
        //         let selectArry=[];
        //         data?.map(one=>{
        //             selectArry.push({key: one.id, value: one.model})
        //         });
        //         console.log(selectArry);
        //         topFormItem2.parameter[2].data=selectArry;
        //         this.setState({
        //             topFormItem2,
        //             // topFormItem2
        //         })
        //     }
        // )
    };


    onSuccess = (data) => {
        const { position } = data;
        const geocoder = new AMap.Geocoder();
        geocoder.getAddress([position.lng, position.lat], (status, result) => {
            if (status === 'complete' && result.regeocode) {
                const { formattedAddress } = result.regeocode;
                console.log(formattedAddress); // 输出地址信息
            } else {
                console.log('无法获取地址信息')
            }
        });
    };

    setTableValue = (value,text,rowData) => {
        switch (value) {

            case 'communicateTime':
                var title='-';
                if(rowData?.dataItems){title=rowData?.dataItems[0]?.communicateTime ?? '-'}
                return <>
                    <span title={'最后一次通讯时间'}>
                        {title}
                    </span>
                </>;
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'解绑'}>
                        <Popconfirm
                            title="是否确定解绑"
                            description="是否确定解绑?"
                            onConfirm={()=>{

                                this.unbindHandheldTerminal(rowData.id)
                            }}
                            // onCancel={cancel}
                            okText="是"
                            cancelText="否"
                        >
                            <u>{'解绑'}</u>
                        </Popconfirm>

                    </div>
                    </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };

    // 解绑手持终端
    unbindHandheldTerminal=(id)=>{

        unbindHandheldTerminal({htIdList:[id]}).then(
            response=>{
                if(response?.data?.data){
                    message.info('解绑成功');
                    this.tableHandheldDevices.refreshTable();

                }else{
                    message.info('解绑失败')
                }
                this.setState({openMpVisible_3:false})
            }
        ).catch(e=>{message.info('解绑异常')})
    };


    //激活
    registerHandheldTerminal=()=>{
        var getSelectChange = this.modalTableHandheldDevices.getSelectChange();
        if(getSelectChange.length===0)return message.info('请选择客户');

        var params={
            "customerId": this.props.location?.state.customerId,
            "customerName": this.props.location?.state.customerName,
            "htIdList": [getSelectChange[0].id]
        };

        registerHandheldTerminal(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('激活成功');
                    this.tableHandheldDevices.refreshTable();

                }else{
                    message.info('激活失败')
                }
                this.setState({openMpVisible_3:false})
            }
        ).catch(e=>{message.info('激活异常')})
    };
    render() {

        const uploadButton = (
            <div>
                {/*<Icon type="plus" />*/}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );


        return (
            <Layout
                className={'item_body_number_back tables'}
                style={{backgroundColor: '#ffffff'}}>

                <div style={{ width:'100%',height:'480px'}}>
{/*燃气具-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{backgroundColor:"#fff",width:'49.9%',height:'100%',float:'left'}}>
                        <div style={{width:'98%',marginLeft:'10px'}}>
                            <div>
                                <a onClick={async ()=>{
                                    this.getGasDeviceList();
                                    await this.setState({
                                        openMpVisible_1:true,
                                        openMpVisible_type_1:true,
                                        topFormItem_1_data:{},
                                        selectGasDeviceId:null
                                    });
                                    await this.forms1?.onModalClose()
                                }}>添加燃气具</a>
                                <a type={'primary'} style={{marginLeft:"85%"}} onClick={()=>{this.getAllInfoByCustomerId();}}>刷新</a>
                            </div>
                        </div>
                        <div style={{margin:'5px'}}>
                            <Table
                                columns={this.state.left_tableData_1}
                                dataSource={this.state.left_tableData_1_data}
                                // rowKey={"id"}
                                bordered
                                pagination = {{
                                    showSizeChanger:false,
                                    showQuickJumper: false,
                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                    total:this.state.left_tableData_1_data?.length,
                                    pageSize:6,
                                    showTotal: (count = this.state.left_tableData_1_data?.length)=>{return '共'+count+'条数据'},
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['6']
                                }}
                            />
                        </div>
                    </div>

                    {/*分隔线*/}
                    <div style={{backgroundColor:"rgb(240,240,240)", width:'0.05%',height:'99%',float:'left'}}/>
{/*安检点-------------------------------------------------------------------------------------------------------------*/}
                    <div style={{backgroundColor:"#fff",width:'49.9%',height:'100%',float:'left'}}>
                        <div style={{width:'98%',marginLeft:'10px'}}>
                            <div>
                                <a onClick={async ()=>{
                                    this.getGasBurnerInfoByCustomerId();//获取燃气具类型
                                    await this.setState({
                                        openMpVisible_2:true,
                                        openMpVisible_type_2:true,
                                        topFormItem_2_data:{longitude:112.962538, latitude:28.195452,},// 手动默认地址
                                        coordinate:{longitude:112.962538, latitude:28.195452,},// 手动默认地址
                                        selectGasDeviceId:null
                                    });



                                    await this.forms2.onModalClose();
                                    document.getElementById('latNum').value='湖南省长沙市岳麓区桔子洲街道橘子洲大桥橘子洲风景名胜区'
                                }
                                }>添加安检点</a>
                                <a type={'primary'} style={{marginLeft:"85%"}}
                                   onClick={()=>{this.getAllInfoByCustomerId();}}
                                >刷新</a>
                            </div>
                        </div>
                        <div style={{margin:'5px',overflowX:'auto'}}>
                            <Table
                                columns={this.state.right_tableData_2}
                                dataSource={this.state.right_tableData_1_data}
                                // rowKey={"id"}
                                bordered
                                // pagination={false}
                                pagination = {{
                                    showSizeChanger:false,
                                    showQuickJumper: false,
                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                    total:this.state.right_tableData_1_data?.length,
                                    pageSize:6,
                                    showTotal: (count = this.state.right_tableData_1_data?.length)=>{return '共'+count+'条数据'},
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['6']
                                }}
                            />
                        </div>
                        {/*<div>*/}
                        {/*    <Pagination*/}
                        {/*        // size="small"*/}
                        {/*        position={'center'}*/}
                        {/*        style={{marginTop: '20px',float: 'right',fontSize: '15px'}}*/}
                        {/*        total={this.state.right_tableData_1_data?.length} // 总条数*/}
                        {/*        showTotal={(total, range) => `当前第${range[0]}-${range[1]}条 / 共${this.state.right_tableData_1_data?.length??0}条` + (rowSelection ? `，已选 ${rowDataLen} 条` : '')}*/}
                        {/*        current={params?.page} // 当前页数*/}
                        {/*        defaultsize={params?.size} // 默认每页条数*/}
                        {/*        defaultPageSize={params?.size}*/}

                        {/*        showQuickJumper={params?.jumper}*/}
                        {/*        // pageSizeOptions={arr ?? [5,10,15]}*/}
                        {/*        showSizeChanger={false}*/}
                        {/*        onChange={this.pageChange} // 状态改变的回调*/}
                        {/*    >*/}
                        {/*    </Pagination>*/}
                        {/*</div>*/}
                    </div>
                </div>
{/*-----------------------------------------------------------图片-----------------------------------------------------*/}
                {/*分隔线*/}
                <div style={{backgroundColor:"rgb(240,240,240)", width:'100%',height:'0.1%'}}/>
                <div style={{ width:'99.5%',height:' 250px',margin:'5px'}}>
                    <span style={{fontWeight: 'bold'}}>智慧手持终端</span>
                    <a style={{paddingLeft:'15px'}}
                        onClick={async ()=>{
                            await this.setState({openMpVisible_3:true});
                            this.modalTableHandheldDevices.refreshTable();
                        }}>注册</a>
                    <a style={{float:'right',paddingRight:'20px'}}
                       onClick={async ()=>{
                           this.tableHandheldDevices.refreshTable();
                       }}>刷新</a>

                    <TableComponents
                        paramenter={this.state.tableHandheldDevices}
                        setData = {queryHandheldTerminal}
                        setTableValue={this.setTableValue}
                        ref={e => this.tableHandheldDevices = e}
                    >
                    </TableComponents>
                </div>
                {/*<div style={{ width:'100%',height:' 170px'}}>*/}
                {/*    <div style={{ width:'100%',height:'15%',paddingLeft:'5px',fontWeight:'bold',color:'#000'}}>*/}
                {/*        <span style={{fontSize:'15px'}}>安检照片</span>*/}
                {/*    </div>*/}
                {/*    <div  style={{ width:'100%',height:'85%',paddingLeft:'5px'}}>*/}
                {/*        <Upload*/}
                {/*            action={this.state.fileUrl}*/}
                {/*            listType="picture-card"*/}
                {/*            name={"file"}*/}
                {/*            fileList={this.state.fileList}*/}
                {/*            onPreview={this.handlePreview}*/}
                {/*            onChange={this.onChangeUpload}*/}
                {/*        >*/}
                {/*            {this.state.fileList.length >= 4 ? null : uploadButton}*/}
                {/*        </Upload>*/}
                {/*    </div>*/}
                {/*</div>*/}

{/*-弹窗-未激活终端---------------------------------------------------------------------------------------*/}
                <Modal
                    title={'激活终端'}
                    style={{ top: 0 }}
                    visible={this.state.openMpVisible_3}
                    okText="确定"
                    onOk={() => this.setState({openMpVisible_3:false})}          	// 点击确定
                    onCancel={() => {this.setState({openMpVisible_3:false});}}       // 点击取消
                    footer={[
                        <Button key="back" onClick={() => {this.setState({openMpVisible_3:false})}}>取消</Button>,
                        <Button type="primary" onClick={() => {
                            this.registerHandheldTerminal();
                        }}>激活</Button>
                    ]}

                    width={1200}
                    centered
                >
                    <div>
                        <div style={{width:'100%',height:'40px'}}>
                            <div style={{float:'left',fontSize:"16px",width:'80px'}}>
                                <span>终端编号:</span>
                            </div>
                            <div style={{float:'left',width:'200px'}}>
                                <Input
                                    value={this.state.modalTableHandheldDevices.params.num}
                                    onChange={(title)=>{
                                        var modalTableHandheldDevices = this.state.modalTableHandheldDevices;
                                        modalTableHandheldDevices.params.num=title.target.value;
                                        this.setState({modalTableHandheldDevices:modalTableHandheldDevices});
                                }}/>
                            </div>
                            <div style={{float:'left'}}>
                                <Button type="primary" onClick={async ()=>{
                                    var modalTableHandheldDevices = this.state.modalTableHandheldDevices;
                                    modalTableHandheldDevices.params.page=1;
                                    await this.setState({modalTableHandheldDevices:modalTableHandheldDevices});
                                    this.modalTableHandheldDevices.refreshTable(modalTableHandheldDevices.params);
                                }} >查询</Button>
                                <Button onClick={()=>{
                                    var modalTableHandheldDevices = this.state.modalTableHandheldDevices;
                                    modalTableHandheldDevices.params.num=null;
                                    modalTableHandheldDevices.params.page=1;
                                    this.modalTableHandheldDevices.refreshTable(modalTableHandheldDevices.params);
                                }}>取消</Button>
                            </div>
                        </div>
                        <div>
                            <TableComponents
                                paramenter={this.state.modalTableHandheldDevices}
                                setData = {queryHandheldTerminal}
                                setTableValue={this.setTableValue}
                                ref={e => this.modalTableHandheldDevices = e}
                            >
                            </TableComponents>
                        </div>

                    </div>
                </Modal>
{/*-弹窗-燃气具---------------------------------------------------------------------------------------*/}
                <Modal
                    title={this.state.openMpVisible_type_1?'添加燃气具':'修改燃气具'}
                    style={{ top: 0 }}
                    visible={this.state.openMpVisible_1}
                    okText="确定"
                    footer={[
                        <Button key="back"
                                onClick={() => {
                                    var topFormItem = this.state.topFormItem_1;
                                    topFormItem.parameter.map(one=>{one.disabled=false;});
                                    this.setState({MBJuder:false,openMpVisible_1:false,topFormItem_1:topFormItem})
                                }}
                        >取消</Button>,
                        <Button type="primary"
                                loading={this.state?.MBJuder}
                                onClick={() => this.addOrUpdateGasBurner() }
                        >提交</Button>
                    ]}
                    onCancel={ async () => {
                        await this.forms1.onFinish();
                        await this.setState({openMpVisible_1:false});
                    }}       // 点击取消
                    width={600}
                    centered
                >
                    <div style={{height:'320px',}}>
                        <div style={{width:'90%'}}>
                            <FormsTwo
                                ref={e => this.forms1 = e}
                                formParamenter={this.state.topFormItem_1}
                                data={this.state.topFormItem_1_data}
                            />
                        </div>
                    </div>
                </Modal>
{/*-弹窗-安检点---------------------------------------------------------------------------------------*/}
                <Modal
                    title={this.state.openMpVisible_type_2?'添加安检点':'修改安检点'}
                    style={{ top: 0 }}
                    visible={this.state.openMpVisible_2}
                    okText="确定"
                    // onOk={() => {this.addOrUpdateSecurityCheckpoint();}}         // 点击确定
                    onCancel={async() => {
                        await this.setState({openMpVisible_2:false});
                    }}// 点击取消
                    footer={
                        this.state.modelButtonType
                    }
                    width={1700}
                    centered
                >
                    <div style={{height:'700px',}}>
                        <div style={{width:'100%',height:'150px'}}>
                            <FormsTwo
                                ref={e => this.forms2 = e}
                                formParamenter={this.state.topFormItem_2}
                                data={this.state.topFormItem_2_data}
                            />
                            <div style={{width:'100%',backgroundColor:'red'}}>
                                <div style={{float:'right'}}>
                                    <span style={{fontSize:'15px'}}>地址：</span>
                                    <input
                                        // disabled={true}
                                        id={"latNum"}
                                        type={'text'}
                                        style={{border:"#d9d9d9 1px solid",width:'685px',height:'30px'}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{width:'100%',height:'550px'}}>
                            <Map
                                ref={e => this.maps = e}
                                zoom={14}// 初始画面层级this.state.showMap 15

                                onClick={async (a,b)=>{
                                    AMap.plugin("AMap.Geocoder", () => {
                                        var geoCoder = new AMap.Geocoder({
                                            city: "010", //城市设为北京，默认：“全国”
                                            radius: 1000, //范围，默认：500,
                                            extensions: 'all'
                                        });
                                        console.log(geoCoder);
                                        geoCoder.getAddress(new AMap.LngLat(
                                            a.lnglat.lng,
                                            a.lnglat.lat
                                        ), function(status, result) {
                                            if (status === 'complete'&&result.regeocode) {
                                                document.getElementById('latNum').value = result.regeocode.formattedAddress;
                                            }else{
                                                console.error('根据经纬度查询地址失败')
                                            }
                                        });
                                    });
                                    //----------------------------------------------------
                                    var coordinate = this.state.coordinate;
                                    coordinate.longitude=a?.lnglat?.lng;
                                    coordinate.latitude=a?.lnglat?.lat;
                                    this.forms2.setFieldsValue('longitude',a?.lnglat?.lng);
                                    this.forms2.setFieldsValue('latitude',a?.lnglat?.lat);
                                    this.setState({coordinate:coordinate});
                                }}
                            >
                                <Marker
                                    title={'标点'}
                                    position={new AMap.LngLat(
                                        this.state.coordinate?.longitude,
                                        this.state.coordinate?.latitude
                                         )}
                                         label={{
                                             content: '标点',
                                             // 设置文本标注方位
                                             direction: 'bottom'
                                         }}

                                />

                            </Map>

                        </div>
                    </div>
                </Modal>


                <Modal
                    title={'添加设备'}
                    style={{ top: 0 }}
                    visible={this.state.openMpVisible_4}
                    okText="确定"
                    onOk={async() => {
                        this.addOrUpdateSecurityCheckpoint();// 创建设备
                        this.setState({openMpVisible_4:false});
                    }} // 点击确定
                    onCancel={() => {this.setState({openMpVisible_4:false});}}// 点击取消
                    width={1700}
                    centered
                >
                    <div style={{height:'100%',backgroundColor:'red'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter_1}
                            setData = {devUnboundByCustomerId}
                            // setTableValue={this.setTableValue_1}
                            ref={e => this.table1 = e}
                        >
                        </TableComponents>
                    </div>
                </Modal>
            </Layout>
        )
    }
};
