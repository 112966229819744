import React, { Component } from 'react'
import { Layout, Input, Form } from 'antd'

import TableComponents from '../../../components/common/tables/TableComponents'
import {getNotReturned,getAllManufacturerAndModel} from '../../../services/report'

const FormItem = Form.Item

class NotReturned extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 下拉框长度
			selectWidth: '7.5vw',
			bindArea: true,
            rowSelection: false,
			width: '83.7vw',
            title: '用户抄表和未回传明细报表',
			exportUrl: "/web/reportExportCtrl/exportNotReturned",
			// 表头：变量和变量解释
			header: {
				company: '所属公司',
				readUnit: '读表单元',
				installNumber: '安装',
				contractNumber: '合同',
				userName: '用户名称',
                pointAddress: '用气地址',
				steelNum: '表钢号',
                modelName: '表具型号',
				meterState: '表具状态',
				meterReadingSate: '抄表状态',
				readReason: '读表原因',
				// lastTimeCopy: '上次抄表时间', // 暂不显示
				lastMeterNum: '上次表数',
				thisTimeCopy: '本次抄表时间', // 标况累积量数据时间
				thisMeterNum: '本次表数', // 标况累积
				thisCumulative: '本次累计用气'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'select',
						variableName: 'doddleState',
						str: '抄表状态',
						data: [
							{
								key: '1',
								value: '已抄表'
							},
							{
								key: '2',
								value: '未抄表'
							}
						]
					},
					{
						type: 'input',
						variableName: 'readUnit',
						str: '读表单元'
					}
				]
			},
		},
	};
	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state;
				let {data} = response.data;
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});
				this.setState({tableParamenter})
			}
		)
	};
    render() {
		var areaName = localStorage.getItem('areaName')??null;
		if(areaName==='undefined'){areaName=null}

		return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getNotReturned}
					ref={e => this.table = e}
				>
					<FormItem label={"公司"}>
						<Input disabled value={areaName} size='middle'/>
					</FormItem>
				</TableComponents>
            </Layout>
        )
    }
}
export default NotReturned
