import ajax from './ajax'

//配置报警类型
export const configType = (data) => ajax('/newAlarm/alarm/alarmInfo/update', (data), 'POST')

//分页多条件模糊查询报警基础信息-旧
export const getPageFuzzyQuery = (data) => ajax('/newAlarm/alarmInfo/getPageFuzzyQuery', (data), 'POST')

//更新用户状态
export const updateState = (data) => ajax('/newAlarm/alarm/alarmNotice/updateState', (data), 'POST')

//分页查询所有报警用户
export const pageAlarmUser = (data) => ajax('/newAlarm/alarm/alarmNotice/getPageFuzzyQuery', (data), 'POST')

//新增用户
export const addUser = (data) => ajax('/newAlarm/alarm/alarmNotice/add', (data), 'POST')

//更新用户
export const updateUser = (data) => ajax('/newAlarm/alarm/alarmNotice/update', (data), 'POST')

//注销用户
export const deleteUser = (data) => ajax('/newAlarm/alarm/alarmNotice/delete', (data), 'GET')

//基础消息
export const getMessageInfo = (data) => ajax('/newAlarm/alarm/message/getPageFuzzyQuery', (data), 'POST')

//即时消息
export const getOnLineMessage = (data) => ajax('/newAlarm/alarm/message/getOnLineMessage', (data), 'POST')

//离线消息
export const getOffLineMessage = (data) => ajax('/newAlarm/alarm/message/getOffLineMessage', (data), 'POST')

//报警配置
export const alarmConfig = (data) => ajax('/newAlarm/alarm/alarmConfig/alarmConfig', (data), 'POST')

//查询报警配置的信息
export const pageAllConfig = (data) => ajax('/newAlarm/alarm/alarmConfig/pageAllConfig', (data), 'POST')

//查询报导入的信息
export const getImportInfo = (data) => ajax('/newImport/meterImport/getAll', (data), 'POST')
//查询小区客户报导入的信息
export const getCustomerImportInfo = (data) => ajax('/customermanage/import/getImportInfo', (data), 'POST');
//查询报导入的信息
export const getImportInfoRecord = (data) => ajax('/newImport/meterImport/pageImportRecord', (data), 'POST')

//获取区域ID
export const getDataId = (data) => ajax('/newImport/meterImport/dataId', (data), 'POST')


//获取设备异常信息列表
export const listDeviceExpData = (data) => ajax('/gasdevice/gas/listDeviceExpData', (data), 'POST');



//获取设备异常信息列表
export const processAlarmInfo = (data) => ajax('/newAlarm/alarMessages/processAlarmInfo', (data), 'POST');

//根据报警信息ID查询处置信息
export const getAlarmHanding = (data) => ajax('/newAlarm/alarMessages/getAlarmHanding', (data), 'GET');

//根据devID查询设备信息
export const getDeviceInfoByDevId = (data) => ajax('/newAlarm/alarMessages/getDeviceInfoByDevId', (data), 'GET');

//分页多条件模糊查询报警基础信息-旧
export const getPageFuzzyQuerys = (data) => ajax('/web/alarmHomePage/getPageFuzzyQuery', (data), 'POST');
//分页多条件模糊查询报警基础信息-加区域id
export const getPageFuzzyQueryByAreaId = (data) => ajax('/web/alarmHomePage/getPageFuzzyQueryByAreaId', (data), 'POST');
//分页多条件模糊查询报警基础信息-加区域id
export const getAllAlarmTypes = (data) => ajax('/newAlarm/alarmType/getAllAlarmTypes', (data), 'GET');
