import React, { Component  } from 'react'
import {Layout, Input, Button, Tree, Collapse, Drawer, Checkbox, Modal, Typography, Descriptions, message,Tooltip,Radio} from "antd";
import {SearchOutlined,DownOutlined,SettingOutlined , } from '@ant-design/icons';
import {TYPE_NAME} from "../../../components/Const";
import {
    createHeaderFormClass,
    getDevModelAttr,
    listClassAndModelInfo,
    listDevModelInfo
} from "../../../services/equipment";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import './deviceConfiguration.less'
const { DirectoryTree } = Tree;
const { Panel } = Collapse;
const { Title } = Typography;
export default class deviceConfiguration extends Component {
    state = {
        selectClassModel:null,
        treeData:[],            //原版数据未进行转换
        treeNodeInfo: [],       //转换符合当前树形结构的数据
        expandedKeys: [],       //树节点筛选集-模糊查询使用
        searchValue: '',        //保留模糊查询输入内容
        visibles_1:false,        // 右侧弹出开关

        // 复选框的数据集
        optionsWithDisabled : [],   // 获得数据库的数据项
        newOptionsWithDisabled:'',  // 文本输入要求获得数据
        plainOptions:[],            // 数据项多选框

        headerkeys:[],
        headerAttributes:[],        // 标头

        drawerSwitchData:null,  //折叠框层级

        // 表单参数
        formParam_1: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [{variableName: 'name', str: '所选数据', rules: TYPE_NAME.INPUT, disabled: true,},]
        },
        formParam_1_data:{},

        classId:null,
    };

    //初始化配置
    componentDidMount =()=> {
        this.getTreeDataInfo();  // 获得树形结构的数据
        this.queryAttrVo();      // 右侧弹窗-获取全部数据项-用于右侧多选框数据
    };

    // 左侧-输入框
    inputFindNode=(e)=>{
        // console.log('筛选查询节点');
        const { value } = e.target;
        if(!value){
            this.setState({
                expandedKeys:[],
                searchValue: value
            });
            return null
        }
        let treedata = this.traverseTree();// 平摊分层的树节点-用于检索功能需要
        const expandedKeys = treedata
            .map(item =>{
                if(item.title.indexOf(value)> -1){
                    return item.key.toString();
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);

        this.setState({
            expandedKeys,
            searchValue: value
        })
    };

    // 折叠框=弹出右框功能开关
    genExtra =(data)=>{
        // return <SettingOutlined
        return <a
            onClick={event => {
                this.setState({visibles_1:true,drawerSwitchData:data} );
                this.genExtraVisibles(data);
                event.stopPropagation();

            }}
        >数据项</a>
    };
    // 将折叠面板框选择框内的数据传递给右侧弹出框
    genExtraVisibles=(data)=>{
        // 标头，右侧勾选id
        let {headerAttributes,plainOptions} = this.state;
        plainOptions=[];// 置空多选框内容
        switch (data) {
            case 1:
                // 控制该层级已勾选的数据
                headerAttributes?.forEach(item=>{
                    plainOptions.push(item.id.toString())
                });
                break;
            default:break;
        }
        this.setState({plainOptions:plainOptions});
    };

    // 获取 数据库内 的 树形结构数据
    getTreeDataInfo = () => {
        listClassAndModelInfo().then(data=>{
            if(data.data.data){
                let {treeData,formParam_1} = this.state;
                formParam_1.parameter[0].data=[];
                treeData=data.data.data;
                treeData.forEach(item=>{
                    formParam_1.parameter[0].data.push({key:item.devClassId,value:item.devClassName})
                });
                this.setState({treeData:treeData});
                this.fatherNode();  // 树形结构-内容转换
            }
        })
    };
    // 树形结构-转换父节点用于前端需要
    fatherNode=()=>{
        // console.log("树形结构-转换父节点");
        let {treeData,treeNodeInfo} = this.state;
        let keys=[];
        keys = treeData?.map(({devClassName,devClassId,devModels}) => {
            return {
                title: devClassName,
                key: devClassId,
                children: this.childNode(devModels)
            }
        });
        treeNodeInfo = JSON.parse(JSON.stringify(keys));
        this.setState({treeNodeInfo:treeNodeInfo});
    };
    // 树形结构-转换子节点用于前端需要
    childNode=(treeData)=>{
        // console.log("树形结构-转换子节点");
        let keys = [];
        keys = treeData?.map(({devModelName,devModelId,devModels}) => {
            return {
                title: devModelName,
                key: devModelId,
                children: this.childNode(devModels),
            }
        });
        return keys
    };



    // 筛选根节点数据-用于检索功能需要
    selectedTree=()=>{
        // console.log("筛选根节点数据");
        let treedata = this.traverseTree();
        const expandedKeys= this.state.expandedKeys;
        const newTreeData=[];
        if(expandedKeys.length!==0){
            expandedKeys.forEach(item=>{
                treedata?.forEach(data=>{
                    if(item===data.key.toString()){
                        data.children=[];
                        newTreeData.push(data)
                    }
                })
            });
        }else{
            return this.state.treeNodeInfo
        }
        return newTreeData;
    };
    // 平摊分层的树节点-用于检索功能需要
    traverseTree=()=>{
        const treeDataList=[];
        this.state.treeNodeInfo?.forEach(
            item => {
                treeDataList.push(item);
                if(item?.children){
                    if(item?.children?.length!==0){
                        item.children.forEach(data =>{
                            treeDataList.push(data)
                        })
                    }
                }
            });
        return treeDataList;
    };
    // 树形构造-样式修改
    loop = (data) =>
        data.map(item => {
            const index = item.title?.indexOf(this.state.searchValue);                  // 获取相同值的位置

            const beforeStr = item.title?.substr(0, index);                             // 截取前半部分没有相同的值
            const afterStr = item.title?.substr(index + this.state.searchValue.length); // 获取后半部分不相同的值
            const title =
                index > -1 ?
                    (
                        <span>{beforeStr}<span className="site-tree-search-value">{this.state.searchValue}</span>{afterStr}</span>
                    )
                    :
                    (
                        <span>{item.title}</span>
                    );
            if(index>=-1){
                if (item.children) {
                    return { title, key: item.key, children: this.loop(item.children,null) };
                }
            }
            return {
                title,
                key: item.key,
            };
        });
    //左侧- 树形结构点击触发-获取选取的型号详情-该功能用于清除原选的数据 devClassId devModelId
    onExpand=async (value) => {
        const treeData = this.state.treeData;
        var formParam1Data = this.state.formParam_1_data;
        var data = null;
        treeData.map(one=>{if(one?.devClassId===value[0]){data=one;}});
        if(!data){treeData.map(one=>{one?.devModels.map(two=>{if(two?.devModelId===value[0]){data=two;}})})}
        this.forms.setFieldsValue('name',data?.devClassName??data?.devModelName??null);
        this.setState({classId:value[0],formParam_1_data:formParam1Data});

        // this.getModeData(value);    // 获取选取数据
    };
    // 树形结构数据-点击获取指定数据
    getModeData=(value)=>{
        console.log("树形结构数据-获取指定数据");
        // 根据设备类型id查询设备型号信息-设备页面
        listDevModelInfo({modelIds:value}).then(response => {
            console.log(response);
            // 判断获取数据是否为空
            if (response.data.data){

            }
        });
    };
    // 右侧弹窗-获取全部数据项-用于右侧多选框数据
    queryAttrVo=()=>{
        let optionsWithDisabled = this.state.optionsWithDisabled;
        // 访问后台获取数据项
        getDevModelAttr().then(item=>{
            if(item.data.data){
                // 遍历数据项添加状态
                item.data.data.forEach(item=>{
                    item.disabled=false;
                    optionsWithDisabled.push(item);
                });
                optionsWithDisabled=item.data.data;
                this.setState({optionsWithDisabled});
            }
        });
    };
    // 右框检索多选框的数据的名称-检索输入框的功能
    inputFindCheckbox=(e)=>{
        console.log('输入-筛选查询多选框',e.target);
        const { value } = e.target;
        console.log(value);
        this.setState({newOptionsWithDisabled:value});
    };
    // 右框多选框选择内容保存
    onChange=(checkedValues)=> {
        this.setState({plainOptions: checkedValues})
    };
    // 右侧弹出框关闭
    onClose=()=>{
        console.log("右侧弹出框开关");
        this.exportData();
        this.setState({visibles_1:false} )
    };
    // 右框关闭就触发事件 输出数据到中间的折叠框内
    exportData=()=>{
        // 多选框，本型号中各设备值不同的属性，本型号中各设备需要采集的属性，本型号中各设备需要采集的属性
        // 本型号中各设备值相同的属性,configData
        let {plainOptions,headerAttributes} = this.state;
        const dataList=[];  // 新建一个本地对象获取所有多选框选择的值
        //获取数据线中选取得数据线得完整数据
        this.state.optionsWithDisabled.forEach(item=> {
                if (plainOptions.some(value => value === item.id.toString())) {dataList.push(item)}
            });
        switch (this.state.drawerSwitchData) {
            case 1:
                const temporary=[];     // 临时的变量集-用于存储数据
                //先查看原有标头有无相同数据，有就添加
                headerAttributes.forEach(item=>{
                    if(dataList.some(same=> same.id.toString()===item.id)){
                        temporary.push(item)
                    }
                });
                // 针对第三个有输入框的需要配置内容
                dataList.forEach(item=>{
                    if(temporary.some(same=> same.id===item.id.toString())){
                    }else{
                        item.value="未知";
                        item.common=1;
                        temporary.push(item)
                    }
                });
                headerAttributes=[];
                headerAttributes=temporary;
                break;
            default:break;
        }
        this.setState({headerAttributes:headerAttributes,});
    };

    // 保存
    switchover=()=>{
        const {classId,headerAttributes} = this.state;
        if(!classId){return false;}
        var param=[];
        headerAttributes.map(one=>{param.push({"classId": classId, "headerId": one.id, "name": one.name})});
        createHeaderFormClass(param).then(
            response=>{
                console.log(param);
            }
        )
    };
    onExpands = (keys, info)  => {
        console.log("展开");
        console.log(keys, info);
        console.log("展开");
    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor:'#f0f0f0'}}>
                <div style={{width:'100%',height:'100%',overflowY: 'auto'}} id={'deviceConfiguration'}>
{/*左侧菜单栏----------*/}
                    <div style={{width:'300px',height:'100%',backgroundColor:'#ffffff',float:'left'}}>
                        {/*输入框*/}
                        <div style={{  marginTop: '5%', marginLeft: '10%'}}>
                            <Input.Group compact>
                                <Input style={{ width: 240, }} placeholder="输入查询数据" onChange={this.inputFindNode}/>
                            </Input.Group>
                        </div>
                        {/*菜单列表*/}
                        <div style={{  width: '90%', height: '760px',paddingLeft: '10%', marginTop: '15px', overflowY: 'auto'}}>
                            <DirectoryTree
                                onExpand={this.onExpands}
                                // autoExpandParent={true}
                                className={"left_tree_div_tree_data_div"}
                                onSelect={this.onExpand}
                                icon={false}
                                switcherIcon={<DownOutlined/>}
                                treeData={this.loop(this.selectedTree())}
                            />
                        </div>
                    </div>
{/*右侧数据栏----------*/}
                    <div style={{width:'81.2%',height:'100%',marginLeft:'5px',backgroundColor:'#ffffff',padding:'5px' ,float:'left'}}>
                        {/*表单-按钮*/}
                        {/*<div style={{width:'100%',height:'180px',}}>*/}
                        <div style={{width:'100%',height:'55px',}}>
                            {/*按钮*/}
                            <div style={{width:'100%',height:'25%'}}>
                                <div style={{float:"right"}}>

                                    <Button type="primary" style={{fontSize:15,margin:'0 0 0 10px'}} onClick={()=>{
                                        this.switchover();
                                    }}>
                                        保存
                                    </Button>
                                </div>
                            </div>
                            {/*表单*/}
                            <div style={{width:'99%',height:'75%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.formParam_1}
                                    data={this.state.formParam_1_data}
                                />
                            </div>
                        </div>
                        {/*列表*/}
                        <div style={{width:'100%',height:'640px',padding:'1px'}}>
                            <Collapse
                                defaultActiveKey={['1']}
                                // onChange={this.callback}
                            >
                                <Panel header="表格表头" key="1"
                                    extra={this.genExtra(1)}
                                >
                                    <div>
                                        <Checkbox.Group
                                            key={this.state.headerkeys}
                                            defaultValue={this.state.headerkeys} // 多选框的值
                                            style={{ width: '100%' }}
                                            onChange={(e)=>{this.setState({headerkeys:e})}}    //多选框点击事件
                                        >
                                            <Descriptions
                                                column={{ sm: 5 }}>
                                                {
                                                    this.state.headerAttributes?.map(
                                                        item => (
                                                            <Descriptions.Item>
                                                                <div className={"collapse_Descriptions_3_div"}>

                                                                    <Checkbox
                                                                        disabled={item.disabled}    // 状态控制
                                                                        key={item.id}               // id
                                                                        value={item.id.toString()}
                                                                        onClick={(e)=>{(item.disable=!item.disabled)}}
                                                                    >
                                                                        <Input  className={"right_head_collapse_Descriptions_3_input_div"}
                                                                                key={item.id}
                                                                                defaultValue={item.name}
                                                                                style={{ width: 150}}
                                                                                onChange={(text)=>{ (item.value=text.target.value)}}
                                                                        />
                                                                    </Checkbox>
                                                                </div>
                                                            </Descriptions.Item  >
                                                        )
                                                    )}
                                            </Descriptions>
                                        </Checkbox.Group>

                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
{/*右侧弹窗------------------*/}
                        <div>
                            <Drawer  width={500}
                                     title={
                                         <Input.Group compact style={{float:"lift"}}>
                                             <Title level={5} >数据项名：</Title>
                                             <Input style={{ width: 220,}} placeholder="输入查询数据"
                                                    onChange={this.inputFindCheckbox}/>
                                             <Button  icon={<SearchOutlined />}  />
                                             {/*<Button style={{float:"right"}} type="primary">新增数据项</Button>*/}
                                         </Input.Group>
                                     }
                                     placement="right"
                                     onClose={()=>{this.onClose()}}         // 弹出框关闭触发事件
                                     visible={this.state.visibles_1}  // 控制弹出框开关
                            >
                                <Checkbox.Group
                                    key={this.state.plainOptions}
                                    defaultValue={this.state.plainOptions} // 多选框的值
                                    style={{ width: '100%' }}
                                    onChange={this.onChange}    //多选框点击事件
                                >
                                    {
                                        // 根据右框各个数据展示出来
                                        this.state.optionsWithDisabled.map(item => (
                                            <div className={"drawer_Checkbox_div"}
                                                // 根据文本框输入内容控制输出样式，把部分数据隐藏，indexOf判断字段有无相同字
                                                 style={item?.name?.indexOf(this.state.newOptionsWithDisabled) >-1 ? {}:{display: "none"}}>
                                                {/*悬浮提示每个属性框的迷你窗口-START*/}
                                                <Tooltip title={item.name}>
                                                    <Checkbox
                                                        disabled={item.disabled}    // 状态控制
                                                        key={item.id}               // id
                                                        value={item.id.toString()}>
                                                        <span>{item.name}</span>
                                                    </Checkbox>
                                                </Tooltip>
                                                {/*悬浮提示每个属性框的迷你窗口-END*/}
                                            </div>
                                        ))
                                    }
                                </Checkbox.Group>
                            </Drawer>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
