import React from 'react';

import * as echarts from 'echarts';
// 圆角环形图
class HalfDoughnutChart extends React.Component {
    // componentDidUpdate(num){
    //     this.elementById();
    // }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        if (echarts.getInstanceByDom(document.getElementById(this.props.elementById))) {
            echarts.dispose(document.getElementById(this.props.elementById));
        }

        var chartDom = echarts.init(document.getElementById(this.props.elementById));
        window.addEventListener('resize',function(){chartDom.resize();});
        chartDom.setOption(
            {

                title: {
                    text: this.props.data?.data+'%'??'-',
                    textStyle: {color: '#b7fde4', fontSize: 20},
                    subtext: this.props.data?.name??'-',
                    subtextStyle: {color: '#fff', fontSize:16},
                    itemGap: 2, // 主副标题距离
                    left: 'center',
                    top: '30px'
                },

                // 极坐标系的角度轴
                angleAxis: {
                    max: 100, // 圈型环绕满分
                    clockwise: false, // 逆时针，转向方向
                    // 隐藏刻度线
                    axisLine: {show: false},
                    axisTick: {show: false},
                    axisLabel: {show: false},
                    splitLine: {show: false}
                },
                //坐标轴是否是静态无法交互
                radiusAxis: {
                    type: 'category',
                    // 隐藏刻度线
                    axisLine: {show: false},
                    axisTick: {show: false},
                    axisLabel: {show: false},
                    splitLine: {show: false}
                },
                // 边距

                polar: {
                    center: ['50%', '49%'],
                    radius: ['50%','120%'], //图形大小
                },
                series: [
                    {
                        type: 'bar',
                        data: [{
                            name: '安全率',
                            value: this.props.data?.data??0,
                            itemStyle: {

                                borderColor: '#000',
                                borderRadius: 5,
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 1, 2,
                                        [
                                            {offset: 0, color: this.props.data?.color[0]},
                                            {offset: 0.5, color:  this.props.data?.color[1]},
                                        ])
                                }
                            },
                        }],
                        coordinateSystem: 'polar',
                        roundCap: true,
                        barWidth: 10,
                        barGap: '-100%', // 两环重叠
                        z: 2,// 层级
                    },
                    { // 灰色环
                        type: 'bar',
                        data: [{
                            value: 100,
                            itemStyle: {
                                color: '#35435d',
                                shadowColor: 'rgba(1, 0, 0, 0.2)',
                                shadowBlur: 5,
                                shadowOffsetY: 2
                            }
                        }],
                        coordinateSystem: 'polar',
                        roundCap: false,
                        barWidth: 10,
                        barGap: '-100%', // 两环重叠
                        z: 1
                    }
                ]
            }
        );
    };
    updateData=()=>{
        this.elementById();
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '99%', height: '99%' }}>
            </div>
        );
    }
}
export default HalfDoughnutChart;
