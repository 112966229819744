import React,{ Component } from 'react';
import {Table,message,Space,Layout,Radio,Select,Checkbox,Pagination,Modal,Upload} from 'antd';
import { LoadingOutlined,ReloadOutlined,RiseOutlined,FallOutlined, InboxOutlined   } from '@ant-design/icons';
import './table.css'

import Drawers from './drawers/Drawers'

const { Option } = Select;
const {Content} = Layout;
const { Dragger } = Upload;

class Tables extends Component {

    state={
        // 弹窗开关
        isModalVisible: false,
        // 抽屉开关
        visible: false,

        selectedRowKeys: [], // Check here to configure the default column

        // 传入到抽屉的数据
        record: {},
        // 表格列
        columns: [],

        // 已选的行
        rowData: 0,

        header: JSON.parse(JSON.stringify(this.props.tableParamenter.header)),

        drawersHeader: this.props.tableParamenter.headers ? JSON.parse(JSON.stringify(this.props.tableParamenter.headers)) : JSON.parse(JSON.stringify(this.props.tableParamenter.header)),

		    importModal: false,

        columnMaxWith: this.props.columnMaxWith
    }

    getColumns = header => {
      let {columnMaxWith} = this.state;
      const variables = Object.keys(header);
      let columns = [];
      variables.forEach(value => {
        columns.push(
          {
            title: header[value],
            dataIndex: value,
            // ellipsis: true,
            key: value,
            onCell: () => {
              return {
                style: {
                  maxWidth: columnMaxWith ?? 290
                }
              }
            },
            // sorter: {
            //   compare: (a, b) => {
            //     a[value] = a[value] ?? 'null';
            //     b[value] = b[value] ?? 'null';
            //     return  Object.prototype.toString.call(a[value]).indexOf('String') !== -1
            //         ? (!isNaN(Date.parse(a[value])) && isNaN(a[value]))
            //             ?
            //     new Date(Date.parse(a[value])) > new Date(Date.parse(b[value]))
            //         ? 1 : new Date(Date.parse(a[value])) === new Date(Date.parse(b[value]))
            //         ? 0 : -1 : // 为日期时
            //
            //     a[value].toUpperCase() > b[value].toUpperCase()
            //         ? 1 : (a[value].toUpperCase() === b[value].toUpperCase()) ? 0 : -1 : // 为字符串时
            //     a[value] - b[value] // 为数字时
            //   },
            //   multiple: 1,
            // },
            align: 'left',

            render: (text,rowData) => text === 'null' ? '-' : this.props.setTableValue ? this.props.setTableValue(value,text,rowData) : text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
          },
        )
      });
      return columns
    }
    onClickData=(onRowData)=>{
        this.props.onClickData?.(onRowData)
    };

    // 点击行时打开抽屉，并传入当前行的数据
    onRowData = record => {
      let drawersHeader = this.props.tableParamenter.headers ? JSON.parse(JSON.stringify(this.props.tableParamenter.headers)) : JSON.parse(JSON.stringify(this.props.tableParamenter.header))
      let onRowData = this.props.onRowData ?.(record,drawersHeader)
      this.setState({
        record: onRowData?.onRowData ?? record,
        visible: true,
        drawersHeader: onRowData?.headers ?? drawersHeader
      })
    }

    // 关闭抽屉
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    // 勾选中行时
    onSelectChange = (rowKey, rowData) => {
      this.setState({rowData: rowData.length})
      this.props.onSelectChange(rowData)
    };

    // 动态表头
    getDefCheckValue = (header,obj) => {
      let checkValue = [];
      const keys = Object.keys(obj)
      keys.forEach((objKey,ind) => {
        checkValue.push(
          <Option disabled key={ind}>
            &nbsp;
            <Checkbox
              key={objKey}
              disabled={!ind}
              defaultChecked={header[objKey] ? true : false}
              onChange={e => this.getCheckValue(e.target.checked,objKey,obj[objKey])}>
                {obj[objKey]}
            </Checkbox>
          </Option>
        )
      })
      return checkValue
    }

    // 获取选中表头的值
    getCheckValue = (arr,key,value) => {
      let {header} = this.props.tableParamenter
      !arr ? delete header[key] : header[key] = value
      this.setState({'tableParamenter.header': header})
    }

    // 导出
    exportTable = () => {
      this.props.exportTable()
      message.success('导出成功')
    }

    importTable = () => {
      this.setState({ importModal: true })
      this.props.exportTable()
      // message.success('导入成功')
    }

    // 分页器变化触发
    pageChange = (page, pageSize) => {
      this.props.getPagination(page, pageSize)
    }

    closeImportModal = () => this.setState({ importModal: false })

    render() {

      const props = {
        name: 'file',
        multiple: true,
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} 文件上传成功.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} 文件上传失败.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        accept: '.xls, .xlsx',
      };

        const {header,data,rowSelection,title,importData,exportTable,width,container} = this.props.tableParamenter
        let { rowData, importModal, drawersHeader } = this.state
        const {children,pagination,loading} = this.props
        const {totalCount,page,size,arr} = pagination ? pagination : {}

        return (
          <Layout style={{backgroundColor: '#ffffff',padding: '0 0.5% 0 0.5%'}}>
					  <Content>
              <div className={'font_size5'} style={{textAlign: 'center'}}>
                {title}
              </div>
              <div>
                <Space direction="horizontal" size={20} style={{margin: '0.5vh 0',float: 'left'}}>
                    {children}
                </Space>
                <Space direction="horizontal" size={20} style={{margin: '0.5vh 0',float: 'right'}}>
                  <Select
                    showSearch
                    placeholder= {`选择表头`}
                    style={{ width: '7vw',color: '#000000' }}
                    optionFilterProp="children"
                    filterOption={ (value,option) =>
                        option.children?.props?.children.toLowerCase().indexOf(value.toLowerCase()) >= 0
                    }
                  >
                    {this.getDefCheckValue(header,this.state.header)}
                  </Select>

                  <Radio.Group defaultValue="refresh" buttonStyle="solid">
                    <Radio.Button value="refresh" onClick={this.props.refreshTable}>{loading?.refreshTable? <LoadingOutlined /> : <ReloadOutlined />}刷新</Radio.Button>
                    {
                      importData ? <Radio.Button value="import" onClick={this.importTable}><FallOutlined />导入</Radio.Button> : null
                    }
                    {
                      exportTable ? <Radio.Button value="export" onClick={this.exportTable}><RiseOutlined />导出</Radio.Button> : null
                    }
                  </Radio.Group>
                </Space>
                <div style={{clear: 'both'}}></div>
              </div>
              <div className={'scoll_table_x'} style={{maxWidth: width, overflowX: 'auto'}}>
                <Table
                  columns={this.getColumns(header)}
                  dataSource={data === 'null' ?  [] : data}
                  scroll={false}
                  // components={components}
                  size={"large"}
                  bordered={true}
                  rowKey={this.props.id ?? 'id'}
                  loading={loading?.loading}
                  rowSelection={rowSelection ? {onChange: this.onSelectChange,type: 'radio'} : null}
                  onRow={record => {
                      return {
                          onClick: () => this.onClickData(record), // 单击行
                        onDoubleClick: () => this.onRowData(record), // 双击行
                      };
                  }}
                  pagination={false}
                />
              </div>

              {
                pagination ? (
                  <Pagination
                    position={'right'}
                    style={{marginTop: '0.5vh',float: 'right',fontSize: '15px'}}
                    total={totalCount} // 总条数
                    showTotal={(total, range) => `当前第${range[0]}-${range[1]}条 / 共${totalCount??0}条` + (rowSelection ? `，已选 ${rowData} 条` : '')}
                    current={page} // 当前页数
                    defaultsize={size} // 默认每页条数
                    defaultPageSize={size}
                    showQuickJumper
                    pageSizeOptions={arr ? arr : [5,10,15]}
                    showSizeChanger
                    onChange={this.pageChange} // 状态改变的回调
                  />
                ) : null
              }
              <Drawers
                rowLen={this.props.tableParamenter.rowLen}
                header={drawersHeader}
                visible={this.state.visible}
                onClose={this.onClose}
                record={this.state.record}
                container={container}
              />
					</Content>

          <Modal
            title="数据导入"
            visible={importModal}
            onOk={ this.closeImportModal }
            onCancel={ this.closeImportModal }
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">支持excel</p>
              <p className="ant-upload-hint">
                点击或拖动文件到此区域
              </p>
            </Dragger>
          </Modal>

				</Layout>
        )
    }
}

export default Tables;
