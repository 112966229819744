import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'
import {funTraceInfo} from '../../../services/common'


import {getVerPlanLst,updataVerPlan,findVerMeter,createVerPlan} from '../../../services/meterVerifvication'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			// checkStatus:true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				createTime: '创建时间',
				sheetSteelGrade: '表钢号',
				arrangerName: '计划人',
				plannedVerTime: '计划检定日期',
				validVerTime: '检定有效期至',
				verState: '检定类型',
				edit: '操作',
			},
			// 弹出框
			headers: {
				createTime: '创建时间',
				sheetSteelGrade: '表钢号',
				arrangerName: '计划人',
				plannedVerTime: '计划检定日期',
				validVerTime: '检定有效期至',
				verState: '检定类型',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},

		// 表格参数
		tableModParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			checkStatus:true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				sheetSteelGrade: '表钢号',
				validityTime: '下次检定到期日期',
				model: '型号',
				type: '表具类型',
				start: '状态',
			},
			// 弹出框
			headers: {
				sheetSteelGrade: '表钢号',
				validityTime: '下次检定到期日期',
				model: '型号',
				type: '表具类型',
				start: '状态',
			},
			params: {
				booleanArrange:1,
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: false,
		},

		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'sheetSteelGrade',
					str: '表钢号',
				},
				{
					type: 'select',
					variableName: 'booleanArrange',
					str: '是否有计划',
					data: [
						{
							key: '0',
							value: '是'
						},
						{
							key: '1',
							value: '否'
						},
					]
				},
				{
					type: 'rangePicker',
					variableName: 'createTime',
					time: false,
					str: '到期日期：'
				},
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'arrangerName',
					str: '计划人',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'sheetSteelGrade',
					str: '表钢号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'createTime',
					str: '创建时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'validVerTime',
					str: '有效期',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'plannedVerTime',
					str: '计划日期',
					rules: TYPE_NAME.RANGE_PICKER,
				},

			]
		},

		addFormItem: {
			modal: true,
			number: 3,
            labelCol: 10,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'plannedVerTime',
					str: '设置计划检定日期',
					rules: TYPE_NAME.REQDATE_PICKER,
				},
			]
		},

		    	// 总条数
		totalCount: 0,
		loading: false,
		searchGetData:{},
		basicsData:{},
		voInstallReqData:{},
		voInstallMeterData:{},
		devVisible:false, // 小窗口开关器-1
		delVisible:false,//删除
		delId:''
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'删除'} onClick={() => this.toDelStatus(true,rowData.verPlanId)}>
						<u>{'删除'}</u>
				</div>
				</>
			case 'state':
				return text === 1 ? '报废待入库' : text === 2 ? '报废已入库' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

		//详情
	showDetailBox = async (devVisible,id)=>{
			this.setState({devVisible})

	}


		//删除
		toDelStatus = async (delVisible,id)=>{
			this.setState({delVisible,delId:id})
		}
		delPlanData = async (value)=>{
			updataVerPlan({id:this.state.delId}).then(
					response => {
						if(response.data.data){
							funTraceInfo("表具检定管理", "删除计划", null,this.state.delId)
							message.config("操作成功")
							this.toDelStatus(false)
						}else{
							this.toDelStatus(false)
							message.config('操作失败')

						}
						this.table.refreshTable();// 刷新表格
					}
				)


		};

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data)
		let sTime=data.createTime ?moment(data.createTime[0]._d) : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d) : null;
		let ssTime=data.validVerTime ?moment(data.validVerTime[0]._d) : null;
		let eeTime=data.validVerTime ?moment(data.validVerTime[1]._d) : null;
		let sssTime=data.plannedVerTime ?moment(data.plannedVerTime[0]._d) : null;
		let eeeTime=data.plannedVerTime ?moment(data.plannedVerTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTime=''
		params.validVerTime=''
		params.plannedVerTime=''
		params.createTimeStart=sTime;
		params.createTimeEnd=eTime;
		params.validVerTimeStart=ssTime;
		params.validVerTimeEnd=eeTime;
		params.plannedVerTimeStart=sssTime;
		params.plannedVerTimeEnd=eeeTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};

		// 条件选择：获取输入的条件
		getConditionsValue2 = async value => {
			console.log(value)
				// let sTime=createTime ? moment(createTime[0]._d).format("yyyy-MM-DD") : null;
				// let eTime=createTime ? moment(createTime[1]._d).format("yyyy-MM-DD") : null;
				let sTime=value.createTime ?moment(value.createTime[0]._d) : null;
				let eTime=value.createTime ?moment(value.createTime[1]._d) : null;
				let params = this.state.tableModParamenter.params;
				let formParams=this.table.getParams()
				console.log(this.state)
				Object.assign(params,value)
				params.page=formParams.page
				params.size=formParams.size
				params.createTime=''
				params.validVerTimeStart=sTime;
				params.validVerTimeEnd=eTime;
				let {tableModParamenter} = this.state;
				tableModParamenter.params = params;
				this.setState({tableModParamenter});
				await this.table2.refreshTable(params);

		};


	//创建计划
    onSave = async () => {
		let rowData = this.table2.getSelectChange()
		let user = JSON.parse(sessionStorage.getItem("user"))
		let values = await this.forms2.onFinishNotReset();
		if(!values) return null
		if(rowData.length<1){
			return message.error('未选择数据')
		}else{
			values.arrangerName=user.realName
			values.arrangerExternalId=user.id
			values.verMeterInfoList=rowData

			createVerPlan(values).then(
				response => {
					if(response.data.data==true){
						message.success("创建成功");
						funTraceInfo("表具检定管理", "创建计划", null,values)
						this.setState({devVisible:false})
						this.table.refreshTable();
					}else{
						message.success("创建失败");
						this.setState({devVisible:false})
						this.table.refreshTable();
					}

				}
			)

		}


    };

    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }




	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>检定计划</h3>
						<div style={{position:'relative'}}>
							<div style={{width:'88%'}}>
							<FormsTwo
									ref={e => this.forms = e}
									formParamenter={this.state.topFormItem}
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						</div>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.showDetailBox(true)}><PlusSquareOutlined />创建计划</span>
				</div>
				<div style={{padding:'0 0 0 0',background:'#fff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getVerPlanLst}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				</div>
				<Modal
                        title={'创建计划'}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
						destroyOnClose={true}
						// footer={false}
                        onOk={() => this.onSave(false)}
                        onCancel={() => this.showDetailBox(false)}
                        width={1360}
                        centered
                    >
					<Conditions
							conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
							getConditionsValue={this.getConditionsValue2} // 获取条件框输入的值
							clearCondition={this.clearCondition} // 清除条件
						>
					</Conditions>
					<h4 style={{padding:'10px 0 0 0', margin:'20px 0 0 0', background:'#fff',color:'#1890FF',fontSize:'16px',borderTop:'1px solid #ccc'}}>计划配置信息</h4>
					<div style={{padding:'10px 0 0 0',background:'#fff'}}>
					<FormsTwo
									ref={e => this.forms2 = e}
									formParamenter={this.state.addFormItem}
									data={this.state.searchGetData}
					/>
					</div>
					<div style={{padding:'0 0 0 0',background:'#fff'}}>
					<TableComponents
						paramenter={this.state.tableModParamenter}
						setData = {findVerMeter}
						setTableValue={this.setTableValue}
						ref={e => this.table2 = e}
					>
					</TableComponents>
					</div>

                </Modal>
					<Modal
                        title='删除'
                        style={{ top: 0 }}
                        visible={this.state.delVisible}
                        okText="确定"
                        onOk={() => this.delPlanData(false)}           // 点击确定
                        onCancel={() => this.toDelStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认删除该计划吗？</h4>
                        </div>
                </Modal>

            </Layout>
        )
    }
}
