import React, { Component } from 'react'
import {Button, Layout, message, Modal, Tabs} from 'antd'
import {TYPE_NAME} from "../../../../components/Const";
import FormsTwo from "../../../../components/common/forms/FormsTwo";
import {getCusPropertiesList, getPoTimeTemplateByCustomerId} from "../../../../services/meter";
import {addCustomerNew, updateCustomerNew} from "../../../../services/customer";
export default class accountInfo extends Component {
    state = {
        // 表单参数-类型1-工商
        formParamenter: {
            number: 2,
            labelCol: 5,
            parameter: [
                {
                    variableName: 'type', str: '客户类型', rules: TYPE_NAME.REQ_SELECT,
                    onChange: e => this.onChangeSelect(e),
                    data:
                        this.props.location?.state?.selectWidth?
                            [{key: '2', value: '工商客户'}] : [{key: '1', value: '居民客户'}, {key: '2', value: '工商客户'}]
                },
                {variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT,
                    onChange: (e,a) => this.onChangeSelectList(e,a),
                    data: [{key: "", value: ""}]},
                {variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'organizationCode', str: '组织机构代码', rules: TYPE_NAME.LABLE_18},
                {variableName: 'businessCooperationNum', str: '业务合作号', rules: TYPE_NAME.LABLE_50},
                {variableName: 'legalPerson', str: '法人', rules: TYPE_NAME.LABLE},
                {variableName: 'legalPersonPhone', str: '法人联系电话', rules: TYPE_NAME.PHONE_NUM},
                {variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
                {variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'mainBusiness', str: '主营业务', rules: TYPE_NAME.DESCRIPTION},
                {variableName: 'workTelephone', str: '单位电话', rules: TYPE_NAME.PHONE_NUM},
                {variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
                {variableName: 'gasType', str: '气源', rules: TYPE_NAME.LABLE_NOT_NULL_50},

            ]
        },
        // 表单参数-类型2-居民
        formParamenter_2: {
            number: 2,
            labelCol: 5,
            parameter: [
                {variableName: 'type', str: '客户类型', rules: TYPE_NAME.REQ_SELECT,
                    onChange: e => this.onChangeSelect(e), data: [{key: '1', value: '居民客户'}, {key: '2', value: '工商客户'}]},
                {variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT,
                    onChange: (e,a) => this.onChangeSelectList(e,a), data: [{key: "", value: ""}]
                },
                {variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
                {variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
                {variableName: 'gasType', str: '气源', rules: TYPE_NAME.LABLE_NOT_NULL_50},
            ]
        },
        // 表单参数-类型3
        formParamenter_3: {
            number: 2,
            labelCol: 5,
            parameter: [
                {variableName: 'type', str: '检定类型',        rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'intervalType', str: '安排时期',rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'number', str: '次数',          rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true,}
            ]
        },
        // 表单参数-类型4
        formParamenter_4: {
            number: 2,
            labelCol: 5,
            parameter: [
                {variableName: 'type', str: '检定类型',         rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'intervalType', str: '安排时期', rules: TYPE_NAME.REQ_SELECT, disabled: true,
                    onChange: (e,a) => this.onChangeintervalType_2(e,a), data: [{key: '1', value: '每月每天'}, {key: '2', value: '每月'}]},
            ]
        },

        // 默认数据
        data: this.props.rowData ?? {province: '湖南省', city: '长沙市'},
        data1: {type: '安检',intervalType:'每年'},
        data2: {type: '自检'},
        nature:this.props?.rowData?.nature??null,


        juder:2,// 客户类型模式
        selectWidth:this.props?.selectWidth?? true,// 客户性质
    };
// ===========================
    async componentDidMount(){
        console.log('基本信息');
        this.onChangeSelect(this.props?.rowData?.type); // 客户类型排版表单
        this.getCusAll();                               // 查询客户性质列表
        this.getPoTimeTemplateByCustomerId();           // 初始化获取客户性质
    }
    // 初始化获取客户性质
    getPoTimeTemplateByCustomerId=()=>{
        //选择类型控制展示内容
        if(this.props?.selectWidth){
            if(this.props.rowData?.customerId){
                // 获取检查数据
                getPoTimeTemplateByCustomerId({customerId:this.props?.rowData.customerId}).then(
                    response=>{
                        var {data1,data2} = this.state;

                        if(response?.data?.data){
                            response.data.data.map(one=>{
                                if(one.type===1){
                                    data1=one;
                                    data1.type='安检';
                                    data1.intervalType='每年';
                                }else if(one.type===2){
                                    data2=one;
                                    data2.type='自检';
                                    this.onChangeintervalType_2(one.intervalType.toString());
                                    data2.intervalType=data2.intervalType===1?'每月每天':'每月';
                                }
                            });
                            this.setState({data1,data2});
                            this.forms1.onModalClose();
                            this.forms2.onModalClose();
                        }
                    }
                );
            }
        }
    };

// 客户类型：工商和民用模式变更--------
    onChangeSelect = e => {if(e==='1'){this.setState({juder:1});}else{this.setState({juder:2});}};
// 查询客户性质列表------
    getCusAll =()=>{
        getCusPropertiesList().then(
            response=>{
                if(response?.data?.data){
                    let {data} = response.data;
                    let {formParamenter, formParamenter_2} = this.state;
                    formParamenter.parameter[3].data = this.getTreeData(data);formParamenter_2.parameter[3].data = this.getTreeData(data);
                    this.setState({cusPropertiesList: data, formParamenter, formParamenter_2});
                }
            }
        )
    };
// 客户性质列表 递归----------------
    getTreeData = data => {let arr = data?.map(value => {return {key:value?.id, value:value?.name}});return arr};

// 客户性质--赋值----------
    onChangeSelectList = async (e,a) => {
        this.state.cusPropertiesList.map(one=>{
            if(one.name===a.children){
                console.log(one);
                if(this.state.selectWidth){
                    this.forms1.setFieldsValue('number',one.securityCheckNumberYear);
                    this.forms2.setFieldsValue('intervalType',Number(one.selfInspectionNumberMon)>1?'每月':'每月每天');
                    this.forms2.setFieldsValue('number',one.selfCheckedNumberMon);
                    this.onChangeintervalType_2(Number(one.selfInspectionNumberMon)>1?'2':'1',null);
                }
            }
        });
        this.setState({nature:a.children})// 客户性质
    };
//  安检时间类型变更----------
    onChangeintervalType_2= async (e,a)=>{
        if(!e){return false;}
        var {formParamenter_4} =this.state;
        // 自定义类型选用
        if(e==='2'){
            console.log('当前表单不能有次数-1');
            if(!formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
                formParamenter_4.parameter.push({variableName: 'number', str: '次数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true});
            }
        }else{
            console.log('当前表单有次数去掉-2');
            if(formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
                formParamenter_4.parameter.splice(formParamenter_4.parameter.length-1,1)
            }
        }
        this.setState({formParamenter_4})
    };



    // 给与客户基本信息
    getAccountInfo=async ()=>{
        let value = await this.forms.onFinishNotReset();
        if(this.props?.selectWidth){
            if(this.props?.rowData?.customerId){
                value.id=this.props?.rowData?.customerId;
            }
        }else{
            if(this.props?.rowData?.id){
                value.id=this.props?.rowData?.id;
            }
        }
        if(!value) return null;
        value.nature=this.state?.nature;
        value.areaId = JSON.parse(localStorage.getItem('menus'))?.[0];
        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域")}
        if(!value?.id){
            value.voSafetyOfficers=this.state.table_data;
        }
        return value;
    };
    // 给安检数据
    getFormData_1 = async () =>{return await this.forms1.onFinishNotReset();};
    // 给自检数据
    getFormData_2 = async () =>{return await this.forms2.onFinishNotReset();};

// =============================
    render() {
        return (
            <Layout className={'item_body_number_back'}>
                <div style={{width:'100%',height:'100%',backgroundColor:'#fff',paddingLeft:'20px',paddingRight:'100px'}}>
                    <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.juder===2?this.state.formParamenter:this.state.formParamenter_2}
                        data={this.state.data}
                    />
                    {/*判断所处模式*/}
                    {this.state.selectWidth?
                        <>
                            {/*安检*/}
                            <FormsTwo
                                ref={e => this.forms1 = e}
                                formParamenter={this.state.formParamenter_3}
                                data={this.state.data1}
                            />
                            {/*自检*/}
                            <FormsTwo
                                ref={e => this.forms2 = e}
                                formParamenter={this.state.formParamenter_4}
                                data={this.state.data2}
                            />
                        </>
                        :null
                    }
                </div>

            </Layout>
        )
    }
}
