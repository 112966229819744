import React from 'react';
import {Layout,} from 'antd'

class titleInfo extends React.Component {
    state = {
        titleInfo:this.props?.location?.state?.titleInfo??null
    };
    componentDidMount(): void {
    }

    render() {
        return (
            <div style={{ width: '100%', height: '100%' }}>

                <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                    <div dangerouslySetInnerHTML={{__html: this.state.titleInfo}}/>
                </Layout>
            </div>
        );
    }
}
export default titleInfo;
