import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Menu,Table,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsSub'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import moment from 'moment'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'

import {funTraceInfo} from '../../../services/common'
import {getStorageBooksByCondition,getSteelNumsByBookId} from '../../../services/store'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			rowCheck:true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				storageName: '仓库名',
				userName: '出库人',
				addTime: '出库时间',
				orgName: '领料公司',
				confirmorName: '领料人',
				ackTime: '领料人确认时间',
				confirmorPhone: '领料人手机号',
				// optTime: '是否打印交接单',
			},
			//子表表头
			headerChild: {
				steelNum: '设备表钢号',
				applicationNo: '出入库单号',
				state: '状态',
			},
			// 弹出框
			headers: {
				storageName: '仓库名',
				userName: '出库人',
				addTime: '出库时间',
				orgName: '领料公司',
				confirmorName: '领料人',
				ackTime: '领料人确认时间',
				confirmorPhone: '领料人手机号',
			},
			params: {
				page:1,
				size:10,
				pcExpOrImp:'出库'
		},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'createTime',
					str: '出库时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'storageName',
					str: '仓库名',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'organizationName',
					str: '领料公司',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'confirmorPhone',
					str: '手机号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'optName',
					str: '出库人',
					rules: TYPE_NAME.INPUT,
				},
				// {
				// 	variableName: 'maintenanceType',
				// 	str: '是否打印交接单',
				// 	// isMultiple:true,
                //     disabled: false,
				// 	data:[
				// 		{key:'',value:'全部'},
				// 		{key:'是',value:'是'},
				// 		{key:'否',value:'否'},
				// 	],
				// 	rules: TYPE_NAME.SELECT,
				// },
			]
		},
		    	// 总条数
		totalCount: 0,
	}
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data)
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTime=''
		params.addTimeStart=sTime;
		params.addTimeEnd=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }


	

	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>出库台账</h3>
						<div style={{position:'relative'}}>
							<div style={{width:'88%'}}>
							<FormsTwo 
									ref={e => this.forms = e} 
									formParamenter={this.state.topFormItem} 
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'90px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary">导出</Button>
						</div>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff'}}>		
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getStorageBooksByCondition}
						setSubData = {getSteelNumsByBookId}
						setTableValue={this.setTableValue}
						ref={e => this.table = e} 
					>
					</TableComponents>
				</div>
            </Layout>
        )
    }
}
