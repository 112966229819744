import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin } from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel,listPlanRecordByAreaId,listDevByAreaIds} from '../../../services/steamReport'

echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;


export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			// 弹出框
			headers: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			params: {
				areaId:null,
				time:'',
		},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [

				
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'areaId',
					str: '区域选择',
					rules: TYPE_NAME.SELECTINPUT,
					disabled: this.props.location.state ? true : false,
					data:[],
				},
				{
					variableName: 'time',
					str: '时间类别',
					rules: TYPE_NAME.DATE_TIME_FORMAT,
				},
				
			]
		},
		searchGetData:{},

		    	// 总条数
		totalCount: 0,
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editQrCodeStatus(true,rowData.id,rowData.code)}>
						<u>{'修改'}</u>
				</div>
				</>
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		let user = JSON.parse(sessionStorage.getItem("user"))
		getDataIgnoreLevel({userId:user.id}).then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[0].data=selectArry;
			}
		)
		$('.autoTableBox').hide();


		
	}



	onChangeSelect = e => {
		console.log(111,e)
	}




	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		if(!data) return null
		$('.loadBox').show();
		let time=data.time ?moment(data.time._d).format("yyyy-MM-DD") : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		params.granularity=data.granularity
		params.areaId=data.areaId
		params.time=time
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		
		listPlanRecordByAreaId(this.state.tableParamenter.params).then(
			response => {
				const {data} = response.data
				if(data){
					$('.loadBox').hide();
					$('#noMsg').hide();
					$('.autoTableBox').show();
					console.log(data)
					var dateHtml='';
					if(data[0].planRecordResList.length>0)
					{
						$('#dateHeadBox').html('');
						$('#nameHeadBox').html('');
						for(var i=0;i<data[0].planRecordResList.length;i++)
						{
							dateHtml+="<th colSpan='3'>"+data[0].planRecordResList[i].planTime+"</th>"
							$('#nameHeadBox').append('<th>计划值</th><th>用量值</th><th>比例</th>');
						}
					}
					if(data.length>0){
						$('#dataListBox').html('');
						for(var j=0;j<data.length;j++)
						{
							var dataHtml='';
							for(var i=0;i<data[j].planRecordResList.length;i++){
								dataHtml+="<td>"+data[j].planRecordResList[i].planValue+"</td><td>"+data[j].planRecordResList[i].actualValue+"</td><td>"+data[j].planRecordResList[i].planRatio+"%</td>"
							}
							$('#dataListBox').append('<tr><td>'+data[j].pointName+'</td>'+dataHtml+'</tr>');
						}
					}
					$('#dateHeadBox').append("<th rowSpan='2'>客户名称</th>"+dateHtml);
				}else{
					$('.loadBox').hide();
					$('#noMsg').show();
					$('#noMsg').html('暂无数据');
					$('.autoTableBox').hide();
				}
			}
		)
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaId = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox rTable'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff',overflow:'auto',paddingBottom:'20px'}}>
					<div className={'topSearchBox'}>
						<h3>计划报表</h3>
						<div style={{position:'relative'}}>
							<div className={'topFormBox'} style={{width:'90%'}}>
							<FormsTwo 
									ref={e => this.forms = e} 
									formParamenter={this.state.topFormItem} 
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						</div>
					</div>
					<div id='noMsg' style={{fontSize:'30px',lineHeight:'100px',textAlign:'center',color:'#ccc'}}>请先选择区域及时间</div>
					<div className={'autoTableBox'}>
						<table id='autoTable'>
							<tr id='dateHeadBox'>
							</tr>
							<tr id='nameHeadBox'>
							</tr>
							<tbody id='dataListBox'>
							</tbody>
						</table>
					</div>

				</div>
				<div className={'loadBox'}>
					<div className={'loadInner'}></div>
					<div className={'loadIcon'}>
						<Spin></Spin>
						<p>加载中...</p>
					</div>
				</div>
				
            </Layout>
        )
    }
}
