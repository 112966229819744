import React, { Component } from 'react'
import {Layout} from "antd";
import {DoubleRightOutlined,DoubleLeftOutlined,ExclamationCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';
import * as echarts from "echarts";
import './middleMap.less'
import {valveMap} from "../../../services/meter";
import {getInfoByAreaIds} from "../../../services/customer";

export default class middleMap extends Component {
    state = {
         map_type_1:true,   // 警告栏
        bottomType:0,
        allCoordinateShow:[],
        datas:[],// 坐标
    };

    // componentWillUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {
    //     this.getEchartsMap();
    // }

    componentDidMount(): void {
        this.getInfoByAreaIds();
        this.valveMap();   // 获取热力图数据
    };

    setMapType=(params)=>{
        this.setState({map_type_1:params});
        console.log('测试');
    };
    // 根据区域ID查询管道信息
    getInfoByAreaIds=(areaIds=[],name)=>{
        var urlObject = new URLSearchParams(window.location.search);
        var areaId = urlObject.get('areaId');
        var params =  new URLSearchParams();
        params.append('areaIds',areaId??null);
        // params.append('name',name??null);
        getInfoByAreaIds(params).then(
            response=>{
                console.log('根据区域ID查询管道信息');
                var coordinateData = this.state.coordinateData;
                coordinateData=[];
                if(response?.data?.data){
                    response?.data?.data.map(one=>{
                        coordinateData.push({
                            "coordinateData":  one.coordinateData??null,
                            "id": one.id??null,
                            "name":  one.name??null,
                            "style":  one.style??null,
                        });
                    });

                }
                this.piecingCoordinate(coordinateData);// 拼凑数据 data：数据

                this.setState({coordinateData:coordinateData});
            }
        )
    };
    // 拼凑数据 data：数据
    piecingCoordinate = (data=[]) =>{
        var coordinateDatas=[];
        data.map(one=>{
            if(this.ValidateData(one.coordinateData)){
                coordinateDatas.push(JSON.parse(one.coordinateData)[0])
            }
        });
        this.getEchartsMap(null,[],coordinateDatas);       // 地图
        this.setState({allCoordinateShow:coordinateDatas});
    };
    // 验证数据格式是否合适
    ValidateData=(data)=>{
        try{
            if(data ==="" && data === null){return false;}
            if(data.indexOf('[')<0){return false;}
            var params = JSON.parse(data); // 字符串转数据对象
            // 判断当前对象是否数组
            if(!Array.isArray(params)){return false;}
            // 判断对象第二层是否数组
            for(var i=0;i<params.length;i++){if(!Array.isArray(params[i])){
                return false;
            }}
            return true;
        }catch (e) {return false;}
    };



    // 可视化燃气大屏-阀井探测器
    valveMap=()=>{
        var urlObject = new URLSearchParams(window.location.search);
        var areaId = urlObject.get('areaId');

        var params = new URLSearchParams();
        params.append('areaIds',areaId?[areaId]:[]);

        valveMap(params).then(
            response=>{
                const data = response?.data?.data??[];
                var datas= this.state.datas;
                data.map(one=>{
                    datas.push({"id":one?.devId??null,"name": one?.devName??null, "value": [one.longitude, one.latitude, 273]},)
                });

                console.log(datas);
                this.setState({datas:datas});
                this.getEchartsMap(null,datas);
            }
        ).catch(e=>{
            this.getEchartsMap(null,null);
        })
    };
    // 热力图，type=地图显示类型,data=坐标点
    getEchartsMap= async (type,data,e=[])=> {
        var datas = data??this.state.datas;
        var bottomType= type??this.state.bottomType;// 模式

        var areaInfo = JSON.parse(await localStorage.getItem('areaInfo'));
        var xy=[];
        if(areaInfo?.latitude | areaInfo?.longitude){xy=[areaInfo?.longitude,areaInfo?.latitude]}
        else{xy=[113.53176, 27.5844]}

        var chartDom = echarts.init(document.getElementById(this.props?.elementById));

        const data1=[
            [
                {
                    "x": 26.304556594,
                    "y": 111.636809422
                },
                {
                    "x": 26.305528227,
                    "y": 111.637528066
                },
                {
                    "x": 26.305787327,
                    "y": 111.638534169
                },
                {
                    "x": 26.305528227,
                    "y": 111.640905695
                },
                {
                    "x": 26.304103163,
                    "y": 111.639180949
                },
                {
                    "x": 26.302418974,
                    "y": 111.638965355
                },
                {
                    "x": 26.300993871,
                    "y": 111.637959253
                },
                {
                    "x": 26.300281313,
                    "y": 111.636809422
                },
                {
                    "x": 26.298856184,
                    "y": 111.637240609
                },
                {
                    "x": 26.297431037,
                    "y": 111.637168744
                },
                {
                    "x": 26.297171918,
                    "y": 111.636090778
                },
                {
                    "x": 26.297107138,
                    "y": 111.634797218
                },
                {
                    "x": 26.296135434,
                    "y": 111.633647387
                },
                {
                    "x": 26.295163722,
                    "y": 111.633000607
                },
                {
                    "x": 26.294515909,
                    "y": 111.633359929
                },
                {
                    "x": 26.293997656,
                    "y": 111.634006709
                },
                {
                    "x": 26.293738529,
                    "y": 111.634940947
                },
                {
                    "x": 26.293090708,
                    "y": 111.636737558
                },
                {
                    "x": 26.292961144,
                    "y": 111.638246711
                },
                {
                    "x": 26.292766797,
                    "y": 111.639180949
                },
                {
                    "x": 26.292248536,
                    "y": 111.641265017
                },
                {
                    "x": 26.292961144,
                    "y": 111.643564679
                },
                {
                    "x": 26.293479401,
                    "y": 111.645289426
                },
                {
                    "x": 26.294969378,
                    "y": 111.647157902
                },
                {
                    "x": 26.296005873,
                    "y": 111.64794841
                },
                {
                    "x": 26.297236698,
                    "y": 111.648523326
                },
                {
                    "x": 26.299180079,
                    "y": 111.648451461
                },
                {
                    "x": 26.300605204,
                    "y": 111.648954513
                },
                {
                    "x": 26.301058649,
                    "y": 111.650032479
                },
                {
                    "x": 26.300929093,
                    "y": 111.651110446
                },
                {
                    "x": 26.298985742,
                    "y": 111.652116548
                },
                {
                    "x": 26.296005873,
                    "y": 111.653913159
                },
                {
                    "x": 26.294645472,
                    "y": 111.654847397
                },
                {
                    "x": 26.294515909,
                    "y": 111.654991126
                }
            ]
        ];


        var lines=null;
        if(e.length<0){
            lines = e.map(function (track) {
                return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};
            });
        }else{
            lines = this.state.allCoordinateShow.map(function (track) {
                return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};
            });
        }

        await chartDom.setOption(
            {
                tooltip: {
                    trigger: 'item',
                    show:false
                },
                animation: false,
                bmap: {
                    center: xy  ,
                    zoom: 10,
                    roam: true,
                    // 地图样式
                    // mapStyle: {
                    //     styleJson: [
                    //         {
                    //             featureType: 'water',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#d1d1d1'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'land',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#f3f3f3'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'railway',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 visibility: 'off'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'highway',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#fdfdfd'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'highway',
                    //             elementType: 'labels',
                    //             stylers: {
                    //                 visibility: 'off'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'arterial',
                    //             elementType: 'geometry',
                    //             stylers: {
                    //                 color: '#fefefe'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'arterial',
                    //             elementType: 'geometry.fill',
                    //             stylers: {
                    //                 color: '#fefefe'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'poi',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 visibility: 'off'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'green',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 visibility: 'off'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'subway',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 visibility: 'off'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'manmade',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#d1d1d1'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'local',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#d1d1d1'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'arterial',
                    //             elementType: 'labels',
                    //             stylers: {
                    //                 visibility: 'off'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'boundary',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#fefefe'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'building',
                    //             elementType: 'all',
                    //             stylers: {
                    //                 color: '#d1d1d1'
                    //             }
                    //         },
                    //         {
                    //             featureType: 'label',
                    //             elementType: 'labels.text.fill',
                    //             stylers: {
                    //                 color: '#999999'
                    //             }
                    //         }
                    //     ]
                    // }
                    // mapStyle: {
                    //     styleJson: [
                    //         // {featureType: 'water', elementType: 'all', stylers: {color: '#044161'}},        // 水
                    //         // {featureType: 'land', elementType: 'all', stylers: {color: '#091220'}},         // 土地
                    //         // {featureType: 'boundary', elementType: 'geometry', stylers: {color: '#064f85'}},// 边界
                    //         // {featureType: 'railway', elementType: 'all', stylers: {visibility: 'off'}},     // 铁路
                    //         // {featureType: 'highway', elementType: 'geometry', stylers: {color: '#004981'}}, // 公路
                    //         // {featureType: 'highway', elementType: 'geometry.fill', stylers: {color: '#005b96', lightness: 1}},
                    //         // {featureType: 'highway', elementType: 'labels', stylers: {visibility: 'off'}},
                    //         // {featureType: 'arterial', elementType: 'geometry', stylers: {color: '#004981'}},
                    //         // {featureType: 'arterial', elementType: 'geometry.fill', stylers: {color: '#00508b'}},
                    //         // {featureType: 'poi', elementType: 'all', stylers: {visibility: 'off'}},
                    //         // {featureType: 'green', elementType: 'all', stylers: {color: '#056197', visibility: 'off'}},
                    //         // {featureType: 'subway', elementType: 'all', stylers: {visibility: 'off'}},
                    //         // {featureType: 'manmade', elementType: 'all', stylers: {visibility: 'off'}},
                    //         // {featureType: 'local', elementType: 'all', stylers: {visibility: 'off'}},
                    //         // {featureType: 'arterial', elementType: 'labels', stylers: {visibility: 'off'}},
                    //         // {featureType: 'boundary', elementType: 'geometry.fill', stylers: {color: '#029fd4'}},
                    //         // {featureType: 'building', elementType: 'all', stylers: {color: '#1a5787'}},
                    //         // {featureType: 'label', elementType: 'all', stylers: {visibility: 'off'}}
                    //     ]
                    // }
                },
                visualMap: {
                    show: false,
                    top: 'top',
                    min: 0,
                    max: 5,
                    seriesIndex: 0,
                    calculable: true,
                    inRange: {color: ['blue', 'blue', 'green', 'yellow', 'red']}
                },
                series: [
                    // 标点
                    {
                        name: '标点',
                        type: 'effectScatter',
                        coordinateSystem: 'bmap',
                        data: bottomType===1?datas:null,
                        encode: {value: 2},
                        // 标点大小
                        symbolSize: function (val) {
                            return val[2] / 15;
                        },
                        // 标点波动方式
                        showEffectOn: 'emphasis',
                        itemStyle: {
                            color: '#fff',
                            borderColor : '#000'
                        },
                        // hoverAnimation: false,
                        // 展示文本
                        label: {
                            formatter: '{b}',
                            position: 'bottom',
                            show: true,
                        },
                        // 波动样式
                        rippleEffect:{
                            number:3,
                            // color:'#3bf433'
                        },
                        tooltip:{
                            // position: ['50%', '50%'],
                            show:false,
                        }
                    },

                    {
                        type: 'lines',
                        coordinateSystem: 'bmap',
                        data: lines,
                        polyline: true,
                        lineStyle: {
                            color: 'purple',
                            opacity: 1,
                            width: 3
                        }
                    }


                ]
            }
        );
        window.onresize = function() {chartDom.resize();}; // 窗口大小变化时，ECharts地图自适应
    } ;

    render() {
        const elementById = this.props?.elementById;
        return (
            <div id={'middleMap1'} style={{width:'100%',height:'100%'}}>


                {/*按钮*/}
                <div className={'middleMap_head_type'}>
                    <div className={this.state.bottomType===0?'middleMap_head_buttom_2':'middleMap_head_buttom_1'}
                         onClick={async()=>{
                             await this.setState({bottomType:0});
                             this.getEchartsMap(0);   // 热力图
                         }}
                    >
                        <span>地图</span>
                    </div>
                    <div className={this.state.bottomType===1?'middleMap_head_buttom_2':'middleMap_head_buttom_1'}
                         onClick={async ()=>{
                             await this.setState({bottomType:1});
                             this.getEchartsMap(1);   // 热力图
                         }}
                    >
                        <span>阀井探测器</span>
                    </div>
                </div>

                <div id={elementById} className={this.state.map_type_1?'middleMap_type_1':'middleMap_type_2'} />
            </div>
        )
    }
}
