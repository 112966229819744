import React, { Component } from 'react'

import './warningAnimation.less'

export default class warningAnimation extends Component {
    state = {};
    componentDidMount(): void {}

    render() {
        return (
            <div id={'warningAnimation'}>
                {/*<div className="pulse2"/>  /!*波纹*!/*/}
                {/*<div className="pulse1"/>  /!*波纹*!/*/}

                <div className="pulse3"/>  {/*边框*/}
            </div>
        )
    }
}
