import React,{Component} from "react";
import {Layout,Spin} from "antd";

import  EZUIKit from 'ezuikit-js';
// 萤石视频
export default class EZUIKitPlayer extends React.Component{
    state={
        switchSpin:true
    };

    componentDidMount(): void {

        this.player  = new EZUIKit.EZUIKitPlayer({
            id: this.props.id, // 视频容器ID
            accessToken: this.props.accessToken,
            url: this.props.url?.url,
            audio:false,
            plugin: ['talk'],type:'fullScreen'
        });
        this.player.play();
        window.player = this.player;
        //// 切换播放地址场景（注意先执行stop方法停止上次取流）
        //// player.play({
        ////     url: 'ezopen://open.ys7.com/203751922/1.rec?begin=202001000000&end=202001235959'
        //// });
    }
    play = () => {
        var playPromise = this.player.play();
        setTimeout(()=>this.setState({switchSpin:true}),5000);
        playPromise.then((data) => {
            console.log("promise 获取 数据", data);
            this.setState({switchSpin:true});
        })
    };
    stop = () => {
        var stopPromise = this.player.stop();
        stopPromise.then((data) => {
            this.setState({switchSpin:false});
            console.log("promise 获取 数据", data)
        })
    };
    render() {
        return (
            <Layout  style={{width:'100%',height:'100%'}}>

                <div style={{
                    width:'100%',height:'25px',
                    padding:'0 5px 0 5px',
                    backgroundColor:'rgba(4,34,66,0.4)',
                    // position:'absolute ',
                    zIndex:'99'}}>
                    <div style={{float:'left'}}>
                        <span style={{color:'#fff'}}>{this.props.url?.devName}</span>
                    </div>
                    <div style={{float:'right'}}>
                        <a style={{color:'#fff'}} onClick={()=>{
                            if(this.state.switchSpin){
                                this.stop();// 停止
                                this.play();// 启动
                            }
                        }}>{this.state.switchSpin?'刷新':<Spin/>}</a>
                    </div>
                </div>

                <div id={this.props.id} style={{width:'100%',height:'100%',zIndex:'1'}}/>
            </Layout>
        );
    }
}
