import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getAllRelationships,getGasDeviceInName,insertAllRelationships,removeAllRelationships,getAllGasProperties} from '../../../services/meter'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				gasPropertiesName:'用气性质名称',
				gasDeviceName: '用气设备名称',
				ishour: '是否需要填小时用气量',
				createTime:'创建时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				gasPropertiesName:'用气性质名称',
				gasDeviceName: '用气设备名称',
				ishour: '是否需要填小时用气量',
				createTime:'创建时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
				// 设备表格参数
				tableParamenterDev: {
					manageName: "数据项管理",
					// 表格行是否可选
					rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						name: '设备名称',
						createTime:'创建时间',
						// edit: '操作',
					},
					// // 弹出框
					// headers: {
					// 	name: '设备名称',
					// 	createTime:'创建时间',
					// },
					params: {
						page:1,
						size:10,
					},
					// 是否绑定左侧区域
					bindArea: true,
				},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'name',
					str: '用气性质名称'
				}

			]
		},
		// 设备条件选择器参数
		conditionsParamenterDev: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'name',
					str: '设备名称'
				}

			]
		},
		    	// 总条数
		totalCount: 0,
		devVisible:false, // 小窗口开关器-1
		qrId:'',
		oldCode:'',
		modTitle:'添加',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 1,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: '用气性质',
					rules: TYPE_NAME.SELECTINPUT,
					data: []
				}
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'删除'} onClick={() => this.delGasPropertiesData(rowData.id)}>
						<u>{'删除'}</u>
				</div>
				</>
			case 'ishour':
				return text === 0 ? '是' : text === 1 ? '否' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenterDev.params;
		Object.assign(params,value);
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		this.setState({tableParamenter});
		await this.table2.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	//添加设备窗口显示
	addGasPropertiesStatus = async (devVisible)=>{
		getAllGasProperties().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.ModalFormParamenter.parameter[0].data=selectArry;
			}
		)
		await this.setState({devVisible,data:{},modTitle:'添加',qrId:''})
		await this.forms.onModalClose()
    }
	editGasPropertiesStatus = async (devVisible,id,data)=>{
		await this.setState({devVisible:devVisible,qrId:id,data: data,modTitle:'修改'});
		await this.forms.onModalClose()
    }
	delGasPropertiesData = async (id)=>{
		removeAllRelationships({id:id}).then(data=>{
			if(data.data.data){
				funTraceInfo("计量点信息管理", "删除用气性质", null,id)
				message.config("修改成功")
			}else{
				message.config('修改失败')

			}
			this.table.refreshTable();// 刷新表格
		});
	};

	addGasPropertiesData = async (value)=>{
			let devData = await this.forms.onFinishNotReset();
			console.log(devData);
			if(!devData) return null;
			let rowData = this.table2.getSelectChange();
			if(rowData.length < 1) return message.error('未选择数据');
			devData.gasDeviceId=rowData[0].id;
			devData.gasDeviceName=rowData[0].name;
			devData.gasPropertiesId=devData.id;
			insertAllRelationships(devData).then(data=>{

				if(data?.data){
					if(data.data.responseInfo.code===200){

						message.info("新增关系,添加成功");
						funTraceInfo("计量点信息管理", "新增用气性质", null,devData)
						this.addGasPropertiesStatus(false)
					}else{

						this.addGasPropertiesStatus(false);
						message.info('添加失败')

					}
					this.table.refreshTable();// 刷新表格
				}

			});

	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
		this.table2.refreshTable();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>用气性质与设备</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addGasPropertiesStatus(true)}><PlusSquareOutlined />新增</span>
					{/* <span className={'addLink'} onClick={() => this.updatePoint(true)}><EditOutlined />修改</span> */}

				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getAllRelationships}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addGasPropertiesData(true)}           // 点击确定
                        onCancel={() => this.addGasPropertiesStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<Conditions
								conditionsParamenter={this.state.conditionsParamenterDev} // 条件框输入框设置
								getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
								clearCondition={this.clearCondition} // 清除条件
								>
							</Conditions>
							<div style={{position:'absolute', right:'30px',top:'75px',width:'300px'}}>
								<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.ModalFormParamenter}
								data={this.state.data}
								/>
							</div>
							<div style={{paddingTop:'20px'}}>
							<TableComponents
								paramenter={this.state.tableParamenterDev}
								// setData = {getGasDeviceInName}// 旧
								setData = {getGasDeviceInName}
								setTableValue={this.setTableValue}
								ref={e => this.table2 = e}
							>
							</TableComponents>
							</div>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
