import React,{ Component } from 'react'
import {Table,message,Space,Layout,Radio,Select,Checkbox,Pagination,Modal,Upload,notification} from 'antd'
import { LoadingOutlined,ReloadOutlined,RiseOutlined,FallOutlined, InboxOutlined } from '@ant-design/icons'
import './table.css'
import moment from 'moment'
import Pubsub from 'pubsub-js'
import Drawers from './drawers/Drawers'
import Conditions from '../conditions/Conditions'

import {funTraceInfo} from '../../../services/common'

const { Option } = Select;
const {Content} = Layout;
const { Dragger } = Upload;

class TableComponents extends Component {

    state={

      // 弹窗开关
      isModalVisible: false,
      // 抽屉开关
      visible: false,
      // 选中的数据
      selectedRowKeys: [],
      // 传入到抽屉的数据
      record: {},
      // 表格列
      columns: [],
      // 已选的行
      rowDataLen: 0,
      // 表头
      header: JSON.parse(JSON.stringify(this.props.paramenter.header)),
      // 抽屉展示数据
      drawersHeader: JSON.parse(JSON.stringify(this.props.paramenter.headers ?? this.props.paramenter.header)),
      // 弹出导入开关
      importModal: false,
      // 每一列的最大宽度
      columnMaxWith: this.props.paramenter.columnMaxWith,
      // 表格加载动画
      loading: false,
      // 刷新动画
      refreshTable: false,
      // 查询参数
      params: JSON.parse(localStorage.getItem(this.props.paramenter.localStorageName ?? null)) ??
              // JSON.parse(JSON.stringify(this.props.paramenter.params ?? null)) ??
          (this.props.paramenter.params ?? null) ??
              {page: 1,size: 13},
      // 默认参数
      initParams: JSON.parse(localStorage.getItem(this.props.paramenter.localStorageName ?? null)) ??
                  JSON.parse(JSON.stringify(this.props.paramenter.params ?? null)) ??
                  {page: 1,size: 13},
      // 表格数据
      data: [],
      // 总条数
      totalCount: 0,
      // 当前行数据
      rowData: [],
      // 是否绑定区域
      bindArea: this.props.paramenter.bindArea,
      // 触发排序字段时所产生的数据
      sorter: [],
      // 需要进行排序的字段
      sorters: this.props.paramenter.sorters ?? [],
      // 判断是否正在导出，true为正在导出
      isImport: false
    };

    async componentDidMount(){
        // 清理数据
        if(this.props.paramenter.conditionsParamenter?.variableValue){
            // 获取variableValue的参数
            this.props.paramenter.conditionsParamenter?.variableValue?.map(one=>{
                if(this.state?.params[one]){
                    this.Conditions.setFieldsValue(one,this.state?.params[one]);
                }else{
                    this.Conditions.setFieldsValue(one,[]);
                }
            });
        }

      let {params} = this.state;

        // this.clearConditionOne();
      if(this.state.bindArea) {
        this.getAreaId();
        let menusStr = localStorage.getItem('menus')??null;
        console.log('1');
        let menus =
            ((menusStr != null || menusStr !== "null") && typeof menusStr == 'string')
                ?
                JSON.parse(localStorage.getItem('menus'))
                : [];
        params.areaIds = typeof menus === 'object' ? menus : [menus];
          console.log('2');
      }
      if(!this.props.paramenter.isNotInitData) {
          this.getData(params)
      }
    }

    getAreaId = () => {
      Pubsub.subscribe('areaIds', (_, data) => {
        let {params, initParams} = this.state;
        params.areaIds = data;
        params.page = 1;
        params.size = initParams.size;
        this.setState({params});
        this.getData()
      })
    };

    // 组件将要被卸载时,取消订阅
    componentWillUnmount() {
      if(this.state.bindArea) Pubsub.unsubscribe('areaIds')

    }

    getColumns = header => {
      let {columnMaxWith, sorters} = this.state
      const variables = Object.keys(header)
      let columns = []
      variables.forEach(value => {
        columns.push(
          {
            title: header[value],
            dataIndex: value,
            // ellipsis: true,
            key: value,
            onCell: () => {
              return {
                style: {
                  maxWidth: columnMaxWith ?? 500
                }
              }
            },
            sorter: sorters.includes(value) ? {
              compare: (a, b) => {
                a[value] = a[value] ?? 'null'
                b[value] = b[value] ?? 'null'
                return  Object.prototype.toString.call(a[value]).indexOf('String') !== -1 ? (!isNaN(Date.parse(a[value])) && isNaN(a[value])) ?
                new Date(Date.parse(a[value])) > new Date(Date.parse(b[value])) ? 1 : new Date(Date.parse(a[value])) === new Date(Date.parse(b[value])) ? 0 : -1 : // 为日期时
                a[value].toUpperCase() > b[value].toUpperCase() ? 1 : (a[value].toUpperCase() === b[value].toUpperCase()) ? 0 : -1 : // 为字符串时
                a[value] - b[value] // 为数字时
              },
              multiple: 1,
            } : null,
            align: 'left',
            render: (text,rowData) => text === 'null' ? '' : this.props.setTableValue ? this.props.setTableValue(value,text,rowData) : text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
          },
        )
      })
      return columns
    }

    // 点击行时打开抽屉，并传入当前行的数据
    onRowData = record => {
      let drawersHeader = this.props.paramenter.headers ? JSON.parse(JSON.stringify(this.props.paramenter.headers)) : JSON.parse(JSON.stringify(this.props.paramenter.header))
      let onRowData = this.props.onRowData ?.(record,drawersHeader) ?? this.props.paramenter?.drawersItem
      this.setState({
        record: onRowData?.onRowData ?? record,
        visible: true,
        drawersHeader: onRowData?.headers ?? drawersHeader
      })
    }

    // 关闭抽屉
    onClose = () => {
        this.setState({
            visible: false,
        });
    }

    // 勾选中行时
    onSelectChange = (_, rowData) => {
      this.props.getSelectChange?.(rowData)
      this.setState({
        rowDataLen: rowData.length,
        rowData
      })
    }

    // 返回选中的数据
    getSelectChange = () => this.state.rowData

    // 动态表头
    getDefCheckValue = (header,obj) => {
      let checkValue = [];
      const keys = Object.keys(obj)
      keys.forEach((objKey,ind) => {
        checkValue.push(
          <Option disabled key={ind}>
            &nbsp;
            <Checkbox
              key={objKey}
              disabled={!ind}
              defaultChecked={header[objKey] ? true : false}
              onChange={e => this.getCheckValue(e.target.checked,objKey,obj[objKey])}>
                {obj[objKey]}
            </Checkbox>
          </Option>
        )
      });
      return checkValue
    };

    // 获取选中表头的值
    getCheckValue = (arr,key,value) => {
      let {header} = this.props.paramenter
      !arr ? delete header[key] : header[key] = value
      this.setState({}) // 更新页面，禁止删除
    };

    // 导出
    exportTable = () => {
      let {exportUrl,title,fileName,manageName} = this.props.paramenter;
      exportUrl='/gateway'+exportUrl;
      let {params,data,isImport} = this.state;
      let msgKey = 'exportTable';
      // 将对象拆分成一条链接;
      const variables = Object.keys(params);
      if(isImport) return message.warning('文件正在导出或已导出');
      this.setState({isImport: true});
      if(!data) return message.warning('数据为空！不支持导出');
      let link = `${exportUrl}?ajaxFileName=${(fileName??title)+moment().format('lll')}.xlsx`;
      variables.forEach(key => {
        if(params[key]){
          if(!Array.isArray(params[key])) link += `&${key}=${params[key]}`;
          else params[key].forEach(str => link += `&${key}=${str}`);
        }
      });
      try{
        message.loading({ content: '导出中...', msgKey });
        notification['warning']({
          message: '文件导出中',
          description: '文件未生成时，不允许多次点击按钮。'
        });
        window.open(link)

      } catch (error) {

      }
    };

    importTable = () => {
      this.setState({ importModal: true })
      this.props.importTable?.()
    };

    // 分页器变化触发
    pageChange = (page, pageSize) => {
      let {params} = this.state;
      params.page = page;
      params.size = pageSize;
      if(this.props.paramenter?.localStorageName) localStorage.setItem(this.props.paramenter.localStorageName, JSON.stringify(params));
        // 分页查询
        if(this.props?.paramenter?.conditionsParamenter?.pageChange){
            this.props?.paramenter?.conditionsParamenter?.pageChange(params)
        }else{
            this.getData();
        }
      // this.getData();
      this.setState({params})
    };
    // 获取表单数据
    getDataTable(){
        return this.state.data;
    }

    // 查询
    getData = async params => {
      // 每次查询时将导出状态变为true
      let {manageName,title=''} = this.props.paramenter;
      this.setState({loading: true});

      // 指定参数名为空转null
        if(this.props?.paramenter?.conditionsParamenter?.variableName){
            this.props?.paramenter?.conditionsParamenter?.variableName.map(one=>{
                if(params){
                    if(!params[one] || params[one].length===0){
                        params[one]=null;
                    }
                }
                if(this.state?.params){
                    if(!this.state?.params[one] || this.state?.params[one]?.length===0){
                        this.state.params[one]=null;
                    }
                }
            });
        }
        // 参数转换，用于查询
        if(this.props?.paramenter?.conditionsParamenter?.changeValue){
            params = this.props?.paramenter?.conditionsParamenter?.changeValue(params ?? this.state.params);
        }
        console.log('---------------');
        console.log(params);
        console.log(this.state.params);
        console.log('---------------');
      this.props.setData(params ?? this.state.params).then(
          response => {
          let { data=[],pageInfo } = response?.data;
          data = data === 'null' ?  [] : data;
          this.setState({
            loading: false,
            refreshTable: false,
            totalCount: pageInfo?.total??0,
            data: data,
            isImport: false
          });
          message.success('查询成功');
            return data
          // if(manageName) funTraceInfo(manageName, "分页查询"+title, params, data)
        }
      ).catch(
        () => {
          this.setState({
            loading: false,
            refreshTable: false,
            isImport: false
          });
          message.error('查询失败')
        }
      )
    };

    // 刷新
    refreshTable = (params) => {
        if(params){
            this.getData(params);
        }else{
            this.getData();
        }
      this.setState({refreshTable: true})
    };

    // 条件选择：获取输入的条件（代码应该能简化）
    getConditionsValue = value => {
      let {params, initParams} = this.state;
      let propsValue = {};
      if(this.props.getConditionsValue) propsValue = this.props.getConditionsValue(value);
      params = {params} = value;

      Object.assign(initParams, params);
      if(this.state.bindArea) initParams.areaIds = JSON.parse(localStorage.getItem('menus'));
      Object.assign(initParams,params, propsValue);
      params = initParams;
      if(Array.isArray(params.areaIds)){
        params.areaIds=params.areaIds
      }else{
        params.areaIds=[params.areaIds]
      }
      localStorage.setItem(this.props.paramenter.localStorageName,  JSON.stringify(params));
        this.setState({params});
        // 查询参数
        if(this.props?.paramenter?.conditionsParamenter?.onchange){
            this.props?.paramenter?.conditionsParamenter?.onchange(params)
        }else{
            this.getData();
        }
        // this.getData();
    };

    // 清空条件
    clearCondition = () => {
        let params = {};
        if(this.props.clearCondition) return  this.props.clearCondition();
        let {page, size} = this.state.initParams;
        params.page = page;
        params.size = size;
        if(this.state.bindArea){
            if(Array.isArray(JSON.parse(localStorage.getItem('menus')))){
                params.areaIds = JSON.parse(localStorage.getItem('menus'));
            }else {
                params.areaIds = [JSON.parse(localStorage.getItem('menus'))];
            }
        }
        localStorage.setItem(this.props.paramenter.localStorageName,  JSON.stringify(params));
        this.setState({params});
        this.getData()
    };
    // 清空条件-不请求后台
    clearConditionOne = () => {
        let params = {};
        if(this.props.clearCondition) this.props.clearCondition();
        let {page, size} = this.state.initParams;
        params.page = page;
        params.size = size;
        if(this.state.bindArea){
            if(Array.isArray(JSON.parse(localStorage.getItem('menus')))){
                params.areaIds = JSON.parse(localStorage.getItem('menus'));
            }else {
                params.areaIds = [JSON.parse(localStorage.getItem('menus'))];
            }
        }
        localStorage.setItem(this.props.paramenter.localStorageName,  JSON.stringify(params));
        this.setState({params});
    };
    // 获取当前页数和条数
    getPagination = (page, pageSize) => {
      let {params} = this.state;
      params.page = page;
      params.size = pageSize;
      this.setState({params});
      this.getData()
    };

    closeImportModal = () => {
      this.setState({ importModal: false });
      this.refreshTable()
    };

    onChangeTable = (_, __, sorter) => {
      let sorterArr = [];
      if(sorter?.field){
        sorterArr[0] = {
          field: sorter.field,
          order: sorter.order
        }
      }else{
        sorterArr = sorter?.map(({field, order}) => ({field: field, order: order}))
      }
      let {params} = this.state;
      params.sorter = sorterArr;
      this.setState({params});
      this.getData()
    };
    getParams=()=>{
        return this.state.params;
    };

    render() {

        // 表头，点击单选时，标题，宽度，高度，是否设置条件筛选，页面显示条数，条件输入框位置，抽屉宽度，下拉框长度，导出，导入，所属系统名称
        const {header,rowSelection,title,width,height,conditionsParamenter,arr,position,drawerWidth,selectWidth,exportUrl,importUrl,manageName,customRequest} = this.props.paramenter;
        let { rowDataLen, importModal, drawersHeader, data, params, totalCount,loading,refreshTable } = this.state
        const props = {
          name: 'file',
          multiple: true,

          // action: '/upload/upload/file/fileUpload',
          action: importUrl,

            // 手动控制上传参数
            customRequest : customRequest,
            // 测试用
            // customRequest  (files){
            //     console.log('files');
            //     console.log(files);
            //     const formData = new FormData();
            //     formData.append('file', files?.file??null);
            //     formData.append('areaId', 200);
            //     try {
            //         // 使用 fetch 发送文件到服务器
            //         const res =  fetch(importUrl, {
            //             method: 'POST',
            //             body: formData,
            //             ContentType:'.xls, .xlsx'
            //
            //         });
            //         files.onSuccess(files.url);
            //         console.log( res.json())
            //     } catch (err) {
            //         console.error(err)
            //     }
            // },

          onChange(info) {

            const { status } = info.file;
            if (status !== 'uploading') {
              this?.getData?.();
            }
            if (status === 'done') {
                // funTraceInfo(manageName, title+"文件导入成功","", "成功")
                message.success(`${info.file.name} 文件上传成功.`)
            } else if (status === 'error') {
                // funTraceInfo(manageName, title+"文件导出失败","", "失败")
                message.error(`${info.file.name} 文件上传失败.`)
            }
          },
          onDrop(e) {
              console.log('测试');
          },
          accept: '.xls, .xlsx',
      };




      return (
          <Layout style={{backgroundColor: '#ffffff',padding: '0 0.5% 0 0.5%'}}>
            {
              position === 'top' ?
              <Space className={'tableTopSearchBox'} direction="horizontal" size={10} style={{margin: '10px 0'}}>
                <Conditions
                    conditionsParamenter={conditionsParamenter} // 条件框输入框设置
                    getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                    clearCondition={ this.clearCondition} // 清除条件
                >
                  {this.props.children}
                </Conditions>
              </Space> : null
            }
            <Content style={{backgroundColor: '#ffffff'}}>
              <div className={'font_size5'} style={{textAlign: 'center'}}>
                  {title}
              </div>
              <div className={'tableTopSearchBox'} style={{height:'55px'}} >
                <Space direction="horizontal" size={20} style={{margin: '0.5vh 0',float: 'left'}}>
                  {
                    conditionsParamenter && !position ?
                    <Conditions
                        ref={e => this.Conditions = e}
                        validateFields={ this.props?.paramenter?.conditionsParamenter.variableName}
                        conditionsParamenter={conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
                        {this.props.children}
                    </Conditions> : null
                  }
                </Space>
                <Space direction="horizontal" size={20} style={{margin: '0.5vh 0',float: 'right'}}>
                  <Select
                    showSearch
                    placeholder= {`选择表头`}
                    style={{ width: selectWidth ?? '6vw',color: '#000000' }}
                    optionFilterProp="children"
                    filterOption={ (input,option) =>
                      option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.getDefCheckValue(header,this.state.header)}
                  </Select>

                  <Radio.Group defaultValue="refresh" buttonStyle="solid">
                    <Radio.Button value="refresh" onClick={()=>{this.refreshTable()}}>{refreshTable? <LoadingOutlined /> : <ReloadOutlined />}刷新</Radio.Button>
                    {
                      importUrl ? <Radio.Button value="import" onClick={this.importTable}><FallOutlined />导入</Radio.Button> : null
                    }
                    {
                      exportUrl ? <Radio.Button value="export" onClick={this.exportTable}><RiseOutlined />导出</Radio.Button> : null
                    }
                  </Radio.Group>
                </Space>
                <div style={{clear: 'both'}}></div>
              </div>
              <div className={'scoll_table_x'} style={{width: width, maxHeight: height??'90%', overflow: 'auto'}}>
                <Table
                  columns={this.getColumns(header)}
                  dataSource={data}
                  scroll={false}
                  // components={components}
                  size={"large"}
                  bordered={true}
                  rowKey={this.props.id ?? "id"}
                  loading={loading}
                  rowSelection={rowSelection ? {onChange: this.onSelectChange,type: 'radio'} : null}
                  onRow={record => {
                      return {
                          onDoubleClick: () => this.onRowData(record), // 双击行
                      };
                  }}
                  pagination={false}
                  onChange={this.onChangeTable}
                />
              </div>
              <Pagination
                position={'right'}
                style={{marginTop: '10px',float: 'right',fontSize: '15px'}}
                total={totalCount} // 总条数
                showTotal={(total, range) => `当前第${range[0]}-${range[1]}条 / 共${totalCount??0}条` + (rowSelection ? `，已选 ${rowDataLen} 条` : '')}
                current={params?.page} // 当前页数
                defaultsize={params?.size} // 默认每页条数
                defaultPageSize={params?.size}
                showQuickJumper
                pageSizeOptions={arr ?? [5,10,15]}
                showSizeChanger
                onChange={this.pageChange} // 状态改变的回调
              />
              <Drawers
                rowLen={this.props.paramenter.rowLen}
                header={drawersHeader}
                visible={this.state.visible}
                onClose={this.onClose}
                record={this.state.record}
                drawerWidth={drawerWidth}
              />
            </Content>
            <Modal
              title="数据导入"
              visible={importModal}
              onOk={ this.closeImportModal }
              onCancel={ this.closeImportModal }
            >
              <Dragger {...props}

              >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">支持excel</p>
                <p className="ant-upload-hint">
                    点击或拖动文件到此区域
                </p>
              </Dragger>
            </Modal>
          </Layout>
        )
    }
}

export default TableComponents
