import React, { Component } from 'react'
import { NavLink, withRouter } from "react-router-dom"

import {Layout, Button, Radio, message, Modal, Input, Tag} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import {TYPE_NAME} from '../../components/Const'
import {funTraceInfo} from '../../services/common'
import Tables from '../../components/common/tables/Tables'
import Conditions from '../../components/common/conditions/Conditions'
import FormsText from '../../components/common/forms/FormsText'
import {queryPushDetails} from '../../services/pushData'
import moment from "moment";
import TableComponents from '../../components/common/tables/TableComponentsNew'
import FormsTwo from "../../components/common/forms/FormsTwo";
class PushRecordDetails extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据推送记录详情",
			// 表格行是否可选
            rowSelection: false,
			// 表头：变量和变量解释
			header: {
				// id: '详情编号',
				facName: '供应厂家',
				model: '表具型号',
				code: '表钢号',
				meterType: '表具业务类型',
				state: '状态',
				address: '安装地址',
				gasPropeerties: '用气性质',
				gasDevices: '用气设备',
				cusName: '客户名称',
				lastPushState: '最后推送状态',
				lastPushTime: '最后推送时间'
			},

			// 表格数据
			data: []
		},
		// 条件选择器参数
		conditionsParamenter: {
			// 是否显示条件标题
			isLable: true,
			// 是否显示清空按钮
			clearButton: true,
			parameter: [
				{
					type: TYPE_NAME.INPUT,
					variableName: 'cusName',
					str: '客户名称'
				},
				{
					type: TYPE_NAME.INPUT,
					variableName: 'code',
					str: '表钢号'
				},
				{
					type: TYPE_NAME.INPUT,
					variableName: 'adress',
					str: '安装地址'
				},

			]
		},
		// 表单参数
		formText: {
			header: {
				id: '推送编号',
				pushName: '推送名',
				pushObject: '推送对象',
				pushSums: '推送总数',
				url: '推送地址',
				pushInterval: '推送间隔',
				lastPushTime: '最后一次推送时间',
				createTime: '创建时间',

			}
		},
		formTextData:{},
		params: {
			page: 1,
			size: 10,
			adress: null,
			cusName:null,
			code:null,
			recordId: this.props.location?.state?.id
		},
		// 总条数
		totalCount: 0,
		// 选中的数据
		rowData: [],
		// 表格加载动画
		loading: false,
		// 刷新动画
		refreshTable: false,
		selectOneData:{},
		InputData:''
	};

	componentDidMount() {
		var formTextData = this.state.formTextData;
		formTextData = this.props.location.state??{};
		formTextData.pushInterval=this.props.location.state?.pushInterval?(this.props.location.state?.pushInterval+'分钟'):"";
		this.setState({formTextData:formTextData});
		this.getData()
	}


    // 表格：刷新方法
	refreshTable = () => {
		this.setState({refreshTable: true})
		this.getData()
	}





	// 条件选择：获取输入的条件
	getConditionsValue = value => {
		let {params} = this.state
		params.adress = value.adress
		params.cusName = value.cusName
		params.code = value.code
		params.page = 1
		this.setState({params})
		this.getData()
	}

	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
		let {params} = this.state;
		params.page = page;
		params.size = pageSize;
		this.setState({params});
		this.getData()
	};


	// 初始化数据
	getData = () => {
		this.setState({loading: true});
		console.log("查询参数",this.state.params);
		queryPushDetails(this.state.params).then(
			response => {
				let { data,pageInfo } = response.data;
				let {tableParamenter} = this.state;
				tableParamenter.data = data;
				this.setState({
					loading: false,
					refreshTable: false,
					totalCount: pageInfo?.total??0,
					tableParamenter: tableParamenter
				});
				message.success('查询成功')
			}
		).catch(
			() => {
				this.setState({
					loading: false,
					refreshTable: false,
				});
				message.error('查询失败')
			}
		)
	}



	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		params.adress = null
		params.cusName = null
		params.code = null
		this.setState({params})
		this.getData()
	};





    render() {
		const {InputData}=this.state;

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'}
						  onClick={() =>
							  // this.props.history.replace("/customer/clientBusiness")
							  this.props.history.go(-1)
						  }
					><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回数据推送记录管理</span>
				</div>
                <div className={'div_title_from insert_table'} style={{paddingLeft: '3vw'}}>
					<FormsText
						record={this.state.formTextData}
						header={this.state.formText.header}
						rowLen={3}
						span={6}
					/>
				</div>
				<div className={'divider'}></div>
                <div className={'item_body_number_back tables'}>
					<Tables
						tableParamenter={this.state.tableParamenter}  // 表格表头、数据
						refreshTable={this.refreshTable}  // 刷新时触发的方法
						getPagination={this.getPagination} // 分页器变化时触发的方法
						pagination={{
							totalCount: this.state.totalCount, // 总条数
							page: this.state.params.page, // 当前页数
							size: this.state.params.size, // 页面条数
							arr: [5,10] // 页数
						}}
						loading={{
							loading: this.state.loading,
							refreshTable: this.state.refreshTable
						}}
						columnMaxWith = {130}
					>
						<Conditions
							conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
							getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
							clearCondition={this.clearCondition} // 清除条件
						>
						</Conditions>
					</Tables>
                </div>
            </Layout>
        )
    }
}

export default withRouter(PushRecordDetails)
