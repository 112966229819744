import React, { Component } from 'react'
import './index.css'
import './h-ui.reset.css'
import picture404 from '../../assets/404.png'

export default class ErrorPage extends Component {
    render() {
        return (
            <div className={"system"}>
                <img alt={"页面丢失"} src={picture404}/>
                <div className={"title"}>
                    <h2>页面走丢了...</h2>
                    <h4>远方的朋友你好！非常抱歉，您所请求的页面不存在！</h4>
                    <h4>请仔细检查您输入的网址是否正确。</h4>
                </div>	
            </div>
        )
    }
}
