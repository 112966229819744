import React,{Component,useRef} from "react";
import {Button, Layout, Radio, Switch, Table, DatePicker, message, notification, Spin} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import './report.less'
import $ from "jquery";
import {getDateByUserId} from "../../../services/admin";
import {queryReport, querySecurityAssessment} from "../../../services/meter";
import Echart1 from "./js/echart_1";
const { RangePicker } = DatePicker;
// 圆角环形图
// 工商户自检报表
export default class SafetyReport extends Component{
    state={

        noMsgJuder:false,
        selectDate:null,    // 时间选择类型
        areaInfos:[],       // 所有区域详细数据
        areaType:0,         // 区域模式选择，0本区域，1本区域包括子区域
        // 表格标头
        tableData_1_head:[
            {title: '区域名', dataIndex: 'areaName', key: 'areaName',},
            {title: '日期', dataIndex: 'time', key: 'time',},
            {title: '工商客户总数', dataIndex: 'customerTotal', key: 'customerTotal',},
            {title: '当日自检客户数', dataIndex: 'selfCheckCustomerNum', key: 'selfCheckCustomerNum',},
            {title: '当日未自检客户数', dataIndex: 'unSelfCheckCustomerNum', key: 'unSelfCheckCustomerNum',},
            {title: '自检率', dataIndex: 'selfCheckRate', key: 'selfCheckRate',
                render:(text,_)=>{return<>{text}%</>}
            },
            // {title: '环比变化量', dataIndex: 'variation', key: 'variation',},
            // {title: '环比变化率', dataIndex: 'rateOfChange', key: 'rateOfChange',},
        ],
        tableData_1_data:[],        // 表格数据
        tableData_1_param:{},       // 表格参数
        tableData_1_loading:false,  // 载入动画

        juder:true,
        // 柱状图数据
        echartData:{
            type:'line', // line折线 bar柱状
            head:[],// 日期
            data:[]
        },
        echartTitle:<div><span>请配置参数查询图表</span></div>,  // 对图表的提示内容控制

        divDisplay:'none',
        dateError:'',               // 时间框边框样式
        displayData:false,          // 类型选择
        // 条件选择器参数_1
        topFormItem_1: {
            modal: true,
            number: 2,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds',
                    str: '区域选择',
                    fieldNames:{label: 'name', value: 'id', children: 'children'},
                    rules: TYPE_NAME.TREE_SELECT, data:[],
                    onChange: (k,v) => {
                        var tableData1Param = this.state.tableData_1_param;
                        tableData1Param.areaIdMap={[k]:v[0]};
                        this.setState({tableData_1_param:tableData1Param})
                    },
                },
                {
                    variableName: 'granularity', str: '时间类别',
                    onChange: (k,v) => this.onChangeSelect(k,v),
                    rules: TYPE_NAME.SELECTINPUT,
                    data: [
                        {key: '1', value: '日'},
                        {key: '2', value: '月'},
                        {key: '3', value: '年'},
                    ]
                },
            ]
        },

        topFormItem_data_1:[],  // 条件选择器参数_1_参数
    };

    // 初始化地图加载
    componentDidMount() {

        this.getDateByUserId();    // 区域列表
    }
    // 区域列表
    getDateByUserId=()=>{
        if(JSON.parse(sessionStorage.getItem("user"))?.id){
            getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
                response => {
                    if (response?.data) {
                        console.log(response?.data);
                        var topFormItem = this.state.topFormItem_1;
                        topFormItem.parameter[0].data=response?.data;
                        this.setState({topFormItem_1:topFormItem})
                    } else {
                        return message.info('获取不到数据');
                    }
                });
        }
    };

    // 时间类别选择
    onChangeSelect=(k,v)=>{
        console.log('时间类别选择');
        console.log(v);
        this.setState({displayDate:'',selectDate:v.value});
    };
    // 日期选择
    getRangePicker=()=>{
        switch (this.state.selectDate) {
            case "2":
                return<>
                    <div>
                        <div>
                            <RangePicker
                                style={{border: this.state.dateError}}
                                // showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={"YYYY-MM"}
                                picker={"month"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
            case "3":
                return<>
                    <div>
                        <div>
                            <RangePicker
                                style={{border: this.state.dateError}}
                                // showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={"YYYY"}
                                picker={"year"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
            default:
                return<>
                    <div>
                        <div>
                            <RangePicker
                                style={{border: this.state.dateError}}
                                // showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={
                                    this.state.selectDate==='1'?"YYYY-MM-DD":
                                        this.state.selectDate==='2'?"YYYY-MM":
                                            this.state.selectDate==='3'?"YYYY":"YYYY-MM-DD"

                                }
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
        }

    };
    upDate=(d,v=[])=>{
        // 检查参数
        this.setState({dateError:''});
        let params = this.state.tableData_1_param;
        params.startTime=v[0];
        params.endTime=v[1];
        this.setState({tableData_1_param:params});
        if(v[0]===""||v[1]===""){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
        }
    };


    // 查询事件
    getConditionsValue= async value =>{
        let data = await this.forms.onFinishNotReset();
        if(!data) return null;
        let params = this.state.tableData_1_param;

        if(!params?.startTime || !params?.endTime){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info("请选择时间范围")
        }
        params.granularity=data.granularity;
        this.setState({tableData_1_param:params});
        console.log(params);
        this.queryReport(params); // 获取当前客户合计
    };

    // 获取当前地址id和排列id
    recursionAreas=(areaId)=>{
        var areaIds=[];
        for(var i=0;i<this.state.areaInfos.length;i++){
            if(this.state.areaInfos[i].parentId===areaId){
                areaIds.push(this.state.areaInfos[i].id);
                var params=[];
                params = this.recursionAreas(this.state.areaInfos[i].id);
                params?.map(one=>{areaIds.push(one)})
            }
        }
        return areaIds
    };

    // 查询的数据
    queryReport=(params)=>{
        this.setState({juder:true,echartTitle:<div><Spin/><span>加载中</span></div>});
        queryReport(params).then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    var echartData = this.state.echartData;
                    echartData.head=[];
                    echartData.data=[];
                    data.map(one=>{
                        // 日期
                        if(one?.time) echartData.head.push(one.time);
                        if(one?.selfCheckRate) echartData.data.push(one.selfCheckRate);
                    });

                    this.setState({
                        juder:false,
                        tableData_1_data:response?.data?.data,
                        echartData:echartData,
                        divDisplay:'',
                        tableData_1_loading:false,
                        noMsgJuder:false
                    })
                }else{
                    this.setState({
                        divDisplay:'none',
                        tableData_1_loading:false,
                        noMsgJuder:false
                    })
                }
            }
        );
    };

    // 表格单选事件
    rowSelection=()=>{

    };

    // 表格分页事件
    getPaginationRight=(page, pageSize)=>{
        console.log("获取当前页数和条数");
        let {tableData_1_param} = this.state;
        tableData_1_param.page = page;
        this.setState({tableData_1_param: tableData_1_param});
        this.queryCustomerPointValueAmount(tableData_1_param);
    };

    render() {

        return (
            <Layout  className={'item_body_number_back tables'}>
                {/**/}
                <div className={'topSearchBox'}>
                    <h3>自检统计报表</h3>
                    <div style={{width:'100%',height:'40px'}}>
                        {/*搜索栏*/}
                        <div style={{width:'65%' ,float:'left'}}>
                            <div style={{width:'60%' ,float:'left'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem_1}
                                    data={this.state.topFormItem_1_data}
                                />
                            </div>
                            <div style={{width:'30%',float: 'left',display:this.state.displayDate,paddingLeft:'5px'}}>
                                {this.getRangePicker()}
                            </div>
                            <div style={{width:'10%',paddingLeft:'5px' ,float:'left'}}>
                                <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        width:'100%',
                        height:'100%',
                        backgroundColor: '#fff',
                        padding:'10px'
                        // display:this.state.divDisplay
                    }}>
                    <div
                        style={{
                            width: '100%',
                            height: '60%',
                        }}
                    >
                        <Table
                            columns={this.state.tableData_1_head}
                            dataSource={this.state.tableData_1_data}
                            loading={this.state.tableData_1_loading}
                            locale={{emptyText: '请选择条件查询'}}
                            scroll={{x: 'auto' }}
                            bordered
                            pagination = {{
                                showSizeChanger:false,
                                showQuickJumper: false,
                                total:this.state.tableData_1_data?.length,
                                pageSize:5,
                                showTotal: (count = this.state.tableData_1_data?.length)=>{return '共'+count+'条数据'},
                                defaultCurrent: 1,
                                pageSizeOptions: ['5']
                            }}
                        >
                        </Table>
                    </div>
                    {this.state.juder?
                        <div
                            style={{
                                width:'100%',
                                height:'40%',
                                textAlign: 'center',
                                backgroundColor:'rgba(222, 222, 222, 0.05)',
                                paddingTop: '80px',
                                fontSize:'18px',
                                fontWeight: 'bold',
                                color:'#b9b9b9'
                            }} >
                            {this.state.echartTitle}
                        </div>
                        :
                        <div style={{width:'100%',height:'40%'}}>
                            <Echart1
                                dataType={this.state.echartData.type}
                                elementById={'main'}// id
                                head={this.state.echartData.head}// 日期
                                data={this.state.echartData.data}// 数值
                            />
                        </div>
                    }
                </div>

                {/*加载效果*/}
                {/*<div id='noMsg' style={{  display:this.state.noMsgJuder? 'none':null,width:'100%', fontSize:'30px',textAlign:'center', overflow:'auto', color:'#ccc',}}>*/}
                {/*    请先选择需要对比的时间类型，再选择时间范围*/}
                {/*</div>*/}
                {/*<div style={{display:this.state.noMsgJuder? null:'none',width:'100%', fontSize:'30px',textAlign:'center', overflow:'auto', color:'#ccc',}}>*/}
                {/*    <Spin />加载中*/}
                {/*</div>*/}
                {/*----------------------*/}
            </Layout>
        );
    }
}
