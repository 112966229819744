import React,{ Component } from 'react'
import {Layout, message} from "antd";
import Tables from "../../../components/common/tables/Tables";
import Conditions from "../../../components/common/conditions/Conditions";
import {queryAllDataInPages,queryDevicePageShowDataByTimeRangeAndContent} from "../../../services/equipment";
import moment from "moment";
export default class equipmentTable extends Component {
    state={
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            parameter: [

                {
                    type: 'rangePicker',
                    variableName: 'rangePicker',
                    str: '订单时间'
                },
                {
                    type: 'input',
                    variableName: 'content',
                    str: '内容匹配'
                }
            ]
        },// 条件选择器参数
        // 表格参数
        tableParamenter: {
            params: {
                total: 0,
                pageIndex: 1,
                pageLine: 15

            },
            // 表格行是否可选
            rowSelection: true,
            // 表头：变量和变量解释
            header: {
                createTime: '通讯时间',
                content: '内容',
                ip: '源ip',
                port: '原端口',
            },
            // 下拉框：变量和变量解释
            headers: {
                createTime: '通讯时间',
                content: '内容',
                ip: '源ip',
                port: '原端口',
            },
            // 表格数据
            data: [],
        },// 表格参数END


        pageinfo: {
            total: 0,
            pageIndex: 1,
            pageLine: 15
        },
        isModalVisible: false,
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,

        QueryShowData:{
            startTime:null,
            endTime:null,
            content:null,
            pageInfo:null
        }
    };
    // 初始默认执行
    componentDidMount() {
        this.getData(); // 获取百条数据
    }
    // 获取百条数据
    getData =()=>{
        this.setState({loading: true});                            // 开启表格载入动画
        queryAllDataInPages(this.state.tableParamenter.params).then(    //  建立链接
            reponse=>{
                console.log(reponse);                                   // 打印接受数据
                let {data,pageInfo} = reponse.data;                     //
                let {tableParamenter,pageinfo} = this.state;
                tableParamenter.data = data;
                pageinfo.total=pageInfo.total;
                this.setState({
                    loading: false,
                    refreshTable: false,
                    pageinfo: pageinfo,
                    tableParamenter: tableParamenter
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')

            }
        )
    };
    // 条件选择：获取输入的条件
    getConditionsValue = value => {

        let {QueryShowData} = this.state;
        if(value.rangePicker!==null){
            QueryShowData.startTime = moment(value.rangePicker[0]._d).format('YYYY-MM-DD HH:mm:ss');
            QueryShowData.endTime = moment(value.rangePicker[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        QueryShowData.content = value.content;
        this.setState({QueryShowData});
        console.log(this.state.QueryShowData);
        QueryShowData.pageInfo = this.state.pageinfo;
        queryDevicePageShowDataByTimeRangeAndContent(QueryShowData).then(
            reponse=>{
                console.log(reponse);
                let {data,pageInfo} = reponse.data;
                let {tableParamenter,pageinfo} = this.state;
                tableParamenter.data = data;
                pageinfo.total=pageInfo.total;
                this.setState({
                    loading: false,
                    refreshTable: false,
                    pageinfo: pageinfo,
                    tableParamenter: tableParamenter
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')

            }
        )
    };
    // 获取当前页数和条数
    getPagination = (page, pageSize) => {
        let {pageinfo} = this.state;

        pageinfo.pageIndex = page;
        pageinfo.pageLine = pageSize;
        this.setState({pageinfo});
        this.getData()
    };
    // 表格：刷新方法
    refreshTable = () => {
        this.setState({refreshTable: true})
        this.getData()
    }
    // 表格：获取勾选中的值
    onSelectChange = data => {
        console.log(data);
    };
    // 清空条件
    clearCondition = () => {

        this.getData()
    };

    render(){

        console.log(sessionStorage.getItem("lastname"));
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div>

                </div>
                <Tables
                    tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
                    exportTable={this.exportTable}                  // 导出时触发的方法
                    onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                    refreshTable={this.refreshTable}                // 刷新时触发的方法
                    getPagination={this.getPagination}              // 分页器变化时触发的方法
                    pagination={{
                        totalCount: this.state.pageinfo.total,          // 总条数
                        page: this.state.pageinfo.pageIndex,               // 当前页数
                        size: this.state.pageinfo.pageLine                // 页面条数
                    }}
                    // setTableValue={this.setTableValue}              // 设置表格显示
                    loading={{
                        loading: this.state.loading,
                        refreshTable: this.state.refreshTable
                    }}
                >
                    <div style={{padding: '0.5% 0.5% 0 0.5%'}}>
                        <Conditions
                            conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                            getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                            clearCondition={this.clearCondition} // 清除条件
                        >
                        </Conditions>
                    </div>
                </Tables>
            </Layout>
        );
    }
}
