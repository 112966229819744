import React from 'react';

import * as echarts from 'echarts';

import '../subpage/bottomThree.less'

// 半圆环形图
class Semicircle extends React.Component {
    componentDidUpdate(num){
        this.elementById();
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {

                tooltip: {
                    show: false, // 关闭鼠标移上去后显示浮框信息
                    trigger: 'item',
                    confine: true,

                },
                title: {
                    text: '表具',
                    textStyle: {
                        color: '#b7fde4',
                        fontSize: 20,
                        fontWeight:'normal'
                    },
                    itemGap: -20, // 主副标题距离
                    left: 'center',
                    top: '40%'
                },
                series: [
                    {
                        name: '数值',
                        type: 'pie',
                        radius: ['95%', '70%'],
                        // center: ['0%', '50%'],
                        // adjust the start angle
                        hoverAnimation: false, // 取消掉环形图鼠标移上去时自动放大
                        startAngle: 0,
                        label: {show: false,},
                        // 内部字体
                        emphasis: {label: {show: false, fontSize: 12, fontWeight: 'bold'}},
                        labelLine: {show: false},
                        data: [
                            {
                                value: this.props?.data?.value,
                                name: this.props?.data?.name,
                                itemStyle: {normal: {color:"#9ffd5d"},},
                            },
                            {
                                value:  this.props?.data?.counNum,
                                name:'总数',
                                itemStyle: {color: "#34d0e8", decal: {symbol: 'none'}},
                                label: {show: false}
                            }
                        ]
                    }
                ]
            }
        );
    };
    render() {
        return (

                <div id={this.props.elementById} style={{ width: '100%', height: '100%' }} />

        );
    }
}
export default Semicircle;
