//订单管理界面
//目前固定用户id,数据库查询获得公司id从而获得对应的供应商
import React,{ Component } from 'react'
import {Layout, Modal, Radio, Table} from "antd";
import Tables from "../../components/common/tables/Tables";
import {NavLink} from "react-router-dom";
import Conditions from "../../components/common/conditions/Conditions";
import {TYPE_NAME} from "../../components/Const";
import {findMeterInCode,getOrderFormDetail,deliveryNodeNumber} from "../../services/procurement";
import {funTraceInfo} from "../../services/common";
import moment from "moment";
export default class order extends Component{
    state={
        manageName:'采购管理-订单管理',
        // 当前页面表格数据
        tableInfo:{
            // 表格行是否可选
            rowSelection: true,
            // 表格字段名
            header:{
                code: '订单编号',
                orderTime: '订单时间',
                arrivalTime: '订单完成时间',
                state: '订单状态',
                companyName:'供应商单位',
                purchaseMan: '供应商联系人'
            },
            // 边缘栏
            headers: {
                code: '订单编号',
                orderTime: '订单时间',
                arrivalTime: '订单完成时间',
                state: '订单状态',
                companyName:'供应商单位',
                purchaseMan: '供应商联系人'
            },
            // 数据
            data:[],
        },
        //订货清单
        shippingList:{
            deliveryHead:{
                customerName: '发货人',
                deliveryState: '发货状态',
                quantity: '提交数量',
            },
            tabelDataOne:[
                {   title: '表刚号', dataIndex: 'steelNum', key:'steelNum'  },
                {   title: '设备类型', dataIndex: 'type', key:'type'  },
                {   title: '设备型号', dataIndex: 'model', key:'model'  },
                {   title: '订单数量', dataIndex: 'quantity', key:'quantity'  },
                {   title: '已选货量', dataIndex: 'number', key:'number'  },
            ],
            tabelDataTwo:[
                {   title: '设备类型', dataIndex: 'type', key:'type'  },
                {   title: '设备型号', dataIndex: 'model', key:'model'  },
                {   title: '订单数量', dataIndex: 'quantity', key:'quantity'  },
                {   title: '已选货量', dataIndex: 'number', key:'number'  },
            ],
            // 临时发货单数据
            oneMeterOrderForm:[],
        },//
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,

            parameter: [
                {
                    type: TYPE_NAME.INPUT,
                    variableName: 'steelNum',
                    str: '订单号'
                },

            ]
        },
        // 条件查询参数-后端
        params: {
            code: null,          // 订单号
            state:null,          // 订单状态
            timeStart:null,      // 订单开始时间
            timeEnd:null,        // 订单结束时间
            userId:1,            // 用户id
            pageinfo:{
                totalCount: 0,
                page: 1,
                size: 15,
            }
        },
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,
        // 窗口开关
        modal1Visible:false,
    };
    // 表格选框触发事件
    onSelectChange=(value)=>{};
    // 表格刷新按钮触发事件
    refreshTable=()=>{
        this.getConditionsValue()
    };
    // 表格分页功能操作分页按钮触发事件
    getPagination=(page, pageSize)=>{
        let{params}=this.state;
        params.pageinfo.page=page;
        params.pageinfo.size=pageSize;
        this.setState(params);
        this.getConditionsValue();
    };
    // 表格使用导出按钮
    exportTable=()=>{};
    clearCondition=()=>{
        console.log("条件查询-清空");
        this.getConditionsValue();
    };

    // 采购方订单查询-可订单号查询
    getConditionsValue=(vaule)=> {
        console.log("条件查询-订单号查询");
        console.log(vaule);
        if(vaule===null){return null;}// 判断是否有数据
        let {params,tableInfo,manageName} = this.state;
        params.code=vaule?.steelNum;
        findMeterInCode(params).then(
            response=>{
                let data = response.data.data;
                console.log(response);
                if(data?.length!==0){    // 判断是否有数据
                    tableInfo.data = data;
                }else{
                    tableInfo.data = [];
                }
                funTraceInfo(manageName,"条件查询-订单号查询","参数："+params,"返回结果："+tableInfo.data);
                params.pageinfo.totalCount=response.data.pageInfo?.total;
                params.pageinfo.page=response.data.pageInfo?.pageIndex;
                params.pageinfo.size=response.data.pageInfo?.pageLine;
                this.setState({tableInfo:tableInfo})
            }
        )
    };
    // 页面初始化加载
    componentDidMount=()=>{
        let user = JSON.parse(sessionStorage.getItem("user")??null);
        console.log("user");
        console.log(user);
        let {params} = this.state;
        params.userId=user.id;
        this.setState(params);
        this.getConditionsValue();// 获得所有有关用户的订单数据
    };
    // 小型窗口开关
    derailingSwitch=(modal1Visible)=>{
        this.setState({modal1Visible:modal1Visible})
    };
    // 小型窗口表格数据字段名动态选择
    getColumns=()=>{
        console.log("选择数字",this.state.shippingList.oneMeterOrderForm.type);
        switch (this.state.shippingList.oneMeterOrderForm.type) {
            case 1:
                return this.state.shippingList.tabelDataOne;
            case 2:
                return this.state.shippingList.tabelDataTwo;
            default:
                return this.state.shippingList.tabelDataOne;
        }
    };
    // 发货表具和未发货表具各类型总和
    getDeliveryNodeNumber(value){
        deliveryNodeNumber({id:value.id}).then(
            response=>{
                console.log("发货表具和未发货表具各类型总和");
                console.log(response);
                let {shippingList} = this.state;
                shippingList.oneMeterOrderForm.voOrderFormDetails.forEach(one=>{
                    response.data.forEach(data=>{
                        console.log("开始");
                        let key =data.key;// 获得后台key
                        let name="";      // 前台key
                        if(one.model!==null){
                            name+=one.model;
                        }
                        if(one?.steelNum!==null){
                            name+=one.steelNum;
                        }
                        console.log("中间");
                        if(key.search(name)!==-1){
                            console.log("一致");
                            one.number+=data.number;
                            this.setState(shippingList);
                            console.log(one.number);
                        }else{
                            console.log("不一致");
                        }
                    })
                });

            }
        ).catch(()=>{console.log("查询表具各个类型综合报错")})

    };
    onclick=(value)=>{
        console.log("点击事件");
        getOrderFormDetail({id:value.id}).then(
            response=> {
                console.log(response.data);
                let data = response.data;
                let {shippingList} = this.state;
                shippingList.oneMeterOrderForm=data;
                this.setState({shippingList:shippingList});
                if(true){
                    this.getDeliveryNodeNumber(value);//发货表具和未发货表具各类型总和
                }
            }
        );
        this.derailingSwitch(true);
    };
    // 表格部分数据内容修改
    setTableValues = (value,text,rowData) => {
        switch (value) {
            case 'orderTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                );
            case 'arrivalTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                );
            case 'state':
                text = text*1;
                return text === 1 ? '未发货' : text === 2 ? '已发货' : text === 3 ? '部分发货' : text === 4 ? '部分收货':text === 5 ? '已收货': '-';
                // return text === 1 ? '未发货' : text === 2 ? '已发货' : text === 3 ? '部分发货' : text === 4 ? '部分收货':text === 5 ? '已收货': '-';
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };
    render(){
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <Tables
                    tableParamenter = {this.state.tableInfo}                    // 表格表头、数据
                    onClickData={this.onclick}
                    exportTable={this.exportTable}                              // 导出时触发的方法
                    onSelectChange={this.onSelectChange}                        // 选中多选框时触发的方法
                    refreshTable={this.refreshTable}                            // 刷新时触发的方法
                    getPagination={this.getPagination}                          // 分页器变化时触发的方法
                    pagination={{
                        totalCount: this.state.params.pageinfo.totalCount,   // 总条数
                        page: this.state.params.pageinfo.page,               // 当前页数
                        size: this.state.params.pageinfo.size                // 页面条数
                    }}
                    setTableValue={this.setTableValues}                          // 设置表格部分内容显示效果
                    loading={{
                        loading: this.state.loading,
                        refreshTable: this.state.refreshTable
                    }}
                >
                    <Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
                        <Radio.Group defaultValue="addClient" buttonStyle="solid">
                            {/*<NavLink to="/purchase/shopping">*/}
                            {/*    <Radio.Button value="addClient">表号订单</Radio.Button>*/}
                            {/*</NavLink>*/}
                            <NavLink to="/purchase/shoppingNumber">
                                <Radio.Button value="addClient">型号订单</Radio.Button>
                            </NavLink>
                        </Radio.Group>
                    </Conditions>
                </Tables>
                <Modal
                    title="订单表具详情"
                    style={{ top: 0 }}
                    visible={this.state.modal1Visible}
                    okText="确定"
                    onOk={() => this.derailingSwitch(false)}           // 点击确定
                    onCancel={() => this.derailingSwitch(false)}       // 点击取消
                    width={800}
                    centered
                >
                    <Table
                        bordered
                        // rowSelection={{onChange: this.getTest, type: 'radio'} }
                        // rowKey={record => record.id}
                        dataSource={this.state.shippingList.oneMeterOrderForm.voOrderFormDetails}
                        columns={this.getColumns()}
                        scroll={{ y: 550 }}
                    />
                </Modal>
            </Layout>

        );
    }
}
