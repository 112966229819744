import React, { Component } from 'react'
import {Layout, Select, Form, Tag, Button, Modal, Upload, Image, Radio, message, Popconfirm} from 'antd'
import TableComponents from '../../components/common/tables/TableComponents'
import {getImportInfoRecord} from '../../services/alarm'
import {
    addCusProperties,
    pageCusPropertiesList,
    querySecurityAssessment, removeCusProperties,
    updateCusProperties
} from "../../services/meter";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../components/Const";
import {NavLink} from "react-router-dom";

const { Option } = Select;
const FormItem = Form.Item;
// 客户配置
export default class customerConfiguration extends Component {
    state = {
        // 表格参数
        tableParamenter: {
            manageName: "客户配置",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'customerConfiguration',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '客户配置',

            header: {
                name: '客户性质名称',
                securityCheckNumberYear: '每年应安检次数',
                // selfCheckedType: '每月应自检类型',
                selfCheckedNumberMon: '每月应自检次数 ',
                selfInspectionNumberMon: '每月应自查次数',
                selfInspectionNumberYear: '每年应自查次数',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'name',
                        str: '客户性质',
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },

                ],

            },
        },
        formType:0,//0添加，1修改
        visible_1:false,
        // 表单参数
        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'name', str: '客户性质', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'securityCheckNumberYear', str: '每年应安检次数', rules: TYPE_NAME.REQ_NUMBER,},
                {variableName: 'selfInspectionNumberMon', str: '每月应自查次数', rules: TYPE_NAME.REQ_NUMBER,
                    onChange:(title)=>{
                       if(title){
                           this.state.formParamenter.parameter[4].rules=TYPE_NAME.NUMBER;
                       }else{
                           this.state.formParamenter.parameter[4].rules=TYPE_NAME.REQ_NUMBER;
                       }
                    }
                },
                {variableName: 'selfCheckedNumberMon', str: '每月应自检次数', rules: TYPE_NAME.REQ_SELECT,
                    data:[{key:'1',value:'每天'},{key:'2',value:'自定义'},],
                    onChange:(v,d)=>{this.selfCheckedNumberMon(v,d)}
                },
                {variableName: 'selfInspectionNumberYear', str: '每年应自查次数', rules: TYPE_NAME.REQ_NUMBER,
                    onChange:(title)=>{
                        if(title){
                            this.state.formParamenter.parameter[2].rules=TYPE_NAME.NUMBER;
                        }else{
                            this.state.formParamenter.parameter[2].rules=TYPE_NAME.REQ_NUMBER;
                        }
                    }
                },
            ]
        },

        tableId:null
    };

    // 自定义自检次数
    selfCheckedNumberMon=(v,d)=>{
        var formParamenter = this.state.formParamenter;
        // 判断有没有超过表单量
        if(formParamenter.parameter.length>5){this.state.formParamenter.parameter.splice(4,1);}
        if(v.toString()==='2'){
            formParamenter.parameter.splice(4,0, {variableName: 'num', str: '自检次数', rules: TYPE_NAME.REQ_NUMBER});
        }
        this.setState({formParamenter:formParamenter})
    };

    // selfCheckedNumberMon
    setTableValue = (value,text,rowData) => {
        switch (value) {
            // case 'selfCheckedType':
            //     var title = text?text===1?'每月每天':text!==1?'自定义':'-':'-';
            //     return<>
            //         <span>{title}</span>
            //         </>;
            case 'selfCheckedNumberMon':
                var title = rowData.selfCheckedType===1?'每天': rowData.selfCheckedNumberMon;
                return<>
                    <span>{title}</span>
                </>;
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async ()=>{
                             await this.setState({
                                 visible_1:true,        // 弹窗
                                 formType:1,            // 新增和修改模式
                                 essentialData:rowData, // 数据
                                 tableId:rowData.id     // id
                             });
                             this.forms.onModalClose();
                             // 判断是否每天或自定义
                             console.log(rowData);
                             this.selfCheckedNumberMon(Number(rowData.selfCheckedNumberMon)>1?2:1,null);// 自定义次数
                             // 赋值
                             this.forms.setFieldsValue('selfCheckedNumberMon',Number(rowData.selfCheckedNumberMon)>1?'自定义':'每天');
                             if(Number(rowData.selfCheckedNumberMon)>1){this.forms.setFieldsValue('num',rowData.selfCheckedNumberMon);}
                             }
                         }
                    >
                        <u >{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title={'是否删除'}
                                    onConfirm={()=>{
                                        console.log('是否删除');
                                        this.removeCusProperties(rowData.id);
                                    }}
                                    okText="是" cancelText="否">
                            <a>{'删除'}</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };

    // 添加客户性质
    addCusProperties=async ()=>{
        var param = await this.forms.onFinish();
        if(!param){return false;}

        var formParamenter = this.state.formParamenter;
        console.log(formParamenter.parameter.length);
        if(formParamenter.parameter.length>=6){
            formParamenter.parameter.splice(4,1);
        }
        var params={
            "name": param.name,
            "securityCheckNumberYear": param.securityCheckNumberYear,       // 每年应安检次数
            "selfInspectionNumberMon": param.selfInspectionNumberMon,       // 每月应自查次数
            "selfCheckedType": param.num?2:1,  // 自定义自检次数
            "selfCheckedNumberMon": param.num??param.selfCheckedNumberMon,  // 自定义自检次数
            "selfInspectionNumberYear": param.selfInspectionNumberYear      // 每年应自查次数
        };
        this.setState({visible_1:false,formParamenter:formParamenter});
        addCusProperties(params).then(
            response=>{
                if(response.data?.data){
                    message.info('添加成功');
                }else{
                    message.info('添加失败')
                }
                this.table.refreshTable();
            }
        ).catch(e=>{ message.info('添加异常')})
    };

    updateCusProperties=async()=>{
        console.log("修改");
        var params = await this.forms.onFinish();
        if(!params){return false;}
        this.setState({visible_1:false});
        params.id=this.state.tableId;
        params.selfCheckedNumberMon=(params.selfCheckedNumberMon==='1') || (params.selfCheckedNumberMon==='每天')?1:params.num;
        params.selfCheckedType = params.selfCheckedNumberMon===1?1:2;  // 自定义自检次数类型
        console.log(params);
        updateCusProperties(params).then(
            response=>{
                console.log(response);
                if(response.data?.data){
                    message.info('修改成功');
                }else{
                    message.info('修改失败')
                }
                this.table.refreshTable();
            }
        ).catch(e=>{ message.info('修改异常')})
    };

    // 删除客户性质
    removeCusProperties=(id)=>{

        removeCusProperties({id}).then(
            response=>{
                console.log(response);
                if(response.data?.data){
                    message.info('删除成功');
                }else{
                    message.info('删除失败')
                }
                this.table.refreshTable();
            }
        ).catch(e=>{ message.info('删除异常')})

    };


    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={pageCusPropertiesList}
                    setTableValue={this.setTableValue}// 设置表格显示
                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addClient" buttonStyle="solid">
                        <Radio.Button value="addClient"
                                      onClick={async ()=>{

                                          await this.setState({visible_1:true,formType:0,essentialData:{}});
                                          await this.forms.onFinish();
                                      }}
                        >
                            新增客户性质
                        </Radio.Button>
                    </Radio.Group>
                </TableComponents>

                <Modal
                    title={this.state.formType===0?"添加性质":'修改性质'}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_1}
                    okText="确定"
                    onOk={() => {
                        this.state.formType===0?
                            this.addCusProperties()
                            :
                            this.updateCusProperties()

                    }}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={500}
                    centered
                >
                    <div style={{width:'100%',height:'100%'}}>
                        <div>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.formParamenter}
                                data={this.state.essentialData}
                            />
                        </div>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
