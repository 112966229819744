import React,{Component} from "react";
import {Button, Form, Layout, Select, Tabs, DatePicker, Radio, message, Cascader, Tag, Spin} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import {TYPE_NAME} from "../../../components/Const";
import Echart1 from "../js/echart_1";
import moment from "moment";
import {queryPeakValleyPage} from "../../../services/report";
import {getMetersBySteelNum} from "../../../services/meter";
import {getDataIgnoreLevel} from "../../../services/steamReport";
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const { Option } = Select;

// 多表具用量对比
export default class meterssAnalysisPage extends Component {

    state = {
        areaInfos:[],       // 所有区域详细数据
        itemIdList:['1184'],// 数据项id集
        // 时间集
        dates:{
            startTime:null,
            endTime:null
        },
        dateError:'',       // 时间框样式控制
        formSelect:[],      // 被选用户下拉框
        devIds:[],        // 用气点
        juder:true,
        echartTitle:<div><span>请配置参数查询图表</span></div>,  // 对图表的提示内容控制
        cascaderStatus_1:'',
        // 柱状图数据
        echartData:{
            type:'bar', // line折线 bar柱状
            head:[],// 日期
            data:[]
        },

        RadioSelectOne:null,
        // 控制器表格参数
        tableParamenter_1: {

            manageName: "SIM卡申请管理",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                meterManufacturer: '供应商',
                modelName: '表具型号',
                steelNum: '表钢号',
                type: '表具业务类型',
                state: '表具业务状态',
                installSite:'用气点地址',
                properties: '用气性质',
                // gasEquipment: '用气设备',
                customerName: '客户名称',
                edit: '操作',
            },

            // rowData:[],
            params: {
                areaIds:[],
                page:1,
                size:5,

            },

        },


        // 条件选择器参数_1
        topFormItem_1: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds', str: '区域选择', rules: TYPE_NAME.SELECTINPUT, data:[],
                    onChange: (k,v) => {
                        this.areaIdsSelect(k,v);
                    },
                },
                // {variableName: 'manufacture', str: '表具厂商', rules: TYPE_NAME.INPUT,},
                {variableName: 'steelNum', str: '表钢号', rules: TYPE_NAME.INPUT,},
                {variableName: 'name', str: '客户名称', rules: TYPE_NAME.INPUT,},
                // {variableName: 'id', str: '客户编号', rules: TYPE_NAME.INPUT,},
                // {variableName: 'addressInfo', str: '客户地址', rules: TYPE_NAME.INPUT,}
            ]
        },
        topFormItem_data_1:[],
    };

    recursionAreas=(areaId)=>{
        var areaIds=[];
        for(var i=0;i<this.state.areaInfos.length;i++){
            if(this.state.areaInfos[i].parentId===areaId){
                areaIds.push(this.state.areaInfos[i].id);
                var params=[];
                params = this.recursionAreas(this.state.areaInfos[i].id);
                params?.map(one=>{areaIds.push(one)})
            }
        }
        return areaIds
    };

    areaIdsSelect= (k,v) => {
        var tableParamenter_1 = this.state.tableParamenter_1;
        tableParamenter_1.params.areaIds=[k];
        var areaIds = this.recursionAreas(k);
        areaIds.map(one=>{tableParamenter_1.params.areaIds.push(one);});
        this.setState({tableParamenter_1: tableParamenter_1});
        this.table.refreshTable(tableParamenter_1.params);
    };


    // 初始化地图加载
    componentDidMount() {
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                let areaIds =[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        selectArry.push({key:data[i].id,value:data[i].name})
                        areaIds.push(data[i].id)
                    }
                }
                var tableParamenter = this.state.tableParamenter_1;
                tableParamenter.params.areaIds=areaIds;
                this.state.topFormItem_1.parameter[0].data=selectArry;
                this.setState({tableParamenter_1:tableParamenter});
                this.table.refreshTable(this.state.tableParamenter_1.params);
            }
        );
    }
    // 表单数据
    onFinish_1=(value)=>{
        console.log('表单数据');
        // 日期框样式
        var dates = this.state.dates;
        if(!dates.startTime){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
        }
        // 列表数据
        var formSelect = this.state.formSelect;
        if(formSelect.length===0){
            this.setState({cascaderStatus_1:'error'});
            return message.info('请选择表具');
        }
        // 图表数据
        if(formSelect.length>5){
            return message.info('对比数量超过加载量');
        }
        var params={
            // areaIds:['290'],
            "devIds": [],
            "startTime": dates.startTime,       // 开始时间
            "endTime": dates.endTime,           // 结束时间
            "granularity": value.dateType,      // 1:时，2:日，3：月，4：明细分析
            "itemIdList": this.state.itemIdList,// 数据项id集合；用量：1184，温度：1205，压力：1206、1225，密度：1150，瞬时流量：1182,频率：1204
            // "pointIds": [],         // 计量点集
            page:1,
            size:1000
        };
        if(params.granularity==="1"){
            params.startTime=params.startTime+":00:00";
            params.endTime=params.endTime+":00:00";
        }
        this.state.devIds.forEach(one=>{
            params.devIds.push(one.devId)
        });
        this.setState({juder:true,echartTitle:<div><Spin/><span>加载中</span></div>});
        queryPeakValleyPage(params).then(
            response=>{
                const {data}=response.data;
                if(data){
                    // 图表数据
                    var echartData = this.state.echartData;
                    echartData.head=[];
                    echartData.data=[];
                    // 配置表头
                    for(var i=0;i<data.length;i++){
                        if(data[i]?.reportList){
                            for(var a=0;a<data[i]?.reportList.length;a++){
                                echartData.head.push(data[i]?.reportList[a].time)
                            }
                        }
                        break;
                    }
                    // 配置数据
                    for(var i=0;i<data.length;i++){
                        var param={
                            label:data[i]?.name,
                            values:[],
                        };
                        if(data[i]?.reportList.length!==0){
                            for(var z=0;z<data[i]?.reportList.length;z++){
                                param.values.push(data[i].reportList[z].value || 0);
                            }
                        }else{
                            for(var q=0;q<echartData.head.length;q++){
                                param.values.push(0);
                            }
                        }
                        echartData.data.push(param);// 添加数据
                    }

                    return this.setState({echartData: echartData,juder:false});

                }else{
                    message.info("暂无数据");
                    this.setState({echartTitle:<div><span>暂无数据</span></div>})
                }
                this.setState({juder:true});
            }
        ).catch(e=>{
            message.info("访问数据失败");
            this.setState({echartTitle:<div><span>暂无数据</span></div>,juder:true})
        });



    };
    // 添加进入客户下拉框
    addUserSelect=(rowData)=>{
        console.log('添加进入客户下拉框');
        var formSelect = [...this.state.formSelect];
        var devIds = this.state.devIds;
        //判断参数情况
        if(formSelect.length>4){
            return message.info('超出最大加载量');
        }
        if(!rowData?.steelNum){
            return message.info('当前表具不符合添加条件');
        }

        devIds.push(rowData);
        formSelect.push([rowData.steelNum]);
        this.setState({formSelect:formSelect,devIds:devIds});
    };
    // 表格-样式
    setTableValue=(value,text,rowData)=>{
        if(text === 'null') return '-';
        switch (value) {
            // case 'type':
            //     text = text*1;
            //     return text === 1 ? <Tag color={'geekblue'}>备用表</Tag> : text === 2 ? <Tag color={'green'}>用户表</Tag> : '';
            case 'comTime':
                return text?moment(text).format("yyyy-MM-DD 00:00:00"):'-';
            case 'edit':
                var juder=true;
                for(var i=0;i<this.state.formSelect.length;i++){
                    if(this.state.formSelect[i][0]===rowData.steelNum){
                        juder=false;
                    }
                }
                if(juder){
                    return<>
                        <div className={'tableLink'} title={'加入对比'}
                            // 添加进入客户下拉框
                             onClick={()=>{this.addUserSelect(rowData);}}
                        >
                            <u>{'加入对比'}</u>
                        </div>
                    </>;
                }
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 表格-查询
    getConditionsValue= async value =>{
        let data = await this.forms.onFinishNotReset();
        if(!data) return false;
        // {variableName: 'steelNum', str: '表钢号', rules: TYPE_NAME.INPUT,},
        // {variableName: 'name', str: '客户名称', rules: TYPE_NAME.INPUT,},
        // // {variableName: 'id', str: '客户编号', rules: TYPE_NAME.INPUT,},
        // {variableName: 'addressInfo', str: '客户地址', rules: TYPE_NAME.INPUT,}
        var tableParamenter = this.state.tableParamenter_1;
        tableParamenter.params.manufacture=data?.manufacture || null;
        tableParamenter.params.steelNum=data?.steelNum || null;
        tableParamenter.params.name=data?.name || null;
        // tableParamenter.params.id=data?.id || null;
        tableParamenter.params.addressInfo=data?.addressInfo || null;
        await this.table.refreshTable(tableParamenter.params);
    };
    // 日期选择
    getRangePicker=()=>{
        // return<>
        //     <div>
        //         <div>
        //             <RangePicker
        //                 key={this.state.timeSelect}
        //                 size="small"
        //                 style={{border: this.state.dateError}}
        //                 showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
        //                 format={this.state.selectDate==='1'?"YYYY-MM-DD HH":
        //                     this.state.selectDate==='2'?"YYYY-MM-DD":
        //                         this.state.selectDate==='3'?"YYYY-MM":"YYYY-MM-DD HH"
        //
        //                 }
        //                 onChange={(d,v)=>{this.upDate(d,v)}}
        //             >
        //             </RangePicker></div>
        //         <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
        //     </div>
        // </>;
        switch (this.state.selectDate) {
            case "3":
                return<>
                    <div>
                        <div>
                            <RangePicker
                                key={this.state.timeSelect}
                                size="small"
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={"YYYY-MM"}
                                picker={"month"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
            default:
                return<>
                    <div>
                        <div>
                            <RangePicker
                                key={this.state.timeSelect}
                                size="small"
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={this.state.selectDate==='1'?'YYYY-MM-DD HH':"YYYY-MM-DD"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
        }
    };
    upDate=(d,v=[])=>{
        // 检查参数
        this.setState({dateError:''});
        let params = this.state.dates;
        params.startTime=v[0];
        params.endTime=v[1];
        this.setState({dates:params});
        if(v[0]===""||v[1]===""){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
        }
    };
    render() {
        return  (
            <Layout  className={'item_body_number_back tables'}>
                <div>

                    {/*----------------上-----------------*/}
                    <div className={'topSearchBox'}>
                        <h3>多表具用量对比</h3>
                        <div style={{position:'relative'}}>
                            <div style={{width:'90%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem_1}
                                    data={this.state.topFormItem_data_1}
                                />
                            </div>
                            <Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                        </div>
                    </div>
                    {/*----------------上END---------------*/}

                    {/*-----------------中----------------*/}
                    <div style={{widht:'100%',height:'400px',padding:'10px 0 0 0',background:'#fff'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter_1}
                            setData = {getMetersBySteelNum}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        />
                    </div>
                    {/*-----------------中END--------------*/}

                    <div style={{width:'100%',height:'315px',backgroundColor: '#fff',padding:'10px'}}>
                        {/*底部样式上层*/}
                        <div style={{width: '100%',height:'50px'}}>

                            <div style={{width: '30%',float:'left',paddingTop:'4px'}}>
                                <span >表钢号列表:</span>
                                <Cascader
                                    status={this.state.cascaderStatus_1}
                                    style={{ width: '80%',marginLeft:'5px'}}
                                    size="small"
                                    value={this.state.formSelect}
                                    multiple
                                    onChange={(value)=>{
                                        var devIds = this.state.devIds;
                                        // 如果为空直接清空
                                        if(value.length===0){
                                            devIds=[];
                                        }else{
                                            for(var one=0;one<devIds.length;one++){
                                                var juder=true;
                                                for(var two=0;two<value.length;two++){
                                                    if(value[two][0]===devIds[one].steelNum){
                                                        juder=false;
                                                    }
                                                }
                                                if(juder){
                                                    devIds.splice(one,1)
                                                }
                                            }
                                        }
                                        this.setState({formSelect:value,devIds:devIds})
                                    }}
                                />
                            </div>
                            {/*表单DIV*/}
                            <div style={{width: '52%',float:'left'}}>
                                {/*表达*/}
                                <Form
                                    id="form"
                                    name="dynamic_form_nest_item"
                                    ref={e => this.form = e}
                                    onFinish={(value)=>{
                                        this.onFinish_1(value)
                                    }}
                                    style={{width: '100%',height:'100%' }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    <div style={{ width: '240px',float:'left' }}>
                                        <Form.Item
                                            label="时间尺度"
                                            name="dateType"
                                            rules={[{ required: true, message: '请选择时间尺度' }]}
                                        >
                                            <Select
                                                // mode="multiple"
                                                optionLabelProp="label"
                                                size="small"
                                                placeholder="时间类型"
                                                onChange={(a)=>{
                                                    this.setState({
                                                        selectDate:a,
                                                        timeSelect: new Date(),
                                                        dates:{}
                                                    })
                                                }}
                                                maxTagCount={'responsive'}
                                            >
                                                <Option value={'1'} label={'小时'}>小时</Option>
                                                <Option value={'2'} label={'日'}>日</Option>
                                                <Option value={'3'} label={'月'}>月</Option>
                                                <Option value={'5'} label={'峰谷'}>峰谷</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{width: '400px', paddingTop:'4px',float:'left'}}>
                                        {this.getRangePicker()}
                                    </div>
                                    <div style={{ width: '15%',float:'left'}}>
                                        <Form.Item label=" " colon={false}>
                                            <Button type="primary" htmlType="submit">
                                                对比
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                            {/**/}
                            <div style={{width: '18%',float:'left'}}>

                            </div>
                        </div>
                        {this.state.juder?
                            <div style={{widht:'100%',height:'85%',textAlign: 'center',backgroundColor:'rgba(222, 222, 222, 0.05)', paddingTop: '80px',
                                fontSize:'18px',  fontWeight: 'bold',color:'#b9b9b9'}} >
                                {this.state.echartTitle}
                            </div>
                            :
                            <div style={{widht:'100%',height:'85%'}}>
                                <Echart1
                                    dataType={this.state.echartData.type}
                                    elementById={'main'}// id
                                    head={this.state.echartData.head}// 日期
                                    data={this.state.echartData.data}// 数值
                                />
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        );
    }
}
