import React, { Component } from 'react'

import { Layout, Button, Tabs, Input, Col, Row, message, Modal, Select, Form } from 'antd'

import { ArrowLeftOutlined } from '@ant-design/icons';



import FormsTwo from '../../../components/common/forms/FormsTwo'


import {funTraceInfo} from '../../../services/common'
import { TYPE_NAME } from '../../../components/Const'

import { saveMp, getMpByName, getOne, getGasPropeertiesInCusId, getGasPropertiesAndDeviceInGasId } from '../../../services/meter'

const { TabPane } = Tabs;

const { Option } = Select;

const FormItem = Form.Item;

export default class AddPoint extends Component {

	state = {
		displayKey:false,
		// 表单参数
		formParamenter: {
			modal: true,
			number: 4,
			labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'id',
					str: '计量点代码',
					rules: TYPE_NAME.INPUT,
					disabled: true
				},
				{
					variableName: 'name',
					str: '计量点名称',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'environment',
					str: '用气环境',
					rules: TYPE_NAME.LABLE
				},
				{
					variableName: 'customerId',
					str: '客户号',
					rules: TYPE_NAME.ID_NUMBER,
					// disabled: this.props.location.state?.dataKey === '2' ? true : false
					disabled: true
				},
				{
					variableName: 'contractNumber',
					str: '合同号',
					rules: TYPE_NAME.LABLE_NOT_NULL
				},
				{
					variableName: 'installNumber',
					str: '安装号',
					rules: TYPE_NAME.LABLE_NOT_NULL_20
				},
				{
					variableName: 'province',
					str: '省',
					rules: TYPE_NAME.LABLE_NOT_NULL
				},
				{
					variableName: 'city',
					str: '市',
					rules: TYPE_NAME.LABLE_NOT_NULL
				},
				{
					variableName: 'district',
					str: '行政区域',
					rules: TYPE_NAME.NAME_NOT_NULL
				},
				{
					variableName: 'street',
					str: '街道',
					rules: TYPE_NAME.LABLE_NOT_NULL_20
				},
				{
					variableName: 'buildNum',
					str: '楼栋号',
					rules: TYPE_NAME.LABLE_20
				},
				{
					variableName: 'unitNum',
					str: '单元号',
					rules: TYPE_NAME.LABLE_20
				},
				{
					variableName: 'storeyNum',
					str: '楼层号',
					rules: TYPE_NAME.LABLE_20
				},
				{
					variableName: 'doorplateNum',
					str: '门牌号',
					rules: TYPE_NAME.LABLE_20
				},
				{
					variableName: 'address',
					str: '地址描述',
					rules: TYPE_NAME.LABLE_50
				},
			]

		},
		pointForm: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		pointFormValue: {},
		itemValue: null,
		data: {
			customerId: this.props.location.state?.customerId,
		},
		dataKey: this.props.location.state?.dataKey ?? '1',
		param: {
			properties: null,
			gasId: null,
			gasDevice: [
				{
					gasDeviceId: null,
					gasEquipment: null

				},

			]

		},
		properties: null,
		gasProperties: [],
		gasEquipment: [],
		gasDevice: [],
		isHour:null,
		tabsJuder:true
	};

	async componentDidMount() {
		await this.getGasAll();
		const { name, id } = this.props.location?.state ?? {};
		console.log(!!id);
		this.setState({tabsJuder:!!id});
		if (name) {
			// this.mpName?.value = name
			this.getData(id)
		}
	}

	// 点击用气性质时调用函数gasId
	onChangeSelect = (e, {children,ishour}) => {
		console.log(e);
		console.log(children);
		console.log(ishour);
		this.setState({ properties: children, isHour:ishour,displayKey:false});
		let value = {
			id: e
		};

		getGasPropertiesAndDeviceInGasId(value).then(
			response => {
				const { data } = response.data;
				let { param, pointForm ,gasDevice} = this.state;
				param.properties = children;
				param.gasId = e;
				pointForm.parameter = [];
				// data.map(one=>{
				// 	var params={
				// 		key:data.id,
				// 		value:data.name,
				// 	};
				// 	gasDevice.push(params);
				// });
				this.setState({
					param,
					gasDevice: data,
					gasEquipment: [],
					pointForm
				});

			}

		);
		let arr=this.state.gasProperties.filter(({id}) => id === e);
		let hour=arr[0]?.ishour;
		this.setState({isHour:hour});
	};

	getDevice=(e)=>{
		let value = {
			id: e
		};
		getGasPropertiesAndDeviceInGasId(value).then(
			response => {
				const { data } = response.data
				this.setState({
					gasDevice: data,
				});
			}
		)
	};

	getList = (e, a) => {
		let param = {
			id: e
		};
		getGasPropertiesAndDeviceInGasId(param).then(
			response => {
				let { data } = response.data;
				let { formParamenter, param } = this.state;
				formParamenter.parameter[6].data = this.getTreeData(data);
				param.properties = a;
				param.gasId = e;
				this.setState({
					formParamenter,
					param
				});
			}
		)
	};

	onChangeSelectList = (e, val) => {


		// let arr = val.map(({ key }) => key);
		let arr = [];
		// 循环获取选择的id
		for(var a=0;a<val?.length;a++){
			for(var i=0;i<this.state?.gasDevice?.length;i++){
				if(this.state?.gasDevice[i].name===val[a].children){
					arr.push(this.state?.gasDevice[i].id.toString());
				}
			}
		}
		console.log(arr);
		console.log('---------arr------------');
		this.setState({ gasEquipment: e });
		let list = val?.map(val => {
			var key='';
			for(var i=0;i<this.state?.gasDevice?.length;i++){
				if(this.state?.gasDevice[i].name===val?.children){
					key = this.state?.gasDevice[i].id.toString();
					break;
				}
			}
			return {
				// gasDeviceId: val?.key,
				gasDeviceId:key,
				gasEquipment: val?.children
			}
		});
		console.log(list);
		console.log('---------list------------');
		let {param,pointForm} =this.state;
		param.gasDevice=list
		this.setState({ param});
		let item = []
		val?.forEach(value => {
				if(this.state.isHour === 1){
					item.push(
						{
						variableName: 'consumption'+value.key,
						str: value?.children+"用气量",
						rules: TYPE_NAME.ID_NUMBER_10
						}
					)
				}
				item.push(
					{
						variableName: 'count'+value.key,
						str: value?.children+"数量",
						rules: TYPE_NAME.ID_NUMBER_10
					}
				)
			}
		);
		pointForm.parameter=item?.[0] ? item : null;
		this.setState({
			pointForm
		})

	};

	// 提交表单
	onSave = async () => {
		if(!this.state.properties){
			return this.setState({displayKey:true})
		}
		let value = await this.forms.onFinishNotReset();
		let	pointValue = await this.pointForm.onFinishNotReset();
		if (!value || !pointValue) return null;



		let counts = [], consumption = [];
		let gasArr = Object.values(pointValue);
		// 1: 有用气量 0：没有用气量
		if(this.state.isHour === 1){
			gasArr.forEach((value,ind) => {
				if(ind % 2 === 1) counts.push(value);
				else consumption.push(value)
			})
		}else{
			counts = gasArr
		}

		value.consumption = consumption;
		value.counts = counts;
		value.meterId = this.state.data?.meterId
		value.locationId = this.state.data?.locationId
		value.areaName = localStorage.getItem('areaName')
		value.mpAreaId = JSON.parse(localStorage.getItem('menus'))?.[0]
		value.gasPropertiesId = this.state.param.gasId;
		value.properties = this.state.param.properties;
		value.gasDevice = this.state.param.gasDevice;
		value.operator= JSON.parse(sessionStorage.getItem("user")).username;

		if(!value?.gasPropertiesId){
			console.log("111");
			console.log(value?.properties);
			this.state.gasProperties.map(one=>{
				console.log(one.name);
				if(one.name===value?.properties){
					console.log("333");
					value.gasPropertiesId=one.id;
				}
			});
		}else{
			console.log("222");
		}
		console.log(value);



		Modal.confirm({
			title: `是否绑定到${localStorage.getItem('areaName')}?`,
			onOk: () => {
				//新增计量点
				saveMp(value).then(
					response => {
						let { data } = response.data;
						if(response.data.responseInfo.code===200){
							if (data) {
								funTraceInfo("计量点信息管理", "新增(修改)计量点", null,value);
								message.success(value?.id ? "修改成功" : "新增成功")
								this.props.history.go(-1)
								// this.props.history.replace('/point/pointManagement')
							} else {
								message.error(data)
							}
						}else{
							message.error(data)
						}

					}
				)
			}
		})
	};

	// 清空表单
	clear = (e, info) => {
		let { formParamenter,pointForm } = this.state
		this.setState({ dataKey: e })
		if (e !== '2') {
			pointForm.parameter = []
			this.setState({ data: {},gasEquipment:[], properties: null,pointForm })
			setTimeout(() => {this.forms.onModalClose();this.pointForm.onModalClose()}, 0)
		}

		if (e === '2' && info === 'clear') {
			message.warn("修改时不允许清空")
		}

		formParamenter.parameter[3] = {
			variableName: 'customerId',
			str: '客户号',
			rules: TYPE_NAME.ID_NUMBER,
			disabled: e !== '2' ? false : true
		}
		this.setState({ formParamenter })
	}

	getData = (id) => {
		if (typeof id !== 'object') {
			getOne({ mpId: id }).then(
				response => {

					const { data } = response.data;
					let { param } = this.state;
					if(data?.customerId===null){
						console.log("1");
						message.info("该用气点存在多个客户，请联系系统管理员！");
						this.props.history.go(-1)
					}else{
						console.log("2");
					}
					let {pointForm,pointFormValue} = this.state;
					let item = [];
					let {gasDevice} = data;
					gasDevice?.forEach(value => {
							if(data?.ishour === 1){
								item.push(
									{
										variableName: 'consumption'+value?.gasDeviceId,
										str: value?.gasEquipment+"用气量",
										rules: TYPE_NAME.ID_NUMBER_10
									}
								);
								pointFormValue['consumption'+value?.gasDeviceId] = value.consumption
							}
							item.push(
								{
									variableName: 'count'+value?.gasDeviceId,
									str: value?.gasEquipment+"数量",
									rules: TYPE_NAME.ID_NUMBER_10
								}
							);
							pointFormValue['count'+value?.gasDeviceId] = value.counts
						}
					);
					pointForm.parameter=item?.[0] ? item : null;

					this.getDevice(data.gasPropertiesId);
					console.log('gasDevice');
					console.log(gasDevice);
					let arr = gasDevice?.map(({ gasEquipment }) => gasEquipment+'');
					console.log(arr);
					this.setState({
						data: data ,
						gasEquipment:arr,
						properties:data.properties,
						pointFormValue,
						pointForm,
						isHour: data.ishour
					});

					param.gasDevice = gasDevice;
					this.getList(data.gasPropertiesId, data.properties);
					this.forms.onModalClose();
					this.setState({ param });
				}
			)
		} else {
			let name = this.mpName.state?.value
			getMpByName({ name }).then(
				response => {
					const { data } = response?.data

					console.log("getMpByName");
					if (data?.length > 1) {
						message.warning('查询出多个计量点')
					} else if (data?.length === 1) {
						console.log(data?.[0])
						this.setState({ data: data?.[0] })
						message.success('查询成功')
						this.forms.onModalClose()
					} else {
						message.warning('未找到计量点')
					}
				}
			)
		}
	}

	//查询用气性质列表
	getGasAll = () => {
		let key = {
			name: this.props.location.state?.nature
		};
		getGasPropeertiesInCusId(key).then(
			response => {
				let { data } = response.data;

				this.setState({gasProperties: data});
			}
		)
	};

	//递归
	getTreeData = data => {
		let arr = data?.map(value => {
			return {
				key: value?.id,
				value: value?.name
			}
		})
		return arr
	}

	render() {
		const { dataKey, gasEquipment } = this.state
		return (
			<Layout className={'item_body_number_back'}>

				<div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回</span>
				</div>

				<div style={{ width: '100%', height: '100%', backgroundColor: '#ffffff', padding: '0 1%' }}>

					<Tabs defaultActiveKey={dataKey ?? "1"} onChange={e => this.clear(e)} tabPosition={'top'} centered={true} style={{ marginTop: '0.5vh' }}>
						<TabPane tab="新增计量点" key="1" disabled={this.state.tabsJuder}>
						</TabPane>
						<TabPane tab="修改计量点" key="2" disabled={!this.state.tabsJuder}>
							{/*<Row gutter={24} style={{ marginBottom: '1%' }}>*/}
							{/*	<Col span={6} offset={8}>*/}
							{/*		<Input ref={e => this.mpName = e} placeholder={'输入计量点名称'} />*/}
							{/*	</Col>*/}
							{/*	<Button type="primary" onClick={this.getData}>查询</Button>*/}
							{/*</Row>*/}
						</TabPane>
					</Tabs>

					<div>
						<Row gutter={24} style={{ marginBottom: '1%' }}>
							<Col span={8} offset={4}>
								<FormItem
									label={"用气性质"}
									// rules={TYPE_NAME.REQ_SELECT}
									rules={[{ required: true }]}
								>
									<Select
										value={this.state.properties}
										style={{ width: '100%',border:this.state.displayKey?'1px solid #f5222d':null}}
										placeholder={`请选择用气性质`}
										onChange={(e, a) => this.onChangeSelect(e, a)}
									>
										{
											this.state.gasProperties?.map(({ id, name }) => <Option key={id}>{name}</Option>)
										}
									</Select>
									<div style={{

										position:'absolute',
										top:'20%',
										left:'-20%',
										zIndex:'2',
										color:'red',
										fontSzie:'14px'
									}}>
										<span style={{color:'red'}}>*</span>
									</div>
									<div style={{
										display:this.state.displayKey?null:'none',
										height:'100%',
										width:'100%',
										position:'absolute',
										top:'32px',
										left:'0',
										zIndex:'2',
										color:'red',
										fontSzie:'14px'
									}}>
										<span >请选择用气性质</span>
									</div>
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem
									label={"用气设备"}
									rules={TYPE_NAME.REQ_SELECT}
								>
									<Select
										ref={e => this.multiple = e}
										allowClear
										mode={"multiple"}
										style={{ width: '100%' }}
										value={this.state.gasEquipment}
										placeholder={`请选择用气设备`}
										// options={this.state.gasDevice}
										onChange={(e, option,b) => this.onChangeSelectList(e, option)}
									>
										{
											this.state.gasDevice?.map(one=> <Option key={one.name} label={one.name}>{one.name}</Option>)
										}
									</Select>
								</FormItem>
							</Col>
						</Row>

						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.formParamenter}
							data={this.state.data}
						/>

						<FormsTwo
							ref={e => this.pointForm = e}
							formParamenter={this.state.pointForm}
							data={this.state.pointFormValue}
						/>
						<div className={'div_button_center'}>
							<Button type="primary" onClick={this.onSave}>保存</Button>
							&nbsp;&nbsp;&nbsp;
							<Button type="primary" onClick={() => this.clear(dataKey, "clear")}>清空</Button>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}
