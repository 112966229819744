import React, { Component } from 'react'
import {Layout, Button, Tabs, Modal, Select, Input, message, Popconfirm, Upload, Image} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {FORM_ITEM, TYPE_NAME} from "../../../components/Const";
import TableComponents from "../../../components/common/tables/TableComponentsNew";


import {
    addDeviceAlarm, bindDev,
    listDeviceModelByDeviceClassId,
    pageDeviceInfo,
    pageValveInfo, pageValveInfoNew, removeDev, updateBaseDevice,
    updateDevice
} from "../../../services/qrCode";
import {PlusSquareOutlined,RedoOutlined} from '@ant-design/icons';
import {NavLink} from "react-router-dom";
import moment from "moment";
import {funTraceInfo} from "../../../services/common";
const Option = Select.Option;
const { TabPane } = Tabs;
// 设备信息管理
export default class deviceDataInfo extends Component {
  state= {

      modalVisible:false,       // 开关-1
      modalVisible_1:false,     // 开关-1
      modalVisible_2:false,     // 开关-2
      modalVisible_3:false,     // 开关-3-压力表
      modalVisible_type_3:null, // 开关-3-状态
      modalVisible_4:false,     // 开关-4-监控
      modalVisible_5:false,     // 开关-5-阀井安装
      modalVisible_6:false,     // 开关-6-阀井查看
      modalVisible_7:false,     // 开关-7-调压箱
      inputError_2_communicateno:'',//error
      inputError_2_devModelName:'',//error
      inputError_2_manufacture:'',//error
      inputError_2_routeno:'',//error
      inputError_2_hdstno:'',//error
      inputError_2_devName:'',//error
      selectDisplay_1:'系统式',

      selectInput_2_newValue:[],  // 手学输入
      selectInput_2_record:[],    // 选择数据
      // 探测器数据
      topFormItem_1:{
          id:'',
          communicateno:'',// 设备通信号
          devModelName:'',// 设备型号
          manufacture:'', // 设备生产厂家
          routeno:'',     // 路号
          hdstno:'',      // 探测器编号
          devName:'',     // 探测器名称
      },
      // 探测器设备
      topFormItem_devModelName_data:[],

      // 搜索条件-控制器
      topFormItem: {
          modal: true,
          number: 4,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'devModelName', str: '控制器型号', rules: TYPE_NAME.INPUT,},
              {variableName: 'hdstno', str: '控制器编号', rules: TYPE_NAME.INPUT,},
              {variableName: 'manufacture', str: '供应商', rules: TYPE_NAME.INPUT,}
          ],
          searchGetData:{}
      },
      // 搜索条件-阀门
      topFormItem_2: {
          modal: true,
          number: 4,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'uniqueEncoding', str: '设备编号', rules: TYPE_NAME.INPUT,},
          ],
          searchGetData:{}
      },

      modalFormItemText:'创建',
      // 弹框-阀井-内容
      modalFormItem: {
          modal: true,
          number: 1,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'uniqueEncoding', str: '唯一编码', rules: TYPE_NAME.NOT_BLANK,},
              {onChange: e => this.onChangeSelect_1(e), variableName: 'devModelId', str: '阀井型号', rules: TYPE_NAME.REQ_SELECT, data:[]},
              {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
              {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: true,},
              {variableName: 'installPosition', str: '安装位置', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
          ],
          searchGetData:{}
      },
      modalFormItem_data:{},
      // 弹框-管钉-内容
      modalFormItem_1: {
          modal: true,
          number: 1,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'uniqueEncoding', str: '唯一编码', rules: TYPE_NAME.NOT_BLANK,},
              {onChange: e => this.onChangeSelect_2(e), variableName: 'devModelId', str: '管钉型号', rules: TYPE_NAME.REQ_SELECT, data:[]},
              {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
              {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: true,},
          ],
          searchGetData:{}
      },

      // 弹框-压力表-表单
      modalFormItem_2: {
          modal: true,
          number: 1,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'uniqueEncoding', str: '唯一编码', rules: TYPE_NAME.NOT_BLANK,},
              {onChange: e => this.onChangeSelect_3(e), variableName: 'devModelId', str: '型号', rules: TYPE_NAME.REQ_SELECT, data:[]},
              {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
              {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: true,},
          ],
          searchGetData:{}
      },
      modalFormItem_data_2:{},
      // 弹框-监控-表单
      modalFormItem_3: {
          modal: true,
          number: 1,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              // {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
              // {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
              // {variableName: 'devCode', str: '设备验证码', rules: TYPE_NAME.NOT_BLANK,},
              // {variableName: 'bindContacts', str: '客户联系人', rules: TYPE_NAME.NOT_BLANK,},
              // {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK},
              // {variableName: 'remark', str: '备注', rules: TYPE_NAME.TEXT_AREA,},

              {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
              // {variableName: 'uniqueEncoding', str: '唯一编码', rules: TYPE_NAME.NOT_BLANK,},
              {variableName: 'devCode', str: '设备验证码', rules: TYPE_NAME.NOT_BLANK,},
              // {onChange: e => this.onChangeSelect_1(e), variableName: 'devModelId', str: '型号', rules: TYPE_NAME.REQ_SELECT, data:[]},
              {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
              {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              // {variableName: 'installPosition', str: '安装位置', rules: TYPE_NAME.NAME_NOT_NULL,},
          ],
          searchGetData:{}
      },
      modalFormItem_data_3:{},
      // 控制器表格参数
      tableParamenter_1: {
          manageName: "SIM卡申请管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              hdstno: '控制器编号',
              communicateno: '控制器通信号',
              devModelName:'设备型号',
              devNumber:'下辖设备数',
              // manufactureTime: '生产日期',
              manufacture: '供应商',
              state: '状态',
              // status: '通信状态',
              comTime: '通信时间',
              // dataTime: '最后数据时间',
              edit: '操作',
          },

          rowData:[],
          params: {
              // deviceType:10196,
              deviceType:10196,
              page:this.props?.location?.state?.page||1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 探测器表格参数
      tableParamenter_2: {
          manageName: "SIM卡申请管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              devModelName:'设备型号',
              parentNo: '控制器编号',
              parentComNo: '控制器通信号',
              ikRouteNo:'探测器路号',
              hdstno:'探测器编号',
              state:'状态',
              // address: '安装点地址',
              installPosition: '安装位置',
              manufactureTime: '生产日期',

              edit: '操作',
          },
          // 弹出框
          headers: {
              devModelName:'设备型号',
              parentNo: '控制器编号',
              parentComNo: '控制器通信号',
              ikRouteNo:'探测器路号',
              hdstno:'探测器编号',
              // address: '安装点地址',

              installPosition: '安装位置',
              // comTime: '到期时间',
          },
          params: {
              // deviceType:10168,
              deviceType:10168,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 阀井表格参数
      tableParamenter_3: {
          manageName: "SIM卡申请管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              devModelName:'设备型号',
              // devType: '设备类型',
              serialno:'设备编号',
              manufacture: '生产厂家',
              state:'设备状态',
              // uniqueEncoding: '唯一编码',
              installPosition: '安装位置',
              comStatus: '通信状态',
              manufactureTime: '生产日期',
              systemTime: '通信时间',
              edit: '操作',
          },
          params: {
              // areaIds:[],
              devType:10200,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 家用报警器表格参数
      tableParamenter_4: {
          manageName: "SIM卡申请管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              devModelName:'设备型号',
              // devType: '设备类型',
              devName:'设备名',
              serialno:'设备编号',
              manufacture: '生产厂家',
              state:'设备状态',
              uniqueEncoding: '唯一编码',
              installPosition: '安装位置',
              comStatus: '通信状态',
              systemTime: '通信时间',
              edit: '操作',
          },
          params: {
              devType:10202,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 管钉表格参数
      tableParamenter_5: {
          manageName: "管钉管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              devModelName:'设备型号',
              // devType: '设备类型',
              devName:'设备名',
              serialno:'设备编号',
              manufacture: '生产厂家',
              state:'设备状态',
              uniqueEncoding: '唯一编码',
              installPosition: '安装位置',
              comStatus: '通信状态',
              systemTime: '通信时间',
              edit: '操作',
          },
          params: {
              devType:10210,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 压力表表格参数
      tableParamenter_6: {
          manageName: "压力表管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              devModelName:'设备型号',
              // devType: '设备类型',
              devName:'设备名',
              serialno:'设备编号',
              manufacture: '生产厂家',
              state:'设备状态',
              uniqueEncoding: '唯一编码',
              installPosition: '安装位置',
              comStatus: '通信状态',
              systemTime: '通信时间',
              edit: '操作',
          },
          params: {
              devType:10171,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 监控表表格参数
      tableParamenter_7: {
          manageName: " 监控表管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              // devModelName:'设备型号',
              // devType: '设备类型',
              devName:'设备名',
              serialno:'设备编号',
              manufacture: '生产厂家',
              state:'设备状态',
              uniqueEncoding: '唯一编码',
              installPosition: '安装位置',
              edit: '操作',
          },
          params: {
              devType:10209,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      addAndUpDataSelect:{},
      // 调压柜表格参数
      tableParamenter_8: {
          manageName: "SIM卡申请管理",
          // 表格行是否可选
          rowSelection: false,
          //下拉框长度
          selectWidth: '6.5vw',
          // 表头：变量和变量解释
          header: {
              devModelName:'设备型号',
              // devType: '设备类型',
              serialno:'设备编号',
              manufacture: '生产厂家',
              state:'设备状态',
              // uniqueEncoding: '唯一编码',
              installPosition: '安装位置',
              comStatus: '通信状态',
              manufactureTime: '生产日期',
              systemTime: '通信时间',
              edit: '操作',
          },
          params: {
              // areaIds:[],
              devType:10211,
              page:1,
              size:10,

          },
          // 是否绑定左侧区域
          bindArea: true,
      },
      // 弹窗-探测器
      formItem_2: {
          modal: true,
          number: 1,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'communicateno', str: '通信号', rules: TYPE_NAME.NOT_BLANK,disabled: true,},
              {variableName: 'devModelName', str: '设备型号', rules: FORM_ITEM.NOT_BLANK, disabled: true},
              {variableName: 'manufacture', str: '设备生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: true,},
              {variableName: 'routeno', str: '路号', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              {variableName: 'hdstno', str: '探测器编号', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              {variableName: 'devName', str: '探测器名称', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
          ]
      },
      formItem_2_data:null,
      // 弹窗-阀井安装
      formItem_3: {
          modal: true,
          number: 1,
          labelCol: 8,
          layout: "horizontal",
          parameter: [
              {variableName: 'installer', str: '安装人', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              {variableName: 'installerTel', str: '安装联系电话', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              {variableName: 'installPosition', str: '安装位置', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
              {variableName: 'installDate', str: '安装日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
          ]
      },
      formItem_3_data:{
          installer:JSON.parse(sessionStorage.getItem("user"))?.realName??null,
          installerTel:JSON.parse(sessionStorage.getItem("user"))?.phone??null
      },
      formItem_3_rowData:{},

      activeKey:this.props.location.state?.activeKey??'1',// 标签页


      fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
      fileList_1: [], // 设备照片
      fileList:[],
  };
  componentDidMount() {
      console.log(localStorage.getItem('menus'))
  }

    onChangeSelect_1=async(value, index) => {

        const {modalFormItem} = this.state;
        for(var i = 0; i<modalFormItem.parameter[3].data.length;i++){
            if(modalFormItem.parameter[3].data[i].devModelId===value){
                this.addAndUpdataForms.setFieldsValue('manufacture',modalFormItem.parameter[3].data[i].manufacture);

                modalFormItem.searchGetData.devModelName=modalFormItem.parameter[3].data[i].devModelName;
                modalFormItem.searchGetData.manufacture=modalFormItem.parameter[3].data[i].manufacture;
                this.setState({modalFormItem:modalFormItem});
            }
        }
    };
    onChangeSelect_2=async(value, index) => {

        const {modalFormItem_1} = this.state;
        for(var i = 0; i<modalFormItem_1.parameter[3].data.length;i++){
            if(modalFormItem_1.parameter[3].data[i].devModelId===value){
                this.pipeNail.setFieldsValue('manufacture',modalFormItem_1.parameter[3].data[i].manufacture);

                modalFormItem_1.searchGetData.devModelName=modalFormItem_1.parameter[3].data[i].devModelName;
                modalFormItem_1.searchGetData.manufacture=modalFormItem_1.parameter[3].data[i].manufacture;
                this.setState({modalFormItem_1:modalFormItem_1});
            }
        }
    };
    onChangeSelect_3=async(value, index) => {

        const {modalFormItem_2} = this.state;
        for(var i = 0; i<modalFormItem_2.parameter[3].data.length;i++){
            if(modalFormItem_2.parameter[3].data[i].devModelId===value){
                this.modalFormItem2.setFieldsValue('manufacture',modalFormItem_2.parameter[3].data[i].manufacture);
                modalFormItem_2.searchGetData.devModelName=modalFormItem_2.parameter[3].data[i].devModelName;
                modalFormItem_2.searchGetData.manufacture=modalFormItem_2.parameter[3].data[i].manufacture;

                this.setState({modalFormItem_2:modalFormItem_2});
            }
        }
    };
  listDeviceModelByDeviceClassId=(deviceClassId)=>{
      // 获取控制器型号数据
      listDeviceModelByDeviceClassId({deviceClassId : deviceClassId}).then(
          response=>{
              if(response?.data?.data){
                  var modalFormItem = this.state.modalFormItem;
                  modalFormItem.parameter[3].data=[];
                  var data=response.data.data;
                  for(var i=0;i<data.length;i++){
                      var parameter= data[i];
                      parameter.key=data[i].devModelId;
                      parameter.value=data[i].devModelName;
                      // parameter.value=data[i].devModelName,
                      modalFormItem.parameter[3].data.push(parameter)
                  }
                  this.setState({modalFormItem:modalFormItem});
              }else {
                  message.info('获取设备型号失败，请刷新页面')
              }
          }
      ).catch(error=>{
          message.info('异常错误')
      });
  };
    listDeviceModelByDeviceClassId_1=()=>{
        // 获取控制器型号数据
        listDeviceModelByDeviceClassId({deviceClassId : '10210'}).then(
            response=>{
                if(response?.data?.data){
                    var modalFormItem = this.state.modalFormItem_1;
                    modalFormItem.parameter[3].data=[];
                    var data=response.data.data;
                    for(var i=0;i<data.length;i++){
                        var parameter= data[i];
                        parameter.key=data[i].devModelId;
                        parameter.value=data[i].devModelName;
                        // parameter.value=data[i].devModelName,
                        modalFormItem.parameter[3].data.push(parameter)
                    }
                    this.setState({modalFormItem_1:modalFormItem});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });
    };
    listDeviceModelByDeviceClassId_2=(deviceClassId)=>{
        // 获取控制器型号数据
        listDeviceModelByDeviceClassId({deviceClassId : deviceClassId}).then(
            response=>{
                if(response?.data?.data){
                    var modalFormItem = this.state.modalFormItem_2;
                    modalFormItem.parameter[3].data=[];
                    var data=response.data.data;
                    for(var i=0;i<data.length;i++){
                        var parameter= data[i];
                        parameter.key=data[i].devModelId;
                        parameter.value=data[i].devModelName;
                        // parameter.value=data[i].devModelName,
                        modalFormItem.parameter[3].data.push(parameter)
                    }
                    this.setState({modalFormItem_2:modalFormItem});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });
    };
    listDeviceModelByDeviceClassId_3=(deviceClassId)=>{
        // 获取控制器型号数据
        listDeviceModelByDeviceClassId({deviceClassId : deviceClassId}).then(
            response=>{
                if(response?.data?.data){
                    var modalFormItem = this.state.modalFormItem_3;
                    modalFormItem.parameter[4].data=[];
                    var data=response.data.data;
                    for(var i=0;i<data.length;i++){
                        var parameter= data[i];
                        parameter.key=data[i].devModelId;
                        parameter.value=data[i].devModelName;
                        // parameter.value=data[i].devModelName,
                        modalFormItem.parameter[4].data.push(parameter)
                    }
                    this.setState({modalFormItem_3:modalFormItem});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });
    };
    // 条件选择：获取输入的条件
    getConditionsValue = async value => {
        let data = await this.forms.onFinishNotReset();
        var tableParamenter = this.state.tableParamenter_1;

        tableParamenter.params.devModelName=data.devModelName;
        tableParamenter.params.serialno=data.hdstno;
        tableParamenter.params.manufacture=data.manufacture;

        let menusStr = localStorage.getItem('menus');
        let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : [];
        tableParamenter.params=Object.assign(tableParamenter.params,{areaIds:menus,uniqueEncoding:data.uniqueEncoding || null});


        this.setState({tableParamenter_1:tableParamenter});
        console.log(tableParamenter);
        await this.table.refreshTable(tableParamenter.params);
    };
    getConditionsValue_1= async value => {
        let data = await this.forms3.onFinishNotReset();
        var tableParamenter = this.state.tableParamenter_3;
        let menusStr = localStorage.getItem('menus');
        let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : [];
        tableParamenter.params=Object.assign(tableParamenter.params,{areaIds:menus,uniqueEncoding:data.uniqueEncoding || null});
        // tableParamenter.params.uniqueEncoding=data.uniqueEncoding || null;
        this.setState({tableParamenter_3:tableParamenter});
        await this.table2.refreshTable(tableParamenter.params);
    };
    getConditionsValue_2= async value => {
        let data = await this.forms4.onFinishNotReset();
        var tableParamenter = this.state.tableParamenter_4;
        let menusStr = localStorage.getItem('menus');
        let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : []
        tableParamenter.params=Object.assign(tableParamenter.params,{areaIds:menus,uniqueEncoding:data.uniqueEncoding || null});
        // tableParamenter.params.uniqueEncoding=data.uniqueEncoding || null;
        this.setState({tableParamenter_4:tableParamenter});
        console.log(tableParamenter);
        await this.table3.refreshTable(tableParamenter.params);
    };
    getConditionsValue_3= async value => {
        let data = await this.forms5.onFinishNotReset();
        var tableParamenter = this.state.tableParamenter_5;
        let menusStr = localStorage.getItem('menus');
        let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : []
        tableParamenter.params=Object.assign(tableParamenter.params,{areaIds:menus,uniqueEncoding:data.uniqueEncoding || null});

        // tableParamenter.params.uniqueEncoding=data.uniqueEncoding || null;
        this.setState({tableParamenter_5:tableParamenter});
        await this.table4.refreshTable(tableParamenter.params);
    };
    getConditionsValue_4= async value => {
        let data = await this.forms3.onFinishNotReset();
        var tableParamenter = this.state.tableParamenter_8;
        let menusStr = localStorage.getItem('menus');
        let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : [];
        tableParamenter.params=Object.assign(tableParamenter.params,{areaIds:menus,uniqueEncoding:data.uniqueEncoding || null});
        // tableParamenter.params.uniqueEncoding=data.uniqueEncoding || null;
        this.setState({tableParamenter_8:tableParamenter});
        await this.table2.refreshTable(tableParamenter.params);
    };
    // 监控
    setTableValue_5=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'systemTime':
                var title_16=data?.data?data?.data[0]?.systemTime?moment(data.data[0]?.systemTime).format('YYYY-MM-DD HH:mm:ss') : '-': '-';
                return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除监控?"
                                    onConfirm={()=>{
                                        this.removeDev_2(data?.id,'10209')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 调压柜
    setTableValue_6=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'serialno':
                return (
                    <NavLink to={{pathname: "/deviceDataInfo/newPointManagement",state: {rowData: data}}}>
                        <div title={text}>
                            <u>{text}</u>
                        </div>
                    </NavLink>
                );
            case 'systemTime':
                var title_16=data?.data?data?.data[0]?.systemTime?moment(data.data[0]?.systemTime).format('YYYY-MM-DD HH:mm:ss') : '-': '-';
                return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;

            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async()=>{

                             if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域");}
                             Modal.confirm({
                                 title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                 onOk: async () => {
                                     this.listDeviceModelByDeviceClassId(10211);// 获取控制器型号数据
                                     var modalFormItem = this.state.modalFormItem;
                                     modalFormItem.searchGetData = Object.assign({},data);
                                     modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                                     await this.setState({
                                         modalFormItem:modalFormItem,
                                         modalVisible_7:true,
                                         modalFormItemText:'修改'});
                                     await this.addAndUpdataForms?.onModalClose();
                                     console.log(data?.manufactureTime)
                                 }})


                         }}
                    >
                        <u>{'修改'}</u>
                    </div>                  {/*安装*/}
                    <div className={'tableLink'} title={'安装'}>
                        <Popconfirm title={data?.state!=='已安装'?"是否安装当前阀井?":'是否查看已安装阀井'}
                                    onConfirm={ async ()=>{
                                        console.log('-------------------');
                                        console.log(data);
                                        await this.setState({
                                            formItem_3_rowData:data,// 所选行数据
                                            modalVisible_5:true,    // 弹窗
                                            device_juder:data?.state==='已安装'?true:false,     // 弹窗是否修改
                                            modalFormItemText:data?.serialno?"安装:"+data?.serialno:'安装',// 文本
                                            // 弹窗表单数据
                                            formItem_3_data:{
                                                installer:data?.installer??JSON.parse(sessionStorage.getItem("user"))?.realName??null,
                                                installerTel:data?.installerTel??JSON.parse(sessionStorage.getItem("user"))?.phone??null,
                                                installPosition:data?.installPosition??null,
                                                installDate:data?.installDate?moment(data?.installDate):null

                                            },
                                            fileList_1:data?.photos??[]  // 清空图片
                                        });
                                        await this.formItem3.onFinish();
                                    }}
                                    okText="是"
                                    cancelText="否"
                        >
                            <a>安装</a>
                        </Popconfirm>
                    </div>
                    {/*删除*/}
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除调压柜?"
                                    onConfirm={()=>{
                                        this.removeDev(data?.id,'10211')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }

    };
    // 压力表
    setTableValue_4=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'systemTime':
                var title_16=data?.data?data?.data[0]?.systemTime?moment(data.data[0]?.systemTime).format('YYYY-MM-DD HH:mm:ss') : '-': '-';
                return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async()=>{
                             if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
                                 return message.info("请选择区域");
                             }

                             Modal.confirm({
                                 title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                 onOk: async () => {
                                     this.listDeviceModelByDeviceClassId_2('10171');
                                     var modalFormItem = this.state.modalFormItem_2;
                                     modalFormItem.searchGetData=data;
                                     modalFormItem.parameter[3].disabled=true;
                                     modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                                     await this.setState({
                                         modalFormItem_2:modalFormItem,
                                         modalVisible_3:true,
                                         modalFormItemText:'修改压力表',
                                         modalFormItem_data_2:data
                                     });
                                     await this?.modalFormItem2?.onModalClose();
                                 }})

                         }}
                    >
                        <u>{'修改'}</u>
                    </div>

                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除压力表?"
                                    onConfirm={()=>{
                                        this.removeDev_1(data?.id,'10171')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };


    setTableValue_3=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'systemTime':
                var title_16=data?.data?data?.data[0]?.systemTime?moment(data.data[0]?.systemTime).format('YYYY-MM-DD HH:mm:ss') : '-': '-';
                return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async()=>{
                             if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
                                 return message.info("请选择区域");
                             }

                             Modal.confirm({
                                 title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                 onOk: async () => {
                                     this.listDeviceModelByDeviceClassId_1();
                                     var modalFormItem = this.state.modalFormItem_1;
                                     modalFormItem.searchGetData=data;
                                     modalFormItem.parameter[3].disabled=true;
                                     await this?.pipeNail?.onModalClose();
                                     modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                                     await this.setState({
                                         modalFormItem_1:modalFormItem,
                                         modalVisible_2:true,
                                         modalFormItemText:'修改'
                                     });
                                     await this?.pipeNail?.onModalClose();
                                 }})

                         }}
                    >
                        <u>{'修改'}</u>
                    </div>

                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除管钉?"
                                    onConfirm={()=>{
                                        this.removeDev_1(data?.id,'10210')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 阀井
    setTableValue_2=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'serialno':
                return (
                    <NavLink to={{pathname: "/deviceDataInfo/newPointManagement",state: {rowData: data}}}>
                        <div title={text}>
                            <u>{text}</u>
                        </div>
                    </NavLink>
                );
            case 'systemTime':
                var title_16=data?.data?data?.data[0]?.systemTime?moment(data.data[0]?.systemTime).format('YYYY-MM-DD HH:mm:ss') : '-': '-';
                return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;

            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async()=>{

                             if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域");}
                             Modal.confirm({
                                 title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                 onOk: async () => {
                                     this.listDeviceModelByDeviceClassId(10200);// 获取控制器型号数据
                                     var modalFormItem = this.state.modalFormItem;
                                     modalFormItem.searchGetData = Object.assign({},data);
                                     modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                                     await this.setState({
                                         modalFormItem:modalFormItem,
                                         modalVisible_1:true,
                                         modalFormItemText:'修改'});
                                     await this.addAndUpdataForms?.onModalClose();
                                     console.log(data?.manufactureTime)
                                 }})


                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    {/*安装*/}
                    <div className={'tableLink'} title={'安装'}>
                        <Popconfirm title={data?.state!=='已安装'?"是否安装当前阀井?":'是否查看已安装阀井'}
                                    onConfirm={ async ()=>{
                                        console.log('-------------------');
                                        console.log(data);
                                        await this.setState({
                                            formItem_3_rowData:data,// 所选行数据
                                            modalVisible_5:true,    // 弹窗
                                            device_juder:data?.state==='已安装'?true:false,     // 弹窗是否修改
                                            modalFormItemText:data?.serialno?"安装:"+data?.serialno:'安装',// 文本
                                            // 弹窗表单数据
                                            formItem_3_data:{
                                                installer:data?.installer??JSON.parse(sessionStorage.getItem("user"))?.realName??null,
                                                installerTel:data?.installerTel??JSON.parse(sessionStorage.getItem("user"))?.phone??null,
                                                installPosition:data?.installPosition??null,
                                                installDate:data?.installDate?moment(data?.installDate):null

                                            },
                                            fileList_1:data?.photos??[]  // 清空图片
                                        });
                                        await this.formItem3.onFinish();
                                    }}
                                    okText="是"
                                    cancelText="否"
                        >
                            <a>安装</a>
                        </Popconfirm>
                    </div>
                    {/* 安装--查看*/}
                    {/*<div className={'tableLink'} title={'查看'} style={{display:data?.state==='已安装'?null:'none'}}*/}
                    {/*     onClick={async()=>{*/}
                    {/*         this.setState({*/}
                    {/*             fileList:data?.photos??[],*/}
                    {/*             modalVisible_6:true,*/}
                    {/*             modalFormItemText:'查看',*/}
                    {/*         });*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <u>{'查看'}</u>*/}
                    {/*</div>*/}


                    {/*删除*/}
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除阀井?"
                                    onConfirm={()=>{
                                        this.removeDev(data?.id,'10202')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }

    };
    setTableValue_1=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'address':
                return data.address.address || '-';
            case 'comTime':
                return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async ()=>{
                             console.log('data');
                             console.log(data);
                             var formData={
                                 id:data.id??null,
                                 communicateno:data?.communicateno??null,    // 通信号
                                 devModelName:data?.devModelName??null,    // 设备型号
                                 manufacture:data?.manufacture??null,   // 设备生产厂家
                                 routeno:data?.ikRouteNo??null,    // 路号
                                 hdstno:data?.hdstno??null,    // 探测器编号
                                 devName:data?.devName??null,    // 探测器编号
                                 manufactureTime:data?.manufactureTime?moment(data.manufactureTime):null,    // 生产日期
                             };


                             await this.setState({
                                 modalVisible:true,
                                 formItem_2_data:formData,
                             });
                             this.formItem2.onFinish();
                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除探测器?"
                                    onConfirm={()=>{
                                        this.removeDev1(data?.id,'10168')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }

    };

    setTableValue = (value,text,data,) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'manufactureTime':
                return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
            case 'comTime':
                // var title =data?.childrenList[0]?.communicateno??'-';
                // return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
                // return data?.childrenList[0]?.comTime??'-';
            case 'dataTime':
                return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={()=>{
                             console.log(this.state.tableParamenter_1.params.page);
                             this.props.history.push({
                                 pathname: './details/addDevicePage',
                                 rowData: data,
                                 page:this.state.tableParamenter_1.params.page
                             })
                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除控制器?"
                                    onConfirm={()=>{
                                        this.removeDev2(data?.id,'10196')
                                    }}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    onChange=(key)=>{
        console.log(key)
    };
    onChangeSelect = (value, index) => {
        const { selectInput_2_record } = this.state;
        console.log('===============点击的value', value);
        selectInput_2_record[index] = value || '';
        this.setState({selectInput_2_record});
    };

    onSearchSelect = (value, index) => {
        var {selectInput_2_newValue } = this.state;
        selectInput_2_newValue=[];
        if (!!value) {
            selectInput_2_newValue.push(value || '');
            this.setState({selectInput_2_newValue });
        }
    };

    onBlurSelect = (index) => {
        const { selectInput_2_newValue } = this.state;
        const value = selectInput_2_newValue[index];
        console.log('===============输入的value', value);
        if (!!value) {
            this.onChangeSelect(value, index);
            delete selectInput_2_newValue[index]; // 在onBlur后将对应的key删除，防止当从下拉框中选择后再次触发onBlur时经过此处恢复成原来的值
        }
    };


    // 修改探测器
    upDetector = async value =>{
        var parameter = await this.formItem2.onFinishNotReset();
        console.log(parameter);
        this.setState({modalVisible:false});
        //
        var {formItem_2_data,tableParamenter_2} = this.state;
        // topFormItem_1.devModelName=this.state.selectInput_2_record[0];
        parameter.id=formItem_2_data.id;
        parameter.deviceType=10168;
        updateDevice(parameter).then(
            response=>{
                if(response.data.data){
                    // funTraceInfo("二维码管理", "修改探测器", null,topFormItem_1);
                    message.info("修改成功")
                }else{
                    message.info("修改失败")
                }
                this.table1.refreshTable();
            }
        );

    };

// 添加和修改阀门
    addAndUpdata= async (value) =>{
        // 获取表单数据，并且检验
        let data = await this.addAndUpdataForms.onFinish();
        if(!data) return false;
        var modalFormItem = this.state.modalFormItem;

        data = Object.assign(
            {
                id:modalFormItem.searchGetData?.id,
                devModelName:modalFormItem.searchGetData.devModelName,
                manufacture:modalFormItem.searchGetData.manufacture,
                devType:10200,
                areaId:JSON.parse(localStorage.getItem('menus'))?.[0]
            },
            data);
        data.manufactureTime =data?.manufactureTime? moment(data.manufactureTime).format('YYYY-MM-DD'):null;

        // 清空表单数据
        modalFormItem.searchGetData={};

        console.log(data);
        this.setState({modalVisible_1:false,modalFormItem:modalFormItem});
        addDeviceAlarm(data).then(
            response=>{
                console.log(response);
                if(response.data.data){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table2.refreshTable();
                }else if(response?.data?.responseInfo?.code===200){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table2.refreshTable();
                } else{
                    message.info(this.state.modalFormItemText+'失败,'+response.data.responseInfo.description)
                }
            }
        )
    };
    // 添加和修改管钉
    addAndUpdata_1= async value =>{
        // 获取表单数据，并且检验
        let data = await this.pipeNail.onFinish();
        if(!data) return false;
        var modalFormItem = this.state.modalFormItem_1;
        data = Object.assign(
            {
                id:modalFormItem.searchGetData?.id,
                devModelName:modalFormItem.searchGetData.devModelName,
                manufacture:modalFormItem.searchGetData.manufacture,
                devType:10210,
                areaId:JSON.parse(localStorage.getItem('menus'))?.[0]
            },
            data);
        data.manufactureTime =data?.manufactureTime? moment(data.manufactureTime).format('YYYY-MM-DD'):null;

        // 清空表单数据
        modalFormItem.searchGetData={};
        this.setState({modalVisible_2:false,modalFormItem:modalFormItem});
        addDeviceAlarm(data).then(
            response=>{
                console.log(response);
                if(response.data.data){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table4.refreshTable()
                }else if(response?.data?.responseInfo?.code===200){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table4.refreshTable()
                } else{
                    message.info(this.state.modalFormItemText+'失败,'+response.data.responseInfo.description)
                }
            }
        )
    };
    // 添加和修改压力表
    addAndUpdata_2= async value =>{
        // 获取表单数据，并且检验
        let param = await this.modalFormItem2.onFinishNotReset();
        if(!param) return false;
        var modalFormItem = this.state.modalFormItem_2;
        param = Object.assign(
            {},
            param,
            {
                id:modalFormItem.searchGetData?.id??null,
                devType:this.state.modalVisible_type_3,
                devModelName:modalFormItem.searchGetData?.devModelName??null,
                areaId:JSON.parse(localStorage.getItem('menus'))?.[0]
            }
            );
        param.manufactureTime = moment(param.manufactureTime).format('YYYY-MM-DD');

        addDeviceAlarm(param).then(
            response=>{
                if(response.data.data){
                    message.info(this.state.modalFormItemText+'成功');
                    modalFormItem.searchGetData={};
                    this.setState({modalVisible_3:false,modalFormItem:modalFormItem,modalFormItem_data_2:{}});
                    return this.table5.refreshTable()
                }else if(response?.data?.responseInfo?.code===200){
                    console.log('2222222222');
                    message.info(this.state.modalFormItemText+'成功');
                    modalFormItem.searchGetData={};
                    this.setState({modalVisible_3:false,modalFormItem:modalFormItem,modalFormItem_data_2:{}});
                    return this.table5.refreshTable()
                } else{
                    console.log('33333333333');
                    return message.info(this.state.modalFormItemText+'失败,'+response.data.responseInfo.description)
                }
            }
        )
    };
// 添加和修改调压箱
    addAndUpdata_3= async (value) =>{
        // 获取表单数据，并且检验
        let data = await this.addAndUpdataForms.onFinish();
        if(!data) return false;
        var modalFormItem = this.state.modalFormItem;

        data = Object.assign(
            {
                id:modalFormItem.searchGetData?.id,
                devModelName:modalFormItem.searchGetData.devModelName,
                manufacture:modalFormItem.searchGetData.manufacture,
                devType:10211,
                areaId:JSON.parse(localStorage.getItem('menus'))?.[0]
            },
            data);
        data.manufactureTime =data?.manufactureTime? moment(data.manufactureTime).format('YYYY-MM-DD'):null;

        // 清空表单数据
        modalFormItem.searchGetData={};

        console.log(data);
        this.setState({modalVisible_7:false,modalFormItem:modalFormItem});
        addDeviceAlarm(data).then(
            response=>{
                console.log(response);
                if(response.data.data){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table2.refreshTable();
                }else if(response?.data?.responseInfo?.code===200){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table2.refreshTable();
                } else{
                    message.info(this.state.modalFormItemText+'失败,'+response.data.responseInfo.description)
                }
            }
        )
    };
    // 删除阀井
    removeDev=(devId,devType)=>{
        console.log(devId);
        console.log(devType);
        removeDev({devId:devId,devType:devType}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                   message.info('删除成功');

                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table2.refreshTable();
            }
        )

    };
    // 删除管钉
    removeDev_1=(devId,devType)=>{
        removeDev({devId:devId,devType:devType}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    message.info('删除成功');
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table4.refreshTable();
            }
        )

    };
    // 删除监控
    removeDev_2=(devId,devType)=>{
        removeDev({devId:devId,devType:devType}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功');
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table5.refreshTable();
            }
        )

    };
    // 删除探测器
    removeDev1=(devId,devType)=>{
        removeDev({devId:devId,devType:devType}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功')
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table1.refreshTable();
            }
        )

    };
    // 删除控制器
    removeDev2=(devId,devType)=>{
        console.log(devId);
        console.log(devType);
        removeDev({devId:devId,devType:devType}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    message.info('删除成功')
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table.refreshTable();
            }
        )

    };

    // 弹窗-阀门
    clearData =async value=> {

        var modalFormItem = this.state.modalFormItem;
        modalFormItem.searchGetData={};
        this.addAndUpdataForms.onModalClose();
        this.setState({modalFormItem:modalFormItem,modalVisible_1:false,modalVisible_7:false});
    };
    // 弹窗-管钉
    clearData_1 =async value=> {

        var modalFormItem = this.state.modalFormItem_1;
        modalFormItem.searchGetData={};
        this.pipeNail.onModalClose();
        this.setState({modalFormItem_1:modalFormItem,modalVisible_2:false});
    };
    // 弹窗-压力表
    clearData_2 =async value=> {

        var modalFormItem = this.state.modalFormItem_2;
        modalFormItem.searchGetData={};
        this.modalFormItem2.onModalClose();
        this.setState({modalFormItem_2:modalFormItem,modalVisible_3:false});
    };
    // 新增监控设备
    addDev_2= async () =>{
        var params = await this.modalFormItem3.onFinishNotReset();
        if(!params){return false;}

        let user = JSON.parse(sessionStorage.getItem("user"));
        params= Object.assign({} ,params,{"devType": '10209',});
        params.manufactureTime = moment(params.manufactureTime).format('YYYY-MM-DD');
        console.log(params);
        this.setState({modalVisible_4:false})
        addDeviceAlarm(params).then(
            response=>{
                console.log(response);
                if(response.data.data){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table5.refreshTable()
                }else if(response?.data?.responseInfo?.code===200){
                    message.info(this.state.modalFormItemText+'成功');
                    this.table5.refreshTable()
                } else{
                    message.info(this.state.modalFormItemText+'失败,'+response.data.responseInfo.description)
                }
            }
        )

    };


    // 弹窗设置
    handlePreview = (file) => {
        console.log('弹窗设置');
        console.log(file);







        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };
    onChange_1 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_1:file.fileList})
    };

    // 安装点类型-安装设备
    bindDev= async () =>{
        var rowData = this.state.formItem_3_rowData;
        var params = await this.formItem3.onFinishNotReset();
        if(!params){return false;}


        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
            return message.info("请选择区域");
        }

        params=Object.assign(
            {
                id:rowData?.id??null,
                devId:rowData?.devId??null,          // device服务主键id
                devType:rowData?.devType??null,      // 设备类型
                hdstno:rowData?.hdstno??null,        // 厂家编号
                serialno:rowData?.serialno??null,    // 序列号/设备编号


                regionManagementId:JSON.parse(localStorage.getItem('menus'))?.[0]??null,
                name:localStorage.getItem('areaName')??null,
                locationType:'区域',                 // 安装点类型
                photos:[]
            },
            params
        );
        // params=Object.assign({photos:[]},params);

        // 处理照片
        this.state.fileList_1.map(one=>{
            var photo1=
                {
                    'devId':rowData?.devId??null,
                    "devType": 10200,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": one?.url??one?.response
                };
            params.photos.push(photo1)
        });

        params.installDate=params?.installDate?moment(params?.installDate).format('YYYY-MM-DD'):null;

        this.setState({modalVisible_5:false});
        bindDev(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('安装成功')
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table2.refreshTable();
            }
        )
    };

    // 修改阀警设备
    updateBaseDevice = async () =>{

        var rowData = this.state.formItem_3_rowData;
        var params = await this.formItem3.onFinishNotReset();

        if(!params){return false;}
        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
            return message.info("请选择区域");
        }
        params=Object.assign(
            {
                id:rowData?.id??null,
                devId:rowData?.devId??null,          // device服务主键id
                devType:rowData?.devType??null,      // 设备类型
                hdstno:rowData?.hdstno??null,        // 厂家编号
                serialno:rowData?.serialno??null,    // 序列号/设备编号

                regionManagementId:JSON.parse(localStorage.getItem('menus'))?.[0]??null,
                name:localStorage.getItem('areaName')??null,
                locationType:'区域',                 // 安装点类型

            },
            params
        );
        params=Object.assign({photos:[]},params);
        console.log(this.state.fileList_1);
        // 处理照片
        this.state.fileList_1.map(one=>{
            var photo1=
                {
                    'devId':rowData?.devId??null,
                    "devType": 10200,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": one?.url??one?.response
                };
            params.photos.push(photo1)
        });

        params.installDate=params?.installDate?moment(params?.installDate).format('YYYY-MM-DD'):null;
        console.log(params)
        this.setState({modalVisible_5:false});
        updateBaseDevice(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('修改成功')
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table2.refreshTable();
            }
        );

    };


    render() {
        const { previewVisible, previewImage} = this.state;
      const uploadButton = (
          <div>
              {/*<Icon type="plus" />*/}
              <div className="ant-upload-text">上传安装图片</div>
          </div>
      );
    return (
        <Layout className={'item_body_number_back tables'}>
            <div>
                <div className={'topSearchBox'}>
                    <h3>设备信息管理</h3>

                </div>
                {/*---------------------------------分割线------------------------------------*/}
                <div style={{'backgroundColor': '#ffffff'}}>



                    <div id={'video-container'}/>
                    <div>
                        <Tabs
                            tabPosition={"top"}
                            // animated={true}
                            activeKey={this.state.activeKey}
                            onTabClick={(key)=>{this.setState({activeKey:key})}}
                        >
{/*报警控制器---------------------------------------------------------------------------------------------------------*/}
                            <TabPane tab="报警控制器" key="1">
                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'90%'}}>
                                        <FormsTwo
                                            ref={e => this.forms = e}
                                            formParamenter={this.state.topFormItem}
                                            data={this.state.topFormItem.searchGetData}
                                        />
                                        <Button style={{position:'absolute', right:'10px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                                    </div>
                                </div>
                                <div className={'topBtnBox'} style={{"pointer-events": this.state.pointer_1}}>
                                    <NavLink
                                        to={{
                                            pathname: "./details/addDevicePage",
                                            state: {text: 'rowData.orderId'}
                                        }}
                                    >
                                        <span className={'addLink'} ><PlusSquareOutlined />创建新设备</span>
                                    </NavLink>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={async()=>{
                                              await this.table.refreshTable();
                                          }} >
                                            <RedoOutlined />
                                            刷新
                                        </span>
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_1}
                                            setData = {pageDeviceInfo}
                                            setTableValue={this.setTableValue}
                                            ref={e => this.table = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>
{/*报警探测器---------------------------------------------------------------------------------------------------------*/}
                            <TabPane tab="报警探测器" key="2">
                                <div className={'topBtnBox'} style={{width:'100%',height:'35px',}}>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={async()=>{
                                              await this.table1.refreshTable();
                                          }} >
                                            <RedoOutlined />
                                            刷新
                                        </span>
                                </div>
                                <div style={{width:'100%',padding:'0 0 0 0',background:'#fff'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_2}
                                            setData = {pageDeviceInfo}
                                            setTableValue={this.setTableValue_1}
                                            ref={e => this.table1 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>
{/*阀井---------------------------------------------------------------------------------------------------------------*/}
                            <TabPane tab="阀井" key="3">
                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'90%'}}>
                                        <FormsTwo
                                            ref={e => this.forms3 = e}
                                            formParamenter={this.state.topFormItem_2}
                                            data={this.state.topFormItem_2.searchGetData}
                                        />
                                        <Button style={{position:'absolute', right:'10px',top:'0'}} type="primary"
                                                onClick={() => this.getConditionsValue_1()}>
                                            查询
                                        </Button>
                                    </div>
                                </div>

                                {/*-*/}
                                <div className={'topBtnBox'}>
                                    <span className={'addLink'}
                                          onClick={()=>{
                                              if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
                                                  return message.info("请选择区域");
                                              }
                                              Modal.confirm({
                                                      title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                                      onOk: () => {
                                                          this.listDeviceModelByDeviceClassId(10200);
                                                          this.setState({modalVisible_1:true,modalFormItemText:'创建'});
                                                      }})


                                          }} >
                                            <PlusSquareOutlined />创建新阀井
                                    </span>

                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={()=>{this.table2.refreshTable();}} >
                                        <RedoOutlined />刷新
                                    </span>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={()=>{
                                              var href = window.location.href;
                                              var port = window.location.port;
                                              sessionStorage.setItem("selectMode", JSON.stringify({a:'阀井',b:10200}));
                                              window.open((href.substr(0,href.indexOf(port))+port)+"/deviceModelMap");
                                          }} >
                                        地图模式
                                    </span>
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff',width:'100%'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_3}
                                            setData = {pageValveInfo}
                                            setTableValue={this.setTableValue_2}
                                            ref={e => this.table2 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>

{/*家用报警---------------------------------------------------------------------------------------------------------------*/}
                            <TabPane tab="家用报警" key="4">
                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'90%'}}>
                                        <FormsTwo
                                            ref={e => this.forms4 = e}
                                            formParamenter={this.state.topFormItem_2}
                                            data={this.state.topFormItem_2.searchGetData}
                                        />
                                        <Button style={{position:'absolute', right:'10px',top:'0'}} type="primary"
                                                onClick={() => this.getConditionsValue_2()}>
                                            查询
                                        </Button>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"25px"}}>
                                    <a style={{float: 'right',paddingRight:'10px'}}
                                          onClick={async()=>{await this.table3.refreshTable();}} >
                                        <RedoOutlined />刷新
                                    </a>
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff',width:'100%'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_4}
                                            setData = {pageValveInfo}
                                            setTableValue={this.setTableValue_2}
                                            ref={e => this.table3 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>

{/*管钉---------------------------------------------------------------------------------------------------------------*/}
                            <TabPane tab="管钉" key="5">
                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'90%'}}>
                                        <FormsTwo
                                            ref={e => this.forms5 = e}
                                            formParamenter={this.state.topFormItem_2}
                                            data={this.state.topFormItem_2.searchGetData}
                                        />
                                        <Button style={{position:'absolute', right:'10px',top:'0'}} type="primary"
                                                onClick={() => this.getConditionsValue_3()}>
                                            查询
                                        </Button>
                                    </div>
                                </div>
                                <div className={'topBtnBox'}>
                                    <span className={'addLink'}
                                          onClick={async ()=>{
                                              if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
                                                  return message.info("请选择区域");
                                              }

                                              Modal.confirm({
                                                  title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                                  onOk:async () => {
                                                      this.listDeviceModelByDeviceClassId_1();
                                                      var modalFormItem = this.state.modalFormItem_1;
                                                      modalFormItem.parameter[3].disabled=false;
                                                      modalFormItem.searchGetData={};
                                                      await this.setState({
                                                          modalFormItem_1:modalFormItem,
                                                          modalVisible_2:true,
                                                          modalFormItemText:'创建'
                                                      });
                                                      this.pipeNail?.onModalClose();
                                                  }});




                                          }} >
                                            <PlusSquareOutlined />创建新管钉
                                    </span>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={async()=>{await this.table4.refreshTable();}} ><RedoOutlined />
                                          刷新</span>
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff',width:'100%'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_5}
                                            setData = {pageValveInfo}
                                            setTableValue={this.setTableValue_3}
                                            ref={e => this.table4 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>
{/*压力表---------------------------------------------------------------------------------------------------------------*/}
                            <TabPane tab="压力表" key="6">
                                <div className={'topBtnBox'}>
                                    <span className={'addLink'}
                                          onClick={async ()=>{
                                              if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
                                                  return message.info("请选择区域");
                                              }

                                              Modal.confirm({
                                                  title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                                  onOk:async () => {
                                                      this.listDeviceModelByDeviceClassId_2('10171');
                                                      var modalFormItem = this.state.modalFormItem_2;
                                                      modalFormItem.parameter[3].disabled=false;
                                                      modalFormItem.searchGetData={};
                                                      await this.setState({
                                                          modalFormItem_2:modalFormItem,
                                                          modalVisible_3:true,
                                                          modalFormItemText:'创建压力表',
                                                          modalVisible_type_3:'10171',
                                                          modalFormItem_data_2:{}
                                                      });
                                                      this.modalFormItem2?.onModalClose();
                                                  }});



                                          }} >
                                            <PlusSquareOutlined />创建新压力表
                                    </span>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={async()=>{await this.table4.refreshTable();}} >
                                        <RedoOutlined />刷新
                                    </span>
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff',width:'100%'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_6}
                                            setData = {pageValveInfo}
                                            setTableValue={this.setTableValue_4}
                                            ref={e => this.table5 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>
{/*监控-----------------------*/}
                            <TabPane tab="监控" key="7">
                                <div className={'topBtnBox'}>
                                    <span className={'addLink'}
                                          onClick={async ()=>{
                                              if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){
                                                  return message.info("请选择区域");
                                              }

                                              Modal.confirm({
                                                  title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                                  onOk:async () => {
                                                      var modalFormItem = this.state.modalFormItem_3;
                                                      modalFormItem.searchGetData={};
                                                      await this.setState({
                                                          modalFormItem_3:modalFormItem,
                                                          modalVisible_4:true,
                                                          modalFormItemText:'创建监控',
                                                          modalVisible_type_3:'10209',
                                                          modalFormItem_data_3:{}
                                                      });
                                                      this.modalFormItem3?.onModalClose();
                                                  }});
                                          }} >
                                            <PlusSquareOutlined />创建监控
                                    </span>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={async()=>{await this.table5.refreshTable();}} >
                                        <RedoOutlined />刷新
                                    </span>
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff',width:'100%'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_7}
                                            setData = {pageValveInfo}
                                            setTableValue={this.setTableValue_5}
                                            ref={e => this.table5 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>
{/*调压柜-----------------------*/}
                            <TabPane tab="调压柜" key="8">
                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'90%'}}>
                                        <FormsTwo
                                            ref={e => this.forms3 = e}
                                            formParamenter={this.state.topFormItem_2}
                                            data={this.state.topFormItem_2.searchGetData}
                                        />
                                        <Button style={{position:'absolute', right:'10px',top:'0'}} type="primary"
                                                onClick={() => this.getConditionsValue_4()}>
                                            查询
                                        </Button>
                                    </div>
                                </div>

                                {/*-*/}
                                <div className={'topBtnBox'}>
                                    <span className={'addLink'}
                                          onClick={  ()=>{
                                              if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域");}
                                              Modal.confirm({
                                                  title: `是否绑定到${localStorage.getItem('areaName')}?`,
                                                  onOk:async () => {
                                                      this.listDeviceModelByDeviceClassId(10211);
                                                      await this.setState({
                                                          modalVisible_7:true,
                                                          modalFormItemText:'创建'
                                                      });
                                                      this.addAndUpdataForms.onModalClose();
                                                  }})


                                          }} >
                                            <PlusSquareOutlined />创建新调压柜
                                    </span>

                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={()=>{this.table2.refreshTable();}} >
                                        <RedoOutlined />刷新
                                    </span>
                                    <span className={'addLink'} style={{float: 'right',right:'0px'}}
                                          onClick={()=>{
                                              var href = window.location.href;
                                              var port = window.location.port;
                                              sessionStorage.setItem("selectMode", JSON.stringify({a:'调压柜',b:10211}));
                                              window.open((href.substr(0,href.indexOf(port))+port)+"/deviceModelMap");
                                          }} >
                                        地图模式
                                    </span>
                                    {/*<span className={'addLink'} style={{float: 'right',right:'0px'}}*/}
                                    {/*      onClick={()=>{*/}
                                    {/*          var href = window.location.href;*/}
                                    {/*          var port = window.location.port;*/}
                                    {/*          sessionStorage.setItem("pageDeviceInfo", JSON.stringify(this.table2.state.params));*/}
                                    {/*          window.open((href.substr(0,href.indexOf(port))+port)+"/deviceModelMap");*/}
                                    {/*      }} >*/}
                                    {/*    地图模式*/}
                                    {/*</span>*/}
                                </div>
                                <div style={{padding:'0 0 0 0',background:'#fff',width:'100%'}}>
                                    <div>
                                        <TableComponents
                                            paramenter={this.state.tableParamenter_8}
                                            setData = {pageValveInfo}
                                            setTableValue={this.setTableValue_6}
                                            ref={e => this.table2 = e}
                                        >
                                        </TableComponents>
                                    </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div>
{/*弹出窗口----创建探测器1------------------------------*/}
                <Modal
                    title={'修改探测器'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible}
                    okText="确定"
                    onOk={() => this.upDetector()}           // 点击确定
                    onCancel={() => this.setState({modalVisible:false})}       // 点击取消
                    width={800}
                    centered
                >
                    <div style={{"width":"70%","margin-left":"80px"}}>
                        <FormsTwo
                            ref={e => this.formItem2 = e}
                            formParamenter={this.state.formItem_2}
                            data={this.state.formItem_2_data}
                        />
                    </div>
                </Modal>
{/*弹出窗口-2-阀井-----*/}
                <Modal
                    title={this.state.modalFormItemText+'阀井'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_1}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => this.addAndUpdata()}
                    // 点击取消
                    onCancel={()=> {
                        this.clearData();
                    }}
                    width={800}
                    centered
                >
                    <div style={{width:'80%', marginLeft: '30px',height:'350px' }}>
                        <FormsTwo
                            ref={e => this.addAndUpdataForms = e}
                            formParamenter={this.state.modalFormItem}
                            data={this.state.modalFormItem.searchGetData}
                        />

                    </div>
                </Modal>
{/*弹出窗口-2--管钉---*/}
                <Modal
                    title={this.state.modalFormItemText+'管钉'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_2}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => this.addAndUpdata_1()}
                    // 点击取消
                    onCancel={()=> {
                        this.clearData_1();
                    }}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'320px' }}>
                        <FormsTwo
                            ref={e => this.pipeNail = e}
                            formParamenter={this.state.modalFormItem_1}
                            data={this.state.modalFormItem_1.searchGetData}
                        />
                    </div>
                </Modal>
 {/*弹出窗口-3--压力表---*/}
                <Modal
                    title={this.state.modalFormItemText}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_3}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => this.addAndUpdata_2()}
                    // 点击取消
                    onCancel={()=> {
                        this.clearData_2();
                    }}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'320px' }}>
                        <FormsTwo
                            ref={e => this.modalFormItem2 = e}
                            formParamenter={this.state.modalFormItem_2}
                            data={this.state.modalFormItem_data_2}
                        />
                    </div>
                </Modal>
{/*监控--------------------------------------*/}
                <Modal
                    title={this.state.modalFormItemText}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_4}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => {
                        this.addDev_2();// 新增

                    }}
                    // 点击取消
                    onCancel={()=> {this.setState({modalVisible_4:false})}}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'100%' }}>
                        <FormsTwo
                            ref={e => this.modalFormItem3 = e}
                            formParamenter={this.state.modalFormItem_3}
                            data={this.state.modalFormItem_data_3}
                        />
                    </div>
                </Modal>
{/*阀门-安装--------------------------------------*/}
                <Modal
                    title={this.state.modalFormItemText}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_5}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => {this.setState({modalVisible_5:false})}}
                    // 点击取消
                    onCancel={()=> {this.setState({modalVisible_5:false})}}
                    width={800}
                    centered
                    footer={[
                        <Button
                            key="submit"
                            type="primary"
                            onClick={()=>{

                                if(this.state.device_juder){
                                    // {
                                    //     uid: '-1',
                                    //     name: 'image.png',
                                    //     status: 'done',
                                    //     url: 'http://common-photo.oss-cn-hangzhou.aliyuncs.com/UNKNOWN/4trxn92e3ebsirmfudhk0yuydp8ytum-USER-UNKNOWN-TYPE-UNKNOWN-2024-06-29-15-30-39-918.png',
                                    // },

                                    var fileLists = [];
                                    this.state.formItem_3_rowData?.photos.map(one=>{
                                        fileLists.push(
                                            {
                                                uid: '-1',
                                                name: 'image.png',
                                                status: 'done',
                                                url: one?.photoPath??null,
                                            },

                                        )
                                    });


                                    this.setState({device_juder:false,fileList_1:fileLists})
                                }else{

                                    this.state?.formItem_3_rowData?.state==='已安装'?
                                        this.updateBaseDevice() // 修改阀警设备
                                        :
                                        this.bindDev();         // 安装点类型-安装设备
                                }
                            }}
                        >
                            {this.state.device_juder?'修改模式':'安装'}
                        </Button>,
                        <Button key="back" onClick={()=>{this.setState({modalVisible_5:false})}}>
                            返回
                        </Button>
                    ]}


                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'100%' }}>
                        <FormsTwo
                            ref={e => this.formItem3 = e}
                            formParamenter={this.state.formItem_3}
                            data={this.state.formItem_3_data}
                        />
                    </div>
                    <div style={{width:'80%', marginLeft: '30px',height:'100px' }}>
                        {
                            this.state?.device_juder?
                                this.state?.formItem_3_rowData?.photos.map(one=>{
                                    return<>
                                        <div style={{width:'100px',float:'left'}}>
                                            <Image width={90} src={one.photoPath}/>
                                        </div>
                                    </>
                                })
                                :
                                <Upload
                                    action={this.state.fileUrl}
                                    name={"file"}
                                    listType="picture-card"
                                    fileList={this.state.fileList_1}
                                    onPreview={this.handlePreview}
                                    onChange={this.onChange_1}
                                >
                                    {this.state.fileList_1.length >= 4 ? null : uploadButton}
                                </Upload>
                        }
                    </div>
                    <Modal visible={previewVisible} footer={null} onCancel={()=>{this.setState({ previewVisible: false })}}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </Modal>
{/*弹出窗口-调压箱-----*/}
                <Modal
                    title={this.state.modalFormItemText+'调压柜'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_7}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => this.addAndUpdata_3()}
                    // 点击取消
                    onCancel={()=> {
                        this.clearData();
                    }}
                    width={800}
                    centered
                >
                    <div style={{width:'80%', marginLeft: '30px',height:'350px' }}>
                        <FormsTwo
                            ref={e => this.addAndUpdataForms = e}
                            formParamenter={this.state.modalFormItem}
                            data={this.state.modalFormItem.searchGetData}
                        />

                    </div>
                </Modal>

            </div>
        </Layout>
    )
  }
}
