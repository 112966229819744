// 委托单管理页面
import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Select, Button, Input} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'

import {
	getTemporaryGasCylinderList1,
	getTemporaryGasCylinderList2,
	updateCylinder,
	deleteTemporaryGasCylinder,
	getCylinderList,
	getTemporaryGasCylinderList2_1, getTemporaryGasCylinderList1_1
} from '../../../services/verifvication'
const { Option } = Select;
export default class PointManagement extends Component {

    state = {
				// 表格参数
				tableParamenter: {
					paginationWidth:true,
					prompt:false,// 是否提示请求状态
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						deviceName: '名称',
						model: '规格',
						startNumber: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						detonationPoint:'低爆点',
						remark: '短名',
						edit: '操作',
					},
					// 弹出框
					headers: {
						deviceName: '名称',
						model: '规格',
						startNumber: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						detonationPoint:'低爆点',
						remark: '短名',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						// type: 1,	// 委托单
						userId:JSON.parse(sessionStorage.getItem("user")).id,
						// page:1,
						// size:10,
					}
				},
				// 表格参数
				tableTwoParamenter: {
					paginationWidth:true,
					prompt:false,// 是否提示请求状态
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					// 表头：变量和变量解释
					header: {
						deviceName: '名称',
						model: '规格',
						startNumber: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						detonationPoint:'低爆点',
						remark: '短名',
						edit: '操作',
					},
					// 弹出框
					headers: {
						deviceName: '名称',
						model: '规格',
						startNumber: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						detonationPoint:'低爆点',
						remark: '短名',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						// type: 2,	// 委托单
						userId:JSON.parse(sessionStorage.getItem("user")).id,
						// page:1,
						// size:10,
					}
				},
			// 添加表格参数
			tableListParamenter: {

				prompt:false,// 是否提示请求状态
				manageName: "数据项管理",
				// 表格行是否可选
				// rowSelection: true,
				//下拉框长度
				selectWidth: '6.5vw',
				// 表头：变量和变量解释
				header: {
					// deviceName: '设备名称',
					model: '型号',
					measuringRange: '测量范围',
					uncertainValue: '误差值',
					startNumber: '制造厂及出厂编号',
					// timings: '检定周期或复校间隔',
					// nearFuture: '末次检定或校准日期',
					// certificate: '检定或校准机构及证书号',
					remark: '短名',
					edit: '操作',

				},
				// 弹出框
				headers: {
					deviceName: '设备名称',
					model: '型号',
					measuringRange: '测量范围',
					uncertainValue: '误差值',
					startNumber: '制造厂及出厂编号',
					// timings: '检定周期或复校间隔',
					// nearFuture: '末次检定或校准日期',
					// certificate: '检定或校准机构及证书号',
					remark: '短名'
				},
				params: {
					type:'配置管理',

					page:1,
					size:10,
				},
				// 是否绑定左侧区域
				bindArea: false
			},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'verificationRecordEntrustNum',
					str: '委托单号',
				}
			]
		},
		    	// 总条数
		totalCount: 0,
		listVisible:false, // 弹窗开关
		listType:'',
	};

	//弹出表格格式化
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'添加'} onClick={() => this.addListData(rowData)}>
						<u>{'添加'}</u>
				</div>
				</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	//表单表格格式
	setFormTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'删除'} onClick={() => this.removeListData(rowData.id)}>
						<u>{'删除'}</u>
				</div>
				</>;
			case 'expirationTime':
				text=moment(text).format("YYYY-MM-DD HH:mm:ss");
				return (<span>{text}</span>);
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : '';
		}
	};

	componentDidMount(){
	}


	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
			let params = this.state.tableParamenter.params;
			let formParams=this.table.getParams()
			Object.assign(params,value);
			params.page=formParams.page
			params.size=formParams.size
			let {tableParamenter} = this.state;
			tableParamenter.params = params;
			this.setState({tableParamenter});
			await this.table.refreshTable(params);

	};

// 初始化表格数据
getData = async () => {
	this.setState({})
	this.table.refreshTable();
  };

	//添加列表窗口显示
	addListStatus=(listVisible,listType)=>{
		this.setState({listVisible,listType:listType});
		console.log(this.state.listType);
		this.table1.refreshTable();// 刷新表格
		this.table2.refreshTable();// 刷新表格
	};
	//添加配置
	addListData= (rowData)=>{
		console.log(rowData);
		console.log(this.state.listType);
		rowData.type=this.state.listType;
		rowData.cylinderId=rowData.id;
		rowData.id=null;
		rowData.expirationTime=moment(rowData.expirationTime).format('YYYY-MM-DDT00:00:00');
		rowData.nearFuture=moment(rowData.nearFuture).format('YYYY-MM-DDT00:00:00');
		rowData.userId=JSON.parse(sessionStorage.getItem("user")).id;
		console.log(rowData);
		updateCylinder(rowData).then(data=>{
			console.log(data.data.data);
			if(data.data.data===true){
				funTraceInfo("报警器检定管理", "配置气瓶信息", null,rowData)
				message.success("添加成功");
				this.table.refreshTable();// 刷新表格

			}else{
				message.error(data.data.responseInfo.description)
				this.table.refreshTable();// 刷新表格
			}
		});
    };
	//删除配置
	removeListData= (rowId)=>{
		deleteTemporaryGasCylinder({id:rowId}).then(data=>{
			console.log(data.data.data)
			if(data.data.data==true){
				message.success("删除成功")
				funTraceInfo("报警器检定管理", "删除配置", null,rowId)
				this.table1.refreshTable();// 刷新表格
				this.table2.refreshTable();// 刷新表格
			}else{
				message.error('删除失败')
			}
		});
    };

	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	   this.setState({params:params});
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{background:'#fff'}}>
				<div className={'topTitleBox'} style={{clear:'both',padding:'20px'}}>
				<h3>我的气瓶</h3>
				<div style={{float:'right'}}>
						{/*<Button */}
						{/*	style={{margin:'0 0 0 20px'}} */}
						{/*	onClick={() => this.props.history.push({pathname:"/verifvication/bottleManagement"})}>*/}
						{/*	气瓶管理*/}
						{/*</Button>*/}
				</div>
				</div>
				<div className={'topTitleBox'} style={{clear:'both',padding:'20px'}}>
							<b style={{fontSize:'16px'}}>检定用标准和装置</b>
							<span className={'addLink addList'} onClick={() => this.addListStatus(true,1)}><PlusSquareOutlined />新增</span>
						</div>
						<div style={{clear:'both',background:'#fff',padding:'10px'}}>
							<TableComponents
							paramenter={this.state.tableParamenter}
							setData = {getTemporaryGasCylinderList1_1}
							setTableValue={this.setFormTableValue}
							ref={e => this.table1 = e}
						>
							</TableComponents>
						</div>
						<div className={'topTitleBox'} style={{clear:'both',padding:'20px'}}>
							<b style={{fontSize:'16px'}}>本次使用的计量器具</b>
							<span className={'addLink addList'} onClick={() => this.addListStatus(true,2)}><PlusSquareOutlined />新增</span>
						</div>
						<div style={{clear:'both',background:'#fff',padding:'10px'}}>
							<TableComponents
							paramenter={this.state.tableTwoParamenter}
							setData = {getTemporaryGasCylinderList2_1}
							setTableValue={this.setFormTableValue}
							ref={e => this.table2 = e}
						>
							</TableComponents>
				</div>
				<Modal
                        title="添加"
                        style={{ top: 0 }}
                        visible={this.state.listVisible}
                        okText="确定"
                        onOk={() => this.addListStatus(false)}           // 点击确定
                        onCancel={() => this.addListStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<div style={{width:'100%',height:'30px'}}>
								<div style={{width:'50%',}}>
									<span style={{width:'25%'}}>短名：</span>
									<Input value={this.state.remark}
										   style={{width:'55%'}}
										   onChange={(text)=>{this.setState({remark:text.target.value})}}/>
									<Button type={'primary'}
											onClick={()=>{
												var tableListParamenter = this.state.tableListParamenter;
												tableListParamenter.params.remark=this.state?.remark||null;
												tableListParamenter.params.page=1;
												this.table.refreshTable(tableListParamenter.params);// 刷新表格
											}}
									>
										查询
									</Button>
								</div>

							</div>
							<TableComponents
								paramenter={this.state.tableListParamenter}
								setData = {getCylinderList}
								setTableValue={this.setTableValue}
								ref={e => this.table = e}
							>
							</TableComponents>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
