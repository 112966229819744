import React,{Component} from 'react';
import Echarts_2 from "./echarts_2";
import Echart_1 from "./echarts_1";
import moment from "moment";

import {queryDevWorkingState, queryAlarmNumber, meterQueryDevWorkingState} from '../../../../services/qrCode'
import {message} from "antd";
import {getDateByUserId} from "../../../../services/admin";
import '../less/leftType.less'

class leftType extends React.Component {

    state={
        // 采购计划进度条
        leftData_1:{
            numData:0,       // 数据值
            ratioData:'0%',    // 比率值
        },
        // 空心半圆进度条
        leftData_2:{
            online:{singular:0,total:200},   // 在线率
            failure:{singular:0,total:200},  // 故障率
        },
        leftData_3:{
            online:0,      // 在线
            disconnect:0,  // 掉线
            total:0,      // 总数
            restore:0     // 恢复
        },
        // 使用情况 最近七日曲线图
        leftData_4:{
            // 数值
            data:{
                untreateds: [],
                processed: [],
                totalToday: [],
            },
            xData:[],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:25} // 最小、最大、间距
        },
        areaIds:[]
    };
    componentDidMount(): void {
        this.getDateByUserId(); // 获取当前用户区域id
        // this.queryDevWorkingState(); // 设备在线率
        // this.queryAlarmNumber();    // 通过当前用户所属客户下的所有报警器的报警信息
    }
    // 获取当前用户区域id
    getDateByUserId=()=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    var areaIds = this.state.areaIds;
                    this.forChildren(response?.data,areaIds);
                    this.setState({areaIds});
                    this.meterQueryDevWorkingState(areaIds);
                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };
    forChildren=(children=[],areaIds=[])=>{
        children?.map(one=>{
            if(one.type==='蒸汽'){
                areaIds.push(one.id.toString());
            }
            if(one?.children){
                this.forChildren(one?.children,areaIds);
            }
        });
        return areaIds;
    };
    // 查询当前登录用户下的所有设备的在线情况
    meterQueryDevWorkingState=(areaIds)=>{
        console.log('查询当前登录用户下的所有设备的在线情况');
        if(!areaIds){
            this.getDateByUserId();
        }
        var params={
            areaIds:areaIds,
            nowTime:moment().startOf("day").format("YYYY-MM-DD")
        };
        meterQueryDevWorkingState(params).then(response=>{
            console.log('查询当前登录用户下的所有设备的在线情况');
            if(response?.data?.data){
                var data = response?.data?.data;
                let {leftData_2,leftData_3,leftData_4} =this.state;
                leftData_2.online.singular= parseFloat(data[data.length-1]?.todayOnLineRate).toFixed(0);// 在线率
                leftData_2.failure.singular= parseFloat(data[data.length-1]?.todayFaultRate).toFixed(0);// 故障率
                leftData_2.online.total=200-leftData_2.online.singular;
                leftData_2.failure.total=200-leftData_2.failure.singular;

                leftData_3.online=data[data.length-1].todayOnLine;             // 在线数
                leftData_3.disconnect=data[data.length-1].todayOfflineNum;     // 掉线数
                leftData_3.total=data[data.length-1].num;                      // 总数数
                leftData_3.restore=data[data.length-1].todayRecoveryNum;       // 恢复数
                // 初始化
                // leftData_4.xData=[];// 时期
                leftData_4.data.untreateds=[];
                leftData_4.data.processed=[];
                leftData_4.data.totalToday=[];
                // 循环获取周期数据
                data.map(one=>{
                    leftData_4.data.totalToday.push(one?.todayOnLineRate);
                    leftData_4.xData.push(one?.time)
                });
                this.setState({leftData_2,leftData_3,leftData_4:leftData_4});
                // response?.data?.data.map(one=>{
                //     console.log(one);
                // });
            }
        })
    };


    // 设备在线率
    queryDevWorkingState=()=>{
        // 10167蒸汽 10195燃气
        var params_1={
            customerIds:[],
            type:10168
        };
        // var params_2={
        //     type:10196,
        //     customerIds:[]
        // };
        var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));
        if (customerNames?.length !== 0) {
            customerNames?.map(one => {
                params_1.customerIds.push(parseInt(one.id));
                // params_2.customerIds.push(parseInt(one.id))
            });
        }
        // 探测器
        queryDevWorkingState(params_1).then(
            response=>{
                if(response?.data?.data){
                    let {leftData_2,leftData_3} =this.state;
                    var data = response?.data?.data;
                    leftData_2.online.singular= parseFloat(data.todayOnLineRate).toFixed(0);// 在线率
                    leftData_2.failure.singular= parseFloat(data.todayFaultRate).toFixed(0);// 故障率
                    leftData_3.online=data.todayOnLine;             // 在线数
                    leftData_3.disconnect=data.todayOfflineNum;     // 掉线数
                    leftData_3.total=data.num;                      // 总数数
                    leftData_3.restore=data.todayRecoveryNum;       // 恢复数
                    this.setState({leftData_2,leftData_3});
                }
            }
        );
    };
    // 通过当前用户所属客户下的所有报警器的报警信息
    queryAlarmNumber=()=>{
        var params={
            customerIds: [],
            type:10168
        };
        var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));
        if (customerNames?.length !== 0) {
            customerNames?.map(one => {
                params.customerIds.push(parseInt(one.id))
            });
        }
        console.log('通过当前用户所属客户下的所有报警器的报警信息');
        queryAlarmNumber(params).then(
            response=>{
                if(response?.data?.data){
                    var {leftData_4} = this.state;
                    var data = response?.data?.data;
                    leftData_4.xData=[];
                    leftData_4.data.untreateds=[];
                    leftData_4.data.processed=[];
                    leftData_4.data.totalToday=[];
                    // 循环获取周期数据
                    for(var i=data.length-1;i>=0;i--){
                        leftData_4.xData.push(moment(data[i]?.nowTime).format("DD"));
                        leftData_4.data.untreateds.push(data[i]?.untreated || 0);
                        leftData_4.data.processed.push(data[i]?.processed || 0);
                        leftData_4.data.totalToday.push(data[i]?.totalToday || 0);
                    }
                    this.setState({leftData_4:leftData_4})
                }
            }
        )
    };
    render() {
        return (
            <div id={'leftType'}>
                {/*上*/}
                <div className={'leftType_top_div'}>
                    {/*使用情况*/}
                    <div style={{ 'width':' 100%', 'height': '38%'}}>
                        <div style={{ 'width':' 100%', 'height': '20%'}}>
                            <span
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#00b6ec'}}
                            >使用情况</span>
                        </div>
                        {/*内容*/}
                        <div style={{'width':' 100%', 'height': '65%',background: 'linear-gradient(170deg, #012439,#003345 65px,#012439)',borderRadius: '5px',padding:'4%'}}>
                            <div style={{'width':' 100%', 'height': '25%'}}>
                                <span className={'body_left_top_div_spen_1'}>今日采购计划</span>
                                <span className={'body_left_top_div_spen_2'}>{this.state.leftData_1.numData}</span>
                                <span className={'body_left_top_div_spen_3'}>吨</span>
                            </div>
                            {/*进度条*/}
                            <div style={{ margin: '4% 0 4% 0', backgroundColor: '#00488d', width: '100%', height: '15%', borderRadius: '15px'}}>
                                <div style={{'width':this.state.leftData_1.ratioData, background: 'linear-gradient(170deg, #2165b1, #2fbbda)', height: '100%', borderRadius: '15px',}}/>
                            </div>
                            <div  style={{'width':' 100%', 'height': '25%'}}>
                                {/*<span className={'body_left_top_div_spen_4'}>完成里程300km</span>*/}
                                <span className={'body_left_top_div_spen_6'}>{this.state.leftData_1.ratioData}</span>
                                <span className={'body_left_top_div_spen_5'}>完成率：</span>
                            </div>
                        </div>
                    </div>
                    {/*表具信息*/}
                    <div style={{ 'width':' 100%', 'height': '60%'}}>
                        {/*标题*/}
                        <div style={{ 'width':' 100%', 'height': '15%'}}>
                            <span
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: '#00b6ec'
                                }}
                            >表具信息</span>
                        </div>
                        {/*内容*/}
                        <div style={{ 'width':' 100%', 'height': '85%'}}>
                            {/*上*/}
                            <div style={{ 'width':' 100%', 'height': '45%'}}>
                                <div style={{ 'width':' 100%', 'height': '100%'}}>
                                    <div className={'img_1'} style={{  margin: "0% 4% 5% 2%"}}>
                                        <Echart_1
                                            elementById={'leftTopData_2_1'}
                                            data={{
                                                value: this.state.leftData_2.online.singular,
                                                name: '今日在线率',
                                                color:'#00b4db',
                                                counNum:this.state.leftData_2.online.total}}
                                        />
                                        <div style={{color:'#67c4d3',fontSize:'18px',margin:'-14% 0 0 38%'}}>{this.state.leftData_2.online.singular}%</div>
                                        <div style={{color:'#00b4e0',fontSize:'14px',margin:'-3% 0 0 27%'}}>今日在线率</div>
                                    </div>
                                    <div className={'img_1'}>
                                        <Echart_1
                                            elementById={'leftTopData_2_2'}
                                            data={{ value: this.state.leftData_2.failure.singular, name: '今日故障率',color:'#f49d00',counNum:this.state.leftData_2.failure.total}}
                                        />
                                        <div style={{color:'#f6a700',fontSize:'18px',margin:'-14% 0 0 38%'}}>{this.state.leftData_2.failure.singular}%</div>
                                        <div style={{color:'#f6a700',fontSize:'14px',margin:'-3% 0 0 27%'}}>今日掉线率</div>
                                    </div>
                                </div>
                            </div>
                            {/*下*/}
                            <div style={{ 'width':' 100%', 'height': '55%'}}>
                                <div className={'img_2'} style={{textAlign: 'center',padding:'1%',margin:'0 3% 3% 9%'}}>
                                    <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                        <span>{this.state.leftData_3.online}</span>
                                    </div>
                                    <div style={{color: '#fff',fontSize:'12px'}}>
                                        <span>今日在线数</span>
                                    </div>
                                </div>
                                <div className={'img_2'} style={{textAlign: 'center',padding:'1%'}}>
                                    <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                        <span>{this.state.leftData_3.disconnect}</span>
                                    </div>
                                    <div style={{color: '#fff',fontSize:'12px'}}>
                                        <span>今日掉线数</span>
                                    </div>
                                </div>
                                <div className={'img_2'} style={{textAlign: 'center',padding:'1%',margin:'0 3% 0 9%'}}>
                                    <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                        <span>{this.state.leftData_3.total}</span>
                                    </div>
                                    <div style={{color: '#fff',fontSize:'12px'}}>
                                        <span>今日总数</span>
                                    </div>
                                </div>
                                <div className={'img_2'} style={{textAlign: 'center',padding:'1%'}}>
                                    <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                        <span>{this.state.leftData_3.restore}</span>
                                    </div>
                                    <div style={{color: '#fff',fontSize:'12px'}}>
                                        <span>今日恢复数</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*下*/}
                <div
                    style={{
                        backgroundColor: '#001b2c',
                        width: '90%',
                        height: '40%',
                        margin: '5% 5% 0 5%',
                        padding: '5%',
                        borderRadius: '2px',
                    }}
                >
                    {/*标题*/}
                    <div style={{ 'width':' 100%', 'height': '20%'}}>
                        <span style={{  fontSize: '18px', fontWeight: 'bold', color: '#00b6ec'}}>使用情况</span>
                    </div>
                    {/*内容*/}
                    <div style={{  width: '100%',height: '80%'}}>
                        <div style={{  width: '100%',height: '15%'}}>
                            <div style={{ width: '40%',height: '80%', background: 'linear-gradient(100deg, #005970, #001e30 70%)', padding:'2px 0 0 5px'}}>
                                <span style={{color:'#8fa4ac',fontSize:'8px',  fontWeight: 'initial'}}>●最近七天在线率曲线图</span>
                            </div>
                        </div>
                        <div style={{  width: '100%',height: '85%'}}>
                            <Echarts_2
                                elementById={'leftTopData_1_1'}
                                data={this.state.leftData_4.data}    // 数值
                                xData={this.state.leftData_4.xData}  // X轴数据
                                number={this.state.leftData_4.number}// 最小、最大、间距
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default leftType;
