import React, { Component } from 'react'
import { Layout, Select, Form } from 'antd'
import TableComponents from '../../components/common/tables/TableComponents'
import {getImportInfoRecord} from '../../services/alarm'

const { Option } = Select
const FormItem = Form.Item

export default class ImportInfoRecord extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			// manageName: "报表查询",
            rowSelection: false,
			// width: '83.7vw',
            // title: '表具导入',
			// exportUrl: "/web/reportExportCtrl/exportDataDifference",
			// importUrl: "/meterimport/meterImport/import",
			// 表头：变量和变量解释
			header: {
                id: '标识',
				excelName: 'EXECL文件名',
				importTime: '导入时间',
				importQuantity: '导入数量',
				importResult: '导入结果',
                meterId: '导入的表钢号',
			},
			
		},
	}

	setTableValue = (value,text,rowData) => {

        switch (value) {
            case 'importResult':
                // text = text * 1
                return text === true ? '导入成功' : text === false ? '导入失败'  : '-'
				default:
					return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
    }
}
	
	render() {
        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getImportInfoRecord}
					setTableValue={this.setTableValue}// 设置表格显示
					clearCondition={this.clearCondition}
					ref={e => this.table = e} 
				>
				</TableComponents>
            </Layout>
        )
    }
}