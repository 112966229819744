import React, { Component } from 'react'
import {Layout} from "antd";
import './middleRight.less'
import HalfDoughnutChart1 from "../../gasDataPanel/assembly/HalfDoughnutChart";
import Echart_1 from "../../gasDataPanel/assembly/HalfDoughnutChart_1";
import Echart from "../../qrCode/deviceMap/js/echart";
import Semicircle1 from "../assembly/Semicircle_1";
import {largeScreenMeterAnalysis} from "../../../services/customerDataPanel";
export default class middleRight extends Component {
    state={
        // 环形圆进度条
        leftData_1:{
            name:'安全率',data:91,color:['#3D8DFF','#62DBFF']
        },
        rightData_2:[
            {value:0,name:'在线探头数',color:'#f49d00'},
            {value:0,name:'掉线探头数',color:'#2cf4d4'},
        ],
        tableData:[
            {key:1,name:'周师傅',data:'报警器、安全阀、压力...',type:'已处理'},
            {key:2,name:'友谊阿波罗',data:'燃气设施接触电线',type:'已处理'},
            {key:3,name:'宜家家居',data:'燃具无熄火保护装置',type:'已处理'},
            {key:4,name:'检测产业园',data:'燃气设施接触电线',type:'未处理'},
        ],

        // 空心半圆进度条
        semicircle_data_1:{
            online:{singular:50,total:150,color:['#29aeff','#8bcdff']},   // 在线率
            // failure:{singular:93,total:103,color:['#39ecff','#67ff99']},  // 故障率
        },


        "standbyMeterNum": '0',    // 备用表数量
        "userMeterNum": '0',       // 用户表数量
        "newAddNum": '0',          // 本月新增
        "newInstallNum": '0',      // 本月新装
        "discardNum": '0',         // 本月废弃
        "meterReadingRate": '0',   // 抄表率
        "abnormalNum": '0',        // 异常数
        "differenceNum": '0',      // 产销差
    };

    componentDidMount(): void {
        this.largeScreenMeterAnalysis(); // 大屏表具分析(2024-02-28新增)
    }
    // 大屏表具分析(2024-02-28新增)
    largeScreenMeterAnalysis=()=>{
        var params =  new URLSearchParams();
        // params.append('areaIdList',aredIds);
        largeScreenMeterAnalysis().then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    var tableData = this.state.tableData;
                    var semicircleData1 = this.state.semicircle_data_1;


                    semicircleData1.online.singular=data?.standbyMeterNum??'0';
                    semicircleData1.online.total=data?.userMeterNum??'0';
                    tableData=[];
                    data?.list.map(one=>{
                        tableData.push(
                            {name:one.type,data:one.name,type:one.state},
                        )
                    });
                    // id: 150, type: "户内管道设施", name: "管道未按规范暗设", time: null, level: "II", state: "已整改", checkId: "39",…
                    this.setState({
                        semicircle_data_1:semicircleData1,
                        tableData:tableData,
                        "standbyMeterNum": Math.round(Number(data?.standbyMeterNum))??'0',  // 备用表数量
                        "userMeterNum": Math.round(Number(data?.userMeterNum))??'0',        // 用户表数量
                        "newAddNum": data?.newAddNum??'0',              // 本月新增
                        "newInstallNum": data?.newInstallNum??'0',      // 本月新装
                        "discardNum": data?.discardNum??'0',            // 本月废弃
                        "meterReadingRate": Math.round(Number(data?.meterReadingRate ))+'%'??'0%',// 抄表率
                        "abnormalNum": Math.round(Number(data?.abnormalNum ))??'0',          // 异常数
                        "differenceNum": Math.round(Number(data?.differenceNum))+'%'??'0%' ,      // 产销差

                    });
                }
            }

        )
    };

    getTable=()=>{
        var tableData = this.state.tableData;
        var i=true;
        return<>
            {
                tableData.map(one=>{
                    i=!i;
                   return(
                       <div className={i?'middleRight_data_type_2_table_div_1':'middleRight_data_type_2_table_div_2'}>
                           <div style={{width:'40%',height:'100%',float:'left',paddingLeft:'1%'}}><span style={{color:"rgb(185 196 207)"}}>{one.name}</span></div>
                           <div style={{width:'40%',height:'100%',float:'left',paddingLeft:'1%'}}><span style={{color:"rgb(185 196 207)"}}>{one.data}</span></div>
                           <div style={{width:'20%',height:'100%',float:'left',paddingLeft:'1%'}}><span style={{color:one.type==='未处理'?'#FF6236':'#FFB220'}}>{one.type}</span></div>
                       </div>
                   )
                })
            }
        </>
    };

    render() {


        return (
            <div id={'middleRight_2'}>
                <div className={'middleRight_head_type'}>
                    <span className={'middleRight_head_type_title'}>
                        用气安全分析
                    </span>
                </div>
                <div className={'middleRight_data_type'}>
                    {/*一排*/}
                    <div style={{width:'100%',height: '25%'}}>
                        <div style={{width:'30%',height:'100%',float:'left',marginLeft:'15%'}}>
                            <div className={'middleRight_data_type_1_div_4_img'}>
                                {/*<HalfDoughnutChart1*/}
                                {/*    elementById={'rightTopData_1_1'}*/}
                                {/*    data={{*/}
                                {/*        data: this.state.leftData_1.data,*/}
                                {/*        name: this.state.leftData_1.name,*/}
                                {/*        color:this.state.leftData_1.color,*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <Semicircle1
                                    elementById={'rightTopData_1_1'}
                                    data={{
                                        value: this.state.semicircle_data_1.online.singular,
                                        name: '今日在线率',
                                        title:'30min',
                                        color:this.state.semicircle_data_1.online.color,
                                        counNum:this.state.semicircle_data_1.online.total}}
                                />
                            </div>
                        </div>
                        <div style={{width:'50%',height:'100%',float:'left'}}>
                            <div style={{width:'100%',height:'50%',paddingTop:'12%'}}>
                                <div style={{width:'30%',height:'100%',float:'left'}}>
                                    <div style={{width:'15%',height:'100%',paddingTop:'5px',float:'left'}}>
                                        <div style={{width:'90%',height:'45%',backgroundColor:"#34d0e8",borderRadius: '50px'}}/>
                                    </div>
                                    <div style={{width:'85%',height:'100%',float:'left'}}><span style={{color:'#FFFFFF',float:'left'}}>用户表</span></div>
                                    {/*<span style={{color:'#FFFFFF',float:'left'}}>用户表</span>*/}
                                </div>
                                <div style={{width:'70%',height:'100%',float:'left'}}>
                                    <span style={{color:'#FFFFFF',fontWeight: 'bold'}}>{this.state.userMeterNum}</span>
                                </div>
                            </div>
                            {/*-------------*/}
                            <div style={{width:'100%',height:'50%'}}>

                                {/*<div style={{width:'30%',height:'100%',float:'left'}}>*/}
                                {/*    <span style={{color:'#FFFFFF'}}>备用表</span>*/}
                                {/*</div>*/}
                                <div style={{width:'30%',height:'100%',float:'left'}}>
                                    <div style={{width:'15%',height:'100%',paddingTop:'5px',float:'left'}}>
                                        <div style={{width:'90%',height:'20%',backgroundColor:"#9ffd5d",borderRadius: '50px'}}/>
                                    </div>
                                    <div style={{width:'85%',height:'100%',float:'left'}}><span style={{color:'#FFFFFF',float:'left'}}>备用表</span></div>
                                    {/*<span style={{color:'#FFFFFF',float:'left'}}>用户表</span>*/}
                                </div>

                                <div style={{width:'70%',height:'100%',float:'left'}}>
                                    <span style={{color:'#FFFFFF',fontWeight: 'bold'}}>{this.state.standbyMeterNum}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/**/}
                    <div style={{width:'100%',height: '15%',paddingTop:'1%'}}>
                        <div style={{width:'33%',height:'100%',float:'left'}}>
                            <div className={'middleRight_data_type_4_img_1'}>
                                <div><span >{this.state.newAddNum}</span></div>
                                <div><span >本月新表</span></div>
                            </div>
                        </div>
                        <div style={{width:'33%',height:'100%',float:'left'}}>
                            <div className={'middleRight_data_type_4_img_1'}>
                                <div><span >{this.state.newInstallNum}</span></div>
                                <div><span >本月新装</span></div>
                            </div>
                        </div>
                        <div style={{width:'33%',height:'100%',float:'left'}}>
                            <div className={'middleRight_data_type_4_img_1'}>
                                <div><span >{this.state.discardNum}</span></div>
                                <div><span >本月报废</span></div>
                            </div>
                        </div>
                    </div>
                    {/*三排*/}
                    <div style={{width: '100%',height: '24%',paddingLeft:'10%'}}>

                        <div style={{width: '90%',height: '68%',paddingTop:"1.5%"}}>
                            <div style={{width:'5vw',height:"11vh",float:'left'}}>
                                <div style={{width: '100%',height: '65%'}}>
                                    <div className={'middleRight_data_type_3_img_1'}>
                                        <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                            <span style={{color:"#fff",fontWeight:"bold",fontSize:'18px'}}>{this.state.meterReadingRate}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"35%",paddingTop:'5px',textAlign: 'center'}}>
                                    <span className={'middleRight_data_type_1_title'}>昨日抄表率</span>
                                </div>
                            </div>
                            <div style={{width:'5vw',height:"11vh",float:'left',marginLeft:'12%'}}>
                                <div style={{width: '100%',height: '65%'}}>
                                    <div className={'middleRight_data_type_3_img_2'}>
                                        <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                            <span style={{color:"#fff",fontWeight:"bold",fontSize:'18px'}}>{this.state.abnormalNum}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"35%",paddingTop:'5px',textAlign: 'center'}}>
                                    <span className={'middleRight_data_type_1_title'}>用气异常</span>
                                </div>
                            </div>
                            <div style={{width:'5vw',height:"11vh",float:'left',marginLeft:'12%'}}>
                                <div style={{width: '100%',height: '65%'}}>
                                    <div className={'middleRight_data_type_3_img_3'}>
                                        <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                            <span style={{color:"#fff",fontWeight:"bold",fontSize:'18px'}}>{this.state.differenceNum}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"35%",paddingTop:'5px',textAlign: 'center'}}>
                                    <span className={'middleRight_data_type_1_title'}>供销差</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*二排*/}
                    <div style={{width: '100%',height: '32%'}}>
                        <div style={{width:'100%',height:'20%'}}>
                            <span style={{fontSize:'14px',color:'#BAE9FF',float:'right',paddingRight:'10%'}}>更多 ></span>
                        </div>
                        <div style={{width:'100%',height:'80%',overflowY: 'auto'}}>{this.getTable()}</div>
                    </div>
                </div>
            </div>
        )
    }
}
