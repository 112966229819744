import React, {Component} from 'react'
import { Layout, Button, Space} from "antd";

export default class  deviceMap extends Component{
    state ={


    };
    componentDidMount() {
        // this.props.history.replace('/mapDemo');
        // this.props.history.replace('/mapDemo1');
    }
    transfer=()=>{
        this.props.history.push({
            pathname:"/devMap",
            state:{

            }
        })
    };
    render() {
        return(
            <Layout  className={'item_body_number_back tables'}>
                <div style={{width: '100%', height: '100%',background: '#fff',padding:'20% 0 0 40%'}}>
                    <div
                        onClick={()=>{this.transfer()}}
                        style={{
                            width: '20%',
                            height: '50px',
                            paddingTop:'8px',
                            textAlign: 'center',
                            fontSize:'18px',
                            color:'#fff',
                            background:'#1890ff',
                            border:'1px #ccc solid',
                            borderRadius: '10px',
                            cursor:'pointer'}}>
                        <span>智慧能源平台</span>
                    </div>
                </div>

            </Layout>
        );
    }
}
