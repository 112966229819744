import React, { Component } from 'react'
import {Layout} from "antd";
import './gasDataPanel_v.01.less'
import { ExpandOutlined } from '@ant-design/icons';
import WarningAnimation from './subpage/warningAnimation'
import MiddleLeft from './subpage/middleLeft'
import MiddleRight from './subpage/middleRight'
import MiddleMap1 from "./subpage/middleMap";
import BottomFive from "./subpage/bottomFive";
import BottomThree from "./subpage/bottomThree";
import BottomTwo from "./subpage/bottomTwo";
import BottomOne from "./subpage/bottomOne";
import {ExclamationCircleOutlined,CloseCircleOutlined} from '@ant-design/icons';
import moment from "moment";
import {getPageFuzzyByState} from "../../services/meter";
import FormsTwo from "../../components/common/forms/FormsTwo";
export default class gasDataPanel extends Component {
    state = {
        middleMiddleType:true,
        headLeftDate:'22',  // 时间
        warn_type_1:false,   // 警告栏
        textSwitch:1,
        warning_data:[{},{},{},{},{}],
        warning_num:0,
        warning_date:null,     // 时间
        warning_title:null,     // 标题
        warning_content:null,   // 内容

    };

    componentDidMount = async () => {

        // 循环加载
        // setInterval(()=>{this.circulateTime();},10000);
        this.getTime();             // 初始化加载当天时间
        // this.getPageFuzzyByState(); // 获取热力图警告数据
    };
    getTime=()=>{
        //获得显示时间的div
        var now=new Date();
        //替换div内容
        var t_div  =  <>
            <div>
                <div style={{width:'33%',float:'left'}}>
                    <span>{moment(now).format("HH:mm:ss")}</span>
                </div>
                <div style={{width:'43%',float:'left'}}>
                    <span>{moment(now).format("yyyy-MM-DD")}</span>
                </div>
                <div style={{width:'20%',float:'left'}}>
                    <span>{' 星期'+'日一二三四五六'.charAt(new Date().getDay())}</span>
                </div>

                {/*moment(now).format("HH:mm:ss  ")+moment(now).format("  yyyy-MM-DD")+("  星期"+now.getDay())*/}
            </div>
        </>;

        this.setState({headLeftDate:t_div});
        // setTimeout(this.getTime(),1000);
    };
    // 循环时间
    circulateTime=()=>{
        this.setState({
            warn_type_1:!this.state.warn_type_1,
            textSwitch:  Math.floor(Math.random() * 3)
        })
    };


    // 获取热力图警告数据
    getPageFuzzyByState=()=>{
        const now = new Date();
        now.setMinutes(now.getMinutes() - 10);

        // var param={
        //     "page": {
        //         "size": 10,
        //         "current": 1
        //     },
        //     "baseAlarmInfoAlarmTimeStart": "2024-03-01 00:00:00",
        //     "baseAlarmInfoAlarmTimeEnd": "2024-03-09 00:00:00",
        //     "state": "未处理"
        // };
        var param={
            page: {size: 10, current: 1},
            baseAlarmInfoAlarmTimeStart: moment(now).format('YYYY-MM-DD HH:mm:ss'),
            baseAlarmInfoAlarmTimeEnd: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            state: "未处理"
        };
        getPageFuzzyByState(param).then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    if(data.length!==0){
                        this.setState({
                            warning_num:0,
                            warning_data:data,  // 警告数据集合
                            warn_type_1:true,   // 开启弹窗
                        });
                        return setTimeout(()=>this.timedTasks_1(),3000);// 定期刷任务。
                    }else{
                        this.setState({
                            warning_num:0,
                            warning_date:null,  // 警告数据集合
                            warn_type_1:false,  // 开启弹窗
                        });
                        return setTimeout(()=>this.getPageFuzzyByState(),10000);// 定期刷任务。
                    }
                }else{
                    return setTimeout(()=>this.getPageFuzzyByState(),10000);// 定期刷任务。
                }
            }
        )
    };


    // 定时任务-1-初始化运行
    timedTasks_1=async ()=>{
        var {warning_data,warning_num} = this.state;


        if(Number(warning_num)<Number(warning_data.length-1)){
            console.log('warning_num');
            warning_num=warning_num+1;
            await this.setState({
                warning_num:warning_num,
                warn_type_1:true
            });

            setTimeout(()=>this.timedTasks_1(),3000);
        }else{
            console.log('循环对比当前数据集合的长度，循环结束再继续');
            this.setState({
                warning_num:0,
                warning_data:[],
                warn_type_1:false
            });
            this.getPageFuzzyByState();
        }
    };

    // 定时任务-2-查询解释后定时开关提示
    timedTasks_2=()=>{

    };


    render() {
        return (
            <Layout id={'gasDataPanel1'}>

                <div className={'body_type'}>
                    <div style={{display:this.state.warn_type_1?null:'none'}}>
                        <WarningAnimation/>
                    </div>


{/*顶部-标题行--------------------------------------------------------------------------------------------------------*/}
                    <div className={'head_type'}>
                        <div className={'head_type_img'}>
                            <div  style={{width:'30%',height:'100%',float:'left',padding:'1.7% 1% 1.5% 5%',color:'#fff'}}>
                                <div style={{width:'100%',height:'100%'}}>
                                    <div style={{float:'left',width:'45%'}}>
                                        <span>管理员 罗成 12055559138</span>
                                    </div>
                                    <div style={{float:'left',width:'50%'}}>
                                        <span>负责人 何水潇 135233788130</span>
                                    </div>
                                </div>
                            </div>
                            <div  style={{width:'40%',height:'100%',float:'left',textAlign: 'center',paddingTop:'1.4%'}}>
                                <span style={{color:'#fff',fontSize:'28px'}}>燃气公司安全可视化大屏</span>
                            </div>
                            <div  style={{width:'30%',height:'100%',float:'left',padding:'1.7% 0% 2.5% 6%',color:'#fff'}}>
                                <div style={{width:'100%',height:'100%'}}>
                                    <div style={{float:'left',width:'60%',fontSize:'17px',borderRight: '3px #b4b4b5 ridge'}}>
                                        {this.state.headLeftDate}
                                    </div>
                                    <div style={{float:'left',width:'35%',height:'100%'}}>
                                        <div className={'head_type_right_img_1'}/>
                                        {/*<div className={'head_type_right_img_2'}/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
{/*中部---------------------------------------------------------------------------------------------------------------*/}
                    <div className={'middle_type'}>
{/*中部--左*/}
                        <div className={'middle_left_type'}>
                            <div className={'middle_left_img'}>
                                <MiddleLeft/>
                            </div>
                        </div>
{/*中部--中*/}
                        <div className={this.state.middleMiddleType?'middle_middle_type_1':'middle_middle_type_2'}>
                        {/*<div className={'middle_middle_type_1'}>*/}
                            {/*大小按钮*/}
                            <div className={this.state.middleMiddleType?'middle_middle_MiddleMap_type_1':'middle_middle_MiddleMap_type_2'}
                                 onClick={()=>{
                                     this.MiddleMap1.setMapType(!this.state.middleMiddleType);
                                     // this.MiddleMap1.valveMap();
                                     this.setState({middleMiddleType:!this.state.middleMiddleType});
                                 }}
                            ><ExpandOutlined style={{ fontSize: '18px', color: '#1763ef' }}/>
                            </div>
{/*提示条------------------------------------------------------------------------------------------*/}
{/*                            {this.timedTasks_1()}*/}
                            <div
                                className={this.state.middleMiddleType?'middle_middle_warningBar_div_1':'middle_middle_warningBar_div_2'}
                                style={{display:this.state.warn_type_1?null:'none'}}
                            >
                                <div style={{width:'98%',height:'100%',position: 'absolute',padding :'0px 2px 5px 15px'}}>
                                    <ExclamationCircleOutlined style={{ marginTop:'10px', fontSize: '28px',color: '#ff5654' ,float:'left'}} />
                                    <span style={{marginTop:'10px', fontSize: '18px',color: '#ff5654',marginLeft:'4px',float:'left'}}>处理超时：</span>
                                    <div style={{width:'72%',height:'100%',float:'left',whiteSpace:"pre-line",paddingTop:'3px',
                                        display: 'flex',
                                        alignItems: 'center'}}>
                                        <span style={{fontSize: '15px',color: '#ff9a27'}}>
                                            {this.state.warning_data[this.state.warning_num]?.alarmTime?
                                                moment(this.state.warning_data[this.state.warning_num]?.alarmTime).format('YYYY-MM-DD HH:mm:ss')
                                            :null}
                                            {this.state.warning_data[this.state.warning_num]?.customerName}
                                            <span style={{fontSize: '15px',color: '#fff'}}>
                                                {this.state.warning_data[this.state.warning_num]?.alarmDescription}
                                            </span>
                                        </span>
                                    </div>

                                    <div style={{ marginTop:'10px',width:'10%',height:'80%',float:'right', flexWrap: 'wrap',textAlign:'center'}}>
                                        <div className={'middle_middle_warningBar_div_buttom'}>
                                            <span style={{color:'#fff'}}>
                                                查看
                                            </span>
                                        </div>
                                    </div>
                                </div>

{/*关闭提示栏按钮------------------------------------------*/}
                                <CloseCircleOutlined style={{float:'right',cursor: 'pointer',color:'#fff'}} onClick={()=>{this.setState({warn_type_1:false});}}/>
{/*底图---------------------------------------------------*/}
                                <div style={{backgroundColor: 'rgb(10 48 95/90%)', width:'100%',height:'100%'}}/>
                            </div>
{/*地图------------------------------------------*/}
                            <MiddleMap1
                                ref={e => this.MiddleMap1 = e}
                                elementById={'MiddleMap1'}
                            />
                        </div>


{/*中部--右------------------------------------------*/}
                        <div className={'middle_right_type'}>
                            <div className={'middle_right_img'}>
                                <MiddleRight/>
                            </div>
                        </div>
                    </div>
{/*/!*低部---------------------------------------------------------------------------------------------------------------*!/*/}
                    <div className={'bottom_type'}>
                        {/*1*/}
                        <div className={'bottom_type_1'}>
                            <div className={'bottom_type_1_img'}>
                                <BottomOne/>
                            </div>
                        </div>
                        {/*2*/}
                        <div className={'bottom_type_2'}>
                            <div className={'bottom_type_2_img'}>
                                <BottomTwo/>
                            </div>
                        </div>
                        {/*3*/}
                        <div className={'bottom_type_3'}>
                            <div className={'bottom_type_3_img'}>
                                <BottomThree/>
                            </div>
                        </div>
                        {/*4*/}
                        <div className={'bottom_type_4'}>
                            <div className={'bottom_type_4_img'}>
                                <BottomFive/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
