import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Switch} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel,realTimeDataMonitoring,getLevelById} from '../../../services/steamReport'

echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;


export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				pointName: '客户名称',
				modelName: '表具型号',
				instFlow: '瞬时流量',
				temp: '温度',
				pre: '压力',
				density: '密度',
				todayFlow: '今日用量',
				totalFlow: '累计流量',
				mainsState: '市电状态',
				communicateStatus: '通讯状态',
				maxFlow: '最大流量',
				minFlow: '最小流量',
				communicateTime: '通信时间',
				dataTime: '数据时间',
			},
			// 弹出框
			headers: {
				pointName: '客户名称',
				modelName: '表具型号',
				instFlow: '瞬时流量',
				temp: '温度',
				pre: '压力',
				density: '密度',
				todayFlow: '今日用量',
				totalFlow: '累计流量',
				mainsState: '市电状态',
				communicateStatus: '通讯状态',
				maxFlow: '最大流量',
				minFlow: '最小流量',
				communicateTime: '通信时间',
				dataTime: '数据时间',
			},
			params: {
				areaIds:[289],
				granularity:1,
				startTime:'',
				endTime:'',
				itemIdList:[1184],
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					isMultiple:true,
                    disabled: false,
					data:[],
					type: 'select',
				},
				{
					type: 'select',
					variableName: 'granularity',
					str: '时间类别',
					data: [
						{
							key: '1',
							value: '时'
						},
						{
							key: '2',
							value: '日'
						},
						{
							key: '3',
							value: '月'
						}
					]
				},
				// {
				// 	type: 'rangePicker',
				// 	variableName: 'createTime',
				// 	time: false,
				// 	str: '时间'
				// },
				
			]
		},
		topFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					rules: TYPE_NAME.SELECTINPUT,
					disabled: this.props.location.state ? true : false,
					data:[],
					onChange: (k,v) => this.onChangeSelect(k,v)
				}
				// {
				// 	variableName: 'devIds',
				// 	str: '设备选择',
				// 	// isMultiple:true,
                //     disabled: false,
				// 	data:[],
				// 	rules: TYPE_NAME.SELECTINPUT,
				// },
				// {
				// 	variableName: 'granularity',
				// 	str: '时间类别',
				// 	rules: TYPE_NAME.SELECTINPUT,
				// 	data: [
				// 		{
				// 			key: '1',
				// 			value: '时'
				// 		},
				// 		{
				// 			key: '2',
				// 			value: '日'
				// 		},
				// 		{
				// 			key: '3',
				// 			value: '月'
				// 		}
				// 	]
				// },
				// {
				// 	variableName: 'createTime',
				// 	str: '时间范围',
				// 	rules: TYPE_NAME.REQRANGE_PICKER,
				// },
				
			]
		},
		searchGetData:{},
		    	// 总条数
		totalCount: 0,
		refBl:false,
		ckVal:false
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'instFlow':
				text = rowData.instFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'mainsState':
				return text === 'ON' ? <span>ON</span> : text === 'OFF' ? <span style={{color:'red',fontWeight:'bold'}}>OFF</span> : '-';
			case 'communicateStatus':
				return text === '通讯正常' ? <span>通讯正常</span> : text === '通讯中断' ? <span style={{color:'red',fontWeight:'bold'}}>通讯中断</span> : '-';
			case 'pointName':
				text = rowData.pointName;
				return <>
				{rowData.mainsState=='OFF'|| rowData.communicateStatus=='通讯中断'? <span style={{color:'red',fontWeight:'bold'}}>{text}</span> : <span>{text}</span>}
				</>
			case 'temp':
				text = rowData.temp*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'pre':
				text = rowData.pre*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'density':
				text = rowData.density*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'todayFlow':
				text = rowData.todayFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'totalFlow':
				text = rowData.totalFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		let user = JSON.parse(sessionStorage.getItem("user"))
		getDataIgnoreLevel({userId:user.id}).then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[0].data=selectArry;
			}
		)
		this.countdown = setInterval(this.getData, 30000);
	}
	componentWillUnmount() {
		clearInterval(this.countdown);
	}
	onChangeSelect = (e) => {
		this.state.tableParamenter.params.areaIds=[e]
		this.setState({})
	}




	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		if(!data) return null
		// let sTime=data.createTime ?moment(data.createTime[0]._d) : null;
		// let eTime=data.createTime ?moment(data.createTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		params.areaIds=[data.areaIds]
		params.devIds=data.devIds
		if(this.state.ckVal==true){
			getLevelById({areaId:data.areaIds}).then(
				response => {
					const {data} = response.data
					params.areaIds=data
					let {tableParamenter} = this.state
					tableParamenter.params = params
					this.setState({tableParamenter})
					this.table.refreshTable(params);
				}
			)
		}else{
			let {tableParamenter} = this.state
			tableParamenter.params = params
			this.setState({tableParamenter})
			this.table.refreshTable(params);
		}

		// if(sTime){
		// 	params.startTime=sTime;
		// }
		// if(eTime){
		// 	params.endTime=eTime;
		// }

		
		
	};
	onChange = checked => {
		console.log(`switch to ${checked}`);
		this.state.refBl=checked;
		this.setState(this.state.params)
	  }
	onCheck = checked => {
		this.state.ckVal=checked;
	}
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaId = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	console.log(this.state.tableParamenter.params.areaIds)
	let params = this.state.tableParamenter.params
	if(this.state.refBl==true)
	{
		this.setState({})
		this.table.refreshTable(params);
	}
  }
	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox rTimeBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff'}}>
				<div className={'topSearchBox'}>
				<h3>报警器实时数据监控</h3>
				<div style={{position:'relative'}}>
						<div style={{width:'90%'}}>
						<FormsTwo 
								ref={e => this.forms = e} 
								formParamenter={this.state.topFormItem} 
								data={this.state.searchGetData}
						/>
						</div>
						<div style={{position:'absolute', left:'500px',top:'5px'}}>包含子区域：<Switch checkedChildren="是" unCheckedChildren="否" onChange={this.onCheck} /></div><div style={{position:'absolute', right:'100px',top:'5px'}}>是否实时刷新：<Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={this.onChange} /></div><Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
				</div>
				</div>

				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {realTimeDataMonitoring}
					setTableValue={this.setTableValue}
					ref={e => this.table = e} 
				>
				</TableComponents>
				</div>
            </Layout>
        )
    }
}
