import React, {Component} from 'react'
import {Layout, Button, Modal, Radio, Input, DatePicker, Popconfirm, message, InputNumber, Spin} from 'antd'
import TableComponents from '../../components/common/tables/TableComponents'
import {
    abandonedConfig,
    addLearningConfig, getDetailByConfigId,
    getLearningConfigInCondition, pushLearning, updateLearningConfig,
} from "../../services/meter";
import {TYPE_NAME} from "../../components/Const";
import BraftEditor from "braft-editor";

import "braft-editor/dist/index.css";
import moment from 'moment'
const { RangePicker} = DatePicker;
export default class textConfiguration extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "学习配置",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'textConfiguration',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '学习配置',

            header: {
                title: '标题',
                creator: '创建人',
                createTime: '创建时间',
                updateTime: '最后修改时间',
                type: '通知类型',
                learnTime: '学习时间(秒)',
                state: '状态',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'title',
                        str: '标题',
                        initData: JSON.parse(localStorage.getItem('textConfiguration'))?.name
                    },
                    {
                        type: TYPE_NAME.SELECT,
                        variableName: 'state',
                        str: '状态',
                        data:[
                            {key:'已推送',value:'已推送'},
                            {key:'未推送',value:'未推送'},
                        ],
                        initData: JSON.parse(localStorage.getItem('textConfiguration'))?.name
                    },
                    {
                        type: TYPE_NAME.RANGE_PICKER,
                        variableName: 'date',
                        str: '创建时间',
                        initData: JSON.parse(localStorage.getItem('textConfiguration'))?.name
                    },
                ],

                onchange:(e)=>{
                    var param= {
                        page: 1, // 页
                        size: 10,// 数量
                    };
                    if(e?.title)param.title=e.title;
                    if(e?.state)param.state=e.state;
                    if(e?.date){
                        if(e.date.length!==0){
                            param.startTime=moment(e.date[0]);
                            param.endTime=moment(e.date[1]);
                        }
                    }
                    this.table.refreshTable(param);
                }
            },
        },
        editorState : BraftEditor.createEditorState('<p>初始值</p>'),

        visible_1:false,        // 弹窗开关-1
        visible_2:false,         // 弹窗开关-2

        status_1:null,  // 标题错误提示
        status_2:null,  // 学习时间错误提示
        status_3:null,  // 安排时间错误提示

        radioData:1,            // 配置选择
        pushConfiguration:1,    // 推送配置、1正常、2上报。3单独文案模式
        view:true,// 查看或执行功能

        configId:null,          // id
        learnId:null,           // 学习id
        titleInfo:null,         // 文本
        contentInfo:null,       // 内容文案
        learnTime:null,         // 学习时间
        lastTime:null,          // 安排时间
        startTime:null,         // 开始时间
        endTime:null,           // 结束时间
        load:true,
    };

    setTableValue = (value,text,rowData) => {

        switch (value) {
            case 'state':
                return <><span style={{color:text==='未推送'?'red':null}}>{text}</span></>;
            case 'edit':
                return<>
                    {rowData.state==='未推送'?
                        <div className={'tableLink'} title={'推送'} >
                            <Popconfirm title="是否推送?"
                                        onConfirm={()=>{
                                            this.setState({load:false});
                                            this.pushLearning(rowData.id);
                                        }}
                                        okText="是" cancelText="否">
                                <u>推送</u>
                            </Popconfirm>
                        </div>
                        :
                        <div className={'tableNoLink'} title={'推送'} >
                            <u>推送</u>
                        </div>
                    }
                    {rowData.state==='未推送'?
                        <div className={'tableLink'} title={'修改'}
                             onClick={()=>{
                                 this.setState({
                                     radioData:rowData.type==='学习'?1:2,// 模式
                                     pushConfiguration:3,
                                     configId:rowData.id,
                                     view:true
                                 });
                                 this.getDetailByConfigId(rowData.id);
                             }}
                        >
                            <u>{'修改'}</u>
                        </div>
                        :
                        <div className={'tableNoLink'} title={'修改'} >
                            <u>修改</u>
                        </div>
                    }
                    <div className={'tableLink'} title={'查看'}
                         onClick={()=>{

                             this.props.history.push({
                                 pathname:"/textEditing/taskDetails",
                                 state:{
                                     rowData:rowData
                                 }
                             })

                             // 已停用
                             // this.setState({
                             //     radioData:rowData.type==='学习'?1:2,// 模式
                             //     pushConfiguration:3,
                             //     configId:rowData.id,
                             //     view:false
                             // });
                             // this.getDetailByConfigId(rowData.id);
                         }}
                    >
                        <u>{'查看'}</u>
                    </div>
                    <div className={'tableLink'} title={'废弃'} >
                        {
                            rowData.state==='废弃'?
                                <div className={'tableNoLink'} title={'废弃'} >
                                    <u>废弃</u>
                                </div>
                                :
                                <Popconfirm title="是否废弃?"
                                            onConfirm={()=>{
                                                this.abandonedConfig(rowData.id);
                                            }}
                                            okText="是" cancelText="否">
                                    <u>废弃</u>
                                </Popconfirm>
                        }

                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };

    // 创建学习配置
    addLearningConfig=()=>{
        var {titleInfo,contentInfo,learnTime,lastTime,radioData} = this.state;
        var juder = false;
        if(!titleInfo){
            juder=true;
            this.setState({status_1:'error'});// 标题错误提示
            message.info('标题不能为空');
        }
        if(!learnTime&& !lastTime){
            juder=true;
            this.setState({status_2:'error'});// 学习时间错误提示
            this.setState({status_3:'error'});// 安排时间错误提示
            message.info('时间不能为空');
        }
        if(juder)return false;

        var data ={
            "title": titleInfo,             // 标题
            "content": contentInfo,         // 内容
            "learnTime": learnTime,         // 学习时间
            "lastTime": lastTime,           // 任务最后完成时间
            "type": radioData===1?'学习':'任务', // 类型（学习/任务）
            "createTime": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),   // 创建时间
            "creator": JSON.parse(sessionStorage.getItem("user")).realName,      // 创造者
            "state": "未推送",                   // 推送状态(已推送/未推送/废弃)
        };
        // 创建学习配置
        addLearningConfig(data).then(
            response=>{
                console.log(response);
                if(response?.data?.data) {
                    this.setState({visible_2:false});
                    this.table.refreshTable();
                    return message.info('添加完成');
                }else{
                    message.info('添加失败');

                }
            });
    };

    // 根据配置ID查询配置详情信息
    getDetailByConfigId=(configId)=>{
        if(!configId)return false;
        getDetailByConfigId({configId}).then(
            response=>{
                console.log(response);
                if(response?.data?.data) {
                    var data = response?.data?.data;
                    this.setState({
                        learnId:data?.learnId??null,
                        editorState: BraftEditor.createEditorState(data?.content??null),
                        titleInfo:data?.title??null,
                        contentInfo:data?.content??null,
                        learnTime:data?.learnTime??null,
                        lastTime:data?.lastTime??null,
                    });
                    this.setState({visible_2:true});
                }else{
                    message.info('获取数据失败，请重新操作');

                }
            }
        );
    };

    // 修改学习配置
    updateLearningConfig=()=>{
        var {configId,learnId,titleInfo,contentInfo,learnTime,lastTime,radioData} = this.state;
        var juder = false;
        if(!titleInfo){
            juder=true;
            this.setState({status_1:'error'});// 标题错误提示
            message.info('标题不能为空');
        }
        if(!learnTime&& !lastTime){
            juder=true;
            this.setState({status_2:'error'});// 学习时间错误提示
            this.setState({status_3:'error'});// 安排时间错误提示
            message.info('时间不能为空');
        }
        if(juder)return false;

        var data ={
            "id":configId,                  // id
            "learnId":learnId,              // 学习id
            "title": titleInfo,             // 标题
            "content": contentInfo,         // 内容
            "learnTime": learnTime,         // 学习时间
            "lastTime": lastTime,           // 任务最后完成时间
            "type": radioData===1?'学习':'任务', // 类型（学习/任务）
            "createTime": moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),   // 创建时间
            "creator": JSON.parse(sessionStorage.getItem("user")).realName,      // 创造者
            "state": "未推送",                   // 推送状态(已推送/未推送/废弃)
        };
        console.log(data);

        updateLearningConfig(data).then(
            response=>{
                console.log(response.data.data);
                if(response?.data?.data) {
                    this.setState({visible_2:false});
                    this.table.refreshTable();
                    return message.info('修改完成');

                }else{
                    message.info('修改失败');

                }
            });
    };

    // 推送学习
    pushLearning=(configId)=>{

        pushLearning({configId}).then(
            response=>{
                console.log(response.data.data);
                if(response?.data?.data) {
                    this.table.refreshTable();

                    return message.info('推送学习完成');
                }else{
                    message.info('推送学习失败');
                }
                this.setState({load:true});
            }).catch(e=>this.setState({load:true}));


    };

    // 废弃学习配置
    abandonedConfig=(configId)=>{
        abandonedConfig({configId}).then(
            response=>{
                if(response?.data?.data){
                    this.table.refreshTable();
                    return message.info('废弃成功');
                }else{
                    return message.info('废弃失败');
                }
            }
        )
    };

    // 清空数据
    wipeData=()=>{

    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

                <div style={{
                    paddingTop:'25%',
                    zIndex:9999,
                    textAlign: 'center',
                    width:'100%',
                    height:'100%',
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    backgroundColor:'rgba(193,193,193,0.3)',
                    display: this.state.load?'none':null
                }}
                >
                    <Spin/><span style={{fontSize:'21px',marginLeft:'5px'}}>提交中</span>
                </div>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={getLearningConfigInCondition}
                    setTableValue={this.setTableValue}// 设置表格显示
                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addClient" buttonStyle="solid">
                        <Radio.Button value="addClient"
                                      onClick={()=>{
                                          this.setState({
                                              configId:null,
                                              visible_1:true,pushConfiguration:1,
                                              editorState: BraftEditor.createEditorState(null),
                                              titleInfo:null,
                                              contentInfo:null,
                                              learnTime:null,
                                              lastTime:null,
                                              view:true
                                          })}}>
                            新增任务
                        </Radio.Button>
                    </Radio.Group>
                </TableComponents>
{/*--选择配置---------------------------------------------------------------------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_1}
                    onOk={ async () => {}}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={800}
                    centered
                    closable={false}

                    footer={[
                        <Button key="back" onClick={() => this.setState({visible_1:false})}>取消</Button>,
                        <Button type="primary" onClick={() => this.setState({visible_1:false,visible_2:true})}>下一步</Button>
                    ]}
                >
                    <div style={{height:'160px',overflowY:'auto'}}>
                        <div style={{width:'80%',margin:'10% 5% 5% 10%' ,textAlign:'center'}}>

                            <Radio.Group onChange={(e)=>{this.setState({radioData:e.target.value})}} value={this.state?.radioData}>
                                <Radio  value={1}>学习通知</Radio>
                                <Radio value={2}>任务</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </Modal>
{/*--文案配置---------------------------------------------------------------------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_2}
                    onOk={ async () => {}}           	// 点击确定
                    onCancel={() => this.setState({visible_2:false})}	// 点击取消
                    width={880}
                    centered
                    closable={false}
                    footer={[
                        <Button key="back" onClick={() => {
                            // 返回或关闭
                            this.state.pushConfiguration===1
                                ?
                                this.setState({visible_1:true,visible_2:false})// 返回
                                :
                                this.setState({visible_2:false});// 关闭



                        }}>
                            返回
                        </Button>,
                        <Button type="primary" onClick={() => {
                            if(this.state.view){
                                if(this.state.configId){
                                    this.updateLearningConfig(); // 修改学习配置
                                }else{
                                    this.addLearningConfig();    // 创建学习配置
                                }
                            }else {
                                this.setState({visible_2:false});
                            }
                        }}>
                            确定
                        </Button>
                    ]}
                >
                    <div style={{width:'100%',height:this.state.pushConfiguration===2?"550px":"500px" }}>
                        <div style={{width:'100%',height:'8%',padding:'1px'}}>
                            <Input value={this.state.titleInfo} placeholder={'请输入标题'} status={this.state.status_1}
                                   onChange={(e)=>{this.setState({titleInfo:e.target.value})}}
                            />
                        </div>
                        {/*文案*/}
                        <div style={{width:'100%',height:'90%',padding:'1px'}}>
                            <BraftEditor
                                contentStyle={{ height: "350px" }}
                                style={{ border: "1px solid #cecece" }}
                                value={this.state.editorState}
                                onChange={(v)=>{
                                    let content = v.toHTML();  //调用editorState.toHTML()来获取HTML格式的内容
                                    console.log('v', v, 'content', content);
                                    this.setState({
                                        editorState:v,
                                        contentInfo:content,
                                    });
                                }}
                            />
                        </div>
                        {/*时间选择*/}
                        <div style={{width:'100%',height:'5%',padding:'5px 1px 1px 1px'}}>
                            {this.state.radioData===1?
                                <div style={{width:'100%',height:'50px'}}>
                                    <div style={{width:'8%',float:'left',textAlign:'center',paddingTop:'5px'}}>
                                        <span>学习时间</span>
                                    </div>
                                    <div style={{width:'20%',float:'left'}}>
                                        <InputNumber  addonAfter="秒" value={this.state.learnTime} placeholder={'请输入学习时间'}
                                               status={this.state.status_2}
                                               onChange={(e)=>{
                                                   this.setState({learnTime:e})
                                               }}
                                        />
                                    </div>
                                </div>

                                :

                                this.state.pushConfiguration===2?
                                    // 文本
                                    <div style={{width:'100%',height:'40px'}}>
                                        <div style={{width:'12%',float:'left',textAlign:'center',paddingTop:'5px'}}><span>最后完成时间</span></div>
                                        <div style={{width:'20%',float:'left',paddingTop:'5px'}}>
                                            <span>2002-4-02</span>
                                        </div>
                                    </div>
                                    :
                                    // 可选内容
                                    <div style={{width:'100%',height:'50px'}}>
                                        <div style={{width:'12%',float:'left',textAlign:'center',paddingTop:'5px'}}><span>最后完成时间</span></div>
                                        <div style={{width:'20%',float:'left'}}>
                                            <DatePicker style={{width: '100%'}} placeholder={'选择时间'}
                                                        status={this.state.status_3}
                                                        value={this.state?.lastTime?moment(this.state?.lastTime):null}
                                                        onChange={(e)=>{this.setState({lastTime:e?moment(e._d).format('YYYY-MM-DD HH:mm:ss'):null,})}}
                                            />
                                        </div>
                                    </div>
                            }
                            {/*--------------------------------------------------------------------------------------*/}
                            {
                                this.state.pushConfiguration===2?
                                    <div style={{width:'100%',height:'40px'}}>
                                        <div style={{width:'12%',float:'left',textAlign:'center',paddingTop:'5px'}}><span>放假时间</span></div>
                                        <div style={{width:'40%',float:'left'}}>
                                            <RangePicker
                                                // showTime={{ format: 'HH:mm:ss' }}
                                                ormat={"YYYY-MM-DD"}
                                                style={{width: '100%'}} placeholder={['开始时间','结束时间']}
                                                value={[
                                                    this.state.startTime?moment(this.state.startTime):null,
                                                    this.state.endTime?moment(this.state.endTime):null,
                                                ]}
                                                onChange={(e)=>{
                                                    if(e){if(e.length!==0){
                                                            this.setState({
                                                                startTime:moment(e[0]._d).format('YYYY-MM-DD HH:mm:ss'),
                                                                endTime:moment(e[1]._d).format('YYYY-MM-DD HH:mm:ss'),
                                                            });
                                                        }}
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
