import React, { Component } from 'react'
import {Layout, Divider, Input, message, Button, Modal, Table, Select, Popconfirm, TreeSelect} from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import FormsTwo from "../../../../components/common/forms/FormsTwo";
import {FORM_ITEM, TYPE_NAME} from "../../../../components/Const";
import './addDevicePage.less'
import moment from "moment";
import {addDevice, updateDevice, listDeviceModelByDeviceClassId, removeDev} from "../../../../services/qrCode";
import {removeGasBurner} from "../../../../services/meter";
const Option = Select.Option;
export default class addDevicePage extends Component {
    state={



        modalVisible:false, // 开关-1

        // 控制器
        inputError_1_serialno:'',//error
        inputError_1_communicateno:'',//error
        inputError_1_devModelName:'',//error
        inputError_1_manufacture:'',//error
        inputError_1_hdstno:'',//error
        inputError_1_devName:'',//error
        selectInput_1_newValue:[],  // 手学输入
        selectInput_1_record_id:this.props?.location?.rowData?.devModelId||null,
        selectInput_1_record:this.props?.location?.rowData?.devModelName?[this.props?.location?.rowData?.devModelName]:[],    // 选择数据
        topFormItem_1:{
            serialno:this.props?.location?.rowData?.serialno ||'',          // 设备二维码
            communicateno:this.props?.location?.rowData?.communicateno ||'',// 设备通信号
            devModelName:this.props?.location?.rowData?.devModelName || '', // 设备型号
            manufacture:this.props?.location?.rowData?.manufacture||'',     // 设备生产厂家
            hdstno:this.props?.location?.rowData?.hdstno||'',               // 控制器编号
            devName:this.props?.location?.rowData?.devName||'',             // 控制器名称
        },
        topFormItem_devModelName_1_data:[],

        devModelId:null,
        devModelName:null,

        formType:0,// 新增和修改类型


        formData1:null,// 保存控制器选择的数据
        // 控制器
        topFormItem_11: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'serialno', str: '设备二维码', rules: FORM_ITEM.AutoComplete, disabled: false,},
                {variableName: 'communicateno', str: '设备通信号', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'devModelName',
                    str: '设备型号',
                    fieldNames:{label: 'key', value: 'name', children: 'children'},
                    rules: FORM_ITEM.AutoComplete,
                    disabled: false,
                    onChange: (k,v) => {
                        var formData1 = this.state.formData1;
                        formData1=null;
                        if(v?.key){
                            this.state.topFormItem_11?.parameter[2]?.data.map(one=>{
                                console.log(one);
                                if(one?.key.toString()===v?.key.toString()){
                                    formData1=one;
                                    this.setState({formData1:formData1});
                                    this.topFormItem1.setFieldsValue('manufacture',one?.manufacture??null);
                                }
                            });
                        }else {
                            this.setState({formData1:formData1});
                            this.topFormItem1.setFieldsValue('manufacture',null);
                        }

                    },
                    data:[]
                },
                {variableName: 'manufacture', str: '设备生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'hdstno', str: '控制器编号', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'devName', str: '控制器名称', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
            ]
        },
        formData2:null,
        // 探测器
        topFormItem_2: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                // {variableName: '1', str: '探测器类型', rules: TYPE_NAME.REQ_SELECT,disabled: false, data: [{key:'1',value:'系统式'}],},
                {variableName: 'devModelName',
                    str: '设备型号',
                    fieldNames:{label: 'key', value: 'name', children: 'children'},
                    rules: FORM_ITEM.AutoComplete,
                    disabled: false,
                    onChange: (k,v) => {
                        var formData2 = this.state.formData2;
                        formData2=null;
                        if(v?.key){
                            this.state.topFormItem_2?.parameter[0]?.data.map(one=>{
                                console.log(one);
                                if(one?.key.toString()===v?.key.toString()){
                                    formData2=one;
                                    this.setState({formData2:formData2});
                                    this.topFormItem2.setFieldsValue('manufacture',one?.manufacture??null);
                                }
                            });
                        }else {
                            this.setState({formData2:formData2});
                            this.topFormItem2.setFieldsValue('manufacture',null);
                        }
                    },
                    data:[]
                },
                {variableName: 'manufacture', str: '设备生产厂家', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'routeno', str: '路号', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'hdstno', str: '探测器编号', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'devName', str: '探测器名称', rules: TYPE_NAME.NOT_BLANK,disabled: false,},
                {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT,disabled: false,},
            ]
        },


        selectDisplay_1:'系统式',
        selectInput_2_newValue:[],      // 手学输入
        selectInput_2_record:[],        // 选择数据
        // 探测器数据
        topFormItem:{
            communicateno:'',// 设备通信号
            devModelId:'',
            devModelName:'',// 设备型号
            manufacture:'', // 设备生产厂家
            routeno:'',     // 路号
            hdstno:'',      // 探测器编号
            devName:'',     // 探测器名称
        },

        // 探测器设备
        topFormItem_devModelName_data:[],
        tableIndex:null,
        tableParamenter_1:[
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName',width:"120px"},
            {title: '路号', dataIndex: 'ikRouteNo', key: 'ikRouteNo',width:"100px"},
            {title: '探测器编号', dataIndex: 'hdstno', key: 'hdstno',width:"100px"},
            {title: '探测器名称', dataIndex: 'devName', key: 'devName',width:"100px"},
            {title: '设备供应商', dataIndex: 'manufacture', key: 'manufacture',width:"100px"},
            {title: '设备状态', dataIndex: 'state', key: 'state',width:"100px"},
            {title: '生产日期', dataIndex: 'manufactureTime', key: 'manufactureTime',width:"100px",
                render: (_, record,index) =>{
                return<>
                    {record?.manufactureTime?moment(record.manufactureTime,).format('yyyy-MM-DD'):null}
                </>
                }
            },
            {title: '操作', dataIndex: 'edit', key: 'edit',width:"100px",
                render: (_, record,index) =>
                    <>

                        <div className={'tableLink'} title={'修改'}
                             onClick={async ()=>{
                                 var {topFormItem_1,selectInput_2_record} = this.state;

                                 topFormItem_1=record;
                                 topFormItem_1.routeno=record.ikRouteNo;     // 路号
                                 selectInput_2_record=[];// 清空所选探测器 设备型号 数据-没用了
                                 selectInput_2_record.push(record.devModelName);// 设备型号-没用了
                                 var topFormData=record;
                                 topFormData.routeno=record.ikRouteNo;     // 路号
                                 topFormData.manufactureTime=moment( record.manufactureTime);

                                 await this.setState({
                                     modalVisible:true,
                                     topFormItem_2_data:topFormData,// 表单数据
                                     topFormItem_1,
                                     selectInput_2_record,
                                     tableIndex:index,
                                     formType:1,
                                     devModelName:record.devModelName
                                 });
                                 this.topFormItem2.setFieldsValues(record);
                             }}
                        >
                            <u>{'修改'}</u>
                        </div>

                        <div className={'tableLink'} title={'删除'}>
                            <Popconfirm title="是否删除探测器?"
                                        onConfirm={()=>{
                                            if(record.state!=='已安装'){
                                                if(record?.id){
                                                    this.removeDev1(record?.id,'10168');
                                                }else{
                                                    var tableParamenter_1_data = [...this.state.tableParamenter_1_data];
                                                    tableParamenter_1_data.splice(index,1,);
                                                    this.setState({tableParamenter_1_data:tableParamenter_1_data});
                                                }


                                            }else{
                                                message.info('已安装设备不应许删除')
                                            }

                                        }}
                                // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>删除</a>
                            </Popconfirm>
                        </div>
                    </>
            }
        ],
        tableParamenter_1_data:this.props?.location?.rowData?.childrenList?this.props?.location?.rowData?.childrenList:[],


        deviceClassId:10168,

        button_loading_1:false,

        // 10196 控制器
        // 10198 独立式
        // 10168 系统是undefined
    };

    // 删除探测器
    removeDev1=(devId,devType,index)=>{
        console.log(devId);
        console.log(devType);
        removeDev({devId:devId,devType:devType}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功');
                    this.listDeviceModelByDeviceClassId_1();
                    var tableParamenter_1_data = [...this.state.tableParamenter_1_data];
                    tableParamenter_1_data.splice(index,1,);
                    this.setState({tableParamenter_1_data:tableParamenter_1_data});
                    // this.table1.refreshTable(this.state.tableParamenter_2.params);
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }

            }
        )
    };

    componentDidMount =async ()=> {

        console.log('添加设备');
        var {tableParamenter_1_data} = this.state;
        tableParamenter_1_data=[];

        if(this.props?.location?.rowData){
            this.props?.location?.rowData?.childrenList?.map(one=>{
                var param=one;
                param.routeno=one?.ikRouteNo || null;
                tableParamenter_1_data.push(param);
            });
            var topFormData=this.props?.location?.rowData;
            topFormData.manufactureTime=moment( topFormData?.manufactureTime);
        }

        // await this.topFormItem1?.onModalClose();// 刷新表单
        await this.setState({
            tableParamenter_1_data, topFormItem_11_data: topFormData
        });
        await this.topFormItem1?.onModalClose();// 刷新表单
        // 获取选择框数据
        this.listDeviceModelByDeviceClassId();// 根据设备类型id查询设备型号信息
        this.listDeviceModelByDeviceClassId_1(); // 探测器
    };
    // 探测器
    listDeviceModelByDeviceClassId_1=()=>{
        var deviceClassId = this.state.selectDisplay_1==='系统式'?10168:10198;
        // 获取探测器设备型号
        listDeviceModelByDeviceClassId({deviceClassId : deviceClassId}).then(
            response=>{
                if(response?.data?.data){
                    console.log('获取探测器设备型号');
                    var topFormItem_devModelName_data= this.state.topFormItem_devModelName_data;
                    var topFormItem_2 = this.state.topFormItem_2;
                    topFormItem_devModelName_data=[];
                    response.data.data.forEach(one =>{
                        var parameter={
                            classId:one.classId,
                            key:one.devModelId,
                            value:one.devModelName,
                            manufacture:one.manufacture,
                            remark:one.remark,
                            searchName:one.searchName,
                            thresholdValue:one.thresholdValue,
                            state:one.state
                        };
                        topFormItem_2.parameter[0].data.push(parameter);
                        topFormItem_devModelName_data.push(parameter)
                    });
                    this.setState({topFormItem_devModelName_data,})
                }else {
                    // message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('接口请求问题，异常错误，请联系管理员')
        })
    };
    // 根据设备类型id查询设备型号信息
    listDeviceModelByDeviceClassId=()=>{
        // 获取控制器型号数据
        listDeviceModelByDeviceClassId({deviceClassId : '10196'}).then(
            response=>{
                if(response?.data?.data){

                    var formItem11 = this.state.topFormItem_11;
                    var data=response.data.data;
                    for(var i=0;i<data.length;i++){
                        var parameter={
                            classId:data[i].classId,
                            key:data[i].devModelId,
                            value:data[i].devModelName,
                            manufacture:data[i].manufacture,
                            remark:data[i].remark,
                            searchName:data[i].searchName,
                            thresholdValue:data[i].thresholdValue
                        };
                        formItem11.parameter[2].data.push(parameter)
                    }
                    this.setState({topFormItem_11:formItem11});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });

    };
    updataDetector=async(tableIndex)=>{
        var forms1 = await this.topFormItem2.onFinish();
        if(!forms1)return false;
        let tableParamenter_1_data = [...this.state.tableParamenter_1_data];

        forms1.id=this.state.topFormItem_2_data.id;
        forms1.devModelId=this.state?.formData2?.key??this.state.topFormItem_2_data.devModelId;
        forms1.devId=this.state.topFormItem_2_data.devId;
        forms1.ikRouteNo= forms1.routeno;
        tableParamenter_1_data[tableIndex]=forms1;

        this.setState({modalVisible:false,tableParamenter_1_data:tableParamenter_1_data})
    };
    // 添加探测器-再用
    addDetector = async value =>{
        var forms1 = await this.topFormItem2.onFinish();
        if(!forms1){return false;}
        let tableParamenter_1_data = [...this.state.tableParamenter_1_data];

        forms1.devModelId=this.state.formData2?.key??this.state.topFormItem_2_data.devModelId;
        tableParamenter_1_data.push(forms1);
        console.log(forms1);
        this.setState({modalVisible:value,tableParamenter_1_data:tableParamenter_1_data})
    };

    //--新增控制器-再用
    createDetectorClick= async value =>{

        // ---------------------------------------------------------
        var {formData1,tableParamenter_1_data} = this.state;
        var data1 = await this.topFormItem1.onFinishNotReset();
        if(!data1) return false;


        data1.devId=this.props?.location?.rowData?.devId??null;
        data1.devModelId=formData1?.key ?? this.props?.location?.rowData?.devModelId;
        data1.id=this.props?.location?.rowData?.id?this.props?.location?.rowData.id:null;
        data1.deviceType=10196;
        data1.childrenList=[];
        data1.areaId=JSON.parse(localStorage.getItem('menus'))?.[0];
        tableParamenter_1_data?.forEach(one=>{
            one.deviceType=10168;
            one.communicateno=data1.communicateno;
            data1?.childrenList.push(one);
        });
        //--------------------------------------------------
        this.setState({button_loading_1:true});
        if(data1?.id===null){
            addDevice(data1).then(
                response=>{
                    if(response.data?.data){
                        this.props.history.push({
                            pathname: '/deviceDataInfo/deviceDataInfo',
                            page:this.props?.location?.page
                        });
                        message.info("添加成功");
                    }else{
                        message.info("添加失败");
                    }
                    this.setState({button_loading_1:false});
                }
            )
        }else{
            updateDevice(data1).then(
                response=>{
                    if(response.data?.data){
                        this.props.history.push({
                            pathname: '/deviceDataInfo/deviceDataInfo',
                            page:this.props?.location?.page
                        });
                        message.info("修改成功");
                    }else{
                        message.info("修改失败");
                    }
                    this.setState({button_loading_1:false});
                }
            )
        }
    };

    // 探测器选择内容添加-没用
        handleChange=(value)=>{
        console.log('探测器选择内容添加');
        console.log(value);
        var topFormItem = this.state.topFormItem_2;// 表单参数
        var deviceClassId=this.state.deviceClassId;// 提交参数设备编号
        var params;// 表单数据参数
        if(value==='系统式'){
            deviceClassId=10168; // 10168 系统是
            params= {variableName: 'routeno', str: '路号', rules: TYPE_NAME.NOT_BLANK};
            for(var i=0;i<topFormItem.parameter.length;i++){
                if(topFormItem.parameter[i].variableName==='communicateno'){
                    console.log("通讯号");
                    topFormItem.parameter.splice(i,1);
                }else if(topFormItem.parameter[i].variableName==='routeno'){
                    console.log("路号");
                    topFormItem.parameter.splice(i,1);
                }
            }
            topFormItem.parameter.push(params);
        }else{
            deviceClassId=10198; // 10198 独立式
            params={variableName: 'communicateno', str: '通信号', rules: TYPE_NAME.SELECTINPUT,};
            for(var i=0;i<topFormItem.parameter.length;i++){
                if(topFormItem.parameter[i].variableName==='routeno'){
                    console.log("路号");
                    topFormItem.parameter.splice(i,1);
                }else if(topFormItem.parameter[i].variableName==='communicateno'){
                    console.log("通讯号");
                    topFormItem.parameter.splice(i,1);
                }
            }
            topFormItem.parameter.push(params)
        }
        this.listDeviceModelByDeviceClassId_1();
    };

        // 没用
    onChangeSelect = (value, index,data) => {
        const { selectInput_2_record ,topFormItem} = this.state;
        console.log('===============点击的value', value);
        if(data){
            topFormItem.devModelId=data.key;
        }
        selectInput_2_record[index] = value || '';
        this.setState({selectInput_2_record,topFormItem});
    };

    onSearchSelect = (value, index) => {
        var {selectInput_2_newValue,topFormItem} = this.state;
        topFormItem.devModelId=null;
        selectInput_2_newValue=[];
        if (!!value) {
            selectInput_2_newValue.push(value || '');
            this.setState({selectInput_2_newValue,topFormItem});
        }
    };

    onBlurSelect = (index) => {
        const { selectInput_2_newValue } = this.state;
        const value = selectInput_2_newValue[index];
        console.log('===============输入的value', value);
        if (!!value) {
            this.onChangeSelect(value, index);
            delete selectInput_2_newValue[index]; // 在onBlur后将对应的key删除，防止当从下拉框中选择后再次触发onBlur时经过此处恢复成原来的值
        }
    };
    onChangeSelect_1 = (value, index,data) => {
        const { selectInput_1_record,selectInput_1_record_id } = this.state;
        let devModelId=this.state.selectInput_1_record_id;
        if(data){
            devModelId=data.key;
        }
        selectInput_1_record[index] = value || '';
        this.setState({selectInput_1_record,selectInput_1_record_id:devModelId});
    };

    onSearchSelect_1 = (value, index) => {
        var {selectInput_1_newValue,selectInput_1_record_id} = this.state;
        selectInput_1_record_id=null;
        selectInput_1_newValue=[];
        if (!!value) {
            selectInput_1_newValue.push(value || '');
            this.setState({selectInput_1_newValue,selectInput_1_record_id});
        }
    };

    onBlurSelect_1 = (index) => {
        const { selectInput_1_newValue } = this.state;
        const value = selectInput_1_newValue[index];
        if (!!value) {
            this.onChangeSelect_1(value, index);
            delete selectInput_1_newValue[index]; // 在onBlur后将对应的key删除，防止当从下拉框中选择后再次触发onBlur时经过此处恢复成原来的值
        }
    };


    render() {
        return(
            <Layout className={'item_body_number_back tables'}>
                <div id={'addDevicePage'}>
                    <div>
                        {/*返回上层*/}
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() =>
                            {
                                this.props.history.push({
                                    pathname: '/deviceDataInfo/deviceDataInfo',
                                    state:{page:this.props?.location?.page}
                                })
                            }}
                            ><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>设备信息管理</span>
                        </div>
                        {/*返回上层END-----*/}
                    </div>
{/*控制器表单----------------------------------------------------------------------------------------------*/}
                    <div className={"body_css"}>
                        <div>
                            <div className={'FormsTwo_1'}>
                                <div style={{"width":"100%","padding-left":"70px"}}>
                                    <span style={{"font-size":"18px","font-weight": "bold"}}>创建设备类型：可燃气体报警控制器</span>
                                </div>
                                <div  style={{"width":"80%","margin-left":"20px"}}>
                                    <FormsTwo
                                        ref={e => this.topFormItem1 = e}
                                        formParamenter={this.state.topFormItem_11}
                                        data={this.state.topFormItem_11_data}
                                    />
                                </div>
                            </div>
                        </div>
{/*探测器表格----------------------------------------------------------------------------------------------*/}
                        <div >
                            <div className={'topBtnBox'}>
                                <span className={'addLink'}
                                      onClick={async () => {
                                          if(this.state?.tableParamenter_1_data.length===0){
                                              this.setState({modalVisible:true,formType:0});
                                          }else{
                                              var tableParamenter_1_data = this.state.tableParamenter_1_data;
                                              console.log(tableParamenter_1_data);
                                              var data = tableParamenter_1_data[0];
                                              data.installDate= data?.installDate?moment(data.installDate):null;
                                              data.manufactureTime= data?.installDate?moment(data.manufactureTime):null;
                                              await this.setState({
                                                  modalVisible:true,
                                                  formType:0,
                                                  topFormItem_2_data:data
                                              });
                                              this?.topFormItem2?.setFieldsValues(data);
                                          }
                                      }}>
                                    添加新探测器
                                </span>
                            </div>
                            <div>
                                <Table
                                    columns={this.state.tableParamenter_1}
                                    dataSource={this.state.tableParamenter_1_data}

                                    rowKey={"id"}
                                    bordered
                                    scroll={{ x: 'max-content',y:300 }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    pagination={false}
                                    // pagination = {{
                                    //     showSizeChanger:true,
                                    //     showQuickJumper: true,
                                    //     // onChange: this.getPaginationRight,       // 获得翻页数值
                                    //     total:this.state.tableParamenter_1_data?.length,
                                    //     pageSize:5,
                                    //     showTotal: (count = this.state.tableParamenter_1_data?.length)=>{
                                    //         return '共'+this.state.tableParamenter_1_data?.length+'条数据'
                                    //     },
                                    //     defaultCurrent: 1,
                                    //     pageSizeOptions: ['5']
                                    // }}
                                />
                            </div>
                            <div className={"addbutton_css"}>
                                <Button type="primary" loading={this.state.button_loading_1} onClick={this.createDetectorClick }>提交</Button>
                            </div>
                        </div>
                    </div>
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div>
                        {/*弹出窗口-1*/}
                        <Modal
                            title={this.state.formType===0?'创建探测器':'修改探测器'}
                            style={{ top: 0 }}
                            visible={this.state.modalVisible}
                            okText="确定"
                            onOk={() => {
                                if(this.state.formType===0){
                                    this.addDetector(false)
                                }else{
                                    this.updataDetector(this.state.tableIndex);
                                }
                                }}           // 点击确定
                            onCancel={() => this.setState({modalVisible:false})}       // 点击取消
                            width={800}
                            centered
                        >
                            <div>
                                <div className={'FormsTwo_2'}>
                                    <div style={{"width":"100%","padding-left":"70px"}}>
                                        {/*<span style={{"font-size":"18px","font-weight": "bold"}}>创建设备类型：可燃气体报警控制器</span>*/}
                                    </div>
                                    <div  style={{"width":"70%","margin-left":"80px"}}>
                                        <FormsTwo
                                            ref={e => this.topFormItem2 = e}
                                            formParamenter={this.state.topFormItem_2}
                                            data={this.state.topFormItem_2_data}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/*弹出窗口-1END-----*/}
                    </div>
                </div>
            </Layout>
        )
    }
}

