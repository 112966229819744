import React, { Component } from 'react'
import {Layout} from "antd";
import './middleRight.less'
import {pageHiddenDanger, visualizationSafetyAnalysis} from "../../../services/meter";
export default class middleRight extends Component {
    state={
        tableData:[
            {key:1,name:'周师傅',data:'报警器、安全阀、压力...',type:'已处理'},
            {key:2,name:'友谊阿波罗',data:'燃气设施接触电线',type:'已处理'},
            {key:3,name:'宜家家居',data:'燃具无熄火保护装置',type:'已处理'},
            {key:4,name:'检测产业园',data:'燃气设施接触电线',type:'未处理'},
        ],
        "majorHiddenDangerNum": "0",        // 重大隐患数
        "lowHiddenDangerNum": "0",          // 一级隐患数
        "intermediateHiddenDangerNum": "0", // 二级隐患数
        "annualSecurityCheckRate": "0",     // 年度安检率
        "monthlySelfInspectionRate": "0",   // 月度自检率
        "allHiddenDangerNum": "0"           // 隐患数
    };

    componentDidMount(): void {
        this.visualizationSafetyAnalysis(); // 可视化燃气大屏-安全分析
        // this.pageHiddenDanger();
    }


    getTable=()=>{
        var tableData = this.state.tableData;
        var i=true;
        return<>
            {
                tableData.map(one=>{
                    i=!i;
                   return(
                       <div className={i?'middleRight_data_type_2_table_div_1':'middleRight_data_type_2_table_div_2'}>
                           <div style={{width:'40%',height:'100%',float:'left',paddingLeft:'1%'}}>
                               <span style={{color:"rgb(185 196 207)"}}>{one.name}</span></div>
                           <div style={{width:'40%',height:'100%',float:'left',paddingLeft:'1%'}}>
                               <span style={{color:"rgb(185 196 207)"}}>{one.level}</span></div>
                           <div style={{width:'20%',height:'100%',float:'left',paddingLeft:'1%'}}>
                               <span style={{color:one.type==='未处理'?'#FF6236':'#FFB220'}}>{one.type}</span></div>
                       </div>
                   )
                })
            }
        </>
    };


    // 可视化燃气大屏-安全分析
    visualizationSafetyAnalysis=()=>{
        visualizationSafetyAnalysis().then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    this.setState({
                        "majorHiddenDangerNum": data?.majorHiddenDangerNum??"0",                // 重大隐患数
                        "lowHiddenDangerNum": data?.lowHiddenDangerNum??"0",                    // 一级隐患数
                        "intermediateHiddenDangerNum": data?.intermediateHiddenDangerNum??"0",  // 二级隐患数
                        "annualSecurityCheckRate": data?.annualSecurityCheckRate??"0",          // 年度安检率
                        "monthlySelfInspectionRate": data?.monthlySelfInspectionRate??"0",      // 月度自检率
                        "allHiddenDangerNum": data?.allHiddenDangerNum??"0"                     // 隐患数
                    })
                }

            }
        );
    };

    // 隐患列表
    pageHiddenDanger=()=>{
        var params={
            "page": 1,
            "size": 10,
            "state": "未整改"
        };
        pageHiddenDanger(params).then(
            response=>{
                console.log('安全评估列表');
                var tableData4 = this.state.tableData;
                tableData4=[];
                tableData4.push({type:'对象',name:'隐患名称',level:'分级',state:'状态'});
                if(response?.data?.data){response.data.data.map(one=>{tableData4.push(one)});}
                this.setState({tableData:tableData4});
            }
        )
    };

    render() {
        const {
            majorHiddenDangerNum,lowHiddenDangerNum,intermediateHiddenDangerNum,
            annualSecurityCheckRate,monthlySelfInspectionRate,allHiddenDangerNum
        } = this.state;
        return (
            <div id={'middleRight'}>
                <div className={'middleRight_head_type'}>
                    <span className={'middleRight_head_type_title'}>
                        安全分析
                    </span>
                </div>
                <div className={'middleRight_data_type'}>
                    {/*一排*/}
                    <div style={{width:'100%',height: '30%'}}>
                        <div className={'middleRight_data_type_1_div_1'}>
                            <div style={{width:'5vw',height:'70%',paddingTop:'20px'}}>
                                <div className={'middleRight_data_type_1_div_1_img'}>
                                    <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                        <span style={{color:"#fff",fontWeight:"bold",fontSize:'20px'}}>{majorHiddenDangerNum}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding:'10px 0 0 20px'}}>
                                <span className={'middleRight_data_type_1_title'}>重大隐患</span>
                            </div>
                        </div>
                        <div className={'middleRight_data_type_1_div_2'}>
                            <div style={{width:'5vw',height:'70%',paddingTop:'20px'}}>
                                <div className={'middleRight_data_type_1_div_2_img'}>
                                    <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                        <span style={{color:"#fff",fontWeight:"bold",fontSize:'20px'}}>{lowHiddenDangerNum}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding:'10px 0 0 20px'}}>
                                <span className={'middleRight_data_type_1_title'}>一级隐患</span>
                            </div>
                        </div>
                        <div className={'middleRight_data_type_1_div_3'}>
                            <div style={{width:'5vw',height:'70%',paddingTop:'20px'}}>
                                <div className={'middleRight_data_type_1_div_3_img'}>
                                    <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                        <span style={{color:"#fff",fontWeight:"bold",fontSize:'20px'}}>{intermediateHiddenDangerNum}</span>
                                    </div>
                                </div>
                            </div>
                            <div style={{padding:'10px 0 0 20px'}}>
                                <span className={'middleRight_data_type_1_title'}>二级隐患</span>
                            </div>
                        </div>
                    </div>
                    {/*二排*/}
                    <div style={{width: '100%',height: '32%'}}>
                        <div style={{width:'100%',height:'15%'}}>
                            <span style={{fontSize:'14px',color:'#BAE9FF',float:'right',paddingRight:'10%'}}>更多 ></span>
                        </div>
                        <div style={{width:'100%',height:'85%',overflowY: 'auto'}}>

                            {this.getTable()}
                        </div>
                    </div>
                    {/*三排*/}
                    <div style={{width: '100%',height: '38%',paddingLeft:'10%'}}>
                        <div style={{width: '100%',height: '30%',paddingTop:'3%'}}>
                            <span className={'middleRight_head_type_title'}>安全检查</span>
                        </div>
                        <div style={{width: '90%',height: '68%'}}>
                            <div style={{width:'5vw',height:"12vh",float:'left'}}>
                                <div style={{width: '100%',height: '65%'}}>
                                    <div className={'middleRight_data_type_3_img_1'}>
                                        <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                            <span style={{color:"#fff",fontWeight:"bold",fontSize:'18px'}}>{annualSecurityCheckRate}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"35%",paddingTop:'5px',textAlign: 'center'}}>
                                    <span className={'middleRight_data_type_1_title'}>年度安检</span>
                                </div>
                            </div>
                            <div style={{width:'5vw',height:"12vh",float:'left',marginLeft:'12%'}}>
                                <div style={{width: '100%',height: '65%'}}>
                                    <div className={'middleRight_data_type_3_img_2'}>
                                        <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                            <span style={{color:"#fff",fontWeight:"bold",fontSize:'18px'}}>{monthlySelfInspectionRate}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"35%",paddingTop:'5px',textAlign: 'center'}}>
                                    <span className={'middleRight_data_type_1_title'}>当月自检</span>
                                </div>
                            </div>
                            <div style={{width:'5vw',height:"12vh",float:'left',marginLeft:'12%'}}>
                                <div style={{width: '100%',height: '65%'}}>
                                    <div className={'middleRight_data_type_3_img_3'}>
                                        <div style={{width: '100%',height:'30px',textAlign:'center'}}>
                                            <span style={{color:"#fff",fontWeight:"bold",fontSize:'18px'}}>{allHiddenDangerNum}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"35%",paddingTop:'5px',textAlign: 'center'}}>
                                    <span className={'middleRight_data_type_1_title'}>隐患数</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
