import React, { Component } from 'react'
import {Button, Layout, message, notification} from 'antd'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getAbnormalUsage,getAllManufacturerAndModel} from '../../../services/report'
import {TYPE_NAME} from "../../../components/Const";
import FormsTwo from "../../../components/common/forms/FormsTwo";

export default class AbnormalUsage extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 下拉框长度
			selectWidth: '6.5vw',
			// 是否绑定左侧区域
			bindArea: true,
            rowSelection: false,
			width: '83.7vw',
            title: '表具异常用气报表',
			exportUrl: "/web/reportExportCtrl/exportAbnormalUsage",
			// 表头：变量和变量解释
			header: {
				company: '公司',
				readUnit: '读表单元',
                userName: '用户名称',
				installNumber: '安装',
				contractNumber: '合同',
                id: '表具编号',
                meterManufacturer: '表具厂商',
                // protocolModel: '协议型号',
                modelName: '表具型号',
                properties: '用气性质',
				userType: '用户类型',
                gasEquipment: '用气设备',
                simCard: 'SIM卡号',
				yesterdayUseGas: '前日用气量',
				todayUseGas: '当日用气量',
                fluctuationValue: '波动值'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'input',
						variableName: 'fluctuationValue',
						str: '波动值'
					},
					{
						type: 'rangePicker',
						variableName: 'dataTime',
						str: '时间'
					}
				]
			},
			params:{}
		},
		topFormItem: {
			modal: true,
			number: 4,
			labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					rules: 'select',
					variableName: 'meterManufacturer',
					str: '表具厂商',
					data: []
				},
				{
					rules: TYPE_NAME.INPUT,
					variableName: 'properties',
					str: '用气性质'
				},
				{
					rules: TYPE_NAME.INPUT,
					variableName: 'fluctuationValue',
					str: '波动值'
				},
				{
					rules: 'rangePicker',
					variableName: 'dataTime',
					str: '时间'
				},
			],
			data:{}
		},
	};
	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter,topFormItem} = this.state;
				let {data} = response.data;

				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});


				topFormItem.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});
				this.setState({tableParamenter,topFormItem})
			}
		)
	}
	// 条件选择：获取输入的条件
	getConditionsValue = ({dataTime}) => (
		{
			startTime: dataTime ? moment(dataTime[0]?._d).format("YYYY-MM-DD HH:mm:ss") : null,
			endTime: dataTime ? moment(dataTime[1]?._d).format("YYYY-MM-DD HH:mm:ss") : null
		}
	)

    render() {
        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>

				<div style={{position:'relative'}}>
					<div className={'topFormBox'} style={{width:'80%',  margin:'10px'}}>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.topFormItem}
							data={this.state.topFormItem.data}
						/>

						<Button style={{position:'absolute', left:'82%',top:'10px'}}
								type="primary"
								onClick={async() => {
									let formData = await this.forms.onFinishNotReset();
									let menusStr = localStorage.getItem('menus');
									let menus = ((menusStr !== null || menusStr !== "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : [];

									if(!formData){return false;}
									var params={
										meterManufacturer:formData.meterManufacturer || null,
										properties:formData.properties || null,
										fluctuationValue : formData.fluctuationValue || null,
									};
									if(formData?.dataTime){
										params.startTime=moment(formData?.dataTime[0]).format("YYYY-MM-DD");
										params.endTime=moment(formData?.dataTime[1]).format("YYYY-MM-DD");
									}

									params.areaIds=menus;

									// 导出
									// let {exportUrl,title,fileName,manageName} = this.props.paramenter;
									var exportUrl='/gateway'+'/web/reportExportCtrl/exportAbnormalUsage';
									// let {data,isImport} = this.state;
									let msgKey = 'exportTable';
									// 将对象拆分成一条链接
									const variables = Object.keys(params);
									// if(isImport) return message.warning('文件正在导出或已导出');
									// this.setState({isImport: true})
									// if(!data) return message.warning('数据为空！不支持导出');
									let link = `${exportUrl}?ajaxFileName=${('表具异常用气报表') +moment().format('YYYY-MM-DD')}`;
									//
									variables.forEach(key => {
										if(params[key]){
											if(!Array.isArray(params[key])) link += `&${key}=${params[key]}`;
											else params[key].forEach(str => link += `&${key}=${str}`);

										}
									});
									console.log(link);
									try{
										message.loading({ content: '导出中...', msgKey });
										notification['warning']({
											message: '文件导出中',
											description: '文件未生成时，不允许多次点击按钮。'
										});
										window.open(link)

									} catch (error) {

									}
								}}
						>
							导出
						</Button>

					</div>
				</div>


				{/*<TableComponents*/}
				{/*	paramenter={this.state.tableParamenter}*/}
				{/*	setData={getAbnormalUsage}*/}
				{/*	getConditionsValue={this.getConditionsValue}*/}
				{/*	ref={e => this.table = e}*/}
				{/*/>*/}
            </Layout>
        )
    }
}
