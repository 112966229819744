import React from 'react';

import * as echarts from 'echarts';
// 条形图
class BarChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };

    elementById= async =>{

        var series=[];
        // 处理单挑数据拼接
        // this.props?.data?.map(
        //     one=>{
        //         series.push(
        //             {
        //                 name: one.name,
        //                 type: 'bar',
        //                 stack: 'total',
        //                 label: {
        //                     show: false,
        //                 },
        //                 emphasis: {
        //                     focus: 'series'
        //                 },
        //                 data: one.data
        //             },
        //
        //         )
        //     }
        // );
        // this.props?.data?.map(
        //     one=>{
        //         one.data.map(
        //             two=>{
        //                 series.push(
        //                     {
        //                         name: one.name,
        //                         type: 'bar',
        //                         stack: 'total',
        //                         label: {show: false,},
        //                         emphasis: {focus: 'series'},
        //                         data: two,
        //                         color:new echarts.graphic.LinearGradient(0, 0, 1, 1, [
        //                             {
        //                                 offset: 0,
        //                                 color: '#3861fb'
        //                             },
        //                             {
        //                                 offset: 1,
        //                                 color: '#2fc0d2'
        //                             }
        //                         ])
        //                     },
        //                 )
        //             }
        //         );
        //     }
        // );
        // console.log(series);
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                //标题
                // title: {
                //     text: 'World Population'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {},
                grid: {
                    top:'10%',
                    left: '10%',
                    right: '14%',
                    bottom: '0%',
                    containLabel: true
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    // interval:this.props.number?.interval,// 数值间隔
                    data:this.props?.yData,
                    type: 'category',
                    // boundaryGap: [0, 1],
                },
                yAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    // interval:this.props.number?.interval,// 数值间隔
                    type: 'value',
                    data:this.props?.yData
                },
                // series:series
                series: [
                    {
                        name: '隐患用户',
                        type: 'bar',
                        stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.props?.data2,
                        itemStyle:{
                            normal:{
                                color:'#c46054'
                            }
                        }

                    },
                    {
                        name: '安全用户',
                        type: 'bar',//line
                        stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.props?.data1,
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(1,0,0,0,
                                    [
                                        {offset:0,color:'#57c6ff'},
                                        {offset:1,color:'#58c6fb'},
                                    ])
                            }
                        }
                    },
                    // {
                    //     name: '液化气漏气',
                    //     type: 'bar',
                    //     stack: 'b',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [32, 33, 30, 33, 39, 33, 39],
                    //     itemStyle:{
                    //         normal:{
                    //             color:'#c46054'
                    //         }
                    //     }
                    //
                    // },
                    // {
                    //     name: '液化气用量',
                    //     type: 'bar',//line
                    //     stack: 'b',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [320, 332, 301, 334, 390, 33, 39],
                    //     itemStyle:{
                    //         normal:{
                    //             color:new echarts.graphic.LinearGradient(1,0,0,0,
                    //                 [
                    //                     {offset:0,color:'#BAFD86'},
                    //                     {offset:1,color:'#dcfdd3'},
                    //                 ])
                    //         }
                    //     }
                    // },
                ]
            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
