import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Menu,Table } from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsSub'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'


import {listQrcode} from '../../../services/steamReport'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				code: '二维码编号',
				type: '二维码类型',
				createTime: '提交时间',
			},
			//子表表头
			headerChild: {
				code: '二维码编号（子表）',
				type: '二维码类型（子表）',
				createTime: '提交时间（子表）',
			},
			// 弹出框
			headers: {
				code: '二维码编号',
				type: '二维码类型',
				createTime: '提交时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'code',
					str: '二维码编号'
				}
				
			]
		},
		    	// 总条数
		totalCount: 0,
		devVisible:false, // 小窗口开关器-1
		qrId:'',
		oldCode:'',
		modTitle:'添加二维码',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'code',
					str: '二维码编号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'type',
					str: '二维码类型',
					rules: TYPE_NAME.SELECT,
					data: [
						{
							key: 'F',
							value: '防盗铅封二维码'
						}
					]
				}
			]
		},
	}
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }


	

	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>用气量报表</h3>
				<Conditions 
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>			
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {listQrcode}
					setSubData = {listQrcode}
					setTableValue={this.setTableValue}
					ref={e => this.table = e} 
				>
				</TableComponents>
            </Layout>
        )
    }
}
