import React, { Component } from 'react'
import {Layout, Button, Modal, Tree, Input, InputNumber, message} from 'antd'
import { NavLink } from "react-router-dom"
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import {TYPE_NAME} from '../../../components/Const'
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {DownOutlined,PlusSquareOutlined,SearchOutlined} from '@ant-design/icons';
import {partBgetOrdersByCon, getMeterOrderForm} from '../../../services/orderForm'
let user = JSON.parse(sessionStorage.getItem("user"));
const { DirectoryTree } = Tree;
export default class orderFormGCManagement extends Component {

    state = {

        modalVisible:false, // 弹窗-1
        modalVisible2:false, // 弹窗-2



        treeData:[],     //原版数据未进行转换
        expandedKeys: [],//树节点筛选集-模糊查询使用-记录id
        searchValue: '', //保留模糊查询输入内容
        // 弹窗树结构-2 数据
        treeNodeInfo: [
            {key:1, title:"DY150", num:0,children:[]},
            {key:2,title:"DY200",num:0,children:[]},
            {key:3,title:"DN-89",num:0,children:[]},
            {key:4,title:"DY250",num:0,children:[]},
        ],
        // 弹窗树结构-3 数据
        treeNodeRightInfo: [
            {key:1, title:"DY150", num:0,children:[]},
            {key:2,title:"DY200",num:0,children:[]},
            {key:3,title:"DN-89",num:0,children:[]},
            {key:4,title:"DY250",num:0,children:[]},
        ],
        // 条件查询栏
        topFormItem: {
            modal: true,
            number: 4,
            labelCol: 6,
            layout: "horizontal",
            parameter: [
                {variableName: 'createTime', str: '创建时间', rules: TYPE_NAME.RANGE_PICKER,},
                {variableName: 'completionTime', str: '完成时间', rules: TYPE_NAME.RANGE_PICKER,},
                {variableName: 'state', str: '订单状态',
                    // isMultiple:true,
                    disabled: false,
                    data:[
                        {key:'等待厂家接单',value:'等待厂家接单'},
                        {key:'厂家已接单',value:'厂家已接单'},
                        {key:'已发货',value:'已发货'},
                        {key:'部分发货',value:'部分发货'},
                        {key:'已收货',value:'已收货'},
                        {key:'结单',value:'结单'},
                        {key:'退单',value:'退单'}
                    ],
                    rules: TYPE_NAME.SELECT,
                },
            ]
        },
        // ----条件查询栏
        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                orderFormNo: '订单编号',
                createTime: '创建时间',
                completionTime: '完成时间',
                supplierCompany:'供应厂家',
                theTotal: '台数',
                state: '订单状态',
                edit: '操作',
            },
            // 弹出框
            headers: {
                orderFormNo: '订单编号',
                createTime: '创建时间',
                completionTime: '完成时间',
                supplierCompany:'供应厂家',
                theTotal: '台数',
                state: '订单状态'
            },
            params: {
                page:1,
                size:10,
                // supplierId:user?.orgId,
                supplierCompany:JSON.parse(sessionStorage.getItem("user")).orgName
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        // ----表格参数



    };

    // 条件选择：获取输入的条件
    getConditionsValue = async value => {

        console.log(user);
        let data = await this.forms.onFinishNotReset();

        let sTime=data.applyTime ?moment(data.createTime[0]._d) : null;
        let eTime=data.applyTime ?moment(data.createTime[1]._d) : null;
        let csTime=data.applyTime ?moment(data.completionTime[0]._d) : null;
        let ceTime=data.applyTime ?moment(data.completionTime[0]._d) : null;
        let params = this.state.tableParamenter.params;

        Object.assign(params,data);
        //创建时间
        params.applyStartTime=sTime;
        params.applyEndTime=eTime;
        // 完成时间
        params.completionTimeStart=csTime;
        params.completionTimeEnd=ceTime;
        let {tableParamenter} = this.state;
        tableParamenter.params = params;
        this.setState({tableParamenter});
        await this.table.refreshTable(params);
    };
    // --------------条件选择：获取输入的条件
    // 清空条件
    clearCondition = () => {
        let {params} = this.state;
        // params.relevanceInfoAssociationName=null,
        // params.alarmLevel=null,

        this.setState({params:params});
        this.getData();
    };
    // ----------------清空条件
    // 配置表格操作功能
    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-'; // 判断参数是否为空
        switch (value) {

            case 'theTotal':
                return <>
                    <div>
                        <span>{text}</span>
                    </div>
                    </>;

            case 'edit':
                text = rowData.state;
                return <>
                    <div className={'tableLink'}
                         title={'查看'}
                         >
                        <NavLink

                            to={{
                                pathname: "/orderForm/orderFormGCDetails",
                                state: {
                                    orderId: rowData.orderId,
                                }
                            }}
                        >
                            <u>{'查看'}</u>
                        </NavLink>

                    </div>
                    {/*{text!=='废弃'|text!=='已接单'*/}
                    {/*    ? <div className={'tableLink'}*/}
                    {/*           title={'接单'}*/}
                    {/*           onClick={() => this.toReceiptStatus(true,rowData)}>*/}
                    {/*        <u>{'接单'}</u>*/}
                    {/*    </div>*/}
                    {/*    : <div className={'tableNoLink'} title={'接单'}>*/}
                    {/*        <u>{'接单'}</u>*/}
                    {/*    </div>}*/}
                    {/*<div className={'tableLink'}*/}
                    {/*     title={'废弃'}*/}
                    {/*     onClick={() => this.toDelStatus(true,rowData)}>*/}
                    {/*    <u>{'废弃'}</u>*/}
                    {/*</div>*/}
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // ------------------配置表格操作功能
    // 表格选择框
    onChangeSelect = e => {
        console.log(111,e)
    };
    // ---------------------------表格选择框
    // 弹窗开关-1
    selectModal1=(data)=>{
        this.setState({modalVisible:data});
    };
    // 关闭弹窗-1，开启弹窗-2
    upModalData1=(data)=>{
        this.selectModal2(false);
        this.selectModal1(true);
    };

    // 弹窗开关-2
    selectModal2=(data)=>{
        this.setState({modalVisible2:data});
    };
    // 关闭弹窗-1，开启弹窗-2
    upModalData2=(data)=>{
        this.selectModal1(false);
        this.selectModal2(true);
    };
    // 订单号输入框
    inputOrderNo=(e)=>{
        const { value } = e.target;
        console.log(value)
    };
    // 树形构造-样式修改
    loop = (data) =>
        data.map(item => {
            const index = item.title.indexOf(this.state.searchValue);// 获取相同值的位置
            const beforeStr = item.title.substr(0, index);  // 截取前半部分没有相同的值
            const afterStr = item.title.substr(index + this.state.searchValue.length);  // 获取后半部分不相同的值
            const title =
                index > -1 ? (
                    <span>{beforeStr}
                    <span className="site-tree-search-value">{this.state.searchValue}</span>
                        {afterStr}
                        <span
                            style={{"color":"#f5222d","float": "right"}}
                            onClick={() => this.addModelNum(item)}
                        >添加</span>
                    </span>)
                    :
                    (<span>{item.title}
                        <span
                            style={{"color":"#f5222d","float": "right"}}
                            onClick={() => this.addModelNum(item)}
                        >添加</span>
                    </span>);
            if (item.children) {
                return { title, key: item.key, children: this.loop(item.children) };
            }
            return {
                title,
                key: item.key,
            };
        });
    // 添加商品数据
    addModelNum=(item)=>{
        let treeNodeRightInfo = this.state.treeNodeRightInfo;
        var juder=true;
        treeNodeRightInfo?.forEach(one=>{
            if(item.key===one.key){
                console.log("数据相同不能添加");
                juder = false;
            }
        });
        if(juder){
            treeNodeRightInfo.push(item);
            this.setState({treeNodeRightInfo});
        }

    };
    // 筛选根节点数据-用于检索功能需要
    selectedTree=()=>{
        // console.log("筛选根节点数据");
        let treedata = this.traverseTree();
        const expandedKeys= this.state.expandedKeys;
        const newTreeData=[];
        if(expandedKeys.length!==0){
            expandedKeys.forEach(item=>{
                treedata?.forEach(data=>{
                    if(item===data.key.toString()){
                        data.children=[];
                        newTreeData.push(data)
                    }
                })
            });
        }else{
            return this.state.treeNodeInfo
        }
        return newTreeData;
    };

    // 获取需要筛选的节点名-输入框内容
    inputFindNode=(e)=>{
        // console.log('筛选查询节点');
        const { value } = e.target;
        if(!value){
            console.log('为空');
            this.setState({
                expandedKeys:[],
                searchValue: value
            });
            return null
        }

        let treedata = this.state.treeNodeInfo;
        const expandedKeys =treedata
            .map(item =>{
                if(item.title.indexOf(value)> -1){
                    return item.key.toString();
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        console.log(expandedKeys);
        this.setState({
            expandedKeys,
            searchValue: value
        })
    };
    // 分装下节点数据源
    traverseTree=()=>{
        const treeDataList=[];
        this.state.treeNodeInfo.forEach(
            item => {
                treeDataList.push(item);
                if(item.children.length!==0){
                    item.children.forEach(data =>{
                        treeDataList.push(data)
                    })
                }
            });
        return treeDataList;
    };

    // 树形构造-样式修改
    loopesl = (data) =>
        data.map(item => {
            const title =
                (
                    <span>
                        <span  style={{"float": "left"}}>{item.title}</span>
                        <span
                            style={{"color":"#f5222d","float": "right"}}
                            onClick={() => this.deleteModelNum(item)}
                        >删除</span>
                        <InputNumber   style={{"width": "50px","float": "right"}}
                                       min={1} max={99}
                                       onChange={(number)=>{
                                           item.num =number;
                                       }}
                        />

                    </span>
                );

            return {
                title,
                key: item.key,
            };
        });
    // 删除节点
    deleteModelNum=(data)=>{
        let treeNodeRightInfo =this.state.treeNodeRightInfo;
        for(let i = 0; i<this.state.treeNodeRightInfo.length;i++){
            console.log(this.state.treeNodeRightInfo[i]);
            if(data.key===treeNodeRightInfo[i].key){
                treeNodeRightInfo.splice(i,1);
            }
        }
        this.setState({treeNodeRightInfo})
    };
    componentDidMount() {
        let dd = JSON.parse(sessionStorage.getItem("user"));
        let tableParamenter = this.state.tableParamenter;
        console.log(user);
        console.log(dd);
        if(!tableParamenter.params.supplierCompany){
            return message.info("获取不到登录人员信息，请重新登入页面")
        }else{
            tableParamenter.params.supplierCompany=dd.orgName;
            this.setState({tableParamenter:tableParamenter});
            this.table.refreshTable(this.state.tableParamenter.params)
        }
    }

    render() {

        return (
            <Layout className={'item_body_number_back tables'}>
                {/*条件搜索栏*/}
                <div className={'topSearchBox'}>
                    <h3>订单厂家</h3>
                    <div style={{position:'relative'}}>
                        <div className={'topFormBox'} style={{width:'90%'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.topFormItem}
                                data={this.state.searchGetData}
                            />
                        </div>
                        <Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                    </div>
                </div>
                {/*-----------------------------------------条件搜索栏END------------------------------------*/}



                {/*<div className={'topBtnBox'}>*/}
                {/*    <span className={'addLink'} onClick={() => this.selectModal1(true)}><PlusSquareOutlined />创建订单</span>*/}
                {/*</div>/!*------------------------------------创建订单按钮END---------------------------*!/*/}
                {/*中间表格*/}
                <div style={{padding:'0 0 0 0',background:'#fff'}}>
                    <div>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData = {partBgetOrdersByCon}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        >
                        </TableComponents>
                    </div>
                </div>{/*-----------------------------------------中间表格END---------------------------------------*/}
                {/*弹出窗口*/}
                <Modal
                    title={'创建订单'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible}
                    okText="确定"
                    onOk={() => this.selectModal1(false)}           // 点击确定
                    onCancel={() => this.selectModal1(false)}       // 点击取消
                    width={1000}
                    centered
                    footer={[
                        <Button key="back" onClick={() => this.selectModal1(false)}>返回</Button>,
                        <Button type="primary" onClick={() => this.upModalData2(false)}>下一步</Button>
                    ]}
                >
                    <div style={{"height":"60px" }}>
                        <span>
                            <span style={{"color":"#1890ff"}}><span  style={{"color":"#f5222d"}}>*</span>原始订单号：</span>
                            <Input style={{ "width":"20%" }} placeholder="输入查询数据" onChange={this.inputOrderNo}/>
                        </span>

                    </div>
                    <div style={{  "width": "100%","height": "600px"}}>
                        {/*1-选择供应厂家*/}
                        <div className={"orderXinAo_modal_div"}>
                            <div>
                                <span style={{"color":"#f5222d"}}>*</span>
                                <span style={{"color":"#1890ff"}}>供应厂家</span>
                            </div>
                            <div className={"orderXinAo_modal_directoryTree_div"}>
                                <div style={{"width": "95%","height": "10%", "padding":"4% 0 0 7%"}}>

                                </div>
                                <DirectoryTree
                                    onExpand={this.onExpands}
                                    // onSelect={this.onExpand}
                                    icon={false}
                                    switcherIcon={<DownOutlined/>}
                                    // treeData={this.loop(this.selectedTree())}
                                    treeData={this.state.treeNodeInfo}
                                />

                            </div>
                        </div>{/*  -----------------1-选择供应厂家END------------------------  */}
                        {/*2-选择型号*/}
                        <div className={"orderXinAo_modal_div"}>
                            <div>
                                <span style={{"color":"#f5222d"}}>*</span>
                                <span style={{"color":"#1890ff"}}>型号</span>
                            </div>

                            <div className={"orderXinAo_modal_directoryTree_div"}>
                                <div style={{"width": "95%","height": "10%", "padding":"4% 0 0 7%"}}>
                                    <Input.Group compact>
                                        <Input style={{ "width":"95%" }} placeholder="输入查询数据" onChange={this.inputFindNode}/>
                                         {/*<Button style={{ "width":"10%" }}  icon={<SearchOutlined />}  />*/}
                                    </Input.Group>
                                </div>
                                <div>
                                    <DirectoryTree
                                        onExpand={this.onExpands}
                                        onSelect={this.onExpand}
                                        icon={false}
                                        switcherIcon={<DownOutlined/>}
                                        treeData={this.loop(this.selectedTree())}
                                        // treeData={this.state.treeNodeInfo}
                                    />
                                </div>

                            </div>
                        </div>{/*  -----------------2-选择型号END------------------------  */}
                        {/*3-选择数量*/}
                        <div className={"orderXinAo_modal_div"}>
                            <div>
                                <span style={{"color":"#f5222d"}}>*</span>
                                <span style={{"color":"#1890ff"}}>添加数量</span>
                            </div>
                            <div className={"orderXinAo_modal_directoryTree_div"}>
                                <div style={{"width": "95%","height": "10%", "padding":"4% 0 0 7%"}}>

                                </div>
                                <DirectoryTree
                                    onExpand={this.onExpands}
                                    onSelect={this.onExpand}
                                    icon={false}
                                    fieldNames={{ title: 'title', key: 'key', children: 'children' }}
                                    switcherIcon={<DownOutlined/>}
                                    treeData={this.loopesl(this.state.treeNodeRightInfo)}
                                    // treeData={ this.state.treeNodeRightInfo}
                                    // treeData={this.loop(this.selectedTree())}
                                />
                            </div>
                        </div>{/*  -----------------3-选择数量END------------------------  */}
                    </div>
                </Modal>{/*  -----------------弹窗-1END------------------------  */}
                {/*弹出窗口2*/}
                <Modal
                    title={'创建订单'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible2}
                    okText="确定"
                    onOk={() => this.selectModal2(false)}           // 点击确定
                    onCancel={() => this.selectModal2(false)}       // 点击取消
                    width={1000}
                    centered
                    footer={[
                        <Button key="back"
                                onClick={() => this.selectModal2(false)}
                        >
                            返回
                        </Button>,
                        <Button type="primary"
                            onClick={() => this.upModalData1(false)}
                        >
                            上一步
                        </Button>,
                        <Button type="primary"
                                onClick={this.inputOrderNo}
                        >
                            创建
                        </Button>]}
                >
                         <div style={{"padding-left": "35%"}}>
                            <span style={{"color":"#1890ff"}}><span  style={{"color":"#f5222d"}}>*</span>原始订单号：</span>
                            <Input style={{ "width":"250px" }} placeholder="输入查询数据" onChange={this.inputOrderNo}/>
                        </div>
                    {/*<Input style={{ "width":"45%" }} placeholder="输入查询数据" onChange={this.inputOrderNo}/>*/}
                </Modal>{/*  -----------------弹窗-2END------------------------  */}
            </Layout>
        )
    }
}
