import { Component } from 'react'
import {Layout, message, Button, Tree, Spin} from 'antd';
import { NavLink } from "react-router-dom"
import './font/iconfont.css'
import './css/index.css'
import './css/reset.css'
import image from './images/index-bg.png'
import unnull from './images/un-null-bg.png'
import unfull from './images/un-full-bg.png'
import innull from './images/in-null-bg.png'
import infull from './images/in-full-bg.png'
import $, { data } from 'jquery'
import { AppstoreOutlined } from '@ant-design/icons';
import {  getTreeByNodeIds, testData, getChildNodesById } from '../../services/area'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/line';


import {
    meterSumByAreaIds,
    purchaseMeterSumCurrentMonthByAreaIds,
    installedMeterSumCurrentMonthByAreaIds,
    installedMeterSumCurrentByAreaIds,
    uninstalledMeterSumCurrentByAreaIds,
    selectRateInfosBy7Days,
    getNewlyTheAreaIdBy15Days,
    alarmTop5ByAreaId,
    getGasConsumptionTop10MeterInfo,
    getFileRecordTop,
    queryTopTenGasByAreaId, queryMonthDayGasAccumulate, alarmInfoByAreaId
} from '../../services/dataIntegration'
import moment from "moment";
import {getDateByUserId} from "../../services/admin";
import {meterQueryDevWorkingState} from "../../services/qrCode";
export default class RegionalInfo extends Component {

    state = {
        juder_1:1,//数值开关
        juder_2:1,//数值开关
        juder_3:1,//数值开关
        tree: {},
        // 菜单栏参数
        menus: {
            title: '区域管理',
            width: 15,
            backgroundColor: '#ffffff',
            visible: true,
            content: []
        },
        //树的参数
        expandedKeys: [],
        checkedKeys: [],
        selectedKeys: [],
        autoExpandParent: [],

        treeData: [],
        treeParentId: null,

        firstTreeId: null,

        listAreaId: null,

        treeIds: {
            areaIds: null,
        },
        areaId: "255",
        // 参数-后端
        params: {
            //表具数
            MeterNum: "",
            //表具月安装数
            MeterMonthInstall: "",
            //表具月购买数
            MeterMonthPurchase: "",
            //7日平均抄表率
            MeterReadingRate: "",
            //报警次数
            AlarmsNum: "",
            //今日累计工矿流量
            TotalWorkingSum: "",
            //未安装表具数
            UnInstallMeterNum: "",
            //已安装表具数
            AlreadyInstallMeterNum: null,
            //未安装百分比
            UnPercentage: null
        },
        // 柱状图初始化
        innitBarChart: {
            data:[],
            xData: [],
            // yData: {}
        },
        // 折线图初始化
        innitLineChart: {
            data: null,
            xData: null,
            yData: null
        },
        //报警中心
        alarmCenter: null,
        //实时报表下载
        aealTimeReportDownload: null,
        //用气量前Top10
        gasConsumption: null,
        //区域名称
        areaName: null,

        //报警ID
        alarmId:null,
        loginType:1,
    }


    async componentDidMount() {

        this.setState({loginType:JSON.parse(sessionStorage.getItem("loginType"))});
        let user = JSON.parse(sessionStorage.getItem("user"));
        let menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
        let dataAuthories = JSON.parse(sessionStorage.getItem("dataAuthories"));
        let customerNames = JSON.parse(sessionStorage.getItem("customerNames"));

        this.getAreaData();

    }

    //查询区域信息
    getAreaData = () => {
        const dataAuthories = JSON.parse(sessionStorage.getItem("dataAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("dataAuthories"))
        getTreeByNodeIds(dataAuthories).then(
            response => {
                let { menus, tree } = this.state
                menus.content = JSON.parse(response.data?.data ?? null)
                let data = menus.content
                tree.treeData = data
                if(data){
                    for(var i = 0; i < data.length; i++){
                        if(data[i].id === 259){
                            data.splice(i,1);
                        }
                    }
                    data.splice(-1, 1)

                if(menus.content.length>1){
                    let bit = menus.content[0].children?.length
                    if (bit != 1) {
                        var key = menus.content[0].id
                        var value = menus.content[0].name
                        var keyId=key

                        if(keyId==260){
                            keyId=258
                        }
                        this.setState({ areaId: key, areaName: value , alarmId:keyId})
                        this.getChildNodes(key);
                    } else {
                        var key = menus.content[0].children[0]?.id
                        var value = menus.content[0].children[0]?.name
                        var keyId=key
                        if(keyId==260){
                            keyId=258
                        }
                        this.setState({ areaId: key, areaName: value, alarmId:keyId })
                        this.getChildNodes(key);
                    }
                }

                this.setState({ treeData: menus.content, menus, tree })
                }
            }
        )

    }

    //根据区域id获取表具数
    getMeterSum = () => {
        meterSumByAreaIds(this.state.treeIds.areaIds).then(
            response => {
                const { data } = response.data
                //基本数据
                let { params } = this.state
                params.MeterNum = data
                this.setState({ params })
                this.getUninstalledMeterSumCurrent();
            }
        ).catch(
            () => {
                message.error('根据区域id获取表具数失败', [1])
            }
        )
    }

    //根据区域id查询表具月购买数
    getPurchaseMeterSumCurrentMonth = () => {
        purchaseMeterSumCurrentMonthByAreaIds(this.state.treeIds.areaIds).then(
            response => {
                const { data } = response.data
                //基本数据
                let { params } = this.state

                params.MeterMonthPurchase = data
                this.setState({ params })
            }
        ).catch(
            () => {
                message.error('根据区域查询表具月购买数失败', [1])
            }
        )
    }

    //根据区域id查询表具月安装数
    getInstalledMeterSumCurrentMonth = () => {
        installedMeterSumCurrentMonthByAreaIds(this.state.treeIds.areaIds).then(
            response => {
                const { data } = response.data
                //基本数据
                let { params } = this.state
                params.MeterMonthInstall = data
                this.setState({ params })
            }
        ).catch(
            () => {
                message.error('根据区域查询表具月安装数失败', [1])
            }
        )
    }

    //根据区域id查询表具安装数
    getInstalledMeterSumCurrent = () => {
        installedMeterSumCurrentByAreaIds(this.state.treeIds.areaIds).then(
            response => {
                const { data } = response.data
                //基本数据
                let { params } = this.state

                params.AlreadyInstallMeterNum = data
                this.setState({ params })
                this.initCircleChart();

            }
        ).catch(
            () => {
                message.error('根据区域查询表具安装数失败', [1])
            }
        )
    }

    //根据区域id查询表具未安装数
    getUninstalledMeterSumCurrent = () => {
        uninstalledMeterSumCurrentByAreaIds(this.state.treeIds.areaIds).then(
            response => {
                const { data } = response.data
                //基本数据
                let { params } = this.state
                params.UnInstallMeterNum = data
                this.setState({ params })
                this.getInstalledMeterSumCurrent();
            }
        ).catch(
            () => {
                message.error('根据区域查询表具未安装数失败', [1])
            }
        )
    }

    //根据区域id查询抄表率
    getSelectRateInfos = () => {
        // 旧 抄表率
        let para = {
            areaId: this.state.areaId
        };
        this.setState({ juder_2:1});
        selectRateInfosBy7Days(para).then(
            response => {

                if(response?.data){
                    console.log('-------------------response');
                    console.log(response?.data);
                    console.log('-------------------response');
                    const data = response?.data;
                    //基本数据
                    // let{innitBarChart} =this.state
                    let { params, innitBarChart } = this.state;
                    var number=0;
                    data?.collectionRates.map(one=>{
                        number=Number(number)+Number(one);
                    });
                    if(data?.collectionRates.length){
                        console.log('============');
                        console.log(data?.collectionRates);
                        params.MeterReadingRate = parseFloat(number/data?.collectionRates.length).toFixed(2)+'%' || 0+'%';
                        console.log(params.MeterReadingRate);
                    }else{
                        params.MeterReadingRate =  0+'%';
                    }
                    // params.MeterReadingRate = data.averageRate;
                    innitBarChart.xData = data.collectionRateDates;
                    innitBarChart.data = data.collectionRates;
                    console.log(11111111111);
                    this.setState({ params, innitBarChart, juder_2:3});
                    this.innitBarChart();
                }else{
                    this.setState({ juder_2:2});

                }
            }

        ).catch(
            () => {
                message.error('根据区域查询抄表率失败', [1])
            }
        )

        // 新 抄表率
        // var treeData =null;
        // if(this.state?.areaId !==null){
        //     treeData = [this.findTreeId(this.state?.treeData,this.state?.areaId,treeData)];
        // }else{
        //     treeData = this.state?.treeData;
        // }
        // var areaIds = [];
        // areaIds = this.forChildren(treeData,areaIds);
        // this.meterQueryDevWorkingState(areaIds);
    };
    findTreeId=(children=[],areaId,treeData)=>{
        for(var i =0 ;i<children.length;i++){
            if(children[i].id===areaId){
                treeData=children[i];
            }else if(children[i]?.children){
                treeData = this.findTreeId(children[i]?.children,areaId,treeData);
            }
        }
        return treeData;
    };
    forChildren_1= (children=[],areaIds=[])=>{
        for(var i = 0; i<children.length;i++){
            areaIds.push(children[i].id);
            if(children[i]?.children.length!==0){
                this.forChildren_1(children[i]?.children,areaIds);
            }
        }
        return areaIds;
    };
    forChildren= (children=[],areaIds=[])=>{
        for(var i = 0; i<children.length;i++){
            areaIds.push(children[i].id);
            if(children[i]?.children.length!==0){
                this.forChildren(children[i]?.children,areaIds);
            }
        }
        return areaIds;
    };
    // 查询当前登录用户下的所有设备的在线情况
    meterQueryDevWorkingState=(areaIds)=>{
        // if(!areaIds){
        //     this.getDateByUserId();
        // }
        // var params={
        //     areaIds:areaIds,
        //     nowTime:moment().startOf("day").format("YYYY-MM-DD")
        // };
        // this.setState({ juder_2:1});
        // meterQueryDevWorkingState(params).then(response=>{
        //
        //     let { params, innitBarChart } = this.state;
        //     innitBarChart.xData=[];
        //     innitBarChart.data=[];
        //     params.MeterReadingRate=0;
        //     if(response?.data?.data){
        //         var data = response?.data?.data;
        //         var number =0;
        //         data.map(one=>{
        //             number+=Number(one?.todayOnLineRate);
        //             innitBarChart.xData.push(one?.time);
        //             innitBarChart.data.push(one?.todayOnLineRate);
        //         });
        //
        //         params.MeterReadingRate = parseFloat(number/7).toFixed(0)+'%' || 0+'%';
        //
        //         this.setState({ params, innitBarChart ,juder_2:3});
        //         this.innitBarChart();
        //     }else {
        //         this.setState({ juder_2:2});
        //     }
        //
        // })
    };

    //根据区域Id号，查询15天日用气量
    getNewlyTheAreaId = () => {
        this.setState({ juder_3:1 });
        console.log('根据区域Id号，查询15天日用气量');
        var areaId=[];
        var treeData = [];
        areaId=this.forChildren_1(
            [this.findTreeId(this.state.tree.treeData,
                this.state.treeIds.areaIds[this.state.treeIds.areaIds.length-1],
                treeData)],areaId);
        const params = new URLSearchParams();
        params.append('areaId',areaId);
        //{areaId:areaIds}
        queryMonthDayGasAccumulate(params).then(
            response => {

                let {innitLineChart } = this.state;
                var dates=[];
                var datas=[];
                if(response.data.data){

                    var data=response.data.data;
                    console.log('---------------');
                    console.log(data[data.length-1]);
                    var params = this.state.params;
                    params.TotalWorkingSum=data[data.length-1].value;
                    console.log('---------------');

                    data.map(one=>{
                        dates.push(one.time);
                        datas.push(one.value);
                    });
                    innitLineChart.xData = dates;
                    innitLineChart.data = datas;
                    // innitLineChart.yData = data.minAndMax
                    this.setState({ innitLineChart,juder_3:3,params:params,});
                    this.innitLineChart();
                }else{
                    this.setState({ juder_3:2 });
                }

            }
        ).catch(
            () => {
                message.error('根据区域查询15天日用气量失败', [1])
            }
        );



        let para = {
            areaId: this.state.areaId
        };
        getNewlyTheAreaIdBy15Days(para).then(
            response => {
                const data = response?.data;
                //基本数据
                // let{innitBarChart} =this.state
                let { params } = this.state;
                // params.TotalWorkingSum = data.newlyConsumption;
                // innitLineChart.xData = data.consumptionDates
                // innitLineChart.data = data.consumptions
                // innitLineChart.yData = data.minAndMax
                this.setState({ params });
            }

        ).catch(
            () => {
                message.error('根据区域查询15天日用气量失败', [1])
            }
        )
    };

    //根据区域Id号，查询前5条报警记录
    getAlarmTop5 = () => {

        var areaIds=[];
        var treeData = [];
        areaIds=this.forChildren_1(
            [this.findTreeId(this.state.tree.treeData,
                this.state.treeIds.areaIds[this.state.treeIds.areaIds.length-1],
                treeData)],areaIds);

        let para = {
            // areaId: this.state.alarmId
            areaIds:areaIds
        };
        alarmInfoByAreaId(para).then(
            response => {
                console.log('根据区域Id号，查询前5条报警记录');
                console.log(response?.data);
                console.log('根据区域Id号，查询前5条报警记录');
                const { data } = response?.data;
                //基本数据
                // let{innitBarChart} =this.state
                let { params, alarmCenter } = this.state

                params.AlarmsNum = data?.alarmSum
                alarmCenter = data.voAlarmBaseInfos
                this.setState({ params, alarmCenter })
            }

        ).catch(
            () => {
                message.warning('根据区域查询报警信息失败', [1])
            }
        )
    };
    //查询最新时间的用气量前10的区域信息
    getGasConsumptionTop10 = () => {

        this.setState({gasConsumption:[],juder_1:1});
        var areaIds=[];
        var treeData = [];
        areaIds=this.forChildren_1(
            [this.findTreeId(this.state.tree.treeData,
                this.state.treeIds.areaIds[this.state.treeIds.areaIds.length-1],
                treeData)],areaIds);
        queryTopTenGasByAreaId({areaIds:areaIds}).then(
            response => {
                if(response?.data?.data){
                    var data = response?.data?.data;
                    var gasConsumption = this.state.gasConsumption;
                    gasConsumption=[];
                    for(var i = 0; i<10;i++){
                        if(data.length>10){
                            gasConsumption.push(data[i]);
                        }
                    }
                    this.setState({gasConsumption:gasConsumption,juder_1:3})
                }else{
                    this.setState({juder_1:2})
                }
            }
        ).catch(() => {
            this.setState({juder_1:2});
                message.error('查询最新时间的用气量前10的区域信息失败', [1])
            }
        );


        // getGasConsumptionTop10MeterInfo(this.state.treeIds.areaIds).then(
        //     response => {
        //         const data = response?.data;
        //         //基本数据
        //         // let{innitBarChart} =this.state
        //         let { gasConsumption } = this.state;
        //         if( response?.data.data){
        //
        //
        //             gasConsumption = data;
        //             this.setState({ gasConsumption })
        //         }else{
        //             gasConsumption = null;
        //             this.setState({ gasConsumption })
        //         }
        //
        //
        //     }
        //
        // ).catch(
        //     () => {
        //         message.error('查询最新时间的用气量前10的区域信息失败', [1])
        //     }
        // )
    };

    //根据区域Id号，查询最新的十条文件记录
    getFileRecordTop10 = () => {
        let para = {
            areaId: this.state.areaId,
            page: 1,
            size: 12
        }
        getFileRecordTop(para).then(
            response => {
                const { data } = response?.data

                let { aealTimeReportDownload } = this.state
                aealTimeReportDownload = data
                this.setState({ aealTimeReportDownload })
            }
        ).catch(
            () => {
                message.error('查询最新的十条文件记录失败', [1])
            }
        )
    }

    // 初始化表格数据
    getData = () => {
        // 根据区域id获取表具数
        this.getMeterSum();
        // 根据区域id查询表具月购买数
        this.getPurchaseMeterSumCurrentMonth();
        // 根据区域id查询表具月安装数
        this.getInstalledMeterSumCurrentMonth();
        // 根据区域id查询表具月安装数
        // this.getInstalledMeterSumCurrent();
        // 根据区域id查询表具未安装数
        // this.getUninstalledMeterSumCurrent();
        // 根据区域id查询抄表率
        this.getSelectRateInfos();
        //根据区域Id号，查询15天日用气量
        this.getNewlyTheAreaId();
        //根据区域Id号，查询前5条报警记录
        this.getAlarmTop5();
        //查询最新时间的用气量前10的区域信息
        this.getGasConsumptionTop10();
        //根据区域Id号，查询最新的十条文件记录
        this.getFileRecordTop10();
    }

    //获取该id下的子节点
    getChildNodes = data => {

        let param = {
            parentId: data
        }
        getChildNodesById(param).then(
            response => {
                const { data } = response.data
                //基本数据
                let { treeIds } = this.state
                treeIds.areaIds = data
                this.setState({ treeIds })
                this.getData();
                // this.initCircleChart();
            }
        ).catch(
            () => {
                message.error('该区域下查询子区域失败', [1])
            }
        )
    }

    // 环形图初始化
    initCircleChart = () => {
        // var initChart = {};
        var unData = this.state.params.UnInstallMeterNum;
        var inData=this.state.params.AlreadyInstallMeterNum;
        var num =this.state.params.MeterNum;

        if (num == 0) {
            var percentage = 0 + '%';
        } else {
            var value = (Number(unData) / num);
            var percentage = Math.floor(value * 100) + '%';
        }

        var showTxt = "未安装";
        var circleChart = echarts.init(document.getElementById('circle-chart'));
        var option = {
            tooltip: {
                trigger: 'none'
            },
            legend: {
                show: false
            },
            graphic: {
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    // text: 111 + '\n\n' + 111 + '%',
                    textAlign: 'center',
                    fill: 'rgba(255, 255, 255, 0.65)',
                    fontSize: 12
                }
            },
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    silent: true,
                    radius: ['60%', '80%'],
                    avoidLabelOverlap: false,
                    label: {
                        normal: {
                            show: true,
                            position: 'center',
                            formatter: function () {
                                return showTxt + '\n\n' + percentage
                            },
                        },

                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {
                            value: inData,
                            name: "已安装",
                            itemStyle: {
                                color: '#1D8BFF',
                                shadowBlur: 10,   // 图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果
                                shadowOffsetX: 0, // 阴影水平方向上的偏移距离
                                shadowColor: '#112949' // 阴影颜色
                            }
                        },
                        {
                            value: unData,
                            name: '未安装',
                            itemStyle: {
                                color: '#78F4FD'
                            }
                        }
                    ]
                },
                {
                    type: 'pie',
                    silent: true,
                    clockWise: false,
                    radius: ['82%', '82%'],//边框大小
                    center: ['50%', '50%'],//边框位置
                    labelLine: {
                        show: false
                    },
                    data: [
                        {
                        value: 10,
                        itemStyle: {
                            normal: {
                                borderWidth: 4,//设置边框粗细
                                borderColor: '#FDFFFF'//边框颜色
                            }
                        }
                    }
                    ]
                },
            ]
        };
        circleChart.setOption(option);
        // }

    };

    // 柱状图初始化
    innitBarChart = () => {

        if(!this.state?.innitBarChart?.data){
            return false;
        }
        // initChart.innitBarChart = function(dataX, dataY, seriesData){
        var barChart = echarts.init(document.getElementById('bar-chart'),"macarons");


        barChart.setOption({
            title: {
                text: '7日抄表率',
                textAlign: 'auto',
                textStyle: {
                    color: 'rgba(255, 255, 255, 0.45)',
                    fontSize: 12,
                    fontFamily: 'PingFang SC-Regular, PingFang SC'
                },
                padding: [
                    10,  // 上
                    0, // 右
                    0,  // 下
                    16, // 左
                ]
            },
            xAxis: {
                type: 'category',
                data: this.state.innitBarChart.xData,
                // 分隔线
                splitLine: {
                    show: false
                },
                // 坐标轴轴线
                axisLine: {
                    show: false
                },
                // 坐标轴刻度
                axisTick: {
                    show: false
                },
                // 刻度标签
                axisLabel: {
                    show: true
                }
            },
            yAxis: {
                data: [0, 0.25, 0.5, 0.75, 1],
                type: 'value',
                // 分隔线
                splitLine: {
                    show: false
                },
                // 坐标轴轴线
                axisLine: {
                    show: false
                },
                // 坐标轴刻度
                axisTick: {
                    show: false
                },
                // 刻度标签
                axisLabel: {
                    show: true
                }
            },
            series: [
                {
                    data: this.state.innitBarChart.data,
                    type: 'bar',
                    barWidth: 16,
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: '#78F4FD' },
                                    { offset: 0.1, color: '#78F4FD' },
                                    { offset: 1, color: 'rgba(120, 244, 253, 0)' }
                                ]
                            ),
                            label: {
                                show: true,
                                position: "top",
                                textStyle: {
                                    fontSize: "12",
                                    color: "rgba(255, 255, 255, 0.45)"
                                }
                            }
                        }
                    }
                }
            ]
        });
        // }
    }

    // 初始化折线图
    innitLineChart = () => {
        // initChart.innitLineChart = function (dataX, dataY, seriesData){
        var lineChart = echarts.init(document.getElementById('line-chart'));
        var lineChartOption = {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                data: this.state.innitLineChart.xData,
                // 分隔线
                splitLine: {
                    show: false
                },
                // 坐标轴轴线
                axisLine: {
                    show: false
                },
                // 坐标轴刻度
                axisTick: {
                    show: false
                },
                // 刻度标签
                axisLabel: {
                    show: true
                }
            },

            yAxis: {
                // data: this.state.innitLineChart.yData,
                // type: 'value',
                // name:'立方米(M³)',
                // name: "立方米(M³)",
                // axisLabel: {
                //     margin: 2,
                //     formatter: function (value, index) {
                //         if (value >= 10000 && value < 10000000) {
                //             value = value / 10000 + "万";
                //         } else if (value >= 10000000) {
                //             value = value / 10000000 + "千万";
                //         }
                //         return value;
                //     }
                // },
                // 分隔线
                splitLine: {
                    show: false
                },
                // 坐标轴轴线
                axisLine: {
                    show: false
                },
                show :false,
                // 坐标轴刻度
                // axisTick: {
                //     show: false,
                //     inside: true
                // },
                // // 刻度标签
                // axisLabel: {
                //     show: true
                // }
            },
            // grid: { // 配置grid的containLabel属性
            //     containLabel: true,
            // },
            series: [
                {
                    data: this.state.innitLineChart.data,
                    type: 'line',
                    smooth: true,
                    symbolSize: 0,
                    lineStyle: {
                        color: '#60D8FD' //改变折线颜色
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                { offset: 0, color: 'rgba(118, 202, 250, 0.35)' },
                                { offset: 1, color: 'rgba(60, 90, 238, 0)' }
                            ]
                        )
                    },
                    emphasis: {
                        // 鼠标hover上去的时候，拐点的颜色和样式
                        itemStyle: {
                            color: '#fff', //颜色
                            borderColor: '#0072FF', //图形的描边颜色
                            borderWidth: 2, // 描边的线宽
                            shadowBlur: 1, // 图形的阴影大小
                            shadowColor: '#0072FF', // 图形的阴影颜色
                        },
                    }
                }
            ]
        };

        lineChart.setOption(lineChartOption);
        // }
    }

    //获取树的数据
    // getTreeData = data => {
    //     // console.log("data数据======", data)
    //     let arr = []
    //     data?.forEach(val => {
    //         let bit = val.children?.length
    //         arr.push(
    //             bit != 1 ? (
    //                 <li className={"menu-item"}>
    //                     <div key={val.id} id={val.id}>
    //                         <span id={val.id} className={"icon iconfont icon-plus"}>&#xe62c;</span>
    //                         <span className={"icon iconfont icon-minus"}>&#xe62d;</span>
    //                         <span id={val.id}>{val.name}</span>
    //                     </div>
    //                     <ul className={"sub-menu-list"}>
    //                         {val.children?.map(v => {
    //                             // console.log("子节点", v)
    //                             return (!v?.children ? this.getTreeData(v.children) : (
    //                                 <div key={v.id} id={v.id}>
    //                                     <li className="sub-menu-item" id={v.id}>{v.name}</li>
    //                                 </div>
    //                             ))
    //                         })
    //                         }
    //                     </ul>
    //                 </li>) : (
    //                 <li className={"menu-item"}>
    //                     {val.children?.map(v => {
    //                         // console.log("子节点", v)
    //                         return (!v?.children ? this.getTreeData(v.children) : (
    //                             <div key={v.id} id={v.id}>
    //                                 <span id={v.id} className={"icon iconfont icon-plus"}>&#xe62c;</span>
    //                                 <span className={"icon iconfont icon-minus"}>&#xe62d;</span>
    //                                 <span id={v.id}>{v.name}</span>
    //                             </div>
    //                         ))
    //                     })
    //                     }
    //                 </li>
    //             ))
    //     })

    //     arr.splice(-1, 1);
    //     return arr
    // }


    //递归
    getTreeData = data => {
        data = Array.isArray(data) ? data : []



        // let tree = []
        // console.log(data)
        // data?.forEach(val => {
        //     let bit = val.children?.length
        //     // console.log(1111,bit,val)
        //     tree.push(
        //         bit == 1 ? (
        //             data?.map(({children}) => {
        //                 console.log("111111",children)
        //                 return {
        //                     title: children[0]?.name,
        //                     key: children[0]?.id,
        //                     disabled: false,
        //                     children:null
        //                 }
        //             }
        //             )
        //         ) : (
        //            data?.map(value => {
        //                 console.log("21212222",value)
        //                 return {
        //                     title: value?.name,
        //                     key: value?.id,
        //                     disabled: false,
        //                     children: this.getTreeData(value?.children)
        //                 }
        //             })
        //         ))
        // })



        let tree = data?.map(value => {
            let bit = value.children?.length
            if (bit == 1) {
                return {
                    title: value?.children[0]?.name,
                    key: value?.children[0]?.id,
                    disabled: false,
                    children: this.getTreeData(value?.children[0]?.children)
                }
            } else {

                return {
                    title: value?.name,
                    key: value?.id,
                    disabled: false,
                    children: this.getTreeData(value?.children)
                }
            }

        }
        )


        return tree
    };

    //获取报警中心的数据
    getAlarmCenter = data => {

        let value = []
        data?.forEach(v => {
            value.push(
                <tr className={"red-tr"}>
                    <td className={"circle-dot"}>{v.associationName}</td>
                    <td>{v.alarmType}</td>
                    <td>{v.alarmTime}</td>
                </tr>
            )
        })
        return value
    }
    //获取实时报表下载
    getRealTimeData = data => {
        let value = []
        data?.forEach((v, index) => {
            value.push(

                <li key={index} value={v.createTime} onClick={() => this.btnClick(v.filePath, index)} className={"active"}>{v.createTime}</li>

            )
        })
        return value
    }


    //报表下载的点击事件
    btnClick = data => {
        window.location.href = data
    }


    //用气量Top10
    gasConsumptionData = data => {
        if(!data || data.length===0){return null}
        if(data.length===0){return null}
        let value = [];
        let key = "01";
        var i = "100";
        data?.forEach(v => {
            let gas = (v.normalizedValue * i);

            value?.push(
                <li>
                    <p>
                        <span>{key}</span>
                        <span>{v.pointName}</span>
                        {/*<span className="use-num">{v.gasConsumption}</span>*/}
                        <span className="use-num">{v.value}</span>
                        <span>立方</span>
                    </p>
                    <div className="progress-box">
                        <div className="progress-bar" style={{ width: gas + "%" }}></div>
                    </div>
                </li>

            );
            key++;
            key = /^\d$/.test(key) ? '0' + key : key;
            // ratio = ratio - i;
        });
        return value
    };

    //展开/收起节点时触发
    onExpand = (expandedKeysValue) => {
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false
        });
    };

    //点击树节点触发
    onSelect = (selectedKeysValue, info) => {
        let key = info.node.key;
        let value = info.node.title;
        let { alarmCenter } = this.state;
        alarmCenter = null
        this.setState({ alarmCenter, areaName: value });
        this.getChildData(key);
    };

    //获取该id下的数据
    getChildData = data => {
        let param = {
            parentId: data
        };



        getChildNodesById(param).then(
            response => {
                const { data } = response.data;
                if (data.length == 1) {
                    //基本数据
                    let { treeIds, areaId,alarmId } = this.state
                    treeIds.areaIds = data;
                    areaId = data[0]
                    alarmId=data[0]
                    this.setState({ treeIds, areaId ,alarmId})
                } else {
                    //基本数据
                    let { treeIds, areaId ,alarmId} = this.state
                    treeIds.areaIds = data
                    areaId = data[data.length - 1]
                    if(areaId=260){
                        alarmId=258
                    }else{
                        alarmId=data[data.length - 1]
                    }
                    this.setState({ treeIds, areaId ,alarmId})
                }
                this.getData();
                // this.initCircleChart();
            }
        ).catch(
            () => {
                message.error('该区域下查询失败', [1])
            }
        )

    };


    render() {


        return (
            <Layout className={"regional-content"}>
                {/*横条*/}
                <header>
                    <div className={"head-title"}>
                        {this.state.loginType===3
                        ?'长沙新奥数据分析与管理系统':'智慧能源平台'}
                    </div>

                    {<div className={"background-application"}>
                        <NavLink to="/customer">
                            {/* <Button icon={<AppstoreOutlined  style={{ fontSize: '16px', color: 'Aqua ' }}/> } style={{background:'#0b4c92',color:'Aqua '}}>后台管理</Button> */}
                            <Button icon={<AppstoreOutlined style={{ fontSize: '16px', color: 'Aqua ' }} />} style={{ backgroundImage: "url(" + image + ")", color: 'Aqua ' }}>后台管理</Button>
                        </NavLink>
                    </div>}
                </header>

                <div className={"content-box clear-float"}>
                    <div className={"side-menu qq"}>

                        <Tree className={"treeColor"}
                            treeData={this.getTreeData(this.state.tree.treeData)}
                            getTreeNode={this.getAreaData}
                            onExpand={this.onExpand}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            checkedKeys={this.state.checkedKeys}
                            onSelect={this.onSelect}
                            selectedKeys={this.state.selectedKeys}
                        />

                    </div>
                    <div className="content-main">
                        <p className="main-title">{this.state.areaName}区域</p>
                        <ul className="data-list">
                            <li>
                                <p>
                                    <span className="num">{this.state.params.MeterNum}</span>
                                    <span>块</span>
                                </p>
                                <p>表具数</p>
                            </li>
                            <li>
                                <p>
                                    <span className="num">{this.state.params.MeterMonthInstall}</span>
                                    <span>块</span>
                                </p>
                                <p>表具月安装数</p>
                            </li>
                            <li>
                                <p>
                                    <span className="num">{this.state.params.MeterMonthPurchase}</span>
                                    <span>块</span>
                                </p>
                                <p>表具月购买数</p>
                            </li>
                            <li>
                                <p>
                                    <span className="num">{this.state.params.MeterReadingRate}</span>
                                </p>
                                <p>7日平均抄表率</p>
                            </li>
                            <li>
                                <p>
                                    <span className="num">{this.state.params.AlarmsNum}</span>
                                    <span>块</span>
                                </p>
                                <p>报警次数</p>
                            </li>
                            <li>
                                <p>
                                    <span className="num">{this.state.params.TotalWorkingSum}</span>
                                    <span>立方米</span>
                                </p>
                                <p>今日标况流量</p>
                            </li>
                        </ul>
                        <div className="chart-box">
                            <div className="chart-item">
                                <div className="three-box">
                                    <ul className="chart-list">
                                        <li className="chart-item-box">
                                            <p>
                                                <span className="title-dot"></span>
                                                <span className="title-txt">表具数</span>
                                            </p>
                                            <div className="circle-chart-wrap">
                                                <div className="chart-legend">
                                                    <div className="uninstall-box">
                                                        <p>未安装</p>
                                                        <p>
                                                            <span className="uninstall-num">{this.state.params.UnInstallMeterNum}</span>个
                                                        </p>
                                                        <div className="uninstall-cell cell-box">
                                                            {/* <img src="./images/un-null-bg.png"/> */}
                                                            <img src={unnull} />
                                                            <div className="cell-null" style={{ width: '78%' }}>
                                                                <img src={unfull} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="install-box">
                                                        <p>已安装</p>
                                                        <p>
                                                            <span className="install-num">{this.state.params.AlreadyInstallMeterNum}</span>个
                                                        </p>
                                                        <div className="install-cell cell-box">
                                                            {/* <img src={require("./images/in-null-bg.png")} /> */}
                                                            <img src={innull} />
                                                            <div className="cell-null" style={{ width: " 32%" }}>
                                                                {/* <img src={require("./images/in-full-bg.png")} /> */}
                                                                <img src={infull} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="circle-chart"></div>
                                            </div>
                                        </li>
                                        <li className="chart-item-box cancel-space">
                                            <p>
                                                <span className="title-dot"></span>
                                                <span className="title-txt">抄表率</span>
                                            </p>
                                            <div style={{width:'100%',
                                                height:'100%',
                                                textAlign: 'center',
                                                display: this.state.juder_2===3?'none':null
                                            }}>
                                                {this.state.juder_2===1?<><span style={{color:'#ffffff'}}>加载中</span><Spin/></>
                                                    :
                                                    <span style={{color:'#ffffff'}}>无数据</span>
                                                }
                                            </div>
                                            <div id="bar-chart" style={{display: this.state.juder_2===3?null:'none'}}></div>
                                        </li>
                                    </ul>
                                    <div className="special-box">
                                        <p>
                                            <span className="title-dot"></span>
                                            <span className="title-txt">每日标况总流量</span>
                                        </p>
                                        <div style={{width:'100%',
                                            height:'100%',
                                            textAlign: 'center',
                                            display: this.state.juder_3===3?'none':null
                                        }}>
                                            {this.state.juder_3===1?<><span style={{color:'#ffffff'}}>加载中</span><Spin/></>
                                                :
                                                <span style={{color:'#ffffff'}}>无数据</span>
                                            }
                                        </div>
                                        <div id="line-chart" style={{display: this.state.juder_3===3?null:'none'}}></div>
                                    </div>
                                </div>


                                <div className="two-box">
                                    <ul >
                                        <li className="chart-item-box">
                                            <p>
                                                <span className="title-dot"></span>
                                                <span className="title-txt">报警中心</span>
                                            </p>
                                            <div className="table-wrap qq">
                                                <table>
                                                    <tr>
                                                        <th>单位</th>
                                                        <th>异常情况</th>
                                                        <th>时间</th>
                                                    </tr>
                                                    {this.getAlarmCenter(this.state.alarmCenter)}
                                                </table>
                                            </div>
                                        </li>
                                        <li className="chart-item-box">
                                            <p>
                                                <span className="title-dot"></span>
                                                <span className="title-txt">实时报表下载</span>
                                            </p>
                                            <div className="chart-list">
                                                <ul id="testDownload">
                                                    {this.getRealTimeData(this.state.aealTimeReportDownload)}
                                                    {/* {this.download()} */}
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rank-list">
                                <p>
                                    <span className="title-dot"></span>
                                    <span className="title-txt">用气量Top10</span>
                                </p>
                                <ul className="top-rank-list qq">
                                    <div style={{width:'100%',
                                        height:'100%',
                                        textAlign: 'center',
                                        display: this.state.juder_1===3?'none':null
                                    }}>
                                        {this.state.juder_1===1?<><span style={{color:'#ffffff'}}>加载中</span><Spin/></>
                                            :
                                            <span style={{color:'#ffffff'}}>无数据</span>
                                        }

                                    </div>
                                    <div style={{ display: this.state.juder_1===3?null:'none'}}>
                                        {this.gasConsumptionData(this.state.gasConsumption)}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <script src={require("./js/jquery.min.js")}></script> */}
                {/* <script src="./js/echarts.min.js"></script>
        <script src="./js/initEcharts.js"></script>
        <script src="./js/index.js"></script> */}



            </Layout>
        )
    }
}

