import React, { Component } from 'react'
import {Layout, Button, message, Modal,} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {TYPE_NAME} from '../../../components/Const'

import FormsTwo from '../../../components/common/forms/FormsTwo'
import {
    addCustomerNew,
    addSafetyOfficer,
    getSafetyOfficerByCustomerId, removeSafetyOfficer,
    updateCustomerNew, updateSafetyOfficer
} from '../../../services/customer'
import {
    addPoTimeTemplate,
    getCusPropertiesList,
    getPoTimeTemplateByCustomerId, updatePoTimeTemplate
} from '../../../services/meter'
import {queryRelationData, queryResidentialCommunity} from "../../../services/community";
export default class residentAccount extends Component {

    state = {
        juder:2,

        // 表单参数-类型2-居民
        formParamenter_2: {
            parameter: [

                {variableName: 'province', str: '省', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'city', str: '市', disabled: false, rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'nature', str: '客户性质', rules: TYPE_NAME.REQ_SELECT, data: [{key: "", value: ""}]},
                {variableName: 'residentPopulation', str: '客户类型', rules: TYPE_NAME.REQ_SELECT,
                    onChange: (e,a) => this.onChange_1(e,a),
                    data: [
                        {key: "1", value: "小区用户"},
                        {key: "2", value: "散户"},
                    ]
                },
                {variableName: 'name', str: '客户名称', rules: TYPE_NAME.LABLE_NOT_NULL_50},
                {variableName: 'principal', str: '联系人', rules: TYPE_NAME.LABLE_NOT_NULL_20},
                {variableName: 'principalPhone', str: '联系人电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL},
                {variableName: 'detailAddress', str: '详细地址', rules: TYPE_NAME.NODESCRIPTION},
                {variableName: 'gasType', str: '气源', rules: TYPE_NAME.LABLE_NOT_NULL_50},
            ]
        },
        cusPropertiesList:[],

        // 表单参数-类型3
        formParamenter_3: {
            parameter: [
                {variableName: 'type', str: '检定类型', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'intervalType', str: '安排时期', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'number', str: '次数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true,}
            ]
        },
        // 表单参数-类型4
        formParamenter_4: {
            parameter: [
                {variableName: 'type', str: '检定类型', rules: TYPE_NAME.NOT_BLANK, disabled: true,},
                {variableName: 'intervalType', str: '安排时期', rules: TYPE_NAME.REQ_SELECT, disabled: true,
                    onChange: (e,a) => this.onChangeintervalType_2(e,a),
                    data: [{key: '1', value: '每月每天'}, {key: '2', value: '每月'}]
                },
            ]
        },

        // 默认数据
        data: this.props.location?.state?.rowData ?? {province: '湖南省', city: '长沙市',type:'1'},
        data1: {type: '安检',intervalType:'每年'},
        data2: {type: '自检'},
        nature:null,



        form_modal_1:{
            modal: true,
            number: 1,
            labelCol: 8,
            parameter: [
                {variableName: 'name', str: '负责人姓名', rules: TYPE_NAME.INPUT},
                {variableName: 'phone', str: '联系电话', rules: TYPE_NAME.PHONE_NUM},
                {variableName: 'type', str: '类型', rules: TYPE_NAME.REQ_SELECT,
                    data: [{key: '负责人', value: '负责人'}, {key: '管理员', value: '管理员'}]
                },
            ]
        },
        form_modal_1_data:null,

        table_data:[], 		//弹框表格数据
        table_loading:false,
        table_rowData:null, // 所选行数据源
        table_index:0,		// 表格所选行级
        addOrUpModel:true,	// 新增或修改
        table_param:{
            total:0,
            page:1,
            size:5,

        },

        visible_1:false,    // 弹窗开关
        visible_1_juder:'0',//0新增 1修改
        selectMode:this.props.location?.state?.rowData?.id?1:0,// 0新增，1修改

        addFormParam:[]

    };

    // 时间类型
    onChangeintervalType_2= async (e,a)=>{
        if(!e){return false;}
        var {formParamenter_4} =this.state;
        // 自定义类型选用
        if(e==='2'){
            console.log('当前表单不能有次数-1');
            if(!formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
                formParamenter_4.parameter.push({variableName: 'number', str: '次数', rules: TYPE_NAME.REQ_NUMBER,min:1,max:30, disabled: true});
            }
        }else{
            console.log('当前表单有次数去掉-2');
            if(formParamenter_4.parameter.find(one=>{if(one?.variableName==='number') return true;})){
                formParamenter_4.parameter.splice(formParamenter_4.parameter.length-1,1)
            }
        }
        this.setState({formParamenter_4})
    };


    async componentDidMount(){
        // 客户类型排版表单
        this.onChangeSelect(this.props.location?.state?.rowData?.type);

        console.log(this.props.location?.state?.rowData);

        if(this.props.location?.state?.rowData?.id){
            if(this.props.location?.state?.rowData?.residentPopulation){

                this.onChange_1(this.props?.location?.state?.rowData?.residentPopulation===1?'1':'2',null);// 初始化小区
                this.onChange_2(this.props?.location?.state?.rowData?.regionManagementId,null,true);    // 新增楼栋
                // this.onChange_3(this.props?.location?.state?.rowData?.buildingId,null);						// 单元号 units


                this.forms.setFieldsValue('residentPopulation',this.props.location?.state?.rowData?.residentPopulation??null);
                this.forms.setFieldsValue('regionManagementId',this.props.location?.state?.rowData?.regionManagementId??null);
                this.forms.setFieldsValue('buildingId',this.props.location?.state?.rowData?.buildingId??null);
                this.forms.setFieldsValue('unitId',this.props.location?.state?.rowData?.unitId??null);
            }
        }
        this.getCusAll();// 查询客户性质列表

        // // 0 新增客户，1修改客户
        // if(this.props.location?.state?.rowData?.id){
        //     this.getSafetyOfficerByCustomerId();// 查询安全员列表
        // }

        this.queryResidentialCommunity();// 获取小区列表
    };


    // 获取小区列表
    queryResidentialCommunity=(name)=>{
        var params={
            name:name??null,
            page:1,
            size:99
        };
        queryResidentialCommunity(params).then(
            response => {
                if(response?.data?.data){
                    var addFormParam = this.state.addFormParam;
                    const data = response.data.data;
                    data.map(one=>{
                        one=Object.assign({},one,{key:one.id, value:one.name});
                        addFormParam.push(one)
                    });
                    this.setState({addFormParam:addFormParam})
                }
            }
        )
    };


    // 查询安全员列表
    getSafetyOfficerByCustomerId=()=>{
        this.setState({table_loading:true});
        var params={
            "id": this.props.location?.state?.rowData?.customerId,
            "page": 1,
            "size": 5
        };
        getSafetyOfficerByCustomerId(params).then(
            response => {
                if(response?.data?.data){
                    var tableData = [...this.state.table_data];
                    var tableParam = this.state.table_param;
                    tableData = response?.data?.data;
                    tableParam.total = response.data.pageInfo.total;
                    this.setState({table_data:tableData,table_param:tableParam})
                }
                this.setState({table_loading:false})
            }
        )
    };

    onChangeSelect = e => {
        var {juder}=this.state;
        if(e==='1'){
            juder=1
        }else{
            juder=2
        }
        this.setState({juder})
    };

    // 客户类型
    onChange_1 = async (e,a) => {
        var {formParamenter_2,addFormParam} = this.state;
        if(e.indexOf(1)!==-1){// 判断选择类型是否小区

            // 循环有没有小区，又小区不新增
            if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('regionManagementId')!==-1)return true;})){
                formParamenter_2.parameter.splice(4,0,
                    {variableName: 'regionManagementId', str: '小区',
                        onChange: (e,a) => this.onChange_2(e,a),
                        rules: TYPE_NAME.REQ_SELECT, data: addFormParam
                    },);
            }
            // 循环有没有楼栋，又楼栋不新增
            if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('buildingId')!==-1)return true;})){
                formParamenter_2.parameter.splice(5,0,
                    {variableName: 'buildingId', str: '楼栋',
                        onChange: (e,a) => this.onChange_3(e,a),
                        rules: TYPE_NAME.SELECT, data: []
                    },);
            }
            // 循环有没有楼栋，又楼栋不新增
            if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('unitId')!==-1)return true;})){
                formParamenter_2.parameter.splice(6,0,
                    {variableName: 'unitId', str: '单元号',
                        rules: TYPE_NAME.SELECT, data: []
                    },);
            }
            // 循环有没有楼栋，又楼栋不新增
            if(!formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('householdNumber')!==-1)return true;})){
                formParamenter_2.parameter.splice(7,0,
                    {variableName: 'householdNumber', str: '户号', rules: TYPE_NAME.LABLE_NOT_NULL_50},);
            }
        }else{// 删除小区
            if(formParamenter_2.parameter.find(one=>{if(one.variableName.indexOf('regionManagementId')!==-1)return true;})){
                formParamenter_2.parameter.splice(4,3);
            }
        }
        this.forms.setFieldsValue('regionManagementId',null);
        this.forms.setFieldsValue('buildingId',null);
        this.forms.setFieldsValue('unitId',null);
        this.setState({formParamenter_2:formParamenter_2});
    };
    // 小区-控制楼栋
    onChange_2 = async (e,a,juder) => {
        var addFormParam = this.state.addFormParam;
        // 地址
        addFormParam.map(one=>{if(e===one.id){this.forms.setFieldsValue('detailAddress',one.address);}});

        this.forms.setFieldsValue('buildingId',null);// 清空单元号数据
        this.forms.setFieldsValue('unitId',null);// 清空单元号数据
        if(!e){return false;}
        var params={regionManagementId: e, type: 1, businessType:'小区',};
        queryRelationData(params).then(
            response => {
                var {formParamenter_2} = this.state;
                var buildingLists=[];
                if(response?.data?.data){
                    var data =response?.data?.data;
                    if(data?.buildingList){
                        data?.buildingList.map(
                            one=>{buildingLists.push({key:one?.id.toString(),value:one?.number,units:one?.units??[]})}
                        );
                    }
                }

                // 给楼栋添加下来数据
                for(var i=0;i<formParamenter_2.parameter.length; i++){
                    if(formParamenter_2.parameter[i].variableName.indexOf('buildingId')!==-1){
                        formParamenter_2.parameter[i].data=buildingLists;
                    }
                }
                // 清空单元号数据
                for(var i=0;i<formParamenter_2.parameter.length; i++){
                    if(formParamenter_2.parameter[i].variableName.indexOf('unitId')!==-1){
                        formParamenter_2.parameter[i].data=[];
                    }
                }
                // 用于控制初始化数据
                if(juder){
                    for(var i=0;i<formParamenter_2.parameter.length; i++){
                        if(formParamenter_2.parameter[i].variableName.indexOf('unitId')!==-1){
                            buildingLists.map(one=>{
                                if(one.key.indexOf((this.props.location?.state?.rowData?.buildingId??null).toString())!==-1){
                                    one?.units.map(two=>{
                                        formParamenter_2.parameter[i].data.push({key:two?.id.toString(),value:two?.name,})});
                                }
                            });
                        }
                    }
                }
                this.setState({formParamenter_2:formParamenter_2,});
            }
        )
    };

    // 楼栋-控制单元号
    onChange_3 = async (e,a) => {
        if(!e){return false;}
        this.forms.setFieldsValue('unitId',null);// 清空单元号数据
        var {formParamenter_2} = this.state;
        var units=[];


        for(var i=0;i<formParamenter_2.parameter.length; i++){
            if(formParamenter_2.parameter[i].variableName.indexOf('buildingId')!==-1){
                formParamenter_2.parameter[i].data.map(one=>{
                    if(one.key.indexOf(e)!==-1){
                        one?.units.map(two=>{units.push({key:two?.id.toString(),value:two?.name,})});
                    }
                });
            }
        }
        // 赋值
        for(var i=0;i<formParamenter_2.parameter.length; i++){
            if(formParamenter_2.parameter[i].variableName.indexOf('unitId')!==-1){
                formParamenter_2.parameter[i].data=units;
            }
        }
        this.setState({formParamenter_2:formParamenter_2})
    };

    onSave = async () => {
        let value ={id:null,type:1,address:{}};         //  数据集合
        let data = await this.forms.onFinishNotReset(); // 表单数据
        if(!data) return false;
        value=Object.assign({}, value,data);
        value.address.province=value?.province??null;   // 省
        value.address.city=value?.city??null;           // 市

        // 修改时候给上传数据赋id
        if(this.props.location?.state?.rowData?.customerId){
            value.id=this.props.location?.state?.rowData?.customerId;
        }else if(this.props.location?.state?.rowData?.id){
            value.id=this.props.location?.state?.rowData?.id;
        }

        // value.nature=this.state?.nature;
        value.areaId = JSON.parse(localStorage.getItem('menus'))?.[0];
        if(JSON.parse(localStorage.getItem('menus'))?.[0]===undefined){return message.info("请选择区域")}
        if(!value?.id){value.voSafetyOfficers=this.state.table_data;}


        console.log(value);
        Modal.confirm({
            title: `是否绑定到${localStorage.getItem('areaName')}?`,
            onOk: () => {
                message.loading({ content: value.id ? '修改中...' : '开户中...', key: 'save'});
                console.log('--------');
                console.log(value);
                // 修改
                if(value?.id){
                    updateCustomerNew(value).then(
                        response => {
                            if(response?.data?.data){
                                // funTraceInfo("客户管理", "开户", null,value)
                                message.success({ content: value.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
                                if(this.props.location?.state?.selectWidth){
                                    this.addOrUpdatePoTimeTemplate(response?.data?.data);
                                }
                                // this.props.history.replace('/customer/businessMaintain')
                                return this.props.history.go(-1);
                            }else{
                                message.error({ content: value.id ? '修改失败' : '该号码已存在或系统错误', key: 'save', duration: 2})
                            }
                        }
                    ).catch(
                        () => message.error({ content: value.id ? '修改失败' : '开户失败', key: 'save', duration: 2})
                    )
                }else{
                    addCustomerNew(value).then(
                        response => {
                            if(response?.data?.data){
                                // funTraceInfo("客户管理", "开户", null,value)
                                message.success({ content: value.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
                                if(this.props.location?.state?.selectWidth){
                                    this.addOrUpdatePoTimeTemplate(response?.data?.data);
                                }
                                // this.props.history.replace('/customer/businessMaintain')
                                return this.props.history.go(-1);
                            }else{
                                message.error({ content: value.id ? '修改失败' : '该号码已存在或系统错误', key: 'save', duration: 2})
                            }
                        }
                    ).catch(
                        () => message.error({ content: value.id ? '修改失败' : '开户失败', key: 'save', duration: 2})
                    )
                }

            }
        })
    };

    // 新增(修改)客户时间配置
    addOrUpdatePoTimeTemplate= async (data)=>{

        console.log('新增(修改)客户时间配置');

        // let forms1 = await this.forms1.onFinishNotReset();// 安检
        // let forms2 = await this.forms2.onFinishNotReset();// 自检
        // if(!forms1) return false;
        // if(!forms2) return false;
        // console.log(forms1);
        // console.log(forms2);
        // forms1.type=1;// 默认数据
        // forms2.type=2;// 默认数据

        // // 检查类型
        // forms1.intervalType=3;// 默认数据
        // //只针对控制当前每月每天只能一天
        // if((forms2.intervalType==='1') || (forms2.intervalType==='每月每天')){
        //     forms2.intervalType=1;
        //     forms2.number=1;
        // }
        // // 针对修改时
        // if((forms2.intervalType==='2') || (forms2.intervalType==='每月')){
        //     forms2.intervalType=2;
        // }
        //
        //
        // console.log(forms1);
        // console.log(forms2);
        var params =[];

        if(this.state.data1?.id){
            // forms1.id= this.state.data1?.id;
            // forms2.id= this.state.data2?.id;
            // params = [forms1,forms2];
            // 修改客户时间配置
            updatePoTimeTemplate(params).then(
                response => {
                    console.log('修改客户时间配置');
                    console.log(response.data.data);
                    if(response?.data?.data){
                        console.log(response.data.data)
                    }
                }
            )
        }else{
            // params = [forms1,forms2];
            // 新增(修改)客户时间配置
            addPoTimeTemplate(params).then(
                response => {
                    console.log('新增(修改)客户时间配置');
                    console.log(response.data.data);
                    if(response?.data?.data){
                        console.log(response.data.data)
                    }
                }
            )
        }
    };

    clearData = async  () => {
        if(this.state.data?.id){
            message.warn("修改时不允许清空")
        }else{
            await this.form?.resetFields();
        }
    };

    // 查询客户性质列表
    getCusAll =()=>{
        getCusPropertiesList().then(
            response=>{
                if(response?.data?.data){
                    let {data} = response.data;
                    let {
                        formParamenter_2
                    } = this.state;
                    formParamenter_2.parameter[2].data = this.getTreeData(data);
                    this.setState({
                        cusPropertiesList: data,
                        formParamenter_2	// 表单参数-类型2-居民
                    });
                }

            }
        )
    };
    //递归
    getTreeData = data => {
        let arr = data?.map(value => {
            return {
                key:value?.id,
                value:value?.name
            }
        });
        return arr
    };

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList:file.fileList});
    };

    // 本地新增和修改
    localityAddAndUpSafety = async () =>{
        var formData1 = await this.forms_4.onFinishNotReset();
        if(!formData1) return false;
        formData1.path=[];
        // formData1.fileList=this.state.fileList;
        this.state.fileList.map(one=>{formData1.path.push(one.response)});
        var tableData = [...this.state.table_data];// 表格数据
        var {table_param,table_index,addOrUpModel} = this.state;    // 表格参数
        // 新增或修改
        if(addOrUpModel){
            console.log('新增');
            tableData.push(formData1);
            table_param.total = tableData.length;
        }else{
            console.log('修改');
            tableData[(table_param.page-1)*table_param.size+table_index] = formData1;
        }
        this.setState({table_data:tableData,table_param:table_param,visible_1:false})
    };
    // 分页操作
    getPaginationRight=(p,s)=>{
        var tableParam = this.state.table_param;
        tableParam.page=p;
        this.setState({tableParam})
    };

    // 新增安全员
    addSafetyOfficer=async ()=>{
        var formData1 = await this.forms_4.onFinishNotReset();
        if(!formData1) return false;
        formData1.path=[];

        if(this.state.fileList.length!==0){
            this.state.fileList.map(one=>{formData1.path.push(one.response)});
        }else{
            formData1.path=this.state.table_rowData?.path??null;
        }

        formData1.customerId=this.props.location?.state?.rowData?.id;
        addSafetyOfficer(formData1).then(
            response=>{
                if(response?.data?.data){
                    this.getSafetyOfficerByCustomerId();// 查询安全员列表
                    message.info('新增成功')
                }else{
                    message.info('新增失败')
                }
                this.setState({visible_1:false})
            }
        );
    };
    // 修改安全员信息
    updateSafetyOfficer=async ()=>{
        var formData1 = await this.forms_4.onFinishNotReset();
        if(!formData1) return false;
        formData1.fileList=this.state.fileList;
        if(formData1?.fileList.length!==0){
            formData1.path=[];
            this.state.fileList.map(one=>{formData1.path.push(one.response)});
        }else {
            formData1.path=this.state.table_rowData.path
        }
        formData1.id=this.state.table_rowData.id;
        formData1.customerId=this.props.location?.state?.rowData?.customerId;
        console.log(formData1);
        console.log('-----------------------');
        updateSafetyOfficer(formData1).then(
            response=>{
                if(response?.data?.data){
                    this.getSafetyOfficerByCustomerId();// 查询安全员列表
                    message.info('修改成功')
                }else{
                    message.info('修改失败')
                }
                this.setState({visible_1:false})
            }
        );
    };

    render() {
        return (
            <Layout className={'item_body_number_back'}>
                <div className={'div_back_arrow'}>
					<span className={'back_arrow'}
                          onClick={() =>
                              // this.props.history.replace('/customer/clientBusiness')
                              this.props.history.go(-1)
                          }
                    ><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回客户管理</span>
                </div>
                {/*表单*/}
                <div className={'item_body_number'}  style={{backgroundColor:'#fff'}}>
                    <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenter_2}
                        data={this.state.data}
                    />
                    <div className={'card_open_account_div'} style={{height:'70px'}}>
                        <Button type="primary"  htmlType="submit" onClick={() => this.onSave()}>保存</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={() => this.clearData()}>清空</Button>
                    </div>
                </div>


            </Layout>
        )
    }
}
