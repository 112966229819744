import React, { Component } from 'react'
import { Layout, Input, Form, Tag } from 'antd'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getMeterReading, getAllManufacturerAndModel, queryMeterReadingRate} from '../../../services/report'

const FormItem = Form.Item

class MeterReading extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
            // 是否绑定左侧区域
			bindArea: true,
            rowSelection: false,
            title: '抄表率统计报表',
			exportUrl: "/web/reportExportCtrl/exportMeterReading",
			// 表头：变量和变量解释
			header: {
				company: '所属公司',
				readUnit: '读表单元',
				meterManufacturer: '生产厂家',
				measure: '抄表员',
				totalUsers: '总表数',
                alreadyCopyCount: '已抄表数',
				notCopyCount: '未抄表数',
				copyExample: '抄表率'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					// {
					// 	type: 'input',
					// 	variableName: 'properties',
					// 	str: '用气性质'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'readUnit',
					// 	str: '读表单元'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'meterManufacturer',
					// 	str: '生产厂家'
					// }

				]
			},
		},
	};
	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state
				let {data} = response.data
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				})
				this.setState({tableParamenter})
			}
		)
	}
	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'id':
				return Math.random()*10
			case 'copyExample':
				return text*1 < 40 ? <Tag color={'red'}>{text}%</Tag> :
						text*1 < 70 ? <Tag color={'yellow'}>{text}%</Tag> :
						text*1 < 100 ? <Tag color={'blue'}>{text}%</Tag> :
						text*1 === 100 ? <Tag color={'green'}>{text}%</Tag> : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

    render() {
        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {queryMeterReadingRate}
					ref={e => this.table = e}
					setTableValue={this.setTableValue}
				>
					{/*<FormItem label={"公司"}>*/}
					{/*	<Input disabled value={localStorage.getItem('areaName')} size='middle'/>*/}
					{/*</FormItem>*/}
				</TableComponents>
            </Layout>
        )
    }
}
export default MeterReading
