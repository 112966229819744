import React, { Component } from 'react'
import { Layout, Select, Form ,Modal,message} from 'antd'
// import TableComponents from '../../../components/common/tables/TableComponentsNew'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getApprover,examine } from '../../../services/admin'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
const { Option } = Select
const FormItem = Form.Item

export default class ApprovalManagement extends Component {
	state = {
		// 表格参数
		tableParamenter: {
			// 表格行是否可选
            rowSelection: false,
			// 表头：变量和变量解释
			header: {
				// id: '用户id',
				realName: '用户真名',
				company:"公司名称",
				phone: '手机号',
				status: '用户状态',
				positionName: '职位名',
				edit: '审批',
			},
			params: {
				page:1,
				size:10,
		},
		},
		    	// 总条数
		totalCount: 0,


	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'审批'} onClick={() => this.editStatus(rowData.id)}>
						<u>{'审批'}</u>
				</div>
				</>
			case 'status':
				return text === '0' ? '可用' : text === 1 ? '禁用' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	// updatePoint = (devVisible) => {

	// 	let rowData = this.table.getSelectChange()
	// 	if(rowData.length < 1){
	// 		return message.error('未选择数据')
	// 	}else{
	// 		this.setState({devVisible});
	// 	}
	// 	let data = {
	// 		name: rowData[0].name,
	// 		id: rowData[0].id,
	// 		dataKey: "2"
	// 	}

	// }
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	//添加设备窗口显示
	// addQrcodeStatus = async (devVisible)=>{
	// 	await this.setState({devVisible,data:{},modTitle:'添加二维码',qrId:'',oldCode:''})
	// 	await this.forms.onModalClose()
    // }
	editStatus=(id)=>{
		examine({userId: id}).then(
			response => {
				const {data} = response.data
				console.log("data的数据",data)
				this.setState({data: data})
				message.success('审批通过')
				this.table.refreshTable();// 刷新表格
				// funTraceInfo("权限管理", "用户审批", null,id)
			}
		)
    }

	// addQrcodeData = async (value)=>{
	// 		let devData = await this.forms.onFinishNotReset()
	// 		if(this.state.oldCode){
	// 			devData.oldCode=this.state.oldCode
	// 		}
	// 		if(this.state.qrId){
	// 			devData.id=this.state.qrId
	// 			updateQrcode(devData).then(data=>{
	// 				if(data.data.data==true){
	// 					message.config("修改成功")
	// 					this.addQrcodeStatus(false)
	// 				}else{
	// 					this.addQrcodeStatus(false)
	// 					message.config('修改失败')

	// 				}
	// 				this.table.refreshTable();// 刷新表格
	// 			});
	// 		}else{
	// 			addQrcode(devData).then(data=>{
	// 				if(data.data.data==true){
	// 					message.config("添加成功")
	// 					this.addQrcodeStatus(false)
	// 				}else{
	// 					this.addQrcodeStatus(false)
	// 					message.config('添加失败')

	// 				}
	// 				this.table.refreshTable();// 刷新表格
	// 			});
	// 		}

	// };

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getApprover}
					setTableValue={this.setTableValue}
					clearCondition={this.clearCondition}
					ref={e => this.table = e}
				>
				</TableComponents>
            </Layout>
        )
    }
}
