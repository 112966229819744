import React, {Component} from "react";
import {Layout} from "antd";
import FormsText from "../../../components/common/forms/FormsText";

export default class SafetyAssessmentDetailsNew extends Component {
    state = {
        // 表单参数-1
        formText: {
            header: {
                customerName: '客户名称',
                address:'详细地址',
                evaluationResults:'评估结果',
                evaluationData: '评估日期',
            }
        },

    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width:'100%',height:'100%'}}>
                    <div style={{width:'100%',height:'4%',backgroundColor:'#49ff41',textAlign: 'center'}}>
                        <span style={{fontSize:'18px',fontWeight:'bold',paddingLeft: '1vw'}}>客户信息</span>
                    </div>
                    <div style={{width:'100%',height:'26%',backgroundColor:'red'}}>
                        <div style={{width:'50%',height:'100%',backgroundColor:'#ff5e8f',float:'left'}}>
                            <FormsText
                                record={this.state?.evaluationReport ?? {}}
                                header={this.state.formText.header}
                                rowLen={1}
                                span={6}
                            />
                        </div>
                        <div style={{width:'50%',height:'100%',backgroundColor:'#8ac5ff',float:'left'}}>

                        </div>

                    </div>
                    <div style={{width:'100%',height:'40%',backgroundColor:'#fff'}}>

                    </div>
                    <div style={{width:'100%',height:'30%',backgroundColor:'red'}}>

                    </div>
                </div>
            </Layout>
        )
    }
}
