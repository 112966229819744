import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'


import {getSubmitInfoInCondition,getInitCompany,getEngineeringCompany,getSendDetectDetail} from '../../../services/account'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				odd: '送检单号',
				sendCode: '送检表',
				backCode: '备用表',
				state: '送检状态',
				result: '检定结果',
				type: '送检类型',
				sendDetectTime: '计划送检日期',
				completionTime: '送检完成日期',
				userName: '发起人',
				chargeFacName: '工程公司',
				edit: '操作',
			},
			// 弹出框
			headers: {
				odd: '送检单号',
				sendCode: '送检表',
				backCode: '备用表',
				state: '送检状态',
				result: '检定结果',
				type: '送检类型',
				sendDetectTime: '计划送检日期',
				completionTime: '送检完成日期',
				userName: '发起人',
				chargeFacName: '工程公司',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'sendCode',
					str: '送检表',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'backCode',
					str: '备用表',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'chargeFacId',
					str: '工程公司',
					// isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'orgId',
					str: '发起公司',
					// isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'sendDetectTime',
					str: '计划时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'completionTime',
					str: '完成时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'type',
					str: '类型',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'周检',value:'周检'},
						{key:'维修',value:'维修'},
					],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'state',
					str: '状态',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'0',value:'发起'},
						{key:'1',value:'创建'},
						{key:'2',value:'领表'},
						{key:'3',value:'拆装'},
						{key:'4',value:'入库'},
						{key:'5',value:'出库'},
						{key:'6',value:'维修'},
						{key:'7',value:'送检'},
						{key:'8',value:'回装'},
						{key:'9',value:'备用表入库'},
					],
					rules: TYPE_NAME.SELECT,
				},
			]
		},

		    	// 总条数
		totalCount: 0,
		loading: false,
		searchGetData:{},
		basicsData:{},
		voInstallReqData:{},
		voInstallMeterData:{},
		devVisible:false, // 小窗口开关器-1
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'查看详情'} onClick={() => this.showDetailBox(true,rowData.id)}>
						<u>{'查看详情'}</u>
				</div>
				</>
			case 'state':
				return text === 0 ? '发起' : text === 1 ? '创建' : text === 2 ? '领表' : text === 3 ? '拆装' : text === 4 ? '入库' : text === 5 ? '出库' : text === 6 ? '维修' : text === 7 ? '送检' : text === 8 ? '回装' : text === 9 ? '备用表入库' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
		getEngineeringCompany().then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[2].data=selectArry;
			}
		)
		getInitCompany().then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[3].data=selectArry;
			}
		)

		$(document).on('click', '.smallImg', function() {
			var url=$(this).attr('src')
			$('.bigImg').attr('src',url)
			$('.bigImgBox').show()
		});
		$(document).on('click', '.bigImgBox', function() {
			$('.bigImgBox').hide()
		});
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

		//详情
		showDetailBox = async (devVisible,id)=>{
			this.setState({devVisible})
			$('#submissionData').html('')		
			getSendDetectDetail({id:id}).then(
				response => {
					let data=response.data.data
					var voInitHtml=''
					var voCollarMeterHtml=''
					var voDisassemblyHtml=''
					var voDisassemblyUrl=''
					var laidUpHtml=''
					var issueHtml=''
					var voSendDetectTpHtml=''
					var voBackPackHtml=''
					var voBackPackUrl=''
					var voWarehousingHtml=''
					var voDevMaintenancesHtml=''
					if(data)
					{
					if(data.voInit)
					{
						voInitHtml='<div class="installList"><h4>发起信息</h4><div class="installInner">'+
						'<ul>'+
						'<li>送检单号：<span>'+data.voInit.odd+'</span></li>'+
						'<li>计划送检日期：<span>'+data.voInit.sendDetectTime+'</span></li>'+
						'<li>完成日期：<span>'+data.voInit.completionTime+'</span></li>'+
						'<li>送检表：<span>'+data.voInit.sendCode+'</span></li>'+
						'<li>送检状态：<span>'+data.voInit.state+'</span></li>'+
						'<li>工程公司：<span>'+data.voInit.chargeFacName+'</span></li>'+
						'<li>送检类型：<span>'+data.voInit.type+'</span></li>'+
						'</ul></div></div>'
					}
					if(data.voCollarMeter)
					{
						voCollarMeterHtml='<div class="installList"><h4>领表信息</h4><div class="installInner">'+
						'<ul>'+
						'<li>备用表：<span>'+data.voCollarMeter.code+'</span></li>'+
						'<li>领表时间：<span>'+data.voCollarMeter.collarMeterTime+'</span></li>'+
						'<li>表具厂家：<span>'+data.voCollarMeter.manufacture+'</span></li>'+
						'<li>表具型号：<span>'+data.voCollarMeter.modelName+'</span></li>'+
						'</ul></div></div>'
					}
					if(data.voDisassembly)
					{
						if(data.voDisassembly.pics)
						{
							for(var j=0;j<data.voDisassembly.pics.length;j++){
								voDisassemblyUrl+="<img class='smallImg' alt='暂未上传' src="+data.voDisassembly.pics[j]+" />"
							}
							voDisassemblyHtml='<div class="installList"><h4>拆装信息</h4><div class="installInner">'+
							'<ul>'+
							'<li>送检表底数：<span>'+data.voDisassembly.sendBaseNum+'</span></li>'+
							'<li>送检表钢号：<span>'+data.voDisassembly.sendCode+'</span></li>'+
							'<li>备用表底数：<span>'+data.voDisassembly.backBaseNum+'</span></li>'+
							'<li>备用表钢号：<span>'+data.voDisassembly.backCode+'</span></li>'+
							'<li>铅封（送检）：<span></span></li>'+
							'</ul></div></div>'+
							'<div class="installInnerImgBox">'+voDisassemblyUrl+'</div>'
						}else{
							voDisassemblyHtml='<div class="installList"><h4>拆装信息</h4><div class="installInner">'+
							'<ul>'+
							'<li>送检表底数：<span>'+data.voDisassembly.sendBaseNum+'</span></li>'+
							'<li>送检表钢号：<span>'+data.voDisassembly.sendCode+'</span></li>'+
							'<li>备用表底数：<span>'+data.voDisassembly.backBaseNum+'</span></li>'+
							'<li>备用表钢号：<span>'+data.voDisassembly.backCode+'</span></li>'+
							'<li>铅封（送检）：<span></span></li>'+
							'</ul></div></div>'
						}
					}
					if(data.laidUp)
					{	
						var laidUpList=''
						for(var i=0;i<data.laidUp.length;i++){
							if(data.laidUp[i]){
								laidUpList+='<div class="installInnerSmall"><ul>'+
								'<li>操作：<span>'+data.laidUp[i].operation+'</span></li>'+
								'<li>表钢号：<span>'+data.laidUp[i].code+'</span></li>'+
								'<li>库名：<span>'+data.laidUp[i].libraryName+'</span></li>'+
								'<li>领（送）料人：<span>'+data.laidUp[i].picker+'</span></li>'+
								'<li>入（出）库人：<span>'+data.laidUp[i].warehouseman+'</span></li>'+
								'<li>操作时间：<span>'+data.laidUp[i].time+'</span></li>'+
								'</ul></div>'
							}
						}
						laidUpHtml='<div class="installList"><h4>入库信息</h4>'+laidUpList+'</div>'
					}
					if(data.issue)
					{	
						var issueList=''
						for(var i=0;i<data.issue.length;i++){
							if(data.issue[i]){
								issueList+='<div class="installInnerSmall"><ul>'+
								'<li>操作：<span>'+data.issue[i].operation+'</span></li>'+
								'<li>表钢号：<span>'+data.issue[i].code+'</span></li>'+
								'<li>库名：<span>'+data.issue[i].libraryName+'</span></li>'+
								'<li>领（送）料人：<span>'+data.issue[i].picker+'</span></li>'+
								'<li>入（出）库人：<span>'+data.issue[i].warehouseman+'</span></li>'+
								'<li>操作时间：<span>'+data.issue[i].time+'</span></li>'+
								'</ul></div>'
							}
						}
						issueHtml='<div class="installList"><h4>出库信息</h4>'+issueList+'</div>'
					}
					if(data.voSendDetectTp)
					{	
						var voSendDetectTpList=''
						for(var i=0;i<data.voSendDetectTp.length;i++){
							if(data.voSendDetectTp[i]){
								voSendDetectTpList+='<div class="installInnerSmall"><ul>'+
								'<li>送检人：<span>'+data.voSendDetectTp[i].feedingPerson+'</span></li>'+
								'<li>送检日期：<span>'+data.voSendDetectTp[i].sendDetectTpTime+'</span></li>'+
								'<li>送检状态：<span>'+data.voSendDetectTp[i].state+'</span></li>'+
								'<li>检定完成日期：<span>'+data.voSendDetectTp[i].verificationCompletionDate+'</span></li>'+
								'</ul></div>'
							}
						}
						voSendDetectTpHtml='<div class="installList"><h4>送检信息</h4>'+voSendDetectTpList+'</div>'
					}
					if(data.voBackPack)
					{
						if(data.voBackPack.pics)
						{
							for(var j=0;j<data.voBackPack.pics.length;j++){
								voBackPackUrl+="<img class='smallImg' alt='暂未上传' src="+data.voBackPack.pics[j]+" />"
							}
							voBackPackHtml='<div class="installList"><h4>回装信息</h4><div class="installInner">'+
							'<ul>'+
							'<li>送检表底数：<span>'+data.voBackPack.sendBaseNum+'</span></li>'+
							'<li>送检表钢号：<span>'+data.voBackPack.sendCode+'</span></li>'+
							'<li>备用表底数：<span>'+data.voBackPack.backBaseNum+'</span></li>'+
							'<li>备用表钢号：<span>'+data.voBackPack.backCode+'</span></li>'+
							'<li>铅封（送检）：<span></span></li>'+
							'</ul></div></div>'+
							'<div class="installInnerImgBox">'+voBackPackUrl+'</div>'
						}else{
							voBackPackHtml='<div class="installList"><h4>回装信息</h4><div class="installInner">'+
							'<ul>'+
							'<li>送检表底数：<span>'+data.voBackPack.sendBaseNum+'</span></li>'+
							'<li>送检表钢号：<span>'+data.voBackPack.sendCode+'</span></li>'+
							'<li>备用表底数：<span>'+data.voBackPack.backBaseNum+'</span></li>'+
							'<li>备用表钢号：<span>'+data.voBackPack.backCode+'</span></li>'+
							'<li>铅封（送检）：<span></span></li>'+
							'</ul></div></div>'
						}
					}
					if(data.voWarehousing)
					{
						voWarehousingHtml='<div class="installList"><h4>备用表入库</h4><div class="installInner">'+
						'<ul>'+
						'<li>操作：<span>'+data.voWarehousing.operation+'</span></li>'+
						'<li>表钢号：<span>'+data.voWarehousing.code+'</span></li>'+
						'<li>库名：<span>'+data.voWarehousing.libraryName+'</span></li>'+
						'<li>领（送）料人：<span>'+data.voWarehousing.picker+'</span></li>'+
						'<li>入（出）库人：<span>'+data.voWarehousing.warehouseman+'</span></li>'+
						'<li>操作时间：<span>'+data.voWarehousing.time+'</span></li>'+
						'</ul></div></div>'
					}
					if(data.voDevMaintenances)
					{	
						var voDevMaintenancesList=''
						for(var i=0;i<data.voDevMaintenances.length;i++){
							if(data.voDevMaintenances[i]){
								voDevMaintenancesList+='<div class="installInnerSmall"><ul>'+
								'<li>用气点名称：<span>'+data.voDevMaintenances[i].pointName+'</span></li>'+
								'<li>表钢号：<span>'+data.voDevMaintenances[i].stencilNum+'</span></li>'+
								'<li>维修类型：<span>'+data.voDevMaintenances[i].maintenanceType+'</span></li>'+
								'<li>详细地址：<span>'+data.voDevMaintenances[i].location+'</span></li>'+
								'<li>表具型号：<span>'+data.voDevMaintenances[i].devModelName+'</span></li>'+
								'<li>厂家：<span>'+data.voDevMaintenances[i].manufacture+'</span></li>'+
								'<li>联系电话：<span>'+data.voDevMaintenances[i].phone+'</span></li>'+
								'<li>维修结果：<span>'+data.voDevMaintenances[i].status+'</span></li>'+
								'<li>维修时间：<span>'+data.voDevMaintenances[i].createTime+'</span></li>'+
								'<li>维修原因：<span>'+data.voDevMaintenances[i].reason+'</span></li>'+
								'</ul></div>'
							}
						}
						voDevMaintenancesHtml='<div class="installList"><h4>维修信息</h4>'+voDevMaintenancesList+'</div>'
					}
					$('#submissionData').append(voInitHtml+voCollarMeterHtml+voDisassemblyHtml+laidUpHtml+issueHtml+voSendDetectTpHtml+voBackPackHtml+voWarehousingHtml+voDevMaintenancesHtml)
				}else{
					$('#submissionData').html('<p style="text-align:center; font-size:18px;">数据加载失败</>')
				}
					
					
					
				}
			)

		}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log(data)
		let sTime=data.sendDetectTime ?moment(data.sendDetectTime[0]._d) : null;
		let eTime=data.sendDetectTime ?moment(data.sendDetectTime[1]._d) : null;
		let ssTime=data.completionTime ?moment(data.completionTime[0]._d) : null;
		let eeTime=data.completionTime ?moment(data.completionTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.sendDetectTime=''
		params.completionTime=''
		params.sendDetectTimeStart=sTime;
		params.sendDetectTimeEnd=eTime;
		params.completionTimeStart=ssTime;
		params.completionTimeEnd=eeTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	


	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>送检台账</h3>
						<div style={{position:'relative'}}>
							<div style={{width:'88%'}}>
							<FormsTwo 
									ref={e => this.forms = e} 
									formParamenter={this.state.topFormItem} 
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'90px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary">导出</Button>
						</div>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getSubmitInfoInCondition}
					setTableValue={this.setTableValue}
					ref={e => this.table = e} 
				>
				</TableComponents>
				</div>
				<Modal
                        title={'送检详情'}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
						forceRender={true}
						// footer={false}
                        onOk={() => this.showDetailBox(false)}
                        onCancel={() => this.showDetailBox(false)}
                        width={1440}
                        centered
                    >
                        <div className={'installBox submissionDataBox'} id='submissionData'>

                        </div>
                    </Modal>
					<div className={'bigImgBox'}>
						<div className={'bigImgBoxBg'}></div>
						<img className={'bigImg'}></img>
					</div>

            </Layout>
        )
    }
}
