import React from 'react'
import {Layout, Button, Tree, Input, InputNumber, message, Modal,Tooltip,Popconfirm} from "antd";
import Menus from '../../../components/common/menus/Menus'
import './areaTree.less'
import {DownOutlined,PlusSquareOutlined,MinusSquareOutlined,EditOutlined } from '@ant-design/icons';
import {getdataTree,save,update,deleteTree,getDateByUserId} from "./../../../services/admin";
import {funTraceInfo} from '../../../services/common'
const { DirectoryTree } = Tree;
const TreeNode = Tree.TreeNode;
const { Search  } = Input;
export default class areaTree extends React.Component{
    state={
        treeNodeInfo: [],       // 区域树
        expandedKeys:[],        // 节点展开
        searchValue:'',         // 查询输入框
        modalVisible_1:false,   // 弹窗开关-1
        modalVisible_2:false,   // 弹窗开关-2
        parentId:'',            // 父节点名ID
        parentName:'',          // 父节点名-弹窗输入框
        updataName:'',          // 修改节点名-弹窗输入框

        selectNodeOne:{},       // 当前点击获取到的节点数据
        nodeOne:'',             // 点击修改按钮获取的数据
        inputnewName:'',        // 修改节点的新名字
        buttonJuder_1:false,
    };
    componentDidMount() {
        console.log("初始化");
        this.leftAreaNew();
        let menuAuthories =[];
        menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
        menuAuthories.map(one=>{
            if(one.name==='权限管理'){
                one?.children.map(two=>{
                    if(two.name==='新增根节点'){
                        this.setState({buttonJuder_1:true})
                    }
                })
            }
        });
        this.treeNodeAll();// 获取客户区域
    }
    // 获取客户区域
    treeNodeAll = () => {
        console.log(JSON.parse(sessionStorage.getItem("user")));
        // 根据用户查询数据权限
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user")).id}).then(
            response=>{
                console.log('根据用户查询数据权限');
                console.log(response?.data);
                        if(response?.data){
                        //     console.log(response?.data?.data);
                            this.setState({ treeNodeInfo: response?.data });
                        }else{
                            return message.info('获取不到数据');
                        }
            }
        )
    };
    loop = (data) =>
        data.map(item => {
            const index =       item.name.indexOf(this.state.searchValue);   // 获取相同值的位置
            const beforeStr =   item.name.substr(0, index);              // 截取前半部分没有相同的值
            const afterStr =    item.name.substr(index + this.state.searchValue.length);  // 获取后半部分不相同的值

            var title =
                <span>
                    <span key={item.id} title={item.name} style={{fontSize:'15px'}}>
                        {index > -1
                            ? <span>{beforeStr}<span style={{color:'#f5222d'}}>{this.state.searchValue}</span>{afterStr}</span>
                            :<span style={{fontSize:'15px'}}>{item.name}</span>}
                        </span>
                    <span style={{paddingLeft:'5px'}} className={'body_left_interior_tree__span'}>
                        <Tooltip title="新增子节点">
                            <PlusSquareOutlined onClick={()=>{this.setState({modalVisible_2:true,updataName:'',parentId:item.id})}}/>
                        </Tooltip>
                    </span>
                    {item.children?null:
                        <span  style={{paddingLeft:'5px'}} className={'body_left_interior_tree__span'}>
                        <Tooltip title="删除当期节点">
                             <Popconfirm
                                 title="删除节点"
                                 description="是否确定删除该节点?"
                                 onConfirm={()=>{this.delTree(item);}}
                                 // onCancel={cancel}
                                 okText="是"
                                 cancelText="不"
                             >
                                 <MinusSquareOutlined />
                             </Popconfirm>
                        </Tooltip>
                    </span>
                    }
                    {/*<span className={'body_left_interior_tree__span'}><Tooltip title="删除当期节点"><MinusSquareOutlined /></Tooltip></span>*/}
                    <span className={'body_left_interior_tree__span'}><Tooltip title="修改节点">
                          <EditOutlined onClick={()=>{this.setState({nodeOne:item,inputnewName:item.name})}}/>
                    </Tooltip>
                    </span>
                </span>;
            if(this.state.nodeOne!=='' & item.id===this.state.nodeOne.id){
                title=
                    <span>
                       <div style={{width:'100%'}}>
                           <div style={{width:'60%',float:'left'}}>
                               <Input defaultValue={item.name} size={'small'} onChange={(text)=>{this.setState({inputnewName:text.target.value});}}/>
                           </div>
                           <div style={{width:'40%',float:'left'}}>
                               <Button onClick={()=>{this.setState({nodeOne:''});
                                   this.upTree(item.id);
                               }} type="primary" size={'small'}>提交</Button>
                                <Button onClick={()=>{this.setState({nodeOne:''});
                                }} size={'small'}>取消</Button>
                           </div>
                       </div>
                    </span>

            }
            if (item?.children) {
                return {id:item.id,name:title, children: this.childrenLoop(item.children,1) };
            }
            return {
                name:title,
                id:item.id
            };
        });


    childrenLoop = (data,num) =>

        data.map(item => {
            const index = item.name.indexOf(this.state.searchValue);                    // 获取相同值的位置
            const beforeStr = item.name.substr(0, index);                               // 截取前半部分没有相同的值
            const afterStr = item.name.substr(index + this.state.searchValue.length);   // 获取后半部分不相同的值
            var title =
                <span>
                    <span key={item.id} title={item.name} style={{fontSize:'15px'}}>
                        {index > -1
                            ? <span>
                                {beforeStr}<span style={{color:'#f5222d'}}>{this.state.searchValue}</span>{afterStr}
                            </span>
                            :<span style={{fontSize:'15px'}}>{item.name}</span>}
                        </span>
                    {num<5?
                        <span style={{paddingLeft:'5px'}} className={'body_left_interior_tree__span'}>
                        <Tooltip title="新增子节点">
                            <PlusSquareOutlined onClick={()=>{this.setState({modalVisible_2:true,updataName:'',parentId:item.id})}}/>
                        </Tooltip>
                    </span>
                        :null
                    }
                    <span  style={{paddingLeft:'5px'}} className={'body_left_interior_tree__span'}>
                        <Tooltip title="删除当期节点">
                             <Popconfirm
                                 title="删除节点"
                                 description="是否确定删除该节点?"
                                 onConfirm={()=>{this.delTree(item);}}
                                 // onCancel={cancel}
                                 okText="是"
                                 cancelText="不"
                             >
                                 <MinusSquareOutlined />
                             </Popconfirm>
                        </Tooltip>
                    </span>

                    <span className={'body_left_interior_tree__span'}>
                        <Tooltip title="修改节点">
                            <EditOutlined onClick={()=>{this.setState({nodeOne:item,inputnewName:item.name})}}/>
                        </Tooltip>
                    </span>
                </span>;
            if(this.state.nodeOne!=='' & item.id===this.state.nodeOne.id){
                title=
                    <span>
                       <div style={{width:'100%'}}>
                           <div style={{width:'57%',float:'left'}}>
                               <Input defaultValue={item.name} size={'small'} onChange={(text)=>{this.setState({inputnewName:text.target.value});}}/>
                           </div>
                           <div style={{width:'43%',float:'left'}}>
                               <Button onClick={()=>{this.setState({nodeOne:''});
                                   this.upTree(item.id);
                               }} type="primary" size={'small'}>提交</Button>
                                <Button onClick={()=>{this.setState({nodeOne:''});
                                }} size={'small'}>取消</Button>
                           </div>
                       </div>
                    </span>

            }
            if (item?.children) {

                return {id:item.id,name:title, children: this.childrenLoop(item.children,num+1) };
            }
            return {
                name:title,
                id:item.id
            };
        });
    onExpands=(a,b)=>{
        console.log('onExpands');
        console.log(a);
        console.log(b);
        this.setState({expandedKeys:a})
    };
    onSelect=(a=[],b)=>{
        console.log('onSelect');
        console.log(a);
        console.log(b);

        var {treeNodeInfo} = this.state;
        if(a.length!==0){
            this.forChildren(treeNodeInfo,a[0]);
        }
    };
    forChildren=(data=[],selectId)=>{
        var params=null;
        for(var i=0;i<data?.length;i++){
            if(data[i].id===selectId){
                return data[i];

            }
            if(data[i]?.children?.length!==0){
                params = this.forChildren(data[i]?.children,selectId);
                if(params!==null){
                    return params;
                }
            }
        }
        return params;
    };
    // 输入框查询数据树
    treeInput=(text)=>{
        var {expandedKeys,treeNodeInfo} = this.state;
        expandedKeys=[];
        if(text.target.value!==''){
            treeNodeInfo.map(one=>{
                if(one?.children){
                    for(var i=0;i<one?.children.length;i++){
                        if(one?.children[i].name.indexOf(text.target.value) >-1){
                            expandedKeys.push(one.id);
                            break;
                        }
                    }
                }
            });
        }
        console.log(expandedKeys);
        this.setState({searchValue:text.target.value,expandedKeys:expandedKeys})
    };
    // 拖拽事件
    onDrop=(data)=>{
        console.log('拖拽事件');
        console.log(data);// 是否拖拽节点之间
        // 判断所处层级
        var num = data.node.pos.match(/-/g).length;// 目标层级
        console.log('判断所处层级');
        console.log(num);
        // 判断拖拽层级不能为父节点
        // if(data.dragNode.pos.length<=3){
        //     return message.info("父节点不能移区")
        // }
        // 限制不能拖到第5级外
        if(num>5){
            return message.info("当前不支持超出5层数据")
        }

        // if(data.node.expanded){
        //     return message.info("当前所属该父节点内")
        // }
        // if(data.dropPosition===-1){
        //     return message.info("请选择要转移的位置，不能作为父节点")
        // }
        const params={
            id: data.dragNode.id,// 要动的对象
            name: null,
            parentId: data.node.id// 指定到目标
        };
        var {treeNodeInfo} = this.state;
        params.name=this.getTreeNodeInfo(treeNodeInfo,data.dragNode.id);
        if(data.dropToGap){
            params.parentId=0;
        }
        params.userId=JSON.parse(sessionStorage.getItem("user")).id;
        update(params).then(response=>{
            console.log(response);
            if(response?.data?.data){
                message.info(params.name+'移区成功');
            }else{
                message.info("移区失败")
            }
            this.treeNodeAll();// 获取客户区域
        });
        funTraceInfo("区域树管理", "区域修改",data.dragNodesKeys,params)
    };

    getTreeNodeInfo=(treeNodeInfo,id)=>{
        var name =null;
        treeNodeInfo.map(one=>{

            if(one.id===id){
                console.log(one);
                name=one.name;
            }else if(one?.children){
                var children = this.getTreeNodeInfo(one?.children,id);
                if(children){
                    name=children;
                }
            }
        });
        return name;
    };
    // 创建节点
    creactparentNode=(parentId,name)=>{
        var params={
            name:name||null,
            parentId:0,
        };
        params.userId=JSON.parse(sessionStorage.getItem("user")).id;
        save(params).then(response=>{
            console.log(response);
            if(response?.data?.data){
                message.info(params.name+'创建成功');

            }else{
                message.info("创建失败")
            }
            this.treeNodeAll();// 获取客户区域
        });
        funTraceInfo("区域树管理", "区域添加",null,params)
    };
    // 创建子节点
    creactSunNode=()=>{
        if(this.state.updataName===''){
            return message.info('内容不能为空');
        }
        this.setState({modalVisible_2:false});

        var params={
            parentId:this.state.parentId||null,
            name:this.state.updataName||null
        };
        params.userId=JSON.parse(sessionStorage.getItem("user")).id;
        save(params).then(response=>{
            console.log(response);
            if(response?.data?.data){
                message.info(params.name+'创建成功');
            }else{
                message.info("创建失败")
            }
            this.treeNodeAll();// 获取客户区域
        });
        funTraceInfo("区域树管理", "子区域添加",null,params)
    };
    // 修改节点名称
    upTree=(id,name)=>{
        var {selectNodeOne} = this.state;
        var params={
            id:id,
            name:this.state.inputnewName,
        };
        params.userId=JSON.parse(sessionStorage.getItem("user")).id;
        update(params).then(response=>{
            console.log(response);
            if(response?.data?.data){
                message.info(params.name+'修改成功');
            }else{
                message.info("修改失败")
            }
            this.treeNodeAll();// 获取客户区域
        });
        funTraceInfo("区域树管理", "修改节点名称",name,params)
    };
    // 删除子节点
    delTree=(item)=>{
        console.log('删除子节点');
        var params={
            id:item.id,
            parentId:item.parentId,
        };
        params.userId=JSON.parse(sessionStorage.getItem("user")).id;
        console.log(params);

        deleteTree({dataId:params.id,parentId:params.parentId}).then(
            response=>{
                if(response?.data?.data){
                    message.info(response?.data?.responseInfo?.description);
                }else{
                    message.info("删除失败,"+response?.data?.responseInfo?.description)
                }
                this.treeNodeAll();// 获取客户区域
            }
        );
        funTraceInfo("区域树管理", "删除区域",null,params)
    };



    getButton_1=()=>{
        return(
            this.state.buttonJuder_1?
                <Button  type={'primary'} size={"small"} onClick={()=>{this.setState({modalVisible_1:true})}}>新增根节点</Button>
                :
                null
        )
    };

    leftAreaNew=()=>{
        console.log('--------------------');
        console.log(this.props.location);
        console.log('--------------------');
    };
    render() {
        const {expandedKeys}=this.state;

        return(

            <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
                <diV id={'areaTree'}>
                    <div className={'body_div'}>

                        {/*------------------------左侧------------------------*/}
                        <div className={'body_left_div'}>
                            <div className={'body_left_interior_div'}>

                                <div className={'body_left_interior_botton_div'}>
                                    <div>
                                        {this.getButton_1()}
                                        &nbsp;&nbsp;&nbsp;
                                        {/*<Button  type={'primary'} size={"small"}>一键删除根节点</Button>*/}
                                    </div>
                                </div>
                                <div className={'body_left_interior_tree_div'}>
                                    <div style={{width:'60%',height:'40px'}}>
                                        <Input onChange={(text)=>{this.treeInput(text)}}/>
                                    </div>
                                    <div style={{width:'60%',height:'96%', overflowX:'auto'}}>
                                        <Tree
                                            fieldNames={{key:'id',title:'name'}}
                                            defaultExpandAll
                                            // checkable
                                            onExpand={this.onExpands}
                                            onSelect={this.onSelect}
                                            blockNode
                                            draggable={true}                    // 节点拖拽开启
                                            onDrop={(a,b)=>{this.onDrop(a,b)}}  // 节点拖拽事件
                                            expandedKeys={expandedKeys}
                                            switcherIcon={<DownOutlined />}
                                            treeData={this.loop(this.state.treeNodeInfo)}
                                            // treeData={this.state.treeNodeInfo}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*------------------------右侧------------------------*/}
                        <div className={'body_right_div'}>

                        </div>
                    </div>
                </diV>
                <div>
                    <Modal
                        title={'新增根节点'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible_1}
                        okText="确定"
                        onOk={() => {
                            if(this.state.parentName===''){
                                return message.info('内容不能为空');
                            }
                            this.setState({modalVisible_1:false});
                            this.creactparentNode(null,this.state.parentName);
                        }}          // 点击确定
                        onCancel={() => this.setState({modalVisible_1:false})}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{width:'100%',height:'60px'}}>
                            <div style={{width:'30%',textAlign: 'right',float:'left'}}><span>父节点名：</span></div>
                            <div style={{width:'65%',float:'left'}}><Input onChange={(text)=>{this.setState({parentName:text.target.value})}}/></div>

                        </div>
                    </Modal>
                    <Modal
                        title={'新增节点'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible_2}
                        okText="确定"
                        onOk={() => {this.creactSunNode();}}          // 点击确定
                        onCancel={() => this.setState({modalVisible_2:false})}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{width:'100%',height:'60px'}}>
                            <div style={{width:'30%',textAlign: 'right',float:'left'}}><span>新节点名：</span></div>
                            <div style={{width:'65%',float:'left'}}><Input value={this.state.updataName} onChange={(text)=>{this.setState({updataName:text.target.value})}}/></div>

                        </div>
                    </Modal>
                </div>
            </Layout>
        );
    }

}
