import React, {Component} from 'react'
import {Layout, Button, Modal, Radio, Input, DatePicker, Popconfirm, message, InputNumber} from 'antd'
import TableComponents from '../../components/common/tables/TableComponents'
import {getLearningConfigInCondition, taskDetail} from "../../services/meter";
import {TYPE_NAME} from "../../components/Const";
import { ArrowLeftOutlined } from '@ant-design/icons';
import "braft-editor/dist/index.css";
import moment from 'moment'
const { RangePicker} = DatePicker;
export default class textConfiguration extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "学习配置",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'taskDetails',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '任务详情',
            header: {
                title: '标题',
                customerName: '客户名称',
                phone: '负责人电话',
                startTimeOfVacation: '放假开始时间',
                endTimeOfVacation: '放假结束时间',
                state: '状态',
                reportingTime: '上报时间',
                valveState: '进气阀状态',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    // {
                    //     type: TYPE_NAME.NAME,
                    //     variableName: 'title',
                    //     str: '标题',
                    //     initData: JSON.parse(localStorage.getItem('taskDetails'))?.name
                    // },
                    {
                        type: TYPE_NAME.SELECT,
                        variableName: 'state',
                        str: '状态',
                        data:[
                            {key:'未上报',value:'未上报'},
                            {key:'已上报',value:'已上报'},
                        ],
                        initData: JSON.parse(localStorage.getItem('taskDetails'))?.name
                    },
                    {
                        type: TYPE_NAME.SELECT,
                        variableName: 'valveState',
                        str: '进气阀状态',
                        data:[
                            {key:'开',value:'开'},
                            {key:'关',value:'关'},
                        ],
                        initData: JSON.parse(localStorage.getItem('taskDetails'))?.name
                    },
                    {
                        type: TYPE_NAME.DATE_PICKER,
                        variableName: 'reportingTime',
                        str: '上报时间',
                        initData: JSON.parse(localStorage.getItem('taskDetails'))?.name
                    },
                ],

                // onchange:(e)=>{
                //     var param= {
                //         page: 1, // 页
                //         size: 10,// 数量
                //     };
                //     if(e?.title)param.title=e.title;
                //     if(e?.state)param.state=e.state;
                //     if(e?.valveState)param.valveState=e.valveState;
                //     if(e?.reportingTime){
                //         if(e.reportingTime.length!==0){
                //             param.startTime=moment(e.reportingTime[0]);
                //             param.endTime=moment(e.reportingTime[1]);
                //         }
                //     }
                //     this.table.refreshTable(param);
                // }
            },

            params:{
                "learnConfigId": this.props.history?.location?.state?.rowData?.id??0,
                "page": 1,
                "size": 10
            }
        },
    };

    componentDidMount(): void {
        console.log(this.props.history.location.state.rowData.learnId);
    }

    setTableValue = (value,text,rowData) => {

        switch (value) {
            case 'valveState':
                return <><span style={{color:text==='未推送'?'red':null}}>{text??'-'}</span></>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div className={'div_back_arrow'} >
                    <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回</span>
                </div>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={taskDetail}
                    setTableValue={this.setTableValue}// 设置表格显示

                    clearCondition={()=>{
                        var params={
                            "learnConfigId": this.props.history?.location?.state?.rowData?.id??0,
                            "page": 1,
                            "size": 10
                        };
                        this.table.refreshTable(params);
                    }}
                    ref={e => this.table = e}
                >
                </TableComponents>
            </Layout>
        )
    }
}
