import ajax from './ajax'

//根据区域ID查询所有数据
export const selectAllByAreaId = data => ajax('/uploadmanagement/uploaddatarecord/selectAllByCondition', data, 'POST')

// //查询所有的表钢号
// export const getMeterCodes = data => ajax('/uploadmanagement/uploaddatarecord/getMeterCodes', data, 'POST')

//测试数据集成
export const testData = (data) => ajax('/alarm/alarm/alarmConfig/testData', (data), 'POST')

//根据区域id获取表具数
export const meterSumByAreaIds = (data) => ajax('/measurepoint/meterStatistics/meterSumByAreaIds', (data), 'POST')

//根据区域id查询表具月购买数
export const purchaseMeterSumCurrentMonthByAreaIds = (data) => ajax('/measurepoint/meterStatistics/purchaseMeterSumCurrentMonthByAreaIds', (data), 'POST')

//根据区域id查询表具月安装数
export const installedMeterSumCurrentMonthByAreaIds = (data) => ajax('/measurepoint/meterStatistics/installedMeterSumCurrentMonthByAreaIds', (data), 'POST')

//根据区域id查询表具安装数
export const installedMeterSumCurrentByAreaIds = (data) => ajax('/measurepoint/meterStatistics/installedMeterSumCurrentByAreaIds', (data), 'POST')

//根据区域id查询表具未安装数
export const uninstalledMeterSumCurrentByAreaIds = (data) => ajax('/measurepoint/meterStatistics/uninstalledMeterSumCurrentByAreaIds', (data), 'POST')


//根据区域id查询抄表率
export const selectRateInfosBy7Days = (data) => ajax('/measurepoint/collectionRateInfo/selectRateInfosBy7Days', (data), 'POST')

//根据区域Id号，查询15天日用气量
export const getNewlyTheAreaIdBy15Days = (data) => ajax('/measurepoint/gasConsumptionAreaDay/getNewlyTheAreaIdBy15Days', (data), 'POST')

//根据区域Id号，查询前5条报警记录-旧
export const alarmTop5ByAreaId = (data) => ajax('/measurepoint/statsAalarmInfos/alarmTop5ByAreaId', (data), 'POST');
//根据区域Id号，查询前5条报警记录-新
export const alarmInfoByAreaId = (data) => ajax('/measurepoint/statsAalarmInfos/alarmInfoByAreaId', (data), 'POST');

//查询最新时间的用气量前10的区域信息
export const getGasConsumptionTop10MeterInfo = (data) => ajax('/measurepoint/gasConsumptionAreaDay/getGasConsumptionTop10MeterInfo', (data), 'POST')

//根据区域Id号，查询最新的十条文件记录
export const getFileRecordTop = (data) => ajax('/web/fileRecordCtrl/getFileRecordTop', (data), 'POST');

//删除历史报表数据
export const batchRemoveFileRecord = (data) => ajax('/web/fileRecordCtrl/batchRemoveFileRecord', (data), 'GET');

//昨日用气量前10的计量点
export const queryTopTenGasByAreaId = (data) => ajax('/web/meterHomePage/queryTopTenGasByAreaId', (data), 'POST');

//查询一个月内,所选区域下所有计量点的日用量的每日的累积值
export const queryMonthDayGasAccumulate = (data) => ajax('/web/meterHomePage/queryMonthDayGasAccumulate', (data), 'GET');
