import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getGasPropertiesInName,updateGasProperties,addGasProperties,removeGasProperties} from '../../../services/meter'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				name: '用气性质名称',
				ishour: '是否需要填小时用气量',
				createTime:'创建时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				name: '用气性质名称',
				ishour: '是否需要填小时用气量',
				createTime:'创建时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'name',
					str: '用气性质名称'
				}

			]
		},
		    	// 总条数
		totalCount: 0,
		devVisible:false, // 小窗口开关器-1
		qrId:'',
		oldCode:'',
		modTitle:'添加',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 2,
            labelCol: 10,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'name',
					str: '用气性质名称',
					rules: TYPE_NAME.LABLE_NOT_NULL_50,
				},
				{
					variableName: 'ishour',
					str: '是否需要填小时用气量',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						// {
						// 	key: '',
						// 	value: '请选择'
						// },
						{
							key: 0,
							value: '是'
						},
						{
							key: 1,
							value: '否'
						}
					]
				}
			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editGasPropertiesStatus(true,rowData.id,rowData)}>
						<u>{'修改'}</u>
				</div>
				<div className={'tableLink'} title={'删除'} onClick={() => this.delGasPropertiesData(rowData.id)}>
						<u>{'删除'}</u>
				</div>
				</>
			case 'ishour':
				return text === 0 ? '是' : text === 1 ? '否' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	//添加设备窗口显示
	addGasPropertiesStatus = async (devVisible)=>{
		await this.setState({devVisible,data:{},modTitle:'添加',qrId:''})
		await this.forms.onModalClose()
    }
	editGasPropertiesStatus = async (devVisible,id,data)=>{
		await this.setState({devVisible:devVisible,qrId:id,data: data,modTitle:'修改'});
		await this.forms.onModalClose()
    }
	delGasPropertiesData = async (id)=>{
		removeGasProperties({id:id}).then(data=>{
			if(data.data.data){
				funTraceInfo("计量点信息管理", "删除用气性质", null,id)
				message.config("修改成功")
			}else{
				message.config('修改失败')

			}
			this.table.refreshTable();// 刷新表格
		});
	};

	addGasPropertiesData = async (value)=>{
			let devData = await this.forms.onFinishNotReset();
			console.log(devData);
			if(!devData){
				return message.info("内容不能为空")
			}
			if(this.state.oldCode){
				devData.oldCode=this.state.oldCode
			}
			if(this.state.qrId){
				devData.id=this.state.qrId
				updateGasProperties(devData).then(data=>{
					if(data?.data?.data===true){
						message.info("修改成功");
						funTraceInfo("计量点信息管理", "修改用气性质", null,devData)
						this.addGasPropertiesStatus(false)
					}else{
						this.addGasPropertiesStatus(false);
						console.log("修改失败");
						message.config(data.data.responseInfo.description+'修改失败')

					}
					this.table.refreshTable();// 刷新表格
				}).catch(e=>{message.info("请求异常问题，请联系管理员")});
			}else{
				addGasProperties(devData).then(data=>{
					if(data?.data?.data===true){
						message.info("添加成功");
						funTraceInfo("计量点信息管理", "添加用气性质", null,devData)
						this.addGasPropertiesStatus(false);
					}else{
						this.addGasPropertiesStatus(false);
						console.log("添加失败");
						message.info(data.data.responseInfo.description+'添加失败')

					}
					this.table.refreshTable();// 刷新表格
				}).catch(e=>{message.info("请求异常问题，请联系管理员")});
			}

	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>用气性质管理</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addGasPropertiesStatus(true)}><PlusSquareOutlined />新增</span>
					{/* <span className={'addLink'} onClick={() => this.updatePoint(true)}><EditOutlined />修改</span> */}

				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getGasPropertiesInName}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => {
                        	// this.setState({devVisible:false});
                        	this.addGasPropertiesData(true)
                        }}           // 点击确定
                        onCancel={() => this.addGasPropertiesStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
