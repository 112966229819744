import React, { Component } from 'react'
import {Layout, Divider, Timeline, Image, Button, message} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getMeterOrderForm} from "../../../services/orderForm";
import {PageQueryLifecycle, PageQueryLifecycleNew} from '../../../services/qrCode'
import moment from "moment";
import {getDevList} from "../../../services/customerDataPanel";
// 生命周期管理
export default class deviceLifeCycle extends Component {
    state= {
        topFormItem: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'devType', str: '设备类型', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'探测器',value:'探测器'},
                        {key:'表具',value:'表具'},
                        {key:'气瓶',value:'气瓶'},
                        {key:'阀门',value:'阀门'},
                        {key:'阀井',value:'阀井'},
                        {key:'压力表',value:'压力表'},
                    ]
                }
            ],
            searchGetData:{}
        },
        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                devModelName: '设备型号',
                installPosition: '安装地址',
                state: '状态',
                dataTime:'时间',
                // edit: '操作',
            },

            params: {
                customerId: this.props?.location?.state?.rowData?.id??null,
                devType: "探测器",
                page: 0,
                size: 10
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
    };

    componentDidMount =async value=> {

    };
    // 条件选择：获取输入的条件
    getConditionsValue = async value => {
        let data = await this.forms.onFinishNotReset();
        console.log(data);
        var tableParamenter = this.state.tableParamenter;
        tableParamenter.params.devType=data.devType;
        await this.table.refreshTable(tableParamenter.params);
    };



    setTableValue = (value,text,data,) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'expirationTime':
                return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
                <div>
                    <div className={'topSearchBox'}>
                        <h3>临期设备</h3>
                        <div style={{position:'relative'}}>
                            <div className={'topFormBox'} style={{height:'40px'}}>
                                <div  style={{width:'20%',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms = e}
                                        formParamenter={this.state.topFormItem}
                                        data={this.state.topFormItem.searchGetData}
                                    />
                                </div>
                                <div  style={{width:'6%',float:'left',paddingLeft:'10px'}}>
                                    <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/**/}
                    <div style={{padding:'0 0 0 0',background:'#fff'}}>
                        <div>
                            <TableComponents
                                paramenter={this.state.tableParamenter}
                                setData = {getDevList}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            >
                            </TableComponents>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
