import React, { Component } from 'react'
import {Radio, Layout, message, Tree, Table, Image, Button, Modal, Upload, Popconfirm} from "antd";
import {getNewTree} from "../../services/admin";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../components/Const";
import {
    addHome,
    addHomeByOrgId,
    getAllHome,
    getHomeInfoByOrgId,
    removeHomeById,
    updateHome
} from "../../services/configuration";
const RadioGroup = Radio.Group;
// 首页配置
export default class configurationPage extends Component {
    state={
        visible_1:false,
        // 表单参数
        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 5,
            layout: "horizontal",
            parameter: [
                {variableName: 'name', str: '名称', rules: TYPE_NAME.INPUT},
                {variableName: 'url', str: '菜单路径', rules: TYPE_NAME.INPUT},
            ]
        },
        // 右侧数据
        table_columns:[
            {title: '编号', dataIndex: 'id', key: 'id',align:'center',width:'100px'},
            {title: '名称', dataIndex: 'name', key: 'name',align:'center',width:'200px'},
            {title: '内容', dataIndex: 'data', key: 'data',align:'center',width:'300px',
                render: (text,rowData,index) => {
                return<><Image width={150} src={rowData.path}/></>}},
            {title: '操作', dataIndex: 'edit', key: 'edit',width:'150px',
                render: (text,rowData,index) => {
                return<>
                    <div style={{float: 'left',width:'85px'}}>
                        <Button type={'primary'}
                                onClick={async ()=>{
                                   await this.setState({visible_1:true,selectType:1,essentialData:rowData,ImageUrl:rowData.path});
                                    this.forms.onModalClose();
                                }}
                        >修改</Button></div>
                    <div style={{float: 'left'}}>
                        <Popconfirm
                            title="是否确定删除"
                            description="是否确定删除?"
                            onConfirm={()=>{

                                this.removeHomeById(rowData.id)
                            }}
                            // onCancel={cancel}
                            okText="是"
                            cancelText="否"
                        >
                            <Button type={'primary'}>删除</Button>
                        </Popconfirm>
         </div>
                </>
                }}
            ],
        table_data:[
            // {id:'1',name:'内容',data:'1',edit:'e'},
            // {id:'1',name:'内容',data:'1',edit:'e'},
            // {id:'1',name:'内容',data:'1',edit:'e'},
            // {id:'1',name:'内容',data:'1',edit:'e'},
        ],

        // 组织结构
        tree: {
            treeData: [
                {
                    title: 'name',
                    key: 'id',
                    children: [
                        {
                            title: 'name',
                            key: 'id',
                            children: [
                                { title: 'name', key: 'id' }
                                ],
                        }],
                },
            ],
        },

        selectType:0,


        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [], // 设备照片
        ImageUrl:null,
        selectNode:null,// 所选左侧节点数据
        rowDataId:null,
        switchingModes:true
    };

    componentDidMount(): void {
        this.getTreeNode();   // 获取组织机构的树节点
        this.getAllHome();    // 查询所有的首页配置
    }

    // 查询所有的首页配置
    getAllHome=()=>{
        getAllHome().then(
            reponse=>{
                console.log(reponse);
                if(reponse?.data?.data){
                    this.setState({table_data:reponse.data.data})
                }else{
                    this.setState({table_data:[]})
                }
            }
        ).catch(
            this.setState({table_data:[]})
        )
    };


    //获取组织机构的树节点
    getTreeNode = () => {
        this.setState({ loading: true });
        // 新
        getNewTree({userId:JSON.parse(sessionStorage.getItem("user")).id}).then(
            reponse => {
                console.log(reponse.data);
                if(reponse.data.responseInfo.code!==200){
                    return message.info('未有组织结构树数据');
                }
                let { data } = reponse.data;
                let { tree } = this.state;
                // responseInfo.data = data;
                tree.treeData = data;
                this.setState({
                //     loading: false,
                //     refreshTable: false,
                //     totalCount: data.total,
                //     responseInfo: responseInfo,
                    tree
                });
                message.info('查询成功',[0.5])
                // funTraceInfo("权限管理", "组织结构查询", null, tree)
            }
        ).catch((e) => {

                message.info('查询异常，请重新刷新页面',)
            }
        );
    };

    //递归
    getDataTreeData_1 = data => {
        data = Array.isArray(data) ? data : [];
        let tree = data?.map(value => {
            return {
                title: value?.name,
                key: value?.id,
                parent: value?.parentId,
                children: this.getDataTreeData_1(value?.children)
            }
        });
        return tree
    };

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
            // console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {

            message.success(`${file.file.name} 上传成功`);

            // funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        console.log(file.fileList);
        this.setState({fileList:file.fileList});
        // console.log(this.state.fileList)
    };

    onSelectChanges=(a,b,c)=>{

        this.setState({rowDataId:a});
    };


    addHome=async ()=>{
        var param = await this.forms.onFinish();
        if(!param){return false;}

        if(this.state?.fileList.length!==0){param.path=this.state.fileList[0]?.response;}
        param.deleted=1;
        addHome(param).then(
            reponse=>{
                if(reponse?.data?.data){
                    this.getAllHome();    // 查询所有的首页配置
                    message.info('添加配置成功')
                }else{
                    message.info('添加配置失败')
                }
                console.log(param);
            }
        ).catch(e=>{message.info('添加配置异常')});

        this.setState({visible_1:false});
    };

    updateHome=async()=>{
        var param = await this.forms.onFinish();
        if(!param){return false;}
        var essentialData = this.state.essentialData;
        essentialData.name=param.name??null;
        essentialData.url=param.url??null;
        updateHome(essentialData).then(
            reponse=>{
                if(reponse?.data?.data){
                    this.getAllHome();    // 查询所有的首页配置
                    message.info('添加配置成功')
                }else{
                    message.info('添加配置失败')
                }
                console.log(param);
            }
        ).catch(e=>{message.info('添加配置异常')});
        this.setState({visible_1:false});
    };

    removeHomeById=(id)=>{
        if(!id){return false;}
        removeHomeById({id}).then(
            reponse=>{
                if(reponse?.data?.data){
                    this.getAllHome();    // 查询所有的首页配置
                    message.info('删除配置成功')
                }else{
                    message.info('删除配置失败')
                }
            }
        ).catch(e=>{message.info('删除配置异常')});
    };

    // 对组织机构配置首页信息
    addHomeByOrgId=()=>{
        var {selectNode,rowDataId} = this.state;
        if(!selectNode){
            return message.info('请选择组织机构');
        }
        if(!rowDataId){
            return message.info('请选择首页配置');
        }
        var params={
            "homeIds": rowDataId,
            "orgId": selectNode.key
        };
        addHomeByOrgId(params).then(
            reponse=>{
                if(reponse?.data?.data){
                    message.info('添加配置成功')
                }else{
                    message.info('添加配置失败')
                }
            }
        ).catch(e=>{message.info('添加配置异常')});
    };

    // 根据组织机构ID查询首页配置
    getHomeInfoByOrgId=(a,b)=>{
        var param={
            "organizationId": a[0],
            "page": 1,
            "size": 10,
            // "userId": JSON.parse(sessionStorage.getItem("user")).id
        };
        getHomeInfoByOrgId(param).then(
            reponse=>{
                // if(reponse?.data?.data){
                //     var rowDataId = this.state.rowDataId;
                //     rowDataId=[];
                //     reponse.data.data.map(one=>{
                //         rowDataId.push(one.id);
                //         this.setState({rowDataId})
                //     });
                // }
                if(reponse?.data?.data){
                    this.setState({table_data:reponse.data.data})
                }else{
                    this.setState({table_data:[]})
                }
            }

        )
    };

    render() {
        const uploadButton = (
            <div>
                {/*<Icon type="plus" />*/}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (
            <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
                <div style={{width:'100%',height:'100%'}}>
                    {/*上层*/}
                    <div style={{width:'100%',height:'90%'}}>
                        {/*左-组织结构*/}
                        <div style={{width:'30%',height:'100%',padding: '5px 5px 15px 10px',float:'left'}}>
                            <div style={{width:'100%',height:'100%',border: 'rgb(64 149 229) 2px solid'}}>
                                <div style={{width:'100%',height:'5%',padding: '5px'}}>
                                    <span style={{fontSize:'16px',color:'rgb(64 149 229)',fontWeight: 'bold'}}>组织结构：</span>

                                </div>
                                <div style={{width:'100%',height:'95%'}}>
                                    <Tree
                                        treeData={this.getDataTreeData_1(this.state.tree.treeData)} // 新
                                        onSelect={(a,b)=>{
                                            console.log(b.node);
                                            this.setState({selectNode:b.node,switchingModes:false});
                                            this.getHomeInfoByOrgId(a,b);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*右-配置*/}
                        <div style={{width:'70%',height:'100%',padding: '5px 5px 15px 10px',float:'left'}}>
                            <div style={{width:'100%',height:'100%',border: 'rgb(64 149 229) 2px solid'}}>
                                <div style={{width:'100%',height:'5%',padding: '5px'}}>
                                    <span style={{fontSize:'16px',color:'rgb(64 149 229)',fontWeight: 'bold'}}>
                                        {this.state.switchingModes?'首页':this.state?.selectNode?.title??null}
                                        看板 /
                                    </span>
                                    <span className={'tableLink'} onClick={()=>{
                                        // 左侧数据
                                        // 选择模式
                                        if(this.state.switchingModes){
                                            if(this.state?.selectNode){
                                                this.getHomeInfoByOrgId(this.state.selectNode);
                                                this.setState({switchingModes:false});
                                            }else {
                                                message.info('请选择左侧数据')
                                            }
                                        }else{
                                            this.getAllHome();
                                            this.setState({switchingModes:true});
                                        }
                                    }}
                                          >
                                        <u>切换模式</u>
                                    </span>
                                    <div style={{float:'right'}} className={'tableLink'} title={'新增配置'}
                                         onClick={()=>{this.setState({visible_1:true,selectType:0,essentialData:{},ImageUrl:null})}}
                                    >
                                        <u>{'+ 新增配置'}</u>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'95%',padding:'5px',overflowY: 'auto'}}>
                                    <Table
                                        rowKey={'id'}
                                        bordered
                                        pagination={false}
                                        columns={this.state.table_columns}
                                        dataSource={this.state.table_data}
                                        rowSelection={{
                                            selectedRowKeys:this.state.rowDataId,
                                            onChange: this.onSelectChanges,
                                            type: 'radio'
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    {/*下层*/}
                    <div style={{width:'100%',height:'8%'}}>
                        <div style={{float: 'right',padding:'5px'}}>
                            <Button type={'primary'}
                                    onClick={()=>{this.addHomeByOrgId()}}
                            >提交</Button>
                        </div>
                    </div>
                </div>
{/*设备添加/修改-------------------------------*/}
                <Modal
                    title={this.state.selectType===0?"添加配置":'修改配置'}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_1}
                    okText="确定"
                    onOk={async () => {
                        if(this.state.selectType===0){
                            this.addHome();
                        }else{
                            this.updateHome();
                        }

                    }}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={500}
                    centered
                >
                    <div style={{width:'100%',height:'250px'}}>
                        <div style={{width:'90%',height:'100%'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.formParamenter}
                                data={this.state.essentialData}
                            />
                            <div style={{width:'100%',marginLeft:'30px'}}>
                                <div style={{display:this.state.selectType===0?null:'none'}}>
                                    <Upload
                                        action={this.state.fileUrl}
                                        listType="picture-card"
                                        name={"file"}
                                        fileList={this.state.fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.onChangeUpload}
                                    >
                                        {this.state.fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                </div>
                                <div style={{display:this.state.selectType===0?'none':null,height:'70%'}}>
                                    <Image
                                        width={70}
                                        src={this.state?.ImageUrl??null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </Layout>
        )
    }
}
