import React, { Component } from 'react'
import {Layout} from "antd";
import './bottomFive.less'

export default class bottomFive extends Component {
    state = {
        // 空心半圆进度条
        leftData_2:{
            online:{singular:0,total:200},   // 在线率
            failure:{singular:0,total:200},  // 故障率
        },
        tableData:[
            {key:1,name:'燃气安全知识宣传教育班会主题教案'},
            {key:2,name:'学校燃气安全管理方案'},
            {key:3,name:'生产安全用气管理规范'},
            {key:4,name:'城市燃气安全管理问题讨论研究决定'},
            {key:5,name:'燃气安全知识教育教案'},
            {key:7,name:'2024年春节燃气安全教育专题'},
            {key:8,name:'2023年9月燃气安全知识讲座'},
            {key:9,name:'安全阀门日常清理与维护手册'},
            {key:10,name:'长沙市工商户用气安全管理规范'},

        ]
    };


    getTable=()=>{
        var tableData = this.state.tableData;
        var i=true;
        return<>
            {
                tableData.map(one=>{
                    i=!i;
                    return(
                        <div className={i?'bottomFive_data_type_2_table_div_1':'bottomFive_data_type_2_table_div_2'}>
                            <div style={{width:'80%',height:'100%',float:'left',paddingLeft:'1%'}}><span style={{color:"rgb(185 196 207)"}}>{one.name}</span></div>
                            <div style={{width:'20%',height:'100%',float:'left',paddingLeft:'1%'}}><span style={{color:"rgb(185 196 207)"}}>查看</span></div>
                        </div>
                    )
                })
            }
        </>
    };

    render() {
        return (
            <div id={'bottomFive'}>
                <div style={{ width: '100%',height: '20%', padding: '20px 0 0 50px'}}>
                    <span style={{ fontSize: '22px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>教育与培训</span>
                </div>
                <div style={{ width: '90%',height: '80%',paddingLeft:'45px'}}>
                    <div style={{ width: '100%',height: '15%'}}>
                        <div style={{width:'33%',height:'100%',float:'left'}}>
                            <span style={{color:"#fff",width:'45%',float:'left'}}>培训场次</span>
                            <span style={{color:"#FFC18C",float:'left'}}>18</span>
                        </div>
                        <div style={{width:'40%',height:'100%',float:'left'}}>
                            <span style={{color:"#fff",width:'50%',float:'left'}}>参与培训人</span>
                            <span style={{color:"#FFC18C",float:'left'}}>134</span>
                        </div>
                        <div style={{width:'27%',height:'100%',float:'left'}}>
                            <span style={{color:"#fff",width:'60%',float:'left'}}>考试通过</span>
                            <span style={{color:"#FFC18C",float:'left'}}>54</span>
                        </div>
                    </div>
                    <div style={{ width: '100%',height: '75%', overflowY:'auto'}}>
                        {this.getTable()}
                    </div>
                </div>
            </div>
        )
    }
}
