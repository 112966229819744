
import { Component } from 'react'
import { Button, Layout, message, Radio, Modal } from "antd";
import { pageAlarmUser, addUser, deleteUser, updateUser, updateState } from '../../../services/alarm'
import '../../../style/luo.less'
import Pubsub from 'pubsub-js'
import {funTraceInfo} from '../../../services/common'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import React from 'react';
import { TYPE_NAME } from '../../../components/Const'
import Conditions from '../../../components/common/conditions/Conditions'
import Tables from "../../../components/common/tables/Tables";
export default class AlarmUserManagement extends Component {




    state = {
        // statusAndAlarmData:'左框第三个',  // 左-3文本
        responseInfo: {},
        ids: null,
        deleteId: [],
        // 表格参数
        tableParamenter: {
            // 表格行是否可选
            rowSelection: true,
            // 表头：变量和变量解释
            header: {
                userName: '姓名',
                telephone: '电话号码',
                mailbox: '邮箱',
                state: '状态',
                // sendTimer: '定时发送',
                // isOnlineMessage: '是否发送即时在线消息',

            },
            // 下拉框：变量和变量解释
            headers: {
                userName: '姓名',
                telephone: '电话号码',
                mailbox: '邮箱',
                state: '状态',
                // sendTimer: '定时发送',
                // isOnlineMessage: '是否发送即时在线消息',
            },
            // 表格数据
            data: [],
        },// 表格参数END
        expandedKeys: [],
        checkedKeys: [],
        selectedKeys: [],
        autoExpandParent: [],
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,

            parameter: [
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'userName',
                    str: '姓名'
                },
                {
                    type: TYPE_NAME.PHONE_NUM,
                    variableName: 'telephone',
                    str: '电话号码'
                },
                {
                    type: TYPE_NAME.SELECT,
                    variableName: 'state',
                    defaultActiveFirstOption: true,
                    str: '状态',
                    data: [
                        {
                            key: '1',
                            value: '启用'
                        },
                        {
                            key: '2',
                            value: '停用'
                        }
                    ]
                },

            ]
        },

        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 4,
            align: 'center',
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'userName',
                    str: '姓名',
                    rules: TYPE_NAME.INPUT,

                },
                {
                    variableName: 'telephone',
                    str: '电话号码',
                    rules: TYPE_NAME.PHONE_NUM
                },
                {
                    variableName: 'mailbox',
                    str: '邮箱',
                    rules: TYPE_NAME.EMAIL,

                },
                {
                    variableName: 'state',
                    str: '状态',
                    rules: TYPE_NAME.SELECT,
                    data: [
                        {
                            key: '1',
                            value: '启用'
                        },
                        {
                            key: '2',
                            value: '停用'
                        }
                    ]
                },
            ]
        },
        // 总条数
        totalCount: 0,

        page: {
            current: 1,
            size: 10,
        },
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,
        rowData: [],
        visibleadd: false,
        visible2: false,
        updateDatas: {
            id: null,
            userName: "",
            telephone: "",
            mailbox: "",
            state: ""
        }



    };

    async componentDidMount() {
        this.getData();
    }


    getAreaId = () => {
        Pubsub.subscribe('areaId', (msg, data) => {
            let { params } = this.state
            params.areaId = data
            params.page = 1
            params.size = 15
            this.setState({ params })

        })
    }

    // 组件将要被卸载时,取消订阅
    componentWillUnmount() {
        Pubsub.unsubscribe('areaId')
    }



    // 表格：刷新方法
    refreshTable = () => {
        this.setState({ refreshTable: true })
        this.getData();
    }

    // 表格：获取勾选中的值
    onSelectChange = data => {
        let id = data[0].id
        let param = {
            userName: data[0].userName,
            telephone: data[0].telephone,
            mailbox: data[0].mailbox,
            state: data[0].state === 1 ? '启用' : data[0].state === 2 ? '停用' : '-',
        }

        this.setState({ rowData: data, updateDatas: param, ids: id })

    }

    // 表格：导出
    exportTable = () => {
        console.log('数据导出')
    }

    // // 条件选择：获取输入的条件
    getConditionsValue = value => {

        let param = {
            page: this.state.page,
            userName: value.userName,
            state: value.state,
            telephone: value.telephone
        }
        console.log('获取选中的值:', param)
        pageAlarmUser(param).then(
            response => {
                const { data } = response.data
                if (data === null) {
                    message.warning("该条件下不存在用户信息", [0.5])
                    let { tableParamenter } = this.state
                    tableParamenter.data = data
                    const { pageInfo } = response.data
                    let total = pageInfo.total
                    this.setState({ tableParamenter, totalCount: total })
                }
                let { tableParamenter } = this.state
                tableParamenter.data = data
                const { pageInfo } = response.data
                let total = pageInfo.total
                this.setState({ tableParamenter, totalCount: total })

                message.success('查询成功', [0.5])
            }
        )
    }

    // 获取当前页数和条数

    getPagination = (page, pageSize) => {
        let { params } = this.state
        params.pageInfo.pageIndex = page
        params.pageInfo.pageLine = pageSize
        this.setState({ params })
        if (this.state.username == null) {
            this.getData()
        } else {
            this.getDatas()
        }
        // this.getData()
    }


    // 清空条件
    clearCondition = () => {
        this.getData();
    }




    // 初始化表格数据
    getData = () => {
        this.setState({ loading: true });
        let param = {
            page: this.state.page
        }
        pageAlarmUser(param).then(
            response => {
                let { data } = response.data;
                console.log("初始化的数据", data)
                let { tableParamenter } = this.state;
                tableParamenter.data = data;
                // tableParamenter.data = data.voAlarmNoticeUsers;
                const { pageInfo } = response.data
                let total = pageInfo.total
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter: tableParamenter,
                    totalCount: total
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')
            }
        )
    };




    setTableValue = (value, text, rowData) => {
        switch (value) {
            case 'state':
                text = text * 1
                return text === 1 ? '启用' : text === 2 ? '停用' : '-'
            case 'sendTimer':
                text = text * 1
                return text === 1 ? '一天1次' : text === 2 ? '一天两次' : text === 3 ? '一天三次' : '-'
            case 'isOnlineMessage':
                text = text * 1
                return text === 1 ? '发送' : text === 2 ? '不发送' : '-'
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    }


    //用户注销
    deleteInfo = data => {
        if (this.state.rowData.length < 1) return message.warning('未选择数据', [0.5])
        let param = {
            id: this.state.ids
        }
        Modal.confirm({
            title: `是否确认删除通知人？`,
            content: '',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                // 注销用户
                deleteUser(param).then(
                    response => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                            totalCount: data.total,
                        });
                        message.success('用户删除成功', [1])
                        // funTraceInfo("报警管理", "删除报警通知人", null,param)
                        this.getData();
                    }
                ).catch(
                    () => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                        });
                        message.error('用户删除失败', [1])
                        this.getData();
                    }
                )
            }
        })
    }

    //变更状态
    updateStatus = () => {
        if (this.state.rowData.length < 1) return message.warning('未选择数据', [0.5])
        let status = this.state.rowData[0].state
        let param = {
            id: this.state.ids,
            state:status
        }
        console.log("状态", param)
        updateState(param).then(
            response => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                    // totalCount: data.total,
                });
                message.success('用户状态更改成功', [1])
                // funTraceInfo("报警管理", "变更状态", null,param)
                this.getData()
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('用户状态更改失败', [1])
                this.getData()
            }
        )
    }


    showes = () => {
        this.setState({
            visibleadd: true
        });
        setTimeout(() => this.forms.onModalClose(), 0)
    };

    handleOk = async () => {
        this.setState({ loading: true });
        let value = await this.forms.onFinish()
        let params = {
            userName: value.userName,
            telephone: value.telephone,
            mailbox: value.mailbox,
            state: value.state
        }
        // 新增数据权限
        addUser(params).then(
            response => {
                response.data.data ? message.success('新增成功', [0.5]) : message.error('新增失败', [0.5])
                // funTraceInfo("报警管理", "新增", null,params)
                this.getData();
            }
        )
        setTimeout(() => {
            this.setState({ loading: false, visibleadd: false });
        }, 0);

    };

    handleCancel = () => {
        this.setState({ visibleadd: false });
    };


    updateInfo = () => {

        if (this.state.rowData.length < 1) return message.warning('未选择数据', [0.5])
        this.setState({
            visible2: true
        });
        setTimeout(() => this.forms.onModalClose(), 0)
    };

    updateOr = async () => {
        this.setState({ loading: true });
        let value = await this.forms.onFinish()
        let params = {
            id: this.state.ids,
            userName: value.userName,
            telephone: value.telephone,
            mailbox: value.mailbox,
            state: value.state === '启用' ? 1 : value.state === '停用' ? 2 : '-'
        }
        console.log("修改的参数", params)
        // 修改
        updateUser(params).then(
            response => {
                response.data.data ? message.success('修改成功', [0.5]) : message.error('修改失败', [0.5])
                // funTraceInfo("报警管理", "修改",value,params)
            }

        )

        setTimeout(() => {
            this.setState({
                loading: false,
                visible2: false,
            });
        }, 1000);
        this.getData();
    };

    updateOrS = () => {
        this.setState({ visible2: false });
    };


    render() {

        const { loading, visibleadd, visible2 } = this.state;
        return (
            <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
                <Tables
                    tableParamenter={this.state.tableParamenter}  // 表格表头、数据
                    exportTable={this.exportTable}                  // 导出时触发的方法
                    onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                    refreshTable={this.refreshTable}                // 刷新时触发的方法
                    getPagination={this.getPagination}              // 分页器变化时触发的方法
                    setTableValue={this.setTableValue}              // 设置表格显示
                    pagination={{
                        totalCount: this.state.totalCount, // 总条数
                        page: this.state.page.current,// 当前页数
                        size: this.state.page.size // 页面条数
                    }}

                    loading={{
                        loading: this.state.loading,
                        refreshTable: this.state.refreshTable
                    }}
                >
                    <Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
                        <Radio.Group defaultValue="addInfo" buttonStyle="solid">
                            <Radio.Button value="addInfo" type="primary" onClick={this.showes} >新增</Radio.Button>
                            <Modal
                                visible={visibleadd}
                                title="新增用户信息"
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                footer={[
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={loading}
                                        onClick={this.handleOk}
                                    >
                                        提交
                                    </Button>,
                                    <Button key="back" onClick={this.handleCancel}>
                                        返回
                                    </Button>
                                ]}
                            >
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.formParamenter}
                                    data={this.state.data}
                                />
                            </Modal>

                            <Radio.Button value="deleteInfo" onClick={this.deleteInfo}>删除</Radio.Button>
                            <Radio.Button value="updateInfo" onClick={this.updateInfo}>修改</Radio.Button>
                            <Radio.Button value="updateStatus" onClick={this.updateStatus}>状态变更</Radio.Button>
                            <Modal
                                visible={visible2}
                                title="修改用户信息"
                                onOk={this.updateOr}
                                onCancel={this.updateOrS}
                                footer={[
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={loading}
                                        onClick={this.updateOr}
                                    >
                                        提交
                                    </Button>,
                                    <Button key="back" onClick={this.updateOrS}>
                                        返回
                                    </Button>
                                ]}
                            >
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.formParamenter}
                                    data={this.state.updateDatas}
                                />
                            </Modal>
                            {/* <Radio.Button value="deactivateInfo" onClick={this.deactivateInfo}>状态变更</Radio.Button> */}
                        </Radio.Group>
                    </Conditions>
                </Tables>
            </Layout>
        )
    }

}
