import React, { Component } from 'react'
import {Input, Layout, Button, message} from 'antd'
import {ArrowLeftOutlined} from '@ant-design/icons';
import FormsText from "../../../components/common/forms/FormsText";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {FORM_ITEM, TYPE_NAME} from "../../../components/Const";
import $ from 'jquery'
import {saveLocation, updateInstallationPoint} from "../../../services/customer";


//地图安装点
export default class InstallationMap extends React.Component{
    state={
        latNum:this.props.location.state.rowData?.voLocationInfo?.latitude,  // 经度
        lngNum:this.props.location.state.rowData?.voLocationInfo?.longitude,  // 维度
        name:this.props.location?.state?.rowData?.name??'',    // 安装点名称

        // 客户数据
        FormsText_1:{
            customerId:'客户编号',
            principalPhone:'联系电话',
            customerName:'客户名称',
            address:'详细地址',
            mpName:'联系人',
        },
        formsTwo:this.props.location?.state?.formsTwo,// 客户基本信息

        // 详细地址
        topFormItem_1: {
            modal: true,
            number: 3,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'village', str: '小区', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'storey', str: '楼层', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'building', str: '楼栋', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'roomNo', str: '房号', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'unit', str: '单位', rules: TYPE_NAME.INPUT,disabled: false,},
                {variableName: 'houseNo', str: '门牌号', rules: TYPE_NAME.INPUT,disabled: false,},
            ]
        },
        topFormItem_1_data:{
            village:this.props.location.state.rowData?.voLocationInfo?.communities,  // 小区
            storey:this.props.location.state.rowData?.voLocationInfo?.storeyNum,     // 楼层
            building:this.props.location.state.rowData?.voLocationInfo?.buildNum,    // 楼栋
            unit:this.props.location.state.rowData?.voLocationInfo?.unitNum,         // 单位
            houseNo:this.props.location.state.rowData?.voLocationInfo?.doorplateNum, // 门牌号
        },
        juder:this.props.location.state.juder??false,// 修改false,新增true
    };

    componentDidMount =async ()=>{

        document.getElementById('address').value=this.props.location?.state?.rowData?.address;
        this.showInfo();
    };

    // 地图初始化
    showInfo = data => {
        var path = [];
        var map = new window.BMap.Map("container");

        console.log('this.props.location.state.rowData?.voLocationInfo');
        var lng = this.props.location.state.rowData?.voLocationInfo?.longitude || 112.988112;// 经度
        var lat = this.props.location.state.rowData?.voLocationInfo?.latitude || 28.217621;  // 维度
        console.log(lng);
        console.log(lat);
        var point = new window.BMap.Point(lng,lat);
        map.centerAndZoom(point, 15);

        // 清空已有坐标
        var allOverlay = map.getOverlays();
        for (var i = 0; i < allOverlay.length; i++){map.removeOverlay(allOverlay[i]);}
        map.enableScrollWheelZoom();

        // 初始化判断有无坐标
        if(this.props.location.state.rowData?.voLocationInfo?.latitude ){
            var marker2 = new window.BMap.Marker(new window.BMap.Point(
                this.props.location.state.rowData?.voLocationInfo?.longitude,   // 经度
                this.props.location.state.rowData?.voLocationInfo?.latitude     // 维度
            ));
            // 在地图上添加点标记
            map.addOverlay(marker2);
        }


        map.addEventListener('click', function (e) {
            console.log(e);
            console.log('-----------------------');
            $('#latNum').text(e?.point?.lat??null);
            $('#lngNum').text(e?.point?.lng??null);
            // 坐标
            var gc = new window.BMap.Geocoder();
            var pt = e.point;
            var pathNum={lng:e?.Cg?.lng??null, lat:e?.Cg?.lat??null};
            // 清空已有坐标
            var allOverlay = map.getOverlays();
            for (var i = 0; i < allOverlay.length; i++){map.removeOverlay(allOverlay[i]);}
            if(path.length>=1){path=[];}
            path.push(pathNum);
            map.addOverlay(new window.BMap.Marker(path[0]));

            gc.getLocation(pt,function (rs) {
                console.log(rs);
                var addComp = rs.addressComponents;
                $('#province').text(addComp.province);
                $('#city').text(addComp.city);
                $('#district').text(addComp.district);
                $('#street').text(addComp.street);
                $('#streetNumber').text(addComp.streetNumber);
                $('#address').text(addComp.province+addComp.city+addComp.district+addComp.street+addComp.streetNumber);

                document.getElementById('address').value = addComp.province+addComp.city+addComp.district+addComp.street+addComp.streetNumber;
            })
        });
        // 创建点标记
        if(this.state?.rowData?.voLocationInfo?.latitude){
            var marker1 = new window.BMap.Marker(new window.BMap.Point(this.state?.rowData?.voLocationInfo?.latitude, this.state?.rowData?.voLocationInfo?.longitude));
            // 在地图上添加点标记
            map.addOverlay(marker1);
        }
    };

    // 新增
    saveLocation=(params)=>{
        var data = {
            locationType:'客户'
        };
        data=Object.assign({}, data,params);
        saveLocation(data).then(
            response=>{
                if(response.data.data){
                    // message.info(response.data.responseInfo.description);
                    this.props.history.push({
                        pathname:"/customer/devInstallationNew",
                        state: {
                            formsTwo:this.props.location?.state?.formsTwo,
                            // rowData: {id:response.data.data},
                            rowData:this.props.location?.state?.rowData
                        }

                    });
                    // funTraceInfo("客户管理", "提交", null,params);
                    return message.info('提交成功');
                }else {
                    return message.info('提交失败');
                }
            }
        )
    };
    // 修改
    updateInstallationPoint=(params)=>{
        var data = {
            locationType:'客户'
        };
        data=Object.assign({}, data,params);
        updateInstallationPoint(data).then(
            response=>{
                if(response.data.data){
                    // message.info(response.data.responseInfo.description);
                    this.props.history.push({
                        pathname:"/customer/devInstallationNew",
                        state: {
                            formsTwo:this.props.location?.state?.formsTwo,
                            rowData:this.props.location?.state?.formsTwo,
                        }
                    });
                    // funTraceInfo("客户管理", "修改", null,params)
                    return message.info('修改成功');
                }else {
                    return message.info('修改失败');
                }
            }
        )
    };


    params= async (params) =>{
        // 提交数据
        console.log('数据处理');

        var forms1 = await this.forms1.onFinishNotReset();  // 详细位置
        var input = document.getElementById("address");   // 详细地址
        params ={
            id:'',
            customerId:this.state?.formsTwo?.customerId,    // 客户id
            customerName:this.state?.formsTwo?.customerName,// 客户名

            locationName:this.state.name,          // 安装点名称
            address:input.value,                   // 安装点详细地址
            longitude:$("#lngNum").text()??this.props.location.state?.rowData?.voLocationInfo?.longitude, // 经度
            latitude: $("#latNum").text()??this.props.location.state?.rowData?.voLocationInfo?.latitude,  // 维度

            province:$("#province").text()??this.props.location.state?.rowData?.voLocationInfo?.province,   // 省
            city:$("#city").text()??this.props.location.state?.rowData?.voLocationInfo?.city,               // 市
            district:$("#district").text()??this.props.location.state?.rowData?.voLocationInfo?.district,   // 区
            street:$("#street").text()??this.props.location.state?.rowData?.voLocationInfo?.street,         // 街道

            communities:forms1.village ?? null,     // 小区
            storeyNum:forms1.storey ?? null,        // 楼层
            buildNum:forms1.building ?? null,       // 楼栋
            // roomNo:this.state.roomNo,            // 房号
            unitNum:forms1.unit ?? null,            // 单元号
            doorplateNum:forms1.houseNo ?? null,    // 门牌号
        };

        if(this.state.juder){
            params.id=this.props.location?.state?.rowData?.id;
        }
        return params;
    };

    // 创建并安装
    onClick_1=async ()=>{
        var params = await this.params();

        // 判断安装和修改
        if(this.state.juder){
            // 修改
            updateInstallationPoint(params).then(
                response=>{
                    if(response.data.data){
                        this.historyPush(params);
                        message.info('修改成功');
                    }else {
                        return message.info('修改失败');
                    }
                }
            )
        }else{
            // 新增
            saveLocation(params).then(
                response=>{
                    console.log('params.id=response.data.data');
                    console.log(response.data.data);
                    if(response.data.data){
                        // message.info(response.data.responseInfo.description);
                        params.id=response.data.data;
                        // funTraceInfo("客户管理", "提交", null,params);
                        message.info('提交成功');
                        this.historyPush(params);
                    }else {
                        return message.info('提交失败');
                    }
                }
            )
        }
    };

    // 提交
    onClick_2=async ()=>{
        var params = await this.params();
        if(this.state.juder){
            this.updateInstallationPoint(params)// 修改
        }else{
            this.saveLocation(params);// 新增
        }
    };
    // 跳转页面onClick_1
    historyPush=(data)=>{
        console.log('historyPush');
        console.log(data);
        data.name = data?.locationName;
        data.state = this.props.location.rowData?.state;
        this.props.history.push({
            pathname:"/customer/devInstallationNew",
            state: {
                formsTwo: this.props.location?.state?.formsTwo,
                rowData: data
            }
        });
    };

    render() {
        return (
            <Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width:'100%'}}>
                    <div className={'div_back_arrow'}>
                        <span className={'back_arrow'} onClick={() => {this.props.history.go(-1, {state: {rowData: this.props.location?.state?.formsTwo}})}}>
                            <ArrowLeftOutlined />
                        </span>&nbsp;&nbsp;&nbsp;
                        <span className={'font_size1'}>返回上一页</span>
                    </div>
                </div>
                <div style={{width:'100%',height:'160px'}}>
                    {/*左--------------------------------------------------------------*/}
                    <div style={{width:'30%',height:'100%',float:"left",padding:'10px'}}>
                        <FormsText
                            record={this.state?.formsTwo??{}}
                            header={this.state.FormsText_1}
                            rowLen={2}
                            span={6}
                        />
                    </div>
                    {/*中-------------------------------------------------------------*/}
                    <div style={{width:'25%',height:'100%',float:"left",padding:'10px'}}>
                        <div style={{width:'100%',height:'55px'}}>
                            <span>安装点名称:</span>
                            <Input style={{ width: '70%',marginLeft: '1%'}}
                                   value={this.state.name}
                                   onChange={(text)=>{this.setState({name:text.target.value})}}
                                    />
                        </div>
                        <div style={{width:'100%',height:'55px'}}>
                            <span>安装点地址:</span>
                            <input id={"address"} type={'text'}
                                style={{border:"#d9d9d9 1px solid",width:'70%',height:'32px',marginLeft:'1%'}}
                            />
                        </div>
                        <div style={{width:'100%',height:'40px'}}>
                            <div style={{width:'50%',float:'left'}}><span>经度:</span><span id={'lngNum'} >{this.state.latNum}</span></div>
                            <div style={{width:'50%',float:'left'}}><span>纬度:</span><span id={'latNum'} >{this.state.lngNum}</span></div>
                        </div>
                    </div>
                    {/*右-------------------------------------------------------------*/}
                    <div style={{width:'45%',height:'100%',float:"left",padding:'10px'}}>
                        <FormsTwo
                            ref={e => this.forms1 = e}
                            formParamenter={this.state.topFormItem_1}
                            data={this.state.topFormItem_1_data}
                        />
                    </div>
                </div>
{/*底部按钮-----------------------------------------------------------------------------------------------------------*/}
                <div style={{width:'100%',height:'100%',float:"left"}}>
                    <div style={{width:'100%',height:'85%'}}>
                        <div id="container" style={{width:'100%',height:'100%'}} />
                    </div>
                    <div style={{width:'100%',height:'9%',padding:'2%'}}>
                        <div style={{float:'right'}}>
                            <Button type="primary"
                                    style={{
                                        display: this.state.juder?'none':null}}
                                    onClick={this.onClick_1}>
                                创建并安装</Button>

                            <Button type="primary" style={{marginLeft:'10px'}}
                                    onClick={this.onClick_2}>
                                提交
                            </Button>
                        </div>
                    </div>
                </div>

                <div id={'province' } style={{  display: 'none'}}/>
                <div id={'city' } style={{  display: 'none'}}/>
                <div id={'district'} style={{  display: 'none'}}/>
                <div id={'street'} style={{  display: 'none'}}/>
                <div id={'streetNumber'} style={{  display: 'none'}}/>

            </Layout>


        );
    }


}






//
// import React, { Component } from 'react'
// import {Input, Layout, Button, message} from 'antd'
// // import {Map, Marker, NavigationControl, InfoWindow,Polyline,Polygon} from 'react-bmap'
// import './InstallationMapNew.less'
// import {ArrowLeftOutlined} from '@ant-design/icons';
// import $ from 'jquery'
// import {funTraceInfo} from '../../../services/common'
// import {saveLocation,updateInstallationPoint} from '../../../services/customer'
//
// //地图安装点
// export default class InstallationMap extends React.Component{
//     state = {
//         latNum:'',  // 经度
//         lngNum:'',  // 维度
//         name:this.props.location?.state?.rowData?.name?this.props.location?.state?.rowData?.name:'',    // 安装点名称
//         address:this.props.location?.state?.rowData?.address?this.props.location?.state?.rowData?.address:'', // 安装点详细地址
//         village:'', // 小区
//         storey:'',  // 楼层
//         building:'',// 楼栋
//         roomNo:'',  // 房号
//         unit:'',    // 单位
//         houseNo:'', // 门牌号
//
//         juder:false,
//         formsTwo:this.props.location?.state?.formsTwo,// 客户基本信息
//         rowData:this.props.location?.state?.rowData,// 选取数据
//     };
//     // 地图初始化
//     showInfo = data => {
//         var opts = {
//             width : 200,     		// 信息窗口宽度
//             height: 100,     		// 信息窗口高度
//             title : "故宫博物院" ,	// 信息窗口标题
//             message:"这里是故宫"
//         };
//         // var text111="";
//         // var infoWindow = new window.BMap.InfoWindow("地址：北京市东城区王府井大街88号乐天银泰百货八层", opts);
//         // var mapArry=[[112.949494,28.235399]];
//         var path = [];
//         var map = new window.BMap.Map("container");
//         var lng = this.state?.rowData?.voLocationInfo?.latitude || 112.988112;
//         var lat = this.state?.rowData?.voLocationInfo?.longitude || 28.217621;
//         var point = new window.BMap.Point(lng,lat);
//         map.centerAndZoom(point, 15);
//         // 清空已有坐标
//         var allOverlay = map.getOverlays();
//         for (var i = 0; i < allOverlay.length; i++){
//             map.removeOverlay(allOverlay[i]);
//         }
//
//
//
//         map.enableScrollWheelZoom();
//
//         map.addEventListener('click', function (e) {
//             $('#latNum').text(e.Cg.lat);
//             $('#lngNum').text(e.Cg.lng);
//             // 坐标
//             var gc = new window.BMap.Geocoder();
//             console.log(gc);
//             console.log(gc);
//             var pt = e.point;
//             var pathNum={lng:e.Cg.lng,
//                 lat:e.Cg.lat
//             };
//             // 清空已有坐标
//             var allOverlay = map.getOverlays();
//             for (var i = 0; i < allOverlay.length; i++){
//                 map.removeOverlay(allOverlay[i]);
//             }
//
//             if(path.length>=1){
//                 path=[];
//             }
//             path.push(pathNum);
//             map.addOverlay(new window.BMap.Marker(path[0]));
//             console.log('pt');
//             console.log(pt);
//             gc.getLocation(pt,function (rs) {
//                 console.log(rs);
//                 var addComp = rs.addressComponents;
//                 $('#province').text(addComp.province);
//                 $('#city').text(addComp.city);
//                 $('#district').text(addComp.district);
//                 $('#street').text(addComp.street);
//                 $('#streetNumber').text(addComp.streetNumber);
//                 $('#address').text(addComp.province+addComp.city+addComp.district+addComp.street+addComp.streetNumber);
//             })
//         });
//         // 创建点标记
//         if(this.state?.rowData?.voLocationInfo?.latitude){
//             var marker1 = new window.BMap.Marker(new window.BMap.Point(this.state?.rowData?.voLocationInfo?.latitude, this.state?.rowData?.voLocationInfo?.longitude));
//             // 在地图上添加点标记
//             map.addOverlay(marker1);
//         }
//
//
//
//     };
//     componentDidMount(){
//
//         this.setState({
//             latNum:this.state?.rowData?.voLocationInfo?.longitude,      // 经度
//             lngNum:this.state?.rowData?.voLocationInfo?.latitude,      // 维度
//             village:this.state?.rowData?.voLocationInfo?.communities,  // 小区
//             storey:this.state?.rowData?.voLocationInfo?.storeyNum,     // 楼层
//             building:this.state?.rowData?.voLocationInfo?.buildNum,    // 楼栋
//             unit:this.state?.rowData?.voLocationInfo?.unitNum,         // 单位
//             houseNo:this.state?.rowData?.voLocationInfo?.doorplateNum, // 门牌号
//             juder:this.props.location.state?.juder,
//
//
//         });
//         this.showInfo();// 地图初始化
//     }
//
//     addParams=()=>{
//         // 提交数据
//         var params ={
//             id:'',
//             customerId:this.state?.formsTwo?.customerId,    // 客户id
//             customerName:this.state?.formsTwo?.customerName,// 客户名
//             longitude:$("#latNum").text(),          // 经度
//             latitude:$("#lngNum").text(),           // 维度
//             locationName:this.state.name,    // 安装点名称
//             address:this.state.address,         // 安装点详细地址
//             province:$("#province").text(),         // 省
//             city:$("#city").text(),                 // 市
//             district:$("#district").text(),         // 区
//             street:$("#street").text(),             // 街道
//             communities:this.state.village,         // 小区
//             storeyNum:this.state.storey,            // 楼层
//             buildNum:this.state.building,           // 楼栋
//             // roomNo:this.state.roomNo,  // 房号
//             unitNum:this.state.unit,                // 单元号
//             doorplateNum:this.state.houseNo,        // 门牌号
//         };
//         if(this.state.juder){
//             params.id=this.state?.rowData?.id;
//         }
//         return params;
//     };
//     params=()=>{
//         // 提交数据
//         var params ={
//             id:'',
//             customerId:this.state?.formsTwo?.customerId,    // 客户id
//             customerName:this.state?.formsTwo?.customerName,// 客户名
//             longitude:$("#latNum").text()?$("#latNum").text():this.props.location.state?.rowData?.voLocationInfo?.longitude,     // 经度
//             latitude:$("#lngNum").text()?$("#lngNum").text():this.props.location.state?.rowData?.voLocationInfo?.latitude,       // 维度
//             locationName:this.state.name,       // 安装点名称
//             address:this.state.address,         // 安装点详细地址
//             province:$("#province").text()?$("#province").text():this.props.location.state?.rowData?.voLocationInfo?.province,   // 省
//             city:$("#city").text()?$("#city").text():this.props.location.state?.rowData?.voLocationInfo?.city,                   // 市
//             district:$("#district").text()?$("#district").text():this.props.location.state?.rowData?.voLocationInfo?.district,   // 区
//             street:$("#street").text()?$("#street").text():this.props.location.state?.rowData?.voLocationInfo?.street,           // 街道
//             communities:this.state.village,         // 小区
//             storeyNum:this.state.storey,            // 楼层
//             buildNum:this.state.building,           // 楼栋
//             // roomNo:this.state.roomNo,  // 房号
//             unitNum:this.state.unit,                // 单元号
//             doorplateNum:this.state.houseNo,        // 门牌号
//         };
//         // if(params?.province===undefined){
//         //     params.province=this.props.location?.rowData?.province;        // 省
//         //     params.city=this.props.location?.rowData?.city;                // 市
//         //     params.district=this.props.location?.rowData?.district;         // 区
//         //     params.street=this.props.location?.rowData?.street;            // 街道
//         // }
//         if(this.state.juder){
//             console.log(this.props.location?.state?.rowData?.id);
//             params.id=this.props.location?.state?.rowData?.id;
//         }
//         return params;
//     };
//     // 提交并安装
//     onClick_1=()=>{
//         var params =this.params();
//         // 判断安装和修改
//         if(this.state.juder){
//             // 修改
//             updateInstallationPoint(params).then(
//                 response=>{
//                     console.log(response.data);
//                     if(response.data.data){
//                         // message.info(response.data.responseInfo.description);
//                         // funTraceInfo("客户管理", "修改", null,params);
//                         this.historyPush(params);
//                         message.info('修改成功');
//                     }else {
//                         return message.info('修改失败');
//                     }
//                 }
//             )
//         }else{
//             // 新增
//             saveLocation(params).then(
//                 response=>{
//                     console.log('params.id=response.data.data');
//                     console.log(response.data.data);
//                     if(response.data.data){
//                         // message.info(response.data.responseInfo.description);
//                         params.id=response.data.data;
//                         // funTraceInfo("客户管理", "提交", null,params);
//                         message.info('提交成功');
//                         this.historyPush(params);
//                     }else {
//                         return message.info('提交失败');
//                     }
//                 }
//             )
//         }
//
//     };
//     // 提交
//     onClick_2=()=>{
//         var params =this.params();
//         console.log(params);
//         if(this.state.juder){
//             this.updateInstallationPoint(params)// 修改
//         }else{
//             this.saveLocation(params);// 新增
//         }
//     };
//     // 修改
//     updateInstallationPoint=(params)=>{
//         updateInstallationPoint(params).then(
//             response=>{
//                 console.log(response);
//                 if(response.data.data){
//                     // message.info(response.data.responseInfo.description);
//                     this.props.history.push({
//                         pathname:"/customer/InstallationInfo",
//                         state: {
//                             formsTwo:this.props.location?.state?.formsTwo,
//                             rowData:this.props.location?.state?.formsTwo,
//                         }
//                     });
//                     // funTraceInfo("客户管理", "修改", null,params)
//                     return message.info('修改成功');
//                 }else {
//                     return message.info('修改失败');
//                 }
//             }
//         )
//     };
//     // 新增
//     saveLocation=(params)=>{
//         saveLocation(params).then(
//             response=>{
//                 if(response.data.data){
//                     // message.info(response.data.responseInfo.description);
//                     this.props.history.push({
//                         pathname:"/customer/InstallationInfo",
//                         state: {
//                             formsTwo:this.props.location?.state?.formsTwo,
//                             // rowData: {id:response.data.data},
//                             rowData:this.props.location?.state?.rowData
//                         }
//
//                     });
//                     // funTraceInfo("客户管理", "提交", null,params);
//                     return message.info('提交成功');
//                 }else {
//                     return message.info('提交失败');
//                 }
//             }
//         )
//     };
//
//     // 跳转页面onClick_1
//     historyPush=(data)=>{
//         console.log('historyPush');
//         console.log(data);
//         data.name = data?.locationName;
//         data.state = this.props.location.rowData?.state;
//         this.props.history.push({
//             pathname:"/customer/devInstallationNew",
//             state: {
//                 formsTwo: this.props.location?.state?.formsTwo,
//                 rowData: data
//             }
//         });
//     };
//
//     render(){
//         return (
//             <Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
//                 <div id={'InstallationMap'}>
//                     <div style={{width:'100%'}}>
//                         <div className={'div_back_arrow'}>
//                             <span className={'back_arrow'} onClick={() => {
//
//                                 // this.props.history.push({
//                                 //     pathname: "/customer/InstallationInfo",
//                                 //     state: {
//                                 //         rowData: this.props.location?.state?.formsTwo
//                                 //     }
//                                 // });
//                                 this.props.history.go(
//                                     -1,
//                                     {
//
//                                     state: {
//                                         rowData: this.props.location?.state?.formsTwo
//                                     }
//                                 }
//                                 )
//                             }}>
//                                 <ArrowLeftOutlined />
//                             </span>&nbsp;&nbsp;&nbsp;
//                             <span className={'font_size1'}>返回上一页</span>
//                         </div>
//                     </div>
//                     <div style={{width:'100%',height:'15%'}}>
//
//                         <div style={{width:'55.5%',height:'100%',float: 'left', }}>
//                             <div className='formText_1_InstallationMap'>
//                                 <ul>
//                                     <li style={{width:'100%',fontSize:'18PX',fontWeight: 'bold'}}>客户编号</li>
//                                     <li>客户编号：<span>{this.state?.formsTwo?.customerId?this.state?.formsTwo?.customerId:"-"}</span></li>
//                                     <li style={{width:'55.5%'}}>联系电话：<span>{this.state?.formsTwo?.principalPhone?this.state?.formsTwo?.principalPhone:"-"}</span></li>
//                                     <li>客户名称：<span>{this.state?.formsTwo?.customerName?this.state?.formsTwo?.customerName:"-"}</span></li>
//                                     <li>详细地址：<span>{this.state?.formsTwo?.address?this.state?.formsTwo?.address:"-"}</span></li>
//                                     {/*输入框*/}
//                                     <li style={{width:'36%'}}>安装点名称：
//                                         <Input style={{ width: '56%',marginLeft: '8.2%'}} size="small"
//                                                value={this.state.name}
//                                                onChange={(text)=>{
//                                                    this.setState({name:text.target.value})
//                                                }}
//                                         />
//                                     </li>
//                                     <li>联系人：<span>{this.state?.formsTwo?.mpName?this.state?.formsTwo?.mpName:"-"}</span></li>
//                                     <li>创建时间：<span>-</span></li>
//                                     {/*输入框*/}
//                                     <li style={{width:'40%' }}>安装点详细地址：
//                                         <Input
//                                             type={'text'} id={'address'} size="small"
//                                             style={{ width: '50%' }}
//                                             value={this.state.address}
//                                             onChange={(text)=>{
//                                                 this.setState({address:text.target.value})}}
//                                         />
//                                         <a
//                                             style={{marginLeft:'1px'}}
//                                             onClick={()=>{
//                                                 var textValue = [...this.state.address];
//                                                 textValue=$("#address").text();
//                                                 this.setState({address:textValue})
//                                         }}>
//                                             获取位置
//                                         </a>
//                                     </li>
//
//                                 </ul>
//                             </div>
//                             <div>
//
//                             </div>
//                         </div>
//
//                         <div style={{width:'44.5%',height:'100%',float: 'left'}}>
//                             <div className='topTextList'>
//                                 <ul>
//                                     <li>小区：
//                                         <Input style={{ width: '65%' }} size="small" id={'province'}
//                                                value={this.state.village}
//                                                onChange={(text)=>{this.setState({village:text.target.value})}}
//                                         />
//                                     </li>
//                                     <li>楼层：
//                                         <Input style={{ width: '65%' }} size="small" id={'city'}
//                                                value={this.state.storey}
//                                                onChange={(text)=>{this.setState({storey:text.target.value})}}
//                                         />
//                                     </li>
//                                     <li>楼栋：
//                                         <Input style={{ width: '65%' }} size="small" id={'district'}
//                                                value={this.state.building}
//                                                onChange={(text)=>{this.setState({building:text.target.value})}}
//                                         />
//                                     </li>
//                                     <li>房号：
//                                         <Input style={{ width: '65%' }} size="small" id={'street'}
//                                                value={this.state.roomNo}
//                                                onChange={(text)=>{this.setState({roomNo:text.target.value})}}
//                                         />
//                                     </li>
//                                     <li>单位：
//                                         <Input style={{ width: '65%' }} size="small" id={'streetNumber'}
//                                                value={this.state.unit}
//                                                onChange={(text)=>{this.setState({unit:text.target.value})}}
//                                         />
//                                     </li>
//                                     <li>门牌号：
//                                         <Input style={{ width: '65%' }} size="small"
//                                                value={this.state.houseNo}
//                                                onChange={(text)=>{
//                                                    console.log(text.target.value);
//                                                    this.setState({houseNo:text.target.value})}}
//                                         />
//                                     </li>
//                                     <li>经度：<span id={'latNum'} >{this.state.latNum}</span></li>
//                                     <li>纬度：<span id={'lngNum'} >{this.state.lngNum}</span></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//
//                     <div style={{width:'100%',height:'70%'}}>
//                         <div id="container" style={{width:'100%',height:'100%'}} />
//                     </div>
//                     <div style={{width:'100%',height:'9%',padding:'2%'}}>
//                         <div style={{float:'right'}}>
//                             <Button type="primary"
//                                     onClick={this.onClick_1}>
//                                 创建并安装</Button>
//                             <Button type="primary" style={{marginLeft:'10px'}}
//                                     onClick={this.onClick_2}>
//                                 提交
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </Layout>
//         )
//     }
// }
