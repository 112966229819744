import React,{Component} from 'react';

import '../meterMap_div.less'
import '../meterMap_type.less'
import '../less/rightType.less'
import {message} from "antd";
import {getDateByUserId} from "../../../../services/admin";
import {getVerificationInfo, queryProAndMarketingByAreaId} from "../../../../services/earlyWarning";
import * as echarts from "echarts";
import $ from 'jquery'
// 左侧下部分样式
class rightType extends React.Component {

    state = {
        rightData_1: {
            dtaa_1: '0',  // 购销差
            dtaa_2: '0', // 供销差
        },
        rightData_2: {
            dtaa_1: '0', // 总供给
            dtaa_2: '0', // 供销差
            dtaa_3: '0', // 总销售
        },
        // 差曲线图/差柱状图
        rightData_3: {
            xData:[],
            yData_left:{min:0,max:100,interval:500},
            yData_right:{min:0,max:25,interval:15},
            data_1:[],
            data_2:[]
        },
        rightData_4: {
            complete_1:0,// 今日
            complete_2:0,// 本月
            complete_3:0,// 今年
        },
        rightData_5: {
            already_1:0, // 今日
            already_2:0, // 本月
            already_3:0, // 今年
            need_1:0,    // 今日
            need_2:0,    // 本月
            need_3:0,    // 今年
        },
        allAreaId:[]

    };
    componentDidMount=()=>{
        this.getDateByUserId();// 获取当前用户区域id
        this.elementById();
    };
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

    }

    // 获取当前用户区域id
    getDateByUserId=()=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    var allAreaId = this.state.allAreaId;
                    this.forChildren(response?.data,allAreaId);
                    this.queryProAndMarketingByAreaId(allAreaId,'蒸汽');   // 首页产销差及7日图
                    this.getVerificationInfo(allAreaId);            // 检定信息
                    this.setState({allAreaId})
                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };
    forChildren=(children=[],areaIds=[])=>{
        children?.map(one=>{
            if(one.type==='蒸汽'){
                areaIds.push(one.id.toString());
            }
            if(one?.children){
                this.forChildren(one?.children,areaIds);
            }
        });
        return areaIds;
    };
    // 首页产销差及7日图
    queryProAndMarketingByAreaId=(areaIds,type)=>{
        const params = new URLSearchParams();
        params.append('areaIds', [287,289,290]);
        params.append('type', type);
        queryProAndMarketingByAreaId(params).then(
            response => {
                if(response?.data?.data){
                    console.log('首页产销差及7日图');
                    var {rightData_1, rightData_2, rightData_3} = this.state;
                    rightData_1.dtaa_1=response?.data?.data?.procureAndProductionRate;  // 购供差
                    rightData_1.dtaa_2=response?.data?.data?.productionAndMarkRate;     // 产销差
                    rightData_2.dtaa_1=response?.data?.data?.procure; // 总供给
                    rightData_2.dtaa_2=response?.data?.data?.production; // 供销差
                    rightData_2.dtaa_3=response?.data?.data?.mark; // 总销售
                    rightData_3.xData=response?.data?.data?.details?.时间;
                    rightData_3.data_1=response?.data?.data?.details?.差量柱图;
                    rightData_3.data_2=response?.data?.data?.details?.差率曲线;
                    if(rightData_3?.xData){ this.elementById(); }

                    this.setState({rightData_1, rightData_2, rightData_3})
                }
            }
        )
    };
    // 检定信息
    getVerificationInfo=(areaIds)=>{
        getVerificationInfo(areaIds).then(
            response => {
                console.log('检定信息');
                if(response?.data?.data){
                    var {rightData_4, rightData_5} = this.state;
                    rightData_4.complete_1=response.data.data?.todayCompletionRate;// 今日完成率
                    rightData_4.complete_2=response.data.data?.monthCompletionRate;     // 本月完成率
                    rightData_4.complete_3=response.data.data?.yearCompletionRate;      // 今年完成率
                    rightData_5.already_1=response.data.data?.todayVerificationNumber;  // 今日检定率
                    rightData_5.already_2=response.data.data?.monthVerificationNumber;  // 本月检定率
                    rightData_5.already_3=response.data.data?.yearVerificationNumber;   // 今年检定率
                    rightData_5.need_1=response.data.data?.todayQuantityTobeVerified;   // 今日须检定率
                    rightData_5.need_2=response.data.data?.monthQuantityTobeVerified;   // 本月须检定率
                    rightData_5.need_3=response.data.data?.yearQuantityTobeVerified;    // 今年须检定率
                    this.setState({rightData_4, rightData_5})
                }
            }
        )
    };



    elementById=()=>{
        var chartDom = echarts.init(document.getElementById('leftTopData_1_1'), "macarons");
        chartDom.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            // 边距
            grid: {
                top:'6%',
                left: '0%',
                right: '-10%',
                bottom: '0%',
                containLabel: true
            },
            // legend: {
            //     data: ['Evaporation', 'Precipitation', 'Temperature'],
            //     textStyle:{
            //         fontSize:'12',
            //         color:"#fff"
            //     },
            // },
            xAxis: [
                {
                    type: 'category',
                    data: this.state.rightData_3?.xData,
                    axisLine:{
                        lineStyle:{
                            color:"#93a0a8"
                        }
                    },
                    // splitLine: {
                    //     lineStyle: {
                    //         // 设置背景横线
                    //         color: 'red'
                    //     }
                    // },
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                //左
                {
                    type: 'value',
                    // name: '左侧',
                    // min: this.state.rightData_3.yData_left?.min,
                    // max: this.state.rightData_3.yData_left?.max,
                    interval: this.state.rightData_3.yData_left?.interval,
                    axisLine:{
                        lineStyle:{
                            color:"#93a0a8"
                        }
                    },
                    axisLabel: {
                        formatter: '{value}',
                    }
                },
                //右
                {
                    type: 'value',
                    // name: '右侧',
                    // min: this.state.rightData_3.yData_right?.min,
                    // max: this.state.rightData_3.yData_right?.max,
                    interval: this.state.rightData_3.yData_right?.interval,
                    axisLine:{
                        lineStyle:{
                            color:"#93a0a8"
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            // 设置背景横线
                            color: '#8fa4ac',

                        }
                    },
                    axisLabel: {
                        formatter: '{value} %'
                    }
                }
            ],
            series: [
                {
                    name: '汽损量',
                    type: 'bar',
                    tooltip: {
                        valueFormatter: function (value) {
                            return value ;
                        }
                    },
                    data: this.state.rightData_3.data_1
                },
                {
                    name: '汽损率',
                    type: 'line',
                    yAxisIndex: 1,
                    tooltip: {
                        valueFormatter: function (value) {
                            return value + ' %';
                        }
                    },
                    data: this.state.rightData_3.data_2
                }
            ]
        })
    };

    // 今日图
    getImgToday=()=>{
        var complete = this.state.rightData_4.complete_1;
        return complete<=25?'img_today_25': complete<=50 & complete>=25 ? 'img_today_50':
                complete<=75 & complete>=50 ? 'img_today_75':'img_today_100'
    };
    // 本月图
    getImgMonth=()=>{
        var complete = this.state.rightData_4.complete_2;
        return complete<=25?'img_month_25': complete<=50 & complete>=25 ? 'img_month_50':
                complete<=75 & complete>=50 ? 'img_month_75':'img_month_100'
    };
    // 今年图
    getImgYear=()=>{
        var complete = this.state.rightData_4.complete_3;
        return complete<=25?'img_year_25': complete<=50 & complete>=25 ? 'img_year_50':
            complete<=75 & complete>=50 ? 'img_year_75':'img_year_100'
    };
    render() {
        return (
            <div style={{ 'width':' 100%', 'height': '100%'}} id={'rightType'}>
                <div className={'body_right_top_div'}>
                    {/*标题*/}
                    <div style={{ 'width':' 100%', 'height': '8%'}}>
                        <span className={'body_left_top_div_spen_head'}>供销数据</span>
                    </div>
                    {/*内容*/}
                    <div style={{'width':' 100%', 'height': '40%',background: 'linear-gradient(170deg, #012439,#003345 65px,#012439)',borderRadius: '5px',padding:'5%'}}>
                        <div style={{'width':' 100%', 'height': '40%'}}>
                            {/*购销差*/}
                            <div style={{'width':' 50%', 'height': '100%',float:'left'}}>
                                <div className={'right_img_1'}/>
                                <div style={{ 'width':' 40%', 'height': '100%',float:'left',margin:'6% 0 0 2%'}}>
                                    <div style={{color:'#67c4d3',fontSize:'15px',fontWeight: 'bold'}}><span>
                                        {
                                            parseFloat(this.state.rightData_1.dtaa_1).toFixed(2)
                                        }
                                    </span><span>%</span></div>
                                    <div style={{color:'#fff',fontSize:'8px'}}><span>购销差</span></div>
                                </div>
                            </div>
                            {/*供销差m³*/}
                            <div style={{'width':' 50%', 'height': '100%',float:'left'}}>
                                <div className={'right_img_2'}/>
                                <div style={{ 'width':' 40%', 'height': '100%',float:'left',margin:'6% 0 0 2%'}}>
                                    <div style={{color:'#67c4d3',fontSize:'15px',fontWeight: 'bold'}}><span>
                                        {
                                            parseFloat(this.state.rightData_1.dtaa_2).toFixed(2)
                                        }
                                    </span><span>%</span></div>
                                    <div style={{color:'#fff',fontSize:'8px'}}><span>供销差</span></div>
                                </div>
                            </div>
                        </div>
                        <div  style={{'width':' 100%', 'height': '60%',paddingTop:'5%'}}>
                            <div className={'right_img_3'} style={{paddingTop:'3%'}}>
                                <div style={{  textAlign: 'center',color:'#fff',fontWeight: 'bold',fontSize:'15px'}}>
                                    <span>
                                        {
                                            parseFloat(this.state.rightData_2.dtaa_1).toFixed(2)
                                        }
                                    </span><span>万吨</span>
                                </div>
                                <div style={{  textAlign: 'center',color:'#fff',fontSize:'12px'}}>
                                    <span>总采购</span>
                                </div>
                            </div>
                            <div className={'right_img_3'} style={{paddingTop:'3%'}}>
                                <div style={{  textAlign: 'center',color:'#fff',fontWeight: 'bold',fontSize:'15px'}}>
                                    <span>
                                        {
                                        parseFloat(this.state.rightData_2.dtaa_2).toFixed(2)
                                        }
                                    </span><span>万吨</span>
                                </div>
                                <div style={{  textAlign: 'center',color:'#fff',fontSize:'12px'}}>
                                    <span>总供给</span>
                                </div>
                            </div>
                            <div className={'right_img_3'} style={{paddingTop:'3%'}}>
                                <div style={{  textAlign: 'center',color:'#fff',fontWeight: 'bold',fontSize:'15px'}}>
                                    <span>{this.state.rightData_2.dtaa_3}</span><span>万m吨</span>
                                </div>
                                <div style={{  textAlign: 'center',color:'#fff',fontSize:'12px'}}>
                                    <span>总销售</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*图*/}
                    <div style={{'width':' 100%', 'height': '50%',padding:"5% 0% 0% 0%"}}>
                        <div style={{ width: '40%',height: '12%', background: 'linear-gradient(100deg, #005970, #001e30 70%)', padding:'0 0 0 5px'}}>
                            <span style={{color:'#8fa4ac',fontSize:'8px',  fontWeight: 'initial'}}>●差率曲线/差量柱状</span>
                        </div>
                        <div style={{ width: '100%',height: '90%'}}>

                            <div id={'leftTopData_1_1'} style={{ width: '100%', height: '100%' }}/>
                        </div>

                    </div>
                </div>

                <div className={'body_right_bottom_div'}>
                    <div style={{ 'width':' 100%', 'height': '100%'}}>
                        {/*标题*/}
                        <div style={{ 'width':' 100%', 'height': '20%'}}>
                            <span className={'body_left_bottom_div_spen_head'}>检定信息</span>
                        </div>
                        {/*内容*/}
                        <div style={{  width: '100%',height: '80%'}}>
                            {/*上*/}
                            <div style={{  width: '100%',height: '42%'}}>
                                <div  style={{  width: '33.3%',height: '80%',float: 'left',paddingLeft:'3%'}}>
                                    <div className={this.getImgToday()} style={{padding:'40% 22% 0 0', color:'#fff', fontSize:'18px',}}>
                                        <span>{this.state.rightData_4.complete_1}</span>
                                        {/*<span>%</span>*/}
                                    </div>
                                    <span style={{color:'#fff',paddingLeft:'5%'}}>今日完成率</span>
                                </div>
                                <div  style={{  width: '33.3%',height: '80%',float: 'left',paddingLeft:'3%'}}>
                                    <div className={this.getImgMonth()} style={{padding:'40% 22% 0 0',color:'#fff',fontSize:'18px'}}>
                                        <span>{this.state.rightData_4.complete_2}</span>
                                        {/*<span>%</span>*/}
                                    </div>
                                    <span style={{color:'#fff',paddingLeft:'5%'}}>本月完成率</span>
                                </div>
                                <div  style={{  width: '33.3%',height: '80%',float: 'left',paddingLeft:'3%'}}>
                                    <div className={this.getImgYear()} style={{padding:'40% 22% 0 0',color:'#fff',fontSize:'18px'}}>
                                        <span>{this.state.rightData_4.complete_3}</span>
                                        {/*<span>%</span>*/}
                                    </div>
                                    <span style={{color:'#fff',paddingLeft:'5%'}}>今年完成率</span>
                                </div>
                            </div>
                            {/*下*/}
                            <div style={{  width: '100%',height: '58%'}}>
                                <div style={{  width: '100%',height: '50%',marginTop:'2%',}}>
                                    <div style={{  width: '33.3%',height: '100%',float: 'left'}}>
                                        <div className={'right_img_bottom_4'}>
                                            <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                                <span>{this.state.rightData_5.already_1}</span>
                                            </div>
                                            <div style={{color: '#fff',fontSize:'12px'}}>
                                                <span>今日检定数</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{  width: '33.3%',height: '100%',float: 'left'}}>
                                        <div className={'right_img_bottom_4'}>
                                            <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                                <span>{this.state.rightData_5.already_2}</span>
                                            </div>
                                            <div style={{color: '#fff',fontSize:'12px'}}>
                                                <span>本月检定数</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{  width: '33.3%',height: '100%',float: 'left'}}>
                                        <div className={'right_img_bottom_4'}>
                                            <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                                <span>{this.state.rightData_5.already_3}</span>
                                            </div>
                                            <div style={{color: '#fff',fontSize:'12px'}}>
                                                <span>今年检定数</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{  width: '100%',height: '50%'}}>
                                    <div style={{  width: '33.3%',height: '100%',float: 'left'}}>
                                        <div className={'right_img_bottom_4'}>
                                            <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                                <span>{this.state.rightData_5.need_1}</span>
                                            </div>
                                            <div style={{color: '#fff',fontSize:'12px'}}>
                                                <span>今日须检定数</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{  width: '33.3%',height: '100%',float: 'left'}}>
                                        <div className={'right_img_bottom_4'}>
                                            <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                                <span>{this.state.rightData_5.need_2}</span>
                                            </div>
                                            <div style={{color: '#fff',fontSize:'12px'}}>
                                                <span>本月须检定数</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{  width: '33.3%',height: '100%',float: 'left'}}>
                                        <div className={'right_img_bottom_4'}>
                                            <div style={{color: '#00b4e0',fontSize:'18px',height:'24px'}}>
                                                <span>{this.state.rightData_5.need_3}</span>
                                            </div>
                                            <div style={{color: '#fff',fontSize:'12px'}}>
                                                <span>今年须检定数</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default rightType;
