import React, { Component } from 'react'
import {Layout,Divider,Timeline,Image,Table} from "antd";

import { ArrowLeftOutlined } from '@ant-design/icons';
import './deviceDetails.less';
import TableComponents from "../../../../components/common/tables/TableComponentsNew";

import {partBgetOrdersByCon} from "../../../../services/orderForm";
import {getDeviceDetailByDevId,listDeviceRealTimeDataByLocations} from "../../../../services/qrCode";
import Tables from "../../../../components/common/tables/Tables";
// 设备实时数据详情
export default class deviceRealDetails extends Component {
    state= {
        // 设备实时详情展示版-数据
        formText_1_data: {},
        // 设备业务数据-数据
        TimelineData:[
            // {
            //     "operator":"陈善彪",
            //     "tel":null,
            //     "photos":[
            //         {
            //             "devId":0,
            //             "photoKey":"dev",
            //             "devType":null,
            //             "photoPath":"https://ikit2.service.hdstcloud.com:9027/2023-02-23/magazine-unlock-03-2.3.2023022202-_F35E3558C4F34DE54B48B6678E4D91F2-USER-UNKNOWN-TYPE-UNKNOWN-2023-02-23-11-04-41-741.jpg"
            //         }
            //         ],
            //     "operationTime":"2023-02-23T11:06:21",
            //     "remark":"我",
            //     "type":"维护"
            // },
            ],
        // 设备附属设施-表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                orderFormNo: '订单编号',
                createTime: '创建时间',
                completionTime: '完成时间',
                supplierCompany:'供应厂家',
                theTotal: '台数',
                state: '订单状态',
                edit: '操作',
            },
            // 弹出框
            headers: {
                orderFormNo: '订单编号',
                createTime: '创建时间',
                completionTime: '完成时间',
                supplierCompany:'供应厂家',
                theTotal: '台数',
                state: '订单状态'
            },
            params: {
                page:1,
                size:10,
                // supplierId:user?.orgId,
                supplierCompany:JSON.parse(sessionStorage.getItem("user")).orgName
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        leftTableColumns_value:[
            {title: '订单编号', dataIndex: 'orderFormNo', key: 'orderFormNo',},
            {title: '创建时间', dataIndex: 'createTime', key: 'createTime',},
            {title: '完成时间', dataIndex: 'completionTime', key: 'completionTime',},
            {title: '供应厂家', dataIndex: 'supplierCompany', key: 'supplierCompany',},
            {title: '台数', dataIndex: 'theTotal', key: 'theTotal',},
            {title: '订单状态', dataIndex: 'state', key: 'state',},
        ],
        leftTableColumns_data:[]
    };


    // 初始化
    componentDidMount() {
        console.log("初始化");
        console.log(this.props?.location?.state.rowData);
        if(this.props?.location?.state?.rowData?.id){
            getDeviceDetailByDevId({id:this.props?.location?.state.rowData.id,type:10168}).then(
                response=>{
                    if(response?.data?.data){
                        var {TimelineData} = this.state;
                        TimelineData=response?.data?.data;
                        this.setState({TimelineData})
                    }
                }
            )
        }



    };

    getTimelineData=(data)=>{
      console.log(data)

    };


    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
                <div id={'deviceDetails'}>
                    {/*处理返回上一页*/}
                    <div>
                        {/*返回上层*/}
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>设备详情</span>
                        </div>
                        {/*返回上层END-----*/}
                    </div>
                    {/*处理返回上一页END-----*/}
                    {/*业务*/}
                    <div  style={{"backgroundColor":"#fff","height": "810px"}}>
                        {/*上层数据*/}
                        <div>
                            {/*实时数据展板*/}
                            <div className={'formText_1'}>

                                <div className={'formText_1_data'}>
                                    <div style={{"fontSize": "18px","fontWeight": "bold"}}>
                                        <span style={{"width": "60%","display": "inline-block"}}>设备信息</span><span>安装点信息</span>
                                    </div>
                                    <div className={'formText_1_installInner'}>
                                        <ul>
                                            <li>设备型号：<span>{this.props?.location?.state?.rowData?.devModelName?this.props?.location?.state?.rowData?.devModelName:'-'}</span></li>
                                            <li>设备业务状态：<span>-</span></li>
                                            <li>安装点名称：<span>{this.props?.location?.state?.rowData?.locationName?this.props?.location?.state?.rowData?.locationName:'-'}</span></li>

                                            <li>供应商：<span>{this.props?.location?.state?.rowData?.manufacture?this.props?.location?.state?.rowData?.manufacture:'-'}</span></li>
                                            <li>可燃气体浓度：<span>-</span></li>
                                            <li>安装点状态：<span>{this.props?.location?.state?.rowData?.address.state?this.props?.location?.state?.rowData?.address.state:'-'}</span></li>

                                            <li>设备编号：<span>{this.props?.location?.state?.rowData?.hdstno?this.props?.location?.state?.rowData?.hdstno:'-'}</span></li>
                                            <li>报警状态：<span>{this.props?.location?.state?.rowData?.status?this.props?.location?.state?.rowData?.status:'-'}</span></li>
                                            <li style={{width: "40%"}}>安装点地址：<span>{this.props?.location?.state?.rowData?.address.address?this.props?.location?.state?.rowData?.address.address:'-'}</span></li>

                                            <li>控制器编号：<span>{this.props?.location?.state?.rowData?.parentNo?this.props?.location?.state?.rowData?.parentNo:'-'}</span></li>
                                            <li>采集时间：<span>{this.props?.location?.state?.rowData?.dataTime?this.props?.location?.state?.rowData?.dataTime:'-'}</span></li>
                                            <li style={{width: "40%"}}>安装位置：<span>{this.props?.location?.state?.rowData?.location?this.props?.location?.state?.rowData?.location:'-'}</span></li>
                                            <li>控制器通信号：<span>{this.props?.location?.state?.rowData?.parentComNo?this.props?.location?.state?.rowData?.parentComNo:'-'}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*实时数据展板END-----*/}
                        </div>{/*上层数据END-----*/}
                        <div className={"formText_1_data_divider"}/>{/*----------第一条分割线-------------*/}
                        {/*下层数据*/}
                        <div>
                            {/*左*/}
                            <div className={"left_class"}>
                                <div className={"left_class_top"}>
                                    <div>
                                        <span style={{"fontSize": "18px","fontWeight": "bold", "marginLeft": "20px"}}>设备附属信息</span>
                                    </div>
                                    <div>

                                    </div>
                                </div>
                                {/*<div className={"formText_1_data_divider"}/>/!*----------第二条分割线-------------*!/*/}
                                {/*<div className={"left_class_bottom"}>*/}
                                {/*    <div>*/}
                                {/*        <span style={{"fontSize": "18px","fontWeight": "bold", "marginLeft": "20px"}}>设备附属设施</span>*/}
                                {/*    </div>*/}
                                {/*    <div className={"left_class_table"}>*/}
                                {/*        /!*<TableComponents*!/*/}
                                {/*        /!*    paramenter={this.state.tableParamenter}*!/*/}
                                {/*        /!*    setData = {partBgetOrdersByCon}*!/*/}
                                {/*        /!*    setTableValue={this.setTableValue}*!/*/}
                                {/*        /!*    ref={e => this.table = e}*!/*/}
                                {/*        /!*>*!/*/}
                                {/*        /!*</TableComponents>*!/*/}
                                {/*        <Table*/}
                                {/*            columns={this.state.leftTableColumns_value}*/}
                                {/*            dataSource={this.state.leftTableColumns_data}*/}
                                {/*        >*/}
                                {/*        </Table>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            {/*左END-----*/}
                            {/*右*/}
                            <div className={"right_class"}>
                                <div className={"right_class_divider"}/>{/*----------第三条分割线-------------*/}
                                <div className={"right_class_Timeline"}>
                                    <span style={{"fontSize": "18px","fontWeight": "bold","margin":"10px",}}>设备业务记录</span>
                                    <div style={{"margin":"10px",'whiteSpace':'nowrap','overflow-y':'auto','height':'540px'}}>
                                        <Timeline>
                                            {
                                                this.state.TimelineData.length!==0?
                                                    this.state?.TimelineData?.map(
                                                        one=>{
                                                            return(
                                                                <div>
                                                                    <div style={{'padding':'0 0 5px 2px'}}>
                                                                        <span>{one?.operationTime}</span>
                                                                    </div>
                                                                    <div>
                                                                        <Timeline.Item>
                                                                            <div style={{'width':'80%','height':'100px','backgroundColor':'rgb(241 241 241)'}}>
                                                                                {/*图片*/}
                                                                                <div style={{'width':'15.5%','height':'100px','float':'left'}}>
                                                                                    {
                                                                                        one?.photos?
                                                                                            <Image
                                                                                                width={100}
                                                                                                height={100}
                                                                                                src={one?.photos[0]?.photoPath}
                                                                                            />
                                                                                            :null
                                                                                    }
                                                                                </div>
                                                                                {/*图片END*/}
                                                                                {/*数据*/}
                                                                                <div style={{'width':'84%','height':'100px','float':'left'}}>
                                                                                    {/*左*/}
                                                                                    <div style={{'width':'60%','height':'100px','float':'left'}}>
                                                                                        <div style={{'padding':'2px 0 0 8px'}}>
                                                                                            <div>
                                                                                                <u style={{'fontSize':'18px', 'fontWeight': 'bolder'}}>{one.type}</u>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span style={{'fontSize':'14px','fontWeight': 'bolder','color':'rgb(179 180 187)'}}>{one.remark}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*左END*/}
                                                                                    {/*右*/}
                                                                                    <div style={{'width':'40%','height':'100px','float':'left'}}>
                                                                                        <div style={{'float':'left','paddingTop':'50px','fontSize':'14px','fontWeight': 'bolder'}}>
                                                                                            <div>
                                                                                                <span>{one.type}人：</span>
                                                                                                <span>{one.operator}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span>{one.type}联系电话：</span>
                                                                                                <span>{one.tel}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/*右END*/}
                                                                                </div>
                                                                                {/*数据END*/}
                                                                            </div>
                                                                        </Timeline.Item>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )
                                                    :''
                                            }
                                        </Timeline>
                                    </div>
                                </div>
                            </div>
                            {/*右END-----*/}
                        </div>
                        {/*下层数据END-----*/}
                    </div>
                    {/*业务END-----*/}

                </div>
            </Layout>
        )
    }
}
