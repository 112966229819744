import React from 'react';
import {queryDevWorkingState} from '../../../../services/qrCode'
import * as echarts from 'echarts';
import {Button, message} from "antd";

// 带背景色的柱状图
 class  BarwithBackgroundOne extends React.Component {
    state={
        rightDetector:[],

    };
    componentDidMount() {
        this.elementById();

    }
     componentDidUpdate(num){
         this.elementById();
     }
    elementById= async =>{

        // var chartDom = document.getElementById('main');
        // var myChart = echarts.init(chartDom);
        // var option;

        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        chartDom.clear();
        chartDom.setOption(
            {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return tar?.name + '<br/>' + '数量:'+ ' : ' + tar.value;
                    }
                },
                legend: {

                    data: ['今年新增掉线数','今日故障数','今日掉线数','今日在线数','今日恢复数','总数'],
                    align:'left',
                    right:4,
                    textStyle:{
                        fontSize:'12',
                        color:"#fff"
                    },

                },
                xAxis: {
                    min:this.props.axis.min,     // 最小值
                    max:this.props.axis.max,    // 最大值
                    interval:this.props.axis.interval,// 数值间隔
                    type: 'value',
                    // axisLabel:false,
                    show: false,
                    axisLine:{
                        lineStyle:{
                            color:"#909090"
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    data: ['今日新增掉线数','今日故障数','今日掉线数','今日在线数','今日恢复数','总数'],
                    show: false,
                    axisLine:{
                        lineStyle:{
                            color:"#fff"
                        }
                    }
                },
                // 边距
                grid: {
                    top:'18%',
                    left: '-23%',
                    right: '-30%',
                    bottom: '20%',
                    containLabel: true
                },
                series: [

                    {
                        name:'今日新增掉线数', stack: 'Total', type:'bar', barWidth: 30,
                        data:[this.props.rightDetector?.todayAddOfflineNum],
                        label: {
                            show: true, position: 'insideLeft', offset: [0, 1],
                            textStyle: {
                                //数值样式，显示的文字大小和颜色
                                fontSize: '12',
                                color: '#fff',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                            formatter:function (params) {
                                if(params.value === 0){
                                    return''
                                }else{
                                    return params.value
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: '#e9433f'
                            }
                        },
                    },
                    {
                        name:'今日故障数', stack: 'Total', type:'bar', barWidth: 30,
                        data:[0,this.props.rightDetector?.todayFaultRate],
                        label: {
                            show: true, position: 'insideLeft', offset: [0, 1],
                            textStyle: {
                                //数值样式，显示的文字大小和颜色
                                fontSize: '12',
                                color: '#fff',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                            formatter:function (params) {
                                if(params.value === 0){
                                    return''
                                }else{
                                    return params.value
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: '#e9433f'
                            }
                        },
                    },
                    {
                        name:'今日掉线数',
                        type:'bar',
                        barWidth: 30,
                        stack: 'Total',
                        data:[0,0,this.props.rightDetector?.todayOfflineNum],
                        label: {
                            show: true, position: 'insideLeft', offset: [0, 1],
                            textStyle: {
                                //数值样式，显示的文字大小和颜色
                                fontSize: '12',
                                color: '#fff',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                            formatter:function (params) {
                                if(params.value === 0){
                                    return''
                                }else{
                                    return params.value
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: '#cac401'
                            }
                        },
                    },
                    {
                        name:'今日在线数',
                        type:'bar',
                        barWidth: 30,
                        stack: 'Total',

                        data:[0, 0,0,this.props.rightDetector?.todayOnLine],
                        label: {
                            show: true, position: 'insideLeft', offset: [0, 1],
                            textStyle: {
                                //数值样式，显示的文字大小和颜色
                                fontSize: '12',
                                color: '#fff',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                            formatter:function (params) {
                                if(params.value === 0){
                                    return''
                                }else{
                                    return params.value
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: '#00a0e9'
                            }
                        },
                    },
                    {
                        name:'今日恢复数',
                        type:'bar',
                        barWidth: 30,
                        stack: 'Total',
                        data:[0, 0, 0,0,this.props.rightDetector?.todayRecoveryNum],
                        label: {
                            show: true, position: 'insideLeft', offset: [0, 1],
                            textStyle: {
                                //数值样式，显示的文字大小和颜色
                                fontSize: '12',
                                color: '#fff',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                            formatter:function (params) {
                                if(params.value === 0){
                                    return''
                                }else{
                                    return params.value
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: '#04e0f9'
                            }
                        },
                    },
                    {
                        name:'总数',
                        type:'bar',
                        barWidth: 30,
                        stack: 'Total',
                        showBackground: true,
                        data:[0, 0, 0, 0,0,this.props.rightDetector?.num],
                        label: {
                            show:true,
                            position: 'insideLeft',
                            offset: [0, 1],
                            textStyle: {
                                fontSize: '12',
                                color: '#fff',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                            formatter:function (params) {
                                if(params.value === 0){
                                    return''
                                }else{
                                    return params.value
                                }
                            }
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: '#728cda'
                            }
                        },
                    }
                ]
            },true)
    };

    render() {
        console.log(this.props.rightDetector);
        return (
            <div id={this.props.elementById} style={{ width: '90%', height: '120%'}}/>
        );
    }
}

export default BarwithBackgroundOne;
