import ajax from './ajax'


//添加首页配置
export const addHome = (data) => ajax('/newAuth/home/addHome', (data), 'POST');
//修改首页配置
export const updateHome = (data) => ajax('/newAuth/home/updateHome', (data), 'POST');
//根据Id删除首页配置
export const removeHomeById = (data) => ajax('/newAuth/home/removeHomeById', (data), 'GET');

//查询所有的首页配置
export const getAllHome = (data) => ajax('/newAuth/home/getAllHome', (data), 'GET');

//对组织机构配置首页信息
export const addHomeByOrgId = (data) => ajax('/newAuth/home/addHomeByOrgId', (data), 'POST');

//根据组织机构ID查询首页配置
export const getHomeInfoByOrgId = (data) => ajax('/newAuth/home/getHomeInfoByOrgId', (data), 'POST');
