import React, { Component } from 'react'
import {Layout} from "antd";
import './bottomOne.less'

export default class bottomTwo extends Component {
    state = {};


    render() {
        return (
            <div id={'bottomOne'}>
                <div style={{ width: '100%',height: '20%', padding: '20px 0 0 50px'}}>
                    <span style={{ fontSize: '22px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>管网监控</span>
                </div>
                {/*1*/}
                <div style={{ width: '100%',height: '45%',  fontFamily: '微軟正黑體'}}>
                    {/*1-1*/}
                    <div style={{ width: '100%',height: '30%'}}>
                        {/*1-1-1*/}
                        <div style={{ width: '20%',height: '85%',marginLeft:'6%',float:'left'}}>
                            <div style={{paddingTop:'10%',width: '35%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_1_img_1'}/>
                            </div>
                            <div style={{width:'60%',height:'100%',float:'left'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'18px',color:'#FF7349',}}>3个</span>
                                </div>
                                <div style={{width:'100%',height:'50%',marginTop:'2px'}}>
                                    <span style={{fontSize:'10px',color:'#DCEFFF',width:'100%'}}>浓度异常</span>
                                </div>
                            </div>
                        </div>
                        {/*1-1-2*/}
                        <div style={{ width: '20%',height: '85%',marginLeft:'4%',float:'left'}}>
                            <div style={{paddingTop:'10%',width: '35%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_1_img_2'}/>
                            </div>
                            <div style={{width:'60%',height:'100%',float:'left'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'18px',color:'#FF7349',}}>4个</span>
                                </div>
                                <div style={{width:'100%',height:'50%',marginTop:'2px'}}>
                                    <span style={{fontSize:'10px',color:'#DCEFFF',width:'100%'}}>压力异常</span>
                                </div>
                            </div>
                        </div>
                        {/*1-1-3*/}
                        <div style={{ width: '20%',height: '85%',marginLeft:'3%',float:'left'}}>
                            <div style={{paddingTop:'10%',width: '35%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_1_img_3'}/>
                            </div>
                            <div style={{width:'60%',height:'100%',float:'left'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'18px',color:'#FF7349',}}>6个</span>
                                </div>
                                <div style={{width:'100%',height:'50%',marginTop:'2px'}}>
                                    <span style={{fontSize:'10px',color:'#DCEFFF',width:'100%'}}>流量异常</span>
                                </div>
                            </div>
                        </div>
                        {/*1-1-4*/}
                        <div style={{ width: '20%',height: '85%',marginLeft:'3%',float:'left'}}>
                            <div style={{paddingTop:'10%',width: '35%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_1_img_4'}/>
                            </div>
                            <div style={{width:'60%',height:'100%',float:'left'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'18px',color:'#FF7349',}}>2个</span>
                                </div>
                                <div style={{width:'100%',height:'50%',marginTop:'2px'}}>
                                    <span style={{fontSize:'10px',color:'#DCEFFF',width:'100%'}}>流量异常</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*1-2*/}
                    <div style={{width:'100%',height:'35%',paddingLeft:'5%',paddingTop:'2%'}}>
                        {/*1-2-1*/}
                        <div style={{ width: '20%',height: '95%',float:'left'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_2_img_1'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>6</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>场站</span>
                                </div>
                            </div>
                        </div>
                        {/*1-2-2*/}
                        <div style={{ width: '20%',height: '95%',float:'left',marginLeft:'5%'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_2_img_2'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>12</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>充装站</span>
                                </div>
                            </div>
                        </div>
                        {/*1-2-3*/}
                        <div style={{ width: '20%',height: '95%',float:'left',marginLeft:'5%'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_2_img_3'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>145</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>阀井</span>
                                </div>
                            </div>
                        </div>
                        {/*1-2-4*/}
                        <div style={{ width: '20%',height: '95%',float:'left',marginLeft:'4%'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_2_img_4'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>8</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>调压柜</span>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/*1-3*/}
                    <div style={{width:'100%',height:'35%',paddingLeft:'5%',paddingTop:'2%'}}>
                        {/*1-3-1*/}
                        <div style={{ width: '20%',height: '95%',float:'left'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_3_img_1'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>32</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>窨井</span>
                                </div>
                            </div>
                        </div>
                        {/*1-3-2*/}
                        <div style={{ width: '20%',height: '95%',float:'left',marginLeft:'5%'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_3_img_2'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>4</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>门站</span>
                                </div>
                            </div>
                        </div>
                        {/*1-3-3*/}
                        <div style={{ width: '20%',height: '95%',float:'left',marginLeft:'5%'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_3_img_3'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>1785</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>气瓶</span>
                                </div>
                            </div>
                        </div>
                        {/*1-3-4*/}
                        <div style={{ width: '20%',height: '95%',float:'left',marginLeft:'4%'}}>
                            <div style={{width: '45%',height: '100%',float:'left'}}>
                                <div className={'bottomOne_data_div_3_img_4'}/>
                            </div>
                            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>
                                <div style={{width:'100%',height:'50%',marginTop:'-3px'}}>
                                    <span style={{fontSize:'16px',color:'#BCE8FF',}}>1573</span>
                                </div>
                                <div style={{width:'100%',height:'50%'}}>
                                    <span style={{fontSize:'12px',color:'#B0DCFF',width:'100%'}}>管盯</span>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                {/*<div style={{ width: '100%',height: '45%'}}>*/}
                {/*    /!*1-1*!/*/}
                {/*    <div style={{width:'100%',height:'32%',paddingLeft:'6%'}}>*/}
                {/*        /!*1-1-1*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'35%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_1_img_1'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'65%',height:'100%',float:'left'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#FF7349',}}>3个</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#DCEFFF',width:'100%'}}>浓度异常</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-1-2*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'35%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_1_img_2'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'65%',height:'100%',float:'left'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#FF7349',}}>3个</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#DCEFFF', width:'100%'}}>浓度异常</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-1-3*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'35%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_1_img_3'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'65%',height:'100%',float:'left'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#FF7349', }}>3个</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#DCEFFF', width:'100%'}}>浓度异常</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-1-4*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'35%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_1_img_4'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'65%',height:'100%',float:'left'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#FF7349',}}>3个</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#DCEFFF',width:'100%'}}>浓度异常</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    /!*1-2*!/*/}
                {/*    <div style={{width:'100%',height:'30%',paddingLeft:'5%'}}>*/}
                {/*        /!*1-1-1*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_2_img_1'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>12</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>场站</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-1-2*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_2_img_2'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>245</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>充装站</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-1-3*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_2_img_3'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>145</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>阀井</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-1-4*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_2_img_4'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>8</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>调压柜</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    /!*1-3*!/*/}
                {/*    <div style={{width:'100%',height:'32%',paddingLeft:'5%',marginTop:'2%'}}>*/}
                {/*        /!*1-3-1*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_3_img_1'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>12</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>窨井</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-3-2*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_3_img_2'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>245</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>门站</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-3-3*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_3_img_3'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>145</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'14px',color:'#B0DCFF',width:'100%'}}>气瓶</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*1-3-4*!/*/}
                {/*        <div style={{width:'24%',height:'100%',float:'left'}}>*/}
                {/*            <div style={{width:'45%',height:'100%',float:'left',paddingTop:'5%'}}>*/}
                {/*                <div className={'bottomOne_data_div_3_img_4'}/>*/}
                {/*            </div>*/}
                {/*            <div style={{width:'55%',height:'100%',float:'left',paddingLeft:'1%'}}>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{fontSize:'18px',color:'#BCE8FF',}}>8</span>*/}
                {/*                </div>*/}
                {/*                <div style={{width:'100%',height:'50%'}}>*/}
                {/*                    <span style={{    fontWeight: 'inherit',fontSize:'12px',color:'#B0DCFF',width:'100%',fontFamily: '幼圆'}}>管盯</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*2*/}
                <div style={{ width: '100%',height: '18%',paddingLeft:'6%',marginTop:'2%'}}>
                    <div style={{width:'90%',height:'40%',backgroundColor:'#05254c'}}>
                        <div style={{width:'12%',textAlign: 'center',float:"left"}}><span style={{color:'#FFCD8E'}}>类别</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>浓度</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>压力</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>流量</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>温度</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>高压</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>低压</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>阀井</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>管盯</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>窨井</span></div>
                    </div>
                    <div style={{width:'90%',height:'40%',backgroundColor:'#062b54'}}>
                        <div style={{width:'12%',textAlign: 'center',float:"left"}}><span style={{color:'#FFCD8E'}}>异常数</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>0</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>2</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>6</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>7</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>5</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>1</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>3</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>3</span></div>
                        <div style={{width:'9.5%',textAlign: 'center',float:"left"}}><span style={{color:'#b3bdc9'}}>2</span></div>
                    </div>
                </div>
                {/*3*/}
                <div style={{ width: '100%',height: '10%',paddingLeft:'32%'}}>
                    <div className={'bottomOne_data_div_button_2'}>
                        <span style={{color:'#b3bdc9'}}>岳麓</span>
                    </div>
                    <div className={'bottomOne_data_div_button_1'}>
                        <span style={{color:'#b3bdc9'}}>雨花</span>
                    </div>
                    <div className={'bottomOne_data_div_button_1'}>
                        <span style={{color:'#b3bdc9'}}>天心</span>
                    </div>
                    <div className={'bottomOne_data_div_button_1'}>
                        <span style={{color:'#b3bdc9'}}>开福</span>
                    </div>
                    <div className={'bottomOne_data_div_button_1'}>
                        <span style={{color:'#b3bdc9'}}>浏阳</span>
                    </div>
                </div>
            </div>
        )
    }
}
