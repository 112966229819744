import React, { Component } from 'react'
import {Button, Layout, message, Modal, Popconfirm, Table, Tag} from 'antd'
import {TYPE_NAME} from "../../../../components/Const";
import TableComponents from "../../../../components/common/tables/TableComponents";
import {
    configLevel, customerUnbind,
    getCustomerByChild,
    getCustomerByParent,
    newGetPageCustomerName
} from "../../../../services/customer";
import { LoadingOutlined,ReloadOutlined} from '@ant-design/icons'

export default class hierarchicaRelationship extends Component {
    state = {
// 子公司------------------------
        table_columns_1:[

            {title: '客户编号', dataIndex: 'id',   key: 'id',  align:'center'},
            {title: '客户名称', dataIndex: 'name', key: 'name',align:'center'},
            {title: '客户类型', dataIndex: 'type', key: 'type',align:'center',
                render: (text,rowData,index) => {return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';}
            },
            {title: '联系人', dataIndex: 'principal', key: 'principal',align:'center'},
            {title: '联系人电话', dataIndex: 'principalPhone', key: 'principalPhone',align:'center'},
            {title: '详细地址', dataIndex: 'detailAddress', key: 'detailAddress',align:'center'},
            // {title: '操作', dataIndex: 'edit', key: 'edit',width:'150px',}
        ],
        table_data_1:[],
        rowDataId_1:[],


// 母公司------------------------
        table_columns_2:[
            {title: '客户编号', dataIndex: 'id',   key: 'id',  align:'center'},
            {title: '客户名称', dataIndex: 'name', key: 'name',align:'center'},
            {title: '客户类型', dataIndex: 'type', key: 'type',align:'center',
                render: (text,rowData,index) => {
                    return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
                }
            },
            {title: '联系人', dataIndex: 'principal', key: 'principal',align:'center'},
            {title: '联系人电话', dataIndex: 'principalPhone', key: 'principalPhone',align:'center'},
            {title: '详细地址', dataIndex: 'detailAddress', key: 'detailAddress',align:'center'},
            // {title: '操作', dataIndex: 'edit', key: 'edit',width:'150px',}
        ],
        table_data_2:[],

        // 表格参数
        tableParamenter: {
            manageName: "客户关系管理系统",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            // localStorageName: 'hierarchicaCustomers',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '客户关系管理',
            header: {
                id: '客户编号',
                businessCooperationNum: '业务合作号',
                name: '客户名称',
                type: '客户类型',
                nature: '客户性质',
                // organizationCode: '组织代码',
                // legalPerson: '法人',
                // legalPersonPhone: '法人联系电话',
                principal: '联系人',
                principalPhone: '联系人电话',
                detailAddress: '详细地址',
                edit:'操作'
            },
            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'name',
                        str: '客户名称',
                        initData: JSON.parse(localStorage.getItem('customer'))?.name
                    },
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'businessCooperationNum',
                        str: '业务合作号',
                        initData: JSON.parse(localStorage.getItem('customer'))?.businessCooperationNum
                    },
                ]
            },
            params:{
                id:0,
                size:10,
                page:1
            }
        },

        refresh_1:false,
        refresh_2:false,
        customerTable:[],
        InCustomerTable:[],
        visible_1:false,// 客户列表弹窗开关
    };
// ===========================

    componentDidMount(): void {


        if(this.props?.rowData?.customerId){
            this.getCustomerByChild();
            this.getCustomerByParent();
        }


    }

// 根据子客户查询父客户
    getCustomerByChild=()=>{
        var customerId = this.props?.rowData?.customerId;
        this.setState({refresh_2:true});
        getCustomerByChild({customerId}).then(
            response=>{

                if(response?.data?.data){
                    console.log('根据子客户查询父客户');
                    console.log(response);
                    var InCustomerTable = this.state.InCustomerTable;
                    InCustomerTable.push(response?.data?.data);
                    console.log(InCustomerTable);
                    this.setState({table_data_2:[response?.data?.data],InCustomerTable:InCustomerTable})
                }
                this.setState({refresh_2:false})
            }
        )
    };
// 根据父客户查询子客户
    getCustomerByParent=()=>{
        var params={
            "id": this.props?.rowData?.customerId,
            "page": 1, "size": 999
        };
        this.setState({refresh_1:true});
        getCustomerByParent(params).then(
            response=>{
                if(response?.data?.data){
                    console.log('根据父客户查询子客户');
                    console.log(response);
                    var InCustomerTable = this.state.InCustomerTable;
                    response?.data?.data.map(one=>{
                        InCustomerTable.push(one);
                    });
                    this.setState({table_data_1:response?.data?.data,InCustomerTable:InCustomerTable})
                }
                this.setState({refresh_1:false})
            }
        )
    };
// 子表格单选框-------------
    onSelectChanges=(a,b,c)=>{this.setState({rowDataId_1:a});};

    addCustomer=(rowData)=>{
        console.log(this.props?.rowData?.customerId);
        if(this.props?.rowData?.customerId){
            if(this.props?.rowData?.customerId===rowData.id){
                return message.info('当前公司不能作为上下级');
            }
        }
        if(this.state.type==='2'){
            if(this.state.table_data_2.length>=1){
                return message.info('当前母公司只能有一家');
            }
        }

        var tableData = this.state.type==='1'?[...this.state.table_data_1]:[...this.state.table_data_2];


        tableData.push(rowData);
        var customerTable = tableData.concat(this.state.type==='1'?this.state.table_data_2:this.state.table_data_1);




        this.state.type==='1'?
        this.setState({table_data_1:tableData,customerTable:customerTable})
        :
        this.setState({table_data_2:tableData,customerTable:customerTable})
    };
    deleteCustomer=(rowData)=>{
        var tableData1 = [...this.state.table_data_1];
        var tableData2 = [...this.state.table_data_2];

        if(this.state.InCustomerTable.find(one=>{if(one.id===rowData?.id){return true}})){
            this.customerUnbind(rowData?.id);// 解绑
        }else{
            for(var i=0;tableData1.length>i;i++){if(tableData1[i]?.id===rowData?.id){tableData1.splice(i,1)}
            }
            for(var a=0;tableData2.length>a;a++){if(tableData2[a]?.id===rowData?.id){tableData2.splice(a,1)}}
            var customerTable = tableData1.concat(tableData2);
            this.setState({table_data_1:tableData1,table_data_2:tableData2,customerTable:customerTable})
        }



    };

// 客户列表表格样式---------
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'state':
                text = text*1;
                return text === 1 ? '正式' : text === 2 ? '非正式' : '';
            case 'edit':
                return (
                    <div>
                        <div style={{paddingLeft:'10px',float: 'left'}}>
                            {this.state.customerTable.find(one=>{
                                if(one.id===rowData.id)return true;
                            })?
                                <a style={{color:'red'}} onClick={()=>{
                                    this.deleteCustomer(rowData);
                                }}>取消客户</a>
                                :
                                <a onClick={()=>{
                                    this.addCustomer(rowData);
                                }}>添加客户</a>
                            }

                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    onSave=()=>{

        if(!this.props?.rowData?.customerId){
            return message.info('当前客户不可操作此功能，却确定是否已创建');
        }

        var params={
            "childrenIds": [],
            "cusId": this.props?.rowData?.customerId,
            "parentId": 0
        };
        this.state.table_data_1.map(one=>{params.childrenIds.push(one.id)});
        if( this.state.table_data_2.length!==0){
            params.parentId= this.state.table_data_2[0].id
        }
        console.log(params);
        // 客户分配权级
        configLevel(params).then(
            response=>{
                console.log('客户分配权级');
               if(response?.data?.data){
                   message.info('分配权级成功')
               }else{
                   message.info('分配权级失败')
               }
            }
        )
    };



    // 客户关系解绑
    customerUnbind=(id)=>{
        // 客户关系解绑
        customerUnbind({customerId:id}).then(
            response=>{
                console.log('客户关系解绑');
                if(response.data.data){
                    // 本地删除
                    message.info('当前子客户解绑成功');
                    var rowDataId = this.state.rowDataId_1;
                    var tableData1 = [...this.state.table_data_1];
                    var tableData2 = [...this.state.table_data_2];
                    for(var i=0;tableData1.length>i;i++){if(tableData1[i]?.id===rowDataId[0]){tableData1.splice(i,1);}}
                    for(var a=0;tableData2.length>a;i++){if(tableData2[a]?.id===rowDataId[0]){tableData2.splice(a,1);}}
                    this.setState({table_data_1:tableData1,table_data_2:tableData2});
                }else{
                    message.info('当前子客户解绑失败');
                }
            }
        )
    };
// ===========================
    render() {
        return (
            <Layout className={'item_body_number_back'}>
                <div style={{width:'100%',height:'100%',backgroundColor:'#fff',padding:'10px'}}>
{/*子公司---------------------------------------------*/}
                    <div style={{width:'100%',height:'340px',padding:'10px 20px 20px 20px', border: '1px #bbbbbb ridge'}}>
                        <div style={{width:'100%',height:'30px'}}>
                           <div style={{float:'left'}}><span style={{fontSize:'16px', fontFamily: '微软雅黑'}}>子公司</span></div>

                            <div style={{width:'70px',float:'right',textAlign:'center'}}>
                                {this.state.refresh_1?<LoadingOutlined style={{color:'#1890ff'}}/> : <ReloadOutlined style={{color:'#1890ff'}}/>}
                                <a style={{fontSize:'16px'}}
                                   onClick={()=>{
                                       if(this.props?.rowData?.customerId){
                                           this.getCustomerByParent();// 根据父客户查询子客户
                                       }
                                   }}
                                >
                                    刷新
                                </a>
                            </div>
                            {this.state.table_data_2.length===0?
                                <div>
                                    <div style={{float:'right',marginLeft:'10px'}}>
                                        <Button size={'small'} onClick={()=>{
                                            var customerTable =this.state.table_data_1.concat(this.state.table_data_2);
                                            this.setState({visible_1:true,customerTable:customerTable,type:'1'})
                                        }}
                                        >添加</Button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div style={{float:'right',marginLeft:'10px'}}>
                                        <Button size={'small'} disabled>添加</Button>
                                    </div>
                                </div>
                            }
                            <div style={{float:'right'}}>
                                <Button size={'small'}
                                        onClick={()=>{
                                            var rowDataId = this.state.rowDataId_1;
                                            if(rowDataId.length===0){return message.info('请选择要删除的子客户')}
                                            if(this.state.InCustomerTable.find(one=>{if(one.id===rowDataId[0]){return true}})){
                                                this.customerUnbind(rowDataId[0]);// 解绑
                                            }else{
                                                // 本地删除
                                                var tableData = [...this.state.table_data_1];
                                                for(var i=0;tableData.length>i;i++){
                                                    if(tableData[i]?.id===rowDataId[0]){
                                                        tableData.splice(i,1);
                                                    }
                                                }
                                                this.setState({table_data_1:tableData});
                                            }

                                        }}
                                >删除</Button>
                            </div>

                        </div>
                        <div>
                            <Table
                                rowKey={'id'}
                                bordered
                                pagination={false}
                                columns={this.state.table_columns_1}
                                dataSource={this.state.table_data_1}
                                scroll={{ y: 200 }}
                                rowSelection={{
                                    selectedRowKeys:this.state.rowDataId,
                                    onChange: this.onSelectChanges,
                                    type: 'radio'
                                }}
                            />
                        </div>
                    </div>
                    <div style={{width:'100%',height:'50px',}}/>
{/*母公司---------------------------------------------*/}
                    <div style={{width:'100%',height:'300px',padding:'10px 20px 20px 20px', border: '1px #bbbbbb ridge'}}>
                        <div style={{width:'100%',height:'30px'}}>
                            <div style={{float:'left'}}>
                                <span style={{fontSize:'16px', fontFamily: '微软雅黑'}}>母公司</span>
                            </div>
                            <div style={{width:'70px',float:'right',textAlign:'center'}}>
                                {this.state.refresh_2?<LoadingOutlined style={{color:'#1890ff'}}/> : <ReloadOutlined style={{color:'#1890ff'}}/>}
                                <a style={{fontSize:'16px'}}
                                   onClick={()=>{
                                       if(this.props?.rowData?.customerId){
                                           this.getCustomerByChild();// 根据子客户查询父客户
                                       }
                                   }}
                                >
                                    刷新
                                </a>
                            </div>
                            {this.state.table_data_1.length === 0 ?
                                <div>
                                    <div style={{float:'right',marginLeft:'10px'}}>
                                        {this.state.table_data_2.length<1?
                                            <Button size={'small'} onClick={()=>{
                                                var customerTable =this.state.table_data_1.concat(this.state.table_data_2);
                                                this.setState({visible_1:true,customerTable:customerTable,type:'2'})
                                            }}>添加</Button>
                                            :
                                            <Button size={'small'} disabled >添加</Button>
                                        }
                                    </div>
                                </div>
                            :
                                <div>
                                    <div style={{float:'right',marginLeft:'10px'}}>
                                        <Button size={'small'} disabled>添加</Button>
                                    </div>
                                </div>
                            }
                            <div style={{float:'right'}}>
                                <Button size={'small'}
                                        onClick={()=>{
                                            var rowDataId = this.state.rowDataId_1;
                                            if(rowDataId.length===0){return message.info('请选择要删除的客户')}

                                            if(this.state.InCustomerTable.find(one=>{if(one.id===rowDataId[0]){return true}})){
                                                this.customerUnbind(this.props?.rowData?.customerId);// 解绑
                                            }else{
                                                var tableData = [...this.state.table_data_2];
                                                for(var i=0;tableData.length>i;i++){
                                                    if(tableData[i]?.id===rowDataId[0]){
                                                        tableData.splice(i,1);
                                                    }
                                                }
                                                this.setState({table_data_2:tableData});
                                            }


                                        }}
                                >删除</Button>
                            </div>
                        </div>
                        <div>
                            <Table
                                rowKey={'id'}
                                bordered
                                pagination={false}
                                columns={this.state.table_columns_2}
                                dataSource={this.state.table_data_2}
                                scroll={{ y: 170 }}
                                rowSelection={{
                                    selectedRowKeys:this.state.rowDataId,
                                    onChange: this.onSelectChanges,
                                    type: 'radio'
                                }}
                            />
                        </div>
                    </div>
                    <div className={'card_open_account_div'} style={{height:'70px'}}>
                        <Button type="primary"  htmlType="submit" onClick={() => this.onSave()}>保存</Button>
                        {/*&nbsp;&nbsp;&nbsp;*/}
                        {/*<Button type="primary" onClick={() => this.clearData()}>清空</Button>*/}
                    </div>

                </div>

                <Modal
                    title={"客户列表"}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_1}
                    okText="确定"
                    onOk={() => this.setState({visible_1:false})}          	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={1200}
                    centered
                >
                    <div style={{width:'100%'}}>
                        <div>
                            <TableComponents
                                paramenter={this.state.tableParamenter}
                                // setData={getPageCustomerName}
                                setData={getCustomerByParent}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            />
                        </div>
                    </div>

                </Modal>

            </Layout>
        )
    }
}
