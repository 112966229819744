import ajax from './ajax'

// 获取痕迹系统的所有数据
export const getMarkData = data => ajax('/meterimport/traceInformation/getAll', data, 'POST')

//多条件查询痕迹记录
export const getLikeData = data => ajax('/meterimport/traceInformation/getAllByCondition', data, 'POST')

//查询痕迹来源
export const getTraceSources = data => ajax('/meterimport/traceInformation/getTraceSources', data, 'POST')

//查询操作名称
export const getOperationNames = data => ajax('/meterimport/traceInformation/getOperationNames', data, 'POST')

//查询操作人员
export const getOperatorNames = data => ajax('/meterimport/traceInformation/getOperatorNames', data, 'POST')

//级联选择
export const getDatas = data => ajax('/meterimport/traceInformation/getOperationNamesBySource', data, 'GET')



