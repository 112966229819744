import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Button, notification} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel} from '../../../services/steamReport'
import {getDateByUserId} from "../../../services/admin";

echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;
var downIp =global.pubData.url;


export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				areaName: '区域名称',
				dataTime: '时间',
				summary: '总表用量(吨)',
				subTable: '子表用量(吨)',
				currentGasLoss: '供销差(吨)',
				currentGasLossReta: '供销差率(%)',
			},
			// 弹出框
			headers: {
				areaName: '区域名称',
				dataTime: '时间',
				summary: '总表用量(吨)',
				subTable: '子表用量(吨)',
				currentGasLoss: '供销差(吨)',
				currentGasLossReta: '供销差率(%)',
			},
			params: {
				areaIds:[],
				granularity:1,
				startTime:'',
				endTime:'',
				itemIdList:[1184],
				page:1,
				size:100,
		},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					isMultiple:true,
                    disabled: false,
					data:[],
					type: 'select',
				},
				{
					type: 'select',
					variableName: 'granularity',
					str: '时间类别',
					data: [
						{
							key: '1',
							value: '时'
						},
						{
							key: '2',
							value: '日'
						},
						{
							key: '3',
							value: '月'
						},
						{
							key: '5',
							value: '峰谷'
						}
					]
				},
				{
					type: 'rangePicker',
					variableName: 'createTime',
					time: false,
					str: '时间'
				},

			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					fieldNames:{label: 'name', value: 'id', children: 'children'},
					rules: TYPE_NAME.TREE_SELECT,
					variableName: 'areaIds',
					str: '区域选择',

					disabled: this.props.location.state ? true : false,
					data:[],
				},
				// {
				// 	variableName: 'devIds',
				// 	str: '设备选择',
				// 	// isMultiple:true,
                //     disabled: false,
				// 	data:[],
				// 	rules: TYPE_NAME.SELECTINPUT,
				// },
				{
					variableName: 'granularity',
					str: '时间类别',
					rules: TYPE_NAME.SELECTINPUT,
					data: [
						{
							key: '1',
							value: '时'
						},
						{
							key: '2',
							value: '日'
						},
						{
							key: '3',
							value: '月'
						},
						{
							key: '5',
							value: '峰谷'
						}
					]
				},
				{
					variableName: 'createTime',
					str: '时间范围',
					rules: TYPE_NAME.RANGE_REQ_DATE_PICKER,
				},

			]
		},
		searchGetData:{},

		    	// 总条数
		totalCount: 0,
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editQrCodeStatus(true,rowData.id,rowData.code)}>
						<u>{'修改'}</u>
				</div>
				</>
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		let user = JSON.parse(sessionStorage.getItem("user"));
		// getDataIgnoreLevel({userId:user.id}).then(
		// 	response => {
		// 		const {data} = response.data;
		// 		let selectArry=[];
		// 		if(data){
		// 			for(var i=0;i<data.length;i++){
		// 				selectArry.push({key:data[i].id,value:data[i].name})
		// 			}
		// 		}
		// 		this.state.topFormItem.parameter[0].data=selectArry;
		// 	}
		// )


		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
			response => {
				if (response?.data) {
					console.log('--------------');
					console.log(response?.data);
					this.state.topFormItem.parameter[0].data=response?.data;
				} else {
					return message.info('获取不到数据');
				}
			}
		)
		// areaGasLossAnalysis(this.state.tableParamenter.params).then(
		// 	response => {
		// 		const {data} = response.data
		// 		const echartArryX=[];
		// 		const echartArryL=[];
		// 		const echartArryY=[];
		// 		console.log(data)
		// 		if(data){
		// 			for(var i=0;i<data.length;i++)
		// 			{
		// 				echartArryX.push(data[i].dataTime);
		// 				echartArryL.push(data[i].currentGasLossReta.replace("%",""));
		// 				echartArryY.push(data[i].currentGasLoss);
		// 			}
		// 		}
		// 		console.log(echartArryL)
		// 		this.getPie(echartArryX,echartArryL,echartArryY);
		// 	}
		// )



	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	getPie =  (echartArryX,echartArryL,echartArryY) => {
		setTimeout(() => {
		// 基于准备好的dom，初始化echarts实例
		myChart = echarts.init(document.getElementById('pie1'));
		// 绘制图表
		myChart.setOption({
			tooltip: {
				trigger: 'axis',
				axisPointer: {
				  type: 'cross',
				  crossStyle: {
					color: '#999'
				  }
				}
			  },
			//   toolbox: {
			// 	feature: {
			// 	  dataView: { show: true, readOnly: false },
			// 	  magicType: { show: true, type: ['line', 'bar'] },
			// 	  restore: { show: true },
			// 	  saveAsImage: { show: true }
			// 	}
			//   },
			  legend: {
				data: ['气损量', '气损率']
			  },
			  xAxis: [
				{
				  type: 'category',
				  data: echartArryX,
				  axisPointer: {
					type: 'shadow'
				  }
				}
			  ],
			  yAxis: [
				{
				  type: 'value',
				  name: '气损量',
				  axisLabel: {
					formatter: '{value} 吨'
				  }
				},
				{
				  type: 'value',
				  name: '气损率',
				  axisLabel: {
					formatter: '{value} %'
				  }
				}
			  ],
			  series: [
				{
				  name: '气损量',
				  type: 'bar',
				  tooltip: {
					valueFormatter: function (value) {
					  return value + ' 吨';
					}
				  },
				  data: echartArryY
				},
				{
				  name: '气损率',
				  type: 'line',
				  yAxisIndex: 1,
				  tooltip: {
					valueFormatter: function (value) {
					  return value + '%';
					}
				  },
				  data: echartArryL
				}
			  ]
		});
	},100)
}

	reportExport = async ()=>{
		let data = await this.forms.onFinishNotReset();
		if(!data) return null;
		console.log(data);
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		var params=[];
		this.forTreeArea(
			this.state.topFormItem.parameter[0].data,
			data.areaIds,
			params);
		console.log(params);

		var exportUrl= downIp+'/web/reportExportCtrl/exportAreaGasLossAnalysis';
		let link = `${exportUrl}
		?ajaxFileName=区域汽损分析
		&itemIdList=1184
		&granularity=${data.granularity}
		&startTime=${sTime}
		&endTime=${eTime}
		&areaIds=${params}`

		;
		// params.forEach(str => link += `'areaIds'=${str}`);
		// console.log(link);
		// window.open(link)
		window.open(downIp+'/web/reportExportCtrl/exportAreaGasLossAnalysis?' +
			'areaIds='+params+
			'&ajaxFileName=区域汽损分析' +
			'&itemIdList=1184' +
			'&granularity='+data.granularity+
			'&startTime='+sTime+
			'&endTime='+eTime
		)
	};
	forTreeArea = (data,key,areaIds=[]) =>{
		data.map(one=>{
			if(one.id===key){
				areaIds.push(one.id);
				if(one?.children){
					this.addAllTreeArea(one?.children,areaIds);
				}
			}else if(one?.children){
				this.forTreeArea(one?.children,key,areaIds);
			}
		});
		return areaIds;
	};
	addAllTreeArea = (data,areaIds=[]) =>{
		data.map(one=>{
			areaIds.push(one.id);
			if(one?.children){
				this.addAllTreeArea(one?.children,areaIds);
			}
		});
		return areaIds;
	};
	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		console.log('条件选择：获取输入的条件');
		console.log(data);
		console.log('条件选择：获取输入的条件');
		if(!data) return null;
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let params = this.state.tableParamenter.params;
		Object.assign(params,value);
		params.granularity=data.granularity;
		params.areaIds=[];
		this.forTreeArea(
			this.state.topFormItem.parameter[0].data,
			data.areaIds,
			params.areaIds);

		params.devIds=[];
		params.devIds=data.devIds;
		if(sTime){
			params.startTime=sTime;
		}
		if(eTime){
			params.endTime=eTime;
		}
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		this.setState({tableParamenter});
		this.table.refreshTable(params);

		areaGasLossAnalysis(params).then(
			response => {
				const {data} = response.data
				const echartArryX=[];
				const echartArryL=[];
				const echartArryY=[];
				console.log(data)
				if(data){
					for(var i=0;i<data.length;i++)
					{
						if(data[i].areaName!=='汇总'){
							echartArryX.push(data[i].dataTime);
							echartArryL.push(data[i].currentGasLossReta.replace("%",""));
							echartArryY.push(data[i].currentGasLoss);
						}

					}
				}
				this.getPie(echartArryX,echartArryL,echartArryY);
			}
		)
	};
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff'}}>
				<div className={'topSearchBox'}>
				<h3>区域汽损分析</h3>
					<div style={{position:'relative'}}>
						<div style={{width:'90%'}}>
						<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.topFormItem}
								data={this.state.searchGetData}
						/>
						</div>
						<Button style={{position:'absolute', right:'100px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						<Button style={{position:'absolute', right:'0',top:'0'}} onClick={() => this.reportExport()}>导出</Button>
				</div>
				</div>

				<div style={{width:'100%',height:'35%',overflowY: 'auto'}}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {areaGasLossAnalysis}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
					</TableComponents>
				</div>


					<div id="pie1" style={{height: '400px',background:'#fff', margin:'50px 0 0 0',paddingTop:'15px'}}/>

				</div>
            </Layout>
        )
    }
}
