import React, { Component } from 'react'
import {Button, Input, Layout, Table, Tabs, Upload, Modal, message, Popconfirm, DatePicker} from 'antd'
import {ArrowLeftOutlined,RedoOutlined} from '@ant-design/icons';
import './devInstallation.less'
import {funTraceInfo} from '../../../services/common'
import {
    getBoundDevicesById,
    devDisassembly,
    getBoundValveAlarmById,
    valveAlarmDisassembly
} from '../../../services/customer'
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import moment from "moment";

const { TextArea } = Input;
const {TabPane} = Tabs;
// 卸载安装
export default class unloadDevNew extends Component {
    state={
        // 样式点击约束
        pointer_1:'auto',//auto/none
        fontColor_1:'red',
        formsTwo:this.props.location?.state?.formsTwo,
        installer:JSON.parse(sessionStorage.getItem("user")).realName?JSON.parse(sessionStorage.getItem("user")).realName:'',       // 卸载人
        installerDate:moment().startOf("day").format("YYYY-MM-DD"),   // 安装时间
        telephone:JSON.parse(sessionStorage.getItem("user")).phone?JSON.parse(sessionStorage.getItem("user")).phone:'',       // 电话
        installPosition:'', // 安装位置
        installerInfo:'',   // 安装信息

        inputError:'',
        inputError_1:'',
        // 表格参数_12
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            overflow: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                parentNo: '控制器编号',
                communicateno: '控制器通信号',
                devModelName: '设备型号',
                // manufactureTime: '生产日期',
                state: '状态',
                comStatus: '通信状态',
                comTime: '最后通信时间',
                edit: '操作',
            },
            params: {
                devType:'10196',
                ids:[this.props.location?.state?.rowData?.id],
                page:1,
                size:3,
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        // 表格参数_12
        tableParamenter_1: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: false,
            selectHead:false,
            overflow: true,
            //下拉框长度
            selectWidth: '200px',
            // 表头：变量和变量解释
            header: {

                devModelName:'设备型号',
                hdstno: '唯一编码',
                manufacture: '供应商',
                state: '状态',
                edit: '操作',
            },
            params: {
                devType:'10168',
                ids:[this.props.location?.state?.rowData?.id],
                page:1,
                size:4,
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                // jumper:true
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        // 表格参数-阀井
        tableParamenter_2: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: false,
            selectHead:false,
            overflow: true,
            //下拉框长度
            selectWidth: '200px',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                status: '通信状态',
                comTime: '通信时间',
                dataTime: '最后数据时间',

            },
            params: {
                ids:[this.props.location?.state?.rowData?.id],
                devType:[10200],
                page:1,
                size:6,
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                // jumper:true
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        // 表格参数-家用报警
        tableParamenter_3: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: false,
            selectHead:false,
            overflow: true,
            //下拉框长度
            selectWidth: '200px',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',

            },
            params: {
                ids:[this.props.location?.state?.rowData?.id],
                devType:[10202],
                page:1,
                size:6,
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                // jumper:true
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        previewVisible: false,  // 照片弹窗
        previewImage: '',       // 浏览图片路径
        fileList_1: [], // 设备照片
        fileList_2: [], // 环境照片
        fileList_3: [], // 其他照片

    };
    componentWillMount() {
        console.log(this.props.location?.state?.formsTwo)

    }
    handleCancel = () => this.setState({ previewVisible: false });

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };


    // 低部表格单选
    onSelectChange=(_,b,)=>{
        this.setState({onSelectChangeData:b})
    };

    installationDev= yarn =>{
        var rowData=this?.table?.getSelectChange();
        if(rowData.length===0){
            return message.info('请选择安装的设备');
        }

        if(this.state.installer===''){
            this.setState({inputError:'error'});
            return message.info("卸载人不能为空")
        }
        if(this.state.telephone===''){
            this.setState({inputError_1:'error'});
            return message.info("联系电话不能为空")
        }
        var params={
            // 所属客户ID
            customerId:this.state.formsTwo?.customerId?this.state.formsTwo.customerId:'',
            // 所属客户名称
            customerName:this.state.formsTwo?.customerName?this.state.formsTwo.customerName:'',
            // 设备层主键id
            devId:rowData[0]?.devId?rowData[0]?.devId:'',
            // 设备名称
            devName:rowData[0]?.devName?rowData[0]?.devName:'',
            // 探测器ID
            gasAlarmId:rowData[0]?.id?rowData[0].id:'',
            // 厂家编号
            hdstno:rowData[0]?.manufacture?rowData[0].manufacture:'',
            // 安装位置
            // installPosition:this.state.formsTwo?.address?this.state.formsTwo.address:'',
            // 主键ID
            locationId:this.state.formsTwo?.id?this.state.formsTwo.id:'',
            // 父设备通讯号
            parentComNo:rowData[0]?.parentComNo?rowData[0].parentComNo:'',
            // 父设备ID
            parentId:rowData[0]?.parentId?rowData[0].parentId:'',
            // 父设备编号
            parentNo:rowData[0]?.parentNo?rowData[0].parentNo:'',
            // 唯一标识
            serialno:rowData.serialno?rowData[0].serialno:'',

            // 绑定客户联系人 客户联系人
            bindContacts:this.state.installer?this.state.installer:'',
            // 安装人
            installer:this.state.installer?this.state.installer:'',
            // 创建时间
            installDate:this.state.installerDate||'',
            // 安装联系电话
            installerTel:this.state.telephone?this.state.telephone:'',
            // 手机号码
            phone:this.state.telephone?this.state.telephone:'',
            // 安装地址
            installPosition:this.state.installPosition||'',
            // 安装备注
            remark:this.state.installerInfo?this.state.installerInfo:'',

        };
        console.log(params);
        // installationDev(params).then(
        //     response=>{
        //         console.log(response);
        //     }
        // )
    };
    onChange_1 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            funTraceInfo("客户管理", "上传图片", null,null)
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_1:file.fileList})
    };
    onChange_2 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            funTraceInfo("客户管理", "上传图片", null,null)
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_2:file.fileList})
    };
    onChange_3 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            funTraceInfo("客户管理", "上传图片", null,null)
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_3:file.fileList})
    };
    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return <>
                    <div style={{ float: 'left',"pointer-events": this.state.pointer_1,}}>
                        <a style={{paddingLeft:'15px', float: 'left',color:this.state.fontColor_1}}
                           onClick={() => {
                               if(this.state.installer===''){
                                   this.setState({inputError:'error'});
                                   return message.info("卸载人不能为空")
                               }
                               if(this.state.telephone===''){
                                   this.setState({inputError_1:'error'});
                                   return message.info("联系电话不能为空")
                               }
                               console.log(rowData);
                               var params={
                                   deviceType: 10196,
                                   id: rowData.id,
                                   operator: this.state?.installer,
                                   tel: this.state?.telephone
                               };

                               this.setState({pointer_1:'none',fontColor_1:'#999'});// 开启锁住请求按钮
                               devDisassembly(params).then(
                                   response=>{
                                       if(response?.data?.data){
                                           message.info(response.data.responseInfo.description);
                                           // funTraceInfo("客户管理", "拆卸设备", null,params);
                                           this?.table1?.refreshTable(this.state.tableParamenter.params);
                                           this?.table2?.refreshTable(this.state.tableParamenter_1.params);
                                           setTimeout(()=>{this.setState({pointer_1:'auto',fontColor_1:''});},5000);
                                       }
                                   }
                               )
                           }}
                        >拆下此设备</a>
                    </div>


                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    setTableValue_1 = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return <>
                    <div style={{ float: 'left',"pointer-events": this.state.pointer_1}}>
                        <a style={{paddingLeft:'15px', float: 'left',color:this.state.fontColor_1}}
                           onClick={() => {
                               if(this.state.installer===''){
                                   this.setState({inputError:'error'});
                                   return message.info("卸载人不能为空")
                               }
                               if(this.state.telephone===''){
                                   this.setState({inputError_1:'error'});
                                   return message.info("联系电话不能为空")
                               }
                               console.log(this.props.location?.state?.rowData);
                               var params={
                                   deviceType: 10200,
                                   locationId: this.props.location?.state?.rowData?.id,
                                   id: rowData.id,
                                   operator: this.state?.installer,
                                   tel: this.state?.telephone
                               };
                               console.log(params);
                               this.setState({pointer_1:'none',fontColor_1:'#999'});// 开启锁住请求按钮

                               valveAlarmDisassembly(params).then(
                                   response=>{
                                       if(response?.data?.data){
                                           message.info(response.data.responseInfo.description);
                                           // funTraceInfo("客户管理", "拆卸设备", null,params)
                                           setTimeout(()=>{this.setState({pointer_1:'auto',fontColor_1:''});},5000);
                                           this.table.refreshTable(this.state.tableParamenter.params);
                                       }else{
                                           message.info(response.data.responseInfo.description);
                                       }
                                   }
                               )
                           }}
                        >拆下此设备</a>
                    </div>


                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    render() {
        const { previewVisible, previewImage} = this.state;
        const uploadButton = (
            <div>
                {/*<Icon type="plus" />*/}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={'devInstallation'}>
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() =>{
                                this.props.history.go(-1)
                            }}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>返回上一页</span>
                        </div>
                    </div>
                    {/*---------上部分--------*/}
                    <div style={{ "width": '99.9%','height': '420px'}}>
                        {/*左边*/}
                        <div style={{  "width": '49.9%','height': '60%','float': 'left'}}>
                            {/*表单*/}
                            <div>
                                <div style={{"fontSize": "18px","fontWeight": "bold","paddingLeft":'5px'}}>
                                    <span className={'formText_div_span'}>客户信息</span>
                                    <span className={'formText_div_span'}>安装点信息</span>
                                    <span className={'formText_div_span'}>卸载信息</span>
                                </div>
                                <div className={'formText_installInner'}>
                                    <ul>
                                        <li>客户名：<span>{this.state?.formsTwo?.customerName?this.state?.formsTwo?.customerName:"-"}</span></li>
                                        <li>联系人：<span>{this.state?.formsTwo?.mpName?this.state?.formsTwo?.mpName:"-"}</span></li>
                                        <li>联系电话：<span>{this.state?.formsTwo?.principalPhone?this.state?.formsTwo?.principalPhone:"-"}</span></li>
                                    </ul>
                                </div>
                                <div className={'formText_installInner'}>
                                    <ul>
                                        <li>安装点名称：<span>{this.props?.location?.state?.rowData?.name?this.props?.location?.state?.rowData?.name:"-"}</span></li>
                                        <li>安装点状态：<span>{this.state?.formsTwo?.state?this.state?.formsTwo?.state:"-"}</span></li>
                                        <li>安装点地址：<span>{this.state?.formsTwo?.address?this.state?.formsTwo?.address:"-"}</span></li>
                                    </ul>
                                </div>

                                <div className={'formText_installInner'}>
                                    <ul>
                                        <li>卸载人：
                                            <Input status={this.state.inputError} style={{marginLeft: '5%', width: '65%' }}
                                                   size="small"
                                                   value={this.state.installer}
                                                   onChange={(text)=>{
                                                       this.setState({installer:text.target.value,inputError:''})
                                                   }}/></li>
                                        <li>安装时间：
                                            <DatePicker
                                                style={{ width: '65%' }}
                                                size="small"
                                                defaultValue={moment(this.state.installerDate, 'YYYY-MM-DD')}
                                                onChange={(text)=>{
                                                    text =moment(text).format('YYYY-MM-DD');
                                                    console.log(text);
                                                    this.setState({installerDate:text})
                                                }}
                                            />
                                            {/*<Input style={{ width: '65%' }} size="small" onChange={(text)=>{this.setState({installerDate:text.target.value})}}/>*/}
                                        </li>
                                        <li>联系电话：<Input
                                            status={this.state.inputError_1}
                                            style={{ width: '65%' }}
                                            size="small"
                                            value={this.state.telephone}
                                            onChange={(text)=>{
                                                this.setState({telephone:text.target.value,inputError_1:''})
                                            }}
                                        /></li>
                                        <li style={{ 'float': 'left' }} >安装位置：</li>
                                        <TextArea style={{marginTop:'3%',width: '65%','float': 'left' }} autoSize={{ minRows: 2, maxRows: 4 }}
                                                  onChange={(text)=>{this.setState({installPosition:text.target.value})}}
                                        />
                                        <li style={{ 'float': 'left' }} >安装信息：</li>
                                        <TextArea style={{marginTop:'3%',width: '65%','float': 'left' }} autoSize={{ minRows: 4, maxRows: 4 }}
                                                  onChange={(text)=>{this.setState({installerInfo:text.target.value})}}
                                        />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/*竖线*/}
                        <div className={'verticalLine'} />
                        {/*右边*/}
                        <div style={{  "width": '49.9%','height': '400px','float': 'left','paddingLeft':'2px'}}>
                            <div>
                                <Tabs
                                    // activeKey={activeKey}
                                    tabPosition={"top"}
                                    animated={true}
                                    activeKey={this.state.tabKey}
                                    onTabClick={(key)=>{this.setState({tabKey:key})}}
                                >
                                    <TabPane tab="报警控制器" key="1">
                                        <div>
                                            <div style={{width:'99%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table1.refreshTable(this.state.tableParamenter.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'100%',height:'330px'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter}
                                                    setData = {getBoundDevicesById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table1 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="报警探测器" key="2">
                                        <div>
                                            <div style={{width:'99%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table2.refreshTable(this.state.tableParamenter_1.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'100%',height:'330px'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_1}
                                                    setData = {getBoundDevicesById}
                                                    // setTableValue={this.setTableValue}
                                                    ref={e => this.table2 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="阀井" key="3">
                                        <div>
                                            <div style={{width:'99%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table3.refreshTable(this.state.tableParamenter_2.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'100%',height:'330px',overflow: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_2}
                                                    setData = {getBoundValveAlarmById}
                                                    // setTableValue={this.setTableValue_1}
                                                    ref={e => this.table3 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="家用报警器" key="4">
                                        <div>
                                            <div style={{width:'99%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table4.refreshTable(this.state.tableParamenter_3.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'100%',height:'330px'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_3}
                                                    setData = {getBoundValveAlarmById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table4 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    {/*横线*/}
                    <div className={'horizontalLine'}/>
                </div>
            </Layout>

        );
    }
}
