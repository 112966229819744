import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getVersionById,releaseVersionByPcAdmin,getVersionInfosByPcAdmin} from '../../../services/IKit'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				versionName: '版本名称',
				versionNo: '版本号',
				publisherName: '版本发布者名称',
				appType: '移动端类型',
				updateContent: '更新内容说明',
				downUrl: '下载地址',
				createTime: '提交时间',
				edit: '操作',
			},
			// 弹出框
			headers: {
				versionName: '版本名称',
				versionNo: '版本号',
				publisherName: '版本发布者名称',
				appType: '移动端类型',
				updateContent: '更新内容说明',
				downUrl: '下载地址',
				createTime: '提交时间',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'versionName',
					str: '版本名称'
				}

			]
		},
		    	// 总条数
		totalCount: 0,
		devVisible:false, // 小窗口开关器-1
		versionId:'',
		oldCode:'',
		modTitle:'发布版本信息',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 2,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'versionName',
					str: '版本名称',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'versionNo',
					str: '版本号',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'publisherName',
					str: '版本发布者名称',
					disabled: true,
					rules: TYPE_NAME.NAME_NOT_NULL,
				},
				{
					variableName: 'appType',
					str: '移动端类型',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: 'Android',
							value: 'Android'
						},
						{
							key: 'IOS',
							value: 'IOS'
						},
					]
				},
				{
					variableName: 'updateContent',
					str: '更新内容说明',
					rules: TYPE_NAME.TEXT_AREA,
				}

			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editVersionInfoStatus(true,rowData.id)}>
						<u>{'修改'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	componentDidMount(){
		console.log(this.state.params)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	//添加设备窗口显示
	addVersionInfoStatus = async (devVisible)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		await this.setState({devVisible,data:{publisherId:user.id,publisherName:user.realName},modTitle:'发布版本信息',versionId:''})
		await this.forms.onModalClose()
    }
	editVersionInfoStatus=(devVisible,id)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		this.setState({devVisible:devVisible,data:{publisherId:user.id,publisherName:user.realName},versionId:id,modTitle:'修改版本信息'});
		getVersionById({versionId: id}).then(
			response => {
				const {data} = response.data
				this.setState({data: data})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
    }

	addVersionInfoData = async (value)=>{
			let devData = await this.forms.onFinishNotReset()
			if(!devData) return null
			if(this.state.versionId){
				devData.id=this.state.versionId
			}
			devData.publisherId=this.state.data.publisherId
			releaseVersionByPcAdmin(devData).then(data=>{
				if(data.data.data==true){
					// funTraceInfo("IKit系统管理", "发布版本", null,devData)
					message.config("成功")
					this.addVersionInfoStatus(false)
				}else{
					this.addVersionInfoStatus(false)
					message.config('失败')

				}
				this.table.refreshTable();// 刷新表格
			});

	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>版本信息</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addVersionInfoStatus(true)}><PlusSquareOutlined />新增</span>
					{/* <span className={'addLink'} onClick={() => this.updatePoint(true)}><EditOutlined />修改</span> */}

				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getVersionInfosByPcAdmin}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addVersionInfoData(true)}           // 点击确定
                        onCancel={() => this.addVersionInfoStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
