import ajax from './ajax'





// 二维码列表
export const listQrcode = data => ajax('/qrcode/qrcode/listQrcode', data, 'POST');


//区域
export const areaGasLossAnalysis = data => ajax('/web/chart/areaGasLossAnalysis', data, 'POST')


//获取所有区域
export const getDataIgnoreLevel = data => ajax('/web/areaTreeCtrl/getAreaIgnoreLevel', data, 'POST')

//获取所有子区域
export const getLevelById = data => ajax('/web/areaTreeCtrl/getLevelById', data, 'GET')


//获取温压
export const tempPreComparisonCurve = data => ajax('/web/chart/tempPreComparisonCurve', data, 'POST')



//获取用量列表
export const queryPeakValleyPage = data => ajax('/web/reportCtrl/queryPeakValleyPage', data, 'POST')




//实时数据
export const realTimeDataMonitoring = data => ajax('/web/chart/realTimeDataMonitoring', data, 'POST')

export const realTimeDataMonitoringPage = data => ajax('/web/chart/realTimeDataMonitoringPage', data, 'POST')


export const detailedAnalysisList = data => ajax('/web/chart/detailedAnalysisList', data, 'POST')




//获取设备
export const listDevByAreaIds = data => ajax('/web/chart/listDevByAreaIds', data, 'POST')



//计划报表
export const listPlanRecordByAreaId = data => ajax('/web/plan/listPlanRecordByAreaId', data, 'POST')

//用量计划
export const queryTomorrowPlan = data => ajax('/web/plan/queryTomorrowPlan', data, 'POST')


// //设备信息实时监控
// export const listDeviceRealTimeData = data => ajax('/gasdevice/gas/listDeviceRealTimeData', data, 'POST')




//设备信息实时监控-旧
export const listDeviceRealTimeData = data => ajax('/web/alarmInfo/listDeviceRealTimeData', data, 'POST');

//设备信息实时监控-新
export const pageRealTimeValve = data => ajax('/web/valveInfo/pageRealTimeValve', data, 'POST');


//数据推送配置-新
export const configPushData = data => ajax('/checkup/checkRecord/configPushData', data, 'POST');
//查询配置
export const queryConfig = data => ajax('/checkup/checkRecord/queryConfig', data, 'GET');
//查询推送记录
export const queryPushRecord = data => ajax('/checkup/checkRecord/queryPushRecord', data, 'POST');























