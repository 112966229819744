
import { Component } from 'react'
import { Layout, message, Tooltip ,Radio,Modal,Button} from "antd";
import { NavLink } from "react-router-dom"
import moment from "moment";
import { alarmConfig,pageAllConfig,getMessageInfo } from '../../../services/alarm'
import '../../../style/luo.less'
import Pubsub from 'pubsub-js'
import React from 'react';
import { TYPE_NAME } from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import Conditions from '../../../components/common/conditions/Conditions'
import Tables from "../../../components/common/tables/Tables";
export default class AlarmConfig extends Component {




    state = {
        // statusAndAlarmData:'左框第三个',  // 左-3文本
        responseInfo: {},
        treeId: [],
        deleteId: [],
        // 表格参数
        tableParamenter: {
            // 表格行是否可选
            // rowSelection: true,
            // 表头：变量和变量解释
            header: {
                id:'标识',
                modelId: '表具型号',
                alarmType: '报警类型',
                alarmThreshold: '报警阈值',
                // alarmReason: '报警原由',
            },
            // 下拉框：变量和变量解释
            headers: {
                id:'标识',
                maugeModel: '表具型号',
                alarmType: '报警类型',
                alarmThreshold: '报警阈值',
                // alarmReason: '报警原由',
            },
            // 表格数据
            data: [],
        },// 表格参数END
        expandedKeys: [],
        checkedKeys: [],
        selectedKeys: [],
        autoExpandParent: [],
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            parameter: [



            ]
        },

        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 4,
            align: 'center',
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'dataItemId',
                    str: '数据',
                    rules: TYPE_NAME.SELECT,
                    data: [
                        {
                            key: '1184',
                            value: '累计流量'
                        },
                        {
                            key: '1205',
                            value: '温度'
                        },
                        {
                            key: '1225',
                            value: '压力'
                        },
                        {
                            key: '1248',
                            value: '瞬时流量'
                        },
                        {
                            key: '1266',
                            value: '工况瞬时流量'
                        },
                        {
                            key: '1267',
                            value: '工况累积流量'
                        },
                        {
                            key: '3000',
                            value: '电池电量'
                        },
                    ]

                },
                {
                    variableName: 'alarmType',
                    str: '类型',
                    rules: TYPE_NAME.SELECT,
                    defaultValue: [ {
                        // key: '阈值',
                        // value: '阈值'
                    }] ,
                    data: [
                        {
                            key: '阈值',
                            value: '阈值'
                        },
                    ]
                },
                {
                    variableName: 'max',
                    str: '上限',
                    rules: TYPE_NAME.INPUT,

                },
                {
                    variableName: 'min',
                    str: '下限',
                    rules: TYPE_NAME.INPUT,
                },
            ]
        },




        //总条数
        totalCount: 0,
        pageinfo:{
            current:1,
            size:10,
        },
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,
        rowData: [],
        visibleadd: false,
        data: {
            alarmType:"阈值"
        }
    };

    async componentDidMount() {

    //   this.state=selecetion.map(item=>item.state)
    //   console.log()

        this.getData();

    }

    // 表格：刷新方法
    refreshTable = () => {
        this.setState({ refreshTable: true })
        this.getData();
    }


    // 表格：获取勾选中的值
    onSelectChange = data => {
        console.log("在线数据",data)
        this.setState({ rowData: data })

    }

    // 表格：导出
    exportTable = () => {
        console.log('数据导出')
    }

    // 条件选择：获取输入的条件
    getConditionsValue = value => {
        let { params } = this.state; // 获取两个state的数据管理
        if (value.messageTime !== null) {
            params.messageInfoMessageTimeStart = moment(value.messageTime[0]._d).format('YYYY-MM-DD HH:mm:ss');
            params.messageInfoMessageTimeEnd = moment(value.messageTime[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        this.setState({ params });   // 确定修改
        // console.log("ssssss",this.state.params)
        let param = {
            machineProgramName: value.programName,
            messageUserMessageUserName: value.messageUserName,
            messageType:value.messageType,
            messageInfoMessageTimeStart: this.state.params.messageInfoMessageTimeStart,
            messageInfoMessageTimeEnd: this.state.params.messageInfoMessageTimeEnd,
            page: this.state.pages
        }
        getMessageInfo(param).then(
            response => {
                const { data } = response.data
                let { tableParamenter } = this.state
                tableParamenter.data = data
                const { pageInfo } = response.data
                let total = pageInfo.total

                let { pages } = this.state
                pages.current = pageInfo.pageIndex
                pages.size = pageInfo.pageLine
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter: tableParamenter,
                    totalCount: total,
                    pages
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')
            }
        )
    }

    setTableValue = (value, text, rowData) => {
        switch (value) {
            case 'messageTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                )
            case 'receiptTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                )
            case 'sendTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                )
            case 'sendState':
                text = text * 1

                return text === 1 ? '已发送' : text === 2 ? '未发送' : '-'

            case 'messageType':
                text = text * 1
                if(text===1){

                    return (
                        <NavLink to={{pathname:"/home/onlineMessage",
                        state: {
                            programName: rowData.programName,
                            messageContent: rowData.messageContent,
                            messageTitle: rowData.messageTitle,
                            messageTime:  moment(rowData.messageTime).format("YYYY-MM-DD HH:mm:ss"),
                            messageUserName: rowData.messageUserName,
                            telephone: rowData.telephone,
                            mailbox: rowData.mailbox,
                            receiptTime:  moment(rowData.receiptTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendTime:  moment(rowData.sendTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendState:rowData.sendState === 1 ? '已发送' : rowData.sendState === 2 ? '未发送' : '-',
                            reader: rowData.reader,
                            readTime: moment(rowData.readTime).format("YYYY-MM-DD HH:mm:ss"),
                            readState:rowData.readState === 1 ? '已阅读' : rowData.readState === 2 ? '未阅读' : '-'
                        }
                        }}>
                            <Tooltip title={text}>
                                <u>{text === 1 ? '即时消息' : '-'}</u>
                            </Tooltip>
                        </NavLink>

                    );
                }else if(text===2){
                    return (
                        <NavLink to={{pathname:"/home/offlineMessage",
                        state :{
                            programName: rowData.programName,
                            messageContent: rowData.messageContent,
                            messageTitle: rowData.messageTitle,
                            messageTime:  moment(rowData.messageTime).format("YYYY-MM-DD HH:mm:ss"),
                            messageUserName: rowData.messageUserName,
                            telephone: rowData.telephone,
                            programMailbox: rowData.programMailbox,
                            receiptTime:  moment(rowData.receiptTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendTime:  moment(rowData.sendTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendState:rowData.sendState === 1 ? '已发送' : rowData.sendState === 2 ? '未发送' : '-',
                               }
                        }}>
                            <Tooltip title={text}>
                                <u>{ text === 2 ? '离线消息' : '-'}</u>
                            </Tooltip>
                        </NavLink>

                    );
                }else{
                    return ''
                }

            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    }

    // 获取当前页数和条数
    getPagination = (page, pageSize) => {
        let {pageinfo} = this.state
	    pageinfo.current = page
		pageinfo.size = pageSize
		this.setState({pageinfo})
        this.getData()
    }



    // 清空条件
    // clearCondition = () => {
    //     let { params } = this.state
    //     params.username = null
    //     this.setState({ params })
    //     if (this.state.username == null) {
    //         this.getData()
    //     } else {
    //         this.getDatas()
    //     }
    // }


    //展开/收起节点时触发
    onExpand = (expandedKeysValue: React.Key[]) => {
        console.log('onExpand', expandedKeysValue);
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false
        });
    };

    //点击复选框触发
    onCheck = (checkedKeysValue: React.Key[]) => {
        console.log('onCheck', checkedKeysValue);
        //点击树节点触发
        this.setState({
            checkedKeys: checkedKeysValue,
        });
    };

    //初始化表格数据
    getData = () => {
        // this.setState({loading: true});
        let param = {
            current:this.state.pageinfo.current,
            size:this.state.pageinfo.size,

          }
          console.log("参数",param)
          pageAllConfig(param).then(
            response => {
                const { data } = response.data
                const { pageInfo } = response.data
                console.log('分页111111111111', pageInfo);
                let total = pageInfo.total
                let { tableParamenter } = this.state
                tableParamenter.data = data
                this.setState({ tableParamenter, totalCount: total })
                message.success('查询成功', [0.5])
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                })
                message.error('查询失败', [0.5])
            }
        )
    }

    showes = () => {
        this.setState({
            visibleadd: true,
        });
        setTimeout(() => this.forms.onModalClose(), 0)
    };

    handleOk = async () => {
        this.setState({ loading: true });
        let value = await this.forms.onFinish()
        let params = {
            dataItemId: value.dataItemId,
            alarmType:value.alarmType,
            max:value.max,
            min:value.min
        }
        console.log("配置参数")
        console.log(params)
        // 新增数据权限
        alarmConfig(params).then(
            response => {
                response.data.data ? message.success('新增成功', [0.5]) : message.error('新增失败', [0.5])
                this.getData();
                // funTraceInfo("报警管理", "新增报警配置", null,params)
            }
        )
        setTimeout(() => {
            this.setState({ loading: false, visibleadd: false });
        }, 0);
    };

    handleCancel = () => {
        this.setState({ visibleadd: false });
    };






    render() {
        const { loading, visibleadd } = this.state;
        return (
            <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
                <Tables
                    tableParamenter={this.state.tableParamenter}  // 表格表头、数据
                    exportTable={this.exportTable}                  // 导出时触发的方法
                    onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                    refreshTable={this.refreshTable}                // 刷新时触发的方法
                    getPagination={this.getPagination}              // 分页器变化时触发的方法
                    setTableValue={this.setTableValue}              // 设置表格显示

                    pagination={{
                        totalCount: this.state.totalCount, // 总条数
                        page: this.state.pageinfo.current,// 当前页数
                        size: this.state.pageinfo.size // 页面条数
                    }}

                    // loading={{
                    //     loading: this.state.loading,
                    //     refreshTable: this.state.refreshTable
                    // }}
                >

                          <Radio.Group defaultValue="addConfig" buttonStyle="solid">
                            <Radio.Button value="addConfig" type="primary" onClick={this.showes} >新增报警配置</Radio.Button>
                            <Modal
                                visible={visibleadd}
                                title="新增配置"
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                footer={[
                                    <Button
                                        key="submit"
                                        type="primary"
                                        loading={loading}
                                        onClick={this.handleOk}
                                    >
                                        提交
                                    </Button>,
                                    <Button key="back" onClick={this.handleCancel}>
                                        返回
                                    </Button>
                                ]}
                            >
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.formParamenter}
                                    data={this.state.data}
                                />
                            </Modal>


                            </Radio.Group>
                </Tables>
            </Layout>
        )
    }

}
