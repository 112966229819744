import ajax from './ajax'





// 二维码列表
export const listQrcode = data => ajax('/qrcode/qrcode/listQrcode', data, 'POST');
//获取二维码
export const getQrcodeDetail = data => ajax('/qrcode/qrcode/getQrcodeDetail', data, 'GET');
// 新增二维码
export const addQrcode = data => ajax('/qrcode/qrcode/addQrcode', data, 'POST');
// 修改二维码
export const updateQrcode = data => ajax('/qrcode/qrcode/updateQrcode', data, 'POST');


// 报警器详情or客户列表

export const getAllCustomer = data => ajax('/qrcode/customer/getAllCustomer', data, 'POST');
export const getAlarmDevInfo = data => ajax('/qrcode/device/getAlarmDevInfo', data, 'POST');


// ----------------------------------------------------
// 多条件分页查询生命周期 -旧
export const PageQueryLifecycle = data => ajax('/qrcode/newDevice/PageQueryLifecycle', data, 'POST');

// 多条件分页查询生命周期 -新
export const PageQueryLifecycleNew = data => ajax('/web/newPoint/PageQueryLifecycle', data, 'POST');

// 多条件分页查询设备信息
export const PageDevInfo = data => ajax('/qrcode/newDevice/PageDevInfo', data, 'POST');


// 多条件分页查询报警消息配置信息
export const getPageAlarmSourceQuery = data => ajax('/newAlarm/alarmSource/getPageAlarmSourceQuery', data, 'POST');
// 查询父表id获取子表列表
export const getConfigsByAlarmSourceId = data => ajax('/newAlarm/alarmNoticeConfigFeign/getConfigsByAlarmSourceId', data, 'GET');
// 保存和新增配置数据
export const saveAlarmNoticeConfig = data => ajax('/web/alarmNoticeConfig/saveAlarmNoticeConfig', data, 'POST');
// 按区域保存配置
export const saveOrUpdateAlarmNoticeConfigByArea = data => ajax('/web/alarmNoticeConfig/saveOrUpdateAlarmNoticeConfigByArea', data, 'POST');

// 获取选取子表详细数据
export const getNoticeAreasByConfigId = data => ajax('/newAlarm/alarmNoticeArea/getNoticeAreasByConfigId', data, 'GET');

// 分页查询设备信息
export const pageDeviceInfo = data => ajax('/web/alarmInfo/pageDeviceInfo', data, 'POST');

// 分页查询设备历史数据（2023-12-20添加，通过设备id，时间段查询历史数据）
export const queryHisDataByDevIdAndTime = data => ajax('/web/alarmInfo/queryHisDataByDevIdAndTime', data, 'POST');
// 新增设备
export const addDevice = data => ajax('/web/alarmInfo/addDevice', data, 'POST');
// 修改设备
export const updateDevice = data => ajax('/web/alarmInfo/updateDevice', data, 'POST');


// 根据设备id查询操作信息
export const getDeviceDetailByDevId = data => ajax('/qrcode/newDevice/getDeviceDetailByDevId', data, 'GET');


// 查询设备业务统计（检定信息\维护信息）
export const queryDevBusinessStatistics = data => ajax('/web/alarmHomePage/queryDevBusinessStatistics', data, 'POST');
// 通过客户查询安装点（地图坐标）
export const queryAllInstallationPointCoordinatesByCustomerIds = data => ajax('/web/alarmHomePage/queryAllInstallationPointCoordinatesByCustomerIds', data, 'POST');
// 查询当前登录用户下的所有设备的在线情况（设备在线率）
export const queryDevWorkingState = data => ajax('/web/alarmHomePage/queryDevWorkingState', data, 'POST');
// 通过安装点id查询安装点详细信息(地图表格)
export const queryInstallationPointDetail = data => ajax('/web/alarmHomePage/queryInstallationPointDetail', data, 'GET');

// 根据设备类型id查询设备型号信息
export const listDeviceModelByDeviceClassId = data => ajax('/web/alarmInfo/listDeviceModelByDeviceClassId', data, 'GET');


// 通过当前用户所属客户下的所有报警器的报警信息
export const queryAlarmNumber = data => ajax('/web/alarmHomePage/queryAlarmNumber', data, 'POST');


// 根据安装点查询设备实时数据
export const listDeviceRealTimeDataByLocations = data => ajax('/web/alarmInfo/listDeviceRealTimeDataByLocations', data, 'GET');


// 修改状态-暂时无用
// export const updateState = data => ajax('/web/alarmSource/updateState', data, 'GET');

// 修改子表内容状态
export const updateStatez = data => ajax('/newAlarm/alarmNoticeConfigFeign/updateState', data, 'GET');
// 修改父表内容状态
export const updateState = data => ajax('/newAlarm/alarmSource/updateState', data, 'GET');



// 查询当前登录用户下的所有设备的在线情况
export const meterQueryDevWorkingState = data => ajax('/web/meterHomePage/queryDevWorkingState', data, 'POST');

// 新增阀警设备
export const addDeviceAlarm  = data => ajax('/web/valveInfo/addDevice', data, 'POST');
// 分页查询阀警信息-旧
// export const pageValveInfo  = data => ajax('/web/valveInfo/pageValveInfo', data, 'POST');
// 分页查询阀警信息-新
export const pageValveInfo = data => ajax('/gasdevice/valve/pageValveInfo', data, 'POST');

// 设备拆卸
export const removeDev  = data => ajax('/web/valveInfo/removeDev', data, 'GET');
// 安装点类型-安装设备
export const bindDev  = data => ajax('/web/valveInfo/bindDev', data, 'POST');

// 新增报警类型
export const addAlarmSource  = data => ajax('/newAlarm/alarmSource/addAlarmSource', data, 'POST');
// 修改报警类型
export const updateAlarmSource  = data => ajax('/newAlarm/alarmSource/updateAlarmSource', data, 'POST');
// 删除报警类型
export const delAlarmSource  = data => ajax('/newAlarm/alarmSource/delAlarmSource', data, 'POST');


// 分页查询阀警历史数据
export const pageHistoricalTimeValve  = data => ajax('/web/valveInfo/pageHistoricalTimeValve', data, 'POST');


// 修改阀警设备
export const updateBaseDevice = data => ajax('/gasdevice/valve/updateBaseDevice', data, 'POST');


