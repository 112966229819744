
import { Component } from 'react'
import { Button, Layout, Tree, message,  Radio, Modal } from "antd";
import {
  deleteOrganizationTree,
  updateOrganizationTree,
  addOrganizationTree,
  updatePosition,
  addPosition,
  getOrganizationTree,
  listPositionsByOrganizationId,
  deletePosition,
  getNewTree, updateNew
} from '../../../services/admin'
import '../../../style/luo.less'
import React from 'react';
import Tables from '../../../components/common/tables/Tables';

import FormsTwo from '../../../components/common/forms/FormsTwo'

import { TYPE_NAME } from '../../../components/Const'

import {funTraceInfo} from '../../../services/common'

export default class BasicSettings extends Component {




  state = {
    buttonLoading_1:false,
    selectedNodes:[],
    tableData: [],
    nodeId: [],
    treeId: [],
    chi:[],
    responseInfo: {},
    // 表格参数
    tableParamenter: {
      // 表格行是否可选
      rowSelection: true,
      // 表头：变量和变量解释
      header: {
        positionId: '职位序号',
        name: '职位名称',
      },
      // 下拉框：变量和变量解释
      headers: {
        positionId: '职位序号',
        name: '职位名称',
      },
      // 表格数据
      data: null,
    },// 表格参数END

    expandedKeys: [],
    checkedKeys: [],
    selectedKeys: [],
    autoExpandParent: [],
    tree: {
      treeData: [
        {
          title: 'name',
          key: 'id',

          children: [
            {
              title: 'name',
              key: 'id',

              children: [
                { title: 'name', key: 'id' },
              ],
            },
          ],
        },
      ]
    },
    rowData: [],


    // 总条数
    totalCount: 0,

    params: {
      page: 1,
      size: 15,
      name: null,
      areaId: JSON.parse(localStorage.getItem('menus'))?.[0]
    },
    // 表格加载动画
    loading: false,
    visible: false,
    visible1: false,
    visibleadd:false,
    visible2:false,
    // 刷新动画
    refreshTable: false,


    // 表单参数
    formParamenter: {
      modal: true,
      number: 1,
      labelCol: 4,
      align: 'center',
      layout: "horizontal",
      parameter: [
        {
          variableName: 'positionId',
          str: '序号',
          rules: TYPE_NAME.INPUT,
          disabled: true,

        },
        {
          variableName: 'name',
          str: '职位名称',
          rules: TYPE_NAME.NOT_BLANK
        }
      ]
    },

    formParamenteres: {
      modal: true,
      number: 1,
      labelCol: 6,
      align: 'center',
      layout: "horizontal",
      parameter: [
        // {
        //   variableName: 'id',
        //   str: '组织部门序号',
        //   rules: TYPE_NAME.INPUT,
        //   disabled: true,
        //
        // },
        {
          variableName: 'name',
          str: '组织部门名称',
          rules: TYPE_NAME.NOT_BLANK
        }
      ]
    },

    formdatas: [],
    updateDatas: {
      name: 'aaa'
    }
  };

  componentDidMount() {
    this.getTreeNode();
    this.setState({nodeId:[]})
  }



  //展开/收起节点时触发
  onExpand = (expandedKeysValue: React.Key[]) => {
    this.setState({
      expandedKeys: expandedKeysValue,
      autoExpandParent: false
    });
  };




  //点击复选框触发
  onCheck = (checkedKeysValue: React.Key[]) => {
  };

  //点击树节点触发
  onSelect = (selectedKeysValue: React.Key[], info: any) => {
    console.log('info');
    console.log(info);
    console.log('点击树节点触发');
    console.log(selectedKeysValue);
    console.log(info.selectedNodes);
    this.state.selectedNodes=info.selectedNodes;
    let childrens=info.node.children;
    let node=null;
    if(info.selected){
      node = info.node.key;
    }
    let key = info.node.key;
    let param = {
      organizationId: key
    };
    let name=info.node.title;

    listPositionsByOrganizationId(param).then(
      response => {
        let { data } = response.data;

        if (data === null) {
          message.error("该组织部门下不存在职位",[0.5]);
          let { tableParamenter,updateDatas } = this.state;
          tableParamenter.data = data;
          updateDatas.name=name;
          this.setState({ tableParamenter, treeId: key, nodeId: node , chi:childrens,updateDatas});
        } else {
          let { tableParamenter,updateDatas } = this.state;
          updateDatas.name=name;
          tableParamenter.data = data;
          this.setState({ tableParamenter, treeId: key, nodeId: node ,chi:childrens,updateDatas});
          message.success('查询成功',[0.5]);
        }
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败'[0.5])
      }
    )
  };

  getData = () => {
    let param = {
      organizationId: this.state.treeId
    }

    listPositionsByOrganizationId(param).then(
      response => {
        let { data } = response.data

        let { tableParamenter } = this.state
        tableParamenter.data = data
        this.setState({ tableParamenter })

      }
    )
  }


  //表格刷新
  refreshTable = () => {
    this.getData();
  }

  // 表格：获取勾选中的值
  onSelectChange = tableData => {
    this.setState({ rowData: tableData })

  }



  getTreeNode = () => {//获取树节点
    this.setState({ loading: true });
    let userId =  JSON.parse(sessionStorage.getItem("user")).id;

    if(!userId){
      return message.info('当前获取不到需要的客户信息，新尝试重新登入系统获取或联系管理员')
    }
    // 新
    getNewTree({userId:userId}).then(
        reponse => {
          console.log(reponse.data.responseInfo.code);
          if(reponse.data.responseInfo.code!==200){
            return message.info('未有组织结构树数据');
          }
          let { data } = reponse.data;
          let { responseInfo, tree } = this.state;
          responseInfo.data = data;
          tree.treeData = data;
          this.setState({
            loading: false,
            refreshTable: false,
            totalCount: data.total,
            responseInfo: responseInfo,
            tree
          });
          message.info('查询成功',[0.5])
          // funTraceInfo("权限管理", "组织结构查询", null, tree)
        }
    ).catch((e) => {
        this.setState({loading: false, refreshTable: false,});

        message.info('查询异常，请重新刷新页面',)
      }
    );

    // // 旧
    // getOrganizationTree(this.state.responseInfo).then(
    //   reponse => {
    //
    //     let { data } = reponse.data;
    //
    //     let { responseInfo, tree } = this.state;
    //     responseInfo.data = data;
    //     tree.treeData = data;
    //     this.setState({
    //       loading: false,
    //       refreshTable: false,
    //       totalCount: data.total,
    //       responseInfo: responseInfo,
    //       tree
    //     });
    //     message.success('查询成功',[0.5])
    //     // funTraceInfo("权限管理", "组织结构查询", null, tree)
    //   }
    // ).catch(
    //   () => {
    //     this.setState({
    //       loading: false,
    //       refreshTable: false,
    //     });
    //     message.error('查询失败',[0.5])
    //   }
    // )
  };

  //职位注销
  deletePost = data => {
    if (this.state.rowData.length < 1) return message.warning('未选择数据',[0.5]);

    let ids = this.state.rowData[0].positionId
    let name=this.state.rowData[0].name

    let param = {
      positionId: ids,
      name:name
    }

    Modal.confirm({
      title: `是否确认注销该职位？`,
      content: '',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk: () => {
        // 注销用户
        deletePosition(param).then(
          response => {
            this.setState({
              loading: false,
              refreshTable: false,
              totalCount: data.total,
            });
            console.log(response.data.responseInfo);
            if(response.data){
              if(response.data.responseInfo.code){
                message.success(response.data.responseInfo.description,)
              }else{
                message.success('职位注销失败');


              }
              this.getData();
              // if(response.data.responseInfo.code){
              //   message.success('职位注销失败'+response.data.responseInfo.description,[0.5])
              // }else{
              //   message.success('职位注销成功',[0.5]);
              //   this.getData();
              //   funTraceInfo("权限管理", "注销职位", param, null)
              // }

            }

          }
        ).catch(
          () => {
            this.setState({
              loading: false,
              refreshTable: false,
            });

            message.error('职位注销失败',[0.5])
            this.getData();
          }
        )
      }
    })
  };

  //递归
  getTreeData_1 = data => {
    data = Array.isArray(data) ? data : [];
    let tree = data?.map(value => {
      return {
        title: value?.name,
        key: value?.id,
        parent: value?.parentId,
        // dataIndex:value?.positions?.name,
        disabled: false,
        children: this.getTreeData_1(value?.children)
      }
    });
    return tree
  }


  //递归
  getTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(value => {
      return {
        title: value?.nodeExtend?.name,
        key: value?.nodeExtend?.id,
        parent: value?.id,
        // dataIndex:value?.positions?.name,
        disabled: false,
        children: this.getTreeData(value?.children)
      }
    })
    return tree
  }



  showModals = () => {
    if (this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
    let datas = {
      id: this.state.treeId,
      positionId: this.state.rowData[0].positionId,
      name: this.state.rowData[0].name,
    }


    this.setState({
      visible1: true,
      formdatas: datas
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  showModal = () => {
    let key = this.state.nodeId;

    if (key.length === 0) return message.warning('未选择组织部门',[0.5]);
    this.setState({
      visible: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  //新增
  handleOk = async () => {
    let value = await this.forms.onFinish();
    let params = {
      name: value.name,
      nodeId: this.state.nodeId
    }

    // 新增数据权限
    addPosition(params).then(
      response => {
        response.data.data ? message.success('新增成功',[0.5]) : message.error('新增失败',[0.5])
        this.getData();
        funTraceInfo("权限管理", "新增职位", null, params)
      }
    );
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 0);

  };

  updateOk = async () => {
    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      nodeId: this.state.treeId,
      positionId: this.state.rowData[0].positionId,
    }

    // 修改职位
    updatePosition(params).then(
      response => {
        response.data.data ? message.success('修改成功') : message.error('修改失败',[0.5])
        funTraceInfo("权限管理", "修改职位", this.state.formdatas, params)
        this.getData();
      }
    )
    setTimeout(() => {
      this.setState({ loading: false, visible1: false });
    }, 0);

  };


  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCancels = () => {
    this.setState({ visible1: false });
  };

  showes = () => {
    this.setState({
      visibleadd: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  addOr = async () => {
    this.setState({ loading: true });
    let value = await this.forms.onFinish();
    if(!value) return false;

    let params = {
      name: value.name,
      parentNodeId: this.state?.nodeId?.length===0?null:this.state?.nodeId
    };
    // 新增数据权限
    addOrganizationTree(params).then(
      response => {
        response.data.data ? message.success('新增成功',[0.5]) : message.error('新增失败',[0.5]);
        funTraceInfo("权限管理", "新增组织", null, params);
        this.getTreeNode();
      }
    );
    setTimeout(() => {
      this.setState({ loading: false, visibleadd: false });
    }, 0);

  };

  addOrS = () => {
    this.setState({ visibleadd: false });
  };

  update = () => {
    let key = this.state.nodeId;
    if (key.length === 0) return message.warning('未选择组织部门',[0.5])
    this.setState({
      visible2: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  updateOr = async () => {

    this.setState({
      loading: true,

    });

    var selectedNodes = this.state.selectedNodes[0];
    let value = await this.forms.onFinish();
    let params = {
      id:selectedNodes.key,                   // 组织机构id
      name: value.name,                       // 组织机构名
      parentId:selectedNodes?.parent, // 父id
      type:selectedNodes?.type || null,       // 组织机构类型：公司还是部门
    };
    // 修改职位-新
    updateNew(params).then(
        response => {
          if(response?.data){
            if(response?.data?.responseInfo?.code!==200){
              return message.info('修改失败');
            }
            if(response?.data?.data){
              // loading
              this.getTreeNode();
              funTraceInfo("权限管理", "修改组织结构", this.state.updateDatas, params);
              return message.info('修改成功');
            }else{
              return message.info('修改失败');
            }
          }
        }
    ).catch(()=>{
      message.error('修改失败')
    })

    // 修改职位-旧
    // updateOrganizationTree(params).then(
    //   response => {
    //     response.data.data ? message.success('修改成功') : message.error('修改失败',[0.5])
    //     funTraceInfo("权限管理", "修改组织结构", this.state.updateDatas, params)
    //     this.getTreeNode();
    //
    //   }
    // )
    setTimeout(() => {
      this.setState({
        loading: false,
        visible2: false,
      });
    }, 2000);
  };

  updateOrS = () => {
    this.setState({ visible2: false });
  };

  deleteOrS=()=>{
    let key = this.state.nodeId
    if (key.length === 0) return message.error('未选择组织部门')
    if(this.state.chi.length === 0 ){
      let param = {
        organizationId: this.state.treeId,
        nodeId: key,
        organizationName:this.state.updateDatas
      }
      Modal.confirm({
        title: `是否确认删除该组织部门？`,
        onOk: () => {
          // 删除数据权限
          deleteOrganizationTree(param).then(
            response => {
              this.setState({
                loading: false,
                refreshTable: false,
              });
              // if(response.data?.data){
              //   message.success('删除成功',[0.5])
              //   this.getTreeNode();
              //   // funTraceInfo("权限管理", "删除组织结构", param, null)
              // }else{
              //   message.error('删除成功'+response.data.responseInfo.description,[0.5]);
              //   this.getTreeNode();
              // }
              message.success(response?.data?.responseInfo?.description,[0.5])
              this.getTreeNode();
            }
          ).catch(
            () => {
              this.setState({
                loading: false,
                refreshTable: false,
              });
              message.error('删除失败', [0.5]);
              this.getTreeNode();
            }

          )
        }
      })
    }else{
      return message.error('请先对下面的组织部门进行删除进行删除',[0.5])
    }
  }

  render() {

    const { loading, visible, visible1,visibleadd,visible2 } = this.state;
    return (
      <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
        <div id={'equipment_frame'}>
          <div className={'left_frame'}>
            <div className={'particulars_info_dives'}>
              <span className="title">组织结构：</span>
                <div className={'buttoner'}>
                <Radio.Group defaultValue="" buttonStyle="solid">
                <Button type="primary" onClick={this.showes} >新增组织</Button>

                <Modal
                      visible={visibleadd}
                      title="新增组织结构"
                      onOk={this.addOr}
                      onCancel={this.addOrS}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={this.addOr}
                        >
                          提交
                        </Button>,
                        <Button key="back" onClick={this.addOrS}>
                          返回
                        </Button>
                      ]}
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenteres}
                        data={this.state.data}
                      />
                    </Modal>


                </Radio.Group>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio.Group>

                <Button  onClick={this.deleteOrS} >注销组织</Button>
                </Radio.Group>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio.Group>
                <Button   onClick={this.update} >修改组织</Button>
                <Modal
                  visible={visible2}
                  title="修改组织结构"
                  onOk={this.updateOr}
                  onCancel={this.updateOrS}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={this.updateOr}
                    >
                      提交
                    </Button>,
                    <Button key="back" onClick={this.updateOrS}>
                      返回
                    </Button>
                  ]}
                >
                  <FormsTwo
                    ref={e => this.forms = e}
                    formParamenter={this.state.formParamenteres}
                    data={this.state.updateDatas}
                  />
                </Modal>

                </Radio.Group>
                </div>

                <Tree
                    treeData={this.getTreeData_1(this.state.tree.treeData)}// 新
                  // treeData={this.getTreeData(this.state.tree.treeData)}// 旧
                  getTreeNode={this.getTreeNode}
                  onExpand={this.onExpand}
                  expandedKeys={this.state.expandedKeys}
                  autoExpandParent={this.state.autoExpandParent}
                  onCheck={this.onCheck}
                  checkedKeys={this.state.checkedKeys}
                  onSelect={this.onSelect}
                />

            </div>
          </div>

          <div className={'right_frame'}>
            <div className={'nTable_info_div'} >
              <div className={'poss'}>
                <Tables
                    columnMaxWith={'50%'}
                  tableParamenter={this.state.tableParamenter}
                  setTableValue={this.setTableValue} //设置表格显示
                  exportTable={this.exportTable}   // 导出时触发的方法
                  refreshTable={this.refreshTable}  // 刷新时触发的方法
                  onSelectChange={this.onSelectChange} // 选中多选框时触发的方法
                  loading={{
                    loading: this.state.loading,
                    refreshTable: this.state.refreshTable
                  }}
                  id={'positionId'}
                >

                  <Radio.Group defaultValue="" buttonStyle="solid">
                    <Button type="primary" onClick={this.showModal} >新增职位</Button>
                    <Modal
                      visible={visible}
                      title="新增职位"
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={this.handleOk}
                        >
                          提交
                        </Button>,
                        <Button key="back" onClick={this.handleCancel}>
                          返回
                        </Button>
                      ]}
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenter}
                        data={this.state.data}
                      />
                    </Modal>

                  </Radio.Group>

                  <Button onClick={this.deletePost}>注销职位</Button>
                  <Radio.Group>
                    {/* <Button    onClick={this.deletePost}>注销职位</Button> */}
                    <Button type="info" onClick={this.showModals}>修改职位</Button>
                    <Modal
                      visible={visible1}
                      title="修改职位"
                      onOk={this.updateOk}
                      onCancel={this.handleCancels}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={this.updateOk}
                        >
                          提交
                        </Button>,
                        <Button key="back" onClick={this.handleCancels}>
                          返回
                        </Button>
                      ]}
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenter}
                        data={this.state.formdatas}
                      />
                    </Modal>


                  </Radio.Group>

                </Tables>
              </div>

            </div>

          </div>
        </div>

      </Layout>
    )
  }

}
