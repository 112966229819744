import React from 'react';
import {Input, Layout, message,} from 'antd'
import {doLogin} from "../../services/auth";

import moment from "moment";
class loginPage extends React.Component {
    state = {
        titleInfo:this.props?.location?.state?.titleInfo??null
    };
    componentDidMount(): void {
        var urlObject = new URLSearchParams(window.location.search);
        var account = urlObject.get('account');
        console.log(account);
        var password = urlObject.get('password');
        console.log(password);
        this.onFinish(account,password);

    }

    onFinish = (account,password) => {
        if(!account && !password){
            console.log('失败');
            return false;
        }
        console.log('成功');
        doLogin({
            "account": account,
            "password": password
        }).then(
            response => {
                if(response.data?.data){
                    var urlObject = new URLSearchParams(window.location.search);
                    var account = urlObject.get('account');

                    this.props.history.replace('/customerDataPanel?' +
                        'id='+ urlObject.get('id') +
                        '&customerId='+ urlObject.get('customerId') +
                        '&customerName='+ urlObject.get('customerName') +
                        '&principalPhone='+ urlObject.get('principalPhone') +
                        '&type='+ urlObject.get('type')
                    )
                }else{
                    message.error('登入错误:'+response.data?.responseInfo.description)
                }
            }
        ).catch(
            e => message.error("服务器异常")
        )
    };

    render() {
        return (
            <div style={{ width: '100%', height: '100%' }}>

                <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                    {/*<input type="file" id="fileInput" />*/}
                    <Input type='file'  onChange={(e)=>{
                        const file = e.target.files[0]; // 获取用户选定的文件

                        console.log( e);
                        if (!file) {return;}
                        const reader = new FileReader();
                        console.log(reader);
                        reader.onload = function(e) {

                            const content = e.target.result;
                            console.log(content);
                        };
                        reader.onerror = function(e) {console.error("File could not be read! Code " + e.target.error.code);};
                        reader.readAsText(file); // 读取文件内容为文本

                    }} />
                </Layout>
            </div>
        );
    }
}
export default loginPage;
