import React from 'react';

import * as echarts from 'echarts';

// 带背景色的柱状图
class BarwithBackground extends React.Component {
    componentDidUpdate(num){
        this.elementById();
    }

    componentDidMount() {
        this.elementById();
    }
    elementById= async =>{
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");

        chartDom.setOption(
            {
                // 边距
                grid: {
                    top:'0%',
                    left: '-11%',
                    right: '10%',
                    bottom: '-20%',
                    containLabel: true
                },
                xAxis: {
                    min:this.props.number.min,     // 最小值
                    max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    axisLabel:false,
                    show: false
                },
                yAxis: {
                    type: 'category',
                    data: [ '今年'],
                    show: false,
                    // axisLine:{
                    //     lineStyle:{
                    //         color:"#fff"
                    //     }
                    // }

                },
                series: [
                    {
                        data: [this.props.data],
                        type: 'bar',
                        showBackground: true,
                        label: {
                            show: true,
                            position: 'insideLeft',
                            offset: [0, 1],
                            textStyle: {
                                //数值样式，显示的文字大小和颜色
                                fontSize: '12',
                                color: '#000',
                                // fontWeight: 400,
                                // lineHeight: '18',
                            },
                        },
                        itemStyle: {
                            normal: {
                                //这里是颜色
                                color: this.props.background.color1
                            }
                        },
                        //柱形图条纹内嵌颜色
                        backgroundStyle: {
                            color:  this.props.background.color2
                        }
                    }
                ]
            })
    };


    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '30%'}}/>
        );
    }
}

export default BarwithBackground;
