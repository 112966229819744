import React, { Component } from 'react'
import {Button, Image, Layout, message, Modal, Popconfirm, Tag, Upload} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {TYPE_NAME,FORM_ITEM} from "../../components/Const";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {handleCheckResult, pageHiddenDanger, querySecurityAssessment} from "../../services/meter";
import {NavLink} from "react-router-dom";
import FormsTwo from "../../components/common/forms/FormsTwo";
export default class hiddenDangerList extends Component {
    state = {

        topFormItem: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    rules: FORM_ITEM.INPUT,
                    variableName: 'customerName',
                    str: '客户名称',
                },
                {
                    rules: FORM_ITEM.SELECT,
                    variableName: 'state',
                    str: '状态',
                    data:[
                        {key:"已整改",value:'已整改'},
                        {key:"未整改",value:'未整改'},
                    ]
                },
                {
                    rules: FORM_ITEM.SELECT,
                    variableName: 'level',
                    str: '级别',
                    data:[
                        {key:"I",value:'I'},
                        {key:"II",value:'II'},
                        {key:"III",value:'III'},
                    ]
                },
                {
                    rules: FORM_ITEM.SELECT,
                    variableName: 'classification',
                    str: '类型',
                    data:[
                        {key:"安检",value:'安检'},
                        {key:"自查",value:'自查'},
                    ]
                },
            ]
        },

        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                customerName: '客户名称',
                type: '隐患',
                classification: '类型',
                level: '级别',
                state: '状态',
                edit: '操作',
            },
            params:{
                page:1,
                size:12
            },
            // 条件选择器参数
            // conditionsParamenter: {
            //     // 条件
            //     parameter: [
            //         {
            //             type: TYPE_NAME.NAME,
            //             variableName: 'name',
            //             str: '客户名称',
            //             initData: JSON.parse(localStorage.getItem('customer'))?.name
            //         },
            //         {
            //             type: TYPE_NAME.SELECT,
            //             variableName: 'state',
            //             str: '状态',
            //             initData: JSON.parse(localStorage.getItem('customer'))?.businessCooperationNum,
            //             data:[
            //                 {key:"已处理",value:'已处理'},
            //                 {key:"未处理",value:'未处理'},
            //             ]
            //         },
            //         {
            //             type: TYPE_NAME.SELECT,
            //             variableName: 'level',
            //             str: '级别',
            //             initData: JSON.parse(localStorage.getItem('customer'))?.businessCooperationNum,
            //             data:[
            //                 {key:"一般",value:'一般'},
            //                 {key:"重大",value:'重大'},
            //                 {key:"特重大",value:'特重大'},
            //             ]
            //         },
            //         {
            //             type: TYPE_NAME.SELECT,
            //             variableName: 'classification',
            //             str: '类型',
            //             initData: JSON.parse(localStorage.getItem('customer'))?.businessCooperationNum,
            //             data:[
            //                 {key:"安检",value:'安检'},
            //                 {key:"自查",value:'自查'},
            //             ]
            //         },
            //     ]
            // },
        },

        modalVisible_1:false,   // 弹窗—2查看隐患图片
        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [],           // 设备照片

    };

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'edit':
                return<>
                    {rowData.state==='已整改'?
                        <div>
                            <u style={{cursor: 'pointer',color:'rgba(0,0,0,0.41)'}}
                               onClick={()=>{
                                   this.setState({
                                       modalVisible_1:true,
                                       selectRowData_1:rowData, // 当前选择表格行数据
                                       fileList:rowData?.photos,// 图片初始化
                                   });
                               }}
                            >查看</u>
                        </div>
                        :
                        <div  title={'未处理'} style={{cursor: 'pointer'}}
                              onClick={()=>{
                                  this.setState({
                                      modalVisible_2:true,
                                      selectRowData_1:rowData,// 当前选择表格行数据
                                      fileList:[],            // 图片初始化
                                  });
                              }}
                        >
                            <u style={{color:'red'}}>处理</u>
                        </div>
                    }
                    {/*<div className={'tableLink'} title={'处理'}*/}
                    {/*     onClick={async ()=>{*/}
                    {/*         console.log('---------------');*/}
                    {/*         console.log(rowData);*/}
                    {/*         this.setState({fileList:rowData?.photos||[],modalVisible_1:true})*/}
                    {/*     }}*/}
                    {/*>*/}
                    {/*    <u >{'查看'}</u>*/}
                    {/*</div>*/}
                    {/*<div className={'tableLink'} title={'忽略'}>*/}
                    {/*    <Popconfirm title={'是否忽略'}*/}
                    {/*                onConfirm={()=>{*/}
                    {/*                    console.log('是否忽略');*/}
                    {/*                    this.removeCusProperties(rowData.id);*/}
                    {/*                }}*/}
                    {/*                okText="是" cancelText="否">*/}
                    {/*        <a>{'忽略'}</a>*/}
                    {/*    </Popconfirm>*/}
                    {/*</div>*/}
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 表格查询
    getPageHiddenDanger=async ()=>{
        var params = await this.forms.onFinishNotReset();
        // if(!params){return false}
        params.size=this.state.tableParamenter.params.size;
        params.page=this.state.tableParamenter.params.page;
        this.table.refreshTable(params);
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList:file.fileList});
    };
    render() {
        const uploadButton = (
            <div>
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (
            <Layout className={'item_body_number_back'}>
                <div style={{width:'100%',height:'98%',backgroundColor:'#fff'}}>
                    <div style={{width:'100%',height:'50px',padding:"10px 10px 0 10px"}}>
                        <div style={{width:'80%',height:'100%'}}>
                            <div style={{width:'85%',height:'100%',float:'left'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem}
                                    data={this.state.searchGetData}
                                />
                            </div>
                            <div style={{width:'15%',height:'100%',float:'left',paddingLeft:'10px'}}>
                                <div style={{float:'left'}}><Button type="primary" onClick={()=>{this.getPageHiddenDanger()}} >查询</Button></div>
                                <div style={{float:'left'}} onClick={()=>{this.forms.onModalClose()}}><Button>清空</Button></div>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',padding:"0 5px 0 5px"}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData={pageHiddenDanger}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        />
                    </div>
                </div>
                {/*图片---------------------------------------------------------------------------------------------------------------*/}
                <Modal
                    title={'查看'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_1}
                    // okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={()=>{this.setState({modalVisible_1:false})}}
                    // 点击取消
                    onCancel={()=>{this.setState({modalVisible_1:false})}}
                    width={600}
                    centered
                >
                    {this.state?.fileList.map(one=>{return<><Image width={105} src={one.path}/></>})}
                </Modal>

                <Modal
                    title={"图片上传"}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_2}
                    okText="确定"
                    onOk={() => {
                        var params={
                            "hiddenDangerId": this.state.selectRowData_1?.id,// 选择的表格数据id
                            "photos": []// 图片数据
                        };
                        this.state.fileList.map(one=>{
                            params.photos.push(
                                {
                                    "customerId": this.state.selectRowData_1?.customerId,
                                    "path": one.response,
                                    "recordId": this.state.selectRowData_1.id,
                                }
                            )
                        });
                        if(this.state.fileList.length===0){return false;}
                        handleCheckResult(params).then(
                            response=>{
                                if(response?.data?.data){
                                    message.info('提交成功');
                                    this.setState({modalVisible_2:false});
                                    this.getPageHiddenDanger();   // 表格数据
                                }else{
                                    message.info('提交失败')
                                }
                            }
                        );
                    }}           	// 点击确定
                    onCancel={() => this.setState({modalVisible_2:false})}	// 点击取消
                    width={900}
                    centered
                >
                    <div>
                        <Upload
                            action={this.state.fileUrl}
                            listType="picture-card"
                            name={"file"}
                            fileList={this.state.fileList}
                            onPreview={this.handlePreview}
                            onChange={this.onChangeUpload}
                        >
                            {
                                // this.state.fileList.length >= 4 ? null :
                                uploadButton}
                        </Upload>
                    </div>
                </Modal>

            </Layout>
        )
    }
}
