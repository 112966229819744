import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Button, Switch, DatePicker} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
import $ from 'jquery'
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel,detailedAnalysisList,getLevelById,listDevByAreaIds} from '../../../services/steamReport'
import {getDateByUserId} from "../../../services/admin";
const { RangePicker } = DatePicker;
echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;
var downIp =global.pubData.url;


export default class PointManagement extends Component {

    state = {

		dateError:'',// 时间框样式控制
		dates:{},// 保存现在选择的时间数据
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				pointName: '客户名称',
				modelName: '表具型号',
				instFlow: '瞬时流量(吨)',
				frequency:'频率',
				temp: '温度(℃)',
				pre: '压力(MPa)',
				density: '密度(kg/m³)',
				consumption: '累计流量(吨)',
				// communicateTime: '通信时间',
				dataTime: '数据时间',
			},
			// 弹出框
			headers: {
				pointName: '客户名称',
				modelName: '表具型号',
				instFlow: '瞬时流量(吨)',
				temp: '温度(℃)',
				pre: '压力(MPa)',
				density: '密度(kg/m³)',
				consumption: '累计流量(吨)',
				// communicateTime: '通信时间',
				dataTime: '数据时间',
			},
			params: {
				areaIds:[289],
				granularity:4,
				startTime:'',
				endTime:'',
				itemIdList:[1182,1184,1205,1150,1206,1204],
				page:1,
				size:10,
				jumper:true
			},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					isMultiple:true,
                    disabled: false,
					data:[],
					type: 'select',
				},
				{
					type: 'select',
					variableName: 'granularity',
					str: '时间类别',
					data: [
						{
							key: '1',
							value: '时'
						},
						{
							key: '2',
							value: '日'
						},
						{
							key: '3',
							value: '月'
						},
						{
							key: '5',
							value: '峰谷'
						}
					]
				},
				// {
				// 	type: 'rangePicker',
				// 	variableName: 'createTime',
				// 	time: false,
				// 	str: '时间'
				// },

			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					fieldNames:{label: 'name', value: 'id', children: 'children'},
					rules: TYPE_NAME.TREE_SELECT,
					disabled: this.props.location.state ? true : false,
					data:[],
					onChange: (k,v) => this.getAllDev(k,v)
				},
				{
					variableName: 'devIds',
					str: '设备选择',
					// isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECTINPUT,
				},
				// {
				// 	variableName: 'granularity',
				// 	str: '时间类别',
				// 	rules: TYPE_NAME.SELECTINPUT,
				// 	data: [
				// 		{
				// 			key: '1',
				// 			value: '日'
				// 		},
				// 		{
				// 			key: '2',
				// 			value: '月'
				// 		},
				// 		{
				// 			key: '3',
				// 			value: '年'
				// 		}
				// 	]
				// },
				// {
				// 	variableName: 'createTime',
				// 	str: '时间范围',
				// 	rules: TYPE_NAME.RANGE_REQ_DATE_PICKER,
				// },

			]
		},
		pageNumFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'pageNo',
					str: '页码',
					rules: TYPE_NAME.INPUT
				}
			]
		},
		searchGetData:{},
		    	// 总条数
		totalCount: 0,
		refBl:false,
		ckVal:false
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'instFlow':
				text = rowData.instFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'temp':
				text = rowData.temp*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'pre':
				text = rowData.pre*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'density':
				text = rowData.density*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'totalFlow':
				text = rowData.totalFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		let user = JSON.parse(sessionStorage.getItem("user"))
		// getDataIgnoreLevel({userId:user.id}).then(
		// 	response => {
		// 		const {data} = response.data
		// 		let selectArry=[]
		// 		if(data){
		// 			for(var i=0;i<data.length;i++){
		// 				selectArry.push({key:data[i].id,value:data[i].name})
		// 			}
		// 		}
		// 		this.state.topFormItem.parameter[0].data=selectArry;
		// 	}
		// )
		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
			response => {
				if (response?.data) {
					console.log(response?.data);
					this.state.topFormItem.parameter[0].data=response?.data;
				} else {
					return message.info('获取不到数据');
				}
			}
		)
		this.countdown = setInterval(this.getData, 30000);
	}
	getAllDev = (areaIds) => {
		var treeAreaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,areaIds,treeAreaIds);
		listDevByAreaIds({
			// areaIds:[areaIds]
			areaIds:treeAreaIds
		}).then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devId,value:data[i].devName})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)
	};
	forTreeArea = (data,key,areaIds=[]) =>{
		data.map(one=>{
			if(one.id===key){
				areaIds.push(one.id);
				if(one?.children){
					this.addAllTreeArea(one?.children,areaIds);
				}
			}else if(one?.children){
				this.forTreeArea(one?.children,key,areaIds);
			}
		});
		return areaIds;
	};
	addAllTreeArea = (data,areaIds=[]) =>{
		data.map(one=>{
			areaIds.push(one.id);
			if(one?.children){
				this.addAllTreeArea(one?.children,areaIds);
			}
		});
		return areaIds;
	};
	componentWillUnmount() {
		clearInterval(this.countdown);
	}
	onChangeSelect = (e) => {
		this.state.tableParamenter.params.areaIds=[e]
		this.setState({})
	}


	reportExport = async ()=>{
		let data = await this.forms.onFinishNotReset();
		if(!data) return null
		// let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		// let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let sTime=this.state?.dates?.startTime?this.state?.dates?.startTime : null;
		let eTime=this.state?.dates?.startTime?this.state?.dates?.endTime : null;
		window.open(downIp+'/web/reportExportCtrl/exportDetailedAnalysisList?areaIds='+data.areaIds+'&ajaxFileName=明细报表&itemIdList=1182,1184,1205,1150,1206,1204&granularity=4&startTime='+sTime+'&endTime='+eTime+'&devIds='+data.devIds)
	};

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		if(!data | !this.state?.dates?.startTime){
			if(!this.state?.dates?.startTime){this.setState({dateError:'1px solid #f5222d'});}
			return null;
		}

		// let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		// let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let sTime=this.state?.dates?.startTime?this.state?.dates?.startTime : null;
		let eTime=this.state?.dates?.startTime?this.state?.dates?.endTime : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,value);
		params.areaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,data.areaIds,params.areaIds);
		// params.areaIds=[data.areaIds]
		params.devIds=[data.devIds];
		params.granularity=4
		if(sTime){
			params.startTime=sTime;
		}
		if(eTime){
			params.endTime=eTime;
		}
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		this.table.refreshTable(params);
		console.log(this.state.tableParamenter.params)

	};

	onChange = checked => {
		console.log(`switch to ${checked}`);
		this.state.refBl=checked;
		this.setState(this.state.params)
	  }
	onCheck = checked => {
		this.state.ckVal=checked;
	}
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaId = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	let params = this.state.tableParamenter.params
	if(this.state.refBl==true)
	{
		this.setState({})
		this.table.refreshTable(params);
	}
  }

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	};
	// 日期选择
	getRangePicker=()=>{
		return<>
			<div style={{width: '100%'}}>
				<div style={{width: '100%'}}>
					<div  style={{width: '18%',float:'left',display:'inline-block'}}>
						<nobr  style={{color:'red'}}>*</nobr>
						<nobr  style={{fontSize:'16px'}}>时间范围:</nobr>
					</div>
					<div style={{width: '72%',float:'left'}}>
						<RangePicker
							// size="small"
							style={{border: this.state.dateError}}
							format={"YYYY-MM-DD HH:mm"}
							showTime={{ format: 'HH:mm' }}
							onChange={(d,v)=>{
								this.upDate(d,v)
							}}
						>
						</RangePicker>
					</div>
				</div>
				<div style={{paddingLeft:'72px',color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
			</div>
		</>;
	};
	upDate=(d,v=[])=>{
		// 检查参数
		this.setState({dateError:''});
		let params = this.state.dates;
		params.startTime=v[0];
		params.endTime=v[1];
		this.setState({dates:params});
		if(v[0]===""||v[1]===""){
			this.setState({dateError:'1px solid #f5222d'});
			return message.info('请选择时间范围');
		}
	};

    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff'}}>
				<div className={'topSearchBox'}>
				<h3>明细报表</h3>
				<div style={{position:'relative'}}>
						<div style={{width:'90%'}}>
							<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.topFormItem}
								data={this.state.searchGetData}
							/>
						</div>
					<div style={{position:'absolute', left:'48%',top:'0'}}>
						{this.getRangePicker()}
					</div>
						<Button style={{position:'absolute', right:'100px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						<Button style={{position:'absolute', right:'0',top:'0'}} onClick={() => this.reportExport()}>导出</Button>
				</div>
				</div>

				<div style={{width:'100%',float:'left'}}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {detailedAnalysisList}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
					</TableComponents>
				</div>
				</div>
            </Layout>
        )
    }
}
