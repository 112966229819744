import React,{Component} from "react";
import {Button, Cascader, DatePicker, Form, Layout, message, Select, Space, Spin, Tag} from "antd";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getPageCustomerName} from "../../../services/customer";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import moment from "moment";
import {queryCustomerPointValuePage} from "../../../services/report";
import Echart1 from '../js/echart_1'
import './analysis.less'
import {getDataIgnoreLevel} from "../../../services/steamReport";
const { RangePicker } = DatePicker;
const { Option } = Select;
// 圆角环形图
// 多客户用量对比
export default class dataAnalysis extends Component{
    state={
        areaInfos:[],       // 所有区域详细数据
        customerDatas:[],   // 客户数据集
        formSelect:[],      // 被选用户下拉框
        deleSelectOne:null, // 删除选择的数据
        selectDate:null,    // 时间选择类型
        dateDates:null,
        selectOneData:null,
        cascaderStatus_1:'',
        juder:true,
        echartTitle:<div><span>请配置参数查询图表</span></div>,  // 对图表的提示内容控制
        // 时间集
        dates:{
            startTime:null,
            endTime:null
        },
        dateError:'',// 时间框样式控制
        value:null,
        // 柱状图数据
        echartData:{
            type:'bar', // line折线 bar柱状
            head:[],// 日期
            data:[]
        },

        // 控制器表格参数
        tableParamenter_1: {
            manageName: "客户报表管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系电话',
                addressInfo: '详细地址',
                // g: '用气量数量',
                // h: '子客户数',
                // j: '通信时间',
                edit: '操作',
            },

            rowData:[],
            params: {
                areaIds:[],
                page:1,
                size:5,

            },
            // 是否绑定左侧区域
            bindArea: false,
        },


        // 条件选择器参数_1
        topFormItem_1: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds', str: '区域选择', rules: TYPE_NAME.SELECTINPUT, data:[],
                    onChange: (k,v) => {
                        this.areaIdsSelect(k,v);
                    },
                },
                {
                    variableName: 'name',
                    str: '客户名称',
                    rules: TYPE_NAME.INPUT,
                },
                {
                    variableName: 'id',
                    str: '客户编号',
                    rules: TYPE_NAME.INPUT,
                },
                {
                    variableName: 'addressInfo',
                    str: '客户地址',
                    rules: TYPE_NAME.INPUT,
                }
            ]
        },
        topFormItem_data_1:[],

        // 条件选择器参数_2
        topFormItem_2: {

        },
        topFormItem_data_2:[]
    };



    recursionAreas=(areaId)=>{
        var areaIds=[];
        for(var i=0;i<this.state.areaInfos.length;i++){
            if(this.state.areaInfos[i].parentId===areaId){
                areaIds.push(this.state.areaInfos[i].id);
                var params=[];
                params = this.recursionAreas(this.state.areaInfos[i].id);
                params?.map(one=>{areaIds.push(one)})
            }
        }
        return areaIds
    };

    areaIdsSelect= (k,v) => {
        var tableParamenter_1 = this.state.tableParamenter_1;
        tableParamenter_1.params.areaIds=[k];
        var areaIds = this.recursionAreas(k);
        areaIds.map(one=>{tableParamenter_1.params.areaIds.push(one);});
        this.setState({tableParamenter_1: tableParamenter_1});
        this.table.refreshTable(tableParamenter_1.params);
    };

    // 初始化地图加载
    componentDidMount() {
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                let areaIds =[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        selectArry.push({key:data[i].id,value:data[i].name});
                        areaIds.push(data[i].id)
                    }
                }
                var tableParamenter = this.state.tableParamenter_1;
                tableParamenter.params.areaIds=areaIds;
                this.state.topFormItem_1.parameter[0].data=selectArry;
                this.setState({tableParamenter_1:tableParamenter});
                this.table.refreshTable(this.state.tableParamenter_1.params);
            }
        );
    }
    // 表格-查询
    getConditionsValue= async value =>{
        let data = await this.forms.onFinishNotReset();
        if(!data){return false}
        var tableParamenter = this.state.tableParamenter_1;
        tableParamenter.params.name=data.name;
        tableParamenter.params.id=data.id;
        tableParamenter.params.addressInfo=data.addressInfo;
        await this.table.refreshTable(tableParamenter.params);
    };

    // 添加进入客户下拉框
    addUserSelect=(rowData)=>{
        console.log('添加进入客户下拉框');
        var formSelect = [...this.state.formSelect];
        var customerDatas = this.state.customerDatas;
        if(formSelect.length>4){
            return message.info('超出最大加载量');
        }
        customerDatas.push(rowData);

        formSelect.push([rowData.name]);
        this.setState({formSelect:formSelect,cascaderStatus_1:'',customerDatas:customerDatas});
    };



    // 表格-样式
    setTableValue=(value,text,rowData)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'addressInfo':
                return <div className={'table_overflow'} title={'地址'}>{
                    (rowData.province?rowData.province:'')
                    +(rowData.city?rowData.city:'')
                    +(rowData.district?rowData.district:'')
                    +(rowData.street?rowData.street:'')
                }</div>;
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'comTime':
                return text?moment(text).format("YYYY-MM-DD HH:mm:ss"):'-';
            case 'edit':
                var juder=true;
                for(var i=0;i<this.state.formSelect.length;i++){
                    if(this.state.formSelect[i][0]===rowData.name){
                        juder=false;
                    }
                }
                if(juder){
                    return<>
                        <div className={'tableLink'} title={'加入对比'}
                            // 添加进入客户下拉框
                             onClick={()=>{this.addUserSelect(rowData);}}
                        >
                            <u>{'加入对比'}</u>
                        </div>
                    </>;
                }

            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 表单数据
    onFinish_1=(value)=>{
        console.log('表单数据');
        var dates = this.state.dates;   // 日期框样式
        if(!dates.startTime){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
        }
        var customerDatas = this.state.customerDatas;
        if(customerDatas.length===0){
            this.setState({cascaderStatus_1:'error'});
            return message.info('请选择客户');
        }
        var params={
            "itemIdList":[],
            "startTime": dates.startTime,       // 开始时间
            "endTime": dates.endTime,           // 结束时间
            "granularity": value.dateType,      // 1:时，2:日，3：月，4：明细分析
            "customerIds": [],   // 客户列表
            "page": 1,
            "pointIds": [],
            "size": 1000,
        };
        customerDatas.forEach(one=>{
            params.customerIds.push(one.id)
        });
        // var echartData = this.state.echartData;// 图表
        // if(echartData.data.length>4){
        //     return message.info('对比数量超过加载量');
        // }

        if(customerDatas.length>5){
            return message.info('对比数量超过加载量');
        }

        this.setState({juder:true,echartTitle:<div><Spin/><span>加载中</span></div>});
        queryCustomerPointValuePage(params).then(
            response=> {
                const {data} = response.data;
                if (data) {
                    // 图表数据
                    var echartData = this.state.echartData;
                    echartData.head = [];
                    echartData.data = [];
                    // 配置表头
                    for (var i = 0; i < data.length; i++) {
                        if (data[i]?.reportList) {
                            for (var a = 0; a < data[i]?.reportList.length; a++) {
                                echartData.head.push(data[i]?.reportList[a].time)
                            }
                        }
                        break;
                    }
                    // 配置数据
                    for (var i = 0; i < data.length; i++) {
                        var param = {
                            label: data[i]?.name,
                            values: [],
                        };
                        if (data[i]?.reportList.length !== 0) {
                            for (var a = 0; a < data[i]?.reportList.length; a++) {
                                param.values.push(data[i]?.reportList[a].value || 0);
                            }
                        } else {
                            for (var q = 0; q < echartData.head.length; q++) {
                                param.values.push(0);
                            }
                        }
                        echartData.data.push(param);// 添加数据
                    }

                    return this.setState({echartData: echartData,juder:false});
                } else {
                    message.info("暂无数据");
                    this.setState({echartTitle:<div><span>暂无数据</span></div>})
                }
                this.setState({juder:true});
            }
        );

    };
    // 日期选择
    getRangePicker=()=>{
        // return<>
        //     <div>
        //         <div>
        //             <RangePicker
        //                 key={this.state.timeSelect}
        //                 size="small"
        //                 style={{border: this.state.dateError}}
        //                 showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
        //                 format={this.state.selectDate==='1'?"YYYY-MM-DD HH":
        //                     this.state.selectDate==='2'?"YYYY-MM-DD":
        //                         this.state.selectDate==='3'?"YYYY-MM":"YYYY-MM-DD HH"
        //
        //                 }
        //                 onChange={(d,v)=>{this.upDate(d,v)}}
        //             >
        //             </RangePicker></div>
        //         <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
        //     </div>
        // </>;
        switch (this.state.selectDate) {
            case "3":
                return<>
                    <div>
                        <div>
                            <RangePicker
                                key={this.state.timeSelect}
                                size="small"
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={"YYYY-MM"}
                                picker={"month"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
            default:
                return<>
                    <div>
                        <div>
                            <RangePicker
                                key={this.state.timeSelect}
                                size="small"
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={this.state.selectDate==='1'?'YYYY-MM-DD HH':"YYYY-MM-DD"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
        }
    };
    upDate=(d,v=[])=>{
        // 检查参数
        this.setState({dateError:''});
        let params = this.state.dates;
        params.startTime=v[0];
        params.endTime=v[1];
        this.setState({dates:params});
        if(v[0]===""||v[1]===""){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
        }
    };



    render() {

        return (

            <Layout  className={'item_body_number_back tables'}>
                <div>
                    {/*----------------上-----------------*/}
                    <div className={'topSearchBox'}>
                        <h3>多客户用量对比</h3>
                        <div style={{position:'relative'}}>
                            <div style={{width:'90%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem_1}
                                    data={this.state.topFormItem_data_1}
                                />
                            </div>
                            <Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                        </div>
                    </div>
                    {/*----------------上END---------------*/}

                    {/*-----------------中----------------*/}
                    <div style={{widht:'100%',height:'400px',padding:'10px 0 0 0',background:'#fff'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter_1}
                            setData = {getPageCustomerName}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        />
                    </div>
                    {/*-----------------中END--------------*/}

                    {/*-----------------下----------------*/}

                    <div style={{widht:'100%',height:'315px',backgroundColor: '#fff',padding:'10px'}}>
                        <div style={{width: '100%',height:'50px' }}>
                            <div style={{width: '30%',float:'left',paddingTop:'4px'}}>
                                <span >对比客户列表:</span>
                                <Cascader
                                    status={this.state.cascaderStatus_1}
                                    style={{ width: '70%',marginLeft:'5px'}}
                                    size="small"
                                    value={this.state.formSelect}
                                    multiple
                                    onChange={(value)=>{
                                        var customerDatas = this.state.customerDatas;
                                        // 如果为空直接清空
                                        if(value.length===0){
                                            customerDatas=[];
                                        }else{
                                            for(var one=0;one<customerDatas.length;one++){
                                                var juder=true;
                                                for(var two=0;two<value.length;two++){
                                                    if(value[two][0]===customerDatas[one].name){
                                                        juder=false;
                                                    }
                                                }
                                                if(juder){
                                                    customerDatas.splice(one,1)
                                                }
                                            }
                                        }

                                        this.setState({formSelect:value,customerDatas:customerDatas})
                                    }}
                                />
                            </div>
                            <div style={{width: '70%',float:'left'}}>
                                <Form
                                    id="form"
                                    name="dynamic_form_nest_item"
                                    onFinish={(value)=>{
                                        this.onFinish_1(value)
                                    }}
                                    style={{width: '100%',height:'50px' }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    <div style={{ width: '250px',float:'left' }}>
                                        <Form.Item label="时间尺度" name="dateType" rules={[{ required: true, message: '请选择时间类型' }]}>
                                            <Select
                                                optionLabelProp="label"
                                                size="small"
                                                placeholder="时间类型"
                                                onChange={(a)=>{this.setState({
                                                    selectDate:a,
                                                    timeSelect: new Date(),
                                                    dates:{}
                                                })}}
                                                maxTagCount={'responsive'}
                                            >
                                                <Option value={'1'} label={'小时'}>小时</Option>
                                                <Option value={'2'} label={'日'}>日</Option>
                                                <Option value={'3'} label={'月'}>月</Option>
                                                <Option value={'5'} label={'峰谷'}>峰谷</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{width: '400px', paddingTop:'4px',float:'left'}}>
                                        {this.getRangePicker()}
                                    </div>
                                    <div>
                                        <Form.Item label=" " colon={false}>
                                            <Button type="primary" htmlType="submit">
                                                对比
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {this.state.juder?
                            <div style={{widht:'100%',height:'85%',textAlign: 'center',backgroundColor:'rgba(222, 222, 222, 0.05)', paddingTop: '80px',
                                fontSize:'18px',  fontWeight: 'bold',color:'#b9b9b9'}} >
                                {this.state.echartTitle}
                            </div>
                            :
                            <div style={{widht:'100%',height:'85%'}}>
                                <Echart1
                                    dataType={this.state.echartData.type}
                                    elementById={'main'}// id
                                    head={this.state.echartData.head}// 日期
                                    data={this.state.echartData.data}// 数值
                                />
                            </div>
                        }
                    </div>
                    {/*-----------------下END--------------*/}
                </div>
            </Layout>
        );
    }
}
