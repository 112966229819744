import React, { Component } from 'react'

import { Tree } from 'antd'
import './trees.less'
import Pubsub from 'pubsub-js'
import {SmileOutlined} from '@ant-design/icons';
const { DirectoryTree } = Tree

class Trees extends Component {

  // 设置数据
  getData = data => {
    let keys = [];

    keys = data?.map(({name,id,children,latitude,longitude}) => {
      return {
        title: name,
        key: id.toString(),
        // icon: <SmileOutlined />,
        children: this.getData(children),
        isLeaf: children?.length > 0 ? false : true,
        latitude: latitude,
        longitude: longitude,
      }
    });
    return keys
  }

  // 对数据进行排序，将同类型数据放在一起，如有子节点、无子节点
  sortData = data => {

    let treeData = this.getData(data);
    return treeData?.sort(val => val.isLeaf ? 1 : -1)
  };

  // 点击节点时触发
  onSelect = (keys, info) => {
    console.log('点击节点时触发');
    console.log(keys);
    console.log(info);

    var params={
      id:info.node.key,
      areaName:info.node?.title,
      children:info.node?.children,
      latitude: info.node?.latitude,
      longitude:info.node?.longitude,
    };
    info.node.children?.forEach(({key}) => keys.push(key));
    // let [name] = [keys[0],info.node.title]
    localStorage.setItem('menus', JSON.stringify(keys ?? null));
    localStorage.setItem('areaName', info.node.title);
    localStorage.setItem('areaInfo', JSON.stringify(params));
    Pubsub.publish('areaIds', keys);
    this.props?.changArea(info.node.title,'#1F1F1F');
  };

  render() {
    let treeData = this.sortData(this.props.data)
    let defaultKey = localStorage.getItem('menus')?.[0]
    defaultKey = defaultKey?.[0] ? defaultKey : [treeData?.[0]?.key]
    let {backgroundColor='#404840'} = this.props.parameter

    return (
      <DirectoryTree
        style={{fontSize: '1rem', color: '#ffffff', backgroundColor: backgroundColor}}
        multiple={false}
        className={'table_overflow'}
        defaultSelectedKeys={defaultKey}
        onSelect={this.onSelect}
        onExpand={this.onExpand}
        treeData={treeData}
      />
    )
  }
}

export default Trees
