
import { Component } from 'react'
import { Layout,Tree, message, Radio,Modal } from "antd";
import { NavLink } from "react-router-dom"
import {getAppOrganizationTree,fuzzyQueryUser,listAppUserByOrganizationId,logOffUser,updateState} from '../../../services/admin'
import '../../../style/luo.less'
import Pubsub from 'pubsub-js'
import React from 'react';
import moment from "moment";
import {TYPE_NAME} from '../../../components/Const'
import Conditions from '../../../components/common/conditions/Conditions'
import Tables from "../../../components/common/tables/Tables";
import {funTraceInfo} from '../../../services/common'
export default class AccountCenter extends Component {

    state={
        // statusAndAlarmData:'左框第三个',  // 左-3文本
        responseInfo:{},
        treeId: [],
        deleteId:[],
            // 表格参数
            tableParamenter: {
              // 表格行是否可选
              rowSelection: true,
              // 表头：变量和变量解释
              header: {
                  company: '公司',
                  username: '用户名',
                  realName: '真实姓名',
                  // organization: '组织机构',
                  appPositionName: '职位',
                  phone: '手机号码',
                  // birthday: '生日',
                  createTime:'创建时间',
                  status: '状态',
                  // address:'地址',
                  // workStatus: '工作状态',
              },
              // 下拉框：变量和变量解释
              headers: {
                company: '公司',
                username: '用户名',
                realName: '真实姓名',
                // organization: '组织机构',
                appPositionName: '职位',
                phone: '手机号码',
                // birthday: '生日',
                createTime:'创建时间',
                status: '状态',
                // address:'地址',
                // workStatus: '工作状态',
               
              },
              // 表格数据
              data: [],
            },// 表格参数END    
        expandedKeys: [],
        checkedKeys:[],
        selectedKeys:[],
        autoExpandParent:[],
        tree: {
          treeData :[
          {
            title: 'name',
            key: 'id',
           
            children: [
              {
                title: 'name',
                key: 'id',
               
                children: [
                  { title: 'name', key: 'id'},
                ],
              },
            ],
          },
        ]
      }, 
      // 条件选择器参数
		conditionsParamenter: {
			// 是否显示条件标题
			isLable: true,
			// 是否显示清空按钮
			clearButton: true,
            
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'username',
					str: '用户名'
				},
				
			]
		},
    	// 总条数
		totalCount: 0,

		params: {
      pageInfo: {
        pageIndex:1,
        pageLine:10,
        
      },
      username: null,
      // organizationId :this.state.treeId
			// areaId: localStorage.getItem('menus')
		},
    // 表格加载动画
		loading: false,
		// 刷新动画
		refreshTable: false,
        rowData: [],
        // data: {}
    };

    async  componentDidMount() {
      this.getTreeNode();
   
    }

    
	getAreaId = () => {
		Pubsub.subscribe('areaIds', (msg, data) => {
			let {params} = this.state
			params.areaIds = data
			params.page = 1
			params.size = 15
			this.setState({params})
		
		})
	}

  // 组件将要被卸载时,取消订阅
	componentWillUnmount() {
		Pubsub.unsubscribe('areaId')
	}



	// 表格：刷新方法
	refreshTable = () => {
		// this.setState({refreshTable: true})
		this.getData();
    this.setState({loading:false})
    // funTraceInfo("权限管理", "刷新账户信息", this.state.params, this.state.tableParamenter.data)
	}

	// 表格：获取勾选中的值
	onSelectChange = data => {
      let ss=data[0].status
    if(ss==='可用') {
      this.setState({rowData: data,deleteId: data})
    }else {
      this.setState({rowData: data,deleteId: data})
      message.warning('该用户已被禁用',[0.5])
    }
	}

	// 表格：导出
	exportTable = () => {
		console.log('数据导出')
	}

	// 条件选择：获取输入的条件
	getConditionsValue = value => {
    let key = this.state.treeId
    if(key.length === 0 ) return message.warning('未选择组织部门',[0.5])
		// let {params} = this.state
		// params.userName = value.userName
    let {params} = this.state
		params.username = value.username
    this.setState({params})
    let param = {
      pageInfo: this.state.params.pageInfo,
      organizationId: this.state.treeId,
      username:this.state.params.username
    }
    console.log('获取选中的值:',param)
    listAppUserByOrganizationId(param).then(
      response => {
        const {data} = response.data 
        if(data === null) {
          message.warning("该条件下不存在用户信息",[0.5])
          let {tableParamenter} = this.state
          tableParamenter.data = data
          const {pageInfo} = response.data 
          let total= pageInfo.total
          this.setState({tableParamenter,totalCount:total})
        }
        let {tableParamenter} = this.state
        tableParamenter.data = data
        const {pageInfo} = response.data 
        let total= pageInfo.total
        this.setState({tableParamenter,totalCount:total})
        message.success('查询成功',[0.5])
        funTraceInfo("权限管理", "模糊查询账户信息", param, tableParamenter.data)
      }
    )

	}



	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
		let {params} = this.state
		params.page = page
		params.size = pageSize
		this.setState({params})
    if(this.state.username == null){
      this.getData()
    }else{
      this.getDatas()
    }
		// this.getData()
	}

  getDatas = () => {
    let param = {
      page:1,
      size:10,
      organizationId: this.state.treeId,
      username:this.state.params.username
    }
    console.log('para',param);
    fuzzyQueryUser(param).then(
      response => {
        const {data} = response.data 
        console.log('22222222222', data);
        const {pageInfo} = response.data 
        console.log('分页111111111111', pageInfo);
        let total= pageInfo.total
       
        let {tableParamenter} = this.state
        tableParamenter.data = data
        this.setState({tableParamenter,totalCount:total})
        message.success('查询成功',[0.5])
        console.log('sssssssss',this.state.totalCount);
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        })
        message.error('查询失败',[0.5])
      }
    )
  }

// 清空条件
clearCondition = () => {
  let {params} = this.state
  params.username = null
  this.setState({params})
  if(this.state.username == null){
    this.getData()
  }else{
    this.getDatas() 
  }
}


    //展开/收起节点时触发
     onExpand = (expandedKeysValue) => {
      console.log('onExpand', expandedKeysValue);
      this.setState({
          expandedKeys: expandedKeysValue,
          autoExpandParent: false
        });
    };  
    
    //点击复选框触发
    onCheck = (checkedKeysValue) => {
      console.log('onCheck', checkedKeysValue);
    //点击树节点触发
      this.setState({
        checkedKeys: checkedKeysValue,
      });
    };

// 初始化表格数据
getData = () => {
  this.setState({loading: true})
  let param = {
    page:1,
    size:10,
    organizationId: this.state.treeId
  }
  console.log('para',param);
  listAppUserByOrganizationId(param).then(
    response => {
      const {data} = response.data 
      console.log('22222222222', data);
      const {pageInfo} = response.data 
      console.log('分页111111111111', pageInfo);
      let total= pageInfo.total
      let {tableParamenter} = this.state
      tableParamenter.data = data
      this.setState({tableParamenter,totalCount:total,loading: false})
      message.success('查询成功',[0.5])
    }
  ).catch(
    () => {
      this.setState({
        loading: false,
        refreshTable: false,
      })
      message.error('查询失败',[0.5])
    }
  )
}
    //点击树节点触发
     onSelect = (selectedKeysValue, info) => {
      console.log('onSelect', selectedKeysValue,info,info.node.key);
      let key = info.node.key
      let param = {
          page:1,
          size:10,
          total: null,
          organizationId: key
      }
      this.setState({loading: true})
      // console.log('ssss', param2);
      listAppUserByOrganizationId(param).then(
        response => {
          const {data} = response.data 
          console.log('onSelect11111111', data);
          if(data === null){
            message.warning('未查询到该组织机构下的数据',[0.5])
            const {pageInfo} = response.data 
            console.log('分页', pageInfo);
            let total= pageInfo?.total
            console.log("total",total)
            let {tableParamenter} = this.state
            tableParamenter.data = data
            this.setState({tableParamenter,totalCount:total,loading: false})
          }else{
            const {pageInfo} = response.data 
          console.log('分页', pageInfo);
          let total= pageInfo?.total
          console.log("total",total)
          let {tableParamenter} = this.state
			  	tableParamenter.data = data
          this.setState({tableParamenter,totalCount:total,loading: false})
          message.success('查询成功')
          }
         
        }
      )
      this.setState({treeId:key});
    };

    //用户注销
    deleteAccount = data =>{
      if(this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
      let val =this.state.rowData[0]
      let param = {
        id: val.id,
      }
      console.log("注销用户带的参数",param)
      Modal.confirm({
        title: `是否确认注销用户？`,
        content: '',
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk: () => {
          // 注销用户
          logOffUser(param).then(
            response => {
              this.setState({
                loading: false,
                refreshTable: false,
                // totalCount: data.total,
            });
            message.success('用户注销成功',[1])
            this.getData();
            funTraceInfo("权限管理", "App注销用户", param, null)
            }
          ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('用户注销失败',[1])
                this.getData();
            }
        )
        }
      })
    }

    //变更状态
    updateStatus=()=>{
      if(this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
      let val =this.state.rowData[0]
      if(val.status=='可用'){
        val.status=2
      }else if(val.status=='未审核'){
        message.success('未审核状态不支持该操作',[1])
        return
      }else if(val.status=='停用'){
        val.status=0
      }
      let param={
        id :val.id,
        status:val.status
      }
      console.log("状态",param)
      updateState(param).then(
        response => {
          this.setState({
            loading: true,
            refreshTable: true,
            // totalCount: data.total,
        });
        message.success('用户状态更改成功',[1])
        this.getData()
        funTraceInfo("权限管理", "App状态变更", param, null)
        }
      ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('用户状态更改失败',[1])
            this.getData()
        }
    )
    }

    //修改
    updateInfo = () => {
    if(this.state.rowData.length < 1) return message.warning('未选择组织部门或数据',[0.5])

		this.props.history.push({
			pathname: '/upAppData/UpAppData',
			state: this.state.rowData[0]
		})
	}



  getTreeNode =()=>{//获取树节点
    this.setState({loading: true});
    getAppOrganizationTree(this.state.responseInfo).then(
      reponse=>{
          let {data} = reponse.data;
          let {responseInfo,tree} = this.state;
          responseInfo.data = data;
          tree.treeData = data
          this.setState({
              loading: false,
              refreshTable: false,
              totalCount: data.total,
              responseInfo: responseInfo,
              tree
          });
          message.success('查询成功',[0.5])
      }
  ).catch(
      () => {
          this.setState({
              loading: false,
              refreshTable: false,
          });
          message.error('查询失败',[0.5])
      }
  )
  };

  //递归
  getTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(value => {
        return {
          title:value?.nodeExtend?.name,
          key:value?.nodeExtend?.id,
          // dataIndex:value?.positions?.name,
          disabled: false,
          children: this.getTreeData(value?.children)
        }
    })
    return tree
  };

  setTableValue = (value,text,rowData) => {
    switch (value) {
        case 'createTime':
            text =moment(text).format('YYYY-MM-DD HH:mm:ss');
           return(
               <span>{text}</span>
           )
           default:
            return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
    }
}

    render() {
        return (
            <Layout  className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={'equipment_frame'} style={{width:'100%'}}>
                    <div className={'left_table'} style={{margin:'10px',height:'80%'}}>
                    <div className={'particulars_info_div'}  style={{margin:'0 10px',width:'100%'}}>
                            <span className="title">组织结构：</span>
                            <div className={'textBox_particulars_info_div'}>
                                          
                            <Tree
                                treeData={this.getTreeData(this.state.tree.treeData)}
                                getTreeNode={this.getTreeNode}
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={this.state.autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                onSelect={this.onSelect}
                                selectedKeys={this.state.selectedKeys}
                            />
                            </div>

                        </div>
                            </div>

                      <div className={'right_table'} style={{margin:'10px',width:'72%'}}>    
				             <div className={'aTable_info_div'} style={{width:'100%',margin:'0'}}>
                     <div className={'poes'}>
                     <Tables
                            tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
                            exportTable={this.exportTable}                  // 导出时触发的方法
                            onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                            refreshTable={this.refreshTable}                // 刷新时触发的方法
                            getPagination={this.getPagination}              // 分页器变化时触发的方法
                            setTableValue={this.setTableValue}              // 设置表格显示

                            pagination={{
                              totalCount: this.state.totalCount, // 总条数
                              page: this.state.params.pageInfo.pageIndex, // 当前页数
                              size: this.state.params.pageInfo.pageLine // 页面条数
                            }} 

                            loading={{
                                loading: this.state.loading,
                                refreshTable: this.state.refreshTable
                            }}
                        >
                    <Conditions 
                      conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                      getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                      clearCondition={this.clearCondition} // 清除条件
                    >
                      <Radio.Group defaultValue="AddAppUser" buttonStyle="solid">
                        {/* <NavLink to="/addAppUser/AddAppUser">
                          <Radio.Button value="AddAppUser">新增账户信息</Radio.Button>
                        </NavLink> */}
                        <Radio.Button value="deleteAccount"  onClick={this.deleteAccount}>注销用户</Radio.Button>
                        <Radio.Button value="updatePost"  onClick={this.updateInfo}>修改账户信息</Radio.Button>
                        <Radio.Button value="updateStatus"  onClick={this.updateStatus}>状态变更</Radio.Button>
                      </Radio.Group>
                    </Conditions>
                  </Tables>
                </div>
                </div>
                </div>
                </div>
                       
            </Layout>
        )
    }

}