import ajax from './ajax'

//  查询小区列表
export const queryResidentialCommunity = (data) => ajax('/customermanage/residentialCommunity/queryResidentialCommunity', (data), 'POST');
//  查询行政区域
export const queryAdministrativeRegionByPid = (data) => ajax('/customermanage/residentialCommunity/queryAdministrativeRegionByPid', (data), 'GET');
//  创建-修改小区
export const createOrUpdateResidentialCommunity = (data) => ajax('/customermanage/residentialCommunity/createOrUpdateResidentialCommunity', (data), 'POST');
//  创建-修改楼栋
export const createOrUpdateBuilding = (data) => ajax('/customermanage/residentialCommunity/createOrUpdateBuilding', (data), 'POST');

//  添加小区关系
export const saveRelation = (data) => ajax('/customermanage/residentialCommunity/saveRelation', (data), 'POST');
//  查询小区下的关联数据
export const queryRelationData = (data) => ajax('/customermanage/residentialCommunity/queryRelationData', (data), 'POST');
//  移除小区关系
export const removeRelation = (data) => ajax('/customermanage/residentialCommunity/removeRelation', (data), 'POST');

//  删除小区
export const removeResidentialCommunity = (data) => ajax('/customermanage/residentialCommunity/removeResidentialCommunity', (data), 'POST');
//  查询小区下的关联数据
export const getCustomerByRegionIds = (data) => ajax('/customermanage/residentialCommunity/getCustomerByRegionIds', (data), 'POST');


//  根据小区查询小区用气量和异常数
export const regionManagementUsage = (data) => ajax('/web/ResidentialGas/regionManagementUsage', (data), 'POST');
