import React, { Component } from 'react'
import {Layout} from 'antd'
// import {Map, Marker, NavigationControl, InfoWindow,Polyline,Polygon} from 'react-bmap'


export default class BaiduMap extends React.Component{
	state = {
	}

	componentDidMount(){
		// window.open("http://app.hdstcloud.com:8882/map/");
		window.location.replace('http://app.hdstcloud.com:8882/map/')
	}
	render(){
		return (
			<Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
				<div className={'mapLinkBox'}>
					<div>
						<a href='http://app.hdstcloud.com:8882/map/' target='_blank'>浏阳新奥热力片区</a>
					</div>
				</div>
			</Layout>
		)
	}
}
