import React, { Component } from 'react'
import { Layout, Button, Tabs, Input, Col, Row, message, Modal } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import FormsTwo from '../../../components/common/forms/FormsTwo'

import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import {saveDataItem,getOne} from '../../../services/device'

const { TabPane } = Tabs;

export default class AddPoint extends Component {

    state = {
        // 表单参数
		formParamenter: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'id',
					str: '数据项代码',
					rules: TYPE_NAME.INPUT,
					disabled: true
				},
				{
					variableName: 'name',
					str: '名称',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'unit',
					str: '单位',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'remark',
					str: '备注',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'searchName',
					str: '搜索名称',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'dataType',
					str: '数据类型',
					rules: TYPE_NAME.REQ_SELECT,
					data:[
						{key:'字符串',value:'字符串'},
						{key:'整数',value:'整数'},
						{key:'浮点型',value:'浮点型'},
						{key:'双精度浮点型',value:'双精度浮点型'},
					]
				},
			]
		},
		data: {
			customerId: this.props.location.state?.customerId
		},
		dataKey: this.props.location.state?.dataKey ?? '1'
    }

	async componentDidMount(){
		const {name, id} = this.props.location?.state ?? {}
		if(id) {
			this.getData(id)
		}
	}

	getData = id => {
			getOne({itemId: id}).then(
				response => {
					const {data} = response.data
					this.setState({data: data})
					message.success('查询成功')
					this.forms.onModalClose()
				}
			)
	}

    onSave = async () => {
		let value = await this.forms.onFinishNotReset();
		if(!value) return null;
		value.id = this.state.data?.id;
		value.locationId = this.state.data?.locationId;
		value.areaName = localStorage.getItem('areaName');
		console.log(value.areaName);
		if(value.areaName==='undefined'){
			return message.info('请选择绑定区域');
		}
		value.mpAreaId = JSON.parse(localStorage.getItem('menus'))?.[0];
		Modal.confirm({
			title: `是否绑定到${localStorage.getItem('areaName')}?`,
			onOk: () => {
				// 新增数据项
				saveDataItem(value).then(
					response => {
						let {data} = response.data;
						if(data === true){
							// funTraceInfo("设备管理", "新增数据项", null,value)
							message.success(value?.id ? "修改成功" : "新增成功");
							this.props.history.go(-1);
							// this.props.history.replace('/point/pointManagement')
						}else{
							message.error(data);
						}
					}
				)
			}
		})
    };

    clear = (e, info) => {
		if(this.props.location.state){
			message.error("修改时不允许清空");
		}else{
			this.forms.onModalClose();
			let {formParamenter} = this.state;
			this.setState({dataKey: e});
			this.setState({formParamenter});
		}
    };


    render() {

		const {dataKey} = this.state;

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回</span>
				</div>
				<div style={{width: '100%',height: '100%',backgroundColor: '#ffffff',padding: '30px 1%'}}>
					<div>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.formParamenter}
							data={this.state.data}
						/>
						<div className={'div_button_center'}>
							<Button type="primary" onClick={this.onSave}>保存</Button>
							&nbsp;&nbsp;&nbsp;
							<Button type="primary" onClick={() => this.clear(dataKey, "clear")}>清空</Button>
						</div>
					</div>
				</div>

            </Layout>
        )
    }
}
