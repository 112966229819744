// 新增订单界面1

import React,{ Component } from 'react'
import {Button, Layout, Modal, InputNumber, Table, Popconfirm, message, Input, DatePicker, Select} from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import Conditions from "../../components/common/conditions/Conditions";
import './shopping.less'
import {addUnorderedMeterOrder,classifyDeviceAccordingToModel,getAllAreaInfo} from '../../services/procurement'
import {funTraceInfo} from "../../services/common";
import moment from "moment";
const { Option } = Select;
export default class shopping extends Component{
    state={
        manageName:'采购管理-型号订单',
        OrderFormData:{
            orderSourceId:"1",
            supplierId:"180",    // 供应商，在选取供应商后会更新
            code:"",             // 订单号
            deliveryTime:'',     // 发货时间
            areaId:'',
            areaName:'',
            orderFormDetails:[]  // 表具
        },

        // 条件 供应商-选择器参数
        orderParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            // 文本框内容
            parameter: [
                {
                    type: 'select',
                    variableName: 'areaId',
                    str: '表具供应商选取',
                    data:[]
                },
                {
                    type: 'input',
                    variableName: 'modelId',
                    str: '型号',

                },

            ]
        },
        // 左表格=订单
        leftTableData:{
            columns:[
                {title: '设备类型', dataIndex: 'type', key: 'type'},
                {title: '设备型号', dataIndex: 'model', key: 'model'},
                {title: '生产商', dataIndex: 'deliverStatus', key: 'deliverStatus'},
                {title: '选购数量', dataIndex: 'quantity', key: 'quantity'},
                {width:100, title: '操作', dataIndex: 'operation',
                    render: (_, record) =>
                        <Popconfirm title="确定删除?" onConfirm={() => this.deleteCommodity(record)}>
                            <a>删除</a>
                        </Popconfirm>
                },
            ],
            paginationProps:{
                total: '', // 数据总数
                pageSize: '13', // 每页条数
                current: '1', // 当前页码
            },
            // 表格加载动画
            loading: false,
            // 刷新动画
            refreshTable: false,
        },//
        // 左表-选购表具列表
        leftData:[],
        rightTableData:{
            columns:[
                {title: '设备类型', dataIndex: 'type', key: 'type'},
                {title: '设备型号', dataIndex: 'model', key: 'model'},
                {title: '生产商', dataIndex: 'deliverStatus', key: 'deliverStatus'},
                {title: '剩余数量', dataIndex: 'remanent', key: 'remanent',fixed: 'right'},
                {title: '选购数量', dataIndex: 'quantity', key: 'quantity',fixed: 'right'},
                {title: '操作', dataIndex: 'operation', key: 'operation',fixed: 'right',
                    render: (_, record) =>
                        <a onClick={() => this.addOrderButton(record)}>添加</a>
                },
            ],
            data:[],
            paginationProps:{
                modelId:null,
                modelName:null,
                areaId:null,
                totalCount: 0,
                page: 1,
                size: 13,
            },
        },

        pageinfo:{
            modelId:null,
            areaId:null,
            totalCount: 0,
            page: 1,
            size: 13,
        },
        // 右表格=表具列表

        // 数字选择框数量限制
        rightOrderNumber:{
            orderMin:"1",
            orderMax:"10"
        },
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,
        tableData:{},
        quantity:1,         // 选择数量
        modal1Visible:false,// 窗口开关
        modal1Visible1:false,// 窗口开关
        modal1Visible2:false,// 窗口开关
        //    ---------------------------------------

    };
    // 页面初始化加载
    componentDidMount=()=>{
        let user = JSON.parse(sessionStorage.getItem("user")??null);
        console.log("user");
        console.log(user);
        let {OrderFormData} = this.state;
        OrderFormData.orderSourceId=user.id;
        this.setState(OrderFormData);
        this.getAllAreaInfos();
    };
    getAllAreaInfos=()=>{
        getAllAreaInfo().then(
            response=>{
                console.log("获取所有区域id");
                console.log(response);
                if(response.data.data) {

                    let orderParamenter = this.state.orderParamenter;
                    orderParamenter.parameter[0].data=response.data.data;
                    this.setState({orderParamenter:orderParamenter});
                }else {
                    message.info("区域查询失败")
                }
            }
        );
    };

    // 文本框-参数获取
    getConditionsValue=(value)=>{
        console.log(value);
        let {OrderFormData,rightTableData} = this.state;
        if(value.areaId===undefined||value.areaId===null){
            return message.info("供应商不能为空");
        }
        // 如果之前的区域和目前选择不同清空数据
        if(value.areaId!==OrderFormData.supplierId){
            this.clearCondition();
        }
        OrderFormData.supplierId=value.areaId;              // 获得发货后需要的供应商区域
        rightTableData.paginationProps.areaId=value.areaId;
        rightTableData.paginationProps.modelName=value.modelId;
        this.setState({OrderFormData:OrderFormData,rightTableData:rightTableData});

        this.classifyDeviceAccordingToModel();  //获取表具数据
        // this.underlying(value.areaId);// 获取表具数据

    };
    classifyDeviceAccordingToModel=()=>{
        console.log("获取表具数据");
        classifyDeviceAccordingToModel(this.state.rightTableData.paginationProps).then(
            response=> {
                console.log("获取表具数据");
                console.log(response);
                let {rightTableData,manageName} = this.state;
                if(response.data.data){
                    let data =response.data;

                    rightTableData.data=data?.data;
                    rightTableData.paginationProps.totalCount=data?.pageInfo?.total;
                    rightTableData.paginationProps.page=data?.pageInfo?.pageIndex;
                    rightTableData.paginationProps.size=data?.pageInfo?.pageLine;
                    funTraceInfo(manageName,"条件查询-获取表具列表","参数："+this.state.rightTableData.paginationProps,"返回结果："+rightTableData.data);
                    this.setState({
                        loading: false,
                        refreshTable: false,
                        rightTableData:rightTableData
                    });
                }else{
                    rightTableData.data=[];
                    rightTableData.paginationProps.totalCount=0;
                    rightTableData.paginationProps.page=1;
                    this.setState({
                        loading: false,
                        refreshTable: false,
                        rightTableData:rightTableData
                    });
                }

            }
        )
    };



    // 根据左表内容清空数据和选择的数量，适用于单个删除
    emptyOrderData=()=>{
        let  {leftData} = this.state;
        leftData.forEach(data=>{
            this.deleteCommodity(data);
        });

    };
    // 表格=选框触发事件
    onSelectChangesLeft=()=>{};
    // 表格=刷新按钮触发事件
    refreshTableLeft=()=>{};
    // 表格=分页功能操作分页按钮触发事件
    getPaginationLeft=()=>{};
    // 表格=使用导出按钮
    exportTableLeft=()=>{};
    // 表格=选框触发事件
    onSelectChangesRight=()=>{};
    // 表格=刷新按钮触发事件
    refreshTableRight=()=>{};

    // 表格=使用导出按钮
    exportTableRight=()=>{};

    // 新增订单-提交控制数据更新
    addOrderButton=(value)=>{
        let {rightOrderNumber} = this.state;
        if(value.number===undefined){
            rightOrderNumber.orderMax=value.remanent
        }else {
            rightOrderNumber.orderMax=value.remanent-value.number;
        }
        this.setState({modal1Visible:true,tableData:value,rightOrderNumber:rightOrderNumber})
    };
    // 确定点击提交添加数量
    submitForm=()=>{
        if(this.rightChoose()){
            this.leftAddData();
        }
        this.setState({modal1Visible:false});
    };
    // 选购数量控制
    rightChoose=()=>{
        let {tableData,quantity,rightTableData,} = this.state;

        // 修改右表数量
        for(let i=0; i<rightTableData.data.length; i++){             // 循环右表数据
            console.log("判断中");
            if(rightTableData.data[i].model===tableData.model){           // 判断获取指定id一行数据
                let total;
                if(rightTableData.data[i].quantity===undefined){    // 判断循环到的数据内的number有没有值
                    total = quantity;                                // 没有值，直接给添加
                }else {
                    total=rightTableData.data[i].quantity+quantity; // 有值就和刚选的数量加一起
                }
                console.log(total);
                if(rightTableData.data[i].remanent>=total){
                    console.log("数量少于可发货量");
                    rightTableData.data[i].quantity =total;
                    this.setState({rightTableData:rightTableData});
                    return true;
                }else{
                    console.log("数量大于可发货量");
                    // this.derailingSwitch1(true)
                    return false;
                }

            }
        }
    };
    // 左框数据添加
    leftAddData=()=>{
        const  data = [...this.state.leftData];
        const {tableData} = this.state;
        let judge=true;
        if(data.length===0){
            data.push(tableData);
        }else {
            for(let i=0;i<data.length;i++){
                if(data[i].model===tableData.model){
                    judge=false;
                    data[i]=tableData;
                }
            }
            if(judge){
                data.push(tableData);
            }
        }
        this.setState({leftData:data})
    };
    // 删除所选商品
    deleteCommodity=(value)=>{
        console.log("deleteCommodity",value);
        const  leftData = [...this.state.leftData];
        const rightTableData = this.state.rightTableData;
        for(let i = 0; i<leftData.length;i++){
            if(value.model===leftData[i].model){
                leftData.splice(i,1);
            }
        }
        for(let i = 0; i<rightTableData.data.length;i++){
            if(value.model===rightTableData.data[i].model){
                rightTableData.data[i].quantity=0;
            }
        }
        this.setState({leftData:leftData,rightTableData: rightTableData})
    };
    // 提交订单
    submitOrder=()=>{
        let {OrderFormData,leftData,manageName} = this.state;
        if(leftData.length!==0){
            OrderFormData.orderFormDetails=leftData;
            console.log(OrderFormData);
            addUnorderedMeterOrder(OrderFormData).then(
                response=> {
                    console.log("提交订单");
                    console.log(response);
                    if(response.data.data){
                        funTraceInfo("采购管理", "创建型号订单", null,OrderFormData)
                        message.success("提交成功");
                        funTraceInfo(manageName,"提交订单","参数："+OrderFormData,"提交成功");
                        this.emptyOrderData();
                    }else{
                        message.success("提交失败"+response.data.responseInfo.description);
                        funTraceInfo(manageName,"提交订单","参数："+OrderFormData,"提交失败")
                    }
                    this.derailingSwitch1(false);
                }
            )
        }else{
            this.derailingSwitch1(false);
            message.success("提交表具不能为空");
        }


    };
    // 开关器-1-选择数量
    derailingSwitch=(modal1Visible)=>{
        this.setState({modal1Visible:modal1Visible})
    };
    // 开关器-2-提交清单
    derailingSwitch1=(modal1Visible1)=>{
        this.setState({modal1Visible1:modal1Visible1})
    };
    // 开关器-2-提交清单
    derailingSwitch2=(modal1Visible2)=>{
        this.setState({modal1Visible2:modal1Visible2})
    };
    // 数字输入框-实时获得数值
    clickQuantity=(quantity)=>{
        console.log(quantity);
        this.setState({quantity});
    };
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'operation':
                return (
                    <a onClick={() => this.addOrderButton(rowData)}>添加</a>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };
    deleteData=()=>{
        this.derailingSwitch2(true);
    };
    // 文本框数据清空-清空
    clearCondition=(value)=>{
        this.derailingSwitch2(value);
        console.log("清空数据");
        let {OrderFormData,rightTableData,leftData} = this.state;
        OrderFormData.supplier = null;      // 供应商
        OrderFormData.deliveryTime = null;  // 时间
        OrderFormData.orderFormDetails.splice(0,OrderFormData.orderFormDetails.length);// 删除选取的表具列表
        rightTableData.data=[];
        rightTableData.paginationProps.page=1;
        rightTableData.paginationProps.totalCount=0;
        leftData=[];
        this.setState({orderFormData:OrderFormData,rightTableData:rightTableData,leftData:leftData});
        // this.emptyOrderData();// 用户重新查询清空数据
    };
    // 表格选框触发事件
    onSelectChange=(value)=>{};
    // 表格刷新按钮触发事件
    refreshTable=()=>{};
    // 表格分页功能操作分页按钮触发事件
    getPagination=()=>{};
    // 表格使用导出按钮
    exportTable=()=>{};

    // 右边表格：获取当前页数和条数
    getPaginationRight = (page, pageSize) => {
        console.log("右边表格：获取当前页数和条数");
        console.log(page +","+ pageSize);
        let {rightTableData} = this.state;
        rightTableData.paginationProps.page = page;
        this.setState({rightTableData: rightTableData});
        this.classifyDeviceAccordingToModel();
    };
    // 文本框数据获取-日期
    getDatetime=(date,dateString)=>{
        console.log("文本框数据获取-日期");
        console.log(date, dateString);
        if(dateString!==null||dateString!==""){
            let {OrderFormData = {}} = this.state;
            console.log(OrderFormData);
            OrderFormData.deliveryTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
            this.setState({OrderFormData:OrderFormData});
        }

    };

    //订单号手写
    onSearch=(value)=>{
        console.log("订单号手写");
        let OrderFormData = this.state.OrderFormData;
        OrderFormData.code=value.target.value;
        this.setState(OrderFormData);
        console.log(value.target.value);
    };
    selectChang=(value,index)=>{
        console.log("实时获得下拉框数据");
        console.log(index);
        let {OrderFormData} = this.state;
        OrderFormData.areaId=index.key;
        OrderFormData.areaName=index.value;

        console.log(OrderFormData);
        this.setState({OrderFormData:OrderFormData});
    };
    showTotal=(value)=> {
        console.log(value);
        let {rightTableData} = this.state;

    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={shopping}>
                    <div className={'div_back_arrow'}>
                        <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                        <span className={'font_size1'}>返回订单管理</span>
                        <span className={' font_div '}>型号订单管理</span>
                    </div>
                    <div className={"Conditions_div"}>
                    </div>
                    {/*左框*/}
                    <div className={"leftTableData_div"}>
                        <div className={"leftTableData_button_div"}>
                            <div className={"button_div"}>
                                <Button type={'primary'} onClick={() => this.derailingSwitch1(true)}>提交订单</Button>&nbsp;
                            </div>
                            <div>

                            </div>
                            <div>
                            {/*<span>请选择收货点：</span>*/}
                            {/*<Select style={{ width: 200 }}*/}
                            {/*        showSearch*/}
                            {/*        onChange={this.selectChang}*/}
                            {/*        placeholder="请选择收货点"*/}
                            {/*    // onSearch={this.state.selectSearch}*/}
                            {/*>*/}
                            {/*    {*/}
                            {/*        this.state.orderParamenter.parameter[0].data.map( item => (*/}
                            {/*            <Option key={item.key} value={item.value}>{item.value}</Option>)*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*</Select>*/}
                                <span>请输入订单号：</span>
                                <Input type="text" placeholder="输入单号" style={{ width: 200 }} onChange={this.onSearch}/>&nbsp;
                                <span>请输入发货时间：</span>
                                <DatePicker defaultValue={moment(new Date(),'YYYY-MM-DD')} onChange={this.getDatetime} />
                            </div>
                        </div>

                        <div className={"leftTableData_Top_div1"}>
                            <div className={"leftTableData_Title_div"}>
                                <span className={'font_size0'}>选购列表</span>
                            </div>
                        </div>
                        <Table
                            bordered
                            pagination = {{
                                showSizeChanger:true,
                                showQuickJumper: true,
                                onChange: this.showTotal,       // 获得翻页数值
                                // total:this.state.leftData.length,
                                total: this.state.leftTableData.paginationProps.total,
                                pageSize:13,
                                showTotal: (count = this.state.leftData.length)=>{
                                    return '共'+this.state.leftData.length+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['13']
                            }}
                            dataSource={this.state.leftData}
                            columns={this.state.leftTableData.columns}
                            rowKey={"id"}
                            scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                            // scroll={{ y: 750,x:1200 }}
                            // onRow={this.onClickRow}
                            // rowClassName={this.setRightRowClassName}
                        />
                        {/*<Table*/}
                        {/*    bordered*/}
                        {/*    pagination={this.state.leftTableData.paginationProps}*/}
                        {/*    dataSource={this.state.leftData}*/}
                        {/*    columns={this.state.leftTableData.columns}*/}
                        {/*    rowKey={"id"}*/}
                        {/*    // scroll={{ y: 550 }}*/}
                        {/*    // rowClassName={this.setRowClassName}*/}
                        {/*/>*/}
                    </div>
                    {/*左框*/}
                    {/*右框*/}
                    <div className={"rightTableData_div"}>
                        <div className={"rightTableData_font_div"}>
                            <Conditions
                                conditionsParamenter={this.state.orderParamenter} // 条件框输入框设置
                                getConditionsValue={this.getConditionsValue}
                                clearCondition={this.deleteData} // 清除条件
                            >
                            </Conditions>
                        </div>
                        <div className={"rightTableData_Top_div"}>
                            <div className={"rightTableData_Title_div"}>
                                <span className={'font_size0'}>表具列表</span>
                            </div>
                        </div>
                        <Table
                            bordered
                            pagination = {{
                                showSizeChanger:true,
                                showQuickJumper: true,
                                onChange: this.getPaginationRight,       // 获得翻页数值
                                total:this.state.rightTableData.paginationProps.totalCount,
                                pageSize:13,
                                showTotal: (count = this.state.rightTableData.paginationProps.totalCount)=>{
                                    return '共'+this.state.rightTableData.paginationProps.totalCount+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['13']
                            }}
                            dataSource={this.state.rightTableData.data}
                            columns={this.state.rightTableData.columns}
                            rowKey={'id'}
                            // scroll={{ y: 750,x:1200 }}
                            scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了

                        />
                    </div>
                    {/*右框*/}
                    <div>
                        {/*窗口-1*/}
                        <Modal
                            title="请选择购买数量"
                            style={{ top: 0 }}
                            visible={this.state.modal1Visible}
                            okText="确定"
                            onOk={() => this.submitForm()}           // 点击确定
                            onCancel={() => this.derailingSwitch(false)}       // 点击取消
                            width={180}
                            centered
                        >
                            <Popconfirm
                                title="Are you sure to delete this task?"
                                // onConfirm={confirm}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <InputNumber
                                    min={this.state.rightOrderNumber.orderMin}
                                    max={this.state.rightOrderNumber.orderMax}
                                    defaultValue={1}
                                    style={{width: 130,}}
                                    onChange={this.clickQuantity}
                                />
                            </Popconfirm>

                        </Modal>
                        {/*窗口-2*/}
                        <Modal
                            title="待生成订单"
                            style={{ top: 0 }}
                            visible={this.state.modal1Visible1}
                            okText="发货"
                            onOk={() => this.submitOrder(false)}                  // 点击确定
                            onCancel={() => this.derailingSwitch1(false)}       // 点击取消
                            width={400}
                            centered
                        >
                            <div style={{textAlign:'center'}}>
                                <span  className={'Modal_title_div'}>确认提交订单</span>
                            </div>

                        </Modal>
                        {/*窗口-2*/}
                        <Modal
                            title="删除内容"
                            style={{ top: 0 }}
                            visible={this.state.modal1Visible2}
                            okText="确定"
                            onOk={() => this.clearCondition(false)}                  // 点击确定
                            onCancel={() => this.derailingSwitch2(false)}       // 点击取消
                            width={250}
                            centered
                        >
                            <div>
                                <span  className={'Modal_title_div'}>清空会包含所选商品</span>
                            </div>

                        </Modal>
                    </div>
                </div>
            </Layout>

        )
    }
}
