import React,{Component} from "react";
import {Button, Cascader, DatePicker, Form, Layout, message, Select, Space, Spin, Tag} from "antd";
import { Map, Marker, InfoWindow ,ScaleControl,Polyline,MassMarks} from '@uiw/react-amap';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { AMap } = window;
// 热力图框
export default class RiskMapBox extends React.Component{
    state={
    };
    transfer=()=>{
        this.props.history.push({
            pathname:"/RiskMap",
            state:{

            }
        })
    };
    render() {
        return (
            <Layout  className={'item_body_number_back tables'}>
                <div style={{width: '100%', height: '100%',background: '#fff',padding:'20% 0 0 40%'}}>
                    <div
                        onClick={()=>{this.transfer()}}
                        style={{width: '20%', height: '50px',paddingTop:'8px',textAlign: 'center',fontSize:'18px',color:'#fff',background:'#1890ff',border:'1px #ccc solid', borderRadius: '10px',cursor:'pointer'}}>
                        <span>风险热力图</span>
                    </div>
                </div>
            </Layout>
        );
    }
}
