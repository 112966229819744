import React, { Component } from 'react'
import { NavLink, withRouter } from "react-router-dom"

import {Layout, Button, Radio, message, Modal, Input, Tag, Popconfirm} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import {TYPE_NAME} from '../../components/Const'
import {funTraceInfo} from '../../services/common'
import Tables from '../../components/common/tables/Tables'
import Conditions from '../../components/common/conditions/Conditions'
import FormsText from '../../components/common/forms/FormsText'
import {
	delPushDevs,
	queryDevs,
	queryInstallMeters,
	queryPushConfigs,
	savePushDevs,
	updatePushDevs
} from '../../services/pushData'
import moment from "moment";
import TableComponents from '../../components/common/tables/TableComponentsNew'
import FormsTwo from "../../components/common/forms/FormsTwo";
class PushConfigurationDetails extends Component {

    state = {
		pushId:null,
		modeTitle:'新增设备',
		modalVisible_1:false,
		pushConfigId:null,// 配置id
		modalFormItem: {
			modal: true,
			number: 3,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{variableName: 'code', str: '表刚号', rules: TYPE_NAME.NOT_BLANK,},
				{variableName: 'cusName', str: '客户名称', rules: TYPE_NAME.INPUT,},
				{variableName: 'facName', str: '供应厂家', rules: TYPE_NAME.INPUT,},
				{variableName: 'gasDevices', str: '用气设备', rules: TYPE_NAME.INPUT,},
				{variableName: 'gasPropeerties', str: '用气性质', rules: TYPE_NAME.INPUT,}
			],
		},
		searchGetData:{},
		// 表格参数
		modalTableParamenter: {
			prompt:false,// 是否提示请求状态
			manageName: "数据项管理",
			// 表格行是否可选
			rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				sheetSteelGrade: '表钢号',
					modelName: '型号名称',
					name: '客户名称',
					manufacture: '厂商',
					state: '状态',
					edit:'操作'

			},
			// 弹出框
			headers: {
				sheetSteelGrade: '表钢号',
					modelName: '型号名称',
					name: '客户名称',
					manufacture: '厂商',
					state: '状态',
					// edit:'操作'
			},
			params: {
				steelNum:null,
				isAreaIds:0,
				page:1,
				size:10,
			},
			// 是否绑定左侧区域
			bindArea: false

		},

		// 表格参数
		tableParamenter: {
			manageName: "数据推送记录详情",
			// 表格行是否可选
            rowSelection: false,
			// 表头：变量和变量解释
			header: {
				// id: '详情编号',
				facName: '供应厂家',
				model: '表具型号',
				code: '表钢号',
				meterType: '表具业务类型',

				address: '安装地址',
				gasPropeerties: '用气性质',
				gasDevices: '用气设备',
				cusName: '客户名称',
				lastPushState: '最后推送状态',
				lastPushTime: '最后推送时间',
				state:'推送开关',
				edit:'操作'
			},

			// 表格数据
			data: []
		},
		// 条件选择器参数
		conditionsParamenter: {
			// 是否显示条件标题
			isLable: true,
			// 是否显示清空按钮
			clearButton: true,
			parameter: [
				{
					type: TYPE_NAME.INPUT,
					variableName: 'cusName',
					str: '客户名称'
				},
				{
					type: TYPE_NAME.INPUT,
					variableName: 'code',
					str: '表钢号'
				},
				{
					type: TYPE_NAME.INPUT,
					variableName: 'adress',
					str: '安装地址'
				},

			]
		},
		// 表单参数
		formText: {
			header: {
				// id: '配置编号',
				pushName: '推送名',
				pushObject: '推送对象',
				pushInterval: '推送间隔',
				url: '推送地址',
				lastPushTime: '最后一推送时间',
				lastSuccess: '最后一次成功数',
				lastFails: '最后一次失败数',
				pushSums: '推送总数',

			}
		},
		params: {
			page: 1,
			size: 10,
			adress: null,
			cusName:null,
			code:null,
			configId: this.props.location?.state?.id
		},
		// 总条数
		totalCount: 0,
		// 选中的数据
		rowData: [],
		// 表格加载动画
		loading: false,
		// 刷新动画
		refreshTable: false,
		selectOneData:{},
		InputData:''
	};

	componentDidMount() {
		this.getData();
		console.log('this.props.location?.state?.id');
		console.log(this.props.location?.state?.id);
	}


    // 表格：刷新方法
	refreshTable = () => {
		this.setState({refreshTable: true})
		this.getData()
	}





	// 条件选择：获取输入的条件
	getConditionsValue = value => {
		let {params} = this.state
		params.adress = value.adress
		params.cusName = value.cusName
		params.code = value.code
		params.page = 1
		this.setState({params})
		this.getData()
	}

	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
		let {params} = this.state;
		params.page = page;
		params.size = pageSize;
		this.setState({params});
		this.getData()
	};


	// 初始化数据
	getData = () => {
		this.setState({loading: true})
		console.log("查询参数",this.state.params)
		queryDevs(this.state.params).then(
			response => {
				let { data,pageInfo } = response.data
				let {tableParamenter} = this.state
				tableParamenter.data = data
				this.setState({
					loading: false,
					refreshTable: false,
					totalCount: pageInfo?.total??0,
					tableParamenter: tableParamenter
				})
				message.success('查询成功')
			}
		).catch(
			() => {
				this.setState({
					loading: false,
					refreshTable: false,
				})
				message.error('查询失败')
			}
		)
	}



	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		params.adress = null
		params.cusName = null
		params.code = null
		this.setState({params});
		this.getData()
	};
	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'state':
				var title=text==="0"?'是否关闭推送':'是否启动推送';
				var title1=text==="0"?'已开启':'已关闭';
				return (
					<>
						<div className={'tableLink'} title={'开关'}>
							<Popconfirm title={title}
										onConfirm={()=>{
											this.updatePushDevs_1(rowData.id,text==="0"?1:0);
										}}
								// onCancel={cancel}
										okText="是" cancelText="否">
								<a>{title1}</a>
							</Popconfirm>
						</div>
					</>
				);
			case 'edit':
				return (
					<>
						<div className={'tableLink'} title={'修改'}
							 onClick={async ()=>{
								 var params={
									 code:rowData.code,
									 cusName:rowData.cusName,
									 facName:rowData.facName,
									 gasDevices:rowData.gasDevices,
									 gasPropeerties:rowData.gasPropeerties,
								 };
								 this.setState({pushId:rowData.id});
								 this.modalData(true,rowData,'修改设备')
							 }}
						>
							<a>修改</a>
						</div>
						<div className={'tableLink'} title={'删除'}>
							<Popconfirm title="是否删除设备?"
										onConfirm={()=>{
											this.delPushDevs(rowData.id);
										}}
								// onCancel={cancel}
										okText="是" cancelText="否">
								<a>删除</a>
							</Popconfirm>
						</div>
					</>
				);
				break;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	setTableValue_1=(value,text,rowData)=>{
		// steelNum: '表钢号',
		// 	modelName: '型号名称',
		// 	name: '客户名称',
		// 	manufacture: '厂商',
		// 	properties: '用气性质',
		switch (value) {
			// 型号名称
			case 'modelName':
				var title_1=rowData?.device?.modelName || '-';
				return <>{title_1 ? <div className={'table_overflow'} title={title_1}>{title_1}</div> : '-'}</>;
			// //客户名称
			// case 'name':
				// 厂商
			case 'manufacture':
				var title_2=rowData?.device?.manufacture || '-';
				return <>{title_2 ? <div className={'table_overflow'} title={title_2}>{title_2}</div> : '-'}</>;
				// 用气性质
			case 'edit':
				return <>
					<div className={'tableLink'} title={'选择'}
						 onClick={async ()=>{
							 this.selectData(rowData);
						 }}
					>
						<a>选择</a>
					</div>
				</>;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	selectData=async (rowData)=>{
		var searchGetData = this.state.searchGetData;
		searchGetData.code = rowData?.sheetSteelGrade;
		searchGetData.facName = rowData?.device?.manufacture;
		console.log(searchGetData);
		await this.setState({searchGetData});
		await this.forms.onModalClose()

	};

	modalData = async (devVisible,data,title)=>{
		await this.setState({modalVisible_1:devVisible,searchGetData:data,modeTitle:title});
		await this.forms.onModalClose()
	};

	// 创建数据推送配置下的设备
	savePushDevs= async() =>{
		console.log(this.props.location.state);
		var params = await this.forms.onFinishNotReset();
		if(!params) return false;
		params.pushConfigId=this.props.location.state?.id;
		params.state=0;
		savePushDevs(params).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('配置成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('配置失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		})
	};
	// 修改数据推送配置下的设备
	updatePushDevs_1=async(id,num) =>{
		var params = {
			id:id,
			state:num,
			pushConfigId:this.props.location?.state?.id
		};
		console.log(params);
		updatePushDevs(params).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('操作成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('操作失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		})
	};
	// 修改数据推送配置下的设备
	updatePushDevs= async() =>{
		var params = await this.forms.onFinishNotReset();
		console.log('this.state?.pushId');

		if(!params) return false;
		if(this.state?.pushId){
			params.id=this.state.pushId;
		}
		params.pushConfigId=this.props.location?.state?.id;
		console.log(params);
		updatePushDevs(params).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('修改成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('修改失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		})
	};
	// 删除数据推送配置下的设备
	delPushDevs=(id)=>{
		var param = new URLSearchParams();
		param.append("id",id);
		delPushDevs(param).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('删除成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('删除失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		});
	};



    render() {
		const {InputData}=this.state;

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'}
						  onClick={() =>
							  // this.props.history.replace("/customer/clientBusiness")
							  this.props.history.go(-1)
						  }
					><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回数据推送配置管理</span>
				</div>
                <div className={'div_title_from insert_table'} style={{paddingLeft: '3vw'}}>
					<FormsText
						record={this.props.location.state??{}}
						header={this.state.formText.header}
						rowLen={3}
						span={6}
					/>
				</div>
				<div className={'divider'}></div>
                <div className={'item_body_number_back tables'}>
					<Tables
						tableParamenter={this.state.tableParamenter}  // 表格表头、数据
						setTableValue={this.setTableValue}
						refreshTable={this.refreshTable}  // 刷新时触发的方法
						getPagination={this.getPagination} // 分页器变化时触发的方法
						pagination={{
							totalCount: this.state.totalCount, // 总条数
							page: this.state.params.page, // 当前页数
							size: this.state.params.size, // 页面条数
							arr: [5,10] // 页数
						}}
						loading={{
							loading: this.state.loading,
							refreshTable: this.state.refreshTable
						}}
						columnMaxWith = {130}
					>
						<Radio.Group defaultValue="addClient" buttonStyle="solid">
							<Radio.Button value="addClient" onClick={()=>{this.modalData(true,{},'配置设备')}}>配置设备</Radio.Button>
						</Radio.Group>
						<Conditions
							conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
							getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
							clearCondition={this.clearCondition} // 清除条件
						>
						</Conditions>
					</Tables>
					<Modal
						title={this.state.modeTitle}
						style={{ top: 0 }}
						visible={this.state.modalVisible_1}
						// okText={this.state.modalFormItemText}
						// 点击确定
						onOk={()=>{
							if(this.state.modeTitle==='配置设备'){
								this.savePushDevs();	// 新增
							}else {
								this.updatePushDevs();// 修改
							}

						}}
						// 点击取消
						onCancel={async ()=>{this.modalData(false,{})}}
						width={1200}
						centered
					>
						<div style={{width:'100%'}}>
							<span>表钢号：<Input style={{width:'20%'}} onChange={(text)=>{
								var modalTableParamenter = this.state.modalTableParamenter;
								console.log(text.target.value);
								modalTableParamenter.params.steelNum=text.target.value;
								this.setState({modalTableParamenter:modalTableParamenter})
							}} />
							<Button type={'primary'}
									onClick={()=>{
										this.table.refreshTable(this.state.modalTableParamenter.params);
									}}
							>查询</Button></span>
						</div>
						<div style={{width:'100%'}}>
							<TableComponents
								paramenter={this.state.modalTableParamenter}
								setData={queryInstallMeters}
								setTableValue={this.setTableValue_1}
								ref={e => this.table = e}
							>
							</TableComponents>
						</div>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.modalFormItem}
							data={this.state.searchGetData}
						/>
					</Modal>
                </div>

            </Layout>
        )
    }
}

export default withRouter(PushConfigurationDetails)
