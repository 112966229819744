import React, { Component } from 'react'
import {Layout,Button} from "antd";
import './middleLeft.less'
import BarChart from "../assembly/BarChart";
import BarChart1 from "../assembly/BarChart_1";
import HalfDoughnutChart1 from "../assembly/HalfDoughnutChart";
import {dividedByRegion, gasQuantity} from "../../../services/customerDataPanel";

export default class middleLeft extends Component {
    state = {

        // 环形圆进度条
        leftData_1:{
            name:'安全率',data:91,color:['#3D8DFF','#62DBFF']
        },
        // 环形圆进度条
        leftData_2:{
            name:'安全率',data:93,color:['#96EE79','#F7FD86']
        },


        // 曲线图-横向
        leftData_eCharts:{
            // 数值
            data1:[],
            data2:[],
            yData:[],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天''商综','机关','学校','工业','餐饮'
            number:{min:0,max:100,interval:100} // 最小、最大、间距
        },

        // 曲线图-竖向
        leftData_eCharts_1:{
            // 数值
            data1:[],
            data2:[],
            yData:[],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天''岳麓','芙蓉','雨花','天心','开福','宁乡','浏阳'
            number:{min:0,max:100,interval:100} // 最小、最大、间距
        },

        "gasQuantity": 0,   // 燃气数量
        "hiddenDanger": 0,  // 隐患数量
        "safetyRate": 0,   // 安全率
        "secure": 0,         // 	安全数量

        selectButtonCss:null,

    };

    componentDidMount(): void {
        console.log('初始化');
        this.dividedByRegion(); // 燃气用户(按区分组)
        this.gasQuantity();
    }

    // 燃气用户(按区分组)
    dividedByRegion=(type)=>{
        console.log('燃气用户(按区分组)');
        // var params =  new URLSearchParams();
        // params.append('type',type);
        dividedByRegion({type}).then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    var leftDataECharts = this.state.leftData_eCharts;
                    var leftDataECharts1 =  this.state.leftData_eCharts_1;
                    leftDataECharts.yData=[];
                    leftDataECharts.data1=[];
                    leftDataECharts.data2=[];
                    leftDataECharts1.yData=[];
                    leftDataECharts1.data1=[];
                    leftDataECharts1.data2=[];
                    data?.区域?.map(
                        one=>{
                            leftDataECharts.yData.push(one?.areaName??'null');
                            leftDataECharts.data1.push(one?.secure??'0');
                            leftDataECharts.data2.push(one?.hiddenDanger??'0');
                        }
                    );
                    data?.客户性质?.map(
                        two=>{
                            leftDataECharts1.yData.push(two?.areaName??'null');
                            leftDataECharts1.data1.push(two?.secure??'0');
                            leftDataECharts1.data2.push(two?.hiddenDanger??'0');
                        }
                    );
                    this.setState({leftData_eCharts:leftDataECharts,leftData_eCharts_1:leftDataECharts1})
                }
            }
        )
    };

    // 燃气用户(安全-隐患)
    gasQuantity=(type)=>{
        // var params =  new URLSearchParams();
        // params.append('type',type);
        gasQuantity({type}).then(
            response=>{
                if(response?.data?.data){
                    var data = response.data.data;
                    var data1 = this.state.leftData_1;
                    data1.data=data?.safetyRate;
                    this.setState({
                        leftData_1:data1,
                        "gasQuantity": data?.gasQuantity,   // 燃气数量
                        "hiddenDanger": data?.hiddenDanger, // 隐患数量
                        "safetyRate": data?.safetyRate,     // 安全率
                        "secure": data?.secure              // 	安全数量
                    })
                }
            }
        );
    };

    render() {
        const {selectButtonCss} = this.state;


        return (
            <div id={'middleLeft1'}>
                <div style={{width: '100%',height: '10%', padding: '20px 0 0 50px'}}>

                    <div style={{float:'left',width:'100px'}}>
                        <span style={{ fontSize: '22px', color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>燃气用户</span>
                    </div>
                    <div style={{float:'right',width:'150px'}}>
                        <div className={selectButtonCss===1?'middleLeft_data_1_button_2':'middleLeft_data_1_button_1'}
                             onClick={()=>{
                                 this.setState({selectButtonCss:!selectButtonCss?1:selectButtonCss===1?null:1});
                                 this.dividedByRegion(!selectButtonCss?1:selectButtonCss===1?null:1); // 燃气用户(按区分组)
                                 this.gasQuantity(!selectButtonCss?1:selectButtonCss===1?null:1);     // 燃气用户(安全-隐患)
                             }}
                        >
                            <span style={{color:'#b3bdc9'}}>居民</span>
                        </div>
                        <div className={selectButtonCss===2?'middleLeft_data_1_button_2':'middleLeft_data_1_button_1'}
                             onClick={()=>{
                                 this.setState({selectButtonCss:!selectButtonCss?2:selectButtonCss===2?null:2});
                                 this.dividedByRegion(!selectButtonCss?2:selectButtonCss===2?null:2); // 燃气用户(按区分组)
                                 this.gasQuantity(!selectButtonCss?2:selectButtonCss===2?null:2);     // 燃气用户(安全-隐患)

                             }}
                        >
                            <span style={{color:'#b3bdc9'}}>工商</span>
                        </div>

                    </div>
                </div>
                {/*第一排*/}
                <div style={{width:'100%',height:'32%',margin:'2% 10% 0 10%'}}>
                    <div style={{width:'80%',height:'80%',float:'left'}}>
                        <div style={{width:'50%',height:'90%',float:'left'}}>
                            <HalfDoughnutChart1
                                elementById={'leftTopData_1_9'}
                                data={{
                                    data: this.state.leftData_1.data,
                                    name: this.state.leftData_1.name,
                                    color:this.state.leftData_1.color,
                                }}
                            />
                        </div>
                        <div style={{width:'40%',height:'90%',float:'left',padding:'10% 0% 10% 0%'}}>
                            <div style={{width:'30%',height:'100%',float:'left',marginLeft:'10%'}}>
                                <span style={{color:'#a8e9d6',fontSize:'18px'}}>燃气</span>
                            </div>
                            <div style={{width:'50%',height:'100%',float:'left'}}>
                                <span style={{color:'#fdfdfd',fontSize:'18px'}}>{this.state.gasQuantity}家</span>
                            </div>

                        </div>
                    </div>
                    <div style={{width:'80%',height:'20%',float:'left'}}>
                            <div style={{width:'45%',height:'100%',margin:'0 5px 0 5PX',float:'left'}}>
                                <div className={'middleLeft_data_1_img_1'} style={{padding:'5px 0 5px 5px'}}>
                                    <div className={'middleLeft_data_1_img_2'} style={{float:'left'}}/>
                                    <div style={{width:'82%',height:'100%',float:'left'}}>
                                        <div style={{width:'55%',height:'100%',float:'left',marginLeft:'1%',textAlign: 'center'}}>
                                            <span style={{color:'#a8e9d6'}}>安全用户</span>
                                        </div>
                                        <div style={{width:'42%',height:'100%',float:'left'}}>
                                            <span style={{color:'#fdfdfd'}}>{this.state.secure}家</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div style={{width:'45%',height:'100%',margin:'0 5px 0 5PX',float:'right'}}>
                            <div className={'middleLeft_data_1_img_1'} style={{padding:'5px 0 5px 5px'}}>
                                <div className={'middleLeft_data_1_img_3'} style={{float:'left'}}/>
                                <div style={{width:'82%',height:'100%',float:'left'}}>
                                    <div style={{width:'55%',height:'100%',float:'left',marginLeft:'1%',textAlign: 'center'}}>
                                        <span style={{color:'#a8e9d6'}}>隐患用户</span>
                                    </div>
                                    <div style={{width:'42%',height:'100%',float:'left'}}>
                                        <span style={{color:'#fdfdfd'}}>{this.state.hiddenDanger}家</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*第二排*/}
                <div style={{width:'100%',height:'25%'}}>
                    <BarChart
                        elementById={'leftData_1'}
                        data1={this.state.leftData_eCharts_1.data1}    // 数值
                        data2={this.state.leftData_eCharts_1.data2}    // 数值
                        yData={this.state.leftData_eCharts_1.yData}  // X轴数据
                        number={this.state.leftData_eCharts_1.number}// 最小、最大、间距
                    />
                </div>
                {/*第三排*/}
                <div style={{width:'100%',height:'26%'}}>
                    <BarChart1
                        elementById={'leftData_2'}
                        data1={this.state.leftData_eCharts.data1}    // 数值
                        data2={this.state.leftData_eCharts.data2}    // 数值
                        yData={this.state.leftData_eCharts.yData}  // X轴数据
                        number={this.state.leftData_eCharts.number}// 最小、最大、间距
                    />
                </div>
            </div>
        )
    }
}
