import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Spin} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'
import * as echarts from 'echarts/lib/echarts';
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel,queryPeakValleyPage,listDevByAreaIds} from '../../../services/steamReport'
import {getDateByUserId} from "../../../services/admin";

echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;
var downIp =global.pubData.url;


export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			// 弹出框
			headers: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			params: {
				areaIds:[],
				granularity:1,
				startTime:'',
				endTime:'',
				itemIdList:[1184],
				page:1,
				size:100,
		},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [


			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					fieldNames:{label: 'name', value: 'id', children: 'children'},
					rules: TYPE_NAME.TREE_SELECT,
					disabled: this.props.location.state ? true : false,
					data:[],
				},
				{
					variableName: 'granularity',
					str: '时间类别',
					rules: TYPE_NAME.SELECTINPUT,
					data: [
						{
							key: '1',
							value: '时'
						},
						{
							key: '2',
							value: '日'
						},
						{
							key: '3',
							value: '月'
						},
						{
							key: '5',
							value: '峰谷'
						}
					]
				},
				{
					variableName: 'createTime',
					str: '时间范围',
					rules: TYPE_NAME.RANGE_REQ_DATE_PICKER,
				},

			]
		},
		searchGetData:{},

		    	// 总条数
		totalCount: 0,
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editQrCodeStatus(true,rowData.id,rowData.code)}>
						<u>{'修改'}</u>
				</div>
				</>
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		let user = JSON.parse(sessionStorage.getItem("user"))
		// getDataIgnoreLevel({userId:user.id}).then(
		// 	response => {
		// 		const {data} = response.data
		// 		let selectArry=[]
		// 		if(data){
		// 			for(var i=0;i<data.length;i++){
		// 				selectArry.push({key:data[i].id,value:data[i].name})
		// 			}
		// 		}
		// 		this.state.topFormItem.parameter[0].data=selectArry;
		// 	}
		// )
		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
			response => {
				if (response?.data) {
					console.log(response?.data);
					this.state.topFormItem.parameter[0].data=response?.data;
				} else {
					return message.info('获取不到数据');
				}
			}
		)
		$('.autoTableBox').hide();



	}



	onChangeSelect = e => {
		console.log(111,e)
	}

	reportExport = async ()=>{
		let data = await this.forms.onFinishNotReset();
		if(!data) return null
		var tableParamenter = this.state.tableParamenter;

		tableParamenter.areaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,data.areaIds,tableParamenter.areaIds);

		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		window.open(downIp+'/web/reportExportCtrl/exportPlanValleyList?areaIds='+tableParamenter.params.areaIds+'&ajaxFileName=用量报表&itemIdList=1184&granularity='+data.granularity+'&startTime='+sTime+'&endTime='+eTime+'&devIds='+data.devIds)
	};

	forTreeArea = (data,key,areaIds=[]) =>{
		data.map(one=>{
			if(one.id===key){
				areaIds.push(one.id);
				if(one?.children){
					this.addAllTreeArea(one?.children,areaIds);
				}
			}else if(one?.children){
				this.forTreeArea(one?.children,key,areaIds);
			}
		});
		return areaIds;
	};
	addAllTreeArea = (data,areaIds=[]) =>{
		data.map(one=>{
			areaIds.push(one.id);
			if(one?.children){
				this.addAllTreeArea(one?.children,areaIds);
			}
		});
		return areaIds;
	};
	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		if(!data) return null
		$('.loadBox').show();
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		params.granularity=data.granularity
		params.areaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,data.areaIds,params.areaIds);
		// params.areaIds=[data.areaIds]
		if(sTime){
			params.startTime=sTime;
		}
		if(eTime){
			params.endTime=eTime;
		}
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})

		queryPeakValleyPage(this.state.tableParamenter.params).then(
			response => {
				const {data} = response.data
				if(data){
					$('.loadBox').hide();
					$('#noMsg').hide();
					$('.autoTableBox').show();
					console.log(data);
					var dateHtml='';
					if(data[0].reportList.length>0)
					{
						$('#dateHeadBox').html('');
						$('#nameHeadBox').html('');
						for(var i=0;i<data[0].reportList.length;i++)
						{
							dateHtml+="<th colSpan='3'><p>"+data[0].reportList[i].time+"</p><div>起始值(吨)</div><div>终止值(吨)</div><div>用量(吨)</div></th>"
						}
					}
					if(data.length>0){
						$('#dataListBox').html('');
						for(var j=0;j<data.length;j++)
						{
							var dataHtml='';
							for(var i=0;i<data[j].reportList.length;i++){
								var first=data[j].reportList[i].first?data[j].reportList[i].first:'-';
								var end=data[j].reportList[i].end?data[j].reportList[i].end:'-'
								var value=data[j].reportList[i].value?data[j].reportList[i].value:'-';
								dataHtml+="<td>"+parseFloat(first).toFixed(2)+"</td><td>"+parseFloat(end).toFixed(2)+"</td><td>"+parseFloat(value).toFixed(2)+"</td>"
							}
							var allTimeTotal=data[j].allTimeTotal?data[j].allTimeTotal:'-'
							$('#dataListBox').append('<tr>' +
								'<td>'+data[j].name+'</td>' +
								'<td>'+parseFloat(allTimeTotal).toFixed(2)+'</td>'+
								'<td>'+data[j].monthTotal+'</td>'
								+dataHtml+'</tr>');
						}
					}
					$('#dateHeadBox').append("<th style='z-index:9; min-width:350px;'>客户名称</th><th style='z-index:9; min-width:150px;'>合计</th><th style='z-index:9; min-width:150px;'>月统计</th>"+dateHtml);
				}else{
					$('.loadBox').hide();
					$('#noMsg').text('暂无数据');
				}
			}
		)

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaId = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox rTable'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff',overflow:'auto',paddingBottom:'20px'}}>
					<div className={'topSearchBox'}>
						<h3>用量报表</h3>
						<div style={{position:'relative'}}>
							<div className={'topFormBox'} style={{width:'90%'}}>
							<FormsTwo
									ref={e => this.forms = e}
									formParamenter={this.state.topFormItem}
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'100px',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
							<Button style={{position:'absolute', right:'0',top:'0'}} onClick={() => this.reportExport()}>导出</Button>
						</div>
					</div>
					<div id='noMsg' style={{fontSize:'30px',lineHeight:'100px',textAlign:'center',color:'#ccc'}}>请先选择需要对比的表具及时间范围</div>
					<div className={'autoTableBoxFix'}>
						<table id='autoTable'>
							<thead>
								<tr id='dateHeadBox'>
								</tr>
							</thead>
							<tbody id='dataListBox'>
							</tbody>
						</table>
					</div>

				</div>
				<div className={'loadBox'}>
					<div className={'loadInner'}></div>
					<div className={'loadIcon'}>
						<Spin></Spin>
						<p>加载中...</p>
					</div>
				</div>
            </Layout>
        )
    }
}
