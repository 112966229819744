import React,{Component,useRef} from "react";
import {Button, Layout, Radio, Switch, Table, DatePicker, message, notification, Spin} from "antd";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import {getDataIgnoreLevel} from "../../../services/steamReport";
import {queryCustomerPointValuePage,queryCustomerPointValueAmount} from "../../../services/report";
import {ToTopOutlined} from '@ant-design/icons';
import './report.less'
import $ from "jquery";
const { RangePicker } = DatePicker;
export default class customerReport extends Component{
    state={

        noMsgJuder:false,
        selectDate:null,    // 时间选择类型
        areaInfos:[],       // 所有区域详细数据
        areaType:0,// 区域模式选择，0本区域，1本区域包括子区域

        tableData_1_head:[],    // 表格标头
        tableData_1_data:[],    // 表格数据
        // 表格参数
        tableData_1_param:{
            areaIds: [],
            granularity:null,
            page:1,
            size:10,
            total:0,
            totalNum:0
        },
        tableData_1_loading:false,

        divDisplay:'none',
        dateError:'',       // 时间框边框样式
        displayData:false,  // 类型选择
        // 条件选择器参数_1
        topFormItem_1: {
            modal: true,
            number: 2,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds', str: '区域选择', rules: TYPE_NAME.SELECTINPUT, data:[],
                },
                {
                    variableName: 'granularity', str: '时间类别',
                    onChange: (k,v) => this.onChangeSelect(k,v),
                    rules: TYPE_NAME.SELECTINPUT,
                    data: [
                        {key: '1', value: '时'},
                        {key: '2', value: '日'},
                        {key: '3', value: '月'},
                        {key: '5', value: '峰谷'}
                    ]
                },
            ]
        },
        // 条件选择器参数_1_参数
        topFormItem_data_1:[],
    };

    // 初始化地图加载
    componentDidMount() {
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        console.log(data[i]);
                        selectArry.push({key:data[i].id,value:data[i].name})
                    }
                }
                this.state.topFormItem_1.parameter[0].data=selectArry;
            }
        );
    }


    // 时间类别选择
    onChangeSelect=(k,v)=>{
        console.log('时间类别选择');
        console.log(v);
        this.setState({displayDate:'',selectDate:v.value});
    };
    // 日期选择
    getRangePicker=()=>{

        switch (this.state.selectDate) {

            case "3":
                return<>
                    <div>
                        <div>
                            <RangePicker
                                style={{border: this.state.dateError}}
                                // showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={"YYYY-MM"}
                                picker={"month"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
            default:
                return<>
                    <div>
                        <div>
                            <RangePicker
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={
                                    this.state.selectDate==='1'?"YYYY-MM-DD HH":
                                    this.state.selectDate==='2'?"YYYY-MM-DD":
                                        this.state.selectDate==='3'?"YYYY-MM":"YYYY-MM-DD"

                                }
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
        }

    };
    upDate=(d,v=[])=>{
        // 检查参数
        this.setState({dateError:''});
        let params = this.state.tableData_1_param;
        params.startTime=v[0];
        params.endTime=v[1];
        this.setState({tableData_1_param:params});
        if(v[0]===""||v[1]===""){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
        }
    };


    // 查询事件
    getConditionsValue= async value =>{
        console.log('查询事件');
        let data = await this.forms.onFinishNotReset();
        if(!data) return null;
        console.log(data);
        let params = this.state.tableData_1_param;
        // 判断参数
        if(!params?.startTime || !params?.endTime){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info("请选择时间范围")
        }
        params.areaIds=[];
        params.areaIds.push(data.areaIds);
        if(this.state.areaType==='1'){
            var areaIds = this.recursionAreas(data.areaIds);
            areaIds.map(one=>{params.areaIds.push(one);})
        }
        params.granularity=data.granularity;
        this.setState({tableData_1_param:params});
        this.queryCustomerPointValueAmount(params); // 获取当前客户合计
    };


    recursionAreas=(areaId)=>{
        var areaIds=[];
        for(var i=0;i<this.state.areaInfos.length;i++){
            if(this.state.areaInfos[i].parentId===areaId){
                areaIds.push(this.state.areaInfos[i].id);
                var params=[];
                params = this.recursionAreas(this.state.areaInfos[i].id);
                params?.map(one=>{areaIds.push(one)})
            }
        }
        return areaIds
    };

    // 获取当前客户合计
    queryCustomerPointValueAmount=(params)=>{
        var fparam={
            areaIds:params.areaIds,
            granularity:params.granularity,
            startTime:params.startTime,
            endTime:params.endTime,
            page:1,
            size:10
        };
        this.setState({tableData_1_loading:true,noMsgJuder:true});
        queryCustomerPointValueAmount(fparam).then(
            response=>{
                if(response?.data?.data){
                    var data = response.data.data;
                    var tableData = [...this.state.tableData_1_data];
                    tableData=[];
                    let param={name:data[data.length-1]?.name, allTimeTotal:data[data.length-1]?.allTimeTotal};
                    for(var a=0;a<data[data.length-1].reportList.length;a++){
                        // 起始时间
                        param['first'+a]=data[data.length-1]?.reportList[a]?.first;
                        // 截止时间
                        param['end'+a]=data[data.length-1]?.reportList[a]?.end;
                        // 数值
                        param['value'+a]=data[data.length-1]?.reportList[a]?.value!==null ?parseFloat(data[data.length-1].reportList[a].value).toFixed(2):null;
                    }
                    tableData.push(param);
                    this.setState({tableData_1_data:tableData,noMsgJuder:false});
                    this.queryCustomerPointValuePage(params);   // 查询的数据
                }else{
                    $('#noMsg').text('暂无数据');
                    this.setState({divDisplay:'none',tableData_1_loading:false,noMsgJuder:false})
                }
            }
        );
    };

    // 查询的数据
    queryCustomerPointValuePage=(params)=>{
        queryCustomerPointValuePage(params).then(
            response=>{
                if(response?.data?.data){
                    var data = response.data.data;
                    $('#noMsg').text('');
                    var tablehead = [...this.state.tableData_1_head];
                    var tableData = [...this.state.tableData_1_data];
                    // eslint-disable-next-line no-unused-expressions
                    tablehead=[];
                    tablehead.push({title: '客户名称', dataIndex: 'name', key: 'name',fixed: true,});
                    tablehead.push({title: '合计', dataIndex: 'allTimeTotal', key: 'allTimeTotal',});
                    var oneData=[];
                    for(var w=0;w<data?.length;w++){
                        if(data[w].reportList?.length!==0){
                            oneData=data[w].reportList;
                            break;
                        }
                    }
                    // 拼凑表头
                    if(this.state.displayData){
                        console.log('特色类型');
                        for(var i=0;i<oneData.length ;i++){
                            var param={title: oneData[i]?.time?oneData[i]?.time:'时段名获取失败',
                                children:[
                                    {title: '起始量', dataIndex: ('first'+i), key:('first'+i),render(a,b) {return a||0;}},
                                    {title: '结束量', dataIndex: ('end'+i), key:('end'+i),render(a,b) {return a||0;}},
                                    {title: '用量', dataIndex: ('value'+i), key:('value'+i),render(a,b) {return a||0;}},
                                ]};
                            tablehead.push(param);
                        }

                        console.log('拼凑数据');
                        for(var i=0;i<data.length;i++){
                            let param={name:data[i]?.name, allTimeTotal:data[i]?.allTimeTotal};
                            for(var q=0;q<data[i].reportList.length;q++){
                                param['first'+q]=data[i].reportList[q].first;
                                param['end'+q]=data[i].reportList[q].end;
                                param['value'+q]=data[i].reportList[q].value!==null
                                    ?parseFloat(data[i].reportList[q].value).toFixed(2):null;
                            }
                            tableData.push(param)
                        }

                    }else {
                        console.log('常规类型');
                        // 拼凑表头
                        for(var i=0;i<oneData.length ;i++){
                            tablehead.push({title: "("+oneData[i]?.time +")用量", dataIndex: 'value'+i, key:'value'+i,render(a,b) {return a||0;}});
                        }
                        console.log('拼凑数据');
                        for(var i=0;i<data.length;i++){

                            let param={name:data[i]?.name, allTimeTotal:data[i]?.allTimeTotal};
                            for(var q=0;q<data[i].reportList.length;q++){
                                param['value'+q]=data[i].reportList[q].value!==null?parseFloat(data[i].reportList[q].value).toFixed(2):null;
                            }
                            tableData.push(param)
                        }
                    }

                    var tableData_1_param = this.state.tableData_1_param;
                    // 增加表格数量总长度
                    tableData_1_param.totalNum=Math.round((response.data.pageInfo.total/10)+response.data.pageInfo.total);
                    tableData_1_param.total=response.data.pageInfo.total;
                    this.setState({
                        tableData_1_head:tablehead,
                        tableData_1_data:tableData,
                        tableData_1_param:tableData_1_param,divDisplay:'',
                        tableData_1_loading:false,noMsgJuder:false})
                }else{
                    $('#noMsg').text('暂无数据');
                    this.setState({divDisplay:'none',tableData_1_loading:false,noMsgJuder:false})
                }
            }
        );
    };

    // 表格单选事件
    rowSelection=()=>{

    };

    // 表格分页事件
    getPaginationRight=(page, pageSize)=>{
        console.log("获取当前页数和条数");
        let {tableData_1_param} = this.state;
        tableData_1_param.page = page;
        this.setState({tableData_1_param: tableData_1_param});
        this.queryCustomerPointValueAmount(tableData_1_param);
    };
    // 导出
    exportData=async ()=>{
        let data = await this.forms.onFinishNotReset();
        let tableDataParams = this.state.tableData_1_param;
        // 检验参数
        if(!tableDataParams?.startTime || !tableDataParams?.endTime){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info("请选择时间范围")
        }
        if(!data){
            return false;
        }
        if(!tableDataParams?.total){
            return message.info('请先查询要导出的数据')
        }
        var param= {
            total: tableDataParams.total,               // 总数

            areaIds: tableDataParams.areaIds,           // 地址id
            granularity: tableDataParams.granularity,   // 1:时，2:日，3：月，4：明细分析
            startTime: tableDataParams.startTime,       // 开始时间
            endTime: tableDataParams.endTime,           // 结束时间
            itemIdList: ['1184'],                       // 数据项id集合；用量：1184，温度：1205，压力：1206、1225，密度：1150，瞬时流量：1182,频率：1204
            type:'客户报表'	                            //类型 客户报表--用气点用量报表--表具用气量报表
        };
        console.log(tableDataParams.areaIds);
        var urlExport ="/gateway/web/exportStatisticalReportCtrl/reportExport";
        let link = `${urlExport}?`;
        link += `total=${tableDataParams.total}`;
        link += `&areaIds=${tableDataParams.areaIds}`;
        link += `&granularity=${tableDataParams.granularity}`;
        link += `&startTime=${tableDataParams.startTime}`;
        link += `&endTime=${tableDataParams.endTime}`;
        link += `&itemIdList=1184`;
        link += `&type=客户报表`;
        console.log(link);
        try{

            notification['warning']({
                message: '文件导出中',
                description: '文件未生成时，不允许多次点击按钮。'
            });
            window.open(link)

        } catch (error) {

        }
    };
    // 导出-特殊
    exportData_1=async ()=>{
        let data = await this.forms.onFinishNotReset();
        let tableDataParams = this.state.tableData_1_param;
        // 检验参数
        if(!tableDataParams?.startTime || !tableDataParams?.endTime){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info("请选择时间范围")
        }
        if(!data){
            return false;
        }
        if(!tableDataParams?.total){
            return message.info('请先查询要导出的数据')
        }
        var param= {
            total: tableDataParams.total,               // 总数

            areaIds: tableDataParams.areaIds,           // 地址id
            granularity: tableDataParams.granularity,   // 1:时，2:日，3：月，4：明细分析
            startTime: tableDataParams.startTime,       // 开始时间
            endTime: tableDataParams.endTime,           // 结束时间
            itemIdList: ['1184'],                       // 数据项id集合；用量：1184，温度：1205，压力：1206、1225，密度：1150，瞬时流量：1182,频率：1204
            type:'客户报表'	                            //类型 客户报表--用气点用量报表--表具用气量报表
        };
        console.log(tableDataParams.areaIds);
        var urlExport ="/gateway/web/exportStatisticalReportCtrl/reportExportStart";
        let link = `${urlExport}?`;
        link += `total=${tableDataParams.total}`;
        link += `&areaIds=${tableDataParams.areaIds}`;
        link += `&granularity=${tableDataParams.granularity}`;
        link += `&startTime=${tableDataParams.startTime}`;
        link += `&endTime=${tableDataParams.endTime}`;
        link += `&itemIdList=1184`;
        link += `&type=客户报表`;
        try{

            notification['warning']({
                message: '文件导出中',
                description: '文件未生成时，不允许多次点击按钮。'
            });
            window.open(link)

        } catch (error) {

        }
    };



    render() {

        return (
            <Layout  className={'item_body_number_back tables'}>
                {/**/}
                <div className={'topSearchBox'}>
                    <h3>区域客户用量报表</h3>
                    <div style={{width:'100%',height:'40px'}}>
                        {/*1*/}
                        <div style={{width:'15%' ,float:'left'}}>
                            <Radio.Group
                                defaultValue="0"
                                buttonStyle="solid"
                                onChange={(e)=>{
                                    console.log(e.target.value);
                                    this.setState({areaType:e.target.value})
                                }}>
                                <Radio.Button value="0">本区域</Radio.Button>
                                <Radio.Button value="1">本区域及子区域</Radio.Button>
                            </Radio.Group>
                        </div>
                        {/*2*/}
                        <div style={{width:'65%' ,float:'left'}}>
                            <div style={{width:'60%' ,float:'left'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem_1}
                                    data={this.state.topFormItem_1_data}
                                />
                            </div>
                            <div style={{width:'30%',float: 'left',display:this.state.displayDate,paddingLeft:'5px'}}>
                                {this.getRangePicker()}
                            </div>
                            <div style={{width:'10%',paddingLeft:'5px' ,float:'left'}}>
                                <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            </div>
                        </div>
                        {/*3*/}
                        <div  style={{width:'10%',float: 'left' }}>
                            <span>显示起始示值：</span>
                            <Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={(juder)=>{
                                this.setState({displayData:juder});
                                this.getConditionsValue()
                            }} />
                        </div>
                        {/*4*/}
                        <div  style={{width:'10%',float: 'left', textAlign: 'right'}}>
                            <Button  onClick={()=>{
                                if(this.state.displayData){
                                    this.exportData_1()// 特色表格
                                }else{
                                    this.exportData()   // 通常表格
                                }

                            }}><ToTopOutlined/>导出</Button>
                        </div>
                    </div>
                </div>
                {/**/}
                <div id={'eportPage'} style={{
                    width:'100%',
                    height:'100%',
                    textAlign: 'center',
                    backgroundColor: '#fff',
                    display:this.state.divDisplay,
                    // overflowX: 'auto'
                }}>
                    <Table
                        rowClassName={(record,index)=>{

                            if(record.name==='合计'){
                                return 'tableColorDiv'
                            }
                        }}
                        columns={this.state.tableData_1_head}
                        dataSource={this.state.tableData_1_data}
                        // rowKey={"id"}
                        // bordered
                        loading={this.state.tableData_1_loading}
                        scroll={{x: 'auto' }}
                        pagination = {{
                            showSizeChanger:true,
                            showQuickJumper: true,
                            onChange: this.getPaginationRight,       // 获得翻页数值
                            total:this.state.tableData_1_param.total,
                            pageSize:11,
                            showTotal: (count = this.state.tableData_1_param.total)=>{
                                return '共'+this.state.tableData_1_param.total+'条数据'
                            },
                            defaultCurrent: 1,
                            pageSizeOptions: ['11']
                        }}
                    >
                    </Table>
                </div>
                <div id='noMsg' style={{  display:this.state.noMsgJuder? 'none':null,width:'100%', fontSize:'30px',textAlign:'center', overflow:'auto', color:'#ccc',}}>
                    请先选择需要对比的时间类型，再选择时间范围
                </div>
                <div style={{display:this.state.noMsgJuder? null:'none',width:'100%', fontSize:'30px',textAlign:'center', overflow:'auto', color:'#ccc',}}>
                    <Spin />加载中
                </div>
            </Layout>
        );
    }
}
