import React, { Component } from 'react'
import {DoubleRightOutlined,DoubleLeftOutlined,ExclamationCircleOutlined,CloseCircleOutlined,PlusOutlined} from '@ant-design/icons';
import './mapManagePage.less'

import {Button, message, Modal, Popconfirm,} from "antd";
import {TYPE_NAME} from "../../components/Const";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {addPiPingDev, getInfoByAreaIds, removePiPingDev, updatePiPingDev} from "../../services/customer";
import * as echarts from "echarts";
import {getDateByUserId} from "../../services/admin";
import * as BMap from "react-bmap";
export default class mapManagePage extends Component {
    state={
        visible_1:false,    // 弹窗开关-1
        selectOneType:null, // 左表点击样式
        coordinateData:[],  // 左表数据
        allCoordinateShow:[],// 展示所有坐标
        rowData:null,       // 操作修改等选择的数据
        clickData:[],       // 点击所选数据用于地图展示
        coordinate:[112.96168,28.190913],// 默认坐标-目前没多大用
        // 区域信息
        areaInfo:{
            areaId:200,
            areaName:'未知区域',
        },

        // 弹窗表单数据
        formParam:{
            modal: true,
            number: 1,
            labelCol: 5,
            layout: "horizontal",
            parameter: [

                {variableName: 'name', str: '管线名称', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'coordinateData', str: '坐标数据', rules: TYPE_NAME.TEXT_AREA_REQ,maxLength:180},
            ]
        },
        formParamData:{},
        // 顶部表单
        formParam_1:{
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'areaId', str: '所属区域',     rules: TYPE_NAME.REQ_TREE_SELECT, disabled: false,
                    fieldNames:{label: 'name', value: 'id', children: 'children'},
                    data:[],
                    onChange: (e,a) => this.onChangeIntervalType_1(e,a),
                },
            ]
        },
        formParamData_1:{}
    };
    componentDidMount(): void {
        this.getInfoByAreaIds([200]); // 根据区域ID查询管道信息
        this.getDateByUserId();              // 区域列表
    };

    // 地图,参数选取列表的数据,data全部数据,coordinate坐标
    valueMap= async (data=[],coordinate=[],e=[])=>{
        var chartDom = echarts.init(document.getElementById('chartDomMapId'));
        var xy=this.state.coordinate;                       // 默认坐标

        if(e.length!==0){xy=[e[0][0].y,e[0][0].x]} // 判有无管道图获取第一个管道坐标.
        else if(data.length!==0){xy=[data[0][0].y,data[0][0].x]} // 判有无管道图获取第一个管道坐标.
        else if(coordinate.length!==0){xy = coordinate}
        // 数据调整
        const lines_1 = data.map(function (track) {
            return {
                coords: track.map(function (seg, idx) {
                    return [seg.y,seg.x];
                })};
        });
        // 数据调整
        const lines_2 = e.map(function (track) {
            return {
                coords: track.map(function (seg, idx) {
                    return [seg.y,seg.x];
                })};
        });
        await chartDom.setOption(
            {
                animation: false,
                bmap: {center: xy, zoom: 16, roam: true,},
                series: [
                    {
                        type: 'lines',
                        coordinateSystem: 'bmap',
                        data:  lines_1.map(function (pipe) {
                            return {
                                coords: pipe.coords,
                                lineStyle: {
                                    width: 3,
                                    opacity: 10,
                                    color: 'green',
                                }
                            };
                        }),
                        polyline: true,
                        // lineStyle: {color: 'purple', opacity: 1, width: 3}
                    },
                    {
                        type: 'lines',
                        coordinateSystem: 'bmap',
                        data:  lines_2.map(function (pipe) {
                            return {
                                coords: pipe.coords,
                                lineStyle: {
                                    width: 7,
                                    opacity: 2,
                                    color: 'rgba(255,34,57,0.8)',
                                }
                            };
                        }),
                        polyline: true,
                        // lineStyle: {color: 'purple', opacity: 1, width: 3}
                    }

                ]

            });
        // 窗口大小变化时，ECharts地图自适应
        window.onresize = function() {
            chartDom.resize();
        };
    };

    // 获取区域
    getDateByUserId=()=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    var formParam_1 = this.state.formParam_1;
                    var coordinate=[];
                    formParam_1.parameter[0].data=response?.data;
                    if(response?.data[0]?.longitude && response?.data[0]?.latitude ){
                        coordinate=[response?.data[0]?.longitude,response?.data[0]?.latitude];
                        this.valueMap([],coordinate);
                    }
                    this.forms_1.setFieldsValue('areaId',response?.data[0].id);
                    this.setState({
                        formParam_1:formParam_1,
                        areaInfo:{
                            areaId:response?.data[0].id,
                            areaName:response?.data[0].name
                        },
                    })
                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };
    // 循环获取所选区域的数据
    forTreeArea = (data,key=String,areaInfo) =>{
        data.map(one=>{
            if(one.id.toString().indexOf(key)!==-1){
                areaInfo = one;
            }else if(one?.children){
                this.forTreeArea(one?.children,key,areaInfo);
            }
        });
        return areaInfo;
    };

    // 区域列表选择事件
    onChangeIntervalType_1 =  (e,a,c) =>{
        var formParam1 = this.state.formParam_1;
        var coordinate=[];
        var areaInfo = null;
        areaInfo = this.forTreeArea(formParam1.parameter[0].data,e,areaInfo);
        if(areaInfo?.longitude && areaInfo?.latitude ){
            coordinate=[areaInfo?.longitude,areaInfo?.latitude];
        }else{
            coordinate = [112.96168,28.190913]
        }

        this.setState({areaInfo: {areaId:e, areaName:a[0]},coordinate:coordinate});

        this.valueMap([],coordinate);
        this.getInfoByAreaIds([e]);// 查询列表数据
    };

    //左侧-编译数据列表
    getTableTree=(coordinateData=[])=>{
        console.log(coordinateData);
        if(coordinateData.length===0){
            return(
                <div style={{width:'100%',height:'100%',backgroundColor:'rgba(255, 255, 255, 0.4)',paddingTop:"40%",textAlign: 'center'}}>
                    <span style={{fontWeight: 'bold',fontSize:'18px'}}>暂无数据</span>
                </div>
            )
        }
        return coordinateData.map(one=>{
            return(
                <div className={one?.id===this.state.selectOneType?'selectOneType':null}
                     style={{
                         width:'100%',height:'50px',marginTop:'5px',padding:'0 10px 10px 10px',
                         border: '1px rgba(141, 197, 255,0.5) solid',
                         backgroundColor:one?.id===this.state.selectOneType?'rgba(10,180,29,0.2)':'#fff',
                     }}
                     onClick={()=>{
                         var clickData = this.state.clickData;
                         clickData=[];
                         // 验证当前选择数据是否符合要求，符合条件就展示
                         if(this.ValidateData(one.coordinateData??null)){
                             clickData=JSON.parse(one.coordinateData);
                         }
                         this.valueMap(this.state.allCoordinateShow,[],clickData);  // 刷新地图
                         this.setState({selectOneType:one?.id,clickData:clickData})}}
                >
                    <div style={{width:'30%',height:'100%',float:'left',}}/>
                    <div style={{width:'40%',height:'100%',float:'left' }}>
                        <div style={{width:'100%',height:'25px'}}>
                            <span style={{fontWeight: 'bold'}}>名称：</span><span>{one?.name}</span>
                        </div>
                        <div style={{width:'100%',height:'25px'}}>
                            <span style={{fontWeight: 'bold'}}>样式：</span><span>{one?.style}</span>
                        </div>
                    </div>
                    <div style={{width:'25%',height:'100%',float:'left'}}>
                        <div className={'spen_css_1'}
                             onClick={async ()=>{
                                 await this.setState({rowData:one, visible_1:true,});
                                 this.forms.setFieldsValues({
                                     "coordinateData": one?.coordinateData??null,
                                     "name": one?.name??null,
                                 });
                             }}
                        >
                            <span>修改</span>
                        </div>
                        <div className={'spen_css_2'}
                             >
                            <Popconfirm title="是否删除?"
                                        onConfirm={()=>{
                                            this.setState({rowData:null,});
                                            this.removePiPingDev(one?.id??null);
                                        }}
                                        >
                                <span>删除</span>
                            </Popconfirm>
                        </div>
                    </div>
                </div>
            )
        })
    };

    // 拼凑数据 data：数据
    piecingCoordinate = (data=[]) =>{
        var coordinateDatas=[];
        data.map(one=>{
            if(this.ValidateData(one.coordinateData)){
                coordinateDatas.push(JSON.parse(one.coordinateData)[0])
            }
        });

        this.valueMap(coordinateDatas);                     // 地图
        this.setState({allCoordinateShow:coordinateDatas})
    };

    // 验证数据格式是否合适
    ValidateData=(data)=>{
        try{
            if(data ==="" && data === null){return false;}
            if(data.indexOf('[')<0){return false;}
            var params = JSON.parse(data); // 字符串转数据对象
            // 判断当前对象是否数组
            if(!Array.isArray(params)){return false;}
            // 判断对象第二层是否数组
            for(var i=0;i<params.length;i++){if(!Array.isArray(params[i])){

                return false;
            }}
            return true;
        }catch (e) {return false;}
    };

    // 添加管道数据
    addPiPingDev = async (rowData) => {
        var data = await this.forms.onFinish();
        if(!data)return false;

        var params = {
            'areaId':this.state.areaInfo.areaId,
            "coordinateData": data?.coordinateData??null,
            "id": rowData?.id??null,
            "name": data?.name?data?.name:null,
            "style": '默认'
        };
        this.setState({visible_1:false,rowData:null});
        (rowData?updatePiPingDev:addPiPingDev) (rowData?params:[params]).then(
            response=>{
                if(response.data.data){
                    message.info('添加成功');
                    this.getInfoByAreaIds([this.state.areaInfo.areaId]);
                }
            }
        )
    };

    // 删除管道数据
    removePiPingDev=(id)=>{
        if(!id)return message.info('请选择要删除数据');
        removePiPingDev({id:id}).then(
            response=>{
                if(response.data.data){message.info('删除成功')}else{message.info('删除失败')}
                this.getInfoByAreaIds([this.state.areaInfo.areaId]);
            }
        )
    };

    // 根据区域ID查询管道信息
    getInfoByAreaIds=(areaIds=[],name)=>{
        var params =  new URLSearchParams();
        params.append('areaIds',areaIds??null);
        // params.append('name',name??null);
        getInfoByAreaIds(params).then(
            response=>{
                console.log('根据区域ID查询管道信息');
                var coordinateData = this.state.coordinateData;
                coordinateData=[];
                if(response?.data?.data){
                    response?.data?.data.map(one=>{
                        coordinateData.push({
                            "coordinateData":  one.coordinateData??null,
                            "id": one.id??null,
                            "name":  one.name??null,
                            "style":  one.style??null,
                        });
                    });

                }
                this.piecingCoordinate(coordinateData);

                this.setState({coordinateData:coordinateData});
            }
        )
    };


    render() {
        return (

            <div id={'mapManagePageBody'}>
{/*顶部样式-------------------------------*/}
                <div style={{  width: '100vw',height: '50px', opacity: '0.9', backgroundColor:'#fff',position: 'absolute', zIndex: '999'}}>
                    <div style={{  width: '20%', height: '100%', padding: '5px 10px 0 0',float: 'left'}}>
                        <FormsTwo
                            ref={e => this.forms_1 = e}
                            formParamenter={this.state.formParam_1}
                            data={this.state.formParamData_1}
                        />
                    </div>
                    <div style={{  width: '65%', height: '100%',  float: 'left'}}>
                    </div>
                    <div style={{  width: '10%', height: '100%',float: 'left'}}>
                    </div>
                </div>
{/*左框样式-------------------------------*/}
                <div className={'body_left_css_1'}>
                    {/*顶部*/}
                    <div style={{width: '100%',height: '6%',padding:'4px'}}>
                        <div style={{  width: '75%', height: '100%', float: 'left',padding:' 0 0 0 10px'}}>
                            <span style={{fontSize:"15px",fontWeight: 'bold'}}>{this.state.areaInfo?.areaName}</span>
                        </div>
                        <div style={{  width: '25%', height: '100%', float: 'left'}}>
                            <Button type={'primary'}
                                    icon={<PlusOutlined />}
                                    size={'small'}
                                    onClick={async ()=>{
                                    await this.setState({visible_1:true,rowData:null});
                                    this.forms.onModalClose();
                                }}
                            />
                        </div>
                    </div>
{/*内容-----------------------------------------------------------*/}
                    <div style={{width: '100%',height: '92%', overflowY: 'auto'}}>
                        {this.getTableTree(this.state.coordinateData)}
                    </div>
                </div>
                <div id={'chartDomMapId'} style={{width:'100vw',height:'100vh',zIndex:'9',}}/>

{/*弹窗-----------------------------------------------------------*/}
                <div style={{zIndex:'99999', position: 'absolute'}}>
                    <Modal
                        maskClosable={true}
                        closable={true}
                        visible={this.state.visible_1}
                        onOk={ async () => {this.setState({visible_1:false})}}           	// 点击确定
                        onCancel={() => this.setState({visible_1:false})}	// 点击取消
                        width={550}
                        centered
                        footer={[
                            <Button onClick={()=>{this.addPiPingDev(this.state.rowData);}}>保存</Button>
                            ,
                            <Button onClick={()=>{this.setState({visible_1:false,rowData:null})}}>取消</Button>,
                        ]}
                    >
                        <div style={{width:'100%',height:'100%',paddingTop:'20px'}}>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.formParam}
                                data={this.state.formParamData}
                            />
                        </div>

                    </Modal>
                </div>
            </div>
        )
    }
}
