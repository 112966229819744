import { Component  } from 'react';

import { Modal,  Input, Form, Button, Col, Row, Select, DatePicker,message,InputNumber  } from 'antd';

import {FORM_ITEM,RULE} from '../../Const'

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const { Option } = Select;

class Modals extends Component {

    getFromData = e => {
    }
   
    // 获取表单数据
    onFinish = async () => {
        const values = await this.form.validateFields()
        this.props.getFormValue(values)
        this.props.onModalClose()
        this.form.resetFields()
        message.success('提交成功')
    }

    onFinishFailed = (errorInfo) => {
        message.error('填写未完成或格式不正确')
        message.error('提交失败')
    };

    onModalClose = () => {
        this.form.resetFields()
        this.props.onModalClose()
    }

    // 根据类型返回相应的item（可以考虑直接从父组件使用对象形式传来，那么这段代码就可以省略）
    // 时间组件宽度不知道为什么失效，所以手动设置值为100%
    getItem = (type,str,data,itemSize) => {
        switch (type) {
            case FORM_ITEM.PASSWORD:
                return <Input.Password placeholder={`输入${str}`}/>
            case FORM_ITEM.INPUT:
                return  <Input placeholder={`输入${str}`} size={itemSize}/>
            case FORM_ITEM.INPUT_NUMBER:
                return  <InputNumber style={{width: '100%'}} placeholder={`输入${str}`} size={itemSize}/>
            case FORM_ITEM.RANGE_PICKER:
                return  <RangePicker style={{width: '100%'}} placeholder={['开始时间','结束时间']} size={itemSize}/>
            case FORM_ITEM.DATE_PICKER:
                return  <DatePicker style={{width: '100%'}} placeholder={'选择时间'} size={itemSize}/>
            case FORM_ITEM.TEXT_AREA:
                return  <Input.TextArea style={{verticalAlign: 'middle'}} rows={4} placeholder={str} size={itemSize}/>
            case FORM_ITEM.SELECT:
                return  <Select placeholder={`选择${str}`} size={itemSize}>
                            {
                                data.length > 0 ? data.map(option => {
                                    return <Option key={option.key} value={option.key}>{option.value}</Option>
                                }) : null
                            }
                        </Select>
            default:
                return <Input placeholder={`输入${str}`} size={itemSize}/>
        }
    }

    // 设置表单中的数据
    setFormItem = parameter => {
        parameter = parameter ? parameter : []
        const {number,itemSize} = this.props.modalParamenter ? this.props.modalParamenter : {}
        let rowItem = []
        // 列的默认长度
        let span = 24
        // 为下方循环的变量提前定义
        let [nextVariableName,nextStr,nextData,nextRules] = []
        for (let i = 0,len = parameter.length; i < len; i++) {
            // 当前变量的数据
            let {variableName,str,data,rules} = parameter[i]
            // 下一条数据的变量。i+1会超出数组长度，所以进行判断
            if(i+1 < len) [nextVariableName,nextStr,nextData,nextRules] = [
                parameter[i+1].variableName,
                parameter[i+1].str,
                parameter[i+1].data,
                parameter[i+1].rules
            ]
            let rule = RULE[rules]
            let nextRule = RULE[nextRules]
            if(number === 2){
                // 每次循环都会更新该变量，所以需要每次重新进行赋值
                span = 12

                // 如果当前item为文本域，则该列的长度为24
                if(rule.type === 'textArea') span = 24 
            }
            // 将要显示的数据放入数组中，该数组直接返回，用于展示元素。
            // row：行，col：列，基本为一行两列，如果为文本域，则独占一行
            rowItem.push(
                <Row gutter={24} key={variableName}>
                    <Col span={span} key={i}>
                        <FormItem
                            name={variableName}
                            label={str}
                            rules={rule['rules']}
                        >
                            {this.getItem(rule['type'],str,data,itemSize)}
                        </FormItem>
                    </Col>

                    {/* 第一列或者第二列为文本域时，结束当前行。不是文本域则添加第二列，并进行++i操作 */}
                    {
                        i+1 === len || rule['type'] === 'textArea' || nextRule['type'] === 'textArea' || number === 1 ? null : (
                            <Col span={span} key={++i}>
                                <FormItem
                                    name={nextVariableName}
                                    label={nextStr}
                                    rules={nextRule['rules']}
                                >
                                    {this.getItem(nextRule['type'],nextStr,nextData,itemSize)}
                                </FormItem>
                            </Col>
                        )
                    }
                </Row>
            )
        }
        return rowItem
    }

    render() {

        const {parameter,title,width} = this.props.modalParamenter ? this.props.modalParamenter : {}
        
        return (
            <div>
                <Modal title={title} 
                    centered={true}
                    width={width ? width+'vw' : '35vw'}
                    visible={this.props.isModalVisible} 
                    onCancel={this.props.onModalClose}
                    footer={
                        <div style={{textAlign: 'right' }}>
                            <Button onClick={this.onModalClose} style={{ marginRight: 8 }}>
                                取消
                            </Button>
                            <Button key='submit' type="primary" htmlType="submit" onClick={this.onFinish}>
                                提交
                            </Button>
                        </div>
                    }
                >
                    <Form
                        layout="vertical"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        ref={e => this.form = e}
                    >
                        {this.setFormItem(parameter)}
                    </Form>
                </Modal>
            </div>
        )
    }
    }

export default Modals;