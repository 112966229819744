import React, { Component } from 'react'
import {Layout} from "antd";
import './bottomTwo.less'
import BasicLineChart from '../assembly/BasicLineChart'
import BarChart1 from "../assembly/BarChart_1";
export default class bottomTwo extends Component {
    state = {
        // 使用情况 最近七日曲线图
        leftData_eCharts:{
            name:"抽查合格率",
            // 数值
            data:[
                {name:'探测器',data:[170,200,120,210,160,140,170],color:['#67ff96','#b3ffc2']},
                {name:'用气设备',data:[150,70,220,140,90,90,210],color:['#FFA06C','#FFC081']},
                {name:'压力表',data:[70,140,250,60,110,60,150],color:['#4972ff','#878eff']},
                {name:'阀门',data:[130,190,210,130,220,230,120],color:['#2fe1ff','#95e2ff']},
            ],
            yData:['1月','2月','3月','4月','5月','6月','7月'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:50} // 最小、最大、间距
        },
        leftData_eCharts_1:{
            // 数值
            data:[
                {name:'燃气',data:[{name:'燃气泄露',data:[5,20,60,30,40]},{name:'燃气使用',data:[5,20,60,30,40]}]},
                {name:'液化',data:[{name:'液化泄露',data:[5,20,60,30,40]},{name:'液化使用',data:[5,20,60,30,40]}]},

            ],
            yData:['岳麓','芙蓉','雨花','天心','开福','宁乡','浏阳'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:100} // 最小、最大、间距
        },
    };


    render() {
        return (
            <div id={'bottomTwo'}>
                <div style={{ width: '100%',height: '20%', padding: '20px 0 0 30px'}}>
                    <span style={{ fontSize: '22px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>设备生命周期</span>
                </div>
                <div style={{ width: '100%',height: '80%'}}>
                    {/*上部分*/}
                    <div style={{ width: '100%',height: '55%'}}>
                        {/*1*/}
                        <div style={{ width: '100%',height: '45%'}}>
                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'10%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_1'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>7589</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>工业探测器</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_2'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>8920</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>家用探测器</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_3'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>18068</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>计量表具</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_4'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>45</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>调压器</span>
                                </div>
                            </div>
                        </div>
                        {/*2*/}
                        <div style={{ width: '100%',height: '45%',marginTop:'3%'}}>
                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'10%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_1'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>4329</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>阀门</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_2'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>8987</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>气瓶</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_3'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>235</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>阴极保护</span>
                                </div>
                            </div>

                            <div style={{ width: '15%',height: '100%',float:'left',marginLeft:'6%'}}>
                                <div style={{width:'100%',height:'75%'}}>
                                    <div className={'bottomTwo_data_div_1_img_1'}>
                                        <div style={{width:'100%',height:'20px', textAlign:'center'}}>
                                            <span style={{color:'#fff'}}>6898</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%',textAlign:'center'}}>
                                    <span style={{fontSize:'12px',color:'#fff'}}>用气设备</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*下部分*/}
                    <div style={{ width: '100%',height: '40%'}}>
                        <BasicLineChart
                            elementById={'bottomTwo_1'}
                            name={this.state.leftData_eCharts.name}
                            data={this.state.leftData_eCharts.data}    // 数值
                            yData={this.state.leftData_eCharts.yData}  // X轴数据
                            number={this.state.leftData_eCharts.number}// 最小、最大、间距
                        />

                    </div>
                </div>
            </div>
        )
    }
}
