import ajax from './ajax'





// 获取安装列表
export const getInstallationLedgerInfoInCondition = data => ajax('/businesse/pcInstall/getInstallationLedgerInfoInCondition', data, 'POST');

// 获取维修列表
export const queryDevMaintenance = data => ajax('/businesse/maintenance/queryDevMaintenance', data, 'POST');

// 获取换表列表
export const queryReplaceDeviceRecord = data => ajax('/businesse/replaceDevice/queryReplaceDeviceRecord', data, 'POST');

// 获取首检台账列表
export const pcGetBooksByCon = data => ajax('/businesse/pcFirstBook/pcGetBooksByCon', data, 'POST');

// 获取首检台账子表
export const pcGetDevicesByBookId = data => ajax('/businesse/pcFirstBook/pcGetDevicesByBookId', data, 'GET');





// 获取安检列表
export const getSecurityLst = data => ajax('/businesse/PcSecurityCtrl/getSecurityLst', data, 'POST');
//获取安检详情
export const getSecurityOne = data => ajax('/businesse/PcSecurityCtrl/getSecurityOne', data, 'GET');

// 获取报废列表
export const getScrapBookLst = data => ajax('/businesse/pcScrap/getScrapBookLst', data, 'POST');
//获取报废详情
export const getScrapBookInfo = data => ajax('/businesse/pcScrap/getScrapBookInfo', data, 'GET');
//获取报废原因下拉
export const getScrapCause = data => ajax('/businesse/pcScrap/getScrapCause', data, 'GET');

//获取报废公司下拉
export const getCompany = data => ajax('/businesse/pcScrap/getCompany', data, 'GET');

// 获取送检列表
export const getSubmitInfoInCondition = data => ajax('/businesse/pcSubmit/getSubmitInfoInCondition', data, 'POST');
//获取发起公司下拉
export const getInitCompany = data => ajax('/businesse/pcSubmit/getInitCompany', data, 'GET');
//获取送检详情
export const getSendDetectDetail = data => ajax('/businesse/pcSubmit/getSendDetectDetail', data, 'GET');

















//获取工程公司下拉
export const getEngineeringCompany = data => ajax('/businesse/pcInstall/getEngineeringCompany', data, 'GET');


//获取安装详情
export const getInitInfoByInitId = data => ajax('/businesse/pcInstall/getInitInfoByInitId', data, 'GET');

// 查询所有厂商和型号
export const getAllManufacturerAndModel = data => ajax('/web/deviceCtrl/getAllManufacturerAndModel', data, 'GET')






// 证书解析
export const ocr = data => ajax('/businesse/ocr/certProcess', data, 'POST')

// 查询所有厂商和型号
export const updateCert = data => ajax('/businesse/ocr/updateCert', data, 'POST')

// 删除证书记录
export const removeCert = data => ajax('/businesse/ocr/removeCert', data, 'GET')















