import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag} from 'antd'
import { NavLink } from "react-router-dom"
import moment from 'moment'
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,CloseOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {assignItem,queryReportConfig,getAllItem} from '../../../services/device'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表规则配置",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				name: '报表名称	',
				enName: '数据库表名	',
				startTime: '报表开始统计时间',
				isCrossSky: '是否跨天',
				customStartTime: '自定义每天开始时间',
				customEndTime: '自定义每天结束时间	',
				cron: '定时任务执行时间	',
				yearStartTime: '年开始时间	',
				type: '统计类型	',
				edit: '操作',
			},
			// 弹出框
			headers: {
				name: '报表名称	',
				enName: '数据库表名	',
				startTime: '报表开始统计时间',
				isCrossSky: '是否跨天',
				customStartTime: '自定义每天开始时间',
				customEndTime: '自定义每天结束时间	',
				cron: '定时任务执行时间	',
				yearStartTime: '年开始时间	',
				type: '统计类型	',
				dataItemInfoIdList:'数据项id',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true
		},

		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'name',
					str: '报表名称'
				}
				
			]
		},
		    	// 总条数
		totalCount: 0,
		ReportConfigVisible:false, // 小窗口开关器-1
		showReportConfigVisible:false, // 小窗口开关器-1
		detailReportConfigVisible:false, // 小窗口开关器-1
		tabelBox:'none',
		qrId:'',
		oldCode:'',
		modTitle:'添加规则',
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 2,
            labelCol: 7,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'name',
					str: '报表名称',
					rules: TYPE_NAME.NOT_BLANK,
				},
				{
					variableName: 'enName',
					str: '数据库表名',
					rules: TYPE_NAME.TEXT_EN,
				},
				{
					variableName: 'dataItemInfoIdList',
					str: '数据指标',
					isMultiple:true,
					data:[],
					rules: TYPE_NAME.SELECTINPUT,
				},
				{
					variableName: 'type',
					str: '统计类型',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: '1',
							value: '累计值'
						},
						{
							key: '2',
							value: '平均值'
						}
					]
				},
				{
					variableName: 'customStartTime',
					str: '开始时间',
					rules: TYPE_NAME.ID_NUMBER,
				},
				{
					variableName: 'isCrossSky',
					str: '是否跨天',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: '1',
							value: '是'
						},
						{
							key: '0',
							value: '否'
						}
					]
				},
				{
					variableName: 'customEndTime',
					str: '结束时间',
					rules: TYPE_NAME.ID_NUMBER,
				},
				{
					variableName: 'startTime',
					str: '开始统计时间',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'cron',
					str: '定时执行时间',
					rules: TYPE_NAME.ID_NUMBER,
				},
				{
					variableName: 'yearStartTime',
					str: '年开始时间',
					rules: TYPE_NAME.ID_NUMBER,
				},
			]
		},
		ModalFormParamenter2: {
			modal: true,
			number: 2,
            labelCol: 7,
			layout: "horizontal", 
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'name',
					str: '报表名称',
					disabled: true,
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'enName',
					str: '数据库表名',
					disabled: true,
					rules: TYPE_NAME.TEXT_EN,
				},
				{
					variableName: 'dataItemInfoIdList',
					str: '数据指标',
					disabled: true,
					isMultiple:true,
					data:[],
					rules: TYPE_NAME.SELECTINPUT,
				},
				{
					variableName: 'type',
					str: '统计类型',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: '1',
							value: '累计值'
						},
						{
							key: '2',
							value: '平均值'
						}
					]
				},
				{
					variableName: 'customStartTime',
					str: '开始时间',
					disabled: true,
					rules: TYPE_NAME.ID_NUMBER,
				},
				{
					variableName: 'isCrossSky',
					str: '是否跨天',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: '1',
							value: '是'
						},
						{
							key: '0',
							value: '否'
						}
					]
				},
				{
					variableName: 'customEndTime',
					str: '结束时间',
					disabled: true,
					rules: TYPE_NAME.ID_NUMBER,
				},
				{
					variableName: 'startTime',
					str: '开始统计时间',
					disabled: true,
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'cron',
					str: '定时执行时间',
					disabled: true,
					rules: TYPE_NAME.ID_NUMBER,
				},
				{
					variableName: 'yearStartTime',
					str: '年开始时间',
					disabled: true,
					rules: TYPE_NAME.ID_NUMBER,
				},

			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'isCrossSky':
				text = text*1;
				return text === 1 ? '是' : text === 0 ? '否' : '-';
			case 'type':
				text = text*1;
				return text === 1 ? '累计值' : text === 2 ? '平均值' : '-';
			case 'edit':
				return <>
				<div className={'tableLink'} title={'查看详情'} onClick={() => this.showReportConfigStatus(true,rowData)}>
						<u>{'查看详情'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}


	updatePoint = (ReportConfigVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({ReportConfigVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		console.log(this.state.params)
		getAllItem().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.ModalFormParamenter.parameter[2].data=selectArry;
			}
		)
	}
	onChangeSelect = (k,v) => {
		console.log(k,v)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		Object.assign(params,value)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.ReportConfig('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	
	//添加设备窗口显示
	addReportConfigStatus = async (ReportConfigVisible)=>{
		await this.setState({ReportConfigVisible,data:{},modTitle:'添加规则'})
		await this.forms.onModalClose()
    }

		//添加设备窗口显示
	showReportConfigStatus = (showReportConfigVisible,rowData)=>{
		let allArry
		let nameArry=[]
		getAllItem().then(
			response => {
				const {data} = response.data
				allArry=data
				if(allArry.length>0){
					if(rowData.dataItemInfoIdList){
						for(var i=0;i<allArry.length;i++)
						{
							for(var j=0;j<rowData.dataItemInfoIdList.length;j++){
								if(rowData.dataItemInfoIdList[j]==allArry[i].id||rowData.dataItemInfoIdList[j]==allArry[i].name)
								{
									console.log(allArry[i].name)
									nameArry.push(allArry[i].name)
								}
							}
						}
					}
					rowData.dataItemInfoIdList=nameArry
					this.setState({showReportConfigVisible:showReportConfigVisible,data:rowData})
					this.forms.onModalClose()
				}
			}
		)

    }
	hideReportConfigStatus = (showReportConfigVisible)=>{
		this.setState({showReportConfigVisible:showReportConfigVisible})
    }
	
	addReportConfigData = async (value)=>{
			let devData = await this.forms.onFinishNotReset()
			if(!devData) return null
			console.log(devData.startTime)
			devData.startTime=moment(devData.startTime).format("YYYY-MM-DD HH:mm:ss")
			console.log(devData.startTime)
			assignItem(devData).then(data=>{
				if(data.data.data==true){
					message.config("修改成功")
					funTraceInfo("设备管理", "配置需要生成报表的数据项", null,devData)
					this.addReportConfigStatus(false)
				}else{
					this.addReportConfigStatus(false)
					message.config('修改失败')
	
				}
				this.table.refreshTable();// 刷新表格
			});

	}; 
	

	onRowData = (record) => {
		if(record.isCrossSky){
			if(record.isCrossSky=='1')
			{
				record.isCrossSky='是'
			}else if(record.isCrossSky=='0')
			{
				record.isCrossSky='否'
			}
		}
		if(record.type){
			if(record.type=='1')
			{
				record.type='累计值'
			}else if(record.type=='2')
			{
				record.type='平均值'
			}
		}		
		return {
			onRowData: record
		}
	}


	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>报表规则配置</h3>
				<Conditions 
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>		
				<div className={'topBtnBox'}>
					<span className={'addLink'} onClick={() => this.addReportConfigStatus(true)}><PlusSquareOutlined />新增</span>
					{/* <span className={'addLink'} onClick={() => this.updatePoint(true)}><EditOutlined />修改</span> */}
					
				</div>	
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {queryReportConfig}
					setTableValue={this.setTableValue}
					onRowData = {this.onRowData}
					ref={e => this.table = e} 
				>
				</TableComponents>
					<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.ReportConfigVisible}
                        okText="确定"
                        onOk={() => this.addReportConfigData(true)}           // 点击确定
                        onCancel={() => this.addReportConfigStatus(false)}       // 点击取消
                        width={1000}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.ModalFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>
					<Modal
                        title='查看规则'
                        style={{ top: 0 }}
                        visible={this.state.showReportConfigVisible}
                        okText="确定"
                        onOk={() => this.hideReportConfigStatus(false)}           // 点击确定
                        onCancel={() => this.hideReportConfigStatus(false)}       // 点击取消
						footer={null}
                        width={1000}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.ModalFormParamenter2} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
