import ajax from './ajax'





// 获取计划列表
export const getVerPlanLst = data => ajax('/businesse/PcVerPlanCtrl/getVerPlanLst', data, 'POST');

// 创建计划
export const createVerPlan = data => ajax('/businesse/PcVerPlanCtrl/createVerPlan', data, 'POST');

// 周检到期
export const findVerMeterWeek = data => ajax('/businesse/PcWeeklyInspectionCtrl/findVerMeter', data, 'POST');





//删除计划
export const updataVerPlan = data => ajax('/businesse/PcVerPlanCtrl/updataVerPlan', data, 'GET')

// 查询检定计划表具
export const findVerMeter = data => ajax('/businesse/PcVerPlanCtrl/findVerMeter', data, 'POST');


//检定首页
export const listCertByCon = data => ajax('/businesse/ocr/listCertByCon', data, 'POST');

//获取安检详情
export const getCertDetail = data => ajax('/businesse/ocr/getCertDetail', data, 'GET');


//获取安检详情
export const cerReportExport = data => ajax('/newAlarm/exportStatisticalReportCtrl/cerReportExport', data, 'POST');

























