import React, {Component} from 'react'
import {Layout, Input, Button, Checkbox, TreeSelect, Spin, Tag, Table, Cascader, message, Modal,} from "antd";
import './newConfigurePage.less'
import {ArrowLeftOutlined} from '@ant-design/icons';
import TableComponents from "../../../../components/common/tables/TableComponentsNew";
import {treeNodeAll,getTreeByNodeIds} from "../../../../services/area";
import {getPageCustomerName, newGetPageCustomerName} from "../../../../services/customer";
import {TYPE_NAME} from "../../../../components/Const";
import {funTraceInfo} from '../../../../services/common'
import FormsTwo from "../../../../components/common/forms/FormsTwo";
import {saveAlarmNoticeConfig,getNoticeAreasByConfigId} from "../../../../services/qrCode";
import {getDateByUserId} from "../../../../services/admin";
const CheckboxGroup = Checkbox.Group;

// 设备配置信息
export default class  newConfigurePage extends Component {

    state ={
        // 多选框的选择数据disabled 1、短信、2、电话、3、邮件、4、app、5、系统页面
        plainOptions : [
            {id:1,label:'短信',value:1}
            ,{id:2,label:'电话',value:2,disabled:false}
            ,{id:3,label:'邮件',value:3}
            ,{id:4,label:'app',value:4,disabled:true}
            ,{id:5,label:'系统页面',value:5,disabled:true}],

        Input_name:this.props?.location?.state?.headerChild?.configName,          // 配置名
        checkedList:[],         // 多选框-选中的值
        Input_1_value:[],       // 手写添加的值-推送目标
        Input_2_value:[],       // 手写添加的值

        modalVisible_1:false,   // 开关
        customerList:[],        // 客户列表


        inputError:'',
        tableLeftCss:"",
        // 右表-客户表格参数
        tableParamenter_1: {
            manageName: "客户表格",
            // 表格行是否可选
            rowSelection: false,
            isNotInitData:true,
            //下拉框长度
            selectWidth: '6.5vw',
            header: {
                id: '客户编号',
                businessCooperationNum: '业务合作号',
                name: '客户名称',
                // type: '客户类型',
                // nature: '客户性质',
                // principal: '联系人',
                principalPhone: '联系人电话',
                edit:'操作'
            },
            data:[],
            params: {
                areaIds:[],
                page:1,
                size:10,
            },
        },

        tableParamenter_2:[
            {title: '客户编号', dataIndex: 'id', key: 'id',render: (_,record,)=> <div><span>{record.id}</span></div>},
            {title: '业务合作号', businessCooperationNum: 'businessCooperationNum', key: 'businessCooperationNum',render: (_,record,)=> <div><span>{record.businessCooperationNum}</span></div>},
            {title: '客户名称', name: 'name', key: 'name',render: (_,record,)=> <div><span>{record.name}</span></div>},
            // {title: '客户类型', name: 'type', key: 'type',
            //     render: (_,record,)=>
            //         <div>
            //             <span>{record.type*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : record.type*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : record.type*1 === 3 ? <Tag color={'#f5222d'}>区域</Tag>:''}
            //             </span>
            //         </div>},
            // {title: '客户性质', name: 'nature', key: 'nature',render: (_,record,)=> <div><span>{record.nature}</span></div>},
            // {title: '联系人', name: 'principal', key: 'principal',render: (_,record,)=> <div><span>{record.principal}</span></div>},
            {title: '联系人电话', name: 'principalPhone', key: 'principalPhone',render: (_,record,)=> <div><span>{record.principalPhone}</span></div>},
            {title: '操作', name: 'edit', key: 'edit'
                , render: (_, record) =>
                    <div className={'tableLink'} title={'删除'}
                         onClick={() => this.deleteLeftData(record)}
                    >
                        <u>{'删除'}</u>
                    </div>
            }
        ],

        // 条件查询栏
        topFormItem: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'userName', str: '客户名', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'telephone', str: '电信电话', rules: TYPE_NAME.PHONE_NUM,},
                {variableName: 'mailbox', str: '推送邮箱', rules: TYPE_NAME.EMAIL,}
            ]
        },
        searchGetData:{},
        tableParamenter_2_input_data:[],
        tableParamenter_2_data:[],  // 左表数据


        treeNodeInfo_1:[],        // 客户区域
        treeNodeInfo_1_value:[],  // 选中的客户区域

        load:true
    };




    // 初始化加载
    componentDidMount() {

        const dataAuthories = JSON.parse(sessionStorage.getItem("dataAuthories") === 'undefined' ? 'null' : sessionStorage.getItem("dataAuthories"));

        this.treeNodeAll(dataAuthories);// 获取区域列表
        console.log(this.props?.location?.state?.headerChild);

        // 初始化获取推送方式的多选框
        var checkedList = this.state.checkedList;
        this.props?.location?.state?.headerChild?.noticeType.forEach(one=>{
            checkedList.push(parseInt(one))
        });
        // 初始化获取推送方式的多选框-END

        // 获取子表详情-左表数据
        this.getNoticeAreasByConfigId();
        // 获取子表详情-左表数据-END

        // 获取客户信息
        var Input1Value=this.state.Input_1_value;   // 客户输入框
        var customerList =this.state.customerList;  // 客户列表
        this.props?.location?.state?.headerChild?.noticeUsers.forEach(one=>{

            var param={
                userName:one.userName,
                telephone:one.telphons,
                mailbox:one.mails
            };
            Input1Value.push([one.userName]);
            customerList.push(param);
        });
        // 获取客户信息-END
        // 配置名
        let inputName =this.state.Input_name;
        inputName=this.props?.location?.state?.headerChild?.configName;
        this.setState({
            checkedList:checkedList,
            Input_1_value:Input1Value,
            customerList:customerList,
            Input_name:inputName
        })
    }
    // 获取子表详情-左表数据
    getNoticeAreasByConfigId=()=>{
        if(this.props?.location?.state?.headerChild?.id){
            getNoticeAreasByConfigId({alarmNoticeConfigId:this.props?.location?.state?.headerChild?.id}).then(
                response=>{
                    if(response?.data?.data){
                        var tableParamenter = [...this.state.tableParamenter_2_data];

                        response?.data?.data.map(one=>{
                            var valuer={};
                            if(one?.areaId){
                                valuer.id=one.areaId;
                                valuer.name=one.areaName;
                                valuer.type=3;
                                tableParamenter.push(valuer);
                            }else{
                                valuer.id=one.cusId;
                                valuer.name=one.areaName;
                                valuer.type=2;
                                tableParamenter.push(valuer);
                            }
                        });
                        this.setState({tableParamenter_2_data:tableParamenter})
                    }
                    console.log(response);
                }
            )
        }
    };

    // 获取客户区域
    treeNodeAll = (data) => {
        console.log('获取客户区域');
        // getTreeByNodeIds(data).then(
        //     response => {
        //         let {menus} = this.state.treeNodeInfo_1;
        //         menus = JSON.parse(response.data?.data);
        //         console.log('1');
        //         console.log(menus);
        //         this.setState({treeNodeInfo_1:menus});
        //     }
        // ).catch(e=>{return message.info("异常问题，请联系管理员")});

        //  treeNodeAll().then(
        // 	response => {
        // 		let {menus} = this.state.treeNodeInfo_1;
        // 		menus = JSON.parse(response.data?.data);
        // 		this.setState({treeNodeInfo_1:menus});
        // 	}
        // )
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    console.log('2');
                    console.log(response?.data);
                    let {menus} = this.state.treeNodeInfo_1;
                    menus = response?.data;
                    this.setState({treeNodeInfo_1:menus});

                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };

    // 加入区域下所有对象
    addAllCustomerName=()=>{
        console.log("加入区域下所有对象");
        var tableParamenter = [...this.state.tableParamenter_2_data];   // 左表数据
        var treeNodeInfoValue = this.state.treeNodeInfo_1_value;        // 选取的区域
        var treeNodeInfo = this.state.treeNodeInfo_1;                   // 所有区域数据
        var areaIds = this.funareaIds(treeNodeInfo,treeNodeInfoValue);  // 选区的详情，参数：全部区域，所选区域不含子区域，返回整个区域详情包括子区域


        // 1-判断有无重名的区域，先循环选取的区域，在内部循环表格数据
        for(var i=0;i<areaIds.length;i++){                              // 选取区域详情
            var juder_1=true;
            for(var a=0;a<this.state.tableParamenter_2_data.length;a++){ // 左表数据
                if(this.state.tableParamenter_2_data[a].type===3){
                    // 对比表格有没有重复的区域
                    if(areaIds[i].name===this.state.tableParamenter_2_data[a].name){
                        juder_1=false;
                    }
                }
            }
                if(juder_1){
                    var param={id:areaIds[i].id,name:areaIds[i].name,type:3};
                    tableParamenter.push(param);
                }
            }
        // var juder_2=true;
        // for(var a=0;a<this.state.tableParamenter_2_data.length;a++){
        //     if(this.state.tableParamenter_2_data[a].type!==3){
        //         var promise = this.getPageCustomerName_1(this.state.tableParamenter_2_data[a].name);
        //         promise.then((result)=>{
        //             juder_2=result;
        //         });
        //     }
        //
        // }
        this.setState({tableParamenter_2_data:tableParamenter,tableLeftCss:''});
    };

    getPageCustomerName_1=(text)=>{
        var params = this.state.tableParamenter_1;
        params.params.name=text;
        return getPageCustomerName(params.params).then(
            (response)=>{
                const data=response?.data?.data;
                if(data!==null){
                    message.info("已有客户添加，请先删除该客户");
                    return false;
                }
            }
        );
    };

    // 刷新右表
    getPageCustomerName=async areaIds=>{
        var tableParamenter = this.state.tableParamenter_1; // 右表数据结构
        await this.table.refreshTable(tableParamenter.params)
    };

    // 用户选取地区后的操作
    treeSelectOnChange_1=(text = String )=>{
        this.setState({treeNodeInfo_1_value:text});
        var treeNodeInfo = this.state.treeNodeInfo_1;       // 所有区域数据
        var tableParamenter = this.state.tableParamenter_1; // 右表数据结构
        tableParamenter.params.areaIds=[];
        var areaIds = this.funareaIds(treeNodeInfo,text);
        areaIds?.map(one=>{
            tableParamenter.params.areaIds.push(one.id);
        });
        this.setState({tableParamenter_1:tableParamenter});
        this.getPageCustomerName();
    };


    // 用户选取地区后的操作-递归
    funareaIds=(data,text)=>{
        var areaIds=[];
        data.map(one => {
            if(one.name===text){
                areaIds.push(one);
                if(one?.children){
                    one?.children.map(two=>{
                        areaIds.push(two);
                    })
                }
            }else if(one?.children){
                 this.funareaIds(one?.children,text)?.map(
                    one=>{
                        areaIds.push(one);
                    }
                )
            }
        });
        return areaIds;
    };



    // 右表添加功能
    addRightData=(data)=>{
        var treeNodeInfoValue = this.state.treeNodeInfo_1_value;        // 选取的区域
        var tableParamenter = [...this.state.tableParamenter_2_data];   // 左表数据

        var juder=true;
        tableParamenter.forEach(one=>{
            if(one.type===3){
                if(one.name===treeNodeInfoValue){
                    message.info("该客户已在添加区域内");
                    return juder =false;
                }
            }else if(one.id===data.id){
                message.info("该客户已添加");
                return juder = false;
            }
        });
        if(juder){
            tableParamenter.push(data);
        }
        this.setState({tableParamenter_2_data:tableParamenter,tableLeftCss:''})
    };
    // 右表删除功能
    deleteLeftData=(data)=>{
        var tableParamenter = [...this.state.tableParamenter_2_data];
        for(let i = 0; i<tableParamenter.length;i++){
            if(data.name===tableParamenter[i].name){
                tableParamenter.splice(i,1);
            }
        }
        this.setState({tableParamenter_2_data:tableParamenter})
    };

    // 添加客户
    addCustomer=async value => {
        let data = await this.forms.onFinishNotReset();
        var input1 = [...this.state.Input_1_value];
        var customerList = this.state.customerList;

        if(!data?.userName){
            return message.info("请填写客户名")
        }

        if(!data?.telephone &&  !data?.mailbox){
            return message.info("请填写电话或邮箱必填一项")
        }
        if(data){
            customerList.push(data);
        }
        if(data.userName){
            input1.push([data.userName]);
        }
        this.setState({customerList:customerList,Input_1_value:input1,modalVisible_1:false})
    };

    // 删除推送目标
    deleUserName=(text)=>{
        var input1 = [...this.state.Input_1_value]; // 原数据
        var customerList = this.state.customerList; // 客户列表
        console.log(text);
        console.log(input1);
        console.log(customerList);

        if(text.length!==0){
            // 遍历输入框的值，寻找以删除的值
            for(var i=0;i<input1.length;i++){
                var juder=true;
                // 判断当前值是否还存在
                for(var a=0;a<text.length;a++){
                    if(input1[i][0]===text[a][0]){
                        console.log("重复"+input1[i][0]);
                        juder=false;
                    }
                }
                // 判断是否存在， 不存在直接删除
                if(juder){
                    for(var b=0;a<customerList.length;b++){
                        if(input1[i][0]===customerList[b]?.userName){
                            console.log("去除的"+input1[i][0]);
                            customerList.splice(b,1)
                        }
                    }
                }
            }
        }else{
            input1=[];
            customerList=[]
        }

        this.setState({Input_1_value:text,customerList:customerList})
    };

    // 提交
    createDetectorClick=()=>{
        console.log(this.props?.location?.state?.rowData);
        // 配置提交参数
        var params={
            type:0,
            id:this.props?.location?.state?.headerChild?.id?this.props?.location?.state?.headerChild?.id:null,// id获取，没有id视为新增
            configName:this.state.Input_name,   // 配置名
            noticeType:this.state.checkedList,  // 多选框-选中的值
            alarmSourceId:this.props?.location?.state?.rowData?.id,        // 类型id
            createUserId:JSON.parse(sessionStorage.getItem("user")).id,         //申请人id
            createUserName:JSON.parse(sessionStorage.getItem("user")).orgName, //申请人名称
            voAlarmNoticeArea:[],   // 区域
            voAlarmNoticeUsers:[],  // 客户
        };

        if(!params?.configName){
            console.log(params?.configName);
            this.setState({inputError:'error'});
            return message.info("请填写配置名");

        }

        //处理区域id和客户id
        var tableParamenter = this.state.tableParamenter_2_data;
        // 限制不能提交空数据
        if(tableParamenter.length===0){
            this.setState({tableLeftCss:'1px #f5222d solid'});
            return message.info("请先选择客户或者区域");
        }

        // 封装左表提交的数据
        tableParamenter.forEach(one=>{
            var areas={};
            if(one.type===3){
                areas={areaId:one.id, name:one.name};
                params.voAlarmNoticeArea.push(areas);
            }else{
                areas={
                    customerId:one.id,
                    name:one.name
                };
                params.voAlarmNoticeArea.push(areas);
            }
        });
        // 封装客户信息
        var customerList = this.state.customerList;
        customerList.forEach(one=>{
            one.alarmSourceId=this.props?.location?.state?.rowData?.alarmTypeId;// 类型id
            params.voAlarmNoticeUsers.push(one);    // 客户信息
        });
        console.log(params);
        this.setState({load:false});
        saveAlarmNoticeConfig(params).then(
            response=>{
                console.log(response);
                // funTraceInfo("二维码管理", "保存和新增配置数据", null,params);
                this.setState({load:true});
                message.info(response.data.responseInfo.description);
                this.props.history.push({
                    pathname: '/deviceConfigure/deviceConfigure',
                    state:{
                        selectParams: this.props?.location?.state?.selectParams
                    }
                })
            }
        ).catch(error=>{
            this.setState({load:true});
            message.info('异常问题')
        })

    };

    // 右表样式
    setTableValue_1 = (value,text,rowData) => {
        switch (value) {
            case 'id':
                return (
                    <div className={'table_overflow'} title={text}>
                        <u>{text}</u>
                    </div>
                )
            case 'type':
                text = text*1
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'state':
                text = text*1
                return text === 1 ? '正式' : text === 2 ? '非正式' : '';
            case 'edit':
                if(this.state.tableParamenter_2_data.length!==0){
                    var juder=true;
                    for(var i=0;i<this.state.tableParamenter_2_data.length;i++){
                        if(this.state.tableParamenter_2_data[i].id===rowData.id){
                            juder=false;
                        }
                    }
                    if(juder){
                        return<>
                            <div className={'tableLink'} title={'添加'}
                                 onClick={() => this.addRightData(rowData)}
                            >
                                <u>{'添加'}</u>
                            </div>
                        </>;
                    }
                }else {
                    return<>

                            <div className={'tableLink'} title={'添加'}
                                 onClick={() => this.addRightData(rowData)}
                                >
                                <u>{'添加'}</u>
                            </div>
                        </>;
                }


            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    render() {

        return(
            <Layout className={'item_body_number_back tables'}>
                <div id={'newConfigurePage'}>
                    <div style={{
                        paddingTop:'25%',
                        zIndex:9999,
                        textAlign: 'center',
                        width:'100%',
                        height:'100%',
                        whiteSpace: 'nowrap',
                        position: 'absolute',
                        backgroundColor:'rgba(193,193,193,0.3)',
                        display: this.state.load?'none':null
                    }}
                    >
                        <Spin/><span style={{fontSize:'21px',marginLeft:'5px'}}>提交中</span>
                    </div>
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'}
                                  onClick={() => this.props.history.push({
                                      pathname: '/deviceConfigure/deviceConfigure',
                                      state:{selectParams: this.props?.location?.state?.selectParams}})
                                  }
                            ><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>设备详情</span>
                        </div>
                    </div>
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div>
                        <div  className={"formText_1_div"}>
                            <div>
                                <span style={{"width": "60%","fontSize": "18px","font-weight": "bold"}}>报警源信息</span>
                            </div>
                            <div className={"formText_1_installInner"}>
                                <ul>
                                    <li>来源系统：<span>{this.props?.location?.state?.rowData?.systemName}</span></li>
                                    <li>报警类型：<span>{this.props?.location?.state?.rowData?.alarmTypeName}</span></li>
                                    <li>报警优先级：<span>{this.props?.location?.state?.rowData?.level}</span></li>
                                </ul>
                            </div>
                        </div>
                        {/*------------------------------------------*/}
                        <div  className={"formText_1_div"}>
                            <div>
                                <span style={{"width": "60%","font-size": "18px","font-weight": "bold"}}>推送信息</span>
                            </div>
                            <div className={"formText_1_installInner"}>
                                <ul>
                                    <li><span>{this.props?.location?.state?.rowData?.pushContent}</span></li>
                                </ul>
                            </div>
                        </div>
                        {/*------------------------------------------*/}
                        <div  className={"formText_2_div"}>
                            <div>
                                <span style={{"width": "60%","font-size": "18px","font-weight": "bold"}}>报警配置</span>
                            </div>
                            <div className={"formText_1_installInner"}>
                                <ul>
                                    <li>配置名：<span  ><Input style={{  "width": "60%"}}
                                                           status={this.state.inputError}
                                                           placeholder="请输入配置名"
                                                           defaultValue={this.state.Input_name}
                                                           onChange={(text=String)=>
                                                           {
                                                               console.log(this.state.tableParamenter_1)
                                                               var value='';
                                                               if(!text.target.value){
                                                                   value='error'
                                                               }
                                                               this.setState({Input_name: text.target.value,inputError:value});}}
                                    /></span></li>
                                    <li>消息推送：<span>
                                        <CheckboxGroup options={this.state.plainOptions}
                                                       value={this.state.checkedList}
                                                       onChange={(list)=>{ this.setState({checkedList:list})}} />
                                    </span></li>
                                    <li>推送目标：
                                        <span >
                                            {/*<Select*/}
                                            {/*    size="small"*/}
                                            {/*    mode="tags"*/}
                                            {/*    style={{ width: '50%',"margin-left":"28px" }}*/}
                                            {/*    onChange={(text=String)=>{this.setState({Input_1_value:text})}}*/}
                                            {/*    tokenSeparators={[',']}*/}
                                            {/*    // options={options}*/}
                                            {/*/>*/}
                                            <Cascader
                                                style={{ width: '54%' }}
                                                size="small"
                                                value={this.state.Input_1_value}
                                                // options={this.state.Input_2}
                                                onChange={(text)=>{this.deleUserName(text)}}
                                                multiple
                                                maxTagCount="responsive"
                                            />
                                            <Button type="primary"
                                                    onClick={() => this.setState({modalVisible_1:true})} >
                                                <span style={{"color":"#fff"}} >添加用户</span>
                                            </Button>
                                        </span>

                                    </li>
                                    {/*<li>推送目标邮箱地址：*/}
                                    {/*    <span>*/}
                                            {/*<Select*/}
                                            {/*    size="small"*/}
                                            {/*    mode="tags"*/}
                                            {/*    style={{ width: '50%' }}*/}
                                            {/*    onChange={(text=String)=>{this.setState({Input_2_value:text})}}*/}
                                            {/*    tokenSeparators={[',']}*/}
                                            {/*    // options={options}*/}
                                            {/*/>*/}
                                            {/*<Cascader*/}
                                            {/*    style={{ width: '50%' }}*/}
                                            {/*    size="small"*/}
                                            {/*    value={this.state.Input_2_value}*/}
                                            {/*    options={this.state.Input_2}*/}
                                            {/*    onChange={(text)=>{this.deleMailbox(text)}}*/}
                                            {/*    multiple*/}
                                            {/*    maxTagCount="responsive"*/}
                                            {/*/>*/}
                                    {/*    </span>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={"top_separate_div"}/>
                    {/*----分割线----/}
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div className={"bottom_div"}>
                        <div className={"bottom_left_div"}>
                            <div>
                                <span>报警对象推送清单</span>
                                <Input placeholder={'请输入查询值,根据客户编码，名称，联系人查询'}  style={{  "width": "100%"}}
                                       onChange={(data)=>{
                                           var text =data.target.value.toString();
                                           var tableParamenter= this.state.tableParamenter_2_data;
                                           var tableParamenter_2_input_data = this.state;
                                           tableParamenter_2_input_data=[];
                                           if(text!==''){
                                               tableParamenter.forEach(one=>{
                                                   if(one?.id?.indexOf(text)>-1 || one?.name?.indexOf(text)>-1 || one?.principal?.indexOf(text)>-1){
                                                       tableParamenter_2_input_data.push(one);
                                                   }
                                               });
                                           }
                                           this.setState({tableParamenter_2_input_data})
                                       }}

                                />
                            </div>
                            <div  style={{ "marginTop":"5.5px" ,"border":this.state.tableLeftCss}}>
                                <Table

                                    columns={this.state.tableParamenter_2}
                                    dataSource={this.state.tableParamenter_2_input_data.length===0?  this.state.tableParamenter_2_data:this.state.tableParamenter_2_input_data}
                                    rowKey={"id"}
                                    bordered
                                    // rowSelection={
                                    //     { type:"checkbox"}
                                    // //     //"checkbox"
                                    // //     // "radio"
                                    // }
                                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    pagination = {{
                                        showSizeChanger:true,
                                        showQuickJumper: true,
                                        // onChange: this.getPaginationRight,       // 获得翻页数值
                                        total:this.state.tableParamenter_2_data.length,
                                        pageSize:10,
                                        showTotal: (count = this.state.tableParamenter_2_data.length)=>{
                                            return '共'+this.state.tableParamenter_2_data.length+'条数据'
                                        },
                                        defaultCurrent: 1,
                                        pageSizeOptions: ['10']
                                    }}
                                >
                                </Table>
                            </div>
                        </div>
                        <div className={"bottom_separate_div"}/>
                        {/*------------------------------------------*/}
                        <div className={"bottom_right_div"}>

                            <div>
                                <span>可产生该报警对象</span>
                                <div>
                                    <TreeSelect
                                        showSearch
                                        style={{ width: '30%' }}
                                        value={this.state.treeNodeInfo_1_value}
                                        dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                                        fieldNames={{ label: 'name' ,value:'name'}}
                                        placeholder="请选择客户区域"
                                        allowClear
                                        // treeDefaultExpandAll
                                        onChange={this.treeSelectOnChange_1}
                                        treeData={this.state.treeNodeInfo_1}
                                    />
                                    <Button type="primary" onClick={this.addAllCustomerName}>加入区域下所有对象</Button>
                                    <Input  style={{"marginLeft":"17%",  "width": "20%"}}
                                            onChange={(text=String)=> {
                                                var params = this.state.tableParamenter_1;
                                                params.params.name = text.target.value;
                                                this.setState({tableParamenter_1: params});

                                            }}/>
                                    <Button type="primary" onClick={this.getPageCustomerName}>查询客户</Button>
                                </div>
                            </div>
                            <div>
                                <TableComponents
                                    paramenter={this.state.tableParamenter_1}
                                    // setData={getPageCustomerName}
                                    setData={newGetPageCustomerName}
                                    setTableValue={this.setTableValue_1}
                                    ref={e => this.table = e}
                                >

                                </TableComponents>
                            </div>
                        </div>
                        {/*------------------------------------------*/}
                    </div>
                    {/*----------------------------------------------------------------------------------------------*/}
                    <div className={"top_separate_div"}/>
                    <div className={"bottom_button_div"}>
                        <div className={"bottom_button_css"}>
                            <Button type="primary" onClick={() => this.createDetectorClick(true)}>提交</Button>
                        </div>
                    </div>



                </div>
                <div>
                    <Modal
                        title={'客户'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible_1}
                        okText="确定"
                        onOk={() => this.addCustomer()}           // 点击确定
                        onCancel={() => this.setState({modalVisible_1:false})}       // 点击取消
                        width={400}
                        centered
                    >
                        <div>
                            <FormsTwo
                                ref={e => this.forms = e}
                                formParamenter={this.state.topFormItem}
                                // data={this.state.searchGetData}
                            />
                        </div>
                    </Modal>
                </div>
            </Layout>
        );
    }
}
