import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Popconfirm} from 'antd'
import {TYPE_NAME} from '../../../components/Const'
import {funTraceInfo} from '../../../services/common'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getPageCustomerName, cancelCustomer, getPageMpAddress, getLocationById} from '../../../services/customer'
import {querySecurityAssessment} from "../../../services/meter";

// 工商户安全评估
export default class SafetyAssessment extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "客户关系管理系统",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: false,
            // 获取表格参数数据
            localStorageName: 'SafetyAssessment',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '客户关系管理',
            exportUrl: "/web/customerExportCtrl/exportCustomer",
            header: {
                customerId: '客户编号',
                customerName: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系人电话',
                address: '详细地址',
                annualSecurityInspectionRate: '年度安检率',
                annualSelfInspectionRate: '月度自检率',
                gasApplianceStatus: '燃气具状态',
                securityCheckStatus: '燃气设备检定状态',
                evaluationResults: '评估结果',
                edit: '安全评估报告',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'customerName',
                        str: '客户名称',
                        initData: JSON.parse(localStorage.getItem('SafetyAssessment'))?.customerName
                    },
                ]
            },
        },
    };
    componentWillMount() {

    }

    // 注销客户
    cancelCustomer=(id)=>{
        if(!id){return message.info('请选择客户');}
        var param = {
            "page": 1,
            "size": 10,
            "address": "",
            "customerId": id
        };
        // 验证计量点
        getPageMpAddress(param).then(
            response=>{
                // return message.info('该客户存在计量点或安装点，无法进行注销');
                console.log(response);
                if(response?.data?.data){
                    console.log(response);
                    return message.info('该客户存在计量点，无法进行注销');
                }else{
                    var locatioParam = {customerId:id, page:1, size:10,};
                    // 验证安装点
                    getLocationById(locatioParam).then(
                        response1=>{
                            console.log(response1);
                            if(response1?.data?.data){
                                console.log(response1);
                                return message.info('该客户存在安装点，无法进行注销');
                            }else{
                                cancelCustomer({customerId:id}).then(
                                    response2=>{
                                        if(response2.data.data){
                                            this.table.refreshTable();
                                            return message.info('注销成功');
                                        }else{
                                            return message.info('注销失败');
                                        }
                                    })
                            }
                        });
                }

            }
        );
    };

    setTableValue = (value,text,rowData) => {
        switch (value) {
            // case 'id':
            //     // 安装点参数
            //     var formsTwo_data={
            //         customerId:     rowData?.id,			// 用户id
            //         address:        rowData?.addressInfo,	// 地址
            //         customerName:   rowData?.name,   		// 客户名称
            //         createDate:'-',     // 创建时间
            //         mpName:         rowData?.principal,		// 联系人
            //         principalPhone: rowData?.principalPhone	// 电话
            //     };
            //     return (
            //         <NavLink to={{pathname: "/customer/BusinessMaintainNew",state: {
            //                 id: rowData.id,
            //                 type: rowData.type*1 === 1 ? '居民用户' : rowData.type*1 === 2 ? '工商用户' : '',
            //                 nature: rowData.nature,
            //                 address: rowData.addressInfo,
            //                 name: rowData.name,
            //                 principalPhone: rowData.principalPhone,
            //                 rowData:formsTwo_data
            //             }}}>
            //             <div className={'table_overflow'} title={text}>
            //                 <u>{text}</u>
            //             </div>
            //         </NavLink>
            //     );
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            case 'state':
                text = text*1;
                return text === 1 ? '正式' : text === 2 ? '非正式' : '';
            case 'edit':

                return (
                    <div>
                        <div style={{float: 'left'}}>
                            <NavLink to={{
                                pathname: "/customer/SafetyAssessmentDetails",
                                state:{
                                    rowData:rowData
                                }
                            }}
                            >
                                <div title={'查看报告'}>
                                    <a>查看报告</a>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    updateCustomer = () => {
        let rowData = this.table.getSelectChange();
        if(rowData.length < 1) return message.error('未选择数据');
        console.log(rowData)
        this.props.history.push({
            pathname: '/customer/openAccount',
            state: {
                rowData:rowData[0],
                selectWidth: 2
            }
        })
    };
    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'customerId'}
                    paramenter={this.state.tableParamenter}
                    setData={querySecurityAssessment}
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                >

                </TableComponents>

            </Layout>
        )
    }
}
