import React,{Component} from 'react'
import {Button, Layout, message, Table} from "antd";

import './equipments.less'
import FormsText from "../../../components/common/forms/FormsText";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import {getEquipmentParticular} from "../../../services/equipment";
import {funTraceInfo} from '../../../services/common'
export default class equipments  extends Component {
    state= {
        // 左框上部分titile内容
        leftTopTitle: {
            data: {},
            header: {
                phone: '手机电话',
                communication: '电池电压',

            }
        },
        // 左框中层titilen内容
        leftCentreTitle: {
            data: {},
            header: {
                location: '远传表位置号',
                meterTime: '仪表时间',
                wcInstantaneousDelivery: '工况瞬时流量',
                instantaneousDelivery: '标况瞬时流量',
                wcCumulativeDischarge: '工况累计流量',
                cumulativeDischarge: '标况累计流量',
                gasTemperature:'燃气温度',
                gasPressure:'燃气压力',
                StatusAndAlarm:'状态与报警'
            }
        },

        // 右框上半部分titile内容
        formParamenter: {
            modal: true,
            number: 1,
            labelCol: 10,
            layout: "horizontal",
            // itemSize: 'large',
            parameter: [
                {
                    variableName: 'id',
                    str: '被监控的燃气表标识',
                    rules: TYPE_NAME.NAME,
                },
                {
                    variableName: 'phone',
                    str: '被监控的手机号',
                    rules: TYPE_NAME.NAME
                },

            ]
        },
        // 右框表格内容
        responseInfo:{
            paginationProps:{
                total: '', // 数据总数
                pageSize: '15', // 每页条数
                current: '1', // 当前页码
            },

            data:[],
            dataSource : [
                {
                    title: '通讯时间',
                    dataIndex: 'createTime',
                    key: 'createTime',
                    width:100,
                },
                {
                    title: '内容',
                    dataIndex: 'content',
                    key: 'content',
                    width:200,
                    render: (text,record)=>(
                        <span style={{wordWrap:'break-word',wordBreak:'break-word'}}>
                        {text}
                    </span>
                    )
                },
                {
                    title: '源ip',
                    dataIndex: 'ip',
                    key: 'ip',
                    width:100,
                },
                {
                    title: '原端口',
                    dataIndex: 'port',
                    key: 'port',
                    width:80,
                },
            ]
        },
        statusAndAlarmData:"",  // 左框第三个文本内容
        rowId:'',
        socket:null,
        formData:{},    // 右框文本框内容
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,
    };
    // 页面初始化启动
    componentDidMount() {
        this.openSocket();  // 开启服务
    }
    // 页面离开启动
    componentWillUnmount(){
        let {socket} = this.state; // 开启 获取 State的对象。
        socket.onclose();
    }
    guid =()=> {    // 随机生成
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });};
    openSocket=()=>{
        let _that = this;   // 异步需要的this
        let {socket,responseInfo} = this.state;

        if(typeof(WebSocket) == "undefined"){ console.log("您的浏览器不支持WebSocket")}
        else{
            console.log("您的浏览器支持WebSocket");

            let number = this.guid();
            console.log("随机生成数");
            console.log(number);
            //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
            //var socketUrl="${request.contextPath}/demo/imserver/"+$("#userId").val();
            // var socketUrl = "http://192.168.2.130:" + 5321 + "/imserver/" + number;
            var socketUrl = "http://localhost:" + 5321 + "/imserver/" + number;
            socketUrl = socketUrl.replace("https", "ws").replace("http", "ws");

            console.log(socketUrl);
            if (socket != null) {
                socket.close();
                socket = null;
            }

            socket = new WebSocket(socketUrl);
            //打开事件
            socket.onopen = function () {
                console.log("websocket已打开");

                //socket.send("这是来自客户端的消息" + location.href + new Date());
            };
            //获得消息事件
            socket.onmessage = function (msg) {
                console.log("获得消息事件");
                console.log(msg);
                responseInfo.data=JSON.parse(msg.data);
                responseInfo.paginationProps.total=responseInfo.data.length;
                _that.setState({responseInfo})
                //发现消息进入    开始处理前端触发逻辑
            };
            //关闭事件
            socket.onclose = function () {
                socket.close();
                console.log("websocket已关闭");
            };
            //发生了错误事件
            socket.onerror = function () {
                console.log("websocket发生了错误");
            }
        }
        this.setState({socket})

    };
    sendMessage=(item)=>{   // 监控按钮事件
        this.forms.onFinish();
        setTimeout(() => {
            let {socket} = this.state; // 开启 获取 State的对象。
            if (typeof (WebSocket) == "undefined") {
                console.log("您的浏览器不支持WebSocket");
            } else {
                try{
                    console.log("您的浏览器支持WebSocket");
                    socket.send('{"contentText":"' + this.state.formData.id +'","phone":"'+this.state.formData.phone+'"}');
                }catch (e) {
                    socket.close();
                }


            }
            this.setState({socket})
        }, 0)

    };
    setRowClassName = (record) => {
        return record.id === this.state.rowId ? 'clickRowStyl' : '';
    };
    // 右框表格点击事件
    onClickRow=(record)=>{
        return {
            onClick: () => {
                getEquipmentParticular({content:record.content}).then(
                    reponse=>{
                        let {leftCentreTitle} = this.state;
                        if(reponse.data.data!=null){

                            leftCentreTitle.data=reponse.data.data;
                        }else{
                            message.success('无数据');
                            leftCentreTitle.data='';
                        }


                        console.log("点击表格选取指定数据");
                        console.log(reponse);
                        this.setState({leftCentreTitle})
                    }
                ).catch(
                    () => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                        });
                        message.error('不是实时数据包，暂时不提供解析功能')

                    }
                );

                this.setState({
                    statusAndAlarmData: record.content,
                    rowId: record.id,
                });
            },
        };
    };
    // 获取表单信息
    getFormValue = value => {
        console.log(value);
        this.setState({formData: value})
    };
    render() {
        let user = sessionStorage.getItem("user")
        console.log(JSON.parse(user))
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div className={"body_div"}>
                    <div className={"left_body_div"}>
                        <div className={"left_body_top_div"}>
                            <span className="left_body_title_div">登录信息：</span>
                            <div className={"left_body_top_title_div"}>
                                <FormsText
                                    record = {this.state.formData}
                                    header = {this.state.leftTopTitle.header}
                                    rowLen = {4}
                                />
                            </div>
                        </div>
                        <div className={"left_body_centre_div"}>
                            <span className="left_body_title_div">实时信息：</span>
                            <div className={'left_body_centre_top_title_div'}>
                                <span>燃气表标识：</span>
                                <span>{this.state.formData.id}</span>
                            </div>
                            <div className={"left_body_centre_title_div"}>
                                <FormsText
                                    record = {this.state.leftCentreTitle.data}
                                    header = {this.state.leftCentreTitle.header}
                                    rowLen = {2}
                                />
                            </div>
                        </div>
                        <div className={"left_body_below_div"}>
                            <span className="left_body_title_div">内容详情：</span>
                            <div className={"left_body_below_title_div"}>
                                <span>{this.state.statusAndAlarmData}</span>
                            </div>
                        </div>
                    </div>
                    <div className={"right_body_div"}>
                        <div className={"right_body_top_div"}>
                            <div className={"right_body_top_titile_div"}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.formParamenter}
                                    getFormValue={this.getFormValue}
                                />
                            </div>
                            <div className={'right_body_top_botton_div'}>
                                <Button type="primary" onClick={this.sendMessage}>监控</Button>
                            </div>
                        </div>
                        <div className={"right_body_table_div"}>
                            <div>
                                <div className={"right_body_table_head_div"}>
                                    <span className={'right_body_table_head_titile_div font_size0'}>监控列表</span>
                                </div>
                                <div className={"right_body_table_data_div"}>
                                    <Table
                                        bordered
                                        pagination={this.state.responseInfo.paginationProps}
                                        dataSource={this.state.responseInfo.data}
                                        columns={this.state.responseInfo.dataSource}
                                        rowKey={"id"}
                                        scroll={{ y: 500 }}
                                        onRow={this.onClickRow}
                                        rowClassName={this.setRowClassName}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
