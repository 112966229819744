import React, { Component } from 'react'
import { Layout, Input, Form } from 'antd'

import TableComponents from '../../../components/common/tables/TableComponents'
import {getRealTimeData,getAllManufacturerAndModel} from '../../../services/report'

const FormItem = Form.Item

class RealTimeData extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 下拉框长度
			selectWidth: '7.5vw',
			bindArea: true,
            rowSelection: false,
			width: '83.7vw',
            title: '表具实时数据报表',
			exportUrl: "/web/reportExportCtrl/exportRealTimeData",
			// 表头：变量和变量解释
			header: {
				company: '公司',
				readUnit: '读表单元',
                userName: '用户名称',
				installNumber: '安装号',
				contractNumber: '合同',
				steelNum: '表钢号',
                address: '详细地址',
                id: '表具编号',
                meterManufacturer: '表具厂商',
                // protocolModel: '协议型号',
                modelName: '表具型号',
                simCard: 'SIM卡号',
                collectionTime: '采集时间',
                dataTime: '数据时间',
                clockInTable: '表内时钟',
                pressure: '压力',
                standardFlow: '标况流量',
                standardTotal: '标况总量',
                workingFlow: '工况流量',
                workingTotal: '工况总量',
                temperature: '温度',
                signal: '信号量',
                voltageValue: '电压值',
                valveState: '阀门状态',
                productionYear: '表具生产年份'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'input',
						variableName: 'readUnit',
						str: '读表单元'
					},
					{
						type: 'input',
						variableName: 'simCard',
						str: '通讯号',
					},
				]
			},
		},
	}

	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state
				let {data} = response.data
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				})
				this.setState({tableParamenter})
			}
		)
	}

	setTableValue = (value,text,_) => {
		switch (value) {
			case 'protocolModel':
				return '新奥协议'
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
		}
	};

    render() {
		var areaName = localStorage.getItem('areaName')??null;
		if(areaName==='undefined'){areaName=null}

		return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
               <TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getRealTimeData}
					ref={e => this.table = e}
				>
					<FormItem label={"公司"}>
						<Input disabled value={areaName} size='middle'/>
					</FormItem>
				</TableComponents>
            </Layout>
        )
    }
}
export default RealTimeData
