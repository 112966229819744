import React from 'react';

import * as echarts from 'echarts';

import '../subpage/bottomThree.less'

// 半圆环形图
class Semicircle extends React.Component {
    componentDidUpdate(num){
        this.elementById();
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {

                tooltip: {
                    show: false, // 关闭鼠标移上去后显示浮框信息
                    trigger: 'item',
                    confine: true,

                },
                title: {
                    text: this.props.data.title,
                    textStyle: {
                        color: '#b7fde4',
                        fontSize: 14,
                        fontWeight:'normal'
                    },
                    // subtext: '30',
                    // subtextStyle: {
                    //     color: '#fff',
                    //     fontSize:18,
                    //     left:'100'
                    // },
                    itemGap: -20, // 主副标题距离
                    left: 'center',
                    top: '70%'
                },
                // legend: {
                //     top: '5%',
                //     left: 'center',
                //     // doesn't perfectly work with our tricks, disable it
                //     selectedMode: false
                // },
                series: [
                    {
                        name: '数值',
                        type: 'pie',
                        radius: ['140%', '100%'],
                        center: ['50%', '98%'],
                        // adjust the start angle
                        hoverAnimation: false, // 取消掉环形图鼠标移上去时自动放大
                        startAngle: 180,
                        label: {
                            show: false,
                            // position: 'center'
                            // formatter(param) {
                            //     // correct the percentage
                            //     return param.name + ' (' + param.percent * 2 + '%)';
                            // }
                        },
                        // 内部字体
                        emphasis: {
                            label: {
                                show: false,
                                fontSize: 12,
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {
                                value: this.props?.data?.value,
                                name: this.props?.data?.name,

                                itemStyle: {
                                    normal: {
                                        color:new echarts.graphic.LinearGradient(0,0,0,1,
                                            [
                                                {offset:1,color:this.props?.data?.color[0]},
                                                {offset:0,color:this.props?.data?.color[1]},
                                            ])
                                    },
                                },
                            },
                            // { value: 0, name: '今日在线数' },
                            {
                                value:  this.props?.data?.counNum,
                                name:'总数',
                                itemStyle: {
                                    color: '#004354',
                                    decal: {
                                        symbol: 'none'
                                    }
                                },
                                label: {
                                    show: false
                                }
                            }
                        ]
                    }
                ]
            }
        );
    };
    render() {
        return (

                <div id={this.props.elementById} style={{ width: '100%', height: '100%' }} />

        );
    }
}
export default Semicircle;
