import React,{ Component } from 'react'
import './deliver.less'
import {Layout, Button, message, Modal, Table, Popconfirm, Select, Input} from "antd";
import FormsText from "../../../components/common/forms/FormsText";

import { ArrowLeftOutlined } from '@ant-design/icons';
import {getMetersBySteelNum,addDeliveryNote,getMeterOrderById,
    getVoDeliveryNote,updataDeliveryNode,supplierShip,getMeterOrderList,getDeleteAllTemporay,getAllAreaInfoInId} from "../../../services/supplier";
import {funTraceInfo} from "../../../services/common";
const { Option } = Select;
// 送货-窗口栏
export default class deliver extends Component{

    state= {

        manageName:'供应商信息管理-订单管理',
        // 表格点击默认获取指定数据
        deliveryNoteDeta: {
            id:null,
            meterId:null,           //  表具id
            model:null,             //  型号
            modelId:null,           //  型号id
            type:null,              //  类型
            steelNum:null,          //  表刚号
            orderId:null,           //  订单号
            consignor:null,         //  发货人
        },
        placeholderz:{
            key:null,
            value:"发货地点",
            selectSwitch:false,
        },

        options:[],
        record:null,
        //发货清单
        shippingList:{
            // 页头内容
            deliveryHead:{
                customerName: '发货人',
                deliveryState: '发货状态',
                quantity: '提交数量',
            },
            // 临时发货单表格
            deliveryNoteHear: [
                {title: '表具id', dataIndex: 'meterId', key:'meterId'},
                {title: '型号', dataIndex: 'model', key:'model'},
                {title: '类型', dataIndex: 'type', key:'type'},
                {title: '表刚号', dataIndex: 'steelNum', key:'steelNum'},
                {title: '操作', dataIndex: 'operation',
                    render: (_, record) =>
                        <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                            <a>删除</a>
                        </Popconfirm>
                },
            ],
            // 临时发货单数据
            deliveryNote:{
                customerName: '发货人',
                deliveryState: '未发货',
                quantity: '订单数量',
                areaName:'名称',
                areaId:'id',
                meterOrderId:null,// 订单id
                commodity:[]
            },
        },
        commodityData:null,
        //台头文本框
        realTimeData:{
            header: {
                code: '订单号',
                orderTime: '下单时间',
                arrivalTime: '要求到货时间',
                buyerName:'采购方',
                companyName:'供应商单位',
                purchaseMan:'供应商联系人',
                state: '订单状态'
            },
            data:[]
        },
        meterOrderForm:[],  // 用于保管订单详情

        leftTableDatas:{
            tabelDataOne:[
                {   title: '表刚号', dataIndex: 'steelNum', key:'steelNum',width:200},
                {   title: '设备类型', dataIndex: 'type', key:'type' ,width:80 },
                {   title: '设备型号', dataIndex: 'model', key:'model' ,width:150 },
                {   title: '订单数量', dataIndex: 'quantity', key:'quantity'  ,width:50,fixed: 'right'},
                {   title: '发货量', dataIndex: 'number', key:'number'  ,width:50,fixed: 'right'},
            ],
            tabelDataTwo:[
                {   title: '设备类型', dataIndex: 'type', key:'type'  ,width:80},
                {   title: '设备型号', dataIndex: 'model', key:'model' ,width:150 },
                {   title: '订单数量', dataIndex: 'quantity', key:'quantity'  ,width:50,fixed: 'right'},
                {   title: '发货量', dataIndex: 'number', key:'number'  ,width:50,fixed: 'right'},
            ],
            data:[],
        },

        //  左边框的订单商品表格
        leftTableData: {
            // 表格行是否可选
            rowSelection: true,
            // 表头：变量和变量解释
            header: {
                type: '设备类型',
                model: '设备型号',
                // deliveryState: '发货状态',
                quantity: '订单数量',
                number:'发货量',
                // customerName: '发货人',
                // deliveryTime: '发货时间'
            },
            // 下拉框：变量和变量解释
            headers: {
                type: '设备类型',
                model: '设备型号',
                // deliveryState: '发货状态',
                quantity: '订单数量',
                number:'发货量',
                // customerName: '发货人',
                // deliveryTime: '发货时间'

            },
            // 表格数据
            data: [],
        },// 表格参数END

        leftParams: {
            total: 0,// 总条数
            page: 1,
            size: 10,
        },
        //  左边框的表格.数据

        //  右边框的表格
        rightTableData: {
            // 表格行是否可选
            rowSelection: true,

            columns:[
                {title: '表刚号', dataIndex: 'steelNum', key: 'steelNum',width:200,},
                {title: '通信号', dataIndex: 'communication', key: 'communication',width:100,},
                {title: '设备型号', dataIndex: 'model', key: 'model',width:200,},
                {title: '设备类型', dataIndex: 'modelName', key: 'modelName',width:100,},
                {title: '生产商', dataIndex: 'deliverStatus', key: 'deliverStatus',width:100,},
                {title: '操作', dataIndex: 'deliverTime', key: 'deliverTime',fixed: 'right',  width:80,
                    render: (_, record) =>
                        <a onClick={() => this.addOrderButton(record)}>添加</a>
                },
            ],
            data: [],// 表格数据
            paginationProps:{
                areaId:180,     // 查找表具用的id地址
                steelNum:null,
                modelId:null,
                total: '', // 数据总数
                size: '12', // 每页条数
                page: '1', // 当前页码
            }

        },
        // 表格参数END

        rightParams: {
            count: 0,// 总条数
            page: 1,
            size: 10,
        },
        dfs :[],
        commodityModel : [
            {
                title: '表刚号',
                dataIndex: 'steelNum',
                key: 'steelNum',
                sorter: {// 用于快捷排序
                    compare: (a, b) => a.consigner - b.consigner,
                    multiple: 1,
                }
            }
        ],// 表格標頭數據 暂时无用
        modal1Visible: false,   // 对话框1开关
        moda21Visible: false,   // 对话框2开关
        moda31Visible: false,   // 对话框2开关
        moda51Visible : false,  // 对话框-刷新功能
        number:1,               // 数量-临时记载


        selectedRowKeys:''
    };
    //开启该界面执行
    componentDidMount() {
        console.log("数据",this.props.location.state);
        this.getMeterOrderById();        // 获得指定id订单详情


    }

    // 获取所有区域id
    getAllAreaInfo=()=>{
        console.log("获取所有区域id"+this.state.meterOrderForm.id);
        getAllAreaInfoInId({orderId:this.state.meterOrderForm.id}).then(
            response=>{
                if(response.data.data){
                    console.log("获取所有区域id");
                    console.log(response);
                    // let options= this.state.options;
                    let {options,placeholderz,shippingList}= this.state;
                    options=response.data.data;
                    response.data.data.forEach(data=>{
                        if(data.disable){
                            console.log("获取所有区域id1");
                            console.log(data);
                            placeholderz.selectSwitch=true;
                            response.data.data.forEach(data1=>{
                                if(!data1.disable){
                                    placeholderz.key=data1.key;
                                    placeholderz.value=data1.value;
                                    shippingList.deliveryNote.areaId=data1.key;
                                    shippingList.deliveryNote.areaName=data1.value;
                                }
                            });
                        }
                    });

                    this.setState({options:options,placeholderz:placeholderz,shippingList:shippingList});

                }else {
                    message.info("区域查询失败")
                }
        })
    };

    // 未发货单-开关
    derailingSwitch1=(modal1Visible)=>{
        this.setState({modal1Visible})
    };
    // 已发货单-开关
    derailingSwitch2=(moda21Visible)=>{
        this.setState({moda21Visible})
    };
    // 已发货单-开关
    derailingSwitch3=(moda31Visible)=>{
        this.setState({moda31Visible})
    };
    // 已发货单-开关
    derailingSwitch5=(moda51Visible)=>{
        this.setState({moda51Visible})
    };
    // 对话框-发货清单容器
    setModal1Visible =(modal1Visible) =>{
        this.getShippingList();// 获取发货清单数据
        this.derailingSwitch1(modal1Visible);//小窗口开关
        this.getMeterOrderList();       // 刷新采购单

    };
    // 获取发货清单数据
    getShippingList=()=>{
        console.log("获取发货清单数据");
        let code = this.state.meterOrderForm.code;

        getVoDeliveryNote({orderId:this.state.meterOrderForm.id}).then(
            response=>{
                console.log("获取发货清单数据");
                console.log(response);
                let data = response.data;
                let {shippingList ,meterOrderForm}=this.state;
                if( data.data.meterOrderId!==null){
                    if(meterOrderForm.code !== data.data.meterOrderId){
                        console.log("订单号不相同");
                        this.derailingSwitch2(true)
                    }
                }
                shippingList.deliveryNote=data.data;
                if(data.data.commodity===null||data.data.commodity===""){
                    console.log("data.data.commodity");
                    shippingList.deliveryNote.commodity=[];
                }
                this.setState({shippingList:shippingList});
                console.log("ceshi",this.state.shippingList.deliveryNote)
            }

        ).catch(()=>{ console.log("查询失败")});
    };

    // 左边表格：导出
    exportTableLeft = () =>{};
    // 左边表格：获取勾选中的值
    onSelectChangesLeft =()=>{};
    // 左边表格：刷新方法
    refreshTableLeft = () => {
        this.setState({refreshTable: true});
        this.getCommodityData() //查询供应商商品表格数
    };
    // 左边表格：获取当前页数和条数
    getPaginationLeft = (page, pageSize) => {
        let {leftParams} = this.state;
        leftParams.page = page;
        leftParams.size = pageSize;
        this.setState({params: leftParams});
        this.getCommodityData() //查询供应商商品表格数
    };
    // 右边表格：导出
    exportTableRight = () => {
        console.log('数据导出')
    };
    // 右边表格：获取勾选中的值
    onSelectChangesRight = (data) => {
        console.log(data);
        let {deliveryNoteDeta} =this.state;
        deliveryNoteDeta.meterId=data.id;
        deliveryNoteDeta.modal=data.modelName;
        deliveryNoteDeta.sheetSteelGrade=data.devId;
        this.setState({rowData:deliveryNoteDeta})
    };
    // 右边表格：刷新方法
    refreshTableRight = () => {
        this.setState({refreshTable: true});
        this.getCommodityData() //查询供应商商品表格数
    };
    // 右边表格：获取当前页数和条数
    getPaginationRight = (page, pageSize) => {
        let {rightTableData} = this.state;
        rightTableData.paginationProps.page = page;
        this.setState({rightTableData: rightTableData});
        this.getCommodityData() //查询供应商商品表格数
    };

    // 刷新采购单
    getMeterOrderList=()=>{
        getMeterOrderList({id: this.props.location.state.id}).then(
            response=>{
                console.log("刷新采购单");
                console.log(response.data.data);
                if(response){
                    let {data} = response.data;
                    let {leftTableDatas,leftParams,} = this.state;
                    leftParams.total=data.length;       // 左框列表表具数量
                    leftTableDatas.data = data;                    // 左框列表数据
                    console.log(leftTableDatas.data);
                    this.setState({leftTableDatas:leftTableDatas,leftParams:leftParams})
                }

            })
    };
    // 根据订单类型切换表单字段
    getOneOrderForm=()=>{
        switch (this.state.meterOrderForm.type) {
            case 1:
                return this.state.leftTableDatas.tabelDataOne;
            case 2:
                return this.state.leftTableDatas.tabelDataTwo;
            default:
                return []
        }
    };
    // 查询一个订单详情不包括表具列表
    getMeterOrderById=()=>{
        try{
            if(this.props.location.state.id===null){
                return message.info("异常问题，未能检查到订单号");
            }
        }catch(Exception){
            return message.info("异常问题，请重新登录");
        }
        getMeterOrderById({id: this.props.location.state.id}).then(
            response=>{
                console.log("查询一个订单内的商品信息");
                console.log(response);
                let {data} = response.data;
                let {meterOrderForm,rightTableData} = this.state;
                rightTableData.paginationProps.areaId =data.supplierId;    // 区域id,用于采集供应商表具
                meterOrderForm=data;
                if(meterOrderForm.areaId!==null){

                }
                if(meterOrderForm.state===1){
                    meterOrderForm.state="未发货"
                }else if(meterOrderForm.state===2){
                    meterOrderForm.state="已发货"
                }if(meterOrderForm.state===3){
                    meterOrderForm.state="部分发货"
                }

                this.setState({
                    loading: false,
                    refreshTable: false,
                    meterOrderForm:meterOrderForm,
                    rightTableData:rightTableData
                });
                this.getAllAreaInfo();           // 获取所有区域id
                this.getCommodityData();         // 查询供应商表具列表
                this.getShippingList();          // 获取发货清单数据
                this.getMeterOrderList();        // 刷新采购单
                message.success('查询成功');
                return true;
            }

        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败');
                return false;
            }
        )
    };

    //查询供应商表具列表
    getMetersBySteelNum=()=>{
        getMetersBySteelNum(this.state.rightTableData.paginationProps).then(
            response=>{
                console.log("查询供应商表具列表");
                console.log(response);
                let {rightTableData} = this.state;
                if(response.data.data!==null){
                    let data = response.data;
                    rightTableData.data = data.data;
                    rightTableData.paginationProps.total=data.pageInfo.total;
                    // message.success('查询成功');
                }else{
                    rightTableData.data =[];
                    message.success('无数据')

                }
                this.setState({
                    loading: false,
                    refreshTable: false,
                    rightTableData
                });
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')

            }
        );
    };
    getRefreshDatas=()=>{   // 刷新按钮
        let {rightTableData} = this.state;
        rightTableData.paginationProps.steelNum=null;
        rightTableData.paginationProps.modelId=null;
        this.setState(rightTableData);
        this.getCommodityData();     //  查询供应商商品表格数
    };
    // 查询供应商商品表格数据
    getCommodityData =()=>{
        this.setState({loading: true});
        this.getMetersBySteelNum(); //查询供应商商品表格数据

    };

    // 添加到发货单按钮
    checkboxTable = () => {};


    //  发货按钮
    shipments=(modal1Visible)=> {
        let {shippingList,placeholderz,manageName} = this.state;
        if(placeholderz.key!==null){
            shippingList.deliveryNote.areaId=placeholderz.key;
            shippingList.deliveryNote.areaName=placeholderz.value;
        }
        this.setState({shippingList:shippingList});
        if(this.state.shippingList.deliveryNote.commodity.length!==0){      // 无商品不能发货
            console.log(this.state.shippingList.deliveryNote);
            if(this.state.shippingList.deliveryNote.areaId===null){
                message.info("发货区域不能为空");
                return false;
            }
            supplierShip(this.state.shippingList.deliveryNote).then(
                response=>{
                    if(response.data.data){
                        message.info("发货成功");
                        this.handleDelete();            // 清空清单内的表具
                        this.derailingSwitch1(modal1Visible);
                        this.getMeterOrderById();       // 获得指定id订单详情
                        this.getRefreshDatas();
                        funTraceInfo(manageName,"发货","参数："+this.state.shippingList.deliveryNote,"发货成功")
                    }else{
                        message.info("发货失败");
                        this.derailingSwitch1(modal1Visible);
                        funTraceInfo(manageName,"发货","参数："+this.state.shippingList.deliveryNote,"发货失败")
                    }
                }
            ).catch((e)=>{
                message.info("发货失败");
                this.derailingSwitch1(modal1Visible);
                funTraceInfo(manageName,"发货","参数："+this.state.shippingList.deliveryNote,"异常问题，发货失败")
            });

        }else {
            message.info("发货商品不能为空")
        }


    };
    // 删除清单内的表具
    handleDelete = (value) => {
        // 发货-临时单商品删除
        updataDeliveryNode(value).then(
            response=>{
                console.log("删除清单内的表具");
                console.log(response);
                if(response.data){

                    this.getShippingList();         // 获取发货清单数据
                    this.getMeterOrderList();       // 刷新采购单
                }else{
                    message.info("删除失败")
                }
            }
        ).catch(()=>{ message.info("异常失败")})

    };

    showTotal=(total)=> {
        console.log(total)
    };
    // 表具添加申请
    addOrderButton=(value)=>{

        let {deliveryNoteDeta,manageName} =this.state;
        deliveryNoteDeta.meterId=value.id;
        deliveryNoteDeta.model=value.model;
        deliveryNoteDeta.modelId=value.modelId;
        deliveryNoteDeta.type=value.type;
        deliveryNoteDeta.orderId=this.state.meterOrderForm.code;
        deliveryNoteDeta.steelNum=value.steelNum;
        let user = JSON.parse(sessionStorage.getItem("user")??null);    // 获取发货人id
        deliveryNoteDeta.consignor =user.id;
        if(deliveryNoteDeta.steelNum!==null){   // 没有表刚号不添加

            addDeliveryNote(deliveryNoteDeta).then(
                response =>{
                    console.log("提交清单表具是否成功");
                    console.log(response);
                    if(response.data.data){
                        this.getMeterOrderList();       // 刷新采购单
                        this.getShippingList();         // 获取发货清单数据
                        funTraceInfo(manageName,"清单表具添加","参数："+deliveryNoteDeta,"添加成功");
                        return message.info("添加成功");
                    }else {
                        // return message.info(response.data.responseInfo.description);
                        // if(this.state.meterOrderForm.type===2){
                        //     return message.info("该订单以发货");
                        // }
                        this.derailingSwitch5(true);
                        funTraceInfo(manageName,"清单表具添加","参数："+deliveryNoteDeta,"添加失败");
                    }
                }
            ).catch(() => {

                funTraceInfo(manageName,"清单表具添加","参数："+deliveryNoteDeta,"添加失败");
                return message.info("添加失败")
                }
            );

        }
    };
    // 添加表具判断
    addDataJudge=(value)=> {
        this.getShippingList();
        let {shippingList} = this.state;
        let Judge=false;
        console.log("添加表具判断");
        for(let i=0;i<shippingList.deliveryNote.commodity.length;i++){
            if(shippingList.deliveryNote.commodity[i].meterId === value.id.toString()){
                Judge=true;
            }
        }
        return Judge;
    };
    // 变更颜色-通过清单选中表具修改CSS样式
    setRightRowClassName=(value)=>{
        let deliveryNote = this.state.shippingList.deliveryNote;   //  选购数据
        for(let i=0;i<deliveryNote.commodity.length;i++){
            if(value.steelNum===deliveryNote.commodity[i].steelNum){
                return 'table-color-dust'
            }else if(value.id===452744){
            }
        }


    };
    // 路由跳转 - 清单不符合取消清空调转回前页面
    ReturnToPage=()=>{
        return this.props.history.go(-1);
    };
    // 清空清单功能-在判断条件时候启动
    deleteInventory=()=>{
        getDeleteAllTemporay().then(
            response=>{
                console.log("清空清单");
                console.log(response);
                if(!response.data){
                    let shippingList = this.state.shippingList;
                    shippingList.deliveryNote.quantity="";
                    shippingList.deliveryNote.commodity=[];
                    this.getMeterOrderList();       // 刷新采购单
                    this.setState(shippingList);
                    this.derailingSwitch2(false)
                }else{
                    message.info("删除失败");
                    this.ReturnToPage();
                }
            }
        )
    };
    // 左表点击内容事件
    onLeftClickRow=(record)=>{
        console.log("左表点击内容事件");
        let {meterOrderForm} = this.state;
        console.log(meterOrderForm);
        let {rightTableData} = this.state;  // 调用右表管理参数
        if(meterOrderForm.type===1){
            rightTableData.paginationProps.steelNum = record.steelNum;
            rightTableData.paginationProps.modelId=null;
            rightTableData.paginationProps.page=1;
            rightTableData.paginationProps.total=0;
            this.setState({rightTableData:rightTableData});
            console.log(rightTableData);
            this.getCommodityData();        // 获得表具商品列表
        }else if(meterOrderForm.type===2) {
            rightTableData.paginationProps.steelNum = null;
            rightTableData.paginationProps.modelId=record.modelId;
            rightTableData.paginationProps.page=1;
            rightTableData.paginationProps.total=0;
            this.setState({rightTableData:rightTableData});
            console.log(rightTableData);
            this.getCommodityData();        // 获得表具商品列表
        }

    };
    // 实时获得下拉框数据
    selectChang=(value,index)=>{
        console.log("实时获得下拉框数据");
        let {shippingList} = this.state;
        shippingList.deliveryNote.areaId=index.key;
        shippingList.deliveryNote.areaName=index.value;
        this.setState({shippingList:shippingList});
    };

    render(){

        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                {/*<div className={'div_back_arrow'}>*/}
                {/*    */}
                {/*    */}
                {/*    */}
                {/*</div>*/}
                <div className={'div_back_arrow'}>
                    <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回订单管理</span>
                    <span className={' font_div font_size2'}>发货管理</span>
                </div>
                <div id={'deliver_div'}>
                    {/*台头内容*/}
                    <div className={'head_div deliver_from_text'}>
                        <FormsText
                            record = {this.state.meterOrderForm}
                            header = {this.state.realTimeData.header}
                            rowLen={6}
                        />
                    </div>
                    {/*左框*/}
                    <div className={"leftTableData_div"}>
                        <div className={"leftTableData_button_div"}>
                            <div >
                                <Button type={'primary'} onClick={() => this.shipments(false)}>发货</Button>&nbsp;
                                <Button type={'primary'} onClick={() => this.setModal1Visible(true)}>发货清单</Button>&nbsp;&nbsp;
                                <span>请选择收货点：</span>
                                <Select style={{ width: 200 }}
                                        showSearch
                                        onChange={this.selectChang}
                                        placeholder={this.state.placeholderz.value}
                                        disabled={this.state.placeholderz.selectSwitch}
                                        // onSearch={this.state.selectSearch}
                                >
                                    {
                                        this.state.options.map( item => (
                                             <Option key={item.key} value={item.value}  disabled={item.disable}>{item.value}</Option>)
                                        )
                                    }
                                </Select>&nbsp;
                            </div>
                        </div>
                        <div className={"leftTableData_Top_div1"}>
                            <div className={"leftTableData_Title_div"}>
                                <span className={'font_size0'}>选购列表</span>
                            </div>
                        </div>
                        <Table
                            bordered
                            pagination = {{
                                showSizeChanger:true,
                                showQuickJumper: true,
                                onChange: this.getPaginationLeft,       // 获得翻页数值
                                total:this.state.leftParams.total,
                                pageSize:12,
                                showTotal: (count = this.state.leftParams.total)=>{
                                    return '共'+this.state.leftParams.total+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['12']
                            }}
                            dataSource={this.state.leftTableDatas.data}
                            columns={this.getOneOrderForm()}
                            rowKey={"id"}
                            scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                            // scroll={{ y: 550 ,x:1000}}
                            onRow={(record) => ({
                                onClick: () => {
                                    this.onLeftClickRow(record);
                                },
                            })}
                            // rowClassName={this.onLeftClickRow}
                        />
                    </div>
                    {/*左框 END*/}
                    {/*右框*/}
                    <div className={'rightTableData_div'}>
                        <div className={"nTable_info_div"}>
                            <div className={"rightTableData_button_div"}>
                                {/*<span>供应商：</span>*/}
                                <Input type="text" placeholder="输入单号" style={{ width: 200 }} onChange={this.onSearch} datatype={true}/>&nbsp;
                                <Button className={"rightTableData_button_div_sx"}
                                        type={'primary'}
                                        onClick={this.getRefreshDatas}>刷新
                                        {/*// onClick={() => this.derailingSwitch5(true)}>刷新*/}
                                </Button>&nbsp;
                            </div>
                            <div className={"leftTableData_Top_div1"}>
                                <div className={"leftTableData_Title_div"}>
                                    <span className={'font_size0'}>表具列表</span>
                                </div>
                            </div>
                            <Table
                                bordered
                                pagination = {{
                                    showSizeChanger:true,
                                    showQuickJumper: true,
                                    onChange: this.getPaginationRight,       // 获得翻页数值
                                    total:this.state.rightTableData.paginationProps.total,
                                    pageSize:12,
                                    showTotal: (count = this.state.rightTableData.paginationProps.total)=>{
                                        return '共'+this.state.rightTableData.paginationProps.total+'条数据'
                                    },
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['12']
                                }}
                                dataSource={this.state.rightTableData.data}
                                columns={this.state.rightTableData.columns}
                                rowKey={"id"}
                                scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                // scroll={{ y: 750,x:1200 }}
                                // onRow={this.onClickRow}
                                rowClassName={this.setRightRowClassName}
                            />


                            <Modal
                                title="待生成发货单"
                                style={{ top: 0 }}
                                visible={this.state.modal1Visible}
                                okText="发货"
                                onOk={() => this.shipments(false)}                  // 点击确定
                                onCancel={() => this.derailingSwitch1(false)}       // 点击取消
                                width={900}
                                centered
                            >
                                <div className={'head_div'}>
                                    <FormsText
                                        record = {this.state.shippingList.deliveryNote}
                                        header = {this.state.shippingList.deliveryHead}
                                        rowLen={4}
                                        span={8}
                                    />
                                </div>
                                <div>
                                    <Table
                                        bordered
                                        dataSource={this.state.shippingList.deliveryNote.commodity}
                                        columns={this.state.shippingList.deliveryNoteHear}
                                        scroll={{ y: 450 }}
                                    />
                                </div>
                            </Modal>
                            <Modal
                                title="提示"
                                style={{ top: 0 }}
                                visible={this.state.moda31Visible}
                                okText="确定"
                                onOk={() => this.derailingSwitch3(false)}           // 点击确定
                                onCancel={() => this.derailingSwitch3(false)}       // 点击取消
                                width={300}
                                centered
                            >
                                <div>
                                    <h3>申请发货表具商品过多，将无法正常发货</h3>
                                </div>
                            </Modal>
                            <Modal
                                title="发货清单"
                                style={{ top: 0 }}
                                visible={this.state.moda21Visible}
                                okText="清空"
                                onOk={() => this.deleteInventory()}           // 点击确定
                                onCancel={() => this.ReturnToPage()}       // 点击取消
                                width={420}
                                centered
                            >
                                <div>
                                    <h3>当前订单数据与用户发货清单数据不符，是否清空清单数据</h3>
                                </div>
                            </Modal>


                            <Modal
                                title="提示"
                                style={{ top: 0 }}
                                visible={this.state.moda51Visible}
                                okText="确定"
                                onOk={() => this.derailingSwitch5(false)}           // 点击确定
                                onCancel={() => this.derailingSwitch5(false)}       // 点击取消
                                width={300}
                                centered
                            >
                                <div>
                                    <h3>当前添加表具不服要求，请检查选择数量或点击左表表具筛选右表表具内容</h3>
                                </div>
                            </Modal>

                        </div>

                    </div>
                </div>
            </Layout>
        )
    }
}
