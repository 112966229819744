import React, { Component } from 'react'
import {Button, Checkbox, DatePicker, Image, Input, Layout, message, Modal, Spin, Switch, Table, Tooltip,} from "antd";
import './customerVisualDataInterface_v.01.less'
import HalfDoughnutChart1 from "./js/HalfDoughnutChart";
import BarChart1 from "./js/BarChart_1";
import BarChart2 from "./js/BarChart_1";
import BasicRadarChart from './js/BasicRadarChart'
import EZUIKitPlayer from "./js/EZUIKitPlayer";
import DetailedReport from "./js/detailedReport";
import {DoubleRightOutlined,LoadingOutlined,UndoOutlined} from '@ant-design/icons'

import {
    getDevList,
    getDevNumber,
    getAlarmNumber,
    getAlarmList,
    homePageGasAnalysis,
    largeScreenSelfCheckInfoRecord,
    safetyAnalysis,
    liveFluoriteDev,
    queryLearning,
    largeScreenHiddenDanger, getEvaluationResults, queryLearningByCustomer, updateResults
} from "../../services/customerDataPanel";
import {
    getAllInfoByCustomerId, getDetailByLearnId, learningTaskReporting,
    pageHiddenDanger,  updateStatusByCustomerId
} from "../../services/meter";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {getLocationById, getSafetyOfficerByCustomerId} from "../../services/customer";
import $ from "jquery";
import {TYPE_NAME} from "../../components/Const";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {getPageFuzzyQuerys} from "../../services/alarm";
import Tables from "../../components/common/tables/Tables";
import Conditions from "../../components/common/conditions/Conditions";

const { RangePicker } = DatePicker;
const {TextArea} = Input;
const {AMap} =window;
export default class customerVisualDataInterface extends Component {

    state={
        headLeftDate:null,  // 时间
        headRightWeather:null, // 天气
        headRightTemperature:null, // 温度
        visible_1:false,    // 弹窗;
        visible_2:false,    // 文本弹窗
        visible_3:false,    // 数据弹窗
        visible_4:false,    // 下方-1-数据表格弹窗
        visible_5:false,    // 下方-2-数据表格弹窗
        visible_6:false,    // 中右-数据表格弹窗
        visible_7:false,    // 下方-3-数据表格弹窗
        visible_1_title:'对话框',// 弹窗文字
        leftData_status:'打开',
        // 环形圆进度条
        leftData_1:{name:'立方',data:9,color:['#3D8DFF','#62DBFF']},
        // 环形圆进度条
        leftData_2:{name:'立方',data:91,color:['#96EE79','#F7FD86']},
        // 曲线图-竖向-1
        leftData_barChart_1:{
            // 数值
            data:[
                {name:'日用气量',color:['#07EDE1','#2BA4B8'], data:[]}],
            yData:[],// X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:1000} // 最小、最大、间距
        },
        // 曲线图-竖向-2
        leftData_barChart_2:{
            // 数值
            data:[{name:'月用气量',color:['#49BCFF','#1770F7'], data:[]}],
            yData:[],// X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:3000} // 最小、最大、间距
        },
        // 雷达图
        rightData_1:{
            data:{name:'风险率', value:[57, 42, 87, 42, 77, 72, 37]},
            yData:[
                { name: '警情风险', max: 100 },
                { name: '隐患风险', max: 100 },
                { name: '工作风险', max: 100 },
                { name: '设备风险', max: 100 },
                { name: '计划完成风险', max: 100 },
                { name: '管理风险', max: 100 }
            ]
        },
        // 设备在线数-底部表格1
        bottomOne_devNumber: {
            "airBottle": 0,     // 气瓶
            "detector": 0,      // 探测器
            "meter": 0,         // 表具
            "pressureGage": 0,  // 压力表
            "valve": 0          // 阀门
        },
        // 设备报警次数-底部表格3
        bottomThree_devNumber:{
            "abnormalAlarm": 0, // 异常报警
            "alarmNumber": 0,   // 报警次数
            'status':'关'        // 电动阀开关
        },

        checkStatus:true,    // 检查状态
        inletBallValve:true, // 进气球阀-开关按钮
        exhaustFan:true,     // 排气扇-开关按钮
        electricValve:true,  // 电动阀-开关按钮

        // 表格数据-1
        table_data_1:[
            {model:'设备型号',address:'安装点地点',type:'设备状态',date:'数据时间',edit:'操作'},
            {model:'CSJQ-0013路',address:'徐福记海鲜洋湖店',type:'正常',date:'2024.01.17',edit:'查看'},
            {model:'CSJQ-0013路',address:'徐福记海鲜洋湖店',type:'过期',date:'2024.01.17',edit:'查看'},
            {model:'CSJQ-0013路',address:'徐福记海鲜洋湖店',type:'正常',date:'2024.01.17',edit:'查看'},
            {model:'CSJQ-0013路',address:'徐福记海鲜洋湖店',type:'正常',date:'2024.01.17',edit:'查看'},
            ],
        table_data_1_param:{
            customerId: new URLSearchParams(window.location.search).get('customerId')??null,
            devType: "",
            page: 0,
            size: 10
        },

        // 表格数据-2
        table_data_2:[
            {pointType:'设备型号',address:'安检位置',pointStatus:'安检状态',concentration:'可燃点浓度',time:'自检时间'},
            {pointType:'5899663321',address:'厨房表具接口',pointStatus:'到期末自检',concentration:'0',time:'2024.01.17'},
            {pointType:'5899663321',address:'弯管接口',pointStatus:'正常',concentration:'0',time:'2024.01.17'},
            {pointType:'5899663321',address:'阀门接口',pointStatus:'正常',concentration:'0',time:'2024.01.17'},
            {pointType:'5899663321',address:'阀门接口',pointStatus:'正常',concentration:'0',time:'2024.01.17'},
        ],

        // 表格数据-3
        table_data_3:[
            {alarmType:'2024.1.2 报警器、电动阀未维护',edit:'查看'},
            {alarmType:'报警器、电动阀到期未检定',edit:'查看'},
            {alarmType:'燃气设施触电',edit:'查看'},
            {alarmType:'燃气设施触电',edit:'查看'},
        ],
        table_data_3_param:{
            customerId: new URLSearchParams(window.location.search).get('customerId')??null,
            devType: "",
            page: 1,
            size: 10
        },


        table_head_4:[
            {c:'对象',e:'type'},
            {c:'隐患名称',e:'name'},
            {c:'分级',e:'level'},
            {c:'发现时间',e:'time'},
            {c:'状态',e:'edit'},
        ],
        // 表格数据-4-中-右
        table_data_4:[

            // {model:'安全管理',address:'燃气设施接触电线',type:'I',date:'2024.01.17',edit:'未处理'},
            // {model:'安全管理',address:'燃具无熄火保护装',type:'II',date:'2024.01.17',edit:'未处理'},
            // {model:'户内管道设施',address:'燃气设施接触电线',type:'III',date:'2024.01.17',edit:'未处理'},
            // {model:'燃烧器具',address:'燃气设施接触电线',type:'I',date:'2024.01.17',edit:'未处理'},
        ],
        // 表格数据-5-下-4
        table_data_5:[],
        table_data_5_Param:{size:'6',page:"1",determine:true},

        topFormItem: {
            modal: true,
            number: 3,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds',
                    str: '区域选择',
                    fieldNames:{label: 'name', value: 'id', children: 'children'},
                    rules: TYPE_NAME.TREE_SELECT,
                    disabled: this.props.location.state ? true : false,
                    data:[],
                    onChange: (k,v) => this.getAllDev(k,v)
                },
                {
                    variableName: 'devIds',
                    str: '设备选择',
                    // isMultiple:true,
                    disabled: false,
                    data:[],
                    rules: TYPE_NAME.SELECTINPUT,
                },
                {
                    variableName: 'createTime',
                    str: '时间范围',
                    rules: TYPE_NAME.RANGE_REQ_DATE_PICKER,
                },

            ]
        },
        searchGetData:{},
        // 下方1-表格查询参数
        topFormItem_1: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'devType', str: '设备类型', rules: TYPE_NAME.SELECT,
                    data:[
                        {key:'探测器',value:'探测器'},
                        {key:'表具',value:'表具'},
                        {key:'气瓶',value:'气瓶'},
                        {key:'阀门',value:'阀门'},
                        {key:'阀井',value:'阀井'},
                        {key:'压力表',value:'压力表'},
                    ]
                }
            ],
            searchGetData:{}
        },
        // 下方1-表格参数
        tableParamenter_1: {
            manageName: "临时数据",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                devModelName: '设备型号',
                installPosition: '安装地址',
                state: '状态',
                dataTime:'时间',
                // edit: '操作',
            },

            params: {
                customerId: new URLSearchParams(window.location.search)?.get('id')??null,
                devType: "探测器",
                page: 0,
                size: 10
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        // 下方2-表格
        tableData_2: [
            {title: '安检点类型', dataIndex: 'type', key: 'type'},
            {title: '电子标签号', dataIndex: 'ifIdCode', key: 'ifIdCode'},
            {title: '设备编号', dataIndex: 'devId', key: 'devId'},
            {title: '安检点状态', dataIndex: 'selfInspectionStatus', key: 'selfInspectionStatus'},
            {title: '最后安检时间', dataIndex: 'endSecurityCheckTime', key: 'endSecurityCheckTime'},
            {title: '最后自检时间', dataIndex: 'endSelfTestTime', key: 'endSelfTestTime'},
        ],
        tableData_2_data:[], // 下方2-表格参数

        // 中-右-表格参数
        tableParamenter_3: {
            manageName: "安全隐患",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                customerName: '客户名称',
                type: '隐患',
                classification: '类型',
                level: '级别',
                state: '状态',
                edit: '操作',
            },

            params: {
                customerName: new URLSearchParams(window.location.search)?.get('customerName')??null,
                page: 1,
                size: 10
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        // 下方-3-表格参数
        tableParamenter_4: {
            manageName: "安全隐患",
            rowSelection: false,       // 表格行是否可选
            selectWidth: '6.5vw',    //下拉框长度
            bindArea: true,     // 是否绑定左侧区域
            // 表头：变量和变量解释
            header: {
                sourceName : '来源名称',
                alarmDescription : '报警描述',
                alarmTime : '报警时间',
                associationName : '报警对象',
                alarmType : '报警类型',
                alarmLevel : '报警等级',
                state:'状态',
            },
            params: {

                total:0,

                "page": {
                    "current": 1,
                    "size": 13
                },
                // "userId": 282,
                userId:JSON.parse(sessionStorage.getItem("user"))?.id ?? null,
                code: new URLSearchParams(window.location.search)?.get('customerName')??null,

            },

            // 表格数据
            data: [],
        },
        // 下方-4-表格参数
        tableParamenter_5: {
            manageName: "安全隐患",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                sourceName : '来源名称',
                alarmDescription : '报警描述',
                alarmTime : '报警时间',
                associationName : '报警对象',
                alarmType : '报警类型',
                alarmLevel : '报警等级',
                state:'状态',
            },
            params: {
                customerName: new URLSearchParams(window.location.search)?.get('customerName')??null,
                page: 0,
                size: 10
            },
            // 是否绑定左侧区域
            bindArea: true,
        },

        tableParamenter_6: {
            columns:[
                {title: '来源名称', dataIndex: 'sourceName', key: 'sourceName',},
                {title: '报警描述', dataIndex: 'alarmDescription', key: 'alarmDescription',},
                {title: '报警时间', dataIndex: 'alarmTime', key: 'alarmTime',},
                {title: '报警对象', dataIndex: 'associationName', key: 'associationName',},
                {title: '报警类型', dataIndex: 'alarmType', key: 'alarmType',},
                {title: '报警等级', dataIndex: 'alarmLevel', key: 'alarmLevel',fixed: 'right',},
                {title: '状态', dataIndex: 'state', key: 'state',fixed: 'right',},
            ],
            data: [],// 表格数据
            loading:false,
            params:{
                total:0,
                page: {
                    current: 1,
                    size: 13
                },
                userId:JSON.parse(sessionStorage.getItem("user")).id,
                // code: new URLSearchParams(window.location.search)?.get('customerName')??null,

            }

        },


        modal_table_data_1:[],// 弹窗表格数据
        querySecurityAssessment:null,   // 安全评估二维码-中-右
        evaluationPath:null,            // 中-右-二维码路径
        evaluationResults:null,         // 中-右-二维码名

        liveFluoriteDevs:null,                  // 视频数据
        playerParam:{size:4, page:1,total:15},  // 视频参数
        administrators:{name:null,phone:null},  // 顶部-左侧数据-管理员
        adminHead:{name:null,phone:null},       // 顶部-左侧数据-负责员
        titleInfo_1:[{name:'客服',title:'你好',float:true},],// 文本信息-客服对话
        titleInfo_2:null,


        countDown:null,             // 即时秒
        countDown_disabled:true,    // 文案解锁按钮
        setCountDown:null,          // 计时器
        countDownType:false,        // 时间类型
        countDownTitle:false,
        requestType:true,

        dates:null,     // 时间
        configId:null,  // id
        setStartPackage:null,// 计时器缓存-可能无用

        startTimeOfVacation:null,
        endTimeOfVacation:null,


        modalLoading_1:false,   // 按钮加载
        Loading_1:true,         // 二维码刷新

        warningPrompt:false,    // 请求时间
        warningNum:0,           // 请求时间

        requestSwitch:0,       // 请求开关

        cachingData:null,

    };
    componentDidMount =async ()=> {
        this.startPackage();
        var setStartPackage = this.state.setStartPackage;
        // 定时刷新
        clearInterval();                // 开局清空计时器-无指定
        clearInterval(setStartPackage); // 开局清空计时器
        setStartPackage=null;           // 先置空
        setStartPackage = setInterval(this.startPackage,10000);// 加载即时内容
        this.setState({setStartPackage});// 保存

        var urlObject = new URLSearchParams(window.location.search);
        var id = urlObject.get('id');
        var areaId = urlObject.get('areaId');
        this.liveFluoriteDev(id);                 // 视频列表-中-中
        this.updateResults();                     // 更新二维码
        this.getSafetyOfficerByCustomerId(id);    // 管理员负责人
        this.getTime();                           // 初始化加载当天时间
        this.getDate();                           // 初始化加载当天天气
        this.homePageGasAnalysis(id);             // 首页用用气分析-中-左
        this.largeScreenHiddenDanger(id,areaId);  // 安全隐患列表-中-右
        this.getDevList('探测器');           // 设备表格列表-小龙-下-1
        this.getDevNumber(id);                    // 设备数量-小龙-下-1
        // this.queryLearning();                  // 分页条件查询安全学习信息-小龙-下-4-旧-不用
        this.queryLearningByCustomer(id);         // 分页条件查询安全学习信息-小龙-下-4-新

        this.getAlarmList('报警次数');       // 报警列表(报警信息)-小龙-下-3

    };
    startPackage=()=>{


        // 设置日期选择器的最小时间为当前时间减去10秒
        // var datePicker = document.getElementById('your-date-picker-id');
        // datePicker.min = currentTime.toISOString().split('.')[0] + 'Z'; // 转换为ISO格式并去掉毫秒部分


        // if(this.state.requestSwitch===0){
        //     console.log('-------1---------');
        //     console.log(this.state.requestSwitch);
        // }
        var urlObject = new URLSearchParams(window.location.search);
        var id = urlObject.get('id');

        this.querySecurityAssessment(id);         // 安全评估二维码-中-右

        // this.safetyAnalysis(id);               // 安全分析雷达图-中-右-别的地方启动
        // this.pageHiddenDanger();               // 隐患列表-中-右-不用

        this.largeScreenSelfCheckInfoRecord(id);  // 大屏安检信息-邓总-下-2
        this.getAlarmNumber(id);                  // 报警数量(报警信息)-小龙-下-3
        // this.getAlarmList('报警次数');       // 报警列表(报警信息)-小龙-下-3

    };

    getDate=()=>{
        console.log('天气预报-------------------------');
        AMap.plugin('AMap.Weather', function() {
            //创建天气查询实例
            var weather = new AMap.Weather();
            //执行实时天气信息查询
            weather.getLive('长沙市', function(err, data) {
                $('#weather').text(data?.weather ?? '天气');
                $('#temperature').text(data?.temperature+'°' ?? '气温');
            });
        });
        };

    // 循环时间
    circulateTime=()=>{
        var urlObject = new URLSearchParams(window.location.search);
        var id = urlObject.get('id');
        this.getAlarmNumber(id);                    // 报警数量(报警信息)-小龙-下-3
        this.getAlarmList('报警次数');          // 报警列表(报警信息)-小龙-下-3
    };
    // 大屏安全隐患
    largeScreenHiddenDanger=(id,areaId)=>{
        console.log('安全评估列表');
        var tableData4 = this.state.table_data_4;
        tableData4=[];
        largeScreenHiddenDanger({'customerId':id,'areaId':areaId}).then(
            response=>{
                console.log('----------------------');
                console.log(response?.data?.data);
                if(response?.data?.data){
                    response.data.data.map(one=>{tableData4.push(one)});
                }
                this.setState({table_data_4:tableData4});
            }
        ).catch(e=>this.setState({table_data_4:tableData4}))
    };

    // 分页条件查询当前客户的安全学习信息
    queryLearningByCustomer=(id)=>{
        var param = this.state.table_data_5_Param;
        param.customerId=id;
        queryLearningByCustomer(param).then(
            response=>{
                if(response?.data?.data){
                    var tableData5 = this.state.table_data_5;
                    tableData5=[];
                    response?.data?.data.map(one=>{
                        tableData5.push(one)
                    });
                    this.setState({table_data_5:tableData5})
                }else{
                    if(Number(param.page)<1){
                        param.page=Number(param.page)-1;
                    }
                }
                param.determine=true;
                this.setState({table_data_5_Param:param})
            }
        )
    };

    // 分页条件查询安全学习信息
    queryLearning=()=>{
        var param={
            // "content": "",  // 内容
            // "ids": [],      // id
            "page": 1,      // 页数
            "size": 5,      // 数量
            // "state": 0,     // 状态
            // "title": ""     // 标题
        };
        console.log('分页条件查询安全学习信息');
        queryLearning(param).then(
            response=>{
                console.log('分页条件查询安全学习信息');
                this.setState({table_data_5:response?.data?.data??[]})
            }
        );
    };
    // 管理员负责人
    getSafetyOfficerByCustomerId=(id)=>{
        var param = {
            "id": id,
            "page": 1,
            "size": 99
        };
        getSafetyOfficerByCustomerId(param).then(
            response=>{
                var {administrators,adminHead} = this.state;
                if(response?.data?.data){
                    console.log('管理员负责人');
                    response?.data?.data.map(one=>{
                        if(one.type==='管理员'){
                            administrators.name=one.name??null;
                            administrators.phone=one.phone??null;
                        }else if(one.type==='负责人'){
                            adminHead.name=one.name??null;
                            adminHead.phone=one.phone??null;
                        }
                    });
                    this.setState({administrators,adminHead})
                }
            }
        );
    };

    getTime=()=>{
        //获得显示时间的div
        var now=new Date();
        //替换div内容
        var t_div  =  <>
            <div>
                <div style={{width:'28%',float:'left'}}>
                    <span>{moment(now).format("HH:mm:ss")}</span>
                </div>
                <div style={{width:'40%',float:'left'}}>
                    <span>{moment(now).format("yyyy-MM-DD")}</span>
                </div>
                <div style={{width:'20%',float:'left'}}>
                    <span>{' 星期'+'日一二三四五六'.charAt(new Date().getDay())}</span>
                </div>

                {/*moment(now).format("HH:mm:ss  ")+moment(now).format("  yyyy-MM-DD")+("  星期"+now.getDay())*/}
            </div>
        </>;


        this.setState({headLeftDate:t_div});
        // setTimeout(this.getTime(),1000);
    };
    // 视频列表-中-中
    liveFluoriteDev=(id)=>{
        var parms = new URLSearchParams();
        parms.append('customerId',id);
        parms.append('type','大屏');

        liveFluoriteDev(parms).then(
            response=>{
                if(response?.data?.data){
                    var playerParam = this.state.playerParam;
                    if(response.data.data?.infos){
                        playerParam.page = 1;
                        playerParam.total = response?.data?.data?.infos.length;
                    }
                    this.setState({liveFluoriteDevs:response?.data?.data})
                }
            }
        );
    };

    // 安全评估列表-中-右
    querySecurityAssessment=(id)=>{
        this.setState({requestSwitch:this.state.requestSwitch+=1});
        getEvaluationResults({'customerId':id}).then(
            response=> {
                this.setState({requestSwitch:this.state.requestSwitch-=1});
                if(response?.data?.data){
                    if(response?.data?.data?.evaluationResults==='安全'){
                        this.safetyAnalysis(id,true);                  // 安全分析雷达图-中-右
                    }else{
                        this.safetyAnalysis(id,false);                  // 安全分析雷达图-中-右
                    }
                    this.setState({
                        querySecurityAssessment:response?.data?.data,
                        evaluationPath:response.data?.data?.evaluationPath,
                        evaluationResults:response.data?.data?.evaluationResults,

                    });
                }else{
                    this.safetyAnalysis(id,false);                  // 安全分析雷达图-中-右-别的地方启动
                }

            }
        )
    };


    // 隐患列表
    pageHiddenDanger=()=>{
        var params={
            customerName:this.props.location.state.rowData.name,
            "page": 1,
            "size": 10,
        };
        pageHiddenDanger(params).then(
            response=>{
                console.log('安全评估列表');
                var tableData4 = this.state.table_data_4;
                tableData4=[];
                tableData4.push({type:'对象',name:'隐患名称',level:'分级',state:'状态'});
                if(response?.data?.data){response.data.data.map(one=>{tableData4.push(one)});}
                this.setState({table_data_4:tableData4});
            }
        )
    };


    // 安全分析-中-右数据
    safetyAnalysis=(id,state)=>{
        console.log('安全分析');
        if(!id){return false;}
        safetyAnalysis({customerId:id}).then(
            response=>{
                var rightData = this.state.rightData_1;
                if(response?.data?.data){
                    var data = response?.data?.data;
                    rightData.data.data=[];
                    // true就全满,false就用接受数据
                    if(state){
                        rightData.data.data.push(100);//警情风险
                        rightData.data.data.push(100);// 隐患风险
                        rightData.data.data.push(100);// 工作风险
                        rightData.data.data.push(100);//设备风险
                        rightData.data.data.push(100);// 计划完成风险
                        rightData.data.data.push(100);// 管理风险
                    }else{
                        rightData.data.data.push(Number(data?.warningInstanceRisks)??0);//警情风险
                        rightData.data.data.push(Number(data?.hiddenDangerRisks)??0);// 隐患风险
                        rightData.data.data.push(Number(data?.workRisks)??0);// 工作风险
                        rightData.data.data.push(Number(data?.devRisks)??0);//设备风险
                        rightData.data.data.push(Number(data?.planRisks)??0);// 计划完成风险
                        rightData.data.data.push(Number(data?.manageRisks)??0);// 管理风险
                    }
                    this.setState({rightData_1:rightData})
                }else{
                    this.setState({rightData_1:[0,0,0,0,0,0]})
                }
            }
        );

    };

    // 首页用用气分析-中-左
    homePageGasAnalysis=(id)=>{
        console.log('首页用用气分析');
        if(!id){return false;}
        homePageGasAnalysis([id]).then(
            response=>{
                var {leftData_1,leftData_2,leftData_barChart_1,leftData_barChart_2} = this.state;

                if(response?.data?.data){
                    var data = response?.data?.data;

                    //圆形图
                    if(data?.dayValues){
                        if(data?.dayValues.length !==0 ){
                            // 后第二位
                            if(data?.dayValues[data?.dayValues.length-2]>0){
                                leftData_1.data=data?.dayValues[data?.dayValues.length-2]
                            }else if(data?.dayValues[data?.dayValues.length-2]<=0){
                                leftData_1.data=1;
                            }
                            // 后第一位// 当前弃用
                            // if(data?.dayValues[data?.dayValues.length-1]>0){
                            //     leftData_2.data=data?.dayValues[data?.dayValues.length-1]
                            // }else if(data?.dayValues[data?.dayValues.length-1]<=0){
                            //     leftData_2.data=1;
                            // }


                        }
                    }
                    // 昨日实时数据
                    leftData_2.data=data?.instantaneousFlowRate ?? 1;
                    // 前7天的用量
                    data?.dayValues.map(one=>{
                        if(one<=0){
                            leftData_barChart_1.data[0].data.push(1);
                        }else{
                            leftData_barChart_1.data[0].data.push(one);
                        }
                    });
                    // 前7天的日期
                    leftData_barChart_1.yData = data?.dayTime??[];
                    // 月用量值
                    data?.monthValues.map(
                        one=>{
                            if(one<=0){
                                leftData_barChart_2.data[0].data.push(1)
                            }else{
                                leftData_barChart_2.data[0].data.push(one)
                            }
                            });
                    // 月用量长度
                    leftData_barChart_2.yData = data?.monthTime??[];
                    this.setState({
                        leftData_status:data?.status??'关',
                        leftData_1:leftData_1,
                        leftData_2:leftData_2,
                        leftData_barChart_1:leftData_barChart_1,
                        leftData_barChart_2:leftData_barChart_2
                    })
                }else{
                    leftData_1.data=0; // 昨日用量
                    leftData_2.data=0;     // 今日用量
                    leftData_barChart_1.data[0].data = [];   // 前7天的用量
                    leftData_barChart_1.yData = [];            // 日期
                    leftData_barChart_2.data[0].data = []; // 今年每月用量
                    leftData_barChart_2.yData = [];          // 月长度

                    this.setState({
                        leftData_status:'打开',
                        leftData_1:leftData_1,
                        leftData_2:leftData_2,
                        leftData_barChart_1:leftData_barChart_1,
                        leftData_barChart_2:leftData_barChart_2
                    })
                }
            }
        )

    };

    // 大屏安检信息-下-2
    largeScreenSelfCheckInfoRecord=(id)=>{
        this.setState({requestSwitch:this.state.requestSwitch+=1});
        if(!id){return false;}
        var params = new URLSearchParams();
        params.append('customerIds',id);
        largeScreenSelfCheckInfoRecord(params).then(
            response=>{
                this.setState({requestSwitch:this.state.requestSwitch-=1});
                var {checkStatus,inletBallValve,table_data_2} = this.state;
                table_data_2=[];
                if(response?.data?.data){
                    var data = response?.data?.data;
                    checkStatus=(data?.checkStatus==='未自检')?false:true;

                    inletBallValve=true;
                    if(data?.pointList){
                       if(data?.pointList.length!==0){
                           inletBallValve=(data.pointList[0].valveStatus==='开')?true:false;
                       }
                    }

                    table_data_2.push({pointType:'设备型号',address:'安检位置',pointStatus:'安检状态',concentration:'可燃点浓度',time:'自检时间'});
                    data?.pointList.map(one=>{table_data_2.push(one)});

                    this.setState({
                        checkStatus:checkStatus,        // 检查状态
                        inletBallValve:inletBallValve,  // 阀门状态
                        table_data_2:table_data_2,      // 表格
                    })
                }else{
                    checkStatus=false;
                    inletBallValve=false;

                    table_data_2.push(
                        {pointType:'设备型号',address:'安检位置',pointStatus:'安检状态',concentration:'可燃点浓度',time:'自检时间'},
                        {pointType:'5899663321',address:'厨房表具接口',pointStatus:'到期末自检',concentration:'0',time:'2024.01.17'},
                        {pointType:'5899663321',address:'弯管接口',pointStatus:'正常',concentration:'0',time:'2024.01.17'},
                        {pointType:'5899663321',address:'阀门接口',pointStatus:'正常',concentration:'0',time:'2024.01.17'},
                        {pointType:'5899663321',address:'阀门接口',pointStatus:'正常',concentration:'0',time:'2024.01.17'},
                    );

                    this.setState({
                        checkStatus:checkStatus,        // 检查状态
                        inletBallValve:inletBallValve,  // 阀门状态
                        table_data_2:table_data_2,      // 表格
                    })
                }

            }
        )
    };

    // 设备数量
    getDevNumber=(id)=>{
        if(!id){return false;}
        getDevNumber({customerId:id}).then(
            response=>{
                if(response?.data?.data){
                    this.setState({bottomOne_devNumber:response?.data?.data})
                }else{
                    this.setState({
                        bottomOne_devNumber:{
                            "airBottle": 0,     // 气瓶
                            "detector": 0,      // 探测器
                            "meter": 0,         // 表具
                            "pressureGage": 0,  // 压力表
                            "valve": 0,         // 阀门
                            "valvePit": 0       // 阀井
                        }

                    })
                }
            }
        )
    };
    // 设备列表-探测器
    getDevList=(type)=>{
        console.log('设备列表');
        // if(!this.props?.location?.state?.rowData){return false;}
        var params = this.state.table_data_1_param;
        params.devType=type;
        this.setState({table_data_1_param:params});
        getDevList(params).then(
            response=>{
                var tableData1 = this.state.table_data_1;
                tableData1=[];
                tableData1.push({devModelName:'设备型号',installPosition:'安装点地点',state:'设备状态',dataTime:'数据时间',edit:'操作'},);
                if(response?.data?.data){
                    response.data.data.map(one=>{one.edit='查看';tableData1.push(one);});
                }
                this.setState({table_data_1:tableData1});
            }
        ).catch(e=>{})
    };
    // 设备列表-表具-内容指定
    getDevList_1=(type)=>{
        console.log('设备列表');
        var params = this.state.table_data_1_param;
        params.devType=type;
        this.setState({table_data_1_param:params});
        getDevList(params).then(response=>{if(response?.data?.data){this.setState({modal_table_data_1:response.data.data});}})
    };

    // 报警数量(报警信息)
    getAlarmNumber=(id)=>{
        this.setState({requestSwitch:this.state.requestSwitch+=1});
        if(!id){return false;}
        getAlarmNumber({customerId:id}).then(
            response=>{
                this.setState({requestSwitch:this.state.requestSwitch-=1});
                if(response?.data?.data){
                    var data = response?.data?.data;

                    var bottomThreeDevNumber =this.state.bottomThree_devNumber;
                    bottomThreeDevNumber.abnormalAlarm = data?.abnormalAlarm??'0';
                    bottomThreeDevNumber.alarmNumber = data?.alarmNumber??'0';
                    bottomThreeDevNumber.status =
                        data?.status.indexOf('开')>=0?'开阀'
                            : data?.status.indexOf('关')>=0?'关阀':'开阀';
                    this.setState({
                        bottomThree_devNumber:bottomThreeDevNumber
                        })
                }else{
                    this.setState({bottomThree_devNumber:{
                            "abnormalAlarm": 0, // 异常报警
                            "alarmNumber": 0,   // 报警次数
                            'status':'开'
                        }})
                }
            }
        )
    };

    // 报警列表(报警信息)
    getAlarmList=(type)=>{
        this.setState({requestSwitch:this.state.requestSwitch+=1});
        var params = this.state.table_data_3_param;
        params.devType=type;

        getAlarmList(params).then(
            response=>{
                this.setState({requestSwitch:this.state.requestSwitch-=1});
                var tableData3 = this.state.table_data_3;
                var warningPrompt = this.state.warningPrompt;
                tableData3=[];
                if(response?.data?.data){
                    var data = response?.data?.data;
                    var cachingData = this.state.cachingData;// 缓存数据
                    // // 报警控制-条件要求时间范围内
                    // if(data[0]?.alarmTime){
                    //     console.log('-------开始报警测试----------');
                    //     var selectedTime = new Date(data[0].alarmTime);// 获取第一条数据
                    //     console.log('-------获取的时间----------'+selectedTime);
                    //     var currentTime = new Date();                   // 获取当前时间
                    //     console.log('-------当前的时间----------'+currentTime);
                    //     currentTime.setSeconds(currentTime.getSeconds() - 30);// 当前时间去掉20秒
                    //     console.log('-------当前的时间去掉1分钟warningNum----------'+currentTime);
                    //     if (selectedTime >= currentTime) {
                    //         console.log('-------开----------');
                    //         this.setState({warningPrompt:true});// 警告提示开关 -开
                    //     }else{
                    //         console.log('-------关----------');
                    //         this.setState({warningPrompt:false});// 警告提示开关 -关
                    //     }
                    // }


                    // 判断缓存数据是否为空
                    if(cachingData){
                        // 不为空判断缓存数据与目前第一条是否相同-不相同报警
                        if(response.data.data[0].messageId!==cachingData){
                            this.setState({warningPrompt:true});// 警告提示开关 -开
                        }else{
                            this.setState({warningPrompt:false});// 警告提示开关 -关
                        }
                    }else{
                        this.setState({warningPrompt:false});// 警告提示开关 -关
                    }

                    cachingData=response.data.data[0].messageId;
                    response.data.data.map(one=>{one.edit='查看';tableData3.push(one);});
                    this.setState({cachingData:cachingData});
                }
                this.setState({table_data_3:tableData3});
                setTimeout(()=>{ this.getAlarmList('报警次数');},10000);// 10计时器
            }
        ).catch(e=>{})


    };


    forAlarmList=()=>{

    };


    // 表格-1-编辑
    getBottomOneTableData=(tableData)=>{
        var i=true;
        var tableData1Param = this.state.table_data_1_param;
        return tableData.map(one=>{
            i=!i;
            return(
                <div style={{width:'100%',height:'20%',backgroundColor:i?'rgb(6 48 84/40%)':'rgb(5 42 76/40%)'}}>
                    <div className={'title_type_1'} style={{width:'30%'}}>{one.devModelName}</div>
                    <div className={'title_type_1'} style={{width:'30%',}}> <Tooltip title={one.installPosition}>{one.installPosition}</Tooltip></div>
                    <div className={'title_type_1'} style={{width:'15%',color:one.type==='过期'?"#FCB430":"rgb(174 188 199)"}}>{one.state}</div>
                    <div className={'title_type_1'} style={{width:'20%'}}>
                        <Tooltip title={one.dataTime}>
                            {one.dataTime}
                        </Tooltip>
                    </div>
                    {/*{one.edit==='操作'?*/}
                    {/*    <div className={'title_type_1'} style={{width:'11%', color:"rgb(174 188 199)", float:'left'}}>{one.edit}</div>*/}
                    {/*    :*/}
                    {/*    <div className={'title_type_1'} style={{width:'11%', color:"#70FFF1", float:'left'}}>*/}
                    {/*        <span style={{fontSize:'13px',color:'#70FFF1',cursor: 'pointer'}}*/}
                    {/*              onClick={()=>{this.history_1()}}*/}
                    {/*        >*/}
                    {/*            {one.edit}*/}
                    {/*            <DoubleRightOutlined /></span>*/}
                    {/*    </div>*/}

                    {/*}*/}

                </div>
            )
        });
    };
    // 表格-2-下-2
    getBottomTwoTableData=(tableData)=>{
        var i=true;
        return tableData.map(one=>{
            i=!i;
            return(
                <div style={{width:'100%',height:'19%',backgroundColor:i?'rgb(6 48 84/40%)':'rgb(5 42 76/40%)'}}>
                    {/*<div className={'title_type_1'} style={{width:'22%'}}>{one.pointType}</div>*/}
                    <div className={'title_type_1'} style={{width:'32%'}}>
                        <Tooltip title={one.address}>
                            {one.address}
                        </Tooltip>
                        </div>
                    <div className={'title_type_1'} style={{width:'21%',color:one.pointStatus==='燃气设施触电'?"#FCB430":"rgb(174 188 199)"}}>{one.pointStatus}</div>
                    <div className={'title_type_1'} style={{width:'18%'}}>{one.concentration}</div>
                    <div className={'title_type_1'} style={{width:'25%'}}>
                        <Tooltip title={one.time}>
                            {one.time}
                        </Tooltip>
                    </div>
                </div>
            )
        });
    };
    // 表格-3
    getBottomThreeTableData=(tableData)=>{
        var i=true;
        return tableData.map(one=>{
            i=!i;
            return(
                <div style={{width:'100%',height:'25%',backgroundColor:i?'rgb(6 48 84/40%)':'rgb(5 42 76/40%)'}}>
                    <div className={'title_type_2'} style={{width:'45%', color:"rgb(185 128 15)",float:'left'}}>
                        <Tooltip title={one.info}>
                            {one.info}
                        </Tooltip>
                    </div>
                    <div className={'title_type_2'} style={{width:'42%'}}>{one.alarmTime}</div>

                    <div className={'title_type_2'} style={{width:'13%'}}>
                        <span style={{fontSize:'13px',color:'rgb(174 188 199)',cursor:'pointer'}}
                              onClick={()=>{
                                  this.setState({visible_7:true});
                                  this.getPageFuzzyQuerys();
                              }}
                        >更多 <DoubleRightOutlined /></span>
                    </div>
                </div>
            )
        });

    };
    // 表格-4
    getBottomFourTableData=(tableData=[])=>{
        var i=true;

        if(tableData.length!==0 && tableData){
            return tableData.map(one=>{
                i=!i;
                return(
                    <div style={{
                        width:'100%',height:'20%',paddingTop:'1%',textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        backgroundColor:i?'rgb(6 48 84/40%)':'rgb(5 42 76/40%)',color:'#fff'
                    }}
                    >
                        {
                            this.state.table_head_4.map(two=>{
                                return(
                                    <div style={{
                                        width:(100/this.state.table_head_4.length)+'%',
                                        textAlign: 'center',float:'left',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}>
                                        <Tooltip title={one[two.e] ??null}>
                                            <span>{one[two.e] ?? '-'}</span>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            })
        }else{
            return(
                <div style={{
                    width:'100%',height:'20%',paddingTop:'1%',textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                    backgroundColor:'rgb(5 42 76/40%)',color:'#fff',textAlign:'center'
                }}>
                    <span>暂无数据</span>
                </div>
            )
        }


        // return tableData.map(one=>{
        //     i=!i;
        //     // type:'对象',classification:'隐患名称',level:'分级',state:'状态'
        //     return(
        //         <div style={{width:'100%',height:'19%',backgroundColor:i?'rgb(6 48 84/40%)':'rgb(5 42 76/40%)'}}>
        //             <div className={'title_type_1'} style={{width:'25%'}}>{one?.type??'-'}</div>
        //             <div className={'title_type_1'} style={{width:'45%'}}><Tooltip title={one?.name??'-'}>{one?.name??'-'}</Tooltip></div>
        //             <div className={'title_type_1'} style={{width:'15%'}}>{one?.level??'-'}</div>
        //             <div className={'title_type_1'} style={{width:'15%'}}>{one?.state??'-'}</div>
        //         </div>
        //     )
        // });

    };
    // 表格-4
    getBottomFiveTableData=(tableData)=>{
        var i=true;
        return tableData.map(one=>{
            i=!i;
            return(
                <div style={{width:'100%',height:'25px',backgroundColor:i?'rgb(6 48 84/40%)':'rgb(5 42 76/40%)'}}>
                    <div className={'title_type_2'} style={{width:'80%',
                        color:"rgb(185 128 15)",float:'left'}}>
                        <Tooltip title={one.title}>
                            {one.title}
                        </Tooltip>
                    </div>
                    <div className={'title_type_1'} style={{width:'20%',cursor:'pointer'}}
                         onClick={async ()=>{
                             await this.getDetailByConfigId(one.id);// 获取计算时间
                             await this.setState({
                                 countDown_disabled:true,   // 重置按钮开关
                                 visible_2:true,            // 开启弹窗
                                 titleInfo_2:one.content    // 弹窗内容
                             });

                             // // 加载计时器
                             // var setCountDown = this.state.setCountDown;
                             // await clearInterval(setCountDown); // 开局清空计时器
                             // setCountDown=null;                 // 先置空
                             // setCountDown = setInterval(this.CountDown,1000);
                             // await this.setState({setCountDown})

                         }}
                    >
                        <span>查看</span>
                    </div>
                </div>
            )
        });
    };
    // 视频栏
    getliveFluoriteDevs=(tableData)=>{
        if(!tableData){return false}
        var title=null;
        var i=0;
        if(!tableData?.infos){return false}

        // 循环数据列表
        for(var v=0;v<tableData.infos.length;v++){
            i++;
            if(tableData.infos[v].url){
                return (
                    <div className={tableData.infos.length<=1?'customerDataPanel_centre_bottom_player_body_img_div_1':'customerDataPanel_centre_bottom_player_body_img_div_2'}>

                        <div className={'customerDataPanel_centre_bottom_player_img_div'} style={{padding:'3%'}}>

                            <EZUIKitPlayer
                                ref={e => this.EZUIKitPlayer = e}
                                id={'EZUIKitPlayer'+i}
                                accessToken={tableData?.accessToken}
                                url={tableData.infos[v]}
                            />
                        </div>
                    </div>
                )
            }
        }
    };

    // 模拟视频列表
    getliveFluoriteDevs1=(tableData)=>{
        var liveFluoriteDevs = this.state.liveFluoriteDevs; // 视频列表
        var playerParam = this.state.playerParam;           // 视频分页参数

        //实行代码
        if(!liveFluoriteDevs){return false}
        if(!liveFluoriteDevs?.infos){return false}
        var datas=liveFluoriteDevs.infos.slice((playerParam.page*playerParam.size)-4,playerParam.page*playerParam.size);

        // // 切换不同样式
        var num = datas.length>=3?"5":datas.length>=2?"4":"3";
        var i=0;

        // 实行代码
        return (
            <div  style={{width:'100%',height:'100%'}}>
                {datas.map(one=>{
                    i++;
                    return(
                        <div className={'customerDataPanel_centre_bottom_player_body_img_div_'+num}>

                            {one?.url?

                                <EZUIKitPlayer
                                ref={e => this.EZUIKitPlayer = e}
                                id={'EZUIKitPlayer'+i}
                                accessToken={liveFluoriteDevs?.accessToken}
                                url={one}
                                />
                                :
                                <div style={{width:'100%',height:'100%',backgroundColor:"#f0f2f5"}}>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )

    };


    // 视频操作栏
    getActionBar=()=>{
        var liveFluoriteDevs = this.state.liveFluoriteDevs;
        var juder = true;
        if(!liveFluoriteDevs) juder = true;
        if(!liveFluoriteDevs?.infos)juder = true;
        if(liveFluoriteDevs?.infos.length>4)juder = false;

        return(
            juder?
                <div style={{width:'100%',height:'100%'}}>
                    <div className={'customerDataPanel_centre_bottom_right_img_2_div'}/>
                </div>
                :
                <div style={{width:'100%',height:'100%'}}>
                    <div style={{width:'100%',height:'33.5%'}}>
                        <div className={'customerDataPanel_centre_bottom_right_img_2_div'}/>
                    </div>
                    <div style={{width:'100%',height:'33%'}}>
                        <div className={'customerDataPanel_centre_bottom_right_img_2_div'}/>
                    </div>
                    <div style={{width:'100%',height:'33.5%'}}>
                        <div className={'customerDataPanel_centre_bottom_right_img_2_div'}/>
                    </div>
                </div>

        )
    };

    // 按钮功能
    PageFlippingButton=(num)=>{
        var playerParam = this.state.playerParam;
        switch (num) {
            case '0':// 首页
                playerParam.page=playerParam.page>1?playerParam.page-=1:1;
                break;
            case '1':// 末端
                if(playerParam.total===0){
                    playerParam.page=1;
                }else{
                    playerParam.page = Math.ceil(playerParam.total/4)>playerParam.page
                        ? playerParam.page+=1 : Math.ceil(playerParam.total/4);

                }
                break;
            default:
                playerParam.page=1;
                break;
        }
        this.setState({playerParam:playerParam});
    };

    // 对话框文本
    dialogBoxTitle=(titles)=>{
        if(!titles) return false;
        return (
            titles.map(
                one=>{
                    if(one.float){
                        return<>
                            <div style={{width:'100%',marginTop:'5px'}}>
                                <div style={{width:'100%',height:'100%',paddingLeft:'10px'}}>
                                    <span style={{color:'#858585'}}>{one?.name}</span>
                                </div>
                                <div style={{width:'90%',height:'100%',marginLeft:'10px'}}>
                                    <span style={{width:'auto',paddingLeft:'10px',backgroundColor:'#fff',paddingRight:'10px',borderRadius: '2px'}}>{one?.title}</span>
                                </div>
                            </div>
                        </>
                    }
                    else{
                        return<>
                            <div style={{width:'99%',marginTop:'5px'}}>
                                <div style={{width:'100%',height:'22px',paddingRight:'10px',textAlign: 'right'}}>
                                    <span style={{color:'#858585'}}>{one?.name}</span>
                                </div>

                                <div style={{width:'90%',height:'100%',marginLeft:'10px',textAlign:'right'}}>
                                    <span style={{width:'auto',backgroundColor:'#d9ffc6',paddingLeft:'10px',paddingRight:'10px',borderRadius: '2px'}}>{one?.title}</span>
                                </div>
                            </div>
                        </>
                    }
                }
            )
        )
    };

    addTitle=()=>{
        if(!this.state?.textValue) return false;
        var titleInfo1 = this.state.titleInfo_1;
        titleInfo1.push({
            name:'李四',
            title:this.state?.textValue,
            float: false
        });
        let element = document.getElementById("box");
        element.scrollIntoView();               // 元素的顶部将对齐到可滚动祖先的可见区域的顶部。
        element.scrollIntoView(false);      // 元素的底部将与可滚动祖先的可见区域的底部对齐。
        // #对于新添加的元素有时可能不能立即获取到，此时可以设置个定时器
        setTimeout(()=>{
            let div=document.getElementById('box');
            if(div){div.scrollIntoView(false);}
        },200);
        this.setState({titleInfo_1:titleInfo1,textValue:null})
    };

    history_1=()=>{
        // this.props.history.replace('/meter/meterManagement')
        var param={
            "customerId": new URLSearchParams(window.location.search).get('customerId'),
            "page": 1,
            "size": 10
        };
        getLocationById(param).then(
            response=>{
                if(response?.data?.data){

                    var tableData1Param = this.state.table_data_1_param;
                    this.props.history.push({
                        pathname:tableData1Param.devType==='探测器'?"/customer/devInstallationNew":"/customer/BusinessMaintainNew",
                        state: {
                            formsTwo:{
                                id: new URLSearchParams(window.location.search).get('id'),
                                customerId:     new URLSearchParams(window.location.search).get('customerId'),// 用户id
                                address:        new URLSearchParams(window.location.search).get('address'),// 地址
                                customerName:   new URLSearchParams(window.location.search).get('customerName'),   // 客户名称
                                createDate:'-',     // 创建时间
                                // mpName:         this.props.location?.state?.rowData.mpName?         this.props.location?.state?.rowData?.mpName:'-',         // 联系人
                                // principalPhone: this.props.location?.state?.rowData.principalPhone? this.props.location?.state?.rowData?.principalPhone:'-'  // 电话
                            },
                            rowData:tableData1Param.devType==='探测器'?response?.data?.data[0]:
                                {
                                    id: new URLSearchParams(window.location.search).get('id'),
                                    customerId: new URLSearchParams(window.location.search).get('customerId'),
                                    type: new URLSearchParams(window.location.search).get('type'),
                                    nature: new URLSearchParams(window.location.search).get('nature'),
                                    address: new URLSearchParams(window.location.search).get('address'),
                                    customerName: new URLSearchParams(window.location.search).get('customerName'),
                                    principalPhone: new URLSearchParams(window.location.search).get('principalPhone'),
                                }
                        }
                    })
                }
                console.log(response?.data?.data[0]);
            }
        )

    };

    // 天气预备
    getWeather= () =>{
        if(!document.getElementById('weather'))return false;
        var element = document.getElementById('weather').innerText;


        switch (element) {
            case '多云':
                return <div className={'head_weather_img_1'} style={{width:'20px',height:'20px',float:'left'}}/>;
            case '晴':
                return <div className={'head_weather_img_2'} style={{width:'20px',height:'20px',float:'left'}}/>;
            case '小雨':
                return <div className={'head_weather_img_4'} style={{width:'20px',height:'20px',float:'left'}}/>;
            case '中雨':
                return <div className={'head_weather_img_4'} style={{width:'20px',height:'20px',float:'left'}}/>;
            case '大雨':
                return <div className={'head_weather_img_4'} style={{width:'20px',height:'20px',float:'left'}}/>;
            default:
                return <div className={'head_weather_img_1'} style={{width:'20px',height:'20px',float:'left'}}/>;
        }
    };

    // 滚动到底部触发
    loadMoreDataFn=()=>{
        var edjs = document.getElementsByTagName('div')[0];
        var time = 10;
        // 先创建一个定时器对象
        var timer = setInterval(function(){
            // console.log('我是匿名函数');
            time --;
            if (time === 0){
                // 符合条件时，清除定时器
                clearInterval(timer);
            }
            edjs.innerHTML = time;
        },1000);
    };


    // 根据配置ID查询配置详情信息
    getDetailByConfigId=(learnId)=>{
        if(!learnId)return false;

        var customerId =  new URLSearchParams(window.location.search).get('customerId');
        getDetailByLearnId({learnId,customerId}).then(
            response=>{
                // 加载计时器
                var setCountDown = this.state.setCountDown;
                if(response?.data?.data) {
                    var data = response?.data?.data;
                    if(data?.voLearnersList.state==='未上报'){
                        this.setState({
                            configId:data.id,
                            countDownType:data?.type.indexOf('学习')!==-1?true:false,//判断是否有最后时间，有就视为任务开启时间选择
                            countDown:data?.learnTime??null,          // 即时分钟
                            requestType:true
                        });
                        // 判断文章类型-有无学习时间，
                        if(data?.type.indexOf('学习')!==-1){
                            clearInterval(setCountDown); // 开局清空计时器
                            setCountDown=null;                 // 先置空
                            setCountDown = setInterval(this.CountDown,1000);// 加载即时内容
                            this.setState({setCountDown});// 保存
                        }
                    }else{

                        if(data?.type.indexOf('任务')!==-1){
                            var voLearnersList = data?.voLearnersList;

                            this.setState({
                                startTimeOfVacation:voLearnersList?.startTimeOfVacation?moment(voLearnersList?.startTimeOfVacation).format("yyyy-MM-DD"):null,
                                endTimeOfVacation:voLearnersList?.startTimeOfVacation?moment(voLearnersList?.endTimeOfVacation).format("yyyy-MM-DD"):null,
                                countDownType:false,      // 时间类型-可选
                                countDownTitle:true,      // 切换时间文本-开
                            });
                        }else{
                            this.setState({
                                countDown_disabled:false,   // 重置按钮开关-关
                                countDownTitle:false,       // 切换时间文本-关
                                countDownType:true,         // 时间类型-不可选
                                requestType:false,          // 控制是否请求-不可请求
                            });
                        }



                    }
                }else{
                    this.setState({
                        countDown_disabled:false,   // 重置按钮开关-关
                        countDownTitle:false,       // 切换时间文本-关
                        countDownType:true,         // 时间类型-不可选
                        requestType:false           // 控制是否请求-不可请求
                    });
                    message.info('获取数据失败，请重新操作');

                }
            }
        );
    };

    // 计时器
    CountDown= async ()=> {
        var span = document.getElementById("countDown");
        if(!span){return clearInterval(this.state.setCountDown);}// 获取不到ID就停止循环
        //改变span里面的值
        var r = /[0-9]+/g;
        console.log(span.innerText);
        var a = span.innerText.match(r);
        span.innerText = "("+ ( a - 1)+")";
        if (span.innerText === "(0)") {
            clearInterval(this.state.setCountDown);
            this.setState({countDown_disabled:false})
        }
    };

    updateResults=()=>{
        var customerId = new URLSearchParams(window.location.search).get('customerId');
        updateResults({customerId}).then(
            response=>{
                if(response?.data?.data){
                    console.log(response);
                    this.setState({Loading_1:true});
                    this.querySecurityAssessment(customerId)
                }
            }
        )
    };

    // 条件选择：获取输入的条件
    getConditionsValue = async value => {
        let data = await this.forms_1.onFinishNotReset();
        var tableParamenter = this.state.tableParamenter_1;
        tableParamenter.params.devType=data.devType;
        await this.table_1.refreshTable(tableParamenter.params);
    };
    // 根据客户Id查询安检点燃气具信息
    getAllInfoByCustomerId=()=>{

        getAllInfoByCustomerId({customerId:new URLSearchParams(window.location.search)?.get('id')}).then(
            response=>{
                var tableData1Data = this.state.tableData_2;
                var tableData2Data = this.state.tableData_2_data;
                if(response?.data){
                    var data = response.data.data;
                    tableData1Data=data?.voGasBurnerList || null;
                    tableData2Data=data?.voSecurityCheckpointList || null;
                    tableData1Data?.forEach(
                        one=>{
                            one.state===0?one.state='正常':one.state='过期'
                        });
                    tableData2Data?.forEach(
                        one=>{
                            one.state===0?one.state='正常':one.state='过期'
                        });

                    this.setState({
                        tableData_2_data:tableData2Data,
                    })
                }

            })
    };

    // 表格：刷新方法
    refreshTable = () => {this.getData();};
    // 获取当前页数和条数
    getPagination = (page, pageSize) => {
        let {tableParamenter_6} = this.state; // 分页，和后端参数
        tableParamenter_6.loading = true;       // 页数
        tableParamenter_6.params.page.current = page;       // 页数
        tableParamenter_6.params.page.size = pageSize;      // 每页数量
        this.setState({tableParamenter_6:tableParamenter_6});
        this.getPageFuzzyQuerys();
    };

    // 查询表格数据
    getPageFuzzyQuerys =()=>{
        let {tableParamenter_6} = this.state;
        tableParamenter_6.loading = true;
        this.setState({tableParamenter_6: tableParamenter_6});
        let param = this.state.tableParamenter_6.params;
        getPageFuzzyQuerys(param).then(
            response=>{
                let {data} = response.data;
                tableParamenter_6.data = data;
                const {pageInfo} = response.data;   // 获取分页数据
                tableParamenter_6.params.total= pageInfo.total;
                tableParamenter_6.loading = false;
                this.setState({
                    tableParamenter_6:tableParamenter_6,
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                tableParamenter_6.loading = false;
                this.setState({tableParamenter_6: tableParamenter_6,});
                message.error('查询失败')
            }
        )
    };



    render(){
        const {bottomOne_devNumber,bottomThree_devNumber}=this.state;

        return(
            <Layout id={'customerDataPanel'}>
                <div style={{display:this.state.warningPrompt?null:'none'}}>
                    <div className="pulse4"/>  {/*感叹号*/}
                    <div className="pulse2"/>  {/*波纹*/}
                    <div className="pulse1"/>  {/*波纹*/}
                    <div className="pulse3"/>  {/*边框*/}
                </div>

{/*主体----------*/}
                <div className={'customerDataPanel_body_img'}>
{/*上-----------*/}
                    <div className={'customerDataPanel_top_div'}>
                        <div className={'customerDataPanel_top_img_div'}>
                            <div style={{width:'30%',height:'100%',padding:'1.2% 0 0 4%',float:'left'}}>
                                <div style={{color:'#fff',float:'left',width:'45%',overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                    <span>管理员&nbsp;
                                        {this.state.administrators.name}&nbsp;
                                        {this.state.administrators.phone}
                                    </span>
                                </div>
                                <div style={{color:'#fff',float:'left',width:'50%',overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                                    <span>
                                        负责人&nbsp;
                                        {this.state.adminHead.name}&nbsp;
                                        {this.state.adminHead.phone}
                                    </span>
                                </div>
                            </div>
                            <div style={{width:'40%',height:'100%',textAlign:'center',float:'left',paddingTop:'0.5%'}}>
                                <span style={{color:'#fff',letterSpacing:'2px',fontSize:'28px',fontWeight:'bold'}}>
                                    {/*{this.props.location.state.rowData.name}驾驶舱*/}
                                    {new URLSearchParams(window.location.search).get('customerName')}
                                    安全驾驶舱
                                </span>
                            </div>
                            <div style={{width:'30%',height:'100%',float:'left',padding:'1.2% 0 0 4%'}}>
                                <div style={{float:'left',width:'58%',fontSize:'17px'}}>
                                    <span style={{color:'#fff'}}>{this.state.headLeftDate}</span>
                                </div>
                                <div style={{float:'left',width:'40%',fontSize:'17px',color:"#fff"}}>
                                    <div style={{float:'left',width:'40%'}}>
                                        {this.getWeather()}
                                        <div id={'weather'} style={{float:'left',width:'60%'}}/>
                                    </div>

                                    <div style={{float:'left',width:'40%'}}>
                                        <div className={'head_weather_img_3'} style={{width:'20px',height:'20px',float:'left'}}/>
                                        <div id={'temperature'} style={{float:'left',width:'50%'}}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
{/*中------------*/}
                    <div className={'customerDataPanel_centre_div'}>
{/*--中--左--------*/}
                        <div className={'customerDataPanel_centre_left_div'}>
                            <div className={'customerDataPanel_centre_left_img_div'}>
                                <div style={{width:'100%',height:'13%', padding: '4% 0 0 11%'}}>
                                    <div style={{width:'65%',height:'100%', float:'left'}}>
                                        <span style={{
                                            fontSize: '21px',
                                            letterSpacing:'3px',
                                            color:'#BAE9FF',
                                            fontFamily: '方正兰亭黑简体'
                                        }}>用气分析</span>
                                    </div>
                                    <div style={{width:'25%',height:'100%', float:'left'}}>
                                        <div style={{width:'38%',height:'40%',float:'right',marginTop:'12%',marginRight:'10%'}}>
                                            <div className={this.state?.leftData_status.indexOf('开')!==-1?'customerDataPanel_ico_1':'customerDataPanel_ico_2'}
                                            />
                                        </div>
                                        <div style={{width:'18%',height:'40%',float:'right',marginTop:'8%',marginRight:'1%'}}>
                                            <span style={{color:'rgb(174 188 199)'}}>{this.state.leftData_status.indexOf('开')!==-1?'开':'关'}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'25%'}}>
                                    <div style={{width:'20%',height:'100%',marginLeft:'21.5%',float:'left'}}>
                                        <div style={{width:'100%',height:'74%'}}
                                             onClick={()=>{
                                                 this.getDevList_1('表具');// 查询
                                                 this.setState({visible_3:true})
                                             }}
                                        >
                                            {/*圆形图*/}
                                            <HalfDoughnutChart1
                                                elementById={'leftTopData_1_1'}
                                                data={{
                                                    data: this.state.leftData_1.data,
                                                    name: this.state.leftData_1.name,
                                                    color:this.state.leftData_1.color,
                                                }}
                                            />
                                        </div>
                                        <div style={{width:'100%',height:'26%', textAlign:'center'}}>
                                            <span style={{  fontSize: '16px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>昨日用气量</span>
                                        </div>
                                    </div>
                                    <div style={{width:'20%',height:'100%',marginLeft:'17.8%',float:'left'}}>
                                        <div style={{width:'100%',height:'74%'}}
                                             onClick={()=>{
                                                 this.getDevList_1('表具');          // 查询设备列表
                                                 this.setState({visible_3:true});   // 开启弹窗

                                             }}
                                        >
                                            {/*圆形图*/}
                                            <HalfDoughnutChart1
                                                elementById={'leftTopData_1_2'}
                                                data={{
                                                    data: this.state.leftData_2.data,
                                                    name: this.state.leftData_2.name,
                                                    color:this.state.leftData_2.color,
                                                }}
                                            />
                                        </div>
                                        <div style={{width:'100%',height:'26%', textAlign:'center'}}>
                                            <span style={{  fontSize: '15px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>实时用量</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'30%'}}
                                     onClick={()=>{
                                         this.getDevList_1('表具');// 查询
                                         this.setState({visible_3:true})
                                     }}
                                >
                                    {/*柱形图*/}
                                    <BarChart1
                                        elementById={'leftTopData_2_1'}
                                        data={this.state.leftData_barChart_1.data}    // 数值
                                        yData={this.state.leftData_barChart_1.yData}  // X轴数据
                                        number={this.state.leftData_barChart_1.number}// 最小、最大、间距
                                    />
                                </div>
                                <div style={{width:'100%',height:'30%'}}
                                     onClick={()=>{
                                         this.getDevList_1('表具');// 查询
                                        this.setState({visible_3:true})
                                     }}
                                >
                                    {/*柱形图*/}
                                    <BarChart1
                                        elementById={'leftTopData_2_2'}
                                        data={this.state.leftData_barChart_2.data}    // 数值
                                        yData={this.state.leftData_barChart_2.yData}  // X轴数据
                                        number={this.state.leftData_barChart_2.number}// 最小、最大、间距
                                    />
                                </div>
                            </div>
                        </div>
{/*--中--中--------*/}
                        <div className={'customerDataPanel_centre_bottom_div'}>
                            <div className={'customerDataPanel_centre_bottom_img_div'}>
                                <div style={{width:'100%',height:'100%'}}>
                                    {/*视频栏*/}
                                    <div style={{width:'93.5%',height:'100%',float:'left'}}>
                                        {this.getliveFluoriteDevs1(this.state.liveFluoriteDevs)}
                                    </div>
                                    {/*操作栏*/}
                                    <div style={{width:'6%',height:'100%',float:'left'}}>
                                        <div style={{width:'100%',height:'100%'}}>
                                            <div style={{width:'100%',height:'32%'}}/>
                                            <div style={{width:'100%',height:'8%'}}>
                                                <div className={'customerDataPanel_centre_bottom_right_img_1_div'} style={{cursor:'pointer'}}
                                                     onClick={()=>{this.PageFlippingButton('0');}}
                                                />
                                            </div>
                                            <div style={{width:'100%',height:'20%'}}>
                                                {this.getActionBar()}
                                            </div>
                                            <div style={{width:'100%',height:'8%'}}>
                                                <div className={'customerDataPanel_centre_bottom_right_img_3_div'} style={{cursor:'pointer'}}
                                                     onClick={()=>{this.PageFlippingButton('1');}}
                                                />
                                            </div>
                                            <div style={{width:'100%',height:'32%'}}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
{/*--中--右--------*/}
                        <div className={'customerDataPanel_centre_right_div'}>
                            <div className={'customerDataPanel_centre_right_img_div'}>
                                <div style={{width:'100%',height:'22%', padding: '4% 4% 0 11%'}}>
                                    <div style={{width:'50%',height:'100%',float:'left'}}>
                                        <span style={{fontSize:'21px',letterSpacing:'3px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>安全分析</span>
                                    </div>
                                    {/*二维码*/}
                                    <div style={{width:'50%',height:'100%',float:'left'}}>

                                        <div style={{width:'50%',height:'100%',float:'right'}}>
                                            <div style={{width:'100%',height:'80%',padding:'7% 0 0 15%'}}>
                                                <div style={{width:'20px',
                                                    height:'20px',
                                                    backgroundColor:'rgba(0, 0, 0, 0.3)',
                                                    color:'#fff',
                                                    zIndex:'999',
                                                    position: 'absolute',
                                                    textAlign:'center',
                                                    marginLeft:'45px'
                                                }}>
                                                    {this.state.Loading_1?
                                                        <span style={{cursor: 'pointer'}}
                                                              onClick={()=>{
                                                                  this.setState({Loading_1:false});
                                                                  this.updateResults();// 更新二维码
                                                              }}
                                                        ><UndoOutlined/></span>
                                                        :<LoadingOutlined/>
                                                    }

                                                </div>
                                                <NavLink to={{
                                                    pathname: "/customer/SafetyAssessmentDetails",
                                                    state:{rowData:this.state.querySecurityAssessment}
                                                }}
                                                >
                                                    <Image width={65} src={this.state?.evaluationPath || null}/>
                                                </NavLink>
                                                {/*<div className={'customerDataPanel_top_img_1_div'}/>*/}
                                            </div>
                                            <div style={{width:'100%',height:'20%',textAlign:'center'}}>
                                                    <a style={{color:'#fff',fontSize:'13px'}}>{this.state.evaluationResults}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*六边图*/}
                                <div style={{width:'100%',height:'40%'}}>
                                    <BasicRadarChart
                                        elementById={'rightTopData_1_2'}
                                        data={this.state.rightData_1.data}
                                        ydata={this.state.rightData_1.yData}
                                    />
                                </div>
                                {/*安全隐患列表*/}
                                <div style={{width:'100%',height:'38%'}}>
                                    <div style={{width:'100%',height:'15%'}}>
                                        <div style={{width:'20%',height:'100%',float:"left",textAlign:'center',marginLeft:'7%'}}>
                                            <span style={{fontSize:'13px',color:'rgb(174 188 199)',letterSpacing:'3px'}}>安全隐患</span>
                                        </div>
                                        <div style={{width:'10%',height:'100%',float:"left",textAlign:'center',marginLeft:'55%'}}>

                                            {/*<NavLink to={{pathname: "/secure/hiddenDangerList",state: {}}}>*/}
                                            {/*    <span style={{fontSize:'13px',color:'rgb(174 188 199)'}}>更多</span>*/}
                                            {/*</NavLink>*/}
                                            <span style={{fontSize:'13px',color:'rgb(174 188 199)',cursor:'pointer'}}
                                                  onClick={()=>{this.setState({visible_6:true});}}
                                            >更多 <DoubleRightOutlined /></span>

                                        </div>
                                    </div>
                                    <div style={{width:'100%',height:'80%',paddingLeft:'9%',paddingRight:'7.5%'}}>
                                        {/*表格标头*/}
                                        <div style={{width:'100%',height:'20%',backgroundColor:"rgb(5 42 76/40%)",color:'#fff'}}>
                                            {
                                                this.state.table_head_4.map(one=>{
                                                    return(
                                                        <div style={{width:(100/this.state.table_head_4.length)+'%',paddingTop:'1%',textAlign: 'center',float:'left'}}>
                                                            <span>{one.c}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>



                                        <div style={{width:'100%',height:'78%',overflowY: 'auto'}}>
                                            {this.getBottomFourTableData(this.state.table_data_4)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
{/*下------------*/}
                    <div className={'customerDataPanel_bottom_div'}>
{/*--下--1--------*/}
                        <div className={'customerDataPanel_bottom_one_div'}>
                            <div className={'customerDataPanel_bottom_one_img_div'}>
                                <div style={{width:'100%',height:'19.5%',padding: '4.5% 0 0 11%'}}>
                                    <span style={{  fontSize: '21px', letterSpacing:'3px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>设备信息</span>
                                </div>
                                <div style={{width:'100%',height:'20%'}}>
                                    <div style={{width:'10%',height:'100%',marginLeft:'8%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_one_1_img_div'}>
                                                <a style={{fontSize:'16px',fontWeight:'bold',color:'#fff'}}
                                                   onClick={()=>{this.getDevList('探测器')}}>{bottomOne_devNumber.detector}</a></div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}><span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>探测器</span></div>
                                    </div>
                                    <div style={{width:'10%',height:'100%',marginLeft:'5%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_one_2_img_div'}>
                                                <a style={{fontSize:'16px',fontWeight:'bold',color:'#fff'}}
                                                   onClick={()=>{this.getDevList('表具')}}>{bottomOne_devNumber.meter}</a></div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}><span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>表具</span></div>
                                    </div>
                                    <div style={{width:'10%',height:'100%',marginLeft:'5%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_one_3_img_div'}><a style={{fontSize:'16px',fontWeight:'bold',color:'#fff'}} onClick={()=>{this.getDevList('气瓶')}}>{bottomOne_devNumber.airBottle}</a></div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}><span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>气瓶</span></div>
                                    </div>
                                    <div style={{width:'10%',height:'100%',marginLeft:'5%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_one_4_img_div'}><a style={{fontSize:'16px',fontWeight:'bold',color:'#fff'}} onClick={()=>{this.getDevList('阀门')}}>{bottomOne_devNumber.valve}</a></div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}><span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>阀门</span></div>
                                    </div>

                                    <div style={{width:'10%',height:'100%',marginLeft:'5%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_one_2_img_div'}>
                                                <a style={{fontSize:'16px',fontWeight:'bold',color:'#fff'}}
                                                   onClick={()=>{this.getDevList('阀井')}}>{bottomOne_devNumber.valvePit}</a></div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}><span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>阀井</span></div>
                                    </div>

                                    <div style={{width:'10%',height:'100%',marginLeft:'5%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_one_5_img_div'}><a style={{fontSize:'16px',fontWeight:'bold',color:'#fff'}} onClick={()=>{this.getDevList('压力表')}}>{bottomOne_devNumber.pressureGage}</a></div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}><span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>压力表</span></div>
                                    </div>
                                </div>
                                {/*表格*/}
                                <div style={{width:'100%',height:'61%',paddingTop:'4%'}}>
                                    <div style={{width:'100%',height:'17%'}}>
                                        <div style={{width:'22%',height:'100%',float:"left",textAlign:'center',marginLeft:'10%'}}>
                                            <span style={{fontSize:'13px',color:'rgb(174 188 199)',letterSpacing:'2px'}}>设备生命周期</span>
                                        </div>
                                        <div style={{width:'12%',height:'100%',float:"left",textAlign:'center',marginLeft:'48%'}}>
                                            {/*<NavLink to={{pathname: "/device/deviceLifeCycle",*/}
                                            {/*    state: {*/}
                                            {/*        rowData:{*/}
                                            {/*            id: new URLSearchParams(window.location.search).get('id'),*/}
                                            {/*            customerId: new URLSearchParams(window.location.search).get('customerId'),*/}
                                            {/*            type: new URLSearchParams(window.location.search).get('type'),*/}
                                            {/*            nature: new URLSearchParams(window.location.search).get('nature'),*/}
                                            {/*            address: new URLSearchParams(window.location.search).get('address'),*/}
                                            {/*            customerName: new URLSearchParams(window.location.search).get('customerName'),*/}
                                            {/*            principalPhone: new URLSearchParams(window.location.search).get('principalPhone'),*/}
                                            {/*        }*/}
                                            {/*}}}*/}
                                            {/*>*/}
                                            {/*    <span style={{fontSize:'13px',color:'rgb(174 188 199)'}}>更多 <DoubleRightOutlined /></span>*/}
                                            {/*</NavLink>*/}
                                            <span style={{fontSize:'13px',color:'rgb(174 188 199)',cursor:'pointer'}}
                                                  onClick={()=>{this.setState({visible_4:true});}}
                                            >更多 <DoubleRightOutlined /></span>
                                        </div>
                                    </div>
                                    <div style={{width:'100%',height:'80%',paddingLeft:'9%',paddingRight:'7.5%'}}>
                                        <div style={{width:'100%',height:'90%',overflowY: 'auto'}}>
                                            {this.getBottomOneTableData(this.state.table_data_1)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
{/*--下--2--------*/}
                        <div className={'customerDataPanel_bottom_two_div'}>
                            <div className={'customerDataPanel_bottom_two_img_div'}>
                                <div style={{width:'100%',height:'19.5%',padding: '4.5% 0 0 13%'}}>
                                    <span style={{  fontSize: '21px', letterSpacing:'2px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>安检信息</span>
                                </div>
                                <div  style={{width:'100%',height:'20%'}}>
                                    <div style={{width:'35%',height:'100%',float:'left',marginLeft:'12%'}}>
                                        <div className={'customerDataPanel_bottom_two_1_img_div'}>
                                            <div style={{width:'55%',height:"100%",marginLeft:'45%'}}>
                                                <div style={{width:'100%',height:'50%'}}>
                                                    <div className={this.state?.checkStatus?'customerDataPanel_bottom_two_3_img_div':'customerDataPanel_bottom_two_4_img_div'}/>
                                                </div>
                                                <div style={{width:'100%',height:'50%'}}>
                                                    <span style={{fontSize:'13px',color:"rgb(174 188 199)"}}>今日自检</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:'35%',height:'100%',float:'left',marginLeft:'9%'}}>
                                        <div className={'customerDataPanel_bottom_two_2_img_div'}>
                                            <div style={{width:'55%',height:"100%",marginLeft:'45%'}}>
                                                <div style={{width:'100%',height:'50%'}}>

                                                    <div style={{width:'38%',height:'40%',float:'right',marginTop:'12%',marginRight:'10%'}}>
                                                        <div className={this.state.inletBallValve?'customerDataPanel_ico_1':'customerDataPanel_ico_2'}
                                                             // onClick={()=>{this.setState({inletBallValve:!this.state.inletBallValve})}}
                                                        />
                                                    </div>
                                                    <div style={{width:'18%',height:'40%',float:'right',marginTop:'8%',marginRight:'1%'}}>
                                                        <span style={{color:'rgb(174 188 199)'}}>{this.state.inletBallValve?'开':'关'}</span>
                                                    </div>
                                                </div>
                                                <div style={{width:'100%',height:'50%'}}>
                                                    <span style={{fontSize:'13px',color:"rgb(174 188 199)"}}>进气球阀</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*表格*/}
                                <div style={{width:'100%',height:'61%',paddingTop:'5%'}}>
                                    <div style={{width:'100%',height:'15%'}}>
                                        <div style={{width:'20%',height:'100%',float:"left",textAlign:'center',marginLeft:'11%'}}>
                                            <span style={{fontSize:'13px',color:'rgb(174 188 199)',letterSpacing:'3px'}}>安检详情</span>
                                        </div>
                                        <div style={{width:'12%',height:'100%',float:"left",textAlign:'center',marginLeft:'50%'}}>

                                            <span style={{fontSize:'13px',color:'rgb(174 188 199)',cursor:'pointer'}}
                                                  onClick={()=>{
                                                      this.setState({visible_5:true});
                                                      this.getAllInfoByCustomerId();
                                                  }}
                                            >更多 <DoubleRightOutlined /></span>
                                            <div onClick={()=>{
                                                // var rowData = this.props?.location?.state?.rowData;
                                                this.props.history.push({
                                                    pathname: '/customer/BusinessMaintainNew',
                                                    state: {
                                                        selectId:'3',
                                                        // id: rowData.id,
                                                        // customerId: rowData.id,
                                                        // type: rowData.type*1 === 1 ? '居民用户' : rowData.type*1 === 2 ? '工商用户' : '',
                                                        // nature: rowData.nature,
                                                        // address: rowData.address,
                                                        // customerName: rowData.customerName,
                                                        // principalPhone: rowData.principalPhone,
                                                        // rowData:rowData
                                                        // 适用于调整新页面
                                                        id: new URLSearchParams(window.location.search).get('id'),
                                                        customerId: new URLSearchParams(window.location.search).get('customerId'),
                                                        type: new URLSearchParams(window.location.search).get('type')*1 === 1 ? '居民用户' : new URLSearchParams(window.location.search).get('type')*1 === 2 ? '工商用户' : '',
                                                        nature: new URLSearchParams(window.location.search).get('nature'),
                                                        address: new URLSearchParams(window.location.search).get('address'),
                                                        customerName: new URLSearchParams(window.location.search).get('customerName'),
                                                        principalPhone: new URLSearchParams(window.location.search).get('principalPhone'),
                                                        rowData:{
                                                            id: new URLSearchParams(window.location.search).get('id'),
                                                            customerId: new URLSearchParams(window.location.search).get('customerId'),
                                                            type: new URLSearchParams(window.location.search).get('type'),
                                                            nature: new URLSearchParams(window.location.search).get('nature'),
                                                            address: new URLSearchParams(window.location.search).get('address'),
                                                            customerName: new URLSearchParams(window.location.search).get('customerName'),
                                                            principalPhone: new URLSearchParams(window.location.search).get('principalPhone'),
                                                        }
                                                    }
                                                })
                                            }}>

                                            </div>

                                        </div>
                                    </div>
                                    <div style={{width:'100%',height:'80%',paddingLeft:'8.5%',paddingRight:'5%'}}>
                                        <div style={{width:'100%',height:'90%',overflowY: 'auto'}}>
                                            {this.getBottomTwoTableData(this.state.table_data_2)}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
{/*--下--3--------*/}
                        <div className={'customerDataPanel_bottom_three_div'}>
                            <div className={'customerDataPanel_bottom_three_img_div'}>
                                <div style={{width:'100%',height:'19%',padding: '4.5% 0 0 8%'}}>
                                    <span style={{  fontSize: '21px', letterSpacing:'2px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>报警信息</span>
                                </div>
                                <div  style={{width:'100%',height:'22%'}}>
                                    <div style={{width:'18%',height:'100%',marginLeft:'22%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_three_1_img_div'}>
                                                <a style={{color:'#fff',fontSize:'16px',fontWeight:'bold'}}
                                                   onClick={()=>{this.getAlarmList('报警次数')}}
                                                >
                                                    {bottomThree_devNumber.alarmNumber}</a>
                                            </div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign:'center'}}>
                                            <span style={{color:'rgb(174 188 199)',fontSize:'12px'}}>报警次数</span>
                                        </div>
                                    </div>
                                    <div style={{width:'18%',height:'100%',marginLeft:'20%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%'}}>
                                            <div className={'customerDataPanel_bottom_three_2_img_div'}>
                                                <a style={{color:'#fff',fontSize:'16px',fontWeight:'bold'}}
                                                   onClick={()=>{this.getAlarmList('异常次数')}}
                                                >{bottomThree_devNumber.abnormalAlarm}</a>
                                            </div>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign:'center'}}>
                                            <span style={{color:'rgb(174 188 199)',fontSize:'12px'}}>异动次数</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'22%'}}>
                                    <div style={{width:'33.5%',height:'100%',marginLeft:'10.5%',float:'left',paddingTop:'3%'}}>
                                        <div style={{width:'100%',height:'75%'}}>
                                            <div className={'customerDataPanel_bottom_four_1_img_div'}>
                                                <div style={{width:'53%',height:"100%",marginLeft:'47%'}}>
                                                    <div style={{width:'100%',height:'40%'}}>
                                                        <div style={{width:'40%',height:'70%',float:'right',marginTop:'5%',marginRight:'10%'}}>
                                                            {/*<div className={this.state.exhaustFan?'customerDataPanel_ico_2':'customerDataPanel_ico_1'}*/}
                                                            <div className={'customerDataPanel_ico_1'}
                                                                 onClick={()=>{this.setState({exhaustFan:!this.state.exhaustFan})}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{width:'100%',height:'50%'}}>
                                                        <span style={{fontSize:'12px',color:"rgb(174 188 199)"}}>排气扇</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{width:'33.5%',height:'100%',marginLeft:'12.5%',float:'left',paddingTop:'3%'}}>
                                        <div style={{width:'100%',height:'75%'}}>
                                            <div className={'customerDataPanel_bottom_four_2_img_div'}>
                                                <div style={{width:'53%',height:"100%",marginLeft:'47%'}}>
                                                    <div style={{width:'100%',height:'40%'}}>
                                                        <div style={{width:'40%',height:'70%',float:'right',marginTop:'5%',marginRight:'10%'}}>
                                                            <div style={{cursor: 'pointer'}} className={this.state.bottomThree_devNumber?.status.indexOf('开')!==-1?'customerDataPanel_ico_1':'customerDataPanel_ico_2'}
                                                                 onClick={()=>{
                                                                     var bottomThree_devNumber = this.state.bottomThree_devNumber;
                                                                     // // 不等于开的时候操作
                                                                     if(bottomThree_devNumber?.status.indexOf('开')<=0){
                                                                         bottomThree_devNumber.status='开';
                                                                         this.setState({bottomThree_devNumber:bottomThree_devNumber});
                                                                         var customerId = new URLSearchParams(window.location.search).get('customerId');
                                                                         updateStatusByCustomerId({customerId}).then(
                                                                             response=>{
                                                                                 if(response?.data?.data){
                                                                                     bottomThree_devNumber.status='开';
                                                                                 }else{
                                                                                     bottomThree_devNumber.status='关';
                                                                                     message.info('启动失败')
                                                                                 }
                                                                                 this.setState({bottomThree_devNumber:bottomThree_devNumber});
                                                                             }
                                                                         )


                                                                     }

                                                                 }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{width:'100%',height:'50%'}}>
                                                        <span style={{fontSize:'12px',color:"rgb(174 188 199)"}}>切断阀</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'37%',paddingLeft:'7%',paddingRight:'7%'}}>
                                    <div style={{width:'100%',height:'85%',overflowY: 'auto'}}>
                                        {this.getBottomThreeTableData(this.state.table_data_3)}
                                    </div>
                                </div>
                            </div>
                        </div>
{/*--下--4--------*/}
                        <div className={'customerDataPanel_bottom_four_div'}>
                            <div className={'customerDataPanel_bottom_four_img_div'}>
                                <div style={{width:'100%',height:'19%',padding: '4.5% 10% 0 11%'}}>
                                    <span style={{  fontSize: '21px', letterSpacing:'2px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>客户中心</span>
                                    {/*<div style={{width:'15%',height:'100%',float:'right',paddingTop:'1%'}}>*/}
                                    {/*    <span style={{  fontSize: '14px', letterSpacing:'2px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>更多 <DoubleRightOutlined /></span>*/}
                                    {/*</div>*/}
                                </div>

                                <div style={{width:'100%',height:'20%'}}>
                                    <div style={{width:'12%',height:'100%',marginLeft:'15%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%',cursor:'pointer'}}
                                             onClick={()=>{this.setState({visible_1:true,visible_1_title:'在线客服'})}}>
                                            <div className={'customerDataPanel_bottom_five_1_img_div'}/>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}>
                                            <span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>在线客服</span>
                                        </div>
                                    </div>
                                    <div style={{width:'12%',height:'100%',marginLeft:'8%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%',cursor:'pointer'}}
                                             onClick={()=>{this.setState({visible_1:true,visible_1_title:'故障报修'})}}>
                                            <div className={'customerDataPanel_bottom_five_2_img_div'}/>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}>
                                            <span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>故障报修</span></div>
                                    </div>
                                    <div style={{width:'12%',height:'100%',marginLeft:'7%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%',cursor:'pointer'}}
                                             onClick={()=>{this.setState({visible_1:true,visible_1_title:'投诉举报'})}}>
                                            <div className={'customerDataPanel_bottom_five_3_img_div'}/>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}>
                                            <span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>投诉举报</span></div>
                                    </div>
                                    <div style={{width:'12%',height:'100%',marginLeft:'7%',float:'left'}}>
                                        <div style={{width:'100%',height:'70%',cursor:'pointer'}}
                                             onClick={()=>{this.setState({visible_1:true,visible_1_title:'其他服务'})}}>
                                            <div className={'customerDataPanel_bottom_five_4_img_div'}/>
                                        </div>
                                        <div style={{width:'100%',height:'30%',textAlign: 'center'}}>
                                            <span style={{fontSize:'12px',color:'rgb(174 188 199)'}}>其他服务</span></div>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'22%',padding: '7% 10% 0 11%'}}>
                                    <span style={{  fontSize: '21px', letterSpacing:'2px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>安全通知</span>
                                    <div style={{width:'15%',height:'100%',float:'right',paddingTop:'1%'}}>
                                        <span style={{  fontSize: '14px', letterSpacing:'2px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>更多 <DoubleRightOutlined /></span>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:'37%',paddingLeft:'7%',paddingRight:'7%'}}>
                                    <div ref={e=>{this.myRef=e;}}
                                         style={{width:'100%',height:'85%',overflowY: 'auto'}}
                                         // onScrollCapture={() => {
                                         //     // 监听滚动
                                         //    // 未滚动到底部
                                         //     let res=this.myRef.scrollHeight - this.myRef.clientHeight- this.myRef.scrollTop;
                                         //     console.log(res);
                                         //     if (res===0) {
                                         //         console.log('未滚动到底部');
                                         //         var param = this.state.table_data_5_Param;
                                         //         if(param.determine){
                                         //             param.page=Number(param.page)+1;
                                         //             param.determine=false;
                                         //             this.setState({table_data_5_Param:param});
                                         //             var urlObject = new URLSearchParams(window.location.search);
                                         //             var id = urlObject.get('id');
                                         //             this.queryLearningByCustomer(id);
                                         //         }
                                         //     }
                                         // }}
                                    >
                                        {this.getBottomFiveTableData(this.state.table_data_5)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    title={this.state.visible_1_title}
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_1}
                    onOk={ async () => {}}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={700}
                    centered
                    footer={null}
                >
                    <div id={'scrollDiv'} style={{height:'400px'}}>
                        <div style={{width:'70%',height:'100%'}}>
                            {/*文本*/}
                            <div style={{width:'100%',height:'70%'}}>
                                <div style={{width:'100%',height:'100%', borderRadius: '5px',backgroundColor:'#f9f9f9',overflowY: 'auto'}}>
                                    {this.dialogBoxTitle(this.state.titleInfo_1)}
                                    <div id={'box'}/>
                                </div>
                            </div>
                            {/*输入框*/}
                            <div style={{width:'100%',height:'25%', borderRadius: '10px',padding:'10px 0 0 0'}}>
                                <div style={{width:'100%',height:'100%'}}>
                                    <TextArea
                                        value={this.state.textValue}
                                        onChange={(text)=>{this.setState({textValue:text?.target?.value})}}
                                        paddingBlock={3}
                                        style={{width:455, height: 80, resize: 'none' }}
                                    />
                                </div>
                            </div>
                            <div style={{width:'100%'}}>
                                <Button style={{float:'right'}} onClick={()=>{this.addTitle()}}>发送</Button>
                            </div>
                        </div>


                    </div>
                </Modal>

{/*文档--------------------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    visible={this.state.visible_2}
                    onOk={ async () => {this.setState({visible_2:false})}}           	// 点击确定
                    onCancel={async () =>
                    {
                        await clearInterval(this.state.setCountDown);       // 清空计时器
                        this.setState({
                            dates:null,
                            visible_2:false,        // 弹窗开关
                            countDown:null,            // 即时分钟
                            countDown_disabled:true // 按钮开关
                        }); // 关闭弹窗
                    }}	// 点击取消
                    width={1000}
                    centered
                    footer={
                        <div style={{width:'100%',height:'40px'}}>
                            {/*时期选择 countDownTitle*/}
                            <div style={{float:'left',  display: this.state.countDownType?'none':null}}>
                                <span>放假日期：</span>
                                {this.state.countDownTitle?
                                    <span style={{display: this.state.countDownTitle?null:'none'}}>{this.state.startTimeOfVacation}-{this.state.endTimeOfVacation}</span>
                                    :
                                    <RangePicker
                                    showTime={false}
                                    format="YYYY-MM-DD"
                                    value={this.state?.dates}
                                    onCalendarChange={val =>{
                                        if(!val){return this.setState({countDown_disabled:true})}// 对按钮限制-关
                                        if(val){if(!val[0] || !val[1]){
                                            this.setState({dates:null});// 对按钮限制-开
                                            return false;
                                        }}// 对按钮限制-关
                                        this.setState({dates:val,countDown_disabled:false})// 对按钮限制-开
                                    }}
                                    placeholder={['开始时间','结束时间']}
                                />
                                }
                            </div>
                            {/*按钮*/}
                            <div style={{float:'right'}}>
                                <Button  type="primary" disabled={this.state.countDown_disabled}
                                         loading={this.state.modalLoading_1}
                                         onClick={ async ()=>{
                                             var dates = this.state.dates;
                                             var param={
                                                 "configId": this.state.configId,             // 配置ID
                                                 "customerId": new URLSearchParams(window.location.search).get('customerId'),   // 客户Id
                                             };
                                             if(dates)
                                                 if(dates.length!==0){
                                                     param.startTimeOfVacation=moment(dates[0]).format("yyyy-MM-DD HH:mm:ss");// 放假结束时间
                                                     param.endTimeOfVacation=moment(dates[1]).format("yyyy-MM-DD HH:mm:ss");  // 放假开始时间
                                                 }
                                             this.setState({
                                                 modalLoading_1:true,
                                                 countDown:null,           // 重置即时秒
                                                 countDown_disabled:true// 关闭按钮
                                             });
                                             if(this.state.requestType){
                                                 learningTaskReporting(param).then(
                                                     response=>{
                                                         if(response?.data?.data){
                                                             message.info('上报成功')
                                                         }else{
                                                             message.info('上报失败')
                                                         }
                                                         this.setState({
                                                             modalLoading_1:false,
                                                             visible_2:false,       // 关闭弹窗
                                                         });
                                                     });
                                             }else{
                                                 this.setState({
                                                     modalLoading_1:false,
                                                     visible_2:false,       // 关闭弹窗
                                                 });
                                             }
                                             await clearInterval(this.state.setCountDown);       // 清空计时器
                                         }}
                                >
                                    <span>确定<span id={'countDown'} style={{ display: this.state.countDownType?null:'none'}}>{this.state.countDown}</span></span>
                                </Button>
                            </div>
                        </div>
                    }
                >
                    <div style={{width:'99%',height:'800px',overflowY:'auto'}}>
                        {/*文本-------------------------------------*/}
                        <div dangerouslySetInnerHTML={{__html: this.state.titleInfo_2}}/>
                    </div>
                </Modal>


{/*数据弹窗------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    closable={false}
                    visible={this.state.visible_3}
                    onOk={ async () => {}}           	// 点击确定
                    onCancel={() => this.setState({visible_3:false})}	// 点击取消
                    width={1500}
                    centered

                >
                    <div style={{height:'500px',overflowY: 'auto'}}>
                        <DetailedReport
                            elementById={'DetailedReport_1'}
                            customerId={new URLSearchParams(window.location.search).get('customerId')}
                            data={this.state.leftData_barChart_1.data}    // 数值
                            yData={this.state.leftData_barChart_1.yData}  // X轴数据
                            number={this.state.leftData_barChart_1.number}// 最小、最大、间距
                        />
                    </div>
                </Modal>
{/*下方1-数据表格弹窗------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    closable={false}
                    visible={this.state.visible_4}
                    onOk={ async () => {this.setState({visible_4:false})}}           	// 点击确定
                    onCancel={() => this.setState({visible_4:false})}	// 点击取消
                    width={1500}
                    centered

                >
                    <div>
                        <div className={'topSearchBox'}>
                            <div style={{position:'relative'}}>
                                <div className={'topFormBox'} style={{height:'40px'}}>
                                    <div  style={{width:'20%',float:'left'}}>
                                        <FormsTwo
                                            ref={e => this.forms_1 = e}
                                            formParamenter={this.state.topFormItem_1}
                                            data={this.state.topFormItem_1.searchGetData}
                                        />
                                    </div>
                                    <div  style={{width:'6%',float:'left',paddingLeft:'10px'}}>
                                        <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{height:'500px',padding:'0 0 0 0',background:'#fff', overflowY: 'auto'}}>
                            <TableComponents
                                paramenter={this.state.tableParamenter_1}
                                setData = {getDevList}
                                // setTableValue={this.setTableValue}
                                ref={e => this.table_1 = e}
                            >
                            </TableComponents>
                        </div>
                    </div>
                </Modal>
{/*下方2-数据表格弹窗------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    closable={false}
                    visible={this.state.visible_5}
                    onOk={ async () => {this.setState({visible_5:false})}}           	// 点击确定
                    onCancel={() => this.setState({visible_5:false})}	// 点击取消
                    width={1500}
                    centered
                >
                    <div>
                        <div style={{width:'100%',marginLeft:'10px'}}>
                            <a type={'primary'} style={{marginLeft:"95%"}}
                               onClick={()=>{this.getAllInfoByCustomerId();}}
                            >刷新</a>
                        </div>
                        <div style={{height:'500px',padding:'0 0 0 0',background:'#fff', overflowY: 'auto'}}>
                            <Table
                                columns={this.state.tableData_2}
                                dataSource={this.state.tableData_2_data}
                                bordered
                                pagination = {{
                                    showSizeChanger:false,
                                    showQuickJumper: false,
                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                    total:this.state.tableData_2_data?.length,
                                    pageSize:6,
                                    showTotal: (count = this.state.tableData_2_data?.length)=>{return '共'+count+'条数据'},
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['6']
                                }}
                            />
                        </div>
                    </div>
                </Modal>
{/*中右-数据表格弹窗------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    closable={false}
                    visible={this.state.visible_6}
                    onOk={ async () => {this.setState({visible_6:false})}}           	// 点击确定
                    onCancel={() => this.setState({visible_6:false})}	// 点击取消
                    width={1500}
                    centered
                >
                    <div>
                        <div style={{height:'30px',width:'100%',marginLeft:'10px'}}>
                            <a type={'primary'} style={{marginLeft:"95%"}}
                               onClick={()=>{this.table_2.refreshTable()}}
                            >刷新</a>
                        </div>
                        <div style={{height:'500px',padding:'0 0 0 0',background:'#fff', overflowY: 'auto'}}>
                            <TableComponents
                                paramenter={this.state.tableParamenter_3}
                                setData={pageHiddenDanger}
                                // setTableValue={this.setTableValue}
                                ref={e => this.table_2 = e}
                            />
                        </div>
                    </div>
                </Modal>

{/*下方-3-数据表格弹窗------------------------------------------*/}
{/*                <Modal*/}
{/*                    maskClosable={false}*/}
{/*                    closable={false}*/}
{/*                    visible={this.state.visible_7}*/}
{/*                    onOk={ async () => {this.setState({visible_7:false})}}           	// 点击确定*/}
{/*                    onCancel={() => this.setState({visible_7:false})}	// 点击取消*/}
{/*                    width={1500}*/}
{/*                    centered*/}
{/*                >*/}
{/*                    <div>*/}
{/*                        <div style={{width:'100%',marginLeft:'10px'}}>*/}
{/*                            <a type={'primary'} style={{marginLeft:"95%"}}*/}
{/*                               onClick={()=>{this.table_3.refreshTable()}}*/}
{/*                            >刷新</a>*/}
{/*                        </div>*/}
{/*                        <div style={{height:'500px',padding:'0 0 0 0',background:'#fff', overflowY: 'auto'}}>*/}
{/*                            <TableComponents*/}
{/*                                paramenter={this.state.tableParamenter_4}*/}
{/*                                setData={getPageFuzzyQuerys}*/}
{/*                                // setTableValue={this.setTableValue}*/}
{/*                                ref={e => this.table_3 = e}*/}
{/*                            />*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                </Modal>*/}
{/*下方-4-数据表格弹窗------------------------------------------*/}
                <Modal
                    maskClosable={false}
                    closable={false}
                    visible={this.state.visible_7}
                    onOk={ async () => {this.setState({visible_7:false})}}           	// 点击确定
                    onCancel={() => this.setState({visible_7:false})}	// 点击取消
                    width={1500}
                    centered
                >
                    <div>
                        <div style={{width:'100%',marginLeft:'10px'}}>
                            <a type={'primary'} style={{marginLeft:"95%"}}
                               onClick={()=>{this.getPageFuzzyQuerys()}}
                            >刷新</a>
                        </div>
                        <div style={{height:'500px',padding:'0 0 0 0',background:'#fff', overflowY: 'auto'}}>

                            <Table
                                columns={this.state.tableParamenter_6.columns}
                                dataSource={this.state.tableParamenter_6.data}
                                bordered
                                loading={this.state.tableParamenter_6.loading}
                                pagination = {{
                                    showSizeChanger:false,
                                    showQuickJumper: false,
                                    onChange: this.getPagination,       // 获得翻页数值
                                    total:this.state.tableParamenter_6.params.total,
                                    pageSize:13,
                                    showTotal: (count = this.state.tableParamenter_6.params.total)=>{return '共'+count+'条数据'},
                                    defaultCurrent: 1,
                                    pageSizeOptions: ['13']
                                }}
                            />
                        </div>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
