import React, { Component } from 'react'
import { Layout, Select, Form } from 'antd'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getDataDifference, getAllManufacturerAndModel} from '../../../services/report'

const { Option } = Select
const FormItem = Form.Item

export default class DataDifference extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
            rowSelection: false,
			width: '83.7vw',
            title: '用户导入数据差异报表',
			exportUrl: "/web/reportExportCtrl/exportDataDifference",
			importUrl: "/web/customerCtrl/importData",
			// 表头：变量和变量解释
			header: {
				content: '内容',
                steelNum: '表钢号',
                // company: '公司',
				name: '姓名',
				installNumber: '安装号',
				contractNumber: '合同号',
				// modelName: '表具型号',
                // meterManufacturer: '表具厂商',
				street: '街道',
				communities: '小区',
				buildNum: '楼栋',
				unitNum: '单元',
				doorplateNum: '房间号'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'input',
						variableName: 'steelNum',
						str: '表钢号'
					},
					{
						type: 'input',
						variableName: 'contractNum',
						str: '合同号'
					},
					// {
					// 	type: 'input',
					// 	variableName: 'installNumber',
					// 	str: '安装号'
					// }
				]
			},
		},
		// 下拉框
		listDatas: [],
		// 类型选择
		modelDatas: [],
		manufactureValue: null,
		meterModelValue: null
	}

	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: true}).then(
			response => {
				let {data} = response.data
				this.setState({listDatas: data})
			}
		)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = () => {
		let  {meterModelValue, manufactureValue} = this.state
		return {
			modelId: meterModelValue,
			manufacture: manufactureValue
		}
	}

	// 清空条件
	clearCondition = () => {
		this.setState({
			manufactureValue: null,
			meterModelValue: null,
			modelDatas: []
		})
	}

	listMeterManufacturer = e => {
		let {listDatas} = this.state
		let modelDatas = listDatas?.filter(val => val.manufacturer === e)?.[0]?.models
		this.setState({
			modelDatas,
			manufactureValue: e,
			meterModelValue: null
		})
	}

	listMeterModel = e => this.setState({ meterModelValue: e })

	render() {
		
		let {listDatas, modelDatas} = this.state
		
        return (
            <Layout className={'item_body_number_back report'} style={{backgroundColor: '#ffffff'}}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={getDataDifference}
					getConditionsValue={this.getConditionsValue}
					clearCondition={this.clearCondition}
					ref={e => this.table = e} 
				>
					<FormItem
						label={"厂商"}
						rules={[{ required: true }]}
						initialValue={null}
					>
						<Select 
							placeholder={`选择厂商`} 
							style={{width: '6vw'}}
							value={this.state.manufactureValue}
							onChange={e => this.listMeterManufacturer(e)}
						>
							{
								listDatas?.map(({manufacturer}) => <Option key={manufacturer}>{manufacturer}</Option>)
							}
						</Select>
					</FormItem>
					<FormItem
						label={"型号"}
						rules={[{ required: true }]}
					>
						<Select 
							style={{width: '6vw'}}
							value={this.state.meterModelValue}
							placeholder={`选择型号`} 
							onChange={e => this.listMeterModel(e)}
						>
							{
								modelDatas?.map(({id,name}) => <Option key={id}>{name}</Option>)
							}
						</Select>
					</FormItem>
				</TableComponents>
            </Layout>
        )
    }
}