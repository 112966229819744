import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Popconfirm} from 'antd'
import {TYPE_NAME} from '../../components/Const'

import TableComponents from '../../components/common/tables/TableComponents'
import {delPushConfig, queryPushConfigs, savePushConfig, updatePushConfig} from '../../services/pushData'
import FormsTwo from "../../components/common/forms/FormsTwo";

export default class PushConfiguration extends Component {

    state = {
		pushId:null,
    	modeTitle:'新增配置',
		modalVisible_1:false,
		// 表格参数
		tableParamenter: {
			manageName: "数据推送管理系统",
			// 是否绑定左侧区域
			bindArea: false,
			// 表格行是否可选
            rowSelection: false,
			// 获取表格参数数据
			localStorageName: 'pushConfiguration',
			//下拉框长度
			selectWidth: '7.5vw',
			// 表头：变量和变量解释
			fileName: '数据推送配置',
			header: {
				id: '配置编号',
				pushName: '推送名',
				pushObject: '推送对象',
				pushInterval: '推送间隔（分钟）',
				url: '推送地址',
				lastPushTime: '最后一次推送时间',
				lastSuccess: '最后一次成功数',
				lastFails: '最后一次失败数',
				pushSums: '推送总数',
				state:'推送开关',
				edit:'操作'
			},

			// 条件选择器参数
			conditionsParamenter: {
				// 条件
				parameter: [
					{
						type: TYPE_NAME.NAME,
						variableName: 'pushName',
						str: '推送名',
						initData: JSON.parse(localStorage.getItem('pushConfiguration'))?.pushName
					},
					{
						type: TYPE_NAME.NAME,
						variableName: 'url',
						str: '推送地址',
						initData: JSON.parse(localStorage.getItem('pushConfiguration'))?.url
					},
				]
			},


		},

		modalFormItem: {
			modal: true,
			number: 1,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{variableName: 'pushName', str: '推送名', rules: TYPE_NAME.NOT_BLANK,},
				{variableName: 'pushObject', str: '推送对象', rules: TYPE_NAME.NOT_BLANK,},
				{variableName: 'pushInterval', str: '推送间隔（分钟）', rules: TYPE_NAME.ID_NUMBER_10,},
				{variableName: 'url', str: '推送地址', rules: TYPE_NAME.TEXT_AREA,},
			],
		},
		searchGetData:{}
	};

	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'id':
				return (
					<NavLink to={{pathname: "/pushData/PushConfigurationDetails",state: {
						id: rowData.id,
						pushName: rowData.pushName,
						pushObject: rowData.pushObject,
						pushSums: rowData.pushSums,
						url: rowData.url,
						pushInterval: rowData.pushInterval,
						lastPushTime: rowData.lastPushTime,
						lastFails: rowData.lastFails,
						lastSuccess: rowData.lastSuccess,
					}}}>
						<div className={'table_overflow'} style={{color:'#1890ff'}} title={text}>
							<u>{text}</u>
						</div>
					</NavLink>
				);
			case 'state':
				var title=text===0?'是否关闭推送':'是否启动推送';
				var title1=text===0?'已开启':'已关闭';
				return (
					<>
						<div className={'tableLink'} title={'开关'}>
							<Popconfirm title={title}
										onConfirm={()=>{
											this.updatePushConfig_1(rowData.id,text===0?1:0);
										}}
								// onCancel={cancel}
										okText="是" cancelText="否">
								<a>{title1}</a>
							</Popconfirm>
						</div>
					</>
				);
			case 'edit':
				return (
					<>
						<div className={'tableLink'} title={'修改'}
							 onClick={async ()=>{
							 	this.setState({pushId:rowData.id});
								 this.modalData(true,rowData,'修改配置')
							 }}
						>
							<a>修改</a>
						</div>
						<div className={'tableLink'} title={'删除'}>
							<Popconfirm title="是否删除配置?"
										onConfirm={()=>{
											this.delPushConfig(rowData.id);
										}}
								// onCancel={cancel}
										okText="是" cancelText="否">
								<a>删除</a>
							</Popconfirm>
						</div>
					</>
				);
				break;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	//添加设备窗口显示
	modalData = async (devVisible,data,title)=>{
		await this.setState({modalVisible_1:devVisible,searchGetData:data,modeTitle:title});
		await this.forms.onModalClose()
	};

	savePushConfig= async() =>{

		var params = await this.forms.onFinishNotReset();
		console.log(params);
		if(!params) return false;
		// await this?.forms?.onModalClose();
		savePushConfig(params).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('新增成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.table.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('新增失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		})
	};
	// 修改数据推送开关
	updatePushConfig_1= async(id,num) =>{
		var params = {
			id:id,
			state:num
		};
		console.log(params);

		updatePushConfig(params).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('操作成功');
					this.setState({modalVisible_1:false});
					this.table.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('操作失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		})
	};
	// 修改数据推送配置
	updatePushConfig= async() =>{
		var params = await this.forms.onFinishNotReset();
		console.log(params);
		if(!params) return false;
		if(this.state?.pushId){
			params.id=this.state.pushId;
		}
		updatePushConfig(params).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('修改成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.table.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('修改失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		})
	};
	delPushConfig=(id)=>{
		var param = new URLSearchParams();
		param.append("id",id);
		delPushConfig(param).then(response=>{
			if(response?.data){
				if(response?.data.responseInfo.code===200){
					message.info('删除成功');
					this.setState({modalVisible_1:false,searchGetData:{}});
					this.table.refreshTable();
				}else if(response?.data.responseInfo.code===500){
					message.info('删除失败')
				}else{
					message.info('异常问题，请重新操作')
				}
			}
		});
	};


    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

				<TableComponents
					paramenter={this.state.tableParamenter}
					setData={queryPushConfigs}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
					<Radio.Group defaultValue="addClient" buttonStyle="solid">
						<Radio.Button value="addClient" onClick={()=>{this.modalData(true,{ level:2, state:1 },'新增配置')}}>新增配置</Radio.Button>
					</Radio.Group>
				</TableComponents>
				<Modal
					title={this.state.modeTitle}
					style={{ top: 0 }}
					visible={this.state.modalVisible_1}
					// okText={this.state.modalFormItemText}
					// 点击确定
					onOk={()=>{
						if(this.state.modeTitle==='新增配置'){
							this.savePushConfig();	// 新增
						}else {
							this.updatePushConfig();// 修改
						}

					}}
					// 点击取消
					onCancel={async ()=>{this.modalData(false,{})}}
					width={600}
					centered
				>
					<FormsTwo
						ref={e => this.forms = e}
						formParamenter={this.state.modalFormItem}
						data={this.state.searchGetData}
					/>
				</Modal>
            </Layout>
        )
    }
}
