import React, { Component } from 'react'
import {Button, Layout, message, Modal, Popconfirm, Table, Tabs} from 'antd'
import { ArrowLeftOutlined,GlobalOutlined } from '@ant-design/icons'
import {TYPE_NAME} from "../../components/Const";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {Map, Marker} from "@uiw/react-amap";
import {addOrUpdateGateStation} from "../../services/station";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getDevInfoByCustomerId, pageLikeMpName} from "../../services/meter";
import {getLocationById, getUnboundValveAlarm} from "../../services/customer";
import {getDateByUserId} from "../../services/admin";
import {queryRelationData, removeRelation, saveRelation} from "../../services/community";
import moment from "moment";
const { TabPane } = Tabs;
const { AMap } = window;
// 门站管理
export default class addOrModifyStation extends Component {

    state = {
// 表单参数-1-第一排
        form_1: {
            number: 4,
            labelCol: 8,
            parameter: [
                {variableName: 'name', str: '门站名称',                            rules: TYPE_NAME.NOT_BLANK, disabled: false,},
                {variableName: 'areaId', str: '所属区域',     rules: TYPE_NAME.REQ_TREE_SELECT, disabled: false,
                    fieldNames:{label: 'name', value: 'id', children: 'children'},
                    data:[],
                    onChange: (e,a) => this.onChangeIntervalType_1(e,a),
                },
                {variableName: 'gasSource', str: '气源',                           rules: TYPE_NAME.NOT_BLANK, disabled: false,},
                {variableName: 'productionDate', str: '投产日期',                  rules: TYPE_NAME.DATE_PICKER, disabled: false,},
                {variableName: 'designLife', str: '设计年限',                      rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'project', str: '建设单位',                         rules: TYPE_NAME.INPUT, disabled: false,},
                {variableName: 'designUnit', str: '设计单位',                      rules: TYPE_NAME.INPUT, disabled: false,},
                {variableName: 'intakePressure', str: '进气压力',                  rules: TYPE_NAME.ID_NUMBER, disabled: false,},
                {variableName: 'dailyGasSupplyCapacity', str: '日供气能力m³',        rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'numberOfOnDutyPersonnel', str: '值守人数',         rules: TYPE_NAME.INPUT, disabled: false,},
                {variableName: 'conveyingHighPressure', str: '输送高压',           rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'conveyingIntermediatePressure', str: '输送次高压', rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'transportMediumPressure', str: '输送中压',         rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'conveyingLowPressure', str: '输送低压',            rules: TYPE_NAME.NUMBER, disabled: false,},
                {variableName: 'position', str: '门站位置',                        rules: TYPE_NAME.NAME_NOT_NULL, disabled: true,},
            ],
        },
        form_1_data:{},
// 弹窗-查询
        form_2: {
            number: 1,
            labelCol: 8,
            parameter: [
                {variableName: 'areaId', str: '所属区域',     rules: TYPE_NAME.REQ_TREE_SELECT, disabled: false,
                    fieldNames:{label: 'name', value: 'id', children: 'children'}, data:[],
                    onChange: (e,a) => this.onChangeIntervalType_2(e,a),
                },
            ],
        },
        form_2_data:{},
// 表格参数
//计量点---------
        tableData_header_1:[
            {title: '计量点代码', dataIndex: 'id', key: 'id'},
            {title: '用气用途', dataIndex: 'purpose', key: 'purpose'},
            {title: '用气性质', dataIndex: 'properties', key: 'properties'},
            {title: '用气设备', dataIndex: 'gasEquipment', key: 'gasEquipment'},
            {title: '计量点名称', dataIndex: 'mpName', key: 'mpName'},
            {title: '客户类型', dataIndex: 'customerType', key: 'customerType'},
            {title: '客户号', dataIndex: 'customerId', key: 'customerId'},
            {title: '合同号', dataIndex: 'contractNum', key: 'contractNum'},
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum'},
            {title: '表具型号', dataIndex: 'modelName', key: 'modelName'},
            {title: '是否装表', dataIndex: 'isInstall', key: 'isInstall'},
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum'},
            {title: '计量点状态', dataIndex: 'state', key: 'state'},
            {title: '计量点地址', dataIndex: 'address', key: 'address'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.id,2,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
// 压力表---------
        tableData_header_2:[
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType'},
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '设备编号', dataIndex: 'serialno', key: 'serialno'},
            {title: '生产厂家', dataIndex: 'manufacture', key: 'manufacture'},
            {title: '设备状态', dataIndex: 'state', key: 'state'},
            {title: '唯一编码', dataIndex: 'uniqueEncoding', key: 'uniqueEncoding'},
            {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.devId,3,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
// 监控--------
        tableData_header_3:[
            {title: '设备类型', dataIndex: 'devType', key: 'devType'},
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '设备编号', dataIndex: 'serialno', key: 'serialno'},
            {title: '生产厂家', dataIndex: 'manufacture', key: 'manufacture'},
            {title: '设备状态', dataIndex: 'state', key: 'state'},
            // {title: '唯一编码', dataIndex: 'uniqueEncoding', key: 'uniqueEncoding'},
            // {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.serialno,4,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],
// 阀井------
        tableData_header_4:[
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName'},
            {title: '设备类型', dataIndex: 'devType', key: 'devType'},
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '设备编号', dataIndex: 'serialno', key: 'serialno'},
            {title: '生产厂家', dataIndex: 'manufacture', key: 'manufacture'},
            {title: '设备状态', dataIndex: 'state', key: 'state'},
            {title: '唯一编码', dataIndex: 'uniqueEncoding', key: 'uniqueEncoding'},
            {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render: (text, record) =>{
                    return<>
                        <div className={'tableLink'} title={'解绑'}>
                            <Popconfirm title="是否解绑?"
                                        onConfirm={()=>{
                                            this.removeRelation(record.devId,5,record);
                                        }}
                                        okText="是" cancelText="否">
                                <a>解绑</a>
                            </Popconfirm>
                        </div></>;
                }
            },
        ],

// 表格参数_弹窗_设备列表
        tableParam_1: {
            overflow:true,
            manageName: "设备列表",
            // 表格行是否可选
            // rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {},
            params: {
                areaIds:[],
                page: 1,
                size: 10,
            },
            // 是否绑定左侧区域
            bindArea: false
        },



        tableParam_data_1: [],// 计量点
        tableParam_data_2: [],// 压力表/阀进/监控表数据
        tableParam_loading:false,


        areaName:null,// 地址名

        items :[],          // 标签页
        longitude:null,     // 经度
        latitude:null,      // 纬度

        visible_title:null, // 弹窗标题

        visible_1:false,    // 弹窗开关-1
        visible_2:false,    // 弹窗开关-2
        visible_2_type:1,   // 类型
    };
    async componentDidMount() {

        // 初始化配置标签页
        var items = [
            {key: '1', label: '计量点', children:this.getTabsProps(1,'计量点')},
            {key: '2', label: '压力表', children:this.getTabsProps(2,'压力表')},
            {key: '3', label: '监控', children:this.getTabsProps(3,'监控')},
            {key: '4', label: '阀井', children:this.getTabsProps(4,'阀井')}
        ];
        this.setState({items:items,});

        var rowData = this.props.location?.state?.rowData;
        if(!this.props.location?.state?.selectWidth){
            rowData.productionDate= rowData?.productionDate?moment(rowData?.productionDate):null;
            await this.setState({form_1_data:rowData,});
            this.forms1.onModalClose();
        }
        this.queryRelationData(2);
        this.getDateByUserId(rowData?.areaId??null);// 获取区域




    }
    //标签页-表格
    getTabsProps = (key=String,name,rowData)=>{
        if(!key){return false;}
        return <>
            <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                      onClick={async ()=>{
                          await this.setState({visible_title: '绑定'+name,visible_2:true,visible_2_type:key});
                          this.setTableHead(key);
                      }}>
                    绑定{name}
                </span>
                <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                      onClick={()=>{
                          var tableParamData1 = [...this.state.tableParam_data_1];
                          tableParamData1=[{},{}];
                          console.log('1111111');
                          this.setState({tableParam_data_1:tableParamData1})
                          // this.queryRelationData(key);
                      }}>
                    刷新
                </span>
            </div>
            <div>
                <Table
                    // columns={this.state["tableData_header_"+key]}
                    columns={this.state.tableData_header_1}
                    // dataSource={key===1?this.state.tableParam_data_1:this.state.tableParam_data_2}
                    dataSource={this.state.tableParam_data_1}
                    bordered
                    loading={this.state.tableParam_loading}
                    // pagination = {{
                    //     showSizeChanger:true,
                    //     showQuickJumper: true,
                    //     total:key===1?this.state.tableParam_data_1.length:this.state.tableParam_data_2.length,
                    //     pageSize:4,
                    //     showTotal: (count = (key===1?this.state.tableParam_data_1.length:this.state.tableParam_data_2.length))=>{
                    //         return '共'+key===1?this.state.tableParam_data_1.length:this.state.tableParam_data_2.length+'条数据'
                    //     },
                    //     defaultCurrent: 1,
                    //     pageSizeOptions: ['4']
                    // }}
                />
            </div>
        </>
    };

    // 获取区域
    getDateByUserId=(areaId)=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    this.state.form_1.parameter[1].data=response?.data;
                    this.state.form_2.parameter[0].data=response?.data;

                    if(areaId) {
                        if(this.props.location?.state?.rowData){
                            var tableParam1 = this.state.tableParam_1;
                            var treeAreaIds=[];
                            this.forTreeArea(response?.data,areaId,treeAreaIds);
                            tableParam1.params.areaIds=treeAreaIds;
                            this.setState({tableParam_1:tableParam1});
                        }
                    }

                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };

    // 所属区域选择功能
    onChangeIntervalType_1= async (e,a)=>{
        console.log(e);//200
        console.log(a);//[区域名]
        if(!e){return false;}
        var tableParam1 = this.state.tableParam_1;
        var form2Data = this.state.form_2_data;

        var treeAreaIds=[];
        this.forTreeArea(this.state.form_1.parameter[1].data,e,treeAreaIds);
        tableParam1.params.areaIds=treeAreaIds;
        this.setState({areaName:a[0],tableParam_1:tableParam1});
    };
    onChangeIntervalType_2= async (e,a)=>{
        if(!e){return false;}
        var tableParam1 = this.state.tableParam_1;
        var treeAreaIds=[];
        this.forTreeArea(this.state.form_2.parameter[0].data,e,treeAreaIds);
        tableParam1.params=Object.assign({},tableParam1.params,{areaIds:treeAreaIds});
        this.setState({areaName:a[0],tableParam_1:tableParam1});
    };

    forTreeArea = (data,key=String,areaIds=[]) =>{

        data.map(one=>{
            if(one.id.toString().indexOf(key)!==-1){
                areaIds.push(one.id);
                if(one?.children){
                    this.addAllTreeArea(one?.children,areaIds);
                }
            }else if(one?.children){
                this.forTreeArea(one?.children,key,areaIds);
            }
        });

        return areaIds;
    };
    addAllTreeArea = (data,areaIds=[]) =>{
        data.map(one=>{
            areaIds.push(one.id);
            if(one?.children){
                this.addAllTreeArea(one?.children,areaIds);
            }
        });
        return areaIds;
    };
    // 弹窗表格切换
    setTableHead=(type)=>{
        var tableParam1 = this.state.tableParam_1;
        tableParam1.header=type===2?
            {   id: '计量点代码',
                installNumber: '安装号',
                purpose: '用气用途',
                properties: '用气性质',
                gasEquipment: '用气设备',
                name: '计量点名称',
                customerId: '客户号',
                contractNumber: '合同号',
                steelNum: '表钢号',
                state:'状态',
                edit:'操作',
            }
            :
            tableParam1.header=type===4?
                {
                    devName:'设备名',
                    serialno:'设备编号',
                    manufacture: '生产厂家',
                    state:'设备状态',
                    uniqueEncoding: '唯一编码',
                    installPosition: '安装位置',
                    edit: '操作',
                }
                :
                {
                    devModelName:'设备型号',
                    devName:'设备名',
                    serialno:'设备编号',
                    manufacture: '生产厂家',
                    state:'设备状态',
                    installPosition: '安装位置',
                    comStatus: '通信状态',
                    systemTime: '通信时间',
                    edit: '操作',}
        ;
        this.setState({tableParam_1:tableParam1})
    };
    setTableValue_1 = (value,text,rowData) => {

        if(text === 'null') return '-';
        switch (value) {

            case 'edit':
                return <>
                    <div className={'tableLink'} title={'绑定'}>
                        <Popconfirm title="是否绑定?"
                                    onConfirm={()=>{
                                        this.saveRelation(rowData.id,this.state.visible_2_type,rowData)
                                    }}
                                    okText="是" cancelText="否">
                            <a>绑定</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 添加小区关系 - type类型（1：楼栋，2：计量点，3：压力表10171，4：监控10209，5：阀进10200)
    saveRelation=(relationId,type,rowData)=>{
        console.log(relationId);

        if(!relationId){return false;}
        if(!type){return false;}
        var params={

            'installer':JSON.parse(sessionStorage.getItem("user")).realName??null,  // 安装人
            'installerTel':JSON.parse(sessionStorage.getItem("user"))?.phone??null, //安装联系电话

            'id':relationId,	    // 未绑定的设备id

            'businessType':'门站',
            'devId':rowData?.devId?rowData?.devId:null,                         // device服务主键id
            'devType':type===3?'10171':type===4?'10209':type===5?'10200':null,  // 设备类型
            'hdstno':rowData?.hdstno??null,	                                    // 厂家编号
            'serialno':rowData?.serialno??null,                                 // 序列号/设备编号

            "regionManagementId": this.props.location?.state?.rowData.id.toString(),       // 小区id

            "relationId" :                              // 设备类型,
                type===2?relationId:
                    type===3?rowData?.devId:
                        type===4?rowData?.serialno:
                            type===5?rowData?.devId:
                                null,                  // 关联id
            "type": type                             // 类型
        };
        saveRelation(params).then(
            response => {
                if(response?.data?.data){
                    message.info('操作成功')
                }else{
                    message.info('操作失败'+response?.data?.responseInfo?.description);
                }
                this.table.refreshTable(this.state.tableParam_1.params);
                this.queryRelationData(type);
            }
        );

    };
    // 查询表格
    queryRelationData=(e)=>{
        var rowData = this.props.location?.state?.rowData;
        if(!rowData){return false;}
        var params={

            "regionManagementId": rowData?.id,
            "relationId": null,
            "type": e,
            businessType:'门站',
        };
        this.setState({tableParam_loading:true});
        queryRelationData(params).then(
            response => {
                if(response?.data?.data){
                    // buildingList     楼顶
                    // pointList        计量点
                    // valveAlarmLists  压力表/阀进/监控
                    // 楼顶表数据，计量点表数据，压力表/阀进/监控表数据
                    var data =response?.data?.data;
                    this.setState({
                        tableParam_data_1:data?.pointList??[],       // 计量点
                        tableParam_data_2:data?.valveAlarmLists??[], // 压力表/阀进/监控

                    })
                }
                this.setState({tableParam_loading:false});

            }
        )
    };
    // 添加(修改)门站
    addOrUpdateGateStation = async () =>{
        var forms1 = await this.forms1.onFinishNotReset();
        if(!forms1){return false}
        var params = Object.assign({},forms1);
        if(this.props.location?.state?.rowData){
            params.id=this.props.location?.state?.rowData?.id??null;
        }
        var {areaName} = this.state;
        if(areaName){
            params.areaName = areaName;
        }else{
            params.areaId = this.props.location?.state?.rowData?.areaId??null;
            params.areaName = this.props.location?.state?.rowData?.areaName??null;
        }

        console.log(params);
        Modal.confirm({
            title: `是否确认操作`,
            onOk: () => {
                addOrUpdateGateStation(params).then(
                    response => {
                        if(response?.data?.data){
                            message.success({ content: params?.id ? '修改成功' : '开户成功', key: 'save', duration: 2});
                            return this.props.history.go(-1);
                        }else{
                            message.info('保存失败'+response?.data?.responseInfo?.description);
                        }
                    }
                )
            }
        });
    };
    // 移除小区关系-能直接删除楼栋
    removeRelation=(relationId,type=String,rowData)=>{
        console.log(relationId);
        console.log(type);
        console.log(type==='3');
        console.log(rowData);
        // var rowData = this.props.location?.state?.rowData;
        if(!relationId){return false;}
        var params = {
            'id':rowData.id,	    // 未绑定的设备id
            'installer':JSON.parse(sessionStorage.getItem("user")).realName??null,          // 安装人
            'installerTel':JSON.parse(sessionStorage.getItem("user"))?.phone??null,         //安装联系电话
            businessType:'门站',

            'devId':rowData?.devId?rowData?.devId:null,                                               // device服务主键id
            'devType':type==='3'?'10171':type==='4'?'10209':type==='5'?'10200':null,                  // 设备类型
            'hdstno':rowData?.hdstno??null,	                                                     // 厂家编号
            'serialno':rowData?.serialno??null,                                                 // 序列号/设备编号

            "regionManagementId":this.props.location?.state?.rowData.id,
            "relationId" :                              // 设备类型,
                type==='2'?relationId:
                    type==='3'?rowData?.devId:
                        type==='4'?rowData?.serialno:
                            type==='5'?rowData?.devId:
                                relationId,                  // 关联id
            "type": type                             // 类型
        };
        removeRelation(params).then(
            response => {
                if(response?.data?.data){
                    this.queryRelationData(type);

                }else{
                    message.info('删除失败')
                }
            }
        )
    };



    render() {
        const {items} = this.state;
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
{/*返回上一页----------*/}
            <div style={{width:'100%',height:'100%'}}>
                <div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}>
                        <ArrowLeftOutlined />
					</span>&nbsp;&nbsp;&nbsp;<span className={'font_size2'}>返回上一页</span>
                </div>
                {/*  主体 */}
                <div style={{width:'100%',height:'795px',padding:'10px'}}>
{/*表单-上部分--------*/}
                    {/*标题*/}
                    <div>
                        <div style={{width:'100%',height:'100%', marginLeft:'10px',marginTop:'-5px'}}>
                            <div style={{width:'60px',height:'25px', textAlign:'center', borderRadius: '0px 3px 3px 0px',backgroundColor:'#fff'}}>
                                <span>基本信息</span>
                            </div>
                        </div>
                    </div>
                    {/*地址按钮*/}
                    <div>
                        <div style={{width:'30px',height:'30px', textAlign:'center', marginLeft:'1170px',marginTop:'175px'
                        }}>
                            <div style={{width:'100%',height:'100%',paddingTop:"5px",cursor:'pointer',
                                borderRadius: '0px 3px 3px 0px',backgroundColor:'#1890ff'}}
                                 onClick={ async () => {this.setState({visible_title:'地图',visible_1:true})}}
                            >
                                <GlobalOutlined style={{color:'#fff'}}/>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'1610px',height:'286px',marginTop:'-220px',padding:'20px 10px 10px 10px',border:'1px solid rgb(240 242 245)'}}>
                        <div style={{width:'98%',height:'220px',float:'left'}}>
                            <FormsTwo
                                ref={e => this.forms1 = e}
                                formParamenter={this.state.form_1}
                                data={this.state.form_1_data}
                            />
                        </div>
                        {/*保存*/}
                        <div style={{width:'98%',height:'40px',float:'left'}}>
                            <Button type="primary" style={{float:'right'}}
                                    onClick={()=>{this.addOrUpdateGateStation();}}
                            >
                                保存
                            </Button>
                        </div>

                    </div>
{/*表格-下部分--------*/}
                    <div>
                        <div style={{width:'100%',height:'100%', marginLeft:'10px',marginTop:'15px',display: this.props.location?.state?.selectWidth?'none':null}}>
                            <div style={{cursor:'pointer', width:'60px',height:'25px', textAlign:'center', borderRadius: '0px 3px 3px 0px',backgroundColor:'#fff'}}>
                                <span>设备信息</span>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'100%',height:'470px',marginTop:'-15px',padding:'20px 10px 10px 10px',border:'1px solid rgb(240 242 245)',display: this.props.location?.state?.selectWidth?'none':null}}>
                        <div>
                            {/*<Tabs  type="card" defaultActiveKey="1" items={items}*/}
                            {/*      onChange={(key)=>{*/}
                            {/*          this.getTabsProps(key);*/}
                            {/*      }}/>*/}
                            <Tabs type="card"
                                  onTabClick={(e,a)=>{
                                      this.setState({
                                          visible_2_type:e,
                                      });
                                      this.queryRelationData(e);
                                  }}>
                                {/*计量点*/}
                                <TabPane tab="计量点" key="2" style={{width: '100%',height: '100%'}}>
                                    <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      this.setState({
                                                          visible_title:'绑定计量点',
                                                          visible_2:true,
                                                          visible_2_type:2,
                                                          form_2_data:{areaId:this.forms1.getData('areaId')?.toString()??null}// 弹窗-地址查询表单数据
                                                      });
                                                      await this.setTableHead(2); // 弹窗表格表头切换
                                                  }}>
                                                绑定计量点
                                            </span>
                                        <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                              onClick={()=>{this.queryRelationData(2);}}>
                                                刷新
                                            </span>
                                    </div>
                                    <div style={{width:'100%',height:'380px'}}>
                                        <Table
                                            columns={this.state.tableData_header_1}
                                            dataSource={this.state.tableParam_data_1}
                                            bordered
                                            loading={this.state.tableParam_loading}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                total:this.state.tableParam_data_1.length,
                                                pageSize:4,
                                                showTotal: (count = this.state.tableParam_data_1.length)=>{
                                                    return '共'+this.state.tableParam_data_1.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['4']
                                            }}
                                        />

                                    </div>
                                </TabPane>
                                {/*压力表*/}
                                <TabPane tab="压力表" key="3" style={{width: '100%',height: '100%'}}>
                                    <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam1 = this.state.tableParam_1;
                                                      tableParam1.params.devType=10171;
                                                      this.setState({
                                                          tableParam_1:tableParam1,
                                                          visible_title:'绑定压力表',
                                                          visible_2:true,
                                                          visible_2_type:3,
                                                      });
                                                      await this.setTableHead(3); // 弹窗表格表头切换
                                                      this.table.refreshTable(tableParam1.params);
                                                  }}>
                                                绑定压力表
                                            </span>
                                        <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                              onClick={()=>{this.queryRelationData(3);}}>
                                                刷新
                                            </span>
                                    </div>
                                    <div style={{width:'100%',height:'380px'}}>
                                        <Table
                                            columns={this.state.tableData_header_2}
                                            dataSource={this.state.tableParam_data_2}
                                            bordered
                                            loading={this.state.tableData_loading}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                total:this.state.tableParam_data_2.length,
                                                pageSize:4,
                                                showTotal: (count = this.state.tableParam_data_2.length)=>{
                                                    return '共'+this.state.tableParam_data_2.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['4']
                                            }}
                                        />
                                    </div>
                                </TabPane>
                                {/*监控*/}
                                <TabPane tab="监控" key="4" style={{width: '100%',height: '100%'}}>
                                    <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam1 = this.state.tableParam_1;
                                                      tableParam1.params.devType=10209;
                                                      this.setState({
                                                          tableParam_1:tableParam1,
                                                          visible_title:'绑定监控',
                                                          visible_2:true,
                                                          visible_2_type:4,
                                                      });
                                                      await this.setTableHead(4); // 弹窗表格表头切换
                                                      this.table.refreshTable(tableParam1.params);
                                                  }}>
                                                绑定监控
                                            </span>
                                        <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                              onClick={()=>{this.queryRelationData(4);}}>
                                                刷新
                                            </span>
                                    </div>
                                    <div style={{width:'100%',height:'380px'}}>
                                        <Table
                                            columns={this.state.tableData_header_3}
                                            dataSource={this.state.tableParam_data_2}
                                            bordered
                                            loading={this.state.tableData_loading}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                total:this.state.tableParam_data_2.length,
                                                pageSize:4,
                                                showTotal: (count = this.state.tableParam_data_2.length)=>{
                                                    return '共'+this.state.tableParam_data_2.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['4']
                                            }}
                                        />
                                    </div>
                                </TabPane>
                                {/*阀井------------------------------*/}
                                <TabPane tab="阀井" key="5" style={{width: '100%',height: '100%'}}>
                                    <div style={{width:'100%',height:'22px',paddingLeft:'20px',paddingRight:'20px'}}>
                                            <span style={{float:'left',cursor: 'pointer',color:'#1890ff'}}
                                                  onClick={async ()=>{
                                                      var tableParam1 = this.state.tableParam_1;
                                                      tableParam1.params.devType=10200;
                                                      this.setState({
                                                          tableParam_1:tableParam1,
                                                          visible_title:'绑定阀井',
                                                          visible_2:true,
                                                          visible_2_type:5,
                                                      });
                                                      await this.setTableHead(5); // 弹窗表格表头切换
                                                      this.table.refreshTable(tableParam1.params);
                                                  }}>
                                                绑定阀井
                                            </span>
                                        <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                              onClick={()=>{
                                                  this.queryRelationData(5);
                                              }}>
                                                刷新
                                            </span>
                                    </div>
                                    <div style={{width:'100%',height:'380px'}}>
                                        <Table
                                            columns={this.state.tableData_header_4}
                                            dataSource={this.state.tableParam_data_2}
                                            bordered
                                            loading={this.state.tableData_loading}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                total:this.state.tableParam_data_2.length,
                                                pageSize:4,
                                                showTotal: (count = this.state.tableParam_data_2.length)=>{
                                                    return '共'+this.state.tableParam_data_2.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['4']
                                            }}
                                        />
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>

                    </div>
                </div>
            </div>




{/*弹窗-地图坐标-----------*/}
                <Modal
                    title={this.state.visible_title}
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_1}
                    onOk={ async () => {
                        this.forms1.setFieldsValue('position',document.getElementById('latNum').value??null);   // 街道
                        this.setState({visible_1:false});
                    }}           	// 点击确定
                    onCancel={() =>{
                        this.setState({visible_1:false});
                    }}	// 点击取消
                    width={1400}
                    centered
                >
                    <div style={{width:'100%',height:'45px'}}>

                        <div style={{float:'left',width:'40%'}}>
                            <span style={{fontSize:'15px'}}>详细地址：</span>
                            <input disabled={true} id={"latNum"} type={'text'} style={{border:"#d9d9d9 1px solid",width:'85%',height:'30px'}}/>
                        </div>
                        <div style={{float:'left',width:'12%'}}>
                            <span style={{fontSize:'15px'}}>纬度：</span>
                            <input disabled={true} id={"lat"} type={'text'} style={{border:"#d9d9d9 1px solid",width:'115px',height:'30px'}}/>
                        </div>
                        <div style={{float:'left',width:'12%'}}>
                            <span style={{fontSize:'15px'}}>经度：</span>
                            <input disabled={true} id={"lng"} type={'text'} style={{border:"#d9d9d9 1px solid",width:'115px',height:'30px'}}/>
                        </div>
                    </div>
                    <div style={{width:'100%',height:'550px'}}>
                        <Map
                            ref={e => this.maps = e}
                            zoom={12}// 初始画面层级this.state.showMap 15
                            center={this.state.showMap}
                            onClick={async (a,b,)=>{
                                AMap.plugin("AMap.Geocoder", () => {
                                    var geoCoder = new AMap.Geocoder({
                                        city: "010", //城市设为北京，默认：“全国”
                                        radius: 1000, //范围，默认：500,
                                        extensions: 'all'
                                    });
                                    geoCoder.getAddress(new AMap.LngLat(a.lnglat.lng, a.lnglat.lat), function(status, result) {
                                        if (status === 'complete'&&result.regeocode) {
                                            document.getElementById('latNum').value = result.regeocode?.formattedAddress??null;
                                        }else{console.error('根据经纬度查询地址失败');}
                                    });
                                });
                                document.getElementById('lat').value = a?.lnglat?.lat;//维度
                                document.getElementById('lng').value = a?.lnglat?.lng;//经度
                                this.setState({longitude:a?.lnglat?.lng, latitude:a?.lnglat?.lat})
                            }}
                        >
                            <Marker
                                title={'标点'}
                                position={new AMap.LngLat(this.state?.longitude, this.state?.latitude)}
                                label={{content: '标点', direction: 'bottom'}}
                            />
                        </Map>
                    </div>
                </Modal>


{/*弹窗-绑定设备-----------*/}
                <Modal
                    title={this.state.visible_title}
                    maskClosable={false}
                    style={{'background':'#bd37ad'}}
                    visible={this.state.visible_2}
                    onOk={ async () => {
                        this.setState({visible_2:false});
                    }}           	// 点击确定
                    onCancel={ async  () =>{
                        this.setState({visible_2:false});
                    }}	// 点击取消
                    width={1400}
                    centered
                >
                    <div style={{height:'100%',backgroundColor:"#fff"}}>
                        <div style={{width:'100%',paddingLeft:'10px',paddingRight:'20px'}}>
                            <div style={{float:'left',
                                // display:this.state.visible_2_type===2?null:'none'
                            }}>
                                <div style={{width:'300px',float:'left'}}>
                                    <FormsTwo
                                        ref={e => this.forms2 = e}
                                        formParamenter={this.state.form_2}
                                        data={this.state.form_2_data}
                                    />
                                </div>
                                <div style={{width:'100px',float:'left'}}>
                                    <Button type="primary"
                                            onClick={ async ()=>{
                                                var params = await this.forms2.onFinishNotReset();
                                                var tableParam1 = this.state.tableParam_1;
                                                tableParam1.params=Object.assign({},tableParam1.params,params);
                                                this.table.refreshTable( tableParam1.params );
                                            }}
                                    >
                                        查询
                                    </Button>
                                </div>
                            </div>

                            <span style={{float:'right',cursor: 'pointer',color:'#1890ff'}}
                                  onClick={()=>{this.table.refreshTable(this.state.tableParam_1.params);}}
                            >
                                刷新
                            </span>
                        </div>
                        <div style={{width:'100%',height:'500px',overflowY: 'auto'}}>
                            <TableComponents
                                paramenter={this.state.tableParam_1}
                                setData = {this.state.visible_2_type===2?pageLikeMpName:getUnboundValveAlarm}
                                setTableValue={this.setTableValue_1}
                                ref={e => this.table = e}
                            >
                            </TableComponents>
                        </div>
                    </div>
                </Modal>

            </Layout>
        )
    }
}
