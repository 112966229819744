import React, { Component } from 'react'
import {Layout, Button, Popconfirm, message, } from 'antd'
import {getPageMpAddress} from '../../../services/customer'
import { NavLink } from "react-router-dom"
import './InstallationInfoNew.less'
import {ArrowLeftOutlined} from '@ant-design/icons';
import {funTraceInfo} from '../../../services/common'
import {getLocationById,removeDev} from '../../../services/customer'
import TableComponents from "../../../components/common/tables/TableComponentsNew";
// import {InstallationMap} from '../businessNew/InstallationMap'
// //工商户详情页 - 安装点
export default class InstallationInfoNew extends Component {
    state = {
        formsTwo_data:{
            customerId:     this.props.location?.state?.customerId?    this.props.location?.state?.customerId:'-',// 用户id
            address:        this.props.location?.state?.address?       this.props.location?.state?.address:'-',// 地址
            customerName:   this.props.location?.state.customerName?  this.props.location?.state?.customerName:'-',   // 客户名称
            createDate:'-',     // 创建时间
            mpName:         this.props.location?.state?.rowData.mpName?         this.props.location?.state?.rowData?.mpName:'-',         // 联系人
            principalPhone: this.props.location?.state?.rowData.principalPhone? this.props.location?.state?.rowData?.principalPhone:'-'  // 电话
        },
        tableParamenter: {
            manageName: "安装点",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                name: '安装点名称',
                address: '安装点地址',
                state: '安装点状态',
                devNumber:'已安装设备类型',
                devTotal: '设备总数',
                edit: '操作',
            },
            params: {
                customerId:this.props.location?.state?.rowData?.customerId,
                page:1,
                size:10,
                locationType:'客户'
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
    };
    // 获取用户详情
    getPageMpAddress=()=>{
        var params= {page: 1, size: 10, address: '', customerId: this.props.location?.state?.rowData?.id};
        getPageMpAddress(params).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    var formsTwoData = this.state.formsTwo_data;
                    formsTwoData=response.data.data[0];
                    this.setState({formsTwo_data:formsTwoData})
                }
            }
        )
    };
    componentDidMount() {}

    // 注销设备
    removeDev=(value)=>{
        console.log(value);
        removeDev({locationId:value.id}).then(
            response=>{
                if(response?.data?.data){
                    this.table.refreshTable(this.state.tableParamenter.params);
                    funTraceInfo("客户管理", "设备注销", null,value);
                    return message.info("注销成功")
                }else {
                    this.table.refreshTable(this.state.tableParamenter.params);
                    return message.info("注销失败")
                }

            }

        )
    };

    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return <>
                    <div style={{ float: 'left'}}>

                        <NavLink
                            to={{pathname:"../customer/devInstallationNew",
                                state:{
                                    formsTwo:this.state?.formsTwo_data,
                                    rowData:rowData
                                }
                            }}
                            >
                            安装
                        </NavLink>

                    </div>
                    <NavLink
                        style={{paddingLeft:'15px', float: 'left'}}
                        to={{pathname:"../customer/unloadDevNew",
                            state:{
                                formsTwo:this.state?.formsTwo_data,
                                rowData:rowData,
                                juder:true
                            }
                        }}
                    >
                        拆卸
                    </NavLink>

                    <NavLink style={{paddingLeft:'15px', float: 'left'}}
                        to={{pathname:"../customer/InstallationMapNew",
                            state:{
                                formsTwo:this.state?.formsTwo_data,
                                rowData:rowData,
                                juder:true
                            }
                        }}
                    >
                        修改
                    </NavLink>

                    <Popconfirm title="是否注销该设备?"
                                onConfirm={()=>{
                                    if(rowData.state==='安装'){
                                        return message.info('安装点已安装设备，无法注销');
                                    }
                                    if(rowData.devTotal>0){
                                        return message.info('安装点已安装设备，无法注销');
                                    }
                                    this.removeDev(rowData);
                                }}
                        // onCancel={cancel}
                                okText="Yes" cancelText="No">
                        <a style={{paddingLeft:'15px', float: 'left'}}>注销</a>
                    </Popconfirm>


                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={'InstallationInfo'}>
                    {/**/}
                    {/*<div className={'formText_div'}>*/}
                    {/*    <div className={'formText_1_installInner'}>*/}
                    {/*        <ul>*/}
                    {/*            <li>客户编号：<span>{this.state?.formsTwo_data?.customerId}</span></li>*/}
                    {/*            <li>详细地址：<span>{this.state?.formsTwo_data?.address}</span></li>*/}

                    {/*            <li>客户名称：<span>{this.state?.formsTwo_data?.customerName}</span></li>*/}
                    {/*            <li>创建时间：<span>-</span></li>*/}

                    {/*            <li>联系人：<span>{this.state?.formsTwo_data?.mpName}</span></li>*/}
                    {/*            <li>联系电话：<span>{this.state?.formsTwo_data?.principalPhone}</span></li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/**/}
                    <div style={{width:'100%'}}>
                        <div style={{width:'100%',marginLeft:'10px'}}>
                            <div>

                                <NavLink
                                    to={{pathname:"../customer/InstallationMapNew",
                                        state:{
                                            formsTwo:this.state?.formsTwo_data,
                                            rowData:this.props.location?.state?.rowData
                                        }
                                    }}
                                >
                                    新建安装点
                                </NavLink>

                                <Button type={'primary'} style={{marginLeft:"89.5%"}}
                                        onClick={()=>{
                                            this.table.refreshTable(this.state.tableParamenter.params);
                                        }}
                                >刷新</Button>
                            </div>

                        </div>
                        <div>
                            <TableComponents
                                paramenter={this.state.tableParamenter}
                                setData = {getLocationById}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            >
                            </TableComponents>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
