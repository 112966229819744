// 气瓶管理页面
import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Alert, Input, InputNumber, DatePicker} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'

import {funTraceInfo} from '../../../services/common'
import {getCylinderList,insertCylinder,getOneCylinder} from '../../../services/verifvication'

export default class PointManagement extends Component {

    state = {

    	// 添加气瓶参数
    	addPoint:{
			"deviceName": "",	// 设备名称
			"model": "",		// 型号规格
			"manufacturers": "",// 制造厂家
			"calibrationMechanism": "",// 检定或校准机构
			"startNumber": "",	// 出厂编号
			"nearFuture": "",	// 末次检定或校准日期
			"measuringRange": "",	// 测量范围
			"uncertainValue": "",	// 误差值
			"timings": "",		// 检定周期或复校间隔
			"certificate": "",	// 证书号
			"remark": ""		// 备注、短名
		},




		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				deviceName: '设备名称',
				model: '型号',
				measuringRange: '测量范围',
				uncertainValue: '误差值',
				manufacturers: '制造厂家',
				calibrationMechanism: '检定或校准机构',
				startNumber: '出厂编号',
				timings: '检定周期或复校间隔',
				nearFuture: '末次检定或校准日期',
				certificate: '证书号',
				detonationPoint:'低爆点',
				remark: '短名',
				edit: '操作',

			},
			// 弹出框
			headers: {
				deviceName: '设备名称',
				model: '型号',
				measuringRange: '测量范围',
				uncertainValue: '误差值',
				manufacturers: '制造厂家',
				calibrationMechanism: '检定或校准机构',
				startNumber: '出厂编号',
				timings: '检定周期或复校间隔',
				nearFuture: '末次检定或校准日期',
				certificate: '证书号',
				detonationPoint:'爆点',
				remark: '短名'
			},
			params: {
				type:'气瓶管理',
				page:1,
				size:10,
			},
			// 是否绑定左侧区域
			bindArea: true
		},
		CylinderParamenter: {
			modal: true,
			number: 2,
			labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'deviceName',
					str: '设备名称',
					rules: TYPE_NAME.LABLE_NOT_NULL_50,
				},
				{
					variableName: 'model',
					str: '型号规格',
					rules: TYPE_NAME.LABLE_NOT_NULL_50,

				},
				{
					variableName: 'manufacturers',
					str: '制造厂家',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'startNumber',
					str: '出厂编号',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'calibrationMechanism',
					str: '检定或校准机构',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},

				{
					variableName: 'nearFuture',
					str: '末次检定或校准日期',
					rules: TYPE_NAME.REQDATE_PICKER
				},
				{
					variableName: 'measuringRange',
					str: '测量范围',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'uncertainValue',
					str: '误差值',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'timings',
					str: '检定周期或复校间隔',
					rules: TYPE_NAME.ID_NUMBER
				},
				{
					variableName: 'certificate',
					str: '证书号',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'detonationPoint',
					str: '低爆点',
					rules: TYPE_NAME.NUMBER
				},
				{
					variableName: 'remark',
					str: '短名',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
			]
		},

		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'deviceName',
					str: '设备名称'
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'model',
					str: '型号'
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'remark',
					str: '短名'
				},

			]
		},
		derailingSwitch:false, // 小窗口开关器-1
		derailingSwitch_1:false,
		qpId:'',
		modTitle:'添加检测设备',
	};

	// 表格格式
	setTableValue = (value,text,rowData) => {

		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => {
					console.log(rowData);
					this.editDevStatus(true,rowData)
				}}>
						<u>{'修改'}</u>
				</div>
				</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	// 页面初始启动
	componentDidMount(){

	}

	devDataSwitch=async(value)=>{
		let values = await this.forms.onFinishNotReset();	// 获取表单数据
		this.moda1VisibleStatus(value);	// 窗口开关-关
		this.addDevData(values);		// 添加气瓶设备

	};


	addDevDataNew = async ()=>{
		console.log("添加气瓶设备");
		let devData = this.state.addPoint;
		console.log(devData)
		if(
			devData?.deviceName | devData?.model
			& devData?.manufacturers
			& devData?.calibrationMechanism
			& devData?.startNumber
			& devData?.measuringRange
			& devData?.uncertainValue
			& devData?.timings
			& devData?.certificate
			& devData?.nearFuture
		){}else{
			message.info('请填写必填项');
			return this.setState({
				deviceNameStatus:devData?.deviceName?'':'error',
				modelStatus:devData?.model?'':'error',
				manufacturersStatus:devData?.manufacturers?'':'error',
				calibrationMechanismStatus:devData?.calibrationMechanism?'':'error',
				startNumberStatus:devData?.startNumber?'':'error',
				nearFutureStatus: devData?.nearFuture ?'':'error',
				measuringRangeStatus:devData?.measuringRange?'':'error',
				uncertainValueStatus:devData?.uncertainValue?'':'error',
				timingsStatus:devData?.timings?'':'error',
				certificateStatus:devData?.certificate?'':'error',
			})
		}
		if(devData?.nearFuture){

		}
		if(this.state.qpId){
			devData.id=this.state.qpId
		}

		// insertCylinder(devData).then(data=>{
		// console.log(data);
		// if(data.data?.data){
		// 	funTraceInfo("报警器检定管理", "添加气瓶设备", null,devData)
		// 	message.config("添加成功");
		// 	this.setState({derailingSwitch:false,qpId:''})
		// }else{
		// 	this.setState({derailingSwitch:false,qpId:''})
		// 	message.config('测试失败');
		// }
		// this.table.refreshTable();// 刷新表格
		// });
	};
	// 添加气瓶设备
	addDevData=  async ()=>{
		console.log("添加气瓶设备");
		let devData = await this.forms.onFinishNotReset();
		console.log(devData);
		if(!devData) return null;
		if(this.state.qpId){
			devData.id=this.state.qpId
		}
		devData.nearFuture=moment(devData.nearFuture).format('YYYY-MM-DDTHH:mm:ss');
		insertCylinder(devData).then(data=>{
			console.log(data);
			if(data.data?.data){
				funTraceInfo("报警器检定管理", "添加气瓶设备", null,devData)
				message.config("添加成功");
				this.setState({derailingSwitch:false,qpId:''});
				this.table.refreshTable();// 刷新表格
			}else{
				// this.setState({derailingSwitch:false,qpId:''});
				return message.info(data.data.responseInfo.description);
			}
		});
	};
	// 修改气瓶信息
	editDevStatus=(derailingSwitch,rowData)=>{
		this.setState({
			// addPoint:rowData,
			derailingSwitch:derailingSwitch,
			qpId:rowData.id,
			modTitle:'修改气瓶信息'
		});
		this.state.bjqId=rowData.id;
		getOneCylinder({id: rowData.id}).then(
			response => {
				const {data} = response.data;
				data.nearFuture=moment(data.nearFuture);
				this.setState({data: data});
				message.success('查询成功');
				this.forms.onModalClose();
			}
		)
    };

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params;
		let formParams=this.table.getParams()
		Object.assign(params,value);
		params.page=formParams.page
		params.size=formParams.size
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		this.setState({tableParamenter});
		await this.table.refreshTable(params);

	};
		//添加气瓶窗口显示
		moda1VisibleStatus= async (moda1Visible)=>{
			await this.setState({
				derailingSwitch:moda1Visible,
				data:{},
				modTitle:'添加气瓶信息',
				qpId:''});
			await this.forms.onModalClose()
		};


	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		this.setState({params:params});
		this.table.refreshTable();
	   // this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>气瓶管理</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<div className={'topBtnBox'}>
					<span onClick={async () => {this.moda1VisibleStatus(true)}}><PlusSquareOutlined />添加气瓶</span>
					{/*<NavLink to="/verifvication/TemporaryGasCylinderList"><span><ExportOutlined />我的气瓶</span></NavLink>*/}
				</div>
				<div style={{width:'100%',height:'640px',overflowX: 'auto',background:'#fff'}}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getCylinderList}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
					</TableComponents>
				</div>

				{/*添加设备小窗口*/}
				<Modal
					maskClosable={false}
					title={this.state.modTitle}
					style={{ top: 0 }}
					visible={this.state.derailingSwitch}
					okText="确定"
					// 点击确定
					onOk={() => this.addDevData()}
					// 点击取消
					onCancel={ async () => {
						await this.setState({data: null});
						await this.forms.onModalClose();// 清空表单数据
						this.moda1VisibleStatus(false)
					}}
					width={1200}
					centered
				>
					<div>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.CylinderParamenter}
							data={this.state.data}
						/>
					</div>
				</Modal>
				{/*---------------------------新添加气瓶---暂不用------------------------------*/}
				<Modal
					maskClosable={false}
					title={this.state.modTitle}
					style={{ top: 0 }}
					visible={this.state.derailingSwitch_1}
					okText="确定"
					onOk={() => {
						this.addDevDataNew();
						// this.setState({derailingSwitch_1:false})
					}}           	// 点击确定
					onCancel={() => this.setState({derailingSwitch_1:false})}	// 点击取消
					width={1200}
					centered
				>
					<div style={{height:'250px'}}>

						{/*第1行*/}
						<div style={{width:'100%',height:'40px'}}>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'35%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>设备名称：</span>
								</div>
								<div style={{width:'65%',float:'left'}}>
									<Input
										value={ this.state.addPoint.deviceName}
										status={this.state?.deviceNameStatus}
										placeholder={'设备名称'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.deviceName=text.target.value;
											this.setState({addPoint,deviceNameStatus:''});
										}}
									/>
								</div>
							</div>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'40%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>设备型号：</span>
								</div>
								<div style={{width:'60%',float:'left'}}>
									<Input
										value={ this.state.addPoint.model}
										status={this.state?.modelStatus}
										placeholder={'请输入设备型号'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.model=text.target.value;
											this.setState({addPoint,modelStatus:''});
										}}
									/>
								</div>
							</div>
						</div>
						{/*第2行*/}
						<div style={{width:'100%',height:'40px'}}>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'35%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>出厂编号：</span>
								</div>
								<div style={{width:'65%',float:'left'}}>
									<Input
										value={ this.state.addPoint.startNumber}
										status={this.state?.startNumberStatus}
										placeholder={'请输入出厂编号'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.startNumber=text.target.value;
											this.setState({addPoint,startNumberStatus:''});
										}}
									/>
								</div>
							</div>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'40%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>制造厂家：</span>
								</div>
								<div style={{width:'60%',float:'left'}}>
									<Input
										value={ this.state.addPoint.manufacturers}
										status={this.state?.manufacturersStatus}
										placeholder={'请输入制造厂家'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.manufacturers=text.target.value;
											this.setState({addPoint,manufacturersStatus:''});
										}}
									/>
								</div>
							</div>
						</div>

						{/*第3行*/}
						<div style={{width:'100%',height:'40px'}}>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'35%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>测量范围：</span>
								</div>
								<div style={{width:'65%',float:'left'}}>
									<Input
										value={ this.state.addPoint.measuringRange}
										status={this.state?.measuringRangeStatus}
										placeholder={'请输入测量范围'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.measuringRange=text.target.value;
											this.setState({addPoint,measuringRangeStatus:''});
										}}
									/>
								</div>
							</div>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'40%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>误差值：</span>
								</div>
								<div style={{width:'60%',float:'left'}}>
									<Input
										value={ this.state.addPoint.uncertainValue}
										status={this.state?.uncertainValueStatus}
										placeholder={'请输入误差值'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.uncertainValue=text.target.value;
											this.setState({addPoint,uncertainValueStatus:''});
										}}
									/>
								</div>
							</div>

						</div>
						{/*第4行*/}
						<div style={{width:'100%',height:'40px'}}>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'35%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>检定或校准机构：</span>
								</div>
								<div style={{width:'65%',float:'left'}}>
									<Input
										value={ this.state.addPoint.calibrationMechanism}
										status={this.state?.calibrationMechanismStatus}
										placeholder={'请输入检定或校准机构'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.calibrationMechanism=text.target.value;
											this.setState({addPoint,calibrationMechanismStatus:''});
										}}
									/>
								</div>
							</div>
							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'40%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>末次检定或校准日期：</span>
								</div>
								<div style={{width:'60%',float:'left'}}>
									<DatePicker
										style={{width:'100%'}}
										format={'YYYY-MM-DD'}
										value={this.state.addPoint.nearFuture?moment(this.state.addPoint.nearFuture):null}
										status={this.state?.nearFutureStatus}
										placeholder={'请输入末次检定或校准日期'}
										onChange={(title,v)=>{
											var addPoint = this.state.addPoint;
											addPoint.nearFuture=v;
											this.setState({addPoint,nearFutureStatus:''});
										}}
									/>
								</div>
							</div>
							<div style={{width:'30%',height:'100%',float:'left'}}>
								<div style={{width:'50%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>检定周期或复校间隔：</span>
								</div>
								<div style={{width:'30%',float:'left'}}>
									<InputNumber
										min={0}
										value={this.state.addPoint.timings}
										status={this.state?.timingsStatus}
										placeholder={'请输入检定周期或复校间隔'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.timings=text;
											this.setState({addPoint,timingsStatus:''});
										}}
									/>
								</div>
							</div>
						</div>
						{/*第5行*/}
						<div style={{width:'100%',height:'40px'}}>

							<div style={{width:'35%',height:'100%',float:'left'}}>
								<div style={{width:'35%',textAlign: 'right',float:'left'}}>
									<span style={{color:'red'}}>*</span><span>证书号：</span>
								</div>
								<div style={{width:'65%',float:'left'}}>
									<Input
										value={this.state.addPoint.certificate}
										status={this.state?.certificateStatus}
										placeholder={'请输入证书号'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.certificate=text.target.value;
											this.setState({addPoint,certificateStatus:''});
										}}
									/>
								</div>
							</div>
						</div>
						{/*第6行*/}
						<div style={{width:'100%',height:'40px'}}>

							<div style={{width:'40%',height:'100%',float:'left'}}>
								<div style={{width:'30.5%',textAlign: 'right',float:'left'}}>
									<span>备注：</span>
								</div>
								<div style={{width:'57%',float:'left'}}>
									<Input
										value={this.state.addPoint.remark}
										// status={this.state?.remarkStatus}
										placeholder={'请输入备注'}
										onChange={(text)=>{
											var addPoint = this.state.addPoint;
											addPoint.remark=text.target.value;
											this.setState({addPoint,remarkStatus:''});
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</Modal>
            </Layout>
        )
    }
}
