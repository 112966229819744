import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, message, Tag, Popconfirm, Radio} from 'antd'
import {TYPE_NAME} from '../../components/Const'
import TableComponents from '../../components/common/tables/TableComponents'
import {queryResidentialCommunity, removeResidentialCommunity,} from '../../services/community'

// 小区管理
export default class community extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "小区管理",
            // 是否绑定左侧区域
            bindArea: true,
            // 表格行是否可选
            rowSelection: true,
            // // 获取表格参数数据
            localStorageName: 'community',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '小区管理',
            // exportUrl: "/web/customerExportCtrl/exportCustomer",

            header: {
                name: '小区名称',
                administrativeRegion: '行政区域',
                propertyContactPersonTel: '物业联系电话',
                propertyContactPerson: '物业联系人',
                responsiblePerson: '小区燃气负责人',
                createTime: '创建时间',
                updateTime: '最后修改时间',
                belongingBuilding: '楼栋数',
                userNum: '户数',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'name',
                        str: '小区名称',
                        initData: JSON.parse(localStorage.getItem('community'))?.name
                    },
                    {
                        type: 'select',
                        variableName: 'status',
                        str: '供气状态',
                        data: [
                            {key: "正常", value: '正常'},
                            {key: '停气', value: '停气'},
                            {key: '检修', value: '检修'},
                        ]
                    },
                ]
            },
        },
    };
    componentWillMount() {

    }

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'name':
                return (
                    <NavLink to={{pathname: "/community/addOrModifyCommunity",state: {
                            rowData:rowData,
                            selectWidth: false
                        }}}>
                        <div className={'table_overflow'} title={text}>
                            <u>{text}</u>
                        </div>
                    </NavLink>
                );

            case 'edit':

                return (
                    <div>

                        <div style={{paddingLeft:'10px',float: 'left'}}>
                            <Popconfirm title="是否注销小区?"
                                        onConfirm={()=>{this.removeResidentialCommunity(rowData.id)}}
                                        // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>注销小区</a>
                            </Popconfirm>
                        </div>
                    </div>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 删除小区
    removeResidentialCommunity=(id)=>{
        if(!id){return false;}
        var params={idList:[id]};
        removeResidentialCommunity(params).then(
            response => {
                if(response?.data?.data){
                    message.info('注销成功');
                }else{
                    message.info('注销失败');
                }
                this.table.refreshTable();
            }
        )
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'id'}
                    paramenter={this.state.tableParamenter}
                    setData={queryResidentialCommunity}
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addClient" buttonStyle="solid">
                        <NavLink
                            to={{
                                pathname:"/community/addOrModifyCommunity",
                                state:{selectWidth: true}
                            }}
                        >
                            <Radio.Button value="addClient">新增小区</Radio.Button>
                        </NavLink>
                        <Radio.Button value="updateClient" onClick={()=>{
                            let rowData = this.table.getSelectChange();
                            if(rowData.length < 1) return message.error('未选择数据');

                            this.props.history.push({
                                pathname: '/community/addOrModifyCommunity',
                                state: {
                                    rowData:rowData[0],
                                    selectWidth: true
                                }
                            })

                        }}>修改小区</Radio.Button>
                    </Radio.Group>
                </TableComponents>
            </Layout>
        )
    }
}
