import React, { Component } from 'react'
import {Button, Image, Layout, message, Modal, Popconfirm, Table, Upload} from 'antd'
import {
    addSafetyOfficer,
    getSafetyOfficerByCustomerId,
    removeSafetyOfficer,
    updateSafetyOfficer
} from "../../../../services/customer";
import FormsTwo from "../../../../components/common/forms/FormsTwo";
import { LoadingOutlined,ReloadOutlined} from '@ant-design/icons'
import {TYPE_NAME} from "../../../../components/Const";
export default class safetyOfficer extends Component {
    state = {
        // 表格
        table_columns:[
            {title: '安全负责人',dataIndex: 'name', key: 'name',   align:'center'},
            {title: '电话',dataIndex: 'phone', key: 'phone',      align:'center'},
            {title: '类型',dataIndex: 'type', key: 'type',        align:'center'},
            {title: '是否有资质',dataIndex: 'qualifications', key: 'qualifications',align:'center',width:'120px',},
            {title: '操作',dataIndex: 'edit', key: 'edit',align:'center',width:'120px',
                render: (text, rowData,index) => {
                    return<>
                        <div title={'修改'} style={{paddingLeft:'15px', float: 'left'}}
                             onClick={async ()=>{
                                 await this.setState({
                                     visible_1:true,	// 弹窗开关
                                     table_index:index,// 行级
                                     addOrUpModel:false,// 新增或修改
                                     fileList:rowData?.fileList??[],// 新增图片
                                     table_rowData:rowData,
                                     visible_1_juder:rowData?.id?'1':'0'
                                 });
                                 await this.forms_1.setFieldsValues(rowData);// 表单赋值
                        }}><a>修改</a></div>
                        <div  title={'删除'} style={{paddingLeft:'15px', float: 'left'}} onClick={()=>{this.deleteSafetyOfficer(rowData)}}><a>删除</a></div>
                    </>
                }
            },
        ],
        table_loading:false,// 表格加载
        table_data:[],      // 弹框表格数据
        table_param:{
            total:0,
            page:1,
            size:5,

        },
        form_modal_1:{
            modal: true,
            number: 1,
            labelCol: 8,
            parameter: [
                {variableName: 'name', str: '负责人姓名', rules: TYPE_NAME.INPUT},
                {variableName: 'phone', str: '联系电话', rules: TYPE_NAME.PHONE_NUM},
                {variableName: 'type', str: '类型', rules: TYPE_NAME.REQ_SELECT,
                    data: [{key: '负责人', value: '负责人'}, {key: '管理员', value: '管理员'}]
                },
            ]
        },
        form_modal_1_data:null,


        visible_1:false,// 弹窗开关
        visible_1_juder:'0',//0新增 1修改
        selectMode:this.props?.rowData?.id?1:0,// 0新增，1修改

        refresh:false,
        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList:[],        // 图片
    };
// ===========================
    componentDidMount(): void {
        console.log('this.props?.rowData');
        console.log(this.props?.rowData);
        // 0 新增客户，1修改客户
        if(this.props?.rowData?.customerId){
            this.getSafetyOfficerByCustomerId();// 查询安全员列表
        }
    }

    // 查询安全员列表
    getSafetyOfficerByCustomerId=()=>{
        this.setState({table_loading:true,refresh:!this.state.refresh});
        var params={
            "id": this.props?.rowData?.customerId,
            "page": 1,
            "size": 5
        };
        getSafetyOfficerByCustomerId(params).then(
            response => {
                if(response?.data?.data){
                    console.log('response?.data?.data');
                    console.log(response);
                    var tableData = [...this.state.table_data];
                    tableData=[];
                    var tableParam = this.state.table_param;
                    tableData = response?.data?.data;
                    tableParam.total = response.data.pageInfo.total;
                    this.setState({table_data:tableData,table_param:tableParam})
                }
                this.setState({table_loading:false,refresh:!this.state.refresh})
            }
        )
    };

    // 新增安全员
    addSafetyOfficer=async ()=>{
        var formData1 = await this.forms_1.onFinishNotReset();
        if(!formData1) return false;
        formData1.path=[];
        if(this.state.fileList.length!==0){
            this.state.fileList.map(one=>{formData1.path.push(one.response)});
        }else{
            formData1.path=this.state.table_rowData?.path??null;
        }
        formData1.customerId=this.props?.rowData?.customerId;
        addSafetyOfficer(formData1).then(
            response=>{
                if(response?.data?.data){
                    this.getSafetyOfficerByCustomerId();// 查询安全员列表
                    message.info('新增成功')
                }else{
                    message.info('新增失败')
                }
                this.setState({visible_1:false})
            }
        );
    };
    // 修改安全员信息
    updateSafetyOfficer=async ()=>{
        var formData1 = await this.forms_1.onFinishNotReset();
        if(!formData1) return false;
        formData1.fileList=this.state.fileList;
        if(formData1?.fileList.length!==0){
            formData1.path=[];
            this.state.fileList.map(one=>{formData1.path.push(one.response)});
        }else {
            formData1.path=this.state.table_rowData.path
        }
        formData1.id=this.state.table_rowData.id;
        formData1.customerId=this.props?.rowData?.customerId;
        console.log(formData1);
        console.log('-----------------------');
        updateSafetyOfficer(formData1).then(
            response=>{
                if(response?.data?.data){
                    this.getSafetyOfficerByCustomerId();// 查询安全员列表
                    message.info('修改成功')
                }else{
                    message.info('修改失败')
                }
                this.setState({visible_1:false})
            }
        );
    };
    // 本地新增和修改
    localityAddAndUpSafety = async () =>{
        var formData1 = await this.forms_1.onFinishNotReset();
        if(!formData1) return false;
        formData1.path=[];
        // formData1.fileList=this.state.fileList;
        this.state.fileList.map(one=>{formData1.path.push(one.response)});
        var tableData = [...this.state.table_data];// 表格数据
        var {table_param,table_index,addOrUpModel} = this.state;    // 表格参数
        // 新增或修改
        if(addOrUpModel){
            console.log('新增');
            tableData.push(formData1);
            table_param.total = tableData.length;
        }else{
            console.log('修改');
            tableData[(table_param.page-1)*table_param.size+table_index] = formData1;
        }
        this.setState({table_data:tableData,table_param:table_param,visible_1:false})
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {}
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList:file.fileList});
    };




    // 删除操作
    deleteSafetyOfficer=(rowData,index)=>{
        var table_param = this.state.table_param;
        var tableData = [...this.state.table_data];
        if(rowData?.id){
            removeSafetyOfficer({id:rowData?.id}).then(
                response=>{
                    if(response?.data?.data){
                        message.info('删除成功');
                        this.getSafetyOfficerByCustomerId();// 查询安全员列表
                    }else{
                        message.info('删除失败');
                    }
                }
            )
        }else{
            tableData.splice((table_param.page-1)*table_param.size+index,1);
            table_param.total=tableData.length;
        }
        this.setState({table_data:tableData,table_param:table_param});
    };

    getSafetyOfficer=()=>{
        return this.state.table_data
    };

// ===========================
    render() {
        const uploadButton = (<div><div className="ant-upload-text">上传图片</div></div>);
        return (
            <Layout className={'item_body_number_back'}>
                <div style={{width:'100%',height:'100%',backgroundColor:'#fff',padding:'5px 10px 10px 10px'}}>
                    <div style={{width:'100%',height:'40px'}}>
                        <div style={{width:'120px',height:'100%',float:'left'}}>
                            <Button type={'primary'}
                                    onClick={async ()=>{
                                        await this.setState({
                                            visible_1:true,
                                            table_index:0,		 // 表格所选行
                                            addOrUpModel:true,	 // 新增或修改
                                            table_rowData:null,  // 表格数据
                                            fileList:[],		 // 图片
                                            visible_1_juder:'0', // 图片模式
                                        });
                                        this.forms_1.onModalClose();
                                    }}
                            >新增安全员</Button>
                        </div>
                        <div style={{width:'70px',height:'100%',float:'right',textAlign:'center',paddingTop:'8px'}}>
                            {this.state.refresh?<LoadingOutlined style={{color:'#1890ff'}}/> : <ReloadOutlined style={{color:'#1890ff'}}/>}
                            <a style={{fontSize:'16px'}}
                               onClick={()=>{
                                   this.getSafetyOfficerByCustomerId();
                               }}
                            >
                                刷新
                            </a>
                        </div>
                    </div>
                    <div  style={{width:'100%',height:'90%',backgroundColor:'#dff'}}>
                        <Table
                            bordered
                            loading={this.state.table_loading}
                            scroll={{ y: 600 }}
                            columns={this.state.table_columns}
                            dataSource={this.state.table_data}
                            pagination={false}
                        />
                    </div>
                </div>
{/*弹窗---------------------------------------------------------------------------------------------------------------*/}
                <Modal
                    title={"安全员"}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_1}
                    okText="确定"
                    onOk={ async () => {
                        // 模式变更
                        if(this.state.selectMode==='0'){
                            console.log(1);
                            this.localityAddAndUpSafety();// 本地新增和修改
                        }else{
                            console.log('修改模式');

                            if(this.state.table_rowData?.id){
                                console.log(2);
                                this.updateSafetyOfficer();	// 修改安全员信息
                            }else {
                                console.log(3);
                                this.addSafetyOfficer();	//	新增安全员
                            }
                        }
                    }}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={500}
                    centered
                >
                    <div style={{width:'90%'}}>
                        <FormsTwo
                            ref={e => this.forms_1 = e}
                            formParamenter={this.state.form_modal_1}
                            data={this.state.form_modal_1_data}/>
                    </div>
                    <div style={{display:this.state.visible_1_juder==='0'?null:'none'}} >
                        <Upload
                            action={this.state.fileUrl}
                            listType="picture-card"
                            name={"file"}
                            fileList={this.state.fileList}
                            onPreview={this.handlePreview}
                            onChange={this.onChangeUpload}
                        >
                            {this.state.fileList.length >= 4 ? null : uploadButton}
                        </Upload>
                    </div>

                    <div style={{display:this.state.visible_1_juder!=='0'?null:'none'}}>
                        <a onClick={()=>{this.setState({visible_1_juder:'0'})}}>是否修改</a>
                        {
                            this.state.table_rowData?.path?
                                this.state.table_rowData?.path.map(one=>{return<><Image width={105} src={one}/></>})
                                :
                                null
                        }

                    </div>
                </Modal>
            </Layout>
        )
    }
}
