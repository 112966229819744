// 证书列表管理
import React, { Component } from 'react'
import {Button, Cascader, Layout, message, Modal} from 'antd'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import moment from 'moment'

import {
    getCertificateList
} from '../../../services/verifvication'
import {Divider} from "antd/es";
var downIp =global.pubData.url;
export default class certificateTypeOneManagement extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "数据项管理",
            // 表格行是否可选
            // rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                certificateCode:'证书编号',
                certificateName:'证书名',
                applicant:'客户单位',
                lnstrumentName: '设备名',
                model: '型号',
                startNumber:'出厂编号',
                // verifierTime: '检定时间',
                auditStatus: '审批状态',
                checkedByHandwriting: '核验员',
                approvedByHandwriting: '审批员',
                edit: '操作',
            },
            params: {
                page:1,
                size:7,
            },
            // 是否绑定左侧区域
            bindArea: false
        },
        // 条件选择器参数
        conditionsParamenter: {
            parameter: [
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'certificateCode',
                    str: '证书编号'
                },
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'applicant',
                    str: '客户单位'
                },
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'lnstrumentName',
                    str: '设备名'
                },
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'startNumber',
                    str: '出厂编号'
                },
            ]
        },
        totalCount: 0,
        downloadVisible:false, //下载窗口开关器-1
        downloadId:'',
        previewVisible:false, //预览窗口开关器-1
        previewId:'',
        ApprovalVisible:false, //审批窗口开关器-1
        ApprovalId:'',
        ApprovalStatus:'',
        buttonJuder_1:'none',
        certificateDatas:[],   // 客户数据集-有详细的数据
        formSelect:[],      // 被选用户下拉框
    };
    componentDidMount(){
        console.log(this.state.params);
        var menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
        console.log(menuAuthories);
        menuAuthories.map(one=>{
            if(one.name==='报警器检定管理'){
                one.children.map(two=>{
                    if(two.name==='删除设备'){
                        this.setState({deleteDeviceDisplay:''})
                    }else if(two.name==='核验按钮'){
                        this.setState({buttonJuder_1: ''})
                    }
                });
            }
        });
    }
    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'auditStatus':
                return text === 0 ?
                    <span style={{color:'#E59722'}}>待审批</span>
                    :
                    text === 1 ?
                        <span style={{color:'#44A072'}}>通过</span>
                        :
                        <span style={{color:'#f83642'}}>未通过</span>;
            case 'edit':
                text=rowData.auditStatus;
                var juder=true;
                for(var i=0;i<this.state.formSelect.length;i++){
                    if(this.state.formSelect[i][0]===rowData.certificateCode){
                        juder=false;
                    }
                }
                return <>
                    <div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData?.id)}>
                        <u>{'下载'}</u>
                    </div>
                    <div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData?.id)}>
                        <u>{'预览'}</u>
                    </div>
                    {/*{text===0 ?<div className={'tableLink'}*/}
                    {/*                   title={'审批'}*/}
                    {/*                   onClick={() => this.ApprovalStatus(true,rowData?.certificateId)}*/}
                    {/*                   style={{display: this.state.buttonJuder_1}}*/}
                    {/*    >*/}
                    {/*        <u>{'审批'}</u>*/}
                    {/*    </div>*/}
                    {/*    :<div className={'tableNoLink'} title={'审批'} style={{display: this.state.buttonJuder_1}}>*/}
                    {/*        <u>{'审批'}</u>*/}
                    {/*    </div>*/}
                    {/*}*/}

                    {
                        juder?
                            <>
                                <div className={'tableLink'} title={'加入列表'} onClick={() =>this.addUserSelect(rowData)}>
                                    <u>{'加入列表'}</u>
                                </div>
                            </>
                            :<>
                                <div className={'tableLink'} title={'移除列表'} onClick={() =>this.deleteFormSelectDatas(rowData)}>
                                    <u style={{color:'red'}}>{'移除列表'}</u>
                                </div>
                            </>
                    }

                    {/*<div className={juder?'tableLink':'tableNoLink'} title={'加入列表'} onClick={() =>this.addUserSelect(rowData)}>*/}
                    {/*    <u>{'加入列表'}</u>*/}
                    {/*</div>*/}
                </>;
            case 'createTime':
                text=moment(text).format("YYYY-MM-DD HH:mm:ss");
                return (<span>{text}</span>);
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // 添加进入客户下拉框
    addUserSelect=(rowData)=>{
        console.log('添加进入证书列表');
        // 表数据
        var formSelect = [...this.state.formSelect];
        // 里数据
        var certificateDatas = this.state.certificateDatas;
        if(formSelect.length>54){
            return message.info('超出最大加载量');
        }
        certificateDatas.push(rowData);

        formSelect.push([rowData.certificateCode]);
        this.setState({formSelect:formSelect,cascaderStatus_1:'',certificateDatas:certificateDatas});
    };

    //下载窗口
    downloadStatus=(downloadStatus,id)=>{
        this.setState({downloadVisible:downloadStatus,downloadId:id});
    };
    download=(downloadStatus,id)=>{
        this.setState({downloadVisible:downloadStatus});
        console.log(this.state.downloadId)
    };

    // params.forEach(str => link += `'areaIds'=${str}`);
    downloadOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadNoBlankAndStampByCertificateId?id='+alarmId)
    };
    downloadOpen_1=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadNoBlankByCertificateId?id='+alarmId)
    };
    downloadOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/downloadBlankByCertificateId')
    };
    //http://hdstcloud.com:9000/gateway/verification/exceldownload/downloadTagsByAlarmId?
    downloadOpen_allForm=()=>{
        var certificateDatas = this.state.certificateDatas;

        var certificateId='';
        certificateDatas.forEach(one=>{
            console.log(one);
            certificateId+=one.id+','
        });

        window.open(downIp+'/verification/exceldownload/downloadTagsByAlarmId?ids='+certificateId)
    };


    //预览窗口
    previewStatus=(previewStatus,id)=>{
        console.log(id);
        this.setState({previewVisible:previewStatus,previewId:id});
    };
    preview=(previewStatus,id)=>{
        this.setState({previewVisible:previewStatus})
        console.log(this.state.previewId)
    };

    previewOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewNoBlankAndStampByCertificateId?id='+alarmId)
    };
    previewOpen_1=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewNoBlankByCertificateId?id='+alarmId)
    };
    previewOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
        window.open(downIp+'/verification/pdfdownload/previewBlankByCertificateId')
    };
    // 条件选择：获取输入的条件
    getConditionsValue = async value => {
        let params = this.state.tableParamenter.params;
        let formParams=this.table.getParams();
        Object.assign(params,value);
        params.page=1;
        params.size=formParams.size;
        let {tableParamenter} = this.state;
        tableParamenter.params = params;
        this.setState({tableParamenter});
        await this.table.refreshTable(params);

    };
    // 清空条件
    clearCondition = () => {
        let tableParamenter = this.state.tableParamenter;
        let param = {
            page:1,
            size:7,
        };
        tableParamenter.params=param
        this.setState({tableParamenter:tableParamenter});
        this.table.refreshTable(tableParamenter.params);
        // this.getData();
    };

    // 删除展板和数据集
    deleteFormSelectDatas=(rowData)=>{
        var formSelect = [...this.state.formSelect];// 展板数据
        for(var i=0;i<formSelect.length;i++){
            if(formSelect[i][0]===rowData.certificateCode){

                formSelect.splice(i,1)
            }
        }
        this.setState({formSelect:formSelect});
        this.certificateDatas(formSelect);
    };

    certificateDatas= (value)=>{
        var certificateDatas = this.state.certificateDatas;

        // 如果为空直接清空
        if(value.length===0){
            certificateDatas=[];
        }else{
            // 遍历数据集
            for(var one=0;one<certificateDatas.length;one++){
                var juder=true;
                // 遍历获取的数据
                for(var two=0;two<value.length;two++){
                    // 循环判断获取数据在数据集里有没有，又
                    if(value[two][0]===certificateDatas[one].certificateCode){
                        juder=false;
                    }
                }
                if(juder){
                    certificateDatas.splice(one,1)
                }
            }
        }
        this.setState({formSelect:value,certificateDatas:certificateDatas})
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor:'#fff'}}>
                <div className={'topSearchBox'}>
                    <h3>证书列表管理</h3>
                    <Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
                    </Conditions>
                </div>
                <div >
                    <TableComponents
                        paramenter={this.state.tableParamenter}
                        setData = {getCertificateList}
                        setTableValue={this.setTableValue}
                        ref={e => this.table = e}
                    >
                    </TableComponents>
                </div>
                <Divider/>
                <div style={{margin:'5px'}}>
                   <div>
                       <span style={{fontSize:'16px',fontWeight:'bold'}}>证书列表:</span>
                   </div>
                    <div>
                        <Cascader
                            status={this.state.cascaderStatus_1}// 提示颜色
                            style={{ width: '92%'}}
                            value={this.state.formSelect}       // 展板数据
                            multiple
                            onChange={(value)=>{this.certificateDatas(value);}}
                        />
                        <Button style={{float:'right',right:'20px'}} type={'primary'}
                                onClick={()=>this.downloadOpen_allForm()}
                        >标签下载</Button>
                    </div>
                </div>

                <Modal
                    maskClosable={false}
                    title='下载'
                    style={{ top: 0 }}
                    visible={this.state.downloadVisible}
                    okText="确定"
                    onOk={() => this.downloadStatus(false)}           // 点击确定
                    onCancel={() => this.downloadStatus(false)}       // 点击取消
                    width={600}
                    centered
                >
                    <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
                        <span onClick={() => this.downloadOpenNull(this.state.downloadId,1,4,1)}>模板</span>
                        <span onClick={() => this.downloadOpen(this.state.downloadId,0,4,0)}>有章证书</span>
                        <span onClick={() => this.downloadOpen_1(this.state.downloadId,1,4,1)}>无章证书</span>
                    </div>
                </Modal>
                <Modal
                    maskClosable={false}
                    title='预览'
                    style={{ top: 0 }}
                    visible={this.state.previewVisible}
                    okText="确定"
                    onOk={() => this.previewStatus(false)}           // 点击确定
                    onCancel={() => this.previewStatus(false)}       // 点击取消
                    width={600}
                    centered
                >
                    <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
                        <span onClick={() => this.previewOpenNull(this.state.previewId,1,4,1)}>模板</span>
                        <span onClick={() => this.previewOpen(this.state.previewId,0,4,0)}>有章证书</span>
                        <span onClick={() => this.previewOpen_1(this.state.previewId,1,4,1)}>无章证书</span>
                    </div>
                </Modal>
                {/*<Modal*/}
                {/*    title='审核'*/}
                {/*    style={{ top: 0 }}*/}
                {/*    visible={this.state.ApprovalStatus}*/}
                {/*    okText="确定"*/}
                {/*    onOk={() => this.changeApprovalStatus()}           // 点击确定*/}
                {/*    onCancel={() => this.setState({ApprovalStatus:false})}       // 点击取消*/}
                {/*    width={500}*/}
                {/*    centered*/}
                {/*>*/}
                {/*    <div>*/}
                {/*        <FormsTwo*/}
                {/*            ref={e => this.forms = e}*/}
                {/*            formParamenter={this.state.ApprovalParamenter}*/}
                {/*            data={this.state.data}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</Modal>*/}
            </Layout>
        )
    }
}
