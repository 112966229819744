import React, { Component } from 'react'
import {Layout, Input, Form, message, notification, Button, DatePicker, Select} from 'antd'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getDailyConsumption,getAllManufacturerAndModel} from '../../../services/report'
import {TYPE_NAME} from "../../../components/Const";
import FormsTwo from "../../../components/common/forms/FormsTwo";
const { RangePicker } = DatePicker;
const FormItem = Form.Item

export default class DailyConsumption extends Component {
    state = {


		topFormItem: {
			modal: true,
			number: 3,
			labelCol: 10,
			layout: "horizontal",
			parameter: [
				// {
				// 	rules: TYPE_NAME.INPUT,
				// 	variableName: 'meterManufacturer',
				// 	str: '公司',
				//
				// },
				{
					rules: TYPE_NAME.SELECT,
					variableName: 'meterManufacturer',
					str: '表具厂商',
					data: []
				},
				{
					rules: TYPE_NAME.INPUT,
					variableName: 'properties',
					str: '用气性质'
				},
				{
					rules: TYPE_NAME.RANGE_PICKER,
					variableName: 'dataTime',
					str: '时间'
				},
				{
					rules:  TYPE_NAME.INPUT,
					variableName: 'steelNum',
					str: '表刚号'
				},
				{
					rules:  TYPE_NAME.INPUT,
					variableName: 'contractNum',
					str: '合同号'
				},
				{
					rules:  TYPE_NAME.INPUT,
					variableName: 'readUnit',
					str: '读表单元'
				},
			],
			data:{}
		},
		exportUrlData:{
			meterManufacturer:null,	//表具厂商
			properties:null,		// 用气性质
			startTime:null,			// 开始时间
			endTime:null,			// 结束时间
			steelNum:null,			// 表刚号
			contractNum:null,		// 合同号
			readUnit:null			// 读表单元
		},

		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 下拉框长度
			selectWidth: '9vw',
			// 是否绑定左侧区域
			bindArea: true,
			position: 'top',
            rowSelection: false,
			width: '83.7vw',
            title: '日用气量报表',
			// sorters: ["id"],
			exportUrl: "/web/reportExportCtrl/exportDailyConsumption",
			// 表头：变量和变量解释
			header: {
				company: '公司',
				readUnit: '读表单元',
				userName: '用户名称',
				installNumber: '安装',
				contractNumber: '合同',
				sheetSteelGrade: '表钢号',
                meterManufacturer: '表具厂商',
				// protocolModel: '协议型号',
				modelName: '表具型号',
				properties: '用气性质',
				customerType: '用户类型',
                className: '用气设备',
				simCard: 'SIM卡号',
				startTime: '起始气量选取时间',
				standardCumulative: '起始标况累计量',
                endGas: '结束气量选取时间',
				endCumulative: '结束标况累计量',
				dailyGasConsumption: '区间日用气量'
			},



			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'rangePicker',
						variableName: 'dataTime',
						time: true,
						str: '时间'
					},
					{
						type: 'input',
						variableName: 'steelNum',
						str: '表钢号'
					},
					{
						type: 'input',
						variableName: 'contractNum',
						str: '合同号'
					},
					{
						type: 'input',
						variableName: 'readUnit',
						str: '读表单元'
					}
				]
			},
		},
	};
	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter,topFormItem} = this.state;
				let {data} = response.data;
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});
				topFormItem.parameter[0].data=tableParamenter.conditionsParamenter.parameter[0].data;
				console.log(tableParamenter.conditionsParamenter.parameter[0].data);
				this.setState({tableParamenter,topFormItem})
			}
		)
	}
	// 条件选择：获取输入的条件
	getConditionsValue = ({dataTime}) => {
		return (
			{
				startTime: dataTime ? moment(dataTime?.[0]._d).format("yyyy-MM-DD HH:mm:ss") : null,
				endTime: dataTime ? moment(dataTime?.[1]._d).format("YYYY-MM-DD HH:mm:ss") : null
			}
		)
	}

    render() {
        return (
            <Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
				<div className={'topFormBox'} style={{width:'100%',  margin:'10px'}}>
					{/*<div style={{width:'100%',height:'40px'}}>*/}
					{/*	<div style={{width:'25%'}}>*/}
					{/*		<div style={{float:'left',width:'21%'}}>*/}
					{/*			<span style={{fontSize:'16px'}}>公司:</span>*/}
					{/*		</div>*/}
					{/*		<div style={{float:'left',width:'51%'}}>*/}
					{/*			<Input disabled value={localStorage.getItem('areaName')||'未获取公司名'} size='middle'/>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}
					{/*<div style={{width:'80%'}}>*/}
					{/*	<FormsTwo*/}
					{/*		ref={e => this.forms = e}*/}
					{/*		formParamenter={this.state.topFormItem}*/}
					{/*		data={this.state.topFormItem.data}*/}
					{/*	/>*/}
					{/*</div>*/}
					<div style={{width:'100%',float:'left'}}>
						<div style={{width:'85%',float:'left'}}>
							<div style={{width:'100%',height:'40px'}}>
								{/*表具厂商*/}
								<div style={{width:'25%',float:'left'}}>
									<div style={{float:'left',width:'25%'}}>
										<span style={{fontSize:'16px'}}>表具厂商:</span>
									</div>
									<div style={{float:'left',width:'60%'}}>
										<Select
											allowClear
											style={{ width: '100%'}}
											// optionLabelProp="label"
											// size="small"
											value={this.state.exportUrlData.meterManufacturer}
											options={this.state.tableParamenter.conditionsParamenter.parameter[0].data}
											placeholder="表具厂商"
											onChange={(a)=>{
												var exportUrlData = this.state.exportUrlData;
												exportUrlData.meterManufacturer=a;
												this.setState(exportUrlData)}}
											maxTagCount={'responsive'}
										/>
									</div>
								</div>
								{/*用气性质*/}
								<div style={{width:'25%',float:'left'}}>
									<div style={{float:'left',width:'25%'}}>
										<span style={{fontSize:'16px'}}>用气性质:</span>
									</div>
									<div style={{float:'left',width:'60%'}}>
										<Input value={this.state.exportUrlData.properties}
											   size='middle'
											   placeholder='用气性质'
											   onChange={(text)=>{
												var exportUrlData = this.state.exportUrlData;
											   	exportUrlData.properties=text.target.value;
											   	this.setState({exportUrlData})
											   }}
										/>
									</div>
								</div>
								{/*时间*/}
								<div style={{width:'25%',float:'left'}}>
									<div style={{float:'left',width:'15%'}}>
										<span style={{fontSize:'16px',color:'red'}}>*</span>
										<span style={{fontSize:'16px'}}>时间:</span>
									</div>
									<div style={{float:'left',width:'72%'}}>
										<RangePicker
											style={{border:this.state.RangePickerType}}
											key={'RangePicker_1'}
											placeholder='时间'
											// status="error"
											showTime={{ format: 'HH' }}
											format={'YYYY-MM-DD HH'}
											onChange={(d,v)=> {
												var exportUrlData = this.state.exportUrlData;
												exportUrlData.startTime = v[0];
												exportUrlData.endTime = v[1];
												this.setState({exportUrlData,RangePickerType:null})
											}}
										/>
									</div>
								</div>
								{/*表刚号*/}
								<div style={{width:'25%',float:'left'}}>
									<div style={{float:'left',width:'20%'}}>
										<span style={{fontSize:'16px'}}>表钢号:</span>
									</div>
									<div style={{float:'left',width:'65%'}}>
										<Input  value={this.state.exportUrlData.steelNum}
												size='middle'
												placeholder='表钢号'
												onChange={(text)=>{
													var exportUrlData = this.state.exportUrlData;
													exportUrlData.steelNum=text.target.value;
													this.setState({exportUrlData})
												}}
										/>
									</div>
								</div>
							</div>
							<div style={{width:'100%',backgroundColor: '#999999'}}>
								{/*合同号*/}
								<div style={{width:'25%',float:'left'}}>
									<div style={{float:'left',width:'25%'}}>
										<span style={{fontSize:'16px'}}>合同号:</span>
									</div>
									<div style={{float:'left',width:'60%'}}>
										<Input  value={this.state.exportUrlData.contractNum}
												size='middle'
												placeholder='合同号'
												onChange={(text)=>{
													var exportUrlData = this.state.exportUrlData;
													exportUrlData.contractNum=text.target.value;
													this.setState({exportUrlData})
												}}
										/>
									</div>
								</div>
								{/*读表单元*/}
								<div style={{width:'25%',float:'left'}}>
									<div style={{float:'left',width:'25%'}}>
										<span style={{fontSize:'16px'}}>读表单元:</span>
									</div>
									<div style={{float:'left',width:'60%'}}>
										<Input  value={this.state.exportUrlData.readUnit}
												size='middle'
												placeholder='读表单元'
												onChange={(text)=>{
													var exportUrlData = this.state.exportUrlData;
													exportUrlData.readUnit=text.target.value;
													this.setState({exportUrlData})
												}}
										/>
									</div>
								</div>
							</div>
						</div>
						{/*按钮-------------------------------*/}
						<div style={{width:'10%', height:'40px',float:'left'}}>
							<Button style={{
								// position:'absolute',
								// left:'85%',top:'10px'
							}}
									type="primary"
									onClick={async() => {
										// let formData = await this.forms.onFinishNotReset();
										let formData = this.state.exportUrlData;
										console.log(this.state.exportUrlData);
										if(!formData?.startTime){
											this.setState({RangePickerType:'1px red solid'});
											return message.info('请选择日期');
										}


										let menusStr = localStorage.getItem('menus');
										let menus = ((menusStr !== null || menusStr !== "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : [];

										// if(!formData){return false;}
										//
										var params={
											areaIds:menus,
											page:1,
											size:13,
											meterManufacturer:formData.meterManufacturer || null,	// 表具厂商
											properties:formData.properties || null,					// 用气性质
											steelNum : formData.steelNum || null,					// 表刚号
											contractNum : formData.contractNum || null,				// 合同号
											readUnit : formData.readUnit || null,					// 读表单元
											startTime: formData.startTime || null,
											endTime: formData.endTime || null,
										};
										// if(formData?.dataTime){
										// 	params.startTime=moment(formData?.dataTime[0]).format("YYYY-MM-DD");
										// 	params.endTime=moment(formData?.dataTime[1]).format("YYYY-MM-DD");
										// }
										//

										// 导出
										// let {exportUrl,title,fileName,manageName} = this.props.paramenter;
										var exportUrl='/gateway'+'/web/reportExportCtrl/exportDailyConsumption';
										// let {data,isImport} = this.state;
										let msgKey = 'exportTable';
										// 将对象拆分成一条链接
										const variables = Object.keys(params);
										// if(isImport) return message.warning('文件正在导出或已导出');
										// this.setState({isImport: true})
										// if(!data) return message.warning('数据为空！不支持导出');
										let link = `${exportUrl}?ajaxFileName=${('日用气量报表') +moment().format('YYYY-MM-DD')}`;
										//
										variables.forEach(key => {
											if(params[key]){
												if(!Array.isArray(params[key])) link += `&${key}=${params[key]}`;
												else params[key].forEach(str => link += `&${key}=${str}`);

											}
										});
										console.log(link);
										try{
											message.loading({ content: '导出中...', msgKey });
											notification['warning']({
												message: '文件导出中',
												description: '文件未生成时，不允许多次点击按钮。'
											});
											window.open(link)

										} catch (error) {

										}
									}}
							>
								导出
							</Button>
							<Button styple={{paddingLeft:'5px'}}
									onClick={()=>{
										var exportUrlData = this.state.exportUrlData;
										exportUrlData.meterManufacturer=null;
										exportUrlData.endTime=null;
										exportUrlData.startTime=null;
										exportUrlData.properties=null;
										exportUrlData.steelNum=null;
										exportUrlData.contractNum=null;
										exportUrlData.readUnit=null;
										this.setState(exportUrlData)
									}}

							>
								清空
							</Button>
						</div>
					</div>



				</div>
                {/*<TableComponents*/}
				{/*	paramenter={this.state.tableParamenter}*/}
				{/*	setData={getDailyConsumption}*/}
				{/*	getConditionsValue={this.getConditionsValue}*/}
				{/*	ref={e => this.table = e}*/}
				{/*>*/}
				{/*	<FormItem label={"公司"}>*/}
				{/*		<Input disabled value={localStorage.getItem('areaName')} size='middle'/>*/}
				{/*	</FormItem>*/}
				{/*</TableComponents>*/}
            </Layout>
        )
    }
}
