import React,{Component} from "react";
import {Button, Layout, message, Popconfirm, Switch} from "antd";
import FormsTwo from "../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../components/Const";
import {PlusSquareOutlined} from '@ant-design/icons';
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {getAlertConfigurationByConditionNew, removeConfig, updateMeterState} from "../../services/earlyWarning";

// 数据预警配置
export default class earlyWarningInfoDispose extends Component {
    state = {
        // 表格参数_1
        tableParamenter_1: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                id: '配置ID',
                orgName: '配置从属部门',
                profileName: '配置名',
                dataName:'数据名',
                ruleData: '规则',
                monitoringObjectArea: '监控对象从属区域',
                monitoringObjectType: '监控对象类型',
                monitoringObjectTotal: '监控对象总数',
                edit:'操作'
            },
            params: {
                profileName:'',     // 配置名
                dataName:'',        // 数据名
                page:1,
                size:10,
                type: 1,
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        // 条件查询栏
        topFormItem_1: {
            modal: true,
            number: 5,
            labelCol: 7,
            layout: "horizontal",
            parameter: [
                {variableName: 'profileName', str: '配置名', rules: TYPE_NAME.INPUT,},
                {variableName: 'dataName', str: '数据名',rules: TYPE_NAME.INPUT,},
            ]
        },
        topFormItem_1_data:[],
    };
    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return <>
                    <a style={{paddingLeft:'15px', float: 'left'}}
                       onClick={() => {
                           console.log(rowData);
                           this.props.history.push({
                               pathname:"/earlyWarning/earlyWarningCU",
                               state:{
                                   formsTwo:this.state?.formsTwo_data,
                                   rowData:rowData
                               }
                           })
                       }}
                    >修改</a>
                    {rowData.state!==1?
                        <Popconfirm title="是否禁用该设备?"
                                    onConfirm={()=>{this.updateMeterState(rowData,1)}}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a style={{paddingLeft:'15px', float: 'left'}}>禁用</a>
                        </Popconfirm>
                        :
                        <span style={{paddingLeft:'15px', float: 'left',color:'#cccccc'}}>禁用</span>
                    }
                    {rowData.state!==0?
                        <Popconfirm title="是否启用该设备?"
                                    onConfirm={()=>{this.updateMeterState(rowData,0)}}
                            // onCancel={cancel}
                                    okText="是" cancelText="否">
                            <a style={{paddingLeft:'15px', float: 'left'}}>启用</a>
                        </Popconfirm>
                        :
                        <span style={{paddingLeft:'15px', float: 'left',color:'#cccccc'}}>启用</span>
                    }
                    <Popconfirm title="是否删除?"
                                onConfirm={()=>{this.removeConfig(rowData.id)}}
                                okText="是" cancelText="否">
                        <a style={{paddingLeft:'15px', float: 'left'}}>删除</a>
                    </Popconfirm>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    getConditionsValue= async value =>{
        let data = await this.forms.onFinishNotReset();
        var {tableParamenter_1} = this.state;
        tableParamenter_1.params.profileName=data.profileName || null;      // 配置名
        tableParamenter_1.params.dataName=data.dataName || null;            // 数据名
        this.setState({tableParamenter_1:tableParamenter_1});
        await this.table.refreshTable(tableParamenter_1.params);
    };

    // 修改
    updateMeterState=(rowData,num)=>{
        updateMeterState({id:rowData.id,state:num}).then(
            response=>{
                if(response?.data?.data){
                    message.info("操作成功");
                    this.table.refreshTable(this.state.tableParamenter_1.params);
                }else {
                    message.info("操作失败")
                }
            }
        )
    };
    // 删除
    removeConfig=(id)=>{
        var params=new URLSearchParams();
        params.append('ids',[id]);
        removeConfig(params).then(
            response=>{
                if(response?.data?.data){
                    message.info("操作成功");
                    this.table.refreshTable(this.state.tableParamenter_1.params);
                }else {
                    message.info("操作失败")
                }
            }
        )
    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
                <div>
                    <div className={'topSearchBox'}>
                        <h3>数值预警配置</h3>

                        <div style={{width:'1600px',height:'30px'}}>
                            <div style={{width:'600px' ,float:'left'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem_1}
                                    data={this.state.topFormItem_1_data}
                                />

                            </div>
                            <div style={{width:'100px' ,float:'left'}}>
                                <Button  type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            </div>
                        </div>

                    </div>
                    <div className={'topBtnBox'}>
                        <span className={'addLink'}  onClick={() => {
                            this.props.history.push({
                                pathname:"/earlyWarning/earlyWarningCU",
                            })
                        }}
                        >
                            <PlusSquareOutlined />创建新配置
                        </span>
                    </div>
                    <div style={{width:'100%',padding:'0 0 0 0',background:'#fff'}}>
                        <div>
                            <TableComponents
                                paramenter={this.state.tableParamenter_1}
                                setData = {getAlertConfigurationByConditionNew}
                                setTableValue={this.setTableValue}
                                ref={e => this.table = e}
                            >
                            </TableComponents>
                        </div>
                    </div>

                </div>
            </Layout>
        );
    }
}
