import React,{ Component } from 'react'
import {Layout, message, Tooltip} from "antd";
import {getMeterOrderFormByState} from "../../../services/supplier";
import {funTraceInfo} from "../../../services/common";

import {NavLink} from "react-router-dom";
import moment from "moment";
import TableComponents from "../../../components/common/tables/TableComponents";
// import moment from 'moment'

// 订单管理-窗口栏
export default class order extends Component{
    state={


        // 表格参数-暂时无用
        tableParamenter: {
            manageName:'供应商信息管理-订单管理',
            // 表格行是否可选
            rowSelection: true,
            // 表头：变量和变量解释
            header: {
                code: '订单编号',
                orderTime: '订单时间',
                arrivalTime: '订单完成时间',
                state: '订单状态',
                companyName:'订单单位',
                purchaseMan: '订单单位联系人'

            },
            // 条件选择器参数
            conditionsParamenter: {
                // 文本框内容
                parameter: [
                    {
                        type: 'input',
                        variableName: 'code',
                        str: '订单号'
                    },

                    {
                        type: 'rangePicker',
                        variableName: 'dataTime',
                        str: '订单时间'
                    },
                    {
                        type: 'select',
                        variableName: 'state',
                        str: '订单状态',
                        data:[
                            {key:1,value:"未发货"},
                            {key:2,value:"部分发货"},
                            {key:3,value:"已发货"},
                        ]
                    },
                ]
            },// 条件选择器参数END
            params:{
                areaIds:"1",
                userId:"1",
                page:  1,
                size:  15
            }


        },// 表格参数END

    };
    // 页面初始化加载
    componentDidMount=()=>{
        let user = JSON.parse(sessionStorage.getItem("user")??null);
        console.log("user");
        console.log(user);
        let {tableParamenter} = this.state;
        tableParamenter.params.userId=user.id;
        this.setState(tableParamenter)
    };

    // 条件选择：获取输入的条件
    getConditionsValue = ({dataTime}) => (
        {
            timeStart: dataTime ? moment(dataTime[0]?._d).format('YYYY-MM-DD HH:mm:ss') : null,
            timeEnd: dataTime ? moment(dataTime[1]?._d).format('YYYY-MM-DD HH:mm:ss') : null
        }

    );

    // 多条件查询，支持无条件查询
    getQueryData =()=>{
        this.setState({loading: true});
        getMeterOrderFormByState(this.state.params).then(
            response=>{
                console.log(response);
                let {data} = response.data;
                let {tableParamenter,pageinfo,manageName} = this.state;
                tableParamenter.data = data.records;    // 获得数据
                pageinfo.totalCount=data?.total;         // 获得分页数量合
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter:tableParamenter,
                    pageinfo:pageinfo
                });
                message.success('查询成功');
           }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')
            }
        )
    };


    // 表格部分数据内容修改
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'arrivalTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                );
            case 'orderTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                );
            case 'code':
                return (
                    <NavLink to={{pathname: "/supplier/deliver",state: {
                            id:rowData.id,
                        }}}>
                        <Tooltip title={text}>
                            <u>{text}</u>
                        </Tooltip>
                    </NavLink>
                );
            case 'state':
                text = text*1;
                return text === 1 ? '未发货' : text === 2 ? '已发货' : text === 3 ? '部分发货' : text === 4 ? '部分收货':text === 5 ? '已收货': '-';
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };

    render(){
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    paramenter={this.state.tableParamenter}
                    setData={getMeterOrderFormByState}
                    getConditionsValue={this.getConditionsValue}    // 查询
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                />
            </Layout>
        )
    }
}
