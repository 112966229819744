export const FORM_ITEM = {
    INPUT: 'input', // 输入框
    PASSWORD: 'password', // 密码框
    INPUT_NUMBER: 'number',
    RANGE_PICKER: 'rangePicker', // 时间范围
    RANGE_REQ_DATE_PICKER: 'reqrangePicker', // 时间范围
    RANGE_PICKER_TIME:'rangePickerTime',
    SELECT: 'select',  // 下拉框
    SELECTINPUT:'select_input',// 带输入下拉框
    DATE_PICKER: 'datePicker', // 日期选择框
    DATE_PICKER1: 'datePicker1', // 日期选择框
    DATE_PICKER2: 'datePicker2', // 日期选择框-时分

    TEXT_AREA: 'textArea',  // 文本域
    TEXT_AREA_REQ:'textAreaReq',// 文本域非空
    EMAIL: 'email', // 邮箱
    INPUT_NUMBER_STR: 'numberStr', // 浮点数字框
    AutoComplete:'AutoComplete', // 自动索引
    NOT_AutoComplete:'NotAutoComplete', // 自动索引
    DATE_PICKERTIME: 'datePickerTime', // 日期时间选择框必填
    TEMPERATURE: 'temperature', // 温度
    PERCENTAGE:'percentage',//百分比

    TREE_SELECT:'treeSelect',// 层级树结构框
    REQ_TREE_SELECT:'reqTreeSelect',// 层级树结构框


    SELECT_TAGS:'selectTags',// 自定义多参数框
    SELECT_MULTIPLE:'selectMultiple',// 输入框
};

// 在使用该值时，如 NAME:'name' ，在使用时会匹配到name，但是我们在FromTwo里面再根据name进行匹配下方的RULE
export const TYPE_NAME = {

    // 可空
    NAME: 'name', // 姓名（可空）
    LABLE: 'lable', // 名称（可空）
    LABLE_18: 'lable_18', // 名称 1-18（可空）
    LABLE_20: 'lable_20', // 名称 1-20（可空）
    LABLE_50: 'lable_50', // 名称 1-50（可空）
    EMAIL: 'email', // 邮箱地址
    AGE: 'age', // 年龄（可空）
    TEMPERATURE: 'temperature', // 温度
    PERCENTAGE:'percentage',//百分比
    ADDRESS: 'address', // 地址 5-20个字符，并且包含中文（可空）
    DESCRIPTION: 'description', // 描述 1-255
    ID_CARD: 'IDCard', // 身份证号码
    PHONE_NUM: 'phoneNum', // 电话号码（可空）
    LINE_PHONE: 'linePhone', // 固定电话号码（可空）
    TEXT_EN: 'text_en', // 英文文本 1-255

    // 非空
    NO_INPUT_NUMBER: 'noNumber',    //必填数字输入框
    NODESCRIPTION: 'no_description', // 描述 1-255
    NOT_SPECIAL :'notSpecial',  // 只能输入中文，英文和数字
    ADDRESS_NOT_NULL: 'address_notNull', // 地址 5-20个字符，并且包含中文
    NOT_BLANK: 'notBlank', // 非空 2-30
    DATE_TIME_FORMAT: 'dateTimeFormat', // 日期选择框
    NAME_NOT_NULL: 'name_notNull', // 非空 1-100
    LABLE_NOT_NULL: 'lable_notNull', // 非空 1-50
    ID_NUMBER: 'id_number', // 非空 1-20 且为数字
    ID_NUMBER_11_13:'id_number_11_13',//限制数字必须11-13
    NO_ID_NUMBER_11_13:'no_id_number_11_13',//限制数字必须11-13-不能为空
    ID_NUMBER_10: 'id_number_10', // 非空 1-10 且为数字
    LABLE_NOT_NULL_20: 'lable_20_notNull', // 非空 1-20
    LABLE_NOT_NULL_50: 'lable_50_notNull', // 非空 1-50
    LABLE_NOT_NULL_255: 'lable_255_notNull', // 非空 1-50
    LABLE_NOT_NULL_28: 'lable_28_notNull', // 非空 1-28
    PASSWORD: 'password', // 非空 1-28 英文开头, 不可含有中文, 6-20个字符
    EMAIL_NOT_NULL: 'email_notNull', // 邮箱
    IDCard_NOT_NULL: 'IDCard_notNull', // 身份证号
    PHONE_NUM_NOT_NULL: 'phoneNum_notNull', // 电话号码
    LINE_PHONE_NOT_NULL: 'linePhone_notNull', // 固定电话
    TEXT_EN_NOT_NULL: 'text_en_notNull', // 英文文本 1-255位的英文
    REQ_SELECT: 'reqSelect',  // 必选 下拉框

    ID_NUMBER_STR: 'idNumberStr', // 非空 1-10 且可以为浮点数

    REQ_NUMBER: 'reqNumber', // 数字输入框

    // 基本输入框
    NUMBER: 'number', // 数字输入框
    INPUT: 'input', // 输入框
    TEXT_AREA: 'textArea', // 文本域
    TEXT_AREA_REQ:'textAreaReq',// 文本域非空
    RANGE_PICKER: 'rangePicker', // 时间范围
    RANGE_REQ_DATE_PICKER: 'reqrangePicker', // 时间范围
    RANGE_PICKER_TIME:'rangePickerTime',
    SELECT: 'select',  // 下拉框
    SELECTINPUT:'select_input',// 带输入下拉框
    DATE_PICKER: 'datePicker', // 日期选择框
    DATE_PICKER1: 'datePicker1', // 日期选择框-时分秒
    DATE_PICKER2: 'datePicker2', // 日期选择框-时分
    REQDATE_PICKER: 'reqDatePicker', // 日期选择框必填
    AutoComplete:'AutoComplete', // 自动索引
    NOT_AutoComplete:'NotAutoComplete', // 自动索引
    TREE_SELECT:'treeSelect',
    REQ_TREE_SELECT:'reqTreeSelect',// 层级树结构框
    DATE_PICKERTIME: 'datePickerTime', // 日期时间选择框必填

    REQRANGE_PICKER: 'rangePickerReq', // 时间范围

    SELECT_TAGS:'selectTags',// 自定义多参数框
    SELECT_MULTIPLE:'selectMultiple',// 输入框
};

export const RULE = {
    // 可空
    name: {
        type: FORM_ITEM.INPUT,
        rules:  [{ required: false,
            pattern: /^.{0,99}.$/g, message: `输入值长度必须在1-100之间`}] // 姓名（可空）
    },
    lable: {
        type: FORM_ITEM.INPUT,
        rules:  [{ required: false, pattern: /^.{0,49}.$/g, message: `输入值长度必须在1-50之间`}] // 名称（可空）
    },
    lable_18: {
        type: FORM_ITEM.INPUT,
        rules:  [{ required: false, pattern: /^.{0,17}.$/g, message: `输入值长度必须在1-18之间`}] // 名称（可空）
    },
    lable_20: {
        type: FORM_ITEM.INPUT,
        rules:  [{ required: false, pattern: /^.{0,19}.$/g, message: `输入值长度必须在1-20之间`}] // 名称（可空）
    },
    lable_50: {
        type: FORM_ITEM.INPUT,
        rules:  [{ required: false, pattern: /^.{0,49}.$/g, message: `输入值长度必须在1-50之间`}] // 名称（可空）
    },
    email: {
        type: FORM_ITEM.INPUT,
        rules:  [{ type: FORM_ITEM.EMAIL, message: `邮箱格式错误` }] // 邮箱地址
    },
    age: {
        type: FORM_ITEM.INPUT_NUMBER,
        rules: [{ required: false, min: 5, max: 120 }] // 年龄（可空）
    },
    temperature:{
        type: FORM_ITEM.TEMPERATURE,
        rules: [{ required: true}]
    },
    percentage:{
        type: FORM_ITEM.PERCENTAGE,
        rules: [{ required: true}]
    },
    address: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [{ required: false, min: 5, max: 20, message: `地址要求5-20个字符，并且包含中文`, pattern: /[\\u4e00-\\u9fa5]+/g }] // 地址（可空）
    },
    description: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [{ required: false}] // 描述
    },
    no_description: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [{ required: true}] // 描述
    },
    IDCard: {
        type: FORM_ITEM.INPUT,
        rules: [{ required: false, pattern: /(^\\d{15}$)|(^\\d{18}$)|(^\\d{17}(\\d|X|x)$)/g, message: `身份证号码格式不正确` }] // 身份证号（可空）
    },
    phoneNum: {
        type: FORM_ITEM.INPUT,
        rules: [{ required: false, pattern: /^[1]([3-9])[0-9]{9}$/g, message: `电话号码格式不正确` }] // 电话号码（可空）
    },
    linePhone: {
        type: FORM_ITEM.INPUT,
        rules: [{ required: false, pattern: /^[0-9]{3,4}[-][0-9]{7,8}$|^[1]([3-9])[0-9]{9}$/g, message: `固定电话号码错误！` }] // 固定电话(^[0-9]{3,4}[-][0-9]{7,8}$|^[1]([3-9])[0-9]{9}$)
    },
    text_en: {
        type: FORM_ITEM.INPUT,
        rules: [{ required: true, pattern: /^[a-zA-Z]{1,255}$/g, message: `要求是1-255位的英文！` }] // 英文文本
    },
    // 非空
    address_notNull: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [
            { required: true, message: '地址不能为空'},
            { min: 5, max: 20, message: `地址要求5-20个字符，并且包含中文`, pattern: /[\\u4e00-\\u9fa5]+/g }] // 地址（非空）
    },
    notSpecial: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { message: `输入值只能是中文、英文、数字`,pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/ },
        ], // 字符串不能为空
    },
    notBlank: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { message: `输入值不能空格，长度必须在1到30之间`,pattern: /^\S{1,30}$/g },
        ], // 字符串不能为空
    },
    dateTimeFormat: {
        type: FORM_ITEM.DATE_PICKER,
        rules: [{ required: true, message: `时间不能为空` }] // 时间不能为空
    },
    name_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^([\u4E00-\u9FA5]|[\w]){0,99}([\u4E00-\u9FA5]|[\w])$/g, message: `长度必须在1-100之间并不包含特殊符号` },
        ],  //姓名（非空）
    },
    lable_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^([\u4E00-\u9FA5]|[\w]){0,49}([\u4E00-\u9FA5]|[\w])$/g, message: `长度必须在1-50之间并不包含特殊符号` },
        ], // 名称（非空）
    },
    id_number: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^\d{0,19}\d$/g, message: `输入值长度必须在1-20且为数字` },
        ], // 名称（非空）
    },

    id_number_11_13: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: false, message: '输入值不能为空'},
            { pattern: /(^\d{11}$)|(^\d{13}$)|(^\d{15}$)/g , message: `输入值长度必须在11-13-15且为数字` },
        ], // 名称（非空）
    },
    no_id_number_11_13: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /(^\d{11}$)|(^\d{13}$)|(^\d{15}$)/g , message: `输入值长度必须在11-13-15且为数字` },
        ], // 名称（非空）
    },
    id_number_10: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^\d{0,9}\d$/g, message: `输入值长度必须在1-10且为数字` },
        ], // 名称（非空）
    },
    lable_20_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^.{0,19}.$/g, message: `输入值长度必须在1-20之间` },
        ], // 名称（非空）
    },
    lable_50_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^.{0,49}.$/g, message: `输入值长度必须在1-50之间` },
        ], // 名称（非空）
    },
    lable_255_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^.{0,254}.$/g, message: `输入值长度必须在1-255之间` },
        ], // 名称（非空）
    },
    lable_28_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: '输入值不能为空'},
            { pattern: /^.{0,27}.$/g, message: `输入值长度必须在1-28之间` },
        ], // 名称（非空）
    },
    password: {
        type: FORM_ITEM.INPUT,
        rules: [
            {required: true, message: '密码不可以为空'},
            { pattern: /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*_ ]).*$/g, message: `密码必须包含大写，小写，数字，字符，长度最小为6` },
        ], // 密码
    },
    email_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: `邮箱不能为空` },
            { type: FORM_ITEM.EMAIL, message: `邮箱格式错误` }
        ], // 邮箱地址（非空）
    },
    IDCard_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: `身份证号不能为空` },
            { pattern: /(^\\d{15}$)|(^\\d{18}$)|(^\\d{17}(\\d|X|x)$)/g, message: `身份证号码格式不正确` }
        ], // 身份证号（非空）
    },
    phoneNum_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: `电话号码不能为空` },
            { pattern: /^[1]([3-9])[0-9]{9}$/g, message: `电话号码格式不正确` },
        ] // 电话号码（非空）
    },
    linePhone_notNull: {
        type: FORM_ITEM.INPUT,
        rules: [
            { required: true, message: `固定电话不能为空` },
            { pattern: /^[0-9]{3,4}[-][0-9]{7,8}$|^[1]([3-9])[0-9]{9}$/g, message: `固定电话号码错误！` },
        ] // 固定电话号码（非空）
    },
    text_en_notNull: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [
            { required: true, message: `英文文本不能为空` },
            { pattern: /^[a-zA-Z]{1,255}$/g, message: `要求是1-255位的英文！` },
        ] // 地址（非空）
    },
    reqSelect: {
        type: FORM_ITEM.SELECT,
        rules: [{ required: true }]
    },

    reqNumber: {
        type: FORM_ITEM.REQ_NUMBER,
        rules: [{ required: true }]
    },
    number: {
        type: FORM_ITEM.INPUT_NUMBER,
        rules: [{ required: false }]
    },
    input: {
        type: FORM_ITEM.INPUT,
        rules: [{ required: false }]
    },
    textArea: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [{ required: false }]
    },
    textAreaReq: {
        type: FORM_ITEM.TEXT_AREA,
        rules: [{ required: true }]
    },
    rangePicker: {
        type: FORM_ITEM.RANGE_PICKER,
        rules: [{ required: false }]
    },
    rangePickerTime: {
        type: FORM_ITEM.RANGE_PICKER_TIME,
        rules: [{ required: false }]
    },
    reqrangePicker: {
        type: FORM_ITEM.RANGE_PICKER,
        rules: [{ required: true }]
    },
    rangePickerReq: {
        type: FORM_ITEM.REQRANGE_PICKER,
        rules: [{ required: true }]
    },
    select: {
        type: FORM_ITEM.SELECT,
        rules: [{ required: false}]
    },
    select_input: {
        type: FORM_ITEM.SELECTINPUT,
        rules: [{ required: true}]
    },
    AutoComplete: {
        type: FORM_ITEM.AutoComplete,
        rules: [{ required: false}]
    },
    NotAutoComplete: {
        type: FORM_ITEM.NOT_AutoComplete,
        rules: [{ required: true}]
    },
    datePicker: {
        type: FORM_ITEM.DATE_PICKER,
        rules: [{ required: false }]
    },
    datePicker1: {
        type: FORM_ITEM.DATE_PICKER1,
        rules: [{ required: false }]
    },
    datePicker2: {
        type: FORM_ITEM.DATE_PICKER2,
        rules: [{ required: false }]
    },
    reqDatePicker: {
        type: FORM_ITEM.DATE_PICKER,
        rules: [{ required: true }]
    },
    datePickerTime: {
        type: FORM_ITEM.DATE_PICKERTIME,
        rules: [{ required: true }]
    },
    idNumberStr: {
        type: FORM_ITEM.INPUT_NUMBER_STR, // 名称（非空）
        rules: [
            { required: true }
        ]
    },
    treeSelect:{
        type: FORM_ITEM.TREE_SELECT,
        rules: [{ required: false }]
    },
    reqTreeSelect:{
        type: FORM_ITEM.REQ_TREE_SELECT,
        rules: [{ required: true }]
    },
    selectTags: {
        type: FORM_ITEM.SELECT_TAGS,
        rules: [{ required: false }]
    },
    selectMultiple: {
        type: FORM_ITEM.SELECT_MULTIPLE,
        rules: [{ required: false }]
    },
};
