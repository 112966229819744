import React, { Component } from 'react'
import {Layout, Input, Tabs, Button} from 'antd'
import FormsTwo from "../../components/common/forms/FormsTwo";
import moment from "moment";
import {FORM_ITEM} from "../../components/Const";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {pageHistoricalTimeValve} from "../../services/qrCode";
import {queryPushRecord} from "../../services/steamReport";

// 数据推送
export default class DevicePushRecords extends Component {
    state={
        // 查询栏
        topFormItem: {
            modal: true,
            number: 3,
            labelCol: 5,
            layout: "horizontal",
            parameter: [{variableName: 'Time', str: '查询时间', rules: FORM_ITEM.RANGE_PICKER_TIME,}]
        },
        // 表格参数
        tableParamenter: {
            manageName: "表具修改台账",
            // 表格行是否可选
            rowSelection: false,
            overflow:true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                time: '推送时间',
                url: '推送地址',
                num: '推送设备数',
                message: '备注',
                className: '设备类型',
            },
            params:{
                dataTimeRangeEnd: moment(new Date()).format('YYYY-MM-DD 23:59:59'),
                DataTimeRangeStart:moment(new Date()).format('YYYY-MM-DD 00:00:00'),
                devId:this.props.location.state?.rowData?.devId,
                size:10,
                page:1,
            }
        },


    };
    // 查询
    getConditionsValue = async() =>{
        let data = await this.forms.onFinishNotReset();
        // console.log(data);
        let sTime=data?.updateStarTime ?moment(data?.updateStarTime[0]._d).format('YYYY-MM-DD HH:mm:ss') : null;
        let eTime=data?.updateStarTime ?moment(data?.updateStarTime[1]._d).format('YYYY-MM-DD HH:mm:ss') : null;

        let params = this.state.tableParamenter.params;
        Object.assign(params,data);
        params.dataTimeRangeStart=sTime;
        params.dataTimeRangeEnd=eTime;
        let {tableParamenter} = this.state;
        tableParamenter.params = params;
        this.setState({tableParamenter});
        await this.table.refreshTable(params);
    };
    // 清空
    getEmptyValue = async () =>{
        await this.setState({topFormItemData:{}});
        await this.forms.onModalClose();
        var tableParamenter  = this.state.tableParamenter;
        tableParamenter.params.dataTimeRangeEnd = moment(new Date()).format('YYYY-MM-DD 23:59:59');
        tableParamenter.params.DataTimeRangeStart = moment(new Date()).format('YYYY-MM-DD 00:00:00');
        this.setState({tableParamenter:tableParamenter})
    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{width:'100%',height:'100%'}}>
                    <div className={'topSearchBox'}>
                        <h3>推送记录</h3>
                        <div style={{width:'100%',height:'40px',position:'relative',backgroundColor:'#fff',paddingTop:'5px'}}>
                            <div style={{width:'85%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem}
                                    data={this.state.topFormItemData}
                                />
                            </div>
                            <Button style={{position:'absolute', right:'150px',top:'5px'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                            <Button style={{position:'absolute', right:'60px',top:'5px'}} onClick={() => this.getEmptyValue()}>清空</Button>
                        </div>
                    </div>
                    <div style={{width:'100%',height:'400px',padding:'0 0 0 0',background:'#fff'}}>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData={queryPushRecord}
                            // setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        />
                    </div>
                </div>
            </Layout>
        )}
}
