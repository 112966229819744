import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'

import {getSimScrapListInCondition,getManufacturerList} from '../../../services/simCard'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "SIM卡申请管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				createTime: '添加时间',
				scrapTime: '报废时间',
				sim: 'SIM卡号',
				iccId:'ICCID号',
				state: '状态',
				lastUserName: '操作人',
				factoryName: '供应厂家',
			},
			// 弹出框
			headers: {
				createTime: '添加时间',
				scrapTime: '报废时间',
				sim: 'SIM卡号',
				iccId:'ICCID号',
				state: '状态',
				lastUserName: '操作人',
				factoryName: '供应厂家',
			},
			params: {
				page:1,
				size:10,
		},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [			
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'createTime',
					str: '添加时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'facId',
					str: '厂家',
					rules: TYPE_NAME.SELECT,
					disabled: this.props.location.state ? true : false,
					data:[],
				},
				{
					variableName: 'state',
					str: '状态',
					// isMultiple:true,
                    disabled: false,
					data:[
						{key:'',value:'全部'},
						{key:'未使用',value:'未使用'},
						{key:'使用中',value:'使用中'},
						{key:'报废',value:'报废'},
						{key:'退回',value:'退回'},
					],
					rules: TYPE_NAME.SELECT,
				},
				{
					variableName: 'sim',
					str: '卡号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'iccId',
					str: 'ICCID号',
					rules: TYPE_NAME.INPUT,
				},
			]
		},
		    	// 总条数
		totalCount: 0,
		devVisible:false, // 小窗口开关器-1
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editQrCodeStatus(true,rowData.id,rowData.code)}>
						<u>{'修改'}</u>
				</div>
				</>
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {
		
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		getManufacturerList().then(
			response => {
				const {data} = response.data
				let selectArry=[{key:'',value:'全部'}]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)
	}
	onChangeSelect = e => {
		console.log(111,e)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD  00:00:00") : null;
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		params.createTime=sTime;
		params.endTime=eTime;
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);
		
	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }
	

	
 
	
	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	
	   this.setState({params:params})
	   this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
					<h3>SIM卡报废台账</h3>
					<div style={{position:'relative'}}>
								<div className={'topFormBox'} style={{width:'90%'}}>
								<FormsTwo 
										ref={e => this.forms = e} 
										formParamenter={this.state.topFormItem} 
										data={this.state.searchGetData}
								/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
					</div>
				</div>
				<div style={{padding:'10px 0 0 0',background:'#fff'}}>			
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getSimScrapListInCondition}
						setTableValue={this.setTableValue}
						ref={e => this.table = e} 
					>
					</TableComponents>
				</div>
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addQrcodeData(true)}           // 点击确定
                        onCancel={() => this.addQrcodeStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms = e} 
							formParamenter={this.state.ModalFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
