import React,{Component} from "react";
import {Button, Cascader, DatePicker, Form, Layout, message, Select, Space, Spin, Tag} from "antd";
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getPageCustomerName} from "../../../services/customer";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import moment from "moment";
import {multiPeriodContrast} from "../../../services/report";
import {DeleteOutlined} from '@ant-design/icons';
import Echart1 from '../js/echart_1'
import {getDataIgnoreLevel} from "../../../services/steamReport";
const { RangePicker } = DatePicker;
const { Option } = Select;

// 单客户多时段用量对比
export default class customersAnalysisPage extends Component{
    state={
        areaInfos:[],       // 所有区域详细数据
        dateError:'',       // 时间框样式控制
        tableEreeor:'',     // 表格样式
        formSelect:[],      // 被选用户下拉框
        formSelectData:[],  // 选择得数据
        formSelecterror:'', // 多时段选择

        deleSelectOne:null, // 删除选择的数据
        selectDate:null,    // 时间选择类型

        dates:{},
        dateInfo:[],
        value:null,
        juder:true,
        echartTitle:<div><span>请配置参数查询图表</span></div>,  // 对图表的提示内容控制
        // 柱状图数据
        echartData:{
            type:'bar', // line折线 bar柱状
            head:[],// 日期
            data:[]
        },

        // 控制器表格参数
        tableParamenter_1: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                id: '客户编号',
                name: '客户名称',
                type: '客户类型',
                principal: '联系人',
                principalPhone: '联系电话',
                number:'用气点数量',
                addressInfo: '详细地址',
                // g: '用气量数量',
                // h: '子客户数',
                // j: '通信时间',
            },

            rowData:[],
            params: {
                areaIds:[],
                page:1,
                size:5,

            },
            // 是否绑定左侧区域
            bindArea: false,
        },


        // 条件选择器参数_1
        topFormItem_1: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'areaIds', str: '区域选择', rules: TYPE_NAME.SELECTINPUT, data:[],
                    onChange: (k,v) => {
                        this.areaIdsSelect(k,v);
                    },
                },
                {
                    variableName: 'name',
                    str: '客户名称',
                    rules: TYPE_NAME.INPUT,
                },
                {
                    variableName: 'id',
                    str: '客户编号',
                    rules: TYPE_NAME.INPUT,
                },
                {
                    variableName: 'addressInfo',
                    str: '客户地址',
                    rules: TYPE_NAME.INPUT,
                }
            ]
        },
        topFormItem_data_1:[],

        // 条件选择器参数_2
        topFormItem_2: {

        },
        topFormItem_data_2:[],


        startDate:null,
        endDate:null,
    };
    recursionAreas=(areaId)=>{
        var areaIds=[];
        for(var i=0;i<this.state.areaInfos.length;i++){
            if(this.state.areaInfos[i].parentId===areaId){
                areaIds.push(this.state.areaInfos[i].id);
                var params=[];
                params = this.recursionAreas(this.state.areaInfos[i].id);
                params?.map(one=>{areaIds.push(one)})
            }
        }
        return areaIds
    };

    areaIdsSelect= (k,v) => {
        var tableParamenter_1 = this.state.tableParamenter_1;
        tableParamenter_1.params.areaIds=[k];
        var areaIds = this.recursionAreas(k);
        areaIds.map(one=>{tableParamenter_1.params.areaIds.push(one);});
        this.setState({tableParamenter_1: tableParamenter_1});
        this.table.refreshTable(tableParamenter_1.params);
    };

    // 初始化地图加载
    componentDidMount() {
        let user = JSON.parse(sessionStorage.getItem("user"));
        getDataIgnoreLevel({userId:user.id}).then(
            response => {
                const {data} = response.data;
                let selectArry=[];
                let areaIds =[];
                if(data){
                    this.setState({areaInfos:data});
                    for(var i=0;i<data.length;i++){
                        selectArry.push({key:data[i].id,value:data[i].name});
                        areaIds.push(data[i].id)
                    }
                }
                var tableParamenter = this.state.tableParamenter_1;
                tableParamenter.params.areaIds=areaIds;
                this.state.topFormItem_1.parameter[0].data=selectArry;
                this.setState({tableParamenter_1:tableParamenter});
                this.table.refreshTable(this.state.tableParamenter_1.params);
            }
        );
    }
    // 提交对比数据
    upContrastData=()=>{
        console.log("对比");
        let rowData = this.table.getSelectChange();
        var {formSelectData,formSelect} = this.state;// 被选中得数据
        if(rowData.length===0){return message.info('请选择客户');}
        if(formSelect.length===0){
            this.setState({formSelecterror:'error'});
            return message.info('请选择要对比得时段');
        }

        var params=[];
        // 配置参数
        formSelectData.forEach(one=>{
            var param={
                granularity: formSelectData[0].value || null,                     // 1:时，2:日，3：月，4：明细分析，5：峰谷，6：年
                id: rowData[0].id || null,                                        // 需要比较的数据id（客户/计量点/设备id）
                type: "客户",                                                     // 查询类型（客户，计量点，表具）
                startTime:one.dateFrame.startTime ?one.dateFrame.startTime : null,  // 开始时间
                endTime:one.dateFrame.endTime     ?one.dateFrame.endTime : null,    // 结束时间
                // "itemIdList":['1184'],
            };
            params.push(param);
        });
        this.setState({juder:true,echartTitle:<div><Spin/><span>加载中</span></div>});
        // 查询数据
        multiPeriodContrast(params).then(
            response=>{
                if(response?.data?.data){
                    var echartData = this.state.echartData; // 数据表
                    var data = response?.data?.data;        // 响应数据
                    echartData.head=[];
                    echartData.data=[];
                    // 循环获取表头
                    var dataType = formSelectData[0].value==='1'?"小时": formSelectData[0].value==='2'?"日": formSelectData[0].value==='3'?"月": formSelectData[0].value==='4'?"明细分析": formSelectData[0].value==='5'?"峰谷":"年";
                    for(var i = 0; i<data.length;i++){
                        if(data[i]?.detailList){
                            for(var o=1;o<data[i]?.detailList.length+1;o++){
                                // 表头
                                var stringName=("第"+o+dataType);
                                echartData.head.push(stringName)
                            }
                            break;
                        }
                    }
                    // 拼凑数据
                    for(var a = 0; a<data.length;a++){
                        var param ={
                            label:data[a].startTime+"至"+data[a].endTime,
                            values:[]
                        };
                        if(data[a]?.detailList){
                            for(var b=0;b<data[a]?.detailList.length;b++){
                                param.values.push(data[a]?.detailList[b].value) // 数据
                            }
                        }else{
                            for(var d=0;d<echartData.head.length;d++){
                                param.values.push(0)
                            }
                        }
                        echartData.data.push(param);
                    }
                    return this.setState({echartData: echartData,juder:false});
                } else {
                    message.info("暂无数据");
                    this.setState({echartTitle:<div><span>暂无数据</span></div>})
                }
                this.setState({juder:true});
            }
        );
    };


    // 表单数据
    onFinish_1=(value)=>{
        console.log("表单数据");
        var formSelect = [...this.state.formSelect];
        var formSelectData = this.state.formSelectData;
        // 判断条件符合情况-----
        // 判断添加数量
        if(formSelect.length>=4){return message.info('对比数量超过加载量');}

        // 判断时间类型和时间范围是否一致-----
        // 是否选择时间
        if(!this.state?.dates?.startTime){return  this.setState({dateError:'1px solid #f5222d'});}

        if(this.state.selectDate==='3'){
            // function monthsBetween(dstart, dend) {
            //     const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            //     let result = [];
            //     let current = dstart.getMonth();
            //     let end = (dend.getFullYear() - dstart.getFullYear()) * 12 + dend.getMonth();
            //     for (;current <= end; current += 1) {
            //         result.push(monthNames[current % 12]);
            //     }
            //     return result;
            // }
            const start_date = new Date(moment(this.state.dates.startTime,"YYYY-MM-DD 00:00:00"));
            const end_date = new Date(moment(this.state.dates.endTime,"YYYY-MM-DD HH:00:00"));
            let start_date_num = start_date.getMonth();
            let end_date_num = (end_date.getFullYear() - start_date.getFullYear()) * 12 + end_date.getMonth();
            console.log(end_date_num-start_date_num);
            // 循环详情时间数据-判断时间长度是否符合要求
            for(var a =0;a<formSelectData.length;a++){
                if(formSelectData[a].value.indexOf(value.dateType)<=-1){
                    return message.info('添加时间类型必须一致')
                }

                const start_date_1 = new Date(moment(formSelectData[a].dateFrame.startTime,"YYYY-MM-DD 00:00:00"));
                const end_date_1 = new Date(moment(formSelectData[a].dateFrame.endTime,"YYYY-MM-DD 00:00:00"));
                let start_date_num_1 = start_date_1.getMonth();
                let end_date_num_1 = (end_date_1.getFullYear() - start_date_1.getFullYear()) * 12 + end_date_1.getMonth();



                console.log(end_date_num_1-start_date_num_1);
                if((end_date_num-start_date_num)!==(end_date_num_1-start_date_num_1)){
                    this.setState({dateError:'1px solid #f5222d'});
                    return message.info('时间范围与之前得选择范围不相同')
                }
            }

        }else {
            let start_date  = moment(this.state.dates.startTime,"YYYY-MM-DD HH");
            let end_date    = moment(this.state.dates.endTime,"YYYY-MM-DD HH");
            let seconds     = end_date.diff(start_date,"seconds");
            // 循环详情时间数据-判断时间长度是否符合要求
            for(var i =0;i<formSelectData.length;i++){
                if(formSelectData[i].value.indexOf(value.dateType)<=-1){
                    return message.info('添加时间类型必须一致')
                }
                let start_1_date  = moment(formSelectData[i].dateFrame.startTime,"YYYY-MM-DD HH");
                let end_1_date    = moment(formSelectData[i].dateFrame.endTime,"YYYY-MM-DD HH");
                let seconds_1 = end_1_date.diff(start_1_date,"seconds");

                if(seconds!==seconds_1){
                    this.setState({dateError:'1px solid #f5222d'});
                    return message.info('时间范围与之前得选择范围不相同')
                }
            }
        }



        // //------------------数据保存------------------------
        var dateTime = this.state.dates;
        var DateString = "时间段"+ (formSelect.length+1)+ " "+
            // (value.dateType==='1'?'时':value.dateType==='2'?'日':value.dateType==='3'?'月':'峰')+
            ":"+ (dateTime.startTime)+ "至"+ (dateTime.endTime);
        // 判断所选时间范围是否重复
        for(var o=0;o<formSelectData.length;o++){
            if(formSelectData[o].dateFrame.startTime===dateTime.startTime | formSelectData[o].dateFrame.endTime===dateTime.endTime ){
                return message.info('当前所选范围已存在')
            }}
        // 展示页面数据
        formSelect.push([DateString]);
        // 上传参数
        formSelectData.push({
            value:value.dateType,
            label: DateString,
            dateFrame:{
                startTime:dateTime.startTime,
                endTime:dateTime.endTime
            }
        });
        this.setState({formSelect:formSelect,formSelecterror:''})
    };

    // 表格-查询
    getConditionsValue= async value =>{
        let data = await this.forms.onFinishNotReset();
        if(!data){return false}
        var tableParamenter = this.state.tableParamenter_1;
        tableParamenter.params.name=data.name;
        tableParamenter.params.id=data.id;
        tableParamenter.params.addressInfo=data.addressInfo;
        await this.table.refreshTable(tableParamenter.params);
    };
    // 日期选择
    getRangePicker=()=>{
        switch (this.state.selectDate) {
            case "3":
                return<>
                    <div>
                        <div>
                            <RangePicker
                                key={this.state.timeSelect}
                                size="small"
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={"YYYY-MM"}
                                picker={"month"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
            default:
                return<>
                    <div>
                        <div>
                            <RangePicker
                                key={this.state.timeSelect}
                                disabledDate = {(current) => {
                                    if(!this.state.dateInfo){return false;}
                                    var dateNum =30;
                                    if(this.state.formSelectData.length!==0){

                                        var  startDate = Date.parse(moment(this.state.formSelectData[0].dateFrame.startTime).format('YYYY-MM-DD'));
                                        var  endDate = Date.parse(moment(this.state.formSelectData[0].dateFrame.endTime).format('YYYY-MM-DD'));
                                        if (startDate>endDate){
                                            return 0;
                                        }
                                        if (startDate===endDate){
                                            return 1;
                                        }
                                       dateNum=(endDate - startDate)/(1*24*60*60*1000);
                                    }
                                    const tooLate = this.state.dateInfo[0] && current.diff(this.state.dateInfo[0], 'days') > dateNum;
                                    const tooEarly = this.state.dateInfo[1] && this.state.dateInfo[1].diff(current, 'days') > dateNum;
                                    return !!tooEarly || !!tooLate;
                                }}
                                size="small"
                                style={{border: this.state.dateError}}
                                showTime={this.state.selectDate==='1'?{ format: 'HH' }:false}
                                format={this.state.selectDate==='1'?'YYYY-MM-DD HH':"YYYY-MM-DD"}
                                onChange={(d,v)=>{this.upDate(d,v)}}
                                onCalendarChange={(d,v)=>{
                                    this.setState({dateInfo:d});
                                }}
                            >
                            </RangePicker></div>
                        <div style={{color:'#f5222d',display:this.state?.dateError?'':'none'}}>请选择时间范围</div>
                    </div>
                </>;
        }
    };
    // 时间范围保存
    upDate=(d,v=[])=>{
        // 检查参数
        this.setState({dateError:''});
        if(v[0]===""||v[1]===""){
            this.setState({dateError:'1px solid #f5222d'});
            return message.info('请选择时间范围');
            // return false;
        }
        let params = this.state.dates;
        params.startTime=v[0];
        params.endTime=v[1];
        this.setState({dates:params});
    };

    // 表格-样式
    setTableValue=(value,text,rowData)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'addressInfo':
                return <div className={'table_overflow'} title={'地址'}>{
                    (rowData.province?rowData.province:'')
                    +(rowData.city?rowData.city:'')
                    +(rowData.district?rowData.district:'')
                    +(rowData.street?rowData.street:'')
                }</div>;
            case 'type':
                text = text*1;
                return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : '';
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    render() {
        return (
            <Layout  className={'item_body_number_back tables'}>
                <div>
                    {/*----------------上-----------------*/}
                    <div className={'topSearchBox'}>
                        <h3>单客户多时段用量对比</h3>
                        <div style={{position:'relative'}}>
                            <div style={{width:'90%'}}>
                                <FormsTwo
                                    ref={e => this.forms = e}
                                    formParamenter={this.state.topFormItem_1}
                                    data={this.state.topFormItem_data_1}
                                />
                            </div>
                            <Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
                        </div>
                    </div>
                    {/*----------------上END---------------*/}

                    {/*-----------------中----------------*/}
                    <div style={{
                        widht:'100%',
                        height:'400px',
                        padding:'10px 0 0 0',
                        background:'#fff',

                    }}
                    >
                        <TableComponents
                            paramenter={this.state.tableParamenter_1}
                            setData = {getPageCustomerName}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        />
                    </div>
                    {/*-----------------中END--------------*/}

                    {/*-----------------下----------------*/}
                    <div style={{widht:'100%',height:'315px',backgroundColor: '#fff',padding:'10px'}}>
                        <div style={{width:'100%',height:'55px'}}>
                            <div style={{width:'90%',float:'left'}}>
                                <Form
                                    id="form"
                                    name="dynamic_form_nest_item"
                                    ref={e => this.form = e}
                                    onFinish={(value)=>{
                                        this.onFinish_1(value)
                                    }}
                                    style={{width: '100%',height:'50px' }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    <div style={{ width: '300px',float:'left' }}>
                                        <Form.Item
                                            label="时间尺度"
                                            name="dateType"
                                            rules={[{ required: true, message: '请选择时间尺度' }]}
                                        >
                                            <Select
                                                // mode="multiple"
                                                optionLabelProp="label"
                                                size="small"
                                                placeholder="时间类型"
                                                onChange={(a)=>{
                                                    this.setState({
                                                        selectDate:a,
                                                        formSelect:[],
                                                        formSelectData:[],
                                                        timeSelect: new Date(),
                                                        dates:{}
                                                    })
                                                }}
                                                maxTagCount={'responsive'}
                                            >
                                                <Option value={'1'} label={'小时'}>小时</Option>
                                                <Option value={'2'} label={'日'}>日</Option>
                                                <Option value={'3'} label={'月'}>月</Option>
                                                <Option value={'5'} label={'峰谷'}>峰谷</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div style={{ width: '400px',float:'left', paddingTop:'4px',}}>

                                        {this.getRangePicker()}
                                    </div>
                                    <div style={{ width: '500px',float:'left',paddingTop:'4px'}}>
                                        <span >多时段列表:</span>
                                        <Cascader
                                            status={this.state.formSelecterror}
                                            style={{ width: '75%',marginLeft:'5px'}}
                                            size="small"
                                            value={this.state.formSelect}
                                            multiple
                                            onChange={(value)=>{
                                                var formSelectData = this.state.formSelectData;
                                                if(value.length===0){
                                                    formSelectData=[];
                                                }else{
                                                    // 删除保存详细时段内符合数据
                                                    for(var a =0;a<formSelectData.length;a++){
                                                        var juder=true;
                                                        for(var i =0;i<value.length;i++){
                                                            if(value[i][0]===formSelectData[a].label){
                                                                juder=false;
                                                            }
                                                        }
                                                        if(juder){
                                                            formSelectData.splice(a,1)
                                                        }
                                                    }
                                                }
                                                this.setState({formSelect:value,formSelectData:formSelectData})
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: '100px',float:'left'}}>
                                        <Button type="link" htmlType="submit">
                                            将该时段加入对比
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                            <div style={{width:'10%',float:'right'}}>
                                <Button type="primary" onClick={()=>{this.upContrastData();}}>
                                    对比
                                </Button>
                            </div>
                        </div>

                        {/*/!*柱状图*!/*/}
                        {/*<div style={{widht:'90%',height:'85%'}}>*/}
                        {/*    <Echart1*/}
                        {/*        dataType={this.state.echartData.type}*/}
                        {/*        elementById={'main'}// id*/}
                        {/*        head={this.state.echartData.head}// 日期*/}
                        {/*        data={this.state.echartData.data}// 数值*/}
                        {/*    />*/}
                        {/*</div>*/}

                        {this.state.juder?
                            <div style={{widht:'100%',height:'85%',textAlign: 'center',backgroundColor:'rgba(222, 222, 222, 0.05)', paddingTop: '80px',
                                fontSize:'18px',  fontWeight: 'bold',color:'#b9b9b9'}} >
                                {this.state.echartTitle}
                            </div>
                            :
                            <div style={{widht:'100%',height:'85%'}}>
                                <Echart1
                                    dataType={this.state.echartData.type}
                                    elementById={'main'}// id
                                    head={this.state.echartData.head}// 日期
                                    data={this.state.echartData.data}// 数值
                                />
                            </div>
                        }

                    </div>
                    {/*-----------------下END--------------*/}
                </div>
            </Layout>
        );
    }
}
