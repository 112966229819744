import React, { Component } from 'react'
import { BrowserRouter as Router, Route,Switch,Redirect } from "react-router-dom"
import {route} from './route/route'
import ErrorPage from './pages/error/ErrorPage'

class App extends Component {
  render() {

    localStorage.setItem("url",window.location.pathname);

    return (
      <Router>
        <Switch>
          {
            route.map(value => {
              return <Route key={value.path} path={value.path} component={value.component} />
            })
          }
          <Redirect from={'/'} to={'/login'} />
          <Route component={ErrorPage}/>
        </Switch>
      </Router>
    )
  }
}
export default App
