import React,{Component} from 'react';
import {Button, Layout, message} from "antd";
import {PoweroffOutlined,AppstoreOutlined} from '@ant-design/icons';

import './meterMap_div.less'
import './meterMap_type.less'
import LeftType from './js/leftType'// 左侧上部分样式
import MiddleType from './js/middleType'// 中部样式
import RightType from './js/rightType'
import moment from "moment";
import {getDateByUserId} from "../../../services/admin";
import image from "../../dataIntegration/images/index-bg.png";
// 右侧样式
// 表具地图
export default class meterMap extends React.Component{
    state = {
        headLeftDate:'22',  // 时间
        areaIds:[],         // 地址
        loginType:JSON.parse(sessionStorage.getItem("loginType")),
    };
    componentDidMount(): void {

        this.getTime(); // 初始化加载当天时间
        this.getDateByUserId();
    }

    getTime=()=>{
        //获得显示时间的div
        var now=new Date();
        //替换div内容
        console.log(now.getDay());
        var t_div  =  moment(now).format("yyyy-MM-DD")+("  星期"+now.getDay());
        console.log(t_div);

        this.setState({headLeftDate:t_div});
        // setTimeout(this.getTime(),1000);
    };
    // 获取当前用户区域id
    getDateByUserId=()=>{
        getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
            response => {
                if (response?.data) {
                    var areaIds = this.state.areaIds;
                    console.log(response?.data);
                    response?.data?.map(one=>{
                        areaIds.push(one.id.toString());
                        one?.children?.map(two=>{
                            areaIds.push(two.id.toString());
                        })
                    });
                    this.setState({areaIds})
                } else {
                    return message.info('获取不到数据');
                }
            }
        )
    };

    render(){
        return (
            <Layout className={'item_body_number_back reportX'}>
                <div id={'meterMap'}>
                    {/*表头*/}
                    <div className={'head_div'}>
                        <div style={{'width':' 30%', 'height': '80%',padding:'1% 0 0 3%',float:'left',color:'#45909c',fontSize:'18px'}}>
                            {this.state.headLeftDate}
                        </div>
                        <div style={{'width':' 40%', 'height': '100%',float:'left',  textAlign: 'center',  paddingTop:'0.5%'}}>
                            <span style={{color:'#3ebdd8',fontSize:'36px',  fontWeight: 'bold'}}>智慧能源平台</span>
                        </div>
                        <div style={{'width':' 30%', 'height': '100%', float:'left'}}>
                            <div style={{float:'right',padding:'5%'}}>
                                <Button
                                    icon={<AppstoreOutlined style={{ fontSize: '16px', color: 'Aqua ' }} />}
                                    style={{ backgroundColor: "rgba(0, 0, 0, 0.85)",color:'#fff'}}
                                    onClick={()=>{
                                        // this.props.history.go(-1)
                                        this.props.history.replace('/customer');
                                    }}
                                >
                                    后台管理
                                </Button>
                                {/*<AppstoreOutlined style={{ fontSize: '28px',color:'#4a97a2',cursor:'pointer'}} onClick={()=>{*/}
                                {/*    // this.props.history.go(-1)*/}
                                {/*    this.props.history.replace('/customer');*/}
                                {/*}} />后台管理*/}
                            </div>
                        </div>
                    </div>
                    {/*表头END------------*/}
                    {/*内容*/}
                    <div className={'body_div'}>
                        {/*左框表头END------*/}
                        <div className={'body_left_div'}>
                            <LeftType
                                areaIds={this.state.areaIds}
                            />
                        </div>
                        {/*左边END------*/}
                        {/*中间*/}
                        <div className={'body_middle_div'} >
                            <MiddleType
                                areaIds={this.state.areaIds}
                            />
                        </div>
                        {/*中间END------*/}
                        {/*右边*/}
                        <div className={'body_right_div'} >
                            <RightType/>
                        </div>
                        {/*右边END------*/}
                    </div>
                    {/*内容------------*/}
                </div>
            </Layout>
        )
    }
}
