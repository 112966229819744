// 委托单管理页面
import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Select} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import moment from 'moment'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import {funTraceInfo} from '../../../services/common'

import {getEntrustList,allDelTemporaryAlarm,cancellationEntrust,getEntrustInvalid,getAcquisitionSheetList} from '../../../services/verifvication'
const { Option } = Select;
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				address: '地址',
				customerName: '客户名',
				entrustUnit: '客户单位',
				// updateTime: '提交时间',
				createDate: '创建时间',
				creator: '创建人',
				state: '状态',
				edit: '操作',

			},
			// 弹出框
			headers: {
				address: '地址',
				customerName: '客户名',
				entrustUnit: '客户单位',
				// updateTime: '提交时间',
				createDate: '创建时间',
				creator: '创建人',
				state: '状态',
			},
			params: {
				page:1,
				size:10,
				createTimeStart:'',
				createTimeEnd:''
			},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				// {
				// 	type: TYPE_NAME.NAME,
				// 	variableName: 'entrustNum',
				// 	str: '委托单号',
				// },
				{
					type: TYPE_NAME.NAME,
					variableName: 'entrustUnit',
					str: '委托单位'
				},
				{
					type: 'select',
					variableName: 'state',
					str: '状态',
					data: [
						{
							key: '已通过',
							value: '已通过'
						},
						{
							key: '驳回',
							value: '驳回'
						}
					]
				},
				{
					type: 'rangePicker',
					variableName: 'createTime',
					time: false,
					str: '时间'
				},

			]
		},
		    	// 总条数
		totalCount: 0,
		voidVisible:false, //作废窗口开关器-1
		voidId:'',
		entrustInvalidVisible:false, //结单窗口开关器-1
		entrustInvalidId:'',
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
	};

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
			text = rowData.state*1;
			return <>
				<NavLink to={{
					pathname: "/verifvication/collectionDetail",
					state: {
						id: rowData.id,
						state:rowData.state
					}
				}}>
				<div className={'tableLink'} title={'核验采集单'}>
						<u>{'核验采集单'}</u>
				</div>
				</NavLink>
			</>

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	updatePoint = () => {
		let rowData = this.table.getSelectChange();
		if(rowData.length < 1) return message.error('未选择数据');
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		};
		this.props.history.push({
			pathname: '/device/addDataItem',
			state: data
		})
	};
	componentDidMount(){
		console.log(this.state.params)

	}
	onChangeSelect = e => {
		console.log(111,e)
	};

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		console.log(value)
			// let sTime=createTime ? moment(createTime[0]._d).format("yyyy-MM-DD") : null;
			// let eTime=createTime ? moment(createTime[1]._d).format("yyyy-MM-DD") : null;
			let sTime=value.createTime ?moment(value.createTime[0]._d) : null;
			let eTime=value.createTime ?moment(value.createTime[1]._d) : null;
			let params = this.state.tableParamenter.params;
			let formParams=this.table.getParams()
			console.log(this.state)
			Object.assign(params,value)
			params.page=formParams.page
			params.size=formParams.size
			params.createTime=''
			params.createTimeStart=sTime;
			params.createTimeEnd=eTime;
			let {tableParamenter} = this.state;
			tableParamenter.params = params;
			this.setState({tableParamenter});
			await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state;
		  params.areaIds = data;
		  params.page = 1;
		  params.size = initParams.size;
		  this.setState({params});
		  this.getData()
		})
	  }
// 初始化表格数据
getData = async () => {
	this.setState({})
	this.table.refreshTable();
	// this.setState({loading: true})
	// this.getAreaId()
	// let menusStr = localStorage.getItem('menus')
	// let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : []
	// let {params} = this.state
	// params.areaIds=menus
	// getDataItemList(params).then(
	//   response => {
	// 	const {data} = response.data
	// 	const {pageInfo} = response.data
	// 	let total= pageInfo.total
	// 	let {tableParamenter} = this.state
	// 	tableParamenter.data = data
	// 	this.setState({tableParamenter,totalCount:total,loading: false})
	// 	message.success('查询成功',[0.5])
	//   }
	// ).catch(
	//   () => {
	// 	this.setState({
	// 	  loading: false,
	// 	  refreshTable: false,
	// 	})
	// 	message.error('查询失败',[0.5])
	//   }
	// )
  };
    // 作废委托单
	toVoidStatus=(voidVisible,id)=>{
		this.setState({voidVisible,voidId:id});
	};
	toVoid=(voidVisible)=>{
		cancellationEntrust({entrustId: this.state.voidId}).then(
				response => {
					console.log(response)
					this.setState({voidVisible})
					// funTraceInfo("报警器检定管理", "作废委托单", null,this.state.voidId)
					message.success('操作成功')
					this.table.refreshTable();
				}
			)
	};
    // 结单
	entrustInvalidStatus=(entrustInvalidVisible,id)=>{
		this.setState({entrustInvalidVisible,entrustInvalidId:id});
	};
	entrustInvalid=(entrustInvalidVisible)=>{
		getEntrustInvalid({entrustId: this.state.entrustInvalidId}).then(
				response => {
					console.log(response)
					this.setState({entrustInvalidVisible})
					// funTraceInfo("报警器检定管理", "委托单结单", null,this.state.entrustInvalidId)
					message.success('操作成功')
					this.table.refreshTable();
				}
			)
	};



	//下载窗口
	downloadStatus=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus,downloadId:id});
	};
	download=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus})
		console.log(this.state.downloadId)
	};

	downloadOpen=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/downloadByEntrustId?id='+alarmId)
	};
	downloadOpenNull=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/downloadBlankByEntrustId')
	};

	//预览窗口
	previewStatus=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus,previewId:id});
	};
	preview=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus})
		console.log(this.state.previewId)
	};

	previewOpen=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/previewByEntrustId?id='+alarmId)
	};
	previewOpenNull=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/previewBlankByEntrustId')
	};


	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	   this.setState({params:params});
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>核验采集单</h3>
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getAcquisitionSheetList}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title='作废'
                        style={{ top: 0 }}
                        visible={this.state.voidVisible}
                        okText="确定"
                        onOk={() => this.toVoid(false)}           // 点击确定
                        onCancel={() => this.toVoidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认作废该委托单吗？</h4>
                        </div>
                </Modal>
				<Modal
                        title='结单'
                        style={{ top: 0 }}
                        visible={this.state.entrustInvalidVisible}
                        okText="确定"
                        onOk={() => this.entrustInvalid(false)}           // 点击确定
                        onCancel={() => this.entrustInvalidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认结单吗？</h4>
                        </div>
                </Modal>
				<Modal
                        title='下载'
                        style={{ top: 0 }}
                        visible={this.state.downloadVisible}
                        okText="确定"
                        onOk={() => this.downloadStatus(false)}           // 点击确定
                        onCancel={() => this.downloadStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.downloadOpenNull(null,1)}>模板</span>
							<span onClick={() => this.downloadOpen(this.state.downloadId,0)}>委托单</span>
                        </div>
                    </Modal>
					<Modal
                        title='预览'
                        style={{ top: 0 }}
                        visible={this.state.previewVisible}
                        okText="确定"
                        onOk={() => this.previewStatus(false)}           // 点击确定
                        onCancel={() => this.previewStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.previewOpenNull(null,1)}>模板</span>
							<span onClick={() => this.previewOpen(this.state.previewId,0)}>委托单</span>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
