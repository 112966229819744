import React, { Component } from 'react'
import { Layout, Input, Form } from 'antd'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getAbnormalData,getAllManufacturerAndModel} from '../../../services/report'

const FormItem = Form.Item

export default class AbnormalData extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 是否绑定左侧区域
			bindArea: true,
			position: 'top',
            rowSelection: false,
			width: '83.7vw',
            title: '表具异常数据报表',
			exportUrl: "/web/reportExportCtrl/exportAbnormalData",
			// 表头：变量和变量解释
			header: {
				company: '公司',
				readUnit: '读表单元',
                sheetSteelGrade: '表钢号',
				installNumber: '安装',
				contractNumber: '合同',
                userName: '用户名称',
                userAddress: '用户地址',
				alarmType: '报警类型',
                alarmTime: '报警时间',
				setValue: '设定值',
				alarmValue: '报警值',
                alarmReason: '报警原由'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					{
						type: 'input',
						variableName: 'readUnit',
						str: '读表单元'
					},
					{
						type: 'input',
						variableName: 'steelNum',
						str: '表钢号'
					},
					{
						type: 'rangePicker',
						variableName: 'dataTime',
						time: true,
						str: '时间'
					},
					{
						type: 'input',
						variableName: 'contractNum',
						str: '合同'
					}
				]
			},
		},
	}
	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state
				let {data} = response.data
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				})
				this.setState({tableParamenter})
			}
		)
	}
	// 条件选择：获取输入的条件
	getConditionsValue = ({dataTime}) => (
		{
			startTime: dataTime ? moment(dataTime[0]?._d).format("YYYY-MM-DD HH:mm:ss") : null,
			endTime: dataTime ? moment(dataTime[1]?._d).format("YYYY-MM-DD HH:mm:ss") : null
		}
	)

    render() {
		var areaName = localStorage.getItem('areaName')??null;
		if(areaName==='undefined'){areaName=null}

		return (
            <Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
					paramenter={this.state.tableParamenter}
					setData={getAbnormalData}
					getConditionsValue={this.getConditionsValue}
					ref={e => this.table = e}
				>
					<FormItem label={"公司"}>
						<Input disabled value={areaName} size='middle'/>
					</FormItem>
				</TableComponents>
            </Layout>
        )
    }
}
