import React, { Component } from 'react'
import {Layout, Tabs, Descriptions, Button, Table, Popconfirm, Modal, Radio, message,Divider,Checkbox } from "antd";
import './orderFormGC.less';
import {
    getOrderInfosByOrderId,
    addOneDeviceToOrder,
    getDevInfosByClassId,
    deliveryApplying,
    getDeliverysByOrderId,
    getApplysByOrderId,
    deliveryByApply,
    replaceOneDeviceToOrder,
    delOneDeviceFromOrder,
    getReplaceInfosByOrderId, getModelInfoBySupplier, getDevInfosByOrderId
} from "../../../services/orderForm";
import FormsTwo from "../../../components/common/forms/FormsTwo";
import {TYPE_NAME} from "../../../components/Const";
import moment from "moment";
import {funTraceInfo} from '../../../services/common'
const { Header, Footer, Sider, Content } = Layout;
const { TabPane } = Tabs;



// -----------------------订单新奥详情页-----------------------
export default class orderFormGCDetails extends Component {
    state = {

        //按钮约束
        pointer_1:'auto',//auto/none 添加
        pointer_2:'auto',//auto/none 申请
        pointer_3:'auto',//auto/none 发货
        pointer_4:'auto',//auto/none 替换
        pointer_5:'auto',//auto/none 删除
        // 复选框使用
        disabled:1,
        disabled1:[],
        // 弹窗开关
        modalVisible:false, //弹窗-1-添加按钮
        modalVisible1:false,//弹窗-2-手动添加
        modalVisible2:false,//弹窗-2-批量导入
        modalVisible3:false,//弹窗-3-申请发货
        modalVisible4:false,//弹窗-4-发货
        modalVisible5:false,//弹窗-5-换表
        // 当前页标题栏-订单描述
        orderFormDes: {
            orderFormNo: "DD202212260001",
            createTime: "2022-7-12 18:00:01",
            completionTime: "",
            supplierCompany: "重庆市山城设备有限公司",
            counNum: "22",
            state: "测试中",
            supplierName: "罗成",
            supplierPhone: "1875998616"
        },// -----------------------------当前页标题栏-订单描述END---------------------------
        //----------------手动添加------------------
        tableModal_1:[
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum',},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard'},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName'},
            {title: '数据时间', dataIndex: 'applyTime', key: 'applyTime'},
            {title: '标况总量', dataIndex: 'total', key: 'total'},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow'},
            {title: '压力', dataIndex: 'pressure', key: 'pressure'},
            {title: '温度', dataIndex: 'temperature', key: 'temperature'},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus'},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult'},
            {title: '表具状态', dataIndex: 'state', key: 'state'},
            {width:100, title: '操作', dataIndex: 'operation',
                render: (_, record) =>
                    <Popconfirm title="确定删除?" onConfirm={() => this.deleteCommodity(record)}>
                        <a>删除</a>
                    </Popconfirm>
            },
        ],
        tableModal_1_data:[],// 数据源
        // 第一页的手动发货参数
        paginationProps:{
            areaId:"",
            steelNum:"",
            total: '', // 数据总数
            size: '12', // 每页条数
            page: '1', // 当前页码
        },
        // 弹窗-2-手动添加-条件查询栏
        topFormItem: {
            modal: true,
            number: 4,
            labelCol: 6,
            layout: "horizontal",
            parameter: [
                {variableName: 'outModelName',  str: '型号', data:[], rules: TYPE_NAME.REQ_SELECT,},
                {variableName: 'steelNum', str: '表刚号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'simCard', str: 'SIM号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'productionTime', str: '生产日期', rules: TYPE_NAME.REQDATE_PICKER,},
                {variableName: 'communicationNo', str: '通信', rules: TYPE_NAME.INPUT,},
                {variableName: 'imeiNum', str: 'IMEI', rules: TYPE_NAME.INPUT,},
            ]
        },
        searchGetData:{
        },// 弹窗-2-手动添加-条件查询栏-数据源


        // 替换表参数
        ReplaceCommoditForm: {
            modal: true,
            number: 4,
            labelCol: 6,
            layout: "horizontal",
            parameter: [
                {variableName: 'outModelName', str: '型号', rules: TYPE_NAME.NOT_BLANK,disabled: true,},
                {variableName: 'steelNum', str: '表刚号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'simCard', str: 'SIM号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'productionTime', str: '生产日期', rules: TYPE_NAME.REQDATE_PICKER,},
                {variableName: 'communicationNo', str: '通信', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'imeiNum', str: 'IMEI', rules: TYPE_NAME.INPUT,},
            ]
        },
        ReplaceCommoditFormData:{},// 修改修改的表具
        tableModa_2:[
            // {title: '申请发货号', dataIndex: 'applyNum', key: 'applyNum',
            //     onCell:(text,index)=>(this.sharedOnCell_tableData_1(text, index))
            // },
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum',},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard'},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName'},
            {title: '数据时间', dataIndex: 'applyTime', key: 'applyTime'},
            {title: '标况总量', dataIndex: 'total', key: 'total'},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow'},
            {title: '压力', dataIndex: 'pressure', key: 'pressure'},
            {title: '温度', dataIndex: 'temperature', key: 'temperature'},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus'},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult'},
            {title: '表具状态', dataIndex: 'state', key: 'state',
                render: (text, record) =>{
                    switch (record.state) {
                        case "已批准":
                            return<><span style={{"color": "#7cb305"}}>{text}</span></>;
                        case "已发货":
                            return<><span style={{"color": "#f5222d"}}>{text}</span></>;
                        case "申请发货":
                            return<><span style={{"color": "coral"}}>{text}</span></>;
                        case "批准发货":
                            return<><span style={{"color": "7cb305"}}>{text}</span></>;
                        default:
                            return<><span >{text}</span></>;
                    }
                }
            },
        ],

        tableModa_3:[


            // {title: ' ', dataIndex: 'applyNum', key: 'applyNum',
            //     onCell:(text,index)=>(this.sharedOnCell_tableData_1(text, index)),
            //     render:(a,b)=>{
            //         return<>
            //             <Checkbox onChange={this.CheckboxonChange()}></Checkbox>
            //         </>
            //     }
            // },
            {title: '申请发货号', dataIndex: 'applyNum', key: 'applyNum',
                onCell:(text,index)=>(this.sharedOnCell_tableData_1(text, index)),
            },
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum',},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard'},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName'},
            {title: '数据时间', dataIndex: 'applyTime', key: 'applyTime'},
            {title: '标况总量', dataIndex: 'total', key: 'total'},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow'},
            {title: '压力', dataIndex: 'pressure', key: 'pressure'},
            {title: '温度', dataIndex: 'temperature', key: 'temperature'},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus'},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult'},
            {title: '表具状态', dataIndex: 'state', key: 'state',
                render: (text, record) =>{
                switch (record.state) {
                    case "已批准":
                        return<><span style={{"color": "#7cb305"}}>{text}</span></>;
                    case "已发货":
                        return<><span style={{"color": "#f5222d"}}>{text}</span></>;
                    case "申请发货":
                        return<><span style={{"color": "coral"}}>{text}</span></>;
                    case "批准发货":
                        return<><span style={{"color": "7cb305"}}>{text}</span></>;
                    default:
                        return<><span >{text}</span></>;
                }
                }
            },
        ],
        tableModal_3_data:[],//数据源

        tableModal_3_selete:[], // 弹窗_申请发货_复选框
        tableModal_4_selete:[], // 弹窗_发货_复选框
        tableModa3Page:{
            areaId:"",
            steelNum:"",
            total: '', // 数据总数
            size: '12', // 每页条数
            page: '1', // 当前页码
        },
        // 弹窗-2-手动添加-条件查询栏
        topFormItem1: {
            modal: true,
            number: 4,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'consignor', str: '发货人',   rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'consignorPhone', str: '联系电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL,},
                {variableName: 'logisticsCompany', str: '物流公司', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'logisticsPhone', str: '物流联系电话', rules: TYPE_NAME.PHONE_NUM_NOT_NULL,}
            ]
        },

        searchGetData1:{},// 弹窗-2-手动添加-条件查询栏-数据源
        //-----------------------------------------第一页的申请发货END------------------------


        // ------------------第二页-申请发货END---------------------
        tableData_2_data:[ ],
        tableData_2:[
            {title: '申请发货号', dataIndex: 'applyNum', key: 'applyNum',
                onCell:(text,index)=>(this.sharedOnCell(text, index))
            },
            {title: '表钢号', dataIndex: 'steelNum', key: 'steelNum',},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard'},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName'},
            {title: '数据时间', dataIndex: 'applyTime', key: 'applyTime'},
            {title: '标况总量', dataIndex: 'total', key: 'total'},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow'},
            {title: '压力', dataIndex: 'pressure', key: 'pressure'},
            {title: '温度', dataIndex: 'temperature', key: 'temperature'},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus'},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult'},
            {title: '表具状态', dataIndex: 'state', key: 'state',
                render: (text, record) =>{
                    switch (record.state) {
                        case "已批准":
                            return<><span style={{"color": "#7cb305"}}>{text}</span></>;
                        case "已发货":
                            return<><span style={{"color": "#f5222d"}}>{text}</span></>;
                        case "申请发货":
                            return<><span style={{"color": "coral"}}>{text}</span></>;
                        case "批准发货":
                            return<><span style={{"color": "7cb305"}}>{text}</span></>;
                        default:
                            return<><span>{text}</span></>;
                    }
                }
            },
        ],


        // -----------------------------------第二页-申请发货END-------------------------------------
        // -------------------第一页-订单详情-左表-----------------------
        leftTableDatas:[],// 数据源-暂时无用
        // 第一页-订单详情-左表
        leftTableColumns: [
            {title: '设备型号', dataIndex: 'model', key: 'model',},
            {title: '台数', dataIndex: 'quantity', key: 'quantity',},
            {title: '已有', dataIndex: 'alreadyNum', key: 'alreadyNum',},
        ],
        // ----------------------------------第一页-订单详情-左表END----------------------------------
        // ------------------第一页-订单详情-右表-----------------
        tableData_1_right:[
            {title: '表刚号', dataIndex: 'steelNum', key: 'steelNum',},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard',},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName',},
            {title: '数据时间', dataIndex: 'applyTime', key: 'applyTime',},
            {title: '标况总量', dataIndex: 'total', key: 'total',},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow',},
            {title: '压力', dataIndex: 'pressure', key: 'pressure',},
            {title: '温度', dataIndex: 'temperature', key: 'temperature',},
            {title: '阀门状态', dataIndex: 'valueStatus', key: 'valueStatus',},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult',},
            {title: '表具状态', dataIndex: 'state', key: 'state',
                render: (text, record) =>{
                    switch (record?.state) {
                        case "已批准":
                            return<><span style={{"color": "#7cb305"}}>{text}</span></>;
                        case "已发货":
                            return<><span style={{"color": "#f5222d"}}>{text}</span></>;
                        case "申请发货":
                            return<><span style={{"color": "coral"}}>{text}</span></>;
                        case "批准发货":
                            return<><span style={{"color": "7cb305"}}>{text}</span></>;
                        default:
                            return<><span>{text}</span></>;
                    }
                }
            },
        ],
        tableData_1_right_data:[],// 数据源
        rightTablepage:{
            classId:null,
            areaId:"",
            steelNum:"",
            total: 0, // 数据总数
            size: '9', // 每页条数
            page: '1', // 当前页码
        },  // -----------------------------------第一页-订单详情-右表END------------------------------------

        tableData_3_page:{
            areaId:"",
            steelNum:"",
            total: '', // 数据总数
            size: '12', // 每页条数
            page: '1', // 当前页码
        },
        tableData_3_data:[],// 第三页-表格内容
        // 第三页-表格标题
        tableData_3: [
            {title: '表钢号', dataIndex: 'sheetSteelGrade', key: 'sheetSteelGrade'
                // , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"sheetSteelGrade"))
            },
            {title: '型号', dataIndex: 'model', key: 'model'
                // ,onCell:(text,index)=>(this.sharedOnCellmod(text, index))
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"model","deliveryId"))
            },
            {title: '发货时间', dataIndex: 'deliveryTime', key: 'deliveryTime'
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"deliveryTime","deliveryId"))},
            {title: '发货人', dataIndex: 'consignor', key: 'consignor'
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"operatorName","deliveryId"))},
            {title: '联系电话', dataIndex: 'consignorPhone', key: 'consignorPhone'
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"buyerPhone","deliveryId"))},
            {title: '快递单号', dataIndex: 'logisticsNo', key: 'logisticsNo'
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsNo","deliveryId"))},
            {title: '物流公司', dataIndex: 'logisticsCompany', key: 'logisticsCompany'
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsCompany","deliveryId"))},
            {title: '物流联系电话', dataIndex: 'logisticsPhone', key: 'logisticsPhone'
                , onCell: (text, index) => (this.sharedOnCell_tableData_3(text, index,"logisticsPhone","deliveryId"))},
        ],// ----第3页-表格标题
        tableData_3_param:{
            total:0,
            page:1,
            size:100,
        },
        tableData_4_data:[],
        tableData_4: [
            {title: '表钢号', dataIndex: 'oldSteelNum', key: 'oldSteelNum',},
            {title: '被换表刚号', dataIndex: 'newSteelNum', key: 'newSteelNum',},
            {title: '型号', dataIndex: 'outModelName', key: 'outModelName'},
            {title: 'SIM卡号', dataIndex: 'simCard', key: 'simCard'},
            {title: '数据时间', dataIndex: 'replaceTime', key: 'replaceTime'},
            {title: '标况总量', dataIndex: 'total', key: 'total'},
            {title: '工况总量', dataIndex: 'workCumulativeFlow', key: 'workCumulativeFlow'},
            {title: '压力', dataIndex: 'pressure', key: 'pressure'},
            {title: '温度', dataIndex: 'temperature', key: 'temperature'},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus'},
            {title: '测试结果', dataIndex: 'testResult', key: 'testResult'},
            {title: '表具状态', dataIndex: 'state', key: 'state'},
        ],
        // ----表格参数

        //--------------------------------中层END----------------------------------




        // 表格参数
        tableData_3_Paramenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                orderFormNo: '订单编号',
                createTime: '创建时间',
                completionTime: '完成时间',
                supplierCompany:'供应厂家',
                theTotal: '台数',
                state: '订单状态',
                edit: '操作',
            },
            // 弹出框
            headers: {
                orderFormNo: '订单编号',
                createTime: '创建时间',
                completionTime: '完成时间',
                supplierCompany:'供应厂家',
                theTotal: '台数',
                state: '订单状态'
            },
            params: {
                id:this.props.location.state?.orderId
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
    };

    CheckboxonChange=(a,b,c)=>{
        console.log("a");
        console.log(a);
        console.log(b);
        console.log(c);
    };


    // 标签页_发货单列表3_表格样式
    sharedOnCell_tableData_3 = (text,index,dataIndex,key) => {
        let dataSource = this.state.tableData_3_data;
        let num1 =0;

        if(dataSource[index][dataIndex]===null){
            return {rowSpan:1};
        }
        // 判断上层信息，控制本层是否渲染展示
        if(index!==0){
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1][key]===dataSource[index][key]){
                if(dataSource[num1][dataIndex]===dataSource[index][dataIndex]){
                    return {rowSpan:0};
                }
                // return {rowSpan:1};
            }
        }
        // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的

        for(let i =index+1;i<dataSource.length;){
            if(index===4){
            }
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值

            if(dataSource[index][key]===dataSource[i][key]){
                if(dataSource[index][dataIndex]===dataSource[i][dataIndex]){
                    num3++;
                }else{
                    return {rowSpan: num3};
                }
                i++;
            }else{
                return {rowSpan: num3};
            }

        }
        return {rowSpan:num3}
    };// ----标签页_发货单列表3_表格样式
    sharedOnCell_tableData_1 = (text, index,) => {
        let dataSource = this.state.tableModal_3_data;
        let num1 =0;
        // 先判断本层不能是最前面的
        // 该处判断下层是否和上层一致，从而屏蔽
        if(index!==0){
            // console.log(dataSource[index].applyNum);
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1].applyNum===dataSource[index].applyNum){
                return {rowSpan:0};
            }
        }
        // // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的
        for(let i =index+1;i<dataSource.length;){
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值
            if(dataSource[index].applyNum===dataSource[i].applyNum){
                num3++;
            }else{
                return {rowSpan: num3};
            }
            i++;
        }
        return {rowSpan:num3}
    };

    // 发货单数据合并方法
    sharedOnCell = (text, index,) => {
        let dataSource = this.state.tableData_2_data;
        let num1 =0;
        // 先判断本层不能是最前面的
        // 该处判断下层是否和上层一致，从而屏蔽
        if(index!==0){
            // console.log(dataSource[index].applyNum);
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1].applyNum===dataSource[index].applyNum){
                return {rowSpan:0};
            }
        }
        // // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的
        for(let i =index+1;i<dataSource.length;){
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值
            if(dataSource[index].applyNum===dataSource[i].applyNum){
                num3++;
            }else{
                return {rowSpan: num3};
            }
            i++;
        }
        return {rowSpan:num3}
    };

    // 配置表格操作功能
    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-'; // 判断参数是否为空
        switch (value) {
            case 'edit':
                text = rowData.state;
                return <>
                    <div className={'tableLink'}
                         title={'查看'}
                    >
                        <u>{'查看'}</u>

                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    // // 表格选择框
    // onChangeSelect = e => {
    //     console.log(111,e)
    // };
    //---------------------------------------------------中间方法END---------------------------------------------
    // 标题内容
    orderTitle=()=>{
        return <>
            <div>
                <span style={{"color": "rgba(64,149,229,1)"}}>订单号:{this.state.orderFormDes.orderFormNo}</span>
            </div>
        </>
    }; // ----标题内容



    // 页面初始化
    componentDidMount=()=>{
        this.forClassId();
        this.getOrderInfosByOrderId();
        this.setState({pointer_1:'auto',pointer_2:'auto',pointer_3:'auto',pointer_4:'auto',pointer_5:'auto'})

    };// ----页面初始化

    // PC端甲方(新奥)指定订单id查询订单
    getOrderInfosByOrderId=()=>{

        getOrderInfosByOrderId({id:this.props.location.state?.orderId}).then(
            data=>{
                if(data?.data?.data){
                    let topFormItem = this.state.topFormItem;
                    let leftTableDatas = [...this.state.leftTableDatas];
                    leftTableDatas=[];
                    topFormItem.parameter[0].data=[];
                    let orderFormDes = this.state.orderFormDes;
                    orderFormDes=data?.data?.data;
                    orderFormDes?.dtoClassificationList.forEach(one=>{
                        leftTableDatas.push(one);
                        topFormItem.parameter[0].data.push({key:one.model,value:one.model})
                    });
                    this.setState({orderFormDes:orderFormDes,topFormItem:topFormItem,leftTableDatas:leftTableDatas});

                }
            }
        )
    };// ----PC端甲方(新奥)指定订单id查询订单

    forClassId=()=>{// 遍历分类数据id获取全部商品
        getDevInfosByOrderId({id:this.props.location.state?.orderId}).then(item=>{
            let tableData_1_right_data = [...this.state.tableData_1_right_data];
            tableData_1_right_data=[];
            item.data.data.deviceAndDataInfos.forEach(one=>{
                console.log(one);
                tableData_1_right_data.push(one.deviceInfo);
            });
            this.setState({tableData_1_right_data})
        })
    };
    // 窗口_1_开关单选框选择
    onChangeRadio = (e) => {
        this.setState({disabled:e.target.value})
    };

    // 弹窗开关-1-添加弹窗
    selectModal1=(data)=>{
        this.setState({modalVisible:data});
    };

    JumpModalSelect=()=>{
        switch (this.state.disabled.toString()) {
            case "1":
                this.JumpModal1_2(false);
                break;
            case "2":
                this.JumpModal1_5(false);
                break;
            default:
                message.info("请选择操作功能");
                break;
        }
    };
    // 1-2弹窗中转站
    JumpModal1_2=(data)=>{
        this.selectModal1(data);        //1
        this.selectModal2(true);   //2
    };
    // 1-5弹窗中转站
    JumpModal1_5=(data)=>{
        this.selectModal1(data);        //1
        this.selectModal5(true);   //5
    };
    // 弹窗开关-2-手动添加
    selectModal2=(data)=>{
        this.setState({modalVisible1:data});
    };
    // 2-1弹窗中转站
    upModalData2=(data)=>{
        this.selectModal2(data);
        this.selectModal1(true);

    };
    // 弹窗开关-3-申请发货
    selectModal3=(data)=>{
        this.setState({tableModal_3_data:[]});
        this.setState({modalVisible3:data});
    };
    // 3弹窗申请发货按钮
    ApplyForshipmentModal3=(data)=>{
        let tableData_1_right_data = this.state.tableData_1_right_data; // 详情页右表数据
        let tableModal_3_data = [...this.state.tableModal_3_data];      // 申请发货窗口表格数据

        this.setState({modalVisible3:data});
        tableModal_3_data=[];
        // 将选择的数据上交给申请发货列表
        tableData_1_right_data?.forEach(one=>{
            // 可做约束判断添加
            if(one.state==="测试中"){
                tableModal_3_data.push(one);
            }
        });
        this.setState({tableModal_3_data:tableModal_3_data})
    };

    // 弹窗开关-4-发货
    selectModal4=(data)=>{
        this.setState({modalVisible4:data});
    };

    // 4弹窗发货按钮
    deliverGoodsodal4=(data)=>{
        this.setState({modalVisible4:data});

        // let tableData_1_right_data = this.state.tableData_1_right_data; // 详情页右表数据

        getApplysByOrderId({id:this.props.location.state?.orderId}).then(
            data=>{
                if(data?.data?.data){

                    const deliveryNoteList =data?.data?.data;
                    if(deliveryNoteList){
                        let tableModal_3_data = this.state.tableModal_3_data;      // 申请发货窗口表格数据
                        tableModal_3_data=[];
                        deliveryNoteList?.forEach(
                            one=>{
                                let data1={
                                    id:null,
                                    applicantId:null,
                                    applyNum:null,
                                    outModelName:null
                                };
                                if(one?.devices){
                                    one?.devices.forEach(
                                        two=>{
                                            if(two?.deviceInfo){
                                                if(two.deviceInfo.state!=="已发货"){
                                                    data1=two?.deviceInfo;
                                                    data1.id=one.id;
                                                    data1.applyTime=one.applyTime;
                                                    data1.applicantId=one.applicantId;
                                                    data1.applyNum=one.applyNum;
                                                    tableModal_3_data.push(data1);
                                                }
                                            }
                                        }
                                    );
                                }else{
                                    data1.applyTime=one.applyTime;
                                    data1.applicantId=one.applicantId;
                                    data1.applyNum=one.applyNum;
                                    tableModal_3_data.push(one);
                                }

                            });

                        this.setState({tableModal_3_data:tableModal_3_data})
                    }

                }

            }
        );

    };
    // 弹窗开关-5-批量导入
    selectModal5=(data)=>{
        this.setState({modalVisible2:data});
    };
    // 5-1弹窗中转站
    upModalData3=(data)=>{
        this.selectModal5(data);        //5
        this.selectModal1(true);   //1
    };

    // 弹窗开关-6-换表
    selectModal6=(data)=>{
        if(this.state.disabled1.length===0){
            return message.info("请选择可替换表具")
        }else{
            let form = this.state.ReplaceCommoditFormData;
            form.outModelName=this.state.disabled1.outModelName;
            form.outModelId=this.state.disabled1.outModelId;
            this.setState({modalVisible5:data,ReplaceCommoditFormData:form});
        }

    };

    replaceCommodity6=(data)=>{
        this.selectModal6(false);
    };

    UpReplaceCommodity6= async value =>{
        let data1 = await this.forms.onFinishNotReset();

        let orderFormDes = this.state.orderFormDes;
        this.replaceCommodity6(false);
        //------配置参数
        const upData={
            // 新表刚号
            "deviceParam": {
                "communicationNo": data1.communicationNo, // 通讯号
                "imeiNum": data1?.imeiNum?data1.imeiNum:message.info("IMEI不能为空"),  // IMEI号
                "outModelId": this.state.disabled1?.outModelId,         // 外部型号ID
                "outModelName": data1.outModelName,     //  型号名称厂家维护
                "outOrderId": orderFormDes.orderId,     //  订单id
                "productionTime": data1.productionTime?data1.productionTime.format('YYYY-MM-DD HH:mm:ss'):message.info("日期不能为空"), // 生产日期
                "simCard": data1.simCard?data1.simCard:message.info("SIM不能为空"),               // sim
                "steelNum": data1.steelNum?data1.steelNum:message.info("表刚号不能为空"),              // 表钢号
            },
            "orderId": orderFormDes.orderId,            // 订单id
            "steelNum": this.state.disabled1?.steelNum  // 要被替换的表刚号
        };
        this.setState({ pointer_4:'none'});
        // ------提交
        replaceOneDeviceToOrder(upData).then(
            data=>{
                this.setState({pointer_4:'auto'});
                if(this.state.rightTablepage?.classId){
                    this.onChangeLeftTable(this.state.rightTablepage?.classId);
                    this.setState({disabled1:[]})
                }else{
                    this.forClassId();
                }
                this.getOrderInfosByOrderId();

            }
        )
    };



    // 弹窗-2-添加按钮事件
    addCommodity = async value => {
        let data = await this.forms.onFinishNotReset();

        if(data){
                let orderFormDes = this.state.orderFormDes;
                let searchGetData= this.state.searchGetData;
                const tableModal_1_data = [...this.state.tableModal_1_data];
                searchGetData = data;
                let sTime=data?.productionTime ?moment(data.productionTime._d) : null;
                searchGetData.productionTime=sTime?.format('YYYY-MM-DD HH:mm:ss');

                if(orderFormDes?.supplierCompany){
                    // PC端根据制造商名查型号信息
                    getModelInfoBySupplier({supplier:orderFormDes.supplierCompany}).then(
                        response=>{
                            let {data} = response.data;
                            if(data){
                                data.forEach(item=>{
                                    if(item.modelName===searchGetData?.outModelName){
                                        searchGetData.outModelId=item.modelId;
                                    }
                                });
                            }
                        }
                    )
                }
                tableModal_1_data.push(searchGetData);
                this.setState({tableModal_1_data:tableModal_1_data});
        }

    };// ----弹窗-2-添加按钮事件

    // 弹窗-2-提交添加商品申请
    upAddCommodity=()=>{

        let orderFormDes = this.state.orderFormDes;
        let tableModal_1_data = [...this.state.tableModal_1_data];
        if(tableModal_1_data.length===0){
            return message.info("请添加表具");
        }
        tableModal_1_data.forEach(one=>{
            this.state.orderFormDes?.dtoClassificationList.forEach(two=>{
                if(two.modelName===one.modelName){
                    return one.modelId=two.modelId;
                }
            });
            this.selectModal2(false);
            this.setState({pointer_1:'none'});
            one.outOrderId=orderFormDes.orderId;
            addOneDeviceToOrder(one).then(
                data=>{
                    this.setState({pointer_1:'auto'});
                    message.info(one.steelNum+":"+data?.data?.responseInfo.description);
                    this.getOrderInfosByOrderId();
                    if(this.state.rightTablepage?.classId){
                        this.onChangeLeftTable(this.state.rightTablepage?.classId);
                    }else{
                        this.forClassId();
                    }
                }
            );
        });

        this.setState({tableModal_1_data:[]});


    };// ----弹窗-2-提交添加商品申请
    addOneDeviceToOrder=(upData)=>{

    };
    // 第一页左右表单选框
    onChangeLeftTable=(e)=>{
        let id=new URLSearchParams();
        id.append('id',e);
        getDevInfosByClassId(id).then(
            item=>{
                let tableData_1_right_data = [...this.state.tableData_1_right_data];// 第一个右边表格数据源
                let rightTablepage = this.state.rightTablepage;
                tableData_1_right_data=[];
                if(item?.data?.data){
                    let data=item?.data?.data;
                    data.forEach(one=>{
                        if(one?.deviceData){
                        }
                        tableData_1_right_data.push(one.deviceInfo);
                    });
                }
                rightTablepage.classId=e;
                this.setState({tableData_1_right_data:tableData_1_right_data,})
            }
        )
    };

    // 删除所选商品
    deleteCommodity=(value)=>{
        const tableModal1 = [...this.state.tableModal_1_data];
        for(let i = 0; i<tableModal1.length;i++){
            if(value.steelNum===tableModal1[i].steelNum){
                tableModal1.splice(i,1);
            }
        }

        this.setState({tableModal_1_data:tableModal1})
    };// ----删除所选商品



    //  申请发货窗口复选框
    modal3Selec=(e,value)=>{
        let tableSelete = this.state.tableModal_3_selete;
        tableSelete=[];
        value.forEach(one=>{
            tableSelete.push(one);
        });
        this.setState({tableModal_3_selete:tableSelete});
    };
    // 发货窗口复选框
    modal4Selec=(e,value)=>{
        let tableSelete = this.state.tableModal_4_selete;
        tableSelete=[];
        value.forEach(one=>{
            tableSelete.push(one);
        });
        this.setState({tableModal_4_selete:tableSelete});
    };
    // 提交申请发货
    upApplyForshipment=(data)=>{

        let tableSelete = this.state.tableModal_3_selete;
        if(tableSelete.length===0){
            return message.info("请选择需要申请的表具")
        }

        const upValue ={
            "applicantId": JSON.parse(sessionStorage.getItem("user")).id,   //申请人idJSON.parse(sessionStorage.getItem("user")).orgName
            "applicantName": JSON.parse(sessionStorage.getItem("user")).orgName,  //申请人名称
            "deviceIds": [],                            //订单申请发货设备id列表
            "orderId": this.state.orderFormDes?.orderId, //外部订单id
            "steelNums": []                            //订单申请发货设备表钢号列表
        };
        this.setState({modalVisible3:data});
        this.setState({ pointer_1:'none'});
        tableSelete?.forEach(one=>{
            upValue.deviceIds.push(one?.id);
            upValue.steelNums.push(one?.steelNum);
        });
        deliveryApplying(upValue).then(
            data=>{
                this.setState({pointer_1:'auto'});
                this.getOrderInfosByOrderId();
                if(this.state.rightTablepage?.classId){
                    this.onChangeLeftTable(this.state.rightTablepage?.classId);
                }else{
                    this.forClassId();
                }
                this.setState({tableModal_3_selete:[]})
            }
        )
    };

    upDeliverGoodsodal= async value =>{
        let data = await this.forms.onFinishNotReset();
        if(data===null){
            return message.info("请填写发货信息")
        }
        let tableModal_4_selete = this.state.tableModal_4_selete;
        if(tableModal_4_selete.length===0){
            return message.info("请选择发货申请单")
        }
        this.setState({modalVisible4:false});
        const upValue ={
            "applyId": 0,                                   //申请记录主键id
            "deliveryNoteNo": "",                           //物流单号
            "logisticsCompany": data?.logisticsCompany,     //物流公司单位
            "logisticsName": "",                            //物流公司负责人
            "logisticsNo": "",                              //物流单号
            "logisticsPhone": data?.logisticsPhone,         //物流公司电话
            "consignor":data?.consignor,                    // 发货人
            "consignorPhone":data?.consignorPhone,          // 发货人电话
            "operatorId": JSON.parse(sessionStorage.getItem("user")).id,                         //操作人id
            "operatorName": JSON.parse(sessionStorage.getItem("user")).realName                  //操作人名
        };
        this.setState({ pointer_3:'none'});
        tableModal_4_selete?.forEach(one=>{
           upValue.applyId=one.id;
            deliveryByApply(upValue).then(
                data=>{
                    this.setState({pointer_3:'auto'});
                    if(!data.data.data){
                        message.info(data?.data?.responseInfo?.description);
                    }else{
                        message.info("发货成功");
                    }
                    if(this.state.rightTablepage?.classId){
                        this.onChangeLeftTable(this.state.rightTablepage?.classId);
                    }else{
                        this.forClassId()
                    }
                }
            )
        });
    };

    // 获取发货列表
    getApplysByOrderId=()=>{
        let tableData_2_data=[...this.state.tableData_2_data];
        tableData_2_data=[];
        getApplysByOrderId({id:this.props.location.state?.orderId}).then(
            data=>{
                if(data?.data?.data){
                    const deliveryNoteList =data?.data?.data;
                    if(deliveryNoteList){
                        deliveryNoteList?.forEach(
                            one=>{
                                let data1={
                                    id:null,
                                    applicantId:null,
                                    applyNum:null,
                                    outModelName:null
                                };
                                if(one?.devices){
                                    one?.devices.forEach(
                                        two=>{
                                            if(two?.deviceInfo){
                                                data1=two?.deviceInfo;
                                            }
                                            data1.id=one.id;
                                            data1.applyTime=one.applyTime;
                                            data1.applicantId=one.applicantId;
                                            data1.applyNum=one.applyNum;
                                            tableData_2_data.push(data1);
                                        }
                                    );
                                }else{
                                    data1.applyTime=one.applyTime;
                                    data1.applicantId=one.applicantId;
                                    data1.applyNum=one.applyNum;
                                    tableData_2_data.push(one);
                                }

                            });
                        this.setState({tableData_2_data:tableData_2_data})
                    }

                }

            }
        );
    };
    // 获取发货列表
    getDeliverysByOrderId=()=>{
        const meterCommodityList3=[];
        let tableData_3_param = this.state.tableData_3_param;
        tableData_3_param.orderId=this.props.location.state?.orderId;
        getDeliverysByOrderId(tableData_3_param).then(
            data=>{
                if(data?.data?.data){
                    // 分页参数
                    const  deliveryNoteList =data?.data?.data;
                    if(deliveryNoteList){
                        deliveryNoteList.forEach(
                            one=>{
                                one?.meterCommodityList.forEach(
                                    two=>{
                                        two.deliveryId=one?.deliveryId;     // 发货单id
                                        two.deliveryTime=one?.deliveryTime; // 发货单时间
                                        two.logisticsPhone=one?.logisticsPhone;// 物流电话
                                        two.logisticsNo=one?.logisticsNo;       // 物流编号
                                        two.logisticsCompany=one?.logisticsCompany;// 物流单位
                                        two.consignor=one?.consignor;
                                        two.consignorPhone=one?.consignorPhone;
                                        two.model=two?.model;
                                        two.sheetSteelGrade=two?.sheetSteelGrade;
                                        meterCommodityList3.push(two);
                                    })
                            });
                    }
                    this.setState({tableData_3_data:meterCommodityList3 })
                }
            }
        );
    };

    // 标签页-切换事件
    tabsClick=(key)=>{
        switch (key) {
            case "1":
                // this.forClassId();
                break;
            case "2":
                this.getApplysByOrderId();
                break;
            case "3":
                this.getDeliverysByOrderId();
                break;
            case "4":
                let orderFormDes = this.state.orderFormDes;
                let tableData = this.state.tableData_4_data;
                getReplaceInfosByOrderId({id:orderFormDes.orderId}).then(
                    data=>{
                        if(data?.data?.data){
                            tableData=data?.data?.data;
                            message.info(data?.data?.responseInfo.description)
                            this.setState({tableData_4_data: tableData})
                        }
                    }
                );
                break;
            default:
                break;
        }
    };// ----标签页-切换事件

    // 第一页右边多选框
    replaceCommoditySelect=(e,value)=>{
        this.setState({disabled1:value[0]});
    };

    // PC端乙方在订单下删除一个表具
    deleteOrderCommodity=()=>{
        let disabled1 = this.state.disabled1;

        if(disabled1.length===0){
            message.info("请选择要删除表具")
        }else{
            const delteData={
                "orderId": this.state.orderFormDes?.orderId,
                "steelNum": disabled1.steelNum
            };
            this.setState({ pointer_5:'none'});
            delOneDeviceFromOrder(delteData).then(
                data=>{
                    this.setState({ pointer_5:'auto'});
                    if(data?.data?.data){
                        message.info(data?.data?.responseInfo.description);
                        this.getOrderInfosByOrderId();
                        if(this.state.rightTablepage?.classId){
                            this.onChangeLeftTable(this.state.rightTablepage?.classId);
                        }else{
                            this.forClassId();
                        }
                    }else{
                        message.info(data?.data?.responseInfo.description);
                    }

                }
            )
        }
        this.setState({disabled1:[]});

    };
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
                <div style={{  "background-color": "#ffffff"}}>

                    {/*----------返回上层按钮--------------*/}

                    <div className={"orderXinAo_TopCaoZuoLan_div"}>

                        <div className={"orderXinAo_TopCaoZuoLan_info_div"}>
                            <Descriptions
                                // bordered
                                labelStyle={{"font-size": 14,"font-weight": "bold"}}
                                title={this.orderTitle()}
                                column={5}
                                extra={<Button type="primary" onClick={() => this.props.history.go(-1)}>返回</Button>}
                            >
                                <Descriptions.Item labelStyle={{"width": 150,}} label="创建时间">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.createTime
                                            ?this.state.orderFormDes?.createTime:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="完成时间">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.completionTime
                                            ?this.state.orderFormDes.completionTime:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="供应厂家">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.supplierCompany
                                            ?this.state.orderFormDes?.supplierCompany:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="采购总台数">
                                    <span style={{"font-size": 14,"margin-left": "-30%"}}>
                                        {this.state.orderFormDes?.theTotal
                                            ?this.state.orderFormDes?.theTotal:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="订单状态">
                                    <span style={{"font-size": 14,"margin-left": "-40%"}}>
                                        {this.state.orderFormDes?.state
                                            ?this.state.orderFormDes?.state:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="供应厂家负责人">
                                    <span style={{"font-size": 14,"margin-left": "-10%"}}>
                                        {this.state.orderFormDes?.supplierName
                                            ?this.state.orderFormDes?.supplierName:"-"}
                                    </span>
                                </Descriptions.Item>
                                <Descriptions.Item labelStyle={{"width": 150}} label="供应厂家联系电话">
                                    <span style={{"font-size": 14,"margin-left": "-1%"}}>
                                        {this.state.orderFormDes?.supplierPhone
                                            ?this.state.orderFormDes?.supplierPhone:"-"}
                                    </span>
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>

                    <div style={{"padding":5}}>
                        <Tabs
                            // activeKey={activeKey}
                            tabPosition={"top"}
                            animated={true}
                            onTabClick={this.tabsClick}
                        >
                            <TabPane tab="订单详情" key="1">
                                {/*第一页按钮区*/}
                                <div style={{background:'#fff'}}>
                                    <div style={{background:'#fff',"float": "right","margin":"-50px 10px 0 0"}}>
                                        <div>
                                            {/*this.forClassId();*/}
                                            <div style={{"float": "left","padding": " 0 10px 0 0 "}}>
                                                {/*<Button type="link"   onClick={() =>{this.forClassId()}}>添加</Button >*/}
                                                <Button type="link"   onClick={() =>{this.selectModal1(true)}}>添加</Button >
                                            </div>
                                            <div style={{"float": "left","padding": " 0 10px 0 0 "}}>
                                                <Button type="link"
                                                        style={{"pointer-events": this.state.pointer_2}}
                                                        onClick={() =>{this.ApplyForshipmentModal3(true)}}>申请发货</Button>
                                            </div>
                                            <div style={{"float": "left","padding": " 0 10px 0 0 "}}>
                                                <Button type="link"
                                                        style={{"pointer-events": this.state.pointer_3}}
                                                        onClick={() =>{this.deliverGoodsodal4(true)}}>发货</Button>
                                            </div>
                                            <div style={{"float": "left","padding": " 0 10px 0 0 "}}>
                                                <Button type="link"
                                                        style={{"pointer-events": this.state.pointer_4}}
                                                        onClick={() =>{this.selectModal6(true)}}>换表</Button>
                                            </div>
                                            <Popconfirm title="确定删除?"
                                                        style={{"pointer-events": this.state.pointer_5}}
                                                        onConfirm={this.deleteOrderCommodity}
                                            >
                                                <Button type="link">删除</Button>
                                            </Popconfirm>
                                        </div>


                                    </div>

                                </div>
                                {/*----------------------------------------第一页按钮区---------------------------------*/}
                                <div>
                                    {/*第一页左框*/}
                                    <div className={"orderXinAoLeftTableData_div"}>
                                        <Table
                                            columns={this.state.leftTableColumns}
                                            dataSource={this.state.leftTableDatas}
                                            rowKey={"classId"}
                                            bordered
                                            rowSelection={{

                                                onChange: this.onChangeLeftTable,
                                                type:"radio",
                                                //"checkbox"
                                                // "radio"
                                            }}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                // onChange: this.getPaginationRight,       // 获得翻页数值
                                                total:this.orderFormDes?.dtoClassificationList.total,
                                                pageSize:9,
                                                showTotal: (count = this.state.orderFormDes?.dtoClassificationList.size)=>{
                                                    return '共'+count+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['9']
                                            }}
                                        >
                                        </Table>
                                    </div>
                                    {/*---------------------------第一页左框END----------------------*/}
                                    {/*第一页右框*/}
                                    <div className={"orderXinAoRightTableData_div"}>

                                        <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                            <Table
                                                columns={this.state.tableData_1_right} dataSource={this.state.tableData_1_right_data}

                                                rowKey={"id"} bordered
                                                rowSelection={{
                                                    selectedRowKeys:this.state.selectedRowKeys,
                                                    onChange: this.replaceCommoditySelect,
                                                    type:"radio",
                                                }}
                                                pagination = {{
                                                    showSizeChanger:true,
                                                    showQuickJumper: true,
                                                    // onChange: this.getPaginationRight,       // 获得翻页数值
                                                    total:this.state.tableData_1_right_data.length,
                                                    pageSize:9,
                                                    showTotal: (count = this.state.tableData_1_right_data.length)=>{
                                                        return '共'+this.state.tableData_1_right_data.length+'条数据'
                                                    },
                                                    defaultCurrent: 1,
                                                    pageSizeOptions: ['9']
                                                }}
                                            >
                                            </Table>

                                        </div>

                                    </div>
                                    {/*--------------------------第一页右框END----------------------*/}
                                </div>
                            </TabPane>{/*--------------------------第一页END----------------------*/}

                            <TabPane tab="申请发货" key="2">
                                <div>
                                    {/*<div style={{background:'#fff'}}>*/}
                                    {/*    <div style={{background:'#fff',"float": "right","margin":"-50px 10px 0 0"}}>*/}
                                    {/*        /!*<Button type="primary">批量发货</Button>*!/*/}
                                    {/*    </div>*/}

                                    {/*</div>*/}

                                    <div style={{padding:'0 0 0 0',background:'#fff',}}>
                                        <Table
                                            columns={this.state.tableData_2}
                                            dataSource={this.state.tableData_2_data}
                                            // rowKey={"id"}
                                            bordered
                                            // rowSelection={{
                                            //     type:"checkbox",
                                            //     getCheckboxProps: (record) => ({
                                            //         disabled: record.state!=='申请发货'}),
                                            // }}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                // onChange: this.getPaginationRight,       // 获得翻页数值
                                                total:this.state.tableData_2_data.length,
                                                pageSize:12,
                                                showTotal: (count = this.state.tableData_2_data.length)=>{
                                                    return '共'+this.state.tableData_2_data.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['12']
                                            }}
                                        >
                                        </Table>
                                    </div>
                                </div>

                            </TabPane>{/*--------------------------第二页END----------------------*/}
                            <TabPane tab="发货信息" key="3">
                                <div>
                                    <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                        <Table
                                            columns={this.state.tableData_3}
                                            dataSource={this.state.tableData_3_data}
                                            // rowKey={"id"}
                                            bordered
                                            // rowSelection={
                                            //     { type:"checkbox"}
                                            // //     //"checkbox"
                                            // //     // "radio"
                                            // }
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                // onChange: this.getPaginationRight,       // 获得翻页数值
                                                total:this.state.tableData_3_data.length,
                                                pageSize:12,
                                                showTotal: (count = this.state.tableData_3_data.length)=>{
                                                    return '共'+this.state.tableData_3_data.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['12']
                                            }}
                                        >
                                        </Table>
                                    </div>
                                </div>
                            </TabPane>{/*--------------------------第三页----------------------*/}
                            {/*第4页*/}
                            <TabPane tab="换货" key="4">
                                <div>
                                    <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                        <Table
                                            columns={this.state.tableData_4}
                                            dataSource={this.state.tableData_4_data}
                                            pagination = {{
                                                showSizeChanger:true,
                                                showQuickJumper: true,
                                                // onChange: this.getPaginationRight,       // 获得翻页数值
                                                total:this.state.tableData_4_data.length,
                                                pageSize:12,
                                                showTotal: (count = this.state.tableData_4_data.length)=>{
                                                    return '共'+this.state.tableData_4_data.length+'条数据'
                                                },
                                                defaultCurrent: 1,
                                                pageSizeOptions: ['12']
                                            }}
                                        >
                                        </Table>
                                    </div>
                                </div>
                            </TabPane>{/*--------------------------第4页----------------------*/}
                        </Tabs>
                    </div>

                    {/*----------返回上层按钮END--------------*/}
                    <Modal
                        title={'添加表具'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible}
                        okText="确定"
                        onOk={() => this.selectModal1(false)}           // 点击确定
                        onCancel={() => this.selectModal1(false)}       // 点击取消
                        width={700}
                        centered
                        footer={[
                            <Button key="back" onClick={() => this.selectModal1(false)}>返回</Button>,
                            <Button type="primary" onClick={() => this.JumpModalSelect(false)}>添加</Button>
                        ]}
                    >
                        <div style={{  "width": "100%","height":"100px"}}>
                            <div
                                id={"orderGC"}
                                style={{"margin":"10% 0 0 40%",}}
                            >
                                <Radio.Group
                                    onChange={this.onChangeRadio}
                                    defaultValue={"1"}
                                >
                                    <Radio  value={"1"}>手动添加</Radio>
                                    <Radio  value={"2"}>批量导入</Radio>
                                </Radio.Group>
                            </div>

                        </div>
                    </Modal>
                    <Modal
                        title={'添加表具'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible1}
                        okText="确定"
                        onOk={() => this.selectModal2(false)}           // 点击确定
                        onCancel={() => this.selectModal2(false)}       // 点击取消
                        width={1500}
                        centered
                        footer={[
                            <Button key="back" onClick={() => this.upModalData2(false)}>返回</Button>,
                            <Button type="primary" onClick={() => this.upAddCommodity(false)}>添加</Button>
                        ]}
                    >
                        <div style={{"height":"550px"}}>
                            {/*条件搜索栏*/}
                            <div className={'topSearchBox'}>

                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'100%'}}>
                                        <FormsTwo
                                            ref={e => this.forms = e}
                                            formParamenter={this.state.topFormItem}
                                            data={this.state.searchGetData}
                                        />
                                    </div>
                                    <Button
                                        style={{position:'absolute', right:'0',top:'0'}}
                                        type="primary" onClick={() => this.addCommodity()}
                                    >
                                        添加
                                    </Button>
                                </div>
                            </div>
                            {/*-----------------------------------------条件搜索栏END------------------------------------*/}
                            <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                <div>
                                    <Table
                                        columns={this.state.tableModal_1}
                                        dataSource={this.state.tableModal_1_data}
                                        rowKey={"key"}
                                        bordered
                                        pagination = {{
                                            showSizeChanger:true,
                                            showQuickJumper: true,
                                            // onChange: this.getPaginationRight,       // 获得翻页数值
                                            total:this.state.paginationProps.total,
                                            pageSize:6,
                                            showTotal: (count = this.state.paginationProps.total)=>{
                                                return '共'+this.state.paginationProps.total+'条数据'
                                            },
                                            defaultCurrent: 1,
                                            pageSizeOptions: ['6']
                                        }}
                                    >
                                    </Table>
                                </div>
                            </div>{/*-----------------------------------------中间表格END---------------------------------------*/}

                        </div>
                    </Modal>{/* ------------------------------------- 手动发货END--------------------------------  */}
                    {/* ----------------------------批量导入----------------------------  */}
                    <Modal
                        title={'批量导入'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible2}
                        okText="确定"
                        onOk={() => this.selectModal5(false)}           // 点击确定
                        onCancel={() => this.selectModal5(false)}       // 点击取消
                        width={1500}
                        centered
                        footer={[
                            <Button key="back" onClick={() => this.upModalData3(false)}>返回</Button>,
                            <Button type="primary" onClick={() => this.upAddCommodity(false)}>导入</Button>
                        ]}
                    >
                        <div style={{"height":"550px"}}>
                            {/*条件搜索栏*/}
                            <div className={'topSearchBox'}>
                                <span><h4>导入<input/></h4></span>
                            </div>
                            {/*-----------------------------------------条件搜索栏END------------------------------------*/}
                            <div style={{padding:'0 0 0 0',background:'#fff'}}>
                                <div>
                                    <Table
                                        columns={this.state.tableModal_1}
                                        dataSource={this.state.tableModal_1_data}
                                        rowKey={"key"}
                                        bordered
                                        pagination = {{
                                            showSizeChanger:true,
                                            showQuickJumper: true,
                                            // onChange: this.getPaginationRight,       // 获得翻页数值
                                            total:this.state.paginationProps.total,
                                            pageSize:6,
                                            showTotal: (count = this.state.paginationProps.total)=>{
                                                return '共'+this.state.paginationProps.total+'条数据'
                                            },
                                            defaultCurrent: 1,
                                            pageSizeOptions: ['6']
                                        }}
                                    >
                                    </Table>
                                </div>
                            </div>{/*-----------------------------------------中间表格END---------------------------------------*/}

                        </div>
                    </Modal>{/* ------------------------------------- 批量导入END--------------------------------  */}
                    {/*申请发货弹出框*/}
                    <Modal
                        title={'申请发货'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible3}
                        okText="确定"
                        onOk={() => this.selectModal3(false)}           // 点击确定
                        onCancel={() => this.selectModal3(false)}       // 点击取消
                        width={1300}
                        centered
                        footer={[
                            <Button key="back" onClick={() => this.selectModal3(false)}>返回</Button>,
                            <Button
                                style={{"pointer-events": this.state.pointer_2}}
                                type="primary" onClick={() => this.upApplyForshipment(false)}>申请发货</Button>
                        ]}
                    >
                        <Table
                            columns={this.state.tableModa_2}
                            dataSource={this.state.tableModal_3_data}
                            rowKey={"id"}
                            bordered
                            rowSelection={{
                                onChange: this.modal3Selec,
                                type:"checkbox",
                                //"checkbox"
                                // "radio"
                            }}
                            pagination = {{
                                showSizeChanger:true,
                                showQuickJumper: true,
                                // onChange: this.getPaginationRight,       // 获得翻页数值
                                total:this.state.tableModal_3_data.length,
                                pageSize:6,
                                showTotal: (count = this.state.tableModal_3_data.length)=>{
                                    return '共'+this.state.tableModal_3_data.length+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['6']
                            }}
                        >
                        </Table>
                    </Modal>{/*-------------------------------申请发货弹出框END---------------------------------------*/}
                    {/*发货弹出框*/}
                    <Modal
                        title={'发货'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible4}
                        okText="确定"
                        onOk={() => this.selectModal4(false)}           // 点击确定
                        onCancel={() => this.selectModal4(false)}       // 点击取消
                        width={1500}
                        centered
                        footer={[
                            <Button key="back" onClick={() => this.selectModal4(false)}>返回</Button>,
                            <Button
                                style={{"pointer-events": this.state.pointer_3}}
                                type="primary" onClick={() => this.upDeliverGoodsodal(false)}>发货</Button>
                        ]}
                    >
                        <div style={{"height":"500px"}}>
                            {/*条件搜索栏*/}
                            <div className={'topSearchBox'}>

                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'100%'}}>
                                        <FormsTwo
                                            ref={e => this.forms = e}
                                            formParamenter={this.state.topFormItem1}
                                            data={this.state.searchGetData1}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*-----------------------------------------条件搜索栏END------------------------------------*/}

                            <Table
                                columns={this.state.tableModa_3}
                                dataSource={this.state.tableModal_3_data}
                                rowKey={"id"}
                                bordered
                                rowSelection={{
                                    onChange: this.modal4Selec,
                                    type:"checkbox",
                                }}
                                pagination={false}
                                // scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                scroll={{ y:350}}

                            >
                            </Table>
                        </div>
                    </Modal>{/*-------------------------------申请发货弹出框END---------------------------------------*/}

                    {/*-------------换表------------*/}
                    <Modal
                        title={'添加表具'}
                        style={{ top: 0 }}
                        visible={this.state.modalVisible5}
                        okText="确定"
                        onOk={() => this.selectModal6(false)}           // 点击确定
                        onCancel={() => this.selectModal6(false)}       // 点击取消
                        width={1200}
                        centered
                        footer={[
                            <Button key="back" onClick={() => this.selectModal6(false)}>返回</Button>,
                            <Button type="primary"
                                    style={{"pointer-events": this.state.pointer_4}}
                                    onClick={() => this.UpReplaceCommodity6(false)}>替换</Button>
                        ]}
                    >
                        <div style={{"height":"350px"}}>
                            {/*条件搜索栏*/}
                            <div className={'topSearchBox'}>

                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'100%',"margin":"0 0 0 5%"}}>
                                        <Descriptions
                                            // bordered
                                            labelStyle={{"font-size": 14,"font-weight": "bold"}}
                                            // title={this.orderTitle()}
                                            column={5}
                                        >
                                            <Descriptions.Item  label="型号">
                                                <span style={{"font-size": 14,}}>
                                                    {this.state.disabled1?.outModelName ?this.state.disabled1?.outModelName:"-"}
                                                </span>
                                            </Descriptions.Item>
                                            <Descriptions.Item  label="表刚号">
                                                <span style={{"font-size": 14,}}>
                                                    {this.state.disabled1?.steelNum ?this.state.disabled1?.steelNum:"-"}
                                                </span>
                                            </Descriptions.Item>
                                            <Descriptions.Item  label="通信号">
                                                <span style={{"font-size": 14,}}>
                                                    {this.state.disabled1?.communicationNo ?this.state.disabled1?.communicationNo:"-"}
                                                </span>
                                            </Descriptions.Item>
                                            <Descriptions.Item  label="生产日期">
                                                <span style={{"font-size": 14,}}>
                                                    {this.state.disabled1?.productionTime ?this.state.disabled1?.productionTime:"-"}
                                                </span>
                                            </Descriptions.Item>
                                            <Descriptions.Item  label="SIM号">
                                                <span style={{"font-size": 14,}}>
                                                    {this.state.disabled1?.simCard ?this.state.disabled1?.simCard:"-"}
                                                </span>
                                            </Descriptions.Item>
                                            <Descriptions.Item labelStyle={{"width": 150}} label="IMEI号">
                                    <span style={{"font-size": 14,"margin-left": "-10%"}}>
                                        {this.state.disabled1?.imeiNum ?this.state.disabled1?.imeiNum:"-"}
                                    </span>
                                            </Descriptions.Item>

                                        </Descriptions>
                                    </div>
                                 </div>
                            </div>
                            <Divider />
                            {/*-----------------------------------------条件搜索栏END------------------------------------*/}
                            {/*条件搜索栏*/}
                            <div className={'topSearchBox'}>

                                <div style={{position:'relative'}}>
                                    <div className={'topFormBox'} style={{width:'100%'}}>
                                        <FormsTwo
                                            ref={e => this.forms = e}
                                            formParamenter={this.state.ReplaceCommoditForm}
                                            data={this.state.ReplaceCommoditFormData}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*-----------------------------------------条件搜索栏END------------------------------------*/}
                        </div>
                    </Modal>{/* ------------------------------------- 换表END--------------------------------  */}
                </div>
            </Layout>
        )
    }
}
