
import { Component } from 'react'
import { Layout, message, Modal, Tooltip } from "antd";
import { NavLink } from "react-router-dom"
import moment from "moment";
import { deleteUser, updateUserStatus } from '../../../services/admin'
import { getMessageInfo } from '../../../services/alarm'
import {funTraceInfo} from '../../../services/common'
import '../../../style/luo.less'
import Pubsub from 'pubsub-js'
import React from 'react';
import { TYPE_NAME } from '../../../components/Const'
import Conditions from '../../../components/common/conditions/Conditions'
import Tables from "../../../components/common/tables/Tables";
export default class MessageManagement extends Component {




    state = {
        // statusAndAlarmData:'左框第三个',  // 左-3文本
        responseInfo: {},
        treeId: [],
        deleteId: [],
        // 表格参数
        tableParamenter: {
            // 表格行是否可选
            // rowSelection: true,
            // 表头：变量和变量解释
            header: {
                programName: '程序名称',
                messageContent: '消息内容',
                messageTitle: '消息标题',
                messageTime: '消息时间',
                messageType: '消息类型',
                messageUserName: '用户名称',
                receiptTime: '接收时间',
                sendTime: '发送时间',
                sendState: '发送状态',
            },
            // 下拉框：变量和变量解释
            headers: {
                programName: '程序名称',
                messageContent: '消息内容',
                messageTitle: '消息标题',
                messageTime: '消息时间',
                messageType: '消息类型',
                messageUserName: '用户名称',
                receiptTime: '接收时间',
                sendTime: '发送时间',
                sendState: '发送状态',
            },
            // 表格数据
            data: [],
        },// 表格参数END    
        expandedKeys: [],
        checkedKeys: [],
        selectedKeys: [],
        autoExpandParent: [],
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,

            parameter: [
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'programName',
                    str: '程序名称'
                },
                {
                    type: TYPE_NAME.NAME,
                    variableName: 'messageUserName',
                    str: '用户名称'
                },
                {
                    type: TYPE_NAME.SELECT,
                    variableName: 'messageType',
                    str: '消息类型',
                    data:[
                        {
							key:1,
							value:'即时消息'
						},
						{
							key:2,
							value:'离线消息'
						}
                    ]
                },
                {
                    type: 'rangePicker',
                    variableName: 'messageTime',
                    str: '消息时间',
                    time: true
                },

            ]
        },
        params: {
            messageInfoMessageTimeStart: null,
            messageInfoMessageTimeEnd: null
        },

        // 总条数
        totalCount: 0,

        pages: {
            current: 1,
            size: 10,
        },
        // 表格加载动画
        loading: false,
        // 刷新动画
        refreshTable: false,
        rowData: [],
        // data: {}
    };

    async componentDidMount() {
        this.getData();

    }




    // 组件将要被卸载时,取消订阅
    componentWillUnmount() {
        Pubsub.unsubscribe('areaId')
    }



    // 表格：刷新方法
    refreshTable = () => {
        // this.setState({ refreshTable: true })
        // this.getData();
    }

    // 表格：获取勾选中的值
    onSelectChange = data => {
        console.log("在线数据",data)

        this.setState({ rowData: data })
       
    }

    // 表格：导出
    exportTable = () => {
        console.log('数据导出')
    }

    // 条件选择：获取输入的条件
    getConditionsValue = value => {
        let { params } = this.state; // 获取两个state的数据管理
        if (value.messageTime !== null) {
            params.messageInfoMessageTimeStart = moment(value.messageTime[0]._d).format('YYYY-MM-DD HH:mm:ss');
            params.messageInfoMessageTimeEnd = moment(value.messageTime[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        this.setState({ params });   // 确定修改
        // console.log("ssssss",this.state.params)
        let param = {
            machineProgramName: value.programName,
            messageUserMessageUserName: value.messageUserName,
            messageType:value.messageType,
            messageInfoMessageTimeStart: this.state.params.messageInfoMessageTimeStart,
            messageInfoMessageTimeEnd: this.state.params.messageInfoMessageTimeEnd,
            page: this.state.pages
        }
        getMessageInfo(param).then(
            response => {
                const { data } = response.data
                console.log('展示的datassssssssssssssss', data)
                let { tableParamenter } = this.state
                tableParamenter.data = data
                const { pageInfo } = response.data
                let total = pageInfo.total

                let { pages } = this.state
                pages.current = pageInfo.pageIndex
                pages.size = pageInfo.pageLine
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter: tableParamenter,
                    totalCount: total,
                    pages
                });
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('查询失败')
            }
        )
    }

    setTableValue = (value, text, rowData) => {
        switch (value) {
            case 'messageTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                )
            case 'receiptTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                )
            case 'sendTime':
                text = moment(text).format('YYYY-MM-DD HH:mm:ss');
                return (
                    <span>{text}</span>
                )
            case 'sendState':
                text = text * 1
                
                return text === 1 ? '已发送' : text === 2 ? '未发送' : '-'

            case 'messageType':
                text = text * 1
                if(text===1){
                   
                    return (
                        <NavLink to={{pathname:"/alarm/onlineMessage",
                        state: {
                            programName: rowData.programName,
                            messageContent: rowData.messageContent,
                            messageTitle: rowData.messageTitle,
                            messageTime:  moment(rowData.messageTime).format("YYYY-MM-DD HH:mm:ss"),
                            messageUserName: rowData.messageUserName,
                            telephone: rowData.telephone,
                            mailbox: rowData.mailbox,
                            receiptTime:  moment(rowData.receiptTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendTime:  moment(rowData.sendTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendState:rowData.sendState === 1 ? '已发送' : rowData.sendState === 2 ? '未发送' : '-',
                            reader: rowData.reader,
                            readTime: moment(rowData.readTime).format("YYYY-MM-DD HH:mm:ss"),
                            readState:rowData.readState === 1 ? '已阅读' : rowData.readState === 2 ? '未阅读' : '-'
                        }
                        }}>
                            <Tooltip title={text}>
                                <u>{text === 1 ? '即时消息' : '-'}</u>
                            </Tooltip>
                        </NavLink>
                        
                    );
                }else if(text===2){
                    return (
                        <NavLink to={{pathname:"/alarm/offlineMessage",
                        state :{
                            programName: rowData.programName,
                            messageContent: rowData.messageContent,
                            messageTitle: rowData.messageTitle,
                            messageTime:  moment(rowData.messageTime).format("YYYY-MM-DD HH:mm:ss"),
                            messageUserName: rowData.messageUserName,
                            telephone: rowData.telephone,
                            programMailbox: rowData.programMailbox,
                            receiptTime:  moment(rowData.receiptTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendTime:  moment(rowData.sendTime).format("YYYY-MM-DD HH:mm:ss"),
                            sendState:rowData.sendState === 1 ? '已发送' : rowData.sendState === 2 ? '未发送' : '-',
                               }
                        }}>
                            <Tooltip title={text}>
                                <u>{ text === 2 ? '离线消息' : '-'}</u>
                            </Tooltip>
                        </NavLink>
                      
                    );
                }else{
                    return ''
                }
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    }

    // 获取当前页数和条数
    getPagination = (page, pageSize) => {

        let { pages } = this.state
        pages.current = page
        pages.size = pageSize
        this.setState({ pages })
        this.getData()

    }



    // 清空条件
    clearCondition = () => {
        let { params } = this.state
        params.username = null
        this.setState({ params })
        if (this.state.username == null) {
            this.getData()
        } else {
            this.getDatas()
        }
    }


    //展开/收起节点时触发
    onExpand = (expandedKeysValue: React.Key[]) => {
        console.log('onExpand', expandedKeysValue);
        this.setState({
            expandedKeys: expandedKeysValue,
            autoExpandParent: false
        });
    };

    //点击复选框触发
    onCheck = (checkedKeysValue: React.Key[]) => {
        console.log('onCheck', checkedKeysValue);
        //点击树节点触发
        this.setState({
            checkedKeys: checkedKeysValue,
        });
    };

    // 初始化表格数据
    getData = () => {
        let param = {
            page: this.state.pages
        }
        console.log("===========", param)
        getMessageInfo(param).then(
            response => {
                const { data } = response.data
                console.log('22222222222', data);
                const { pageInfo } = response.data
                console.log('分页111111111111', pageInfo);
                let total = pageInfo.total
                let { tableParamenter } = this.state
                tableParamenter.data = data
                this.setState({ tableParamenter, totalCount: total })
                message.success('查询成功', [0.5])
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                })
                message.error('查询失败', [0.5])
            }
        )
    }


    //用户注销
    deleteAccount = data => {
        if (this.state.rowData.length < 1) return message.warning('未选择数据', [0.5])
        let ids = this.state.deleteId
        console.log("sss", ids)
        let ss = ids[0].id
        let param = {
            userId: ss,
            organizationId: this.state.treeId
        }
        console.log("注销用户带的参数", param)
        Modal.confirm({
            title: `是否确认注销用户？`,
            content: '',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                // 注销用户
                deleteUser(param).then(
                    response => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                            totalCount: data.total,
                        });
                        message.success('用户注销成功', [1])
                        this.getData();
                    }
                ).catch(
                    () => {
                        this.setState({
                            loading: false,
                            refreshTable: false,
                        });
                        message.error('用户注销失败', [1])
                        this.getData();
                    }
                )
            }
        })
    }

    //变更状态
    updateStatus = () => {
        if (this.state.rowData.length < 1) return message.warning('未选择数据', [0.5])
        let status = this.state.rowData[0].id
        let param = {
            userId: status
        }
        console.log("状态", status)
        updateUserStatus(param).then(
            response => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                    // totalCount: data.total,
                });
                message.success('用户状态更改成功', [1])
                this.getData()
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                message.error('用户状态更改失败', [1])
                this.getData()
            }
        )
    }

    //修改
    updateInfo = () => {
        if (this.state.rowData.length < 1) return message.warning('未选择组织部门或数据', [0.5])

        this.props.history.push({
            pathname: '/home/updateInfo',
            state: this.state.rowData[0]
        })
    }




    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
                <Tables
                    tableParamenter={this.state.tableParamenter}  // 表格表头、数据
                    exportTable={this.exportTable}                  // 导出时触发的方法
                    onSelectChange={this.onSelectChange}            // 选中多选框时触发的方法
                    refreshTable={this.refreshTable}                // 刷新时触发的方法
                    getPagination={this.getPagination}              // 分页器变化时触发的方法
                    setTableValue={this.setTableValue}              // 设置表格显示

                    pagination={{
                        totalCount: this.state.totalCount, // 总条数
                        page: this.state.pages.current, // 当前页数
                        size: this.state.pages.size // 页面条数
                    }}

                    loading={{
                        loading: this.state.loading,
                        refreshTable: this.state.refreshTable
                    }}
                >
                    <Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >

                    </Conditions>
                </Tables>
            </Layout>
        )
    }

}