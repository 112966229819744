import React, {Component} from 'react'
import {Layout, Table,Button,Radio} from "antd";
import './deviceMapDemo.less'

import Echart from '../js/echart'// 圆角环形图
import Echart_1 from '../js/echart_1'// 折线图堆叠
import Echart_2 from '../js/echart_2'// 带背景色的柱状图
import Echart_3 from '../js/echart_3'// 折线图堆叠
import Echart_map from '../js/echarts_map'// 地图
import Echart_4 from '../js/echart_4'// 带背景色的柱状图
import Echart_5 from '../js/echart_5'// 圆角环形图

import {queryDevWorkingState,queryAllInstallationPointCoordinatesByCustomerIds,queryAlarmNumber,queryDevBusinessStatistics} from '../../../../services/qrCode'
import moment from "moment";

export default class  deviceMapDemo extends Component{
    state={
        // 左上报警统计
        leftTopAlarms:{
            alarmsNum:0,        // 报警数
            repairNum:0,        // 处理数
            noAlarmsNum:0,      // 未报警数
        },
        // 左上七日数据
        leftPeriodAlarms:{
            untreateds:[],
            processed:[],
            totalToday:[]
        },
        xAxisData:[],
        // 左下数据
        leftBottonData:[
            {elementById:'leftBottonData1',nameA:'今日已维护：',nameB:'今日检定数：',data:0,background:{color1:'#28e8ff',color2:'#053e47'}},
            {elementById:'leftBottonData2',nameA:'今日需维护：',nameB:'今日需检定数：',data:0,background:{color1:'#e57152',color2:'#623d34'}},
            {elementById:'leftBottonData3',nameA:'今日总维护率：',nameB:'今日总检定率：',data:0,background:{color1:'#27eac1',color2:'#0b4946'}},

            {elementById:'leftBottonData4',nameA:'本月已维护：',nameB:'本月检定数：',data:0,background:{color1:'#28e8ff',color2:'#053e47'}},
            {elementById:'leftBottonData5',nameA:'本月需维护：',nameB:'本月需检定数：',data:0,background:{color1:'#e57152',color2:'#623d34'}},
            {elementById:'leftBottonData6',nameA:'本月总维护率：',nameB:'本月总检定率：',data:0,background:{color1:'#27eac1',color2:'#0b4946'}},

            {elementById:'leftBottonData7',nameA:'今年已维护：',nameB:'今年检定数：',data:0,background:{color1:'#28e8ff',color2:'#053e47'}},
            {elementById:'leftBottonData8',nameA:'今年需维护：',nameB:'今年需检定数：',data:0,background:{color1:'#e57152',color2:'#623d34'}},
            {elementById:'leftBottonData9',nameA:'今年总维护率：',nameB:'今年总检定率：',data:0,background:{color1:'#27eac1',color2:'#0b4946'}},
        ],
        juder:1, // 左下类型区分

        // 右边-控制器
        rightController:{
            num: 0,                // 总数
            todayAddOfflineNum: 0, // 今日新增掉线数
            todayFaultRate: 0,     // 今日故障率
            todayOfflineNum: 0,    // 今日掉线率
            todayOnLine: 0,        // 今日在线数
            todayOnLineRate: 0,    // 今日在线率
            todayRecoveryNum: 0    // 今日恢复通讯数
        },
        num:95,
        rightDetector_key:1,
        // 右边-探测器
        rightDetector:{
            num: 0,                // 总数
            todayAddOfflineNum: 0, // 今日新增掉线数
            todayFaultRate: 0,     // 今日故障率
            todayOfflineNum: 0,    // 今日掉线率
            todayOnLine: 0,        // 今日在线数
            todayOnLineRate: 0,    // 今日在线率
            todayRecoveryNum: 0    // 今日恢复通讯数
        },

        map_coordinate:[],  // 坐标
    };




    // 初始化操作
    componentDidMount() {
        this.queryAlarmNumber();                                    // 通过当前用户所属客户下的所有报警器的报警信息--左上
        this.queryDevWorkingState();                                // 查询当前登录用户下的所有设备的在线情况
        this.queryAllInstallationPointCoordinatesByCustomerIds();   // 获取地标


        this.queryDevBusinessStatistics();      // 左下
    };

    queryDevBusinessStatistics=(text)=>{
        var params={
            businessType:text || 1,
            customerIds: [],
            type:10168
        };
        var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));
        if (customerNames?.length !== 0) {
            customerNames?.map(one => {
                params.customerIds.push(parseInt(one.id))
            });
        }
        queryDevBusinessStatistics(params).then(
            response=>{
                console.log('queryDevBusinessStatistics');
                console.log(response);
                if(response.data.data){
                    var {leftBottonData} = this.state;
                    leftBottonData[0].data=response.data.data.todayBusinessStatisticsQuantity||0;
                    leftBottonData[1].data=response.data.data.todayNeedBusinessStatisticsQuantity||0;
                    leftBottonData[2].data=response.data.data.todayBusinessStatisticsRate||0;

                    leftBottonData[3].data=response.data.data.thisMonthBusinessStatisticsQuantity||0;
                    leftBottonData[4].data=response.data.data.thisMonthNeedBusinessStatisticsQuantity||0;
                    leftBottonData[5].data=response.data.data.thisMonthBusinessStatisticsRate||0;

                    leftBottonData[6].data=response.data.data.thisYearBusinessStatisticsQuantity||0;
                    leftBottonData[7].data=response.data.data.thisYearNeedBusinessStatisticsQuantity||0;
                    leftBottonData[8].data=response.data.data.thisYearBusinessStatisticsRate||0;

                    this.setState({leftBottonData})
                }
            }
        )
    };



    // 通过当前用户所属客户下的所有报警器的报警信息
    queryAlarmNumber=()=>{
        var params={
            customerIds: [],
            type:10168
        };
        var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));
        if (customerNames?.length !== 0) {
            customerNames?.map(one => {
                params.customerIds.push(parseInt(one.id))
            });
        }
        queryAlarmNumber(params).then(
            response=>{
                if(response?.data?.data){
                    var {leftTopAlarms,leftPeriodAlarms,xAxisData} = this.state;
                    console.log('通过当前用户所属客户下的所有报警器的报警信息');
                    console.log(response);
                    leftTopAlarms.alarmsNum=response?.data?.data[0].totalToday || 0;
                    leftTopAlarms.repairNum=response?.data?.data[0].processed || 0;
                    leftTopAlarms.noAlarmsNum=response?.data?.data[0].untreated || 0;
                    // 循环获取周期数据
                    for(var i=response?.data?.data.length-1;i>=0;i--){
                        xAxisData.push(moment(response?.data?.data[i]?.nowTime).format("DD"));
                        leftPeriodAlarms.untreateds.push(response?.data?.data[i]?.untreated || 0);
                        leftPeriodAlarms.processed.push(response?.data?.data[i]?.processed || 0);
                        leftPeriodAlarms.totalToday.push(response?.data?.data[i]?.totalToday || 0);
                    }

                    // response?.data?.data.forEach(one=>{
                    //     xAxisData.push(moment(one?.nowTime).format("DD"));
                    //     leftPeriodAlarms.untreateds.push(one?.untreated || 0);
                    //     leftPeriodAlarms.processed.push(one?.processed || 0);
                    //     leftPeriodAlarms.totalToday.push(one?.totalToday || 0);
                    // });
                    this.setState({leftTopAlarms:leftTopAlarms,leftPeriodAlarms:leftPeriodAlarms,xAxisData:xAxisData})
                }
            }
        )
    };

    // 设备在线率
    queryDevWorkingState=()=>{
        var params_1={
            customerIds:[],
            type:10168
        };
        var params_2={
            type:10196,
            customerIds:[]
        };
        var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));
        if (customerNames?.length !== 0) {
            customerNames?.map(one => {
                params_1.customerIds.push(parseInt(one.id));
                params_2.customerIds.push(parseInt(one.id))
            });
        }
        // 探测器
        queryDevWorkingState(params_1).then(
            response=>{
                if(response?.data?.data){
                    let {rightDetector} =this.state;
                    rightDetector=response?.data?.data;
                    this.setState({rightDetector:rightDetector});
                }
            }
        );

        // 控制器
        queryDevWorkingState(params_2).then(
            response=>{
                if(response?.data?.data){
                    let {rightController} =this.state;
                    rightController=response?.data?.data;
                    this.setState({rightController:rightController});
                }
            }
        )
    };

    // 坐标
    queryAllInstallationPointCoordinatesByCustomerIds=()=>{
        var params={
            customerIds: [],
        };
        var customerNames = JSON.parse(sessionStorage.getItem("customerNames"));

        if (customerNames?.length !== 0) {
            customerNames?.map(one => {
                params.customerIds.push(parseInt(one.id))
            });
        }
        queryAllInstallationPointCoordinatesByCustomerIds(params).then(
            response=>{
                console.log("获取数据");
                if(response.data.data){
                    var coordinate = this.state.map_coordinate;
                    response.data.data.forEach(one=>{   // 获取数据
                        customerNames.forEach(two=>{    // 提供参数

                            if(one.customerId.toString()===two.id.toString()){
                                var data ={
                                    id:one.id,
                                    customerId:one.customerId,
                                    latitude: one.latitude,
                                    longitude:one.longitude,
                                    customerName:two.residentsName || two.industrialName,
                                    address:one.address
                                };
                                console.log('-----------------------------------------');
                                console.log(this.bMapTransQQMap(one.longitude,one.latitude).lng);
                                console.log('-----------------------------------------');
                                coordinate.push(data);
                            }
                        })
                    });
                    this.setState({map_coordinate:coordinate})
                }
            }
        )
    };
    bMapTransQQMap=(lng, lat)=>{
        let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
        let x = lng - 0.0065;
        let y = lat - 0.006;
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
        let lngs = z * Math.cos(theta);
        let lats = z * Math.sin(theta);
        return {
            lat: lats,
            lng: lngs
        }
    };
    render() {
        const {leftTopAlarms}=this.state;
        console.log(this.state.map_coordinate);
        return(
            <Layout className={"regional-content"}>
                <div id={'dever_map_demo'}>
                    <div className={"dever_map_demo_div"}>
                        <div className={"dever_map_demo_div_image"}>
                            {/*标题*/}
                            <div className={'head_div'}>
                                <div className={'head_div_image'} />
                                <div className={'head_div_text'}/>

                            </div>
                            {/*标题END-----*/}
                            {/*------------------------------------------------------------------------------------------*/}
                            {/*内容*/}
                            <div className={'body_div'}>
                                <div className={'body_left_div'}>
                                    {/*左上-报警统计上图*/}
                                    <div className={'body_left_div_top'}>
                                        <div className={'body_left_div_top_image'}>
                                            <div style={{width: '100%', height: '40%',}}>

                                                {/*<div style={{width: '100%', height: '100%',paddingTop:'38px'}}>*/}
                                                {/*    <Echart_5*/}
                                                {/*        elementById={'main'}*/}
                                                {/*        data={[*/}
                                                {/*            { value: leftTopAlarms.noAlarmsNum, name: '今日未处理',color:'#ff7752' },*/}
                                                {/*            { value: leftTopAlarms.repairNum, name: '今日已处理',color:'#28e8ff' },*/}
                                                {/*            { value: leftTopAlarms.alarmsNum, name: '今日报警数',color:'#27eac1' }*/}
                                                {/*            ]}*/}
                                                {/*    />*/}

                                                <div  className={'body_left_div_echart_heBing'}  style={{  "margin": "38px 0 0 15px"}}>

                                                        <div style={{width:'100%'}}>
                                                            <Echart
                                                                elementById={'main'}
                                                                data={{ value: leftTopAlarms.noAlarmsNum, name: '今日未处理',color:'#ff7752'}}
                                                                data1={{ value: leftTopAlarms.repairNum, name: '今日已处理',color:'#28e8ff'}}
                                                                // data1={{ value: leftTopAlarms.alarmsNum-leftTopAlarms.noAlarmsNum, name: '今日报警数',color:leftTopAlarms.alarmsNum?'#27eac1':'#0b4946'}}
                                                            />
                                                        </div>

                                                    <div style={{width:'100%'}}>
                                                        <div style={{'float':'left',width: '50%'}}>
                                                            <div style={{"marginTop":"-15px",width: '100%',"textAlign": "center"}}>
                                                                <span style={{"color":"#db6b51","fontSize":"18px"}}>{leftTopAlarms.noAlarmsNum}</span>
                                                            </div>
                                                            <div style={{"marginTop":"-5px",width: '100%',height: '50%',"textAlign": "center"}}>
                                                                <span style={{"color":"#db6b51","fontSize":"14px"}}>今日未处理</span>
                                                            </div>
                                                        </div>
                                                        <div style={{'float':'left',width: '50%'}}>
                                                                <div style={{"marginTop":"-15px",width: '100%',"textAlign": "center"}}>
                                                                    <span style={{"color":"#28e8ff","fontSize":"18px"}}>{leftTopAlarms.repairNum}</span>
                                                                </div>
                                                            <div style={{"marginTop":"-5px",width: '100%',height: '50%',"textAlign": "center"}}>
                                                                <span style={{"color":"#28e8ff","fontSize":"14px"}}>今日已处理</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>


                                                {/*</div>*/}
                                                {/*/!*今日未完成率-----*!/*/}
                                                {/*<div className={'body_left_div_echart'} style={{  "margin": "38px 0 0 15px"}}>*/}
                                                {/*    <div>*/}
                                                {/*        <Echart*/}
                                                {/*            elementById={'main'}*/}
                                                {/*            data={{ value: leftTopAlarms.noAlarmsNum, name: '今日未处理',color:'#ff7752'}}*/}
                                                {/*            // data1={{ value: leftTopAlarms.alarmsNum, name: '报警数',color:'#302929'}}*/}
                                                {/*            data1={{ value: leftTopAlarms.alarmsNum-leftTopAlarms.noAlarmsNum, name: '今日报警数',color:leftTopAlarms.alarmsNum?'#27eac1':'#0b4946'}}*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*    <div style={{"marginTop":"-15px",width: '100%',"textAlign": "center"}}>*/}
                                                {/*        <span style={{"color":"#db6b51","fontSize":"18px"}}>{leftTopAlarms.noAlarmsNum}</span>*/}
                                                {/*    </div>*/}
                                                {/*    <div style={{"marginTop":"-5px",width: '100%',height: '50%',"textAlign": "center"}}>*/}
                                                {/*        <span style={{"color":"#db6b51","fontSize":"14px"}}>今日未处理</span>*/}
                                                {/*    </div>*/}

                                                {/*</div>*/}

                                                {/*/!*今日已完成-----*!/*/}
                                                {/*<div className={'body_left_div_echart'} style={{  "margin": "38px 0 0 20px"}}>*/}
                                                {/*    <div>*/}
                                                {/*        <Echart*/}
                                                {/*            elementById={'main1'}*/}
                                                {/*            data={{ value: leftTopAlarms.repairNum, name: '今日已处理',color:'#28e8ff'}}*/}
                                                {/*            // data1={{ value: leftTopAlarms.noAlarmsNum, name: '已处理',color:'#053e47'}}*/}
                                                {/*            data1={{ value: leftTopAlarms.alarmsNum-leftTopAlarms.repairNum, name: '今日报警数',color:leftTopAlarms.alarmsNum?'#27eac1':'#0b4946'}}*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*    <div style={{"marginTop":"-15px",width: '100%',"textAlign": "center"}}>*/}
                                                {/*        <span style={{"color":"#28e8ff","fontSize":"18px"}}>{leftTopAlarms.repairNum}</span>*/}
                                                {/*    </div>*/}
                                                {/*    <div style={{"marginTop":"-5px",width: '100%',height: '50%',"textAlign": "center"}}>*/}
                                                {/*        <span style={{"color":"#28e8ff","fontSize":"14px"}}>今日已处理</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*今日已完成END-----*!/*/}

                                                {/*今日报警率-----*/}
                                                <div className={'body_left_div_echart'} style={{"margin": "38px 0 0 13px"}}>
                                                    <div>
                                                        <Echart
                                                            elementById={'main2'}
                                                            data={{ value: leftTopAlarms.alarmsNum, name: '今日报警数',color:'#27eac1'}}
                                                            // data1={{ value: 0, name: '报警数',color:'#0b4946'}}
                                                        />
                                                    </div>

                                                    <div style={{"marginTop":"-15px",width: '100%',"textAlign": "center"}}>
                                                        <span style={{"color":"#27eac1","fontSize":"18px"}}>{leftTopAlarms.alarmsNum}</span>
                                                    </div>
                                                    <div style={{"marginTop":"-5px",width: '100%',height: '50%',"textAlign": "center"}}>
                                                        <span style={{"color":"#27eac1","fontSize":"14px"}}>今日报警数</span>
                                                    </div>
                                                </div>
                                                {/*今日报警率END-----*/}

                                            </div>
                                            {/*折线图*/}
                                            <div style={{width: '100%', height: '70%'}}>
                                                <Echart_1
                                                    elementById={'mian6'}
                                                    untreateds={this.state.leftPeriodAlarms.untreateds}
                                                    processed={this.state.leftPeriodAlarms.processed}
                                                    totalToday={this.state.leftPeriodAlarms.totalToday}
                                                    number={{min:0,max:60,interval:10}}
                                                    xAxisData={this.state.xAxisData}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {/*左下-------*/}
                                    <div className={'body_left_div_bottom'}>
                                        <div className={'body_left_div_bottom_image'}>
                                            {/*左下-上部分*/}
                                            <div style={{width: '100%', height: '100%',"paddingTop":"50px"}}>
                                                <div style={{"margin":"0 20px 0 10px"}}>
                                                    {/*<span style={{"color":"#fff"}}>维护数</span>*/}
                                                    <Radio.Group name="radiogroup" defaultValue={1} onChange={(e)=>{
                                                        this.queryDevBusinessStatistics(e.target.value);
                                                        this.setState({juder:e.target.value});
                                                        // console.log(this.state.juder);
                                                    }}>
                                                        <Radio value={1} style={{color:'#fff'}}>维护信息</Radio>
                                                        <Radio value={2} style={{color:'#fff'}}>检定信息</Radio>
                                                    </Radio.Group>
                                                    {/*<span style={{"color":"#fff","float": "right"}}>完成率</span>*/}
                                                </div>
                                                <div>
                                                    {this.state.leftBottonData.map(one=>{
                                                        return(
                                                            <div style={{width: '100%', height: '20%',}}>
                                                                <div style={{"paddingTop":"5px",width: '30%',height: '100%',"float": "left","textAlign": "right",}}>
                                                                    <span style={{"color":"#fff","fontSize":"14px"}}>
                                                                        {this.state?.juder===1?one.nameA:one.nameB}
                                                                    </span>
                                                                </div>
                                                                <div style={{width: '65%',height: '100%',"float": "left",}}>
                                                                            <Echart_2
                                                                                elementById={one.elementById}
                                                                                data={one.data}
                                                                                number={{min:0,max:600,interval:100}}
                                                                                background={one.background}
                                                                            />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            {/*左下-下部分*/}
                                            {/*<div style={{width: '100%', height: '70%',"paddingTop":"20px",}}>*/}
                                            {/*    /!*折线图堆叠*!/*/}
                                            {/*    <Echart_3*/}
                                            {/*        elementById={'mian7'}*/}
                                            {/*        data={[120, 132, 101, 134, 90, 230, 210]}*/}
                                            {/*        axis={{ min: 0, max: 900,interval:100}}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className={'body_center_div'}>
                                    <div style={{width: '100%', height: '100%',"padding":"5px 0 0 15px "}}>
                                        <div style={{width: '100%', height: '85%',}}>
                                            <Echart_map
                                            data={this.state.map_coordinate}// 坐标
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*右边-------------------------------------------------------------------------------*/}
                                <div className={'body_right_div'}>
                                    <div className={'body_right_div_image'}>

                                        <div style={{width: '100%', height: '40%',}}>

                                            <div style={{width: '100%', height: '100%',"padding":"45px 0 0 0 "}}>
                                                <div style={{width: '100%', height: '12%'}}>
                                                    <span style={{'paddingLeft':'20px','color':'#fff','fontSize':'18px','fontWeight': 'bolder'}}>探测器</span>
                                                    <span style={{'paddingLeft':'15px','color':'#fff','fontSize':'14px'}}>在线率</span>
                                                </div>
                                                <div style={{width: '100%', height: '100%'}}>
                                                    {/*<span style={{color:'#fff'}}>{this.state.rightDetector.num}</span>*/}
                                                    <Echart_4
                                                        elementById={this.state.rightDetector_key}
                                                        rightDetector={this.state.rightDetector}
                                                        axis={{ min: 0, max: this.state.rightDetector?.num,interval:1}}
                                                    />
                                                </div>
                                            </div>


                                        </div>
                                        <div style={{width: '100%', height: '42%',}}>
                                            <div style={{width: '100%', height: '100%',"padding":"60px 0 0 0 "}}>
                                                <div style={{width: '100%', height: '12%'}}>
                                                    <span style={{'paddingLeft':'20px','color':'#fff','fontSize':'18px','fontWeight': 'bolder'}}>控制器</span>
                                                    <span style={{'paddingLeft':'15px','color':'#fff','fontSize':'14px'}}>在线率</span>
                                                </div>
                                                <div style={{width: '100%', height: '100%'}}>
                                                    <Echart_4
                                                        elementById={'dadsda'}
                                                        rightDetector={this.state.rightController}
                                                        axis={{ min: 0, max: this.state.rightController?.num,interval:1}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div style={{width: '100%', height: '35%',}}>*/}
                                        {/*    <div style={{width: '100%', height: '100%',"paddingTop":"30px",}}>*/}
                                        {/*        /!*折线图堆叠*!/*/}
                                        {/*        <Echart_3*/}
                                        {/*            elementById={'mian8'}*/}
                                        {/*            data={[120, 132, 101, 134, 90, 230, 210]}*/}
                                        {/*            axis={{ min: 0, max: 900,interval:100}}*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                            </div>
                            {/*内容END-----*/}
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
