import ajax from './ajax'
// 采购管理

// 查询指定id的所有订单
export const getMeterOrderForm = data => ajax('/maintain/PurchaseMeterOrderFormCtrl/getMeterOrderForm', data, 'POST');
// 查询指定id的所有订单
export const getOrderFormDetail = data => ajax('/maintain/PurchaseMeterOrderFormCtrl/getOrderFormDetail', data, 'GET');

// 查询指定id的所有订单
export const deliveryNodeNumber = data => ajax('/maintain/PurchaseMeterOrderFormCtrl/deliveryNodeNumber', data, 'GET');

// 查询指定的id下的条件查询的订单
export const findMeterInCode = data => ajax('/maintain/PurchaseMeterOrderFormCtrl/findMeterInCode', data, 'POST');
// 创建型号订单
export const addUnorderedMeterOrder = data => ajax('/maintain/PurchaseBusinessOneCtrl/addMeterOrderForm', data, 'POST');

// 创建表具订单
export const addMeterOrder = data => ajax('/maintain/PurchaseBusinessTwoCtrl/addMeterOrderForm', data, 'POST');

// 表具各类数据整合获取
export const classifyDeviceAccordingToModel = data => ajax('/maintain/PurchaseBusinessOneCtrl/classifyDeviceAccordingToModel', data, 'POST');
// 表具详情列表
export const getMetersBySteelNum = data => ajax('/maintain/PurchaseBusinessTwoCtrl/getMetersBySteelNum', data, 'POST');

// 显示所有区域-发货根据订单情况控制区域显示
export const getAllAreaInfo = data => ajax('/maintain/PurchaseBusinessOneCtrl/getAllAreaInfo', data, 'GET');


//——————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// // 查询指定id的所有订单
// export const getMeterOrderForm = data => ajax('/maintain/procurement/PurchaseMeterOrderFormCtrl/getMeterOrderForm', data, 'POST');
// // 查询指定id的所有订单
// export const getOrderFormDetail = data => ajax('/maintain/procurement/PurchaseMeterOrderFormCtrl/getOrderFormDetail', data, 'GET');
// // 查询指定的id下的条件查询的订单
// export const findMeterInCode = data => ajax('/maintain/procurement/PurchaseMeterOrderFormCtrl/findMeterInCode', data, 'POST');
// // 创建型号订单
// export const addUnorderedMeterOrder = data => ajax('/maintain/procurement/PurchaseBusinessOneCtrl/addMeterOrderForm', data, 'POST');
// // 创建表具订单
// export const addMeterOrder = data => ajax('/maintain/procurement/PurchaseBusinessTwoCtrl/addMeterOrderForm', data, 'POST');
// // 表具各类数据整合获取
// export const classifyDeviceAccordingToModel = data => ajax('/maintain/procurement/PurchaseBusinessOneCtrl/classifyDeviceAccordingToModel', data, 'POST');
// // 表具详情列表
// export const getMetersBySteelNum = data => ajax('/maintain/procurement/PurchaseBusinessTwoCtrl/getMetersBySteelNum', data, 'POST');
