import React, { Component } from 'react'
import {Layout, Input, Select, Tag, Button, Modal, Upload, Image, Radio, message, Popconfirm, Tabs, Checkbox} from 'antd'
import FormsTwo from "../../components/common/forms/FormsTwo";
import {NavLink} from "react-router-dom";
import TableComponents from "../../components/common/tables/TableComponentsNew";
import {pageDeviceInfo} from "../../services/qrCode";
import {PlusSquareOutlined,RedoOutlined} from '@ant-design/icons';
import {configPushData, listDeviceRealTimeData, pageRealTimeValve, queryConfig} from "../../services/steamReport";
import moment from "moment";
const { TabPane } = Tabs;
const { TextArea } = Input;
// 数据推送
export default class DevicePushConfiguration extends Component {
    state={
        // 控制器表格参数
        tableParam_1: {
            manageName: "控制器表格",
            rowSelection: true, // 表格行是否可选
            selectWidth: '6.5vw',//下拉框长度
            overflow:true,
            // 表头：变量和变量解释
            header: {
                hdstno: '控制器编号',
                communicateno: '控制器通信号',
                devModelName:'设备型号',
                devNumber:'下辖设备数',
                manufacture: '供应商',
                state: '状态',
                comTime: '通信时间',
                // edit: '操作',
            },
            params: {devType:10196, page:1, size:3,},
            bindArea: false,// 是否绑定左侧区域
        },
        // 探测器表格参数
        tableParam_2: {
            manageName: "探测器表格",
            rowSelection: true,// 表格行是否可选
            selectWidth: '6.5vw',//下拉框长度
            header: {
                devModelName:'设备型号',
                parentNo: '控制器编号',
                parentComNo: '控制器通信号',
                ikRouteNo:'探测器路号',
                hdstno:'探测器编号',
                state:'状态',
                installPosition: '安装位置',
                // edit: '操作',
            },
            // params: {devType:10168, page:1, size:3,},
            params: {
                areaIds:[],
                page:1,
                size:10,
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                customerIds:[]
                // jumper:true
            },
            bindArea: false, // 是否绑定左侧区域
        },
        // 阀井表格参数
        tableParam_3: {
            manageName: "SIM卡申请管理",
            checkStatus: true,// 表格行是否可选
            selectWidth: '6.5vw',// 下拉框长度
            overflow:true,
            // 表头：变量和变量解释
            header: {
                uniqueEncoding:'唯一编码',

                data_1: '温度',
                data_3: '浓度',
                data_4: '压力值',

                data_16: '异动',
                data_17: '经纬度',
                data_18: '维度',

                data_5: '液位',
                data_6: '终端故障检测',
                data_7: '电池欠压',
                data_8: '水位检测',
                data_9: '温度检测',
                data_10: '可燃气体泄漏低限检测',
                data_11: '可燃气体泄漏高限检测',
                data_12: '压力低低限监测',
                data_13: '压力低限监测',
                data_14: '压力高限监测',
                data_15: '压力高高限监测',



                systemTime: '通信时间',
                devModelName: '设备型号',
                devName:'设备名',
                manufacture:'生产厂家',
                state:'状态',
                installPosition: '安装位置',
                address: '安装点地址',
            },
            // params: {devType:10200, page:1, size:3,},
            params: {
                page:1,
                size:10,
                customerIds:[]

            },
            bindArea: false,// 是否绑定左侧区域
        },
        // 家用报警器表格参数
        tableParam_4: {
            manageName: "SIM卡申请管理",
            rowSelection: true,// 表格行是否可选
            selectWidth: '6.5vw',// 下拉框长度
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                edit: '操作',
            },
            params: {devType:10202, page:1, size:3,},
            bindArea: false,// 是否绑定左侧区域
        },


        forItemData:[],// 数据集
        customers:[],   // 客户列表
        formatText:null,// 描述
        customerId:null,
        paramData:{
            classId: "10200",// 设备类型
            devIds: [],     // 设备id,所选行的设备id
            format: "",     // 推送格式-不用管
            itemIds: [],    // data数据项itemId
            path: "",       // 路径
            customerIds:null
        }

    };
    // 表格复选框
    getSelectChange=async (rowData)=>{
        console.log('表格复选框');
        console.log(rowData);
        var forItemData = this.state.forItemData;
        var paramData = this.state.paramData;
        forItemData=[];
        var ids =[];
        rowData.map(one=>{
            paramData.devIds.push(one.devId);
            ids.push(Number(one.devId));
            one.data.map(two=>{
                if(!forItemData.find(three=>{if(two.itemName===three.label){return true}})){
                    forItemData.push( { label:two.itemName, value: two.itemId })
                }
            });
        });
        await this.table.setSelectedRowKeys(ids);
        this.setState({forItemData,paramData:paramData});
    };

    // 查询配置
    queryConfig=async (userId)=>{
        var customerIds=userId;
        // JSON.parse(sessionStorage.getItem("customerNames"))?.map(one=>{customerIds.push(Number(one.id));});
        var params =  new URLSearchParams();
        params.append('classId','10200');
        params.append('customerIds',customerIds);
        queryConfig(params).then(
            response=>{
                if(response?.data?.data){
                    console.log('查询配置');
                    console.log(response?.data?.data);
                    var data = JSON.parse(response.data.data[0].format);// 解析数据获取推送内容 getDataTable
                    console.log(data);
                    // 推送内容
                    var itemIds=[];response.data.data[0].itemIds.map(one=>{itemIds.push(Number(one));});
                    // 表格选中的值
                    var devIds = [];data.data.map(one=>{devIds.push(Number(one.devId))});this.table.setSelectedRowKeys(devIds);
                    // 目标URL
                    var paramData = this.state.paramData;paramData.path=response?.data?.data[0]?.path;

                    this.setState({
                        paramData:paramData,
                        measures:itemIds,
                        formatText:JSON.stringify(JSON.parse(response.data.data[0].format))
                    });
                }
            }
        )
    };
    // 选取表格数据
    getSetSelectedRow=async (id)=>{
        console.log('选取表格数据');
        var tableData = await this.table.getDataTable();
        console.log(tableData);
        console.log(this.table.getSelectedRowKeys());
    };




    async componentDidMount(){
        var paramData = this.state.paramData;       // 配置参数
        var tableParam_3 = this.state.tableParam_3; // 表格参数
        var customerId = this.state.customerId;     // 表格参数
        var userIds=[];// 当前登陆人员的所有用户
        JSON.parse(sessionStorage.getItem("customerNames"))?.map(one=>{userIds.push(one.id);});
        console.log('userIds');
        console.log( userIds);
        if(userIds.length!==0){
            tableParam_3.params.customerIds.push(userIds[0]);// 查询条件
            customerId=userIds[0];
            // paramData.customerIds=userIds;                       // 提交配置
            this.queryConfig(tableParam_3.params.customerIds);      // 查询配置
        }
        await this.table.refreshTable(tableParam_3.params);
        this.getSetSelectedRow();// 初始化执行
        this.setState({tableParam_3:tableParam_3,customerId:customerId});

    }

    setTableValue_1 = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'data_1':
                var title_1='-';
                rowData.data.map(one=>{
                    if(one.itemId===1205){title_1=one.defaultValue}
                });
                return <>{title_1 ? <div className={'table_overflow'} title={title_1}>{title_1}</div> : '-'}</>;
            case 'data_2':
                var title_2='-';
                rowData.data.map(one=>{
                    if(one.itemId===3049){title_2=one.defaultValue}
                });
                return <>{title_2 ? <div className={'table_overflow'} title={title_2}>{title_2}</div> : '-'}</>;
            case 'data_3':
                var title_3='-';
                rowData.data.map(one=>{
                    if(one.itemId===3219){title_3=one.defaultValue}
                });
                return <>{title_3 ? <div className={'table_overflow'} title={title_3}>{title_3}</div> : '-'}</>;
            case 'data_4':
                var title_4='-';
                rowData.data.map(one=>{
                    if(one.itemId===3221){title_4=one.defaultValue}
                });
                return <>{title_4 ? <div className={'table_overflow'} title={title_4}>{title_4}</div> : '-'}</>;
            case 'data_5':
                var title_5='-';
                rowData.data.map(one=>{
                    if(one.itemId===3224){title_5=one.defaultValue}
                });
                return <>{title_5 ? <div className={'table_overflow'} title={title_5}>{title_5}</div> : '-'}</>;
            case 'data_6':
                var title_6='-';
                rowData.data.map(one=>{
                    if(one.itemId===3225){title_6=one.defaultValue}
                });
                return <>{title_6 ? <div className={'table_overflow'} title={title_6}>{title_6}</div> : '-'}</>;
            case 'data_7':
                var title_7='-';
                rowData.data.map(one=>{
                    if(one.itemId===3226){title_7=one.defaultValue}
                });
                return <>{title_7 ? <div className={'table_overflow'} title={title_7}>{title_7}</div> : '-'}</>;
            case 'data_8':
                var title_8='-';
                rowData.data.map(one=>{
                    if(one.itemId===3227){title_8=one.defaultValue}
                });
                return <>{title_8 ? <div className={'table_overflow'} title={title_8}>{title_8}</div> : '-'}</>;
            case 'data_9':
                var title_9='-';
                rowData.data.map(one=>{
                    if(one.itemId===3228){title_9=one.defaultValue}
                });
                return <>{title_9 ? <div className={'table_overflow'} title={title_9}>{title_9}</div> : '-'}</>;
            case 'data_10':
                var title_10='-';
                rowData.data.map(one=>{
                    if(one.itemId===3229){title_10=one.defaultValue}
                });
                return <>{title_10 ? <div className={'table_overflow'} title={title_10}>{title_10}</div> : '-'}</>;
            case 'data_11':
                var title_11='-';
                rowData.data.map(one=>{
                    if(one.itemId===3230){title_11=one.defaultValue}
                });
                return <>{title_11 ? <div className={'table_overflow'} title={title_11}>{title_11}</div> : '-'}</>;
            case 'data_12':
                var title_12='-';
                rowData.data.map(one=>{
                    if(one.itemId===3231){title_12=one.defaultValue}
                });
                return <>{title_12 ? <div className={'table_overflow'} title={title_12}>{title_12}</div> : '-'}</>;

            case 'data_13':
                var title_13='-';
                rowData.data.map(one=>{
                    if(one.itemId===3232){title_13=one.defaultValue}
                });
                return <>{title_13 ? <div className={'table_overflow'} title={title_13}>{title_13}</div> : '-'}</>;
            case 'data_14':
                var title_14='-';
                rowData.data.map(one=>{
                    if(one.itemId===3233){title_14=one.defaultValue}
                });
                return <>{title_14 ? <div className={'table_overflow'} title={title_14}>{title_14}</div> : '-'}</>;
            case 'data_15':
                var title_15='-';
                rowData.data.map(one=>{
                    if(one.itemId===3234){title_15=one.defaultValue}
                });
                return <>{title_15 ? <div className={'table_overflow'} title={title_15}>{title_15}</div> : '-'}</>;
            case 'data_16':
                var title_16='-';
                rowData.data.map(one=>{
                    if(one.itemId===3235){title_16=one.defaultValue}
                });
                return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;
            case 'data_17':
                var title_17='-';
                rowData.data.map(one=>{
                    if(one.itemId===3236){title_17=one.defaultValue}
                });
                return <>{title_17 ? <div className={'table_overflow'} title={title_17}>{title_17}</div> : '-'}</>;
            case 'data_18':
                var title_18='-';
                rowData.data.map(one=>{
                    if(one.itemId===3237){title_18=one.defaultValue}
                });
                return <>{title_18 ? <div className={'table_overflow'} title={title_18}>{title_18}</div> : '-'}</>;
            case 'systemTime':
                var title_19='-';
                var juder = true;
                rowData.data.map(one=>{
                    if(one?.systemTime){
                        if(juder){
                            title_19 = moment(one.systemTime).format('YYYY-MM-DD HH:mm:ss');
                            juder=false;
                        }
                    }
                });


                return <>{title_19 ? <div className={'table_overflow'} title={title_19}>{title_19}</div> : '-'}</>;

            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div>
                    <div className={'topSearchBox'} style={{height:'60px'}}>
                        <h3>设备数据推送</h3>
                    </div>
                    <div style={{'backgroundColor': '#ffffff'}}>
                        <div id={'video-container'}/>
                        <div style={{padding:'0 0 0 0',width:'100%',height:'400px'}}>
                            <Tabs tabPosition={"top"} animated={true}>
                                {/*<TabPane tab="报警控制器" key="1">*/}
                                {/*    <div style={{width:'100%',height:'100%'}}>*/}
                                {/*        <div style={{width:'100%',height:'25px',paddingRight:"10px"}}>*/}
                                {/*            <a className={'addLink'} style={{float: 'right',right:'0px'}}*/}
                                {/*                  onClick={async()=>{await this.table.refreshTable(this.state.tableParam_1.params);}} >*/}
                                {/*                <RedoOutlined />*/}
                                {/*                刷新*/}
                                {/*            </a>*/}
                                {/*        </div>*/}
                                {/*        <div style={{width:'100%',height:'310px',overflowY: 'auto'}}>*/}
                                {/*            <TableComponents*/}
                                {/*                paramenter={this.state.tableParam_1}*/}
                                {/*                setData = {pageDeviceInfo}*/}
                                {/*                // setTableValue={this.setTableValue}*/}
                                {/*                getSelectChange={this.getSelectChange}*/}
                                {/*                ref={e => this.table = e}*/}
                                {/*            >*/}
                                {/*            </TableComponents>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</TabPane>*/}
                                {/*<TabPane tab="报警探测器" key="2">*/}
                                {/*    <div style={{width:'100%',height:'100%'}}>*/}
                                {/*        <div style={{width:'100%',height:'25px',paddingRight:"10px"}}>*/}
                                {/*            <a className={'addLink'} style={{float: 'right',right:'0px'}}*/}
                                {/*               onClick={async()=>{await this.table.refreshTable(this.state.tableParam_2.params);}} >*/}
                                {/*                <RedoOutlined />*/}
                                {/*                刷新*/}
                                {/*            </a>*/}
                                {/*        </div>*/}
                                {/*        <div style={{width:'100%',height:'310px',overflowY: 'auto'}}>*/}
                                {/*            <TableComponents*/}
                                {/*                paramenter={this.state.tableParam_2}*/}
                                {/*                setData = {listDeviceRealTimeData}*/}
                                {/*                // setTableValue={this.setTableValue}*/}
                                {/*                getSelectChange={this.getSelectChange}*/}
                                {/*                ref={e => this.table = e}*/}
                                {/*            >*/}
                                {/*            </TableComponents>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</TabPane>*/}
                                <TabPane tab="阀井" key="3">
                                    <div style={{width:'100%',height:'100%'}}>
                                        <div style={{width:'100%',height:'25px',paddingRight:"10px",}}>
                                            <Select
                                                // mode="tags"
                                                style={{ width: '60%',paddingLeft:'10px' }}
                                                value={this.state.customerId ??[]}
                                                size={'small'}
                                                placeholder="请选择客户"
                                                allowClear={true}
                                                showSearch
                                                onChange={async (v,e)=>{
                                                    var tableParam_3 = this.state.tableParam_3;
                                                    tableParam_3.params.customerIds=[];
                                                    tableParam_3.params.customerIds.push(v);
                                                    this.setState({
                                                        tableParam_3:tableParam_3,
                                                        customerId:v
                                                    });
                                                    this.queryConfig(this.state.tableParam_3.params.customerIds);
                                                    await this.table.refreshTable(this.state.tableParam_3.params);
                                                }}
                                                fieldNames={{label: 'industrialName', value: 'id', }}
                                                options={JSON.parse(sessionStorage.getItem("customerNames"))??[]}
                                            />
                                            <Button type={'primary'} style={{left:'10px'}}
                                                    size={'small'}
                                               onClick={async()=>{
                                                   await this.table.refreshTable(this.state.tableParam_3.params);
                                                   this.queryConfig(this.state.tableParam_3.params.customerIds);
                                                   this.getSetSelectedRow();
                                               }} >
                                                查询
                                            </Button>

                                            {/*<a className={'addLink'} style={{float: 'right',right:'0px'}}*/}
                                            {/*   onClick={async()=>{await this.table.refreshTable(this.state.tableParam_3.params);}} >*/}
                                            {/*    <RedoOutlined />*/}
                                            {/*    刷新*/}
                                            {/*</a>*/}
                                        </div>
                                        <div style={{width:'100%',height:'310px',overflowY: 'auto'}}>
                                            <TableComponents
                                                id={'devId'}
                                                paramenter={this.state.tableParam_3}
                                                setData = {pageRealTimeValve}
                                                setTableValue={this.setTableValue_1}
                                                getSelectChange={this.getSelectChange}
                                                ref={e => this.table = e}
                                            >
                                            </TableComponents>
                                        </div>
                                    </div>
                                </TabPane>
                                {/*<TabPane tab="家用报警器" key="4">*/}
                                {/*    <div style={{width:'100%',height:'100%'}}>*/}
                                {/*        <div style={{width:'100%',height:'25px',paddingRight:"10px"}}>*/}
                                {/*            <a className={'addLink'} style={{float: 'right',right:'0px'}}*/}
                                {/*               onClick={async()=>{await this.table.refreshTable(this.state.tableParam_4.params);}} >*/}
                                {/*                <RedoOutlined />*/}
                                {/*                刷新*/}
                                {/*            </a>*/}
                                {/*        </div>*/}
                                {/*        <div style={{width:'100%',height:'310px',overflowY: 'auto'}}>*/}
                                {/*            <TableComponents*/}
                                {/*                paramenter={this.state.tableParam_4}*/}
                                {/*                setData = {pageDeviceInfo}*/}
                                {/*                // setTableValue={this.setTableValue}*/}
                                {/*                getSelectChange={this.getSelectChange}*/}
                                {/*                ref={e => this.table = e}*/}
                                {/*            >*/}
                                {/*            </TableComponents>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</TabPane>*/}
                            </Tabs>
                        </div>
                    </div>
                    <div style={{padding:'0 0 0 0',width:'100%',height:'360px'}}>
                        <div style={{width:'100%',height:'90%'}}>
                            <div style={{width:'100%',height:'40%'}}>
                                <div style={{width:'100%',height:'26px',paddingLeft:'10px'}}>
                                    <span style={{fontSize:'18px'}}>推送内容</span>
                                </div>
                                <div style={{width:'100%',height:'80%',padding:'10px'}}>
                                    <div style={{width:'100%',height:'100%',border:'1px #d9d9d9 solid',padding:'30px 30px 30px 60px'}}>
                                        <Checkbox.Group
                                            value={this.state.measures}
                                            onChange={(v)=>{
                                                console.log(v);
                                                this.setState({measures:v});

                                            }}
                                            // options={this.state.forItemData}
                                        >
                                            {this.state.forItemData.map(
                                                one=>{
                                                    return(
                                                        <div style={{float:'left'}}>
                                                            <Checkbox value={one.value}>{one.label}</Checkbox>
                                                            <div style={{width:'20px',float:'left'}}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'20%'}}>
                                <div style={{width:'100%',height:'25px',paddingLeft:'10px'}}>
                                    <span style={{fontSize:'18px'}}>目标URL</span>
                                </div>
                                <div style={{width:'100%',height:'80%'}}>
                                    <div style={{width:'60%',height:'100%',padding:'10px',float:'left'}}>
                                        <Input
                                            value={this.state.paramData.path}
                                            onChange={(e) => {
                                            var params = this.state.paramData;
                                            params.path=e.target.value;
                                            this.setState({params:params});
                                        }}/>
                                    </div>
                                    {/*<div style={{width:'40%',height:'100%',padding:'10px',float:'left'}}>*/}
                                    {/*    <Button type={'primary'}>生成推送格式</Button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div style={{width:'100%',height:'30%',overflowY:'auto',padding:'10px'}}>
                                <TextArea
                                    // disabled
                                    value={this.state.formatText||'系统自动带出'}
                                    onChange={(e) => {console.log(e.target.value);}}
                                    placeholder="Controlled autosize"
                                    // autoSize={{ minRows: 3, maxRows: 10 }}
                                />

                            </div>
                        </div>
                        <div style={{width:'100%',height:'10%'}}>
                            <div style={{width:'200px',height:'100%',float:'right'}}>
                                <Button >取消</Button>
                                <Button type={'primary'}
                                        onClick={()=>{
                                            var paramData = this.state.paramData;
                                            paramData.itemIds=this.state.measures;
                                            paramData.customerIds=this.state.tableParam_3.params.customerIds;
                                            configPushData(paramData).then(
                                                response=>{
                                                    console.log(response);
                                                    if(response.data.data){
                                                        message.info('提交成功')
                                                    }else{
                                                        message.info('提交失败')
                                                    }
                                                }
                                            )

                                        }}
                            >提交</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}
