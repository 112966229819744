import React, { Component } from 'react'
import {Layout, Checkbox, Radio, Button, Upload, Image, Table, message, Spin,Input} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import FormsText from "../../../components/common/forms/FormsText";
import './securityDetails.less'
import {queryCheckInfoDetail, queryCheckTemplate, saveCheckInfoDetail, updateResults} from "../../../services/meter";

const { TextArea } = Input;
// 工商户安检管理-新增修改明细
export default class securityDetails extends Component {

    state = {
        // 表单参数
        formText: {
            header: {
                id: '客户号',
                securityCheckStatus:'安检状态',
                selfCheckStatus:'自检状态',
                principalPhone: '联系电话',

                customerName: '客户名称',
                address: '客户地址',
                securityCheckedTime:'最近安检时间',
                securityCheckRequiredTime:'最近应安检日期',

                principal:'联系人',
                // createDate:'创建时间'
            }
        },

        add_tableData:{
            type:1,
            name:null,
            level:null,
            status:null,
            result:null,
        },
        // 表格内容
        tableData_1_data:[],
        // 表格标题
        tableData_1: [
            {title: '对象', dataIndex: 'type', key: 'type',
                onCell: (text, index) => (this.sharedOnCell_tableData_1(text, index,"model","type"))},
            {title: '隐患名称', dataIndex: 'name', key: 'name'},
            {title: '分级', dataIndex: 'level', key: 'level'},
            {title: '状态', dataIndex: 'status', key: 'status',
                render: (text, index) => (this.sharedOnCell_tableData_2(text, index,"model","status"))},
            {title: '安检结果', dataIndex: 'result', key: 'result',
                // render: (text, index) => (this.sharedOnCell_tableData_3(text, index,"model","result"))
            },
        ],
        tableDataJuder:{dataIndex:null,index:null,juder:true},
        // 表格标题
        tableData_2: [
            {title: '对象', dataIndex: 'type', key: 'type',editable:true,
                onCell: (text, index) => (this.sharedOnCell_tableData_1(text, index,"model","type")),
                render: (text,rowData,index) => {

                return<>
                    {text?
                        <a>{text??'-'}</a>
                        :
                        <Input defaultValue={text}  onChange={async (title)=>{
                            await this.setState({tableDataJuder:true});
                            this.addTableData1('type',title.target.value,index);
                        }}
                                // onBlur={async ()=>{this.setState({tableDataJuder:true})}}
                        />
                    }
                </>
                }
            },
            {title: '隐患名称', dataIndex: 'name', key: 'name',
                render: (text, index) => {
                    return<><Input onChange={(title)=>{this.addTableData('name',title.target.value);}}/></>
                }
            },
            {title: '分级', dataIndex: 'level', key: 'level',
                render: (text, index) => {
                    return<><Input onChange={(title)=>{this.addTableData('level',title.target.value);}}/></>
                }
                },
            {title: '状态', dataIndex: 'status', key: 'status',
                render: (text, index) => (this.sharedOnCell_tableData_2(text, index,"model","status"))},
            {title: '安检结果', dataIndex: 'result', key: 'result',
                // render: (text, index) => (this.sharedOnCell_tableData_3(text, index,"model","result"))
            },
        ],
        // 表格参数
        tableData_1_param:{
            total:0,
            page:1,
            size:100,
        },

        measures:[],
        remark:null,

        // 展示模式
        selectType:this.props.location.state?.selectType||0,

        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [], // 设备照片
        newFileList:[],

        imgResult:'正常',
        load:true
    };

    tesd=(text,rowData,index) => {

        return<>
            {text?
                <a>{text??'-'}</a>
                :
                <Input defaultValue={text}  onChange={async (title)=>{
                    await this.setState({tableDataJuder:true});
                    this.addTableData1('type',title.target.value,index);
                }}
                    // onBlur={async ()=>{this.setState({tableDataJuder:true})}}
                />
            }
        </>
    };


    addTableData= async (title,data)=>{
        var tableData = this.state.add_tableData;
        tableData[title]=data;
        console.log(tableData);
        await this.setState({add_tableData:tableData});
    };
    addTableData1= async (type,data,index)=>{

        var tableData = [...this.state.tableData_1_data];
        tableData[index][type]=data;
        await this.setState({add_tableData:tableData});
    };
    componentDidMount(): void {
        console.log('this.props.location.state');
        console.log(this.props.location.state);

        if(this.props.location.state?.tableData?.id){
            this.queryCheckInfoDetail(this.props.location.state?.tableData?.id);// 查询检查明细
        }else{
            this.queryCheckTemplate();  // 查询模板
        }


    }
    // 查询检查明细
    queryCheckInfoDetail=(id)=>{
        var params =  new URLSearchParams();
        params.append('classification','安检');
        params.append('recordId',id);
        queryCheckInfoDetail(params).then(
            response=>{
                if(response?.data?.data){

                    this.setState({
                        tableData_1_data:response.data.data?.detailList||[],// 表格
                        imgResult:response.data.data?.result||'正常',       // 结果
                        fileList:response.data.data?.photos||[],            // 图片
                        measures:response.data.data?.measures??[],          // 多选框
                        remark:response.data.data?.remark??null,            // 描述
                    })
                }

            }

        );
    };

    // 查询模板
    queryCheckTemplate=()=>{
        var params =  new URLSearchParams();
        params.append('classification','安检');
        queryCheckTemplate(params).then(
            response=>{
                var tableData = this.state.tableData_1_data;
                tableData=response.data.data;
                tableData?.map(one=>{
                    one.status=0
                });
                this.setState({tableData_1_data:tableData})
            }
        )
    };

    // 安检结果展示数据
    sharedOnCell_tableData_3 = (text,index,dataIndex,key) => {
        return<>
            {this.state.selectType===0?
                null
                :
                (<span>{Number(text)===1?'不合格':Number(text) === 0 ?'合格':null}</span>)
            }
        </>
    };

    // 安检状态展示数据
    sharedOnCell_tableData_2 = (text,index,dataIndex,key) => {

        return<>
            {this.state.selectType===0?
                (<Radio.Group defaultValue={Number(text)} onChange={(v)=>{
                    var tableData1Data = this.state.tableData_1_data;
                    tableData1Data?.map(one=>{
                        if(one.id===index.id){return one.status=v.target.value}
                    });
                    this.setState({tableData_1_data:tableData1Data})
                }}>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                </Radio.Group>)
                :
                (<span>{Number(text)===1?'是':'否'}</span>)

            }
        </>
    };


    // 标签页_发货单列表3_表格样式
    // 文本，层级 变量名，key
    sharedOnCell_tableData_1 = (text,index,dataIndex,key) => {
        let dataSource = this.state.tableData_1_data; // 表格内容
        let num1 =0;
        // 判断指定行数的变量名是否为空
        if(dataSource[index][dataIndex]===null){return {rowSpan:1};}
        // 判断上层信息，控制本层是否渲染展示
        if(index!==0){
            num1=index-1;// 获取上一层的位置
            // 判断上层与本层有无相同，相同直接免去该层渲染展示
            if(dataSource[num1][key]===dataSource[index][key]){
                if(dataSource[num1][dataIndex]===dataSource[index][dataIndex]){
                    return {rowSpan:0};
                }
            }
        }
        // ---该处判断本层和下层有无相同值
        let num3 = 1;
        // 循环判断后续值是否有相同的
        for(let i =index+1;i<dataSource.length;){
            if(index===4){
            }
            // 判断本层与下层数据是否相同，相同加值，不相同就返回当前值
            if(dataSource[index][key]===dataSource[i][key]){
                if(dataSource[index][dataIndex]===dataSource[i][dataIndex]){
                    num3++;
                }else{
                    return {rowSpan: num3};
                }
                i++;
            }else{
                return {rowSpan: num3};
            }
        }
        return {rowSpan:num3}
    };

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
            // console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            // funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList:file.fileList});
        // console.log(this.state.fileList)
    };

    // 保存检查明细
    saveCheckInfoDetail=()=> {
        var tableData1Data = this.state.tableData_1_data;           // 表格列表
        var fileList = this.state.fileList;                          // 图片列表
        let user = JSON.parse(sessionStorage.getItem("user"));  // 用户

        var params = {
            "classification": "安检",
            "customerId": this.props.location?.state?.rowData?.customerId||null,
            "customerName": this.props.location?.state?.rowData?.customerName||null,
            "address": this.props.location?.state?.rowData?.address||null,
            "detailList":   tableData1Data||null,
            "operator": user.orgId,
            "operatorName": user.orgName,
            "photos":[],
            "measures":this.state.measures.length!==0?this.state.measures:null,
            'remark':this.state?.remark??null,
        };
        fileList.map(one=>{
            params.photos.push({
                "customerId": this.props.location?.state?.rowData?.customerId||null,
                "path": one?.response||null,
            })
        });
        this.setState({load:false});
        // 保存检查明细
        saveCheckInfoDetail(params).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    this.setState({load:true});
                    message.info('提交成功');
                    this.updateResults();// 更新评估结果
                    return  this.props.history.go(-1);
                }else{
                    this.setState({load:true});
                    message.info('提交失败'+response?.data?.responseInfo?.description);
                }
            }
        )
    };
    updateResults=()=>{
        // 更新评估结果
        updateResults({customerId:this.props.location?.state?.rowData?.customerId}).then(
            response=>{
                if(response?.data?.data){

                }
            }
        )
    };
    render() {

        const uploadButton = (
            <div>
                {/*<Icon type="plus" />*/}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (
            <Layout id={'securityDetails'} className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>

                {/*加载*/}
                <div style={{
                    paddingTop:'25%',
                    zIndex:9999,
                    textAlign: 'center',
                    width:'100%',
                    height:'100%',
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    backgroundColor:'rgba(193,193,193,0.3)',
                    display: this.state.load?'none':null
                    }}
                >
                    <Spin/><span style={{fontSize:'21px',marginLeft:'5px'}}>提交中</span>
                </div>
                {/*返回上一页*/}
                <div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}>
                        <ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回工商户安检管理</span>
                </div>

                {/*客户信息*/}
                <div style={{width:'100%',height:'150px',ZIndex:'99'}}>
                    <span style={{fontSize:'16px',fontWeight:'bold',paddingLeft: '1vw'}}>客户信息</span>
                    <div className={'insert_table'} style={{paddingLeft: '3vw'}}>
                        <FormsText
                            record={this.props.location.state.formsTwo}
                            header={this.state.formText.header}
                            rowLen={4}
                            span={6}
                        />
                    </div>
                </div>
                {/*分割线*/}
                <div className={'divider'}/>

                <div style={{width:'100%', height:'720px',overflowX: 'auto', padding: '10px'}}>
                    {/*图标*/}
                    <div
                        className={this.state.imgResult==='正常'?'img_1':'img_2'}
                        style={{display:this.state.selectType===0?'none':null,}}
                    >
                    </div>
{/*表格---------------------------------------------------------------------------------------------------------------*/}
                    <div style={{width: '100%',height: '100%',zIndex : '1'}}>
                        <div style={{width: '100%'}}>
                            <Table
                                columns={this.state.tableData_1}
                                dataSource={this.state.tableData_1_data}
                                bordered
                                pagination={false}
                            />
                        </div>


{/*照片---------------------------------------------------------------------------------------------------------------*/}
                        <div style={{ width:'100%',height:' 170px'}}>
                            <div style={{ width:'100%',height:'15%',paddingLeft:'5px',fontWeight:'bold',color:'#000'}}>
                                <span style={{fontSize:'15px'}}>安检照片</span>
                            </div>
                            <div  style={{ width:'100%',height:'85%',paddingLeft:'5px'}}>
                                <div style={{display:this.state.selectType===0?null:'none'}}>
                                    <Upload
                                        action={this.state.fileUrl}
                                        listType="picture-card"
                                        name={"file"}
                                        fileList={this.state.fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.onChangeUpload}
                                    >
                                        {this.state.fileList.length >= 4 ? null : uploadButton}
                                    </Upload>
                                </div>
                                <div style={{display:this.state.selectType===0?'none':null}}>
                                    {
                                        this.state.fileList.map(one=>{
                                            return<>
                                                <Image width={105} src={one.path}/>
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {/*横线*/}
                        <div style={{backgroundColor:' #d9d9d9', height: '1px', width: '100%', float: 'left', margin:'3px',display:this.state.selectType===0?null:'none'}}/>

                        <div style={{height: '100px', width: '100%', float: 'left', margin:'15px 3px 3px 3px',
                            // display:this.state.selectType===0?null:'none'
                        }}
                        >
                            <div style={{width:'120px', float:'left'}}>
                                <span style={{fontWight:'bold'}}>措施</span>
                            </div>
                            <div style={{width:'180px', float:'left'}}>
                                <Checkbox.Group
                                    value={this.state.measures}
                                    onChange={(v)=>{this.setState({measures:v});}}

                                >
                                    <span><Checkbox value="线器正刚"  disabled={this.state.selectType !== 0} >限期整改</Checkbox></span>
                                    <span><Checkbox value="停业整顿"  disabled={this.state.selectType !== 0} >停业整顿</Checkbox></span>
                                </Checkbox.Group>
                            </div>
                            <div style={{width:'500px',float:'left'}}>
                                <TextArea
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    value={this.state.remark}
                                    disabled={this.state.selectType !== 0}
                                    onChange={(text)=>{
                                        this.setState({remark:text.target.value});
                                    }}
                                />
                            </div>
                        </div>
                        {/*横线*/}
                        <div style={{backgroundColor:' #d9d9d9', marginTop:'2px', height: '1px', width: '100%', float: 'left', margin:'3px'}}/>
{/*底部---------------------------------------------------------------------------------------------------------------*/}
                        <div style={{float:'right', height:'40px',margin:'3px',display:this.state.selectType===0?null:'none'}}>
                            <Button type={'primary'} onClick={()=>this.saveCheckInfoDetail()}>提交</Button>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}
