// 委托单下的检定报警器管理
import React, { Component } from 'react'
import { Layout, Button, Tabs, Input, Col, Row, message, Modal } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import { NavLink } from "react-router-dom"

import {TYPE_NAME} from '../../../components/Const'
import {getVerificationRecordById,addGasAlarmRecord,getCustomer,getCustomerOne,AlarmRecordInInvalid,updateEntrust,insertEntrust,copyEntrustInfo,getEquipmentName,getModelByAlaramId} from '../../../services/verifvication'
import {saveDataItem,getOne} from '../../../services/device'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import { data } from 'jquery';
import {funTraceInfo} from '../../../services/common'
const { TabPane } = Tabs;
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
export default class AddPoint extends Component {

    state = {
				// 表格参数
				tableParamenter: {
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						id: '序号',
						equipmentName: '设备名称',
						model: '型号规格',
						manufacturers: '制作厂家',
						startNumber: '出厂编号',
						type: '类型',
						state: '状态',
						verifier: '检定员',
						check: '核验员',
						edit: '操作',
					},
					// 弹出框
					headers: {
						code: '委托单号',
						name: '委托单位',
						unit: '状态',
						remark: '委托类型',
						type: '联系人',
						phone: '联系电话',
						updateTime: '提交时间',
						createTime: '接单时间',
						codeUser: '接单员',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						entrustId: this.props.location.state.id,	// 委托单
						page:1,
						size:10,
					}
				},
				// 总条数
				totalCount: 0,

				// params: {
				// 	entrustId:'',	// 委托单
				// 	page:1,
				// 	size:10,
				// 	// page:{
				// 	// 	page:1,
				// 	// 	size:10,
				// 	// }

				// 	// username: null,
				// 	// organizationId :this.state.treeId
				// 	// areaId: localStorage.getItem('menus')
				// },
        // 表单参数
		formParamenter: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'customerId',
					str: '委托单位',
					disabled: true,
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.onChangeSelect(k,v)
				},
				// {
				// 	variableName: 'entrustUnit',
				// 	str: '委托单位',
				// 	disabled: true,
				// 	rules: TYPE_NAME.INPUT,
				// },
				{
					variableName: 'principal',
					str: '联系人',
					rules: TYPE_NAME.INPUT,

				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'province',
					str: '省',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'verificationQuantity',
					str: '检定仪器数量',
					rules: TYPE_NAME.INPUT
				},
			]
		},
		        // 表单参数
		// formParamenter: {
		// 	modal: true,
		// 	number: 4,
        //     labelCol: 7,
		// 	layout: "horizontal",
		// 	// itemSize: 'large',
		// 	parameter: [
		// 		{
		// 			variableName: 'id',
		// 			str: '委托单位',
		// 			rules: TYPE_NAME.INPUT,
		// 		},
		// 		{
		// 			variableName: 'name',
		// 			str: '联系人',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50,
		//
		// 		},
		// 		{
		// 			variableName: 'unit',
		// 			str: '联系电话',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 		{
		// 			variableName: 'remark',
		// 			str: '单位电话',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 		{
		// 			variableName: 'remark',
		// 			str: '客户地址',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 		{
		// 			variableName: 'searchName',
		// 			str: '检定仪器数量',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 	]
		// },
        // 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'startNumber',
					str: '出厂编号',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'equipmentName',
					str: '设备名称',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.equipmentIdSelect(k,v)
				},
				{
					variableName: 'model',
					str: '型号规格',
					rules: TYPE_NAME.AutoComplete,
					data:[],
					// onChange: (k,v) => this.onChangeSelect(k,v)

				},
				{
					variableName: 'manufacturers',
					str: '制造厂家',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'location',
					str: '安装位置',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'province',
					str: '省',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					rules: TYPE_NAME.INPUT
				},
			]
		},
		data: {
			customerId: ''
		},
		dataKey: this.props.location.state?.dataKey ?? '1',
		devVisible:false, // 小窗口开关器-1
		voidVisible:false, //作废窗口开关器-1
		voidId:'',
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
		voGasAlarmRecordList:[],
		equipmentName:''
    };

	componentDidMount(){
		console.log(this.props.location);
		console.log("页面初始化");
		const {name, id} = this.props.location?.state ?? {};
		let {tableParamenter} = this.state;
		tableParamenter.params.entrustId = id;
		this.setState({tableParamenter});
		if(id) {
			this.getData(id)
		}
		getCustomer().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				console.log(data)
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].customerUnit})
					}
				}
				console.log(selectArry);
				this.state.formParamenter.parameter[0].data=selectArry;
			}
		)
		getEquipmentName().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].equipmentName})
					}
				}
				this.state.ModalFormParamenter.parameter[1].data=selectArry;
			}
		)
	}
	equipmentIdSelect = (k,v) => {
		getModelByAlaramId({alaramId: k}).then(
			response => {
				console.log(k,v,response)
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devModelName,value:data[i].devModelName})
					}
				}
				// data.model=null
				this.state.equipmentName=v.children
				if(data){
					this.setState({data: data})
					message.success('查询成功')
					this.state.ModalFormParamenter.parameter[2].data=selectArry;
				}
			}
		)
	}
	onChangeSelect = (k,v) => {
		getCustomerOne({id: k}).then(
			response => {
				const {data} = response.data
				let selectData=this.state
				selectData.customerId=data.id
				selectData.entrustUnit=data.customerUnit
				selectData.principal=data.principal
				selectData.phone=data.principalPhone
				selectData.province=data.province
				selectData.city=data.city
				selectData.district=data.district
				selectData.details=data.details
				this.setState({data: selectData})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
	}
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				text = rowData.state;
				return <>
					<div>


				{/* <div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData.id)}>
						<u>{'下载'}</u>
				</div>
				<div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData.id)}>
						<u>{'预览'}</u>
				</div> */}

				{text=='核验通过' ? <div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData.id)}>
						<u>{'下载'}</u>
				</div>
				: <div className={'tableNoLink'} title={'下载'}>
					<u>{'下载'}</u>
					</div>}

				{text=='核验通过' ? <div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData.id)}>
				<u>{'预览'}</u>
				</div>
				: <div className={'tableNoLink'} title={'预览'}>
					<u>{'预览'}</u>
				</div>}




				{text=='待检定' ? <NavLink to={{
					pathname: "/verifvication/verificationRegistration",
					state: {
						id: rowData.id,
						rowVal:rowData
					}
				}}>
				<div className={'tableLink'} title={'检定'}>
						<u>{'检定'}</u>
				</div>
				</NavLink>
				: <div className={'tableNoLink'} title={'检定'}>
					<u>{'检定'}</u>
					</div>}

				{text=='待核验' ? <NavLink to={{
					pathname: "/verifvication/verificationRegistration",
					state: {
						id: rowData.id,
						customerId:this.state.data.customerId,
						formData:true,
						status:2
					}
				}}>
				<div className={'tableLink'} title={'核验'}>
						<u>{'核验'}</u>
				</div>
				</NavLink>
				: <div className={'tableNoLink'} title={'核验'}>
				<u>{'核验'}</u>
				</div>}

				{text=='作废' ? <div className={'tableNoLink'} title={'作废'}>
				<u>{'作废'}</u>
				</div>
				: <div className={'tableLink'} title={'作废'} onClick={() => this.toVoidStatus(true,rowData.id)}>
				<u>{'作废'}</u>
				</div>}

					</div>
				</>



		default:
		return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	getData = id => {
		getVerificationRecordById({entrustId: id,page:1,size:10}).then(
				response => {
					const {data} = response.data;
					this.setState({customerId: data.customerId});
					this.setState({voGasAlarmRecordList: data.voGasAlarmRecordList});
					this.setState({data: data});
					message.success('查询成功');
					this.forms.onModalClose()
				}
			)
	};

	//创建委托单
    onSave = async () => {
		const {id,copy} = this.props.location?.state ?? {};
		console.log(this.state.voGasAlarmRecordList)
		let user = JSON.parse(sessionStorage.getItem("user"))
		let values = await this.forms.onFinishNotReset();
		values.creator=user.realName
		values.voGasAlarmRecordList=this.state.voGasAlarmRecordList
		values.id=id
    	if(copy==true){
			values.id=null
			copyEntrustInfo(values).then(data=>{
				if(data.data===true){
					message.success("创建成功");
					funTraceInfo("报警器检定管理", "创建委托单", null,values)
				}else {
					message.success("创建失败");
					this.table.refreshTable();// 刷新
				}
				this.props.history.go(-1)
			})
		}else{
		updateEntrust(values).then(data=>{
			if(data.data.data===true){
				message.success("创建成功");
				funTraceInfo("报警器检定管理", "修改委托单", null,values)
				this.table.refreshTable();// 刷新
			}else {
				message.error("创建失败");
				this.table.refreshTable();// 刷新
			}
			this.props.history.go(-1)
		})
		}

    };
	//添加设备窗口显示
	addQrcodeStatus=(devVisible)=>{
        this.setState({devVisible});
    };
	addGasAlarmData = async (value)=>{
		const {id} = this.props.location?.state ?? {};
		let devData = await this.forms.onFinishNotReset()
		if(!devData) return null
		devData.entrustId=id
		devData.equipmentName=this.state.equipmentName
		addGasAlarmRecord(devData).then(data=>{
			if(data.data.data==true){
				message.config("添加成功")
				funTraceInfo("报警器检定管理", "添加报警器记录", null,devData)
				this.addQrcodeStatus(false)
			}else{
				this.addQrcodeStatus(false)
				message.config('添加失败')

			}
			this.table.refreshTable();// 刷新表格
		});
		await this.forms.onModalClose();
	};
	//添加设备窗口显示
    clear = (e, info) => {
		let {formParamenter} = this.state;
		this.setState({dataKey: e});
		this.setState({formParamenter})
    }

	//作废检定窗口显示
	toVoidStatus=(voidVisible,id)=>{
		this.setState({voidVisible,voidId:id});
	};
	toVoid=(voidVisible)=>{
		AlarmRecordInInvalid({gasId: this.state.voidId}).then(
			response => {
				console.log(response)
				this.setState({voidVisible})
				message.success('操作成功')
				funTraceInfo("报警器检定管理", "检定作废", null,this.state.voidId)
				this.forms.onModalClose()
				this.table.refreshTable();// 刷新表格
			}
		)
    };

	//下载窗口
	downloadStatus=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus,downloadId:id});
	};
	download=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus})
		console.log(this.state.downloadId)
	};

	downloadOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadByAlarmId?id='+alarmId)
	};
	downloadOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadBlankByAlarmId')
	};

	//预览窗口
	previewStatus=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus,previewId:id});
	};
	preview=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus})
		console.log(this.state.previewId)
	};

	previewOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewByAlarmId?id='+alarmId)
	};
	previewOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewBlankByAlarmId')
	};


    render() {

		const {dataKey} = this.state;

        return (
            <Layout className={'item_body_number_back tables'}>
				<div style={{width: '100%',height: '100%',backgroundColor: '#ffffff',padding: '30px 1%'}}>
					<div className={'topTitleBox'}>
						<h3>报警器检定记录管理</h3>
						<div style={{float:'right'}}>
						{/* <Button style={{margin:'0 0 0 20px'}} type="primary" onClick={()=>{this.onSave()}}>保存</Button> */}
						<Button style={{margin:'0 0 0 20px'}} onClick={() => this.props.history.go(-1)}>返回</Button>
						</div>
					</div>

					<div style={{clear:'both',padding:'0 10px'}}>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.formParamenter}
							data={this.state.data}
						/>
					</div>
					{/*<div className={'topTitleBox'} style={{padding:'0 0 10px 0'}}>*/}
					{/*	<span className={'addLink'} onClick={() => this.addQrcodeStatus(true)}><PlusSquareOutlined />新增检定报警器</span>*/}
					{/*</div>*/}
					<div style={{clear:'both',padding:'0 10px'}}>
						<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getVerificationRecordById}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
						</TableComponents>
					</div>
					<Modal
                        title="添加检定设备"
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addGasAlarmData(true)}           // 点击确定
                        onCancel={() => this.addQrcodeStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>

					<Modal
                        title='作废'
                        style={{ top: 0 }}
                        visible={this.state.voidVisible}
                        okText="确定"
                        onOk={() => this.toVoid(false)}           // 点击确定
                        onCancel={() => this.toVoidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认作废该检定记录吗？</h4>
                        </div>
                    </Modal>
					<Modal
                        title='下载'
                        style={{ top: 0 }}
                        visible={this.state.downloadVisible}
                        okText="确定"
                        onOk={() => this.downloadStatus(false)}           // 点击确定
                        onCancel={() => this.downloadStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.downloadOpen(this.state.downloadId,0,1,0)}>记录</span>
							<span onClick={() => this.downloadOpenNull(this.state.downloadId,0,1,1)}>模板</span>
                        </div>
                    </Modal>
					<Modal
                        title='预览'
                        style={{ top: 0 }}
                        visible={this.state.previewVisible}
                        okText="确定"
                        onOk={() => this.previewStatus(false)}           // 点击确定
                        onCancel={() => this.previewStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.previewOpen(this.state.previewId,0,1,0)}>记录</span>
							<span onClick={() => this.previewOpenNull(this.state.previewId,0,1,1)}>模板</span>
                        </div>
                    </Modal>
				</div>


            </Layout>
        )
    }
}
