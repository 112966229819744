import React, { Component } from 'react'
import { Layout,Select ,message,Form} from 'antd'
import {funTraceInfo} from '../../../services/common'
import Tables from '../../../components/common/tables/Tables'
import Conditions from '../../../components/common/conditions/Conditions'
import {selectAllByCondition,getMeterCodes} from '../../../services/upload'
import moment from "moment";
import {TYPE_NAME} from '../../../components/Const'
const { Option } = Select
const FormItem = Form.Item
export default class UploadRecord extends Component{
    state = {
		// 表格参数
		tableParamenter: {
            rowSelection: false,
            // title: '痕迹记录查询',
			// 表头：变量和变量解释
			header: {
				metercode : '表钢号',
				// subnum : '子表号',
				fstdccfa : '标况累积流量(m3)',
				fstdcifa : '标况瞬时流量(m3/h)',
                fwkccfa : '工况累积流量(m3)',
				fwkcifa : '工况瞬时流量(m3/h)',		
				pagasabsu : '压力(Kpa)',
				tgas : '温度(℃)',
				// leftamount : '剩余量',
				// dtuid : 'DTU号码',
                collecttime : '数据采集时间',
				uploadtime : '上传时间',
                
			},
			// 下拉框：变量和变量解释
			headers: {
				metercode : '表钢号',
				// subnum : '子表号',
				fstdccfa : '标况累积流量(m3)',
				fstdcifa : '标况瞬时流量(m3/h)',
                fwkccfa : '工况累积流量(m3)',
				fwkcifa : '工况瞬时流量(m3/h)',		
				pagasabsu : '压力(Kpa)',
				tgas : '温度(℃)',
				// leftamount : '剩余量',
				// dtuid : 'DTU号码',
                collecttime : '数据采集时间',
				uploadtime : '上传时间',
			},
			// 表格数据
			data: [],
		},		
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            parameter: [
                {
                    type: 'input',
                    variableName: 'metercode',
                    str: '表钢号',
                    time:true
                },
                {
                    type: 'rangePicker',
                    variableName: 'uploadtime',
                    str: '上传时间',
                    time:true
                },
            ]
        },
		// 总条数
		totalCount: 0,
        pageinfo:{
            offset:1,
            limit:10,
        },
         // 参数-后端
        params: {
            metercode: null,          
            
            uploadtimeStart:null,
            uploadtimeEnd:null
        },
         // 表格加载动画
         loading: false,
         // 刷新动画
         refreshTable: false,
        keyes:[],
        listDatas:[],
        manufactureValue: [],
		meterModelValue: [],
        bos:null,

        operatorNames:null,
        operationNamees:null,
        metercode:null
	}

    componentDidMount() {
        this.getData();
       
    }


    // 条件选择：获取输入的条件
    getConditionsValue = (value={}) => {
        console.log(typeof value.uploadtime)
        let {params} = this.state;// 获取两个state的数据管理
        params.metercode = this.state.metercode;
        if(value.uploadtime){
            params.uploadtimeStart = moment(value.uploadtime?.[0]._d).format('YYYY-MM-DD HH:mm:ss');
            params.uploadtimeEnd   = moment(value.uploadtime?.[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        this.setState({params ,keyes:value.metercode,metercode:value.metercode});   // 确定修改
        console.log("ssssss",this.state.params)

        let param = {
            metercode: value.metercode,
            uploadtimeStart:this.state.params.uploadtimeStart,
            uploadtimeEnd:this.state.params.uploadtimeEnd,
            offset:this.state.pageinfo.offset,
            limit:this.state.pageinfo.limit,
           
          }
          console.log("ssssss",param)
          selectAllByCondition(param).then(
            response => {
              const {data} = response.data 
              console.log('展示的datassssssssssssssss', data)
              let {tableParamenter} = this.state
              tableParamenter.data = data
              const {pageInfo} = response.data 
              let total= pageInfo.total
              let {pageinfo} = this.state
              pageinfo.pageIndex = pageInfo.pageIndex
              pageinfo.pageLine = pageInfo.pageLine
            this.setState({
                loading: false,
                refreshTable: false,
                tableParamenter:tableParamenter,
                totalCount:total,
                pageinfo
            });
            message.success('查询成功')
        }
    ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('查询失败')
        }
    )
    
};

    getDatas =()=>{
        console.log("oooooooooo",this.state.pageinfo.pageIndex)
        let param = {
            operatorName: this.state.operatorNames,
            operationName:this.state.operationNamees,
            operationStartTime:this.state.params.operationStartTime,
            operationEndTime:this.state.params.operationEndTime,
            offset:this.state.pageinfo.pageIndex,
            limit:this.state.pageinfo.pageLine,
            traceSource:this.state.traceValue
          }
          console.log("ssssss",param)
          (param).then(
            response => {
              const {data} = response.data 
             
              let {tableParamenter} = this.state
              tableParamenter.data = data
              const {pageInfo} = response.data 
             
              let total= pageInfo.total
              let {pageinfo} = this.state
              pageinfo.pageIndex = pageInfo.pageIndex
              pageinfo.pageLine = pageInfo.pageLine
            this.setState({
                loading: false,
                refreshTable: false,
                tableParamenter:tableParamenter,
                totalCount:total,
                pageinfo
            });
            message.success('查询成功')
        }
    ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('查询失败')
        }
    )
    }

    // 清空条件
    clearCondition = () => {
        let {params} = this.state;
        params.uploadtimeStart=null
        params.uploadtimeEnd=null
        this.setState({
            metercode:null,
            params
        })
        this.getData();
    }

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'collecttime':
                text =moment(text).format('YYYY-MM-DD HH:mm:ss');
            case 'uploadtime':
                text =moment(text).format('YYYY-MM-DD HH:mm:ss');
               return(
                   <span>{text}</span>
               )
               default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    }

    // getSelects = ()=>{
    //     ().then(
	// 		response => {
	// 		  let {data} = response.data
	// 		this.setState({
    //             manufactureValue :data,
    //         })
	// 		}
	// 	  )     
    // }

    getSelectes =e =>{
        console.log("ssssssssssss",e)
        this.setState({metercode: e})
        getMeterCodes().then(
			response => {
			  let {data} = response
			this.setState({listDatas:data})
			}
		  )
			}

    // getoos =() =>{
    //             ().then(
    //                 response => {
    //                   let {data} = response.data
    //                   this.setState({meterModelValue:data})
    //                 })
            
    //                 }

     // 查询表格数据
     getData =()=>{
        this.setState({loading: true});
        let param = {
            metercode: this.state.metercode,
            uploadtimeStart:this.state.params.uploadtimeStart,
            uploadtimeEnd:this.state.params.uploadtimeEnd,
            offset:this.state.pageinfo.offset,
            limit:this.state.pageinfo.limit,
           
          }
          console.log("参数",param)
        selectAllByCondition(param).then(
            response=>{
                console.log("response ："+response);
                let {data} = response.data;
                let {tableParamenter} = this.state;
                tableParamenter.data = data;
                const {pageInfo} = response.data 
                console.log('分页111111111111', data);
                let total= pageInfo.total
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter:tableParamenter,
                    totalCount:total
                });
                // this.getSelect();
                this.getSelectes();
                // this.getoos();
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                // this.getSelect();
                this.getSelectes();
                // this.getoos();
                message.error('查询失败')

            }
        )
    };

	// 菜单栏: 获取菜单栏选中的值
	getMenusValue = value => {
		console.log('菜单栏选中的值',value)
	}

	// 表格：刷新方法
	refreshTable = () => {
        this.getData();
		console.log('表单刷新了')
	}

	// 表格：导出
	exportTable = () => {
		console.log('数据导出')
	}

	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
        let {pageinfo} = this.state
	    pageinfo.offset = page
		pageinfo.limit = pageSize
		this.setState({pageinfo})
        console.log("====分页参数变化",this.state.pageinfo)
        // if(this.state.metercode == null ){
            this.getData()  
        //     console.log("popob")
        //   }else{
        //     this.getDatas()
        //     console.log("popoc")
        //   }
	}

    getbos=e=>{
       this.setState({traceValue: e})
       let popo={
        traceSource :e,
       }
       (popo).then(
        response => {
          let {data} = response.data
          console.log("sssss",data)
          this.setState({
              meterModelValue:data,
            operationNamees:null
        })
        })
    }

    improtTable = () => {
        console.log('数据导入')
    }

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{padding: '0.5% 0.5% 0 0.5%'}}>
                    <Conditions 
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >

						{/* <FormItem
							label={"表钢号"}
							rules={TYPE_NAME.REQ_SELECT}
						>
							<Select 
								style={{minWidth: '7vw', maxWidth: '10vw'}}
								value={this.state.metercode}
								placeholder={`选择表钢号`}                  
								onChange={e => this.getSelectes(e)}  
							>
								{
									this.state.listDatas?.map(name => <Option key={name}>{name}</Option>)
								}
							</Select>
						</FormItem> */}

                    </Conditions>
                </div>
                
				<Tables 
					tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
					exportTable={this.exportTable}  // 导出时触发的方法
					refreshTable={this.refreshTable}  // 刷新时触发的方法
					getPagination={this.getPagination} // 分页器变化时触发的方法
					improtTable={this.improtTable} // 导入方法
                    setTableValue={this.setTableValue}
                    pagination={{
                        totalCount: this.state.totalCount, // 总条数
                        page: this.state.pageinfo.offset,// 当前页数
                        size: this.state.pageinfo.limit // 页面条数
                    }}
				/>
            </Layout>
        )
    }
}