import { Component } from 'react'

import { Button,Drawer} from 'antd'

import FormsText from '../../forms/FormsText'
import './drawers.less'

class Drawers extends Component {
    render() {

        return (
            <Drawer
                title="详细信息"
                width={this.props.drawerWidth ?? 900}
                onClose={this.props.onClose}
                visible={this.props.visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div style={{textAlign: 'right' }}>
                        <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>
                            关闭
                        </Button>
                    </div>
                }
            >
                <div className={'drawerForm insert_table'}>
                    <FormsText
                        record = {this.props.record}
                        header = {this.props.header}
                        rowLen = {this.props.rowLen}
                        layout = {"vertical"}
						span = {0}
                    />
                </div>
                {this.props.container}
                
            </Drawer>
        );
    }
}

export default Drawers