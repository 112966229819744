// 燃气报警器页面
import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag, Alert} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'

import {getAlarmList,insertAlarm,getOneAlarm,getCustomer,getCustomerOne,getEquipmentName,getModelByAlaramId} from '../../../services/verifvication'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			// importUrl: "/gateway/verification/ImpAndExpManagement/createEntrustByExcel",
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				id: '报警器序号',
				equipmentName: '设备名称',
				model: '型号',
				manufacturers: '制造厂家',
				startNumber: '出厂编号',
				companyName: '送检单位',
				createTime: '创建时间',
				verification: '历史检定记录',
				// edit: '操作',
			},
			// 弹出框
			headers: {
				id: '报警器序号',
				equipmentName: '设备名称',
				model: '型号',
				manufacturers: '制造厂家',
				startNumber: '出厂编号',
				companyName: '送检单位',
				createTime: '创建时间',
				verification: '历史检定记录',
			},
			params: {
				page:1,
				size:10,
			},
			// 是否绑定左侧区域
			bindArea: true
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'equipmentName',
					str: '设备名称'
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'model',
					str: '设备型号'
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'companyName',
					str: '送检单位'
				},

			]
		},
		    	// 总条数
		totalCount: 0,
		bjqId:'',
		modTitle:'添加报警器',
		devVisible:false, // 小窗口开关器-1
		// 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'customerId',
					str: '客户选择',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.onChangeSelect(k,v)
				},
				{
					variableName: 'companyName',
					str: '送检单位',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'equipmentName',
					str: '设备名称',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.equipmentIdSelect(k,v)
				},
				{
					variableName: 'model',
					str: '型号规格',
					rules: TYPE_NAME.AutoComplete,
					data:[],
					// onChange: (k,v) => this.onChangeSelect(k,v)

				},
				{
					variableName: 'manufacturers',
					str: '制造厂家',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'location',
					str: '安装位置',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'province',
					str: '省',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'longitude',
					str: '经度',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'latitude',
					str: '维度',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'startNumber',
					str: '出厂编号',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},

			]
		},
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'verification':
			return <>
				<NavLink to={{
				pathname: "/verifvication/getVerificationRecord",
				state: {
					id: rowData.id,
				}
			}}>
			<div className={'tableLink'} title={'查看'}>
					<u>{'查看'}</u>
			</div>
			</NavLink>
			</>
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editDevStatus(true,rowData.id)}>
						<u>{'修改'}</u>
				</div>
				</>
			case 'createTime':
				text=moment(text).format("YYYY-MM-DD HH:mm:ss");
				return (<span>{text}</span>)
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}

	updatePoint = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}
		this.props.history.push({
			pathname: '/device/addDataItem',
			state: data
		})
	}
	componentDidMount(){
		console.log(this.state.params)
		getCustomer().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				console.log(data)
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].principal})
					}
				}
				console.log(selectArry);
				this.state.ModalFormParamenter.parameter[0].data=selectArry;
			}
		)
		getEquipmentName().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].equipmentName})
					}
				}
				this.state.ModalFormParamenter.parameter[2].data=selectArry;
			}
		)
	}
	equipmentIdSelect = (k,v) => {
		getModelByAlaramId({alaramId: k}).then(
			response => {
				console.log(v.children)
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devModelName,value:data[i].devModelName})
					}
				}
				// data.model=null
				this.state.equipmentName=v.children
				this.setState({data: data})
				message.success('查询成功')
				this.state.ModalFormParamenter.parameter[3].data=selectArry;

			}
		)
	}
	onChangeSelect = (k,v) => {
		getCustomerOne({id: k}).then(
			response => {
				const {data} = response.data
				console.log(data)
				let selectData=this.state
				selectData.customerId=data.id
				selectData.entrustUnit=data.customerUnit
				selectData.principal=data.principal
				selectData.phone=data.principalPhone
				selectData.province=data.province
				selectData.city=data.city
				selectData.district=data.district
				selectData.details=data.details
				this.setState({data: selectData})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
	}

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let params = this.state.tableParamenter.params
		let formParams=this.table.getParams()
		Object.assign(params,value)
		params.page=formParams.page
		params.size=formParams.size
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaIds = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
	getData = () => {

  	}

	//添加报警器窗口显示
	addDevStatus= async (devVisible)=>{
        await this.setState({devVisible,data:{province: '湖南省',city: '长沙市'},modTitle:'添加报警器',bjqId:''})
		await this.forms.onModalClose()
    };
	editDevStatus=(devVisible,id)=>{
        this.setState({devVisible,bjqId:id,modTitle:'修改报警器'});
		// this.state.bjqId=id;
		getOneAlarm({id: id}).then(
			response => {
				const {data} = response.data
				this.setState({data: data})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
    };

    // 添加报警器
	addDevData = async ()=>{
		console.log("添加报警器");
		let devData = await this.forms.onFinishNotReset();
		if(!devData) return null
		if(this.state.bjqId){
			devData.id=this.state.bjqId
		}
		console.log(devData);
		devData.equipmentName=this.state.equipmentName
		insertAlarm(devData).then(data=>{
			console.log(data);
			if(data.data?.data){
				message.config("添加成功");
				funTraceInfo("报警器检定管理", "添加报警器", null,devData)

				this.setState({devVisible:false,bjqId:''})
			}else{
				this.setState({devVisible:false,bjqId:''})
				message.config('测试失败');
			}
			this.table.refreshTable();// 刷新表格
		});
	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
	   this.setState({params:params});
		this.table.refreshTable();
	   // this.getData();
	}
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				{/*<h3>报警器管理 <span className={'addLink'} style={{float:'right',fontSize:'16px',color:'#1890ff',cursor:'pointer'}} onClick={() => this.addDevStatus(true)}><PlusSquareOutlined style={{marginRight:'10px'}} />新增</span></h3>*/}
				<Conditions
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >
				</Conditions>
				</div>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getAlarmList}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
				</TableComponents>
				<Modal
                        title={this.state.modTitle}
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addDevData(true)}           // 点击确定
                        onCancel={() => this.addDevStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
