import React, { Component } from 'react'
import { Layout, Button, message, Modal ,Tree} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
	queryUsernameExist,
	getOrganizationTree,
	insertUser,
	listPositionsByOrganizationId,
	getNewTree
} from '../../../services/admin'
import FormsTwo from '../../../components/common/forms/FormsTwo'

import {TYPE_NAME} from '../../../components/Const'
import '../../../style/luo.less'
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'



export default class AddAccount extends Component {

    state = {
		responseInfo:{},
        // 表单参数
		formParamenter: {
			fromData:[],
			modal: true,
			number: 3,
            labelCol: 5,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'username',
					str: '用户名',
					rules: TYPE_NAME.PHONE_NUM_NOT_NULL
				},
				// {
				// 	variableName: 'account',
				// 	str: '用户名',
				// 	rules: TYPE_NAME.NOT_BLANK
				// },
				{
					variableName: 'password',
					str: '密码',
					rules: TYPE_NAME.PASSWORD
				},
				{
					variableName: 'realName',
					str: '真实姓名',
					rules: TYPE_NAME.NOT_SPECIAL
				},
				// {
				// 	variableName: 'birthday',
				// 	str: '生日',
				// 	rules: TYPE_NAME.DATE_TIME_FORMAT
				// },
				// {
				// 	variableName: 'address',
				// 	str: '地址',
				// 	rules: TYPE_NAME.NOT_BLANK
				// },
                {
					variableName: 'positionId',
					str: '职位',
					rules: TYPE_NAME.REQ_SELECT,
					data:[
						{
							key: '',
							value: '',
						}
					]
				},
                {
					variableName: 'phone',
					str: '手机号码',
					rules: TYPE_NAME.PHONE_NUM_NOT_NULL
				},
                {
					variableName: 'email',
					str: '邮箱',
					rules: TYPE_NAME.EMAIL_NOT_NULL
				},
                {
					variableName: 'sex',
					str: '性别',
					rules: TYPE_NAME.REQ_SELECT,
					data:[
						{
							key:'男',
							value:'男'
						},
						{
							key:'女',
							value:'女'
						}
					]
				},
                {
					variableName: 'remark',
					str: '备注',
					rules: TYPE_NAME.INPUT
				},


			]

		},
		data: {},
		expandedKeys: [],
        checkedKeys:[],
        selectedKeys:[],
        autoExpandParent:[],
        tree: {
          treeData :[
          {
            title: 'name',
            key: 'id',

            children: [
              {
                title: 'name',
                key: 'id',

                children: [
                  { title: 'name', key: 'id'},
                ],
              },
            ],
          },
        ]
      },
    };

	async componentDidMount(){

		if (/^[\u4e00-\u9fa5_a-zA-Z0-9]+$/.test('1a吧》》《《')){

			console.log(1)
		}
		else
		{
			console.log(2)
		}

		this.getTreeNode();
	}

    // 获取表单信息并添加
	onSave = async () => {
        // let value = await this.forms.onFinish();
		let value = await this.forms.onFinishNotReset();
		console.log("查询到的值",value);
		// value.birthday = moment(value.birthday).format("YYYY-MM-DD")
		// console.log("111111111",value.birthday)
		// value.createTime=moment(value.createTime).format("YYYY-MM-DD HH:mm:ss")
		Modal.confirm({
			title: `是否确认添加？`,
			content: '',
			okText: '是',
			okType: 'danger',
			cancelText: '否',
			onOk: () => {
				// 新增用户
				let param= {
					username :value?.username

				};
				console.log("22222",param);

				// 检验用户
				queryUsernameExist(param).then(
					response=>{
						console.log(response.data);
						if(response.data.data === true ) {
							console.log(1);
							return message.info('该用户名已经存在，请重新编辑账户信息')
						}else{
							console.log(2);
							value.account = value?.username;
							// 新增
							insertUser(value).then(
								response1 => {
									console.log('新增');
									// response.data.data ?
									// 	message.success('新增成功',[0.5])
									// 	:
									// // 	message.error(response.data.data,[0.5]);
									// console.log(response?.data);
									console.log(response1?.data?.responseInfo.code);
									if(response1?.data.responseInfo.code===200){
										console.log(3);
										message.info('新增成功');
										this.props.history.go(-1)

									}else{
										console.log(4);
										return message.info(response1?.data?.responseInfo?.description);
									}

									// if(response1?.data.responseInfo.code!==200){
									//
									// }

									// this.props.history.go(-1)
									funTraceInfo("权限管理", "新增账户信息", null, value)
								}
							)

						}

					}
				)
			}
		})

    }


	 //展开/收起节点时触发
     onExpand = (expandedKeysValue: React.Key[]) => {
		console.log('onExpand', expandedKeysValue);
		this.setState({
			expandedKeys: expandedKeysValue,
			autoExpandParent: false
		  });
	  };

	  //点击复选框触发
	  onCheck = (checkedKeysValue: React.Key[]) => {
		console.log('onCheck', checkedKeysValue);


	  //点击树节点触发
		this.setState({
		  checkedKeys: checkedKeysValue,
		});
	  };

	  //点击树节点触发
	   onSelect = (selectedKeysValue: React.Key[], info: any) => {
		let key = info.node.key
		let param = {
			organizationId: key
		  }
		console.log('获取树节点的参数', param);
		listPositionsByOrganizationId(param).then(
			response => {
			  let {data} = response.data
			  console.log('后端返回的数据', data);
			  if(data === null){
				  message.error("该组织部门下不存在数据")
				  let {formParamenter} =this.state
					formParamenter.parameter[5].data=data
					this.setState({formParamenter})
			  }
			let {item ,formParamenter} =this.state
				item = data?.map(value => {
				return {
				  	key:value.positionId+'',
				  	value:value.name,
				}
			})
			console.log("==========",item)
			// console.log(item,formParamenter.parameter.filter(val => val.str === '职位'))
			formParamenter.parameter[3].data = item
			this.setState({formParamenter,item:item})
			message.success('查询成功')
			}
		  )
	  };


    clear = e => {
		let {data} = this.state
		if(e !== 2 || Object.keys(data).length === 0){
			this.setState({
				data: {},
				responseInfo:{}
			})
			setTimeout(() => this.forms.onModalClose(), 0)
		}
    }


	getTreeNode =()=>{//获取树节点

		this.setState({loading: true});
		//旧
		// getOrganizationTree(this.state.responseInfo).then(
		//   reponse=>{
		// 	  console.log(reponse);
		// 	  let {data} = reponse.data;
		// 	  console.log("查询到的数据",data);
		// 	  let {responseInfo,tree} = this.state;
		// 	  responseInfo.data = data;
		// 	  tree.treeData = data
		// 	  this.setState({
		// 		  loading: false,
		// 		  refreshTable: false,
		// 		  totalCount: data.total,
		// 		  responseInfo: responseInfo,
		// 		  tree
		// 	  });
		// 	  message.success('查询成功',[0.5])
		//
		//   }
		//   ).catch(
		//   	() => {
		//   		this.setState({
		// 			loading: false,
		// 			refreshTable: false,
		// 		});
		// 	  message.error('查询失败',[0.5])
		//   	});
		//新
		getNewTree({userId:JSON.parse(sessionStorage.getItem("user")).id}).then(
			reponse => {
				console.log(reponse.data.responseInfo.code);
				if(reponse.data.responseInfo.code!==200){
					return message.info('未有组织结构树数据');
				}
				let { data } = reponse.data;
				let { responseInfo, tree } = this.state;
				responseInfo.data = data;
				tree.treeData = data;
				this.setState({
					loading: false,
					refreshTable: false,
					totalCount: data.total,
					responseInfo: responseInfo,
					tree
				});
				message.success('查询成功',[0.5])
				// funTraceInfo("权限管理", "组织结构查询", null, tree)
			}
		).catch((e) => {
				this.setState({loading: false, refreshTable: false,});

				message.error('查询异常，请重新刷新页面',)
			});
	  };

	//递归
	getTreeData_1 = data => {
		data = Array.isArray(data) ? data : [];
		let tree = data?.map(value => {
			return {
				title: value?.name,
				key: value?.id,
				parent: value?.parentId,
				// dataIndex:value?.positions?.name,
				disabled: false,
				children: this.getTreeData_1(value?.children)
			}
		});
		return tree
	};

//递归
getTreeData = data => {
	let tree = data?.map(value => {
		return {
		  title:value?.nodeExtend?.name,
		  key:value?.nodeExtend?.id,
		  // dataIndex:value?.positions?.name,
		  disabled: false,
		  children: this.getTreeData(value?.children)
		}
	})
	return tree
  }

    render() {


        return (
            <Layout  className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
				<div className={'div_back_arrow'} >
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回</span>
				</div>

				<div id={'equipment_frame'}>
                    <div className={'left_frame'}>
                    <div className={'particulars_info_div'}>
                            <span className="title">组织结构：</span>
							<div className={'textBox_particulars_info_div'}>
							<Tree
							 	// checkable
                                treeData={this.getTreeData_1(this.state.tree.treeData)}
                                getTreeNode={this.getTreeNode}
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={this.state.autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                onSelect={this.onSelect}
                                selectedKeys={this.state.selectedKeys}
                            />
                        </div>
						</div>
                        </div>

						<div className={'left_frame'}>
                    <div className={'particulars_account'}>
                            <span className="title">新增账户：</span>
							<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.formParamenter}
								data={this.state.data}
						/>
							<div className={'div_button_center'}>
							<Button type="primary" onClick={this.onSave}>确定</Button>
							&nbsp;&nbsp;&nbsp;
							<Button type="primary" onClick={this.clear}>取消</Button>

						</div>
                        </div>
                        </div>

                </div>
            </Layout>
        )
    }
}
