import ajax from './ajax'

// 订单新奥--PC端甲方(新奥)多条件查询订单
export const getMeterOrderForm = data => ajax('/businesse/pcOrderBook/partAgetOrdersByCon', data, 'POST');
// 订单新奥--PC端甲方(新奥)创建一个订单
export const addOneOrder = data => ajax('/businesse/pcPartyAOrder/addOneOrder', data, 'POST');

// 订单新奥--详情页--PC端甲方(新奥)指定订单id查询订单
export const getOrderInfosByOrderId = data => ajax('/businesse/pcOrderDetail/getOrderInfosByOrderId', data, 'GET');
// 订单新奥--详情页--标签--发货信息标签--PC端根据外部订单id查申请记录
export const getApplysByOrderId = data => ajax('/businesse/pcOrderBook/getApplysByOrderId', data, 'GET');
// 订单新奥--详情页--标签--发货信息标签--PC端根据外部订单id查发货记录
export const getDeliverysByOrderId = data => ajax('/businesse/pcOrderBook/getDeliverysByOrderId', data, 'POST');
// 订单新奥--详情页--标签--申请发货标签--甲方批准一个发货申请
export const approveTheApplyDelivery = data => ajax('/businesse/pcPartyAOrder/approveTheApplyDelivery', data, 'POST');


// 订单厂家--PC端甲方(厂家)多条件查询订单
export const partBgetOrdersByCon = data => ajax('/businesse/pcOrderBook/partBgetOrdersByCon', data, 'POST');

// 订单厂家--详情页--标签--订单详情标签--PC端乙方在订单下添加一个表具
export const addOneDeviceToOrder = data => ajax('/businesse/pcPartyBOrder/addOneDeviceToOrder', data, 'POST');
// 订单厂家--详情页--标签--订单详情标签--PC端根据型号id查设备但不含实时数据详情
export const getDevInfosByClassId = data => ajax('/businesse/pcOrderDetail/getDevInfosByClassId', data, 'GET');
// 订单厂家--详情页--标签--申请发货窗口--PC端乙方在一个订单下申请发货
export const deliveryApplying = data => ajax('/businesse/pcPartyBOrder/deliveryApplying', data, 'POST');
// 订单厂家--详情页--标签--发货窗口--PC端乙方在一个订单下申请发货
export const deliveryByApply = data => ajax('/businesse/pcPartyBOrder/deliveryByApply', data, 'POST');
// 订单厂家--详情页--标签--发货窗口--PC端乙方在订单下更换一个表具
export const replaceOneDeviceToOrder = data => ajax('/businesse/pcPartyBOrder/replaceOneDeviceToOrder', data, 'POST');
// 订单厂家--详情页--标签--发货窗口--PC端乙方在订单下删除一个表具
export const delOneDeviceFromOrder = data => ajax('/businesse/pcPartyBOrder/delOneDeviceFromOrder', data, 'POST');
// 订单厂家--详情页--标签--订单详情标签--PC端根据外部订单id查换货信息
export const getReplaceInfosByOrderId = data => ajax('/businesse/pcOrderBook/getReplaceInfosByOrderId', data, 'GET');

// 订单厂家--详情页--标签--订单详情标签--PC端根据外部订单id查设备详情
export const getDevInfosByOrderId = data => ajax('/businesse/pcOrderDetail/getDevInfosByOrderId', data, 'GET');




// 查询厂家（所有厂商和型号）
export const getAllManufacturerAndModel = data => ajax('/web/deviceCtrl/getAllManufacturerAndModel', data, 'GET');
// PC端根据制造商名查型号信息
export const getModelInfoBySupplier = data => ajax('/businesse/pcOrderBook/getModelInfoBySupplier', data, 'GET');
