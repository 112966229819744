import React, { Component } from 'react'
import { Layout, Button, message, Divider, Col, Row, Select, Modal, Form } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {manualCollect, getAllDevClass, selectDevInfoAttr, listDevModelInfo, listDeviceModelByDeviceClassId} from '../../../services/meter'
import moment from 'moment'
import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
const { Option } = Select
const FormItem = Form.Item

export default class MenterOperating extends Component {

    state = {
		devType:"",
        // 表单参数
		formParamenter: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'type',
					disabled:true,
					str: '表具类型',
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: '1',
							value: '备用表'
						},
						{
							key: '2',
							value: '用户表'
						}
					]
				},
				{
					variableName: 'communication',
					str: '表具通讯号',
					disabled:true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20
				}
			]
		},
		modelForm: {
			modal: true,
			number: 2,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		deviceForm: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		deviceFormItem: {
			modal: true,
			number: 2,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				// {
				// 	variableName: 'devName',
				// 	str: '设备名称',
				// 	disabled:true,
				// 	rules: TYPE_NAME.INPUT
				// },
				{
					variableName: 'location',
					str: '安装位置',
					disabled:true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'searchName',
					str: '检索名称',
					disabled:true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'manualCollectDataTime',
					str: '手工采集时间',
					rules: TYPE_NAME.DATE_PICKERTIME
				}
			]
		},
		// 型号信息
		modelData: [],
		// 类型信息
		typeData: [],
		// 设备初始值
		deviceItem: {},
		// 表具信息
		meterValue: {},
		// 设备信息
		deviceType: {
			devModelName: null,
			devModelId: null, // 型号id
			model: null, // 型号名称
			attr: [] // 数据项
		},
		item: [],

		// 数据项默认数据
		deviceFormItemData: {}
    };

	async componentDidMount(){

    	console.log(this.props.location.state);

		let devId = this.props.location.state?.devId;
		if(devId) this.getDevInfo(devId);
		this.getData();
	}

	// 根据设备id查询设备信息
	getDevInfo = devId => {
		selectDevInfoAttr({devId: devId}).then(
			response => {
				let {data} = response.data;
				let arr = {}, parameter = [];
				data?.data?.forEach(value => {
					console.log(value,devId);
					arr[value.name] = value.value;
					parameter.push({
						variableName: value.name,
						str: value.name,
						rules: TYPE_NAME.INPUT,
						disabled: devId && value?.id === '1144' ? true : false
					})
				});
				let {deviceType, deviceForm} = this.state;
				deviceForm.parameter = parameter;
				deviceType.devModelId = data?.devModelId;
				deviceType.model = data?.devModelName;
				this.setState({
					deviceItem: data,
					deviceType,
					// deviceFormItemData: arr,
					deviceForm,
					item: data?.data,
					devType: data?.classId.toString(),
					devModel: data?.devModelName
				})
				setTimeout(() => this.deviceFromItem?.onModalClose(), 0)
			}
		)
	};

	// 提交表单
    onSave = async () => {
		let {meterValue, deviceType, deviceItem} = this.state
		meterValue = await this.meterFrom.onFinishNotReset()
		deviceType = await this.deviceFrom.onFinishNotReset()
		deviceItem = await this.deviceFromItem.onFinishNotReset()
		if(!meterValue || !deviceType || !deviceItem) return null
		if(meterValue.type && meterValue.communication){
			Modal.confirm({
				title: `是否绑定到${localStorage.getItem('areaName')}?`,
				onOk: () => {
					deviceType = this.getDeviceValue(deviceType)
					let value = Object.assign(meterValue, deviceType, deviceItem)
					let modelId = value.devModelId
					value.id = this.props.location.state?.id
					value.devId = this.props.location.state?.devId
					value.areaName = localStorage.getItem('areaName')
					value.devModelId = this.props.location.state?.devModelId ?? modelId
					value.meterAreaId = JSON.parse(localStorage.getItem('menus'))?.[0]
					value.manualCollectDataTime=value.manualCollectDataTime ?moment(value.manualCollectDataTime._d).format("yyyy-MM-DD HH:mm:ss") : null;
					if(value.devModelId==null) {
						message.error('未选择型号')
						return true
					}
					if(value?.attr?.length < 1) {
						message.error('该表没有数据项信息')
						return true
					}
					let arr = value.attr.filter(data => data.id === '3001' || data.id === '1144')
					// if(arr.length < 1){
					// 	message.error('该表没有表钢号或者物联网手机号')
					// 	return true
					// }
					manualCollect(value).then(
						response => {
							console.log(value)
							let {responseInfo = {}, data = false} = response.data
							let msg = value.id ? '修改' : '新增'
							if(data) {
								message.success(msg+'表具成功')
								this.meterFrom.onModalClose()
								this.deviceFrom.onModalClose()
								this.deviceFromItem.onModalClose()
								// funTraceInfo("表具信息管理", "表具新增(修改)", null,value)
								this.props.history.replace('/meter/meterManagement')
							}else {
								if(responseInfo?.code === 200) message.error(responseInfo?.description)
								else message.error(msg+'表具失败')
							}
						}
					)
				}
			})
		}
    }

	// 取消表单
    clear = () => {
		if(this.props.location.state?.id){
			message.warn("修改时不允许清空")
		}else{
			let {deviceForm} = this.state
			deviceForm.parameter = []
			this.setState({
				devType: null,
				devModel: null,
				deviceForm
			})
			this.meterFrom.onModalClose()
			this.deviceFrom.onModalClose()
			this.deviceFromItem.onModalClose()
		}
    }

	// 表具型号发生改变时
	openModal = (e,option) => {
		let {deviceType,devModel} = this.state
		deviceType.devModelId = e
		deviceType.devModelName = option.children
		devModel = option.children
		this.setState({deviceType,devModel})

		listDevModelInfo([e]).then(
			response => {
				let {data} = response.data ?? {}
				let items = data?.attr?.filter(value => value.common === 2)
				let {item, deviceForm} = this.state
				item = items?.map(
					value => {
						if(value?.id === '1501'){
							return {
								variableName: value.name,
								str: value.name,
								rules: TYPE_NAME.REQ_SELECT,
								data: [
									{
										key: '左进',
										value: '左进'
									},
									{
										key: '右进',
										value: '右进'
									}
								]
							}
						}
						return {
							variableName: value.name,
							str: value.name,
							rules: TYPE_NAME.NOT_BLANK
						}
					}
				)
				deviceForm.parameter = item
				this.setState({deviceForm, item: items})
			}
		)
    }

	// 设备item信息
	getDeviceValue = value => {
		let {item,deviceType} = this.state
		let meter = []
		meter = item?.map(v => {
			return {
				id: v.id,
				name: v.name,
				value: value[v.name],
			}
		})
		deviceType.attr = meter
		return deviceType
	}

	// 获取型号数据
	getData = () => {
		getAllDevClass().then(
			response => {
				this.setState({typeData: response.data.data})
			}
		)
	}

	openType = (e,_) => {
		listDeviceModelByDeviceClassId({deviceClassId: e}).then(
			response => {
				this.setState({modelData: response.data.data, devModel: null})
			}
		)
	}

    render() {

		let {modelData, typeData, deviceType,deviceItem} = this.state

        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>表具操作</span>
				</div>
                <div className={'item_body_number_back div_title_from'} style={{marginBottom: 0, marginTop: 0}}>
					<Divider style={{marginTop: 0}}>表具信息</Divider>
                    <FormsTwo
						ref = {e => this.meterFrom = e}
						formParamenter = {this.state.formParamenter}
						data = {this.props.location.state}
					/>
					<Divider>设备信息</Divider>
						<Row gutter={24} style={{marginBottom: '1%'}}>
							<Col span={10} offset={2}>
								<FormItem
                                    label={"设备类型"}
									rules={TYPE_NAME.REQ_SELECT}
                                >
                                    <Select
										disabled={this.props.location.state ? true : false}
										value={this.state.devType}
										// value={'10167'}
										defaultValue={deviceItem?.className}
										style={{width: '100%'}}
										placeholder={`选择设备类型`}
										onChange={(e,option) => this.openType(e,option)}
									>
										{
											typeData?.map(({id,name}) => <Option key={id}>{name}</Option>)
										}
									</Select>
                                </FormItem>
							</Col>
							<Col span={10}>
								<FormItem
                                    label={"设备型号"}
									rules={TYPE_NAME.REQ_SELECT}
                                >
                                    <Select
										disabled={this.props.location.state ? true : false}
										style={{width: '100%'}}
										value={this.state.devModel}
										defaultValue={deviceType.model ?? null}
										placeholder={`选择设备型号`}
										onChange={(e,option) => this.openModal(e,option)}
									>
										{
											modelData?.map(({devModelId,devModelName}) => <Option key={devModelId}>{devModelName}</Option>)
										}
									</Select>
                                </FormItem>
							</Col>
						</Row>
					<FormsTwo
						ref={e => this.deviceFromItem = e}
						formParamenter={this.state.deviceFormItem}
						data={this.state.deviceItem}
					/>
					<FormsTwo
						ref={e => this.deviceFrom = e}
						formParamenter={this.state.deviceForm}
						data={this.state.deviceFormItemData}
						getFormValue={this.getDeviceValue}
					/>
                    <div className={'div_button_center'}>
                        <Button type="primary" onClick={this.onSave}>保存</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={() => this.props.history.go(-1)}>返回</Button>
                    </div>
                </div>
            </Layout>
        )
    }
}
