import React from 'react';

import * as echarts from 'echarts';

// 折线图堆叠
class BarwithBackground extends React.Component {
    // 监控数据变化，刷新
    // componentDidUpdate(num){
    //     this.elementById();
    // }

    // 初始化
    componentDidMount() {
        console.log(this.props.data);
        this.elementById();
    }

    elementById=()=>{
        var series=[];
        this.props.data.map(
            one=>{
                series.push({
                    // name: one.label,
                    // data: one.values,
                    data:one,
                    type: this.props.dataType,
                    label: {
                        show: false
                    },
                })
            }
        );
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        chartDom.setOption(
            {
                title: {
                    text: '数据表',
                    textStyle:{
                        fontSize:'12',
                        color:"#000",
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        var result = '';
                        for (var i = 0; i < params.length; i++) {
                            result += params[i].name + '<br />';
                            result += params[i].seriesName + ': ' + params[i].value + "%<br />";
                        }
                        return result;
                    }
                },
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //         type: 'shadow'
                //     },
                // },
                legend: {
                    top: '0%',
                    left: '50px',
                    right:0,
                },
                // 滑动设定
                dataZoom: [
                    {
                        type: 'slider',
                        realtime: true,
                        // start: 0,
                        // end: 40,  // 初始展示40%
                        height: 4,
                        fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
                        borderColor: "rgba(17, 100, 210, 0.12)",
                        handleSize:0, // 两边手柄尺寸
                        showDetail: false, // 拖拽时是否展示滚动条两侧的文字
                        top:'96%',

                        // zoomLock:true, // 是否只平移不缩放
                        moveOnMouseMove:true, //鼠标移动能触发数据窗口平移
                        // zoomOnMouseWheel :true, //鼠标移动能触发数据窗口缩放
                    },
                    {
                        type: 'inside',
                        show: true,
                        height: 5,
                        // start: 1,
                        // end: 35
                    },

                ],
                // dataZoom: [
                //     {
                //         // 滚动条以及缩放
                //         type: "inside",
                //         // start: 0,
                //         // end: endZoom,    // 这里end跟数据有关系 数据越多，end越小 end是百分比例
                //         startValue: 0, // 从头开始。
                //         endValue: 6,  // 最多六个
                //         minValueSpan: 6,  // 放大到最少几个
                //         maxValueSpan: 365,  //  缩小到最多几个
                //     },
                // ],
                // 边距
                grid: {
                    top:'15%',
                    left: '0%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true
                },

                xAxis: {
                    type: 'category',
                    data: this.props.head
                },


                // xAxis:[
                //     {
                //         name:'测试1',
                //         type:'category',
                //         //让表具刻度向靠里显示
                //         axisTick:{
                //             inside:true
                //         },
                //         axisLabel:{
                //           inside:true
                //         },
                //         // 设置刻度线颜色等样式
                //         axisLine:{
                //             lineStyle:{
                //                 color:'red',
                //                 width:3
                //             }
                //         },
                //         splitLine:{
                //             show:true,
                //             lineStyle:{
                //                 type:'dashed'
                //             }
                //         }
                //     },
                //     {
                //         name:'测试2',
                //         type:'category',
                //         axisTick:{
                //             inside:true
                //         },
                //         axisLabel:{
                //             inside:true
                //         },
                //         axisLine:{
                //             lineStyle:{
                //                 color:'red',
                //                 width:3
                //             }
                //         },
                //         splitLine:{
                //             show:true,
                //             lineStyle:{
                //                 type:'dashed'
                //             }
                //         }
                //     },
                //     {
                //         name:'测试3',
                //         type:'category',
                //         axisTick:{
                //             inside:true
                //         },
                //         axisLabel:{
                //             inside:true
                //         },
                //         axisLine:{
                //             lineStyle:{
                //                 color:'red',
                //                 width:3
                //             }
                //         },
                //         splitLine:{
                //             show:true,
                //             lineStyle:{
                //                 type:'dashed'
                //             }
                //         }
                //     },
                // ],
                yAxis: {
                    type: 'value'
                },


                // series:series
                series:[
                    {
                        name: '自检率',
                        // data: one.values,

                        data: this.props.data,
                        type: this.props.dataType,

                        // label: {
                        //     show: true,
                        //     formatter(param) {
                        //         // correct the percentage
                        //         return param + ' (' + param+ '%)';
                        //     }
                        // },
                    }
                ]

            });
        chartDom.showLoading();

        setTimeout(() => {
            // setOption前隐藏loading事件
            chartDom.hideLoading();
            chartDom.setOption(chartDom.getOption());
        }, 1000);
    };
    render() {
        return (
            <div id={this.props.elementById}
                 style={{ width: '100%', height: '100%' }}
            />
        );
    }
}
export default BarwithBackground;
