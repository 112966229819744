import React, { Component } from 'react'
import { Layout, Button, message, Modal ,Tree,Checkbox,Tooltip} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import {updatePositionByUserId,getAppOrganizationTree,updateUser,listAppPositionsByOrganizationId,getFunctionList,getFunctionByPositionID} from '../../../services/admin'
import FormsTwo from '../../../components/common/forms/FormsTwo'

import {TYPE_NAME} from '../../../components/Const'
import '../../../style/luo.less'
import moment from 'moment'
import {funTraceInfo} from '../../../services/common'

export default class updateInfo extends Component {

    state = {
		responseInfo:{},
		formParamenter: {
			// datas:{},
			fromData:[],
			modal: true,
			number: 3,
            labelCol: 5,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'id',
					str: '用户代码',
					rules: TYPE_NAME.INPUT,
					disabled: true
				},
				{
					variableName: 'username',
					str: '用户名',
					rules: TYPE_NAME.NOT_BLANK,
					disabled: true
				},

				{
					variableName: 'realName',
					str: '真实姓名',
					rules: TYPE_NAME.NOT_BLANK,
					disabled: true
				},
				// {
				// 	variableName: 'birthday',
				// 	str: '生日',
				// 	rules: TYPE_NAME.DATE_TIME_FORMAT
				// },
				// {
				// 	variableName: 'address',
				// 	str: '地址',
				// 	rules: TYPE_NAME.NOT_BLANK
				// },
                {
					variableName: 'appPositionId',
					str: '职位',
					rules: TYPE_NAME.REQ_SELECT,
					// disabled: this.props.location.state ? true : false,
					data:[
						{
							key: this.props.location.state?.appPositionId,
							value: this.props.location.state?.appPositionName,
						}
					],
					onChange: (k,v) => this.getAppfun(k,v)
				},
                {
					variableName: 'phone',
					str: '手机号码',
					rules: TYPE_NAME.PHONE_NUM_NOT_NULL,
					disabled: true
				},
                // {
				// 	variableName: 'email',
				// 	str: '邮箱',
				// 	rules: TYPE_NAME.EMAIL_NOT_NULL
				// },
                // {
				// 	variableName: 'sex',
				// 	str: '性别',
				// 	rules: TYPE_NAME.REQ_SELECT,
				// 	data:[
				// 		{
				// 			key:'男',
				// 			value:'男'
				// 		},
				// 		{
				// 			key:'女',
				// 			value:'女'
				// 		}
				// 	]
				// },
                // {
				// 	variableName: 'remark',
				// 	str: '备注',
				// 	rules: TYPE_NAME.INPUT
				// },
				{
					variableName: 'status',
					str: '用户状态',
					rules: TYPE_NAME.REQ_SELECT,
					disabled: true,
					data:[
						{
							key:'0',
							value:'正常'
						},
						{
							key:'1',
							value:'未审批'
						},
						{
							key:'2',
							value:'停用'
						}
					]
				},


			]

		},
		plainOptions:[],
		checkOptions:[],
		funCheckArry:[],
		data: {},
		tables:{},
		expandedKeys: [],
        checkedKeys:[],
        selectedKeys:[],
        autoExpandParent:[],
        tree: {
          treeData :[
          {
            title: 'name',
            key: 'id',

            children: [
              {
                title: 'name',
                key: 'id',

                children: [
                  { title: 'name', key: 'id'},
                ],
              },
            ],
          },
        ]
      },
    };

	async componentDidMount(){
		this.getTreeNode();
		let pagedata=this.props.location.state ?? {};
		let ckArry=[]
		let pArry=[]
		getFunctionList().then(
			response=>{
					if(response.data.data){
						for(var i=0;i<response.data.data.length;i++)
						{

							ckArry.push({label:response.data.data[i].name,value:response.data.data[i].id.toString(),disabled: true});

						}
						this.setState({checkOptions:ckArry})
					}
				}
		)
		getFunctionByPositionID({id: pagedata.appPositionId}).then(
			response => {
				if(response.data.data){
					for(var i=0;i<response.data.data.length;i++)
					{
						pArry.push(response.data.data[i].id.toString());
					}
				}
				this.setState({plainOptions:pArry})

			}
		)
	}

	getAppfun = (k,v) => {
		getFunctionByPositionID({id: k}).then(
			response => {
				let pArry=[]
				if(response.data.data){
					for(var i=0;i<response.data.data.length;i++)
					{
						pArry.push(response.data.data[i].id.toString());
					}
				}

				this.setState({plainOptions:pArry})

			}
		)
	}

    // 获取表单信息并添加
	onSave = async () => {
        let value = await this.forms.onFinish()
		// value.birthday = moment(value.birthday).format("YYYY-MM-DD")
		// console.log("111111111",value.birthday)
		// value.createTime=moment(value.createTime).format("YYYY-MM-DD HH:mm:ss")
		console.log(value)
		Modal.confirm({
			title: `是否确认修改账户信息信息？`,
			content: '',
			okText: '是',
			okType: 'danger',
			cancelText: '否',
			onOk: () => {
				let param= {
					userId :value.id,
					appPositionId:value.appPositionId
				}

				updatePositionByUserId(param).then(
					response=>{
						// if(response.data.data === true ) {
						// 	return message.error('该用户名已经存在，请重新编辑账户信息',[1])
						// }else{
							updateUser(value).then(
								response => {
									response.data.data ? message.success('修改成功',[0.5]) : message.error('修改失败',[0.5])
									this.props.history.go(-1);
									funTraceInfo("权限管理", "修改App数据", this.props.location.state ?? {}, value)
								}
							)
						}

					// }
				)
			}
		})
    }


	 //展开/收起节点时触发
     onExpand = (expandedKeysValue: React.Key[]) => {
		console.log('onExpand', expandedKeysValue);
		this.setState({
			expandedKeys: expandedKeysValue,
			autoExpandParent: false
		  });
	  };

	  //点击复选框触发
	  onCheck = (checkedKeysValue: React.Key[]) => {
		console.log('onCheck', checkedKeysValue);


	  //点击树节点触发
		this.setState({
		  checkedKeys: checkedKeysValue,
		//   responseInfo
		});
	  };


	  //点击树节点触发

	  onSelect = (selectedKeysValue: React.Key[], info: any) => {
		console.log('onSelect', info);
		let key = info.node.key
		let param = {
			organizationId: key
		  }
		console.log('onSelect', key);
		listAppPositionsByOrganizationId(param).then(
			response => {
			  let {data} = response.data
			  console.log('onSelect', data);
			let {item ,formParamenter} =this.state
			// formParamenter.parameter.filter(val => val.str === '职位').data
				item = data?.map(value => {
				return {
					// variableName:value.name,
					// str : value.name,
					// rules: 'input',
				  	key:value.id+'',
				  	value:value.name,
				}
			})
			console.log(item,formParamenter.parameter.filter(val => val.str === '职位'))
			formParamenter.parameter[3].data = item
			// formParamenter.parameter.data=item
			// let {formParamenter} = this.state
			// formParamenter.parameter.data = item
			console.log(formParamenter.parameter.filter(val => val.str === '职位'))
			this.setState({formParamenter,item:item})
			console.log('下拉框数据', formParamenter);
			console.log('下拉框数据', item);
			message.success('查询成功',[0.5])

			}
		  )
	  };


    clear = e => {
		let {data} = this.state
		if(e !== 2 || Object.keys(data).length === 0){
			this.setState({
				data: {},
				responseInfo:{},
			})
			setTimeout(() => this.forms.onModalClose(), 0)
		}

    }


	getTreeNode =()=>{//获取树节点
		this.setState({loading: true});
		// var cars=this.props.location.state ??{};
		getAppOrganizationTree(this.state.responseInfo).then(
		  reponse=>{
			  console.log(reponse);
			  let {data} = reponse.data;
			  console.log("查询到的数据",data);
			  let {responseInfo,tree} = this.state;
			  responseInfo.data = data;
			  tree.treeData = data
			  this.setState({
				  loading: false,
				  refreshTable: false,
				  totalCount: data.total,
				  responseInfo: responseInfo,
				//   tables :cars,
				  tree
			  });
			  message.success('查询成功',[0.5])
			//   console.log("lokk look",this.state.tables);
		  }
	  ).catch(
		  () => {
			  this.setState({
				  loading: false,
				  refreshTable: false,
			  });
			  message.error('查询失败',[0.5])
		  }
	  )
	  };



//递归
getTreeData = data => {
	let tree = data?.map(value => {
		return {
		  title:value?.nodeExtend?.name,
		  key:value?.nodeExtend?.id,
		  // dataIndex:value?.positions?.name,
		  disabled: false,
		  children: this.getTreeData(value?.children)
		}
	})
	return tree
  }

    render() {
        return (
			<Layout  className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
			<div className={'div_back_arrow'} >
				<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
				<span className={'font_size2'}>返回</span>
			</div>

			<div id={'equipment_frame'}>
                    <div className={'left_frame'}>
                    <div className={'particulars_info_div'}>
                            <span className="title">组织结构：</span>
							<div className={'textBox_particulars_info_div'}>
							<Tree
							 	// checkable
                                treeData={this.getTreeData(this.state.tree.treeData)}
                                getTreeNode={this.getTreeNode}
                                onExpand={this.onExpand}
                                expandedKeys={this.state.expandedKeys}
                                autoExpandParent={this.state.autoExpandParent}
                                onCheck={this.onCheck}
                                checkedKeys={this.state.checkedKeys}
                                onSelect={this.onSelect}
                                selectedKeys={this.state.selectedKeys}
                            />
                        </div>
						</div>
                        </div>


						<div className={'left_frame'}>
                    <div className={'particulars_account'}>
                            <span className="title">修改账户：</span>
							<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.formParamenter}
								// data={this.state.data}
								data={this.props.location.state ?? {}}
						/>

							<h4 style={{fontSize:'16px'}}>APP功能</h4>
							<div className={'funCheckList'}>
								<Checkbox.Group
									key={this.state.plainOptions}
									defaultValue={this.state.plainOptions} // 多选框的值
									style={{ width: '100%' }}
									// onChange={this.onChange}    //多选框点击事件
								>
									{
										// 根据右框各个数据展示出来
										this.state.checkOptions.map(item => (
												<Tooltip title={item.name}>
													<Checkbox
														disabled={item.disabled}    // 状态控制
														key={item.value}               // id
														value={item.value.toString()}>
														<span>{item.label}</span>
													</Checkbox>
												</Tooltip>
										))
									}
								</Checkbox.Group>
							</div>



							<div className={'div_button_center'}>
								<Button type="primary" onClick={this.onSave}>确定</Button>
								&nbsp;&nbsp;&nbsp;
								<Button type="primary" onClick={this.clear}>取消</Button>
							</div>
                        </div>
                        </div>

                </div>


            </Layout>
        )
    }
}
