import ajax from './ajax'





// 评论列表
export const getCommentsByPcAdmin = data => ajax('/ikitmanage/PcMine/getCommentsByPcAdmin', data, 'POST');
//获取设备列表
export const pcGetUserDevicesByCondition = data => ajax('/ikitmanage/PcDevice/pcGetUserDevicesByCondition', data, 'POST');
//获取版本列表
export const getVersionInfosByPcAdmin = data => ajax('/ikitmanage/PcMine/getVersionInfosByPcAdmin', data, 'POST');

//获取设备类型
export const getDeviceTypes = data => ajax('/ikitmanage/PcDevice/getDeviceTypes', data, 'POST');


//获取设备状态信息
export const equipmentStatusInfo = data => ajax('/ikitmanage/messageManage/info/equipmentStatusInfo', data, 'POST');

//获取检定到期信息
export const verificationExpirationInfo = data => ajax('/ikitmanage/messageManage/info/verificationExpirationInfo', data, 'POST');

//获取检定结果信息
export const verificationResultsInfo = data => ajax('/ikitmanage/messageManage/info/verificationResultsInfo', data, 'POST');

//获取证书生成信息
export const certificateNotificationInfo = data => ajax('/ikitmanage/messageManage/info/certificateNotificationInfo', data, 'POST');

//获取用户信息
export const getPersonnelInfo = data => ajax('/auth/admin/user/getPersonnelInfo', data, 'POST');
//修改用户状态
export const updateUserStatus = data => ajax('/auth/admin/user/updateUserStatus', data, 'POST');



//给用户新增或修改组织机构
export const addOrUpdateAllocation = data => ajax('/newAuth/admin/user/addOrUpdateAllocation', data, 'POST');

//给用户新增或修改组织机构
export const userBoundOrg = data => ajax('/newAuth/admin/user/userBoundOrg', data, 'GET');


// 回复
export const replyCommentByParentId = data => ajax('/ikitmanage/PcMine/replyCommentByParentId', data, 'POST');

//发布版本
export const releaseVersionByPcAdmin = data => ajax('/ikitmanage/PcMine/releaseVersionByPcAdmin', data, 'POST');

//获取版本详情
export const getVersionById = data => ajax('/ikitmanage/PcMine/getVersionById', data, 'GET');



//获取二维码
export const getQrcodeDetail = data => ajax('/qrcode/qrcode/getQrcodeDetail', data, 'GET');
// 新增二维码
export const addQrcode = data => ajax('/qrcode/qrcode/addQrcode', data, 'POST');
// 修改二维码
export const updateQrcode = data => ajax('/qrcode/qrcode/updateQrcode', data, 'POST');


//获取版本详情
export const uploadOneImgToOss = data => ajax('/ikitmanage/ossManagement/uploadOneImgToOss', data, 'GET');













