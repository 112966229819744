import ajax from './ajax'

// 分页查询表具信息和该表具下的设备信息 ---> 根据计量点名称查询
export const pageLikeMpName = data => ajax('/web/mpCtrl/pageLikeMpName', data, 'POST')

// 分页查询表具---表钢号
export const getMetersBySteelNum = data => ajax('/web/meterCtrl/getMetersBySteelNum', data, 'POST')

// 获取设备的所有型号信息
export const getAllDevClass = data => ajax('/web/deviceCtrl/getAllDevClass', data, 'GET')

// 根据设备id获取所有数据项
export const getItemByDevId = data => ajax('/web/deviceCtrl/getItemByDevId', data, 'GET')

// 查询型号下的数据项信息
export const listDevModelInfo = data => ajax('/web/deviceCtrl/listDevModelInfo', data, 'POST')

// 获取类型下的型号信息
export const listDeviceModelByDeviceClassId = data => ajax('/web/deviceCtrl/listDeviceModelByDeviceClassId', data, 'GET')

// 根据设备id查询设备信息
export const selectDevInfoAttr = devId => ajax('/web/deviceCtrl/selectDevInfoAttr', devId, 'GET')

// 新增表具
export const saveMeter = data => ajax('/web/meterCtrl/saveMeter', data, 'POST')

//手工采集
export const manualCollect = data => ajax('/web/meterCtrl/manualCollect', data, 'POST')

// 根据表具id查询表具信息
export const getMeterById = meterId => ajax('/web/meterCtrl/getMeterById', meterId, 'GET')


// 根据表具id查询表具实时信息
export const getRealTimeDataById = meterId => ajax('/web/meterCtrl/getMeterById', meterId, 'GET')

// 新增计量点
export const saveMp = data => ajax('/web/mpCtrl/saveMp', data, 'POST')

// 根据计量点名称查询单个表具信息
export const getMpByName = data => ajax('/web/mpCtrl/getMpByName', data, 'GET')

// 根据计量点id查询
export const getOne = data => ajax('/web/mpCtrl/getOne', data, 'GET')

// 新增计量点底表数据
export const addHistoryMeter = data => ajax('/measurepoint/historyMeter/addHistoryMeter', data, 'POST')

// 查询所有区域信息
export const meterAreaAll = data => ajax('/measurepoint/treeNode/getAll', data, 'GET')



//开通计量点
export const openMp = data => ajax('/web/stateMachineCtrl/openMp', data, 'POST')


//用气性质接口
//查询客户性质列表
export const getCusPropertiesList = data => ajax('/web/gas/getCusPropertiesList', data, 'GET')

//查询用气性质列表
export const getGasPropeertiesInCusId = data => ajax('/web/gas/getGasPropeertiesInCusId', data, 'GET')

//查询用气性质列表
export const getGasPropertiesAndDeviceInGasId = data => ajax('/web/gas/getGasPropertiesAndDeviceInGasId', data, 'POST')



//根据用气性质名称查询用气性质
export const getGasPropertiesInName = data => ajax('/web/gas/getGasPropertiesInName', data, 'POST')
//新增
export const addGasProperties = data => ajax('/web/gas/addGasProperties', data, 'POST')
//修改
export const updateGasProperties = data => ajax('/web/gas/updateGasProperties', data, 'POST')
//删除
export const removeGasProperties = data => ajax('/web/gas/removeGasProperties', data, 'GET')


//根据设备名称查询设备
export const getGasDeviceInName = data => ajax('/web/gasDevice/getGasDeviceInName', data, 'POST')
//新增
export const addGasDevice = data => ajax('/web/gasDevice/addGasDevice', data, 'POST')
//修改
export const updateDevice = data => ajax('/web/gasDevice/updateDevice', data, 'POST')
//删除
export const removeGasDevice = data => ajax('/web/gasDevice/removeGasDevice', data, 'GET')


//根据设备名称查询性质和设备
export const getAllRelationships = data => ajax('/web/gasDevice/getAllRelationships', data, 'POST')
//新增
export const insertAllRelationships = data => ajax('/web/gasDevice/insertAllRelationships', data, 'POST')
//删除
export const removeAllRelationships = data => ajax('/web/gasDevice/removeAllRelationships', data, 'GET')
//查询所有性质
export const getAllGasProperties = data => ajax('/web/gas/getAllGasProperties', data, 'GET')


//获取未安装表具
export const getMetersByAreaIds = data => ajax('/web/customerCtrl/getMetersByAreaIds', data, 'POST')

//替换未安装表具
export const replaceMeter = data => ajax('/web/stateMachineCtrl/replaceMeter', data, 'POST')



//查询所有性质
export const updateMeterStencilNum = data => ajax('/web/meterCtrl/updateMeterStencilNum', data, 'GET');

// 氢气表具实时数据报表---23-7-19
export const getHydrogenMeterRealTimeData = data => ajax('/web/reportCtrl/getHydrogenMeterRealTimeData', data, 'POST');


// 根据客户Id查询安检点燃气具信息
export const getAllInfoByCustomerId = data => ajax('/checkup/gasSafety/getAllInfoByCustomerId', data, 'GET');
// 根据客户ID查询燃气具信息
export const getGasBurnerInfoByCustomerId = data => ajax('/checkup/gasSafety/getGasBurnerInfoByCustomerId', data, 'GET');


// 新增(修改)燃气具
export const addOrUpdateGasBurner = data => ajax('/checkup/gasSafety/addOrUpdateGasBurner', data, 'POST');
// 删除燃气具
export const removeGasBurner = data => ajax('/checkup/gasSafety/removeGasBurner', data, 'GET');

// 新增(修改)安检点
export const addOrUpdateSecurityCheckpoint = data => ajax('/checkup/gasSafety/addOrUpdateSecurityCheckpoint', data, 'POST');
// 删除安检点
export const removeSecurityCheckpoint = data => ajax('/checkup/gasSafety/removeSecurityCheckpoint', data, 'GET');

// 查询所有的用气设备
export const getGasDeviceList = data => ajax('/checkup/gasSafety/getGasDeviceList', data, 'GET');

// 查询检查记录
export const queryCheckInfoRecord = data => ajax('/checkup/checkRecord/queryCheckInfoRecord', data, 'GET');// 查询检查记录
export const querySelfCheckInfoRecord = data => ajax('/checkup/checkRecord/querySelfCheckInfoRecord', data, 'GET');
// 查询自检明细
export const querySelfCheckInfoDetail = data => ajax('/checkup/checkRecord/querySelfCheckInfoDetail', data, 'GET');

// 查询模板
export const queryCheckTemplate = data => ajax('/checkup/checkTemplate/queryCheckTemplate', data, 'GET');
// 保存检查明细
export const saveCheckInfoDetail = data => ajax('/checkup/checkInfoDetail/saveCheckInfoDetail', data, 'POST');
// 查询检查明细
export const queryCheckInfoDetail = data => ajax('/checkup/checkInfoDetail/queryCheckInfoDetail', data, 'GET');

// 查询评估报告
export const getEvaluationReport = data => ajax('/checkup/securityAssessment/getEvaluationReport', data, 'GET');
// 条件查询工商户安全评估列表
export const querySecurityAssessment = data => ajax('/checkup/securityAssessment/querySecurityAssessment', data, 'POST');

// 新增客户时间配置
export const addPoTimeTemplate = data => ajax('/checkup/planCheck/addPoTimeTemplate', data, 'POST');
// 修改客户时间配置
export const updatePoTimeTemplate = data => ajax('/checkup/planCheck/updatePoTimeTemplate', data, 'POST');

// 根据客户ID查询时间配置
export const getPoTimeTemplateByCustomerId = data => ajax('/checkup/securityAssessment/getPoTimeTemplateByCustomerId', data, 'GET');

// 查询报表
export const queryReport = data => ajax('/checkup/report/queryReport', data, 'POST');
// 地图下方数据
export const belowMapData = data => ajax('/checkup/homePage/belowMapData', data, 'GET');
// 地图左侧数据
export const leftMapData = data => ajax('/checkup/homePage/leftMapData', data, 'GET');

// 查询隐患
export const queryHiddenDanger = data => ajax('/checkup/checkInfoDetail/queryHiddenDanger', data, 'GET');

// 查询所有未绑定的设备
export const getUnboundDevices = data => ajax('/checkup/newPoint/getUnboundDevices', data, 'POST');




// 查询灶具隐患
export const queryGasBurner = data => ajax('/checkup/gasSafety/queryGasBurner', data, 'POST');


//分页条件查询客户性质列表
export const pageCusPropertiesList = data => ajax('/web/gas/pageCusPropertiesList', data, 'POST');
// 添加客户性质
export const addCusProperties = data => ajax('/web/gas/addCusProperties', data, 'POST');
// 修改客户性质
export const updateCusProperties = data => ajax('/web/gas/updateCusProperties', data, 'POST');
// 删除客户性质
export const removeCusProperties = data => ajax('/web/gas/removeCusProperties', data, 'GET');
// 保存检查明细
export const saveCheckTemplate = data => ajax('/checkup/checkTemplate/saveCheckTemplate', data, 'POST');

// 处理检查结果
export const handleCheckResult = data => ajax('/checkup/checkInfoDetail/handleCheckResult', data, 'POST');

// 提交审批
export const submitHandleCheck = data => ajax('/checkup/examineAndApprove/submitHandleCheck', data, 'POST');


// 查询灶具隐患
export const queryCustomerExamineAndApprove = data => ajax('/checkup/examineAndApprove/queryCustomerExamineAndApprove', data, 'POST');
// 通过审批
export const passHandleCheck = data => ajax('/checkup/examineAndApprove/passHandleCheck', data, 'POST');
// 驳回
export const rejectHandleCheck = data => ajax('/checkup/examineAndApprove/rejectHandleCheck', data, 'POST');
// 通过安检记录id查询驳回流程
export const queryFlowByRecordId = data => ajax('/checkup/examineAndApprove/queryFlowByRecordId', data, 'GET');
// 查询安全隐患
export const pageHiddenDanger = data => ajax('/checkup/checkInfoDetail/pageHiddenDanger', data, 'POST');

// 根据客户ID查询设备信息
export const getDevInfoByCustomerId = data => ajax('/web/valveInfo/getDevInfoByCustomerId', data, 'POST');
// 根据客户ID查询未绑定设备信息
export const devUnboundByCustomerId = data => ajax('/checkup/gasSafety/devUnboundByCustomerId', data, 'POST');

// 系统设备新增
export const addDev = data => ajax('/web/screenManagement/addDev', data, 'POST');
// 系统设备删除
export const deleteDev = data => ajax('/web/screenManagement/deleteDev', data, 'GET');
// 莹石设备新增
export const addFluoriteDev = data => ajax('/web/screenManagement/addFluoriteDev', data, 'POST');
// 莹石设备删除
export const deleteFluoriteDev = data => ajax('/web/screenManagement/deleteFluoriteDev', data, 'GET');
// 莹石设备状态
export const FluoriteDevState = data => ajax('/web/screenManagement/FluoriteDevState', data, 'GET');


// 更新评估结果
export const updateResults = data => ajax('/checkup/securityAssessment/updateResults', data, 'GET');


// 条件查询学习配置
export const getLearningConfigInCondition = data => ajax('/checkup/learn/getLearningConfigInCondition', data, 'POST');
// 任务详情
export const taskDetail = data => ajax('/checkup/learn/taskDetail', data, 'POST');
// 创建学习配置
export const addLearningConfig = data => ajax('/checkup/learn/addLearningConfig', data, 'POST');
// 根据配置ID查询配置详情信息
export const getDetailByConfigId = data => ajax('/checkup/learn/getDetailByConfigId', data, 'GET');
// 根据学习ID查询配置详情信息
export const getDetailByLearnId = data => ajax('/checkup/learn/getDetailByLearnId', data, 'GET');
// 修改学习配置
export const updateLearningConfig = data => ajax('/checkup/learn/updateLearningConfig', data, 'POST');
// 推送学习
export const pushLearning = data => ajax('/checkup/learn/pushLearning', data, 'GET');
// 废弃学习配置
export const abandonedConfig = data => ajax('/checkup/learn/abandonedConfig', data, 'GET');
// 可视化燃气大屏-安全分析
export const visualizationSafetyAnalysis = data => ajax('/web/screenManagement/visualizationSafetyAnalysis ', data, 'GET');
// 可视化燃气大屏-学习任务上报
export const learningTaskReporting = data => ajax('/checkup/learn/learningTaskReporting', data, 'POST');
// 可视化燃气大屏-更新控制器阀门状态
export const updateStatusByCustomerId = data => ajax('/web/screenManagement/updateStatusByCustomerId', data, 'GET');



// 获取热力图警告数据
export const getPageFuzzyByState = data => ajax('/web/alarmHomePage/getPageFuzzyByState', data, 'POST');

// 可视化燃气大屏-阀井探测器
export const valveMap = data => ajax('/web/screenManagement/valveMap', data, 'GET');

