import React, { Component } from 'react'
import { Layout, Button, message, Divider, Col, Row, Select, Modal, Form } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import {saveDevice, getAllDevClass, selectDevInfoAttr, listDevModelInfo, listDeviceModelByDeviceClassId} from '../../../services/device'

import {TYPE_NAME} from '../../../components/Const'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
const { Option } = Select
const FormItem = Form.Item

export default class MenterOperating extends Component {

    state = {
		showAttr:'none',
		showData:'none',
		showConfig:'none',
        // 表单参数
		formParamenter: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				// {
				// 	variableName: 'type',
				// 	str: '设备类型',
				// 	rules: TYPE_NAME.REQ_SELECT,
				// 	data: [
				// 		{
				// 			key: '1',
				// 			value: '备用表'
				// 		},
				// 		{
				// 			key: '2',
				// 			value: '用户表'
				// 		}
				// 	]
				// }
				// {
				// 	variableName: 'communication',
				// 	str: '表具通讯号',
				// 	rules: TYPE_NAME.LABLE_NOT_NULL_20
				// }
			]
		},
		modelForm: {
			modal: true,
			number: 2,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		deviceForm: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		deviceFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'className',
					str: '设备类型',
					rules: TYPE_NAME.SELECTINPUT,
					disabled: this.props.location.state ? true : false,
					data:[],
					onChange: (k,v) => this.equipmentIdSelect(k,v)
				},
				{
					variableName: 'devModelName',
					str: '型号规格',
					rules: TYPE_NAME.SELECTINPUT,
					disabled: this.props.location.state ? true : false,
					data:[],
					onChange: (k,v) => this.openModal(k,v)

				},
				{
					variableName: 'devName',
					str: '设备名称',
					rules: TYPE_NAME.NOT_BLANK
				},
				// {
				// 	variableName: 'devStatus',
				// 	str: '设备业务状态',
				// 	rules: TYPE_NAME.SELECT,
				// 	data: [
				// 		{
				// 			key: 0,
				// 			value: '异常'
				// 		},
				// 		{
				// 			key: 1,
				// 			value: '正常'
				// 		}
				// 	]
				// },
				// {
				// 	variableName: 'communicateStatus',
				// 	str: '通讯状态',
				// 	rules: TYPE_NAME.SELECT,
				// 	data: [
				// 		{
				// 			key: 0,
				// 			value: '异常'
				// 		},
				// 		{
				// 			key: 1,
				// 			value: '正常'
				// 		}
				// 	]
				// },
				// {
				// 	variableName: 'manufacture',
				// 	str: '生产厂商',
				// 	rules: TYPE_NAME.INPUT
				// },
				{
					variableName: 'searchName',
					str: '检索名称',
					rules: TYPE_NAME.NOT_BLANK
				},
				{
					variableName: 'location',
					str: '设备位置描述',
					rules: TYPE_NAME.NOT_BLANK
				}
			]
		},
		attrFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		dataFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		configFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: []
		},
		// 型号信息
		modelData: [],
		// 类型信息
		typeData: [],
		// 配置初始值
		configItem: {},
		// 数据初始值
		dataItem: {},
		// 属性初始值
		attrItem: {},
		// 设备初始值
		deviceGetData: {},
		// 表具信息
		meterValue: {},
		// 设备信息
		deviceType: {
			devModelName: null,
			devModelId: null, // 型号id
			model: null, // 型号名称
			attr: [] // 数据项
		},
		item: [],

		// 数据项默认数据
		deviceFormItemData: {}
    }

	async componentDidMount(){
		let devId = this.props.location.state?.devId
		if(devId) this.getDevInfo(devId)
		this.getData()

		getAllDevClass().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].name})
					}
				}
				this.state.deviceFormItem.parameter[0].data=selectArry;
			}
		)


	}
	equipmentIdSelect = (k,v) => {
		listDeviceModelByDeviceClassId({deviceClassId: k}).then(
			response => {
				console.log(k,v)
				const {data} = response.data

				let selectArry=[]
				if(data){
					this.state.deviceFormItem.parameter[1].data=[]
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devModelId,value:data[i].devModelName})
					}
				}
				// data.model=null
				this.state.equipmentName=v.children
				message.success('查询成功')
				this.state.deviceFormItem.parameter[1].data=selectArry;

			}
		)
	}
	// 根据设备id查询设备信息
	getDevInfo = devId => {
		selectDevInfoAttr({devId: devId}).then(
			response => {

				let {data} = response.data
				let arr = {}, parameter = []

				data?.attr?.forEach(value => {
					console.log(value,devId)
					arr[value.name] = value.value
					parameter.push({
						variableName: value.name,
						str: value.name,
						rules: TYPE_NAME.INPUT,
						disabled: devId && value?.id === '1144' ? true : false
					})
				})
				let {deviceType, deviceForm,attrFormItem} = this.state
				attrFormItem.parameter = parameter
				deviceForm.parameter = parameter
				deviceType.devModelId = data?.devModelId
				deviceType.model = data?.devModelName
				console.log(data);
				this.setState({
					deviceGetData: data,
					deviceType,
					deviceFormItemData: arr,
					deviceForm,
					item: data?.attr,
					devType: data?.className,
					devModel: data?.devModelName,
					devModelId:data?.devModelId,
					devModelName:data?.devModelName,
					classId:data?.classId,
					className:data?.className
				})
				console.log(this.state.classId)
				this.foundDataItem(data);
				setTimeout(() => this.deviceFormItemAllData?.onModalClose(), 0)
			}
		)
	}

	// 提交表单
    onSave = async () => {
		let {deviceValue, attrValue, dataValue,configValue} = this.state;
		deviceValue = await this.deviceFormItemAllData.onFinishNotReset();
		attrValue = await this.attrFormItemAllData.onFinishNotReset()
		dataValue = await this.dataFormItemAllData.onFinishNotReset()
		configValue = await this.configFormItemAllData.onFinishNotReset()
		let attrValueArry=[]
		let dataValueArry=[]
		let configValueArry=[]
		for (let key in attrValue) {
            //用for循环转换resObj对象
			attrValueArry.push({
                id: key,
                value: attrValue[key]
              });
        }
		for (let key in dataValue) {
            //用for循环转换resObj对象
			dataValueArry.push({
                id: key,
                value: dataValue[key]
              });
        }
		for (let key in configValue) {
            //用for循环转换resObj对象
			configValueArry.push({
                id: key,
                value: configValue[key]
              });
        }
		if(!deviceValue) return null
		let value = Object.assign(deviceValue, attrValue, dataValue,configValue)
		let modelId = value.devModelId
		console.log(value,modelId)
		value.devId = this.props.location.state?.devId
		value.areaName = localStorage.getItem('areaName')
		// value.devModelId = this.props.location.state?.devModelId ?? modelId
		value.devModelId = this.state.devModelId
		value.classId = this.state.classId
		value.className = this.state.className
		value.devModelName = this.state.devModelName
		value.meterAreaId = JSON.parse(localStorage.getItem('menus'))?.[0]
		value.attr=attrValueArry
		value.data=dataValueArry
		value.config=configValueArry
		console.log(this.state);
		saveDevice(value).then(
			response => {
				let {responseInfo = {}, data = false} = response.data
				let msg = value.devId ? '修改' : '新增'
				if(data) {
					// funTraceInfo("设备管理", "设备新增(修改)", null,value)
					message.success(msg+'设备成功')
					this.props.history.replace('/device/deviceManagement')
				}else {
					if(responseInfo?.code === 200) message.error(responseInfo?.description)
					else message.error(msg+'设备失败')
				}
			}
		)
    }

	// 取消表单
    clear = () => {
		if(this.props.location.state){
			message.error("修改时不允许清空")
		}else{
			let {deviceForm} = this.state
			deviceForm.parameter = []
			this.setState({
				devType: null,
				devModel: null,
				deviceForm
			})
			this.deviceFormItemAllData.onModalClose()
		}
    }
	//根据数据源输出数据项
	foundDataItem = value => {
		console.log(value);
		if(value?.attr?.length>0)
		{
			this.setState({showAttr:'block'});
			this.state.attrFormItem.parameter=[];
			let attrItem = this.state;
			for(var i=0;i<value.attr.length;i++)
			{

				this.state.attrFormItem.parameter.push({
				variableName: value.attr[i].id,
				str: value.attr[i].name,
				rules: TYPE_NAME.INPUT
				});
				attrItem[value.attr[i].id] = value.attr[i].value
			}
			this.setState({attrItem:attrItem});
		}else{
			this.setState({showAttr:'none'});
			this.state.attrFormItem.parameter=[];
		}
		if(value?.config?.length>0)
		{
			this.setState({showConfig:'block'});
			this.state.configFormItem.parameter=[];
			let configItem = this.state
			for(var i=0;i<value.config.length;i++)
			{
				this.state.configFormItem.parameter.push({
				variableName: value.config[i].id,
				str: value.config[i].name,
				rules: TYPE_NAME.INPUT
				})
				configItem[value.config[i].id] = value.config[i].value
			}
			this.setState({configItem:configItem});
		}else{
			this.setState({showConfig:'none'});
			this.state.configFormItem.parameter=[];
		}
		if(value?.data.length>0)
		{
			this.setState({showData:'block'});
			this.state.dataFormItem.parameter=[];
			let dataItem = this.state
			for(var i=0;i<value.data.length;i++)
			{
				this.state.dataFormItem.parameter.push({
				variableName: value.data[i].id,
				str: value.data[i].name,
				disabled:true,
				rules: TYPE_NAME.INPUT
				})
				dataItem[value.data[i].id] = value.data[i].value
			}
			this.setState({dataItem:dataItem});
		}else{
			this.setState({showData:'none'});
			this.state.dataFormItem.parameter=[];
		}
	}

	//根据数据源输出数据项

	// 表具型号发生改变时
	openModal = (e,option) => {
		console.log(e);
		let {deviceType,devModel,devModelId,devModelName} = this.state;
		devModelId = e;
		devModelName = option.children;
		let modelIdsArry=[];
		modelIdsArry.push(e);
		deviceType.devModelName = option.children;
		devModel = option.children;
		this.setState({deviceType,devModel,devModelId,devModelName});
		listDevModelInfo({modelIds:modelIdsArry}).then(
			response => {
				// funTraceInfo("设备管理", "型号变更", null,modelIdsArry)
				this.foundDataItem(response.data.data[0]);
			}
		)

    }

	// 设备item信息
	getDeviceValue = value => {
		let {item,deviceType} = this.state
		let meter = []
		meter = item?.map(v => {
			return {
				id: v.id,
				name: v.name,
				value: value[v.name],
			}
		})
		deviceType.attr = meter
		return deviceType
	}

	// 获取型号数据
	getData = () => {
		getAllDevClass().then(
			response => {
				this.setState({typeData: response.data.data})
			}
		)
	}

	openType = (e,option) => {
		this.setState({devType: e,className:option.children})
		listDeviceModelByDeviceClassId({deviceClassId: e}).then(
			response => {
				this.setState({modelData: response.data.data, devModel: null})
			}
		)
	}

    render() {

		let {modelData, typeData, deviceType,deviceGetData} = this.state
        return (
            <Layout className={'item_body_number_back'}>
				<div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>添加设备</span>
				</div>
                <div className={'item_body_number_back div_title_from'} style={{marginBottom: 0, marginTop: 0}}>
					<Divider style={{marginTop: 0}}>基本信息</Divider>
					{/* <Form>
					<Row gutter={24}>
							<Col span={8}>
								<FormItem
                                    label="设备类型"
									rules={[{ required: true }]}
                                >
                                    <Select
										disabled={this.props.location.state ? true : false}
										value={this.state.devType}
										defaultValue={deviceGetData?.className}
										style={{width: '100%'}}
										placeholder={`选择设备类型`}
										onChange={(e,option) => this.openType(e,option)}
									>
										{
											typeData?.map(({id,name}) => <Option key={id}>{name}</Option>)
										}
									</Select>
                                </FormItem>
							</Col>
							<Col span={8}>
								<FormItem
                                    label="设备型号"
									rules={[{ required: true }]}
                                >
                                    <Select
										disabled={this.props.location.state ? true : false}
										style={{width: '100%'}}
										value={this.state.devModel}
										defaultValue={deviceType.model ?? null}
										placeholder={`选择设备型号`}
										onChange={(e,option) => this.openModal(e,option)}
									>
										{
											modelData?.map(({devModelId,devModelName}) => <Option key={devModelId}>{devModelName}</Option>)
										}
									</Select>
                                </FormItem>
							</Col>
						</Row>
					</Form> */}
					<FormsTwo
						ref={e => this.deviceFormItemAllData = e}
						formParamenter={this.state.deviceFormItem}
						data={deviceGetData}
					/>
					<div style={{display:this.state.showAttr}}>
						<Divider>属性信息</Divider>
						<FormsTwo
						ref={e => this.attrFormItemAllData = e}
						formParamenter={this.state.attrFormItem}
						data={this.state.attrItem}
						/>
					</div>
					<div style={{display:this.state.showData}}>
						<Divider>数据信息</Divider>
						<FormsTwo
						ref={e => this.dataFormItemAllData = e}
						formParamenter={this.state.dataFormItem}
						data={this.state.dataItem}
						/>
					</div>
					<div style={{display:this.state.showConfig}}>
						<Divider>配置信息</Divider>
						<FormsTwo
						ref={e => this.configFormItemAllData = e}
						formParamenter={this.state.configFormItem}
						data={this.state.configItem}
						/>
					</div>

                    <div className={'div_button_center'}>
                        <Button type="primary" onClick={this.onSave}>保存</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button type="primary" onClick={this.clear}>清空</Button>
                    </div>
                </div>
            </Layout>
        )
    }
}
