import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
import $ from 'jquery'
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel,tempPreComparisonCurve,listDevByAreaIds} from '../../../services/steamReport'
import {getDateByUserId} from "../../../services/admin";

echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;


export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			// 弹出框
			headers: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			params: {
				areaIds:[289],
				granularity:4,
				startTime:'',
				endTime:'',
				itemIdList:[1182,1205,1150,1206],
				page:1,
				size:100,
		},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: []
		},
		topFormItem: {
			modal: true,
			number: 3,
            labelCol: 6,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					fieldNames:{label: 'name', value: 'id', children: 'children'},
					rules: TYPE_NAME.TREE_SELECT,
					disabled: this.props.location.state ? true : false,
					data:[],
					onChange: (k,v) => this.getAllDev(k,v)
				},
				{
					variableName: 'devIds',
					str: '设备选择',
					// isMultiple:true,
                    disabled: false,
					data:[],
					rules: TYPE_NAME.SELECTINPUT,
				},
				// {
				// 	variableName: 'granularity',
				// 	str: '时间类别',
				// 	rules: TYPE_NAME.SELECTINPUT,
				// 	data: [
				// 		{
				// 			key: '1',
				// 			value: '日'
				// 		},
				// 		{
				// 			key: '2',
				// 			value: '月'
				// 		},
				// 		{
				// 			key: '3',
				// 			value: '年'
				// 		}
				// 	]
				// },
				{
					variableName: 'createTime',
					str: '时间范围',
					rules: TYPE_NAME.RANGE_REQ_DATE_PICKER,
				},

			]
		},
		searchGetData:{},

		    	// 总条数
		totalCount: 0,
	}

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editQrCodeStatus(true,rowData.id,rowData.code)}>
						<u>{'修改'}</u>
				</div>
				</>;
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : '';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : '';
		}
	};
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange();
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	}
	componentDidMount(){
		let user = JSON.parse(sessionStorage.getItem("user"))
		// getDataIgnoreLevel({userId:user.id}).then(
		// 	response => {
		// 		const {data} = response.data
		// 		let selectArry=[]
		// 		if(data){
		// 			for(var i=0;i<data.length;i++){
		// 				selectArry.push({key:data[i].id,value:data[i].name})
		// 			}
		// 		}
		// 		this.state.topFormItem.parameter[0].data=selectArry;
		// 	}
		// )

		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
			response => {
				if (response?.data) {
					console.log(response?.data);
					this.state.topFormItem.parameter[0].data=response?.data;
				} else {
					return message.info('获取不到数据');
				}
			}
		)

		tempPreComparisonCurve(this.state.tableParamenter.params).then(
			response => {
				const {data} = response.data
				let tArry=[]
				let cArry=[]
				let mArry=[]
				let pArry=[]
				let x=[]
				let t=[]
				let c=[]
				let m=[]
				let p=[]
				if(data){
					x=data[0].xaxis
					t=data[0].tempList
					c=data[0].instFlowList
					m=data[0].densityList
					p=data[0].preList
					tArry = t.map((item) => {
						return Number(item);
					  });
					cArry = c.map((item) => {
						return Number(item);
					  });
					mArry = m.map((item) => {
						return Number(item);
					  });
					pArry = p.map((item) => {
						return Number(item);
					  });
					  $('#noMsg').hide();
					  $('#chartBox').show();
					  this.getPie('pie1',x,cArry,'瞬时流量');
					  this.getPie('pie2',x,tArry,'温度');
					  this.getPie('pie3',x,mArry,'密度');
					  this.getPie('pie4',x,pArry,'压力');
				}
			}
		)



	}

	getAllDev = (areaIds) => {
		var treeAreaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,areaIds,treeAreaIds);
		listDevByAreaIds({
			// areaIds:[areaIds]
			areaIds:treeAreaIds
		}).then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devId,value:data[i].devName})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)
	}
	forTreeArea = (data,key,areaIds=[]) =>{
		data.map(one=>{
			if(one.id===key){
				areaIds.push(one.id);
				if(one?.children){
					this.addAllTreeArea(one?.children,areaIds);
				}
			}else if(one?.children){
				this.forTreeArea(one?.children,key,areaIds);
			}
		});
		return areaIds;
	};
	addAllTreeArea = (data,areaIds=[]) =>{
		data.map(one=>{
			areaIds.push(one.id);
			if(one?.children){
				this.addAllTreeArea(one?.children,areaIds);
			}
		});
		return areaIds;
	};

	onChangeSelect = e => {
		console.log(111,e)
	}


		// 条件选择：获取输入的条件
		getConditionsValue = async value => {
			let data = await this.forms.onFinishNotReset();
			if(!data) return null
			let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
			let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
			let params = this.state.tableParamenter.params;
			Object.assign(params,value);
			params.areaIds=[];
			this.forTreeArea(this.state.topFormItem.parameter[0].data,data.areaIds,params.areaIds);
			// params.areaIds=[data.areaIds]
			params.devIds=[data.devIds]
			params.granularity=4
			if(sTime){
				params.startTime=sTime;
			}
			if(eTime){
				params.endTime=eTime;
			}
			let {tableParamenter} = this.state
			tableParamenter.params = params
			this.setState({tableParamenter})


			tempPreComparisonCurve(this.state.tableParamenter.params).then(
				response => {
					const {data} = response.data
					let tArry=[]
					let cArry=[]
					let mArry=[]
					let pArry=[]
					let x=[]
					let t=[]
					let c=[]
					let m=[]
					let p=[]
					if(data){
						x=data[0].xaxis
						t=data[0].tempList
						c=data[0].instFlowList
						m=data[0].densityList
						p=data[0].preList
						tArry = t.map((item) => {
							return Number(item);
						  });
						cArry = c.map((item) => {
							return Number(item);
						  });
						mArry = m.map((item) => {
							return Number(item);
						  });
						pArry = p.map((item) => {
							return Number(item);
						  });
						  $('#noMsg').hide();
						  $('#chartBox').show();
						  this.getPie('pie1',x,cArry,'瞬时流量');
						  this.getPie('pie2',x,tArry,'温度');
						  this.getPie('pie3',x,mArry,'密度');
						  this.getPie('pie4',x,pArry,'压力');

					}else{
						$('#noMsg').text('暂无数据');
					}

				}
			)

		};

	getPie =  (elmName,echartArryX,echartArryY,echartName) => {
		// 基于准备好的dom，初始化echarts实例
		myChart = echarts.init(document.getElementById(elmName));
		// 绘制图表
		myChart.setOption({
			tooltip: {
				trigger: 'axis',
			  },
			//   toolbox: {
			// 	feature: {
			// 	  dataView: { show: true, readOnly: false },
			// 	  magicType: { show: true, type: ['line', 'bar'] },
			// 	  restore: { show: true },
			// 	  saveAsImage: { show: true }
			// 	}
			//   },
			  legend: {
				data: [echartName]
			  },
			  xAxis: [
				{
				  type: 'category',
				  data: echartArryX,
				  axisPointer: {
					type: 'shadow'
				  }
				}
			  ],
			  grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: echartName,
				  type: 'line',
				//   tooltip: {
				// 	valueFormatter: function (value) {
				// 	  return value + ' 吨';
				// 	}
				//   },
				  data: echartArryY
				}
			  ]
		});
}



    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaId = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	this.setState({})
	this.table.refreshTable();
  }

	// 清空条件
	clearCondition = () => {
		let {params} = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params})
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff'}}>
					<div className={'topSearchBox'}>
					<h3>明细分析</h3>
						<div style={{position:'relative'}}>
							<div style={{width:'90%'}}>
							<FormsTwo
									ref={e => this.forms = e}
									formParamenter={this.state.topFormItem}
									data={this.state.searchGetData}
							/>
							</div>
							<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						</div>
					</div>
					<div id='noMsg' style={{fontSize:'30px',lineHeight:'100px',textAlign:'center',color:'#ccc'}}>请先选择区域，设备及时间范围</div>
					<div id='chartBox' style={{display:'none'}}>
						<div id="pie1" style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'48%',float:'left'}}></div>
						<div id="pie2" style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'48%',float:'left'}}></div>
						<div id="pie3" style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'48%',float:'left'}}></div>
						<div id="pie4" style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'48%',float:'left'}}></div>
					</div>

				</div>
            </Layout>
        )
    }
}
