import React, { Component } from 'react'
import {
	Layout,
	Modal,
	Radio,
	message,
	Tag,
	Button,
	Spin,
	Upload,
	Select,
	Form,
	Input,
	Popconfirm,
	DatePicker, notification
} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import { TYPE_NAME } from '../../../components/Const'
import { PlusSquareOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import $ from 'jquery'
import dayjs from 'dayjs';
import {funTraceInfo} from '../../../services/common'
import {ocr, removeCert, updateCert} from '../../../services/account'
import { listCertByCon, getCertDetail } from '../../../services/meterVerifvication'

export default class PointManagement extends Component {

	state = {
		displayKey:true,
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
			rowSelection: false,
			// checkStatus:true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				certNo: '证书编号',
				uploadTime: '上传时间',
				result: '检定结论',
				serialNo: '出厂编号',
				verifyReason: '检定依据',
				deviceName: '计量器具名称',
				verifyDate: '检定日期',
				expireDate: '有效期至',
				verifyType: '检定类型',
				paramK: '仪表系数',
				edit: '操作',
			},
			// 弹出框
			headers: {
				certNo: '证书编号',
				uploadTime: '上传时间',
				result: '检定结论',
				serialNo: '出厂编号',
				verifyReason: '检定依据',
				deviceName: '计量器具名称',
				verifyDate: '检定日期',
				expireDate: '有效期至',
				verifyType: '检定类型',
				paramK: '仪表系数',
			},
			params: {
				page: 1,
				size: 10,
			},
			// 是否绑定左侧区域
			bindArea: true,
		},

		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'sheetSteelGrade',
					str: '表钢号',
				},
				{
					type: 'select',
					variableName: 'booleanArrange',
					str: '是否有计划',
					data: [
						{
							key: '0',
							value: '是'
						},
						{
							key: '1',
							value: '否'
						},
					]
				},
				{
					type: 'rangePicker',
					variableName: 'createTime',
					time: false,
					str: '到期日期：'
				},
			]
		},
		topFormItem: {
			modal: true,
			number: 4,
			labelCol: 7,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'createTime',
					str: '上传时间',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'checkTime',
					str: '检定日期',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'expireTime',
					str: '有效期',
					rules: TYPE_NAME.RANGE_PICKER,
				},
				{
					variableName: 'checkType',
					str: '检定类型',
					rules: TYPE_NAME.SELECT,
					data: [
						{
							key: '',
							value: '全部'
						},
						{
							key: '周检',
							value: '周检'
						},
						{
							key: '首检',
							value: '首检'
						},
						{
							key: '维修',
							value: '维修'
						},
					]
				},
				{
					variableName: 'reason',
					str: '检定依据',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'serialNo',
					str: '出厂编号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'deviceName',
					str: '器具名称',
					rules: TYPE_NAME.INPUT,
				},

			]
		},

		addFormItem: {
			modal: true,
			number: 3,
			labelCol: 10,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'plannedVerTime',
					str: '设置计划检定日期',
					rules: TYPE_NAME.REQDATE_PICKER,
				},
			]
		},

		// 总条数
		totalCount: 0,
		loading: false,
		searchGetData: {},
		basicsData: {},
		errors:[],
		repeats:[],
		voInstallReqData: {},
		voInstallMeterData: {},
		devVisible: false, // 小窗口开关器-1
		devVisible_1:false,
		delVisible: false,//删除
		delId: '',



		//上传所需
		modalVisible_1: false,
		keyOptions: null,
		fileList: [],
		previewTitle: null,
		previewImage: null,
		previewOpen: false,
		// fileUrl: 'http://192.168.1.107:28089/qrcode/parsingQrCodes',
		// fileUrl:"/gateway/businesse/ocr/parsingQrCodes",
		fileUrl:"/gateway/businesse/ocr/parsingQrCode",
		uploadParam: {
			path: [],
			userId: null,
			userName: null
		}
	};

	setTableValue = (value, text, rowData) => {
		if (text === 'null') return '-'
		switch (value) {
			case 'verifyDate':
				return<><span>{text?moment(text).format("yyyy-MM-DD"):'-'}</span></>;
			case 'edit':
				return <>
					<div className={'tableLink'} title={'查看详情'} onClick={() => this.showDetailBox(true, rowData.id)}>
						<u>{'查看详情'}</u>
					</div>
					<div className={'tableLink'} title={'修改'}  onClick={() => this.showDetailBox_updata(true, rowData.id)}>
						<u>{'修改'}</u>
					</div>
					<div className={'tableLink'} title={'删除'} >
						<Popconfirm
							title="是否确定删除"
							description="是否确定删除?"
							onConfirm={()=>{this.removeCert(rowData.id)}}
							// onCancel={cancel}
							okText="是"
							cancelText="否"
						>
							<u>{'删除'}</u>
						</Popconfirm>

					</div>
				</>;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if (rowData.length < 1) {
			return message.error('未选择数据')
		} else {
			this.setState({ devVisible });
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	};
	componentDidMount() {
		console.log(this.state.params)
		$(document).on('click', '.smallImg', function () {
			var url = $(this).attr('src')
			$('.bigImg').attr('src', url)
			$('.bigImgBox').show()
		});
		$(document).on('click', '.bigImgBox', function () {
			$('.bigImgBox').hide()
		});
		let user = JSON.parse(sessionStorage.getItem("user"));
		let { uploadParam } = this.state
		uploadParam.userId = user.id
		uploadParam.userName = user.username
		this.setState({ uploadParam })
	}
	onChangeSelect = e => {
		console.log(111, e)
	}

	//详情
	showDetailBox = async (devVisible, id) => {
		this.setState({ devVisible })
		getCertDetail({ id: id }).then(
			response => {
				let data = response.data.data;
				if (data) {
					this.state.basicsData.certNo = data.certNo
					this.state.basicsData.deviceName = data.deviceName
					this.state.basicsData.serialNo = data.serialNo
					this.state.basicsData.manufacturer = data.manufacturer
					this.state.basicsData.verifyReason = data.verifyReason
					this.state.basicsData.result = data.result
					this.state.basicsData.company = data.company
					this.state.basicsData.verifyDate = data.verifyDate
					this.state.basicsData.sheetSteelGrade = data.sheetSteelGrade
					this.state.basicsData.address = data.address
					this.state.basicsData.start = data.start
					this.state.basicsData.expireDate = data.expireDate
					if (data.appearance) {
						this.state.basicsData.appearance = data.appearance
					} else {
						$('#appearanceBox').hide();
					}
					if (data.close) {
						this.state.basicsData.close = data.close
					} else {
						$('#closeBox').hide();
					}
					$('#componentList').html('');
					$('#errorsList').html('');
					$('#repeatsList').html('');
					if (data.errors.length > 0) {
						for (var i = 0; i < data.errors.length; i++) {
							$('#errorsList').append('<tr><td>' + data.errors[i].standard + '</td><td>' + data.errors[i].value + '</td></tr>')
						}
					}
					if (data.repeats.length > 0) {
						for (var i = 0; i < data.errors.length; i++) {
							$('#repeatsList').append('<tr><td>' + data.repeats[i].standard + '</td><td>' + data.repeats[i].value + '</td></tr>')
						}
					}
					if (data.certPath) {
						var imgUrl = ''
						let urlArry = data.certPath.split(',')
						for (var i = 0; i < urlArry.length; i++) {
							imgUrl += "<img class='smallImg' alt='暂未上传' src=" + urlArry[i] + " />"
						}
						$('#componentList').append("<div class='installInnerSmall'>" +
							"<div class='installInnerImgBox'>" + imgUrl + "</div>" +
							"</div>")
					} else {
						$('#componentList').append("<div class='installInnerSmall'><p style='text-align:center; font-size:18px; color:#ccc;'>暂未上传照片</p></div>")
					}
				}
			})

	}

	//详情
	showDetailBox_updata = async (devVisible_1, id) => {

		getCertDetail({ id: id }).then(
			response => {
				let data = response.data.data;
				// const teaday = moment().subtract('1','day').format("YYYY-MM-DD");
				// console.log(teaday)
				if (data) {
					this.state.basicsData.id = data.id;
					this.state.basicsData.certNo = data.certNo;				// 证书编号
					this.state.basicsData.deviceName = data.deviceName;		// 计量器具名称
					this.state.basicsData.serialNo = data.serialNo;			// 出厂编号
					this.state.basicsData.manufacturer = data.manufacturer;	//制造单位
					this.state.basicsData.verifyReason = data.verifyReason;	// 检定依据
					this.state.basicsData.result = data.result;				// 检定结论
					this.state.basicsData.company = data.company;			// 委托单位
					this.state.basicsData.verifyDate = data.verifyDate;		// 检定日期
					this.state.basicsData.sheetSteelGrade = data.sheetSteelGrade;// 表钢号
					this.state.basicsData.address = data.address;			// 地址
					this.state.basicsData.start = data.start;				// 类型
					this.state.basicsData.expireDate = data.expireDate; 	//有效期
					this.state.errors=data.errors;							// 误差值
					this.state.repeats=data.repeats;						// 重复性
					if (data.appearance) {
						this.state.basicsData.appearance = data.appearance
					} else {
						$('#appearanceBox_updata').hide();
					}
					if (data.close) {
						this.state.basicsData.close = data.close
					} else {
						$('#closeBox').hide();
					}
					$('#componentList_updata').html('');
					$('#errorsList_updata').html('');
					$('#repeatsList_updata').html('');
					if (data.errors.length > 0) {
						for (var i = 0; i < this.state.errors.length; i++) {
							$('#errorsList_updata').append('' +
								'<input ' +
								'id="errors1'+i+'" ' +
								'value="' +this.state.errors[i].standard+'" ' +
								'style="width: 100px;"/>'+
								'<input ' +
								'id="errors2'+i+'" '+
								'value="' +this.state.errors[i].value+'" ' +
								'style="width: 100px;"/>'

						)
						}
					}
					if (data.repeats.length > 0) {
						for (var i = 0; i < this.state.errors.length; i++) {
							$('#repeatsList_updata').append('' +
								'<input ' +
								'id="repeats1'+i+'" ' +
								'value="' +this.state.repeats[i].standard+'" ' +
								'style="width: 100px;"/>'+
								'<input ' +
								'id="repeats2'+i+'" ' +
								'value="' +this.state.repeats[i].value+'" style="width: 100px;"/>'

						)
						}
					}
					if (data.certPath) {
						var imgUrl = ''
						let urlArry = data.certPath.split(',')
						for (var i = 0; i < urlArry.length; i++) {
							imgUrl += "<img class='smallImg' alt='暂未上传' src=" + urlArry[i] + " />"
						}
						$('#componentList_updata').append("<div class='installInnerSmall'>" +
							"<div class='installInnerImgBox'>" + imgUrl + "</div>" +
							"</div>")
					} else {
						$('#componentList_updata').append("<div class='installInnerSmall'><p style='text-align:center; font-size:18px; color:#ccc;'>暂未上传照片</p></div>")
					}
					this.setState({ devVisible_1 });
				}
			}).catch(e=>{
			this.setState({ devVisible_1 });
		})


	};
	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		let sTime = data.createTime ? moment(data.createTime[0]._d) : null;
		let eTime = data.createTime ? moment(data.createTime[1]._d) : null;
		let ssTime = data.checkTime ? moment(data.checkTime[0]._d) : null;
		let eeTime = data.checkTime ? moment(data.checkTime[1]._d) : null;
		let sssTime = data.expireTime ? moment(data.expireTime[0]._d) : null;
		let eeeTime = data.expireTime ? moment(data.expireTime[1]._d) : null;
		let params = this.state.tableParamenter.params
		Object.assign(params, data)
		params.createTime = ''
		params.checkTime = ''
		params.expireTime = ''
		params.uploadStartDate = sTime;
		params.uploadEndDate = eTime;
		params.verifyStartDate = ssTime;
		params.verifyEndDate = eeTime;
		params.expireStartDate = sssTime;
		params.expireEndDate = eeeTime;
		let { tableParamenter } = this.state
		tableParamenter.params = params
		this.setState({ tableParamenter })
		await this.table.refreshTable(params);

	};

	// 条件选择：获取输入的条件
	getConditionsValue2 = async value => {
		console.log(value)
		// let sTime=createTime ? moment(createTime[0]._d).format("yyyy-MM-DD") : null;
		// let eTime=createTime ? moment(createTime[1]._d).format("yyyy-MM-DD") : null;
		let sTime = value.createTime ? moment(value.createTime[0]._d) : null;
		let eTime = value.createTime ? moment(value.createTime[1]._d) : null;
		let params = this.state.tableModParamenter.params;
		let formParams = this.table.getParams()
		console.log(this.state)
		Object.assign(params, value)
		params.page = formParams.page
		params.size = formParams.size
		params.createTime = ''
		params.validVerTimeStart = sTime;
		params.validVerTimeEnd = eTime;
		let { tableModParamenter } = this.state;
		tableModParamenter.params = params;
		this.setState({ tableModParamenter });
		await this.table2.refreshTable(params);

	};


	getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
			let { params, initParams } = this.state
			params.areaIds = data
			params.page = 1
			params.size = initParams.size
			this.setState({ params })
			this.getData()
		})
	}
	// 初始化表格数据
	getData = () => {
		this.setState({})
		this.table.refreshTable();
	}




	// 清空条件
	clearCondition = () => {
		let { params } = this.state
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

		this.setState({ params: params })
		this.getData();
	}


	/********************************上传代码********************************** */

	handleCancel = () => this.setState({ previewOpen: false });

	// 弹窗设置
	handlePreview = (file) => {

		this.setState({
			previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
			previewOpen: true,// 弹窗开关
		});
	};


	handleChange = file => {

		if (file.file.status !== 'uploading') {
			// console.log(file.file, file.fileList);
		}
		if (file.file.status === 'done') {
			message.success(`${file.file.name} 上传成功`);
		} else if (file.file.status === 'error') {
			message.error(`${file.file.name} 上传异常.`);
		}
		let { uploadParam } = this.state;

		if(file.fileList[file.fileList.length - 1]?.response?.data){
			uploadParam.path.push(file.fileList[file.fileList.length - 1]?.response?.data);
		}
		this.setState({ fileList: file.fileList, uploadParam });
		console.log(this.state.fileList)
	};

	// 弹窗的表单获取方法
	onFinish = (value) => {
		let key = this.state.uploadParam.path;
		 let analysis=value.select
		if (key == null || key.length == 0) return message.warning('未上传检定图片', [1])
		this.setState({displayKey:false});
		var params={
			path: [],
			userId: null,
			userName: null
		}
		this.state.uploadParam.path.map(one=>{
			params.path=one;
			ocr(params).then(
				response => {
					if (response.data.data === null) {
						return message.error('证书解析错误', [0.5])
					} else {
						let value = response.data.data;
						value.verifyType = analysis;
						value.userId = this.state.uploadParam.userId;
						value.userName = this.state.uploadParam.userName;
						updateCert(value).then(
							response => {
								response.data.data ? message.success('证书解析成功', [0.5]) : message.error('证书解析失败', [0.5])
								this.setState({ modalVisible_1: false, fileList: []})
								this.form.resetFields();
								funTraceInfo("表具检定管理", "证书解析", null,value)
								this.table.refreshTable(this.state.tableParamenter.params);
							}
						);

					}
					var uploadParam = this.state.uploadParam;
					uploadParam.path=[];
					this.setState({displayKey:true,uploadParam:uploadParam});
					this.setState({displayKey:true});
				}
			);
			this.getData();
			this.setState({ modalVisible_1: false, fileList: [] });
			this.form.resetFields();
		})

	};

	uploadButton = (
		<div>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8,
				}}
			>
				上传证书
			</div>
		</div>
	);

	// 修改证书
	updataVer=()=>{
		console.log('修改证书');
		this.setState({devVisible_1:false});
		var param={
			"id": this.state.basicsData?.id,				// 主键ID

			"certNo": this.state.basicsData?.certNo,			// 证书编号
			"deviceName": this.state.basicsData?.deviceName,		// 设备名称
			"serialNo": this.state.basicsData?.serialNo,			// 序列号
			"manufacturer": this.state.basicsData?.manufacturer,		// 生产厂家


			"verifyReason": this.state.basicsData?.verifyReason,		// 检定依据
			"result": this.state.basicsData?.result,		 	// 检定结果
			"company": this.state.basicsData?.company,			// 委托单位
			"verifyDate": this.state.basicsData?.verifyDate,		// 检定日期

			"appearance": this.state.basicsData?.appearance,		// 外观
			"close": this.state.basicsData?.close,			// 密封性

			// 示值误差
			"errors": [],

			// 重复性
			"repeats": [],

		};
		console.log('1');
		// 误差值
		for(var i=0;i<this.state.errors.length;i++){
			const errors={
				standard:null,
				value:null
			};
			var standard = document.getElementById('errors1'+i) || null;
			if(standard){
				errors.standard=standard.value
			}
			var value = document.getElementById('errors2'+i) || null;
			if(standard){
				errors.value=value.value
			}
			param.errors.push(errors)
		}
		console.log('2');
		// 重复性
		for(var a=0;a<this.state.repeats.length;a++){
			const repeats={
				standard:null,
				value:null
			};
			var repeatsStandard = document.getElementById('repeats1'+a) || null;
			if(repeatsStandard){
				repeats.standard=repeatsStandard.value
			}
			var repeatsData = document.getElementById('repeats2'+a) || null;
			if(repeatsData){
				repeats.value=repeatsData.value
			}
			param.repeats.push(repeats)
		}
		console.log(param);
		updateCert(param).then(response => {
			console.log(response);
			if(response?.data?.data){
				message.info("修改成功");
			}else{
				message.info("修改失败");
			}
			this.table.refreshTable(this.state.tableParamenter.params);
		}).catch(e=>{
			return message.info("访问失败，修改异常");
		})
	};

	// 删除证书
	removeCert= id =>{
		removeCert({id:id}).then(response=>{
			if(response?.data?.data){
				message.info("删除成功");

			}else{
				message.info("删除失败");
			}
			this.table.refreshTable(this.state.tableParamenter.params);
		}).catch(e=>{
			return message.info("访问失败，删除异常");
		})
	};

	// 导出
	exportData=async ()=>{
		console.log('导出');

		// let {exportUrl,title,fileName,manageName} = this.props.paramenter;
		var exportUrl='/gateway/web/exportStatisticalReportCtrl/cerReportExport';
		let data = await this.forms.onFinishNotReset();
		console.log(data);
		let link = `${exportUrl}?ajaxFileName=${('检定数据')+moment().format('lll')}.xlsx`;


		// 上传时间
		if(data?.createTime){
			let sTime = data.createTime ? moment(data.createTime[0]._d) : null;
			let eTime = data.createTime ? moment(data.createTime[1]._d) : null;
			link += `&uploadStartDate=${sTime}`;
			link += `&uploadEndDate=${eTime}`;
		}
		// 检定日期
		if(data?.checkTime){
			let ssTime = data.checkTime ? moment(data.checkTime[0]._d) : null;
			let eeTime = data.checkTime ? moment(data.checkTime[1]._d) : null;
			link += `&verifyStartDate=${ssTime}`;
			link += `&verifyEndDate=${eeTime}`;
		}
		// 有效期
		if(data?.expireTime){
			let sssTime = data.expireTime ? moment(data.expireTime[0]._d) : null;
			let eeeTime = data.expireTime ? moment(data.expireTime[1]._d) : null;
			link += `&expireStartDate=${sssTime}`;
			link += `&expireEndDate=${eeeTime}`;
		}
		// 检定类型
		if(data?.checkType){
			link += `&checkType=${data?.checkType}`;
		}
		// 检验依据
		if(data?.reason){
			link += `&reason=${data?.reason}`;
		}
		// 出厂编号
		if(data?.serialNo){
			link += `&serialNo=${data?.serialNo}`;
		}
		// 器具名称
		if(data?.deviceName){
			link += `&deviceName=${data?.deviceName}`;
		}
		link += `&page=1`;
		link += `&size=-1`;
		link += `&orderType=2`;

		console.log(link);

		try{

			notification['warning']({
				message: '文件导出中',
				description: '文件未生成时，不允许多次点击按钮。'
			});
			window.open(link)

		} catch (error) {

		}




	};


	render() {



		const { uploadLoading, visible,basicsData} = this.state;
		const { previewOpen, previewImage } = this.state;
		return (
			<Layout className={'item_body_number_back tables'}>
				<div style={{height:'100%',width:'100%',background:'#000',opacity:'0.7',
					display:this.state.displayKey?'none':null,
					paddingTop:'20%',
					textAlign: 'center',
					color:'#fff',
					position:'absolute', top:'0px', left:'0', zIndex:'2'}}>
					<span>请稍等</span><Spin  size="large"/>
				</div>
				<div className={'topSearchBox'}>
					<h3>检定首页</h3>
					<div style={{ position: 'relative' }}>
						<div style={{ width: '88%' }}>
							<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.topFormItem}
								data={this.state.searchGetData}
							/>
						</div>
						<Button style={{ position: 'absolute', right: '0', top: '0' }}
								type="primary"
								onClick={() => this.getConditionsValue()}>查询
						</Button>
					</div>
				</div>
				<div style={{ position: 'absolute', right: '40px', top: '120px' }}>
					<Button
						// size='small'
						type="primary"
						style={{ borderRadius: ' 0 5px 5px 0 ', float: 'left' }}
						onClick={() => this.setState({ modalVisible_1: true ,fileList:[]})}
					>上传</Button>

					<Modal
						title={'上传证书'}
						style={{ top: 0 }}
						visible={this.state.modalVisible_1}
						okText="确定"
						onOk={() => this.setState({ modalVisible_1: false, previewOpen: false })}            // 点击确定
						onCancel={() => this.setState({ modalVisible_1: false, previewOpen: false })}       // 点击取消
						width={580}
						centered
						footer={[
							<Button type="primary" form="form" htmlType="submit">
								提交
							</Button>
						]}
					>
						<div>
							<Form
								id="form"
								name="dynamic_form_nest_item"
								onFinish={(value) => { this.onFinish(value) }}
								ref={e => this.form = e}
								style={{ maxWidth: 700 }}
								autoComplete="off"
								// form={this.form}
							>

								<div>
									<Form.Item
										name={['upload']}
										// label={'上传'}
									>
										<Upload
											action={this.state.fileUrl}
											listType="picture-card"
											name={"file"}
											fileList={this.state.fileList}
											onPreview={this.handlePreview}
											onChange={this.handleChange}
										>
											{this.state.fileList.length >= 5 ? null : this.uploadButton}
										</Upload>
									</Form.Item>
								</div>
								<div>
									<Form.Item
										name={['select']}
										label={'检定类型'}
										rules={[{ required: true, message: '不能为空' }]}
									>
										<Select
											size="small"
											placeholder="请选择检定类型"
											style={{ width: '100px' }}
											// onChange={(a) => {
											// 	this.setState({ keyOptions: a })
											// 	// console.log(key);
											// 	// console.log(name);
											// }}
											allowClear
											// onClear={(a) => {
											// 	this.setState({ keyOptions: null })
											// 	// console.log(key);
											// 	// console.log(name);
											// }}
											// value={this.state.keyOptions}

											options={[
												{ value: '周检', label: '周检' },
												{ value: '首检', label: '首检' },
												{ value: '维修', label: '维修' }]}
										/>
									</Form.Item>
								</div>
								<Modal visible={previewOpen} footer={null} onCancel={this.handleCancel}>
									<img alt="example" style={{ width: '100%' }} src={previewImage} />
								</Modal>
							</Form>
						</div>
					</Modal>
				</div>
				<div style={{ position: 'absolute', right: '40px', top: '155px' }}>
					<Button
						// size='small'
						type="primary"
						style={{ borderRadius: ' 0 5px 5px 0 ', float: 'left' }}
						onClick={() => this.exportData()}
					>
						导出
					</Button>
				</div>
				<div style={{ padding: '10px 0 0 0', background: '#fff' }}>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData={listCertByCon}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
					</TableComponents>
				</div>
				<Modal
					title={'检定详情'}
					style={{ top: 0 }}
					visible={this.state.devVisible}
					okText="确定"
					forceRender={true}
					// footer={false}
					onOk={() => this.showDetailBox(false)}
					onCancel={() => this.showDetailBox(false)}
					width={1360}
					centered
				>
					<div className={'installBox'}>
						<div className={'installList'}>
							<h4>基本信息</h4>
							<div className={'installInner'}>
								<ul>
									<li>证书编号：<span>{this.state.basicsData.certNo}</span></li>
									<li>计量器具名称：<span>{this.state.basicsData.deviceName}</span></li>
									<li>出厂编号：<span>{this.state.basicsData.serialNo}</span></li>
									<li>制造单位：<span>{this.state.basicsData.manufacturer}</span></li>
									<li>检定依据：<span>{this.state.basicsData.verifyReason}</span></li>
									<li>检定结论：<span>{this.state.basicsData.result}</span></li>
									<li>委托单位：<span>{this.state.basicsData.company}</span></li>
									<li>检定日期：<span>{this.state.basicsData.verifyDate}</span></li>
									<li>有效期至：<span>{this.state.basicsData.expireDate}</span></li>
								</ul>
							</div>
						</div>
						<div className={'installList'}>
							<h4>检定结果</h4>
							<div className={'installInner'}>
								<ul>
									<li id='appearanceBox'>外观：<span>{this.state.basicsData.appearance}</span></li>
									<li id='closeBox'>密封性：<span>{this.state.basicsData.close}</span></li>
									<li>示值误差：
										<table>
											<tbody id='errorsList'>
											</tbody>
										</table>
									</li>
									<li>重复性：
										<table>
											<tbody id='repeatsList'>
											</tbody>
										</table>
									</li>
								</ul>
							</div>
						</div>
						<div className={'installList'}>
							<h4>证书文件</h4>
							<div id='componentList'>

							</div>
						</div>
					</div>
				</Modal>
				{/*修改------------------------------------*/}
				<Modal
					title={'检定详情'}
					style={{ top: 0 }}
					visible={this.state.devVisible_1}
					okText="确定"
					forceRender={true}
					// footer={false}
					onOk={() => this.updataVer()}
					onCancel={() => this.setState({devVisible_1:false})}
					width={1360}
					centered
				>
					<div className={'installBox'}>
						<div className={'installList'}>
							<h4>基本信息</h4>
							<div className={'installInner'}>
								<ul>
									<li>证书编号：
										{/*<Input*/}
										{/*	size="small"*/}
										{/*	style={{width:'200px',}}*/}
										{/*	value={this.state.basicsData.certNo}*/}
										{/*	onChange={(title)=>{*/}
										{/*		basicsData.certNo=title.target.value;*/}
										{/*		console.log(title.target.value)*/}
										{/*	}}*/}
										{/*/>*/}
										<span>{this.state.basicsData.certNo}</span>
									</li>
									<li>计量器具名称：
										<Input
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.deviceName}
											onChange={(title)=>{
												basicsData.deviceName=title.target.value;
												console.log(title.target.value)
											}}
										/>
										</li>
									<li>出厂编号：
										<Input
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.serialNo}
											onChange={(title)=>{
												basicsData.serialNo=title.target.value;
												console.log(title.target.value)
											}}
										/>
									</li>
									<li>制造单位：
										<Input
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.manufacturer}
											onChange={(title)=>{
												basicsData.manufacturer=title.target.value;
												console.log(title.target.value)
											}}
										/>
									</li>
									<li>检定依据：
										<Input
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.verifyReason}
											onChange={(title)=>{
												basicsData.verifyReason=title.target.value;
												console.log(title.target.value)
											}}
										/>
									</li>
									<li>检定结论：
										<Input
											size="small"
											style={{width:'200px',
												marginLeft: '28px'}}
											value={this.state.basicsData.result}
											onChange={(title)=>{
												basicsData.result=title.target.value;
												console.log(title.target.value)
											}}
										/>
									</li>
									<li>委托单位：
										<Input
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.company}
											onChange={(title)=>{
												basicsData.company=title.target.value;
												console.log(title.target.value)
											}}
										/>
									</li>
									<li>检定日期：

										<DatePicker
											style={{width:'200px',}}
											size="small"
											value={moment(this.state.basicsData.verifyDate, "YYYY-MM-DD")}
											format={"YYYY-MM-DD"}
											onChange={(title)=>{
												console.log(this.state.basicsData.verifyDate);
												basicsData.verifyDate=moment(title).format("YYYY-MM-DD");
											}}
										/>

									</li>
									<li>有效期至：
										<DatePicker
											style={{width:'200px',}}
											size="small"
											value={moment(this.state.basicsData.expireDate, "YYYY-MM-DD")}
											format={"YYYY-MM-DD"}
											onChange={(title)=>{
												console.log(this.state.basicsData.expireDate);
												basicsData.expireDate=moment(title).format("YYYY-MM-DD");
											}}
										/>

									</li>
								</ul>
							</div>
						</div>
						<div className={'installList'}>
							<h4>检定结果</h4>
							<div className={'installInner'}>
								<ul>
									<li id='appearanceBox'>外观：
										<Select
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.appearance}
											onChange={(title)=>{
												basicsData.appearance=title;
											}}
										>
											<option value={'符合'}>符合</option>
											<option value={'不符合'}>不符合</option>
										</Select>
										{/*<Input*/}
										{/*	size="small"*/}
										{/*	style={{width:'200px',}}*/}
										{/*	value={this.state.basicsData.appearance}*/}
										{/*	onChange={(title)=>{*/}
										{/*		basicsData.appearance=title.target.value;*/}
										{/*		console.log(title.target.value)*/}
										{/*	}}*/}
										{/*/>*/}
									</li>
									<li id='closeBox'>密封性：
										<Select
											size="small"
											style={{width:'200px',}}
											value={this.state.basicsData.close}
											onChange={(title)=>{
												basicsData.close=title;
											}}
										>
											<option value={'符合'}>符合</option>
											<option value={'不符合'}>不符合</option>
										</Select>
										{/*<Input*/}
										{/*	size="small"*/}
										{/*	style={{width:'200px',}}*/}
										{/*	value={this.state.basicsData.close}*/}
										{/*	onChange={(title)=>{*/}
										{/*		basicsData.close=title.target.value;*/}
										{/*		console.log(title.target.value)*/}
										{/*	}}*/}
										{/*/>*/}
									</li>
									<li>示值误差：
										<table>
											<tbody id='errorsList_updata'>
											</tbody>
										</table>
									</li>
									<li>重复性：
										<table>
											<tbody id='repeatsList_updata'>
											</tbody>
										</table>
									</li>
								</ul>
							</div>
						</div>
						<div className={'installList'}>
							<h4>证书文件</h4>
							<div id='componentList_updata'>

							</div>
						</div>
					</div>
				</Modal>
				<div className={'bigImgBox'}>
					<div className={'bigImgBoxBg'}></div>
					<img className={'bigImg'}></img>
				</div>

			</Layout>
		)
	}
}
