import React, { Component } from 'react'
import { Layout, Input, Form, Tag } from 'antd'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponents'
import {getInfoManagement,getAllManufacturerAndModel} from '../../../services/report'

const FormItem = Form.Item;

class InfoManagement extends Component {
    state = {
		// 表格参数
		tableParamenter: {
			manageName: "报表查询",
			// 下拉框长度
			selectWidth: '7.5vw',
			// 是否绑定左侧区域
			bindArea: true,
			position: 'top',
            rowSelection: false,
			width: '83.7vw',
            title: '用户信息管理报表',
			exportUrl: "/web/reportExportCtrl/exportInfoManagement",
			// 表头：变量和变量解释
			header: {
                company: '所属公司',
				readUnit: '读表单元',
				userId: '用户编号',
                installNumber: '安装',
				contractNumber: '合同',
                userName: '用户姓名',
                userType: '用户类型',
				address: '用户地址',
				phone: '联系电话',
				steelNum: '表钢号',
				modelName: '表型号',
				addTime: '添加时间', // 客户创建时间
                updateTime: '修改时间',
				lastTimeCopy: '上次抄表时间', // 标况累积量数据时间
                readReason: '读表原因',
                meterState: '表具状态',
                properties: '用气性质',
                gasEquipment: '用气设备'
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: 'select',
						variableName: 'meterManufacturer',
						str: '表具厂商',
						data: []
					},
					{
						type: 'select',
						variableName: 'userType',
						str: '用户类型',
						data: [
							{
								key: '1',
								value: '居民客户'
							},
							{
								key: '2',
								value: '工商客户'
							}
						]
					},
					// {
					// 	type: 'input',
					// 	variableName: 'readUnit',
					// 	str: '读表单元'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'userId',
					// 	str: '用户编号'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'userName',
					// 	str: '用户姓名'
					// },
					// {
					// 	type: 'input',
					// 	variableName: 'address',
					// 	str: '用户地址'
					// },
					// {
					// 	type: 'rangePicker',
					// 	variableName: 'addTime',
					// 	str: '添加时间'
					// },
					{
						type: 'input',
						variableName: 'steelNum',
						str: '表钢号'
					},
					{
						type: 'input',
						variableName: 'properties',
						str: '用气性质'
					},
					// {
					// 	type: 'input',
					// 	variableName: 'gasEquipment',
					// 	str: '用气设备'
					// }
				]
			},
		},
	}
	componentDidMount(){
		this.setValue()
	}

	setValue = () => {
		getAllManufacturerAndModel({hasModel: false}).then(
			response => {
				let {tableParamenter} = this.state;
				let {data} = response.data;
				tableParamenter.conditionsParamenter.parameter[0].data = data?.map(({manufacturer}) => {
					return {
						key: manufacturer,
						value: manufacturer
					}
				});
				this.setState({tableParamenter})
			}
		)
	}
	// 条件选择：获取输入的条件
	getConditionsValue = ({addTime}) => {
		return {
			startTime: addTime ? moment(addTime[0]?._d).format("YYYY-MM-DD HH:mm:ss") : null,
			endTime: addTime ? moment(addTime[1]?._d).format("YYYY-MM-DD HH:mm:ss") : null
		}
	}

	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'userType':
				return text*1 === 1 ? <Tag color={'geekblue'}>居民用户</Tag> : text*1 === 2 ? <Tag color={'green'}>工商用户</Tag> : ''
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

    render() {

    	var areaName = localStorage.getItem('areaName')??null;
    	if(areaName==='undefined'){areaName=null}

        return (
            <Layout className={'item_body_number_back reportX'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
					paramenter={this.state.tableParamenter}
					setData={getInfoManagement}
					setTableValue={this.setTableValue}
					getConditionsValue={this.getConditionsValue}
					ref={e => this.table = e}
				>
					<FormItem label={"公司"}>
						<Input disabled value={areaName} size='middle'/>
					</FormItem>
				</TableComponents>
            </Layout>
        )
    }
}
export default InfoManagement
