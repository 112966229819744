import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Popconfirm, Table} from 'antd'
import FormsText from "../../../components/common/forms/FormsText";
import { ArrowLeftOutlined ,RedoOutlined} from '@ant-design/icons';
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getLocationById} from "../../../services/customer";
import {querySelfCheckInfoDetail} from "../../../services/meter";

// 工商户安全自检管理-记录详情
export default class SelfInspectionDetails extends Component {

    state = {
        // 表单参数
        formText: {
            header: {
                customerId: '客户编号',
                securityCheckStatus:'安检状态',
                state:'自检状态',
                principalPhone: '联系电话',

                customerName: '客户名称',
                address: '客户地址',
                securityCheckedTime:'最近安检时间',
                securityCheckRequiredTime:'最近应安检日期',

                principal:'联系人',
                // createDate:'创建时间'
            }
        },

        // 表格内容
        tableData_1_data:this.props.location?.state?.tableData?.pointList ||[],
        // 表格标题
        tableData_1: [
            {title: '安检设备编号', dataIndex: 'devNo', key: 'devNo',},
            {title: '安检点位置', dataIndex: 'address', key: 'address'},
            {title: '安检点编号', dataIndex: 'pointNum', key: 'pointNum'},
            {title: '安检点类型', dataIndex: 'pointType', key: 'pointType',},
            {title: '阀门状态', dataIndex: 'valveStatus', key: 'valveStatus',},
            // {title: '安检点状态', dataIndex: 'result', key: 'result',},
            {title: '可燃气体浓度', dataIndex: 'concentration', key: 'concentration',},
            {title: '最近自检日期', dataIndex: 'time', key: 'time',},
        ],


        tableParamenter: {
            manageName: "记录明细",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // exportUrl: "/web/customerExportCtrl/exportCustomer",// 导入
            // 表头：变量和变量解释
            header: {
                devNo: '安检设备编号',
                address:'安检点位置',
                pointNum:'安检点编号',
                pointType: '安检点类型',
                valveStatus: '阀门状态',
                // pointType: '安检点状态',
                concentration:'可燃气体浓度',
                securityCheckRequiredTime:'最近自检日期',
            },
            params: {
                devId:this.props.location?.state?.tableData?.devId,
                time:this.props.location?.state?.tableData?.time,
                page:1,
                size:10,
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        selectRowData:null,
        switchType:0,// 类型切换，0正常页面，1明细界面
    };

    componentDidMount(): void {
        this.querySelfCheckInfoDetail();
    }

    querySelfCheckInfoDetail=()=>{
        var param = new URLSearchParams();
        param.append('devId',this.props.location?.state?.tableData?.devId);
        param.append('time',this.props.location?.state?.tableData?.time);
        querySelfCheckInfoDetail(param).then(
            response=>{
                var tableData = [...this.state.tableData_1_data];
                if(response?.data?.data){
                    tableData=response.data.data;
                    this.setState({tableData_1_data:tableData})
                }

            }
        )
    };



    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return <>
                    <NavLink
                        to={{pathname:"../customer/securityDetails",
                            state:{
                                formsTwo:this.props.location.state,
                                rowData:rowData
                            }
                        }}
                    >
                        详情
                    </NavLink>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    render() {

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                {/*返回上一页*/}
                <div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}>
                        <ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回工商户安全自检</span>
                </div>
                {/*客户信息*/}
                <div style={{width:'100%',height:'150px',}}>
                    <span style={{fontSize:'16px',fontWeight:'bold',paddingLeft: '1vw'}}>客户信息</span>
                    <div className={'insert_table'} style={{paddingLeft: '3vw'}}>
                        <FormsText
                            record={this.props.location.state.formsTwo}
                            header={this.state.formText.header}
                            rowLen={4}
                            span={6}
                        />
                    </div>
                </div>
                {/*分割线*/}
                <div className={'divider'}/>
                <div className={'topBtnBox'}>
                    <span className={'addLink'} style={{float: 'right',right:'0px'}} onClick={async()=>{this.querySelfCheckInfoDetail();}} ><RedoOutlined />刷新</span>
                </div>
                <div style={{margin:'5px',overflowY: 'auto'}}>
                    <Table
                        columns={this.state.tableData_1}
                        dataSource={this.state.tableData_1_data}
                        rowKey={"id"}
                        bordered
                        pagination={false}
                    />
                    {/*<TableComponents*/}
                    {/*    paramenter={this.state.tableParamenter}*/}
                    {/*    setData = {querySelfCheckInfoDetail}*/}
                    {/*    // setTableValue={this.setTableValue}*/}
                    {/*    ref={e => this.table = e}*/}
                    {/*>*/}
                    {/*</TableComponents>*/}
                </div>
            </Layout>
        )
    }
}
