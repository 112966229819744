import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button,Switch,embed,Tabs} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
import $ from 'jquery'
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {listDeviceRealTimeData, pageRealTimeValve, pageValveInfo} from '../../../services/steamReport'


// 设备实时数据监控
echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;
var downIp =global.pubData.url;
const { TabPane } = Tabs;


export default class deviceRealTimeData extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			selectHead:false,
			overflow:true,
			//下拉框长度
			selectWidth: '200px',
			// 表头：变量和变量解释
			header: {
				parentModel: '控制器型号',
				parentNo: '控制器编号',
				routeno: '探测器路号',
				serialno: '探测器编号',
				installPosition: '安装位置',
				// comStatus: '通信状态',
				// elcStatus: '市电状态',
				currentData: '可燃气体浓度',
				status: '报警状态',
				dataTime: '数据时间',
				comTime: '通信时间',
				devModelName: '探测器型号',
				parentComNo: '控制器通信号',
				location: '安装点地址',
				edit:'操作'
			},
			params: {
				page:1,
				size:10,
				userId:JSON.parse(sessionStorage.getItem("user")).id,
				// jumper:true
			},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 表格参数-阀井
		tableParamenter_1: {
			manageName: "数据项管理",
			// 表格行是否可选
			rowSelection: false,
			selectHead:false,
			overflow:true,
			//下拉框长度
			selectWidth: '200px',
			// 表头：变量和变量解释
			header: {
				uniqueEncoding:'唯一编码',

				data_1: '温度',
				data_3: '浓度',
				data_4: '压力值',

				data_16: '异动',
				data_17: '经度',
				data_18: '维度',

				data_5: '液位',
				data_6: '终端故障检测',
				data_7: '电池欠压',
				data_8: '水位检测',
				data_9: '温度检测',
				data_10: '可燃气体泄漏低限检测',
				data_11: '可燃气体泄漏高限检测',
				data_12: '压力低低限监测',
				data_13: '压力低限监测',
				data_14: '压力高限监测',
				data_15: '压力高高限监测',



				systemTime: '通信时间',
				devModelName: '设备型号',
				devName:'设备名',
				manufacture:'生产厂家',
				state:'状态',
				installPosition: '安装位置',
				address: '安装点地址',
			},
			params: {
				page:1,
				size:10,
				// userId:JSON.parse(sessionStorage.getItem("user")).id,
				// jumper:true,
				customerIds:[],
				devType:'10200'
			},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 表格参数-管钉
		tableParamenter_2: {
			manageName: "数据项管理",
			// 表格行是否可选
			rowSelection: false,
			selectHead:false,
			overflow:true,
			//下拉框长度
			selectWidth: '200px',
			// 表头：变量和变量解释
			header: {
				uniqueEncoding:'唯一编码',
				data_1:'甲烷浓度',//1
				data_2: '温度',		//1
				data_3:'环境压力',	//1
				data_4:'浸水情况',	//1
				data_5: '异动情况',	//1
				data_6:'经度',		//1
				data_7: '纬度',		//1
				data_8: '传感器故障状态',//1
				data_9: '电压',			//1
				data_10: '信号强度',		//1
				data_11: '报警状态',		// 1
				// data_12: '历史上报状态',
				data_13: 'IMEI',
				data_14: '数据时间',


				systemTime: '通信时间',
				devModelName: '设备型号',
				devName:'设备名',
				manufacture:'生产厂家',
				state:'状态',
				installPosition: '安装位置',
				address: '安装点地址',
			},
			params: {
				page:1,
				size:10,
				// userId:JSON.parse(sessionStorage.getItem("user")).id,
				// jumper:true,
				customerIds:[],
				devType:'10210'
			},
			// 是否绑定左侧区域
			bindArea: true,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: [
				{
					variableName: 'areaIds',
					str: '区域选择',
					isMultiple:true,
                    disabled: false,
					data:[],
					type: 'select',
				},
				{
					type: 'select',
					variableName: 'granularity',
					str: '时间类别',
					data: [
						{
							key: '1',
							value: '时'
						},
						{
							key: '2',
							value: '日'
						},
						{
							key: '3',
							value: '月'
						},
						{
							key: '5',
							value: '峰谷'
						}
					]
				},
				// {
				// 	type: 'rangePicker',
				// 	variableName: 'createTime',
				// 	time: false,
				// 	str: '时间'
				// },

			]
		},
		topFormItem: {
			modal: true,
			number: 4,
            labelCol: 8,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'controllerNo',
					str: '控制器编号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'detectorNo',
					str: '探测器编号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'commnunitNo',
					str: '控制器通信号',
					rules: TYPE_NAME.INPUT,
				},
				{
					variableName: 'location',
					str: '安装位置',
					rules: TYPE_NAME.INPUT,
				},
			]
		},
		searchGetData:{},
		    	// 总条数
		totalCount: 0,
		refBl:false,
		ckVal:false,
		topFormItem_1: {
			modal: true,
			number: 4,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'uniqueEncoding',
					str: '唯一编码',
					rules: TYPE_NAME.INPUT,
				},

			],
			data:{}
		},
		topFormItem_2: {
			modal: true,
			number: 4,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{
					variableName: 'uniqueEncoding',
					str: '唯一编码',
					rules: TYPE_NAME.INPUT,
				},

			],
			data:{}
		},
		activeKey:1,// 标签页所选页面
	};
	setTableValue_2 = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'data_1':// 甲烷浓度
				var title_1='-';
				rowData.data.map(one=>{
					if(one.itemId===3219){title_1=one.defaultValue}
				});
				return <>{title_1 ? <div className={'table_overflow'} title={title_1}>{title_1}</div> : '-'}</>;
			case 'data_2':// 温度
				var title_2='-';
				rowData.data.map(one=>{
					if(one.itemId===1205){title_2=one.defaultValue}
				});
				return <>{title_2 ? <div className={'table_overflow'} title={title_2}>{title_2}</div> : '-'}</>;
			case 'data_3':// 环境压力
				var title_3='-';
				rowData.data.map(one=>{
					if(one.itemId===1225){title_3=one.defaultValue}
				});
				return <>{title_3 ? <div className={'table_overflow'} title={title_3}>{title_3}</div> : '-'}</>;
			case 'data_4':// 浸水情况
				var title_4='-';
				rowData.data.map(one=>{
					if(one.itemId===3227){title_4=one.defaultValue}
				});
				return <>{title_4 ? <div className={'table_overflow'} title={title_4}>{title_4}</div> : '-'}</>;
			case 'data_5':// 异动情况
				var title_5='-';
				rowData.data.map(one=>{
					if(one.itemId===3235){title_5=one.defaultValue}
				});
				return <>{title_5 ? <div className={'table_overflow'} title={title_5}>{title_5}</div> : '-'}</>;
			case 'data_6':// 经度
				var title_6='-';
				rowData.data.map(one=>{
					if(one.itemId===3236){title_6=one.defaultValue}
				});
				return <>{title_6 ? <div className={'table_overflow'} title={title_6}>{title_6}</div> : '-'}</>;
			case 'data_7':// 纬度
				var title_7='-';
				rowData.data.map(one=>{
					if(one.itemId===3237){title_7=one.defaultValue}
				});
				return <>{title_7 ? <div className={'table_overflow'} title={title_7}>{title_7}</div> : '-'}</>;
			case 'data_8':// 传感器故障状态
				var title_8='-';
				rowData.data.map(one=>{
					if(one.itemId===3254){title_8=one.defaultValue}
				});
				return <>{title_8 ? <div className={'table_overflow'} title={title_8}>{title_8}</div> : '-'}</>;
			case 'data_9':// 电压
				var title_9='-';
				rowData.data.map(one=>{
					if(one.itemId===3000){title_9=one.defaultValue}
				});
				return <>{title_9 ? <div className={'table_overflow'} title={title_9}>{title_9}</div> : '-'}</>;
			case 'data_10':// 信号强度
				var title_10='-';
				rowData.data.map(one=>{
					if(one.itemId===1186){title_10=one.defaultValue}
				});
				return <>{title_10 ? <div className={'table_overflow'} title={title_10}>{title_10}</div> : '-'}</>;
			case 'data_11':// 报警状态
				var title_11='-';
				rowData.data.map(one=>{
					if(one.itemId===3216){title_11=one.defaultValue}
				});
				return <>{title_11 ? <div className={'table_overflow'} title={title_11}>{title_11}</div> : '-'}</>;
			// case 'data_12':// 历史上报状态
			// 	var title_12='-';
			// 	rowData.data.map(one=>{
			// 		if(one.itemId===3231){title_12=one.defaultValue}
			// 	});
			// 	return <>{title_12 ? <div className={'table_overflow'} title={title_12}>{title_12}</div> : '-'}</>;
			case 'data_13':// IMEI
				var title_13='-';
				rowData.data.map(one=>{
					if(one.itemId===1238){title_13=one.defaultValue}
				});
				return <>{title_13 ? <div className={'table_overflow'} title={title_13}>{title_13}</div> : '-'}</>;
			case 'data_14':// 数据时间
				var title_14='-';
				var juder1 = true;
				rowData.data.map(one=>{
					if(one?.systemTime){
						if(juder1){
							title_14 = moment(one.systemTime).format('YYYY-MM-DD HH:mm:ss');
							juder1=false;
						}
					}
				});


				return <>{title_14 ? <div className={'table_overflow'} title={title_14}>{title_14}</div> : '-'}</>;

			// rowData.data.map(one=>{
				// 	if(one.itemId===3233){title_14=one.defaultValue}
				// });
				// return <>{title_14 ? <div className={'table_overflow'} title={title_14}>{title_14}</div> : '-'}</>;
			case 'systemTime':
				var title_19='-';
				var juder2 = true;
				rowData.data.map(one=>{
					if(one?.systemTime){
						if(juder2){
							title_19 = moment(one.systemTime).format('YYYY-MM-DD HH:mm:ss');
							juder2=false;
						}
					}
				});


				return <>{title_19 ? <div className={'table_overflow'} title={title_19}>{title_19}</div> : '-'}</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	setTableValue_1 = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'data_1':
				var title_1='-';
				rowData.data.map(one=>{
					if(one.itemId===1205){title_1=one.defaultValue}
				});
				return <>{title_1 ? <div className={'table_overflow'} title={title_1}>{title_1}</div> : '-'}</>;
			case 'data_2':
				var title_2='-';
				rowData.data.map(one=>{
					if(one.itemId===3049){title_2=one.defaultValue}
				});
				return <>{title_2 ? <div className={'table_overflow'} title={title_2}>{title_2}</div> : '-'}</>;
			case 'data_3':
				var title_3='-';
				rowData.data.map(one=>{
					if(one.itemId===3219){title_3=one.defaultValue}
				});
				return <>{title_3 ? <div className={'table_overflow'} title={title_3}>{title_3}</div> : '-'}</>;
			case 'data_4':
				var title_4='-';
				rowData.data.map(one=>{
					if(one.itemId===3221){title_4=one.defaultValue}
				});
				return <>{title_4 ? <div className={'table_overflow'} title={title_4}>{title_4}</div> : '-'}</>;
			case 'data_5':
				var title_5='-';
				rowData.data.map(one=>{
					if(one.itemId===3224){title_5=one.defaultValue}
				});
				return <>{title_5 ? <div className={'table_overflow'} title={title_5}>{title_5}</div> : '-'}</>;
			case 'data_6':
				var title_6='-';
				rowData.data.map(one=>{
					if(one.itemId===3225){title_6=one.defaultValue}
				});
				return <>{title_6 ? <div className={'table_overflow'} title={title_6}>{title_6}</div> : '-'}</>;
			case 'data_7':
				var title_7='-';
				rowData.data.map(one=>{
					if(one.itemId===3226){title_7=one.defaultValue}
				});
				return <>{title_7 ? <div className={'table_overflow'} title={title_7}>{title_7}</div> : '-'}</>;
			case 'data_8':
				var title_8='-';
				rowData.data.map(one=>{
					if(one.itemId===3227){title_8=one.defaultValue}
				});
				return <>{title_8 ? <div className={'table_overflow'} title={title_8}>{title_8}</div> : '-'}</>;
			case 'data_9':
				var title_9='-';
				rowData.data.map(one=>{
					if(one.itemId===3228){title_9=one.defaultValue}
				});
				return <>{title_9 ? <div className={'table_overflow'} title={title_9}>{title_9}</div> : '-'}</>;
			case 'data_10':
				var title_10='-';
				rowData.data.map(one=>{
					if(one.itemId===3229){title_10=one.defaultValue}
				});
				return <>{title_10 ? <div className={'table_overflow'} title={title_10}>{title_10}</div> : '-'}</>;
			case 'data_11':
				var title_11='-';
				rowData.data.map(one=>{
					if(one.itemId===3230){title_11=one.defaultValue}
				});
				return <>{title_11 ? <div className={'table_overflow'} title={title_11}>{title_11}</div> : '-'}</>;
			case 'data_12':
				var title_12='-';
				rowData.data.map(one=>{
					if(one.itemId===3231){title_12=one.defaultValue}
				});
				return <>{title_12 ? <div className={'table_overflow'} title={title_12}>{title_12}</div> : '-'}</>;

			case 'data_13':
				var title_13='-';
				rowData.data.map(one=>{
					if(one.itemId===3232){title_13=one.defaultValue}
				});
				return <>{title_13 ? <div className={'table_overflow'} title={title_13}>{title_13}</div> : '-'}</>;
			case 'data_14':
				var title_14='-';
				rowData.data.map(one=>{
					if(one.itemId===3233){title_14=one.defaultValue}
				});
				return <>{title_14 ? <div className={'table_overflow'} title={title_14}>{title_14}</div> : '-'}</>;
			case 'data_15':
				var title_15='-';
				rowData.data.map(one=>{
					if(one.itemId===3234){title_15=one.defaultValue}
				});
				return <>{title_15 ? <div className={'table_overflow'} title={title_15}>{title_15}</div> : '-'}</>;
			case 'data_16':
				var title_16='-';
				rowData.data.map(one=>{
					if(one.itemId===3235){title_16=one.defaultValue}
				});
				return <>{title_16 ? <div className={'table_overflow'} title={title_16}>{title_16}</div> : '-'}</>;
			case 'data_17':
				var title_17='-';
				rowData.data.map(one=>{
					if(one.itemId===3236){title_17=one.defaultValue}
				});
				return <>{title_17 ? <div className={'table_overflow'} title={title_17}>{title_17}</div> : '-'}</>;
			case 'data_18':
				var title_18='-';
				rowData.data.map(one=>{
					if(one.itemId===3237){title_18=one.defaultValue}
				});
				return <>{title_18 ? <div className={'table_overflow'} title={title_18}>{title_18}</div> : '-'}</>;
			case 'systemTime':
				var title_19='-';
				var juder = true;
				rowData.data.map(one=>{
					if(one?.systemTime){
						if(juder){
							title_19 = moment(one.systemTime).format('YYYY-MM-DD HH:mm:ss');
							juder=false;
						}
					}
				});


				return <>{title_19 ? <div className={'table_overflow'} title={title_19}>{title_19}</div> : '-'}</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'instFlow':
				text = rowData.instFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'temp':
				text = rowData.temp*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'pre':
				text = rowData.pre*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'density':
				text = rowData.density*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'totalFlow':
				text = rowData.totalFlow*1;
				var ntext=text.toFixed(2)
				return <>
				{ntext ? <div className={'table_overflow'} title={ntext}>{ntext}</div> : ''}
				</>
			case 'dataTime':
				return text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-';
			case 'comTime':
				return text?moment(text).format("yyyy-MM-DD HH:mm:ss"):'-';
			case 'edit':
				// var rowData=this?.table?.getSelectChange();
				return<>
					<div className={'tableLink'} title={'修改'}
						 onClick={()=>{
							 console.log(rowData);
							 this.props.history.push({
								 pathname: './details/deviceRealDetails',
								 state:{
									 rowData: rowData,
								 }

							 })
						 }}
					>
						<u>{'查看'}</u>
					</div>
					<div className={'tableLink'} title={'历史记录'}
						 onClick={()=>{
							 console.log(rowData);
							 this.props.history.push({
								 pathname: './details/deviceRealHistory',
								 state:{
									 rowData: rowData,
								 }

							 })
						 }}
					>
						<u>{'历史记录'}</u>
					</div>
				</>;
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange()
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	};

	//
	// 循环时间
	circulateTime=()=>{
		console.log('循环时间');
		console.log(this.state.activeKey);
		switch (this.state.activeKey.toString()) {
			case '1':
				this?.table?.refreshTable();
				break;
			case '2':
				this?.table_2?.refreshTable();
				break;
			default:break;
		}



	};

	async componentDidMount(){
		// 循环加载
		setInterval(()=>{
			this.circulateTime();
		},60000);
		var tableParamenter_2=this.state.tableParamenter_2;
		var tableParamenter_1=this.state.tableParamenter_1;
		var tableParamenter = this.state.tableParamenter;
		// var userIds=[];
		// JSON.parse(sessionStorage.getItem("customerNames"))?.map(one=>{
		// 	userIds.push(Number(one.id));
		// });
		//
		// if(userIds.length!==0){
		// 	tableParamenter_2.params.customerIds=userIds;
		// 	tableParamenter_1.params.customerIds=userIds;
		// 	// tableParamenter.params.customerIds=userIds;
		// }
		await this.table.refreshTable(tableParamenter.params);
		this.setState({tableParamenter_2:tableParamenter_2,tableParamenter_1:tableParamenter_1,tableParamenter:tableParamenter})
	}
	componentWillUnmount() {
		clearInterval(this.countdown);
	}
	onChangeSelect = (e) => {
		this.state.tableParamenter.params.areaIds=[e];
		this.setState({})
	};




	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		let params = this.state.tableParamenter.params
		Object.assign(params,data)
		let {tableParamenter} = this.state
		tableParamenter.params = params
		this.setState({tableParamenter})
		this.table.refreshTable(params);
		console.log(this.state.tableParamenter.params)

	};

	onChange = checked => {
		console.log(`switch to ${checked}`);
		this.state.refBl=checked;
		this.setState(this.state.params)
	  }
	onCheck = checked => {
		this.state.ckVal=checked;
	}
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state
		  params.areaId = data
		  params.page = 1
		  params.size = initParams.size
		  this.setState({params})
		  this.getData()
		})
	  }
// 初始化表格数据
getData = () => {
	let params = this.state.tableParamenter.params
	if(this.state.refBl==true)
	{
		this.setState({})
		this.table.refreshTable(params);
	}
  };

	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,

	   this.setState({params:params});
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff'}}>
				<div className={'topSearchBox'}>
				<h3>设备实时信息监控</h3>

				</div>
				<div style={{width: '98%',margin:'0 1%',position:'relative',top:'-30px'}}>
					<Tabs
						defaultActiveKey="1"
						onChange={(a)=>{
							this.setState({activeKey:a})
						}}

					>
						<TabPane tab="报警探测器" key="1">
							<div style={{position:'relative'}}>
								<div style={{width:'90%'}}>
									<FormsTwo
										ref={e => this.forms = e}
										formParamenter={this.state.topFormItem}
										data={this.state.searchGetData}
									/>
								</div>
								<Button style={{position:'absolute', right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
							</div>
							<div>
								<TableComponents
									paramenter={this.state.tableParamenter}
									setData = {listDeviceRealTimeData}
									setTableValue={this.setTableValue}
									ref={e => this.table = e}
								>
								</TableComponents>
							</div>
						</TabPane>
						<TabPane tab="阀井" key="2">
							<div style={{position:'relative'}}>
								<div style={{width:'90%'}}>
									<FormsTwo
										ref={e => this.forms = e}
										formParamenter={this.state.topFormItem_1}
										data={this.state.topFormItem_1.data}
									/>
								</div>
								<Button style={{position:'absolute', left:'22%',top:'0'}}
										type="primary"
										onClick={async() => {
											let data = await this.forms.onFinishNotReset();
											console.log(data);
											var tableParamenter_1 = this.state.tableParamenter_1;
											if(data){
												tableParamenter_1.params.uniqueEncoding=data.uniqueEncoding
											}
											this.table_2.refreshTable(tableParamenter_1.params);
										}}
								>
									查询</Button>
							</div>

							<div>
								<TableComponents
									paramenter={this.state.tableParamenter_1}
									setData = {pageRealTimeValve}
									setTableValue={this.setTableValue_1}
									ref={e => this.table_2 = e}
								>
								</TableComponents>
							</div>
						</TabPane>
						<TabPane tab="管钉" key="3">
							<div style={{position:'relative'}}>
								<div style={{width:'90%'}}>
									<FormsTwo
										ref={e => this.forms = e}
										formParamenter={this.state.topFormItem_2}
										data={this.state.topFormItem_2.data}
									/>
								</div>
								<Button style={{position:'absolute', left:'22%',top:'0'}}
										type="primary"
										onClick={async() => {
											let data = await this.forms.onFinishNotReset();
											var tableParamenter_2 = this.state.tableParamenter_2;
											tableParamenter_2.params.uniqueEncoding=data?data.uniqueEncoding:null;
											this.table_3.refreshTable(tableParamenter_2.params);
										}}
								>
									查询
								</Button>
							</div>

							<div>
								<TableComponents
									paramenter={this.state.tableParamenter_2}
									setData = {pageRealTimeValve}
									setTableValue={this.setTableValue_2}
									ref={e => this.table_3 = e}
								>
								</TableComponents>
							</div>
						</TabPane>
					</Tabs>
				</div>
				</div>
            </Layout>
        )
    }
}
