import React, { Component } from 'react'
import {Layout} from "antd";
import './middleLeft.less'
import BarChart from "../../dataPanel/assembly/BarChart";
import BarChart1 from "../../dataPanel/assembly/BarChart_1";
import HalfDoughnutChart1 from "../../dataPanel/assembly/HalfDoughnutChart";

export default class middleLeft extends Component {
    state = {

        // 环形圆进度条
        leftData_1:{
            name:'安全率',data:91,color:['#3D8DFF','#62DBFF']
        },
        // 环形圆进度条
        leftData_2:{
            name:'安全率',data:93,color:['#96EE79','#F7FD86']
        },
        // // 环形圆进度条
        // leftData_2:{
        //     online:{singular:35,total:85},   // 在线率
        //     failure:{singular:35,total:65},  // 故障率
        // },

        // 曲线图-横向
        leftData_eCharts:{
            // 数值
            data:[
                {name:'燃气',data:[{name:'燃气泄露',data:[5,20,60,30,40]},{name:'燃气使用',data:[5,20,60,30,40]}]},
                {name:'液化',data:[{name:'液化泄露',data:[5,20,60,30,40]},{name:'液化使用',data:[5,20,60,30,40]}]},
            ],
            yData:['商综','机关','学校','工业','餐饮'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:100} // 最小、最大、间距
        },

        // 曲线图-竖向
        leftData_eCharts_1:{
            // 数值
            data:[
                {name:'燃气',data:[{name:'燃气泄露',data:[5,20,60,30,40]},{name:'燃气使用',data:[5,20,60,30,40]}]},
                {name:'液化',data:[{name:'液化泄露',data:[5,20,60,30,40]},{name:'液化使用',data:[5,20,60,30,40]}]},

            ],
            yData:['岳麓','芙蓉','雨花','天心','开福','宁乡','浏阳'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:100} // 最小、最大、间距
        },
    };
    render() {
        return (
            <div id={'middleLeft'}>
                <div style={{width: '100%',height: '10%', padding: '20px 0 0 50px'}}>
                    <span style={{  fontSize: '22px', color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>燃气用户</span>
                </div>
                <div style={{width:'100%',height:'35%'}}>

                    <div style={{width:'35%',height:'93%',marginLeft:'12%',marginTop:'2%',float:'left'}}>
                        <div style={{width:'100%',height:'60%',float:'left'}}>
                                    <HalfDoughnutChart1
                                        elementById={'leftTopData_1_1'}
                                        data={{
                                            data: this.state.leftData_1.data,
                                            name: this.state.leftData_1.name,
                                            color:this.state.leftData_1.color,
                                        }}
                                    />
                        </div>
                        <div style={{width:'100%',height:'18%',float:'left'}}>
                            <div style={{width:'30%',height:'100%',float:'left',marginLeft:'18%',textAlign: 'center'}}>
                                <span style={{color:'#a8e9d6'}}>燃气</span>
                            </div>
                            <div style={{width:'40%',height:'100%',float:'left'}}>
                                <span style={{color:'#fdfdfd'}}>18684</span>
                            </div>
                        </div>
                        <div style={{width:'100%',height:'20%',float:'left'}}>
                            <div className={'middleLeft_data_1_img_1'} style={{padding:'5px 0 5px 10%'}}>
                                <div className={'middleLeft_data_1_img_2'} style={{float:'left'}}/>
                                <div style={{width:'82%',height:'100%',float:'left'}}>
                                    <div style={{width:'55%',height:'100%',float:'left',marginLeft:'2%',textAlign: 'center'}}>
                                        <span style={{color:'#a8e9d6'}}>安全用户</span>
                                    </div>
                                    <div style={{width:'42%',height:'100%',float:'left'}}>
                                        <span style={{color:'#fdfdfd'}}>8834家</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'35%',height:'93%',marginLeft:'7%',marginTop:'2%',float:'left'}}>
                        <div style={{width:'100%',height:'60%',float:'left'}}>
                            <HalfDoughnutChart1
                                elementById={'leftTopData_1_2'}
                                data={{
                                    data: this.state.leftData_2.data,
                                    name: this.state.leftData_2.name,
                                    color:this.state.leftData_2.color,
                                }}
                            />
                        </div>
                        <div style={{width:'100%',height:'18%',float:'left'}}>
                            <div style={{width:'30%',height:'100%',float:'left',marginLeft:'20%',textAlign: 'center'}}>
                                <span style={{color:'#a8e9d6'}}>液化气</span>
                            </div>
                            <div style={{width:'40%',height:'100%',float:'left'}}>
                                <span style={{color:'#fdfdfd'}}>6271家</span>
                            </div>
                        </div>
                        <div style={{width:'100%',height:'20%',float:'left'}}>
                            <div className={'middleLeft_data_1_img_1'} style={{padding:'5px 0 5px 10%'}}>
                                <div className={'middleLeft_data_1_img_3'} style={{float:'left'}}/>
                                <div style={{width:'82%',height:'100%',float:'left'}}>
                                    <div style={{width:'55%',height:'100%',float:'left',marginLeft:'2%',textAlign: 'center'}}>
                                        <span style={{color:'#a8e9d6'}}>隐患用户</span>
                                    </div>
                                    <div style={{width:'42%',height:'100%',float:'left'}}>
                                        <span style={{color:'#fdfdfd'}}>112家</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'25%'}}>
                    <BarChart
                        elementById={'leftData_1'}
                        data={this.state.leftData_eCharts.data}    // 数值
                        yData={this.state.leftData_eCharts.yData}  // X轴数据
                        number={this.state.leftData_eCharts.number}// 最小、最大、间距
                    />
                </div>
                <div style={{width:'100%',height:'26%'}}>
                    <BarChart1
                        elementById={'leftData_2'}
                        data={this.state.leftData_eCharts_1.data}    // 数值
                        yData={this.state.leftData_eCharts_1.yData}  // X轴数据
                        number={this.state.leftData_eCharts_1.number}// 最小、最大、间距
                    />
                </div>
            </div>
        )
    }
}
