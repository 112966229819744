import React, { Component } from 'react'
import { Space, Table, Tag ,Button,Tooltip} from 'antd';
import { Map, Marker, InfoWindow ,ScaleControl} from '@uiw/react-amap';
import { CloseOutlined } from '@ant-design/icons';
import {listDeviceRealTimeData} from "../../../../services/steamReport";
import {PageQueryLifecycle} from "../../../../services/qrCode";
const { AMap } = window;

class BarwithBackground extends React.Component {
    state = {
        position:this.props?.data || [
            {id:1, name:"长沙1号",longitude: 113.018664, latitude: 28.199714},
            {id:2, name:"长沙2号",longitude: 113.118664, latitude: 28.199714},
            {id:3, name:"长沙3号",longitude: 113.218664, latitude: 28.199714},
            {id:4, name:"长沙4号",longitude: 113.318664, latitude: 28.199714},
        ],
        // 选中坐标
        selectPosition:{
            // longitude:28.199714,
            // latitude:113.018664
        },
        selectCustomerData:{},
        show : false,
        window_show_1:'none',
        window_show_2:'',
        // 表格参数
        columns:[
            {title: '控制器型号', dataIndex: 'parentModel', key: 'parentModel'},
            {title: '控制器编号', dataIndex: 'parentNo', key: 'parentNo'},
            {title: '探测器路号', dataIndex: 'routeno', key: 'routeno'},
            {title: '探测器编号', dataIndex: '探测器编号', key: '探测器编号'},
            {title: '报警状态', dataIndex: 'status', key: 'status'},
            {title: '安装位置', dataIndex: 'installPosition', key: 'installPosition'},

        ],
        data:[],
        params: {
            page:1,
            size:100,
            userId:JSON.parse(sessionStorage.getItem("user")).id,
            jumper:true
        },
    };

    // 通过点击安装点坐标查询当前设备情况
    listDeviceRealTimeData=(e)=>{
        var params = {
                page:1,
                size:100,
                locations:[],
                jumper:true
            };
        params.locations.push(e.id);
        console.log(params);
        listDeviceRealTimeData(params).then(
            response=>{
                var {selectCustomerData,data} = this.state;
                data=[];
                if(response?.data?.data){
                    data=response.data.data;
                    selectCustomerData.number=response?.data?.data?.length || 0;
                    this.setState({data:data,selectCustomerData})
                }

            }
        )
    };

    bMapTransQQMap(lng, lat) {
        let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
        let x = lng - 0.0065;
        let y = lat - 0.006;
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
        let lngs = z * Math.cos(theta);
        let lats = z * Math.sin(theta);
        return {
            lat: lats,
            lng: lngs
        }
    }

    componentDidMount() {}
    render() {
        const {position,show,selectPosition} = this.state;

        const log =position[0]?.id;
        return (
            <div style={{width: '100%',height: '100%'}}>
                <div style={{width: '100%',height: '100%'}}>

                    <Map zoom={10}
                    >

                        {position.map(one=>{
                            return (
                                <Marker  title={one.customerName}
                                         position={new AMap.LngLat(this.bMapTransQQMap(one.longitude,one.latitude).lat,this.bMapTransQQMap(one.longitude,one.latitude).lng)}
                                         label={{
                                             // 设置文本标注偏移量
                                             // offset: new AMap.Pixel(20, 20),
                                             // 设置文本标注内容
                                             content: one.customerName,
                                             // 设置文本标注方位
                                             direction: 'bottom'
                                         }}
                                         onClick={(evn) => {
                                             selectPosition.longitude=this.bMapTransQQMap(one.longitude,one.latitude).lng;
                                             selectPosition.latitude=this.bMapTransQQMap(one.longitude,one.latitude).lat;
                                             this.setState({show:false});
                                             this.setState({show:true});
                                             var {selectCustomerData} = this.state;
                                             selectCustomerData=one;
                                             this.setState({selectCustomerData});
                                             this.listDeviceRealTimeData(one);
                                         }}
                                />
                            )
                        })}
                        {this.state.show? (
                            <InfoWindow
                                isCustom={true}
                                position={ new AMap.LngLat(selectPosition.latitude,selectPosition.longitude)}
                                offset={{ x: 15, y: -20}}
                                closeWhenClickMap={true}
                            >
                                <div style={{width: '500px',height:'325px'}}>
                                    <div style={{
                                        width:'100%',
                                        height: '100%',
                                        padding:'2px 2px 2px 2px ',
                                        margin:'2px',float: "left",
                                        backgroundColor: '#10213a'}}
                                    >
                                        <div style={{width: "100%", "textAlign": "right",padding:'2px'}}>
                                            <Button size={'small'} shape="circle" icon={<CloseOutlined />} onClick={()=>{ this.setState({show:false});}} />
                                        </div>
                                        <div style={{width: "100%",height:'55px'}}>
                                            <div style={{float: "left",width: "45%",fontSize:"12px",color:"#fff"}}>
                                                <span>客户名称：</span><span>{this.state.selectCustomerData?.customerName || '-'}</span>
                                            </div>
                                            <div style={{float: "left",width: "45%",fontSize:"12px",color:"#fff"}}>
                                                <span>设备总量：</span><span>{this.state.selectCustomerData?.number || '-'}</span>
                                            </div>

                                            <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>
                                                <span>安装点地址：</span><span>{this.state.selectCustomerData?.address || '-'}</span>
                                            </div>
                                            <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>
                                                <span>控制器列表</span>
                                            </div>

                                        </div>
                                        <div style={{width: '100%',height:'235px',backgroundColor:'#fff'}}>
                                            <Table columns={this.state.columns}
                                                   dataSource={this.state.data}
                                                   pagination={false}
                                                // scroll={{ y:600}}
                                                   scroll={{ x: 800,y:200 }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了*/}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </InfoWindow>



                            // <InfoWindow
                            //     isCustom={true}
                            //     position={ new AMap.LngLat(selectPosition.latitude,selectPosition.longitude)}
                            //     offset={{ x: 15, y: -20}}
                            //     closeWhenClickMap={true}
                            // >
                            //     <div style={{width: this.state.window_show_1 ==='none'|this.state.window_show_2 ==='none'?'100px'
                            //             : this.state.window_show_1 ===''|this.state.window_show_2 ===''?'1100px'
                            //             : '530px',
                            //         height:this.state.window_show_1 ===''||this.state.window_show_2 ===''?'330px'
                            //             : '30px',
                            //     }}>
                            //         {/*<div style={{*/}
                            //         {/*    width: this.state.window_show_1 ==='none'?'30px':'500px',*/}
                            //         {/*    height: this.state.window_show_1 ==='none'?'30px':'100%',*/}
                            //         {/*    padding:'2px 2px 2px 2px ',margin:'2px',float: "left",backgroundColor: '#10213a'}}>*/}
                            //         {/*    <div style={{width: "100%","textAlign": "right",padding:'2px'}}>*/}
                            //         {/*        <Button size={'small'} shape="circle" icon={<CloseOutlined />} onClick={()=>{ this.setState({window_show_1:this.state.window_show_1==='none'?'':'none'});}} />*/}
                            //         {/*    </div>*/}
                            //         {/*    <div style={{width: "100%",display:this.state.window_show_1}}>*/}
                            //         {/*        <div style={{float: "left",width: "30%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>控制器编号:</span><span>-</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div style={{float: "left",width: "30%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>控制器编号:</span><span>-</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div style={{float: "left",width: "30%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>下辖设备:</span><span>-</span>*/}
                            //         {/*        </div>*/}
                            //
                            //         {/*        <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>安装地址:</span><span>-</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>安装位置:</span><span>-</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>控制器列表</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div>*/}
                            //         {/*            <Table columns={this.state.columns}dataSource={this.state.data}*/}
                            //         {/*                   scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了*!/*/}
                            //         {/*            />*/}
                            //         {/*        </div>*/}
                            //         {/*    </div>*/}
                            //         {/*</div>*/}
                            //
                            //         {/*<div style={{*/}
                            //         {/*    width: this.state.window_show_2 ==='none'?'30px':'500px',*/}
                            //         {/*    height: this.state.window_show_2 ==='none'?'30px':'100%',*/}
                            //         {/*    padding:'2px 2px 2px 2px ',margin:'2px',float: "left",backgroundColor: '#10213a'}}>*/}
                            //         {/*    <div style={{width: "100%","textAlign": "right",padding:'2px'}}>*/}
                            //         {/*        <Button size={'small'} shape="circle" icon={<CloseOutlined />} onClick={()=>{ this.setState({window_show_2:this.state.window_show_2==='none'?'':'none'});}} />*/}
                            //         {/*    </div>*/}
                            //         {/*    */}
                            //         {/*    <div style={{width: "100%",display:this.state.window_show_2}}>*/}
                            //         {/*        <div style={{float: "left",width: "45%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>客户名称：</span><span>{this.state.selectCustomerData?.customerName || '-'}</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div style={{float: "left",width: "45%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>设备总量：</span><span>{this.state.selectCustomerData?.number || '-'}</span>*/}
                            //         {/*        </div>*/}
                            //
                            //         {/*        <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>安装点地址：</span><span>{this.state.selectCustomerData?.address || '-'}</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div style={{float: "left",width: "100%",fontSize:"12px",color:"#fff"}}>*/}
                            //         {/*            <span>控制器列表</span>*/}
                            //         {/*        </div>*/}
                            //         {/*        <div>*/}
                            //         {/*            <Table columns={this.state.columns}*/}
                            //         {/*                   dataSource={this.state.data}*/}
                            //         {/*                   pagination={false}*/}
                            //         {/*                   // scroll={{ y:600}}*/}
                            //         {/*                   scroll={{ x: 800,y:600 }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了*!/*/}
                            //         {/*            />*/}
                            //         {/*        </div>*/}
                            //         {/*    </div>*/}
                            //         {/*</div>*/}
                            //
                            //     </div>
                            // </InfoWindow>
                        ):null}
                    </Map>
                </div>
            </div>
        )
    }

};
export default BarwithBackground;
