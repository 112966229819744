import React, { Component } from 'react'
import {Layout} from "antd";
import './bottomThree.less'
import BasicLineChart from '../assembly/BasicLineChart'
import Semicircle1 from '../assembly/Semicircle'
import Echart_1 from "../../earlyWarning/map/js/echarts_1";
export default class bottomThree extends Component {
    state = {

        // 空心半圆进度条
        semicircle_data_1:{
            online:{singular:50,total:150,color:['#29aeff','#8bcdff']},   // 在线率
            failure:{singular:93,total:103,color:['#39ecff','#67ff99']},  // 故障率
        },

        leftData_eCharts:{
            name:"隐患变化",
            // 数值
            data:[
                {name:'重大隐患',data:[150,70,220,140,90,90,210],color:['#FFA06C','#FFC081']},
                {name:'一级隐患',data:[170,200,120,210,160,140,170],color:['#fdff45','#fbff96']},
                {name:'二级隐患',data:[120,190,220,120,220,230,160],color:['#4972ff','#878eff']},
            ],
            yData:['1月','2月','3月','4月','5月','6月','7月'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:50} // 最小、最大、间距
        },
    };


    render() {
        return (
            <div id={'bottomThree'}>
                <div style={{ width: '100%',height: '17%', padding: '20px 0 0 30px'}}>
                    <span style={{ fontSize: '22px',color:'#BAE9FF', fontFamily: '方正兰亭黑简体'}}>隐患整治</span>
                </div>
                <div style={{ width: '100%',height: '83%'}}>
                    {/*上部分*/}
                    <div style={{ width: '100%',height: '57%'}}>
                        {/*1-1*/}
                        <div style={{ width: '100%',height: '50%'}}>
                            {/*1-1-1*/}
                            <div style={{ width: '27%',height: '100%',float:'left',marginLeft:'9%'}}>
                                <div className={'bottomThree_data_div_1_img'}>
                                    <div style={{ width: '100%',height: '35px',color:'#FFFFFF'}}>
                                        <div style={{ width: '100%',textAlign:'center'}}>
                                            <span style={{fontSize:'20.05px'}}>98</span>
                                        </div>
                                        <div style={{ width: '100%',marginTop:'-10px',textAlign:'center'}}>
                                            <span style={{fontSize:'12px'}}>应急抢修</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*1-1-2*/}
                            <div style={{ width: '27%',height: '100%',float:'left'}}>
                                <div className={'bottomThree_data_div_1_img'}>
                                    <div style={{ width: '100%',height: '35px',color:'#FFFFFF'}}>
                                        <div style={{ width: '100%',textAlign:'center'}}>
                                            <span style={{fontSize:'20.05px'}}>0</span>
                                        </div>
                                        <div style={{ width: '100%',marginTop:'-10px',textAlign:'center'}}>
                                            <span style={{fontSize:'12px'}}>停气整改</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*1-1-3*/}
                            <div style={{ width: '27%',height: '100%',float:'left'}}>
                                <div className={'bottomThree_data_div_1_img'}>
                                    <div style={{ width: '100%',height: '35px',color:'#FFFFFF'}}>
                                        <div style={{ width: '100%',textAlign:'center'}}>
                                            <span style={{fontSize:'20.05px'}}>13</span>
                                        </div>
                                        <div style={{ width: '100%',marginTop:'-10px',textAlign:'center'}}>
                                            <span style={{fontSize:'12px'}}>限期整改</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%',height: '50%'}}>
                            <div style={{ width: '35%',height: '100%',float:'left',marginLeft:'12%'}}>
                                <div className={'bottomThree_data_div_2_img'}>
                                    <Semicircle1
                                        elementById={'leftTopData_2_1'}
                                        data={{
                                            value: this.state.semicircle_data_1.online.singular,
                                            name: '今日在线率',
                                            title:'30min',
                                            color:this.state.semicircle_data_1.online.color,
                                            counNum:this.state.semicircle_data_1.online.total}}
                                    />
                                </div>
                                <div style={{width:'100%',height:'20%',textAlign:'center',}}>
                                    <span style={{color:'#fff',fontSize:"12px"}}>抢修平均到达时间</span>
                                </div>
                            </div>
                            <div style={{ width: '35%',height: '100%',float:'left',marginLeft:'9%'}}>
                                <div className={'bottomThree_data_div_2_img'}>
                                    <Semicircle1
                                        elementById={'leftTopData_2_2'}
                                        data={{
                                            value: this.state.semicircle_data_1.failure.singular,
                                            name: '今日在线率',
                                            title:'93%',
                                            color:this.state.semicircle_data_1.failure.color,
                                            counNum:this.state.semicircle_data_1.failure.total}}
                                    />
                                </div>
                                <div style={{width:'100%',height:'20%',textAlign:'center',}}>
                                    <span style={{color:'#fff',fontSize:"12px"}}>隐患整改完成率</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/*下部分*/}
                    <div style={{ width: '100%',height: '40%'}}>
                        <BasicLineChart
                            elementById={'bottomThree_1'}
                            name={this.state.leftData_eCharts.name}
                            data={this.state.leftData_eCharts.data}    // 数值
                            yData={this.state.leftData_eCharts.yData}  // X轴数据
                            number={this.state.leftData_eCharts.number}// 最小、最大、间距
                        />
                    </div>
                </div>
            </div>
        )
    }
}
