// 委托单下的检定报警器管理
import React, { Component } from 'react'
import { Layout, Button, Tabs, Input, Col, Row, message, Modal,Card,Spin} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import { NavLink } from "react-router-dom"

import {TYPE_NAME} from '../../../components/Const'
import {addGasAlarmRecord,getAlarmByStratNumber,getCustomerOne,updateEntrust,copyEntrustInfo,getModelByAlaramId,getOneAcquisitionSheet,getDev,getVerificationRecordByDevId,verificationCollectionSheet} from '../../../services/verifvication'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import $ from 'jquery'
import {funTraceInfo} from '../../../services/common'
import dfImg from '../../../assets/systemLogo/dfImg.png'

const { TabPane } = Tabs;
const { Meta } = Card;
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
export default class AddPoint extends Component {

    state = {
				// 表格参数
				tableParamenter: {
					// columnMaxWith: 800,
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						equipmentName: '设备名称',
						model: '型号规格',
						startNumber: '出厂编号',
						type: '类型',
						state: '状态',
						edit:'操作'
					},
					// 弹出框
					headers: {
						equipmentName: '设备名称',
						model: '型号规格',
						startNumber: '出厂编号',
						type: '类型',
						state: '状态',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						id: this.props.location.state.id,	// 委托单
						page:1,
						size:3,
					}
				},

				// 表格参数
				tableOneParamenter: {
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						deviceName: '名称',
						model: '规格',
						certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						edit: '操作',
					},
					// 弹出框
					headers: {
						deviceName: '名称',
						model: '规格',
						certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						type: 1,	// 委托单
						page:1,
						size:10,
						gasId:this.props.location?.state.id,
					}
				},
				// 表格参数
				tableTwoParamenter: {
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					// 表头：变量和变量解释
					header: {
						deviceName: '名称',
						model: '规格',
						// certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
						edit: '操作',
					},
					// 弹出框
					headers: {
						deviceName: '名称',
						model: '规格',
						// certificate: '证书编号',
						uncertainValue: '不确定度/准确度等级(最大允许误差)',
						expirationTime: '有效期',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						type: 2,	// 委托单
						page:1,
						size:10,
						gasId:this.props.location?.state.id,
					}
				},

				// 总条数
				totalCount: 0,

				// params: {
				// 	entrustId:'',	// 委托单
				// 	page:1,
				// 	size:10,
				// 	// page:{
				// 	// 	page:1,
				// 	// 	size:10,
				// 	// }

				// 	// username: null,
				// 	// organizationId :this.state.treeId
				// 	// areaId: localStorage.getItem('menus')
				// },

        // 表单参数
		formParamenter: {
			modal: true,
			number: 2,
            labelCol: 12,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'temperature',
					str: '环境温度',
					disabled: true,
					rules: TYPE_NAME.TEMPERATURE,
				},
				{
					variableName: 'humidity',
					str: '相对湿度',
					disabled: true,
					rules: TYPE_NAME.ID_NUMBER_STR,
				},
				// {
				// 	variableName: 'dataTime',
				// 	time: true,
				// 	str: '时间',
				// 	rules: TYPE_NAME.DATE_PICKER,
				// },
			]
		},
		//仪器基本信息表单参数
		formTwoParamenter: {
			modal: true,
			number: 2,
            labelCol: 12,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'inspectionUnit',
					str: '送检单位',
					disabled: true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20,
				},
				{
					variableName: 'equipmentName',
					str: '仪器名称',
					disabled: true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20,

				},
				{
					variableName: 'manufacturers',
					str: '制造厂家',
					disabled: true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20,
				},
				{
					variableName: 'model',
					str: '型号规格',
					disabled: true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20,

				},
				{
					variableName: 'mileage',
					str: '量程',
					disabled: true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20,
				},
				{
					variableName: 'startNumber',
					str: '出厂编号',
					disabled: true,
					rules: TYPE_NAME.LABLE_NOT_NULL_20,

				},
				{
					variableName: 'method',
					str: '采样方式',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: 1,
							value: '扩散式'
						},
						{
							key: 2,
							value: '吸入式'
						}
					]
				},
				{
					variableName: 'type',
					str: '检定类型',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					onChange: e => this.onChangeSelect(e),
					data: [
						{
							key: 1,
							value: '首次检定'
						},
						{
							key: 2,
							value: '后续检定'
						},
						{
							key: 3,
							value: '使用中检查'
						}
					]

				},
				{
					variableName: 'samplingEquipment',
					str: '检定设备',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: 1,
							value: '固定式'
						},
						{
							key: 2,
							value: '便携式'
						}
					]
				},
				{
					variableName: 'verificationBasis',
					str: '检定依据',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: 'JJG693-2011可燃气体检测报警器',
							value: 'JJG693-2011可燃气体检测报警器'
						}
					]
				},
			]
		},
		//检定结果表单参数
		formResParamenter1: {
					modal: true,
					number: 2,
					labelCol: 12,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'appearanceAndStructure',
							str: '外观和结构',
							disabled: true,
							rules: TYPE_NAME.REQ_SELECT,
							data: [
								{
									key: 0,
									value: '合格'
								},
								{
									key: 1,
									value: '不合格'
								}
							]
						},
						{
							variableName: 'signsAndMarks',
							str: '标志与标识',
							disabled: true,
							rules: TYPE_NAME.REQ_SELECT,
							data: [
								{
									key: 0,
									value: '合格'
								},
								{
									key: 1,
									value: '不合格'
								}
							]
						},
						{
							variableName: 'powerOnTest',
							str: '通电检测',
							disabled: true,
							rules: TYPE_NAME.REQ_SELECT,
							data: [
								{
									key: 0,
									value: '合格'
								},
								{
									key: 1,
									value: '不合格'
								}
							]
						},
						{
							variableName: 'insulationResistance',
							str: '绝缘电阻',
							disabled: true,
							rules: TYPE_NAME.NUMBER,
						},


					]
		},
				//报警功能
			formResParamenter2: {
				modal: true,
				number: 2,
				labelCol: 12,
				layout: "horizontal",
				// itemSize: 'large',
				parameter: [

					{
						variableName: 'indicationOne',
						str: '实测报警值一(%LEL)',
						disabled: true,
						rules: TYPE_NAME.PERCENTAGE,

					},
					{
						variableName: 'indicationTwo',
						str: '实测报警值二(%LEL)',
						disabled: true,
						rules: TYPE_NAME.PERCENTAGE,

					},
					{
						variableName: 'indicationThree',
						str: '实测报警值三(%LEL)',
						disabled: true,
						rules: TYPE_NAME.PERCENTAGE,

					},

				]
		},
		formResParamenter22: {
			modal: true,
			number: 2,
			labelCol: 12,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'operateValue',
					str: '报警动作值(%LEL)',
					disabled: true,
					rules: TYPE_NAME.PERCENTAGE,

				},
				{
					variableName: 'warningFunction',
					str: '报警功能',
					disabled: true,
					rules: TYPE_NAME.REQ_SELECT,
					data: [
						{
							key: "0",
							value: '合格'
						},
						{
							key: "1",
							value: '不合格'
						}
					]
				},

			]
		},

			//示值误差
			formSzParamenter1: {
					modal: true,
					number: 2,
					labelCol: 12,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'normalConcentration',
							str: '标气浓度一',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},

					]
			},
			formSzParamenter11: {
				modal: true,
				number: 2,
				labelCol: 12,
				layout: "horizontal",
				// itemSize: 'large',
				parameter: [
					{
						variableName: 'indicationOne',
						str: '仪器示值一(%LEL)',
						disabled: true,
						rules: TYPE_NAME.PERCENTAGE,

					},
					{
						variableName: 'indicationTwo',
						str: '仪器示值二(%LEL)',
						disabled: true,
						rules: TYPE_NAME.PERCENTAGE,

					},
					{
						variableName: 'indicationThree',
						str: '仪器示值三(%LEL)',
						disabled: true,
						rules: TYPE_NAME.PERCENTAGE,

					},

				]
		},
		formSzParamenter111: {
			modal: true,
			number: 2,
			labelCol: 12,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'averageValue',
					str: '平均值一(%LEL)',
					disabled: true,
					rules: TYPE_NAME.NUMBER,

				},
				{
					variableName: 'indicationError',
					str: '示值误差一',
					disabled: true,
					rules: TYPE_NAME.NUMBER,

				},

			]
	},
			//示值误差
			formSzParamenter2: {
								modal: true,
								number: 2,
								labelCol: 12,
								layout: "horizontal",
								// itemSize: 'large',
								parameter: [
									{
										variableName: 'normalConcentration',
										str: '标气浓度二',
										disabled: true,
										rules: TYPE_NAME.PERCENTAGE,

									},

								]
						},
						formSzParamenter22: {
							modal: true,
							number: 2,
							labelCol: 12,
							layout: "horizontal",
							// itemSize: 'large',
							parameter: [
								{
									variableName: 'indicationOne',
									str: '仪器示值一(%LEL)',
									disabled: true,
									rules: TYPE_NAME.PERCENTAGE,

								},
								{
									variableName: 'indicationTwo',
									str: '仪器示值二(%LEL)',
									disabled: true,
									rules: TYPE_NAME.PERCENTAGE,

								},
								{
									variableName: 'indicationThree',
									str: '仪器示值三(%LEL)',
									disabled: true,
									rules: TYPE_NAME.PERCENTAGE,

								},
							]
					},
					formSzParamenter222: {
						modal: true,
						number: 2,
						labelCol: 12,
						layout: "horizontal",
						// itemSize: 'large',
						parameter: [
							{
								variableName: 'averageValue',
								str: '平均值二(%LEL)',
								disabled: true,
								rules: TYPE_NAME.NUMBER,

							},
							{
								variableName: 'indicationError',
								str: '示值误差二',
								disabled: true,
								rules: TYPE_NAME.NUMBER,

							},

						]
				},
				//示值误差
				formSzParamenter3: {
								modal: true,
								number: 2,
								labelCol: 12,
								layout: "horizontal",
								// itemSize: 'large',
								parameter: [
									{
										variableName: 'normalConcentration',
										str: '标气浓度三',
										disabled: true,
										rules: TYPE_NAME.PERCENTAGE,

									},
								]
				},
				formSzParamenter33: {
					modal: true,
					number: 2,
					labelCol: 12,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '仪器示值一(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationTwo',
							str: '仪器示值二(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationThree',
							str: '仪器示值三(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
					]
				},
				formSzParamenter333: {
					modal: true,
					number: 2,
					labelCol: 12,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'averageValue',
							str: '平均值三(%LEL)',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},
						{
							variableName: 'indicationError',
							str: '示值误差三',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},

					]
				},
				//响应时间
				formResParamenter3: {
					modal: true,
					number: 2,
					labelCol: 12,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '测试结果一',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationTwo',
							str: '测试结果二',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationThree',
							str: '测试结果三',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'average',
							str: '平均值(%LEL)',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},

					]
			},

				//重复性(标准值40%LEL)
				formResParamenter4: {
					modal: true,
					number: 2,
					labelCol: 8,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '示值1～6一(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationTwo',
							str: '示值1～6二(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationThree',
							str: '示值1～6三(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationFour',
							str: '示值1～6四(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationFive',
							str: '示值1～6五(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'indicationSix',
							str: '示值1～6六(%LEL)',
							disabled: true,
							rules: TYPE_NAME.PERCENTAGE,

						},
						{
							variableName: 'averageValue',
							str: '平均值(%LEL)',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},
						{
							variableName: 'repeatability',
							str: '重复性',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},

					]
			},
				//零点漂移
				formResParamenter5: {
					modal: true,
					number: 4,
					labelCol: 10,
					layout: "horizontal",
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'indicationOne',
							str: '0',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationTwo',
							str: '1h',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationThree',
							str: '2h',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationFour',
							str: '3h',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationFive',
							str: '4h',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationSix',
							str: '5h',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'indicationSeven',
							str: '6h',
							disabled: true,
							rules: TYPE_NAME.ID_NUMBER_STR,

						},
						{
							variableName: 'zeroIndication',
							str: '零点示值',
							disabled: true,
							rules: TYPE_NAME.NUMBER,

						},
					]
			},
			//量程漂移
							formResParamenter6: {
								modal: true,
								number: 4,
								labelCol: 10,
								layout: "horizontal",
								// itemSize: 'large',
								parameter: [
									{
										variableName: 'indicationOne',
										str: '0',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'indicationTwo',
										str: '1h',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'indicationThree',
										str: '2h',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'indicationFour',
										str: '3h',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'indicationFive',
										str: '4h',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'indicationSix',
										str: '5h',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'indicationSeven',
										str: '6h',
										disabled: true,
										rules: TYPE_NAME.ID_NUMBER_STR,

									},
									{
										variableName: 'rangeIndication',
										str: '量程示值',
										disabled: true,
										rules: TYPE_NAME.NUMBER,

									},

								]
						},
			//审核
			formShParamenter: {
				modal: true,
				number: 1,
				labelCol: 3,
				layout: "horizontal",
				// itemSize: 'large',
				parameter: [
					{
						variableName: 'problemDescription',
						str: '问题描述',
						rules: TYPE_NAME.TEXT_AREA,

					},

				]
		},
		rightData:{
		},
		data: {
			customerId: ''
		},
		dataKey: this.props.location.state?.dataKey ?? '1',
		devVisible:false, // 小窗口开关器-1
		voidVisible:false, //作废窗口开关器-1
		voidId:'',
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
		voGasAlarmRecordList:[],
		equipmentName:'',
		onSaveVisible:false, // 小窗口开关器-1
		status:'',
		pdfId:'',
    };

	componentDidMount(){
		const {name, id,state} = this.props.location?.state ?? {};
		console.log(state)
		let {tableParamenter} = this.state;
		tableParamenter.params.entrustId = id;
		this.setState({tableParamenter});
		if(state!='已提交')
		{
			$('#btnBox').hide();
		}else{
			$('#btnBox').show();
		}
		if(id) {
			this.getData(id)
		}
		getOneAcquisitionSheet({acquisitionSheetId: id}).then(
			response => {
				const {data} = response.data;
				console.log(data)
				if(data){
				//基础数据
				this.state.rightData.entrustUnit=data.entrustUnit
				this.state.rightData.customerName=data.customerName
				this.state.rightData.invoiceSelection=data.invoiceSelection
				this.state.rightData.phone=data.phone
				this.state.rightData.valveSwitch=data.valveSwitch
				this.state.rightData.verificationQuantity=data.verificationQuantity
				//门店照片
				if(data.voStorePhotos){
					this.state.rightData.doorHeadUrl=data.voStorePhotos.doorHeadUrl
					this.state.rightData.environmentalUrl=data.voStorePhotos.environmentalUrl
					this.state.rightData.licenseUrl=data.voStorePhotos.licenseUrl
					this.state.rightData.otherPhotoUrl=data.voStorePhotos.otherPhotoUrl
				}
				//单据照片
				if(data.voDocumentPhoto){				
				this.state.rightData.agreementUrl=data.voDocumentPhoto.agreementUrl
				this.state.rightData.entrustmentFormUrl=data.voDocumentPhoto.entrustmentFormUrl
				this.state.rightData.otherPhotosUrl=data.voDocumentPhoto.otherPhotosUrl
				}
				if(data.voControllerPcList){
					for(var i=0;i<data.voControllerPcList.length;i++){
						$('#kzBox').append("<h4>可燃气体报警控制器</h4>"+
						"<div class='picAndTextInnerTop'>"+
							"<p><span>通讯编号："+data.voControllerPcList[i].deviceQrCode+"</span><span>出厂编号："+data.voControllerPcList[i].startNumber+"</span></p>"+
							"<p><span>制造厂家："+data.voControllerPcList[i].manufacturer+"</span><span>设备型号："+data.voControllerPcList[i].model+"</span></p>"+
						"</div>"+
						"<ul  class='picAndTextList'>"+
							"<li>"+
							"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].controllerPhotosUrl+" />"+
							"<span>控制器照片</span>"+
							"</li>"+
							"<li>"+
								"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].environmentalPhotosUrl+" />"+
								"<span>环境照片</span>"+
							"</li>"+
							"<li>"+
								"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].installationPhotosUrl+" />"+
								"<span>安装照片</span>"+
							"</li>"+
							"<li>"+
								"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].otherPhotoUrl+" />"+
								"<span>其他照片</span>"+
							"</li>"+
						"</ul>");
						if(data.voControllerPcList[i].voDetectorPcList){
							for(var j=0;j<data.voControllerPcList[i].voDetectorPcList.length;j++){
								$('#kzBox').append("<h4>探测器</h4>"+
								"<div class='picAndTextInnerTop'>"+
									"<p><span>通讯编号："+data.voControllerPcList[i].voDetectorPcList[j].deviceQrCode+"</span><span>出厂编号："+data.voControllerPcList[i].voDetectorPcList[j].startNumber+"</span></p>"+
									"<p><span>制造厂家："+data.voControllerPcList[i].voDetectorPcList[j].manufacturer+"</span><span>设备型号："+data.voControllerPcList[i].voDetectorPcList[j].model+"</span></p>"+
								"</div>"+
								"<ul  class='picAndTextList'>"+
									"<li>"+
									"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].voDetectorPcList[j].equipmentPhotosUrl+" />"+
									"<span>设备照片</span>"+
									"</li>"+
									"<li>"+
										"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].voDetectorPcList[j].environmentalPhotosUrl+" />"+
										"<span>环境照片</span>"+
									"</li>"+
									"<li>"+
										"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].voDetectorPcList[j].installationPhotosUrl+" />"+
										"<span>安装照片</span>"+
									"</li>"+
									"<li>"+
										"<img class='proPic' alt='暂未上传' src="+data.voControllerPcList[i].voDetectorPcList[j].otherPhotoUrl+" />"+
										"<span>其他照片</span>"+
									"</li>"+
								"</ul>");
							}
						}
					}
				}


				if(data.voIndependentDetectorPcList){
					for(var i=0;i<data.voIndependentDetectorPcList.length;i++){
						$('#dlBox').append("<h4>独立探测器</h4>"+
						"<div class='picAndTextInnerTop'>"+
							"<p><span>通讯编号："+data.voIndependentDetectorPcList[i].deviceQrCode+"</span><span>出厂编号："+data.voIndependentDetectorPcList[i].startNumber+"</span></p>"+
							"<p><span>制造厂家："+data.voIndependentDetectorPcList[i].manufacturer+"</span><span>设备型号："+data.voIndependentDetectorPcList[i].model+"</span></p>"+
						"</div>"+
						"<ul  class='picAndTextList'>"+
							"<li>"+
							"<img class='proPic' alt='暂未上传' src="+data.voIndependentDetectorPcList[i].equipmentPhotosUrl+" />"+
							"<span>控制器照片</span>"+
							"</li>"+
							"<li>"+
								"<img class='proPic' alt='暂未上传' src="+data.voIndependentDetectorPcList[i].environmentalPhotosUrl+" />"+
								"<span>环境照片</span>"+
							"</li>"+
							"<li>"+
								"<img class='proPic' alt='暂未上传' src="+data.voIndependentDetectorPcList[i].installationPhotosUrl+" />"+
								"<span>安装照片</span>"+
							"</li>"+
							"<li>"+
								"<img class='proPic' alt='暂未上传' src="+data.voIndependentDetectorPcList[i].otherPhotoUrl+" />"+
								"<span>其他照片</span>"+
							"</li>"+
						"</ul>");
					}
				}

				

				}
				var picNum=$('.proPic').length
				for(var i=0; i<picNum;i++)
				{
					if($('.proPic').eq(i).attr('src').indexOf('/')<0)
					{
						$('.proPic').eq(i).attr('src',dfImg)
						
					}
				}	
			}
		)

		


	}
	refDetailData=(id)=>{
		if(id){
			this.setState({pdfId: id})
			$('#pdfBox').show()
		}else{
			message.error("无检定记录");
		}
    };



	equipmentIdSelect = (k,v) => {
		getModelByAlaramId({alaramId: k}).then(
			response => {
				console.log(k,v,response)
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i]?.devModelName,value:data[i]?.devModelName})
					}
				}
				// data.model=null
				this.state.equipmentName=v.children
				if(data){
					this.setState({data: data})
					this.state.ModalFormParamenter.parameter[2].data=selectArry;
				}

			}
		)
	}
	onChangeSelect = (k,v) => {
		getCustomerOne({id: k}).then(
			response => {
				const {data} = response.data
				let selectData=this.state
				selectData.customerId=data.id
				selectData.entrustUnit=data.customerUnit
				selectData.principal=data.principal
				selectData.phone=data.principalPhone
				selectData.province=data.province
				selectData.city=data.city
				selectData.district=data.district
				selectData.details=data.details
				this.setState({data: selectData})
				this.forms.onModalClose()
			}
		)
	}
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-'
		switch (value) {
			case 'edit':
				text = rowData.state;
				return <>
					<div>
					<div className={'tableLink'} title={'查看详情'} onClick={() => this.refDetailData(rowData.verId)}>
						<u>{'查看详情'}</u>
					</div>
					</div>
				</>
		default:
		return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	}
	test = (value,text,rowData) => {
		console.log(value)
	}
	getData = id => {
		getDev({id: id,page:1,size:3}).then(
				response => {
					const {data} = response.data;
					this.setState({data: data});
					// this.forms.onModalClose()
				}
			)
	};

	//创建委托单
    onSave = async () => {
		const {id,copy} = this.props.location?.state ?? {};
		console.log(this.state.voGasAlarmRecordList)
		let user = JSON.parse(sessionStorage.getItem("user"))
		let values = await this.forms.onFinishNotReset();
		values.creator=user.realName
		values.voGasAlarmRecordList=this.state.voGasAlarmRecordList
		values.id=id
    	if(copy==true){
			values.id=null
			copyEntrustInfo(values).then(data=>{
				if(data.data===true){
					funTraceInfo("报警器检定管理", "委托单创建", null,values)
					message.success("创建成功");

				}else {
					message.success("创建失败");
					this.table.refreshTable();// 刷新
				}
				this.props.history.go(-1)
			})
		}else{
		updateEntrust(values).then(data=>{
			if(data.data.data===true){
				funTraceInfo("报警器检定管理", "委托单修改", null,values)
				message.success("创建成功");
				this.table.refreshTable();// 刷新
			}else {
				message.error("创建失败");
				this.table.refreshTable();// 刷新
			}
			this.props.history.go(-1)
		})
		}

    };
	//添加设备窗口显示
	addQrcodeStatus=(devVisible)=>{
        this.setState({devVisible});
    };
	addGasAlarmData = async (value)=>{
		const {id} = this.props.location?.state ?? {};
		let devData = await this.forms.onFinishNotReset()
		if(!devData) return null
		devData.entrustId=id
		devData.equipmentName=this.state.equipmentName

		getAlarmByStratNumber({startNumber:devData.startNumber,entrustId:id}).then(response=>{
			console.log(response);
				if(response.data.data===true){
					addGasAlarmRecord(devData).then(data=>{
						console.log(data);
						if(data?.data===true){
							funTraceInfo("报警器检定管理", "添加报警器记录", null,devData)
							message.config("添加成功")
							this.addQrcodeStatus(false)
						}else{
							this.addQrcodeStatus(false)
							message.config('添加失败')

						}
						this.table.refreshTable();// 刷新表格
					});
				}else{
					// this.addDevStatus(false);
					message.success('该出厂编号已存在！');
					this.table.refreshTable();// 刷新
				}
		});



		await this.forms.onModalClose();
	};

	// 审核
	onShSave = async (value)=>{
		let user = JSON.parse(sessionStorage.getItem("user"))
		const {id} = this.props.location?.state ?? {};
		let shData = await this.shforms.onFinishNotReset();
		if(!shData) return null
		shData.id=id
		shData.creator=user.realName
		shData.state=this.state.status
		console.log(shData)
		this.setState({onSaveVisible:false});
		$('.loadBox').show();
		verificationCollectionSheet(shData).then(data=>{
			console.log(data);
			if(data.data.data==true){
				$('.loadBox').hide();
				funTraceInfo("报警器检定管理", "审核", null,shData)
				message.success("操作成功");
				this.props.history.go(-1)
			}else{
				$('.loadBox').hide();
				message.error('操作失败');
				this.props.history.go(-1)
			}
		});


	};

	onSaveStatus=(onSaveVisible,value)=>{
		this.setState({onSaveVisible:onSaveVisible,status:value});
	};
	closeAltBox=(onSaveVisible)=>{
		this.setState({onSaveVisible:onSaveVisible});
	};


	//添加设备窗口显示
    clear = (e, info) => {
		let {formParamenter} = this.state;
		this.setState({dataKey: e});
		this.setState({formParamenter})
    }






    render() {

		const {dataKey} = this.state;

        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'collectionDetailBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff',padding: '30px 1%'}}>
					<div style={{width: '60%',float:'left',position:'relative',height:'100%'}}>
						<div className={'topTitleBox'}>
							<h3 style={{marginBottom:'0',position:'relative',zIndex:'999'}}>检定数据</h3>
						</div>
						<div style={{clear:'both',padding:'0 10px',top:'15px',position:'relative'}}>
							<TableComponents
							paramenter={this.state.tableParamenter}
							setData = {getDev}
							setTableValue={this.setTableValue}
							ref={e => this.table = e}
						>
							</TableComponents>
						</div>


						<div className={'leftFormBox'} style={{position:'relative',height:'75%',overflowY:'auto',overflowX:'hidden'}}>

							<embed
									id='pdfBox'
									src={downIp+'/verification/pdfdownload/previewByAppRecordId?id='+this.state.pdfId}
									type="application/pdf"
									height={'94%'}
									width={'96%'}
									style={{margin:'20px 1% 0 1%',display:'none'}}
							/>

						</div>



					</div>



					<div className={'allDataBox'} style={{width: '40%',float:'right',position:'relative',height:'100%',overflow:'auto'}}>
						<div className={'topTitleBox'}>
							<h3>委托单基础数据</h3>
							<div style={{float:'right'}}>
							{/* <Button style={{margin:'0 0 0 20px'}} type="primary" onClick={()=>{this.onSave()}}>保存</Button> */}
							<Button style={{margin:'0 0 0 20px'}} onClick={() => this.props.history.go(-1)}>返回</Button>
							</div>
						</div>
						<div className={'picAndTextBox'} style={{clear:'both',padding:'0 10px'}}>
							<div className={'picAndTextTop'}>
								<p>委托单位：{this.state.rightData.entrustUnit}</p>
								<p><span>委托人：{this.state.rightData.customerName}</span><span>联系电话：{this.state.rightData.phone}</span></p>
								<p><span>有无切断阀：{this.state.rightData.valveSwitch}</span><span>开票选择：{this.state.rightData.invoiceSelection}</span><span>探测器数量：{this.state.rightData.verificationQuantity}</span></p>
								<p>客户地址：{this.state.rightData.entrustUnit}</p>
							</div>
							<h4>门店照片</h4>
							<ul  className={'picAndTextList'}>
								<li>
									<img alt="暂未上传" src={this.state.rightData.doorHeadUrl?this.state.rightData.doorHeadUrl:dfImg} />
									<span>门店照片</span>
								</li>
								<li>
									<img alt="暂未上传" src={this.state.rightData.environmentalUrl?this.state.rightData.environmentalUrl:dfImg} />
									<span>环境照片</span>
								</li>
								<li>
									<img alt="暂未上传" src={this.state.rightData.licenseUrl?this.state.rightData.licenseUrl:dfImg} />
									<span>执照照片</span>
								</li>
								<li>
									<img alt="暂未上传" src={this.state.rightData.otherPhotoUrl?this.state.rightData.otherPhotoUrl:dfImg} />
									<span>其他照片</span>
								</li>
							</ul>
							<h4>单据照片</h4>
							<ul  className={'picAndTextList'}>
								<li>
									<img alt="暂未上传" src={this.state.rightData.agreementUrl?this.state.rightData.agreementUrl:dfImg}/>
									<span>协议单照片</span>
								</li>
								<li>
									<img alt="暂未上传" src={this.state.rightData.entrustmentFormUrl?this.state.rightData.entrustmentFormUrl:dfImg} />
									<span>委托单照片</span>
								</li>
								<li>
									<img alt="暂未上传" src={this.state.rightData.otherPhotosUrl?this.state.rightData.otherPhotosUrl:dfImg} />
									<span>其他照片</span>
								</li>
							</ul>
							<div id='kzBox'>

							</div>

							<div id='dlBox'>

							</div>

							{/* <h4>探测器照片</h4>
							<div className={'picAndTextInnerTop'}>
								<p><span>编号：9527</span><span>出厂编号：00798874</span><span>制造厂家：汉德史特</span><span>路号：18路</span></p>
							</div>
							<ul  className={'picAndTextList'}>
								<li>
									<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />
									<span>设备照片</span>
								</li>
								<li>
									<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />
									<span>环境照片</span>
								</li>
								<li>
									<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />
									<span>安装照片</span>
								</li>
								<li>
									<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />
									<span>其他照片</span>
								</li>
							</ul> */}
							<div style={{clear:'both',padding:'10px'}}>
								<FormsTwo
									ref={e => this.shforms = e}
									formParamenter={this.state.formShParamenter}
									data={this.state.data}
								/>
							</div>
							<div id='btnBox' style={{clear:'both',padding:'0 10px',textAlign:'center'}}>
								<Button type="primary" onClick={() => this.onSaveStatus(true,'已通过')}>通过</Button>
								<Button style={{background:'#ff0000',borderColor:'#ff0000',marginLeft:'20px'}} type="primary" onClick={() => this.onSaveStatus(true,'驳回')}>驳回</Button>
							</div>
						</div>
					</div>

					<Modal
                        title="提示"
                        style={{ top: 0 }}
                        visible={this.state.onSaveVisible}
                        okText="确定"
                        onOk={() => this.onShSave(false)}           // 点击确定
                        onCancel={() => this.closeAltBox(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认此操作吗？</h4>
                        </div>
                	</Modal>
					


				</div>


				<div className={'loadBox'}>
					<div className={'loadInner'}></div>
					<div className={'loadIcon'}>
						<Spin></Spin>
						<p>加载中...</p>
					</div>
				</div>


            </Layout>
        )
    }
}
