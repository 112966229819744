import ajax from './ajax'


// 委托单管理-新建委托单
export const test = data => ajax('/verification/entrust/management/test', data, 'GET');


// 委托单管理-查看委托单
export const getEntrustList = data => ajax('/verification/entrust/management/getEntrustList', data, 'POST');
// 委托单管理-新建委托单
export const insertEntrust = data => ajax('/verification/entrust/management/insertEntrust', data, 'POST');
// 委托单管理-复制
export const copyEntrustInfo = data => ajax('/verification/entrust/management/copyEntrustInfo', data, 'POST');
// 委托单管理-查看指定的委托单
export const getVerificationRecordById = data => ajax('/verification/entrust/management/getVerificationRecordById', data, 'GET');
// 委托单管理-创建委托单-查询临时检定报警器列表-无分页
export const getTemporaryList = data => ajax('/verification/entrust/management/getTemporaryList', data, 'GET');
// 委托单管理-创建委托单-添加临时检定报警器
export const addAlarmInfo = data => ajax('/verification/entrust/management/addAlarmInfo', data, 'POST');
// 委托单管理-创建委托单-删除指定单个临时检定报警器
export const delTemporaryAlarm = data => ajax('/verification/entrust/management/delTemporaryAlarm', data, 'GET');
// 委托单管理-创建委托单-删除所有临时检定报警器列表-清空操作
export const allDelTemporaryAlarm = data => ajax('/verification/entrust/management/allDelTemporaryAlarm', data, 'GET');
// 委托单管理-修改委托单
export const updateEntrust = data => ajax('/verification/entrust/management/updateEntrust', data, 'POST');


// 委托单管理-检查出厂编号是否唯一
export const getAlarmByStratNumber = data => ajax('/verification/alarmVerificationRecord/management/getAlarmByStratNumber', data, 'GET');



// 委托单管理-报警器检定记录管理页面-添加报警器记录
export const addGasAlarmRecord = data => ajax('/verification/alarmVerificationRecord/management/addGasAlarmRecord', data, 'POST');

// 委托单管理-检定
export const gasAlarmVerifierRecord = data => ajax('/verification/alarmVerificationRecord/management/gasAlarmVerifierRecord', data, 'POST');
// 委托单管理-核验
export const checkVerifierRecord = data => ajax('/verification/alarmVerificationRecord/management/checkVerifierRecord', data, 'POST');

// 委托单管理-获取检定详情
export const AlarmRecordInGasId = data => ajax('/verification/alarmVerificationRecord/management/AlarmRecordInGasId', data, 'GET');

// 委托单管理-检定作废
export const AlarmRecordInInvalid = data => ajax('/verification/alarmVerificationRecord/management/AlarmRecordInInvalid', data, 'GET');



// 委托单管理-委托单作废
export const cancellationEntrust = data => ajax('/verification/entrust/management/cancellationEntrust', data, 'GET');

// 委托单管理-委托单结单
export const getEntrustInvalid = data => ajax('/verification/entrust/management/getEntrustInvalid', data, 'GET');


// 委托单管理-删除委托单
export const removeEntrust = data => ajax('/verification/entrust/management/removeEntrust', data, 'GET');


// 委托单管理-删除设备
export const removeAlarm = data => ajax('/verification/alarmVerificationRecord/management/removeAlarm', data, 'GET');








//  气瓶管理页面-查询气瓶数据
export const getCylinderList = data => ajax('/verification/cylinder/management/getCylinderList', data, 'POST');
//  气瓶管理页面-添加气瓶数据
export const insertCylinder = data => ajax('/verification/cylinder/management/insertCylinder', data, 'POST');
// 气瓶管理页面-查询单个数据
export const getOneCylinder = data => ajax('/verification/cylinder/management/getOneCylinder', data, 'GET');
// 气瓶管理页面-查询单个数据
export const getTemporaryGasCylinderList1 = data => ajax('/verification/cylinder/management/getTemporaryGasCylinderList?type=1', data, 'POST');
export const getTemporaryGasCylinderList2 = data => ajax('/verification/cylinder/management/getTemporaryGasCylinderList?type=2', data, 'POST');


// const userId=JSON.parse(sessionStorage.getItem("user")).id;
export const getTemporaryGasCylinderList1_1 = data => ajax('/verification/cylinder/management/getTemporaryGasCylinderList?type=1', data, 'GET');
export const getTemporaryGasCylinderList2_1 = data => ajax('/verification/cylinder/management/getTemporaryGasCylinderList?type=2', data, 'GET');
// 气瓶管理页面-配置气瓶信息
export const updateCylinder = data => ajax('/verification/cylinder/management/updateCylinder', data, 'POST')
// 气瓶管理页面-删除配置气瓶信息
export const deleteTemporaryGasCylinder = data => ajax('/verification/cylinder/management/deleteTemporaryGasCylinder', data, 'POST')

// 气瓶管理页面-查询单个数据
export const getNewTemporaryGasCylinderList1 = data => ajax('/verification/cylinder/management/getNewTemporaryGasCylinderList', data, 'GET')
export const getNewTemporaryGasCylinderList2 = data => ajax('/verification/cylinder/management/getNewTemporaryGasCylinderList', data, 'GET')




// 客户管理页面-查看客户信息
export const getCustomerList = data => ajax('/verification/customer/management/getCustomerList', data, 'POST');
// 客户管理页面-查看指定客户信息
export const getCustomerOne = data => ajax('/verification/customer/management/getCustomerOne', data, 'GET');
// 客户管理页面-添加客户信息
export const insertCustomer = data => ajax('/verification/customer/management/insertCustomer', data, 'POST');
// 客户管理页面-查看无分页客户信息
export const getCustomer = data => ajax('/verification/customer/management/getCustomer', data, 'POST');


// 燃气报警器-查询报警器数据
export const getAlarmList = data => ajax('/verification/alarm/management/getAlarmList', data, 'POST');
// 燃气报警器-查询单个数据
export const getOneAlarm = data => ajax('/verification/alarm/management/getOneAlarm', data, 'GET');

// 燃气报警器-添加报警器数据
export const insertAlarm = data => ajax('/verification/alarm/management/insertAlarm', data, 'POST');

// 燃气报警器-检测记录
export const getVerificationRecordByAlarmId = data => ajax('/verification/alarm/management/getVerificationRecordByAlarmId', data, 'POST');

// 燃气报警器-所有报警器名称
export const getEquipmentName = data => ajax('/verification/alarm/management/getEquipmentName', data, 'GET');

// 燃气报警器-根据报警器ID查型号
export const getModelByAlaramId = data => ajax('/verification/alarm/management/getModelByAlaramId', data, 'GET');



// 证书管理页面-查询委托单
export const getCMEntrustList = data => ajax('/verification/certificate/management/getEntrustList', data, 'POST');
// 证书管理页面-查询证书列表
export const getCertificateInfoByEntrustId = data => ajax('/verification/certificate/management/getCertificateInfoByEntrustId', data, 'POST');
// 证书管理页面-查询证书列表-1
export const getCertificateList = data => ajax('/verification/certificate/management/getCertificateList', data, 'POST');


// 证书管理页面-审核
export const certificateApproval = data => ajax('/verification/certificate/management/certificateApproval', data, 'POST');


//采集单
export const getAcquisitionSheetList = data => ajax('/verification/acquisitionSheetPc/management/getAcquisitionSheetList', data, 'POST');

export const getOneAcquisitionSheet = data => ajax('/verification/acquisitionSheetPc/management/getOneAcquisitionSheet', data, 'GET');


export const getDev = data => ajax('/verification/acquisitionSheetPc/management/getDev', data, 'POST');

export const getVerificationRecordByDevId = data => ajax('/verification/acquisitionSheetPc/management/getVerificationRecordByDevId', data, 'GET');


export const verificationCollectionSheet = data => ajax('/verification/acquisitionSheetPc/management/verificationCollectionSheet', data, 'POST');







// 计算重复性
export const getRepeatability = data => ajax('/verification/result/management/getRepeatability', data, 'POST');


// 计算零点漂移
export const getZeroWander = data => ajax('/verification/result/management/getZeroWander', data, 'POST');
// 计算量程漂移
export const getMileageDrift = data => ajax('/verification/result/management/getMileageDrift', data, 'POST');


// 示值误差
export const getIndicationError = data => ajax('/verification/result/management/getIndicationError', data, 'POST');

















// 分页查询表具信息和该表具下的设备信息 ---> 根据计量点名称查询
export const pageLikeMpName = data => ajax('/web/mpCtrl/pageLikeMpName', data, 'POST')

// 分页查询表具---表钢号
export const getDeviceNum = data => ajax('/newDevice/device/getVoDevicePaging', data, 'POST')

// 获取设备的所有类型信息
export const getAllDevClass = data => ajax('/newDevice/device/getAllDevClass', data, 'GET')

// 根据设备id获取所有数据项
export const getItemByDevId = data => ajax('/newDevice/device/selectDevInfoAttr', data, 'GET')

// 查询型号下的数据项信息
export const listDevModelInfo = data => ajax('/newDevice/device/listDevModelInfo', data, 'POST')

// 获取类型下的型号信息
export const listDeviceModelByDeviceClassId = data => ajax('/newDevice/device/listDeviceModelByDeviceClassId', data, 'GET')

// 根据设备id查询设备信息
export const selectDevInfoAttr = devId => ajax('/newDevice/device/getDevInfoAndExclusiveAttr', devId, 'GET')

// 新增表具
export const saveDevice = data => ajax('/newDevice/device/addDev', data, 'POST')



// 新增数据项
export const saveDataItem = data => ajax('/newDevice/item/addItem', data, 'POST')

// 根据数据项id查询
export const getOne = data => ajax('/newDevice/item/getItem', data, 'GET')


// 新增设备
export const addDev = data =>ajax('/devices/device/addDevClass', data, 'POST');


// 图片上传
export const addOss = data =>ajax('/oss/ossManagement/ossUpload', data, 'POST');


// 查看证书的所有报警器
export const getCertificateInfo = data => ajax('/verification/certificate/management/getCertificateInfo', data, 'POST');
// 查看证书的所有报警器-新
export const getNewCertificateInfo = data => ajax('/verification/certificate/management/getNewCertificateInfo', data, 'POST');
