import React, { Component } from 'react'
import { Layout,Modal,Radio,message,Tag,Button} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import moment from 'moment'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,EditOutlined} from '@ant-design/icons';
import FormsTwo from '../../../components/common/forms/FormsTwo'
import * as echarts from 'echarts/lib/echarts';
import $ from 'jquery'
// 引入柱状图（这里放你需要使用的echarts类型 很重要）
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/pie';
import  'echarts/lib/chart/line';
import { TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent } from 'echarts/components';
import {areaGasLossAnalysis,getDataIgnoreLevel,tempPreComparisonCurve,listDevByAreaIds} from '../../../services/steamReport'
import {getDateByUserId} from "../../../services/admin";
import {getDevList} from "../../../services/customerDataPanel";

echarts.use([TitleComponent,TooltipComponent,LegendComponent,ToolboxComponent,GridComponent]);
var myChart;


export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: false,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			// 弹出框
			headers: {
				areaName: '区域名称',
				currentGasLoss: '本级气损',
				currentGasLossReta: '本级气损率',
				dataTime: '时间',
				subTable: '子表用量',
				summary: '总表用量',
			},
			params: {
				areaIds:[289],
				granularity:4,
				startTime:'',
				endTime:'',
				itemIdList:[1205,1225,1206],
				page:1,
				size:100,
		},
			// 是否绑定左侧区域
			bindArea: false,
		},
		// 条件选择器参数
		conditionsParamenter: {
			parameter: []
		},
		topFormItem: {
			modal: true,
			number: 1,
            labelCol: 5,
			layout: "horizontal",
			parameter: [
				// {
				// 	variableName: 'areaIds',
				// 	str: '区域选择',
				// 	fieldNames:{label: 'name', value: 'id', children: 'children'},
				// 	rules: TYPE_NAME.TREE_SELECT,
				// 	disabled: this.props?.location?.state ? true : false,
				// 	data:[],
				// 	onChange: (k,v) => this.getAllDev(k,v)
				// },
				// {
				// 	variableName: 'devIds',
				// 	str: '设备选择',
				// 	// isMultiple:true,
                //     disabled: false,
				// 	data:[],
				// 	rules: TYPE_NAME.SELECTINPUT,
				// },
				{
					variableName: 'createTime',
					str: '时间范围',
					rules: TYPE_NAME.RANGE_REQ_DATE_PICKER,
				},

			]
		},
		searchGetData:{},
		totalCount: 0,// 总条数

		devIds:[],
	};
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'edit':
				return <>
				<div className={'tableLink'} title={'修改'} onClick={() => this.editQrCodeStatus(true,rowData.id,rowData.code)}>
						<u>{'修改'}</u>
				</div>
				</>;
			case 'type':
				return text === 'F' ? '防盗铅封二维码' : text === 2 ? '防盗铅封二维码' : '';
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : '';
		}
	};
	updatePoint = (devVisible) => {

		let rowData = this.table.getSelectChange();
		if(rowData.length < 1){
			return message.error('未选择数据')
		}else{
			this.setState({devVisible});
		}
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}

	};
	componentDidMount(){
		this.getDevList();// 获取设备数据
		let user = JSON.parse(sessionStorage.getItem("user"));
		getDateByUserId({userId:JSON.parse(sessionStorage.getItem("user"))?.id}).then(
			response => {
				if (response?.data) {
					console.log(response?.data);
					this.state.topFormItem.parameter[0].data=response?.data;
				} else {
					return message.info('获取不到数据');
				}
			}
		)
	}

	getAllDev = (areaIds) => {
		var treeAreaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,areaIds,treeAreaIds);
		listDevByAreaIds({
			// areaIds:[areaIds]
			areaIds:treeAreaIds
		}).then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].devId,value:data[i].devName})
					}
				}
				this.state.topFormItem.parameter[1].data=selectArry;
			}
		)
	};
	forTreeArea = (data,key,areaIds=[]) =>{
		data.map(one=>{
			if(one.id===key){
				areaIds.push(one.id);
				if(one?.children){
					this.addAllTreeArea(one?.children,areaIds);
				}
			}else if(one?.children){
				this.forTreeArea(one?.children,key,areaIds);
			}
		});
		return areaIds;
	};
	addAllTreeArea = (data,areaIds=[]) =>{
		data.map(one=>{
			areaIds.push(one.id);
			if(one?.children){
				this.addAllTreeArea(one?.children,areaIds);
			}
		});
		return areaIds;
	};

	onChangeSelect = e => {
		console.log(111,e)
	};
	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data = await this.forms.onFinishNotReset();
		if(!data) return null;
		let sTime=data.createTime ?moment(data.createTime[0]._d).format("yyyy-MM-DD 00:00:00") : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d).format("yyyy-MM-DD 00:00:00") : null;
		let params = this.state.tableParamenter.params;
		Object.assign(params,value);
		params.areaIds=[];
		this.forTreeArea(this.state.topFormItem.parameter[0].data,data.areaIds,params.areaIds);
		// params.areaIds=[data.areaIds]
		params.devIds=this.state.devIds;
		params.granularity=4;
		if(sTime){
			params.startTime=sTime;
		}
		if(eTime){
			params.endTime=eTime;
		}
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		this.setState({tableParamenter});
		console.log(params);
		tempPreComparisonCurve(this.state.tableParamenter.params).then(
			response => {
				const {data} = response.data;
				let tArry=[];
				// let cArry=[];
				// let mArry=[];
				let pArry=[];
				let x=[];
				let t=[];
				// let c=[];
				// let m=[];
				let p=[];
				if(data){
					x=data[0].xaxis;
					t=data[0].tempList;
					// c=data[0].instFlowList;
					// m=data[0].densityList;
					p=data[0].preList;
					tArry = t.map((item) => {return Number(item);});
					// cArry = c.map((item) => {
					// 	return Number(item);
					//   });
					// mArry = m.map((item) => {
					// 	return Number(item);
					//   });
					pArry = p.map((item) => {return Number(item);});
					$('#noMsg').hide();
					$('#chartBox').show();
					// this.getPie('pie1',x,cArry,'瞬时流量');
					this.getPie('pie2',x,tArry,'温度');
					// this.getPie('pie3',x,mArry,'密度');
					this.getPie('pie4',x,pArry,'压力');
				}else{
					$('#noMsg').text('暂无数据');
				}
			});
		this.elementById('elementById');
	};

	getPie =  (elmName,echartArryX,echartArryY,echartName) => {
		// 基于准备好的dom，初始化echarts实例
		myChart = echarts.init(document.getElementById(elmName));
		// 绘制图表
		myChart.setOption({
			tooltip: {
				trigger: 'axis',
			  },

			  legend: {
				data: [echartName]
			  },
			  xAxis: [
				{
				  type: 'category',
				  data: echartArryX,
				  axisPointer: {
					type: 'shadow'
				  }
				}
			  ],
			  grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true
			  },
			  yAxis: {
				type: 'value'
			  },
			  series: [
				{
				  name: echartName,
				  type: 'line',
				//   tooltip: {
				// 	valueFormatter: function (value) {
				// 	  return value + ' 吨';
				// 	}
				//   },
				  data: echartArryY
				}
			  ]
		});
	};

    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state;
		  params.areaId = data;
		  params.page = 1;
		  params.size = initParams.size;
		  this.setState({params});
		  this.getData();
		})
	  };
    // 初始化表格数据
	getData = () => {
		this.setState({});
		this.table.refreshTable();
	};

	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	   this.setState({params:params});
	   this.getData();
	};

	elementById= (elmName) =>{
		var series=[];
		// 处理单挑数据拼接
		this.props?.data?.map(
			one=>{
				series.push(
					{
						name: one.name,
						type: 'bar',
						// stack: 'a',
						emphasis: {
							focus: 'series'
						},
						data: one.data,
						itemStyle:{
							normal:{
								color:new echarts.graphic.LinearGradient(0,0,0,1,
									[
										{offset:0,color:one.color[0]},
										{offset:1,color:one.color[1]},
									])}}},)}
		);

			myChart = echarts.init(document.getElementById(elmName));

		// var chartDom = echarts.init(document.getElementById('DetailedReport_1') );

		myChart.setOption(
			{
				//标题
				title: {
					text: this.props.data[0].name||null,
					textStyle:{
						color:'#e5e5e5',
						fontWeight:'normal',
						fontSize:'14px'
					},
					left:'44%',
					top:'85%'

				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					top:'10%',
					left: '12%',
					right: '10%',
					bottom: '10%',
					containLabel: true
				},
				xAxis: {
					data:this.props?.yData,
					type: 'category',
				},
				yAxis: {
					type: 'value',
					data:this.props?.yData
				},
				series:series
			}
		);
	};

	getDevList=()=>{
		var param={
			"customerId": this.props?.customerId,
			"devType": "表具",
			"page": 0,
			"size": 10
		};
		getDevList(param).then(
			response=>{
				if(response?.data?.data){
					var data = response?.data?.data;
					var devIds = this.state.devIds;
					data.map(one=>{devIds.push(one.devId)});
					this.setState({devIds})
				}
			}
		)

	};

    render() {
        return (
			<div className={'collectionDetailBox'} style={{width: '100%',height: '100%',backgroundColor: '#ffffff'}}>


				<div style={{width:'100%',height:'40px'}}>

					<div style={{width:'8%',height:'100%',float:"left"}}>
						<span style={{fontSize:'21px',fontWeight: 'bold'}}>明细分析</span>
					</div>
					<div style={{width:'85%',height:'100%',float:"left"}}>
						<div style={{width:'45%',float:"left"}}>
							<FormsTwo
								ref={e => this.forms = e}
								formParamenter={this.state.topFormItem}
								data={this.state.searchGetData}
							/>
						</div>
						<div style={{width:'20%',float:"left"}}>
							<Button style={{right:'0',top:'0'}} type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						</div>

					</div>
				</div>
				<div id={'elementById'} style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'98%'}}/>
				<div id='noMsg' style={{fontSize:'30px',lineHeight:'100px',textAlign:'center',color:'#ccc'}}>请先选择区域，设备及时间范围</div>

				<div id='chartBox' style={{display:'none'}}>
					<div id="pie2" style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'48%',float:'left'}}/>
					<div id="pie4" style={{height: '300px',background:'#fff', margin:'30px 1% 0 1%',width:'48%',float:'left'}}/>
				</div>

			</div>
        )
    }
}
