import React, { Component } from 'react'
import { Layout, Tabs, message, Modal } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';

import FormsTwo from '../../../components/common/forms/FormsTwo'
import {funTraceInfo} from '../../../services/common'
import { TYPE_NAME } from '../../../components/Const'

import { saveMp, getMpByName, getOne } from '../../../services/meter'

export default class OfflineMessage extends Component {

    state = {
        // 表单参数
        formParamenter: {
            modal: true,
            number: 4,
            labelCol: 6,
            layout: "horizontal",
            parameter: [
                {
                    variableName: 'programName',
                    str: '程序名称',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'programMailbox',
                    str: '程序邮箱',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'messageContent',
                    str: '消息内容',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'messageTitle',
                    str: '消息标题',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'messageTime',
                    str: '消息时间',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'messageUserName',
                    str: '用户名称',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'telephone',
                    str: '电话号码',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },

                {
                    variableName: 'receiptTime',
                    str: '接收时间',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'sendTime',
                    str: '发送时间',
                    rules: TYPE_NAME.INPUT,
                    disabled: true
                },
                {
                    variableName: 'sendState',
                    str: '发送状态',
                    rules: TYPE_NAME.INPUT,
                     disabled: true

                },
            ]
        },
        data: {}
    }

    async componentDidMount() {
        const { name, id } = this.props.location.state ?? {}
        if (name) {
            this.mpName.state.value = name
            this.getData(id)
        }
    }

    onSave = async () => {
        let value = await this.forms.onFinish()
        if (!value) return null
        value.meterId = this.state.data?.meterId
        value.locationId = this.state.data?.locationId
        value.mpAreaId = localStorage.getItem('menus');
        value.operator= JSON.parse(sessionStorage.getItem("user")).username;
        Modal.confirm({
            title: `是否绑定到${localStorage.getItem('areaName')}?`,
            onOk: () => {
                // 新增计量点
                saveMp(value).then(
                    response => {
                        const info = this.props.location.state ? '修改' : '新增'
                        response.data.data ? message.success(info + '成功') : message.error(info + '失败')
                        funTraceInfo("报警管理", "新增(修改)离线消息", null,value)
                    }
                )
            }
        })
    }

    clear = e => {
        let { data } = this.state
        if (e !== 2 || Object.keys(data).length === 0) {
            this.setState({ data: {} })
            setTimeout(() => this.forms.onModalClose(), 0)
        }
    }

    getData = id => {
        if (id) {
            getOne({ mpId: id }).then(
                response => {
                    const { data } = response.data
                    this.setState({ data: data })
                    message.success('查询成功')
                    this.forms.onModalClose()
                }
            )
        } else {
            let name = this.mpName.state.value
            getMpByName({ name }).then(
                response => {
                    const { data } = response.data
                    if (data?.length > 1) {
                        message.warning('查询出多个计量点')
                    } else {
                        this.setState({ data: data })
                        message.success('查询成功')
                        this.forms.onModalClose()
                    }
                }
            )
        }
    }

    render() {

        const { dataKey } = this.props.location.state ?? []

        return (
            <Layout className={'item_body_number_back'}>
                <div className={'div_back_arrow'}>
                    <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回</span>
                </div>
                <div style={{ width: '100%', height: '100%', backgroundColor: '#ffffff', padding: '0 1%' }}>
                    <Tabs defaultActiveKey={dataKey ?? "2"} onChange={e => this.clear(e)} tabPosition={'top'} centered={true} style={{ marginTop: '0.5vh' }}>
                    </Tabs>
                    <div>
                        <FormsTwo
                            ref={e => this.forms = e}
                            formParamenter={this.state.formParamenter}
                            data={this.props.location.state ?? {}}
                        />
                        {/* <div className={'div_button_center'}>
							<Button type="primary" onClick={this.onSave}>保存</Button>
							&nbsp;&nbsp;&nbsp;
							<Button type="primary" onClick={this.clear}>清空</Button>
						</div> */}
                    </div>
                </div>

            </Layout>
        )
    }
}
