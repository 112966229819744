import React from 'react';

import * as echarts from 'echarts';
// 圆角环形图
class HalfDoughnutChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        var series=[];
        this.props?.data?.dataS.map(
            one=>{
                series.push(
                    {
                        value: one?.value,
                        name: one?.name,
                        itemStyle: {
                            normal: {
                                color: one?.color, //改变折线点的颜色
                                    // lineStyle: {
                                    //     color: "#ff7752", //改变折线颜色
                                    // },
                            },
                        },
                    },
                )
            }
        );

        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        chartDom.setOption(
            {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '0%',
                    left: 'center'
                },
                series: [
                    {
                        // name: 'Access From',
                        type: 'pie',
                        radius: ['20%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 4,
                            borderColor: '#fff',
                            borderWidth: 1
                        },

                        data: series
                    }
                ]
            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}/>
        );
    }
}
export default HalfDoughnutChart;
