import React from 'react';

import * as echarts from 'echarts';
// 条形图
class BarChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        var series=[];
        // 处理单挑数据拼接
        this.props?.data?.map(
            one=>{
                series.push(
                    {
                        name: one.name,
                        type: 'line',
                        // stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: one.data,
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(1,0,0,0,
                                    [
                                        {offset:0,color:one.color[0]},
                                        {offset:0,color:one.color[1]},
                                    ])
                            }
                        }

                    },

                )
            }
        );
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                // 标题
                title: {
                    text: this.props.name||null,
                    textStyle:{
                        color:'#e5e5e5',
                        fontWeight:'normal',
                        fontSize:'14px'
                    },
                    left:'40%',
                    top:'85%'

                },

                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'

                    }
                },
                legend: {
                    textStyle:{
                        color:'#fff',
                        fontWeight: 'inherit',
                    }
                },
                grid: {
                    top:'28%',
                    left: '10%',
                    right: '14%',
                    bottom: '0%',
                    containLabel: false // 不包含标签区域
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    data:this.props?.yData,
                    type: 'category',

                },
                yAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    data:this.props?.yData,
                    // splitLine: {
                    //     lineStyle: {
                    //         // 设置背景横线
                    //         color: '#f5f7fa',
                    //     }
                    // },

                },
                series:series
                // series: [
                //     {
                //         name: '燃气漏气',
                //         type: 'line',
                //         stack: 'a',
                //         emphasis: {
                //             focus: 'series'
                //         },
                //         data: [32, 33, 30, 33, 39, 33, 39],
                //         itemStyle:{
                //             normal:{
                //                 color:new echarts.graphic.LinearGradient(1,0,0,0,
                //                     [
                //                         {offset:0,color:'#4d76ff'},
                //                         {offset:0,color:'#6df5ff'},
                //                     ])
                //             }
                //         }
                //
                //     },
                //
                // ]
            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
