import ajax from './ajax'

// 查询所有区域信息
export const treeNodeAll = data => ajax('/web/treeCtrl/getAll', data, 'GET')

// 查询指定id的区域信息
export const getTreeByNodeIds = data => ajax('/web/treeCtrl/getTreeByNodeIds', data, 'POST')

//获取该id下的子节点
export const getChildNodesById = (data) => ajax('/web/treeCtrl/getChildNodesById', (data), 'GET')



