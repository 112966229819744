import React, { Component } from 'react'
import { Layout,Select ,message,Form} from 'antd'
import {funTraceInfo} from '../../../services/common'
import Tables from '../../../components/common/tables/Tables'
import Conditions from '../../../components/common/conditions/Conditions'
import {getMarkData ,getLikeData,getTraceSources,getOperationNames,getOperatorNames,getDatas} from '../../../services/mark'
import moment from "moment";
import {TYPE_NAME} from '../../../components/Const'
const { Option } = Select
const FormItem = Form.Item
export default class WorkOrder extends Component{
    state = {
		// 表格参数
		tableParamenter: {
            rowSelection: false,
            // title: '痕迹记录查询',
			// 表头：变量和变量解释
			header: {		
				id : '工单ID',
				type : '工单类型',
				location : '地址',
				content : '内容',
                state : '状态',
                level : '等级',
                createTime : '创建时间',
                responseDateTime : '响应时间',
                completeDateTime : '完成时间',
				requiredResponseDateTime : '要求响应时间',
                deadLine : '要求完成时间',
                
			},
			// 下拉框：变量和变量解释
			headers: {
				id : '工单ID',
				type : '工单类型',
				location : '地址',
				content : '内容',
                state : '状态',
                level : '等级',
                createTime : '创建时间',
                responseDateTime : '响应时间',
                completeDateTime : '完成时间',
				requiredResponseDateTime : '要求响应时间',
                deadLine : '要求完成时间',
				
			},
			// 表格数据
			data: [],
		},		
        // 条件选择器参数
        conditionsParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            parameter: [
                {
                    type: 'rangePicker',
                    variableName: 'createTime',
                    str: '创建时间',
                    time:true
                },
            ]
        },
		// 总条数
		totalCount: 0,
        pageinfo:{
            pageIndex:1,
            pageLine:10,
        },
         // 参数-后端
        params: {
            operatorName: null,          // 订单号
            traceSource :null,          //
            operationName:null,
            operationStartTime:null,
            operationEndTime:null
        },
         // 表格加载动画
         loading: false,
         // 刷新动画
         refreshTable: false,
        keyes:[],
        listDatas:[],
        manufactureValue: [],
		meterModelValue: [],
        bos:null,

        operatorNames:null,
        operationNamees:null,
        traceValue:null
	}

    componentDidMount() {
        this.getData();

    }


    // 条件选择：获取输入的条件
    getConditionsValue = value => {
        console.log(this.state.meterModelValue) 
        let {params} = this.state;     // 获取两个state的数据管理
        params.operatorName = this.state.operatorNames;
        params.traceSource = this.state.traceValue;
        params.operationName = this.state.operationNamees;
        if(value.operationTime!==null){
            params.operationStartTime = moment(value.operationTime[0]._d).format('YYYY-MM-DD HH:mm:ss');
            params.operationEndTime   = moment(value.operationTime[1]._d).format('YYYY-MM-DD HH:mm:ss');
        }
        this.setState({params ,keyes:value.traceSource});   // 确定修改
        console.log("ssssss",this.state.params)

        let param = {
            operatorName: this.state.operatorNames,
            operationName:this.state.operationNamees,
            operationStartTime:this.state.params.operationStartTime,
            operationEndTime:this.state.params.operationEndTime,
            offset:1,
            limit:10,
            traceSource:this.state.traceValue
          }
          console.log("ssssss",param)
          getLikeData(param).then(
            response => {
              const {data} = response.data 
              console.log('展示的datassssssssssssssss', data)
              let {tableParamenter} = this.state
              tableParamenter.data = data
              const {pageInfo} = response.data 
              let total= pageInfo.total
                
              let {pageinfo} = this.state
              pageinfo.pageIndex = pageInfo.pageIndex
              pageinfo.pageLine = pageInfo.pageLine
            this.setState({
                loading: false,
                refreshTable: false,
                tableParamenter:tableParamenter,
                totalCount:total,
                pageinfo
            });
            message.success('查询成功')
        }
    ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('查询失败')
        }
    )
    
};

    getDatas =()=>{
        console.log("oooooooooo",this.state.pageinfo.pageIndex)
        let param = {
            operatorName: this.state.operatorNames,
            operationName:this.state.operationNamees,
            operationStartTime:this.state.params.operationStartTime,
            operationEndTime:this.state.params.operationEndTime,
            offset:this.state.pageinfo.pageIndex,
            limit:this.state.pageinfo.pageLine,
            traceSource:this.state.traceValue
          }
          console.log("ssssss",param)
          getLikeData(param).then(
            response => {
              const {data} = response.data 
             
              let {tableParamenter} = this.state
              tableParamenter.data = data
              const {pageInfo} = response.data 
             
              let total= pageInfo.total
              let {pageinfo} = this.state
              pageinfo.pageIndex = pageInfo.pageIndex
              pageinfo.pageLine = pageInfo.pageLine
            this.setState({
                loading: false,
                refreshTable: false,
                tableParamenter:tableParamenter,
                totalCount:total,
                pageinfo
            });
            message.success('查询成功')
        }
    ).catch(
        () => {
            this.setState({
                loading: false,
                refreshTable: false,
            });
            message.error('查询失败')
        }
    )
    }

    // 清空条件
    clearCondition = () => {

        console.log("操作人",this.state.operatorNames)
        console.log("操作名称",this.state.operationNamees)
        console.log("痕迹来源",this.state.traceValue)
        this.setState({
            operatorNames:null,
            operationNamees:null,
            traceValue:null
        })
        this.getData();
    }

    getSelect =e =>{
        console.log("ssssssssssss",e)
        this.setState({operatorNames: e})
        getOperatorNames().then(
			response => {
			  let {data} = response.data
			this.setState({listDatas:data})
			}
		  )
    }

    getSelects = ()=>{
        getTraceSources().then(
			response => {
			  let {data} = response.data
			this.setState({
                manufactureValue :data,
            })
			}
		  )     
    }

    getSelectes =e =>{
        this.setState({operationNamees: e})
			}

    getoos =() =>{
                getOperationNames().then(
                    response => {
                      let {data} = response.data
                      this.setState({meterModelValue:data})
                    })
            
                    }

     // 查询表格数据
     getData =()=>{
        this.setState({loading: true});
        getMarkData(this.state.pageinfo).then(
            response=>{
                console.log("response ："+response);
                let {data} = response.data;
                let {tableParamenter} = this.state;
                tableParamenter.data = data;
                const {pageInfo} = response.data 
                console.log('分页111111111111', data);
                let total= pageInfo.total
                this.setState({
                    loading: false,
                    refreshTable: false,
                    tableParamenter:tableParamenter,
                    totalCount:total
                });
                this.getSelect();
                this.getSelects();
                this.getoos();
                message.success('查询成功')
            }
        ).catch(
            () => {
                this.setState({
                    loading: false,
                    refreshTable: false,
                });
                this.getSelect();
                this.getSelects();
                this.getoos();
                message.error('查询失败')

            }
        )
    };

	// 菜单栏: 获取菜单栏选中的值
	getMenusValue = value => {
		console.log('菜单栏选中的值',value)
	}

	// 表格：刷新方法
	refreshTable = () => {
        this.getData();
		console.log('表单刷新了')
	}

	// 表格：导出
	exportTable = () => {
		console.log('数据导出')
	}

	// 获取当前页数和条数
	getPagination = (page, pageSize) => {
        let {pageinfo} = this.state
	    pageinfo.pageIndex = page
		pageinfo.pageLine = pageSize
		this.setState({pageinfo})
        if(this.state.operatorNames == null && this.state.operationNamees == null && this.state.traceValue == null){
            this.getData()  
            console.log("popob")
          }else{
            this.getDatas()
            console.log("popoc")
          }
	}

    getbos=e=>{
       this.setState({traceValue: e})
       let popo={
        traceSource :e,
       }
       getDatas(popo).then(
        response => {
          let {data} = response.data
          console.log("sssss",data)
          this.setState({
              meterModelValue:data,
            operationNamees:null
        })
        })
    }

    improtTable = () => {
        console.log('数据导入')
    }

    render() {


        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div style={{padding: '0.5% 0.5% 0 0.5%'}}>
                    <Conditions 
                        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置
                        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                        clearCondition={this.clearCondition} // 清除条件
                    >

                            <FormItem
							label={"工单类型"}
							rules={TYPE_NAME.REQ_SELECT}
						>
							<Select 
								placeholder={`请选择工单类型`} 
								style={{minWidth: '7vw', maxWidth: '10vw'}}
								value={this.state.operatorNames}
								onChange={e => this.getSelect(e)}
							>
								{
								this.state.listDatas?.map(name => 
                                <Option key={name}>{name}</Option>)
								}
							</Select>
						</FormItem>

                    <FormItem
							label={"状态"}
							rules={TYPE_NAME.REQ_SELECT}
						>
							<Select 
								placeholder={`请选择状态`} 
								style={{minWidth: '7vw', maxWidth: '10vw'}}
                                onSelect={e =>this.getbos(e)}
                                value={this.state.traceValue}
                                
							>
								{
								this.state.manufactureValue?.map(name => <Option key={name}>{name}</Option>)
								}
							</Select>
						</FormItem>
						<FormItem
							label={"等级"}
							rules={TYPE_NAME.REQ_SELECT}
						>
							<Select 
								style={{minWidth: '7vw', maxWidth: '10vw'}}
								value={this.state.operationNamees}
								placeholder={`请选择等级`}                  
								onChange={e => this.getSelectes(e)}  
							>
								{
									this.state.meterModelValue?.map(name => <Option key={name}>{name}</Option>)
								}
							</Select>
						</FormItem>

                    </Conditions>
                </div>
                
				<Tables 
					tableParamenter = {this.state.tableParamenter}  // 表格表头、数据
					exportTable={this.exportTable}  // 导出时触发的方法
					refreshTable={this.refreshTable}  // 刷新时触发的方法
					getPagination={this.getPagination} // 分页器变化时触发的方法
					improtTable={this.improtTable} // 导入方法
                    setTableValue={this.setTableValue}
                    pagination={{
                        totalCount: this.state.totalCount, // 总条数
                        page: this.state.pageinfo.pageIndex,// 当前页数
                        size: this.state.pageinfo.pageLine // 页面条数
                    }}
				/>
            </Layout>
        )
    }
}