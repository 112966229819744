import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, Modal, Radio, message, Tag, Popconfirm, Checkbox, Input, Table, Upload} from 'antd'
import FormsText from "../../../components/common/forms/FormsText";
import { ArrowLeftOutlined } from '@ant-design/icons';
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import {getLocationById} from "../../../services/customer";
import {
    handleCheckResult,
    queryCheckInfoDetail,
    queryCheckInfoRecord, queryFlowByRecordId,
    queryHiddenDanger, submitHandleCheck, updateResults
} from "../../../services/meter";
const { TextArea } = Input;
// 工商户安检管理-记录明细
export default class industrySecurityDetails extends Component {

    state = {
        // 表单参数
        formText: {
            header: {
                id: '客户号',
                securityCheckStatus:'安检状态',
                selfCheckStatus:'自检状态',
                principalPhone: '联系电话',
                customerName: '客户名称',
                address: '客户地址',
                securityCheckedTime:'最近安检时间',
                securityCheckRequiredTime:'最近应安检日期',
                principal:'联系人',
                // createDate:'创建时间'
            }
        },
        //表格
        tableParamenter: {
            height:'550px',
            overflow:true,
            manageName: "记录明细",
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            headerStyle:'center',
            // 表头：变量和变量解释
            header: {
                operator: '安检员编号',
                operatorName: '安检员',
                time: '安检日期',
                result:'安检结果',
                status: '处理状态',
                edit: '操作',
            },
            params: {
                recordId:this.props.location?.state?.rowData?.customerId,
                classification:'安检',
                page:1,
                size:10,
            },
            // 是否绑定左侧区域
            bindArea: false,
        },


        // 弹框表格
        table_columns:[
            {title: '隐患名称',dataIndex: 'name', key: 'name',align:'center',width:'100px'},
            {title: '分级',dataIndex: 'level', key: 'level',align:'center',width:'100px'},
            {title: '状态',dataIndex: 'state', key: 'state',align:'center',width:'100px'},
            {title: '处理',dataIndex: 'edit', key: 'edit',align:'center',width:'100px',
                render: (text, rowData,index) => {
                    return<>
                        {rowData.state==='已整改'?
                            <div  title={'已整改'}

                            >
                                <u style={{color:'rgba(0,0,0,0.41)'}}>处理</u>
                            </div>
                            :
                            <div  title={'未处理'} style={{cursor: 'pointer'}}
                                  onClick={()=>{
                                      this.setState({
                                          visible_2:true,
                                          selectRowData_2:rowData,
                                          fileList:[],
                                      });

                                  }}
                            >
                                <u style={{color:'red'}}>处理</u>
                            </div>
                        }

                    </>
            }
            },
        ],
        table_data:[], //弹框表格数据

        selectRowData_1:null,// 选择的一行数据
        selectRowData_2:null,// 选择的一行数据
        switchType:0,// 类型切换，0正常页面，1明细界面
        visible_1:false,
        visible_2:false,
        measures:[],// 类型选择
        remark:null,// 备注
        modal_group:'否',

        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList: [], // 设备照片
        newFileList:[],

        flowByRecordData:null,
    };


    // 通过安检记录id查询驳回流程
    queryFlowByRecordId=(recordId)=>{
        console.log(recordId);
        // var params =  new URLSearchParams();
        // params.append('recordId',recordId);
        queryFlowByRecordId({recordId:recordId}).then(
            reponse=>{
                if(reponse?.data?.data){

                    this.setState({flowByRecordData:reponse?.data?.data});
                }
            });
    };


    componentDidMount(): void {}
    // 查询隐患
    queryHiddenDanger=(recordId)=>{
        var params =  new URLSearchParams();
        params.append('classification','安检');
        params.append('recordId',recordId);
        queryHiddenDanger(params).then(
            reponse=>{
                if(reponse?.data?.data){
                    this.setState({table_data:reponse?.data?.data});
                    this.modalGroup(reponse?.data?.data);
                }
            });
    };

    setTableValue = (value,text,rowData) => {
        if(text === 'null') return '-';
        switch (value) {
            case 'status':
                return <>
                    <div  title={'未处理'} style={{cursor: 'pointer'}}
                          onClick={()=>{
                              this.setState({visible_1:true,selectRowData_1:rowData});
                              this.queryCheckInfoDetail(rowData.id);// 复选框和文本框数据
                              this.queryHiddenDanger(rowData.id);   // 表格数据
                              this.queryFlowByRecordId(rowData.id);// 查询驳回数据

                          }}

                    >
                        <u style={{color: text==='未处理'?'red':null}}>{text}</u>
                    </div>
                </>;
            case 'edit':
                return <>
                    <NavLink
                        to={{pathname:"../customer/securityDetails",
                            state:{
                                formsTwo:this.props.location?.state?.rowData,
                                tableData:rowData,
                                selectType:1
                            }
                        }}
                    >
                        详情
                    </NavLink>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 查询检查明细
    queryCheckInfoDetail=(id)=>{
        var params =  new URLSearchParams();
        params.append('classification','安检');
        params.append('recordId',id);
        queryCheckInfoDetail(params).then(
            response=>{
                if(response?.data?.data){

                    this.setState({
                        measures:response.data.data?.measures??[],          // 多选框
                        remark:response.data.data?.remark??null,            // 描述
                    })
                }
            });
    };

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };
    // 照片点击事件
    onChangeUpload =  file => {
        if (file.file.status !== 'uploading') {
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList:file.fileList});
    };


    // 判断表格状态，更改状态
    modalGroup=(tableData=[])=>{
        var {modal_group} = this.state;
        modal_group = '是';
        if(!tableData){return this.setState({modal_group:'否'})}
        if(tableData.length===0){return this.setState({modal_group:'否'})}
        tableData.map(one=>{
            if(one.state==='未整改'){
                modal_group='否'
            }
        });
        this.setState({modal_group:modal_group})
    };

    // 控制新增记录
    getNewRecordDiv = () =>{
        var rowData = this?.table?.getDataTable();
        var juder = false;
        if(rowData){
            if( rowData[0]?.status === '已处理，审核通过'){
                juder=true
            }else if(rowData[0]?.status === '已处理'){
                juder=true
            }
        }else {
            juder=true
        }

        return<>
            {
                juder?
                    <NavLink
                        to={{pathname:"../customer/securityDetails",
                            state:{
                            formsTwo:this.props.location?.state?.rowData,
                                rowData:this.props.location?.state?.rowData,
                            }}}>
                        新增记录
                    </NavLink>
                    :
                    <span style={{color:'rgba(0,0,0,0.41)'}}>新增记录</span>

            }
        </>
    };

    updateResults=()=>{
        // 更新评估结果
        updateResults({customerId:this.props.location.state?.rowData?.customerId}).then(
            response=>{
                if(response?.data?.data){

                }
            }
        )
    };


    render() {
        const uploadButton = (
            <div>
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                {/*返回上一页*/}
                <div className={'div_back_arrow'}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}>
                        <ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                    <span className={'font_size2'}>返回记录明细管理</span>
                </div>
{/*客户信息-------------------------------------------------------*/}
                <div style={{width:'100%',height:'150px',}}>
                    <span style={{fontSize:'16px',fontWeight:'bold',paddingLeft: '1vw'}}>客户信息</span>
                    <div className={'insert_table'} style={{paddingLeft: '3vw'}}>
                        <FormsText
                            record={this.props.location.state?.rowData}
                            header={this.state.formText.header}
                            rowLen={4}
                            span={6}
                        />
                    </div>
                </div>
{/*分割线--------------------------------------------------------*/}
                <div className={'divider'}/>
                <div>
                    <div style={{padding: '5px 0 0 10px',fontSize:'14px'}}>
                        {this.getNewRecordDiv()}

                        <a style={{float:'right',marginRight:'15px'}}
                           onClick={()=>{this.table.refreshTable();}}
                        >刷新</a>
                    </div>
                    <div>
                        <TableComponents
                            paramenter={this.state.tableParamenter}
                            setData = {queryCheckInfoRecord}
                            setTableValue={this.setTableValue}
                            ref={e => this.table = e}
                        >
                        </TableComponents>
                    </div>
                </div>
{/*处理---------------------------------------------------------------------------------------------------------------*/}
                <Modal
                    title={"处理"}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_1}
                    okText="确定"
                    onOk={() => {
                        console.log(JSON.parse(sessionStorage.getItem("user")));
                        var {modal_group} = this.state;
                        if(!modal_group)return message.info('是否整改完毕不能为空');
                        var param={
                            flowId:this.state?.flowByRecordData?this.state?.flowByRecordData?.id:null,
                            "isOver": modal_group,               // 是否整改完成 是/否
                            "operator": JSON.parse(sessionStorage.getItem("user")).orgId, // 操作人id
                            "operatorName": JSON.parse(sessionStorage.getItem("user")).orgName,// 操作人名
                            "recordId": this.state.selectRowData_1.id,      // 安检记录id
                            "rectificationDesc": this.state.remark,         // 整改说明
                            "rectificationMethods": this.state.measures,    // 整改方式
                            "time":  new Date()                             // 提交时间
                        };
                        submitHandleCheck(param).then(
                            response=>{
                                if(response.data.data){
                                    message.info('提交成功');
                                    this.table.refreshTable();
                                    this.setState({visible_1:false});
                                }else{
                                    message.info('提交失败')
                                }
                            }
                        )
                    }}           	// 点击确定
                    onCancel={() => this.setState({visible_1:false})}	// 点击取消
                    width={900}
                    centered
                >
                    <div style={{width:'100%'}}>
                        <div style={{width:'100%',height:'30px'}}>
                            <Checkbox.Group value={this.state.measures}>
                                <Checkbox value="线器正刚" disabled={true}>限期正刚</Checkbox>
                                <Checkbox value="停业整顿" disabled={true}>停业整顿</Checkbox>
                            </Checkbox.Group>
                        </div>
                        <div style={{width:'100%',height:'100px'}}>
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }}
                                      disabled={true}
                                      value={this.state.remark}
                            />
                        </div>
                        <div style={{width:'100%',height:'20px'}}>
                            <div style={{width:'100%',height:'1px', backgroundColor:'rgba(0,0,0,0.2)' }}/>
                        </div>
                        <div style={{width:'100%',height:'100%',overflowY: 'auto'}}>
                            <Table
                                bordered
                                pagination={false}
                                scroll={{ y: 240 }}
                                columns={this.state.table_columns}
                                dataSource={this.state.table_data}
                            />
                        </div>
                        {/*是否整改完毕*/}
                        <div style={{width:'100%',height:'30px',marginTop:'25px'}}>
                            <div style={{width:'100px',float:'left',fontWeight:'bold'}}>
                                <span>是否整改完毕</span>
                            </div>
                            <div style={{width:'200px',float:'left'}}>
                                <Radio.Group
                                    value={this.state.modal_group}
                                    onChange={(v)=>{
                                        this.setState({modal_group:v.target.value});
                                    }}>
                                    <Radio value={'是'} disabled={true}>是</Radio>
                                    <Radio value={'否'} disabled={true}>否</Radio>
                                </Radio.Group>
                            </div>

                        </div>
                        {/*驳回*/}
                        <div style={{width:'100%',
                            height:'100px',
                            paddingTop:'10px',
                            display: this.state.flowByRecordData?.remark?null:'none'
                        }}
                        >
                                <span style={{color:'red'}}>
                                    驳回:
                                </span>
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} value={this.state.flowByRecordData?.remark}

                            />
                        </div>
                    </div>
                </Modal>

{/*图片上传-----------------------------------------------------------------------------------------------------------*/}
                <Modal
                    title={"图片上传"}
                    maskClosable={false}
                    style={{ top: 0 }}
                    visible={this.state.visible_2}
                    okText="确定"
                    onOk={() => {
                        var params={
                            "hiddenDangerId": this.state.selectRowData_2.id,// 选择的表格数据id
                            "photos": []// 图片数据
                        };
                        this.state.fileList.map(one=>{
                            params.photos.push(
                                {
                                    "customerId": this.props.location.state?.rowData?.customerId,
                                    "path": one.response,
                                    "recordId": this.state.selectRowData_1.id,
                                }
                            )
                        });
                        if(this.state.fileList.length===0){return false;}
                        handleCheckResult(params).then(
                            response=>{
                                if(response?.data?.data){
                                    message.info('提交成功');
                                    this.updateResults(); // 更新评估
                                    this.setState({visible_2:false});
                                    this.queryHiddenDanger(this.state.selectRowData_1.id);   // 表格数据
                                }else{
                                    message.info('提交失败')
                                }
                            }
                        );
                    }}           	// 点击确定
                    onCancel={() => this.setState({visible_2:false})}	// 点击取消
                    width={900}
                    centered
                >
                    <div>
                        <Upload
                            action={this.state.fileUrl}
                            listType="picture-card"
                            name={"file"}
                            fileList={this.state.fileList}
                            onPreview={this.handlePreview}
                            onChange={this.onChangeUpload}
                        >
                            {
                                // this.state.fileList.length >= 4 ? null :
                                    uploadButton}
                        </Upload>
                    </div>
                </Modal>
            </Layout>
        )
    }
};
