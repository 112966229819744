import ajax from './ajax'
//阿里云
import axios from "axios";

// 登录 gateway
// export const doLogin = data => ajax('/gateway/user/doLogin', data, 'POST');

export const doLogin = data => ajax('/user/doLogin', data, 'POST');

//阿里云
// export const doLogin = data => axios.post('/hdstLogin/user/doLogin', data);
