import React from 'react';

import * as echarts from 'echarts';
// 条形图
class BarChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };


    elementById= async =>{

        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                //标题
                // title: {
                //     text: 'World Population'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {},
                grid: {
                    top:'5%',
                    left: '10%',
                    right: '14%',
                    bottom: '-1%',
                    containLabel: true
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    // interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    // boundaryGap: [0, 1],
                },
                yAxis: {
                    type: 'category',
                    data:this.props?.yData
                },
                // series:series
                series: [
                    {
                        name: '隐患用户',
                        type: 'bar',
                        stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.props?.data2,
                        itemStyle:{
                            normal:{
                                color:'#c46054'
                            }
                        }

                    },
                    {
                        name: '安全用户',
                        type: 'bar',
                        stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.props?.data1,
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(1,0,0,0,
                                    [
                                        {offset:0,color:'#57c6ff'},
                                        {offset:1,color:'#58c6fb'},
                                    ])
                            }
                        }
                    },

                ]
            }
        );
    };

    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
