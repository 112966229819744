import Login from '../pages/login/Login'
import Home from '../pages/client/Home'
import ErrorPage from '../pages/error/ErrorPage'
// import ErrorPage from '../pages/qrCode/deviceMap/details/deviceMapDemo'
import RegionalInfo from '../pages/dataIntegration/RegionalInfo'
// import RegionalInfo from '../pages/qrCode/deviceMap/details/deviceMapDemo'
import devMap from "../pages/qrCode/deviceMap/details/deviceMapDemo";
import MeterMap from "../pages/earlyWarning/map/meterMap";
import RiskMap from "../pages/client/riskMap/RiskMap"
import SafetyAssessmentDetails from "../pages/client/safetyAssessment/SafetyAssessmentDetails"
import dataPanel from "../pages/dataPanel/dataPanel_v.02"
import customerDataPanel from "../pages/customerVisualDataInterface/customerVisualDataInterface_v.01"
import loginPage from "../pages/customerVisualDataInterface/loginPage"
import titleInfo from "../pages/customerVisualDataInterface/js/titleInfo"
import gasDataPanel from "../pages/gasDataPanel/gasDataPanel_v.01";
import mapManagePage from "../pages/mapManage/mapManagePage";
import mapManagePageV1 from "../pages/mapManage/mapManagePage_v.1";
import deviceModelMap from '../pages/qrCode/deviceDataInfo/map/deviceModelMap'

export const route = [
	{
		id: 0,
		name: '地图',
		path: "/meterMap",
		component: MeterMap
	},
	{
		id: 1,
        name: '错误页面',
		path: "/error",
		component: ErrorPage
	},
    {
		id: 2,
        name: '登录',
		path: "/login",
		component: Login
	},
	{
		id: 3,
        name: '主界面',
		path: "/regionalInfo",
		component: RegionalInfo
	},
	{
		id: 4,
		name: '设备地图',
		path: "/devMap",
		component: devMap
	},
	{
		id: 5,
		name: '风险地图',
		path: "/RiskMap",
		component: RiskMap
	},
	{
		id: 6,
		name: '工商户安全评估',
		path: "/SafetyAssessmentDetails",
		component: SafetyAssessmentDetails
	},
	{
		id: 7,
        name: '可视化数据面板',
		path: "/dataPanel",
		component: dataPanel
	},
	{
		id: 8,
		name: '可视化数据面板',
		path: "/customerDataPanel",
		component: customerDataPanel
	},
	{
		id: 9,
		name: '可视化燃气数据面板',
		path: "/gasDataPanel",
		component: gasDataPanel
	},

	// 暂无用
	{
		id: 10,
		name: '文本界面',
		path: "/titleInfo",
		component: titleInfo
	},
	{
		id: 11,
		name: '文本界面',
		path: "/loginPage",
		component: loginPage
	},

	{
		id: 12,
		name: '管道地图管理',
		path: "/mapManagePage",
		component: mapManagePage
	},
	{
		id: 13,
		name: '设备地图管理',
		path: "/deviceModelMap",
		component: deviceModelMap
	},
	{
		id: 14,
		name: '设备地图管理',
		path: "/mapManagePageV1",
		component: mapManagePageV1
	},
	{
		id: 15,
		name: '管理',
		path: "/",
		component: Home
	},

];
