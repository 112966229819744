import React, { Component } from 'react'
import { Input, Layout, DatePicker, Tabs, Upload, Modal, message, Table} from 'antd'
import {ArrowLeftOutlined,RedoOutlined} from '@ant-design/icons';
import './devInstallationNew.less'
import {
    getUnboundDevices,
    installationDev,
    getBoundDevicesById,
    getUnboundValveAlarm, installationValveAlarm, getBoundValveAlarmById, getUnboundDevicesByConId, newInstallationDev
} from '../../../services/customer'
import TableComponents from "../../../components/common/tables/TableComponentsNew";
import moment from "moment";
import dayjs from 'dayjs';
import {funTraceInfo} from '../../../services/common'
import {pageValveInfo} from "../../../services/qrCode";
const { MonthPicker, RangePicker } = DatePicker;
const { TextArea } = Input;
const {TabPane} = Tabs;
// 设备安装
export default class devInstallation extends Component {
    state={
        tabKey:"1",
        // 样式点击约束
        pointer_1:'auto',//auto/none
        fontColor_1:'red',

        formsTwo:this.props.location?.state?.formsTwo,
        rowData:this.props.location?.state?.rowData,
        installer:JSON.parse(sessionStorage.getItem("user")).realName?JSON.parse(sessionStorage.getItem("user")).realName:'',       // 安装人
        installerDate:moment().startOf("day").format("YYYY-MM-DD"),   // 安装时间
        telephone:JSON.parse(sessionStorage.getItem("user")).phone?JSON.parse(sessionStorage.getItem("user")).phone:'',       // 电话
        installPosition:'', // 安装位置
        installerInfo:'',   // 安装信息

        inputError:'',
        inputError_1:'',
        // 表格参数
        tableParamenter: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            overflow: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                parentNo: '控制器编号',
                communicateno: '控制器通信号',
                devModelName: '设备型号',
                // manufactureTime: '生产日期',
                state: '状态',
                comStatus: '通信状态',
                comTime: '最后通信时间',
            },
            params: {
                devType:'10196',
                ids:[this.props.location?.state?.rowData?.id],
                page:1,
                size:6,
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        // 表格参数_12
        tableParamenter_22: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: false,
            overflow: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                parentNo: '控制器编号',
                parentComNo: '控制器通信号',
                routeno: '探测器路号',
                devModelName:'设备型号',
                hdstno: '设备编号',
                manufacture: '供应商',
                state: '状态',
            },
            params: {
                devType:'10168',
                ids:[this.props.location?.state?.rowData?.id],
                page:1,
                size:6,
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        parentNo: '控制器编号',
        parentComNo: '控制器通信号',
        routeno: '探测器路号',
        devModelName:'设备型号',
        hdstno: '设备编号',
        // manufactureTime: '生产日期',
        manufacture: '供应商',
        state: '状态',
        comStatus: '通信状态',
        elcStatus: '市电状态',
        currentData: '可燃气体状态',
        status: '报警状态',
        linkageType: '联动状态',
        comTime: '最后通信时间',
        dataTime: '最后数据时间',

        tableData_2_value:[
            {title: '控制器编号', dataIndex: 'parentNo', key: 'parentNo',},
            {title: '控制器通信号', dataIndex: 'parentComNo', key: 'parentComNo',},
            {title: '探测器路号', dataIndex: 'routeno', key: 'routeno',},
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName',},
            {title: '设备编号', dataIndex: 'hdstno', key: 'hdstno',},
            // {title: '生产日期', dataIndex: 'manufactureTime', key: 'manufactureTime',},
            {title: '供应商', dataIndex: 'manufacture', key: 'manufacture',},
            {title: '状态', dataIndex: 'state', key: 'state',},
            {title: '通信状态', dataIndex: 'comStatus', key: 'comStatus',},
            {title: '市电状态', dataIndex: 'elcStatus', key: 'elcStatus',},
            {title: '可燃气体状态', dataIndex: 'currentData', key: 'currentData',},
            {title: '报警状态', dataIndex: 'status', key: 'status',},
            {title: '联动状态', dataIndex: 'linkageType', key: 'linkageType',},
            {title: '最后通信时间', dataIndex: 'comTime', key: 'comTime',},
            {title: '最后数据时间', dataIndex: 'dataTime', key: 'dataTime',},
        ],
        tableData_2_data:[],
        tableData_2_param:{page:1, size:4, total:0},
        tableData_2_selecte:[],

        // 表格参数-阀井
        tableParamenter_3: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: true,
            selectHead:false,
            overflow:true,
            //下拉框长度
            selectWidth: '200px',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                status: '通信状态',
                comTime: '通信时间',
                dataTime: '最后数据时间',
                edit: '操作',
            },
            params: {
                page:1,
                size:3,
                devType:10200,
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                // jumper:true
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        // 表格参数-家用报警器
        tableParamenter_5: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            overflow:true,
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                // edit: '操作',
            },
            params: {
                devType:10202,
                page:1,
                size:3,

            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        // 表格参数-管钉-上-右
        tableParamenter_8: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            overflow:true,
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                // edit: '操作',
            },
            params: {
                ids:[this.props.location?.state?.rowData?.id],
                devType:[10210],
                page:1,
                size:3,
            },
            // 是否绑定左侧区域
            bindArea: true,
        },
        // 表格参数-管钉
        tableParamenter_7: {
            manageName: "SIM卡申请管理",
            // 表格行是否可选
            rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            overflow:true,
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                // edit: '操作',
            },
            params: {
                // ids:[this.props.location?.state?.rowData?.id],
                devType:10210,
                page:1,
                size:3,
            },
            // 是否绑定左侧区域
            bindArea: true,
        },

        // 表格参数-阀井
        tableParamenter_4: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: false,
            selectHead:false,
            overflow: true,
            //下拉框长度
            selectWidth: '200px',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                status: '通信状态',
                comTime: '通信时间',
                dataTime: '最后数据时间',

            },
            params: {
                ids:[this.props.location?.state?.rowData?.id],
                page:1,
                size:6,
                devType: [10200]
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                // jumper:true
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        // 表格参数-家用报警
        tableParamenter_6: {
            manageName: "数据项管理",
            // 表格行是否可选
            rowSelection: false,
            selectHead:false,
            overflow: true,
            //下拉框长度
            selectWidth: '200px',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',

            },
            params: {
                ids:[this.props.location?.state?.rowData?.id],
                devType:[10202],
                page:1,
                size:6,
                // userId:JSON.parse(sessionStorage.getItem("user")).id,
                // jumper:true
            },
            // 是否绑定左侧区域
            bindArea: false,
        },
        tableData_3_value:[

            {title: '控制器编号', dataIndex: 'parentNo', key: 'parentNo',},
            {title: '控制器通信号', dataIndex: 'parentComNo', key: 'parentComNo',},
            {title: '探测器路号', dataIndex: 'routeno', key: 'routeno',},
            {title: '设备型号', dataIndex: 'devModelName', key: 'devModelName',},
            {title: '设备编号', dataIndex: 'hdstno', key: 'hdstno',},
            // {title: '生产日期', dataIndex: 'manufactureTime', key: 'manufactureTime',},
            {title: '供应商', dataIndex: 'manufacture', key: 'manufacture',},
            {title: '状态', dataIndex: 'state', key: 'state',},
            {title: '通信状态', dataIndex: 'comStatus', key: 'comStatus',},
            {title: '市电状态', dataIndex: 'elcStatus', key: 'elcStatus',},
            {title: '可燃气体状态', dataIndex: 'currentData', key: 'currentData',},
            {title: '报警状态', dataIndex: 'status', key: 'status',},
            {title: '联动状态', dataIndex: 'linkageType', key: 'linkageType',},
            {title: '最后通信时间', dataIndex: 'comTime', key: 'comTime',},
            {title: '最后数据时间', dataIndex: 'dataTime', key: 'dataTime',},
        ],
        tableData_3_data:[],
        tableData_3_param:{page:1, size:4, total:0},
        tableData_3_selecte:[],

        previewVisible: false,  // 照片弹窗
        previewImage: '',       // 浏览图片路径

        fileUrl:'http://47.97.117.248:8352/ossManagement/ossUpload',
        fileList_1: [], // 设备照片
        fileList_2: [], // 环境照片
        fileList_3: [], // 其他照片
        newFileList:[],

// 新改内容，底部控制 ------------------------------

        bottomLeftTable_1: {
            manageName: "控制器",
            // 表格行是否可选
            rowSelection: true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                parentNo: '控制器编号',
                communicateno: '控制器通信号',
                devModelName: '设备型号',
                // manufactureTime: '生产日期',
                state: '状态',
                comStatus: '通信状态',
                comTime: '最后通信时间',
            },
            params: {
                devType:10196,
                page:1,
                size:4,
            },
            // 是否绑定左侧区域
            bindArea: false
        },

        bottomRightTable_2: {
            manageName: "探测器",
            // 表格行是否可选
            rowSelection: false,
            overflow:true,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                routeno: '探测器路号',
                devModelName: '设备型号',
                hdstno: '设备编号',
                // manufactureTime: '生产日期',
                state: '状态',
            },
            params: {
                devType:10168,
                id:null,
                page:1,
                size:4,
            },
            // 是否绑定左侧区域
            bindArea: false
        },

        rightTableOverflow:false,

    };
    componentWillMount() {

        this.setState({
            pointer_1:'auto',
            fontColor_1:'',
            // installer:JSON.parse(sessionStorage.getItem("user")).realName?JSON.parse(sessionStorage.getItem("user")).realName:'',
            // telephone:JSON.parse(sessionStorage.getItem("user")).phone?JSON.parse(sessionStorage.getItem("user")).phone:''
        });
        // this.getUnboundDevices();

    }
    handleCancel = () => this.setState({ previewVisible: false });

    // 弹窗设置
    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,    // 路径：网络路径或本地路径
            previewVisible: true,// 弹窗开关
        });
    };


    // 低部表格单选
    onSelectChange=(_,b,)=>{
        this.setState({onSelectChangeData:b})
    };
    // 下方，表格选择事件——1
    installationDev_1=()=>{
        let data = this.table3.getSelectChange();
        console.log(data);
        if(!data){return message.info('请选择添加数据');}
        if(this.state.installer===''){
            this.setState({inputError:'error'});
            return message.info("安装人不能为空")
        }
        if(this.state.telephone===''){
            this.setState({inputError_1:'error'});
            return message.info("联系电话不能为空")
        }
        // 提交参数配置
        var params={
            // 绑定客户联系人 客户联系人
            bindContacts:this.state.installer?this.state.installer:'',
            // 所属客户ID
            customerId:this.state.formsTwo?.customerId?this.state.formsTwo.customerId:'',
            // 所属客户名称
            customerName:this.state.formsTwo?.customerName?this.state.formsTwo.customerName:'',


            // 设备层主键id
            devId:data[0]?.devId?data[0]?.devId:'',
            // 设备名称
            devModelName:data[0]?.devModelName?data[0]?.devModelName:'',
            // 设备名称
            devName:data[0]?.devName?data[0]?.devName:'',
            // 设备类型
            devType:data[0]?.devType?data[0]?.devType:'',
            // 厂家编号
            hdstno:data[0]?.manufacture?data[0].hdstno:'',

            id:data[0]?.id?data[0].id:'',
            // 安装时间
            installDate:this.state.installerDate||'',
            // 安装位置
            // installPosition:this.state.installPosition||'',
            installPosition:this.state.formsTwo?.address?this.state.formsTwo.address:'',
            // 安装人
            installer:this.state.installer?this.state.installer:'',
            // 安装联系电话
            installerTel:this.state.telephone?this.state.telephone:'',
            // 主键ID
            locationId:this.state.rowData?.id?this.state.rowData.id:'',
            // 手机号码
            phone:this.state.telephone?this.state.telephone:'',
            // 唯一标识
            serialno:data[0]?.serialno?data[0].serialno:'',
            // 安装备注
            remark:this.state.installerInfo?this.state.installerInfo:'',

            photos:[]
        };
        // 处理照片
        if(this.state.fileList_1.length!==0){
            var photo1=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_1[0].response
                };
            params.photos.push(photo1);
        }
        if(this.state.fileList_2.length!==0){
            var photo2=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_2[0].response
                };
            params.photos.push(photo2);
        }
        if(this.state.fileList_3.length!==0){
            var photo3=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_3[0].response
                };
            params.photos.push(photo3);
        }
        this.setState({pointer_1:'none',fontColor_1:'#999'});// 开启锁住请求按钮

        installationValveAlarm(params).then(
            response=>{
                if(response?.data?.data){
                    message.info(response.data.responseInfo.description);
                    // funTraceInfo("客户管理", "安装设备", null,params)
                }else{
                    message.info('安装失败');
                }
                setTimeout(()=>{this.setState({pointer_1:'auto',fontColor_1:''});},1000);
                // this.setState({pointer_1:'auto',fontColor_1:''});
                this?.table3?.refreshTable(this.state.tableParamenter_7.params);
                this?.table4?.refreshTable(this.state.tableParamenter_8.params);
            }

        )
    };
    // 旧版，安装探测器
    installationDev= yarn =>{
        var data=this.state.tableData_2_selecte;
        if(data.length===0){
            return message.info('请选择安装的设备');
        }

        if(this.state.installer===''){
            this.setState({inputError:'error'});
            return message.info("安装人不能为空")
        }
        if(this.state.telephone===''){
            this.setState({inputError_1:'error'});
            return message.info("联系电话不能为空")
        }
        // 提交参数配置
        var params={
            // 所属客户ID
            customerId:this.state.formsTwo?.customerId?this.state.formsTwo.customerId:'',
            // 所属客户名称
            customerName:this.state.formsTwo?.customerName?this.state.formsTwo.customerName:'',
            // 设备层主键id
            devId:data[0]?.devId?data[0]?.devId:'',
            // 设备名称
            devName:data[0]?.devName?data[0]?.devName:'',
            // 探测器ID
            gasAlarmId:data[0]?.id?data[0].id:'',
            // 厂家编号
            hdstno:data[0]?.manufacture?data[0].hdstno:'',
            // 安装位置
            // installPosition:this.state.formsTwo?.address?this.state.formsTwo.address:'',
            // 主键ID
            locationId:this.state.rowData?.id?this.state.rowData.id:'',
            // 父设备通讯号
            parentComNo:data[0]?.parentComNo?data[0].parentComNo:'',
            // 父设备ID
            parentId:data[0]?.parentId?data[0].parentId:'',
            // 父设备编号
            parentNo:data[0]?.parentNo?data[0].parentNo:'',
            // 唯一标识
            serialno:data.serialno?data[0].serialno:'',
            // 绑定客户联系人 客户联系人
            bindContacts:this.state.installer?this.state.installer:'',
            // 安装人
            installer:this.state.installer?this.state.installer:'',
            // 创建时间
            installDate:this.state.installerDate||'',
            // 安装联系电话
            installerTel:this.state.telephone?this.state.telephone:'',
            // 手机号码
            phone:this.state.telephone?this.state.telephone:'',
            // 安装位置
            installPosition:this.state.installPosition||'',
            // 安装备注
            remark:this.state.installerInfo?this.state.installerInfo:'',
            photos:[]
        };

        // 处理照片
        if(this.state.fileList_1.length!==0){
            var photo1=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_1[0].response
                };
                params.photos.push(photo1);
        }
        if(this.state.fileList_2.length!==0){
            var photo2=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_2[0].response
                };
            params.photos.push(photo2);
        }
        if(this.state.fileList_3.length!==0){
            var photo3=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_3[0].response
                };
            params.photos.push(photo3);
        }

        this.setState({pointer_1:'none',fontColor_1:'#999'});// 开启锁住请求按钮
        installationDev(params).then(
            response=>{
                if(response?.data?.data){
                    message.info(response.data.responseInfo.description);
                    // funTraceInfo("客户管理", "安装设备", null,params)
                }else{
                    message.info('安装失败');
                }
                // this.getUnboundDevices();
                setTimeout(()=>{this.setState({pointer_1:'auto',fontColor_1:''});},5000);

                // this.setState({pointer_1:'auto',fontColor_1:'',tableData_2_selecte:[]});
                this.setState({tableData_2_selecte:[]});
                this.table.refreshTable(this.state.tableParamenter_22.params);
            }
            // setTimeout()
        ).catch(error=>{
            message.info('请求异常');
            this.setState({pointer_1:'auto',fontColor_1:'',tableData_2_selecte:[]});
        });

    };



    // 查询所有未绑定的设备
    getUnboundDevices=()=>{
        getUnboundDevices(this.state.tableData_2_param).then(
            response=>{
                if(response.data.data){
                    var tableData = [...this.state.tableData_2_data];
                    var params = this.state.tableData_2_param;
                    params.total=response.data.pageInfo.total;
                    params.page=response.data.pageInfo.pageLine;
                    tableData=response.data.data;

                    this.setState({tableData_2_data:tableData,tableData_2_param:params});
                    message.info(response.data.responseInfo.description)
                }
            }
        )
    };
    // 下方-表格1-单选
    onChangeLeftTable=(a,e)=>{
        this.setState({tableData_2_selecte:e})
    };


    async uploadFile(file) {
        // // 获取文件对象
        // const file = this.$refs.fileInput.files[0]
        // 创建 FormData 对象
        const formData = new FormData();
        // 将文件添加到 FormData 中
        formData.append('file', file)

        console.log(file);
        try {
            // 使用 fetch 发送文件到服务器
            const res = await fetch('http://192.168.1.107:8352/ossManagement/ossUpload', {
                method: 'POST',
                body: formData,
                ContentType:'image/jpeg'
            });
            console.log(await res.json())
        } catch (err) {
            console.error(err)
        }
    }

    onChange_1 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_1:file.fileList})
    };
    onChange_2 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_2:file.fileList})
    };
    onChange_3 =  file => {
        if (file.file.status !== 'uploading') {
            console.log(file.file, file.fileList);
        }
        if (file.file.status === 'done') {
            message.success(`${file.file.name} 上传成功`);
            funTraceInfo("客户管理", "上传", null,null)
        } else if (file.file.status === 'error') {
            message.error(`${file.file.name} 上传异常.`);
        }
        this.setState({fileList_3:file.fileList})
    };

    getPaginationRight = (page, pageSize) => {
        console.log("右边表格：获取当前页数和条数");
        console.log(page +","+ pageSize);
        let {tableData_2_param} = this.state;
        tableData_2_param.page = page;
        this.setState({tableData_2_param: tableData_2_param});
        // this.getUnboundDevices();
    };

    // 安装设备-新
    newInstallationDev=()=>{
        var data=this.bottomLeftTable1.getSelectChange();
        if(data.length===0){
            return message.info('请选择安装的设备');
        }

        if(this.state.installer===''){
            this.setState({inputError:'error'});
            return message.info("安装人不能为空")
        }
        if(this.state.telephone===''){
            this.setState({inputError_1:'error'});
            return message.info("联系电话不能为空")
        }
        // 提交参数配置
        var params={
            // 所属客户ID
            customerId:this.state.formsTwo?.customerId?this.state.formsTwo.customerId:'',
            // 所属客户名称
            customerName:this.state.formsTwo?.customerName?this.state.formsTwo.customerName:'',
            // 设备层主键id
            devId:data[0]?.devId?data[0]?.devId:'',
            // 设备名称
            devName:data[0]?.devName?data[0]?.devName:'',
            // 探测器ID
            // gasAlarmId:data[0]?.id?data[0].id:'',
            // 厂家编号
            hdstno:data[0]?.manufacture?data[0].hdstno:'',
            // 安装位置
            installPosition:this.state.formsTwo?.address?this.state.formsTwo.address:'',
            // 主键ID
            locationId:this.state.rowData?.id?this.state.rowData.id:'',
            // 父设备通讯号
            parentComNo:data[0]?.parentComNo?data[0].parentComNo:'',
            // 父设备ID
            parentId:data[0]?.parentId?data[0].parentId:'',
            // 父设备编号
            parentNo:data[0]?.parentNo?data[0].parentNo:'',
            // 唯一标识
            serialno:data.serialno?data[0].serialno:'',
            // 绑定客户联系人 客户联系人
            bindContacts:this.state.installer?this.state.installer:'',
            // 安装人
            installer:this.state.installer?this.state.installer:'',
            // 创建时间
            installDate:this.state.installerDate||'',
            // 安装联系电话
            installerTel:this.state.telephone?this.state.telephone:'',
            // 手机号码
            phone:this.state.telephone?this.state.telephone:'',
            // 安装位置
            // installPosition:this.state.installPosition||'',
            // 安装备注
            remark:this.state.installerInfo?this.state.installerInfo:'',
            photos:[]
        };

        // 处理照片--------------
        if(this.state.fileList_1.length!==0){
            var photo1=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_1[0].response
                };
            params.photos.push(photo1);
        }
        if(this.state.fileList_2.length!==0){
            var photo2=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_2[0].response
                };
            params.photos.push(photo2);
        }
        if(this.state.fileList_3.length!==0){
            var photo3=
                {
                    "devType": 10196,
                    "photoKey": "设备照片(dev) ",
                    "photoPath": this.state.fileList_3[0].response
                };
            params.photos.push(photo3);
        }
        console.log(params);
        this.setState({pointer_1:'none',fontColor_1:'#999'});// 开启锁住请求按钮
        newInstallationDev(params).then(
            response=>{
                if(response?.data?.data){
                    message.info(response.data.responseInfo.description);
                    // funTraceInfo("客户管理", "安装设备", null,params)
                }else{
                    message.info('安装失败');
                }
                // this.getUnboundDevices();
                setTimeout(()=>{this.setState({pointer_1:'auto',fontColor_1:''});},5000);

                this.setState({tableData_2_selecte:[]});
                this?.table1?.refreshTable(this.state.tableParamenter.params);
                this?.table2?.refreshTable(this.state.tableParamenter_22.params);
                this?.bottomLeftTable1?.refreshTable(this.state.bottomLeftTable_1.params);
                this?.bottomRightTable2?.refreshTable(this.state.bottomRightTable_2.params);
            }
        )

    };

    render() {
        const { previewVisible, previewImage} = this.state;
        const uploadButton = (
            <div>
                {/*<Icon type="plus" />*/}
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        return(
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={'devInstallation'}>
                    <div>
                        <div className={'div_back_arrow'}>
                            <span className={'back_arrow'} onClick={() =>
                                this.props.history.go(
                                    -1,
                                    {

                                        state: {
                                            rowData: this.props.location?.state?.formsTwo
                                        }
                                    }
                                )
                            }><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                            <span className={'font_size1'}>返回上一页</span>
                        </div>
                    </div>
{/*上部分--------------------------------------------------------------------------*/}
                    <div style={{ "width": '99.5%','height': '420px'}}>
                        {/*左边*/}
                        <div style={{  "width": '49.9%','height': '60%','float': 'left'}}>
                            {/*表单*/}
                            <div  style={{"width":'100%','height':'90%'}}>
                                <div style={{"fontSize": "18px","fontWeight": "bold","paddingLeft":'5px'}}>
                                    <span className={'formText_div_span'}>客户信息</span>
                                    <span className={'formText_div_span'}>安装点信息</span>
                                    <span className={'formText_div_span'}>安装信息</span>
                                </div>
                                <div className={'formText_installInner'}>
                                    <ul>
                                        <li>客户名：<span>{this.state?.formsTwo?.customerName?this.state?.formsTwo?.customerName:"-"}</span></li>
                                        <li>联系人：<span>{this.state?.formsTwo?.mpName?this.state?.formsTwo?.mpName:"-"}</span></li>
                                        <li>联系电话：<span>{this.state?.formsTwo?.principalPhone?this.state?.formsTwo?.principalPhone:"-"}</span></li>
                                    </ul>
                                </div>
                                <div className={'formText_installInner'}>
                                    <ul>
                                        <li>安装点名称：<span>{this.props?.location?.state?.rowData?.name?this.props?.location?.state?.rowData?.name:"-"}</span></li>
                                        <li>安装点状态：<span>{this.state?.rowData?.state?this.state?.rowData?.state:"-"}</span></li>
                                        <li>安装点地址：<span>{this.state?.rowData?.address?this.state?.rowData?.address:"-"}</span></li>
                                    </ul>
                                </div>

                                <div className={'formText_installInner'}>
                                    <ul>
                                        <li>安装人：
                                            <Input status={this.state.inputError} style={{marginLeft: '5%', width: '65%' }}
                                                   size="small"
                                                   defaultValue={this.state.installer}
                                                   onChange={(text)=>{
                                                       this.setState({installer:text.target.value,inputError:''})
                                                   }}/></li>
                                        <li>安装时间：
                                            <DatePicker
                                                style={{ width: '65%' }}
                                                size="small"
                                                defaultValue={moment(this.state.installerDate, 'YYYY-MM-DD')}
                                                onChange={(text)=>{
                                                    text =moment(text).format('YYYY-MM-DD');
                                                    console.log(text);
                                                    this.setState({installerDate:text})
                                                }}
                                            />
                                        </li>
                                        <li>联系电话：
                                            <Input
                                                status={this.state.inputError_1}
                                                style={{ width: '65%' }}
                                                size="small"
                                                value={this.state.telephone}
                                                onChange={(text)=>{
                                                this.setState({telephone:text.target.value,inputError_1:''})
                                            }}/></li>
                                        <li style={{ 'float': 'left' }} >安装位置：</li>
                                        <TextArea style={{marginTop:'3%',width: '65%','float': 'left' }} autoSize={{ minRows: 2, maxRows: 4 }}
                                                  onChange={(text)=>{this.setState({installPosition:text.target.value})}}
                                        />
                                        <li style={{ 'float': 'left' }} >安装信息：</li>
                                        <TextArea style={{marginTop:'3%',width: '65%','float': 'left' }} autoSize={{ minRows: 4, maxRows: 4 }}
                                                  onChange={(text)=>{this.setState({installerInfo:text.target.value})}}
                                        />
                                    </ul>
                                </div>
                            </div>
{/*图片--------------------------------------------------------------------------------------------------------------*/}
                            <div style={{"width":'100%',}}>
                                <div style={{"width":'100%'}}>
                                    <span style={{"fontSize": "18px","fontWeight": "bold","paddingLeft":'5px'}}>图片信息：</span>
                                </div>
                                <div style={{"width":'100%',height:'120px',"paddingLeft":'5px'}}>
                                    <div style={{'float': 'left'}}>
                                        <Upload
                                            action={this.state.fileUrl}
                                            name={"file"}
                                            listType="picture-card"
                                            fileList={this.state.fileList_1}
                                            onPreview={this.handlePreview}
                                            onChange={this.onChange_1}
                                        >
                                            {this.state.fileList_1.length >= 1 ? null : uploadButton}
                                        </Upload>

                                        <div style={{"paddingLeft":'20px'}}>
                                            <span>设备照片</span>
                                        </div>
                                    </div>
                                    <div style={{'float': 'left'}}>
                                        <Upload
                                            action={this.state.fileUrl}
                                            // action={"http://47.97.117.248:8352/ossManagement/ossUpload"}
                                            listType="picture-card"
                                            name={"file"}
                                            fileList={this.state.fileList_2}
                                            onPreview={this.handlePreview}
                                            onChange={this.onChange_2}
                                        >
                                            {this.state.fileList_2.length >= 1 ? null : uploadButton}
                                        </Upload>

                                        <div style={{"paddingLeft":'20px'}}>
                                            <span>环境照片</span>
                                        </div>
                                    </div>
                                    <div style={{'float': 'left'}}>
                                        <Upload
                                            action={this.state.fileUrl}
                                            listType="picture-card"
                                            name={"file"}
                                            fileList={this.state.fileList_3}
                                            onPreview={this.handlePreview}
                                            onChange={this.onChange_3}
                                        >
                                            {this.state.fileList_3.length >= 1 ? null : uploadButton}
                                        </Upload>

                                        <div style={{"paddingLeft":'20px'}}>
                                            <span>其他照片</span>
                                        </div>
                                    </div>
                                    {/*<div style={{'float': 'right','padding':'12% 4% 0 0'}}>*/}
                                    {/*    <Button type={'primary'} onClick={this.installationDev}>保存</Button>*/}
                                    {/*</div>*/}
                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        {/*竖线*/}
                        <div className={'verticalLine'} />
{/*上部分--右边------------------------------------------------------------*/}
                        <div style={{  "width": '49.9%','height': '420px','float': 'left','paddingLeft':'2px'}}>
                            <div>
                                <Tabs
                                    // activeKey={activeKey}
                                    tabPosition={"top"}
                                    animated={true}
                                    activeKey={this.state.tabKey}
                                    onTabClick={async (key)=>{
                                        this.setState({tabKey:key});

                                    }}
                                >
                                    <TabPane tab="报警控制器" key="1">
                                        <div>
                                            <div style={{width:'95%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table1.refreshTable(this.state.tableParamenter.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'95.5%',height:'330px',overflowY: 'auto',overflowX: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter}
                                                    setData = {getBoundDevicesById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table1 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="报警探测器" key="2">
                                        <div>
                                            <div style={{width:'95%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table2.refreshTable(this.state.tableParamenter_22.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'95%',height:'330px' ,overflowY: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_22}
                                                    setData = {getBoundDevicesById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table2 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="阀井" key="3">
                                        <div>
                                            <div style={{width:'95%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table4.refreshTable(this.state.tableParamenter_4.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'95%',height:'330px',overflowY: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_4}
                                                    setData = {getBoundValveAlarmById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table4 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="家用报警器" key="4">
                                        <div>
                                            <div style={{width:'95%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table4.refreshTable(this.state.tableParamenter_6.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'95%',height:'330px',overflowY: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_6}
                                                    setData = {getBoundValveAlarmById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table4 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="管钉" key="5">
                                        <div>
                                            <div style={{width:'95%',height:'25px'}}>
                                                <a style={{float:'right'}}
                                                   type="link" onClick={()=>{
                                                    this.table4.refreshTable(this.state.tableParamenter_8.params);
                                                }}>
                                                    <RedoOutlined />刷新
                                                </a>
                                            </div>
                                            <div style={{width:'95%',height:'330px',overflowY: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_8}
                                                    setData = {getBoundValveAlarmById}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table4 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                    {/*横线*/}
                    <div className={'horizontalLine'}/>
{/*底部-----------------------------------------------------------------------------------------------------------------*/}
                    <div style={{"width": '99.9 %','height': '380px',overflowX:'auto'}}>
                        <div style={{width: '50%','height': '100%',float:'left'}}>
                            <Tabs
                                tabPosition={"top"}
                                animated={true}
                                activeKey={this.state.tabKey1}
                                onTabClick={(key)=>{
                                    if(key.toString()==='1'){
                                        this.setState({rightTableOverflow:false})
                                    }else{
                                        this.setState({rightTableOverflow:true})
                                    }

                                    this.setState({tabKey1:key});

                                }}
                            >
                                    <TabPane tab="报警控制器" key="1">
                                        <div style={{'margin':'0 0 3px 10px',width: '99.9%',height: '100%'}}>
                                            <div style={{ "pointer-events": this.state.pointer_1,}}>
                                                <a style={{color:this.state.fontColor_1}}
                                                   type="link" onClick={
                                                       this.newInstallationDev
                                                       // this.installationDev
                                                   }>
                                                    安装此设备并提交
                                                </a>
                                                <a style={{float:'right',marginRight:'5px'}}
                                                   type="link" onClick={()=>{
                                                    this.bottomLeftTable1.refreshTable();
                                                }}>
                                                    <RedoOutlined />
                                                    刷新
                                                </a>
                                            </div>
                                            <div style={{overflowX: 'auto'}}>
                                                <TableComponents
                                                    getSelectChange={(a)=>{
                                                        console.log(a[0].id);
                                                        var bottomRightTable_2 =this.state.bottomRightTable_2;
                                                        bottomRightTable_2.params.id=a[0].id;
                                                        this.setState({bottomRightTable_2: bottomRightTable_2});
                                                        this.bottomRightTable2.refreshTable(bottomRightTable_2.params);
                                                    }}
                                                    paramenter={this.state.bottomLeftTable_1}
                                                    setData = {getUnboundDevices}
                                                    // setTableValue={this.setTableValue}
                                                    ref={e => this.bottomLeftTable1 = e}
                                                >
                                                </TableComponents>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="阀井" key="2">
                                        <div style={{'margin':'0 0 3px 10px',width: '99.9%',height: '100%'}}>
                                            <div style={{ "pointer-events": this.state.pointer_1,}}>
                                                <a
                                                    style={{color:this.state.fontColor_1}}
                                                    type="link" onClick={this.installationDev_1}>
                                                    安装此设备并提交
                                                </a>
                                                <a className={'addLink'} style={{float: 'right',right:'5px'}}
                                                   onClick={()=>{
                                                       this.state.tableParamenter_3.params.page=1;
                                                       this.table3.refreshTable(this.state.tableParamenter_3.params);
                                                   }} >
                                                    <RedoOutlined />
                                                    刷新
                                                </a>
                                            </div>
                                            <div style={{overflowX: 'auto'}}>
                                                <TableComponents
                                                    paramenter={this.state.tableParamenter_3}
                                                    setData = {getUnboundValveAlarm}
                                                    setTableValue={this.setTableValue}
                                                    ref={e => this.table3 = e}
                                                >
                                                </TableComponents>

                                            </div>
                                        </div>
                                    </TabPane>
                                <TabPane tab="家用报警器" key="3">
                                    <div style={{'margin':'0 0 3px 10px',width: '99.9%',height: '100%'}}>
                                        <div style={{ "pointer-events": this.state.pointer_1,}}>
                                            <a
                                                style={{color:this.state.fontColor_1}}
                                                type="link" onClick={this.installationDev_1}>
                                                安装此设备并提交
                                            </a>
                                            <a className={'addLink'} style={{float: 'right',right:'5px'}}
                                               onClick={()=>{
                                                   this.state.tableParamenter_3.params.page=1;
                                                   this.table3.refreshTable(this.state.tableParamenter_3.params);
                                               }} >
                                                <RedoOutlined />
                                                刷新
                                            </a>
                                        </div>
                                        <div style={{overflowX: 'auto'}}>
                                            <TableComponents
                                                paramenter={this.state.tableParamenter_5}
                                                setData = {getUnboundValveAlarm}
                                                setTableValue={this.setTableValue}
                                                ref={e => this.table3 = e}
                                            >
                                            </TableComponents>

                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tab="管钉" key="4">
                                    <div style={{'margin':'0 0 3px 10px',width: '99.9%',height: '100%'}}>
                                        <div style={{ "pointer-events": this.state.pointer_1,}}>
                                            <a
                                                style={{color:this.state.fontColor_1}}
                                                type="link" onClick={this.installationDev_1}>
                                                安装此设备并提交
                                            </a>
                                            <a className={'addLink'} style={{float: 'right',right:'5px'}}
                                               onClick={()=>{
                                                   this.state.tableParamenter_7.params.page=1;
                                                   this.table3.refreshTable(this.state.tableParamenter_7.params);
                                               }} >
                                                <RedoOutlined />
                                                刷新
                                            </a>
                                        </div>
                                        <div style={{overflowX: 'auto'}}>
                                            <TableComponents
                                                paramenter={this.state.tableParamenter_7}
                                                setData = {getUnboundValveAlarm}
                                                setTableValue={this.setTableValue}
                                                ref={e => this.table3 = e}
                                            >
                                            </TableComponents>

                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>

                        </div>
                        {/*右*/}
                        <div style={{width: '50%','height': '100%',float:'left',  display: this.state.rightTableOverflow?'none':null}}>
                            <Tabs
                                tabPosition={"top"}
                                animated={true}
                                activeKey={this.state.tabKey2}
                                onTabClick={(key)=>{this.setState({tabKey2:key})}}
                            >
                                <TabPane tab="报警探测器" key="1">
                                    <div style={{'margin':'0 3px 3px 10px',width: '99.9%',height: '100%'}}>
                                        <div style={{ "pointer-events": this.state.pointer_1,height:'22px'}}/>

                                        <div style={{width: '98.9%'}}>
                                            <TableComponents
                                                paramenter={this.state.bottomRightTable_2}
                                                setData = {getUnboundDevicesByConId}
                                                // setTableValue={this.setTableValue}
                                                ref={e => this.bottomRightTable2 = e}
                                            >
                                            </TableComponents>
                                        </div>
                                    </div>
                                </TabPane>

                            </Tabs>
                        </div>
                    </div>

                </div>
            </Layout>

        );
    }
}
