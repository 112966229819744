// 新增订单界面

import React,{ Component } from 'react'
import {Button, Layout, Table, Popconfirm, Modal, message,Input ,DatePicker} from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import Conditions from "../../components/common/conditions/Conditions";
import './shopping.less'
import {funTraceInfo} from '../../services/common'
import {addMeterOrder,getMetersBySteelNum} from "../../services/procurement";
import moment from "moment";

export default class shopping extends Component{
    state={
        dateFormat : 'YYYY/MM/DD',
        //订单提交基本数据
        orderFormData:{
            orderSourceId:"1",     // 采购商
            supplierId:"202",        // 供应商
            code:"",             // 订单号
            deliveryTime:'',     // 发货时间
            orderFormDetails:[]  // 表具
        },


        // 右框条件选择器参数
        steelNumParamenter: {
            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            // 文本框内容
            parameter: [
                {
                    type: 'input',
                    variableName: 'steelNum',
                    str: '表刚号',

                },

            ]
        },
        // 供应商-条件选择器参数
        orderParamenter: {

            // 是否显示条件标题
            isLable: true,
            // 是否显示清空按钮
            clearButton: true,
            // 文本框内容
            parameter: [
                {
                    type: 'select',
                    variableName: 'areaId',
                    str: '表具供应商选取',
                    data:[
                        // {key:141,value:"数据同步分子公司"},
                        // {key:142,value:"长沙新奥测试分子公司"},
                        // {key:143,value:"长沙新奥"},
                        // {key:144,value:"长沙新奥老系统其他"},
                        // {key:145,value:"长沙新奥城北站点"},
                        // {key:146,value:"长沙新奥宁乡站点"},
                        // {key:147,value:"长沙新奥城南站点"},
                        // {key:148,value:"长沙新奥浏阳站点"},
                        // {key:149,value:"长沙新奥星沙站点"},
                        // {key:150,value:"长沙新奥河西站点"},
                        // {key:151,value:"长沙新奥成员公司"},
                        // {key:152,value:"未知区域"},
                        // {key:153,value:"数据同步成员公司"},
                        // {key:176,value:"数据同步分子公司"},
                        // {key:177,value:"长沙新奥测试分子公司"},
                        // {key:178,value:"长沙新奥"},
                        // {key:179,value:"长沙新奥老系统其他"},
                        // {key:180,value:"长沙新奥城北站点"},
                        // {key:181,value:"长沙新奥宁乡站点"},
                        // {key:182,value:"长沙新奥城南站点"},
                        // {key:183,value:"长沙新奥浏阳站点"},
                        // {key:184,value:"长沙新奥星沙站点"},
                        // {key:185,value:"长沙新奥河西站点"},
                        // {key:186,value:"长沙新奥成员公司"},
                        // {key:187,value:"位置区域"},
                        // {key:188,value:"数据同步成员公司"},
                        {key:202,value:"长沙汉德史特"},
                    ]
                }
            ]
        },//
        // 左表格=订单
        leftTableData:{
            columns:[
                {title: '表刚号', dataIndex: 'steelNum', key: 'steelNum', width:200,},
                {title: '通信号', dataIndex: 'communication', key: 'communication', width:100,},
                {title: '设备类型', dataIndex: 'type', key: 'type', width:100,},
                {title: '设备型号', dataIndex: 'model', key: 'model', width:100,},
                {title: '生产商', dataIndex: 'deliverStatus', key: 'deliverStatus', width:100,},
                {width:100, title: '操作', dataIndex: 'operation',fixed: 'right',
                    render: (_, record) => (
                        <Popconfirm title="确定删除?" onConfirm={() => this.handleDelete(record)}>
                            <a>删除</a>
                        </Popconfirm>)
                },
            ],

            paginationProps:{
                total: '', // 数据总数
                pageSize: '15', // 每页条数
                current: '1', // 当前页码
            },
        },//
        leftData:[],
        // 右表格=订单
        rightTableDatas:{
            header: {
                steelNum:"表刚号",
                type:"设备类型",
                model:"设备型号",
                deliverStatus:"生产商",
                operation:"操作",
            },
            data:[],

            // 条件查询
            paginationProps:{
                areaId:null,
                steelNum:"",
                total: '', // 数据总数
                size: '13', // 每页条数
                page: '1', // 当前页码
            },
            // 表格加载动画
            loading: false,
            // 刷新动画
            refreshTable: false,
        },//
        //  右边框的表格
        rightTableData: {
            // 表格行是否可选
            rowSelection: true,
            // 表头：变量和变量解释
            columns:[
                {title: '表刚号', dataIndex: 'steelNum', key: 'steelNum',width:250,},
                {title: '通信号', dataIndex: 'communication', key: 'communication',width:100,},
                {title: '设备类型', dataIndex: 'model', key: 'model',width:200,},
                {title: '设备型号', dataIndex: 'type', key: 'type',width:100,},
                {title: '生产商', dataIndex: 'deliverStatus', key: 'deliverStatus',width:100,},
                {title: '操作', dataIndex: 'deliverTime', key: 'deliverTime',fixed: 'right',  width:80,
                    render: (_, record) =>
                        <a onClick={() => this.addOrderButton(record)}>添加</a>
                },
            ],
            data: [],// 表格数据
            paginationProps:{
                areaId:"",
                steelNum:"",
                total: '', // 数据总数
                size: '12', // 每页条数
                page: '1', // 当前页码
            }

        },
        modal1Visible:false,// 窗口开关
    };
    // 页面初始化加载
    componentDidMount=()=>{
        let user = JSON.parse(sessionStorage.getItem("user")??null);
        console.log("user");
        console.log(user);
        let {orderFormData} = this.state;
        orderFormData.orderSourceId=user.id;
        this.setState(orderFormData);
    };
    clearConditions=()=>{
        console.log("清空数据1");
        let {paginationProps} = this.state;
        paginationProps.steelNum="";
        paginationProps.total= '';
        paginationProps.size= '12';
        paginationProps.page= '1';
        this.setState(paginationProps);
        this.getMetersBySteelNum()
    };
    // 文本框数据清空-清空
    clearCondition=()=>{
        console.log("清空数据");
        let {orderFormData,paginationProps,rightTableData} = this.state;
        // 情况待提交表单数据
        orderFormData.supplier = null;          // 清空供应商
        orderFormData.deliveryTime = null;      // 清空时间
        orderFormData.orderFormDetails.splice(0,orderFormData.orderFormDetails.length);// 删除选取的表具列表
        rightTableData.data=[];
        rightTableData.paginationProps.size='12';
        rightTableData.paginationProps.total='';
        rightTableData.paginationProps.page='1';
        console.log(rightTableData.data);
        paginationProps.areaId = '';
        paginationProps.steelNum="";
        paginationProps.total= '';
        paginationProps.size= '12';
        paginationProps.page= '1';
        this.setState({orderFormData:orderFormData,paginationProps:paginationProps,rightTableData:rightTableData});
        this.emptyOrderData();// 用户重新查询清空数据
    };

    // 文本框数据获取-日期
    getDatetime=(date,dateString)=>{
        console.log("文本框数据获取-日期");
        console.log(date, dateString);
        if(dateString!==null||dateString!==""){
            let {orderFormData} = this.state;
            orderFormData.deliveryTime=orderFormData.deliveryTime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
            this.setState(orderFormData);
        }

    };
    // 文本框数据获取-查询
    getConditionsValue=(value)=>{
        console.log("文本框数据获取-查询");
        console.log(value);
        if(value.areaId===null){
            return message.info("供应商不能为空");
        }
        let {orderFormData} = this.state;
        console.log(orderFormData);
        orderFormData.supplierId=value.areaId;   // 供应商id
        this.derailingSwitch(false);
        this.setState(orderFormData);
        this.emptyOrderData();// 清空表具数据
        this.underlying(value.areaId);
    };
    underlying=(value)=>{
        let {rightTableData} =this.state;
        rightTableData.paginationProps.areaId=value;
        this.setState(rightTableData);
        this.getMetersBySteelNum();//通过区域号查询该区域下的表具列表
    };
    // 右边表格：获取当前页数和条数
    getPaginationRight = (page, pageSize) => {
        let {rightTableData} = this.state;
        rightTableData.paginationProps.page = page;
        this.setState({rightTableData: rightTableData});
        this.getMetersBySteelNum()
    };
    // 表钢号查询表具
    getBySteelNum=(value)=>{
        let {rightTableData} = this.state;
        console.log(rightTableData.paginationProps.areaId);
        if(rightTableData.paginationProps.areaId==='' || rightTableData.paginationProps.areaId===null ){
            return message.info("供应商不能为空");
        }else{
            rightTableData.paginationProps.page = 1;
            rightTableData.paginationProps.steelNum = value.steelNum;
            this.setState({rightTableData: rightTableData});
            this.getMetersBySteelNum()
        }

    };
    //通过区域号查询该区域下的表具列表
    getMetersBySteelNum=()=>{
        console.log("通过区域号查询该区域下的表具列表");
        getMetersBySteelNum(this.state.rightTableData.paginationProps).then(
            response=> {
                let {rightTableData} = this.state;
                let data = response.data;
                console.log("通过区域号查询该区域下的表具列表");
                console.log(response);
                if(data.data!==null){
                    rightTableData.data=data.data;
                    rightTableData.paginationProps.total=data.pageInfo?.total;
                    this.setState(
                        {
                            rightTableData:rightTableData,
                            loading:false,
                            refreshTable:false
                        }
                    )
                }else{
                    message.info("表具列表查询失败")
                }

            }
        )
    };


    // 清空订单数据
    emptyOrderData=()=>{

        let  {leftData} = this.state;
        console.log("leftData",leftData);
        if(leftData.length!==0){
            leftData.forEach(data=>{
                console.log("数据", data);
                this.handleDelete(data);
            });
        }
    };
    //订单号手写
    onSearch=(value)=>{
        console.log("订单号手写");
        let orderFormData = this.state.orderFormData;
        orderFormData.code=value.target.value;
        this.setState(orderFormData);
        console.log(this.state.orderFormData);
    };
    // 表格=选框触发事件
    onSelectChangesLeft=()=>{};
    // 表格=刷新按钮触发事件
    refreshTableLeft=()=>{};
    // 表格=分页功能操作分页按钮触发事件
    getPaginationLeft=()=>{};
    // 表格=使用导出按钮
    exportTableLeft=()=>{};

    // 表格=选框触发事件
    onSelectChangesRight=()=>{};
    // 表格=刷新按钮触发事件
    refreshTableRight=()=>{};
    // 表格=分页功能操作分页按钮触发事件
    getPagination=(page, pageSize)=>{
        let {rightTableData} = this.state;
        rightTableData.paginationProps.page = page;
        rightTableData.paginationProps.size = pageSize;
        this.setState({rightTableData});
        this.getMetersBySteelNum()
    };
    // 表格=使用导出按钮
    exportTableRight=()=>{};
    // 新增订单
    addOrderButton=(value)=> {
        console.log("addOrderButton",value);
        if(this.leftDataAddOrder(value)){  // 判断添加的数据是否成功

            console.log("执行删除");
           
            // this.deleteOrderData(value);
        }
    };
    // 提供删除方法
    deleteOrderData=(value)=>{
        // let rightData = [...this.state.rightData];
        // console.log(rightData);
        // for(let i=0; i<rightData.length; i++){
        //     console.log("循环判断是否存在相同值1");
        //     console.log(rightData[i]);
        //     if(rightData[i].id===value.id){
        //         console.log("循环判断是否存在相同值2");
        //         rightData.splice(i,1)
        //     }
        // }
        // this.setState({rightData:rightData})
    };
    // 左表格添加数据
    leftDataAddOrder=(value)=>{
        let {leftTableData} = this.state;
        const data = [...this.state.leftData];
        let judge =true;    // 新建一个判断是否重复的变量
        if(data.length===0){
            console.log("leftData.length===0");

            data.push(value);

        }else{
            console.log("leftData.length!==0");
            for(let i = 0; i<data.length;i++){
                console.log("循环判断是否存在相同值");
                if(data[i].id===value.id){ judge=false;}
            }
            if(judge){
                console.log("添加数据");
                data.push(value);
            }
        }
        leftTableData.paginationProps.total=data.length;
        this.setState({leftData:data});
        return judge;
    };
    // 左表格删除数据
    handleDelete=(value)=>{
        let leftData = [...this.state.leftData];
        let judge=false;
        for(let i=0;i<leftData.length;i++){
            if(leftData[i].id===value.id){
                leftData.splice(i,1);
                judge=true;
            }
        }
        if(judge){
            console.log("运行1");
        }
        this.setState({leftData:leftData})
    };
    // 提交订单
    submitOrder=()=>{
        let {orderFormData,leftData} = this.state;
        orderFormData.orderFormDetails=leftData;
        addMeterOrder(orderFormData).then(
            response=> {
                console.log(response);
                if(response.data.data){
                    funTraceInfo("采购管理", "创建表具订单", null,orderFormData)
                    message.success("提交成功");
                    this.emptyOrderData(); // 清空订单数据
                }else{
                    message.success(response.data.responseInfo.description);
                }
                this.derailingSwitch(false);
            }
        )

    };
    onClickData=()=>{};
    // 开关器
    derailingSwitch=(modal1Visible)=>{
        this.setState({modal1Visible:modal1Visible})
    };
    // 右框数据动态变更
    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'operation':
                return (
                    <a onClick={() => this.addOrderButton(rowData)}>添加</a>
                );
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : '-'
        }
    };
    showTotal=(total)=> {
        console.log(total)
    };
    // 变更表格颜色颜色
    setRightRowClassName=(value)=>{
        let leftData = this.state.leftData;   //  选购数据

        for(let i=0;i<leftData.length;i++){

            if(value.id.toString()===leftData[i].id.toString()){
                console.log("相同");
                console.log(value.id.toString());
                console.log(leftData[i].id);
                return 'table-color-dust'
            }else if(value.id===452744){
                console.log("1相同");
            }
        }
    };
    render() {
//         console.log(11111,this.state.orderParamenter)
//         let menusCache = JSON.stringify(localStorage.getItem('menus') ?? null)
// console.log(menusCache);

        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <div id={shopping}>
                    <div className={'div_back_arrow'}>
                        <span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
                        <span className={'font_size1'}>返回订单管理</span>
                        <span className={' font_div '}>表号订单管理</span>
                    </div>
                    <div className={"Conditions_div"}>
                        <div className={"Conditions_div1"}>
                            <Conditions
                                conditionsParamenter={this.state.orderParamenter} // 条件框输入框设置
                                getConditionsValue={this.getConditionsValue} // 获取条件框输入的值
                                clearCondition={this.clearCondition} // 清除条件
                            >
                            </Conditions>
                        </div>

                    </div>
                    {/*左框*/}
                    <div className={"leftTableData_div"}>
                        <div className={"leftTableData_button_div"}>
                            <div className={"button_div"}>
                                <Button type={'primary'} onClick={() => this.derailingSwitch(true)}>提交订单</Button>&nbsp;
                            </div>
                            <div>
                                <span>请输入订单号：</span>
                                <Input type="text" placeholder="输入单号" style={{ width: 200 }} onChange={this.onSearch}/>&nbsp;
                                <span>请输入发货时间：</span>
                                <DatePicker defaultValue={moment(new Date(),'YYYY-MM-DD')} onChange={this.getDatetime} />
                            </div>
                        </div>

                        <div className={"leftTableData_Top_div1"}>
                            <div className={"leftTableData_Title_div"}>
                                <span className={'font_size0'}>选购列表</span>
                            </div>
                        </div>
                        <Table
                            bordered
                            pagination = {{
                                showSizeChanger:true,
                                showQuickJumper: true,
                                onChange: this.showTotal,       // 获得翻页数值
                                total:this.state.leftData.length,
                                pageSize:12,
                                showTotal: (count = this.state.leftData.length)=>{
                                    return '共'+this.state.leftData.length+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['12']
                            }}
                            dataSource={this.state.leftData}
                            columns={this.state.leftTableData.columns}
                            rowKey={"id"}
                            scroll={{ y: 750,x:1200 }}
                            // onRow={this.onClickRow}
                            // rowClassName={this.setRightRowClassName}
                        />

                    </div>
                    {/*左框*/}
                    {/*右框*/}
                    <div className={"rightTableData_div"}>
                        <div className={"rightTableData_font_div"}>
                            <Conditions
                                conditionsParamenter={this.state.steelNumParamenter} // 条件框输入框设置
                                getConditionsValue={this.getBySteelNum} // 获取条件框输入的值
                                clearCondition={this.clearConditions} // 清除条件
                            >
                            </Conditions>
                        </div>
                        <div className={"rightTableData_Top_div"}>
                            <div className={"rightTableData_Title_div"}>
                                <span className={'font_size0'}>表具列表</span>
                            </div>
                        </div>
                        <Table
                            bordered
                            pagination = {{
                                showSizeChanger:true,
                                showQuickJumper: true,
                                onChange: this.getPaginationRight,       // 获得翻页数值
                                total:this.state.rightTableData.paginationProps.total,
                                pageSize:12,
                                showTotal: (count = this.state.rightTableData.paginationProps.total)=>{
                                    return '共'+this.state.rightTableData.paginationProps.total+'条数据'
                                },
                                defaultCurrent: 1,
                                pageSizeOptions: ['12']
                            }}
                            dataSource={this.state.rightTableData.data}
                            columns={this.state.rightTableData.columns}
                            rowKey={"id"}
                            scroll={{ y: 750,x:1200 }}
                            onRow={this.onClickRow}
                            rowClassName={this.setRightRowClassName}
                        />

                    </div>
                    {/*右框END*/}
                    {/*窗口-2*/}
                    <Modal
                        title="待生成订单"
                        style={{ top: 0 }}
                        visible={this.state.modal1Visible}
                        okText="发货"
                        onOk={() => this.submitOrder(false)}                  // 点击确定
                        onCancel={() => this.derailingSwitch(false)}       // 点击取消
                        width={200}
                        centered
                    >
                        <div>
                            <span  className={'Modal_title_div'}>确认提交订单</span>
                        </div>

                    </Modal>
                </div>
            </Layout>

        )
    }
}
