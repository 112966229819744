import React, { Component } from 'react'
import {LoadingOutlined,DoubleLeftOutlined,GlobalOutlined,CloseCircleOutlined,PlusOutlined} from '@ant-design/icons';
import './mapManagePage_v.1.less'
import * as echarts from "echarts";
import moment from "moment";
import $ from "jquery";
import HalfDoughnutChart from "./js/HalfDoughnutChart";
import BasicLineChart from "./js/BasicLineChart";
import {Input, Pagination, Popconfirm, Tooltip} from "antd";
import {pageValveInfo} from "../../services/qrCode";
import {getInfoByAreaIds, pageAlarmInfo, queryAccumulates} from "../../services/customer";
import BarAndLineCharts from "./js/BarAndLineCharts";
import {largeScreenMeterAnalysis} from "../../services/customerDataPanel";
import {queryResidentialCommunity, regionManagementUsage} from "../../services/community";

import imgUrl from './img/多云@2x.png'
const {AMap} =window;
// 可视化地图管理平台0.1版
export default class mapManagePage extends Component {
    state={

        href:"http://localhost:3000",
        shrink_type:true,    // 数据面板收缩
        headLeftDate:'时间',  // 时间

        coordinateData:[],
        allCoordinateShow:[],// 管道地图


// 悬浮按钮
        suspension_button_type:'阀门井',// 悬浮按钮样式
        //左侧类型列表
        suspension_button_list:[
            {name:'阀门井',param:10200},
            {name:'调压柜',param:10211},
        ],
        globalOutlined_type:null,
        suspension_loading:false,// 右侧数据加载
        // 右侧数据查询参数
        suspension_tableParams:{
            areaIds:[352],
            devType: 10200,
            page: 1,size: 12
        },
        suspension_tableDataS:[],// 右侧数据列表
        totalCount:0,            // 右侧数据展示列表总量
        valueMapData:[],         // 地图坐标列表

// 第一格
        dev_button_type_1:'阀门井',
        //左侧类型列表
        dev_button_list:[
            {name:'阀门井',param:10200},
            {name:'调压柜',param:10211},
            {name:'表具',param:10117},
            // {name:'表具',param:{}},
        ],
        // 表格-标题
        dev_table_head_title:[
            {c:'设备名',e:'devName'},
            {c:'事件',e:'info'},
            {c:'发生时间',e:'alarmTime'}
            ],
        // 内容
        dev_table_head_data:[],
        dev_table_loading:false,
        dev_table_param:{
            "areaIds": JSON.parse(localStorage.getItem('menus'))??null,
            "devType": 10200,
            "page": 1,
            "size": 8,
            determine:true//翻滚请求参数
        },
// 第二格
        industryNums:2564,//工商户数
        // 环形圆进度条-工商户
        halfDoughnutChart_1:{name:'立方',data:75,color:['rgba(240,150,36,0.9)','#fbf1de']},
        // 使用情况 最近七日曲线图
        BasicLineChart_1:{
            name:"7日用气量",
            // 数值
            data:[
                {name:'工商户',data:[170,120,80,110,160,140,170],color:['#67ff96','#b3ffc2']},
            ],
            yData:['2024-8-18','2024-8-19','2024-8-20','2024-8-21','2024-8-22','6月','2024-8-23'],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            // yData:[],   // X轴数据 '第1天','第2天','第3天','第4天','第5天','第6天','第7天'
            number:{min:0,max:100,interval:50} // 最小、最大、间距
        },


//第三格

        homeNums:0,//居民数
        // 环形圆进度条-居民户
        halfDoughnutChart_2:{
            name:'立方',data:70,color:['rgba(240,150,36,0.9)','#fbf1de']
        },
        // 差曲线图/差柱状图
        barAndLineCharts: {
            xData:['2024-8-18','2024-8-19','2024-8-20','2024-8-21','2024-8-22','6月','2024-8-23'],
            yData_left:{min:0,max:100,interval:500},
            yData_right:{min:0,max:25,interval:15},
            data_1:[111,222,333,231,123,453,131],
            data_2:[111,222,333,231,123,453,131]


            // xData:[],
            // yData_left:{min:0,max:100,interval:500},
            // yData_right:{min:0,max:25,interval:15},
            // data_1:[],
            // data_2:[]
        },

//第四格

        areaUsage:1463,// 区域用量
        stripData1:75,   // 昨日用气总量-条
        stripData2:85,   // 昨日供气总量-条
        "newAddNum": '110',          // 本月新增
        "newInstallNum": '80',      // 本月新装
        "meterReadingRate": '10',   // 抄表率
        "abnormalNum": '20',        // 异常数

        // 表格-标头
        table_head_title:[
            {c:'小区名称',e:'name'},
            {c:'用户数',e:'cusCounts'},
            {c:'昨日用气量',e:'dosages'},
            {c:'异常用气量',e:'alarms'},
        ],
        // 表格-内容
        table_head_data:[],

    };
    componentDidMount(): void {

        console.log(localStorage.getItem('menus'));
        console.log(JSON.parse(localStorage.getItem('menus')));

        var href = window.location.href;
        var port = window.location.port;
        this.setState({href:href.substr(0,href.indexOf(port))+port});

        this.getTime();                 // 时间
        this.getDate();                 // 天气预备
        this.initialization_valueMap(); // 初始化地图

        // 悬浮框表格参数
        var tableParams = this.state.suspension_tableParams;
        tableParams.areaIds=JSON.parse(localStorage.getItem('menus'))??[200];
        tableParams.devType= 10200;
        this.setState({
            tableParams:tableParams,
            selectMode:10200
        });
        // this.queryAccumulates(tableParams.areaIds);          // 二和三格数据及四格-查询工商和民用的用气量的数据
        // this.queryResidentialCommunity(tableParams.areaIds); // 四格-查询小区列表-暂不需要
        this.getInfoByAreaIds(tableParams.areaIds);         // 地图-根据区域ID查询管道信息
        this.largeScreenMeterAnalysis(tableParams.areaIds); // 四格-表具分析-四方格
        this.regionManagementUsage();                       // 四格-查询小区列表
        this.pageValveInfo(tableParams);                    // 地图-获取阀井数据列表
        this.pageAlarmInfo(this.state.dev_table_param);     // 第一格-查询不同设备类型下的报警信息
    };

    // 四格-查询小区列表-暂不需要
    queryResidentialCommunity=(areaIds)=>{
        var param={
            areaIds:areaIds,
            page:1,
            size:99
        };
        queryResidentialCommunity(param).then(
            response=>{

                if(response.data.data){

                    var ids=[];
                    response.data.data.map(one=>{ids.push(Number(one.id))});
                    if(ids.length!==0){
                        this.regionManagementUsage(ids,response.data.data);
                    }
                }
            }
        )
    };

    // 获取小区异常数据，获取的ids，小区列表
    regionManagementUsage=(regionManagementIds=[],tableS,areaId)=>{
        var tableHeadData = [...this.state.table_head_data];
        tableHeadData=[];
        var params =  new URLSearchParams();
        params.append('areaId',areaId??JSON.parse(localStorage.getItem('menus')));
        regionManagementUsage(params).then(
            response=>{
                if(response.data?.data){
                    var datas = response.data.data;
                    datas.map(one=>{
                        var num1 = Math.floor(Math.random(1) * Math.floor(30));
                        var num2 = Math.floor(Math.random() * Math.floor(100));
                        var num3 = Math.floor(Math.random() * Math.floor(100));
                        one.cusCounts=num1;
                        one.dosages=num2;
                        one.alarms=num3;
                        tableHeadData.push(one)
                    });
                    // {c:'昨日用气量',e:'dosages'},
                    // {c:'异常用气量',e:'alarms'},


                    return this.setState({table_head_data:tableHeadData})
                }else{
                    tableHeadData=tableS;
                    return this.setState({table_head_data:tableHeadData})
                }
            }
        ).catch(e=>{
            tableHeadData=tableS;
            return this.setState({table_head_data:tableHeadData})
        })
    };

    // 表具分析(2024-02-28新增)
    largeScreenMeterAnalysis=(aredIds)=>{
        var params =  new URLSearchParams();
        params.append('areaId',aredIds);
        largeScreenMeterAnalysis(params).then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    // id: 150, type: "户内管道设施", name: "管道未按规范暗设", time: null, level: "II", state: "已整改", checkId: "39",…
                    this.setState({
                        "meterReadingRate": Math.round(Number(data?.meterReadingRate ))+'%'??'0%',  // 抄表率-左上
                        "newInstallNum": data?.newInstallNum??'0',                                  // 本月新装-右上
                        "newAddNum": data?.newAddNum??'0',                                          // 本月新增-左下
                        "abnormalNum": Math.round(Number(data?.abnormalNum ))??'0',                 // 异常数-右下
                    });
                }
            }
        )
    };

    // 查询工商和民用的用气量的数据
    queryAccumulates=(areaIds)=>{
        var params =  new URLSearchParams();
        params.append('areaIds',areaIds);
        queryAccumulates(params).then(
            response=>{
                // 工商户-圆形图,工商户-曲线图。居民户-圆形图,居民户-折线和条状
                var {halfDoughnutChart_1,BasicLineChart_1,halfDoughnutChart_2,barAndLineCharts} = this.state;
                if(response?.data?.data){
                    var data = response?.data?.data;
                    //第二格
                    halfDoughnutChart_1.data=data?.industryDosage??0;   // 工商户圆环数据
                    // 拼凑折线图
                    var devData = {name:'工商7日用气量',data:[],color:['#67ff96','#b3ffc2']};
                    data?.industryMaps.map(one=>{
                        devData.data.push(one.value??0);                // 更新数据
                        BasicLineChart_1.yData.push(one.time);          // 更新日期
                    });
                    BasicLineChart_1.data.push(devData);                // 加入参数内
                    //第三格
                    halfDoughnutChart_2.data=data?.homeDosage??0;       // 居民户圆环数据

                    data?.homeMaps.map(one=>{
                        barAndLineCharts.data_1.push(one.value??0);
                        barAndLineCharts.xData.push(one.time);
                    });

                    this.setState({
                        halfDoughnutChart_1:halfDoughnutChart_1,
                        BasicLineChart_1:BasicLineChart_1,
                        halfDoughnutChart_2:halfDoughnutChart_2,
                        barAndLineCharts:barAndLineCharts,
                        areaUsage:data.areaUsage??0,    // 昨日用气总量
                        stripData1:data.areaUsage??0    // 昨日用气总量
                    });
                    this.halfDoughnutChart1.updateData();                   // 更新数据-2格-环形图
                    this.BasicLineChart1.setYData(BasicLineChart_1.yData);  // 更新折线图日期数据-2格-折线图-日期
                    this.BasicLineChart1.setData(BasicLineChart_1.data);    // 更新折线图数据    -2格-折线图-数据
                    this.halfDoughnutChart2.updateData();                   // 更新数据          -3格-环形图
                    this.barAndLineCharts1.updateData();                    // 更新折线图数据-3格-柱状图-数据

                }
            }
        )
    };


    // 根据区域ID查询管道信息
    getInfoByAreaIds=(areaIds=[],name)=>{
        var params =  new URLSearchParams();
        params.append('areaIds',areaIds??null);
        // params.append('name',name??null);
        getInfoByAreaIds(params).then(
            response=>{
                var coordinateData = this.state.coordinateData;
                coordinateData=[];
                if(response?.data?.data){
                    response?.data?.data.map(one=>{
                        coordinateData.push({
                            "coordinateData":  one.coordinateData??null,
                            "id": one.id??null,
                            "name":  one.name??null,
                            "style":  one.style??null,
                        });
                    });

                }
                this.piecingCoordinate(coordinateData);// 拼凑数据 data：数据,并调取地图

                this.setState({coordinateData:coordinateData});
            }
        )
    };
    // 拼凑数据 data：数据
    piecingCoordinate = (data=[]) =>{
        var coordinateDatas=[];
        data.map(one=>{
            // 验证数据是否合适
            if(this.ValidateData(one.coordinateData)){
                coordinateDatas.push(JSON.parse(one.coordinateData)[0])
            }
        });
        this.configure_punctuation([],[],coordinateDatas);       // 地图
        this.setState({allCoordinateShow:coordinateDatas}); // 管道
    };
    // 验证数据格式是否合适
    ValidateData=(data)=>{
        try{
            if(data ==="" && data === null){return false;}
            if(data.indexOf('[')<0){return false;}
            var params = JSON.parse(data); // 字符串转数据对象
            // 判断当前对象是否数组
            if(!Array.isArray(params)){return false;}
            // 判断对象第二层是否数组
            for(var i=0;i<params.length;i++){if(!Array.isArray(params[i])){
                return false;
            }}
            return true;
        }catch (e) {return false;}
    };
    // 获取阀井数据列表
    pageValveInfo=(params)=>{
        this.setState({suspension_loading:true});
        if(!params?.areaIds){return false;}
        if(!Array.isArray(params.areaIds)){
            params.areaIds=[params.areaIds]
        }
        pageValveInfo(params).then(
            response=>{
                var tableDataS =this.state.suspension_tableDataS;
                var valueMapData=this.state.valueMapData;
                tableDataS=[];
                valueMapData=[];
                if(response?.data?.data){
                    let { data=[],pageInfo } = response?.data;
                    console.log('配置地图列表');
                    data.map(one=>{
                        var e = {
                            "id": one.id,                           // id
                            "devId":one.devId,                      // 设备id
                            "name": one.devName,                    // 设备名
                            'installPosition':one.installPosition,  // 安装位置
                            "value": [],                            // 坐标
                            "systemTime":one.data[0].systemTime,    // 通讯时间
                            "data_1":null,                          // 溶度
                            "data_2":null,                          // 阀井压力
                            "data_3":null,                          // 阀井温度
                            "data_4":null,                          // 异动
                            "data_5":null,                          // 液位
                            "data_6":null,                          // 电池
                            color: '#fa8c16'                        // 该坐标颜色
                        };
                        // 坐标
                        if(one.voLocation?.longitude && one.voLocation?.latitude){e.value = [one.voLocation?.longitude,one.voLocation?.latitude,200]}

                        // 数据拼凑，用于地图展示
                        if(one){
                            if(one?.data.length!==0){
                                one?.data.map(two=>{
                                    // 溶度
                                    if(two.itemId===3219){e.data_1 = two?.defaultValue?? '-';}
                                    // 阀井压力
                                    else if(two.itemId===3221){e.data_2 = two?.defaultValue?? '-';}
                                    // 温度
                                    else if(two.itemId===1205){e.data_3 = two?.defaultValue?? '-';}
                                    // 异动
                                    else if(two.itemId===3235){e.data_4 = two?.defaultValue?? '-';}
                                    // 液位
                                    else if(two.itemId===3224){e.data_5 = two?.defaultValue?? '-';}
                                    // 电池
                                    else if(two.itemId===3226){e.data_6 = two?.defaultValue?? '-';}
                                });
                            }
                        }
                        valueMapData.push(e)
                    });
                    this.setState({
                        suspension_loading:false,
                        suspension_tableDataS:data,
                        totalCount:pageInfo?.total??0,
                        valueMapData:valueMapData
                    });
                }else{
                    this.setState({
                        suspension_loading:false,
                        suspension_tableDataS:tableDataS,
                        totalCount:0,
                        valueMapData:valueMapData
                    });
                }
                this.configure_punctuation(valueMapData,null,this.state.allCoordinateShow)
            }
        )
    };
    // 初始化配置地图
    initialization_valueMap(){
        if (echarts.getInstanceByDom(document.getElementById('deviceModelMapId'))) {
            echarts.dispose(document.getElementById('deviceModelMapId'));
        }
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        var xy=[111.63101871462548,26.214717170445535];    // 默认坐标
        chartDom.setOption(
            {
            tooltip: {
                show:true,
                triggerOn: 'click',
                trigger: 'item',
                borderColor: 'rgba(0,0,0,0)',
                backgroundColor: 'rgba(0,0,0,0)',
                padding:[0,0],
                borderWidth: 0,
                formatter: function (params) {
                    console.log(params);
                    if(params?.componentSubType==='lines'){return null;}
                    // 自定义格式化内容
                    return `
                <div style="width: 500px; height: 290px;background-color: #00b6ec; border: 1px #cccccc solid;">
                    <div style="width: 500px; height: 120px;background-color: #629d3b;">
                        <div style="width: 500px; height: 40px;">
                            <div style="color: #fff;font-size: 16px;font-weight: bold; padding: 10px 0 0 50px">阀门井智慧终端</div>
                        </div>
                        <div style="width: 500px; height: 100px;">
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备编号: `+ params.data.devId +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">设备名称: `+ params.data.name +`</div>
                            <div style="color: #fff;font-size: 14px;padding-left: 100px">安装位置: `+ params.data.installPosition +`</div>
                        </div>
                    </div>
                    <div style="width: 500px; height: 170px;background-color: #fff;">
                        <div style="width: 60%; height: 100%; padding: 10px 0 0 60px;float: left">
                            <div style="height: 40px;font-size: 14px;color:#000">通讯时间: `+ params.data.systemTime +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">甲烷溶度: `+ params.data.data_1 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井压力: `+ params.data.data_2 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">阀井温度: `+ params.data.data_3 +`</div>
                        </div>
                        <div style="width: 40%; height: 100%; padding: 10px;float: left">
                            <div style="height: 40px;"/>
                            <div style="height: 40px;font-size: 14px;color:#000">异动: `+ params.data.data_4 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">液位: `+ params.data.data_5 +`</div>
                            <div style="height: 40px;font-size: 14px;color:#000">电池: `+ params.data.data_6 +`</div>
                        </div>
                    </div>
                </div>
            `;
                }
            },
            animation: false,

            bmap: {
                // center: xy,
                center: [111.636422,26.238939],
                zoom: 15,
                // roam: 'true',
                roam: 'move',// scale 禁止平移，move，禁止缩放
                animation: true,

                // 地图样式
                mapStyle: {
                    styleJson: [
                        {
                            featureType: 'water',// 水
                            elementType: 'all',
                            stylers: {color: '#1f2f4e'}
                        },
                        {
                            featureType: 'land',// 土地 35312b 1a2435
                            elementType: 'all',
                            stylers: {color: '#35312b'}
                        },
                        {
                            featureType: 'railway',// 铁路
                            elementType: 'all',
                            stylers: {visibility: 'off'}
                        },
                        {
                            featureType: 'highway',// 公路
                            elementType: 'all',
                            stylers: {color: '#23221d'}
                        },
                        {
                            featureType: 'highway',// 公路
                            elementType: 'labels',
                            stylers: {visibility: 'off'}
                        },
                        {
                            featureType: 'arterial',// 山脉
                            elementType: 'geometry',
                            stylers: {color: '#fefefe'}
                        },
                        {
                            featureType: 'arterial',// 山脉
                            elementType: 'geometry.fill',
                            stylers: {color: '#fefefe'}
                        },
                        {
                            featureType: 'poi',
                            elementType: 'all',
                            stylers: {visibility: 'off', color: '#fefefe'}
                        },
                        {
                            featureType: 'green',
                            elementType: 'all',
                            stylers: {visibility: 'off'}
                        },
                        {
                            featureType: 'subway',// 地铁
                            elementType: 'all',
                            stylers: {visibility: 'off'}
                        },
                        {
                            featureType: 'manmade',
                            elementType: 'all',
                            stylers: {color: '#d1d1d1'}
                        },
                        // {
                        //     featureType: 'local',
                        //     elementType: 'all',
                        //     stylers: {
                        //         color: '#d1d1d1'
                        //     }
                        // },
                        // {
                        //     featureType: 'arterial',
                        //     elementType: 'labels',
                        //     stylers: {
                        //         visibility: 'off'
                        //     }
                        // },
                        {
                            featureType: 'boundary',
                            elementType: 'all',
                            stylers: {color: '#fefefe'}
                        },
                        {
                            featureType: 'building',
                            elementType: 'all',
                            stylers: {color: '#d1d1d1'}
                        },
                        // {
                        //     featureType: 'label',
                        //     elementType: 'labels.text.fill',
                        //     stylers: {
                        //         color: '#999999'
                        //     }
                        // }
                    ]
                },
            },
            series: []
        }


        );
        // 窗口大小变化时，ECharts地图自适应
        window.onresize = function() {chartDom.resize();};
    };
    // 配置地图 data 坐标, location 指定经纬度, e 管道
    configure_punctuation(data=[],location=[],e=[]){
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        // 坐标
        var datas=[];
        if(data.length<0){datas = data;}
        else{datas = this.state.valueMapData;}
        // 管道
        var lines_1;
        if(e.length<0){lines_1 = e.map(function (track) {return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};});}
        else{lines_1 = this.state.allCoordinateShow.map(function (track) {return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};});}

        chartDom.setOption({
            series: [
                // 标点
                {
                    name: '标点',
                    type: 'effectScatter',
                    // type: 'scatter',
                    coordinateSystem: 'bmap',
                    // data: data,
                    data: datas.map(function (pipe) {
                        return {
                            id: pipe.id,
                            devId:pipe.devId,
                            name: pipe?.name,
                            data:pipe?.data,
                            installPosition:pipe.installPosition,
                            value:pipe?.value,
                            systemTime:pipe?.systemTime??null,                      // 通讯时间
                            data_1:pipe?.data_1??null,                          // 溶度
                            data_2:pipe?.data_2??null,                          // 阀井压力
                            data_3:pipe?.data_3??null,                          // 阀井温度
                            data_4:pipe?.data_4??null,                          // 异动
                            data_5:pipe?.data_5??null,                          // 液位
                            data_6:pipe?.data_6??null,                          // 电池
                            itemStyle: {
                                color: pipe?.color,
                                borderColor : 'rgb(240,86,0)'
                            }
                        };
                    }),

                    // 自定义
                    // symbol:'image://'+imgUrl,
                    // symbolSize: [50,50],
                    // showEffectOn:'emphasis',
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        show: true,
                    },
                    // 默认
                    encode: {value: 2},
                    symbolSize: 300/15,
                    showEffectOn: 'emphasis',// 标点波动方式
                    rippleEffect:{number:3,color: 'rgb(240,86,0,0.5)',},// 波动样式
                },
                // 管道
                {
                    type: 'lines',
                    coordinateSystem: 'bmap',
                    data:  lines_1.map(function (pipe) {
                        return {
                            coords: pipe.coords,
                            lineStyle: {
                                width: 3,
                                opacity: 10,
                                color: 'rgba(0,255,33,0.9)',
                            }
                        };
                    }),
                    polyline: true,
                },
            ]
        });
    };
    // 配置地图 data 坐标, location 指定经纬度, e 管道
    configure_conduit(e=[]){
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        // 管道
        var lines_1;
        if(e.length<0){lines_1 = e.map(function (track) {return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};});}
        else{lines_1 = this.state.allCoordinateShow.map(function (track) {return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};});}

        chartDom.setOption({
            series: [
                // 管道
                {
                    type: 'lines',
                    coordinateSystem: 'bmap',
                    data:  lines_1.map(function (pipe) {
                        return {
                            coords: pipe.coords,
                            lineStyle: {
                                width: 3,
                                opacity: 10,
                                color: 'rgba(0,255,33,0.9)',
                            }
                        };
                    }),
                    polyline: true,
                },
            ]
        });
    };

    // 坐标跳转
    coordinate_valueMaps(id,location=[]){
        let mapdata=Object.assign([],this.state.valueMapData);
        mapdata.map(one=>{
           if(one.id===id){
               one.color='red';
           }else{
               one.color='#fa8c16';
           }
        });

        if(location.length<1){
            return false;
        }
        var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
        chartDom.setOption({
            bmap: {
                center: [location[0],location[1]] // 上海的经纬度
            },
            series: [
                // 标点
                {
                    name: '标点',
                    type: 'effectScatter',
                    coordinateSystem: 'bmap',
                    // data: data,
                    data: mapdata.map(function (pipe) {
                        return {
                            id: pipe.id,
                            devId:pipe.devId,
                            name: pipe?.name,
                            data:pipe?.data,
                            installPosition:pipe.installPosition,
                            value:pipe?.value,
                            systemTime:pipe?.systemTime??null,                      // 通讯时间
                            data_1:pipe?.data_1??null,                          // 溶度
                            data_2:pipe?.data_2??null,                          // 阀井压力
                            data_3:pipe?.data_3??null,                          // 阀井温度
                            data_4:pipe?.data_4??null,                          // 异动
                            data_5:pipe?.data_5??null,                          // 液位
                            data_6:pipe?.data_6??null,                          // 电池
                            itemStyle: {
                                color: pipe?.color,
                                borderColor : 'rgb(240,86,0)'
                            }
                        };
                    }),
                    encode: {value: 2},
                    symbolSize: 300/15,
                    showEffectOn: 'emphasis',// 标点波动方式
                    label: {
                        formatter: '{b}',
                        position: 'bottom',
                        show: true,
                    },
                    rippleEffect:{number:3,color: 'rgb(240,86,0,0.5)',},// 波动样式
                },
                ]
        })
    }
    // 地图 data 坐标, location 指定经纬度, e 管道
    // valueMaps1(data=[],location=[],e=[]){
    //     var _this=this;
    //     if (echarts.getInstanceByDom(document.getElementById('deviceModelMapId'))) {
    //         echarts.dispose(document.getElementById('deviceModelMapId'));
    //     }
    //     var chartDom = echarts.init(document.getElementById('deviceModelMapId'));
    //     var xy=[111.63101871462548,26.214717170445535];    // 默认坐标
    //     if(location.length!==0){xy = location}             // 判断有无选取经纬度
    //     else if(data.length!==0){                          // 判断有无列表
    //         // 判断有无地址获取经纬度
    //         if(data[0]?.value.length>2){xy =[data[0]?.value[0],data[0]?.value[1]];}
    //     }
    //
    //     var lines_1;
    //     if(e.length<0){
    //         lines_1 = e.map(function (track) {
    //             return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};
    //         });
    //     }else{
    //         lines_1 = this.state.allCoordinateShow.map(function (track) {
    //             return {coords: track.map(function (seg, idx) {return [seg.y,seg.x];})};
    //         });
    //     }
    //
    //     chartDom.setOption({
    //         tooltip: {
    //             show:true,
    //             triggerOn: 'click',
    //             trigger: 'item',
    //             borderColor: 'rgba(0,0,0,0)',
    //             backgroundColor: 'rgba(0,0,0,0)',
    //             padding:[0,0],
    //             borderWidth: 0,
    //             formatter: function (params) {
    //                 // 自定义格式化内容
    //
    //                 return `
    //             <div style="width: 500px; height: 290px;background-color: #00b6ec; border: 1px #cccccc solid;">
    //                 <div style="width: 500px; height: 120px;background-color: #629d3b;">
    //                     <div style="width: 500px; height: 40px;">
    //                         <div style="color: #fff;font-size: 16px;font-weight: bold; padding: 10px 0 0 50px">阀门井智慧终端</div>
    //                     </div>
    //                     <div style="width: 500px; height: 100px;">
    //                         <div style="color: #fff;font-size: 14px;padding-left: 100px">设备编号: `+ params.data.devId +`</div>
    //                         <div style="color: #fff;font-size: 14px;padding-left: 100px">设备名称: `+ params.data.name +`</div>
    //                         <div style="color: #fff;font-size: 14px;padding-left: 100px">安装位置: `+ params.data.installPosition +`</div>
    //                     </div>
    //                 </div>
    //                 <div style="width: 500px; height: 170px;background-color: #fff;">
    //                     <div style="width: 60%; height: 100%; padding: 10px 0 0 60px;float: left">
    //                         <div style="height: 40px;font-size: 14px;color:#000">通讯时间: `+ params.data.systemTime +`</div>
    //                         <div style="height: 40px;font-size: 14px;color:#000">甲烷溶度: `+ params.data.data_1 +`</div>
    //                         <div style="height: 40px;font-size: 14px;color:#000">阀井压力: `+ params.data.data_2 +`</div>
    //                         <div style="height: 40px;font-size: 14px;color:#000">阀井温度: `+ params.data.data_3 +`</div>
    //                     </div>
    //                     <div style="width: 40%; height: 100%; padding: 10px;float: left">
    //                         <div style="height: 40px;"/>
    //                         <div style="height: 40px;font-size: 14px;color:#000">异动: `+ params.data.data_4 +`</div>
    //                         <div style="height: 40px;font-size: 14px;color:#000">液位: `+ params.data.data_5 +`</div>
    //                         <div style="height: 40px;font-size: 14px;color:#000">电池: `+ params.data.data_6 +`</div>
    //                     </div>
    //                 </div>
    //             </div>
    //         `;
    //             }
    //         },
    //         animation: false,
    //         bmap: {
    //             center: xy,
    //             zoom: 16,
    //             roam: true,
    //             animation: true,
    //             // 地图样式
    //             mapStyle: {
    //                 styleJson: [
    //                     {
    //                         featureType: 'water',// 水
    //                         elementType: 'all',
    //                         stylers: {color: '#1f2f4e'}
    //                     },
    //                     {
    //                         featureType: 'land',// 土地
    //                         elementType: 'all',
    //                         stylers: {color: '#35312b'}
    //                     },
    //                     {
    //                         featureType: 'railway',// 铁路
    //                         elementType: 'all',
    //                         stylers: {visibility: 'off'}
    //                     },
    //                     {
    //                         featureType: 'highway',// 公路
    //                         elementType: 'all',
    //                         stylers: {color: '#23221d'}
    //                     },
    //                     {
    //                         featureType: 'highway',// 公路
    //                         elementType: 'labels',
    //                         stylers: {visibility: 'off'}
    //                     },
    //                     {
    //                         featureType: 'arterial',// 山脉
    //                         elementType: 'geometry',
    //                         stylers: {color: '#fefefe'}
    //                     },
    //                     {
    //                         featureType: 'arterial',// 山脉
    //                         elementType: 'geometry.fill',
    //                         stylers: {color: '#fefefe'}
    //                     },
    //                     {
    //                         featureType: 'poi',
    //                         elementType: 'all',
    //                         stylers: {visibility: 'off', color: '#fefefe'}
    //                     },
    //                     {
    //                         featureType: 'green',
    //                         elementType: 'all',
    //                         stylers: {visibility: 'off'}
    //                     },
    //                     {
    //                         featureType: 'subway',// 地铁
    //                         elementType: 'all',
    //                         stylers: {visibility: 'off'}
    //                     },
    //                     {
    //                         featureType: 'manmade',
    //                         elementType: 'all',
    //                         stylers: {color: '#d1d1d1'}
    //                     },
    //                     // {
    //                     //     featureType: 'local',
    //                     //     elementType: 'all',
    //                     //     stylers: {
    //                     //         color: '#d1d1d1'
    //                     //     }
    //                     // },
    //                     // {
    //                     //     featureType: 'arterial',
    //                     //     elementType: 'labels',
    //                     //     stylers: {
    //                     //         visibility: 'off'
    //                     //     }
    //                     // },
    //                     {
    //                         featureType: 'boundary',
    //                         elementType: 'all',
    //                         stylers: {color: '#fefefe'}
    //                     },
    //                     {
    //                         featureType: 'building',
    //                         elementType: 'all',
    //                         stylers: {color: '#d1d1d1'}
    //                     },
    //                     // {
    //                     //     featureType: 'label',
    //                     //     elementType: 'labels.text.fill',
    //                     //     stylers: {
    //                     //         color: '#999999'
    //                     //     }
    //                     // }
    //                 ]
    //             },
    //         },
    //
    //         series: [
    //             // 标点
    //             {
    //                 name: '标点',
    //                 type: 'effectScatter',
    //                 coordinateSystem: 'bmap',
    //                 // data: data,
    //                 data: this.state.valueMapData.map(function (pipe) {
    //
    //                     return {
    //                         id: pipe.id,
    //                         devId:pipe.devId,
    //                         name: pipe?.name,
    //                         data:pipe?.data,
    //                         installPosition:pipe.installPosition,
    //                         value:pipe?.value,
    //                         systemTime:pipe?.systemTime??null,                      // 通讯时间
    //                         data_1:pipe?.data_1??null,                          // 溶度
    //                         data_2:pipe?.data_2??null,                          // 阀井压力
    //                         data_3:pipe?.data_3??null,                          // 阀井温度
    //                         data_4:pipe?.data_4??null,                          // 异动
    //                         data_5:pipe?.data_5??null,                          // 液位
    //                         data_6:pipe?.data_6??null,                          // 电池
    //                         itemStyle: {
    //                             color: pipe?.color,
    //                             borderColor : 'rgb(240,86,0)'
    //                         }
    //                     };
    //                 }),
    //                 encode: {value: 2},
    //                 symbolSize: 300/15,
    //                 showEffectOn: 'emphasis',// 标点波动方式
    //                 label: {
    //                     formatter: '{b}',
    //                     position: 'bottom',
    //                     show: true,
    //                 },
    //                 rippleEffect:{number:3,color: 'rgb(240,86,0,0.5)',},// 波动样式
    //             },
    //             // 管道
    //             {
    //                 type: 'lines',
    //                 coordinateSystem: 'bmap',
    //                 data:  lines_1.map(function (pipe) {
    //                     return {
    //                         coords: pipe.coords,
    //                         lineStyle: {
    //                             width: 3,
    //                             opacity: 10,
    //                             color: 'rgba(0,255,33,0.9)',
    //                         }
    //                     };
    //                 }),
    //                 polyline: true,
    //             },
    //         ]
    //     });
    //
    //     // 点击地图图标触发
    //     chartDom.on('click',function (params) {});
    //     // 点击地图触发
    //     chartDom.getZr().on('click', function (params,e,b) {
    //         if(_this.state.choiceDataId){
    //             var pixelPoint = [params.offsetX, params.offsetY];
    //             var dataPoint = chartDom.convertFromPixel({ geoIndex: 0 }, pixelPoint);
    //             _this.setState({longitude:dataPoint[0],latitude:dataPoint[1]});
    //             if(data.find(one=>{if(!one.id)return true})){
    //                 data.splice(data.length-1,1,{name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'})
    //             }else{
    //                 data.push({name:'标点',"value": [dataPoint[0], dataPoint[1], 200],color:'red'});
    //             }
    //             chartDom.setOption({
    //                 series: [
    //                     {
    //                         name: '标点',
    //                         type: 'effectScatter',
    //                         coordinateSystem: 'bmap',
    //                         data: data.map(function (pipe) {
    //                             return {
    //                                 id: pipe.id??null,
    //                                 devId:pipe.devId??null,
    //                                 name: pipe?.name??null,
    //                                 data:pipe?.data,
    //                                 installPosition:pipe.installPosition??null,
    //                                 value:pipe?.value??null,
    //                                 itemStyle: {color: pipe?.color??null,}
    //                             };
    //                         }),
    //                         encode: {value: 2},
    //                         symbolSize: function (val) {return val[2] / 15;},// 标点大小
    //                         showEffectOn: 'emphasis',// 标点波动方式
    //                         label: {
    //                             formatter: '{b}',
    //                             position: 'bottom',
    //                             show: true,
    //                         },
    //                         rippleEffect:{number:3,},// 波动样式
    //                     },
    //                     {
    //                         type: 'lines',
    //                         coordinateSystem: 'bmap',
    //                         data:  lines_1.map(function (pipe) {
    //                             return {
    //                                 coords: pipe.coords,
    //                                 lineStyle: {
    //                                     width: 3,
    //                                     opacity: 10,
    //                                     color: 'green',
    //                                 }
    //                             };
    //                         }),
    //                         polyline: true,
    //                     },
    //                 ]
    //             })
    //         }
    //
    //     });
    //     // 窗口大小变化时，ECharts地图自适应
    //     window.onresize = function() {chartDom.resize();};
    // };
    // 时间
    getTime=()=>{
        //获得显示时间的div
        var now=new Date();
        //替换div内容
        var t_div  =  <>
            <div style={{color:'#fff',fontSize:'0.8vw'}}>
                <div style={{width:'65%',paddingLeft:'20px',float:'left',fontSize:'1.5vw',fontWeight: '900'}}>
                    <span>{moment(now).format("HH:mm:ss")}</span>
                </div>
                <div style={{width:'30%',float:'left',textAlign: 'right'}}>
                    <div>
                        <span>{' 星期'+'日一二三四五六'.charAt(new Date().getDay())}</span>
                    </div>
                    <div>
                        <span>{moment(now).format("yyyy-MM-DD")}</span>
                    </div>
                </div>
                {/*moment(now).format("HH:mm:ss  ")+moment(now).format("  yyyy-MM-DD")+("  星期"+now.getDay())*/}
            </div>
        </>;

        this.setState({headLeftDate:t_div});
        setTimeout(()=>this.getTime(),1000);
    };
    //天气预备
    getDate=()=>{
        AMap.plugin('AMap.Weather', function() {
            //创建天气查询实例
            var weather = new AMap.Weather();
            //执行实时天气信息查询
            weather.getLive('长沙市', function(err, data) {
                $('#weather').text(data?.weather ?? '天气');
                $('#temperature').text(data?.temperature+'°' ?? '气温');
            });
        });
    };
    // 天气预备-展示内容
    getWeather= () =>{
        if(!document.getElementById('weather'))return false;
        var element = document.getElementById('weather').innerText;
        switch (element) {
            case '多云':
                return <div className={'head_weather_img_1'} style={{width:'1.5vw',height:'3vh',float:'left'}}/>;
            case '晴':
                return <div className={'head_weather_img_2'} style={{width:'1.5vw',height:'3vh',float:'left'}}/>;
            case '小雨':
                return <div className={'head_weather_img_3'} style={{width:'1.5vw',height:'3vh',float:'left'}}/>;
            case '中雨':
                return <div className={'head_weather_img_3'} style={{width:'1.5vw',height:'3vh',float:'left'}}/>;
            case '大雨':
                return <div className={'head_weather_img_3'} style={{width:'1.5vw',height:'3vh',float:'left'}}/>;
            default:
                return <div className={'head_weather_img_1'} style={{width:'1.5vw',height:'3vh',float:'left'}}/>;
        }
    };
    // 悬浮框-数据操作栏-按钮展示
    getSuspensionButtonListShow=(data=[])=>{
        let i=0;
        return data.map(one=>{
            i+=1;
            return(
                <div className={this.state.suspension_button_type ===one.name?'suspension_div_onClick_1':'suspension_div_3_bottom'}
                     onClick={()=>{
                         var tableParams = this.state.suspension_tableParams;
                         if(this.state.suspension_button_type!==one.name){
                             console.log('悬浮框-数据操作栏-按钮展示');
                             var tableParams = this.state.suspension_tableParams;
                             tableParams.devType=one.param;
                             this.pageValveInfo(tableParams);            // 获取阀井数据列表
                             this.setState({suspension_tableParams:tableParams});
                         }
                         this.setState({suspension_button_type:one.name});
                     }}
                >
                    <div className={'suspension_img_'+i}/>
                    <div style={{width: '60%',height: '100%',float: 'left',textAlign: 'center'}}>
                        <span style={{fontSize:'0.8vw'}}>{one.name}</span>
                    </div>
                </div>
            )
        })
    };
    // 分页器变化触发
    pageChange = (page, pageSize) => {
        console.log('分页器变化触发')
        let params = this.state.suspension_tableParams;
        params.page = page;
        params.size = pageSize;
        this.setState({suspension_tableParams:params});
        this.pageValveInfo(params);
    };
    // 悬浮框-数据操作栏-右侧表格数据列表
    getSuspensionTableListShow=(data=[])=>{
        if(data.length===0){
            return(
                <div style={{width:'100%',height:'100%',paddingTop:"40%",textAlign: 'center'}}>
                    <span style={{fontWeight: 'bold',fontSize:'18px'}}>暂无数据</span>
                </div>
            )
        }

        return<>
            <div style={{width:'100%',height:'5%'}}>
                <Pagination
                    // size="small"
                    // position={'center'}
                    style={{marginTop: '5px',float: 'right',fontSize: '14px',color:'#fff'}}
                    total={this.state.totalCount} // 总条数
                    showTotal={(total, range) => <div style={{color:'#fff'}}>当前第{range[0]}-{range[1]}条 / 共{this.state.totalCount??0}条</div>}
                    current={this.state.suspension_tableParams?.page} // 当前页数
                    defaultsize={this.state.suspension_tableParams?.size}      // 默认每页条数
                    defaultPageSize={this.state.suspension_tableParams?.size}  // 默认每页条数
                    showQuickJumper={this.state.suspension_tableParams?.jumper}
                    showSizeChanger={false}
                    onChange={this.pageChange} // 状态改变的回调
                />
            </div>
            <div style={{width:'100%',height:'94%',padding:'1%',overflowY: 'auto'}}>
                {data.map(one=>{
                    return(
                        <div style={{width:'100%',height:'19%',padding:'1%',borderBottom: '1px rgba(255,255,255,0.1) ridge'}}>
                            {/*设备编号*/}
                            <div style={{width:'100%',height:'25px'}}>
                                <div style={{width:'30%',height:'100%', textAlign: 'right'}} className={'span_css_type_6'}>
                                    <span>设备编号：</span>
                                </div>
                                <div style={{width:'50%',height:'100%',}} className={'span_css_type_6'}>
                                    <span>{one?.devId??null}</span>
                                </div>
                                {/*定位*/}
                                <div style={{width:'20%',height:'100%',float:'left'}}>
                                    <Tooltip title="定位">
                                        <GlobalOutlined className={this.state.globalOutlined_type===one?.devId?'GlobalOutlined_type_1':'GlobalOutlined'} onClick={()=>{
                                            var params=[];
                                            if(one?.voLocation?.longitude && one?.voLocation?.latitude){
                                                params =[one.voLocation?.longitude,one.voLocation?.latitude];
                                                this.setState({globalOutlined_type:one?.devId});
                                                this.coordinate_valueMaps(one.id,params);// 定位地图
                                            }
                                        }}/>
                                    </Tooltip>
                                </div>
                            </div>
                            {/*设备名称-------------*/}
                            <div style={{width:'100%',height:'25px'}}>
                                <div style={{width:'30%',height:'100%',textAlign: 'right'}} className={'span_css_type_6'}>
                                    <span>设备名称：</span>
                                </div>
                                <div style={{width:'50%',height:'100%'}} className={'span_css_type_6'}>
                                    <span>{one?.devName??null}</span>
                                </div>
                            </div>
                            {/*安装位置----------*/}
                            <div style={{width:'100%',height:'25px'}}>
                                <div style={{width:'30%',height:'100%',textAlign: 'right'}} className={'span_css_type_6'}>
                                    <span>安装位置：</span>
                                </div>
                                <div style={{width:'60%',height:'100%'}} className={'span_css_type_6'}>
                                    <Tooltip title={one?.installPosition??null}>
                                        <span>{one?.installPosition??null}</span>
                                    </Tooltip>

                                </div>
                            </div>
                            {/*坐标位置----------*/}
                            <div style={{width:'100%',height:'25px'}}>
                                <div style={{width:'30%',height:'100%',fontWeight: 'bold',float:'left',textAlign: 'right'}}>
                                    <span>坐标位置：</span>
                                </div>
                                <div style={{width:'50%',height:'100%',float:'left', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                    {(this.state.choiceDataId && this.state.choiceDataId===one?.devId)?
                                        <div style={{width:'100%',height:'100%'}}>
                                            <div style={{width:'50%',height:'100%',float:'left'}}>
                                                <span>经度:</span><Input value={this.state.longitude} disabled style={{width:'60%'}} size="small"/>
                                            </div>
                                            <div style={{width:'50%',height:'100%',float:'left'}}>
                                                <span>纬度:</span><Input value={this.state.latitude} disabled style={{width:'60%'}} size="small"/>
                                            </div>
                                        </div>
                                        :
                                        one?.voLocation?.longitude && one?.voLocation?.latitude?
                                            <span>{one?.voLocation?.longitude+','+one?.voLocation?.latitude}</span>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            </>
    };
    //第一格-查询不同设备类型下的报警信息
    pageAlarmInfo=(params)=>{
        this.setState({dev_table_loading:true});
        var param = this.state.dev_table_param;

        if(!params?.areaIds){return false;}
        if(!Array.isArray(params.areaIds)){
            params.areaIds=[params.areaIds]
        }


        pageAlarmInfo(params).then(
            response=>{
                if(response?.data?.data){
                    var data = response?.data?.data;
                    var devTableHeadData = this.state.dev_table_head_data;
                    data.map(one=>{
                        devTableHeadData.push(one)
                    });
                    this.setState({dev_table_head_data:devTableHeadData});
                }else{
                    // 请求无数据重置分页数
                    if(Number(param.page)<1){param.page=Number(param.page)-1;}
                }
                param.determine=true;
                this.setState({dev_table_loading:false,dev_table_param:param})
            }
        ).catch((e)=> {
            param.determine=true;
            if(Number(param.page)<1){param.page=Number(param.page)-1;}
            this.setState({dev_table_loading: false,dev_table_param:param});
            }
        )
    };
    //第一格-表格内容展示
    getDevTableShow=(datas=[])=>{

        var juder = true;
        if(datas.length!==0){
            return datas.map(one=>{
                juder=!juder;
                return(
                    <div style={{
                        width:'100%',height:'20%',paddingTop:'1%',textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                        backgroundColor:juder?'rgba(55,55,55,0.2)':'rgba(45,45,45,0.5)',color:'#fff'
                    }}
                    >
                        {
                            this.state.dev_table_head_title.map(two=>{
                                return(
                                    <div style={{
                                        width:(100/this.state.dev_table_head_title.length)+'%',
                                        textAlign: 'center',float:'left',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}>
                                        <Tooltip title={one[two.e] ??null}>
                                            <span>{one[two.e] ?? '-'}</span>
                                        </Tooltip>
                                    </div>
                                )
                            })
                        }
                    </div>
                );
            })
        }else{
            return(
                <div style={{
                    width:'100%',height:'20%',paddingTop:'1%',textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                    backgroundColor:'rgba(45,45,45,0.5)',color:'#fff',textAlign:'center'
                }}>
                    <span>暂无数据</span>
                </div>
            )
        }
    };
    //第四个-进度条
    getProgressShow=(data)=>{
        var datas = [];
        for(var i=0; i<Math.floor((data/10));i++){datas.push(10);}
        if(Number(data.toString().slice(-1))!==0){datas.push(Number(data.toString().slice(-1)));}
        return datas.map(one=>{
            return(
                <div style={{width:one+'%',height:'100%',border: '1px #404144 solid',float:'left'}}/>
            )}
        )

    };
    //第四格-小区列表表格内容展示
    getTableShow=()=>{
        var juder = true;
        return this.state.table_head_data.map(one=>{
            juder=!juder;
            return(
                <div style={{width:'100%',height:'12%',paddingTop:'1%',backgroundColor:juder?'rgba(55,55,55,0.2)':'rgba(45,45,45,0.5)',color:'#fff'}}>
                    {
                        this.state.table_head_title.map(two=>{
                            return(
                                <div style={{
                                    width:(100/this.state.table_head_title.length)+'%',textAlign: 'center',float:'left',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}>
                                    <span>{one[two.e] ?? '-'}</span>
                                </div>
                            )
                        })
                    }
                </div>
            );

        })
    };
    render() {
        return (
            //外层页面大小数据，内部百分比
            <div id={'mapManagePageV1'} >
{/*顶部-------------------------------------*/}
                <div className={this.state.shrink_type?'top_css_div_1':'top_css_div_1_shrink'}>
                    <div style={{width:'100%',height:'100%',display:this.state.shrink_type?null:'none'}}>
                        {/*左*/}
                        <div style={{width:'22%',height:'98%',padding:'0.5% 0 0 5%',float:'left'}}>
                            <span className={'span_css_type_4'}>智慧能源平台</span>
                        </div>
                        {/*中*/}
                        <div style={{width:'43%',height:'55%',float:'left'}}>
                        </div>
                        {/*右-1*/}
                        <div style={{width:'27%',height:'95%',float:'left',padding:'0.5%'}}>
                            {/*日期*/}
                            <div style={{float:'left',width:'70%',borderRight: '0.1vw #b4b4b5 ridge'}}>
                                {this.state.headLeftDate}
                            </div>
                            {/*天气*/}
                            <div style={{float:'left',width:'30%',padding:'1%'}}>
                                {this.getWeather()}
                                <div id={'weather'} style={{float:'left',width:'60%',color:'#fff',fontSize:'1vw'}}/>
                            </div>
                        </div>
                        {/*右-2*/}
                        <div style={{width:'7%',height:'95%',float:'left',paddingTop:'0.6%'}}>
                            <div style={{boxShadow: '0 0 10px #000', width:'75%',height:'80%',borderRadius: '3px',
                                display: this.props.location?.state?.selectWidth?null:'none'
                            }}>
                                <div style={{width:'100%',height:'100%',borderRadius: '3px',cursor:'pointer',fontSize:"15px",
                                    background: 'linear-gradient(to bottom, rgba(111,111,111,1) 0%, rgba(0,0,0,1) 100%)',
                                    boxShadow: 'inset 0 0 10px rgba(200, 200, 200, 0.1)',color:'#fff',textAlign: 'center',paddingTop:'7px'
                                }}
                                     onClick={()=>{
                                         window.open(this.state.href+"/customer/ClientIndustry"); //云端
                                     }}
                                >
                                    后台管理
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

{/*悬浮按钮-------------------------------------*/}
                {/*悬浮按钮-阀门井*/}
                <div className={this.state.shrink_type ?'suspension_div_1_1':'suspension_div_display'}>
                    <div className={this.state.suspension_button_type === '阀门井'?'suspension_div_onClick_2':'suspension_div_inside'}
                         onClick={()=>{
                             console.log('悬浮按钮-阀门井');
                             if(this.state.suspension_button_type!=='阀门井'){
                                 var tableParams = this.state.suspension_tableParams;
                                 tableParams.devType=10200;
                                 this.pageValveInfo(tableParams);            // 获取阀井数据列表
                                 this.setState({suspension_tableParams:tableParams});
                             }
                             console.log(this.state.suspension_button_type);
                             this.setState({
                                 shrink_type:!this.state.shrink_type,
                                 suspension_button_type:'阀门井'
                             });
                         }}>
                        <div className={'suspension_img_1'}/>
                        <div style={{width: '60%',height: '100%',float: 'left',textAlign: 'center'}}>
                            <span style={{fontSize:'0.8vw'}}>阀门井</span>
                        </div>
                    </div>
                </div>
                {/*悬浮按钮-调压柜*/}
                <div  className={this.state.shrink_type ?'suspension_div_2_1':'suspension_div_display'}>
                    <div className={this.state.suspension_button_type === '调压柜'?'suspension_div_onClick_2':'suspension_div_inside'}
                         onClick={()=>{
                             console.log('悬浮按钮-调压柜');
                             if(this.state.suspension_button_type!=='调压柜'){
                                 var tableParams = this.state.suspension_tableParams;
                                 tableParams.devType=10211;
                                 this.pageValveInfo(tableParams);            // 获取阀井数据列表
                                 this.setState({suspension_tableParams:tableParams});
                             }
                             this.setState({
                                 shrink_type:!this.state.shrink_type,
                                 suspension_button_type:'调压柜'

                             })
                         }}>
                        <div className={'suspension_img_2'}/>
                        <div style={{width: '60%',height: '100%',float: 'left',textAlign: 'center'}}>
                            <span style={{fontSize:'0.8vw'}}>调压柜</span>
                        </div>
                    </div>
                </div>
                {/*切换的-设备栏-----------*/}
                <div  className={this.state.shrink_type ?'suspension_div_display':'suspension_div_3_1'}>
                    {/*标题-智慧能源平台*/}
                    <div style={{width:'100%',height:'5%'}}>
                        <div className={'head_title_type'}>
                            <div style={{width:'90%',height:'100%',float:'left'}}>
                                <span style={{color:'#fa8c16',fontSize:'0.8vw'}}>智慧能源平台</span>
                                <span style={{color:'#c46612',marginLeft:'0.2vw',fontSize:'0.6vw'}}>Smart Energy Platform</span>
                            </div>
                            {/*按钮-展开数据面板*/}
                            <div style={{width:'10%',height:'100%',float:'left',padding:'1%'}}>
                                <DoubleLeftOutlined className={'span_css_type_5'}
                                                    onClick={()=>{this.setState({
                                                        shrink_type:!this.state.shrink_type,
                                                        // suspension_button_type:null
                                                    })}}
                                />
                            </div>
                        </div>
                    </div>
                    {/*内容*/}
                    <div style={{width:'100%',height:'95%'}}>
                        {/*左侧-类型列表*/}
                        <div style={{width:'25%',height:'100%',borderRadius:'0 0 0 5px',backgroundColor:'rgba(255,255,255,0.3)',
                            boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.8)',
                            float:'left'}}>
                            {this.getSuspensionButtonListShow(this.state.suspension_button_list)}
                        </div>
                        {/*右侧-数据列表*/}
                        <div style={{width:'75%',height:'98%',borderRadius:'0 0 5px 0 ',float:'left'}}>

                            {this.state.suspension_loading?
                                <div style={{width:'100%',height:'99%',textAlign: 'center',paddingTop:'30%'}}>
                                    <LoadingOutlined style={{fontSize:'18px'}}/>
                                    <span style={{color:'#fff'}}>加载中</span>
                                </div>
                                :
                                this.getSuspensionTableListShow(this.state.suspension_tableDataS)

                            }
                        </div>
                    </div>
                </div>
{/*数据面板-------------------------------------*/}
{/*-------------第一栏*/}
                <div className={this.state.shrink_type?'bottom_css_div_1':'bottom_css_div_1_shrink'}>
                    {/*标题-用气分析*/}
                    <div style={{width:'100%',height:'25%'}}>
                        <div className={'head_title_type'}>
                            <span style={{color:'#fa8c16',fontSize:'0.8vw'}}>预警/报警信息</span>
                            <span style={{color:'#c46612',marginLeft:'0.2vw',fontSize:'0.6vw'}}>Warning/alarm information</span>
                        </div>
                    </div>
                    {/*内容*/}
                    <div style={{width:'100%',height:'75%'}}>
                        {/*左侧-设备栏*/}
                        <div style={{width:'30%',height:'96%',paddingLeft:'3%',overflowY: 'auto',float:'left'}}>
                            {this.state.dev_button_list.map(one=>{
                                return(
                                    <div className={this.state.dev_button_type_1===one.name?'button_type_css_2':'button_type_css_1'}
                                         onClick={()=>{
                                             var devTableParam = this.state.dev_table_param;
                                             devTableParam.devType=one.param;
                                             devTableParam.page=1;
                                             this.setState({
                                                 dev_button_type_1:one.name,
                                                 dev_table_param:devTableParam,
                                                 dev_table_head_data:[]
                                             });
                                             this.pageAlarmInfo(devTableParam);
                                         }}
                                    >
                                        <span>{one.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                        {/*右侧-表格栏*/}
                        <div style={{width:'67%',height:'100%',float:'left',padding:'1%'}}>
                            {/*表格标头*/}
                            <div style={{width:'100%',height:'20%',paddingTop:'0.1%',backgroundColor:"rgba(255,255,255,0.3)",color:'#fff'}}>
                                {
                                    this.state.dev_table_head_title.map(one=>{
                                        return(
                                            <div style={{width:(100/this.state.dev_table_head_title.length)+'%',paddingTop:'1%',textAlign: 'center',float:'left'}}>
                                                <span>{one.c}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/*表格内容*/}
                            <div style={{width:'100%',height:'80%',color:'#fff',overflowY: 'auto'}}
                                 ref={e=>{this.myRef=e;}}
                                 onScrollCapture={() => {
                                     // 监听滚动
                                     // 未滚动到底部
                                     let res=this.myRef.scrollHeight - this.myRef.clientHeight- this.myRef.scrollTop;
                                     console.log(res);
                                     if (res===1) {
                                         console.log('未滚动到底部');
                                         var devTableParam = this.state.dev_table_param;
                                         if(devTableParam.determine){
                                             devTableParam.page=Number(devTableParam.page)+1;
                                             devTableParam.determine=false;
                                             this.setState({dev_table_param:devTableParam});
                                             this.pageAlarmInfo(devTableParam);

                                             // let element = document.getElementById("box");
                                             // element.scrollIntoView();               // 元素的顶部将对齐到可滚动祖先的可见区域的顶部。
                                             // element.scrollIntoView(false);      // 元素的底部将与可滚动祖先的可见区域的底部对齐。
                                             // // element.scrollIntoView(false)
                                             // // // #对于新添加的元素有时可能不能立即获取到，此时可以设置个定时器
                                             // // setTimeout(()=>{
                                             // //     let div=document.getElementById('123');
                                             // //     if(div){div.scrollIntoView(false);}
                                             // // },200);


                                         }
                                     }
                                 }}
                            >
                                {this.getDevTableShow(this.state.dev_table_head_data)}
                                <div style={{
                                    width:'100%',height:'20%',paddingTop:'1%',textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                    backgroundColor:'rgba(45,45,45,0.5)',color:'#fff',textAlign:'center',
                                    display: this.state.dev_table_loading?null:'none'
                                }}>
                                    <LoadingOutlined style={{fontSize:'18px'}}/>
                                    <span style={{color:'#fff'}}>加载中</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
{/*-------------第二栏*/}
                <div className={this.state.shrink_type?'bottom_css_div_2':'bottom_css_div_2_shrink'}>
                    {/*标题-用气分析*/}
                    <div style={{width:'100%',height:'25%'}}>
                        <div style={{width:'100%',height:'4vh',padding:'1.7% 0 0 10%',
                            background: 'linear-gradient(to bottom, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.2) 100%)',
                            borderBottom: '0.05vw #b4b4b5 solid'
                        }}>
                            <span style={{color:'#fa8c16',fontSize:'0.8vw'}}>工商户用气分析</span>
                            <span style={{color:'#c46612',marginLeft:'0.2vw',fontSize:'0.6vw'}}>Business Gas analysis</span>
                        </div>
                    </div>
                    {/*内容*/}
                    <div style={{width:'100%',height:'75%'}}>
                        {/*左侧-环形图*/}
                        <div style={{width:'30%',height:'100%',padding:'2%',float:'left'}}>
                            <div style={{width:'100%',height:'20%',padding:'3%', textAlign: 'center'}}>
                                <span style={{fontSize:'0.7vw',color:'#fff'}}>工商用户数{this.state.industryNums}</span>
                            </div>
                            <div style={{width:'100%',height:'100px',padding:'1%'}}>
                                <HalfDoughnutChart
                                    elementById={'halfDoughnutChart_1'}
                                    ref={e => this.halfDoughnutChart1 = e}
                                    data={{
                                        data: this.state.halfDoughnutChart_1.data,
                                        name: this.state.halfDoughnutChart_1.name,
                                        color:this.state.halfDoughnutChart_1.color,
                                    }}
                                />
                            </div>
                            <div style={{width:'100%',height:'15%',textAlign: 'center'}}>
                                <span style={{fontSize:'0.7vw',color:'#fff',fontWeight:'700'}}>用气量</span>
                            </div>
                        </div>
                        {/*右侧-折线图*/}
                        <div style={{width:'70%',height:'180px',padding:'2%',float:'left'}}>
                            <BasicLineChart
                                ref={e => this.BasicLineChart1 = e}
                                elementById={'BasicLineChart_1'}
                                name={this.state.BasicLineChart_1.name}
                                data={this.state.BasicLineChart_1.data}    // 数值
                                yData={this.state.BasicLineChart_1.yData}  // X轴数据
                                number={this.state.BasicLineChart_1.number}// 最小、最大、间距
                            />
                        </div>
                    </div>
                </div>
{/*-------------第三栏*/}
                <div className={this.state.shrink_type?'bottom_css_div_3':'bottom_css_div_3_shrink'}>
                    {/*标题-用气分析*/}
                    <div style={{width:'100%',height:'25%'}}>
                        <div style={{width:'100%',height:'4vh',padding:'1.7% 0 0 10%',
                            background: 'linear-gradient(to bottom, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.2) 100%)',
                            // borderTop: '0.05vw #b4b4b5 solid',
                            borderBottom: '0.05vw #b4b4b5 solid'
                        }}>
                            <span style={{color:'#fa8c16',fontSize:'0.8vw'}}>居民户用气分析</span>
                            <span style={{color:'#c46612',marginLeft:'0.2vw',fontSize:'0.6vw'}}>Resident Gas analysis</span>
                        </div>
                    </div>
                    {/*内容*/}
                    <div style={{width:'100%',height:'75%'}}>
                        {/*左侧-圆环圈*/}
                        <div style={{width:'30%',height:'100%',padding:'2%',float:'left'}}>
                            <div style={{width:'100%',height:'20%',padding:'3%', textAlign: 'center'}}>
                                <span style={{fontSize:'0.7vw',color:'#fff'}}>居民用户数{this.state.homeNums}</span>
                            </div>
                            <div style={{width:'100%',height:'100px',padding:'1%'}}>
                                <HalfDoughnutChart
                                    ref={e => this.halfDoughnutChart2 = e}
                                    elementById={'halfDoughnutChart_2'}
                                    data={{
                                        data: this.state.halfDoughnutChart_2.data,
                                        name: this.state.halfDoughnutChart_2.name,
                                        color:this.state.halfDoughnutChart_2.color,
                                    }}
                                />
                            </div>
                            <div style={{width:'100%',height:'15%',textAlign: 'center'}}>
                                <span style={{fontSize:'0.7vw',color:'#fff',fontWeight:'700'}}>用气量</span>
                            </div>
                        </div>
                        {/*右侧-折线和条状*/}
                        <div style={{width:'70%',height:'180px',padding:'2%',float:'left'}}>
                            <BarAndLineCharts
                                ref={e => this.barAndLineCharts1 = e}
                                elementById={'BarAndLineCharts_1'}
                                data={this.state.barAndLineCharts}
                            />
                        </div>
                    </div>
                </div>
{/*-------------第四栏*/}
                <div className={this.state.shrink_type?'bottom_css_div_4':'bottom_css_div_4_shrink'}>
                    {/*<div  style={{width:'100%',height:'100%',display:this.state.shrink_type?null:'none'}}>*/}
                    {/*</div>*/}
                    {/*标题-用气分析*/}
                    <div style={{width:'100%',height:'7%',}}>
                        <div style={{width:'100%',height:'4vh',padding:'1.7% 0 0 10%',
                            background: 'linear-gradient(to bottom, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.2) 100%)',
                            // borderTop: '0.05vw #b4b4b5 solid',
                            borderBottom: '0.05vw #b4b4b5 solid'
                        }}>
                            <span style={{color:'#fa8c16',fontSize:'0.8vw'}}>用气分析</span>
                            <span style={{color:'#c46612',marginLeft:'0.2vw',fontSize:'0.6vw'}}>Gas analysis</span>
                        </div>
                    </div>
                    {/*数字数据-------------*/}
                    <div style={{width:'100%',height:'10%',paddingTop:'4% ',  textAlign: 'center'}}>
                        <span className={'span_css_type_1'}>{this.state.areaUsage}<span style={{marginLeft:'1%'}}>立方</span></span>
                    </div>
                    {/*数据横条-------------*/}
                    <div style={{width:'100%',height:'15%',}}>
                        {/*上*/}
                        <div style={{width:'100%',height:'50%',padding:'1% 0 0 10%',}}>
                            <div style={{width:'100%',height:'30%'}}>
                                <div style={{width:'70%',height:'100%',float:'left'}}>
                                    <span className={'span_css_type_2'}>昨日用气总量</span>
                                </div>
                                <div style={{width:'20%',height:'100%',float:'left'}}>
                                    <span className={'span_css_type_2'}>{this.state.stripData1}</span>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'50%'}}>
                                <div style={{width:'85%',height:'60%',margin:'5px 0 0 5px',border: '2px #8b8c8f solid',background: 'linear-gradient(to right, rgba(0,0,0,0.9),#fff)'}}>
                                    {this.getProgressShow(this.state.stripData1)}
                                    <div style={{width:(100-this.state.stripData1)+'%',height:'100%',backgroundColor:'#5e5c58',float:'left'}}/>
                                </div>
                            </div>
                        </div>
                        {/*下*/}
                        <div style={{width:'100%',height:'50%',padding:'1% 0 0 10%',}}>
                            <div style={{width:'100%',height:'30%'}}>
                                <div style={{width:'70%',height:'100%',float:'left'}}>
                                    <span className={'span_css_type_2'}>昨日供气总量</span>
                                </div>
                                <div style={{width:'20%',height:'100%',float:'left'}}>
                                    <span className={'span_css_type_2'}>{this.state.stripData2}</span>
                                </div>
                            </div>
                            <div style={{width:'100%',height:'50%'}}>
                                <div style={{width:'85%',height:'60%',margin:'5px 0 0 5px',border: '2px #8b8c8f solid', background: 'linear-gradient(to right, rgba(0,0,0,0.9),#fff)'}}>

                                    {this.getProgressShow(this.state.stripData2)}
                                    <div style={{width:(100-this.state.stripData2)+'%',height:'100%',backgroundColor:'#5b5b5e',float:'left'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*四格数据--------------*/}
                    <div style={{width:'100%',height:'22%',padding:'5% 15% 5% 15%'}}>
                        {/*四格数据-上*/}
                        <div style={{width:'100%',height:'50%'}}>
                            {/*左*/}
                            <div style={{paddingLeft:'6%',width:'50%',height:'100%',float:'left',
                                borderRight: '1px #b4b4b5 solid',borderBottom: '1px #b4b4b5 solid'}}>
                                <div style={{textAlign: 'center',width:'100%',height:'100%'}}>
                                    <div style={{width:'100%',height:'60%'}}><span className={'span_css_type_1'}>{this.state.meterReadingRate}</span></div>
                                    <div><span className={'span_css_type_3'}>抄表率</span></div>
                                </div>
                            </div>
                            {/*右*/}
                            <div style={{paddingRight:'10% ',width:'50%',height:'100%',float:'left',borderBottom: '1px #b4b4b5 solid'}}>
                                <div style={{textAlign: 'center',width:'100%',height:'100%'}}>
                                    <div style={{width:'100%',height:'60%'}}><span className={'span_css_type_1'}>{this.state.newInstallNum}</span></div>
                                    <div><span className={'span_css_type_3'}>新装率</span></div>
                                </div>
                            </div>
                        </div>
                        {/*四格数据-下*/}
                        <div style={{width:'100%',height:'50%'}}>
                            {/*左*/}
                            <div style={{paddingLeft:'6%',width:'50%',height:'100%',float:'left',borderRight: '1px #b4b4b5 solid'}}>
                                <div style={{textAlign: 'center',width:'100%',height:'100%',paddingTop:'5%'}}>
                                    <div style={{width:'100%',height:'22%'}}><span className={'span_css_type_3'}>新表率</span></div>
                                    <div><span className={'span_css_type_1'}>{this.state.newAddNum}</span></div>
                                </div>
                            </div>
                            {/*右*/}
                            <div style={{paddingRight:'10%',width:'50%',height:'100%',float:'left'}}>
                                <div style={{textAlign: 'center',width:'100%',height:'100%',paddingTop:'5%'}}>
                                    <div style={{width:'100%',height:'22%'}}><span className={'span_css_type_3'}>报警数</span></div>
                                    <div><span className={'span_css_type_1'}>{this.state.abnormalNum}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*标题-小区列表--------------*/}
                    <div style={{width:'100%',height:'9%',paddingTop:'5%'}}>
                        <div style={{width:'100%',height:'4vh',padding:'1.7% 0 0 10%',
                            background: 'linear-gradient(to bottom, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.2) 100%)',
                            // borderTop: '0.1vw #b4b4b5 solid',
                            borderBottom: '0.1vw #b4b4b5 solid'
                        }}>
                            <span style={{color:'#fa8c16',fontSize:'0.8vw'}}>小区列表</span>
                            <span style={{color:'#c46612',marginLeft:'0.2vw',fontSize:'0.6vw'}}>Community List</span>
                        </div>
                    </div>
                    {/**/}
                    <div style={{width:'100%',height:'36%',padding:'1% 3% 1% 3%'}}>
                        {/*标头*/}
                        <div style={{width:'100%',height:'10%',paddingTop:'0.1%',backgroundColor:"rgba(255,255,255,0.3)",color:'#fff'}}>
                            {
                                this.state.table_head_title.map(one=>{
                                    return(
                                        <div style={{width:(100/this.state.table_head_title.length)+'%',textAlign: 'center',float:'left'}}>
                                            <span>{one.c}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*内容*/}
                        <div style={{width:'100%',height:'90%',color:'#fff',overflowY: 'auto'}}>
                            {this.getTableShow()}
                        </div>
                    </div>


                </div>
                {/*地图*/}
                <div id={'deviceModelMapId'} style={{width:'100%',height:'100%',zIndex:'9',backgroundColor:'#484848'}}/>
            </div>
        )
    }
}
