import ajax from './ajax'

// 发货-获取订单采购单信息
export const getMeterOrderList = data => ajax('/maintain/VoDeliveryNoteCtrl/getMeterOrderList', data, 'GET');

// 订单-条件查询
export const getMeterOrderFormByState = data => ajax('/maintain/MeterOrderFormCtrl/getMeterOrderFormByState', data, 'POST');
// 发货-获取订单采购单信息
export const getMeterOrderById = data => ajax('/maintain/VoDeliveryNoteCtrl/getMeterOrderById', data, 'GET');
// 发货-临时清单商品添加
export const addDeliveryNote = data => ajax('/maintain/VoDeliveryNoteCtrl/addDeliveryNote', data, 'POST');
// 发货-临时清单商品删除
export const updataDeliveryNode = data => ajax('/maintain/VoDeliveryNoteCtrl/updataDeliveryNode', data, 'POST');
// 发货-获得所有已生成发货单
export const getDeliveryNote = data => ajax('/maintain/VoDeliveryNoteCtrl/getInfo', data, 'GET');
// 发货-获得发货和未发货的商品每个类型的总和
export const deliveryNodeNumbers = data =>ajax('/maintain/VoDeliveryNoteCtrl/deliveryNodeNumbers', data, 'GET');
// 发货-提交发货单
export const supplierShip = data => ajax('/maintain/VoDeliveryNoteCtrl/supplierShip', data, 'POST');
// 发货-发货按钮-获得未发货单数据
export const getVoDeliveryNote = data => ajax('/maintain/VoDeliveryNoteCtrl/getVoDeliveryNote', data, 'GET');
// 发货-清空清单
export const getDeleteAllTemporay = data => ajax('/maintain/VoDeliveryNoteCtrl/getDeleteAllTemporay', data, 'POST');
// 表具详情列表
export const getMetersBySteelNum = data => ajax('/maintain/VoDeliveryNoteCtrl/getMetersBySteelNum', data, 'POST');

// 显示所有区域-发货根据订单情况控制区域显示
export const getAllAreaInfoInId = data => ajax('/maintain/VoDeliveryNoteCtrl/getAllAreaInfos', data, 'GET');


//————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————————
// // 订单-分页查询-无条件
// export const getMeterOrderForm = data => ajax('/maintain/supplier/MeterOrderFormCtrl/getMeterOrderForm', data, 'POST');
// // 订单-条件查询
// export const getMeterOrderFormByState = data => ajax('/maintain/supplier/MeterOrderFormCtrl/getMeterOrderFormByState', data, 'POST');
// // 发货-获取订单采购单信息
// export const getMeterOrderById = data => ajax('/maintain/supplier/MeterOrderFormCtrl/getMeterOrderById', data, 'GET');
// //发货-临时单商品添加
// export const addDeliveryNote = data => ajax('/maintain/supplier/VoDeliveryNoteCtrl/addDeliveryNote', data, 'POST');
// //发货-临时单商品删除
// export const updataDeliveryNode = data => ajax('/maintain/supplier/VoDeliveryNoteCtrl/updataDeliveryNode', data, 'POST');
// // 发货-获得所有已生成发货单
// export const getDeliveryNote = data => ajax('/maintain/supplier/VoDeliveryNoteCtrl/getInfo', data, 'GET');
// // 发货-获得发货和未发货的商品每个类型的总和
// export const deliveryNodeNumber = data =>ajax('/maintain/supplier/VoDeliveryNoteCtrl/deliveryNodeNumber', data, 'GET');
// // 发货-提交发货单
// export const supplierShip = data => ajax('/maintain/supplier/VoDeliveryNoteCtrl/supplierShip', data, 'POST');
// // 发货-发货按钮-获得未发货单数据
// export const getVoDeliveryNote = data => ajax('/maintain/supplier/VoDeliveryNoteCtrl/getVoDeliveryNote', data, 'GET');
// // 发货-清空清单
// export const getDeleteAllTemporay = data => ajax('/maintain/VoDeliveryNoteCtrl/getDeleteAllTemporay', data, 'POST');
// // 表具详情列表
// export const getMetersBySteelNum = data => ajax('/maintain/VoDeliveryNoteCtrl/getMetersBySteelNum', data, 'POST');
