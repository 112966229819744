import React, { Component } from 'react'
import { Layout, Button, message, Modal ,Tree} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import {queryUsernameExist,getOrganizationTree,updateUser,listPositionsByOrganizationId,getUserById,updatePassword} from '../../services/admin'
import FormsTwo from '../../components/common/forms/FormsTwo'

import {TYPE_NAME} from '../../components/Const'
import '../../style/luo.less'
import moment from 'moment'

export default class PersonalCenter extends Component {

    state = {
		responseInfo:{},	
		formParamenter: {
			// datas:{},
			fromData:[],
			modal: true,
			number: 3,
            labelCol: 5,
			layout: "horizontal", 
			parameter: [
				{
					variableName: 'id',
					str: '用户代码',
					rules: TYPE_NAME.INPUT,
					disabled: true
				},
				{
					variableName: 'username',
					str: '用户名',
					rules: TYPE_NAME.NOT_BLANK
				},
			
				{
					variableName: 'realName',
					str: '真实姓名',
					rules: TYPE_NAME.NOT_BLANK
				},
			
                {
					variableName: 'phone',
					str: '手机号码',
					rules: TYPE_NAME.PHONE_NUM_NOT_NULL
				},
                {
					variableName: 'email',
					str: '邮箱',
					rules: TYPE_NAME.EMAIL_NOT_NULL
				},
                {
					variableName: 'sex',
					str: '性别',
					rules: TYPE_NAME.REQ_SELECT,
					data:[
						{
							key:'男',
							value:'男'
						},
						{
							key:'女',
							value:'女'
						}
					]
				},
                {
					variableName: 'remark',
					str: '备注',
					rules: TYPE_NAME.INPUT
				},
			
				
			]
	
		},
		data: {},
		tables:{},
		expandedKeys: [],
        checkedKeys:[],
        selectedKeys:[],
        autoExpandParent:[],
        tree: {
          treeData :[
          {
            title: 'name',
            key: 'id',
           
            children: [
              {
                title: 'name',
                key: 'id',
               
                children: [
                  { title: 'name', key: 'id'},
                ],
              },
            ],
          },
        ]
      },
	  users:[],
	  qwdVisible:false, // 小窗口开关器-1
	  	// 弹框表单参数
	  ModalFormParamenter: {
					modal: true,
					number: 1,
					labelCol: 6,
					layout: "horizontal", 
					// itemSize: 'large',
					parameter: [
						{
							variableName: 'password',
							str: '新密码',
							rules: TYPE_NAME.PASSWORD,
						}
					]
	  },
    };

	componentDidMount(){
		
		let user = JSON.parse(sessionStorage.getItem("user")??null);
		getUserById({id:user.id}).then(
			response => {
				console.log(response.data.data)
				this.setState({
					data: response.data.data
				  });
				this.forms.onModalClose()
			}
		)

	}

   


	 //展开/收起节点时触发
     onExpand = (expandedKeysValue: React.Key[]) => {
		console.log('onExpand', expandedKeysValue);
		this.setState({
			expandedKeys: expandedKeysValue,
			autoExpandParent: false
		  });
	  };
	  
	  //点击复选框触发
	  onCheck = (checkedKeysValue: React.Key[]) => {
		console.log('onCheck', checkedKeysValue);

	  
	  //点击树节点触发
		this.setState({
		  checkedKeys: checkedKeysValue,
		//   responseInfo
		});
	  };
	  
	
	  //点击树节点触发
	  
	  onSelect = (selectedKeysValue: React.Key[], info: any) => {
		console.log('onSelect', info);
		let key = info.node.key    
		let param = {
			organizationId: key
		  }
		console.log('onSelect', key);
		listPositionsByOrganizationId(param).then(
			response => {
			  let {data} = response.data 
			  console.log('onSelect', data);
			let {item ,formParamenter} =this.state
			// formParamenter.parameter.filter(val => val.str === '职位').data
				item = data?.map(value => {
				return {
					// variableName:value.name,
					// str : value.name,
					// rules: 'input',
				  	key:value.positionId+'',
				  	value:value.name,
				}
			})
			console.log(item,formParamenter.parameter.filter(val => val.str === '职位'))
			formParamenter.parameter[3].data = item
			// formParamenter.parameter.data=item
			// let {formParamenter} = this.state
			// formParamenter.parameter.data = item
			console.log(formParamenter.parameter.filter(val => val.str === '职位'))
			this.setState({formParamenter,item:item})
			console.log('下拉框数据', formParamenter);
			console.log('下拉框数据', item);
			message.success('查询成功',[0.5])

			}
		  )
	  };
   

    clear = e => {
		let {data} = this.state
		if(e !== 2 || Object.keys(data).length === 0){
			this.setState({
				data: {},
				responseInfo:{},
			})
			setTimeout(() => this.forms.onModalClose(), 0)
		}
		
    }

	//修改密码显示
	pwdStatus = async (qwdVisible)=>{
		await this.setState({qwdVisible})
		await this.forms2.onModalClose()
	}

	changePwdData = async (value)=>{
		let user = JSON.parse(sessionStorage.getItem("user")??null);
		let qwdData = await this.forms2.onFinishNotReset()
		qwdData.id=user.id
		updatePassword(qwdData).then(data=>{
			console.log(data)
			if(data.data.data==true){
				message.success("修改成功")
				this.pwdStatus(false)
			}else{
				this.pwdStatus(false)
				message.error('修改失败')

			}
		});

	}; 
	
	 
	  

  
    render() {
        return (
			<Layout  className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff',overflow:'hidden'}}>
				<div style={{padding:'10px'}}>
					<span className={'back_arrow'} onClick={() => this.props.history.go(-1)}><ArrowLeftOutlined /></span>&nbsp;&nbsp;&nbsp;
					<span className={'font_size2'}>返回</span>
					<Button type="primary" style={{float:'right'}} onClick={() => this.pwdStatus(true)}>修改密码</Button>
				</div>

			            <div id={'font_size_height'}>
                
							<FormsTwo 
								ref={e => this.forms = e} 
								formParamenter={this.state.formParamenter} 
								data={this.state.data}
								// data={JSON.parse(sessionStorage.getItem("user")??null)}
						/>
								<div className={'div_button_center'}>
							<Button type="primary" onClick={this.onSave}>确定</Button>
							&nbsp;&nbsp;&nbsp;
							<Button type="primary" onClick={this.clear}>取消</Button>
						</div>

                </div>
				<Modal
                        title={'修改密码'}
                        style={{ top: 0 }}
                        visible={this.state.qwdVisible}
                        okText="确定"
                        onOk={() => this.changePwdData(true)}           // 点击确定
                        onCancel={() => this.pwdStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div>
							<FormsTwo 
							ref={e => this.forms2 = e} 
							formParamenter={this.state.ModalFormParamenter} 
							data={this.state.data}
							/>
                        </div>
                    </Modal>
				
            </Layout>
        )
    }
}
