import React from 'react';

import * as echarts from 'echarts';
// 条形图
class BarChart extends React.Component {


    componentDidUpdate(num){
        this.elementById();// 初始化执行
    }
    componentDidMount(){
        this.elementById();
    };
    elementById= async =>{

        var series=[];
        // 处理单挑数据拼接
        // this.props?.data?.map(
        //     one=>{
        //         series.push(
        //             {
        //                 name: one.name,
        //                 type: 'bar',
        //                 stack: 'total',
        //                 label: {
        //                     show: false,
        //                 },
        //                 emphasis: {
        //                     focus: 'series'
        //                 },
        //                 data: one.data
        //             },
        //
        //         )
        //     }
        // );
        // this.props?.data?.map(
        //     one=>{
        //         one.data.map(
        //             two=>{
        //                 series.push(
        //                     {
        //                         name: one.name,
        //                         type: 'bar',
        //                         stack: 'total',
        //                         label: {show: false,},
        //                         emphasis: {focus: 'series'},
        //                         data: two,
        //                         color:new echarts.graphic.LinearGradient(0, 0, 1, 1, [
        //                             {
        //                                 offset: 0,
        //                                 color: '#3861fb'
        //                             },
        //                             {
        //                                 offset: 1,
        //                                 color: '#2fc0d2'
        //                             }
        //                         ])
        //                     },
        //                 )
        //             }
        //         );
        //     }
        // );
        // console.log(series);
        var chartDom = echarts.init(document.getElementById(this.props.elementById), "macarons");
        window.addEventListener('resize',function(){
            chartDom.resize();
        });
        chartDom.setOption(
            {
                //标题
                // title: {
                //     text: 'World Population'
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {},
                grid: {
                    top:'5%',
                    left: '10%',
                    right: '14%',
                    bottom: '-1%',
                    containLabel: true
                },
                xAxis: {
                    // min:this.props.number.min,     // 最小值
                    // max:this.props.number.max,    // 最大值
                    interval:this.props.number.interval,// 数值间隔
                    type: 'value',
                    // boundaryGap: [0, 1],
                },
                yAxis: {
                    type: 'category',
                    data:this.props?.yData
                },
                // series:series
                series: [
                    {
                        name: '燃气漏气',
                        type: 'bar',
                        stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: [32, 33, 30, 33, 39],
                        itemStyle:{
                            normal:{
                                color:'#c46054'
                            }
                        }

                    },
                    {
                        name: '燃气用量',
                        type: 'bar',
                        stack: 'a',
                        emphasis: {
                            focus: 'series'
                        },
                        data: [320, 332, 301, 334, 390],
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(1,0,0,0,
                                    [
                                        {offset:0,color:'#57c6ff'},
                                        {offset:1,color:'#58c6fb'},
                                    ])
                            }
                        }
                    },
                    {
                        name: '液化气漏气',
                        type: 'bar',
                        stack: 'b',
                        emphasis: {
                            focus: 'series'
                        },
                        data: [32, 33, 30, 33, 39],
                        itemStyle:{
                            normal:{
                                color:'#c46054'
                            }
                        }

                    },
                    {
                        name: '液化气用量',
                        type: 'bar',
                        stack: 'b',
                        emphasis: {
                            focus: 'series'
                        },
                        data: [320, 332, 301, 334, 390],
                        itemStyle:{
                            normal:{
                                color:new echarts.graphic.LinearGradient(1,0,0,0,
                                    [
                                        {offset:0,color:'#BAFD86'},
                                        {offset:1,color:'#dcfdd3'},
                                    ])
                            }
                        }
                    },
                    // {
                    //     name: '液化气漏气',
                    //     type: 'bar',
                    //     stack: 'b',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [32, 33, 30, 33, 39],
                    //     // 设置柱状渐变色
                    //     // itemStyle: {
                    //     // // 设置柱状渐变色
                    //     // normal:{
                    //     // // 设置柱状圆角
                    //     // barBorderRadius:1,
                    //     // color:new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                    //     //             {
                    //     //                 offset: 0,
                    //     //                 color: '#3861fb'
                    //     //             },
                    //     //             {
                    //     //                 offset: 1,
                    //     //                 color: '#2fc0d2'
                    //     //             }
                    //     // ])
                    //     // }
                    //     // }
                    //
                    // },
                    // {
                    //     name: '液化气用量',
                    //     type: 'bar',
                    //     stack: 'b',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [320, 332, 301, 334, 390],
                    //     // 设置柱状渐变色
                    //     // color:new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                    //     //     {
                    //     //         offset: 0,
                    //     //         color: 'rgb(186 253 134)'
                    //     //     },
                    //     //     {
                    //     //         offset: 1,
                    //     //         color: 'rgb(186 253 134)'
                    //     //     }
                    //     // ])
                    // },
                    // {
                    //     name: '1',
                    //     type: 'bar',
                    //     stack: 'Ad',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [320, 332, 301, 334, 390],
                    //     // 设置柱状渐变色
                    //     // color:new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                    //     //     {
                    //     //         offset: 0,
                    //     //         color: '#3861fb'
                    //     //     },
                    //     //     {
                    //     //         offset: 1,
                    //     //         color: '#2fc0d2'
                    //     //     }
                    //     // ])
                    //     itemStyle: {
                    //         // 设置柱状渐变色
                    //         normal:{
                    //             // 设置柱状圆角
                    //             barBorderRadius:1,
                    //             color:new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#3861fb'
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#2fc0d2'
                    //                 }
                    //             ])
                    //             // 设置每根柱状的颜色不一样且为渐变色
                    //             // color:function(params){
                    //             //     let colorList = [
                    //             //         ['#FF516D', '#FFD1D9'],
                    //             //         ['#DF67F5', '#F4C7FC'],
                    //             //         ['#7245FF', '#D6C9FF'],
                    //             //         ['#4964FF', '#C4CDFF'],
                    //             //         ['#56BDED', '#CBE5F1'],
                    //             //         ['#4EE0B6', '#B7EBDC'],
                    //             //         ['#6AE34C', '#CAF3C0'],
                    //             //         ['#FFD140', '#FFEEBA'],
                    //             //         ['#FA8941', '#FED1B4'],
                    //             //         ['#FF2B2B', '#FFC4C4']
                    //             //     ];
                    //             //     let index = params.dataIndex;
                    //             //     if(index >= colorList.length){
                    //             //         index = params.dataIndex - colorList.length;
                    //             //     }
                    //             //     return new echarts.graphic.LinearGradient(0,0,1,1, [
                    //             //         {
                    //             //             offset: 0,
                    //             //             color: colorList[index][0]
                    //             //         },
                    //             //         {
                    //             //             offset: 1,
                    //             //             color: colorList[index][1]
                    //             //         }
                    //             //     ])
                    //             // }
                    //         }
                    //     },
                    // },

                    // {
                    //     name: 'Union Ads',
                    //     type: 'bar',
                    //     stack: 'Ad',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [220, 182, 191, 234, 290, 330, 310]
                    // },
                    // {
                    //     name: 'Video Ads',
                    //     type: 'bar',
                    //     stack: 'Ad',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [150, 232, 201, 154, 190, 330, 410]
                    // },

                    // {
                    //     name: 'Search Engine',
                    //     type: 'bar',
                    //     data: [862, 1018, 964, 1026, 1679, 1600, 1570],
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     markLine: {
                    //         lineStyle: {
                    //             type: 'dashed'
                    //         },
                    //         data: [[{ type: 'min' }, { type: 'max' }]]
                    //     }
                    // },
                    // {
                    //     name: 'Baidu',
                    //     type: 'bar',
                    //     barWidth: 5,
                    //     stack: 'Engine',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [620, 732, 701, 734, 1090, 1130, 1120]
                    // },
                    // {
                    //     name: 'Google',
                    //     type: 'bar',
                    //     stack: 'Engine',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [120, 132, 101, 134, 290, 230, 220]
                    // },
                    // {
                    //     name: 'Bing',
                    //     type: 'bar',
                    //     stack: 'Search Engine',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [60, 72, 71, 74, 190, 130, 110]
                    // },
                    // {
                    //     name: 'Others',
                    //     type: 'bar',
                    //     stack: 'Search Engine',
                    //     emphasis: {
                    //         focus: 'series'
                    //     },
                    //     data: [62, 82, 91, 84, 109, 110, 120]
                    // }
                ]
            }
        );
    };
    render() {
        return (
            <div id={this.props.elementById} style={{ width: '100%', height: '100%' }}>
            </div>
        );
    }
}
export default BarChart;
