import React, { Component } from 'react'
import { NavLink } from "react-router-dom"
import {Layout, message,  Popconfirm, Radio} from 'antd'
import {TYPE_NAME} from '../../components/Const'
import TableComponents from '../../components/common/tables/TableComponents'
import {queryResidentialCommunity, removeResidentialCommunity,} from '../../services/community'
import {pageList, removeGateStation} from "../../services/station";
import moment from "moment";

// 场站管理
export default class station extends Component {

    state = {
        // 表格参数
        tableParamenter: {
            manageName: "场站管理",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: true,
            // // 获取表格参数数据
            localStorageName: 'station',
            //下拉框长度
            selectWidth: '7.5vw',
            // 表头：变量和变量解释
            fileName: '场站管理',
            // exportUrl: "/web/customerExportCtrl/exportCustomer",
            header: {
                name: '门站名称',
                gasSource: '气源名称',
                intakePressure: '进气压力',
                productionDate: '投产日期',
                designLife: '设计使用年限',
                dailyGasSupplyCapacity: '日供气能力(M/H)',
                conveyingHighPressure: '输送高压',
                conveyingIntermediatePressure: '输送次高压',
                conveyingLowPressure: '输送低压',
                transportMediumPressure: '输送中压',
                numberOfOnDutyPersonnel: '值守人数',
                edit: '操作',
            },

            // 条件选择器参数
            conditionsParamenter: {
                // 条件
                parameter: [
                    {
                        type: TYPE_NAME.NAME,
                        variableName: 'name',
                        str: '名称',
                        initData: JSON.parse(localStorage.getItem('community'))?.name
                    },
                ]
            },
        },
    };
    componentWillMount() {}

    setTableValue = (value,text,rowData) => {
        switch (value) {
            case 'name':
                return (
                    <NavLink to={{pathname: "/station/addOrModifyStation",state: {
                            rowData:rowData,
                            selectWidth: false
                        }}}>
                        <div className={'table_overflow'} title={text}>
                            <u>{text}</u>
                        </div>
                    </NavLink>
                );
            case 'productionDate':
                text =text? moment(text).format('YYYY-MM-DD HH:mm:ss'):null;
                return (
                    <span>{text}</span>
                );
            case 'edit':
                return (
                    <div>

                        <div style={{paddingLeft:'10px',float: 'left'}}>
                            <Popconfirm title="是否删除门站?"
                                        onConfirm={()=>{this.removeGateStation(rowData.id)}}
                                // onCancel={cancel}
                                        okText="是" cancelText="否">
                                <a>删除门站</a>
                            </Popconfirm>
                        </div>
                    </div>
                );
            default:return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };

    // 删除门站
    removeGateStation=(id)=>{
        if(!id){return false;}
        removeGateStation({id}).then(
            response => {
                if(response?.data?.data){
                    message.info('删除成功');
                }else{
                    message.info('删除失败'+response?.data?.responseInfo?.description);
                }
                this.table.refreshTable();
            }
        )
    };

    render() {
        return (
            <Layout className={'item_body_number_back tables'} style={{backgroundColor: '#ffffff'}}>
                <TableComponents
                    id={'id'}
                    paramenter={this.state.tableParamenter}
                    setData={pageList}
                    setTableValue={this.setTableValue}
                    ref={e => this.table = e}
                >
                    <Radio.Group defaultValue="addClient" buttonStyle="solid">
                        <NavLink
                            to={{
                                pathname:'/station/addOrModifyStation',
                                state:{selectWidth: true}
                            }}
                        >
                            <Radio.Button value="addClient">新增门站</Radio.Button>
                        </NavLink>
                        <Radio.Button value="updateClient" onClick={()=>{
                            let rowData = this.table.getSelectChange();
                            if(rowData.length < 1) return message.error('未选择数据');

                            this.props.history.push({
                                pathname: '/station/addOrModifyStation',
                                state: {
                                    rowData:rowData[0],
                                    selectWidth: false
                                }
                            })

                        }}>修改门站</Radio.Button>
                    </Radio.Group>
                </TableComponents>
            </Layout>
        )
    }
}
