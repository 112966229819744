import ajax from './ajax'





// 申请信息详情-新奥
export const getSimCardApplicationListInXinao = data => ajax('/businesse/simPc/application/getSimCardApplicationListInXinao', data, 'POST');

// 申请信息详情-厂家
export const getSimCardApplicationListInManufactor = data => ajax('/businesse/simPc/application/getSimCardApplicationListInManufactor', data, 'POST');

// 审批列表
export const getSimInfoListInCondition = data => ajax('/businesse/simPc/management/getSimInfoListInCondition', data, 'POST');

// 报废台账列表
export const getSimScrapListInCondition = data => ajax('/businesse/simPc/management/getSimScrapListInCondition', data, 'POST');

//批准
export const approveApplication = data => ajax('/businesse/simPc/application/approveApplication', data, 'POST');

//发货
export const deliverGoods = data => ajax('/businesse/simPc/application/deliverGoods', data, 'POST');
//获取SIM卡列表
export const getDeliverGoods = data => ajax('/businesse/simPc/application/getDeliverGoods', data, 'GET');
//拒绝
export const rejectApplication = data => ajax('/businesse/simPc/application/rejectApplication', data, 'POST');

//申请
export const simUsesTheCardToApply = data => ajax('/businesse/simPc/application/simUsesTheCardToApply', data, 'POST');

//删除
export const removeSimCardApplication = data => ajax('/businesse/simPc/application/removeSimCardApplication', data, 'GET');

//退回
export const returnSim = data => ajax('/businesse/simPc/application/returnSim', data, 'POST');

//收货
export const receipt = data => ajax('/businesse/simPc/application/receipt', data, 'GET');


//修改
export const updateSim = data => ajax('/businesse/simPc/management/updateSim', data, 'POST');


//批量添加
export const batchInsertSim = data => ajax('/businesse/simPc/management/batchInsertSim', data, 'POST');
//sim卡删除
export const removeSimCard = data => ajax('/businesse/simPc/management/removeSimCard', data, 'GET');
//批量报废
export const discardSimCard = data => ajax('/businesse/simPc/management/discardSimCard', data, 'POST');






















//获取厂家下拉
export const getManufacturerList = data => ajax('/businesse/simPc/application/getManufacturerList', data, 'GET');


//获取安装详情
export const getInitInfoByInitId = data => ajax('/businesse/pcInstall/getInitInfoByInitId', data, 'GET');




















