import ajax from './ajax'
import moment from 'moment'

// 添加痕迹-旧
// export const addTraceInfo = data => ajax('/tracemanagement/traceInformation/addTraceInfo', data, 'POST')
// 添加痕迹-新
export const addTraceInfo = data => ajax('/meterimport/traceInformation/addTraceInfo', data, 'POST')

/**
 * @description 添加痕迹
 * @param {String} traceSource 系统名称
 * @param {String} operationName 操作名称
 * @param {String} oldContent 参数内容
 * @param {String} newContent 返回结果
 */
export const funTraceInfo = (traceSource, operationName, oldContent, newContent) =>{
    let {account, id} = JSON.parse(sessionStorage.getItem("user")??null) ?? {}
    let params = {
        traceSource: traceSource,
        operationName: operationName,
        operatorName: account,
        operatorId: id,
        oldContent: JSON.stringify(oldContent),
        newContent: JSON.stringify(newContent),
        operationTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    };
    addTraceInfo(params)
}

/**
 * @description 为侧边栏添加图标和文字
 * @param {String} url 根据url地址匹配下方的对象信息
 * @param {String} name 侧边栏标题
 * @returns 侧边栏主体内容
 */
export const subMenuLogo = (url='-',name,components) =>
        {
            let className=components
            return  <>
                <font class={"iconfont font_family " + className}></font>
                {name}
            </>
        }

        {/* <img
            style={{width: '24px', height: '24px',marginRight: '10px'}}
            src={require('../assets/systemLogo/'+url.substring(1)+'.png').default}/> */}


