
import { Component } from 'react'
import { Button, Layout, Tree, message,  Radio, Modal,Checkbox,Tooltip} from "antd";
import { deleteAppOrganizationTree,updateAppOrganizationTree,addAppOrganizationTree,updateAppPosition, addAppPosition, getAppOrganizationTree, listAppPositionsByOrganizationId, deleteAppPosition,getCompanyInfoInCompanyID,getOrgType,getFunctionList,getFunctionByPositionID,updateAuth} from '../../../services/admin'
import '../../../style/luo.less'
import React from 'react';
import Tables from '../../../components/common/tables/Tables';

import FormsTwo from '../../../components/common/forms/FormsTwo'

import { TYPE_NAME } from '../../../components/Const'

import {funTraceInfo} from '../../../services/common'

export default class BasicSettings extends Component {




  state = {
    tableData: [],
    nodeId: [],
    treeId: [],
    chi:[],
    responseInfo: {},
    // 表格参数
    tableParamenter: {
      // 表格行是否可选
      rowSelection: true,
      // 表头：变量和变量解释
      header: {
        id: '职位序号',
        name: '职位名称',
      },
      // 下拉框：变量和变量解释
      headers: {
        id: '职位序号',
        name: '职位名称',
      },
      // 表格数据
      data: null,
    },// 表格参数END
    plainOptions:[],
		checkOptions:[],
		funCheckArry:[],
    expandedKeys: [],
    checkedKeys: [],
    selectedKeys: [],
    autoExpandParent: [],
    tree: {
      treeData: [
        {
          title: 'name',
          key: 'id',

          children: [
            {
              title: 'name',
              key: 'id',

              children: [
                { title: 'name', key: 'id' },
              ],
            },
          ],
        },
      ]
    },
    rowData: [],


    // 总条数
    totalCount: 0,

    params: {
      page: 1,
      size: 15,
      name: null,
      areaId: JSON.parse(localStorage.getItem('menus'))?.[0]
    },
    // 表格加载动画
    loading: false,
    visible: false,
    visible1: false,
    visibleadd:false,
    visible2:false,
    // 刷新动画
    refreshTable: false,


    // 表单参数
    formParamenter: {
      modal: true,
      number: 1,
      labelCol: 4,
      align: 'center',
      layout: "horizontal",
      parameter: [
        {
          variableName: 'positionId',
          str: '序号',
          rules: TYPE_NAME.INPUT,
          disabled: true,

        },
        {
          variableName: 'name',
          str: '职位名称',
          rules: TYPE_NAME.NOT_BLANK
        }
      ]
    },

    formParamenteres: {
      modal: true,
      number: 3,
      labelCol: 7,
      align: 'center',
      layout: "horizontal",
      parameter: [
        {
          variableName: 'id',
          str: '公司序号',
          rules: TYPE_NAME.INPUT,
          disabled: true,

        },
        {
					variableName: 'orgId',
					str: '公司类型',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
				},
        {
          variableName: 'name',
          str: '公司名称',
          rules: TYPE_NAME.NOT_BLANK
        },
        {
          variableName: 'shortName',
          str: '简称',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'province',
          str: '省',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'city',
          str: '市',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'district',
          str: '区',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'address',
          str: '详细地址',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'contactName',
          str: '联系人',
          rules: TYPE_NAME.INPUT,
        },
        {
          variableName: 'contact',
          str: '联系方式',
          rules: TYPE_NAME.INPUT,
        },
      ]
    },

    formdatas: [],
    updateDatas: {
      name: 'aaa'
    }
  };

  componentDidMount() {
    this.getTreeNode();
    let ckArry=[]
    getOrgType().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				console.log(data)
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i]?.id??null,value:data[i].name})
					}
				}
				console.log(selectArry);
				this.state.formParamenteres.parameter[1].data=selectArry;
			}
		)

    getFunctionList().then(
			response=>{
					if(response.data.data){
						for(var i=0;i<response.data.data.length;i++)
						{

							ckArry.push({label:response.data.data[i].name,value:response.data.data[i].id.toString(),disabled: false});

						}
						this.setState({checkOptions:ckArry})
					}
				}
		)

  }

//APP功能授权
addAppFun = data => {
  if (this.state.rowData.length < 1) return message.warning('未选择职位',[0.5])
  updateAuth({positionId: this.state.rowData[0].id,functionIds:this.state.plainOptions}).then(
    response => {
      this.state.rowData=null
      response.data.data ? message.success('授权成功',[0.5]) : message.error('授权失败',[0.5])
      // funTraceInfo("权限管理", "授权", null,this.state.rowData)
    }
  )

}

//根据复选框获取权限
onChange=(checkedValues)=> {
      this.setState({plainOptions: checkedValues})
};

  //展开/收起节点时触发
  onExpand = (expandedKeysValue: React.Key[]) => {
    this.setState({
      expandedKeys: expandedKeysValue,
      autoExpandParent: false
    });
  };




  //点击复选框触发
  onCheck = (checkedKeysValue: React.Key[]) => {
  };

  //点击树节点触发
  onSelect = (selectedKeysValue: React.Key[], info: any) => {

    let childrens=info.node.children;

    if(info.node.key.toString().indexOf('-')===1){
      this.setState({nodeId:[]});
      return false;}

    let node = info.node.parent;

    let key = info.node.key;
    let param = {
      organizationId: key
    }
    let name=info.node.title;

    listAppPositionsByOrganizationId(param).then(
      response => {
        let { data } = response.data

        if (data === null) {
          message.error("该组织部门下不存在职位",[0.5])
          let { tableParamenter,updateDatas } = this.state
          tableParamenter.data = data
          updateDatas.name=name
          this.setState({ tableParamenter, treeId: key, nodeId: node , chi:childrens,updateDatas})
        } else {
          let { tableParamenter,updateDatas } = this.state
          updateDatas.name=name
          tableParamenter.data = data
          this.setState({ tableParamenter, treeId: key, nodeId: node ,chi:childrens,updateDatas})
          message.success('查询成功',[0.5])
        }
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败'[0.5])
      }
    )
  };

  getData = () => {
    let param = {
      organizationId: this.state.treeId
    }

    listAppPositionsByOrganizationId(param).then(
      response => {
        let { data } = response.data

        let { tableParamenter } = this.state
        tableParamenter.data = data
        this.setState({ tableParamenter })

      }
    )
  }


  //表格刷新
  refreshTable = () => {
    // this.setState({ refreshTable: true })
    this.getData();
    // this.getTreeNode();
    // this.onExpand();
    // this.setState({refreshTable:false})



  }

  // 表格：获取勾选中的值
  onSelectChange = tableData => {
    this.setState({ rowData: tableData })
    getFunctionByPositionID({id: tableData[0].id}).then(
			response => {
				let pArry=[]
				if(response.data.data){
					for(var i=0;i<response.data.data.length;i++)
					{
						pArry.push(response.data.data[i].id.toString());
					}
				}

				this.setState({plainOptions:pArry})

			}
		)
  }



  getTreeNode = () => {//获取树节点
    this.setState({ loading: true });
    getAppOrganizationTree(this.state.responseInfo).then(
      reponse => {

        let { data } = reponse.data;

        let { responseInfo, tree } = this.state;
        responseInfo.data = data;
        tree.treeData = data
        this.setState({
          loading: false,
          refreshTable: false,
          totalCount: data.total,
          responseInfo: responseInfo,
          tree
        });
        message.success('查询成功',[0.5])
        // funTraceInfo("权限管理", "组织结构查询", null, tree)
      }
    ).catch(
      () => {
        this.setState({
          loading: false,
          refreshTable: false,
        });
        message.error('查询失败',[0.5])
      }
    )
  };

  //职位注销
  deletePost = data => {
    if (this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])

    let ids = this.state.rowData[0].id
    let name=this.state.rowData[0].name

    let param = {
      id: ids,
      name:name
    }

    Modal.confirm({
      title: `是否确认注销该职位？`,
      content: '',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk: () => {
        // 注销用户
        deleteAppPosition(param).then(
          response => {
            this.setState({
              loading: false,
              refreshTable: false,
              totalCount: data.total,
            });

            message.success('职位注销成功',[0.5])
            this.getData();
            // funTraceInfo("权限管理", "注销职位", param, null)
          }
        ).catch(
          () => {
            this.setState({
              loading: false,
              refreshTable: false,
            });

            message.error('职位注销失败',[0.5])
            this.getData();
          }
        )
      }
    })
  }

  //递归
  getTreeData = data => {
    data = Array.isArray(data) ? data : []
    let tree = data?.map(value => {
      return {
        title: value?.nodeExtend?.name,
        key: value?.nodeExtend?.id,
        parent: value?.id,
        // dataIndex:value?.positions?.name,
        disabled: false,
        children: this.getTreeData(value?.children)
      }
    })
    return tree
  }



  showModals = () => {
    if (this.state.rowData.length < 1) return message.warning('未选择数据',[0.5])
    let datas = {
      id: this.state.treeId,
      positionId: this.state.rowData[0].positionId,
      name: this.state.rowData[0].name,
    }


    this.setState({
      visible1: true,
      formdatas: datas
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  showModal = () => {
    let key = this.state.nodeId;
    console.log(key);

    // if(info.node.key.toString().indexOf('-')===1){
    //   return false;
    // }
    if (key.length === 0) return message.warning('未选择组织部门',[0.5])
    this.setState({
      visible: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  //新增
  handleOk = async () => {
    let value = await this.forms.onFinish()
    let params = {
      name: value?.name,
      organizationId: this.state.treeId
    };

    // 新增数据权限
    addAppPosition(params).then(
      response => {
        response.data.data ? message.success('新增成功',[0.5]) : message.error('新增失败',[0.5])
        this.getData();
        // funTraceInfo("权限管理", "新增职位", null, params)
      }
    )
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 0);

  };

  updateOk = async () => {
    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      organizationId: this.state.treeId,
      id: this.state.rowData[0].id,
    }

    // 修改职位
    updateAppPosition(params).then(
      response => {
        response.data.data ? message.success('修改成功') : message.error('修改失败',[0.5])
        // funTraceInfo("权限管理", "修改职位", this.state.formdatas, params)
        this.getData();
      }
    )
    setTimeout(() => {
      this.setState({ loading: false, visible1: false });
    }, 0);

  };


  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCancels = () => {
    this.setState({ visible1: false });
  };

  showes = () => {
    this.setState({
      visibleadd: true
    });
    setTimeout(() => this.forms.onModalClose(), 0)
  };

  addOr = async () => {
    this.setState({ loading: true });
    let value = await this.forms.onFinish()
    if(this.state.nodeId.length === 0) {
      this.setState({nodeId:null})
    }
    value.parentNodeId=this.state.nodeId
    let params = {
      name: value.name,
      parentNodeId: this.state.nodeId
    }
    // 新增数据权限
    addAppOrganizationTree(value).then(
      response => {
        response.data.data ? message.success('新增成功',[0.5]) : message.error('新增失败',[0.5])
        // funTraceInfo("权限管理", "新增组织", null, value)
        this.getTreeNode();
      }
    )
    setTimeout(() => {
      this.setState({ loading: false, visibleadd: false });
    }, 0);

  };

  addOrS = () => {
    this.setState({ visibleadd: false });
  };

  update = () => {
    let key = this.state.treeId
    if (key.length === 0) return message.warning('未选择组织部门',[0.5])
    getCompanyInfoInCompanyID({id: key}).then(
			response => {
				const {data} = response.data
				this.setState({visible2: true,updateDatas: data})
				message.success('查询成功')
				this.forms.onModalClose()
			}
		)
  };

  updateOr = async () => {
    let value = await this.forms.onFinish()
    let params = {
      name: value.name,
      organizationId: this.state.treeId,
    }
    // 修改职位
    updateAppOrganizationTree(value).then(
      response => {
        response.data.data ? message.success('修改成功') : message.error('修改失败',[0.5])
        // funTraceInfo("权限管理", "修改组织结构", this.state.updateDatas, value)
        this.getTreeNode();

      }
    )
    setTimeout(() => {
      this.setState({
        loading: false,
        visible2: false,
      });
    }, 2000);
  };

  updateOrS = () => {
    this.setState({ visible2: false });
  };

  deleteOrS=()=>{
    let key = this.state.nodeId
    if (key.length === 0) return message.error('未选择组织部门')
    if(this.state.chi.length === 0 ){
      let param = {
        organizationId: this.state.treeId,
        nodeId: key,
        organizationName:this.state.updateDatas
      }
      Modal.confirm({
        title: `是否确认删除该组织部门？`,
        onOk: () => {
          // 删除数据权限
          deleteAppOrganizationTree(param).then(
            response => {
              this.setState({
                loading: false,
                refreshTable: false,
              });
              message.success('删除成功',[0.5])
              this.getTreeNode();
              // funTraceInfo("权限管理", "删除组织结构", param, null)
            }
          ).catch(
            () => {
              this.setState({
                loading: false,
                refreshTable: false,
              });
              message.error('删除失败'[0.5])
              this.getTreeNode();
            }

          )
        }
      })
    }else{
      return message.error('请先对下面的组织部门进行删除进行删除',[0.5])
    }
  }

  render() {

    const { loading, visible, visible1,visibleadd,visible2 } = this.state;
    return (
      <Layout className={'item_body_number_back tables'} style={{ backgroundColor: '#ffffff' }}>
        <div id={'equipment_frame'}>
          <div className={'left_frame'}>
            <div className={'particulars_info_dives'}>
              <span className="title">组织结构：</span>

                <div className={'buttoner'}>
                <Radio.Group defaultValue="" buttonStyle="solid">
                <Button type="primary" onClick={this.showes} >新增</Button>

                <Modal
                      visible={visibleadd}
                      title="新增组织结构"
                      onOk={this.addOr}
                      onCancel={this.addOrS}
                      width={1200}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={this.addOr}
                        >
                          提交
                        </Button>,
                        <Button key="back" onClick={this.addOrS}>
                          返回
                        </Button>
                      ]}
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenteres}
                        data={this.state.data}
                      />
                    </Modal>


                </Radio.Group>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio.Group>

                <Button  onClick={this.deleteOrS} >删除</Button>
                </Radio.Group>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Radio.Group>
                <Button   onClick={this.update} >修改</Button>
                <Modal
                  visible={visible2}
                  title="修改组织结构"
                  onOk={this.updateOr}
                  onCancel={this.updateOrS}
                  width={1200}
                  footer={[
                    <Button
                      key="submit"
                      type="primary"
                      loading={loading}
                      onClick={this.updateOr}
                    >
                      提交
                    </Button>,
                    <Button key="back" onClick={this.updateOrS}>
                      返回
                    </Button>
                  ]}
                >
                  <FormsTwo
                    ref={e => this.forms = e}
                    formParamenter={this.state.formParamenteres}
                    data={this.state.updateDatas}
                  />
                </Modal>

                </Radio.Group>

                </div>

                <Tree
                  treeData={this.getTreeData(this.state.tree.treeData)}
                  getTreeNode={this.getTreeNode}
                  onExpand={this.onExpand}
                  expandedKeys={this.state.expandedKeys}
                  autoExpandParent={this.state.autoExpandParent}
                  onCheck={this.onCheck}
                  checkedKeys={this.state.checkedKeys}
                  onSelect={this.onSelect}
                />

            </div>
          </div>

          <div className={'right_frame'}>
            <div className={'nTable_info_div'} >
              <div className={'poss'} style={{height:'70%'}}>
                <Tables
                  tableParamenter={this.state.tableParamenter}
                  setTableValue={this.setTableValue} //设置表格显示
                  exportTable={this.exportTable}   // 导出时触发的方法
                  refreshTable={this.refreshTable}  // 刷新时触发的方法
                  onSelectChange={this.onSelectChange} // 选中多选框时触发的方法
                  loading={{
                    loading: this.state.loading,
                    refreshTable: this.state.refreshTable
                  }}
                >

                  <Radio.Group defaultValue="" buttonStyle="solid">
                    <Button type="primary" onClick={this.showModal} >新增职位</Button>
                    <Modal
                      visible={visible}
                      title="新增职位"
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={this.handleOk}
                        >
                          提交
                        </Button>,
                        <Button key="back" onClick={this.handleCancel}>
                          返回
                        </Button>
                      ]}
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenter}
                        data={this.state.data}
                      />
                    </Modal>

                  </Radio.Group>

                  <Button onClick={this.deletePost}>注销职位</Button>
                  <Radio.Group>
                    {/* <Button    onClick={this.deletePost}>注销职位</Button> */}
                    <Button type="info" onClick={this.showModals}>修改职位</Button>
                    <Modal
                      visible={visible1}
                      title="修改职位"
                      onOk={this.updateOk}
                      onCancel={this.handleCancels}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={this.updateOk}
                        >
                          提交
                        </Button>,
                        <Button key="back" onClick={this.handleCancels}>
                          返回
                        </Button>
                      ]}
                    >
                      <FormsTwo
                        ref={e => this.forms = e}
                        formParamenter={this.state.formParamenter}
                        data={this.state.formdatas}
                      />
                    </Modal>


                  </Radio.Group>

                </Tables>
              </div>
              <h4 style={{fontSize:'16px',padding:'20px 20px 0 20px'}}>APP功能 <Button style={{float:'right'}} type="primary" onClick={this.addAppFun}>授权</Button></h4>
              <div className={'funCheckList'}>
                    <Checkbox.Group
                      key={this.state.plainOptions}
                      defaultValue={this.state.plainOptions} // 多选框的值
                      style={{ width: '100%' }}
                      onChange={this.onChange}    //多选框点击事件
                    >
                      {
                        // 根据右框各个数据展示出来
                        this.state.checkOptions.map(item => (
                            <Tooltip title={item.name}>
                              <Checkbox
                                disabled={item.disabled}    // 状态控制
                                key={item.value}               // id
                                value={item.value.toString()}>
                                <span>{item.label}</span>
                              </Checkbox>
                            </Tooltip>
                        ))
                      }
                    </Checkbox.Group>
               </div>
            </div>

          </div>
        </div>

      </Layout>
    )
  }

}
