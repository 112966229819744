import React, { Component } from 'react'
import {Button, Input, Layout, message, Modal, Popconfirm, Spin, Table, Tabs} from 'antd'
import {TYPE_NAME} from "../../../../components/Const";
import FormsTwo from "../../../../components/common/forms/FormsTwo";
import TableComponents from "../../../../components/common/tables/TableComponentsNew";
import {
    addDeviceAlarm,
    listDeviceModelByDeviceClassId,
    pageValveInfo,
    removeDev,
    updateDevice
} from "../../../../services/qrCode";
import {PlusSquareOutlined,RedoOutlined} from '@ant-design/icons';
import {
    addDev,
    addFluoriteDev,
    deleteDev, deleteFluoriteDev, FluoriteDevState,
    getDevInfoByCustomerId,
    removeSecurityCheckpoint
} from "../../../../services/meter";
import moment from "moment";
import {liveFluoriteDev} from "../../../../services/customerDataPanel";
import {getUnboundValveAlarm} from "../../../../services/customer";

const {TabPane} = Tabs;
export default class accountInfo extends Component {
    state = {
        // 阀井表格参数
        tableParamenter_1: {
            manageName: "阀井表格参数",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            isNotInitData:true,
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                // devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                edit: '操作',
            },
            params: {
                customerId:this.props?.rowData?.customerId??null,
                devType:'阀井',
                page:1,
                size:10,

            },

        },
        // 压力表表格参数
        tableParamenter_2: {
            manageName: "压力表表格参数",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            isNotInitData:true,
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                // devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                edit: '操作',
            },
            params: {
                customerId:this.props?.rowData?.customerId??null,
                devType:'压力表',
                page:1,
                size:10,
            },
        },
        // 监控表格参数
        tableParamenter_3: [
            {title: '设备名', dataIndex: 'devName', key: 'devName'},
            {title: '萤石云token', dataIndex: 'accessToken', key: 'accessToken'},
            {title: '地址', dataIndex: 'url', key: 'url'},
            {title: '状态', dataIndex: 'bindType', key: 'bindType',
                render:(text,rowData)=>{return<><span>{text===0?'启动中':'停用中'}</span></>}
            },
            {title: '操作', dataIndex: 'edit', key: 'edit',
                render:(text,rowData)=>{
                    return<>
                        <div className={'tableLink'} title={'删除'} >
                            <Popconfirm title="是否删除?"
                                        onConfirm={()=>{
                                            this.setState({load:false});
                                            this.deleteFluoriteDev(rowData.bindId);
                                        }}
                                        okText="是" cancelText="否">
                                <u>删除</u>
                            </Popconfirm>
                        </div>
                        <div className={'tableLink'} title={'切换'} >
                            <Popconfirm title="是否更改当前状态?"
                                        onConfirm={()=>{
                                            this.setState({load:false});
                                            this.FluoriteDevState(rowData.bindId,rowData.bindType===0?1:0);
                                        }}
                                        okText="是" cancelText="否">
                                <u style={{color:rowData.bindType===0?'red':null}}>{rowData.bindType===0?'停用':'启动'}</u>
                            </Popconfirm>
                        </div>
                    </>;
                },
            },
        ],
        tableParamenter_3_data:[],
        tableParamenter_3_param:{customerId:this.props?.rowData?.customerId??null,},
        // 阀门表格参数
        tableParamenter_4: {
            manageName: "阀井表格参数",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            isNotInitData:false,
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                // devType: '设备类型',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                uniqueEncoding: '唯一编码',
                installPosition: '安装位置',
                comStatus: '通信状态',
                systemTime: '通信时间',
                edit: '操作',
            },
            params: {
                customerId:this.props?.rowData?.customerId??null,
                devType:'阀门',
                page:1,
                size:10,
            },
        },
        // 监控设备表格参数
        tableParamenter_5: {
            manageName: "监控设备表格参数",
            // 是否绑定左侧区域
            bindArea: false,
            // 表格行是否可选
            rowSelection: false,
            //下拉框长度
            selectWidth: '6.5vw',
            // 表头：变量和变量解释
            header: {
                devModelName:'设备型号',
                devName:'设备名',
                serialno:'设备编号',
                manufacture: '生产厂家',
                state:'设备状态',
                edit: '操作',
            },
            params: {
                customerId:this.props?.rowData?.customerId??null,
                devType:'阀门',
                page:1,
                size:10,

            },
        },

        // 弹框-通用-内容
        modalFormItem_1: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'hdstno', str: '厂家编号', rules: TYPE_NAME.NOT_BLANK,},
                {onChange: e => this.onChangeSelect_1(e),
                    variableName: 'devModelId', str: '型号',
                    rules: TYPE_NAME.REQ_SELECT, data:[]},
                {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT},
                {variableName: 'remark', str: '备注', rules: TYPE_NAME.TEXT_AREA,},
            ],
            searchGetData:{}
        },
        // 弹框-阀门-内容
        modalFormItem_3: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'hdstno', str: '厂家编号', rules: TYPE_NAME.NOT_BLANK,},
                {
                    onChange:((e)=>{this.listDeviceModelByDeviceClassId1(e);}),
                    variableName: 'devType', str: '阀门类型', rules: TYPE_NAME.REQ_SELECT,
                    data:[{key:'10170',value:'电动阀'}, {key:'10204',value:'进气球阀'}]},
                {onChange: e => this.onChangeSelect_2(e), variableName: 'devModelId', str: '型号', rules: TYPE_NAME.REQ_SELECT, data:[]},

                {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'manufactureTime', str: '生产日期', rules: TYPE_NAME.DATE_TIME_FORMAT},
                {variableName: 'remark', str: '备注', rules: TYPE_NAME.TEXT_AREA,},
            ],
            searchGetData:{}
        },
        // 弹框-监控-内容
        modalFormItem_2: {
            modal: true,
            number: 1,
            labelCol: 8,
            layout: "horizontal",
            parameter: [
                {variableName: 'serialno', str: '设备编号', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'devName', str: '设备名', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'devCode', str: '设备验证码', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'bindContacts', str: '客户联系人', rules: TYPE_NAME.NOT_BLANK,},
                {variableName: 'manufacture', str: '生产厂家', rules: TYPE_NAME.NOT_BLANK},
                {variableName: 'remark', str: '备注', rules: TYPE_NAME.TEXT_AREA,},
            ],
            searchGetData:{}
        },

        rowData:null,           // 表格所选数据存储
        devData_1:null,         // 弹窗-选择设备存储
        // 10171 压力表



        devModel:true,
        modalVisible_1:false,   // 弹窗开关-1
        modalVisible_2:false,   // 弹窗开关-2
        modalVisible_3:false,   // 弹窗开关-3
        modalVisible_4:false,   // 弹窗开关-4
        modalFormItemText:'创建',
        devType:'10200',        // 所选设备 10200阀井，10171压力表
        load:true,
    };

    componentDidMount(): void {

    }

    // 查询监控设备
    liveFluoriteDev=()=>{
        if(!this.props?.rowData?.customerId){return false;}
        var parms = new URLSearchParams();
        parms.append('customerId',this.props?.rowData?.customerId);
        parms.append('type','列表');
        liveFluoriteDev(parms).then(
            response=>{
                var tableData = [...this.state.tableParamenter_3_data];
                tableData=[];
                if(response?.data?.data){
                    response.data.data.infos.map(one=>{
                        tableData.push({
                            bindId:one.bindId??null,
                            devName:one.devName??null,
                            accessToken:response.data?.data?.accessToken??null,
                            bindType:one.bindType??null,
                            url:one?.url??null})
                    });
                    this.setState({tableParamenter_3_data:tableData})
                }
            }
        );
    };
    // 删除系统设备
    deleteDev=(id)=>{
        deleteDev({id}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功');
                }else{
                    message.info('删除失败');
                }
                this.setState({load:true});
            }
        )
    };


    // 阀井型号方法-通用
    onChangeSelect_1=async(value, index) => {
        const {modalFormItem_1} = this.state;
        for(var i = 0; i<modalFormItem_1.parameter[3].data.length;i++){
            console.log(modalFormItem_1.parameter[3].data[i]);
            if(modalFormItem_1.parameter[3].data[i].devModelId===value){
                this.form_1.setFieldsValue('manufacture',modalFormItem_1.parameter[3].data[i].manufacture);
                this.setState({devData_1:modalFormItem_1.parameter[3].data[i]})
            }
        }
    };
    // 阀井型号方法-阀门
    onChangeSelect_2=async(value, index) => {
        const {modalFormItem_3} = this.state;
        for(var i = 0; i<modalFormItem_3.parameter[4].data.length;i++){
            console.log(modalFormItem_3.parameter[4].data[i]);
            if(modalFormItem_3.parameter[4].data[i].devModelId===value){
                this.form_1.setFieldsValue('manufacture',modalFormItem_3.parameter[4].data[i].manufacture);
                this.setState({devData_1:modalFormItem_3.parameter[4].data[i]})
            }
        }
    };
    // 添加和修改阀井
    addDev_1= async (devType) =>{
        var data = await this.form_1.onFinishNotReset();
        if(!data){return false;}
        let user = JSON.parse(sessionStorage.getItem("user"));
        var params={
            // "id": 0,                         // 阀警ID-新增为空
            // "devId": data?.devModelId??null, // device服务主键id-不需要
            // "installPosition": "",           // 安装位置-不需要
            // "locationId": 0,                 // 主键ID-不需要
            // 	设备相关图片 -不需要
            // "photos": [{"devId": 0, "devType": 0, "photoKey": "", "photoPath": ""}],
            "serialno": data?.serialno??null,                           // 序列号/设备编号
            "bindContacts": user?.devName??null,                       // 绑定客户联系人 客户联系人
            "customerId": this.props?.rowData?.customerId??null,        // 所属客户ID
            "customerName": this.props?.rowData?.customerName??null,    // 所属客户名称
            "devName":data?.devName??null,                              // 设备名
            "devModelId": data?.devModelId??null,                       // 设备型号id
            "devModelName": this.state?.devData_1?.devModelName??null,    // 设备型号名
            "devType": devType,                                           // 设备类型
            "hdstno": data?.hdstno??null,                               // 厂家编号
            "manufacture": data?.manufacture??null,                     // 厂家编号
            "manufactureTime":data?.manufactureTime?moment(data.manufactureTime).format("yyyy-MM-DD"):null,               // 生产日期
            "installDate": moment(new Date()).format("yyyy-MM-DD"),// 安装时间-现在
            "installer": user?.realName??null,  // 安装人-登录员
            "installerTel": user?.phone??null,  // 安装联系电话-登录员
            "phone": user?.phone??null,         // 手机号码-登录员
            "remark": data?.remark??null,       // 安装备注

        };
        addDev(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('添加成功')

                }else{
                    message.info('添加失败')
                }
                this.table1.refreshTable();
            }
        )
    };
    // 新增监控设备
    addDev_2= async () =>{
        var data = await this.form_1.onFinishNotReset();
        if(!data){return false;}
        let user = JSON.parse(sessionStorage.getItem("user"));
        var params={
            "serialno": data?.serialno??null,                         // 序列号/设备编号
            "devName": data?.devName??null,                           // 设备名
            "devCode": data?.devCode??null,                           // 设备验证码
            "bindContacts": data.bindContacts??user?.realName,        // 绑定客户联系人 客户联系人
            "customerId": this.props?.rowData?.customerId??null,      // 所属客户ID
            "customerName": this.props?.rowData?.customerName??null,  // 所属客户名称
            "devType": '10209',                                       // 设备类型
            "manufacture": data?.manufacture??null,                   // 厂家编号
            "remark": data?.remark??null,       // 安装备注
        };
        console.log(params);
        addFluoriteDev(params).then(
            response=>{
                if(response?.data?.data){
                    message.info('添加成功');
                    this.liveFluoriteDev()
                }else{
                    message.info('添加失败')
                }
                this.table1.refreshTable();
                this.setState({load:true})
            }
        )
    };

    // 获取控制器型号数据-通用
    listDeviceModelByDeviceClassId=(devType)=>{
        listDeviceModelByDeviceClassId({deviceClassId : devType}).then(
            response=>{
                if(response?.data?.data){
                    var modalFormItem = this.state.modalFormItem_1;
                    modalFormItem.parameter[3].data=[];
                    var data=response.data.data;
                    for(var i=0;i<data.length;i++){
                        var parameter= data[i];
                        parameter.key=data[i].devModelId;
                        parameter.value=data[i].devModelName;
                        // parameter.value=data[i].devModelName,
                        modalFormItem.parameter[3].data.push(parameter)
                    }
                    this.setState({modalFormItem_1:modalFormItem});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });
    };
    // 获取控制器型号数据-阀门
    listDeviceModelByDeviceClassId1=(devType)=>{
        if(!devType){return false;}
        this.setState({devType:devType});
        listDeviceModelByDeviceClassId({deviceClassId : devType}).then(
            response=>{
                if(response?.data?.data){
                    var modalFormItem = this.state.modalFormItem_3;
                    modalFormItem.parameter[4].data=[];
                    var data=response.data.data;
                    for(var i=0;i<data.length;i++){
                        var parameter= data[i];
                        parameter.key=data[i].devModelId;
                        parameter.value=data[i].devModelName;
                        // parameter.value=data[i].devModelName,
                        console.log('-------------------------------------');
                        console.log(parameter);
                        modalFormItem.parameter[4].data.push(parameter)
                    }
                    this.setState({modalFormItem_3:modalFormItem});
                }else {
                    message.info('获取设备型号失败，请刷新页面')
                }
            }
        ).catch(error=>{
            message.info('异常错误')
        });
    };
    setTableValue_1=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async ()=>{
                             var modalFormItem = this.state.modalFormItem_1;

                             modalFormItem.parameter[2].disabled=true;
                             modalFormItem.parameter[4].disabled=true;
                             modalFormItem.parameter[5].disabled=true;

                             modalFormItem.searchGetData.serialno=data?.serialno??null;
                             modalFormItem.searchGetData.devName=data?.devName??null;
                             modalFormItem.searchGetData.hdstno=data?.hdstno??null;
                             modalFormItem.searchGetData.devModelId=data?.devModelId??null;
                             modalFormItem.devType=data?.devType??null;
                             modalFormItem.searchGetData.manufacture=data?.manufacture??null;
                             modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                             modalFormItem.searchGetData.remark=data?.remark??null;
                             await this.setState({
                                 modalVisible_1:true,           // 弹窗
                                 devModel:false,                // 新增和修改模式
                                 modalFormItemText:'修改阀井',   // 标题
                                 devType:'10200',               // 类型
                                 modalFormItem_1:modalFormItem, // 弹窗表单数据
                                 rowData:data                   // 所选表格行
                             });
                             await this.form_1.onFinish();
                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除阀井?"
                                    onConfirm={()=>{
                                        this.removeDev(data?.id)
                                    }}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    setTableValue_2=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async ()=>{
                             var modalFormItem = this.state.modalFormItem_1;

                             modalFormItem.parameter[2].disabled=true;
                             modalFormItem.parameter[4].disabled=true;
                             modalFormItem.parameter[5].disabled=true;


                             modalFormItem.searchGetData.serialno=data?.serialno??null;
                             modalFormItem.searchGetData.devName=data?.devName??null;
                             modalFormItem.searchGetData.hdstno=data?.hdstno??null;
                             modalFormItem.searchGetData.devModelId=data?.devModelId??null;
                             modalFormItem.devType=data?.devType??null;
                             modalFormItem.searchGetData.manufacture=data?.manufacture??null;
                             modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                             modalFormItem.searchGetData.remark=data?.remark??null;
                             await this.setState({
                                 modalVisible_1:true,           // 弹窗
                                 devModel:false,                // 新增和修改模式
                                 modalFormItemText:'修改压力表',   // 标题
                                 devType:'10171',               // 类型
                                 modalFormItem_1:modalFormItem, // 弹窗表单数据
                                 rowData:data                   // 所选表格行
                             });
                             await this.form_1.onFinish();
                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除压力表?"
                                    onConfirm={()=>{
                                        this.removeDev(data?.id)
                                    }}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }

    };
    setTableValue_3=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return<>
                    <div className={'tableLink'} title={'修改'}
                         onClick={async ()=>{
                             this.listDeviceModelByDeviceClassId1(data?.devType);
                             var modalFormItem = this.state.modalFormItem_3;

                             modalFormItem.parameter[2].disabled=true;
                             modalFormItem.parameter[5].disabled=true;
                             modalFormItem.parameter[6].disabled=true;

                             modalFormItem.searchGetData.serialno=data?.serialno??null;
                             modalFormItem.searchGetData.devName=data?.devName??null;
                             modalFormItem.searchGetData.hdstno=data?.hdstno??null;
                             modalFormItem.searchGetData.devType=data?.devType??null;
                             modalFormItem.searchGetData.devModelId=data?.devModelId??null;
                             modalFormItem.searchGetData.manufacture=data?.manufacture??null;
                             modalFormItem.searchGetData.manufactureTime=data?.manufactureTime?moment(data?.manufactureTime):null;
                             modalFormItem.searchGetData.remark=data?.remark??null;
                             await this.setState({
                                 modalVisible_3:true,           // 弹窗
                                 devModel:false,                // 新增和修改模式
                                 modalFormItemText:'修改阀门',   // 标题
                                 modalFormItem_3:modalFormItem, // 弹窗表单数据
                                 rowData:data                   // 所选表格行
                             });

                             await this.form_1.onFinish();
                             this.form_1.setFieldsValue('devType',data?.devType==='10170'?'电动阀':'进气球阀')
                         }}
                    >
                        <u>{'修改'}</u>
                    </div>
                    <div className={'tableLink'} title={'删除'}>
                        <Popconfirm title="是否删除阀门?"
                                    onConfirm={()=>{
                                        this.removeDev(data?.id)
                                    }}
                                    okText="是" cancelText="否">
                            <a>删除</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    setTableValue_4=(value,text,data)=>{
        if(text === 'null') return '-';
        switch (value) {
            case 'edit':
                return<>

                    <div className={'tableLink'} title={'绑定'}>
                        <Popconfirm title="是否绑定设备?"
                                    onConfirm={()=>{
                                        this.removeDev(data?.id)
                                    }}
                                    okText="是" cancelText="否">
                            <a>绑定</a>
                        </Popconfirm>
                    </div>
                </>;
            default:
                return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
        }
    };
    upDetector = async devType =>{
        this.setState({modalVisible_1:false});

        var data = await this.form_1.onFinishNotReset();// 获取表单数据
        if(!data){return false;}
        let user = JSON.parse(sessionStorage.getItem("user"));
        var params={
            "id": this.state.rowData.id,        // 阀警ID-新增为空
            "devId": this.state.rowData.devId, // device服务主键id-不需要
            "serialno": data?.serialno??null,                           // 序列号/设备编号
            "bindContacts": user?.realName??null,                       // 绑定客户联系人 客户联系人
            "customerId": this.props?.rowData?.customerId??null,        // 所属客户ID
            "customerName": this.props?.rowData?.customerName??null,    // 所属客户名称
            "devName":data?.devName??null,                              // 设备名
            "devModelId": data?.devModelId??null,                       // 设备型号id
            "devModelName": this.state?.devData_1?.devModelName??this.state?.rowData?.devModelName,    // 设备型号名
            "devType": devType,                                           // 设备类型
            "hdstno": data?.hdstno??null,                               // 厂家编号
            "manufacture": data?.manufacture??null,                     // 厂家编号
            "manufactureTime":data?.manufactureTime?moment(data.manufactureTime).format("yyyy-MM-DD"):null,               // 生产日期
            "installDate": moment(new Date()).format("yyyy-MM-DD"),// 安装时间-现在
            "installer": user?.realName??null,  // 安装人-登录员
            "installerTel": user?.phone??null,  // 安装联系电话-登录员
            "phone": user?.phone??null,         // 手机号码-登录员
            "remark": data?.remark??null,       // 安装备注

        };
        addDeviceAlarm(params).then(
            response=>{
                console.log(response);
                if(response.data.data){
                    message.info('阀井修改成功');
                    // this.table2.refreshTable(this.state.tableParamenter_3.params);
                }else{
                    message.info('阀井修改失败,'+response.data.responseInfo.description)
                }
                this.table1.refreshTable();
            }
        )

    };




    // 删除阀井
    removeDev=(devId)=>{
        deleteDev({id:devId}).then(
            response=>{
                console.log(response);
                if(response?.data?.data){
                    message.info('删除成功');
                }else{
                    message.info(response?.data?.responseInfo?.description)
                }
                this.table1.refreshTable(this.state.tableParamenter_1.params);
            }
        )
    };

    // 莹石设备删除
    deleteFluoriteDev=(id)=>{
        deleteFluoriteDev({id}).then(
            response=>{
                if(response?.data?.data){
                    message.info('删除成功');
                }else{
                    message.info('删除失败');
                }
                this.setState({load:true});
                this.liveFluoriteDev();
            }

        )
    };

    // 莹石设备状态
    FluoriteDevState=(bindId,bindType)=>{
        console.log(bindId);
        console.log(bindType);
        FluoriteDevState({bindId,bindType}).then(
            response=>{
                if(response?.data?.data){
                    message.info('更改成功');
                }else{
                    message.info('更改失败');
                }
                this.liveFluoriteDev();
                this.setState({load:true});
            }
        )
    };

    render(){
        return (
            <Layout className={'item_body_number_back'}>
                <div style={{
                    paddingTop:'25%',
                    zIndex:9999,
                    textAlign: 'center',
                    width:'100%',
                    height:'100%',
                    whiteSpace: 'nowrap',
                    position: 'absolute',
                    backgroundColor:'rgba(193,193,193,0.3)',
                    display: this.state.load?'none':null
                }}
                >
                    <Spin/><span style={{fontSize:'21px',marginLeft:'5px'}}>提交中</span>
                </div>


                <div style={{width:'100%',height:'100%',backgroundColor:'#fff'}}>
                    <Tabs tabPosition={"top"}
                          animated={true}
                          onTabClick={(e,a)=>{
                              console.log(e);
                              if(e.indexOf('1')!==-1){
                                  this.table1.refreshTable(this.state.tableParamenter_4.params)
                              }else if(e.indexOf('2')!==-1){
                                  this.table1.refreshTable(this.state.tableParamenter_1.params)
                              }else if(e.indexOf('3')!==-1){
                                  this.table1.refreshTable(this.state.tableParamenter_2.params)
                              }else if(e.indexOf('4')!==-1){
                                  this.liveFluoriteDev()// 查询监控
                              }
                          }}
                    >
{/*  阀门-------------------------------------  */}
                        <TabPane tab="阀门" key="1">
                            <div style={{width:'100%',height:'100%'}}>
                                <div style={{width:'100%',height:"40px"}}>
                                    <div className={'topBtnBox'}>
                                        <span className={'addLink'} style={{float: 'left'}}
                                              onClick={()=>{
                                                  var modalFormItem = this.state.modalFormItem_3;

                                                  modalFormItem.parameter[2].disabled=false;
                                                  modalFormItem.parameter[5].disabled=false;
                                                  modalFormItem.parameter[6].disabled=false;

                                                  this.setState(
                                                      {
                                                      modalFormItem_3:modalFormItem,
                                                      modalVisible_3:true,
                                                      modalFormItemText:'创建阀门',
                                                      devModel:true
                                                      })
                                              }} >
                                            <PlusSquareOutlined />创建新阀门
                                        </span>
                                        <span className={'addLink'} style={{float: 'right'}}
                                              onClick={async()=>{await this.table1.refreshTable(this.state.tableParamenter_4.params);}} ><RedoOutlined />刷新
                                        </span>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"70%",padding:'0 0 0 0'}}>
                                    <TableComponents
                                        paramenter={this.state.tableParamenter_4}
                                        setData = {getDevInfoByCustomerId}
                                        setTableValue={this.setTableValue_3}
                                        ref={e => this.table1 = e}
                                    >
                                    </TableComponents>
                                </div>
                            </div>
{/*  阀井-------------------------------------  */}
                        </TabPane>
                        <TabPane tab="阀井" key="2">
                            <div style={{width:'100%',height:'100%'}}>
                                <div style={{width:'100%',height:"40px"}}>
                                    <div className={'topBtnBox'}>
                                        <span className={'addLink'} style={{float: 'left'}}
                                              onClick={()=>{
                                                  this.listDeviceModelByDeviceClassId(10200);

                                                  var modalFormItem = this.state.modalFormItem_1;

                                                  modalFormItem.parameter[2].disabled=true;
                                                  modalFormItem.parameter[4].disabled=true;
                                                  modalFormItem.parameter[5].disabled=true;
                                                  this.setState(
                                                      {
                                                          modalFormItem_1:modalFormItem,
                                                          modalVisible_1:true,
                                                          modalFormItemText:'创建阀井',
                                                          devType:'10200',devModel:true
                                                      })
                                              }} >
                                            <PlusSquareOutlined />创建新阀井
                                        </span>
                                        <span className={'addLink'} style={{float: 'right'}}
                                              onClick={async()=>{await this.table1.refreshTable(this.state.tableParamenter_1.params);}} ><RedoOutlined />刷新
                                        </span>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"70%",padding:'0 0 0 0'}}>
                                    <TableComponents
                                        paramenter={this.state.tableParamenter_1}
                                        setData = {getDevInfoByCustomerId}
                                        setTableValue={this.setTableValue_1}
                                        ref={e => this.table1 = e}
                                    >
                                    </TableComponents>
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="压力表" key="3">
                            <div style={{width:'100%',height:'100%'}}>
                                <div style={{width:'100%',height:"40px"}}>
                                    <div className={'topBtnBox'}>
                                        <span className={'addLink'} style={{float: 'left'}}
                                              onClick={()=>{
                                                  this.listDeviceModelByDeviceClassId(10171);
                                                  var modalFormItem = this.state.modalFormItem_1;
                                                  modalFormItem.parameter[2].disabled=false;
                                                  modalFormItem.parameter[4].disabled=false;
                                                  modalFormItem.parameter[5].disabled=false;

                                                  this.setState(
                                                      {
                                                          modalFormItem_1:modalFormItem,
                                                          modalVisible_1:true,
                                                          modalFormItemText:'创建压力表',
                                                          devType:'10171',
                                                          devModel:true
                                                      })
                                              }} >
                                            <PlusSquareOutlined />创建新压力表
                                        </span>
                                        <span className={'addLink'} style={{float: 'right'}}
                                              onClick={async()=>{await this.table1.refreshTable(this.state.tableParamenter_2.params);}} ><RedoOutlined />刷新
                                        </span>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"70%",padding:'0 0 0 0'}}>
                                    <TableComponents
                                        paramenter={this.state.tableParamenter_2}
                                        setData = {getDevInfoByCustomerId}
                                        setTableValue={this.setTableValue_2}
                                        ref={e => this.table1 = e}
                                    >
                                    </TableComponents>
                                </div>
                            </div>
                        </TabPane>
{/*监控设备--------------*/}
                        <TabPane tab="监控设备" key="4">
                            <div style={{width:'100%',height:'100%'}}>
                                <div style={{width:'100%',height:"40px"}}>
                                    <div className={'topBtnBox'}>
                                        <span className={'addLink'} style={{float: 'left'}}
                                              onClick={()=>{
                                                  this.listDeviceModelByDeviceClassId(10200);
                                                  this.setState({modalVisible_2:true,modalFormItemText:'创建监控',devType:'10209',devModel:true})}} >
                                            <PlusSquareOutlined />创建监控
                                        </span>
                                        {/*<span className={'addLink'} style={{float: 'left'}}*/}
                                        {/*      onClick={()=>{*/}
                                        {/*          this.listDeviceModelByDeviceClassId(10200);*/}
                                        {/*          this.setState({modalVisible_4:true})}} >*/}
                                        {/*    绑定设备*/}
                                        {/*</span>*/}
                                        <span className={'addLink'} style={{float: 'right'}}
                                              onClick={async()=>{this.liveFluoriteDev();}} ><RedoOutlined />刷新
                                        </span>
                                    </div>
                                </div>
                                <div style={{width:'100%',height:"70%", padding:'0 0 0 0'}}>
                                    <Table
                                        columns={this.state.tableParamenter_3}
                                        dataSource={this.state.tableParamenter_3_data}
                                        // rowKey={"id"}
                                        bordered
                                        // pagination={false}
                                        pagination = {{
                                            showSizeChanger:false,
                                            showQuickJumper: false,
                                            // onChange: this.getPaginationRight,       // 获得翻页数值
                                            total:this.state.tableParamenter_3_data?.length,
                                            pageSize:6,
                                            showTotal: (count = this.state.tableParamenter_3_data?.length)=>{return '共'+count+'条数据'},
                                            defaultCurrent: 1,
                                            pageSizeOptions: ['6']
                                        }}
                                    />
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
{/*弹出窗口-1-通用-----*/}
                <Modal
                    title={this.state.modalFormItemText}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_1}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => {
                        if(this.state.devModel){
                            console.log('新增');
                            this.addDev_1(this.state.devType);// 新增
                        }else{
                            console.log('修改');
                            this.upDetector(this.state.devType);
                        }

                        this.setState({modalVisible_1:false})
                    }}
                    // 点击取消
                    onCancel={()=> {
                        this.setState({modalVisible_1:false})
                    }}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'100%' }}>
                        <FormsTwo
                            ref={e => this.form_1 = e}
                            formParamenter={this.state.modalFormItem_1}
                            data={this.state.modalFormItem_1.searchGetData}
                        />
                    </div>
                </Modal>
{/*监控--------------------------------------*/}
                <Modal
                    title={this.state.modalFormItemText}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_2}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => {
                        if(this.state.devModel){
                            console.log('新增');
                            this.addDev_2(this.state.devType);// 新增
                        }else{
                            console.log('修改');
                            // this.upDetector(this.state.devType);
                        }
                        this.setState({modalVisible_2:false,load:false})
                    }}
                    // 点击取消
                    onCancel={()=> {this.setState({modalVisible_2:false})}}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'100%' }}>
                        <FormsTwo
                            ref={e => this.form_1 = e}
                            formParamenter={this.state.modalFormItem_2}
                            data={this.state.modalFormItem_2.searchGetData}
                        />
                    </div>
                </Modal>
{/*弹出窗口-1-通用-----*/}
                <Modal
                    title={this.state.modalFormItemText}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_3}
                    okText={this.state.modalFormItemText}
                    // 点击确定
                    onOk={() => {
                        if(this.state.devModel){
                            console.log('新增');
                            this.addDev_1(this.state.devType);// 新增
                        }else{
                            console.log('修改');
                            this.upDetector(this.state.devType);
                        }

                        this.setState({modalVisible_3:false})
                    }}
                    // 点击取消
                    onCancel={()=> {
                        this.setState({modalVisible_3:false})
                    }}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{width:'80%', marginLeft: '30px',height:'100%' }}>
                        <FormsTwo
                            ref={e => this.form_1 = e}
                            formParamenter={this.state.modalFormItem_3}
                            data={this.state.modalFormItem_3.searchGetData}
                        />
                    </div>
                </Modal>
                {/*弹出窗口-绑定监控设备-----*/}
                <Modal
                    title={'设备绑定'}
                    style={{ top: 0 }}
                    visible={this.state.modalVisible_4}
                    okText={'设备绑定'}
                    // 点击确定
                    onOk={() => {this.setState({modalVisible_3:false})}}
                    // 点击取消
                    onCancel={()=> {this.setState({modalVisible_3:false})}}
                    width={800}
                    centered
                >
                    <div className={'topFormBox'} style={{height:'100%' }}>
                        <TableComponents
                            paramenter={this.state.tableParamenter_5}
                            setData = {getUnboundValveAlarm}
                            setTableValue={this.setTableValue_4}
                            ref={e => this.table3 = e}
                        >
                        </TableComponents>
                    </div>
                </Modal>
            </Layout>
        )
    }
}
