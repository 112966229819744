import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Popconfirm} from 'antd'
import { NavLink } from "react-router-dom"

import TableComponents from '../../../components/common/tables/TableComponents'
import {TYPE_NAME} from '../../../components/Const'

import {funTraceInfo} from '../../../services/common'
import {deleteItem, getDataItemList} from '../../../services/device'

export default class PointManagement extends Component {

    state = {
		// 表格参数
		tableParamenter: {
			manageName: "数据项管理",
			// 表格行是否可选
            rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				id: '数据项代码',
				name: '数据项名称',
				unit: '单位',
				remark: '备注',
				searchName: '搜索名称',
				updateTime: '更新时间',
				createTime: '创建时间',
				edit:'操作'
			},
			// 弹出框
			headers: {
				name: '数据项名称',
				unit: '单位',
				remark: '备注',
				searchName: '搜索名称',
				updateTime: '更新时间',
				createTime: '创建时间',
			},
			// 条件选择器参数
			conditionsParamenter: {
				parameter: [
					{
						type: TYPE_NAME.INPUT,
						variableName: 'name',
						str: '数据项名称'
					},

				]
			},
			// 是否绑定左侧区域
			bindArea: true
		},
	}

	updatePoint = () => {
		let rowData = this.table.getSelectChange()
		if(rowData.length < 1) return message.error('未选择数据')
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		}
		this.props.history.push({
			pathname: '/device/addDataItem',
			state: data
		})
	};
	setTableValue = (value,text,rowData) => {
		switch (value) {
			case 'edit':

				return (
					<div>
						<div style={{paddingLeft:'10px',float: 'left'}}>
							<Popconfirm title="是否删除数据项?"
										onConfirm={()=>{
											this.deleteItem(rowData?.id)
										}}
								// onCancel={cancel}
										okText="是" cancelText="否">
								<a>删除数据项</a>
							</Popconfirm>
						</div>
					</div>
				);
			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	// 删除数据项
	deleteItem=(itemIds)=>{
		deleteItem({itemIds}).then(
			response=>{
				if(response?.data?.data){
					message.info(response?.data?.data)
				}else{
					message.info(response?.data?.data)
				}
				this.table.refreshTable();  // 获得树形结构的数据
			}
		)
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<TableComponents
					paramenter={this.state.tableParamenter}
					setData = {getDataItemList}
					setTableValue={this.setTableValue}
					ref={e => this.table = e}
				>
					<Radio.Group defaultValue="addDataItem" buttonStyle="solid">
						<NavLink to="/device/addDataItem">
							<Radio.Button value="addDataItem">新增数据项</Radio.Button>
						</NavLink>
						<Radio.Button value="updatePoint" onClick={this.updatePoint}>修改数据项</Radio.Button>
					</Radio.Group>
					{/* <Button type="primary" onClick={this.showModal}>计量点关系图</Button> */}
				</TableComponents>
				<Modal centered title="计量点关系图" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
					<p>计量点关系图</p>
				</Modal>
            </Layout>
        )
    }
}
